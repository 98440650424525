<template>
  <div
    v-if="isShowHistory"
    class="enterpriseDetail detail-history"
    id="History"
    data-name="公司历程"
  >
    <el-card :body-style="{ paddingTop: '0' }">
      <div slot="header" class="clearfix">
        <div class="title-tag-text">
          <span>公司历程</span>
        </div>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </div>
      <div class="timeline-container">
        <timeline :timelineData="sourceData.data"></timeline>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getEnterpriseDevelopmentHistory,getExportEnterpriseDevelopmentHistory } from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import timeline from "./timeline.vue";
export default {
  name: "History",
  components: {
    timeline
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isShowHistory: false,
      isLoading: false,
      sourceData: {},
      params: {
        enterpriseId: this.$route.params.id
      }
    };
  },
  created() {
    this.getEnterpriseDevelopmentHistory(this.params, "isShowHistory");
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业信息", "3", "", "", "", "","公司历程", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              getExportEnterpriseDevelopmentHistory(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "公司历程.xlsx");
              });
            }
          }
        } else {
          getExportEnterpriseDevelopmentHistory(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "公司历程.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    getEnterpriseDevelopmentHistory(params, isShowHistory) {
      getEnterpriseDevelopmentHistory(params).then(res => {
        if (res.ok) {
          if (isShowHistory) {
            this.isShowHistory = res.data.data.length ? true : false;
          }
          this.sourceData = res.data;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.detail-history {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  &::after,&::before{
    display: none;
  }
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}

.timeline-container {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  background-color: #f9f9f9;
}
</style>
