<template>
  <div class="notLogin">
    <div class="notLogin-show clearfix">
      <div class="notLogin-show-l">
        <img src="@/assets/img/not-img.png" alt="" />
      </div>
      <div class="notLogin-show-r">
        <h1 class="title">订阅「{{ title }}」栏目即可查看全部内容</h1>
        <p class="text text-overflow" :title="text">
          {{ text }}
        </p>
        <ul class="notLogin-list">
          <li v-for="(item,index) in showTips" :key="index">
            <h1>{{item.title}}</h1>
            <p>{{item.text}}</p>
          </li>
        </ul>
        <div>
          <!-- <el-button type="primary" plain>了解详情</el-button> -->
          <el-button type="info" plain>
             <router-link to="/us?id=usContact" target="_blank" style="color:#828282">联系开通</router-link>
            </el-button>
        </div>
      </div>
    </div>
    <div class="notLogin-table" v-if="$slots.table && $slots.table!=''">
      <div class="g-content-table">
        <div class="g-content-top">
          <h1>{{tableTitle}}</h1>
          <el-button type="primary" plain @click="showLogin()"> 查看全部 </el-button>
        </div>
        <div class="g-content-bottom">
            <slot name="table">{{ table }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "notLogin",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    showTips:[],
    tableTitle: {
      type: String,
      default: "",
    },
   table: {
      type: String,
      default: "",
    },
  },
  methods:{
    //显示登录弹窗
    showLogin() {
       this.$Vlogin({flag:true})
    }
  }
};
</script>
<style lang="scss" scoped>
$notLoginshowH: 301px;
.notLogin-show {
  height: $notLoginshowH;
  background: #fff;
  margin-bottom: 11px;
  .notLogin-show-l {
    width: 34%;
    line-height: $notLoginshowH;
    text-align: center;
    float: left;
    img {
      width: 256px;
      height: 230px;
      vertical-align: bottom;
    }
  }
  .notLogin-show-r {
    width: 66%;
    float: right;
    h1.title {
      font-size: 18px;
      line-height: 27px;
      margin-top: 24px;
      margin-bottom: 6px;
    }
    p.text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 14px;
      color: #828282;
      padding-right: 20px;
    }
    .notLogin-list {
      display: flex;
      margin-left: -10px;
      margin-right: 62px;
      margin-bottom: 63px;
      li {
        flex: 1;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        margin-left: 10px;
        padding: 10px;
      }
    }
  }
}
</style>