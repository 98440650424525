<template>
  <PageContainer>
    <not-logged-in-mask v-if="showNotLoggedInMask" />
    <unsubscribed-mask v-if="showUnsubscribedMask" />
    <div :class="['ChargingInfrastructure', showMask ? 'show-mask' : '']">
      <!-- <div class="title">原材料价格</div> -->
      <el-row :gutter="16">
        <el-col :span="12">
          <card
            title="主要橡胶价格走势"
            btn-id="priceEchart"
            exportData
            exportPng
            exportId="rubberData"
            @exportXls="exportXls"
            @exportSvg="exportSvg"
          >
            <template slot="body">
              <salesVolumeEchart ref="priceEchart" :height="452" unit="元/吨" />
            </template>
          </card>
        </el-col>
        <el-col :span="12">
          <card
            title="磷酸铁锂基准价走势"
            btn-id="lfpPriceEchart"
            exportData
            exportPng
            exportId="lfpData"
            @exportXls="exportXls"
            @exportSvg="exportSvg"
          >
            <template slot="body">
              <salesVolumeEchart
                ref="lfpPriceEchart"
                :height="452"
                unit="万元/吨"
              />
            </template>
          </card>
        </el-col>
        <el-col :span="12">
          <card
            style="margin-top: 16px"
            title="电解钴Co:≥99.8%价格走势"
            btn-id="ecPriceEchart"
            exportData
            exportPng
            exportId="ecData"
            @exportXls="exportXls"
            @exportSvg="exportSvg"
          >
            <template slot="body">
              <salesVolumeEchart
                ref="ecPriceEchart"
                :height="452"
                unit="万元/吨"
              />
            </template>
          </card>
        </el-col>
        <el-col :span="12">
          <card
            style="margin-top: 16px"
            title="硫酸钴Co:≥20.5%价格"
            btn-id="csPriceEchart"
            exportData
            exportPng
            exportId="csData"
            @exportXls="exportXls"
            @exportSvg="exportSvg"
          >
            <template slot="body">
              <salesVolumeEchart
                ref="csPriceEchart"
                :height="452"
                unit="万元/吨"
              />
            </template>
          </card>
        </el-col>
      </el-row>
    </div>
  </PageContainer>
</template>

<script>
import { authMixin } from "@/mixin/newData/index.js";
import { NotLoggedInMask, UnsubscribedMask } from "@/components/newData/mask";
import card from "./common/card.vue"; // 卡片组件
import salesVolumeEchart from "./common/salesVolumeEchart.vue"; // 折线图组件组件
import {
  getMaterialPrice, // 产品价格-原材料价格
  exportMaterialPrice, //原材料价格公共导出接口
} from "@/api/data_new.js";

export default {
  name: "MaterialPrice",
  components: {
    NotLoggedInMask,
    UnsubscribedMask,
    card,
    salesVolumeEchart,
  },
  mixins: [authMixin],
  data() {
    return {};
  },
  computed: {},
  created() {
    this.initData();
  },
  methods: {
    /**
     * @description: 初始化数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:14:45
     * @LastEditTime: Do not edit
     */

    initData() {
      // 获取产品价格-原材料价格数据
      this.getMaterialPrice();
    },
    /**
     * @description: 获取产品价格-原材料价格数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 11:39:50
     * @LastEditTime: Do not edit
     */

    getMaterialPrice() {
      getMaterialPrice().then((res) => {
        if (res.ok) {
          console.log(res);
          const data = res.data;
          this.initPriceEchart(data); // 初始化主要橡胶价格走势
          this.initlfpPriceEchart(data); // 初始化磷酸铁锂价格走势
          this.initecPriceEchart(data); // 电解钴Co:≥99.8%价格走势
          this.initcsPriceEchart(data); // 硫酸钴Co:≥20.5%价格走势
        }
      });
    },
    /**
     * @description: 初始化主要橡胶价格走势
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 11:40:42
     * @LastEditTime: Do not edit
     */

    initPriceEchart(data) {
      // 封装折线图数据
      const xAxisData = data.rubberDateList;
      const priceEchartData = {
        xAxisData: xAxisData.reverse(), // x轴数据
        seriesData: [
          {
            name: "天然橡胶",
            type: "line",
            color: "#165DFF",
            showSymbol: false, // 去掉点
            barWidth: 30,
            areaStyleColor: ["rgba(22, 93, 255,0.2)", "rgba(22, 93, 255,0)"], // 阴影
            data: data.nrPriceList.reverse(),
          },
          {
            name: "顺丁橡胶",
            type: "line",
            color: "#14C9C9",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            areaStyleColor: ["rgba(20, 201, 201,0.2)", "rgba(20, 201, 201,0)"], // 阴影
            data: data.brPriceList.reverse(),
          },
          {
            name: "丁苯橡胶",
            type: "line",
            color: "#F7BA1E",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            areaStyleColor: ["rgba(247, 186, 30,0.2)", "rgba(247, 186, 30,0)"], // 阴影
            data: data.sbrPriceList.reverse(),
          },
        ], // 展示数据
      };
      this.$refs.priceEchart.init(priceEchartData);
      console.log(data, "原材料价格-主要橡胶价格走势-已对接");
    },
    /**
     * @description: 初始化磷酸铁锂价格走势
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 13:54:47
     * @LastEditTime: Do not edit
     */
    initlfpPriceEchart(data) {
      // 封装折线图数据
      const xAxisData = data.lfpDateList;
      const lfpPriceEchartData = {
        xAxisData: xAxisData.reverse(), // x轴数据
        seriesData: [
          {
            name: "价格",
            type: "line",
            color: "#14C9C9",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            areaStyleColor: ["rgba(20, 201, 201,0.2)", "rgba(20, 201, 201,0)"], // 阴影
            data: data.lfpPriceList.reverse(),
          },
        ], // 展示数据
      };
      this.$refs.lfpPriceEchart.init(lfpPriceEchartData);
      console.log(data, "原材料价格-磷酸铁锂价格走势-已对接");
    },
    /**
     * @description: 电解钴Co:≥99.8%价格走势
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 13:54:47
     * @LastEditTime: Do not edit
     */
    initecPriceEchart(data) {
      // 封装折线图数据
      const xAxisData = data.ecDateList;
      const ecPriceEchartData = {
        xAxisData: xAxisData.reverse(), // x轴数据
        seriesData: [
          {
            name: "价格",
            type: "line",
            color: "#F7BA1E",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            areaStyleColor: ["rgba(247, 186, 30,0.2)", "rgba(247, 186, 30,0)"], // 阴影
            data: data.ecPriceList.reverse(),
          },
        ], // 展示数据
      };
      this.$refs.ecPriceEchart.init(ecPriceEchartData);
      console.log(data, "原材料价格-电解钴Co:≥99.8%价格走势-已对接");
    },
    /**
     * @description: 硫酸钴Co:≥20.5%价格走势
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 13:54:47
     * @LastEditTime: Do not edit
     */
    initcsPriceEchart(data) {
      // 封装折线图数据
      const xAxisData = data.csDateList;
      const csPriceEchartData = {
        xAxisData: xAxisData.reverse(), // x轴数据
        seriesData: [
          {
            name: "价格",
            type: "line",
            color: "#722ED1",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            areaStyleColor: ["rgba(114, 46, 209,0.2)", "rgba(114, 46, 209,0)"], // 阴影
            data: data.csPriceList.reverse(),
          },
        ], // 展示数据
      };
      this.$refs.csPriceEchart.init(csPriceEchartData);
      console.log(data, "原材料价格-硫酸钴Co:≥20.5%价格走势-已对接");
    },
    /**
     * @description: 下载图片按钮
     * @param {*} type
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:27:06
     * @LastEditTime: Do not edit
     */

    exportSvg({ type, name }) {
      this.$refs[type].exportSvg(name);
      console.log(type);
    },
    /**
     * @description: 导出数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:32:45
     * @LastEditTime: Do not edit
     */

    exportXls({ exportId, name }) {
      exportMaterialPrice({
        classify: exportId,
      }).then((res) => {
        this.blobstamp(res, name);
      });
    },
    /**
     * @description: 文件导出转化 blob数据流=> 文件
     * @param {*} blob
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:37:13
     * @LastEditTime: Do not edit
     */

    blobstamp(blob, name) {
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      let temp = name + ".xls";
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE
        let IEblob = new Blob([blob.data], {
          type: "application/vnd.ms-excel",
        });
        window.navigator.msSaveOrOpenBlob(IEblob, decodeURIComponent(temp));
        return;
      } else {
        a.href = url;
        a.setAttribute("download", decodeURIComponent(temp));
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";
.ChargingInfrastructure {
  padding: 24px;
  background: #ffffff;

  .title {
    margin-bottom: 20px;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #0d57bc;
      border-radius: 3px;
      margin-right: 8px;
    }
  }
}
</style>
