
//二手车
import $http from '@/plugins/http.js'


//获取所有关键字
export function getKeyWords() {
    return $http.get('/usedCar/getKeyWords')
}
//根据id查询
export function queryById(params) {
    return $http.get('/usedCar/queryById', params)
}
//根据条件查询
export function queryByPage(params) {
    return $http.get('/usedCar/queryByPage',params)
}

//二手车列表
export function insightReport(params) {
    return $http.get('/usedCar/queryByPage',params)
}


//获取图表
export function getEchars(params) {
    return $http.get('/usedCar/getEchars',params)
}

//获取年月
export function getYearMonth(params) {
    return $http.get('/usedCar/getYearMonth',params)
}

//获取自定义标签
export function getDefinedTags(params) {
    return $http.get('/usedCar/getDefinedTags',params)
}

//新增保存模板
export function saveSubmit(params) {
    return $http.get('/usedCar/saveSubmit',params)
}

//删除模板
export function removeTab(params) {
    return $http.get('/usedCar/removeTab',params)
}


//图表查询
export function searchEchart(params) {
    return $http.get('/usedCar/searchEchart',params)
}