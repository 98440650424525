<template>
    <div class="compList">
        <el-table cellspacing="110" :header-cell-style="tableHeaderStyle" :data="tableData" v-loading="loading"
            ref="newTable" style="width: 100%">
            <el-table-column align="center" :header-cell-class-name="'custom-header-cell'"
                v-for="(column, index) in tableHead" :key="index" :prop="column.prop" :label="column.label">
                <template v-if="['企业', '本月环比', '本月同比'].includes(column.label)" v-slot="{ row }">
                    <template v-if="column.label === '企业'">
                        <span :class="[row[column.prop] === '总计' ? 'sum' : '']">
                            {{ row[column.prop] }}
                        </span>
                    </template>
                    <template v-else>
                        <span v-if="row[column.prop] == '-'">--</span>
                        <span v-else :class="` ${row[column.prop] >= 0 ? 'text-up' : 'text-down'}`">{{
                            Math.abs(row[column.prop]) || "--"
                        }}%
                        <span :class="` ${row[column.prop] >= 0 ? 'icon-up' : 'icon-down'}`" />
                        </span>
                        
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    // components: {
    //     VnotLogin,
    // },
    props: {
        tableData: {
            type: Array,
            default: [],
        },
        tableHead: {
            type: Array,
            default: [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        tableHeaderStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return 'background-color: rgb(255, 237, 237); color: #000000;border-top-left-radius:4px;border-bottom-left-radius:4px';
            }
            if (columnIndex === 1) {
                return 'background-color: rgb(255, 237, 237); color: #000000;';
            }
            if (columnIndex === 2) {
                return 'background-color: rgb(255, 237, 237); color: #000000;border-top-right-radius:5.5px;border-bottom-right-radius:5.5px;border-right:3px solid #fff';
            }
            if (columnIndex === 3) {
                return 'background-color:  #e7eefe; color: #000000;border-top-left-radius:4px;border-bottom-left-radius:4px';
            }
            if (columnIndex === 4) {
                return 'background-color:  #e7eefe; color: #000000';
            }
            if (columnIndex === 5) {
                return 'background-color:  #e7eefe; color: #000000;border-top-right-radius:5.5px;border-bottom-right-radius:5.5px;border-right:3px solid #fff';
            }
            if (columnIndex === 6) {
                return 'background-color: #d8f2fa; color: #000000;border-top-left-radius:4px;border-bottom-left-radius:4px';
            }
            if (columnIndex === 7) {
                return 'background-color: #d8f2fa; color: #000000';
            }
            if (columnIndex === 8) {
                return 'background-color: #d8f2fa; color: #000000;border-top-right-radius:4px;border-bottom-right-radius:4px;';
            }

        }
    },
};
</script>

<style scoped lang="scss">
.icon-up {
    display: inline-block;
    height: 12px;
    width: 10px;
    background: url(~@/assets/img/overview/up.png) no-repeat;
    background-size: 100% 100%;
    color: red;
}

.icon-down {
    display: inline-block;
    height: 12px;
    width: 10px;
    background: url(~@/assets/img/overview/down.png) no-repeat;
    background-size: 100% 100%;
}

.text-up {
    margin-left: 4px;
    color: #f53f3f;
}

.text-down {
    margin-left: 4px;
    color: #00b42a;
}
.sum {
    color: #f53f3f;
}
</style>