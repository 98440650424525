<template>
  <div class="card-container">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="btn-wrapper">
        <div class="btn" @click="handleClickDownloadData">
          <img :src="require('@/assets/img/newData/download.png')">
          下载数据
        </div>
        <div class="btn" @click="handleClickExportImage">
          <img :src="require('@/assets/img/newData/export.png')">
          导出图片
        </div>
        <el-radio-group v-model="chartType">
          <el-radio-button v-for="item in chartTypeList" :key="item" :label="item" />
        </el-radio-group>
      </div>
    </div>
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ResizeListener from 'element-resize-detector'

export default {
  name: '',
  props: {
    title: {
      type: String,
      required: true
    },
    reverseChartTypeList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const chartTypeList = ['饼图', '柱状图']
    if (this.reverseChartTypeList) {
      chartTypeList.reverse()
    }
    return {
      chartTypeList,
      chartType: chartTypeList[0],
      chartInstance: null,
      barOption: null,
      pieOption: null,
      chartData: []
    }
  },
  computed: {
    isPieChart() {
      return this.chartType === '饼图'
    },
    color() {
      return ['#2D6DFF', '#2CCECE', '#3CBCF3', '#ADC4D9', '#77D18B', '#86AEEB', '#6043D6', '#9385E9', '#FD902B', '#F8C135']
    }
  },
  watch: {
    chartType() {
      this.showChart()
    }
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chartRef)
    const instance = ResizeListener({
      strategy: 'scroll',
      callOnAdd: false
    })
    instance.listenTo(this.$el, this.handleChartResize)
  },
  methods: {
    setData(data) {
      this.$set(this, 'chartData', data)
      this.showChart()
    },
    showChart() {
      if (!this.chartData) {
        return
      }
      // 如果是饼图，则按饼图
      let option = null
      if (this.isPieChart) {
        option = {
          color: this.color,
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: (param) => {
              return `
                <div style='display: flex; justify-content: space-between;'>
                  <div>
                    ${param.marker}
                    <span>${param.name}</span>
                  </div>
                  <span style='margin-left: 10px; font-weight: bold;'>${param.value}%</span>
                </div>`
            }
          },
          legend: {
            padding: 0,
            top: 20,
            itemGap: 16,
            left: 'center',
            type: 'scroll'
          },
          series: {
            type: 'pie',
            radius: ['45%', '80%'],
            left: 'center',
            top: 50,
            avoidLabelOverlap: true,
            itemStyle: {
              borderWidth: 2,
              borderColor: '#fff'
            },
            labelLine: {
              show: false
            },
            label: {
              show: false,
              formatter: ({ name, value }) => {
                return `{name|${name}}\r\n{value|${value}}{percent|%}`
              },
              width: 140,
              overflow: 'truncate',
              rich: {
                name: {
                  fontSize: 14,
                  color: '#86909C'
                },
                value: {
                  fontWeight: 'bold',
                  padding: [4, 2, 0, 0],
                  fontSize: 20
                },
                percent: {
                  height: 15,
                  verticalAlign: 'bottom'
                }
              }
            },
            data: this.chartData
          }
        }
      } else {
        const yAxis = []
        const seriesData = []
        this.chartData.forEach((item, index) => {
          yAxis.push(item.name)
          seriesData.push({
            value: item.value,
            itemStyle: {
              color: this.color[index]
            }
          })
        })
        option = {
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow'
            },
            formatter: (param) => {
              const dataItem = param[0]
              return `
                <div style='display: flex; justify-content: space-between;'>
                  <div>
                    ${dataItem.marker}
                    <span>${dataItem.name}</span>
                  </div>
                  <span style='margin-left: 10px; font-weight: bold;'>${dataItem.value}%</span>
                </div>`
            }
          },
          legend: {
            show: false
          },
          grid: {
            top: 20,
            left: 0,
            right: 20,
            bottom: 0,
            containLabel: true
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              color: '#86909C'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#E5E6EB'
              }
            }
          },
          yAxis: {
            type: 'category',
            axisLabel: {
              width: 140,
              color: '#86909C',
              overflow: 'truncate'
            },
            data: yAxis.reverse(),
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 2
              }
            }
          },
          series: [
            {
              type: 'bar',
              itemStyle: {
                barBorderRadius: [0, 4, 4, 0]
              },
              barMaxWidth: 19,
              data: seriesData.reverse()
            }
          ]
        }
      }
      this.chartInstance.setOption(option, true)
    },
    handleClickDownloadData() {

    },
    handleClickExportImage() {

    },
    handleChartResize() {
      this.chartInstance?.resize()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-container {
  width: calc(50% - 8px);
  height: 474px;
  padding: 20px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #333333;
    font-weight: bold;
  }

  .btn-wrapper {
    display: flex;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .btn {
      display: flex;
      align-items: center;
      margin-right: 38px;
      cursor: pointer;
      color: #4f4f4f;

      &:first-child {
        margin-right: 28px;
      }
    }
  }
}

.chart {
  width: 100%;
  flex: 1;
  overflow: hidden;
}

::v-deep {
  .el-radio-button__inner {
    padding: 0 12px;
    height: 24px;
    line-height: 22px;
    text-align: center;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #0D57BC;
    border-color: #0D57BC;
    box-shadow: -1px 0 0 0 #0D57BC;

    &:hover {
      color: #fff;
    }
  }

  .el-radio-button__inner:hover {
    color: #0D57BC;
  }
}

@media screen and (max-width : 1500px) {
  .card-container {
    width: 100%;
  }
}

</style>
