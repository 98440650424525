<!--
 * @Author: your name
 * @Date: 2021-07-21 09:38:40
 * @LastEditTime: 2021-07-21 11:41:52
 * @LastEditors: Please set LastEditors
 * @Description: 三轮
 * @FilePath: /information-vue/code/src/views/announcement/announcementSearch/ThirdWheel.vue
-->

<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="公告" text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新公告" :showTips="showTips" />-->
    <template>
      <div>
        <el-form label-width="96px" :model="form" ref="form" class="g-form">
          <UnfurlAndFold title="公告信息">
            <el-row>
              <el-col :span="16">
                <el-form-item label="参数名称：" prop="pc">
                  <el-row type="flex" align="middle">
                    <el-radio-group v-model="form.pc" @change="pcRadio">
                      <el-radio label="">不限</el-radio>
                      <el-radio :label="item" v-for="item in pcData" :key="item"></el-radio>
                    </el-radio-group>
                    <el-input v-model="pcinput" size="small" placeholder="输入批次" style="width: 90px"
                              @input="pcFun"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="撤销情况：" prop="cxbj">
                  <el-radio-group v-model="form.cxbj">
                    <el-radio label="">不限</el-radio>
                    <el-radio :label="item.value" v-for="item in cxlx" :key="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="产品信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="企业名称：" prop="qymc">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.qymc" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产品名称：" prop="cpmc">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.cpmc" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产品号：" prop="cph">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.cph" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="产品型号：" prop="cpxh">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.cpxh" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="发动机信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="型号：" prop="mfdj">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.mfdj" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="生产企业：" prop="mfqy">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.mfqy" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="功率：">
                  <el-row type="flex" align="middle" class="small-input text-center">
                    <el-col :span="4">
                      <el-input v-model="form.mfglStart" size="small"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="4">
                      <el-input v-model="form.mfglEnd" size="small"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kw</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>

          <UnfurlAndFold title="参数信息">
            <el-row class="small-input">
              <el-col :span="8">
                <el-form-item label="外形长：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.changStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.changEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外形宽：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.kuanStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.kuanEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外形高：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.gaoStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.gaoEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="small-input">
              <el-col :span="8">
                <el-form-item label="总质量：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.zzlStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.zzlEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="额定载质量：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.edzlStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.edzlEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="整备质量：">
                  <el-row type="flex" align="middle" style="text-align: center">
                    <el-col :span="6">
                      <el-input v-model="form.zbzlStart" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.zbzlEnd" size="small"
                                oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="其他参数：">
                  <el-select v-model="form.otherParam" placeholder="请选择" size="small">
                    <el-option v-for="item in other" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="参数值：">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.otheParamValue" size="small" placeholder="请输入内容"
                              class="g-form-input"></el-input>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>

          <div class="text-center" style="margin-top: 20px">
            <el-button class="reset-button" @click="reset">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div>
        <div flex="sb">
          <span class="table_title"></span>
          <div>
            <el-button type="primary" plain @click="contrast">一键对比（{{ contrastData.length }}）</el-button>
            <el-button type="primary" v-has-role="'freeusers'" plain @click="excelBtn">导出（{{
                exportData.length
              }}）
            </el-button>
            <el-button type="primary"  plain v-if="isShow" @click="exportValidGGZc">导出有效车型
            </el-button>
          </div>
        </div>
        <el-table :data="tableData" ref="newTable" style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" @select="selectionChange"
                  @selection-change="selectionChange" v-loading="loading" stripe @sort-change="tableSort">
          <el-table-column type="selection" width="55"/>
          <el-table-column sortable="custom" prop="qymc" label="企业名称" min-width="150"/>
          <el-table-column prop="cpmc" label="产品名称"/>
          <el-table-column sortable="custom" prop="cph" label="产品号" min-width="100"/>
          <el-table-column sortable="custom" prop="cpxh" label="产品型号" min-width="100"/>
          <el-table-column sortable="custom" prop="pc" label="批次" width="80"/>
          <el-table-column sortable="custom" prop="sxrq" label="生效日期" min-width="100"/>
          <!-- <el-table-column sortable="custom" prop="tsrq" label="停售日期" width="120px" /> -->
          <el-table-column sortable="custom" prop="bgkz" label="产品状态" min-width="100">
            <template slot-scope="scope">
              {{ ggSate(scope.row.bgkz,scope.row.cxbj) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="200">
            <template slot-scope="scope">
              <div class="table-text-btn">
                <el-popover placement="left" width="160" trigger="hover">
                  <img :src="scope.row.ybzp|| defaultImg" alt="" width="100%"/>
                  <el-button slot="reference" type="text"
                             @click="viewDetail('/announcement/announcementSearch/optional/',scope.row.id,'选装')">选装
                  </el-button>
                </el-popover>
                <span class="line">|</span>
                <el-button type="text"
                           @click="viewDetail('/announcement/announcementSearch/ThirdWheelDetails/',scope.row.id,'查看')">查看
                </el-button>
                <span class="line">|</span>
                <el-button type="text" @click="printFun(scope.row.id)">打印</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="pageData.pages.page" :page-sizes="[10, 20, 30, 40,100]"
                         :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                         :total="pageData.total">
          </el-pagination>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import UnfurlAndFold from "@/components/UnfurlAndFold.vue";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {download} from "@/utils/download.js";
import {
  categoryPage,
  getAllZcPc,
  exportGGZc,
  getZcQueryList,
  exportValidGGZc,
} from "@/api/announcement.js";
import ggcpzt from "@/mixin/ggcpzt.js";
import {buryPoint} from "@/api/common";

export default {
  props: {
    category: "",
  },
  components: {
    // VnotLogin,
    UnfurlAndFold,
  },
  data() {
    return {
      defaultImg: require("@/assets/img/nonecar.jpg"),
      input: "",
      show: false,
      showTips: [
        {
          title: "公告动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "公告库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "公告梳理",
          text: "专家梳理整合，真正读懂公告脉络",
        },
        {
          title: "公告大数据",
          text: "用数据深度理解公告结构和趋势",
        },
      ],
      checkList: [],
      tableData: [],
      form: {
        cph: "",
        //产品号
        cpmc: "",
        //产品名称
        cpxh: "",
        //产品型号
        cxbj: "",
        //撤销情况
        edzlEnd: "",
        //额定载质量结束
        edzlStart: "",
        //额定载质量开始
        changEnd: "",
        //外形长结束
        changStart: "",
        //外形长开始
        gaoEnd: "",
        //外形高结束
        gaoStart: "",
        //外形高结束
        kuanEnd: "",
        //外形宽结束
        kuanStart: "",
        //外形宽结束
        zzlEnd: "",
        //总质量结束
        zzlStart: "",
        //总质量开始
        mfdj: "",
        //发动机型号
        mfglEnd: "",
        //发动机功率结束
        mfglStart: "",
        //发动机功率开始
        mfqy: "",
        //发动机生产企业
        pc: "",
        //批次
        qymc: "",
        //企业名称
        zbzlEnd: "",
        //整备载质量结束
        zbzlStart: "",
        //整备载质量开始
        otherParam: "",
        //其它参数
        otheParamValue: "",
        //参数值
      },
      //批次输入
      pcinput: "",
      cxbjData: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 100,
        },
        total: 0,
      },
      pcData: [],
      contrastData: [],
      exportData: [],
      other: [
        {value: "hxc", label: "货箱栏板内长"},
        {value: "hxg", label: "货箱栏板内高"},
        {value: "hxk", label: "货箱栏板内宽"},
        {value: "rlzl", label: "燃料种类"},
        {value: "ghyjbz", label: "排放依据标准"},
        {value: "zhsh", label: "轴数"},
        {value: "zxxs", label: "转向型式"},
        {value: "zhj", label: "轴距"},
        {value: "zgcs", label: "最高车速"},
        {value: "jjlqj", label: "接近角/离去角"},
        {value: "qxhx", label: "前悬/后悬"},
        {value: "jssh", label: "驾驶室"},
        {value: "qpck", label: "驾驶室准乘人数"},
        {value: "cdxs", label: "传动型式"},
      ],
      cxlx: [
        {value: "y", label: "已撤"},
        {value: "n", label: "未撤"},
      ],
      loading: false,
      isShow: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "公告",
        userId: ""
      },
    };
  },
  created() {

    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().userName == '韩洪臣' || getUserInfo().userName == 'sqsyc2' || getUserInfo().userName == 'sqsyc1' || getUserInfo().userName == 'SQSYC' || getUserInfo().userName == '344655' || getUserInfo().userName == '170502') {

        this.isShow = true;
      }

    } else {
      this.show = false;
    }
    this.init();
    this.getAllZcPc();
    this.getZcQueryList();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    viewDetail(path, id,buttonName) {
      let newUrl = this.$router.resolve({
        path: path + id
      });
      if (getUserInfo()) {

        logRecords('公告','三轮车查询','3','gg_yzk','','','','',buttonName)
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //获取列表
    categoryPage(parmas) {
      this.loading = true;
      categoryPage(parmas).then((res) => {
        this.tableData = res.data.list;
        this.pageData.total = res.data.count;
        this.loading = false;
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.init();
    },
    //获取批次
    getAllZcPc() {
      getAllZcPc().then((res) => {
        this.pcData = res.data;
      });
    },
    //初始化
    init() {
      let data = Object.assign({}, this.form, this.pageData.pages);
      data.cplb = this.category;
      this.categoryPage(data);
    },
    //查询
    searchForm() {
      if (getUserInfo()) {

        logRecords('公告','三轮车查询','3','gg_yzk','','','','','查看')
        if (getUserInfo().roleKey == "paidrole"  || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.pages.page = 1;
            this.init();

          }
        } else {
          this.pageData.pages.page = 1;
          this.init();
        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //重置
    reset() {
      this.$refs.form.resetFields();
      this.pcinput = "";
      this.form.changEnd = "";
      //外形长结束
      this.form.changStart = "";
      //外形长开始
      this.form.gaoEnd = "";
      //外形高结束
      this.form.gaoStart = "";
      //外形高结束
      this.form.kuanEnd = "";
      //外形宽结束
      this.form.kuanStart = "";
      //外形宽结束
      this.form.zzlEnd = "";
      //总质量结束
      this.form.zzlStart = "";
      this.form.mfglEnd = "";
      //发动机功率结束
      this.form.mfglStart = "";
      this.form.zbzlEnd = "";
      //整备载质量结束
      this.form.zbzlStart = "";
      //整备载质量开始
      this.pageData.pages.page = 1;
      this.$refs.newTable.clearSort()
      this.init();
    },
    //批次输入
    pcFun(e) {
      this.form.pc = e;
    },
    //批次选择
    pcRadio() {
      this.pcinput = "";
    },

    excelBtn() {

      if (getUserInfo()) {
        if (this.exportData.length <= 0) {
          this.$message({
            showClose: true,
            message: "请选择导出数据",
            type: "error",
          });
          return false;
        }
        if (this.exportData.length > 100) {
          this.$message({
            showClose: true,
            message: "最多选择100条数据",
            type: "error",
          });
          return false;
        }

        let ids = this.exportData.map((item) => {
          return item.id;
        });
        logRecords('公告','三轮车查询','3','gg_yzk','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              exportGGZc(ids).then((res) => {
                download(res, "三轮车公告数据", "xls");
              });
            }
          }
        } else {
          exportGGZc(ids).then((res) => {
            download(res, "三轮车公告数据", "xls");
          });
        }
      } else {
        this.showLogin();
      }

    },
    //获取字典类型
    getZcQueryList() {
      getZcQueryList().then((res) => {
        let data = res.data;
        this.cxbjData = data.cxbj;
      });
    },
    //选择
    selectionChange(selection) {
      this.contrastData = selection;
      this.exportData = selection;
    },
    //一键比对
    contrast() {
      if (getUserInfo()) {
        if (this.contrastData.length <= 0) {
          this.$message({
            showClose: true,
            message: "请选择对比数据",
            type: "error",
          });
          return false;
        }
        if (this.contrastData.length > 10) {
          this.$message({
            showClose: true,
            message: "最多选择10条数据",
            type: "error",
          });
          return false;
        }
        let ids = this.contrastData.map((item) => {
          return item.id;
        });
        let newUrl = this.$router.resolve({
          path: "/announcement/announcementSearch/ThirdWheelDetails/" +
              ids.join(",")
        });
        logRecords('公告','三轮车查询','3','gg_yzk','','','','','一键对比')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }


    },
    //打印printFun
    printFun(id) {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open("/api/exportZcPdf/ggYzkPdf?cpId=" + id);

          }
        } else {
          window.open("/api/exportZcPdf/ggYzkPdf?cpId=" + id);
        }
      } else {
        this.showLogin();
      }

    },
    //导出有效车型
    exportValidGGZc() {
      logRecords('公告','三轮车查询','3','gg_yzk','','','','','导出全部')

      let data = Object.assign({}, this.form, this.pageData.pages);
      data.cplb = this.category;
      exportValidGGZc(data).then((res) => {
        download(res, "有效车型数据", "xls");
      });


    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }
  }
  ,
  mixins: [ggcpzt],
}
;
</script>
<style lang="scss" scoped>
.table-text-btn {
  .el-button--text {
    padding: 0;
  }

  .line {
    padding: 0 5px;
  }

  .el-button--text,
  .line {
    color: #0d57bc;
  }
}

/deep/ .el-form-item {
  margin-bottom: 0;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
