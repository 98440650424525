<template>
  <router-view v-if="$route.name == '标准动态内容页'"/>
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="标准动态" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>
    <ListFiltering :list="belongAreasData" @handleChange="handleChange" @search="search"/>
    <Vlist :list="standardDynamicData" :pageData="standardDynamicPage" @handleSizeChange="handleSizeChange"
           @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import {standardDynamic} from "@/api/standards.js";
import Vlist from "@/components/list";
import ListFiltering from "@/components/ListFiltering.vue";
import {selectDictDataByType} from "@/api/dict.js";
import {getUserInfo, ydlTk} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
    Vlist,
    ListFiltering,
  },
  data() {
    return {
      bgurl: require("@/assets/img/bg-1.jpg"),
      standardDynamicData: [],
      standardDynamicPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreasData: [],
      belongAreas: [],
      title: "",
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "标准",
        userId: ""
      },
    };
  },
  created() {
    this.standardDynamic();
    this.selectDictDataByType();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    standardDynamic() {
      if (!getUserInfo() && this.standardDynamicPage.pages.page>1) {
        this.showLogin();
      }else {
        let form = Object.assign({}, this.standardDynamicPage.pages, {
          title: this.title,
          belongAreas: this.belongAreas,
        });
        form.belongAreas = form.belongAreas.join(",");
        standardDynamic(form).then((res) => {
          if (res.ok) {
            this.standardDynamicData = res.data.list;
            this.standardDynamicPage.total = res.data.count;
          }
        });
      }
    },
    handleSizeChange(val) {
      this.standardDynamicPage.pages.pageSize = val;
      this.standardDynamic(this.standardDynamicPage.pages);
    },
    handleCurrentChange(val) {
      this.standardDynamicPage.pages.page = val;
      this.standardDynamic(this.standardDynamicPage.pages);
    },
    toHref(item) {
      let newUrl = this.$router.resolve("/standards/dynamic/syc/contentDynamic/" + item.id);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({
        dictType: "mng_bz_standard_tagsThree",
      }).then((res) => {
        if (res.ok) {
          this.belongAreasData = res.data;
        }
      });
    },
    //切换
    handleChange(e) {
      this.belongAreas = e;
      this.standardDynamic();
    },
    search(e) {
      this.standardDynamicPage.pages.page = 1;
      this.title = e;
      this.standardDynamic();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
