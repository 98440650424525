<template>
  <div class="content">
    <table class="table" border="1">
      <tr>
        <td align="right" width="30%">
          <strong>产品型号：</strong>
        </td>
        <td width="70%">
          {{ tableData.vehicleModelNumber || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>批次：</strong>
        </td>
        <td>
          {{ tableData.pc || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>生产企业：</strong>
        </td>
        <td>
          {{ tableData.enterpriseName || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>通用名称：</strong>
        </td>
        <td>
          {{ tableData.commonName || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>细分市场：</strong>
        </td>
        <td>
          {{ tableData.xfsc || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>整车整备质量（kg）：</strong>
        </td>
        <td>
          {{ tableData.curbWeight || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料种类：</strong>
        </td>
        <td>
          {{ tableData.rlzl || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>纯电动续驶里程（km）：</strong>
        </td>
        <td>
          {{ tableData.cddxslc || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料消耗量（L/100km）：</strong>
        </td>
        <td>
          {{ tableData.fuleType || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>发动机排量（mL）：</strong>
        </td>
        <td>
          {{ tableData.engineDisplacement || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>动力蓄电池总质量（kg）：</strong>
        </td>
        <td>
          {{ tableData.batteryWeight || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>动力蓄电池总能量（kWh）：</strong>
        </td>
        <td>
          {{ tableData.dlxdcznl || '-' }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>是否撤销：</strong>
        </td>
        <td>
          {{ tableData.isCancel || '-' }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-color: $allcolor;
  color: #000;
  tr {
    td {
      padding: 5px 10px;
       word-break: break-all;
    }
    &:nth-child(even) {
      background: #bbc8da;
    }
  }
  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
    td,
    th {
        border: 1px solid #ddd;
    }
}
</style>