<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="市场洞察" text="市场洞察栏目围绕市场现状本身从深度和广度全方位展开，分析市场现状、洞察市场走势。" tableTitle="最新市场洞察" :showTips="showTips"></VnotLogin>-->
    <template>
      <el-row style="margin-bottom:16px">
        <el-col :span="24">
          <listHead title="洞察报告" url="assets/img/11153157zuln.png" :isMore="false"/>
          <div style="padding:30px 30px 24px;background:#fff;">
            <el-row :gutter="30" style="margin-bottom:16px">
              <el-col :span="6" v-for="item in insightReportData" :key="item.id">
                <div style="cursor: pointer;color: #0D57BC"
                     @click="toHref('/marketInsight/detail/insightReportDetail/' + item.id,item,'洞察报告','tt_market_insight')" class="img-grop">
                  <img :src="item.imgUrl ? item.imgUrl : defaultImg" :alt="item.title" class="img">
                  <p class="text-overflow-2 img-title"><span style="
                                        display: inline-block;
                                        color: #0D57BC;
                                        font-size: 14px;
                                        line-height: 16px;
                                        padding: 4px;
                                        background: rgba(13, 87, 188, 0.08);
                                        margin-right: 4px;
                                        max-width: 100%;
                                        "
                                                             v-if="item.contentCategory == 'yc'"
                  >原创</span>{{ item.title }}</p>
                  <!-- <span class="img-time">{{ item.updateTime }}</span> -->
                </div>
              </el-col>
            </el-row>
            <div class="text-right">
              <router-link class="local-btn" tag="button" to="/marketInsight/insightReport">查看更多</router-link>
            </div>
          </div>

        </el-col>
      </el-row>
      <el-row style="margin-bottom:16px">
        <el-col :span="24">
          <listHead title="市场动态" url="assets/img/11153157zuln.png" href="/marketInsight/marketDynamic"/>
          <ul class="newUl newUl-cloum">
            <li v-for="item in marketDynamicExplainData" :key="item.id">
              <div style="cursor: pointer;" class="newUl-link"
                   @click="toHref('/marketInsight/detail/marketDynamicDetail'+'?id='+item.id+'&releaseColumn='+''+'&category='+'industry',item,'市场动态','tt_industry_information')">
                <template v-if="item.unscrambleUnit">
                  <div class="newUl-tag">{{ item.unscrambleUnit }}</div>
                </template>
                <div class="newUl-title" v-if="item.shortTitle !=null && item.shortTitle !=''"><span style="
                      display: inline-block;
                      color: #0D57BC;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    "
                >{{ item.releaseColumn }}</span>{{ item.shortTitle }}
                </div>
                <div class="newUl-title" v-else><span style="
                      display: inline-block;
                      color: #0D57BC;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    "
                >{{ item.releaseColumn }}</span>{{ item.title }}
                </div>
                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <!--

      <div class="g-list-box clearfix">

          <div class="g-list">
              <ItemTitle title="市场洞察解读" class="title" toHref="/marketDynamic/dynamic/listExplain" />
              <ItemList v-for="(item, index) in marketDynamicExplainData" :key="item.id" :to="'/marketDynamic/dynamic/contentExplain/' + item.id">
                  <slot slot="important" v-if="index == 0">最新</slot>
                  <slot slot="title">{{ item.title }}</slot>
                  <slot slot="time">{{ item.publishDate }}</slot>
              </ItemList>
          </div>
      </div>

      <div class="g-content-table">
          <div class="g-content-top">
              <h1>最新市场洞察</h1>
              <el-button type="primary" plain @click="$router.push({ path: '/marketDynamic/search/index' })">
                  查看全部
              </el-button>
          </div>
          <div class="g-content-bottom">
              <el-table :data="newmarketDynamicDate" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px" stripe>
                  <el-table-column prop="title" label="市场洞察名称" width="400">
                      <template slot-scope="scope">
                          <router-link :to="'/marketDynamic/dynamic/contentNewmarketDynamic/' + scope.row.id">
                              {{ scope.row.title }}
                          </router-link>
                      </template>
                  </el-table-column>
                  <el-table-column prop="remarks" label="市场洞察摘要" fit />
                  <el-table-column prop="publishDate" label="成文日期" width="130" />
              </el-table>
          </div>
      </div> -->
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 市场洞察动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {insightReport, marketDynamicExplain} from "@/api/insight.js";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {fmtDate} from "@/utils/date.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      showTips: [
        {
          title: "市场动态",
          text: "实时跟踪、全面扫描，最新权威市场动向",
        },
        {
          title: "洞察报告",
          text: "原创分析、深度洞察，AUTOINFO独家视点",
        },
        {
          title: "查看下载",
          text: "在线查看、下载编辑，支持原创报告PPT无限量下载",
        },
        {
          title: "全文检索",
          text: "精准检索、高效查询，支持标题、内容多种检索方式",
        }
      ],
      defaultImg: require("@/assets/img/dnc-img.png"),
      loading: false,
      tableData: [],
      pagination: {},
      show: false,
      insightReportData: [],
      marketDynamicReportData: [],
      marketDynamicExplainData: [],
      newmarketDynamicDate: [],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "市场洞察",
        userId: ""
      },
    };
  },
  computed: {
    newDate() {
      return fmtDate("YY年mm月", new Date());
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
    this.init();

  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //初始化加载
    init() {
      this.insightReport({page: 1, pageSize: 4});
      this.marketDynamicExplain({page: 1, pageSize: 12});
    },
    //洞察报告
    insightReport(params) {
      insightReport(params).then((res) => {
        if (res.ok) {
          this.insightReportData = res.data.list;
          this.insightReportData.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
        }
      });
    },
    //市场洞察解读
    marketDynamicExplain(params) {
      marketDynamicExplain(params).then((res) => {
        if (res.ok) {
          this.marketDynamicExplainData = res.data.list;
          this.marketDynamicExplainData.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
        }
      });
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(url,item,column2,tableName) {
      let newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        logRecords('市场洞察',column2,'1',tableName,item.id,'',item.title,'')
        if (getUserInfo().roleKey == "paidrole"  || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('市场洞察')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //字符串转数组
    strSplit(str) {
      return str.split(",");
    },
  },
};
</script>
<style lang="scss" scoped>
.img-grop {
  display: block;
  width: 100%;

  .img {
    display: block;
    max-width: 100%;
    height: 132px;
    width: 234px;
    margin: 0 auto;

  }

  .img-title,
  .img-time {
    padding-top: 10px;
  }

  .img-title {
    font-size: 15px;
    line-height: 22px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }

  .img-time {
    font-size: 14px;
    line-height: 14px;
    color: #828282;
  }
}

.local-btn {
  width: 128px;
  height: 37px;
  border: 2px solid #0d57bc;
  border-radius: 48px;
  background: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #0d57bc;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
