<!--                零售数据-->
<template>
  <div>
    <el-form :model="form" ref="form" inline label-width="135px" class="n-data-content">
      <h1 class="n-title">显示字段</h1>
      <el-row style="margin:0px 0 10px;">
        <el-col class="n-check-m">
          <el-form-item prop="qymc" style="display:none"></el-form-item>
          <el-form-item prop="cxlx" style="display:none"></el-form-item>
          <el-form-item prop="xflb" style="display:none"></el-form-item>
          <el-form-item prop="rllb" style="display:none"></el-form-item>
          <el-form-item prop="nian" style="display:none"></el-form-item>
          <el-form-item prop="clShow" style="display:none"></el-form-item>
          <el-form-item prop="lsShow" style="display:none"></el-form-item>
          <el-form-item prop="pfShow" style="display:none"></el-form-item>
          <el-checkbox v-model="year" disabled class="isDisabled">年</el-checkbox>
          <el-checkbox v-model="month" @change="changeMonth">月</el-checkbox>
          <el-checkbox v-model="form.qymc" @change="changeFun('qymcValues')">企业名称</el-checkbox>
          <el-checkbox v-model="form.cxlx" @change="changeFun('cxlxValues')">车型名称</el-checkbox>
          <el-checkbox v-model="form.xflb" @change="changeFun('xflbValues')">细分类别</el-checkbox>
          <el-checkbox v-model="form.rllb" @change="changeFun('rllbValues')">燃料类型</el-checkbox>

        </el-col>
      </el-row>
      <div class="n-data-line"></div>
      <h1 class="n-title">查询字段</h1>
      <el-row>
        <el-col :span="8">
          <el-form-item label="时间：">
            <VdateEndStart ref="VdateEndStart" v-model="times" @change="timeChange" :isTimeSwitch="false"
                           :checkDate="checkDate" :close="false" width="180px"/>
            <!--                        <VdateEndStart v-model="times" @change="timeChange" :checkDate="checkDate" :close="false" width="75px" />-->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业名称：" class="no-position" prop="qymcValues">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.qymc">

              <selectCheck v-model="form.qymcValues" :datas="qymcData" :disabled="!form.qymc" :props="props"
                           @change="qymcCheck" @close="closeqy"></selectCheck>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车型名称：" prop="cxlxValues" class="no-position">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cxlx">
              <selectCheck v-model="form.cxlxValues" :datas="cxlxData" :disabled="!form.cxlx"
                           :props="cxprops"></selectCheck>
            </el-tooltip>

          </el-form-item>
        </el-col>
      </el-row>
      <el-row>

        <el-col :span="8">
          <el-form-item label="细分类别：" prop="xflbValues" size="small">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.xflb">

              <el-select class="new-width" v-model="form.xflbValues" placeholder="请选择" size="small"
                         :disabled="!form.xflb" multiple collapse-tags>
                <el-option v-for="item in xflbData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="燃料类型：" prop="rllbValues">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.rllb">

              <el-select class="new-width" v-model="form.rllbValues" placeholder="请选择" size="small"
                         :disabled="!form.rllb" multiple collapse-tags>
                <el-option v-for="item in rllbData" :key="item.rlzl" :label="item.rlzl" :value="item.rlzl">
                </el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="n-data-line"></div>
      <h1 class="n-title">统计结果</h1>
      <el-row>
        <el-col class="n-check-m">
          <el-checkbox v-model="form.clShow">产量</el-checkbox>
          <el-checkbox v-model="form.lsShow">零售销量</el-checkbox>
          <el-checkbox v-model="form.pfShow">批发销量</el-checkbox>
          <el-checkbox v-model="form.tjShow">统计</el-checkbox>
        </el-col>
      </el-row>
      <div class="text-center" style="margin-top: 20px;">
        <el-button @click="resetForm('form')">重置</el-button>
        <el-button type="primary" @click="searchForm">查询</el-button>
      </div>
    </el-form>

    <div class="search-form" style="margin-top:10px">
      <div flex="sb" style="margin-bottom: 16px">
        <span class="table_title"></span>
        <div>
          <!-- <el-button type="primary" plain @click="ExportAll" :loading="excelAllLoading">导出全部Excel</el-button> -->
          <el-button type="primary" plain @click="Export" v-has-role="'freeusers'" :loading="excelLoading">导出
          </el-button>
        </div>
      </div>
      <div v-if="show && !showP">
        <el-table :span-method="arraySpanMethod" v-loading="loading" width="100%" :data="tableData"
                  style="width: 100%; margin-bottom: 20px" stripe
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
          <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop" :key="item.prop">
          </el-table-column>
          <template v-for="item in timeList">
            <el-table-column :label="item" :key="item" align="center">
              <el-table-column label="产量" v-if="clShow" align="center">
                <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-cl'" align="center">
                  <template slot-scope="{ row }">
                    {{ toLocaleString(row[item + '-cl']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比上月增长" :prop="item+'-clhb'" v-if="monthShow && tjShow" align="center">
                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-clhb']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比同期增长" :prop="item+'-cltb'" v-if="tjShow" align="center">

                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-cltb']) }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="零售销量" v-if="lsShow" align="center">
                <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-ls'" align="center">
                  <template slot-scope="{ row }">
                    {{ toLocaleString(row[item + '-ls']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比上月增长" :prop="item+'-lshb'" v-if="monthShow && tjShow" align="center">
                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-lshb']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比同期增长" :prop="item+'-lstb'" v-if="tjShow" align="center">
                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-lstb']) }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="批发销量" v-if="pfShow" align="center">
                <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-pf'" align="center">
                  <template slot-scope="{ row }">
                    {{ toLocaleString(row[item + '-pf']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比上月增长" :prop="item+'-pfhb'" v-if="monthShow && tjShow" align="center">
                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-pfhb']) }}
                  </template>
                </el-table-column>
                <el-table-column label="比同期增长" :prop="item+'-pftb'" v-if="tjShow" align="center">
                  <template slot-scope="{ row }">
                    {{ toFixed(row[item + '-pftb']) }}
                  </template>
                </el-table-column>
              </el-table-column>

            </el-table-column>
          </template>
          <!-- <template v-for="nitem in tableHeaderOther">
              <el-table-column label="产量" v-if="nitem.prop == 'cl'" :key="nitem.prop">
                  <el-table-column :prop="item.cl" :label="item.year1" v-for="(item,index) in tableHeaderTime.cl" :key="'cl-'+index"></el-table-column>
              </el-table-column>
              <el-table-column label="比上月增长（产量）" v-if="nitem.prop == 'clhb'" :key="nitem.prop">
                  <el-table-column :prop="item.clhb" :label="item.year1" v-for="(item,index) in tableHeaderTime.clhb" :key="'clhb-'+index"></el-table-column>
              </el-table-column>
               <el-table-column label="比同期增长（产量）" v-if="nitem.prop == 'cltb'" :key="nitem.prop">
                  <el-table-column :prop="item.cltb" :label="item.year1" v-for="(item,index) in tableHeaderTime.cltb" :key="'cltb-'+index"></el-table-column>
              </el-table-column>

          </template> -->
          <!-- <el-table-column label="产量合计" key="schj" prop="cliangTotal" v-if="showTotal&&clShowFlag"></el-table-column> -->
          <!-- <template v-for="nitem in tableHeaderOther">
              <el-table-column label="零售销量" v-if="nitem.prop == 'ls'" :key="nitem.prop">
                  <el-table-column :prop="item.ls" :label="item.year1" v-for="(item,index) in tableHeaderTime.ls" :key="'ls-'+index"></el-table-column>
              </el-table-column>
               <el-table-column label="比上月增长（零售销量）" v-if="nitem.prop == 'lshb'" :key="nitem.prop">
                  <el-table-column :prop="item.lshb" :label="item.year1" v-for="(item,index) in tableHeaderTime.lshb" :key="'lshb-'+index"></el-table-column>
              </el-table-column>
               <el-table-column label="比同期增长（零售销量）" v-if="nitem.prop == 'lstb'" :key="nitem.prop">
                  <el-table-column :prop="item.lstb" :label="item.year1" v-for="(item,index) in tableHeaderTime.lstb" :key="'lstb-'+index"></el-table-column>
              </el-table-column>
          </template> -->
          <!-- <el-table-column label="零售销量合计" key="schj" prop="lsxliangTotal" v-if="showTotal&&lsShowFlag"></el-table-column> -->
          <!-- <template v-for="nitem in tableHeaderOther">
              <el-table-column label="批发销量" v-if="nitem.prop == 'pf'" :key="nitem.prop">
                  <el-table-column :prop="item.pf" :label="item.year1" v-for="(item,index) in tableHeaderTime.pf" :key="'pf-'+index"></el-table-column>
              </el-table-column>
               <el-table-column label="比上月增长（批发销量）" v-if="nitem.prop == 'pfhb'" :key="nitem.prop">
                  <el-table-column :prop="item.pfhb" :label="item.year1" v-for="(item,index) in tableHeaderTime.pfhb" :key="'pfhb-'+index"></el-table-column>
              </el-table-column>
               <el-table-column label="比同期增长（批发销量）" v-if="nitem.prop == 'pftb'" :key="nitem.prop">
                  <el-table-column :prop="item.pftb" :label="item.year1" v-for="(item,index) in tableHeaderTime.pftb" :key="'pftb-'+index"></el-table-column>
              </el-table-column>
          </template> -->
          <!-- <el-table-column label="批发销量合计" key="schj" prop="pfxliangTotal" v-if="showTotal&&pfShowFlag"></el-table-column> -->
          <!-- <el-table-column label="合计" prop="total"></el-table-column> -->
        </el-table>
      </div>
      <div v-else style="position: relative; ">
        <img style="width:100%;" src="@/assets/img/cycfcx.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height:94px;bottom:450px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:450px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>
      </div>
      <div>单位：辆，%</div>
      <div class="text-right">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  enterPriseList,
  TtPassengerSaaocitionDataPage,
  AlltimeList,
  TtPassengerSaaocitionDataCxingList,
  TtPassengerSaaocitionDataLastTime,
  TtPassengerSaaocitionDataExport,
  TtPassengerSaaocitionDataExportAll,
  geRllxList,
} from "@/api/data.js";
import VdateEndStart from "@/components/dateEndStartTwo";
import {
  downloadFile
} from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";
import {
  getMonthBetween,
  getYearArr
} from "@/utils/date.js";
import {getUserInfo, logRecords, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VdateEndStart,
    selectCheck,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    updataDate: {
      type: String,
    }
  },
  data() {
    return {
      form: {
        nian: false,
        qymc: true,
        cxlx: true,
        xflb: true,
        rllb: true,
        clShow: true,
        lsShow: true,
        pfShow: true,
        tjShow: true,
        qymcValues: [],
        rllbValues: [],
        xflbValues: [],
        timeBegin: "",
        timeEnd: "",
        cxlxValues: [],
      },
      //分页
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      nian: false,
      year: true,
      month: true,
      qymcData: [],
      xflbData: ["轿车", "SUV", "MPV", "微客"],
      rllbData: [],
      times: [],
      tableData: [],
      tableHeader: [],
      tableHeaderTime: [],
      tableHeaderOther: [],
      checkDate: "month",
      cxlxData: [],
      excelLoading: false,
      excelAllLoading: false,
      props: {
        label: "id",
        key: "id",
        name: "qymc",
      },
      cxprops: {
        label: "id",
        key: "id",
        name: "cxing",
      },
      showTotal: false,
      clShowFlag: true,
      lsShowFlag: true,
      pfShowFlag: true,
      tjShowFlag: true,
      timeList: [],
      clShow: true,
      lsShow: true,
      pfShow: true,
      tjShow: true,
      monthShow: true,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  mounted() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }
      }
      this.clShow = this.form.clShow,
          this.lsShow = this.form.lsShow,
          this.pfShow = this.form.pfShow,
          this.tjShow = this.form.tjShow,
          this.monthShow = this.month
    }
    this.init();
    this.TtPassengerSaaocitionDataLastTime();

  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    toLocaleString(val) {
      if (val == null || val == '') {
        return val;
      } else {
        return val.toLocaleString();
      }

    },
    toFixed(val) {

      if (val == null || val == '') {
        return val;
      } else {
        return val.toFixed(2);
      }

    },
    init() {
      geRllxList().then((res) => {
        if (res.ok) {
          this.rllbData = res.data;
        }
      });
    },
    TtPassengerSaaocitionDataLastTime() {
      TtPassengerSaaocitionDataLastTime().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.timeList = [res.data]
          if (this.show) {
            this.TtPassengerSaaocitionDataPage();
          }
          this.enterPriseList();
          this.TtPassengerSaaocitionDataCxingList();
        }
      });
    },
    enterPriseList() {
      let form = {
        isYear: this.form.nian,
        startTime: this.times[0],
        endTime: this.times[1],
      };
      enterPriseList(form).then((res) => {
        if (res.ok) {
          this.qymcData = res.data;
        }
      });
    },
    TtPassengerSaaocitionDataPage() {
      this.loading = true;
      let form = Object.assign({}, this.form, this.pageData);
      form.timeBegin = this.times[0] || "";
      form.timeEnd = this.times[1] || "";
      if (form.timeBegin == form.timeEnd) {
        this.showTotal = false;
      } else {
        this.showTotal = true;
      }
      this.clShowFlag = form.clShow
      this.lsShowFlag = form.lsShow
      this.pfShowFlag = form.pfShow
      this.tjShowFlag = form.tjShow
      delete form.total;
      // this.AlltimeList(
      //     this.getNewDate(this.times[0]),
      //     this.getNewDate(this.times[1]),
      //     form.nian
      // );
      TtPassengerSaaocitionDataPage(form).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.tableHeader = this.tableHeaderForm().tableArr;
          this.tableHeaderOther = this.tableHeaderForm().other;
          this.pageData.count = res.data.count;

          if (this.month) {
            this.timeList = getMonthBetween(form.timeBegin, form.timeEnd)
          } else {
            this.timeList = getYearArr(form.timeBegin, form.timeEnd)
          }
          this.monthShow = this.month
          this.clShow = this.form.clShow
          this.lsShow = this.form.lsShow
          this.pfShow = this.form.pfShow
          this.tjShow = this.form.tjShow
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.month = true
      this.times = [];
      this.checkDate = "month";
      this.TtPassengerSaaocitionDataLastTime();
    },
    //点击月份
    changeMonth(e) {
      this.form.nian = !e
      if (e) {
        this.$refs.VdateEndStart.deteHandleCommand('month', this.updataDate)
      } else {
        this.$refs.VdateEndStart.deteHandleCommand('year')
      }


    },
    searchForm() {
      this.pageData.page = 1;
      if (this.times[0] && !this.times[1]) {
        this.$message.warning("结束时间不能为空");
        return false;
      } else if (!this.times[0] && this.times[1]) {
        this.$message.warning("开始时间不能为空");
        return false;
      }
      if (getUserInfo()) {

        logRecords('数据','乘用车分车型-零售数据','3','tt_passenger_saaocition_data','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.TtPassengerSaaocitionDataPage();
          }
        } else {
          this.TtPassengerSaaocitionDataPage();
        }
      } else {
        this.showLogin();
      }

    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.TtPassengerSaaocitionDataPage();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.TtPassengerSaaocitionDataPage();
    },
    //删除
    selectClose(type) {
      this.form[type].splice(0, 1);
    },
    //鼠标移入添加类名
    addClassFun(e) {
      if (!e.target.classList.contains("disabled")) {
        e.target.parentNode.classList.add("hover-class");
      }
    },
    //鼠标移出添加类名
    removeClassFun(e) {
      e.target.parentNode.classList.remove("hover-class");
    },
    //切换清空值
    changeFun(type) {
      let isArr = Array.isArray(this.form[type]);
      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        this.$set(this.form, type, "");
      }
    },
    //开始结束时间
    timeChange(e) {
      this.times = e.value
      if (e.swatchDateType == "month") {
        this.form.nian = false;
        this.checkDate = "month";
      } else {
        this.form.nian = true;
        this.checkDate = "year";
      }
      this.enterPriseList();
      this.TtPassengerSaaocitionDataCxingList();
    },
    //时间头部
    getNewDate(nowdate) {
      let timeHeader = "";
      let date = new Date(),
          year = date.getFullYear(),
          month =
              date.getMonth() + 1 >= 10 ?
                  date.getMonth() + 1 :
                  "0" + date.getMonth() + 1;
      if (nowdate) {
        if (this.form.nian) {
          timeHeader = nowdate + "-01-01";
        } else {
          timeHeader = nowdate + "-01";
        }
      } else {
        if (this.form.nian) {
          timeHeader = year + "-01-01";
        } else {
          timeHeader = year + "-" + month + "-01";
        }
      }

      return timeHeader;
    },
    //前面头部
    tableHeaderForm() {
      let tableArr = [],
          other = [];
      for (let key in this.form) {
        if (typeof this.form[key] == "boolean") {
          if (this.form[key]) {
            switch (key) {
              case "qymc":
                tableArr.push({
                  label: "企业名称",
                  prop: "qyems",
                });
                break;
              case "xflb":
                tableArr.push({
                  label: "细分类别",
                  prop: "cxflei2",
                });
                break;
              case "cxlx":
                tableArr.push({
                  label: "车型名称",
                  prop: "cxing",
                });
                break;
              case "rllb":
                tableArr.push({
                  label: "燃料类型",
                  prop: "rlzl",
                });
                break;
              case "clShow":
                other.push({
                  label: "产量",
                  prop: "cl",
                });
                other.push({
                  label: "产量同比",
                  prop: "cltb",
                });
                other.push({
                  label: "产量环比",
                  prop: "clhb",
                });
                break;
              case "lsShow":
                other.push({
                  label: "零售销量",
                  prop: "ls",
                });
                other.push({
                  label: "零售销量同比",
                  prop: "lstb",
                });
                other.push({
                  label: "零售销量环比",
                  prop: "lshb",
                });
                break;
              case "pfShow":
                other.push({
                  label: "批发销量",
                  prop: "pf",
                });
                other.push({
                  label: "批发销量同比",
                  prop: "pftb",
                });
                other.push({
                  label: "批发销量环比",
                  prop: "pfhb",
                });
                break;
            }
          }
        }
      }
      return {
        tableArr,
        other
      };
    },
    AlltimeList(begin, end, isNian) {
      AlltimeList({
        begin,
        end,
        isNian
      }).then((res) => {
        if (res.ok) {
          this.tableHeaderTime = this.catgoryGrop(res.data);
        }
      });
    },
    TtPassengerSaaocitionDataCxingList(ids) {
      let form = {
        isYear: this.form.nian,
        startTime: this.times[0],
        endTime: this.times[1],
        ids: ids || [],
      };
      TtPassengerSaaocitionDataCxingList(form).then((res) => {
        if (res.ok) {
          this.cxlxData = res.data;
        }
      });
    },
    checkName(value, type) {
      let id = value[0];
      let str = this[type].filter((item) => item.id == id);
      if (str.length && str.length > 0) {
        return str[0];
      }
    },
    qymcCheck(e) {
      this.TtPassengerSaaocitionDataCxingList(e);
    },
    closeqy() {
      this.TtPassengerSaaocitionDataCxingList(this.form.qymcValues);
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //导出
    Export() {

      if (getUserInfo()) {

        logRecords('数据','乘用车分车型-零售数据','3','tt_passenger_saaocition_data','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            } else {
              this.form.timeBegin = this.times[0];
              this.form.timeEnd = this.times[1];
              let self = this;
              this.excelLoading = true;
              TtPassengerSaaocitionDataExport(this.form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "零售数据.xlsx");
                  }
                });
              });
            }
          }
        } else {
          this.form.timeBegin = this.times[0];
          this.form.timeEnd = this.times[1];
          let self = this;
          this.excelLoading = true;
          TtPassengerSaaocitionDataExport(this.form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "零售数据.xlsx");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }
    },
    //导出
    ExportAll() {
      this.form.timeBegin = this.times[0];
      this.form.timeEnd = this.times[1];
      let self = this;
      this.excelAllLoading = true;
      TtPassengerSaaocitionDataExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "零售数据.xls");
          }
        });
      });
    },
    //分组表头
    catgoryGrop(data) {
      let newData = data;
      let cl = [];
      let ls = [];
      let pf = [];
      let cltb = [];
      let lstb = [];
      let pftb = [];
      let clhb = [];
      let lshb = [];
      let pfhb = [];
      newData.forEach((el) => {
        cl.push({
          cl: el.cl,
          year1: el.year1
        });
        ls.push({
          ls: el.ls,
          year1: el.year1
        });
        pf.push({
          pf: el.pf,
          year1: el.year1
        });
        clhb.push({
          clhb: el.clhb,
          year1: el.year1
        });
        lshb.push({
          lshb: el.lshb,
          year1: el.year1
        });
        pfhb.push({
          pfhb: el.pfhb,
          year1: el.year1
        });
        cltb.push({
          cltb: el.cltb,
          year1: el.year1
        });
        lstb.push({
          lstb: el.lstb,
          year1: el.year1
        });
        pftb.push({
          pftb: el.pftb,
          year1: el.year1
        });
      });
      return {
        cl,
        ls,
        pf,
        clhb,
        lshb,
        pfhb,
        cltb,
        lstb,
        pftb
      };
    },
    returnFixed(num) {
      return num.toFixed(2);
    },
    arraySpanMethod({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      let col = this.tableHeader.length;
      if (this.pageData.page == 1) {
        if (col > 0) {
          if (rowIndex === 0) {
            if (columnIndex === 0) {
              return [1, this.tableHeader.length];
            } else if (columnIndex < col) {
              return [1, 0];
            }
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 50%;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>