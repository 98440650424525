<template>
  <PageContainer>
    <!--            <VnotLogin v-if="!show" title="企业情报" text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新企业情报" :showTips="showTips" />-->
    <template>
      <div class="search-form">
        <el-form class="g-form" :model="form" ref="form">
          <ul class="g-form-item">
            <li>
              <el-form-item prop="enterprise">
                <label class="text-right">生产企业：</label>
                <el-input v-model="form.enterprise" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="province">
                <label class="text-right">省份：</label>
                <el-input v-model="form.province" placeholder="请输入内容" class="g-form-input"></el-input>

              </el-form-item>
            </li>
            <li>
              <el-form-item prop="city">
                <label class="text-right">市：</label>
                <el-input v-model="form.city" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="g-form-item">
            <li>

              <el-form-item prop="type">
                <label class="text-right">类型：</label>
                <el-select v-model="form.type" multiple collapse-tags placeholder="请选择" size="small"
                           class="g-form-input">
                  <el-option v-for="item in qycnlx" :key="item.dictValue" :label="item.dictLabel"
                             :value="item.dictValue">
                  </el-option>
                </el-select>
                <!-- <el-input v-model="strategicPlanningForm.types" placeholder="请输入内容" size="small" ></el-input> -->
              </el-form-item>
            </li>
          </ul>

          <div class="text-center">
            <el-button class="reset-button" @click="resetForm('form')">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="search-form" v-if="show && !showP">


        <el-table :data="enterpriseCapacityData" style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" v-show="!viewSwitchFlag" @sort-change="tableSort" stripe>
          <el-table-column sortable prop="type" label=" 类型" min-width="80"/>
          <el-table-column sortable prop="groups" label="集团" min-width="80"/>
          <el-table-column sortable prop="enterprise" label="生产企业" min-width="120"/>
          <el-table-column sortable prop="factory" label="工厂/基地" min-width="120"/>
          <el-table-column sortable prop="province" label="省份" min-width="80"/>
          <el-table-column sortable prop="city" label="市" min-width="80"/>
          <el-table-column sortable prop="capacity" label="产能(万辆)" min-width="120"/>
          <el-table-column sortable prop="year" label="年" min-width="80"/>
          <el-table-column sortable prop="remark" label="备注" min-width="140"/>
        </el-table>

        <div class="text-right">
          <el-pagination @size-change="enterpriseCapacityInfoChange" @current-change="enterpriseCapacityCurrentChange"
                         :current-page="enterpriseCapacityInfoChange.currentPage" :page-sizes="[10, 20, 30, 40]"
                         :page-size="enterpriseCapacityPage.pages.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="enterpriseCapacityPage.total">
          </el-pagination>
        </div>
      </div>
      <div v-else style="position: relative; ">
        <img style="width:100%;" src="@/assets/img/qycn1.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height: 94px;bottom:350px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:350px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
// import { selectPolicy } from "@/api/policy.js";
import {selectDictDataByType, ttDemension} from "@/api/dict.js";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";

// import { fmtDate } from "@/utils/date.js";
import {
  enterpriseCapacityQueryByPage
} from "@/api/enterpriseAnalysis.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {

      show: false,
      showTips: [
        {
          title: "企业信息",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "企业库查询",
          text: "权威企业库，多条件筛查关联比对",
        },
        {
          title: "企业梳理",
          text: "专家梳理整合，真正读懂企业脉络",
        },
        {
          title: "企业大数据",
          text: "用数据深度理解企业结构和趋势",
        },
      ],
      viewSwitchFlag: false,
      form: {
        enterprise: "",
        city: "",
        province: "",
        type: []
      },
      qycnlx: [],
      enterpriseCapacityData: [],
      enterpriseCapacityPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "企业情报",
        userId: ""
      },
      showP: false,
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('企业情报')) {
          this.showP = true;

        }
      }
      this.enterpriseCapacityQueryByPage(this.enterpriseCapacityPage.pages);

    } else {
      this.show = false;
    }
    this.lxFl();
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    tableSort( column){
      if(this.otherzh(column)) {
        this.enterpriseCapacityPage.pages.orderType = this.otherzh(column)
      } else {
        delete this.enterpriseCapacityPage.pages.orderType
      }
      this.enterpriseCapacityQueryByPage(this.enterpriseCapacityPage.pages)
    },
    otherzh(column){
      if(column.order == 'ascending'){

        return column.prop
      }else if(column.order =='descending'){

        return column.prop+' DESC'
      }else{
        return ''
      }
    },
    enterpriseCapacityQueryByPage(params) {

      enterpriseCapacityQueryByPage(params).then((res) => {
        if (res.ok) {
          this.enterpriseCapacityData = res.data.list;

          this.enterpriseCapacityPage.total = res.data.count;
        }
      });
    },
    //分页
    enterpriseCapacityInfoChange(val) {
      this.enterpriseCapacityPage.pages.pageSize = val;
      this.init();
    },
    enterpriseCapacityCurrentChange(val) {
      this.enterpriseCapacityPage.pages.page = val;
      this.init();
    },
    //初始化
    init() {
      let form = Object.assign(
          {},
          this.form,

          this.enterpriseCapacityPage.pages,
      );
      form.type = form.type.join(",")

      this.enterpriseCapacityQueryByPage(form);
    },
    //数据字典
    lxFl() {
      selectDictDataByType({dictType: "enterprise_capacity"}).then(
          (res) => {
            if (res.ok) {
              this.qycnlx = res.data;
            }
          }
      );
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //表单查询
    searchForm() {

      if (getUserInfo()) {

        logRecords('企业情报','企业产能','4','tt_enterprise_capacity','','','',this.form.enterprise)
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.enterpriseCapacityPage.pages.page = 1;
            this.init();
          }
        } else {
          this.enterpriseCapacityPage.pages.page = 1;
          this.init();
        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.enterprise = "";
      this.province = "";
      this.city = "";
      this.type = "";
      this.init();
      // this.selectPolicy(this.selectPolicyPage.pages);
    },
    // //数组去重
    // unique(arr) {
    //     for (var i = 0; i < arr.length; i++) {
    //         for (var j = i + 1; j < arr.length; j++) {
    //             if (arr[i] == arr[j]) {
    //                 arr.splice(j, 1);
    //                 j--;
    //             }
    //         }
    //     }
    //     return arr;
    // },
    // //获取字典
    // getCategory() {
    //     selectDictDataByType({ dictType: "policy_hot_field" }).then(
    //         (res) => {
    //             if (res.ok) {
    //                 this.belongAreasData = res.data;
    //             }
    //         }
    //     );
    // },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  display: flex;
  align-content: center;
}

.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      width: 85px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      width: 77px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 230px;

      /deep/ .el-input__inner,
      /deep/ .el-input__icon,
      /deep/ &.el-date-editor .el-range-separator,
      /deep/ &.el-range-editor.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.nature-tag {
  overflow: hidden;
  margin-left: -10px;

  li {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    padding: 0 2px;
    border: 1px solid #828282;
    color: #828282;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &.ac1 {
      color: #219653;
      border-color: #219653;
    }

    &.ac2 {
      color: #eb5757;
      border-color: #eb5757;
    }
  }
}

.nature-box {
  position: relative;
  padding-left: 14px;
  height: 22px;
  line-height: 22px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -3px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d9d9d9;
  }

  &.lh {
    &::after {
      background: #27ae60;
    }
  }

  &.lk {
    &::after {
      background: #eb5757;
    }
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
