<template>
  <el-dialog
    :visible="visible"
    width="700px"
    top="100px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div class="header">
      更多汽车数据解决方案
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="tip-container">
      <img :src="require('@/assets/img/newData/info-circle-fill.png')" alt="" />
      请填写您想要的数据信息，我们会尽快与您联系
    </div>
    <el-form ref="formRef" :model="formData" :rules="formDataRules">
      <div class="title block">请选择您感兴趣的服务</div>
      <el-form-item prop="requirementTypeCheckList" style="margin-bottom: 25px">
        <el-checkbox-group
          v-model="formData.requirementTypeCheckList"
          @change="handleRequirementTypeChange"
        >
          <el-checkbox
            v-for="item in requirementTypeList"
            :key="item.dictValue"
            :label="item.dictLabel"
          />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="showRemark"
        prop="remark"
        style="margin-bottom: 25px;"
      >
        <el-input
          v-model="formData.remark"
          type="textarea"
          :rows="3"
          :maxlength="200"
          resize="none"
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="留下您的宝贵意见，我们会努力改进"
        />
      </el-form-item>
      <div class="title block">请填写需求详细描述</div>
      <el-form-item prop="detailedRequirements" style="margin-bottom: 25px;">
        <el-input
          v-model="formData.detailedRequirements"
          type="textarea"
          :rows="5"
          :maxlength="200"
          resize="none"
          placeholder="留下您的宝贵意见，我们会努力改进"
        />
      </el-form-item>
      <div class="user-info-wrapper">
        <el-form-item
          prop="company"
          label="您的公司"
          style="margin-bottom: 25px;"
        >
          <el-input
            v-model="formData.company"
            size="small"
            :maxlength="20"
            placeholder="请输入"
          />
        </el-form-item>
      </div>
      <div class="user-info-wrapper">
        <el-form-item prop="name" label="您的姓名" style="margin-bottom: 25px;">
          <el-input
            v-model="formData.name"
            size="small"
            :maxlength="20"
            placeholder="请输入"
          />
        </el-form-item>
      </div>
      <div class="user-info-wrapper">
        <el-form-item
          prop="phone"
          label="您的电话"
          style="margin-bottom: 25px;"
        >
          <el-input
            v-model="formData.phone"
            size="small"
            :maxlength="11"
            placeholder="请输入"
          />
        </el-form-item>
      </div>
    </el-form>
    <div class="footer">
      <el-button size="small" @click="handleClickClose">取消</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="submitLoading"
        @click="handleClickSubmit"
        >提交</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  selectDictDataByRequirementType,
  insertRequirementRecord,
} from "@/api/data_new.js";
import { getUserInfo } from "@/utils/auth.js";

export default {
  name: "DataCustomizationDialog",
  data() {
    const phoneValidator = (_rule, value, callback) => {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(value)) {
        callback();
        return;
      }
      callback(new Error("请输入正确的电话号码"));
    };
    return {
      visible: false,
      submitLoading: false,
      requirementTypeList: [],
      formData: {
        requirementTypeCheckList: [],
        remark: undefined,
        detailedRequirements: undefined,
        company: undefined,
        name: undefined,
        phone: undefined,
      },
      formDataRules: {
        requirementTypeCheckList: [
          {
            required: true,
            message: "请选择您感兴趣的服务",
            trigger: "change",
          },
        ],
        remark: [{ required: true, message: "请填写需求", trigger: "change" }],
        detailedRequirements: [
          { required: true, message: "请填写详细描述", trigger: "change" },
        ],
        company: [
          { required: true, message: "请输入您的公司", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入您的姓名", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入您的电话", trigger: "change" },
          { validator: phoneValidator, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    showRemark() {
      return this.formData.requirementTypeCheckList[0] === "其他";
    },
  },
  watch: {
    showRemark(val) {
      // 如果不展示
      if (!val) {
        this.$set(this.formData, "remark", undefined);
      }
    },
  },
  created() {
    // 获取数据定制/API接入弹窗的 感兴趣的服务 对应的 checkbox 列表
    selectDictDataByRequirementType().then((res) => {
      if (res) {
        // 需要把返回的结果中的 分地区销量 排除掉不展示
        const newRequirementTypeList = res.data
          .filter((item) => item.dictLabel !== "分地区销量")
          .map(({ dictLabel, dictValue }) => {
            return { dictLabel, dictValue };
          });
        this.$set(this, "requirementTypeList", newRequirementTypeList);
      }
    });
  },
  methods: {
    handleClickSubmit() {
      const userInfo = getUserInfo();
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          const requirementType = this.requirementTypeList.find(
            (item) =>
              item.dictLabel === this.formData.requirementTypeCheckList[0]
          )?.dictValue;
          const {
            remark,
            detailedRequirements,
            company,
            name,
            phone,
          } = this.formData;
          insertRequirementRecord({
            userId: userInfo?.userId,
            requirementType,
            remark: this.showRemark ? remark : undefined,
            detailedRequirements,
            company,
            name,
            phone,
          })
            .then((res) => {
              if (res && res.ok) {
                this.handleClickClose();
                this.$message.success("提交成功");
              } else {
                this.$message.error("提交失败");
              }
            })
            .catch(() => {
              this.$message.error("提交失败");
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    handleClear() {
      this.$set(this, "formData", {
        requirementTypeCheckList: [],
        remark: undefined,
        detailedRequirements: undefined,
        company: undefined,
        name: undefined,
        phone: undefined,
      });
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate();
      });
    },
    handleRequirementTypeChange(val) {
      // 控制只能选中一个
      if (!val[val.length - 1]) {
        this.$set(this.formData, "requirementTypeCheckList", []);
        // console.log(this.formData.requirementTypeCheckList);
      } else {
        this.$set(this.formData, "requirementTypeCheckList", [
          val[val.length - 1],
        ]);
      }
    },
    handleClickClose() {
      this.visible = false;
      this.handleClear();
    },
    open(keyWord) {
      const defaultSelected = this.requirementTypeList.find(
        (item) => item.dictLabel.indexOf(keyWord) > -1
      );
      if (defaultSelected) {
        this.$set(this.formData, "requirementTypeCheckList", [
          defaultSelected.dictLabel,
        ]);
      }
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  color: #1d2129;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;

  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #4e5969;
    cursor: pointer;
  }
}

.tip-container {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: #fef7e4;
  border-radius: 4px;
  padding: 0 16px;
  color: #757575;
  font-size: 13px;
  margin-bottom: 24px;

  img {
    width: 14px;
    height: 14px;
    pointer-events: none;
    margin-right: 5px;
    user-select: none;
  }
}

.title {
  color: #333333;
  display: flex;
  align-items: center;

  &.block {
    margin-bottom: 16px;
  }

  &::after {
    content: "*";
    color: #f53f3f;
    margin-left: 2px;
  }
}

.user-info-wrapper {
  ::v-deep {
    .el-input {
      width: 300px;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

::v-deep {
  .el-dialog {
    border-radius: 4px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 20px 24px 24px;
  }

  .el-form-item {
    display: flex;
    align-items: center;
    width: 100%;

    .el-form-item__label {
      &::before {
        display: none;
      }
      &::after {
        content: "*";
        color: #f56c6c;
        margin-left: 2px;
      }
    }

    .el-form-item__content {
      flex: 1;
    }
  }

  .el-form-item__content {
    line-height: 1;
  }
}
</style>
