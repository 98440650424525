<template>
  <div class="display-mode-container">
    <div
      v-for="item in modeList"
      :key="item.name"
      :class="['item', activeDisplayModeName === item.name ? 'active' : '']"
      @click="handleClickDisplayModeItem(item.name)"
    >
      <img :src="require(`@/assets/img/newData/displayMode/${item.pngName}.png`)" alt="">
      <div class="name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
// 图表类型选择器
export default {
  name: 'DisplayModeSelector',
  props: {
    activeDisplayModeName: {
      type: String,
      required: true
    }
  },
  computed: {
    modeList() {
      return [
        {
          name: '表格',
          pngName: 'table'
        },
        {
          name: '折线图',
          pngName: 'line'
        },
        {
          name: '柱状图',
          pngName: 'bar'
        },
        {
          name: '堆积柱状图',
          pngName: 'stack-bar'
        },
        {
          name: '饼图',
          pngName: 'pie'
        },
        {
          name: '组合图',
          pngName: 'combination'
        },
        // 以下图表为 p1 部分
        {
          name: '指标卡',
          pngName: 'target'
        },
        {
          name: '面积图',
          pngName: 'area'
        }
        // {
        //   name: '交叉表',
        //   pngName: 'cross-table'
        // }
      ]
    }
  },
  methods: {
    // 点击某个图表类型的回调
    handleClickDisplayModeItem(modeName) {
      if (this.activeDisplayModeName !== modeName) {
        this.$emit('change', modeName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.display-mode-container {
  padding: 24px 24px 8px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    width: 103px;
    height: 103px;
    box-sizing: border-box;
    background-color: #F8F8F8;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    color: #333;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition-property: background, color, border-color;
    transition-duration: 0.3s;
    border: 2px solid transparent;

    img {
      width: 40px;
      height: 40px;
      pointer-events: none;
    }

    .name {
      margin-top: 11px;
    }

    &.active, &:hover {
      color: #0D57BC;
      border-color: #0D57BC;
      background-color: #fff;
    }
  }
}
</style>
