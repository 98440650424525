<template>
  <div
    v-if="isShowMainModels"
    class="enterpriseDetail"
    id="mainModels"
    data-name="主要车型"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="主要车型"
      :sourceData="sourceData"
      :propertyName="['marketSegment']"
      :notTooltipList="['vehicleModel']"
      :foldList="['vehicleModel']"
      :hiddenNum="50"
    >
      <template #cardSelect>
        <el-select
          v-model="params.marketSegmentList"
          multiple
          collapse-tags
          filterable
          placeholder="细分类型"
          size="small"
          @change="changeListQuery"
        >
          <el-option
            v-for="item in marketSegmentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-model="params.fuelList"
          multiple
          collapse-tags
          filterable
          placeholder="燃料类型"
          size="small"
          @change="changeListQuery"
        >
          <el-option
            v-for="item in fuelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </template>
      <template #headPaginator>
        <paginator
          ref="paginator"
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import {
  getVehicleModelSelections,
  getVehicleModel,
  postExportVehicleModel
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";

import SearchSelect from "@/components/searchSelect";
export default {
  name: "MainModels",
  components: {
    Paginator,
    TableCard,
    SearchSelect
  },
  data() {
    return {
      exportState: false,
      isShowMainModels: false,
      isLoading: false,
      totalCount: 0,
      sourceData: {},
      params: {
        pageSize: 10,
        pageNum: 1,
        enterpriseId: this.$route.params.id,
        countryList: [],
        fuelList: [],
        marketSegmentList: []
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      countryList: [],
      fuelList: [],
      marketSegmentList: []
    };
  },
  created() {
    const enterpriseSelections = {
      enterpriseId: this.$route.params.id
    };
    this.getVehicleModelSelections(enterpriseSelections);
    this.getVehicleModel(this.params, "isShowMainModels");
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报","企业信息" , "3", "", "", "", "", "主要车型", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postExportVehicleModel(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "主要车型.xlsx");
              });
            }
          }
        } else {
          postExportVehicleModel(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "主要车型.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getVehicleModel(this.params);
    },
    changeListQuery() {
      this.$refs.paginator.resetNum();
      this.params.pageNum = 1;
      this.getVehicleModel(this.params);
    },
    getVehicleModelSelections(params) {
      getVehicleModelSelections(params).then(res => {
        if (res.ok) {
          const { countryList, fuelList, marketSegmentList } = res.data;
          this.countryList = countryList;
          this.fuelList = fuelList;
          this.marketSegmentList = marketSegmentList;
        }
      });
    },
    getVehicleModel(params, isShowMainModels) {
      this.isLoading = true;
      getVehicleModel(params).then(res => {
        if (res.ok) {
          if (isShowMainModels) {
            this.isShowMainModels = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    // 观察路由的变化来更新userId
    "$route.params.id"(newId) {
      this.params.enterpriseId = newId;
    }
  }
};
</script>
