<!--
 * @Author: your name
 * @Date: 2021-07-21 13:09:23
 * @LastEditTime: 2021-07-21 14:49:30
 * @LastEditors: Please set LastEditors
 * @Description: 批发数据
 * @FilePath: /information-vue/code/src/views/data/exportData/index.vue
-->

<template>
  <div>
    <el-form :model="form" ref="form" inline label-width="135px" class="n-data-content">
      <h1 class="n-title">显示字段</h1>
      <el-row style="margin:0px 0 10px;">
        <el-col class="n-check-m">
          <el-form-item prop="qyShow" style="display:none"></el-form-item>
          <el-form-item prop="bj2Show" style="display:none"></el-form-item>
          <el-form-item prop="bj5Show" style="display:none"></el-form-item>
          <el-form-item prop="gbShow" style="display:none"></el-form-item>
          <el-form-item prop="checkYearMonth" style="display:none"></el-form-item>
          <el-form-item prop="cxjbieShow" style="display:none"></el-form-item>
          <el-form-item prop="ppShow" style="display:none"></el-form-item>
          <el-form-item prop="scShow" style="display:none"></el-form-item>
          <el-form-item prop="xsShow" style="display:none"></el-form-item>
          <el-checkbox v-model="year" disabled class="isDisabled">年</el-checkbox>
          <el-checkbox v-model="month" @change="changeMonth">月</el-checkbox>
          <el-checkbox v-model="form.qyShow" @change="changeFun('qyArray')">企业名称</el-checkbox>
          <el-checkbox v-model="form.ppShow" @change="changeFun('ppArray')">车型名称</el-checkbox>
          <el-checkbox v-model="form.bj2Show" @change="changeFun('bj2Array')">细分类别</el-checkbox>
          <el-checkbox v-model="form.bj5Show" @change="changeFun('bj5Array')">燃料类型</el-checkbox>
          <el-checkbox v-model="form.gbShow" @change="changeFun('gbArray')">国别</el-checkbox>
          <el-checkbox v-model="form.cxjbieShow" @change="changeFun('cxjbieArray')">车型级别</el-checkbox>

        </el-col>
      </el-row>
      <div class="n-data-line"></div>
      <h1 class="n-title">查询字段</h1>
      <el-row>
        <el-col :span="8">
          <el-form-item label="时间：">
            <VdateEndStart ref="VdateEndStart" v-model="times" @change="timeChange" :isTimeSwitch="false"
                           :checkDate="checkDate" :close="false" width="180px"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业名称：" class="no-position" prop="qyArray">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.qyShow">

              <selectCheck v-model="form.qyArray" :datas="qyArrayData" :disabled="!form.qyShow" :props="props"
                           @change="qyChange" @close="closeqy"></selectCheck>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车型名称：" class="no-position" prop="ppArray">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.ppShow">

              <selectCheck v-model="form.ppArray" :datas="ppArrayData" :disabled="!form.ppShow"
                           :props="props2"></selectCheck>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="细分类别：" prop="bj2Array">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.bj2Show">

              <el-select class="new-width" v-model="form.bj2Array" placeholder="请选择" size="small"
                         :disabled="!form.bj2Show" multiple collapse-tags>
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="燃料类型：" prop="bj5Array">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.bj5Show">

              <el-select class="new-width" size="small" :disabled="!form.bj5Show" v-model="form.bj5Array"
                         multiple collapse-tags>
                <el-option :value="item.rllx" :label="item.rllx" v-for="item in bj5ArrayData"
                           :key="item.rllx"></el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">

          <el-form-item label="国别：" prop="gbArray">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.gbShow">

              <el-select class="new-width" size="small" :disabled="!form.gbShow" v-model="form.gbArray"
                         multiple collapse-tags>
                <el-option :value="item.chineseGb" :label="item.chineseGb" v-for="item in gbArrayData"
                           :key="item.chineseGb"></el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车型级别：" prop="cxjbieArray">
            <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cxjbieShow">

              <el-select class="new-width" size="small" :disabled="!form.cxjbieShow"
                         v-model="form.cxjbieArray" multiple collapse-tags>
                <el-option :value="item.xfsc" :label="item.xfsc" v-for="item in cxjbieArrayData"
                           :key="item.xfsc"></el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="n-data-line"></div>
      <h1 class="n-title">统计结果</h1>
      <el-row>
        <el-col class="n-check-m">
          <el-checkbox v-model="form.scShow">生产量</el-checkbox>
          <el-checkbox v-model="form.xsShow">销售量</el-checkbox>
          <el-checkbox v-model="form.tjShow">统计</el-checkbox>
        </el-col>
      </el-row>
      <div class="text-center" style="margin-top: 20px;">
        <el-button @click="rest()">重置</el-button>
        <el-button type="primary" @click="searchForm()">查询</el-button>
      </div>
    </el-form>

    <div class="search-form" style="margin-top:10px">
      <div flex="sb" style="margin-bottom: 16px">
        <span class="table_title"></span>
        <div>
          <!-- <el-button type="primary" plain :loading="excelAllLoading" @click="ExportAll()">导出全部Excel</el-button> -->
          <el-button type="primary" plain v-has-role="'freeusers'" :loading="excelLoading" @click="Export()">
            导出
          </el-button>
        </div>
      </div>
      <div v-if="show && !showP">
      <el-table :span-method="arraySpanMethod" v-loading="loading" width="100%" :data="tableData"
                style="width: 100%; margin-bottom: 20px" stripe border
                :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
        <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop" :key="item.prop">
        </el-table-column>
        <template v-for="item in timeList">
          <el-table-column :label="item" :key="item" align="center">
            <el-table-column label="生产" v-if="sclShow" align="center">
              <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-sc'" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-sc']) }}
                </template>
              </el-table-column>
              <el-table-column label="本期止累计" :prop="item+'-bqzlj1'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-bqzlj1']) }}
                </template>
              </el-table-column>
              <el-table-column label="同期止累计" :prop="item+'-tqzlj1'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-tqzlj1']) }}
                </template>
              </el-table-column>
              <el-table-column label="比上月增长" :prop="item+'-schb'" v-if="monthShow && tjlShow" align="center">

                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-schb']) }}
                </template>
              </el-table-column>
              <el-table-column label="比同期增长" :prop="item+'-sctb'" v-if="tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-sctb']) }}
                </template>

              </el-table-column>
              <el-table-column label="比同期累计增长" :prop="item+'-scbtqljzz'" v-if="monthShow && tjlShow" align="center">

                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-scbtqljzz']) }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="销售" v-if="xslShow" align="center">
              <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-xs'" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-xs']) }}
                </template>
              </el-table-column>
              <el-table-column label="本期止累计" :prop="item+'-bqzlj2'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-bqzlj2']) }}
                </template>
              </el-table-column>
              <el-table-column label="同期止累计" :prop="item+'-tqzlj2'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toLocaleString(row[item + '-tqzlj2']) }}
                </template>
              </el-table-column>
              <el-table-column label="比上月增长" :prop="item+'-xshb'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-xshb']) }}
                </template>
              </el-table-column>
              <el-table-column label="比同期增长" :prop="item+'-xstb'" v-if="tjlShow" align="center">

                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-xstb']) }}
                </template>
              </el-table-column>
              <el-table-column label="比同期累计增长" :prop="item+'-xsbtqljzz'" v-if="monthShow && tjlShow" align="center">
                <template slot-scope="{ row }">
                  {{ toFixed(row[item + '-xsbtqljzz']) }}
                </template>

              </el-table-column>
            </el-table-column>

          </el-table-column>
        </template>
        <!-- <template v-for="nitem in tableHeaderOther">
            <el-table-column label="本月完成（生产）" v-if="nitem.prop == 'sc'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.sc" :key="'sc-'+index" :prop="item.sc" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
             <el-table-column label="本期止累计（生产）" v-if="nitem.prop == 'bqzlj1'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.bqzlj1" :key="'bqzlj1-'+index" :prop="item.bqzlj1" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
             <el-table-column label="同期止累计（生产）" v-if="nitem.prop == 'tqzlj1'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.tqzlj1" :key="'tqzlj1-'+index" :prop="item.tqzlj1" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
             <el-table-column label="比上月增长（生产）" v-if="nitem.prop == 'schb'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.schb" :key="'schb-'+index" :prop="item.schb" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
             <el-table-column label="比同期增长（生产）" v-if="nitem.prop == 'sctb'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.sctb" :key="'sctb-'+index" :prop="item.sctb" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
        </template> -->
        <!-- <el-table-column label="生产量合计" key="schj" prop="scTotal" v-if="showTotal&&scShowFlag"></el-table-column> -->
        <!-- <template v-for="nitem in tableHeaderOther">
            <el-table-column label="本月完成（销售）" v-if="nitem.prop == 'xs'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.xs" :key="'xs-'+index" :prop="item.xs" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
            <el-table-column label="本期止累计（销售）" v-if="nitem.prop == 'bqzlj2'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.bqzlj2" :key="'bqzlj2-'+index" :prop="item.bqzlj2" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
            <el-table-column label="同期止累计（销售）" v-if="nitem.prop == 'tqzlj2'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.tqzlj2" :key="'tqzlj2-'+index" :prop="item.tqzlj2" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
            <el-table-column label="比上月增长（销售）" v-if="nitem.prop == 'xshb'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.xshb" :key="'xshb-'+index" :prop="item.xshb" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
             <el-table-column label="比同期增长（销售）" v-if="nitem.prop == 'xstb'" :key="nitem.prop">
                <el-table-column v-for="(item,index) in tableHeaderTime.xstb" :key="'xstb-'+index" :prop="item.xstb" :label="item.dateStr">
                </el-table-column>
            </el-table-column>
        </template> -->
        <!-- <el-table-column label="销售量合计" key="xshj" prop="xsTotal" v-if="showTotal&&xsShowFlag"></el-table-column> -->
        <!--
        <el-table-column prop="total" label="合计">
        </el-table-column> -->
      </el-table>
    </div>
      <div v-else style="position: relative; " >
        <img style="width:100%;" src="@/assets/img/cycfcx.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height:94px;bottom:450px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:450px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>
      </div>
      <div>单位：辆，%</div>
      <div class="text-right">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="乘用车分车型批发数据电子版下载" :visible.sync="dialogVisible" width="800px">
      <div style="height:338px;overflow-x:auto">
        <el-table :data="uploadList" class="rest_table">
          <el-table-column property="attachName" label="名称"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button size="small" icon="el-icon-download"
                         @click="handleDown(scope.$index, scope.row)">下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false" type="primary" plain style="width:204px">关 闭</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
/**
 * @description: 批发数据
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {
  ykbQcPpGetYearMonthList,
  ykbQcPpSelectTable,
  ykbQcPpEnterpriseList,
  ykbQcPpGetDefaultDate,
  getCxjbieList,
  ykbQcPpGetPPList,
  ykbQcPpExport,
  ykbQcPpExportAll,
  ttDataUpload,
  getGbList,
  geRllxListPf, TtPassengerSaaocitionDataExport,
} from "@/api/data.js";
import VdateEndStart from "@/components/dateEndStartTwo";
import {
  downloadFile
} from "@/utils/download.js";
import {
  buryPoint,
  download,
  newdownload
} from "@/api/common.js";
import selectCheck from "@/components/selectCheck";
import {
  getMonthBetween,
  getYearArr
} from "@/utils/date.js";
import {getUserInfo, logRecords, openSy} from "@/utils/auth";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    updataDate: {
      type: String,
    }
  },
  components: {
    // VnotLogin,
    VdateEndStart,
    selectCheck,
  },
  data() {
    return {
      showTips: [{
        title: "数据动态",
        text: "最新权威动向和独家原创解读",
      },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      form: {
        qyShow: true,
        ppShow: true,
        scShow: true,
        xsShow: true,
        tjShow: true,
        ppArray: [],
        bj5Show: false,
        bj2Show: false,
        gbShow: false,
        checkYearMonth: false,
        bj2Array: [],
        bj5Array: [],
        gbArray: [],
        qyArray: [],
        startTime: "",
        endTime: "",
        cxjbieShow: false,
        cxjbieArray: [],
      },
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      checkYearMonth: false,
      year: true,
      month: true,
      times: [],
      qyArrayData: [],
      ppArrayData: [],
      bj5ArrayData: [
        // { value: "0", label: "不细分" },
        // { value: "1", label: "柴油汽车" },
        // { value: "2", label: "汽油汽车" },
        // { value: "3", label: "其他燃料汽车" },
        // { value: "4", label: "普通混合动力" },
        // { value: "5", label: "插电式混合动力" },
        // { value: "6", label: "纯电动" },
        // { value: "7", label: "燃料电池" },
        // { value: "8", label: "天然气" },
        // { value: "9", label: "其他替代燃料" },
        // { value: "10", label: "双燃料" },
      ],
      loading: false,
      tableHeader: [],
      tableHeaderTime: [],
      tableHeaderOther: [],
      checkDate: "month",
      props: {
        multiple: true
      },
      cxjbieArrayData: [],
      options: [{
        value: 5,
        label: "轿车",
      },
        {
          value: 3,
          label: "MPV",
        },
        {
          value: 4,
          label: "SUV",
        },
        {
          value: 6,
          label: "交叉型乘用车",
        },
      ],
      excelAllLoading: false,
      excelLoading: false,
      uploadList: [],
      dialogVisible: false,
      props: {
        label: "id",
        key: "id",
        name: "short_name",
      },
      props2: {
        label: "model_id",
        key: "model_id",
        name: "ppName",
      },
      gbArrayData: [],
      showTotal: false,
      scShowFlag: true,
      xsShowFlag: true,
      tjShowFlag: true,
      timeList: [],
      sclShow: true,
      xslShow: true,
      tjlShow: true,
      monthShow: true,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }
      }
      this.ttDataUpload();
      this.sclShow = this.form.scShow
      this.xslShow = this.form.xsShow
      this.tjlShow = this.form.tjShow

      this.monthShow = this.month
    }
    this.init();
    this.ykbQcPpGetDefaultDate();
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    toLocaleString(val) {
      if (val == null || val == '') {
        return val;
      } else {
        return val.toLocaleString();
      }

    },
    toFixed(val) {

      if (val == null || val == '') {
        return val;
      } else {
        return val.toFixed(2);
      }

    },
    //初始化
    init() {
      getCxjbieList().then((res) => {
        if (res.ok) {
          this.cxjbieArrayData = res.data;
        }
      });
      getGbList().then((res) => {
        if (res.ok) {
          this.gbArrayData = res.data;
        }
      });
      geRllxListPf().then((res) => {
        if (res.ok) {
          let data = res.data;
          var arr2 = data.filter((x, index, self) => {
            var arrids = [];
            data.forEach((item, i) => {
              arrids.push(item.rllx);
            });
            return arrids.indexOf(x.rllx) === index;
          });
          this.bj5ArrayData = arr2;
        }
      });
    },
    ykbQcPpGetPPList(qyString) {
      let form = {
        qyString: qyString ? qyString.join(",") : "",
        isYear: this.form.checkYearMonth,
        startTime: this.times[0],
        endTime: this.times[1],
      };
      ykbQcPpGetPPList(form).then((res) => {
        if (res.ok) {
          this.ppArrayData = res.data;
        }
      });
    },
    ykbQcPpGetDefaultDate() {
      ykbQcPpGetDefaultDate().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.timeList = [res.data]
          // console.log(this.show)
          if(this.show){
            this.ykbQcPpSelectTable();

          }
          this.ykbQcPpEnterpriseList();
          this.ykbQcPpGetPPList();
        }
      });
    },
    ykbQcPpEnterpriseList() {
      let form = {
        isYear: this.form.checkYearMonth,
        startTime: this.times[0],
        endTime: this.times[1],
      };
      ykbQcPpEnterpriseList(form).then((res) => {
        if (res.ok) {
          this.qyArrayData = res.data;
        }
      });
    },
    ykbQcPpSelectTable() {
      this.loading = true;
      let form = Object.assign({}, this.form, this.pageData);
      form.startTime = this.times[0];
      form.endTime = this.times[1];
      if (form.startTime == form.endTime) {
        this.showTotal = false;
      } else {
        this.showTotal = true;
      }
      this.scShowFlag = form.scShow;
      this.xsShowFlag = form.xsShow;
      this.tjShowFlag = form.tjShow;
      delete form.total;
      // this.ykbQcPpGetYearMonthList(
      //     this.getNewDate(this.times[0]),
      //     this.getNewDate(this.times[1]),
      //     form.checkYearMonth
      // );
      ykbQcPpSelectTable(form).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.pageData.count = res.data.count;
          this.tableHeader = this.tableHeaderForm().tableArr;
          this.tableHeaderOther = this.tableHeaderForm().other;

          if (this.month) {
            this.timeList = getMonthBetween(form.startTime, form.endTime)
          } else {
            this.timeList = getYearArr(form.startTime, form.endTime)
          }
          this.monthShow = this.month
          this.sclShow = this.form.scShow
          this.xslShow = this.form.xsShow
          this.tjlShow = this.form.tjShow
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    //点击月份
    changeMonth(e) {
      this.form.checkYearMonth = !e
      if (e) {
        this.$refs.VdateEndStart.deteHandleCommand('month', this.updataDate)
      } else {
        this.$refs.VdateEndStart.deteHandleCommand('year')
      }


    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.ykbQcPpSelectTable();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.ykbQcPpSelectTable();
    },
    //重置
    rest() {
      this.$refs["form"].resetFields();
      this.times = [];
      this.month = true
      this.checkDate = "month";
      this.ykbQcPpGetDefaultDate();
    },
    searchForm() {
      this.pageData.page = 1;
      if (this.times[0] && !this.times[1]) {
        this.$message.warning("结束时间不能为空");
        return false;
      } else if (!this.times[0] && this.times[1]) {
        this.$message.warning("开始时间不能为空");
        return false;
      }
      if (getUserInfo()) {

        logRecords('数据','乘用车分车型-批发数据','3','ykb_qc_pp','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.ykbQcPpSelectTable();
          }
        } else {
          this.ykbQcPpSelectTable();
        }
      } else {
        this.showLogin();
      }
    },
    //开始结束时间
    timeChange(e) {
      this.times = e.value
      if (e.swatchDateType == "month") {
        this.form.checkYearMonth = false;
        this.checkDate = "month";
      } else {
        this.form.checkYearMonth = true;
        this.checkDate = "year";
      }
      this.ykbQcPpEnterpriseList();
      this.ykbQcPpGetPPList(this.form.qyArray);
    },
    changeFun(type) {
      let isArr = Array.isArray(this.form[type]);
      if (type == "bj2Array") {
        if (!this.form.bj2Show) {
          this.form.bj5Show = false;
          this.form.gbShow = false;
        }
      }

      if (type == "bj5Array") {
        if (!this.form.bj2Show) {
          this.form.gbShow = false;
        }
      }

      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        this.$set(this.form, type, "");
      }
    },
    //时间头部
    getNewDate(nowdate) {
      let timeHeader = "";
      let date = new Date(),
          year = date.getFullYear(),
          month =
              date.getMonth() + 1 >= 10 ?
                  date.getMonth() + 1 :
                  "0" + date.getMonth() + 1;
      if (nowdate) {
        if (this.form.checkYearMonth) {
          timeHeader = nowdate + "-01-01";
        } else {
          timeHeader = nowdate + "-01";
        }
      } else {
        if (this.form.checkYearMonth) {
          timeHeader = year + "-01-01";
        } else {
          timeHeader = year + "-" + month + "-01";
        }
      }

      return timeHeader;
    },
    //前面头部
    tableHeaderForm() {
      let tableArr = [],
          other = [];
      for (let key in this.form) {
        if (typeof this.form[key] == "boolean") {
          if (this.form[key]) {
            switch (key) {
              case "qyShow":
                tableArr.push({
                  label: "企业名称",
                  prop: "qy",
                });
                break;
              case "ppShow":
                tableArr.push({
                  label: "车型名称",
                  prop: "pp",
                });
                break;
              case "bj2Show":
                tableArr.push({
                  label: "细分类别",
                  prop: "bj2",
                });
                break;
              case "bj5Show":
                tableArr.push({
                  label: "燃料类型",
                  prop: "bj5",
                });
                break;
              case "gbShow":
                tableArr.push({
                  label: "国别",
                  prop: "gb",
                });
                break;
              case "cxjbieShow":
                tableArr.push({
                  label: "车型级别",
                  prop: "cxjbie",
                });
                break;
              case "scShow":
                other.push({
                  label: "生产",
                  prop: "sc",
                });
                other.push({
                  label: "同期止累计（生产）",
                  prop: "tqzlj1",
                });
                other.push({
                  label: "本期止累计（生产）",
                  prop: "bqzlj1",
                });
                other.push({
                  label: "比上月增长（生产）",
                  prop: "schb",
                });
                other.push({
                  label: "比同期增长（生产）",
                  prop: "sctb",
                });
                break;
              case "xsShow":
                other.push({
                  label: "销售",
                  prop: "xs",
                });
                other.push({
                  label: "本期止累计(销售)",
                  prop: "bqzlj2",
                });
                other.push({
                  label: "同期止累计(销售)",
                  prop: "tqzlj2",
                });
                other.push({
                  label: "比上月增长（销售）",
                  prop: "xshb",
                });
                other.push({
                  label: "比同期增长（销售）",
                  prop: "xstb",
                });
                break;
            }
          }
        }
      }
      return {
        tableArr,
        other
      };
    },
    ykbQcPpGetYearMonthList(startTime, endTime, isYear) {
      ykbQcPpGetYearMonthList({
        startTime,
        endTime,
        isYear
      }).then(
          (res) => {
            if (res.ok) {
              this.tableHeaderTime = this.catgoryGrop(res.data);
            }
          }
      );
    },
    checkName(value) {
      let id = value[0];
      let str = this.qyArrayData.filter((item) => item.id == id);
      if (str.length && str.length > 0) {
        return str[0].enterprise_name;
      }
    },
    //切换企业
    qyChange(e) {
      this.ykbQcPpGetPPList(e);
    },
    closeqy() {
      this.ykbQcPpGetPPList(this.form.qyArray);
    },
    //分组表头
    catgoryGrop(data) {
      let newData = data;
      let sc = [];
      let xs = [];
      let bqzlj1 = [];
      let tqzlj1 = [];
      let bqzlj2 = [];
      let tqzlj2 = [];
      let sctb = [];
      let xstb = [];
      let schb = [];
      let xshb = [];
      newData.forEach((el) => {
        sc.push({
          sc: el.sc,
          dateStr: el.dateStr
        });
        xs.push({
          xs: el.xs,
          dateStr: el.dateStr
        });
        bqzlj1.push({
          bqzlj1: el.bqzlj1,
          dateStr: el.dateStr
        });
        tqzlj1.push({
          tqzlj1: el.tqzlj1,
          dateStr: el.dateStr
        });
        bqzlj2.push({
          bqzlj2: el.bqzlj2,
          dateStr: el.dateStr
        });
        tqzlj2.push({
          tqzlj2: el.tqzlj2,
          dateStr: el.dateStr
        });
        sctb.push({
          sctb: el.sctb,
          dateStr: el.dateStr
        });
        xstb.push({
          xstb: el.xstb,
          dateStr: el.dateStr
        });
        schb.push({
          schb: el.schb,
          dateStr: el.dateStr
        });
        xshb.push({
          xshb: el.xshb,
          dateStr: el.dateStr
        });
      });
      return {
        sc,
        xs,
        bqzlj1,
        tqzlj1,
        bqzlj2,
        tqzlj2,
        sctb,
        xstb,
        schb,
        xshb
      };
    },

    //导出
    Export() {
      if (getUserInfo()) {

        logRecords('数据','乘用车分车型-批发数据','3','ykb_qc_pp','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              this.form.startTime = this.times[0];
              this.form.endTime = this.times[1];
              let _self = this;
              this.excelLoading = true;
              ykbQcPpExport(this.form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "乘用车分车型数据.xlsx");
                  }
                });
              });
            }
          }
        } else {
          this.form.startTime = this.times[0];
          this.form.endTime = this.times[1];
          let _self = this;
          this.excelLoading = true;
          ykbQcPpExport(this.form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "乘用车分车型数据.xlsx");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }

    },
    //导出
    ExportAll() {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
      let _self = this;
      this.excelAllLoading = true;
      ykbQcPpExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "乘用车分车型全部数据.xls");
          }
        });
      });
    },
    //附件列表
    ttDataUpload() {
      ttDataUpload({
        modelName: "data_upload_3"
      }).then((res) => {
        if (res.ok) {
          this.uploadList = res.data;
        }
      });
    },
    //下载附件
    handleDown(index, row) {
      this.download(row);
    },
    download(item) {
      let self = this;
      newdownload({
        fileUrl: item.attachUrl,
        articleId: item.id
      }).then(
          (res) => {
            var reader = new FileReader();
            reader.readAsText(res.data);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                let contentDisposition =
                    res.headers["content-disposition"];
                let patt = new RegExp(
                    "filename=([^;]+\\.[^\\.;]+);*"
                );
                let result = patt.exec(contentDisposition);
                let filename = decodeURI(result[1]);
                downloadFile(res.data, filename);
              }
            });
          }
      );
    },
    onchange(e) {
      this.form.qyArray = e;
    },
    arraySpanMethod({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      let col = this.tableHeader.length;
      if (this.pageData.page == 1) {
        if (col > 0) {
          if (rowIndex === 0) {
            if (columnIndex === 0) {
              return [1, this.tableHeader.length];
            } else if (columnIndex < col) {
              return [1, 0];
            }
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}
.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
