<template>
  <div>
    <ul class="list-box">
      <li v-for="item in newList" :key="getNodeKey(item)">
        <router-link  tag="a" :to="'/index/detail/meetingDetail/'+item.id" class="list-inner">
          <div class="list-top">
            <h1 class="text-overflow">
              {{ item.title }}
            </h1>
            <span>{{ item.conferenceOrganizers }}</span>
          </div>
          <div class="list-bottom">
            <p>
              {{ item.summary }}
            </p>
            <span>{{ item.date }}</span>
          </div>
        </router-link>
      </li>
    </ul>
    <div class="pages">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pageData: {
      default() {
        return {
          pages: {
            page: 1,
            pageSize: 10,
          },
          total: 0,
          currentPage: 0,
        };
      },
    },
    to: {
      type: String,
      default: "",
    },
    props: {
      default() {
        return {
          title: "title",
          date: "publishDate",
          summary: "summary",
          conferenceOrganizers: "conferenceOrganizers",
        };
      },
    },
    nodeKey: String,
  },
  computed: {
    newList() {
      if (this.list.length > 0) {
        for (let key in this.props) {
          this.list.map((item) => {
            return (item[key] = item[this.props[key]]);
          });
        }
        return this.list;
      } else {
        return [];
      }
    },
  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    //绑定key
    getNodeKey(item) {
      if (!this.nodeKey) return item.id;
      return item[this.nodeKey];
    },
  },
};
</script>
<style lang="scss" scoped>
.list-box {
  margin-top: 16px;
  li {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    .list-inner {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      padding: 24px;
      .list-top {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
      h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #141414;
        padding-right: 137px;
        flex: 1;
      }
      .list-bottom {
        position: relative;
        display: flex;
        justify-content: space-between;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        flex: 1;
        padding-right: 137px;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        margin-top: auto;
      }
    }
  }
}
</style>