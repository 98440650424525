<template>
  <PageContainer>

    <!--    <div class="not-login-box" v-if="!showAll">-->
    <!--      <VnotLogin title="二手车" text="二手车报告栏目围绕二手车报告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新二手车报告"-->
    <!--                 :showTips="showTips"/>-->
    <!--    </div>-->

    <div v-loading="loading" class="box_box">
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item
            label="图表类型"
            :rules="[
        { required: true, message: '请选择图表类型', trigger: 'blur' },
      ]"
        >
          <el-radio
              v-model="form.charsType"
              label="柱状图"
              border
              size="small"
              @change="radioChange"
          >柱状图
          </el-radio
          >
          <el-radio
              v-model="form.charsType"
              label="折线图"
              border
              size="small"
              @change="radioChange"
          >折线图
          </el-radio
          >
          <el-radio
              v-model="form.charsType"
              label="堆叠条形图"
              border
              size="small"
              @change="radioChange"
          >堆叠条形图
          </el-radio
          >
          <el-radio
              v-model="form.charsType"
              label="饼图"
              border
              size="small"
              @change="radioChange"
          >饼图
          </el-radio
          >
          <el-radio
              v-model="form.charsType"
              label="组合图"
              border
              size="small"
              @change="radioChange"
          >组合图
          </el-radio
          >
        </el-form-item>
        <el-form-item
            :label="label1"
            :rules="[
        { required: true, message: '请选择维度字段1', trigger: 'blur' },
      ]"
        >
          <el-radio
              v-model="form.projectType1"
              label="tradeYearMonth"
              border
              size="small"
              @change="radioChange1"
          >年月
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="province.keyword"
              border
              size="small"
              @change="radioChange1"
          >省
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="city.keyword"
              border
              size="small"
              @change="radioChange1"
          >市
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="jointVentureAutonomy.keyword"
              border
              size="small"
              @change="radioChange1"
          >合资自主
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="manufacturer.keyword"
              border
              size="small"
              @change="radioChange1"
          >企业
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="brand.keyword"
              border
              size="small"
              @change="radioChange1"
          >品牌
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="models.keyword"
              border
              size="small"
              @change="radioChange1"
          >车型
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="vehicleTypeClassification.keyword"
              border
              size="small"
              @change="radioChange1"
          >车型分类
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="vehicleModelSegmentation.keyword"
              border
              size="small"
              @change="radioChange1"
          >车型细分
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="domesticImports.keyword"
              border
              size="small"
              @change="radioChange1"
          >国产进口
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="emissionStandardsInterval.keyword"
              border
              size="small"
              @change="radioChange1"
          >排放标准
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="transactionPriceInterval.keyword"
              border
              size="small"
              @change="radioChange1"
          >交易价格区间
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="carAgeInterval.keyword"
              border
              size="small"
              @change="radioChange1"
          >车龄区间
          </el-radio
          >
          <el-radio
              v-model="form.projectType1"
              label="provinceInterval.keyword"
              border
              size="small"
              @change="radioChange1"
          >区域
          </el-radio
          >
        </el-form-item>
        <el-form-item
            label="维度字段2"
            v-show="ddtxtShow"
            :rules="[
        { required: true, message: '请选择维度字段2', trigger: 'blur' },
      ]"
        >


          <el-radio
              v-model="form.projectType2"
              label="jointVentureAutonomy.keyword"
              border
              size="small"
              @change="radioChange2"
          >合资自主
          </el-radio
          >

          <el-radio
              v-model="form.projectType2"
              label="vehicleTypeClassification.keyword"
              border
              size="small"
              @change="radioChange2"
          >车型分类
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="vehicleModelSegmentation.keyword"
              border
              size="small"
              @change="radioChange2"
          >车型细分
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="domesticImports.keyword"
              border
              size="small"
              @change="radioChange2"
          >国产进口
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="emissionStandardsInterval.keyword"
              border
              size="small"
              @change="radioChange2"
          >排放标准
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="transactionPriceInterval.keyword"
              border
              size="small"
              @change="radioChange2"
          >交易价格区间
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="carAgeInterval.keyword"
              border
              size="small"
              @change="radioChange2"
          >车龄区间
          </el-radio
          >
          <el-radio
              v-model="form.projectType2"
              label="provinceInterval.keyword"
              border
              size="small"
              @change="radioChange2"
          >区域
          </el-radio
          >
        </el-form-item>

        <el-form-item
            label="指标字段"
            :rules="[
        { required: true, message: '请选择指标字段', trigger: 'blur' },
      ]"
        >
          <el-radio v-model="form.zhiBiao" label="销量" border size="small"
          >销量
          </el-radio
          >
        </el-form-item>
        <!-- <el-form-item
          label="计算类型" v-show="jslxShow"
          :rules="[
            { required: true, message: '请选择指标字段', trigger: 'blur' },
          ]"
        >
          <el-checkbox label="原始值" border v-model="form.jsLxYsz" size="small"
            >原始值</el-checkbox
          >
          <el-checkbox label="占比" border v-model="form.jsLxZb" size="small"
            >占比</el-checkbox
          >
          <el-checkbox label="同比" border v-model="form.jsLxTb" size="small"
            >同比</el-checkbox
          >
          <el-checkbox label="环比" border v-model="form.jsLxHb" size="small"
            >环比</el-checkbox
          >
        </el-form-item> -->
        <el-form-item
            label="显示类型"
            v-show="ddtxtShow"
            :rules="[
        { required: true, message: '请选择显示类型', trigger: 'blur' },
      ]"
        >
          <el-radio v-model="form.xsLx" label="数值" border size="small"
          >数值
          </el-radio
          >
          <el-radio v-model="form.xsLx" label="百分比" border size="small"
          >百分比
          </el-radio
          >
        </el-form-item>
        <el-form-item label="图表标题" prop="txtTitle">
          <el-input
              v-model="form.txtTitle"
              size="small"
              placeholder="请输入图表标题" style="width:300px"
          ></el-input>
        </el-form-item>
        <!-- <UnfurlAndFoldTB title="更多查询条件">
          <el-row>
            <el-col :span="8">
              <el-form-item label="图表标题" prop="txtTitle">
                <el-row type="flex" align="middle">
                  <el-input
                    v-model="form.txtTitle"
                    size="small"
                    placeholder="请输入图表标题" style="width:300px"
                  ></el-input>
                </el-row>
              </el-form-item>
            </el-col> -->
        <!-- <el-col :span="8">
          <el-form-item label="图表副标题" prop="txtSubTitle">
            <el-row type="flex" align="middle">
              <el-input
                v-model="form.txtSubTitle"
                size="small"
                placeholder="请输入图表副标题" style="width:300px"
              ></el-input>
            </el-row>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="8">
          <el-form-item label="X轴标题" prop="txtXAxisTitle">
            <el-row type="flex" align="middle">
              <el-input
                v-model="form.txtXAxisTitle"
                size="small"
                placeholder="请输入X轴标题" style="width:300px"
              ></el-input>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Y轴标题" prop="txtYAxisTitle">
            <el-row type="flex" align="middle">
              <el-input
                v-model="form.txtYAxisTitle"
                size="small"
                placeholder="请输入Y轴标题" style="width:300px"
              ></el-input>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row> -->
        <!-- <el-row>

        </el-row> -->
        <!-- </UnfurlAndFoldTB> -->
        <el-form-item class="text-center" style="margin-top: 20px">
          <el-button type="primary" @click="searchEchart">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-divider id="el-divider"></el-divider>

      <template>
        <div style="margin-left: 23px; font-weight: bold; margin-top: 5px" v-if="selectShow">
          筛选时间：
          <el-select v-model="form.yearMonth" size="small" @change="selectChange" style="width: 90px">
            <el-option
                v-for="item in yearMonthData"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div v-if="show && !showP" id="echartsIds" style="width: 100%; height: 580px; margin-top: 10px"></div>
        <div v-else>
          <img style="width:100%" src="@/assets/img/zdyfx.jpg" alt=""/>
          <center style="height: 1px;">
            <div
                style="position:relative;width:300px;height: 94px;bottom:260px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

              <div style="padding-top: 20px">
                <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
              </div>

              <div style="margin: 10px">
                没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

              </div>
            </div>
            <div
                style="position:relative;width:320px;height:94px;bottom:260px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                v-else>
              <div style="padding-top: 20px;padding-left: 22px;">
                <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
              </div>
              <div style="padding: 10px 20px;text-align: center">
                <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
              </div>
            </div>
          </center>

        </div>
        <div style="margin: 60px" v-has-role="'freeusers'">
          <!-- // 导出按钮  -->
          <span>{{ this.danWei }}</span>
          <el-button
              type="primary"
              style="float: right"
              icon="el-icon-upload"
              @click="exportEvent"
          >导出
          </el-button
          >

          <div v-if="show && !showP">
            <el-table
                :data="modelList"
                :border="true"
                size="small"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                id="echartsTable"
            >
              <el-table-column
                  :label="item"
                  v-for="(item, index) in mokuaiTableTitle"
                  :key="item"
                  align="center"
                  style="user-select: initial"
              >
                <template slot-scope="scope">
                <span v-for="(item2, index2) in scope.row" :key="index2">
                  <span v-if="index2 == index">
                    {{ scope.row[index2] }}
                  </span>
                </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <img style="width:100%" src="@/assets/img/zdyfxbg.jpg" alt=""/>

          </div>
        </div>
      </template>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 二手车图表
 * @param {*}
 * @return {*}
 */
// 引入导出插件
import FileSaver from "file-saver";
import XLSX from "xlsx";
import * as echarts from "echarts";
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
// import UnfurlAndFoldTB from "@/components/UnfurlAndFoldTB.vue";
import {getYearMonth, searchEchart} from "@/api/usedCar.js";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    // UnfurlAndFoldTB,
  },
  data() {
    return {
      showTips: [
        {
          title: "二手车动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "二手车库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "二手车梳理",
          text: "专家梳理整合，真正读懂专题分析脉络",
        },
        {
          title: "二手车大数据",
          text: "用数据深度理解专题分析结构和趋势",
        },
      ],
      loading: false,
      yearMonthData: [],
      yearMonth: "",
      ddtxtShow: false,
      jslxShow: true,
      mokuaiTableTitle: [],
      modelList: [],
      fileName: "",
      label1: "维度字段",
      danWei: "单位：万辆，%",
      form: {
        charsType: "组合图",
        projectType1: "tradeYearMonth",
        projectType2: "jointVentureAutonomy.keyword",
        zhiBiao: "销量",
        jsLxYsz: true,
        jsLxZb: true,
        jsLxTb: true,
        jsLxHb: true,
        xsLx: "百分比",
        txtTitle: "",
        txtSubTitle: "",
        txtXAxisTitle: "",
        txtYAxisTitle: "",
        yearMonth: "",

      },
      showAll: true,
      show: false,
      selectShow: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "二手车",
        userId: ""
      },
      showP: false,
    };
  },

  created() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('二手车')) {
          this.showP = true;
          this.getYearMonth();
        } else {
          this.init(this.valueId);
        }
      } else {
        this.init(this.valueId);
      }
      this.show = true;
      this.showAll = true;
    } else {
      this.showAll = false;
      this.getYearMonth();
    }
  },
  mounted() {
    // 页面加载设置高度自适应
    // this.resizeDom();
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    async init() {
      this.loading = true;
      //获取年月
      await getYearMonth().then((result) => {
        if (result.ok) {

          this.yearMonthData = result.data;
          this.form.yearMonth = this.yearMonthData[0];
        }
      });
      //查询

      let form = this.form;

      await searchEchart(form).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsIds"));

        if (result.ok) {
          let data = result.data;
          this.fileName = data.title;
          let templateFile = data.templateFile;
          eval(templateFile);

          data.xAxisData.unshift(" ");
          this.mokuaiTableTitle = data.xAxisData;
          this.modelList = [];
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.modelList.push(data.seriesData[x]);
          }
          this.loading = false;
        }
      });

    },

    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //导出
    exportEvent() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {
        logRecords('二手车','自定义分析查询','3','used_car_data','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('二手车')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let grid = XLSX.utils.table_to_book(
                  document.querySelector("#echartsTable"),
                  {raw: true}
              );
              let workbook = XLSX.write(grid, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(
                    new Blob([workbook], {
                      type: "application/octet-stream",
                    }),
                    this.fileName + ".xlsx"
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, workbook);
              }
              return workbook;
            }

          }

        } else {
          let grid = XLSX.utils.table_to_book(
              document.querySelector("#echartsTable"),
              {raw: true}
          );
          let workbook = XLSX.write(grid, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                new Blob([workbook], {
                  type: "application/octet-stream",
                }),
                this.fileName + ".xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, workbook);
          }
          return workbook;

        }
      } else {
        this.showLogin();
      }
    },
    //图表类型变化
    radioChange(label) {
      if (label == "堆叠条形图") {
        this.ddtxtShow = true;
        this.label1 = "维度字段1";
        if (this.form.xsLx = "百分比") {
          this.danWei = "单位：%";

        } else if (this.form.xsLx = "数值") {
          this.danWei = "单位：万辆";

        }
      } else {
        this.ddtxtShow = false;
        this.label1 = "维度字段";

      }
      if (label == "组合图") {
        this.jslxShow = true;
        this.danWei = "单位：万辆，%";
      } else {
        this.jslxShow = false;
      }
      if (label == "饼图") {
        this.danWei = "单位：%";
      }
    },
    //维度字段1变化
    radioChange1(label) {
      if (label == "tradeYearMonth") {
        this.selectShow = false;
      } else {
        this.selectShow = true;
      }
    },
    //维度字段2变化
    radioChange2() {
    },
    //查询
    searchEchart() {
      this.formLog.eventType = "2";
      if (this.form.charsType == "堆叠条形图") {

        if (this.form.xsLx == "百分比") {
          this.danWei = "单位：%";
        } else if (this.form.xsLx == "数值") {
          this.danWei = "单位：万辆";

        }
      } else if (this.form.charsType == "组合图") {
        this.danWei = "单位：万辆，%";
      } else if (this.form.charsType == "饼图") {
        this.danWei = "单位：%";
      } else {
        this.danWei = "单位：万辆";
      }
      if (getUserInfo()) {

        logRecords('二手车','自定义分析查询','3','used_car_data','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;

          if (!menuName.includes('二手车')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
              this.search();
              this.insertBuryPoint();

          }
        } else {
          this.search();
          this.insertBuryPoint();
        }
      } else {
        this.showLogin();
      }
    },


    //查询
    search() {
      searchEchart(this.form).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsIds"));

        if (result.ok) {
          let data = result.data;
          this.fileName = data.title;
          let templateFile = data.templateFile;
          eval(templateFile);

          data.xAxisData.unshift(" ");
          this.mokuaiTableTitle = data.xAxisData;
          this.modelList = [];
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.modelList.push(data.seriesData[x]);
          }
        }
      });
    },

    //重置
    reset() {
      this.charsType = "组合图";
      this.projectType1 = "province.keyword";
      this.projectType2 = "jointVentureAutonomy.keyword";
      this.zhiBiao = "销量";
      this.jsLxYsz = true;
      this.jsLxZb = true;
      this.jsLxTb = true;
      this.jsLxHb = true;
      this.xsLx = "数值";
      this.txtTitle = "";
      this.txtSubTitle = "";
      this.txtXAxisTitle = "";
      this.txtYAxisTitle = "";
      this.searchEchart();
    },


    selectChange(yearMonth) {
      this.formLog.eventType = "2";
      if (getUserInfo()) {

        logRecords('二手车','自定义分析查询','3','used_car_data','','','','','时间筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;

          if (!menuName.includes('二手车')) {
            this.sqsyLogin();
            this.insertBuryPoint();
            this.getYearMonth();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
              this.getYearMonth();

            }else{
              this.yearMonth = yearMonth;
              this.getEchars(this.valueId);
            }

          }
        } else {
          this.form.yearMonth = yearMonth;
          this.searchEchart();
        }
      } else {
        this.showLogin();
      }
    },


    //获取年月
    getYearMonth() {
      getYearMonth().then((result) => {
        if (result.ok) {
          this.yearMonthData = result.data;
          this.form.yearMonth = this.yearMonthData[0];
        }
      });
    },
    //获取图表
    getEchars(id) {
      this.loading = true;
      getEchars({id: id, nianYue: this.yearMonth}).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsIds"));

        if (result.ok) {
          let data = result.data;
          let templateFile = data.templateFile;
          eval(templateFile);
          this.loading = false;
        }
      });
    },
    // //获取自定义标签
    // getDefinedTags() {
    //   getDefinedTags().then((result) => {
    //     if (result.ok) {
    //       let data = result.data;
    //       this.dynamicTags = data;
    //       this.valueId = data[0].id;
    //     }
    //   });
    // },
    //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度以达到自适应目的
    resizeDom() {
      var backPersonDom = document.getElementById("echartsIds");
      const boxWidth = document.getElementById("echartsIds").clientWidth;
      backPersonDom.style.height = boxWidth / 3 + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-radio__input {
  display: none;
}

.box_box {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: #ffffff;

}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-radio {

  margin-right: 0;
}

#el-divider {
  height: 14px;
  background-color: #f6f6f6;
}

#divider {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
