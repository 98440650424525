<template>
    <div class="fixed-wrapper" :class="{ 'affix': isAffixed }">
        <slot></slot>
    </div>
</template>
  
<script>
export default {
    props: {
        bottomOffset: {
            type: Number,
            default: 0, // 设置默认值
        },
    },
    data() {
        return {
            isAffixed: false,
            initialBottomOffset: 0,
            flag:false
        };
    },
    mounted() {
        this.initialBottomOffset = this.$el.parentNode.offsetHeight - this.$el.offsetTop;
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = this.getDocumentHeight();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const bottomPosition = documentHeight - (scrollTop + windowHeight);
            // this.isAffixed = bottomPosition > (this.initialBottomOffset + this.bottomOffset);
            if (this.flag && bottomPosition >  this.bottomOffset-70) {
                return
            }
            this.flag = bottomPosition >  this.bottomOffset;
            this.isAffixed = bottomPosition >  this.bottomOffset

        },
        getDocumentHeight() {
            const body = document.body;
            const html = document.documentElement;
            return Math.max(
                body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight
            );
        },
    },
};
</script>
  
<style scoped>
.affix {
    position: fixed;
    bottom: 0;
    width: 100%;
}
</style>