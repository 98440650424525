<template>
  <div
    v-if="isShowProductionbase"
    class="enterpriseDetail productionbase-detail"
    id="Productionbase"
    data-name="生产基地"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="生产基地"
      :sourceData="sourceData"
      :column-width="columnWidth"
      :notTooltipList="['briefIntro']"
      :foldList="['briefIntro']"
      :hiddenNum="47"
    >
      <template #cardSelect>
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="">
            <span>全部</span>
            <i>({{ sourceData.totalNum }})</i>
          </el-menu-item>
          <el-menu-item index="newEnergy">
            <span>新能源</span>
            <i>({{ sourceData.newEnergyNum || 0 }})</i>
          </el-menu-item>
          <el-menu-item index="fuel">
            <span>燃油</span>
            <i>({{ sourceData.fuelNum || 0 }})</i>
          </el-menu-item>
        </el-menu>
      </template>
      <template #headPaginator>
        <paginator
        ref="paginator"
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
    </TableCard>
  </div>
</template>

<script>
import { getProductionBase } from "@/api/enterpriseAnalysis.js";
import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";
export default {
  name: "Productionbase",
  components: {
    Paginator,
    TableCard
  },
  data() {
    return {
      isShowProductionbase: false,
      isLoading: false,
      totalCount: 0,
      sourceData: {},
      params: {
        pageSize: 10,
        pageNum: 1,
        baseType: "",
        enterpriseId: this.$route.params.id
      }
    };
  },
  created() {
    this.getProductionBase(this.params, "isShowProductionbase");
  },

  methods: {
    columnWidth(prop) {
      let returnWidth = null;
      switch (prop) {
        case "briefIntro":
          returnWidth = "30%";
          break;
        case "maxCapacity":
          returnWidth = "10%";
          break;
        default:
          returnWidth = "8%";
          break;
      }
      return returnWidth;
    },
    handleSelect(key, keyPath) {
      this.params.baseType = key;
      this.$refs.paginator.resetNum();
      this.params.pageNum = 1;
      setTimeout(() => {
        this.getProductionBase(this.params);
      }, 300);
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getProductionBase(this.params);
    },
    getProductionBase(params, isShowProductionbase) {
      this.isLoading = true;
      getProductionBase(params).then(res => {
        if (res.ok) {
          if (isShowProductionbase) {
            this.isShowProductionbase = res.data.tableData?.length
              ? true
              : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.productionbase-detail {
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu-item {
    max-height: 7px;
    line-height: 0;
    padding: 0 0 0 20px;
    color: #333;
    font-size: 14px;
    i {
      font-style: normal;
      color: #999;
      margin-left: 10px;
    }
  }

  .el-menu--horizontal > .el-menu-item.is-active,
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active span {
    border-bottom: 2px solid #3f76ea;
    color: #3f76ea;
    padding-bottom: 6px;
  }
}
</style>
