<template>
  <PageContainer>
    <!--           <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" style="padding-top:10px"/>-->
    <template>
      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <h1>主要产销数据表</h1>
            <div>
              <router-link to="/data/list-syc" tag="a" target="_blank" class="more"><span>查看更多</span><i
                  class="el-icon-arrow-right"></i></router-link>
            </div>
          </div>
        </VdataTitle>
        <div class="new-box">
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sychzb/')"
                   class="list-item">{{ newTime[0] }}年{{ newTime[1] }}月份商用车产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnysyccyc/', '2')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sycscqycxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份商用车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/wdqcqysyc/')" class="list-item">
                {{ newTime[0] }}年01-{{ newTime[1] }}月份五大汽车企业集团商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '7')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getZhcfcxCxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '7')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfdwcxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分吨位)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfytcxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分用途)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '12')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '12')"
                   class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getKcfcxCxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '17')"
                   class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getHcfwzclscqycxqkb/')"
                   class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '18')"
                   class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getBgqycscqycxqkb/')"
                   class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfdwcxqkb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分吨位)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfcxcxhzb/')" class="list-item">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分车型)产销汇总表
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <div flex="c">
              <h1 style="margin-right:10px">国内产销数据</h1>

            </div>
            <router-link to="/data/domesticData/sycIndex1" tag="a" class="more"><span>查看更多</span><i
                class="el-icon-arrow-right"></i></router-link>

          </div>
        </VdataTitle>
        <div class="new-box">

          <el-row :gutter="26">
            <el-col :span="12">
              <div class="echarts-inner">
                <div id="production"></div>
              </div>
              <p class="echarts-title">商用车月度市场走势</p>
            </el-col>
            <el-col :span="12">
              <div class="echarts-inner">
                <div id="sales"></div>
              </div>
              <p class="echarts-title">商用车年度市场走势</p>
            </el-col>
          </el-row>

        </div>
      </div>

    </template>

  </PageContainer>
</template>
<script>
import * as echarts from "echarts";
import {
  // ttIndustryInformationEnglish,
  YkbQcCxDataChart,
  getTableData,
} from "@/api/Index.js";
import {fmtDate} from "@/utils/date.js";
import VdataTitle from "@/components/dataTitle";
import {lastTimes, ttDataUpload} from "@/api/data.js";
import {getUserInfo, openSy} from "@/utils/auth.js";
// import VnotLogin from "@/components/notLogin.vue";
import {
  buryPoint,
  // download,
  newdownload
} from "@/api/common.js";
import {
  downloadFile
} from "@/utils/download.js";
import {DualAxes} from "@antv/g2plot";
let dualAxes1;
let dualAxes2;
export default {
  components: {
    VdataTitle,
    // VnotLogin
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "按月度",
        },
        {
          value: "2",
          label: "按年度",
        },
      ],
      tableData: [],
      tableData2: [],
      dialogVisible: false,
      uploadList: [],
      uploadData: [],
      productionTitle: "",
      salesTitle: "",
      tabData: [
        {value: "5", label: "轿车"},
        {value: "4", label: "SUV"},
        {value: "3", label: "MPV"},
        {value: "6", label: "交叉型"},
      ],
      qyAc: "5",
      cxAc: "5",
      indutryData: [],
      yearOrMonth: "1",
      lb: "1",
      newTime: [],
      show: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "数据",
        userId: ""
      },
      showP: false
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;


      this.init();
      // this.ttDataUpload();
      // this.getTableDataQy(this.qyAc);
      // this.getTableDataCx(this.cxAc);
      this.lastTimes();

    } else {
      this.show = false;
    }
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    init() {
      // ttIndustryInformationEnglish({ page: 1, pageSize: 10 }).then(
      //     (res) => {
      //         if (res.ok) {
      //             this.indutryData = res.data.list;
      //         }
      //     }
      // );

      YkbQcCxDataChart({cllx: 2, lb: 1}).then((res) => {
        if (res.ok) {
          this.ColumnLine1(res.data);
        }
      });
      YkbQcCxDataChart({cllx: 2, lb: 2}).then((res) => {
            if (res.ok) {
              this.ColumnLine2(res.data);
            }
      });
      // this.lastTimes();
    },
    ColumnLine1(data) {
      dualAxes1 = new DualAxes('production', {
        data: [data, data],
        xField: 'time',
        yField: ['value', 'count'],
        geometryOptions: [
          {
            geometry: 'column',
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
          },
        ],
        meta: {
          value: {
            alias: '销量（万辆）',
          },
          count: {
            alias: '同比增速（%）',
          },
        },
      });

      dualAxes1.render();
    },
    ColumnLine2(data) {
      dualAxes2 = new DualAxes('sales', {
        data: [data, data],
        xField: 'time',
        yField: ['value', 'count'],
        geometryOptions: [
          {
            geometry: 'column',
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
          },
        ],
        meta: {
          value: {
            alias: '销量（万辆）',
          },
          count: {
            alias: '同比增速（%）',
          },
        },
      });

      dualAxes2.render();

    },
    lastTimes() {
      //获取最新年月
      lastTimes().then((res) => {
        if (res.ok) {
          this.newTime = res.data.cx.split("-");
        }
      });
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //附件列表
    ttDataUpload() {
      let checkLoginName = process.env.VUE_APP_CHECKLOGIN;
      let modelName = "data_upload_1";
      if (checkLoginName == "fuao") {

        modelName = "data_upload_1,data_upload_2,data_upload_3,data_upload_4,data_upload_5"
      }
      ttDataUpload({
        modelName: modelName
      }).then(
          (res) => {
            if (res.ok) {
              this.uploadList = res.data;
            }
          }
      );
    },
    //下载附件
    handleDown(index, row) {
      this.download(row);
    },

    //勾选单行数据
    handleSelectionChange(selection, row) {
      this.uploadData = selection;
    },
    //勾选全部
    handleSelectionAllChange(selection) {
      this.uploadData = selection;
    },
    downAll() {
      console.log(this.uploadData);
    },
    download(item) {
      let self = this;
      newdownload({
        fileUrl: item.attachUrl,
        articleId: item.id
      }).then(
          (res) => {
            var reader = new FileReader();
            reader.readAsText(res.data);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                let contentDisposition =
                    res.headers["content-disposition"];
                let patt = new RegExp(
                    "filename=([^;]+\\.[^\\.;]+);*"
                );
                let result = patt.exec(contentDisposition);
                let filename = decodeURI(result[1]);
                downloadFile(res.data, filename);
              }
            });
          }
      );
    },
    chartsInit(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        legend: {
          data: data.legend,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = Number(datas[i].value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.xAxis3,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: data.series3,
      };
      myChart.setOption(option);
    },
    getTableDataQy(cxjbie) {
      getTableData({isQy: true, cxjbie: cxjbie}).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
        }
      });
    },
    selectChangeYear(lb) {
      YkbQcCxDataChart({cllx: 1, lb: lb}).then((res) => {
        if (res.ok) {
          let data = {
            ...res.data,
            legend: ["销量（万辆）", "同比增速（%）"],
          };
          this.productionTitle = data.title3;
          this.chartsInit("production", data);
        }
      });
      YkbQcCxDataChart({cllx: 2, lb: lb}).then((res) => {
        let data = {
          ...res.data,
          legend: ["销量（万辆）", "同比增速（%）"],
        };
        this.salesTitle = data.title3;
        this.chartsInit("sales", data);
      });


  },
  getTableDataCx(cxjbie) {
    getTableData({isQy: false, cxjbie: cxjbie}).then((res) => {
      if (res.ok) {
        this.tableData2 = res.data;
      }
    });
  },
  qyBtn(qyAc) {
    this.qyAc = qyAc;
    this.getTableDataQy(qyAc);
  },
  cxBtn(cxAc) {
    this.cxAc = cxAc;
    this.getTableDataCx(cxAc);
  },
  returnEnY(m) {
    let newData = "",
        splitStr = "",
        joiStr = "",
        year = "",
        monuth = "";
    if (m.length > 0) {
      newData = m[0].data;
      splitStr = newData.split("");
      splitStr.splice(4, 0, "-");
      joiStr = splitStr.join("");
      year = fmtDate("YYYY", joiStr);
      monuth = fmtDate("mm", joiStr);
    }
    let str = "";
    switch (monuth) {
      case "01":
        str = "1月";
        break;
      case "02":
        str = "2月";
        break;
      case "03":
        str = "3月";
        break;
      case "04":
        str = "4月";
        break;
      case "05":
        str = "5月";
        break;
      case "06":
        str = "6月";
        break;
      case "07":
        str = "7月";
        break;
      case "08":
        str = "8月";
        break;
      case "09":
        str = "9月";
        break;
      case "10":
        str = "10月";
        break;
      case "11":
        str = "11月";
        break;
      case "12":
        str = "12月";
        break;
    }
    return year + "年" + str + "销量（万辆）";
  },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
  historyOpen() {

    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.sqsyLogin();
          this.insertBuryPoint();
        } else {
          if (getUserInfo().roleKey == "trialrole") {
            openSy();
            this.insertBuryPoint();
          } else {
            let newUrl = this.$router.resolve({
              path: "/history",
            });
            window.open(newUrl.href, "_blank");
          }

        }
      } else {
        let newUrl = this.$router.resolve({
          path: "/history",
        });
        window.open(newUrl.href, "_blank");
      }

    } else {
      this.showLogin();
    }
  },
  toHref(url, type) {
    let str = "";
    str = type
        ? (str = url + this.newTime.join("-") + "-01/" + type)
        : url + this.newTime.join("-") + "-01";
    let newUrl = this.$router.resolve({
      path: str
    });
    window.open(newUrl.href, "_blank");

  },
}
,
}
;
</script>
<style lang="scss" scoped>
.list-box {
  // padding: 32px 32px 0;
  background: #fff;
  margin-bottom: 16px;

  &:nth-child(1) {
    height: 440px;
    padding-bottom: 28px;
  }

  &:nth-child(2) {
    height: 440px;
    padding-bottom: 40px;
  }

  &:nth-child(3) {
    padding-bottom: 40px;
  }

  &-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
    }

    .more {
      font-size: 15px;
      line-height: 20px;
      color: #fff;
      text-decoration: none;

      i {
        font-size: 20px;
        padding-left: 7px;
        vertical-align: middle;
      }
    }
  }

  .new-box {
    padding: 20px 30px 0;
  }
}

.list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 6px 0;
  text-decoration: none;
  overflow: hidden;
  color: #4f4f4f;
  font-size: 16px;
  cursor: pointer;

  .tags {
    .tag {
      display: inline-block;
      line-height: 16px;
      color: #0d57bc;
      padding: 4px;
      background: rgba(13, 87, 188, 0.08);
      margin-right: 6px;
    }
  }

  .text {
    flex: 1;
    line-height: 18px;
    padding-right: 15px;
  }
}

.echarts-inner {
  margin-top: -2px;
  margin-bottom: 12px;
}

.echarts-title {
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  color: #4f4f4f;
}

.top-box {
  margin-top: 14px;

  .top-title {
    font-size: 16px;
    line-height: 16px;
    color: #4f4f4f;
    margin-bottom: 24px;
  }

  .top-inner-tab {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;

    &-btn {
      position: relative;
      font-size: 16px;
      line-height: 19px;
      color: #000;
      cursor: pointer;
      margin-right: 28px;
      padding-bottom: 18px;

      &.ac {
        color: #0d57bc;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          display: block;
          height: 3px;
          width: 100%;
          background: #0d57bc;
        }
      }
    }
  }

  .top-inner-tab-content {
    /deep/ .el-table td {
      padding: 10px 0;
    }
  }
}

.pm {
  display: inline-block;
  padding: 0 5px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  background: #95c2ff;

  &-1 {
    background: #eb5757;
  }

  &-2 {
    background: #f2994a;
  }

  &-3 {
    background: #f0c02e;
  }
}

#production,
#sales {
  width: 100%;
  height: 288px;
}

.select-bg {
  /deep/ .el-input__inner {
    background: linear-gradient(
            90.79deg,
            #1768d5 3.39%,
            rgba(13, 87, 188, 0) 390.23%
    );
    color: #fff;
    border: 2px solid #fff;
  }

  /deep/ .el-select .el-input .el-select__caret {
    color: #fff;
  }

  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #fff;
  }
}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
