<template>
    <div class="industry">
        <titlemore title="产业布局" />
        <el-radio-group @change="radioChange" v-model="industryType" size="small">
            <el-radio-button label="电机电控"></el-radio-button>
            <el-radio-button label="动力电池"></el-radio-button>
            <el-radio-button label="半导体芯片设计"></el-radio-button>
            <el-radio-button label="半导体芯片制造"></el-radio-button>
            <el-radio-button label="燃料电池系统及电堆"></el-radio-button>
            <el-radio-button label="雷达"></el-radio-button>
            <el-radio-button label="汽车座椅"></el-radio-button>
            <el-radio-button label="汽车热管理"></el-radio-button>
            <el-radio-button label="智能座舱"></el-radio-button>
        </el-radio-group>
        <div class="top">
            <span v-if="show && !showP" />
            <not-logged-in-mask v-else />
            <china :class="[
            (show && !showP) ? '' : 'show-mask',
            'passenger-car-market-performance-body',
        ]"  :mapList="mapList" :chartTitle="mapdate + '国内' + mapTitle" :maxNum="maxNum" />

        </div>
    </div>
</template>

<script>
import {
    MapData,
} from "@/api/industry.js"
import china from "./components/charts.vue"
import TableCard from "@/components/tableCard.vue";
import SearchSelect from '@/components/searchSelect'
import Paginator from "@/components/paginator/index.vue";
import titlemore from './components/titlemore.vue'
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
import { NotLoggedInMask } from "@/components/newData/mask";
export default {
    components: {
        china,
        titlemore,
        TableCard,
        Paginator,
        SearchSelect,
        NotLoggedInMask,
    },
    props: {},
    data() {
        return {
            showP: false,
            show: false,
            isLoading: false,
            industryType: '电机电控',
            maxNum: '',
            mapList: [],
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
            mapTitle: '主要电动汽车驱动电机及电控企业分布图',
            chartTitle: '电动汽车驱动电机及电控',
            mapdate: '',
        }
    },
    computed: {},
    created() {
        if (getUserInfo()) {
            this.show = true;
            if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                let menuName = getUserInfo().menuName;
                if (!menuName.includes('企业情报')) {
                    this.showP = true;

                }
            }
        } else {
            this.show = false;
        }
    },
    mounted() {
        this.getMapData()
    },
    methods: {
        radioChange() {
            if (getUserInfo()) {
                
                logRecords('企业情报', this.title, '3', 'tt_enterprise_analysis', '', '', '', '','电机电控' )
                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.sqsyLogin();
                        this.insertBuryPoint();
                        this.industryType= '电机电控'
                        return
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.formLog.userId = getUserInfo().userId
                            buryPoint(this.formLog)
                            this.industryType= '电机电控'
                            openSy()
                            return
                        } else {
                        }
                    }
                }
            } else {
                this.showLogin();
                this.industryType= '电机电控'
                return
            }
            let mapTitle = ''
            let chartTitle = ''
            switch (this.industryType) {
                case '电机电控':
                    mapTitle = '主要电动汽车驱动电机及电控企业分布图';
                    chartTitle = '电动汽车驱动电机及电控';
                    break;
                case '动力电池':
                    mapTitle = '主要电动汽车动力锂电池企业分布图';
                    chartTitle = '电动汽车动力锂电池';
                    break;
                case '半导体芯片设计':
                    mapTitle = '半导体芯片设计企业布局图';
                    chartTitle = '导体芯片设计';
                    break;
                case '半导体芯片制造':
                    mapTitle = '半导体芯片制造企业布局图';
                    chartTitle = '半导体芯片制造';
                    break;
                case '燃料电池系统及电堆':
                    mapTitle = '主要燃料电池系统及电堆企业分布图';
                    chartTitle = '燃料电池系统及电堆';
                    break;
                case '雷达':
                    mapTitle = '主要雷达企业分布图';
                    chartTitle = '雷达';
                    break;
                case '汽车座椅':
                    mapTitle = '主要汽车座椅企业分布图';
                    chartTitle = '汽车座椅';
                    break;
                case '汽车热管理':
                    mapTitle = '主要汽车热管理系统企业分布图';
                    chartTitle = '汽车热管理系统';
                    break;
                case '智能座舱':
                    mapTitle = '主要智能座舱系统生产企业分布图';
                    chartTitle = '智能座舱系统';
                    break;
            }
            this.mapTitle = mapTitle
            this.chartTitle = chartTitle
            this.getMapData()
        },
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
        getMapData() {
            MapData({ industryType: this.industryType }).then((res) => {
                this.mapList = res.data.mapData
                this.maxNum = res.data.maxNum
                this.mapdate = res.data.year
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.industry {
    margin-top: 20px;
    background: #ffffff;
    padding: 25px 28px 0 23px;

    .top {
        margin: 20px 0 10px 0;
        position: relative;
        .mapTitle {
            font-size: 24px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            margin: 0 30px 10px 30px;
            padding-top: 30px;
            border-bottom: 1px solid rgb(221, 221, 221);
        }

        .mapContent {
            display: flex;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            padding: 10px 0 15px 0;
            color: rgb(102, 102, 102);

            img {
                margin-right: 7px;
            }
        }
    }
}

::v-deep {

    .el-radio-button {
        border: 0;
        border-radius: 4px;
    }

    .el-radio-button__inner {
        border: 0;
        border-radius: 4px;
        margin-top: 15px;
        background: #f3f3f3;
    }

    .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 4px;
    }

    .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 4px;
    }

    .el-radio-button__inner:hover {
        color: #606266;
    }

    .el-radio-button {
        margin-right: 12px;
    }

    .is-active .el-radio-button__inner {
        background: #3f76ea;
        box-shadow: none
    }

    .is-active :hover {
        color: #fff;
    }
}

.show-mask {
    filter: blur(3px);
    user-select: none;
    -webkit-user-select: none;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }
}
.not-logged-in-mask {
    z-index: 1;
    top: 20%;
}
</style>