<!--视频列表-->
<template>
  <router-view v-if="$route.name == '视频列内容页'"/>
  <PageContainer v-else breadcrumb>
    <div class="video-box">
      <h1 class="video-title">精彩视频</h1>
      <div class="video-top">
        <div class="video-l" @click="routerHref(onlyOne)">
          <img
            class="video-l-img"
            :src="onlyOne.coverUrl"
            :alt="onlyOne.coverName"
          />
          <h1 class="video-l-title">{{ onlyOne.title }}</h1>
          <div class="make"></div>
        </div>
        <ul class="video-r">
          <li
            v-for="item in onlyThree"
            :key="item.id"
            @click="routerHref(item)"
          >
            <img
              class="video-r-img"
              :src="item.coverUrl"
              :alt="item.coverName"
            />
            <h1 class="video-r-title">{{ item.title }}</h1>
            <div class="make"></div>
          </li>
        </ul>
      </div>
      <div class="screen-box" flex="c">
        <el-checkbox
          label="全部领域"
          border
          size="small"
          style="margin-right: 8px"
          @change="kckAll"
          v-model="keywordAll"
        ></el-checkbox>
        <el-checkbox-group v-model="keywords" size="small" @change="kcheck">
          <el-checkbox
            :label="item.dictValue"
            border
            v-for="item in keywordsData"
            :key="item.dictValue"
            >{{ item.dictLabel }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-row :gutter="28">
        <el-col :span="6" v-for="item in list" :key="item.id">
          <div class="video-list" @click="routerHref(item)">
            <div class="video-list-img">
              <img :src="item.coverUrl" :alt="item.coverName" />
            </div>
            <h1 class="video-list-title text-overflow" :title="item.title">
              {{ item.title }}
            </h1>
            <p class="video-list-p text-overflow" :title="item.summary">
              {{ item.summary }}
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="text-right" style="padding-bottom: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="[20, 30, 40, 50]"
          :page-size="pageData.pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import { getTopVideoList, ttVideoRecommendationPage } from "@/api/Index.js";
import { selectDictDataByType } from "@/api/dict.js";
export default {
  data() {
    return {
      keywordAll: true,
      keywords: [],
      keywordsData: [],
      onlyOne: {},
      onlyThree: [],
      list: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        currentPage: 0,
      },
    };
  },
  created() {
    this.getTopVideoList();
    this.selectDictDataByType();
    this.ttVideoRecommendationPage();
  },
  methods: {
    //获取顶置
    getTopVideoList() {
      getTopVideoList().then((res) => {
        if (res.ok) {
          let data = res.data;
          let [onlyOne, ...onlyThree] = data;
          this.onlyOne = onlyOne;
          this.onlyThree = onlyThree;
        }
      });
    },
    //获取标签
    selectDictDataByType() {
      selectDictDataByType({ dictType: "index_video_type" }).then((res) => {
        if (res.ok) {
          this.keywordsData = res.data;
        }
      });
    },
    //获取视频列表
    ttVideoRecommendationPage() {
      let page = this.pageData.pages;
      page = { ...page, keywordsLabel: this.keywords.join(",") };
      ttVideoRecommendationPage(page).then((res) => {
        if (res.ok) {
          this.list = res.data.list;
          this.pageData.total = res.data.count;
        }
      });
    },
    //关键字
    kcheck(e) {
      if (e.length <= 0) {
        this.keywordAll = true;
      } else {
        this.keywordAll = false;
      }
      this.ttVideoRecommendationPage();
    },
    //关键字全选
    kckAll() {
      this.keywords = [];
      this.ttVideoRecommendationPage();
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.ttVideoRecommendationPage(this.pageData.pages);
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.ttVideoRecommendationPage(this.pageData.pages);
    },
    //跳转
    routerHref(item) {
      let newUrl = this.$router.resolve("/index/detail/videoDetail/" + item.id);
      window.open(newUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}
::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
.video-container {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}
.video-box {
  width: 76.667%;
  margin: 0 auto;
}
.video-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
  margin: 24px 0;
}
.video-top {
  display: flex;
  justify-content: space-between;
  height: 506px;
  .video-l {
    position: relative;
    width: 74.366%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    .video-l-img {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        2.29deg,
        #000000 -3.29%,
        rgba(38, 38, 38, 0) 38.6%
      );
    }
    .video-l-title {
      position: absolute;
      left: 24px;
      bottom: 24px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      color: #fff;
      z-index: 1;
    }
  }
  .video-r {
    width: 23.098%;
    height: 100%;
    li {
      position: relative;
      width: 100%;
      height: 160px;
      margin-bottom: 13px;
      border-radius: 4px;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .video-r-img {
      width: 100%;
      height: 100%;
    }
    .video-r-title {
      position: absolute;
      left: 16px;
      bottom: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #fff;
      z-index: 1;
    }
  }
  .video-l,
  .video-r {
    cursor: pointer;
  }
}
.make {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    2.29deg,
    #000000 -3.29%,
    rgba(38, 38, 38, 0) 38.6%
  );
}
.screen-box {
  margin-top: 24px;
  margin-bottom: 16px;
}
.video-list {
  cursor: pointer;
  .video-list-img {
    width: 100%;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .video-list-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000;
    margin-top: 16px;
  }
  .video-list-p {
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    margin-bottom: 20px;
    color: #828282;
    margin-top: 8px;
  }
}
</style>
