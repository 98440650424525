import { fetchAnswer } from '@/api/ai'

const storeKey = 'AI_SERVICE'

const messages = (() => {
  try {
    const messages = JSON.parse(localStorage.getItem(storeKey))
    if (!messages) {
      throw new Error('Invalid AI Service message')
    }
    return messages
  } catch {
    return []
  }
})()

const state = {
  open: false,
  messages: [...messages]
}

const mutations = {
  UPDATE_MESSAGES(state, payload) {
    state.messages = [...state.messages, payload]
  },
  OPEN(state) {
    state.open = true
  },
  CLOSE(state) {
    state.open = false
  }
}

const actions = {
  openAIService({ commit }) {
    commit('OPEN')
  },
  closeAIService({ commit }) {
    commit('CLOSE')
  },
  updateAIService({ commit }, payload) {
    commit('UPDATE_MESSAGES', payload)
  },
  fetchAnswer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      fetchAnswer(payload).then((res) => {
        if (res.ok) {
          const data = res.data

          if (data.flag !== false) {
            commit('UPDATE_MESSAGES', {
              type: 'ai',
              content: data.answer
            })
          }
          resolve(data)
        } else {
          commit('UPDATE_MESSAGES', {
            type: 'ai',
            content: '抱歉，接口错误，请稍后再试 或联系18222220572'
          })
          reject()
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

