<!--减免购置税目录-->
<template>
  <PageContainer>
    <!-- <div class="header">
      <div class="title">减免购置税目录</div>
    </div> -->
    <el-form
      ref="formName"
      :model="form"
      inline
      label-width="100px"
      class="n-data-content"
    >
      <div class="form-item-container el-form-item">
        <div>
          <el-form-item
            label="批次"
            class="no-position first-position"
            prop="pc"
          >

            <selectCheck
              v-model="form.pc"
              :datas="pcData"
              :props="props"
              :is-letters="false"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="产品型号" prop="vehicleModelNumber">

            <el-input
              v-model="form.vehicleModelNumber"
              size="small"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="产品名称" prop="cpmc">
            <el-input
              v-model="form.commonName"
              size="small"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
            <el-form-item label="通用名称" prop="cpmc">
                <el-input
                        v-model="form.vehicleModel"
                        size="small"
                        class="new-width"
                />
            </el-form-item>
        </div>
        <div>
          <el-form-item label="生产企业" prop="scqy">
            <el-input
              v-model="form.enterpriseName"
              size="small"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="燃料种类" prop="rlzl">
            <el-select
              v-model="form.rlzl"
              class="new-width"
              size="small"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in rlzlData"
                :key="item"
                :value="item"
                :label="item"
              />
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="细分市场" prop="xfsc">

            <el-select
              v-model="form.xfsc"
              class="new-width"
              size="small"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in xfscData"
                :key="item"
                :value="item"
                :label="item"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="text-center">
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="resetForm">清空</el-button>
      </div>
    </el-form>

    <card
      title=" "
      :btnflag="show && !showP"
      style="border:none;border-top:1px solid #f0f0f0;"
      @downloadData="Export"
      @downloadAllData="ExportAll"
    >
      <template slot="body">
        <div class="search-form" style="margin-top:10px">
          <div v-if="show && !showP">
            <el-table
              v-loading="loading"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              stripe
              border
              :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
            >
              <el-table-column prop="pc" label="批次" min-width="60" />
              <el-table-column prop="cpxh" label="产品型号" min-width="120" />
              <el-table-column prop="enterpriseName" label="生产企业" min-width="200" />
              <el-table-column prop="commonName" label="产品名称" min-width="120" />
                <el-table-column prop="vehicleModel" label="通用名称" min-width="120" />

                <el-table-column prop="rlzl" label="燃料种类" min-width="80" />
              <el-table-column prop="xfsc" label="细分市场" min-width="80" />
            </el-table>
            <div class="text-right">
              <el-pagination
                :current-page="pageData.page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.count"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
          <div v-else style="position: relative; ">
            <img style="width:100%" src="@/assets/img/mzgzsb.jpg" alt="">
            <center>
              <div
                v-if="!show"
                style="position:relative;width:300px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              >
                <div style="padding-top: 20px">
                  <span>【提示】数据内容需要会员<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="showLogin2()"
                  >登录</span>后查看</span>
                </div>

                <div style="margin: 10px">
                  没有账号？可<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 1)"
                  >申请试用</span>
                </div>
              </div>
              <div
                v-else
                style="position:relative;width:320px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              >
                <div style="padding-top: 20px;padding-left: 22px;">
                  <span
                    style="font-size: 15px;"
                  >【提示】数据内容需要开通本栏目后查看</span>
                </div>
                <div style="padding: 10px 20px;text-align: center">
                  <span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 1)"
                  >在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 2)"
                  >直接联系我们</span>
                </div>
              </div>
            </center>
          </div>
        </div>
      </template>
    </card>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
// import Vtitle from "../components/title.vue";
import {
  getPCInfoListXny2,
  getRlInfoListXny2,
  getXfscInfoListXny2,
  ttXnyMzgzsPage2,
  ttXnyMzgzsDynamicExport2,
  ttXnyMzgzsExportAll2
} from '@/api/data.js'
import { downloadFile } from '@/utils/download.js'
import selectCheck from '@/components/selectCheck'
import { buryPoint } from '@/api/common'
import card from './common/card.vue' // 卡片组件

export default {
  name: 'ExemptionPurchaseTaxCatalog2',
  components: {
    // VnotLogin,
    selectCheck,
    card
    // Vtitle,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: '数据动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '数据库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '数据梳理',
          text: '专家梳理整合，真正读懂数据脉络'
        },
        {
          title: '数据大数据',
          text: '用数据深度理解数据结构和趋势'
        }
      ],
      tableData: [],
      tableHeader: [],
      form: {
        enterpriseNameShow: true,
        commonNameShow: true,
        pcShow: true,
        cpxhShow: true,
        rlzlShow: true,
        xfscShow: true,
        commonName: '',
        enterpriseName: '',
        vehicleModelNumber: '',
        pc: [],
        rlzl: [],
        xfsc: []
      },
      pageData: {
        page: 1,
        pageSize: 10,
        count: 0
      },
      pcData: [],
      rlzlData: [],
      xfscData: [],
      loading: false,
      excelLoading: false,
      excelAllLoading: false,
      props: {
        label: 'pc',
        key: 'pc',
        name: 'pc'
      },
      showP: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '数据',
        userId: ''
      }
    }
  },
  mounted() {
    this.init()
    this.getPCInfoListXny()
    if (getUserInfo()) {
      this.show = true
      if (
        getUserInfo().roleKey == 'paidrole' ||
        getUserInfo().roleKey == 'trialrole'
      ) {
        const menuName = getUserInfo().menuName
        if (!menuName.includes('数据')) {
          this.showP = true
        }
      }
    } else {
      this.show = false
    }
  },
  methods: {
    // 申请试用
    sqsy(url) {
      const newUrl = this.$router.resolve(url)

      window.open(newUrl.href, '_blank')
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示登录弹窗
    showLogin2() {
      this.$Vlogin({ flag: true })
    },
    // 初始化类型
    init() {
      getRlInfoListXny2().then((res) => {
        if (res.ok) {
          this.rlzlData = res.data
        }
      })
      getXfscInfoListXny2().then((res) => {
        if (res.ok) {
          this.xfscData = res.data
        }
      })
    },
    getPCInfoListXny() {
      getPCInfoListXny2().then((res) => {
        if (res.ok) {
          this.form.pc = [res.data[0].pc]
          this.pcData = res.data
          if (this.show) {
            this.ttXnyMzgzsPage()
          }
        }
      })
    },
    // 查询
    ttXnyMzgzsPage() {
      const form = Object.assign({}, this.form, this.pageData)
      for (const key in form) {
        const isArr = Array.isArray(form[key])
        if (isArr) {
          form[key] = form[key].join(',')
        }
      }
      delete form.count
      this.loading = true
      ttXnyMzgzsPage2(form).then((res) => {
        if (res.ok) {
          // this.tableHeader = res.data.tableFiles;
          this.tableData = res.data.list
          this.pageData.count = res.data.count
        }
        this.loading = false
      })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 查询
    search() {
      if (getUserInfo()) {
        logRecords(
          '数据',
          '减免购置税',
          '3',
          'tt_xny_mzgzs',
          '',
          '',
          '',
          '',
          '查询'
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.pageData.page = 1
            this.ttXnyMzgzsPage()
          }
        } else {
          this.pageData.page = 1
          this.ttXnyMzgzsPage()
        }
      } else {
        this.showLogin()
      }
    },
    // 重置
    resetForm() {
      this.$refs.formName.resetFields()
      this.getPCInfoListXny()
    },
    // 切换清空
    changeFun(type) {
      const isArr = Array.isArray(this.form[type])
      if (isArr) {
        this.$set(this.form, type, [])
      } else {
        this.$set(this.form, type, '')
      }
    },
    // 返回名称
    returnName(label) {
      let str = ''
      switch (label) {
        case 'cpxh':
          str = '车辆型号'
          break
        case 'enterpriseName':
          str = '生产企业'
          break
        case 'commonName':
          str = '通用名称'
          break
        case 'pc':
          str = '批次'
          break
        case 'rlzl':
          str = '燃料种类'
          break
        case 'xfsc':
          str = '细分市场'
          break
      }

      return str
    },
    // 分页
    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.ttXnyMzgzsPage()
    },
    handleCurrentChange(val) {
      this.pageData.page = val
      this.ttXnyMzgzsPage()
    },

    // 导出
    Export() {
      if (getUserInfo()) {
        logRecords(
          '数据',
          '减免购置税',
          '3',
          'tt_xny_mzgzs',
          '',
          '',
          '',
          '',
          '导出'
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              const _self = this
              const form = Object.assign({}, this.form, this.pageData)
              for (const key in form) {
                const isArr = Array.isArray(form[key])
                if (isArr) {
                  form[key] = form[key].join(',')
                }
              }
              delete form.count
              this.excelLoading = true
              ttXnyMzgzsDynamicExport2(form).then((res) => {
                this.excelLoading = false
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '减免购置税目录数据.xls')
                  }
                })
              })
            }
          }
        } else {
          const _self = this
          const form = Object.assign({}, this.form, this.pageData)
          for (const key in form) {
            const isArr = Array.isArray(form[key])
            if (isArr) {
              form[key] = form[key].join(',')
            }
          }
          delete form.count
          this.excelLoading = true
          ttXnyMzgzsDynamicExport2(form).then((res) => {
            this.excelLoading = false
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '减免购置税目录数据.xls')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 导出全部
    ExportAll() {
      if (getUserInfo()) {
        logRecords(
          '数据',
          '减免购置税',
          '3',
          'tt_xny_mzgzs',
          '',
          '',
          '',
          '',
          '导出全部'
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              const _self = this
              const form = Object.assign({}, this.form, this.pageData)
              for (const key in form) {
                const isArr = Array.isArray(form[key])
                if (isArr) {
                  form[key] = form[key].join(',')
                }
              }
              delete form.count
              this.excelAllLoading = true
              ttXnyMzgzsExportAll2(form).then((res) => {
                this.excelAllLoading = false
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '减免购置税目录全部数据.xls')
                  }
                })
              })
            }
          }
        } else {
          const _self = this
          const form = Object.assign({}, this.form, this.pageData)
          for (const key in form) {
            const isArr = Array.isArray(form[key])
            if (isArr) {
              form[key] = form[key].join(',')
            }
          }
          delete form.count
          this.excelAllLoading = true
          ttXnyMzgzsExportAll2(form).then((res) => {
            this.excelAllLoading = false
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '减免购置税目录全部数据.xls')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.outer-layer-box {
  padding-right: 0;
  background-color: #fff;
}
.first-position {
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
}
.header {
  padding: 24px;

  .title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #0d57bc;
      margin-right: 8px;
    }
  }
}
.n-data-content {
  padding: 6px 24px 0;
}

.form-item-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  // .el-form-item {
  //   margin-bottom: 0px !important;
  // }
}
.el-form {
  .el-form-item {
    margin-bottom: 0px !important;
  }
}
.el-checkbox {
  margin-right: 16px;
  // margin-left: 16px;
}
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.table-tip {
  width: 760px;
  margin: 0 auto;
}

.search-form {
  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}
.more-icon {
  width: 15px;
}
.text-center {
  margin: 14px 0 24px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;
}

.el-message-box__message p {
  line-height: 14px;
}
</style>
