<!--
 * @Author: your name
 * @Date: 2021-07-22 09:51:49
 * @LastEditTime: 2021-07-22 10:24:46
 * @LastEditors: Please set LastEditors
 * @Description: 企业销售预测
 * @FilePath: /information-vue/code/src/views/sale/workSalesForecast.vue
-->

<template>
  <PageContainer>
<!--    <VnotLogin v-if="!show" title="预测" text="预测栏目围绕预测本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新预测" :showTips="showTips"/>-->
    <template >
      <div class="g-content-table search-form">
        <el-tabs v-model="activeName" @tab-click="handleClick" >
<!--          <el-tab-pane label="企业车型预测" name="企业车型预测" :lazy="true">-->
<!--            <Vyear/>-->
<!--          </el-tab-pane>-->
          <el-tab-pane label="企业销量预测" name="企业销量预测" :lazy="true">
              <Vmonth/>
          </el-tab-pane>

        </el-tabs>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 预测动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import  Vmonth  from "./views/month.vue"
import  Vyear  from "./views/year.vue"
export default {
  components: {
    // VnotLogin,
    Vmonth,
    Vyear
  },
  data() {
    return {
      activeName: "企业销量预测",
      showTips: [
        {
          title: "预测动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "预测库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "预测梳理",
          text: "专家梳理整合，真正读懂预测脉络",
        },
        {
          title: "预测大数据",
          text: "用数据深度理解预测结构和趋势",
        },
      ],
      data1: [
        {
          id: 1,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大两会政",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 2,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
        {
          id: 3,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 4,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
      ],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}
.dynamic-top {
  padding: 20px 32px;
  margin-bottom: 12px;
  background: #fff;
  .dynamic-top-original-box {
    display: flex;
    margin-top: 32px;
    margin-left: -26px;
    cursor: pointer;
    .dynamic-top-original {
      display: flex;
      flex: 1;
      margin-left: 26px;
      .dynamic-top-original-img {
        width: 177px;
        height: 99px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
        }
      }
      .dynamic-top-original-inner {
        margin-left: 6px;
        p {
          line-height: 21px;
          color: #000;
          font-weight: 500;
          height: 64px;
        }
        span {
          color: #828282;
        }
      }
    }
  }
}
</style>
