<template>
  <div v-loading="loading" class="syccxhz">
    <div class="syccxhzHeader">
      <!--          <div></div>-->
      <div class="sycBottom">
        <p>{{ date[0] }}年{{ date[1] }}月份{{ title }}</p>
      </div>
      <div class="sycDan">
        <p>单位：辆，%</p>
      </div>
      <div class="sycDan1">
        <el-button v-has-role="'freeusers'" type="primary" icon="el-icon-upload" @click="exportExcel(title)">导出</el-button>
      </div>
    </div>
    <div>
      <table id="tal" border="1" style="width: 100%;padding: 5px" class="hisTable">
        <thead>
          <tr class="headerClass">
            <td colspan="16" rowspan="1" align="center" style="display: none">{{ date[0] }}年{{ date[1] }}月份{{ title }}</td>
          </tr>
          <tr class="headerClass">
            <td rowspan="2">序号</td>
            <td rowspan="2">生产企业</td>
            <td rowspan="2">品牌</td>
            <td colspan="6">生产</td>
            <td colspan="6">销售</td>
            <td rowspan="2">本期产销率</td>
          </tr>
          <tr class="headerClass">
            <td>本月完成</td>
            <td>本期止累计</td>
            <td>同期止累计</td>
            <td>比上月增长</td>
            <td>比同期增长</td>
            <td>比同期累计增长</td>
            <td>本月完成</td>
            <td>本期止累计</td>
            <td>同期止累计</td>
            <td>比上月增长</td>
            <td>比同期增长</td>
            <td>比同期累计增长</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in tableData" :key="index">
            <!-- <td v-if="item.zhibiao1 == '国内制造' && item.zhibiao1dis != 'none'" rowspan="24">{{item.zhibiao1}}</td>
                        <td v-else-if="item.zhibiao1 == 'CKD'&& item.zhibiao1dis != 'none'" rowspan="24">{{item.zhibiao1}}</td>
                       <td v-if="item.qz == '其中1' && item.qzdis != 'none'" rowspan="8" >{{item.qz}}</td>
                        <td v-if="item.qz == '其中2'&& item.qzdis != 'none'" rowspan="5" >{{item.qz}}</td>
                          <td v-if="item.zhibiao == '商用车总计' && item.zhibiaodis != 'none'" colspan="2">{{item.zhibiao}}</td>
                          <td v-else>{{item.zhibiao}}</td> -->

            <td v-if="item.zhibiao1dis != 'none' && item.zhibiao2 != '总计'" :rowspan="item.zhibiao1span">{{ item.index }}</td>
            <td v-if="item.zhibiao1dis != 'none'&& item.zhibiao2 != '总计'" :rowspan="item.zhibiao1span">{{ item.zhibiao1 }}</td>
            <td v-if="item.zhibiao2 == '总计'" colspan="3">{{ item.zhibiao2 }}</td>
            <td v-else>{{ item.zhibiao2 }}</td>
            <td>{{ item.scBywc }}</td>
            <td>{{ item.scBqzlj }}</td>
            <td>{{ item.scTqzlj }}</td>
            <td>{{ item.scBsyzz }}</td>
            <td>{{ item.scBtqzz }}</td>
            <td>{{ item.scBtqljzz }}</td>

            <td>{{ item.xsBywc }}</td>
            <td>{{ item.xsBqzlj }}</td>
            <td>{{ item.xsTqzlj }}</td>
            <td>{{ item.xsBsyzz }}</td>
            <td>{{ item.xsBtqzz }}</td>
            <td>{{ item.xsBtqljzz }}</td>

            <td>{{ item.bqcxl }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
<!--  <div style="font-size: 50px;text-align: center" v-else>-->
<!--    数据待更新-->
<!--  </div>-->
</template>
<script>
import { getQgCycZyppCxqkb } from '@/api/data'
import { combineCell } from '@/utils/tableData.js'

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { getUserInfo, openSy } from '@/utils/auth'
import XLSXS from 'xlsx-style-medalsoft'
export default {
  data() {
    return {
      tableData: [],
      date: null,
      bj2: null,
      title: '全国乘用车主要品牌产销情况表',
      loading: true
    }
  },
  created() {
    const date = this.$route.params.date
    this.date = date.split('-')
    this.bj2 = Number(this.$route.params.bj2) <= 0 ? '' : this.$route.params.bj2
    this.getQgCycZyppCxqkb({ dataDate: date.split('-').join(''), bj2: this.bj2 })
    this.setTitle(this.$route.params.bj2)
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #ffffff;color:#000000;padding:8px;')
  },
  methods: {

    exportExcel(title) {
      if (getUserInfo().roleKey == 'trialrole') {
        openSy()
      } else {
        /* generate workbook object from table */
        var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
        var table = document.querySelector('#tal').cloneNode(true)
        var wb = XLSX.utils.table_to_book(table, xlsxParam)
        const wbs = wb['Sheets']['Sheet1']
        // 每个单元格设置居中
        for (const key in wbs) {
          if (key.indexOf('!') === -1 && wbs[key].v) {
            wbs[key].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrap_text: true
              }
            }
          }
        }

        /* get binary string as output */
        var wbout = XLSXS.write(wb, { bookType: 'xlsx', bookSST: true, type: 'buffer' })
        try {
          FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), title + '.xlsx')
        } catch (e) {
          if (typeof console !== 'undefined') {
            console.log(e, wbout)
          }
        }
        return wbout
      }
    },
    getQgCycZyppCxqkb(date) {
      getQgCycZyppCxqkb(date).then((res) => {
        if (res.ok) {
          this.tableData = combineCell(res.data)
          let index = 0
          this.tableData.forEach(item => {
            if (item.scBsyzz != '*') {
              item.scBsyzz = Number(item.scBsyzz).toFixed(2)
            }
            if (item.scBtqzz != '*') {
              item.scBtqzz = Number(item.scBtqzz).toFixed(2)
            }
            if (item.scBtqljzz != '*') {
              item.scBtqljzz = Number(item.scBtqljzz).toFixed(2)
            }
            if (item.xsBsyzz != '*') {
              item.xsBsyzz = Number(item.xsBsyzz).toFixed(2)
            }
            if (item.xsBtqzz != '*') {
              item.xsBtqzz = Number(item.xsBtqzz).toFixed(2)
            }
            if (item.xsBtqljzz != '*') {
              item.xsBtqljzz = Number(item.xsBtqljzz).toFixed(2)
            }
            if (item.bycxl != '*') {
              item.bycxl = Number(item.bycxl).toFixed(2)
            }
            if (item.bqcxl != '*') {
              item.bqcxl = Number(item.bqcxl).toFixed(2)
            }
            if (item.zhibiao1dis == '*' && item.zhibiao2 != '总计') {
              index++
              item.index = index
            }
          })
        }
        console.log(this.tableData, 'this.tableData')
        this.loading = false
      })
    },
    setTitle(bj2) {
      switch (bj2) {
        case '5':
          this.title = '基本型乘用车主要品牌产销情况表'
          break
        case '3':
          this.title = 'MPV主要品牌产销情况表'
          break
        case '4':
          this.title = 'SUV主要品牌产销情况表'
          break
        case '6':
          this.title = '交叉型乘用车主要品牌产销情况表'
          break
        default:
          this.title = '全国乘用车主要品牌产销情况表'
      }
    }
  }
}
</script>
<style scoped lang="css">
.hisTable {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}
.headerClass {
    font-size: 16px;
    color: white;
    background-color: #0d57bc;
}
.trOne {
    font-weight: bold;
}
.syccxhzHeader {
    width: 100%;
    height: 90px;

    font-size: 22px;
    text-align: center;
}
.sycBottom {
    width: 100%;
    height: 70px;
    line-height: 70px;

    float: right;
    font-weight: bold;
}
.sycDan {
  width: 10%;
  height: 20px;

  float: left;
  font-size: 13px;
}

.sycDan1 {

  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}
table {
  border-color: #cccccc;
}
</style>
