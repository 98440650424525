import { getUserInfo } from '@/utils/auth.js'

// 用于判断权限
export const authMixin = {
  computed: {
    // 是否展示未登录的遮罩
    showNotLoggedInMask() {
      return getUserInfo()?.userId == null
    },
    // 是否展示未订阅的遮罩
    showUnsubscribedMask() {
      const userInfo = getUserInfo()
      return userInfo && userInfo.menuName && !userInfo.menuName.includes('数据')
    },
    // 是否展示未订阅的遮罩 //国际化
    showUnsubscribedMaskInter() {
      const userInfo = getUserInfo()
      return userInfo && userInfo.menuName && !userInfo.menuName.includes('国际化')
    },
    // 是否是试用用户
    isTrialRole() {
      const userInfo = getUserInfo()
      return userInfo && userInfo.roleKey == 'trialrole'
    },
    // 是否给页面元素加蒙层
    showMask() {
      // 未登录 或者 没买数据栏目且不是试用用户，则展示蒙层
      return this.showNotLoggedInMask || (this.showUnsubscribedMask && !this.isTrialRole && this.showUnsubscribedMaskInter)
    }
  }
}
