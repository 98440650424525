<template>
  <div>
    <div class="enterprise-report" style="margin-top: 20px;">
      <el-card :body-style="{ padding: '0 22px 20px' }">
        <div slot="header" class="clearfix">
          <h3 class="title-tag-text" style="">
            企业分析报告
          </h3>
          <span
            class="more"
            style="float: right;"
            @click="toHref('/workInformation/analysis/index', '_self')"
          >
            查看更多 <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div
          class="content-box"
          style="cursor: pointer"
          v-for="i in reportData"
          :key="i.id"
          @click="
            toHref(
              `/workInformation/analysis/analysisContent/${i.id}`,
              '_blank'
            )
          "
        >
          <img class="news-image" :src="i.imgUrl" />
          <div class="text-box">
            <p>
              <span v-if="i.contentCategory == 'yc'">原创</span>
              <el-tooltip
                effect="light"
                placement="top"
                v-if="queryValueLength(i.title)"
              >
                <template #content>
                  <span>{{ i.title }}</span>
                </template>
                <a>{{ `${i.title.trim().slice(0, 30)}...` }}</a>
              </el-tooltip>
              <a v-else>{{ i.title }}</a>
            </p>
            <div class="release-date">{{ i.updateTime }}</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { logRecords } from "@/utils/auth.js";
import { AnalysisQueryByPage } from "@/api/enterpriseAnalysis.js";
export default {
  name: "enterpriseReport",
  data() {
    return {
      reportParams: {
        pageNo: 1,
        pageSize: 5
      },
      reportData: []
    };
  },
  computed: {
    queryValueLength() {
      return text => {
        if (!text) return false;
        const chineseRegex = /[\u4e00-\u9fa5]/; // 匹配汉字的正则表达式
        const maxNum = chineseRegex.test(text) ? 30 : 15;
        const textNum = text.trim().length;
        return textNum >= maxNum;
      };
    }
  },

  methods: {
    toHref(routeUrl, openWay) {
      const newUrl = this.$router.resolve(routeUrl);
      logRecords("企业情报", "企业分析报告", "", "", "", "", "", "");
      window.open(newUrl.href, openWay);
    },
    init() {
      AnalysisQueryByPage(this.reportParams).then(res => {
        if (res.ok) {
          this.reportData = res.data.list;
        }
      });
    }
  },
  created() {
    this.init();
  }
};
</script>
<style lang="scss">
.enterprise-report {
  .el-card {
    min-width: 400px;
  }
  .el-card__header {
    border: none;
    padding: 30px 22px 20px;
  }
  .el-tooltip__popper.is-light {
    border: none !important;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
      0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow {
    border-top-color: #eaeaea !important;
  }
  .el-tooltip__popper[x-placement^="top"] .popper__arrow {
    border-top-color: #eaeaea !important;
  }
}
</style>
<style lang="scss" scoped>
.more {
  color: #999;
}
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}

.enterprise-report {
  .content-box {
    position: relative;
    width: 100%;
    height: 90px;
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
    .news-image {
      position: absolute;
      left: 0;
      width: 175px;
      height: 100%;
      border-radius: 4px;
      background-position: center center;
      background-size: 100% 100%;
    }
    .text-box {
      position: absolute;
      left: 195px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #222;
        & > span {
          display: inline-block;
          text-align: center;
          width: 32px;
          height: 18px;
          background-image: linear-gradient(
              144deg,
              #ff8e5d 0%,
              rgba(255, 47, 47, 1) 100%
            ),
            linear-gradient(#e7edff, #e7edff);
          background-blend-mode: normal, normal;
          border-radius: 2px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #fff;
          margin-right: 13px;
        }
        & > a {
          color: inherit; /* 使链接颜色和周围文本一致 */
          text-decoration: none; /* 移除下划线 */
          color: #222;
        }
      }
      .release-date {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #bbb;
      }
    }
  }
}
</style>
