<template>
  <!-- 数据栏目所有子页面的布局组件 -->
  <div class="layout-container">
    <side-bar ref="sideBarRef" :is-driving="isDriving" />
    <div class="main-container hidden-scrollbar " @scroll="scrollFun">
      <module-nav-bar :show-shadow="showShadow" />
      <main
        ref="layoutMain"
        v-loading="loading"
        class="layout-main hidden-scrollbar"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)"
      >
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </main>
    </div>
    <el-backtop target=".main-container" :bottom="80" :right="10">
      <div class="back-top-inner">
        <i class="el-icon-arrow-up" />
        TOP
      </div>
    </el-backtop>
    <DriverDialog :visible="showDriverDialog" @close="handleCloseDriverDialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SideBar, ModuleNavBar, DriverDialog } from './comp'
import { driver } from 'driver.js'
import { getUserInfo } from '@/utils/auth.js'
import {
  guideRecordCheck,
  markGuideRecord
} from '@/api/data_new.js'

export default {
  name: 'NewDataLayout',
  components: {
    SideBar,
    ModuleNavBar,
    DriverDialog
  },
  data() {
    return {
      showShadow: false,
      showDriverDialog: false,
      // 是否正在进行新人引导
      isDriving: false,
      userId: ''
    }
  },
  computed: {
    ...mapGetters(['loading']),
    activeModuleName() {
      // 返回第二层路由的 meta.moduleName，该属性用于标识当前路由属于 数据报告、数据查询、数据表下载 的哪一个
      return this.$route.matched[1].meta?.moduleName
    }
  },
  created() {
    const userInfo = getUserInfo()
    this.userId = userInfo.userId
    if (userInfo?.userId) {
      guideRecordCheck({ userId: userInfo.userId })
        .then(res => {
          if (res && res.data) {
            this.showDriverDialog = !res.data.guideStatus
          }
        })
    }
  },
  methods: {
    scrollFun(e) {
      this.showShadow = e.target.scrollTop !== 0
    },
    handleCloseDriverDialog() {
      this.showDriverDialog = false
      this.isDriving = true
      // 在新人引导之前, 关闭当前激活的菜单, 以防 heightLight 和 popover 的位置不准确
      this.$refs.sideBarRef.closeActiveMenu()
      // 最好还是等菜单收起来之后, 再进行引导
      setTimeout(() => {
        const driverObj = driver({
          showProgress: true,
          allowClose: false,
          // 禁止交互
          disableActiveInteraction: true,
          popoverClass: 'driver-popover',
          prevBtnText: '上一步',
          nextBtnText: '下一步',
          doneBtnText: '知道了',
          progressText: '{{current}} / {{total}}',
          steps: [
            {
              element: '#data-query-menus',
              popover: { title: '数据查询', description: '可选择不同数据内容，进行查询' }
            },
            {
              element: '#data-download-menus',
              popover: { title: '数据表下载', description: '可选择数据表下载' }
            },
            {
              element: '#tableDownload',
              popover: { title: '数据表下载', description: '可选择数据表下载' }
            }
          ],
          // 新人引导结束后,重新打开当前激活的菜单
          onDestroyed: () => {
            this.isDriving = false
            this.$refs.sideBarRef.openActiveMenu()
            const userInfo = getUserInfo()
            if (userInfo?.userId) {
              markGuideRecord({ userId: userInfo.userId })
                .then(() => {})
                .catch(() => {})
            }
          }
        })
        driverObj.drive()
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";
@import "~@/styles/newDataCommon.scss";

// 整体的样式
.layout-container {
  display: flex;
  padding-top: 16px;
}
.main-container {
  position: relative;
  flex: 1;
  padding: 0 16px;
  overflow: auto;
}

// 主页面部分的样式
.layout-main {
  position: relative;
  min-height: 0;
  // overflow-x: hidden;
  padding: 16px 0;
}
::v-deep {

  .el-menu {
    border-right: none;
  }
}
</style>
