<!--最新政策列表-->
<template>
  <router-view v-if="$route.name == '最新政策内容'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="最新政策" smallTip="会员专享精华内容推荐" :bgurl="bgurl" />
    <Vlist :list="queryNewPolicyData" :page="queryNewPolicyPage" :pageData="queryNewPolicyPage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { queryNewPolicy } from "@/api/Index.js";
import Vlist from "@/components/list";
import {getUserInfo,logRecords} from "@/utils/auth";
import {accessRecords} from "@/api/common";
export default {
    components: {
        VlistTitle,
        Vlist,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            queryNewPolicyData: [],
            queryNewPolicyPage: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },

        };
    },
    created() {
        this.queryNewPolicy(this.queryNewPolicyPage.pages);
    },
    methods: {
        queryNewPolicy(params) {
            queryNewPolicy(params).then((res) => {
                if (res.ok) {
                    this.queryNewPolicyData = res.data.list;
                    this.queryNewPolicyPage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.queryNewPolicyPage.pages.pageSize = v;
            this.queryNewPolicy(this.queryNewPolicyPage.pages);
        },
        handleCurrentChange(v) {
            this.queryNewPolicyPage.pages.page = v;
            this.queryNewPolicy(this.queryNewPolicyPage.pages);
        },

        toHref(item) {
          logRecords('首页','政策动态','1','tt_first_policy',item.id,'',item.title,'')
            let newUrl = this.$router.resolve("/index/detail/newPolicyDetail/" + item.id);
            window.open(newUrl.href, "_blank");
        },
    },
};
</script>
