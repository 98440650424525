<template>
    <div class="us-page">
        <!-- <div style="width: 1200px; margin: 0 auto; padding: 20px 0">
      <h1 style="margin-bottom: 20px">关于信息网</h1>
      <div v-html="content.htmlContent"></div>
    </div> -->
    
        <div class="us-container">
            
            <div class="us-container-t us-container-b">
            
                <img src="@/assets/img/dzhxt.png" alt="" width="787" style="margin-top:60px">
                <div class="us-contact" id="usContact">
                    <h1 class="us-contact-title">开通试用，请联系市场经理</h1>
                    <div class="d-line"></div>
                    <div class="us-contact-item-box">
                        <!-- <div class="us-contact-item">
                            <img src="@/assets/img/Group 983.png" alt="" width="64" height="64">
                            <div class="photo-info">
                                <h1 class="photo-info-title">董莹</h1>
                                <p class="photo-info-tip">电话：13302019303 022-84379229</p>
                                <p class="photo-info-tip">邮箱：dongying@catarc.ac.cn</p>
                                <div class="line"></div>
                                <p class="photo-info-tip">服务区域：上海、浙江、江苏、江西、福建、安徽、山东、河南、湖北、湖南、四川、重庆、云南、贵州、西藏、台湾</p>
                            </div>
                        </div> -->
                        <div class="us-contact-item">
                            <img src="@/assets/img/Group 984.png" alt="" width="64" height="64">
                            <div class="photo-info">
                                <h1 class="photo-info-title">李文娟</h1>
                                <p class="photo-info-tip">电话：18322511031 022-84370000-分机1582</p>
                                <p class="photo-info-tip">邮箱：liwenjuan@catarc.ac.cn</p>
                                <!-- <div class="line"></div>
                                <p class="photo-info-tip">服务区域：黑龙江、吉林、辽宁、北京、天津、河北、山西、陕西、甘肃、内蒙古、宁夏、青海、新疆、广西、广东、海南、香港、澳门</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Vfooter />
    </div>
</template>
<script>
import { us } from "@/api/us.js";
import Vfooter from "@/components/footer.vue";

export default {
    components: {
      Vfooter,
    },
    data() {
        return {
            content: {},
        };
    },
    mounted() {
        let id = this.$route.query.id;
        if (id) {
            this.$nextTick(() => {
                document.querySelector("#" + id).scrollIntoView();
            });
        }
    },
    methods: {
        us() {
            us().then((res) => {
                if (res.ok) {
                    this.content = res.data[0];
                }
            });
        },
        toView() {
            document.querySelector("#usContact").scrollIntoView();
        },
    },
};
</script>
<style lang="scss" scoped>
.us-head {
    display: flex;
    width: 100%;
    height: 676px;
    background: #0d57bc;
}
.us-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background: url(../../assets/img/Illustration.png) right top no-repeat;
    background-size: 820px;
    .us-banner-l {
        flex: 0 0 652px;
        margin-right: -51px;
        .title {
            font-weight: bold;
            font-size: 46px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #fff;
            margin-top: 14px;
        }
        .des {
            width: 532px;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: #fff;
            margin: 24px 0;
            text-align: justify;
        }
    }
    .us-banner-r {
        align-self: flex-end;
        margin-right: -214px;
    }
    .us-head-btn {
        width: 144px;
        height: 63px;
        font-size: 16px;
        background: #2270da;
        border-radius: 100px;
        border: none;
        color: #fff;
        cursor: pointer;
    }
}
.us-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 254px;
    background: #f4f5f6;
    .us-number {
        width: 217px;
        text-align: center;
        margin-right: 21px;
        .title {
            position: relative;
            font-weight: 500;
            font-size: 42px;
            line-height: 42px;
            text-align: center;
            color: #0d57bc;
            padding-bottom: 11px;
            margin-bottom: 10px;
            &::after {
                position: absolute;
                left: 50%;
                bottom: 0;
                margin-left: -25px;
                content: "";
                display: block;
                width: 52px;
                height: 1px;
                background: #0d57bc;
            }
        }
        .tip {
            font-size: 20px;
            height: 60px;
            line-height: 160%;
            text-align: center;
            letter-spacing: -0.03em;
            color: #717171;
        }
        &:last-child {
            margin-right: -21px;
        }
    }
}
.us-container {
    width: 100%;
}
.us-container-t {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 76px 0 72px;
    .title {
        font-weight: 500;
        font-size: 48px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #000;
    }
    .tips {
        width: 898px;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.03em;
        color: #333333;
        margin: 32px 0;
    }
    .tips-box {
        display: flex;
        .tips-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 380px;
            height: 304px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 24px;
            .tips-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
                text-align: center;
                letter-spacing: -0.03em;
                color: #000;
                margin: 20px 0 16px;
            }
            .tips-content {
                width: 298px;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: rgba(0, 0, 0, 0.8);
            }
            &:nth-child(2) {
                margin: 0 16px;
            }
        }
    }
    &.us-container-b {
        background: #fbfbfb;
        padding: 96px 0 60px;
        .tips {
            margin: 32px 0 36px;
        }
        .tips-box {
            display: flex;
            .tips-item {
                height: 232px;
            }
        }
    }
}
.us-article-box {
    .us-article-list {
        display: flex;
        align-items: center;
        justify-content: center;
        .us-article-list-l {
            margin-right: 176px;
        }
        &:nth-child(odd) {
            background: #fbfbfb;
        }
    }
}
.us-article-inner {
    min-width: 470px;
    padding: 100px 0;
    .title {
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        color: #000;
        margin-bottom: 26px;
    }
    .us-ul {
        margin-bottom: 34px;
        li {
            font-size: 20px;
            line-height: 20px;
            color: #000;
            margin-bottom: 20px;
            padding-left: 30px;
            background: url(../../assets/img/Vectorus.png) center left no-repeat;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .us-article-btn {
        min-width: 199px;
        height: 56px;
        border: 1.4px solid #0d57bc;
        border-radius: 55px;
        background: transparent;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: #0d57bc;
        cursor: pointer;
    }
}
.us-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(13, 87, 188, 0.06);
    border-radius: 2px;
    .us-contact-title {
        margin: 46px 0 15px;
    }
    .d-line {
        width: 64px;
        height: 2px;
        background: #0d57bc;
        margin-bottom: 50px;
    }
    .us-contact-item-box {
        display: flex;
        justify-content: center;
        .us-contact-item {
            display: flex;
            width: 414px;
            // height: 243px;
            height: 140px;
            padding: 34px 24px 28px;
            margin-right: 40px;
            margin-bottom: 68px;
            background: #fff;
            border: 1px solid #f2f2f2;
            border-radius: 2px;
            .photo-info-tip {
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 0.04em;
                color: #333333;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.photo-info {
    margin-left: 27px;

    .photo-info-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.06em;
        color: #000;
    }
    .line {
        width: 100%;
        height: 1px;
        background: #f2f2f2;
        margin: 18px 0 20px;
    }
}
@media screen and (max-width: 1440px) {
    .us-banner {
        .us-banner-l {
            margin-right: -51px;
        }
        .us-banner-r {
            margin-right: -390px;
        }
    }
}
</style>
