<template>
  <div class="comparative-analysis-list">
    <el-card :body-style="{ padding: '0 30px 28px' }">
      <div slot="header" class="clearfix">
        <span>企业对比分析</span>
      </div>
      <div v-if="selectedEnterprise.length" class="selected-enterprise">
        <span class="title">已选企业</span>
        <span v-for="(item, index) in selectedEnterprise" :key="index">
          <el-button class="selected-btn" round>
            {{ findlabelByIds(item, enterpriseOption) }}
            <i class="el-icon-close" @click="delSelected(index)"></i>
          </el-button>
        </span>
      </div>
      <div class="enterprise-Select">
        <span class="filter-name">企业类型</span>
        <div class="type-btn">
          <div>
            <span v-for="(value, index) in buttons" :key="index">
              <el-button
                v-if="
                  changeBtnSelect(value.type) ||
                    (value.type === '整车企业' && selectedCarType)
                "
                :key="value.type"
                :type="buttonType(value)"
                round
                @click="selected(value.type)"
              >
                {{ value.label }}
              </el-button>
              <el-button v-else type="text" @click="selected(value.type)">
                {{ value.label }}
              </el-button>
            </span>
          </div>
          <div
            v-show="
              ['整车企业', '乘用车', '商用车'].includes(selectedEnterpriseType)
            "
          >
            <span
              class="type-btn"
              v-for="(value, index) in towSelect"
              :key="index"
            >
              <el-button
                v-if="
                  changeBtnSelect(value.type) ||
                    (value.type === '整车企业' && selectedCarType)
                "
                :key="value.type"
                :type="buttonType(value)"
                round
                @click="selected(value.type)"
              >
                {{ value.label }}
              </el-button>
              <el-button v-else type="text" @click="selected(value.type)">
                {{ value.label }}
              </el-button>
            </span>
          </div>
        </div>
      </div>
      <div class="enterprise-name">
        <span class="filter-name">企业名称</span>
        <SearchSelect
          ref="enterpriseSelect"
          placeholder="请选择企业名称"
          :filterable="true"
          :multiple="true"
          :collapseTags="false"
          :multipleLimit="0"
          v-model="selectedEnterprise"
          :options="enterpriseOption"
          promptText="最多支持3家同时对比"
        />
      </div>
      <div class="contrast-dimensions">
        <div style="padding-top:16px" class="filter-name">对比维度</div>
        <el-checkbox-group class="select-btn" v-model="checkedList">
          <el-checkbox-button label="全部" @change="checkedAll" />
          <el-checkbox-button
            v-for="city in compareOptions"
            :label="city"
            :key="city"
            :name="city"
            @change="deselectAll"
          >
            {{ city }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>

      <div class="submit-box">
        <el-tooltip
          v-if="disabledType"
          :content="
            selectedEnterprise.length === 0
              ? '请选择对比企业'
              : '一次至少选择2家企业'
          "
          placement="top"
        >
          <el-button
            type="info"
            :style="{
              width: 'calc(100% - 54px)',
              height: '53px',
              fontSize: '14px',
              color: '#999',
              backgroundColor: '#f3f3f3',
              border: 0
            }"
            :class="[{ submit: !disabledType }]"
            plain
            :disabled="disabledType"
          >
            <span :style="{ lineHeight: '36px', marginRight: '18px' }">
              一键生成对比报告
            </span>
            <i
              class="icon-xiangyoujiaohuan iconfont"
              :style="{ fontSize: '36px' }"
            ></i>
          </el-button>
        </el-tooltip>
        <el-button
          v-else
          type="info"
          :style="{
            width: 'calc(100% - 54px)',
            height: '53px',
            fontSize: '14px',
            color: '#999',
            backgroundColor: '#f3f3f3',
            border: 0
          }"
          :class="[{ submit: !disabledType }]"
          plain
          :disabled="disabledType"
          @click="toHref('/workInformation/enterpriseInfo/compared', '_self')"
        >
          <span :style="{ lineHeight: '36px', marginRight: '18px' }">
            一键生成对比报告
          </span>
          <i
            class="icon-xiangyoujiaohuan iconfont"
            :style="{ fontSize: '36px' }"
          ></i>
        </el-button>

        <el-button class="reset" type="text" @click="resetData">重置</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from "@/utils/auth.js";
import { getEnterpriseSelectionsList } from "@/api/enterpriseAnalysis.js";
import { buryPoint } from "@/api/common";

import SearchSelect from "@/components/searchSelect";
export default {
  components: {
    SearchSelect
  },
  data() {
    return {
      selectedEnterpriseType: "整车企业",
      selectedCarType: true,
      isCheckedAll: true,
      disabledType: false,
      enterpriseData: {},
      compareOptions: [],
      selectedEnterprise: [],
      checkedList: ["全部"],
      buttons: [
        { type: "整车企业", label: "整车企业" },
        { type: "零部件企业", label: "零部件企业" }
      ],
      towSelect: [
        { type: "乘用车", label: "乘用车" },
        { type: "商用车", label: "商用车" }
      ],
      completeVehicle: [
        "基本信息",
        "产销量",
        "企业产能",
        "配套关系",
        "主要车型",
        "经营数据"
        // "资产结构"
      ],
      parts: ["基本信息", "主要产品", "配套关系", "业绩情况", "公司历程"],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      }
    };
  },
  methods: {
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(routeUrl, openWay) {
      const routeUrlQuery = {
        path: routeUrl,
        query: {
          selectedEnterpriseType: this.selectedEnterpriseType,
          selectedCarType: this.selectedCarType,
          checkedList: this.checkedList,
          selectedEnterprise: JSON.stringify(this.selectedEnterprise)
        }
      };
      const newUrl = this.$router.resolve(routeUrlQuery);
      if (getUserInfo()) {
        logRecords(
          "企业情报",
          "企业对比分析",
          "3",
          "",
          this.selectedEnterprise.join(", "),
          "",
          "",
          "",
          "一键生成对比报告"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, openWay);
          }
        } else {
          window.open(newUrl.href, openWay);
        }
      } else {
        this.showLogin();
      }
    },
    delSelected(index) {
      this.selectedEnterprise.splice(index, 1);
    },
    findlabelByIds(id, userArray) {
      const user = userArray.find(user => user.value.toString() === id);
      return user ? user.label : null;
    },
    resetData() {
      this.selectedEnterpriseType = "整车企业";
      this.selectedCarType = true;
      this.isCheckedAll = true;
      this.disabledType = false;
      this.selectedEnterprise = [];
      this.checkedList = ["全部"];
    },
    modifyObjectArray(array, keyMap) {
      if (!keyMap || typeof keyMap !== "object") {
        return [];
      }
      // 通用的函数来修改对象数组的键名
      return array.map(item => {
        const modifiedItem = {};
        for (const oldKey in item) {
          if (keyMap.hasOwnProperty(oldKey)) {
            modifiedItem[keyMap[oldKey]] = item[oldKey];
          } else {
            modifiedItem[oldKey] = item[oldKey];
          }
        }
        return modifiedItem;
      });
    },
    getEnterpriseSelectionsList() {
      getEnterpriseSelectionsList().then(res => {
        if (res.ok) {
          // 新的键名映射
          const newKeyMap = {
            enterpriseId: "value",
            enterpriseType: "type",
            enterpriseName: "label",
            enterpriseLogoUrl: "logoUrl"
          };
          this.enterpriseData = {
            commercialManufacturerList: this.modifyObjectArray(
              res.data.commercialManufacturerList,
              newKeyMap
            ),
            passengerManufacturerList: this.modifyObjectArray(
              res.data.passengerManufacturerList,
              newKeyMap
            ),
            partsMakerList: this.modifyObjectArray(
              res.data.partsMakerList,
              newKeyMap
            )
          };
        }
      });
    },
    checkedAll() {
      this.isCheckedAll = !this.isCheckedAll;
    },
    deselectAll() {
      this.isCheckedAll = false;
    },
    selected(type) {
      if (
        type === "零部件企业" ||
        this.selectedEnterpriseType === "零部件企业"
      ) {
        this.isCheckedAll = true;
      }
      this.selectedEnterpriseType = type;
      this.selectedCarType = "乘用车".includes(type) || "商用车".includes(type);
      this.$bus.$emit('valueChangecard', type);
    },
    buttonType(val) {
      if (val.type === "乘用车" || val.type === "商用车") {
        return "text";
      }
      return "整车企业".includes(val.type) || "零部件企业".includes(val.type)
        ? "primary"
        : "text";
    },
    changeBtnSelect(val) {
      return val.includes(this.selectedEnterpriseType);
    }
  },
  computed: {
    enterpriseOption() {
      this.selectedEnterprise = [];
      let selectOptinData = [];
      const passengerManufacturerList = Array.isArray(
        this.enterpriseData.passengerManufacturerList
      )
        ? this.enterpriseData.passengerManufacturerList
        : [];
      const commercialManufacturerList = Array.isArray(
        this.enterpriseData.commercialManufacturerList
      )
        ? this.enterpriseData.commercialManufacturerList
        : [];
      const partsMakerList = Array.isArray(this.enterpriseData.partsMakerList)
        ? this.enterpriseData.partsMakerList
        : [];

      switch (this.selectedEnterpriseType) {
        case "乘用车":
          selectOptinData = passengerManufacturerList;
          break;
        case "商用车":
          selectOptinData = commercialManufacturerList;
          break;
        case "整车企业":
          selectOptinData = Array.from(passengerManufacturerList).concat(
            commercialManufacturerList
          );
          break;

        default:
          selectOptinData = partsMakerList;
          break;
      }
      return selectOptinData;
    }
  },
  watch: {
    isCheckedAll(newVal) {
      if (newVal) {
        this.checkedList = ["全部"];
      } else {
        this.checkedList = this.checkedList.filter(item => item !== "全部");
      }
    },
    selectedEnterprise: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.disabledType = newVal.length > 1 ? false : true;
        if (newVal.length > 3) {
          this.selectedEnterprise = newVal.slice(0, 3);
          this.$refs.enterpriseSelect.triggerChildBlur();
          this.$alert("一次最多仅支持选择3家企业", "", {
            confirmButtonText: "确定"
          });
        }
      }
    },
    checkedList: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal.length) {
          this.isCheckedAll = true;
        }
      }
    },
    selectedEnterpriseType: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.compareOptions =
          newVal === "零部件企业" ? this.parts : this.completeVehicle;
      }
    }
  },
  mounted() {
    this.$bus.$on("valueChangelist", data => {
      this.selectedEnterpriseType = data;
      this.isCheckedAll = true;
      if (data === "整车企业") {
        this.selectedCarType = true;
      } else {
        this.selectedCarType = false;
      }
    });
    this.$bus.$on("valueChangelisttype", data => {
      this.selectedCarType = true;
      if (data === "乘用车企业") {
        this.selectedEnterpriseType = "乘用车";
      } else {
        this.selectedEnterpriseType = "商用车";
      }
    });
    this.getEnterpriseSelectionsList();
  },
  beforeDestroy() {
    this.$bus.$off("valueChangelist");
  }
};
</script>

<style lang="scss">
.comparative-analysis-list {

  .el-button {
    padding: 8px 14px;
    &.el-button--text {
      color: #333;
      &.is-round {
        color: #3f76ea;
      }
    }
  }
  .selected-btn {
    padding: 8px 10px;
    border: solid 1px #3f76ea;
    margin-right: 10px;
    span {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #3f76ea;
      i {
        font-size: 12px;
        color: #3f76ea;
      }
    }
  }
  .submit-box {
    margin-top: 10px;
    .submit {
      background-color: #3f76ea !important;
      color: #fefefe !important;
    }
    .reset {
      margin:0 !important;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #999 !important;
    }
    .el-button--info > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .el-checkbox-button__inner {
    padding: 8px 14px;
    border-radius: 20px !important;
    border: 0 !important;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: #3f76ea;
  }
  .rounnd-select.is-round,
  .text-select.el-button--text {
    padding: 8px 14px !important;
    margin: 0;
  }
  .el-card__header {
    border: none;
    padding: 30px 22px 20px;
  }
}
</style>
<style lang="scss" scoped>
.selected-enterprise {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #f3f3f3;
  .title {
    margin-right: 20px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #333;
  }
}

.tabs {
  ::v-deep {
    .el-tabs__header {
      margin-bottom: 0;
    }

    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__active-bar {
      width: 0 !important;
    }

    .el-tabs__item {
      margin-right: 22px;
      padding: 0;
      font-size: 16px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #111;
        font-weight: bold;

        &::after {
          height: 4px;
          border-radius: 2px;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 20px;
          background-color: #3f76ea;
        }
      }
    }
  }
}

.enterprise-name {
  display: flex;
  margin: 13px 0 0 0;

  .el-select {
    flex: 1;
  }
}

.contrast-dimensions {
  display: flex;
  align-items:baseline;
}

.enterprise-Select {
  min-height: 64px;
  display: flex;

  .select-btn {
    display: flex;
    flex-wrap: wrap;
  }

  .type-btn {
    span {
      width: 35%;
    }
  }
}

.filter-name {
  font-family: MicrosoftYaHei;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  padding-right: 20px;
  padding-top: 8px;
  color: #999;
}

.el-checkbox-group {
  flex: 1;

  .el-checkbox-button {
    margin-top: 10px;
  }
}

.clearfix {
  span {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
  }
}
</style>
<style src="../components/iconfont/iconfont.css"></style>
