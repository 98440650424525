<template>
  <div class="main">
    <div class="box">
      <div class="img">
        <img src="@/assets/img/newData/new-menu-top.png" />
        <div class="text">
          <h1>专业汽车数据库</h1>
          <p><span class="blueNumber">3</span>大功能<span class="orangeNumber">N</span>大主题数据</p>
        </div>
      </div>
      <el-scrollbar ref="scrollbarRef">
        <el-menu ref="menuRef" :default-active="activeMenuPath" unique-opened :default-openeds="defaultOpeneds"
          @select="handleSelectMenu">
          <MenuItem :menu="dataReportRoutes" />
          <MenuItem id="data-query-menus" :menu="dataQueryRoutes" />
          <MenuItem id="data-download-menus" :menu="dataDownloadRoutes" />
        </el-menu>
      </el-scrollbar>
    </div>

    <div class="menu-toggle-trigger" @click="handleMenuToggle">
      <div class="trigger">
        <i :class="['el-icon-caret-left', settings.sider.collapsed ? 'collapse' : '']" />
      </div>
    </div>

    <DriverDialog :visible="showDriverDialog" @close="handleCloseDriverDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { driver } from 'driver.js'
import { getUserInfo } from '@/utils/auth.js'
import {
  guideRecordCheck,
  markGuideRecord
} from '@/api/data_new.js'

import MenuItem from '../newDataLayout/comp/menuItem'
import DriverDialog from '../newDataLayout/comp/driverDialog'

export default {
  name: 'DataSider',
  components: {
    MenuItem,
    DriverDialog,
  },
  data() {
    return {
      hideTimeout: undefined,
      selectedOtherMenu: false,
      showDriverDialog: false,
      // 是否正在进行新人引导
      isDriving: false,
    }
  },
  computed: {
    ...mapGetters({
      permission_routes: 'permission_routes',
      settings: 'settings',
    }),
    // 数据栏目下的所有子菜单
    dataChildrenRoutes() {
      return this.permission_routes.find(item => item.name === '数据')?.children || []
    },
    defaultOpeneds() {
      if (this.selectedOtherMenu || this.isDriving) {
        return []
      }
      return [this.dataReportRoutes[0].path, this.dataQueryRoutes[0]?.path]
    },
    // 获取 数据报告 的所有路由
    dataReportRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据报告')
    },
    // 获取 数据查询 的所有路由
    dataQueryRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据查询')
    },
    // 获取 数据表下载 的路由
    dataDownloadRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据表下载')
    },
    activeMenuPath() {
      // name 有重复的，比如数据栏目下的国内产销数据和新能源专区都有 乘用车-批发量和零售量
      // 所以这里用 path
      return this.$route.path
    }
  },
  created() {
    const userInfo = getUserInfo()
    if (userInfo?.userId) {
      guideRecordCheck({ userId: userInfo.userId })
        .then(res => {
          if (res && res.data) {
            this.showDriverDialog = !res.data.guideStatus
          }
        })
    }
  },
  methods: {
    ...mapActions({
      toggleSider: 'settings/toggleSider',
    }),
    openActiveMenu() {
      const path = this.findActiveMenu()
      if (path) {
        this.$refs.menuRef.open(path)
      }
    },
    scrollToTop() {
      this.$refs.scrollbarRef.wrap.scrollTo(0, 0);
      this.$refs.scrollbarRef.$data.moveX = 0;
      this.$refs.scrollbarRef.$data.moveY = 0;
    },
    closeActiveMenu() {
      const path = this.findActiveMenu()
      if (path) {
        this.$refs.menuRef.close(path)
        this.scrollToTop();
      }
    },
    // 找到当前激活的子级菜单的父级菜单的 path
    findActiveMenu() {
      for (let i = 0; i < this.dataChildrenRoutes.length; i++) {
        const subMenu = this.dataChildrenRoutes[i]
        for (let j = 0; j < subMenu.children.length; j++) {
          const menu = subMenu.children[j]
          if (menu.path === this.activeMenuPath) {
            return subMenu.path
          }
        }
      }
    },
    // 左侧菜单的 select 事件
    handleSelectMenu() {
      this.selectedOtherMenu = true
      this.$store.dispatch('notice/SET_PC', 0)
      this.$store.dispatch('notice/SET_CPLB', '')
      this.$store.dispatch('common/SET_qiehuan', '')
    },
    // 切换菜单折叠收起
    handleMenuToggle() {
      this.toggleSider()
    },
    handleCloseDriverDialog() {
      this.showDriverDialog = false
      this.isDriving = true
      // 在新人引导之前, 关闭当前激活的菜单, 以防 heightLight 和 popover 的位置不准确
      this.closeActiveMenu()
      // 最好还是等菜单收起来之后, 再进行引导
      setTimeout(() => {
        const driverObj = driver({
          showProgress: true,
          allowClose: false,
          // 禁止交互
          disableActiveInteraction: true,
          popoverClass: 'driver-popover',
          prevBtnText: '上一步',
          nextBtnText: '下一步',
          doneBtnText: '知道了',
          progressText: '{{current}} / {{total}}',
          steps: [
            {
              element: '#data-query-menus',
              popover: { title: '数据查询', description: '可选择不同数据内容，进行查询' }
            },
            {
              element: '#data-download-menus',
              popover: { title: '数据表下载', description: '可选择数据表下载' }
            },
            {
              element: '#tableDownload',
              popover: { title: '数据表下载', description: '可选择数据表下载' }
            }
          ],
          // 新人引导结束后,重新打开当前激活的菜单
          onDestroyed: () => {
            this.isDriving = false
            this.openActiveMenu()
            const userInfo = getUserInfo()
            if (userInfo?.userId) {
              markGuideRecord({ userId: userInfo.userId })
                .then(() => {})
                .catch(() => {})
            }
          }
        })
        driverObj.drive()
      }, 300)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";

.main {
  .img {
    padding-top: 6px;
    width: 100%;
    height: 136px;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #f3f5f7;
    img{
      height: 100%;
      object-fit: cover;
    }
    .text {
      padding:33px 0 0 10px;
      color: #999999;
      h1 {
        font-size: 22px;
        color: rgb(34, 24, 21);
        font-weight: bold;
        text-transform: uppercase;
      }
      .blueNumber {
        font-size: 18px;
        color: #3f76ea;
        font-weight: bold;
      }
      .orangeNumber {
        padding-left: 10px;
        color: #ff6913;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .box {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    min-height: 0;
    max-height: 100%;
    position: relative;
    overflow: hidden;
  }
}

.menu-toggle-trigger {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);

  .trigger {
    width: 12px;
    height: 68px;
    cursor: pointer;
    background-color: #0D57BC;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;

    &::before, &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid #F6F6F6;
      border-bottom: 12px solid transparent;
      border-top: 12px solid transparent;
    }

    &::before {
      top: -12px;
    }

    &::after {
      bottom: -12px;
    }

    .el-icon-caret-left {
      transition: transform .3s;
    }

    .collapse {
      transform: rotateY(180deg);
    }
  }
}
</style>
