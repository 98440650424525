//泰达论坛
import $http from '@/plugins/http.js'


//根据id查询
export function ttTaidaiBbsById(params) {
    return $http.get('/homepage/ttTaidaiBbs/'+params)
}
//根据条件查询分页
export function ttTaidaiBbsPage(params) {
    return $http.get('/homepage/ttTaidaiBbs/page',params)
}
