<template>
  <div class="fab-container">
    <el-popover
      :class="[{ visible: contact }]"
      placement="left"
      trigger="hover"
      popper-class="fab-contact-popover"
    >
      <div class="contact-container">
        <span style="color: #0f0f0f;">欢迎联系我们！<br/>我们为您提供最全面、权威、专业的服务</span>
        <div style="padding: 10px;color: #0f0f0f;">
          <span>市场部-李经理</span>
          <br/>
          <img src="@/assets/img/dh.png" />
          <span style="color: #ff6a00;">022-84370000-分机1582/18322511031</span>
          <br/>
          <img src="@/assets/img/yj.png" />
          <span>liwenjuan@catarc.ac.cn</span>
        </div>
      </div>

      <span slot="reference">
        <img class="icon" src="@/assets/images/phone.png" />
        <span>联系我们</span>
      </span>
    </el-popover>

    <Investigate :class="[{ visible: investigate }]" />

    <span
      v-if="enabledAI"
      :class="['ai-service', { visible: service }]"
      @click="handleOpen"
    >
      <img class="icon" src="@/assets/service-gray.svg" />
      <span>在线问答</span>
    </span>

    <span
      :class="['back-top', { visible: backtop }]"
      v-scroll-to="{
        el: 'body',
        duration: 100,
        lazy: false,
        easing: 'easing',
        force: true,
        cancelable: true,
        x: false,
        y: true,
      }"
    >
      <i class="icon el-icon-download" />
      <span>返回顶部</span>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserInfo } from '@/utils/auth.js';

import Investigate from './Investigate';

export default {
  props: {
    contact: {
      required: false,
      type: Boolean,
      default: false,
    },
    investigate: {
      required: false,
      type: Boolean,
      default: false,
    },
    service: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    Investigate,
  },
  data() {
    return {
      backtop: false,
      userInfo: {},
    };
  },
  created() {
    const userInfo = getUserInfo();

    if (userInfo) {
      this.userInfo = { ...userInfo };
    }
  },
  computed: {
    // 付费用户 和 内部用户可使用 AI
    enabledAI() {
      const { userId, roleKey } = this.userInfo;
      return userId && roleKey !== 'trialrole';
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scroll);
  },
  beforeDestory() {
    document.removeEventListener('scroll', this.scroll);
  },
  methods: {
    ...mapActions({
      openAIService: 'AIService/openAIService',
    }),
    scroll() {
      const scrollTop = document.documentElement.scrollTop ?? document.body.scrollTop;
      this.backtop = scrollTop > 100;
    },
    handleOpen() {
      this.openAIService();
    },
  },
};
</script>

<style lang="scss" scoped>
.fab-container {
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: auto;
  color: #999;
  font-size: 12px;
  line-height: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  cursor: default;
  pointer-events: none;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 6px;
  }

  ::v-deep > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 60px;
    pointer-events: auto;
    .icon {
      margin-bottom: 8px;
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
    [class*="el-icon"] {
      font-size: 18px;
    }

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 1px solid rgb(235, 235, 235);
    }

    &.ai-service {
      cursor: pointer;
    }

    &.back-top {
      color: #fff;
      background-color: rgb(63, 118, 234);
      cursor: pointer;
      [class*="el-icon"] {
        transform: rotate(180deg);
      }
    }

    .el-popover__reference-wrapper {
      width: 100%;
      height: 100%;
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    &:not(.visible) {
      position: absolute;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: all .2s ease;
      transform: translateY(10px);
    }
    &.visible {
      position: relative;
      z-index: 3;
      opacity: 1;
      pointer-events: auto;
      transition: all .2s ease;
      transform: none;
    }
  }
}
</style>

<style lang="scss">
.el-popover.fab-contact-popover {
  transform: translateX(-10px);
  .contact-container {
    img {
      width: 15px;
    }
  }
}
</style>
