import Vlogin from './src/main4xcx'
import Vue from 'vue'
let VloginConstructor = Vue.extend(Vlogin)
Vue.component(Vlogin.name,Vlogin)
const install = (o) => {
    const instance = new VloginConstructor({
        el: document.createElement('div')
    })
    document.body.appendChild(instance.$el);
    instance.flag = o.flag;
}
export default install;