<template>
  <div v-if="!siderDisappeared" class="sider">
    <DataSider v-if="isNewDataRoute" />

    <Normal
      v-else
      :isData="isData"
      :isLogin="isLogin"
      :getIfCanViewNewVersion="getIfCanViewNewVersion"
      :childrenMenu="childrenMenu"
      :getrouterName="getrouterName"
      :activeMenuPath="activeMenuPath"
      @click="handleClickGoToNewData"
      @select="handleSelect"
      @ceshi="ceshi"
    />
  </div>
</template>

<script>
import Normal from './Normal';
import DataSider from './DataSider';

export default {
  name: 'Sider',
  props: {
    isDataRoute: {
      required: false,
      type: Boolean,
      default: false,
    },
    isNewDataRoute: {
      required: false,
      type: Boolean,
      default: false,
    },
    siderDisappeared: {
      required: false,
      type: Boolean,
      default: false,
    },
    isData: {
      required: false,
      type: Boolean,
      default: false,
    },
    isLogin: {
      required: false,
      type: [Boolean, Number],
      default: false,
    },
    getIfCanViewNewVersion: {
      required: false,
      type: Boolean,
      default: false,
    },
    childrenMenu: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    getrouterName: {
      required: false,
      type: String,
      default: undefined,
    },
    activeMenuPath: {
      required: false,
      type: String,
      default: undefined,
    },
  },
  components: {
    Normal,
    DataSider,
  },
  methods: {
    handleClickGoToNewData(data) {
      this.$emit('click', data);
    },
    handleSelect(data) {
      this.$emit('select', data);
    },
    ceshi(data) {
      this.$emit('ceshi', data);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/newDataVariables.scss';

.sider {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: $sider-width-expanded;
  background-color: #fff;
  box-shadow:
    2px 1px 1px rgba(33,56,90,.01),
    2px 6px 6px rgba(33,56,90,.01),
    2px 8px 8px rgba(33,56,90,.11);
  transition: width $animation-sider-expanded;

  .main {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    max-height: 100%;
  }
  ::v-deep .el-scrollbar {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  ::v-deep .el-menu {
    .el-menu-item,
    .el-submenu__title {
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss">
@import '~@/styles/newDataVariables.scss';

.fixed-sider {
  .sider {
    position: fixed;
    z-index: $sider-zindex;
    top: 0;
    left: 0;
    bottom: 0;
  }
  &.sider-large {
    .sider {
      width: $sider-width-expanded-large;
    }
  }
  &.fixed-top-nav {
    .sider {
      top: $top-nav-height;
    }
  }
  &.fixed-header {
    .sider {
      top: $header-height;
    }
  }
  &.fixed-top-nav.fixed-header {
    .sider {
      top: $top-nav-height + $header-height;
    }
  }
}

.sider-collapsed {
  .sider {
    width: $sider-width-collapsed;
    transition: width $animation-sider-collapsed;
  }
}
</style>
