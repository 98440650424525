<template>
  <div class="data-container">
    <div class="items">
      <div class="item">
        <div class="lable">{{ data.overallSalesKey }}整体产量</div>
        <div class="value">{{ data.overallProductionTotalValue }}<span class="unit">辆</span></div>
        <div class="group">
          <DataCell
            text="同比"
            :value="data.overallProductionTotalYOY"
          />
          <DataCell
            text="环比"
            :value="data.overallProductionTotalQOQ"
          />
        </div>
      </div>
      <div class="item">
        <div class="lable">{{ data.overallSalesKey }}整体销量</div>
        <div class="value">{{ data.overallSalesTotalValue }}<span class="unit">辆</span></div>
        <div class="group">
          <DataCell
            text="同比"
            :value="data.overallSalesTotalYOY"
          />
          <DataCell
            text="环比"
            :value="data.overallSalesTotalQOQ"
          />
        </div>
      </div>
    </div>

    <div class="chart-container">
      <span class="chart-title">最近 1 年整体乘商产销趋势（单位：万辆）</span>
      <div class="chart-box">
        <div ref="chartRef" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as echarts from 'echarts';

import DataCell from './DataCell';

export default {
  props: {
    reportData: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    logRecords: {
      required: false,
      type: Function,
      default: () => () => null,
    },
  },
  components: {
    DataCell,
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    data() {
      const result = {};
      const data = this.reportData || {};

      // 日期
      // overallSalesKey
      // overallSalesKeyList

      // 整体产量
      // overallProductionTotalValue
      // overallProductionTotalValueList

      // 整体销量
      // overallSalesTotalValue
      // overallSalesTotalValueList

      // 整体产量同比
      // overallProductionTotalYOY

      // 整体产量环比
      // overallProductionTotalQOQ

      // 整体销量同比
      // overallSalesTotalYOY

      // 整体销量环比
      // overallSalesTotalQOQ

      ([
        'overallProductionTotalValue',
        'overallSalesTotalValue',
        'overallProductionTotalYOY',
        'overallProductionTotalQOQ',
        'overallSalesTotalYOY',
        'overallSalesTotalQOQ',
      ]).forEach((key) => {
        result[key] = this.toNumber(data[key]);
      });
      ([
        'overallProductionTotalValue',
        'overallSalesTotalValue',
      ]).forEach((key) => {
        result[key] = this.toLocaleString(result[key]);
      });

      result.overallSalesKey = moment(data.overallSalesKey).format('YYYY年MM月');
      result.overallSalesKeyList = this.getArray(data.overallSalesKeyList).map((date) => moment(date).format('YYYY.MM'));
      result.overallProductionTotalValueList = this.getArray(data.overallProductionTotalValueList).map((d) => this.toNumber(d));
      result.overallSalesTotalValueList = this.getArray(data.overallSalesTotalValueList).map((d) => this.toNumber(d));

      return result;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(data) {
        this.updateChart({ chart: this.chart, data });
      },
    },
    chart: {
      deep: false,
      handler(chart) {
        this.updateChart({ chart, data: this.data });
      },
    },
  },
  mounted() {
    this.initChart();
  },
  beforeDestory() {
    if (this.chart) {
      window.removeEventListener('resize', this.chart.resize, false);
    }
  },
  methods: {
    toNumber(d) {
      const num = Number(d);
      return Number.isFinite(num) ? num : 0;
    },
    toLocaleString(num) {
      return num.toLocaleString('zh-Hans-CN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    getArray(arr) {
      return (arr || []).slice(0, 12).reverse();
    },

    initChart() {
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let tooltipContent = '';
            params.forEach(function (item) {
              tooltipContent += item.marker + item.seriesName + ': ' + (item.value / 10000).toFixed(1);
            });
            return tooltipContent;
          },
        },
        legend: {
          top: '14px',
          right: '6px',
          lineStyle: {
            type: [4, 12],
            dashOffset: 4,
          },
        },
        grid: {
          left: '12px',
          right: '12px',
          bottom: '10px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return (value / 10000).toFixed(1);
            }
          }
        },
        series: [
          {
            name: '整体产量',
            type: 'line',
            symbol: 'none',
            lineStyle: {
              color: '#0069ea',
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(203,223,248, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(203,223,248, 0)',
                },
              ]),
            },
            smooth: true,
            data: [],
          },
          {
            name: '整体销量',
            type: 'line',
            symbol: 'none',
            lineStyle: {
              color: '#35d588',
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(195,240,218, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(195,240,218, 0)',
                },
              ]),
            },
            smooth: true,
            data: [],
          },
        ],
      };

      const chart = echarts.init(this.$refs.chartRef, {
        width: 'auto',
        height: 'auto',
      });

      chart.setOption(option);

      this.chart = chart;
      window.addEventListener('resize', this.chart.resize, false);
    },
    updateChart({ chart, data }) {
      if (!chart || !data || !data.overallSalesKeyList) {
        return;
      }

      chart.setOption({
        xAxis: { data: data.overallSalesKeyList },
        series: [
          { data: data.overallProductionTotalValueList },
          { data: data.overallSalesTotalValueList },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.data-container {
  margin-bottom: 40px;
}
.items {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    width: calc(50% - 6px);
    line-height: 1;
    background-color: #f1f9ff;
    &:nth-of-type(even) {
      background-color: #eaf9ef;
    }
    @media screen and (min-width: 1340px) {
      padding: 14px;
    }
    @media screen and (min-width: 1440px) {
      padding: 14px 16px;
    }
    @media screen and (min-width: 1480px) {
      padding: 14px 18px;
    }

    .lable {
      color: #333;
    }
    .value {
      margin: 12px 0;
      color: #222;
      font-size: 24px;
      font-weight: bold;
      .unit {
        margin-left: 2px;
        color: #999;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .group {
      color: #999;
      font-size: 12px;
      ::v-deep .cell {
        display: inline-flex;
        align-items: center;
        &:not(:first-child) {
          margin-left: 4px;
          @media screen and (min-width: 1340px) {
            margin-left: 8px;
          }
          @media screen and (min-width: 1360px) {
            margin-left: 10px;
          }
          @media screen and (min-width: 1380px) {
            margin-left: 14px;
          }
          @media screen and (min-width: 1400px) {
            margin-left: 18px;
          }
          @media screen and (min-width: 1440px) {
            margin-left: 20px;
          }
          @media screen and (min-width: 1460px) {
            margin-left: 22px;
          }
          @media screen and (min-width: 1480px) {
            margin-left: 24px;
          }
        }

        .icon {
          margin-left: 5px;
        }
        .icon {
          margin-right: 1px;
          width: 12px;
          height: 12px;
        }

        &.up .number {
          color: #ff4848;
        }
        &.down .number {
          color: #33d587;
        }
      }
    }
  }
}
.chart-container {
  position: relative;
  padding-top: 46.1224%;

  .chart-title {
    position: absolute;
    z-index: 2;
    top: 13px;
    left: 10px;
    width: calc(100% - 186px);
    color: #333;
  }

  .chart-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fbfbfb;

    .chart {
      height: 100%;
    }
  }
}
</style>
