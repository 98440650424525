<!--
 * @Author: your name
 * @Date: 2021-07-23 09:01:17
 * @LastEditTime: 2021-07-23 15:43:14
 * @LastEditors: Please set LastEditors
 * @Description: 企业财报页面
 * @FilePath: /information-vue/code/src/views/workInformation/financialReport/index.vue
-->

<template>
  <PageContainer>
    <!--    <VnotLogin-->
    <!--      v-if="!show"-->
    <!--      title="企业情报"-->
    <!--      text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。"-->
    <!--      tableTitle="最新企业情报"-->
    <!--      :showTips="showTips"-->
    <!--    />-->
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs actionNavInformation="FinancialReport" />
      </div>
    </template>
    <div class="topRadio">
      <el-radio-group v-model="activeName" @change="handleClick" size="small">
        <el-radio-button label="整车企业" value="整车企业"></el-radio-button>
        <el-radio-button label="零部件企业" value="零部件企业"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="main-box">
      <div class="left-box">
        <div class="left-input">
          <el-input clearable placeholder="输入企业名称" size="small" v-model="enterpriseName" @input="searchInpt">
            <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="archiveReport"></i>
          </el-input>
        </div>
        <div class="left-list">
          <el-row :gutter="20" type="flex" style="align-items: flex-start;">
            <el-col :span="21">
              <div v-for="(item) in filterData" :key="item.firstChar">
                <div :id="index === 0 ? item.firstChar : ''" v-for="(citem, index) in item.org" flex="sb"
                  class="left-item" :style="citem.check ? 'display:none' : 'display:flex'" :key="citem.id"
                  @click="filterFun(citem.enterpriseName)">
                  <span style="color: #000000">{{
                    citem.enterpriseName
                  }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="index-list-bar" v-if="sortedIndexes.length">
                <span class="index-list-bar-item">选</span>
                <span v-for="(i, index) in sortedIndexes" :key="index" class="index-list-bar-item"
                  :class="{ active: i === selectIndex }" @click="scrollTo(i), i === selectIndex">
                  {{ i }}
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="right-box">
        <div class="dropdown-box">
          <el-radio-group v-model="yearRadio" @change="handleCommand" size="small">
            <el-radio-button label="全部年份">全部年份</el-radio-button>
            <el-radio-button v-for="item in year" :key="item" :label="item">{{ item }}</el-radio-button>
          </el-radio-group>
        </div>
        <div class="selectName" v-if="selectname">
          已选企业： <span class="textselectName">
            <el-radio-group size="small">
            <el-radio-button>{{ selectname }} <span @click="clearselect(selectname)"><i class="el-icon-close"></i>
              </span></el-radio-button>
          </el-radio-group>
          </span>
        </div>
        <div class="right-item" v-for="item in filterTableData" :key="item.id">
          <div style="
            margin: 10px;
            color: #0D57BC;
            font-size: 16px;
            font-weight: 600;
          ">
            {{ item.year }}
          </div>
          <div v-if="show && showW">
            <a flex="sb" v-for="(citem, index) in item.fileInfo" :key="index" @click="toHref(citem.fileUrl, citem)">
              <span class="text-overflow"> <img src="@/assets/img/fileicon.png" />{{ citem.fileName }}</span>
              <span style="color:#c8c8c8"> {{ citem.createTime }}</span>
            </a>
          </div>
          <div v-else>
            <a flex='sb' v-for="citem in item.fileInfo" :key="citem.fileUrl" @click="toHref(citem.fileUrl, citem)">
              <span class="text-overflow"><img src="@/assets/img/fileicon.png" />{{ citem.fileName }}</span>
              <span style="color:#c8c8c8"> {{ citem.createTime }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
import {
  archiveReport,
  queryGroupYears,
  queryFinancialReport
} from "@/api/enterpriseAnalysis.js";
import { buryPoint } from "@/api/common";
import actionTabs from "../components/actionTabs.vue"
export default {
  components: {
    // VnotLogin,
    actionTabs
  },
  data() {
    return {
      activeName: "整车企业",
      showTips: [
        {
          title: "企业情报动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "企业情报库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "企业情报梳理",
          text: "专家梳理整合，真正读懂企业情报脉络",
        },
        {
          title: "企业情报大数据",
          text: "用数据深度理解企业情报结构和趋势",
        },
      ],
      yearRadio: '全部年份',
      tableData: [],
      uniqueorg: [],
      selectIndex: "",
      filterTableData: [],
      show: false,
      showW: false,
      enterprisesData: {},
      list: [],
      enterpriseName: "",
      yearDate: "全部年份",
      year: [],
      filterData: [],
      timer: null,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "企业情报",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (menuName.includes('企业情报')) {
          this.showW = true;
        }
      } else {
        this.showW = true;

      }
    } else {
      this.show = false;
    }
    this.queryGroupYears();

    this.queryFinancialReport()
  },
  watch: {
    filterData: {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        const org = newVal?.map(item => item.org);
        this.uniqueorg = [].concat(...org);
      },
    },
  },
  computed: {
    selectname() {
      let orgname = false
      if ( this.uniqueorg.some(item => item.enterpriseName === this.enterpriseName) ) {
        orgname =  this.enterpriseName
      } else {
        orgname = false
      }
      return orgname
    },
    sortedIndexes() {
      const firstChars = this.filterData?.map(item => item.firstChar);
      const uniqueFirstChars = new Set(firstChars);
      const uniqueFirstCharsArray = Array.from(uniqueFirstChars);
      return uniqueFirstCharsArray.sort()
    },
  },
  methods: {
    scrollTo(index) {
      this.selectIndex = index;
      const element = document.getElementById(index);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 118; // 减去118px的偏移量

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    handleClick() {
      this.filterData = [];
      this.enterpriseName = "";
      this.yearDate = "全部年份";
      this.yearRadio = "全部年份";
      this.queryFinancialReport();
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    //跳转
    toHref(newUrl, item) {
      if (getUserInfo()) {

        logRecords('企业情报', '企业财报', '2', 'tt_enterprise_financial_report', '', item.fileName, '', '')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();

            } else {
              window.open("https://www.autoinfo.org.cn/" + newUrl, "_blank");

            }

          }
        } else {
          window.open("https://www.autoinfo.org.cn/" + newUrl, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //年份切换
    handleCommand(command) {
      this.yearDate = command;
      if (command == "全部年份") {
        this.filterTableData = this.tableData;
      } else {
        this.filterTableData = this.tableData?.filter((item) => {
          return item.year == command;
        });
      }
    },
    //根据年份获取数据
    archiveReport() {
      let data = {
        enterpriseName: this.enterpriseName,
        enterpriseType: this.activeName,
      };
      archiveReport(data).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
          this.filterTableData = res.data;
        }
      });
    },
    //获取所有年份
    queryGroupYears() {
      queryGroupYears().then((res) => {
        if (res.ok) {
          this.year = res.data;
        }
      });
    },
    clearselect(name) {
      this.yearDate = "全部年份";
      this.yearRadio = "全部年份";
      this.enterpriseName = '';
      this.queryFinancialReport()
    },
    //输入筛选
    searchInpt(e) {
      if (getUserInfo()) {

        logRecords('企业情报', '企业财报', '4', 'tt_enterprise_financial_report', '', '', '', this.enterpriseName)
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

        }
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.archiveReport()
        for (let i = 0; i < this.filterData.length; i++) {
          this.filterData[i].org.filter((item) => {
            if (
              item.enterpriseName.toLowerCase().indexOf(e.toLowerCase()) == -1
            ) {
              this.$set(item, "check", true);
            } else {
              this.$set(item, "check", false);
            }
          });
        }
      }, 500);
    },
    //侧边栏筛选
    filterFun(name) {
      this.yearDate = "全部年份";
      this.yearRadio = "全部年份";
      this.enterpriseName = name;
      this.archiveReport();
    },
    //企业财报初始化
    queryFinancialReport() {
      let data = {
        // enterpriseName: this.enterpriseName,
        enterpriseType: this.activeName,
      };
      queryFinancialReport(data).then(res => {
        this.list = res.data.map((item) => {
          item.org = JSON.parse(item.org);
          return item;
        });
        this.filterData = Object.assign([], this.list);
      })
      this.archiveReport();
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-tabs__nav {
    float: none;
    // margin: 0 10px;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-input__inner {
    background: #f3f3f3;
    border: 0;
  }

  .el-checkbox__input {
    display: none;
  }

  .right-box .el-radio-button {
    margin-right: 10px;

    .el-radio-button__inner {
      border-radius: 22px;
      padding: 8px 25px;
      color: #999999;
      border: 1px solid #d5d5d5;
    }
  }

  .right-box .is-active .el-radio-button__inner {
    border: 1px solid rgb(63, 118, 234);
    background: #fff;
    color: rgb(63, 118, 234);
    box-shadow: none
  }
}

.topRadio {
  padding-bottom: 20px;

  ::v-deep {
    .el-radio-button {
      border: 0;
      border-radius: 4px;
    }

    .el-radio-button__inner {
      border: 0;
      border-radius: 4px;
      color: #333333;
    }

    .el-radio-button:first-child {
      margin-right: 12px;
    }

    .is-active .el-radio-button__inner {
      background: #3f76ea;
      color: #fff;
    }
  }
}

.main-box {
  display: flex;

  .left-box {
    width: 300px;

    .left-input {
      padding: 20px;
      background: #ffffff;
    }

    ::v-deep .el-input__clear {
      position: absolute;
      right: 25px;
    }

    .left-list {
      padding: 0 24px 30px 23px;
      background: #ffffff;

      .left-item {
        padding: 10px 0;
        border-bottom: 1px solid #efefef;
        color: #d3d3d3;
        cursor: pointer;
      }
    }
  }

  .right-box {
    flex: 1;
    background: #ffffff;
    margin-left: 10px;
    padding-left: 25px;
    .selectName {
      padding-top: 22px;
      padding-left: 8px;
      .textselectName {
        padding: 0 5px;
      }
      i {
        cursor: pointer;
      }
    }
    .right-item {
      padding: 10px 10px 10px 0;

      a {
        color: #333;
        border-bottom: 1px solid #efefef;
        padding: 10px;
        overflow: hidden;
      }

      a:hover {
        color: #0D57BC;
      }

      .text-overflow {
        img {
          margin-right: 10px;
        }
      }

    }
  }
}

.box {
  //   padding: 0 10%;

  .box_box {
    padding: 10px;
    background: #ffffff;
  }

  .box_box_title {
    margin-top: 10px;
    background: #ffffff;

    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }

    .flex_col {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.dropdown-box {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #0D57BC;
}

.index-list-bar {
  position: relative;
  padding: 14px 8px 14px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.index-list-bar-item {
  display: inline-block;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  padding: 2px;
  color: #ccc;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
}

.index-list-bar-item:hover {
  color: #fff;
  background-image: linear-gradient(128deg,
      #76b3ff 0%,
      rgba(0, 105, 234, 1) 100%),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  border-radius: 4px;
}

.index-list-bar-item.active {
  color: #fff;
  background-image: linear-gradient(128deg,
      #76b3ff 0%,
      rgba(0, 105, 234, 1) 100%),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  border-radius: 4px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
