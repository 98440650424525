import $http from '@/plugins/http.js'
// 获取菜单
export function findMenu(params) {
  return $http.get('/lo/findMenu', params)
}
// 获取根级目录
export function selectFirstMenu() {
  return $http.get('/api/sys/menu/selectFirstMenu')
}

// 富奥获取菜单

export function fuAoFindMenu(params) {
  return $http.get('/lo/fuAoFindMenu', params)
}

// 获取数据栏目-数据查询的菜单
export function getDataQueryMenus(data) {
  return $http.post('/api/newData/searchData/getDataQueryMenus', data)
  // return $http.post('/getDataQueryMenus/copy', data)
}
