<!--视频列表-->
<template>
    <PageContainer breadcrumb>
        <div class="video-box">
            <h1 class="video-title">精彩视频</h1>
            <div class="video-top">
                <div class="video-l">
                    <video-player class="video-player" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>
                <ul class="video-r">
                    <li v-for="item in onlyThree" :key="item.id" @click="routerHref(item)">
                        <img class="video-r-img" :src="item.coverUrl" :alt="item.coverName" />
                        <h1 class="video-r-title">{{ item.title }}</h1>
                        <div class="make"></div>
                    </li>
                </ul>
            </div>
            <h1 class="video-title-h1">{{ data.title }}</h1>
            <p class="video-summary-p">
                {{ data.summary }}
            </p>
            <span class="video-time-span"> {{ data.publishDate }}</span>
            <!-- <h1 class="video-title-h1">视频解读：自动驾驶2022将迎拐点</h1>
			<p class="video-summary-p">
				本报告围绕大陆集团20本报告围绕大陆集团2020年财报信息,20年财报信息，对大陆集团五大业务板块发展情况展开详细分析。
			</p>
			<span class="video-time-span">2020-02-21</span> -->
        </div>
    </PageContainer>
</template>

<script>
import { getTopVideoList, ttVideoRecommendation } from "@/api/Index.js";
export default {
    data() {
        return {
            onlyOne: {},
            onlyThree: [],
            data: {},
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: "../../static/images/test.jpg", //你的封面地址
                // width: document.documentElement.clientWidth, //播放器宽度
                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true, //全屏按钮
                },
            },
        };
    },
    watch: {
        $route(to, from) {
            let id = to.params.id;
            this.getTopVideoList();
            this.ttVideoRecommendation(id);
        },
    },
    created() {
        this.getTopVideoList();
        let id = this.$route.params.id;
        this.ttVideoRecommendation(id);
    },
    methods: {
        //获取顶置
        getTopVideoList() {
            getTopVideoList().then((res) => {
                if (res.ok) {
                    let data = res.data;
                    let [onlyOne, ...onlyThree] = data;
                    this.onlyOne = onlyOne;
                    this.onlyThree = onlyThree;
                }
            });
        },
        //获取视频详情
        ttVideoRecommendation(params) {
            ttVideoRecommendation(params).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                    this.playerOptions.poster = res.data.coverUrl;
                    this.playerOptions.sources=[{
                        type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: res.data.videoUrl, //url地址
                    }];
                }
            });
        },
        //跳转
        routerHref(item) {
            this.$router.push("/index/detail/videoDetail/" + item.id);
            // this.playerOptions.sources = [];
            // this.playerOptions.poster = item.coverUrl;
            // this.playerOptions.sources.push({
            // 	type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // 	src: item.videoUrl, //url地址
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
    display: none;
}

::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
}

.video-container {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-y: auto;
}

.video-box {
    width: 76.667%;
    margin: 0 auto;
}

.video-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #333333;
    margin: 24px 0;
}

.video-top {
    display: flex;
    justify-content: space-between;
    height: 506px;

    .video-l {
        position: relative;
        width: 74.366%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;

        .video-l-img {
            width: 100%;
            height: 100%;
            background: linear-gradient(
                2.29deg,
                #000000 -3.29%,
                rgba(38, 38, 38, 0) 38.6%
            );
        }

        .video-l-title {
            position: absolute;
            left: 24px;
            bottom: 24px;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: #fff;
            z-index: 1;
        }
    }

    .video-r {
        width: 23.098%;
        height: 100%;

        li {
            position: relative;
            width: 100%;
            height: 160px;
            margin-bottom: 13px;
            border-radius: 4px;
            overflow: hidden;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .video-r-img {
            width: 100%;
            height: 100%;
        }

        .video-r-title {
            position: absolute;
            left: 16px;
            bottom: 16px;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #fff;
            z-index: 1;
        }
    }

    .video-l,
    .video-r {
        cursor: pointer;
    }
}

.make {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        2.29deg,
        #000000 -3.29%,
        rgba(38, 38, 38, 0) 38.6%
    );
}

.video-title-h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #333;
    margin-top: 24px;
}

.video-summary-p,
.video-time-span {
    font-size: 18px;
    line-height: 27px;
    color: #828282;
    padding-bottom: 16px;
}

.video-summary-p {
    margin-top: 16px;
}

.video-player {
    width: 100%;
    height: 100%;

    /deep/ .vjs_video_574-dimensions.vjs-fluid {
        padding-top: 0;
    }

    /deep/ .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3 {
        height: 100%;
    }
}

/deep/ .vjs-big-play-button {
    width: 2em;
    height: 2em;
    line-height: 2em;
    top: 50%;
    left: 50%;
    margin-top: -1em;
    margin-left: -1em;
    border-radius: 50%;
}
</style>
