<template>
  <div
    :class="['moving-block', field.noClose ? 'no-close' : '']"
    :style="{ left: `${draggingPosition[0]}px`, top: `${draggingPosition[1]}px` }"
  >
    <span>{{ field.name }}</span>
    <img v-if="!field.noClose" :src="require('@/assets/img/newData/remove.png')" alt="">
  </div>
</template>

<script>

export default {
  name: 'MovingBlock',
  props: {
    field: {
      type: Object,
      required: true
    },
    draggingPosition: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.moving-block {
  position: absolute;
  z-index: 9999;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  border-radius: 3px;
  background-color: #EFF7FF;
  border: 1px solid rgba(52,145,250,0.3);
  cursor: all-scroll;
  user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  color: #333333;
  white-space: nowrap;
  font-size: 14px;

  &.no-close {
    background-color: #fff;
  }
}

img {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}
</style>
