<template>
  <div class="content">
    <table class="table" border="1">
      <tr>
        <td align="right" width="30%">
          <strong>产品型号：</strong>

        </td>
        <td width="70%">{{ tableData.clxh || '-' }}</td>
      </tr>

      <tr>
        <td align="right">
          <strong>批次：</strong>
        </td>
        <td>{{ tableData.zwpc || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>公告状态：</strong>
        </td>
        <td>{{ tableData.ggzt || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>生产企业：</strong>
        </td>
        <td>{{ tableData.qymc || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>品牌：</strong>
        </td>
        <td>{{ tableData.ppai || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>产品名称：</strong>
        </td>
        <td>{{ tableData.cpmc || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>外廓尺寸长(mm)：</strong>
        </td>
        <td>{{ tableData.wkc || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>外廓尺寸宽(mm)：</strong>
        </td>
        <td>{{ tableData.wkk || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>外廓尺寸高(mm)：</strong>
        </td>
        <td>{{ tableData.wkg || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>总质量(kg)：</strong>
        </td>
        <td>{{ tableData.zzl || '-' }}</td>
      </tr>
      <tr>
        <td class="col-md-3" align="right">
          <strong>整备质量(kg)：</strong>
        </td>
        <td>{{ tableData.zbzl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>续驶里程(km,工况法)：</strong>
        </td>
        <td>{{ tableData.xslcgkf || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>续驶里程(km,等速法)：</strong>
        </td>
        <td>{{ tableData.xslcdsf || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>纯电动模式下续驶里程(km,等速法)：</strong>
        </td>
        <td>{{ tableData.cddmsxxslcdsf || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>工况条件下百公里耗电量(Y)(kWh/100km)：</strong>
        </td>
        <td>{{ tableData.gktjxbglhdl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>最高车速(km/h)：</strong>
        </td>
        <td>{{ tableData.zgcs || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>30分钟最高车速(km/h)：</strong>
        </td>
        <td>{{ tableData.zgcs_30 || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>发动机型号：</strong>
        </td>
        <td>{{ tableData.fdjxh || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>发动机生产企业：</strong>
        </td>
        <td>{{ tableData.fdjqy || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料种类：</strong>
        </td>
        <td>{{ tableData.rlzl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>排量/功率(ml/kW)：</strong>
        </td>
        <td>{{ tableData.plgl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>电池系统能量密度(Wh/kg)：</strong>
        </td>
        <td>{{ tableData.dcxtnlmd || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>电池系统总质量占整车整备质量比例(%)：</strong>
        </td>
        <td>{{ tableData.dcxtzzlzzczbzlbl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>储能装置种类：</strong>
        </td>
        <td>{{ tableData.cnzzzl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>储能装置总储电量(kWh)：</strong>
        </td>
        <td>{{ tableData.cnzzzcdl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>快充倍率：</strong>
        </td>
        <td>{{ tableData.kcbl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料电池系统生产企业(主要包含电堆)：</strong>
        </td>
        <td>{{ tableData.rldcxtscqyzybhdd || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料电池系统额定功率(kW)：</strong>
        </td>
        <td>{{ tableData.rldcxtedgl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料电池系统峰值功率(kW)：</strong>
        </td>
        <td>{{ tableData.rldcxtfzgl || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>驱动电机类型：</strong>
        </td>
        <td>{{ tableData.qddjlx || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>驱动电机峰值功率/转速/转矩(kW/r/min/N·m)：</strong>
        </td>
        <td>{{ tableData.qddjfzglzszj || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>节油率水平(%)：</strong>
        </td>
        <td>{{ tableData.fuelSavingRateLevel || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料消耗量(L/100km,B状态)：</strong>
        </td>
        <td>{{ tableData.jylsp || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料消耗量(L/100km,电量平衡运行阶段)：</strong>
        </td>
        <td>{{ tableData.rlxhl_b || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>吨百公里电耗(kWh/t·100km)：</strong>
        </td>
        <td>{{ tableData.dbgldh || '-' }}</td>
      </tr>
      <tr>
        <td align="right">
          <strong>Ekg单位载质量能量消耗量(Wh/km·kg)：</strong>
        </td>
        <td>{{ tableData.dwzzlnlxhl || '-' }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-color: $allcolor;
  color: #000;
  tr {
    td {
      padding: 5px 10px;
       word-break: break-all;
    }
    &:nth-child(even) {
      background: #bbc8da;
    }
  }
  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
    td,
    th {
        border: 1px solid #ddd;
    }
}
</style>