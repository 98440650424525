// 表格的假数据
export const tableFakeData = {
  total: 165,
  dataList: [
    {
      date: '202308',
      production: '0',
      enterprise: '*智己汽车',
      productionAccumulateYoy: '0.0',
      productionQoq: '0.0',
      productionAccumulate: '0',
      productionYoy: '0.0',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '0',
      enterprise: '*飞凡科技',
      productionAccumulateYoy: '0.0',
      productionQoq: '0.0',
      productionAccumulate: '0',
      productionYoy: '0.0',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '149666',
      enterprise: '一汽-大众',
      productionAccumulateYoy: '-5.91',
      productionQoq: '13.29',
      productionAccumulate: '1130366',
      productionYoy: '-9.34',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '77382',
      enterprise: '一汽丰田',
      productionAccumulateYoy: '-10.04',
      productionQoq: '23.76',
      productionAccumulate: '500482',
      productionYoy: '-4.34',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '114238',
      enterprise: '上汽大众',
      productionAccumulateYoy: '-15.47',
      productionQoq: '7.22',
      productionAccumulate: '712039',
      productionYoy: '-15.04',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '4990',
      enterprise: '上汽大通',
      productionAccumulateYoy: '25.23',
      productionQoq: '-7.66',
      productionAccumulate: '36127',
      productionYoy: '3.74',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '35051',
      enterprise: '上汽通用',
      productionAccumulateYoy: '-21.24',
      productionQoq: '4.70',
      productionAccumulate: '204656',
      productionYoy: '-22.99',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '5751',
      enterprise: '上汽通用(沈阳)北盛',
      productionAccumulateYoy: '44.24',
      productionQoq: '9.46',
      productionAccumulate: '50892',
      productionYoy: '-11.54',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '11431',
      enterprise: '上汽通用东岳',
      productionAccumulateYoy: '-25.75',
      productionQoq: '-29.90',
      productionAccumulate: '123803',
      productionYoy: '-58.78',
      vehicleType: '乘用车'
    },
    {
      date: '202308',
      production: '90991',
      enterprise: '上汽通用五菱',
      productionAccumulateYoy: '-32.76',
      productionQoq: '8.72',
      productionAccumulate: '525168',
      productionYoy: '-29.18',
      vehicleType: '乘用车'
    }
  ],
  columnList: [
    {
      prop: 'date',
      showArrow: false,
      label: '日期'
    },
    {
      prop: 'vehicleType',
      showArrow: false,
      label: '车辆类型'
    },
    {
      prop: 'enterprise',
      showArrow: false,
      label: '企业简称'
    },
    {
      prop: 'production',
      showArrow: false,
      label: '产量'
    },
    {
      prop: 'productionQoq',
      showArrow: true,
      label: '产量 月环比'
    },
    {
      prop: 'productionYoy',
      showArrow: true,
      label: '产量 月同比'
    },
    {
      prop: 'productionAccumulate',
      showArrow: false,
      label: '产量 本期止累计'
    },
    {
      prop: 'productionAccumulateYoy',
      showArrow: true,
      label: '产量 累计值同比'
    }
  ]
}

// 折线图和柱状图的假数据
export const lineBarFakeData = {
  yAxis: [
    '辆',
    '%'
  ],
  series: [
    {
      unit: '辆',
      data: [
        '2115287',
        '286031',
        '2274876',
        '300309'
      ],
      name: '产量',
      yAxisIndex: 0
    },
    {
      unit: '%',
      data: [
        '-4.67',
        '-16.39',
        '7.54',
        '4.99'
      ],
      name: '产量 月环比',
      yAxisIndex: 1
    },
    {
      unit: '%',
      data: [
        '-4.29',
        '17.03',
        '5.44',
        '26.19'
      ],
      name: '产量 月同比',
      yAxisIndex: 1
    },
    {
      unit: '辆',
      data: [
        '13384542',
        '2252791',
        '15659418',
        '2553100'
      ],
      name: '产量 本期止累计',
      yAxisIndex: 0
    },
    {
      unit: '%',
      data: [
        '5.91',
        '17.07',
        '5.84',
        '18.08'
      ],
      name: '产量 累计值同比',
      yAxisIndex: 1
    }
  ],
  xAxisArr: [
    [
      '202307',
      '202308'
    ],
    [
      '乘用车',
      '商用车'
    ]
  ]
}

// 堆积柱状图的假数据
export const stackBarFakeData = {
  yAxis: [
    '辆',
    '%'
  ],
  xAxis: [
    '202304',
    '202305',
    '202306',
    '202307',
    '202308'
  ],
  series: [
    {
      stack: 'target',
      unit: '辆',
      data: [
        '1777742',
        '2010806',
        '2218987',
        '2115287',
        '2274876'
      ],
      name: '乘用车-产量',
      yAxisIndex: 0
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '-17.29',
        '13.11',
        '10.35',
        '-4.67',
        '7.54'
      ],
      name: '乘用车-产量 月环比',
      yAxisIndex: 1
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '78.54',
        '18.20',
        '-0.88',
        '-4.29',
        '5.44'
      ],
      name: '乘用车-产量 月同比',
      yAxisIndex: 1
    },
    {
      stack: 'statistic',
      unit: '辆',
      data: [
        '7039462',
        '9050268',
        '11269255',
        '13384542',
        '15659418'
      ],
      name: '乘用车-产量 本期止累计',
      yAxisIndex: 0
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '8.50',
        '10.51',
        '8.07',
        '5.91',
        '5.84'
      ],
      name: '乘用车-产量 累计值同比',
      yAxisIndex: 1
    },
    {
      stack: 'target',
      unit: '辆',
      data: [
        '354761',
        '321829',
        '342121',
        '286031',
        '300309'
      ],
      name: '商用车-产量',
      yAxisIndex: 0
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '-18.35',
        '-9.28',
        '6.31',
        '-16.39',
        '4.99'
      ],
      name: '商用车-产量 月环比',
      yAxisIndex: 1
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '69.14',
        '42.91',
        '31.26',
        '17.03',
        '26.19'
      ],
      name: '商用车-产量 月同比',
      yAxisIndex: 1
    },
    {
      stack: 'statistic',
      unit: '辆',
      data: [
        '1302810',
        '1624639',
        '1966760',
        '2252791',
        '2553100'
      ],
      name: '商用车-产量 本期止累计',
      yAxisIndex: 0
    },
    {
      stack: 'statistic',
      unit: '%',
      data: [
        '9.11',
        '14.48',
        '17.08',
        '17.07',
        '18.08'
      ],
      name: '商用车-产量 累计值同比',
      yAxisIndex: 1
    }
  ]
}

// 饼图的假数据
export const pieFakeData = {
  series: [
    {
      data: [
        {
          unit: '辆',
          name: '一汽集团',
          value: '1388783'
        },
        {
          unit: '辆',
          name: '东风集团',
          value: '895399'
        },
        {
          unit: '辆',
          name: '上汽集团',
          value: '1958496'
        },
        {
          unit: '辆',
          name: '长安集团',
          value: '1009911'
        },
        {
          unit: '辆',
          name: '北汽集团',
          value: '642630'
        },
        {
          unit: '辆',
          name: '其他',
          value: '6107530'
        }
      ],
      name: '产量'
    }
  ]
}

// 组合图的假数据
export const mixFakeData = {
  yAxis: [
    '辆',
    '%'
  ],
  xAxis: [
    '202304',
    '202305',
    '202306',
    '202307',
    '202308'
  ],
  series: [
    {
      unit: '辆',
      'targetName': '产量',
      data: [
        '179036',
        '161927',
        '183186',
        '178204',
        '193046'
      ],
      name: '东风集团-产量'
    },
    {
      unit: '%',
      'targetName': '产量 月环比',
      data: [
        '-33.04',
        '-9.56',
        '13.13',
        '-2.72',
        '8.33'
      ],
      name: '东风集团-产量 月环比'
    },
    {
      unit: '辆',
      'targetName': '产量',
      data: [
        '112666',
        '123293',
        '149302',
        '112570',
        '144799'
      ],
      name: '北汽集团-产量'
    },
    {
      unit: '%',
      'targetName': '产量 月环比',
      data: [
        '-40.97',
        '9.43',
        '21.10',
        '-24.60',
        '28.63'
      ],
      name: '北汽集团-产量 月环比'
    }
  ],
  'targets': [
    {
      stack: '产量',
      name: '产量',
      type: 'bar',
      yAxisIndex: 0
    },
    {
      stack: '产量 月环比',
      name: '产量 月环比',
      type: 'line',
      yAxisIndex: 1
    }
  ]
}

// 指标卡的假数据
export const targetCardFakeData = {
  'list': [
    {
      'cardName': 'MPV产量',
      'value': 51,
      'qoq': -12,
      'yoy': 52
    },
    {
      'cardName': 'MPV销量',
      'value': 50,
      'qoq': -33,
      'yoy': -11
    },
    {
      'cardName': 'SUV产量',
      'value': 91,
      'qoq': 13,
      'yoy': -3
    },
    {
      'cardName': 'SUV销量',
      'value': 80,
      'qoq': 21,
      'yoy': 41
    },
    {
      'cardName': '轿车产量',
      'value': 150,
      'qoq': -43,
      'yoy': 12
    },
    {
      'cardName': '轿车销量',
      'value': 148,
      'qoq': 5,
      'yoy': -32
    },
    {
      'cardName': '皮卡产量',
      'value': 50,
      'qoq': 2,
      'yoy': 4
    },
    {
      'cardName': '皮卡销量',
      'value': 40,
      'qoq': 5,
      'yoy': 3
    }
  ],
  'unitStr': '单位：万辆，%'
}

// 交叉表的假数据
export const crossTableFakeData = {
  columnList: [
    {
      label: '车型',
      prop: 'carType',
      showArrow: null,
      categorical: true,
      columnList: null
    },
    {
      label: '燃料类型',
      prop: 'fuel',
      showArrow: null,
      categorical: true,
      columnList: null
    },
    {
      label: '轿车',
      prop: null,
      showArrow: null,
      categorical: false,
      columnList: [
        {
          label: '汽油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'carPetrolProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'carPetrolProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'carPetrolProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '柴油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'carDieselOilProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'carDieselOilProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'carDieselOilProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '新能源',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'carNewEnergyProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'carNewEnergyProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'carNewEnergyProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: 'SUV',
      prop: null,
      showArrow: null,
      categorical: false,
      columnList: [
        {
          label: '汽油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'suvPetrolProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'suvPetrolProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'suvPetrolProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '柴油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'suvDieselOilProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'suvDieselOilProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'suvDieselOilProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '新能源',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'suvNewEnergyProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'suvNewEnergyProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'suvNewEnergyProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: 'MPV',
      prop: null,
      showArrow: null,
      categorical: false,
      columnList: [
        {
          label: '汽油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'mpvPetrolProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'mpvPetrolProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'mpvPetrolProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '柴油',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'mpvDieselOilProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'mpvDieselOilProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'mpvDieselOilProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        },
        {
          label: '新能源',
          prop: null,
          showArrow: null,
          categorical: false,
          columnList: [
            {
              label: '产量',
              prop: null,
              showArrow: null,
              categorical: false,
              columnList: [
                {
                  label: '本月值',
                  prop: 'mpvNewEnergyProduction',
                  showArrow: null,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月环比',
                  prop: 'mpvNewEnergyProductionQoq',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                },
                {
                  label: '月同比',
                  prop: 'mpvNewEnergyProductionYoy',
                  showArrow: true,
                  categorical: false,
                  columnList: null
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  unitStr: '单位：辆，%',
  total: 150,
  dataList: [
    {
      carType: '轿车',
      fuel: '汽油',
      carPetrolProduction: 10,
      carPetrolProductionQoq: 10,
      carPetrolProductionYoy: 10
    },
    {
      carType: '轿车',
      fuel: '柴油',
      carDieselOilProduction: 10,
      carDieselOilProductionQoq: 10,
      carDieselOilProductionYoy: 10
    },
    {
      carType: '轿车',
      fuel: '新能源',
      carNewEnergyProduction: 10,
      carNewEnergyProductionQoq: 10,
      carNewEnergyProductionYoy: 10
    },
    {
      carType: 'SUV',
      fuel: '汽油',
      suvPetrolProduction: 10,
      suvPetrolProductionQoq: 10,
      suvPetrolProductionYoy: 10
    },
    {
      carType: 'SUV',
      fuel: '柴油',
      suvDieselOilProduction: 10,
      suvDieselOilProductionQoq: 10,
      suvDieselOilProductionYoy: 10
    },
    {
      carType: 'SUV',
      fuel: '新能源',
      suvNewEnergyProduction: 10,
      suvNewEnergyProductionQoq: 10,
      suvNewEnergyProductionYoy: 10
    },
    {
      carType: 'MPV',
      fuel: '汽油',
      mpvPetrolProduction: 10,
      mpvPetrolProductionQoq: 10,
      mpvPetrolProductionYoy: 10
    },
    {
      carType: 'MPV',
      fuel: '柴油',
      mpvDieselOilProduction: 10,
      mpvDieselOilProductionQoq: 10,
      mpvDieselOilProductionYoy: 10
    },
    {
      carType: 'MPV',
      fuel: '新能源',
      mpvNewEnergyProduction: 10,
      mpvNewEnergyProductionQoq: 10,
      mpvNewEnergyProductionYoy: 10
    }
  ]
}
