import store from './store'
import user from './store/modules/user'
// import menuData from './assets/jsondata/menu.json'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import router, { oldDataRoutes } from '@/router'
import { fuAoFindMenu, findMenu, getDataQueryMenus } from './api/menu.js'
import { getUserInfo } from '@/utils/auth.js'
// import canViewNewVersion from '@/utils/canViewNewVersion'

NProgress.configure({ showSpinner: false })
router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  if (!user.state.init) {
    // const accessRoutes = await store.dispatch('permission/generateRoutes', menuData)
    let userId = ''
    if (getUserInfo()) {
      userId = getUserInfo().userId
    }
    let menuData = null; let dataQueryMenus = {}
    if (process.env.VUE_APP_CHECKLOGIN == 'fuao') {
      menuData = await fuAoFindMenu({ userId })
      // 生成并保存基本路由
      store.dispatch('permission/generateRoutes', menuData.data)
      // 查询 数据栏目-数据查询的菜单，因为这部分是后台动态配置的
      dataQueryMenus = await getDataQueryMenus({ userId })
      // 生成并保存新版数据栏目的路由
      store.dispatch('permission/saveDataQueryMenus', dataQueryMenus.data)
      const { path } = to
      // 获取是否将要去旧版的数据栏目
      const isToOldData = oldDataRoutes.some(item => item.path === path)
      // 如果是将要去旧版的数据栏目，则需要打开新版的数据栏目的第一个菜单
      if (isToOldData) {
        store.dispatch('permission/changeToNewDataRoute', true)
        store.commit('user/SET_Init', true)
        return
      }
      store.dispatch('permission/changeToNewDataRoute')
      // 富奥看的是信息网的旧版数据栏目
      // sessionStorage.setItem('version', 'old')
      // store.dispatch('permission/changeToOldDataRoute')
    } else {
      menuData = await findMenu({ userId })
      // 生成并保存基本路由
      store.dispatch('permission/generateRoutes', menuData.data)
      // 查询 数据栏目-数据查询的菜单，因为这部分是后台动态配置的
      dataQueryMenus = await getDataQueryMenus({ userId })
      // 生成并保存新版数据栏目的路由
      store.dispatch('permission/saveDataQueryMenus', dataQueryMenus.data)
      // // 如果没登录，或者不能查看新版，则去旧版
      // if (!userId || !canViewNewVersion(userId)) {
      //   sessionStorage.setItem('version', 'old')
      //   store.dispatch('permission/changeToOldDataRoute')
      //   next({ ...to })
      //   store.commit('user/SET_Init', true)
      //   return
      // }
      if (window.location.href.indexOf('version=old') !== -1 || sessionStorage.getItem('version') === 'old') {
        sessionStorage.setItem('version', 'old')
        store.dispatch('permission/changeToOldDataRoute')
        store.commit('user/SET_Init', true)
        next({ ...to, query: {
            ...to.query,
            version: undefined
          }})
        return
      } else {
        const { path } = to
        // 获取是否将要去旧版的数据栏目
        const isToOldData = oldDataRoutes.some(item => item.path === path)
        // 如果是将要去旧版的数据栏目，则需要打开新版的数据栏目的第一个菜单
        if (isToOldData) {
          store.dispatch('permission/changeToNewDataRoute', true)
          store.commit('user/SET_Init', true)
          return
        }
        store.dispatch('permission/changeToNewDataRoute')
      }
      /**
       获取并生成基本路由，保存到 vuex 里
       获取并生成 新数据栏目的路由，保存到 vuex 里。
       - 切换新数据栏目：
       执行 vuex 的切换新路由的方法 -> 将新数据栏目的路由拼接到当前路由的数据栏目的 children 中 -> 更新路由(不跳转)
       - 切换旧数据栏目：
       执行 vuex 的切换旧路由的方法 -> 将旧数据栏目的路由拼接到当前路由额数据栏目的 children 中 -> 更新路由(跳转)
       */
    }
    store.commit('user/SET_Init', true)
    next({ ...to, replace: true })
  } else {
    next()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

