<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-08-17 17:36:51
-->
<template>
  <div style="width:100%" :id="'salesVolumeEchart' + echartKey">
    <div
      class="salesVolumeEchart"
      :style="`height:${height}px`"
      ref="salesVolumeEchart"
    ></div>
  </div>
</template>
<script>
// 引入echarts
import * as echarts from "echarts";
//引入dom监听器
export default {
  name: "salesVolumeEchart",
  data() {
    return {
      myChart: null, //echartDom对象
      echartKey: Math.random(),
      // Eechart数据
      echartData: {},
    };
  },
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
  },
  mounted() {},
  methods: {
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.echartData = data;
      this.initEchart();
      this.initLister();
    },
    /**
     * @description: 初始化监听器
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:18
     * @LastEditTime: Do not edit
     */
    initLister() {
      let elementResizeDetectorMaker = require("element-resize-detector");
      //监听元素变化
      let erd = elementResizeDetectorMaker();
      erd.listenTo(
        document.getElementById("salesVolumeEchart" + this.echartKey),
        (element) => {
          this.$nextTick(() => {
            //使echarts尺寸重置
            this.myChart.resize();
          });
        }
      );
    },
    /**
     * @description: 初始化echart
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:08
     * @LastEditTime: Do not edit
     */
    initEchart() {
      this.myChart = echarts.init(this.$refs.salesVolumeEchart);
      let legendData = [];
      this.echartData.seriesData.forEach((element) => {
        legendData.push(element.name);
      });
      this.echartData.seriesData.forEach((item) => {
        if (item.areaStyleColor) {
          item["areaStyle"] = {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: item.areaStyleColor[0],
              },
              {
                offset: 1,
                color: item.areaStyleColor[1] || item.areaStyleColor[0],
              },
            ]),
          };
        }
      });
      let option = {
        tooltip: {
          trigger: "axis",
          position: function(pt) {
            return [pt[0], "10%"];
          },
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: this.echartData.xAxisData,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        dataZoom: [
          {
            type: "inside",
            start: 90,
            end: 100,
          },
          {
            start: 90,
            end: 100,
          },
        ],
        legend: {
          data: legendData,
          right: 0,
        },
        series: this.echartData.seriesData,
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.salesVolumeEchart {
  width: 100%;
  height: 100%;
}
</style>
