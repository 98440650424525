<template>
    <router-view v-if="$route.name == '概览详情'" />
    <PageContainer v-else>
        <template slot="actionNav"> <actionTabs actionNavInformation="survey" /></template>
        <analysis />
        <industry />
        <capaction />
        <template slot="right">
          <enterpriseDynamic/>
          <enterpriseInfo />
          <inancialReport />
        </template>
    </PageContainer>
</template>

<script>
import analysis from './compAnalysis.vue'
import industry from './compIndustry.vue'
import capaction from './compCapacity.vue'
import actionTabs from "../components/actionTabs.vue"
import inancialReport from './compinancialReport.vue'
import enterpriseInfo from './compenterpriseInfo.vue'
import enterpriseDynamic from "./compenterpriseDynamic.vue";
export default {
    components: {
        actionTabs,
        analysis,
        industry,
        capaction,
        inancialReport,
        enterpriseDynamic,
        enterpriseInfo
    },
    props: {},
    data() {
        return {

        }
    },
    computed: {},
    methods: {

    },
    created() {

    },
    mounted() {

    },
}
</script>
<style scoped>
::v-deep .el-tabs__nav-wrap::after {
    content: none;
}
</style>
