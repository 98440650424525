<template>
    <div class="capacity-contain">
        <div class="capacity-top">
            <Chart :operate="operate" :mapList="mapList" :maxNum="maxNum" @map-click="handleMapClick" />
        </div>
        <div class="bottom" ref="bottomElement">
            <div class="list-title">
                产能分布详情
            </div>
            <div class="list">
                <div class="list-sele">
                    <div class="item item-model">
                        <span class="checkTitle">省份</span>
                        <SearchSelect placeholder="请选择省份" v-model="form.provinceList" :options="provinceList"
                            size="small" />
                    </div>
                    <div class="item">
                        <span class="checkTitle">市</span>
                        <SearchSelect :isEmpty="cityList.length === 0" placeholder="请选择市" v-model="form.cityList"
                            :options="cityList" size="small" />
                    </div>
                    <div class="item">
                        <span class="checkTitle">类型</span>
                        <el-select v-model="form.typeList" collapse-tags multiple placeholder="请选择类型" size="small"
                            class="g-form-input" clearable filterable>
                            <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <!-- <SearchSelect placeholder="请选择类型" v-model="form.typeList" :options="typeList" size="small" /> -->
                    </div>
                    <div class="item">
                        <span class="checkTitle">生产企业</span>
                        <SearchSelect placeholder="请选择生产企业" v-model="form.enterpriseList" :options="enterpriseList"
                            size="small" />
                    </div>
                    <div class="itembutton" flex="c">
                        <el-button type="primary" size="mini" @click="search()">查询</el-button>
                        <span><el-button type="primary" size="mini" @click="reset()">重置</el-button></span>
                        <el-button type="primary" plain @click="exportExcel" v-has-role="'freeusers'"
                            :loading="excelLoading">导出
                        </el-button>
                    </div>
                </div>
                <div class="table-title">
                    {{ listtitle }}
                </div>
                <div class="tablelist" v-loading="loading">
                    <list @custom-sort-change="handleCustomSortChange" :loading="listloading" :tableHead="tableHead"
                        :tableData="tableData" />
                </div>
            </div>
        </div>
        <div class="pages" v-if="operate">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageData.pages.pageNum" :page-sizes="[10, 20, 30, 40, 100]"
                :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {
    MapData,
    TableData,
    Selections,
    exportTableData
} from "@/api/yieid.js"
import Chart from "./charts.vue"
import list from "./list.vue"
import SearchSelect from '@/components/searchSelect'
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
import {
    downloadFile
} from "@/utils/download.js";

export default {
    components: {
        list,
        Chart,
        SearchSelect
    },
    props: {},
    data() {
        return {
            excelLoading: false,
            operate: true,
            listloading: false,
            listtitle: '',
            typefirstLabel: '',
            modelfirstLabel: '',
            enterfirstLabel: '',
            maxNum: '',
            orderType: '',
            typeList: [],
            mapList: [],
            cityList: [],
            tempcityList: [],
            tableHead: [],
            tableData: [],
            provinceList: [],
            enterpriseList: [],
            pageData: {
                pages: {
                    pageNum: 1,
                    pageSize: 20,
                },
                total: 0,
            },
            form: {
                typeList: [],
                cityList: [],
                provinceList: [],
                enterpriseList: [],
            },
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
            loading: false,
        }
    },
    computed: {},
    created() {
        this.permissionSetting()
    },
    watch: {
        'form.provinceList': {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.length === 0) {
                    this.cityList = this.tempcityList
                    return
                }
                this.form.cityList = []
                this.cityList = this.tempcityList.filter(city =>
                    newVal.includes(city.linkageValue)
                );
            },
        },
    },
    mounted() {
        this.getMapData()
        this.getTableData()
        this.getSelections()
    },
    methods: {
        getSelections() {
            Selections().then(res => {
                this.tempcityList = res.data.cityList
                this.cityList = res.data.cityList
                this.typeList = res.data.typeList
                this.provinceList = res.data.provinceList
                this.enterpriseList = res.data.enterpriseList
            })
        },
        getTableData() {
            this.listloading = true
            let form = {
                ...this.form,
                ...this.pageData.pages,
                orderType: this.orderType
            }
            TableData(form).then(res => {
                this.tableHead = res.data.tableHead
                this.tableData = res.data.tableData
                this.listtitle = res.data.tableInfoStr
                this.pageData.total = res.data.totalCount
                this.listloading = false
            })
        },
        getMapData() {
            MapData({ isOverview: false }).then(res => {
                this.mapList = res.data.mapData
                this.maxNum = res.data.maxNum
            })
        },
        handleCustomSortChange(orderType) {
            this.orderType = orderType
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pageData.pages.pageSize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.pageData.pages.pageNum = val;
            this.getTableData();
        },
        handleMapClick(params) {
            if (!this.operate) {
                return
            }
            if (params.value) {
                // this.$message({
                //     message: '请在下方列表查看该区域企业分布数据',
                //     type: 'success',
                //     center: true
                // })
                const offsetTop = this.$refs.bottomElement.offsetTop;
                window.scrollTo({ top: offsetTop, behavior: "smooth" });
                this.form.provinceList = []
                this.form.provinceList.push(params.name)
                this.search()
            } else {
                this.$message({
                    message: '该区域暂无企业分布数据',
                    type: 'error',
                    center: true
                })
            }
        },
        permissionSetting() {
            if (getUserInfo()) {

                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.operate = false
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.operate = false
                        }
                    }
                }
            } else {
                this.operate = false
            }
        },
        search() {
            this.loading = true;
            if (getUserInfo()) {
                logRecords('企业情报', '国内汽车产能布局', '4', 'tt_enterprise_capacity', '', '', '', '查询')
                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.sqsyLogin();
                        this.insertBuryPoint();
                        this.loading = false;
                        return
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.formLog.userId = getUserInfo().userId
                            buryPoint(this.formLog)
                            openSy()
                            this.loading = false;
                            return
                        } else {
                            this.loading = false;
                        }
                    }
                }
            } else {
                this.showLogin();
                this.loading = false;
                return
            }

            this.pageData.pages.pageNum = 1
            this.getTableData();
            this.loading = false;
        },
        reset() {
            this.pageData.pages = {
                pageNum: 1,
                pageSize: 20,
            }
            this.form = {
                typeList: [],
                cityList: [],
                provinceList: [],
                enterpriseList: [],
            }
            this.getTableData();
        },
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
            this.loading = false;
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
            this.loading = false;
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
        sqsy(url) {
            const newUrl = this.$router.resolve(url);

            window.open(newUrl.href, "_blank");
        },
        exportExcel() {
            this.excelLoading = true
            let form = {
                ...this.form,
                ...this.pageData.pages,
                orderType: this.orderType
            }
            let self = this;
            exportTableData(form).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                    try {
                        JSON.parse(e.target.result);
                        let msg = JSON.parse(e.target.result);
                        console.log(msg);
                        self.$message({
                            showClose: true,
                            message: msg.message,
                            type: "error",
                        });
                    } catch (err) {
                        downloadFile(res, "产能分布详情.xlsx");
                    }
                });
            });
            this.excelLoading = false


        },
    },
}
</script>
<style lang="scss" scoped>
.capacity-top {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    padding-top: 30px;
}

.capacity-contain {
    width: 100%;

    .bottom {
        width: 100%;
        background: #fff;

        .list {
            width: 100%;
            padding: 0 60px 10px 40px;

            .list-sele {
                display: flex;
                flex-wrap: wrap;
            }

            .item {
                padding-right: 30px;
                padding-top: 15px;
            }

            .itembutton {
                padding-top: 15px;

                button {
                    width: 120px;
                }

                span button {
                    background: #fff;
                    border: 0;
                    font-size: 12px;
                    color: rgb(153, 153, 153);
                }
            }

            .el-select {
                width: 210px;
                padding-left: 10px;
            }

            .table-title {
                font-size: 14px;
                color: rgb(34, 34, 34);
                font-weight: bold;
                padding: 30px 0 20px 0;
            }
        }

        .list-title {
            font-size: 18px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            padding: 24px 60px 0 30px;
        }
    }

    .pages {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        background: #fff;
    }
}
</style>
