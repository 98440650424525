<template>
    <div id="map"></div>
</template>

<script>
import echarts from 'echarts4';
import china from '@/assets/jsondata/china_1.json'
echarts.registerMap('china', china)

export default {
    components: {},
    props: {
        mapList:{
            type: Array,
            default: [],
        },
        maxNum:{
            default: '',
        },
        operate: {
            type:Boolean,
            default: true,
        },
    },
    data() {
        return { }
    },
    watch: {
        mapList: {
            deep: true,
            handler(newVal, oldVal) {
                this.renderMap()
            },
        },
    },
    computed: {},
    methods: {
        renderMap() {
            var myChart = echarts.init(document.getElementById('map'));
            var option = {
                title: [
                    {
                        text: '产能分布概览',
                        left: 'left',
                    },
                    {
                        subtext: '单位：万辆',
                        left: 'right',
                        subtextStyle: {
                            color: 'rgb(34, 34, 34)'
                        }
                    },
                ],
                tooltip: {
                    trigger: 'item',
                    backgroundColor: '#fff',
                    padding:[10],
                    formatter:  (params)=> {
                        if (params.value && this.operate) {
                            return `<div style="font-size:14px;color:black">${params.name}：<span style="font-size:20px;">${params.value} </span>万辆</div>
                        <div style="color: #9999;">点击地图区域查看具体名单</div>`
                        } else if (this.operate) {
                            return `<div style="font-size:14px;color:black">该区域暂无相关内容</div>
                        `
                        }
                    }
                },
                // legend: {
                //     orient: 'vertical',
                //     left: 'left'
                // },
                
                visualMap: {
                    min: 1,
                    max: this.maxNum,
                    left: 'left',
                    bottom: '60',
                    text: ['高', '低'],
                    calculable: true,
                    // seriesIndex: [1],
                    inRange: {
                       color: [ "#e0e9f5","#0069ea","#233996"]
                    },
                    show: this.operate
                },

                series: [

                    {
                        name: 'iphone5',
                        type: 'map',
                        mapType: 'china',
                        label: {
                            normal: {
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                areaColor: '#e6ebf2',
                                borderColor: '#fff',
                                borderWidth: 1,
                            },
                        },
                        data: this.mapList
                    }
                ]
            };
            myChart.setOption(option);
            // 点击事件
            myChart.on("click", this.handleClick);
        },
        handleClick(params) {
            this.$emit('map-click', params);
        },
        handleResize() {
            var myChart = echarts.init(document.getElementById("map"));
            myChart.resize();
        }
    },
    created() {

    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.renderMap()
    },
    beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
}
</script>
<style scoped lang="scss">
#map {
    width: 100%;
    padding: 0 70px 0 40px;
    height: 900px;
}
</style>