import pinyin from 'js-pinyin'
import { Button, Checkbox, Input } from 'element-ui';
import { valueEquals } from 'element-ui/src/utils/util';
import { cloneDeep } from 'lodash';

import IndexList from '../IndexList';
import IndexAnchor from '../IndexAnchor';
import Select from './Select.vue';
import { getValueIndex } from './utils';

import './index.scss';

const props = {
  clearable: true,
  filterable: false,
  multiple: true,
  collapseTags: true,
};
const IndexSelectProps = cloneDeep(Select.props);
Object.keys(props).forEach((prop) => {
  if (typeof IndexSelectProps[prop] === 'function') {
    IndexSelectProps[prop] = {
      type: IndexSelectProps[prop],
      default: props[prop],
    };
  } else {
    IndexSelectProps[prop].default = props[prop];
  }
});

export default {
  name: 'IndexSelect',
  props: {
    ...IndexSelectProps,
    options: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    promptText:{
      required: false,
      type: String,
      default: () => "",
    },
    fieldNames: {
      required: false,
      type: Object,
      default: () => ({
        id: 'id',
        label: 'label',
        value: 'value',
      }),
    },
  },
  data() {
    return {
      all: [],
      indexs: [],
      groups: [],
      firstLabels:'',
      filterAll: null,
      filterIndexs: null,
      filterGroups: null,
      query: '',
      checkedAll: false,
      indeterminate: false,
    };
  },
  computed: {
    fields() {
      return Object.assign({
        id: 'id',
        label: 'label',
        value: 'value',
      }, this.fieldNames);
    },
    finalIndexs() {
      return this.filterIndexs || this.indexs;
    },
    finalGroups() {
      return this.filterGroups || this.groups;
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(options) {
        this.update(options);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(newVal,oldVal) {
        this.updateCheckStatus({ newVal });
        if (newVal) {
          if (oldVal && newVal[0] === oldVal[0]) {
            return
          }
            const targetObject = this.options.find(item => item.value === newVal[0]);
            const targetLabel = targetObject ? targetObject.label : "";
            this.firstLabels = targetLabel
        }
      },
    },
    filterAll: {
      deep: true,
      immediate: true,
      handler(value) {
        this.updateCheckStatus({ filterAll: value });
      },
    },
    query(value) {
      this.search(value);
    },
  },
  methods: {
    triggerChildBlur() {
      if (this.$refs.selectRef) {
        this.$refs.selectRef.blur();
      }
    },
    search(query) {
      query = query ? query.trim() : null;
      const selectIns = this.$refs.selectRef;

      if (selectIns) {
        selectIns.query = query;
      }

      if (!query) {
        this.filterAll = null;
        this.filterIndexs = null;
        this.filterGroups = null;

        if (this.filterable && selectIns && (!this.value || this.value.length === 0)) {
          selectIns.currentPlaceholder = selectIns.propPlaceholder;
        }

        return;
      }

      if (this.filterable && selectIns) {
        selectIns.currentPlaceholder = '';
      }

      const options = this.options || [];
      const fields = this.fields;

      const filterOptions = options.filter((item) => {
        const label = item[fields.label].toLowerCase();
        const value = item[fields.value].toLowerCase();
        const py = item.py.toLowerCase();
        return label.indexOf(query) !== -1 || value.indexOf(query) !== -1 || py.indexOf(query) !== -1;
      });

      const result = this.generator(filterOptions);
      this.filterAll = [...result.all];
      this.filterIndexs = [...result.indexs];
      this.filterGroups = [...result.groups];
    },

    update(options) {
      const result = this.generator(options);
      this.all = [...result.all];
      this.indexs = [...result.indexs];
      this.groups = [...result.groups];
    },

    generator(options) {
      options = options || this.options || [];

      if (options.length === 0) {
        return { indexs: [], groups: [], all: [] };
      }

      const all = [];
      const map = new Map();
      const { fields } = this;

      options.forEach((item) => {
        all.push(item[fields.value]);

        const py = pinyin.getFullChars(item[fields.label]);
        const char = py[0];
        item.py = py;

        if (!map.has(char)) {
          map.set(char, []);
        }

        const group = map.get(char);
        group.push(item);
      });

      [...map.values()].forEach((group) => {
        group.sort((a, b) => {
          const charA = a[fields.label];
          const charB = b[fields.label];
          return charA.localeCompare(charB, 'zh-Hans-CN', { sensitivity: 'accent' });
        });
      });

      const indexs = [...map.keys()].sort();
      const groups = indexs.map((char) => {
        const children = map.get(char);
        return { char, children };
      });

      return { indexs, groups, all };
    },

    getValueIndex(arr = [], value) {
      return getValueIndex(arr, value, this.valueKey);
    },

    check(item) {
      const current = item[this.fields.value];

      if (this.multiple) {
        const value = (this.value || []).slice();
        const optionIndex = this.getValueIndex(value, current);
        return optionIndex > -1;
      }

      return false;
    },

    onScroll(options) {
      this.$refs.indexListRef?.setActive();
    },

    emitChange(value) {
      if (!valueEquals(this.value, value)) {
        this.$emit('change', value);
      }
    },

    onChange(checked, item) {
      const current = item[this.fields.value];

      if (this.multiple) {
        const value = (this.value || []).slice();
        const optionIndex = this.getValueIndex(value, current);
        if (optionIndex > -1) {
          value.splice(optionIndex, 1);
        } else if (this.multipleLimit <= 0 || value.length < this.multipleLimit) {
          value.push(current);
        }
        this.$emit('input', value);
        this.emitChange(value);
      }
    },

    updateCheckStatus({ value, filterAll }) {
      value = value || this.value;
      filterAll = filterAll || this.filterAll;

      if (!value || !Array.isArray(value)) {
        this.indeterminate = false;
        this.checkedAll = false;
        return;
      }

      const all = filterAll || this.all;
      const vs = new Set(value);
      const svs = new Set();

      all.forEach((item) => {
        if (vs.has(item)) {
          svs.add(item);
        }
      });

      this.checkedAll = svs.size > 0;
      this.indeterminate = svs.size > 0 && svs.size < all.length;
    },

    onChangeAll(checked) {
      const { checkedAll, indeterminate } = this;
      const all = this.filterAll || this.all;
      const vs = new Set(this.value);

      if (checkedAll && !indeterminate) {
        all.forEach((item) => {
          vs.delete(item);
        });
        this.$emit('input', [...new Set(vs)]);
        return;
      }

      this.$emit('input', [...new Set([...vs, ...all])]);
    },

    onClearAll() {
      const all = this.filterAll || this.all;
      const vs = new Set(this.value);
      all.forEach((item) => {
        vs.delete(item);
      });
      this.$emit('input', [...vs]);
    },
  },
  render() {
    const { options, fields, finalIndexs, finalGroups, promptText } = this;

    return (
      <Select
        ref="selectRef"
        class="index-select-container"
        props={{...this.$props, firstLabel:this.firstLabels,optList:this.options}}
        {...{ attrs: this.$attrs }}
        {...{ on: this.$listeners }}
        {...{ slots: this.$slots }}
        {...{ scopedSlots: this.$scopedSlots }}
        isEmpty={finalIndexs.length === 0}
        onChangeQuery={(query) => this.query = query}
        onScroll={this.onScroll}
      >
        {options.length > 0 && (
          <div class="index-select-actions">
            <Input
              v-model={this.query}
              class="index-select-search"
              size={this.size || 'small'}
              clearable
              suffix-icon="el-icon-search"
              placeholder="搜索"
            />

            {finalGroups.length > 0 && (
              <div class="index-select-btn">
                <Checkbox
                  value={this.checkedAll}
                  indeterminate={this.indeterminate}
                  onChange={this.onChangeAll}
                >
                  全选
                </Checkbox>
                <span>{promptText}</span>
                <Button
                  type="text"
                  icon="el-icon-delete"
                  onClick={this.onClearAll}
                >
                  清空
                </Button>
              </div>
            )}
          </div>
        )}

        {finalGroups.length > 0 && (
          <IndexList ref="indexListRef" indexs={finalIndexs}>
            {finalGroups.map(({ char, children }) => (
              <div key={char} class="index-select-group">
                <IndexAnchor index={char}>{char}</IndexAnchor>
                {children.map((item) => (
                  <Checkbox
                    key={item[fields.value]}
                    value={this.check(item)}
                    label={item[fields.value]}
                    onChange={(checked) => this.onChange(checked, item)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
              </div>
            ))}
          </IndexList>
        )}
      </Select>
    );
  },
};
