<template>
  <div
    v-if="isShowPartsHistory"
    class="enterpriseDetail partsHistory-compared"
    id="partsHistory"
    data-name="公司历程"
  >
    <el-card :body-style="{ paddingTop: '0' }" v-loading="isLoading">
      <div slot="header" class="clearfix">
        <div class="title-tag-text">
          <span>公司历程</span>
        </div>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </div>
      <div class="content-box">
        <div
          class="timeline-container"
          v-for="(i, o) in sourceData"
          :key="i.enterpriseName"
        >
          <div class="enterpriseName" :style="headerTitleStyle(o)">
            {{ i.enterpriseName }}
          </div>
          <div class="enterpeiseContent">
            <timeline :timelineData="i.data"></timeline>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getEnterpriseDevelopmentHistory,postComparisonExportEnterpriseDevelopmentHistory } from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import timeline from "./timeline.vue";

export default {
  name: "PartsHistory",
  components: {
    timeline
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      params: {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      },
      isShowPartsHistory: true,
      isLoading: false,
      sourceData: [],
      enterpriseIdList: [],
      debouncedDataUpdate: null,
      lastRouteChange: ""
    };
  },
  created() {
    this.debouncedDataUpdate = this.debounce(this.updateData, 100);
    this.updateData();
  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业对标分析", "3", "", "", "", "", "公司历程", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postComparisonExportEnterpriseDevelopmentHistory(
                this.params
              ).then(res => {
                this.exportState = false;
                fileDownload(res, "公司历程.xlsx");
              });
            }
          }
        } else {
          postComparisonExportEnterpriseDevelopmentHistory(this.params).then(
            res => {
              this.exportState = false;
              fileDownload(res, "公司历程.xlsx");
            }
          );
        }
      } else {
        this.showLogin();
      }
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    updateData() {
      if (
        this.lastRouteChange === "checkedList" ||
        this.lastRouteChange === ""
      ) {
        this.isShowPartsHistory =
          this.$route.query.checkedList.includes("公司历程") ||
          this.$route.query.checkedList.includes("全部")
            ? true
            : false;
        this.enterpriseIdList = JSON.parse(
          this.$route.query.selectedEnterprise
        );
        this.isLoading = true;
        const promises = this.enterpriseIdList.map(id => {
          return getEnterpriseDevelopmentHistory({ enterpriseId: id });
        });

        Promise.all(promises).then(results => {
          this.sourceData = results.map(res => (res.ok ? res.data : {}));
          this.isLoading = false;
        });
      }
      this.lastRouteChange = "";
    },
    headerTitleStyle(index) {
      const style = {
        "border-radius": "4px",
        "font-family": "MicrosoftYaHei-Bold",
        "font-size": "16px",
        "border-bottom": "2px solid #fff"
      };
      const backgroundColors = {
        0: "#fcefe9",
        1: "#f7edff",
        2: "#effaf0",
        default: "#effaf0"
      };
      const colors = {
        0: "#ff8744",
        1: "#8328cd",
        2: "#5ebf67",
        default: "#5ebf67"
      };

      style["background-color"] =
        backgroundColors[index] || backgroundColors.default;
      style["color"] = colors[index] || colors.default;
      return style;
    },
    getEnterpriseDevelopmentHistory(id, index) {
      getEnterpriseDevelopmentHistory({ enterpriseId: id }).then(res => {
        if (res.ok) {
          this.sourceData = [
            ...this.sourceData.slice(0, index),
            res.data,
            ...this.sourceData.slice(index + 1)
          ];
        }
      });
    },
    isArrayEmptyOrFilledWithEmptyObjects(arr) {
      // 判断数组是否为空
      if (arr.length === 0) {
        return true;
      }

      // 使用 Array.every 检查数组中的每个对象是否为空
      return arr.every(obj => {
        // 判断对象是否为空的一个方法是检查它的键的数量
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"(newId) {
      this.params.enterpriseIdList = JSON.parse(newId);
      this.lastRouteChange = "selectedEnterprise";
      this.debouncedDataUpdate();
    },
    "$route.query.checkedList"() {
      this.lastRouteChange = "checkedList";
      this.debouncedDataUpdate();
    },
    sourceData: {
      handler(newValue, oldValue) {
        // 处理逻辑
        if (this.isArrayEmptyOrFilledWithEmptyObjects(newValue)) {
          this.isShowPartsHistory = false;
          return;
        }
        this.isShowPartsHistory =
          this.$route.query.checkedList.includes("公司历程") ||
          this.$route.query.checkedList.includes("全部")
            ? true
            : false;
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
.partsHistory-compared {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
  &::after,
  &::before {
    display: none;
  }
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}
.content-box {
  display: flex;
}
.timeline-container {
  flex: 1;
  padding-top: 20px;
  width: 420px;
  &:not(:first-child) {
    margin-left: 15px;
  }
  .enterpriseName {
    height: 42px;
    line-height: 42px;
    padding-left: 20px;
  }
}
.enterpeiseContent {
  height: 620px;
  overflow-y: scroll;
  background-color: #f9f9f9;
  padding-top: 32px;
  padding-bottom: 25px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border-radius: 3px;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-track-piece {
  background: rgba(202, 33, 33, 0);
  box-shadow: none;
  opacity: 0;
}
</style>
