<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:20:17
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-13 17:40:55
-->
<!--市场动态列表-->
<template>
  <div class="main">
    <div class="left-box">
      <div class="search-box">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="内容分类">
                <el-radio-group v-model="form.releaseColumn">
                  <el-radio :label="null">全部</el-radio>
                  <el-radio
                    :label="item.dictValue"
                    v-for="(item, index) in releaseColumnList"
                    :key="index"
                    >{{ item.dictLabel }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="关键字">
                <el-input
                  v-model="form.title"
                  placeholder="请输入关键字"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item>
                <div class="search-btn-box">
                  <el-button size="small" type="primary" @click="getListData"
                    >查询</el-button
                  >
                  <el-button size="small" @click="resize">重置</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="info-box">
        <div class="info-scroll-box">
          <massageItem
            v-for="(item, index) in cardList"
            :key="index"
            :data="item"
            logRecordsColumn1="国际化"
            logRecordsColumn2="政务及口岸发布"
            logRecordsTableName="tt_port_information"
            linkUrl="/international/government/details/"
          ></massageItem>
        </div>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageData.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="right-box">
      <information
        class="information"
        iconType="1"
        :data="HotList"
        logRecordsColumn1="国际化"
        logRecordsColumn2="政务及口岸发布"
        logRecordsTableName="tt_port_information"
        linkUrl="/international/government/details/"
      />
      <advertisement class="advertisement" />
    </div> -->
  </div>
</template>
<script>
import massageItem from "../common/massageItem";
import advertisement from "../common/advertisement";
import information from "../common/information";
//字典管理
import { selectDictDataByType } from "@/api/dict.js";
import {
  getPortInformation, // 获取数据
  getHotData, // 获取数据
} from "@/api/data_new.js";

export default {
  components: {
    massageItem,
    advertisement,
    information,
  },
  data() {
    return {
      form: {
        title: null,
        releaseColumn: null,
      },
      cardList: [],
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      releaseColumnList: [],
      HotList: [],
    };
  },
  created() {
    this.getListData();
    this.getHotData();
    this.getDict("contentFl", "releaseColumnList");
  },
  methods: {
    // 获取字典数据
    getDict(tyep, resDataName) {
      selectDictDataByType({ dictType: tyep }).then((res) => {
        if (res.ok) {
          this[resDataName] = res.data;
        }
      });
    },
    /**
     * @description: 查询重置
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:28:38
     * @LastEditTime: Do not edit
     */
    resize() {
      this.form = {
        title: null,
        releaseColumn: null,
      };
      this.getListData();
    },
    // 获取列表数据
    getListData() {
      getPortInformation(
        `?pageNo=${this.pageData.pages.pageNo}&pageSize=${this.pageData.pages.pageSize} `,
        this.form
      ).then((res) => {
        if (res.ok) {
          this.cardList = res.data.list;
          this.pageData.total = res.data.count;
        }
      });
    },
    // 获取HotList数据
    getHotData() {
      getHotData().then((res) => {
        if (res.ok) {
          this.HotList = res.data;
        }
      });
    },
    /**
     * @description: 页数切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:01:53
     * @LastEditTime: Do not edit
     */

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.getListData();
    },
    /**
     * @description: 页码切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:02:01
     * @LastEditTime: Do not edit
     */

    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val;
      this.getListData();
    },
  },
};
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1366px) {
  .main-html {
    font-size: 16px !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;
.main {
  display: flex;
  justify-content: space-between;
  margin-top: 0.666rem;
  margin-right: 0.666rem;
  margin-bottom: 0.666rem;
  .left-box {
    width: 100%;
    // width: 52.883rem * #{$BassZoom};
    // height: 33.8rem; //38.666rem;
    background-color: #fff;
    // margin-right: 0.6666rem;
    .search-box {
      width: calc(100% - 0.5rem * #{$BassZoom});
      margin: calc(0.25rem * #{$BassZoom});
      // height: 5.666rem;
      padding: 1rem;
      background-color: #f0f4f9;
      .el-form /deep/ .el-form-item__label {
        text-align: start;
      }
      /deep/ .el-form-item {
        margin-bottom: 0.24rem;
      }
      .search-btn-box {
        float: right;
      }
    }
    .info-box::-webkit-scrollbar {
      display: none;
    }
    .info-box {
      width: 100%;
      // height: 28.134rem;
      padding: 1rem;
      // overflow-y: scroll;
      .info-scroll-box {
        min-height: 23.134rem;
        width: 100%;
      }
    }
    .pages {
      margin: 1rem;
    }
  }
  .right-box {
    width: 25%;
    .information {
      width: 100%;
      // width: 16.25rem * #{$BassZoom};
      height: 24rem;
      margin-bottom: 0.4rem;
    }
    .advertisement {
      width: 100%;
      // width: 16.25rem * #{$BassZoom};
      height: 9.416rem;
    }
  }
}
@media screen and (max-width: 1366px) {
  // .search-box {
  //   height: 6.66rem !important;
  // }
}
</style>
