<template>
  <div class="announcement-container">
    <div class="overview">
      <span class="text">公告数据已更新至该此批次</span>
      <span class="total">{{ data.ggDataNewPc }}</span>
    </div>

    <div class="items">
      <div class="item">
        <span class="lable">整车</span>
        <span class="value">{{ data.ggZCCount }}<span class="unit">条</span></span>
      </div>
      <div class="item">
        <span class="lable">底盘</span>
        <span class="value">{{ data.ggDPCount }}<span class="unit">条</span></span>
      </div>
      <div class="item">
        <span class="lable">摩托车</span>
        <span class="value">{{ data.ggMotorCount }}<span class="unit">条</span></span>
      </div>
      <div class="item">
        <span class="lable">三轮汽车</span>
        <span class="value">{{ data.ggTricycleCount }}<span class="unit">条</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    announcementData: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    logRecords: {
      required: false,
      type: Function,
      default: () => () => null,
    },
  },
  computed: {
    data() {
      const result = {};
      const data = this.announcementData || {};

      Object.entries(data).forEach(([key, value]) => {
        result[key] = this.toLocaleString(value);
      });

      return result;
    },
  },
  methods: {
    toNumber(d) {
      const num = Number(d);
      return Number.isFinite(num) ? num : 0;
    },
    toLocaleString(num) {
      return num.toLocaleString('zh-Hans-CN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.announcement-container {
  margin-bottom: 30px;
  .overview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 14px 20px;
    color: #ffa191;
    background-color: #fff6f6;
    border-radius: 4px;

    .text {
      position: relative;
      padding-left: 34px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 26px;
        height: 26px;
        background-image: url('~@/assets/images/announcement.png');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .total {
      color: #ff4848;
      font-size: 28px;
      font-weight: bold;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
      width: calc(50% - 5px);
      border: 1px solid #ecf7ff;
      border-radius: 4px;
      .lable {
        padding: 6px 16px 6px 12px;
        line-height: 1;
        color: #299ceb;
        background-color: #ecf7ff;
        border-radius: 4px 4px 20px 0;
      }
      .value {
        padding: 6px 14px;
        color: #333;
        font-size: 24px;
        font-weight: bold;
        .unit {
          font-size: 12px;
          font-weight: normal;
        }
      }

      &:nth-of-type(2) {
        border-color: #eafdec;
        .lable {
          color: #50d48f;
          background-color: #eafdec;
        }
      }
      &:nth-of-type(3) {
        border-color: #fef6ed;
        .lable {
          color: #ff7c00;
          background-color: #fef6ed;
        }
      }
      &:nth-of-type(4) {
        border-color: #f2eeff;
        .lable {
          color: #8971cf;
          background-color: #f2eeff;
        }
      }
    }
  }
}
</style>
