<template>
  <div
    class="enterpriseDetail enterprise-dynamic-detail"
    id="dynamic"
    v-if="isShowDynamic"
    data-name="企业动态"
  >
    <el-card :body-style="{ paddingTop: '0' }">
      <div slot="header" class="clearfix">
        <h3 class="title-tag-text">
          企业动态
        </h3>
        <span
          class="more"
          style="float: right;"
          @click="
            toHref(
              '/workInformation/enterpriseInfo/news',
              '_blank',
              '',
              enterpriseData
            )
          "
        >
          查看更多 <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <el-row>
        <el-col :span="12" v-for="(i, o) in dataSource" :key="dataSource.id">
          <el-tooltip
            effect="light"
            :disabled="isDisabledTooltip"
            :content="i.title"
            placement="top"
            :open-delay="300"
          >
            <div
              class="ellipsis enterprise-dynamic-title"
              @mouseover="onMouseOver($event, o)"
            >
              <span
                @click="
                  toHref(`/workInformation/enterpriseInfo/entrepriseNews/enterDetail/${i.id}`, '_blank', i.id)
                "
                >{{ i.title }}</span
              >
              <!-- 隐藏的元素用于宽度计算 -->
              <div class="hidden-title" ref="hiddenTitle">{{ i.title }}</div>
            </div>
          </el-tooltip>
          <span class="date">{{ i.createTime }}</span>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from "@/utils/auth.js";
import { buryPoint } from "@/api/common";
import {
  getEnterpriseDynamicList,
  getEnterpriseBasicInfo
} from "@/api/enterpriseAnalysis.js";
import TextOverTooltip from "@/components/title/index";
export default {
  name: "Dynamic",
  components: {
    TextOverTooltip
  },
  data() {
    return {
      isShowDynamic: false,
      isDisabledTooltip: true, // 是否需要禁止提示
      dynamicParams: {
        pageSize: 8,
        pageNum: 1,
        enterpriseId: this.$route.params.id
      },
      enterpriseData: {},
      dataSource: [],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      }
    };
  },

  created() {
    const params = {
      enterpriseId: this.$route.params.id
    };
    this.getEnterpriseBasicInfo(params);
    this.getEnterpriseDynamicList(this.dynamicParams, "isShowDynamic");
  },

  methods: {
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(routeUrl, openWay, id, item = {}) {
      const routeUrlQuery = {
        path: routeUrl,
        query: {
          enterpriseType:item.enterpriseType,
          enterpriseName:item.enterpriseName
        }
      };
      if (Object.keys(item).length === 0 && item.constructor === Object) {
        delete routeUrlQuery.query;
      }

      const newUrl = this.$router.resolve(routeUrlQuery);

      if (getUserInfo()) {
        if (id) {
          logRecords("企业情报", "企业动态", "", "", id, "", "", "");
        } else {
          logRecords("企业情报", "企业动态", "", "", "", "", "", "");
        }
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, openWay);
          }
        } else {
          window.open(newUrl.href, openWay);
        }
      } else {
        this.showLogin();
      }
    },
    // 移入事件: 判断内容的宽度contentWidth是否大于父级的宽度
    onMouseOver(event, index) {
      let targetElement = event.target;

      // 确保 targetElement 是你想要检查的 span 元素
      if (targetElement.nodeName !== "SPAN") {
        targetElement = targetElement.querySelector("span");
      }

      let parentWidth = targetElement.parentNode.offsetWidth;
      let contentWidth = this.$refs.hiddenTitle[index].offsetWidth;

      // 检查内容宽度是否超出了父元素宽度
      this.isDisabledTooltip = contentWidth <= parentWidth;
    },
    getEnterpriseBasicInfo(params) {
      getEnterpriseBasicInfo(params).then(res => {
        if (res.ok) {
          this.enterpriseData = res.data;
        }
      });
    },
    getEnterpriseDynamicList(params, isShowDynamic) {
      getEnterpriseDynamicList(params).then(res => {
        if (res.ok) {
          if (isShowDynamic) {
            this.isShowDynamic = res.data.itemList.length ? true : false;
          }
          this.dataSource = res.data.itemList;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.enterprise-dynamic-detail {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .el-col {
    max-width: 598px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &:nth-child(2n) {
      padding-left: 37px;
    }
    &:nth-child(2n-1) {
      padding-right: 37px;
    }
  }
  .enterprise-dynamic-title {
    overflow: hidden;
    max-width: calc(100% - 37px); // 根据你的布局调整这里的值

    span {
      cursor: pointer;
      display: inline-block; // 保留 inline-block
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333;
      &:hover {
        color: #3f76ea;
      }
    }

    .hidden-title {
      position: absolute;
      left: -9999px;
      visibility: hidden;
      white-space: nowrap;
    }
  }
}
</style>
<style lang="scss" scoped>
.more {
  color: #999;
}
.title {
}
.date {
  flex: none;
  margin-left: 12px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #bbb;
  text-align: right;
}
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}
</style>
