<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-08-25 10:52:06
-->
<template>
  <div id="tableData">
    <el-table
      :data="
        tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      :header-cell-style="{ background: '#FAFAFA', color: '#333333' }"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        align="center"
        :prop="item.prop"
        :label="item.label"
      >
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="block" style="margin-top:15px;">
      <el-pagination
        align="center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "tableData",
  data() {
    return {
      tableData: [], // 表格数据
      columnList: [], // 表头数据
      currentPage: 1, // 当前页码
      total: 10, // 总条数
      pageSize: 15, // 每页的数据条数
    };
  },
  created() {},
  methods: {
    /**
     * @description: 初始化数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 10:49:20
     * @LastEditTime: Do not edit
     */

    init({ dataList, columnList }) {
      this.tableData = JSON.parse(JSON.stringify(dataList));
      this.columnList = JSON.parse(JSON.stringify(columnList));
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
