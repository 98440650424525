<template>
    <div>
        <div class="proze-head">
            <img src="@/assets/img/Grouptitle.png" alt="" width="187" height="27">
            <h1 class="proze-head-title">关于二手车栏目正式上线的通知</h1>
            <div class="proze-head-box">
                <img src="@/assets/img/Image.png" alt="" width="300" height="300" class="img1">
                <img src="@/assets/img/Image (1).png" alt="" width="300" height="300" class="img2">
                <p class="proze-head-text">为深入了解二手车市场现状，提供更多二手车市场数据分析，全新推出二手车栏目。</p>
<p class="proze-head-text"><span style="font-weight:bold">二手车栏目</span>提供权威丰富的二手车数据资源，从细分领域、车型品牌、区域分布、交易价格、使用年限等多维度进行数据分析，可视化图表更加直观便捷，支持多种图表展现及原始数据导出，同时，支持自由灵活的自定义查询分析。新栏目也为客户提供专业、全面、深入的二手车分析报告，持续跟踪、全方位、多角度帮助客户了解二手车市场。</p>
<p class="proze-head-text">二手车栏目于<span style="font-weight:bold">2022年9月20日</span>正式上线，欢迎广大用户试用、订阅。</p>
            </div>
        </div>
        <div class="proze-content">
            <div class="proze-center">
                <h1 class="proze-title">联系我们</h1>
                <div class="proze-inner">
                    <h2>业务联系人</h2>
                    <p>冯乾隆 15667083689  fengqianlong@catarc.ac.cn</p>
                    <h2>市场联系人</h2>
                    <p>李文娟 18322511031  liwenjuan@catarc.ac.cn</p>
                </div>
                <!-- <h1 class="proze-title">线索奖励</h1>
                <p class="proze-text">我们也非常欢迎各界人士为我们提供行业发展的相关线索，为厘清行业发展趋势贡献力量。同样，线索提供也是有相关金额奖励。</p>
                <h1 class="proze-title">读者群</h1>
                <p class="proze-text">为更好的服务行业、服务企业，中国汽车工业信息网建立了Autoinfo读者群，入群可及时获取行业政策、标准、战略发展动态、研究报告等，还有机会获取中国汽车工业信息网网主办的各类会议的免费参会名额。</p>
                <h1 class="proze-title">投稿、提供线索或入群方式</h1>
                <p class="proze-text">请扫描下面二维码添加工作人员企业微信，进行投稿或者入读者群。</p>
                <div class="text-center" style="margin-top:-8px">
                    <img src="@/assets/img/qywxewm.png" alt="" width="226" height="224">
                </div> -->
            </div>
        </div>
        <Vfooter />
    </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import { download } from "@/api/common.js";
import { downloadFile } from "@/utils/download.js";
export default {
    components: {
        Vfooter,
    },
    data() {
        return {
            content: {},
        };
    },
    created() {},
    methods: {
        ttPrizeEssay() {
            ttPrizeEssay().then((res) => {
                if (res.ok) {
                    this.content = res.data[0];
                }
            });
        },
        //下载
        downBtn(item) {
            let self = this;
            download({ fileUrl: item.attachUrl }).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                    try {
                        JSON.parse(e.target.result);
                        let msg = JSON.parse(e.target.result);
                        self.$message({
                            showClose: true,
                            message: msg.message,
                            type: "error",
                        });
                    } catch (err) {
                        downloadFile(res, item.attachName);
                    }
                });
            });
        },
    },
};
</script>
<style  lang="scss" scoped>
.proze-head {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 585px;
    background: #0d57bc;
    overflow: hidden;
    .proze-head-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #fff;
        margin-bottom: 53px;
    }
    .proze-head-box {
        position: relative;
        width: 732px;
        height: 300px;
        background: #f2f8ff;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 33px 33px 43px 45px;
        .img1,
        .img2 {
            position: absolute;
        }
        .img1 {
            left: -336px;
            bottom: 53px;
        }
        .img2 {
            right: -320px;
            bottom: 150px;
        }
        .proze-head-text {
            text-indent:2em;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: #03295b;
        }
    }
}
.proze-content {
    width: 100%;
    background: #f2f8ff;
}
.proze-center {
    width: 732px;
    margin: 0 auto;
    background: #fff;
    padding: 29px 39px 63px;
    .proze-title {
        width: 100%;
        height: 56px;
        line-height: 56px;
        text-align: center;
        background: #f2f8ff;
        border-radius: 2px;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #0d57bc;
    }
    .proze-text {
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.03em;
        color: #000000;
        margin: 32px 0 40px;
    }
}
.proze-inner {
    margin: 32px 0 40px;
    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #000000;
        margin-bottom: 10px;
    }
    p {
        font-size: 20px;
        line-height: 160%;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 16px;
    }
}
.seamless-warp {
  line-height:30px;
  height: 60px;
  overflow: hidden;
  //根据自己需要的高度来调整
}
</style>