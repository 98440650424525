//标准栏目
import $http from '@/plugins/http.js'
//最新标准
export function newStandard(params) {
    return $http.get('/api/customerStandard/newStandard', params)
}
//标准征求意见稿
export function solicitComments(params) {
    return $http.post('/api/customerStandard/solicitComments', params)
}
//标准动态
export function standardDynamic(params) {
    return $http.get('/api/customerStandard/standardDynamic', params)
}
//标准库查询
export function standardLibrary(params) {
    return $http.get('/api/customerStandard/standardLibrary', params)
}
//商用车标准
export function getStandardSyc(params) {
    return $http.get('/api/standard/ttStandard/getStandardSyc', params)
}
//标准解读
export function standardUnscramble(params) {
    return $http.get('/api/customerStandard/standardUnscramble', params)
}
//标准树对应标准内容
export function getStandardContent(params) {
    return $http.get('/api/customerStandard/getStandardContent', params)
}
//标准树
export function standardTree(params) {
    return $http.get('/api/customerStandard/standardTree', params)
}
//标准大数据
export function standardBigData(params) {
    return $http.post('/api/customerStandard/standardBigData', params)
}
//标准解读详情
export function ttStandardUnscramble(params) {
    return $http.get('/api/standard/ttStandardUnscramble/'+params)
}
//标准征求意见稿详情
export function ttSolicitComments(params) {
    return $http.get('/api/standard/ttSolicitComments/'+params)
}
//标准动态详情
export function ttStandardDynamic(params) {
    return $http.get('/api/standard/ttStandardDynamic/'+params)
}
//最新标准详情--标准主体
export function ttStandard(params) {
    return $http.get('/api/standard/ttStandard/'+params)
}
//标准主体--code判断
export function ttStandardCode(params) {
    return $http.post('/api/standard/ttStandard/selectByCode' ,params)
}
//标准树对应标准内容详情
export function getStandardContentDetail(params) {
    return $http.get('/api/customerStandard/getStandardContentDetail', params)
}

//标准大数据导出
export function standardDataExport(params) {
    return $http.DownloadDb('/api/customerStandard/standardDataExport', params)
}
//标准库导出
export function standardDataExportZip(params){
    return $http.Downloadget('/api/customerStandard/standardDataExportZip', params)
}
//标准库导出
export function solicitCommentsExportZip(params){
    return $http.Downloadget('/api/standard/ttSolicitComments/solicitCommentsExportZip', params)
}

export function queryByPageInternational(params) {
  return $http.get('/api/standard/ttStandard/queryByPageInternational', params)
}
