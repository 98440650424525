//公告
import $http from '@/plugins/http.js'


//公告信息数量
export function getInfoCount() {
    return $http.get('/api/customerNotice/getInfoCount')
}

//公告批文
export function ggApproval(params) {
    return $http.get('/api/customerNotice/ggApproval', params)
}

//公告公示
export function ggPublic(params) {
    return $http.get('/api/customerNotice/ggPublic', params)
}

//公告整车、摩托、三轮分页查询
export function categoryPage(params) {
    return $http.get('/api/customerNotice/page', params)
}
//获取公告整车库批次
export function getAllZcPc() {
    return $http.get('/api/customerNotice/getAllZcPc')
}
//获取公告底盘库批次
export function getAllDpPc() {
    return $http.get('/api/customerNotice/getAllDpPc')
}
//下载
export function exportGGZc(params) {
    return $http.DownloadDb('/api/customerNotice/exportGGZc?ids='+params)
}
//根据底盘id查看公告底盘详情
export function getDpByDpid(url,query) {
    return $http.get(url, query)
}

//查看公告底盘详情
export function checkDpDetail(url,query) {
    return $http.get(url, query)
}

//公告底盘库EXCEL导出
export function exportGGDp(params) {
    return $http.DownloadDb('/api/customerNotice/exportGGDp?ids='+params)
}

//公告底盘库分页查询
export function pageDp(params) {
    return $http.get('/api/customerNotice/pageDp', params)
}
//获取公告公示所有批次
export function getAllGGPublicPc(params) {
    return $http.get('/api/customerNotice/getAllGGPublicPc', params)
}
//公告公示分页查询
export function pageGGPublic(params) {
    return $http.get('/api/customerNotice/pageGGPublic', params)
}

//公告底盘库查询列表
export function getDpQueryList() {
    return $http.get('/api/customerNotice/getDpQueryList')
}
//公告整车库查询列表
export function getZcQueryList() {
    return $http.get('/api/customerNotice/getZcQueryList')
}

//公告整车库查询详情
export function ggYzk(params) {
    return $http.get('/api/customerNotice/ggYzk/'+params)
}
//公告动态详情
export function dynamicApproval(params) {
    return $http.get('/api/customerNotice/dynamicApproval/'+params)
}

//公告公示查询详情
export function ggPublicContent(params) {
    return $http.get('/api/customerNotice/ggPublic/'+params)
}
//公告批文查询详情
export function ggApprovalContent(params) {
    return $http.get('/api/customerNotice/ggApproval/'+params)
}


//公告底盘库查询详情
export function ggDpk(params) {
    return $http.get('/api/customerNotice/ggDpk/'+params)
}

//公告整车库查询详情多个
export function ggZcCompare(params) {
    return $http.get('/api/customerNotice/ggZcCompare',params)
}
//公告公示查询详情多个
export function ggPublicCompare(params) {
    return $http.get('/api/customerNotice/ggPublicCompare',params)
}
//公告底盘库查询详情多个
export function ggDpCompare(params) {
    return $http.get('/api/customerNotice/ggDpCompare',params)
}

//公告整车库查看历史批次
export function ggZCHistoryPc(params) {
    return $http.get('/api/customerNotice/ggZCHistoryPc',params)
}
//公告整车库历史批次对比车型
export function historyCompare(params) {
    return $http.get('/api/customerNotice/historyCompare',params)
}



//公告公示导出excel
export function exportGGPublic(params) {
    return $http.DownloadDb('/api/customerNotice/exportGGPublic?ids='+params)
}

//获取最新公告时间
export function getggLastUpdateTime() {
    return $http.get('/api/customerNotice/getggLastUpdateTime')
}

//获取最新公告公示时间
export function getggPublicLastUpdateTime() {
    return $http.get('/api/customerNotice/getggPublicLastUpdateTime')
}

//导出有效车型
export function exportValidGGZc(params) {
    return $http.DownloadDb('/api/customerNotice/exportValidGGZc',params)
}
 
 //导出有效底盘
export function exportValidGGDp(params) {
    return $http.DownloadDb('/api/customerNotice/exportValidGGDp',params)
}
  