<template>
  <PageContainer>

    <!--    <div class="not-login-box" v-if="!showAll">-->
    <!--      <VnotLogin title="二手车" text="二手车栏目围绕二手车数据本身从深度和广度全方位展开，展现二手车市场现状、洞察二手车市场走势。" tableTitle="最新二手车报告"-->
    <!--                 :showTips="showTips"/>-->
    <!--    </div>-->

    <div v-loading="loading" class="box_box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="交易价格分布" name="41"></el-tab-pane>
        <el-tab-pane label="不同交易价格区域分布" name="42"></el-tab-pane>
        <el-tab-pane label="不同交易价格车龄分布" name="43"></el-tab-pane>
        <el-tab-pane label="不同交易价格国产进口分布" name="44"></el-tab-pane>
        <el-tab-pane label="不同交易价格排放标准分布" name="45"></el-tab-pane>
        <!-- <el-select v-model="yearMonth" size="small" @change="selectChange">
                <el-option v-for="item in yearMonthData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select> -->


      </el-tabs>

      <template>
        <div style="margin-left: 23px;font-weight:bold;margin-top: 5px;">筛选时间：
          <el-select v-model="yearMonth" size="small" @change="selectChange">
            <el-option v-for="item in yearMonthData" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>

        <div v-if="show && !showP" id="echartsId" style="width: 100%; height: 580px;margin-top:10px"></div>
        <div v-else>
          <img style="width:100%" src="@/assets/img/jyjgqjfx.jpg" alt=""/>
          <center style="height: 1px;">
            <div
                style="position:relative;width:300px;height: 94px;bottom:220px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

              <div style="padding-top: 20px">
                <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
              </div>

              <div style="margin: 10px">
                没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

              </div>
            </div>
            <div
                style="position:relative;width:320px;height:94px;bottom:220px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                v-else>
              <div style="padding-top: 20px;padding-left: 22px;">
                <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
              </div>
              <div style="padding: 10px 20px;text-align: center">
                <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
              </div>
            </div>
          </center>

        </div>
        <div style="margin: 60px" v-has-role="'freeusers'">
          <span>{{ this.danWei }}</span>
          <!-- // 导出按钮  -->
          <el-button type="primary" style="float: right" icon="el-icon-upload" @click="exportEvent">导出</el-button>


          <div v-if="show && !showP">
            <el-table :data="modelList" :border="true" size="small"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }" id="echartsTable">
              <el-table-column :label="item" v-for="(item, index) in mokuaiTableTitle" :key="item" align="center"
                               style="user-select: initial;">
                <template slot-scope="scope">
            <span v-for="(item2, index2) in scope.row" :key="index2">
              <span v-if="index2 == index">
                {{ scope.row[index2] }}
              </span>
            </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <img style="width:100%" src="@/assets/img/jyjgqjfxbg.jpg" alt=""/>

          </div>
        </div>
      </template>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 二手车图表
 * @param {*}
 * @return {*}
 */
// 引入导出插件
import FileSaver from "file-saver";
import XLSX from "xlsx";
import * as echarts from "echarts";
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {getYearMonth, getEchars} from "@/api/usedCar.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //   VnotLogin,
  // },
  data() {
    return {
      activeName: "41",
      showTips: [
        {
          title: "数据资源",
          text: "丰富可靠的数据资源、多样的可视化展示",
        },
        {
          title: "自定义分析",
          text: "自主筛选、高效查询，支持多维度分析和多种图表展现",
        },
        {
          title: "市场报告",
          text: "权威解读、深度分析，全面展现二手车市场现状及走势",
        },
        {
          title: "查看下载",
          text: "在线查看、下载编辑，海量数据和市场报告无限量下载",
        },
      ],
      loading: false,
      danWei: "单位：万辆，%",
      yearMonthData: [],
      yearMonth: "",
      valueId: "41",
      mokuaiTableTitle: [],
      modelList: [],
      fileName: "",
      show: false,
      showAll: true,
      form: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "二手车",
        userId: ""
      },
      showP: false,
    };
  },

  created() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('二手车')) {
          this.showP = true;
          this.getYearMonth();
        } else {
          this.init(this.valueId);
        }
      } else {
        this.init(this.valueId);
      }
      this.show = true;

      this.showAll = true;
    } else {
      this.showAll = false;
      this.getYearMonth();
    }
  },
  mounted() {
    // 页面加载设置高度自适应  
    this.resizeDom();
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    exportEvent() {
      this.form.eventType = "0";
      if (getUserInfo()) {

        logRecords('二手车','总体市场表现分析','3','used_car_data','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('二手车')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            }else {
              let grid = XLSX.utils.table_to_book(
                  document.querySelector("#echartsTable"),
                  {raw: true}
              );
              let workbook = XLSX.write(grid, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(
                    new Blob([workbook], {
                      type: "application/octet-stream",
                    }),
                    this.fileName + ".xlsx"
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, workbook);
              }
              return workbook;
            }

          }
        } else {
          let grid = XLSX.utils.table_to_book(
              document.querySelector("#echartsTable"),
              {raw: true}
          );
          let workbook = XLSX.write(grid, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                new Blob([workbook], {
                  type: "application/octet-stream",
                }),
                this.fileName + ".xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, workbook);
          }
          return workbook;
        }
      } else {
        this.showLogin();
      }

    },
    handleClick(tab, event) {
      this.valueId = tab.name;
      if (tab.name == 41) {
        this.danWei = "单位：万辆，%";
      } else {
        this.danWei = "单位：%";
      }
      this.form.eventType = "2";

      if (getUserInfo()) {

        logRecords('二手车','交易价格区间分析','3','used_car_data','','','','','筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('二手车')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
              this.init(this.valueId);

          }
        } else {
          this.init(this.valueId);
        }
      } else {
        this.showLogin();
      }
    },

    selectChange(yearMonth) {
      this.form.eventType = "2";
      if (getUserInfo()) {
        logRecords('二手车','交易价格区间分析','3','used_car_data','','','','','时间筛选')
       if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

         let menuName = getUserInfo().menuName;

          if (!menuName.includes('二手车')) {
            this.sqsyLogin();
            this.insertBuryPoint();
            this.getYearMonth();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
              this.getYearMonth();
            } else {
              this.yearMonth = yearMonth;
              this.getEchars(this.valueId);
            }
          }
        } else {
          this.yearMonth = yearMonth;
          this.getEchars(this.valueId);
        }
      } else {
        this.showLogin();
      }
    },

    insertBuryPoint() {
      this.form.userId = getUserInfo().userId;
      buryPoint(this.form);
    },
    async init(id) {
      this.loading = true;
      //获取年月
      await getYearMonth().then((result) => {
        if (result.ok) {

          this.yearMonthData = result.data;
          this.yearMonth = this.yearMonthData[0];
        }
      });

      //获取图表
      await getEchars({id: id, nianYue: this.yearMonth}).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsId"));
        ``
        if (result.ok) {
          let data = result.data;
          this.fileName = data.title;
          let templateFile = data.templateFile;
          eval(templateFile);


          data.xAxisData.unshift(" ");
          this.mokuaiTableTitle = data.xAxisData;
          this.modelList = [];
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.modelList.push(data.seriesData[x]);
          }
          this.isShow = true;


          this.loading = false;
        }
      });
    },
    //获取年月
    getYearMonth() {
      getYearMonth().then((result) => {
        if (result.ok) {

          this.yearMonthData = result.data;
          this.yearMonth = this.yearMonthData[0];
        }
      });
    },
    //获取图表
    getEchars(id) {
      this.loading = true;
      getEchars({id: id, nianYue: this.yearMonth}).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsId"));
        ``
        if (result.ok) {
          let data = result.data;
          this.fileName = data.title;
          let templateFile = data.templateFile;
          eval(templateFile);


          data.xAxisData.unshift(" ");
          this.mokuaiTableTitle = data.xAxisData;
          this.modelList = [];
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.modelList.push(data.seriesData[x]);
          }
          this.isShow = true;


          this.loading = false;
        }
      });
    },
    //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度以达到自适应目的
    resizeDom() {
      var backPersonDom = document.getElementById("echartsId");
      if (backPersonDom != null) {
        const boxWidth = document.getElementById("echartsId").clientWidth;
        backPersonDom.style.height = boxWidth / 3 + "px";
      }
    },
  }
  ,
}
;
</script>
<style lang="scss" scoped>
.box_box {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 90px;
    // border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
