<template>
  <div class="elevator">
    <div class="toggle-trigger" @click="isCollapse = !isCollapse">
      <div class="inner">
        <i :class="['el-icon-caret-right', isCollapse ? 'collapse' : '']" />
      </div>
    </div>
    <div :class="['floor-container', isCollapse ? 'collapse' : '']">
      <div class="inner">
        <div
          v-for="item in floors"
          :key="item.targetFloorRef"
          :class="['floor', activeFloorRef === item.targetFloorRef ? 'active' : '']"
          @click="handleClickFloorItem(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResizeListener from 'element-resize-detector'

export default {
  name: 'Elevator',
  props: {
    floors: {
      type: Array,
      required: true
    },
    scrollDomSelector: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 负责滚动的父容器
      scrollDom: null,
      // 比 floors 多一个 scrollTop 属性的数组
      elevatorFloors: [],
      // 当前激活的电梯层
      activeFloorRef: null,
      // 监听父容器的尺寸变化
      resizeListenerInstance: null,
      // 是否折叠
      isCollapse: false
    }
  },
  watch: {
    floors: {
      deep: true,
      immediate: true,
      handler(data) {
        this.init(data);
      },
    },
  },
  mounted() {
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
    this.resizeListenerInstance.removeAllListeners(this.scrollDom)
  },
  methods: {
    init(floors) {
      try {
        const scrollDom = document.querySelector(this.scrollDomSelector)
        if (!scrollDom) {
          throw new Error('scrollDomSelector 没有对应的DOM元素')
        }

        this.scrollDom = scrollDom
        this.$set(this, 'elevatorFloors', floors.map(floor => {
          if (!floor.ready) {
            throw new Error('Invalid DOM');
          }
          return floor;
        }))

        document.addEventListener('scroll', this.handleScroll)
        this.handleScroll()
        this.resizeListenerInstance = ResizeListener({
          strategy: 'scroll',
          callOnAdd: false
        })
        this.resizeListenerInstance.listenTo(this.scrollDom, this.handleScroll)
      } catch {}
    },
    handleScroll() {
      if (!this.scrollDom) {
        console.warn('elevator需要scrollDom!')
        return
      }
      const scrollTop = this.scrollDom.scrollTop
      let newActiveFloorRef = null
      for (let i = 0; i < this.elevatorFloors.length; i++) {
        const elevatorFloor = this.elevatorFloors[i]
        if (
          elevatorFloor.offsetTop >= scrollTop &&
          elevatorFloor.offsetTop < scrollTop + this.scrollDom.clientHeight
        ) {
          newActiveFloorRef = elevatorFloor.targetFloorRef
          break
        }
      }
      if (newActiveFloorRef == null) {
        for (let i = 0; i < this.elevatorFloors.length; i++) {
          const elevatorFloor = this.elevatorFloors[i]
          if (
            elevatorFloor.offsetTop < scrollTop &&
            elevatorFloor.offsetTop + elevatorFloor.offsetHeight >= scrollTop + this.scrollDom.clientHeight
          ) {
            newActiveFloorRef = elevatorFloor.targetFloorRef
            break
          }
        }
      }
      this.activeFloorRef = newActiveFloorRef === null ? this.activeFloorRef : newActiveFloorRef
    },
    handleClickFloorItem(floor) {
      if (!this.scrollDom) {
        console.warn('elevator需要scrollDom!')
        return
      }
      this.scrollDom.scrollTo({
        top: floor.offsetTop,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08);

.elevator {
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 2001;
  display: flex;
  align-items: center;

  .toggle-trigger {
    box-shadow: $box-shadow;

    .inner {
      width: 12px;
      height: 44px;
      cursor: pointer;
      background-color: #fff;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #909399;

      &::before, &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 12px solid #fff;
        border-bottom: 12px solid transparent;
        border-top: 12px solid transparent;
      }

      &::before {
        top: -12px;
      }

      &::after {
        bottom: -12px;
      }

      .el-icon-caret-right {
        transition: transform .3s;
      }

      .collapse {
        transform: rotateY(180deg);
      }
    }
  }

  .floor-container {
    width: 144px;
    background-color: #fff;
    box-shadow: $box-shadow;
    overflow-x: hidden;
    transition: width .3s;

    &.collapse {
      width: 0;
    }

    .inner {
      $border-width: 1px;
      margin: 12px;
      border-left: $border-width solid #CED0D8;

      .floor {
        height: 30px;
        line-height: 30px;
        color: #040B29;
        position: relative;
        cursor: pointer;
        padding-left: 9px;
        transition: color 0.2s;
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          left: -#{$border-width};
          top: 0;
          bottom: 0;
          background-color: transparent;
          transition: background-color 0.2s;
          width: 1px;
        }

        &.active {
          color: #0D57BC;

          &::before {
            background-color: #0D57BC;
          }
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

}
</style>
