import { download } from "@/api/common.js"
import { downloadFile } from '@/utils/download.js'
import {getUserInfo} from "@/utils/auth";
export default {
    methods: {
        //选中
        checkFun(item) {
            if (!item.check) {
                this.checkData.push({
                    id: item.id,
                    fileInfo: item.fileInfo,
                });
            } else {
                for (let i = 0; i < this.checkData.length; i++) {
                    if (this.checkData[i].id == item.id) {
                        this.checkData.splice(i, 1);
                    }
                }
            }
            for (let i = 0; i < this.list.length; i++) {
                if (item.id == this.list[i].id) {
                    this.list[i].check = !this.list[i].check;
                }
            }
        },
        //过滤文件
        filterFile(files) {
            let fileArr = []
            files.forEach(item => {
                if (item.fileInfo.length > 0) {
                    item.fileInfo.forEach(citem => {
                        fileArr.push({ ...citem, id: item.id })
                    })
                }
            });
            return fileArr
        },
        //全部下载
        downAll() {
            if (getUserInfo()) {
                let files = this.filterFile(this.checkData)
                let self = this;
                files.forEach(item => {
                    download({ fileUrl: item.url }).then(res => {
                        var reader = new FileReader();
                        reader.readAsText(res);
                        reader.addEventListener("loadend", function (e) {
                            try {
                                JSON.parse(e.target.result);
                                let msg = JSON.parse(e.target.result);
                                self.$message({
                                    showClose: true,
                                    message: msg.message,
                                    type: "error",
                                });
                            } catch (err) {
                                downloadFile(res, item.fileName);
                            }
                        });
                    })
                });
              }else {
                this.$Vlogin2({flag: true});
            }
        }
    },
}