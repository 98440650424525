<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
    >
      <el-tree
        :data="data"
        show-checkbox
        node-key="id"
        :props="defaultProps"
        ref="tree"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="getNode()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ttDemension } from "@/api/dict.js";
export default {
  props: {
    visible: false,
    code: "",
    title:"",
    getData:{
      type:Array,
      default:[]
    },
  },
  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  created() {
    this.ttDemension({ code: this.code });
  },
  watch: {
    visible() {
      this.$nextTick(function () {
        this.$refs.tree.setCheckedKeys(this.getData);
      });
    },
  },
  methods: {
    ttDemension(parmas) {
      ttDemension(parmas).then((res) => {
        this.data = res.data;
      });
    },
    //获取数据
    getNode() {
      let data = {
        nodeDataName: this.getSimpleCheckedNodes(this.$refs.tree.store),
        nodeDataValue: this.$refs.tree.getCheckedKeys(),
      };
      this.$emit("getNode", data);
    },
    //返回父级
    getSimpleCheckedNodes(store) {
      // 定义数组
      const checkedNodes = [];
      // 判断是否为全选，若为全选状态返回被全选的节点，不为全选状态正常返回被选中的节点
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach((child) => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    //关闭
    close() {
      this.$emit("close");
    }
  },
};
</script>
<style lang="scss" scoped>
</style>