<template>
  <div class="nav-box" :class="childrenMenu.length != 0 ?'nav-box-left':''">
    <div class="nav-box-l">
      <img src="../assets/img/Group47.png" alt="" width="210" height="39">
      <div class="nav-box-menu">
        <template v-for="item in menu">
          <router-link v-if="item.meta && item.meta.hidden == 0" :key="item.name" :to="item.path"
            :class="{ ac: item.name == acName }">
            {{ item.name }}</router-link>
        </template>
      </div>
    </div>
    <div class="nav-box-r">
      <!--            <div class="nav-search">-->
      <!--                    <input type="text" placeholder="输入搜索内容" v-model="searchValue" class="search-input" @keyup.enter="searchBtn">-->
      <!--                    <img src="@/assets/img/Group 506.png" alt="" class="search-icon" @click="searchBtn">-->
      <!--                </div>-->
      <div v-if="show" class="user-info">
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link">
            <img src="@/assets/img/20141229151653_HiM8B.jpeg" alt="">
            <span>{{ userName }}</span>
            <i class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="collection">我的收藏</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button v-else type="primary" plain @click="handleClick()">
        登录
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menu: [],
    show: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: ''
    },
    checkLoginName: {
      type: String,
      default: ''
    },
    childrenMenu:[]
  },
  data() {
    return {
      searchValue: ''
    }
  },
  computed: {
    acName() {
      const routeName = this.$route.matched[0].name || ''
      return routeName
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
    handleCommand(command) {
      this.$emit('command', command)
    },
    searchBtn() {
      if (this.keywords == '') {
        this.$notify({
          title: '提示',
          message: '请输入搜索内容',
          type: 'warning',
          duration: 1800,
          offset: 23
        });
        return false
      }
      this.$emit('searchs', this.searchValue)
      this.searchValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-box,
.nav-box-l,
.nav-box-r,
.nav-box-menu {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.nav-box {
  justify-content: space-between;
  // height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: #f6f6f6 !important;
  padding-bottom: 22px;
}

.nav-box-menu {
  margin-left: 34px;
}

.nav-box-menu a {
  position: relative;
  display: block;
  color: #4f4f4f;
  margin-right: 32px;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
}

.nav-box-menu a.ac {
  font-weight: bold;
}

.nav-box-menu a.ac::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #154385;
}

.nav-box-r {
  margin-right: 20px;
}

.nav-box-l {
  img {
    margin-left: 20px;
  }
}

.user-info {
  font-weight: 500;
  color: #333;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    padding-left: 8px;
  }
}

.nav-search {
  position: relative;
  width: 290px;
  height: 44px;
  background: rgba(13, 87, 188, 0.03);
  padding: 0 35px 0 17px;
  margin-right: 20px;

  .search-input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }

  .search-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 23px;
    margin-top: -8px;
    cursor: pointer;
  }

  .search-input:focus {
    outline: 0px;
  }
}
</style>
