<template>
  <div class="comp-container">
    <div class="setting-item">
      <div class="title">标题</div>
      <el-input
        v-model="title"
        :maxlength="15"
        placeholder="请输入"
        size="small"
        @input="handleTitleInput"
      />
    </div>
    <div v-show="showColorsSelect" class="setting-item">
      <div class="title">颜色</div>
      <el-select
        v-model="selectedColorsName"
        size="small"
        @change="updateGraphSettings"
      >
        <el-option
          v-for="item in colorsOptionList"
          :key="item.value"
          :value="item.value"
          class="colors-select-option"
        >
          <div
            v-for="colorItem in item.colors"
            :key="colorItem"
            class="colors-block"
            :style="{background: colorItem}"
          />
          <div class="title">{{ item.value }}</div>
        </el-option>
      </el-select>
    </div>
    <div v-show="showSingleColorSelect" class="setting-item">
      <div class="title">颜色</div>
      <el-select
        v-model="selectedSingleColorName"
        size="small"
        @change="updateGraphSettings"
      >
        <el-option
          v-for="item in singleColorOptionList"
          :key="item.value"
          :value="item.value"
          class="colors-select-option"
        >
          <div
            class="single-color-block"
            :style="{background: item.itemBackground || item.color}"
          />
          <div class="title">{{ item.value }}</div>
        </el-option>
      </el-select>
    </div>
    <div v-show="isDisplayWithCombinationChart && combinationChartTargetNames.length > 0" class="setting-item">
      <div class="title">指标图标</div>
      <div v-for="targetName in combinationChartTargetNames" :key="targetName" class="target-icon-wrapper">
        <div class="select-wrapper">
          <el-select
            v-model="combinationTargetSetting[targetName].yAxisIndex"
            size="small"
            style="width: 70px;"
            @change="updateGraphSettings"
          >
            <el-option label="主轴" :value="0" />
            <el-option label="次轴" :value="1" />
          </el-select>
          <div class="chart-type-select-wrapper">
            <img
              class="img"
              :src="combinationTargetSetting[targetName].type === 'bar' ?
                require('@/assets/img/newData/graphSetting-bar.png') :
                require('@/assets/img/newData/graphSetting-line.png')"
              alt=""
            >
            <el-select
              v-model="combinationTargetSetting[targetName].type"
              size="small"
              style="width: 56px;"
              popper-class="dragging-combination-diagram-select"
              @change="updateGraphSettings"
            >
              <el-option value="bar" label="bar">
                <img :src="require('@/assets/img/newData/graphSetting-bar.png')" alt="">
              </el-option>
              <el-option value="line" label="line">
                <img :src="require('@/assets/img/newData/graphSetting-line.png')" alt="">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="target-name" :title="targetName">{{ targetName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GraphSetting',
  props: {
    activeDisplayModeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: undefined,
      selectedColorsName: '默认',
      selectedSingleColorName: '浅蓝',
      titleInputTimer: null,
      // 组合图的指标名称列表，用于 v-for 生成组合图的图形设置选项
      combinationChartTargetNames: [],
      // 用于 v-model 存储组合图的图形设置信息
      combinationTargetSetting: null
    }
  },
  computed: {
    // 是否展示多颜色选择器
    showColorsSelect() {
      return this.getIfShowColorsSelect(this.activeDisplayModeName)
    },
    // 是否展示单颜色选择器
    showSingleColorSelect() {
      return this.getIfShowSingleColorSelect(this.activeDisplayModeName)
    },
    // 是否在以 组合图 的形式展示
    isDisplayWithCombinationChart() {
      return this.activeDisplayModeName  === '组合图'
    },
    colorsOptionList() {
      return [{
        value: '默认',
        colors: ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', '#4DC367', '#FF6913']
      }, {
        value: '科技',
        colors: ['#0D57BC', '#7CFAF3', '#2ED5FF', '#004F80', '#A8DBFF', '#33B2FF']
      }, {
        value: '简约',
        colors: ['#165DFF', '#86AEEB', '#14C9C9', '#3CBCF3', '#98B5CF', '#5FC976']
      }, {
        value: '素雅',
        colors: ['#219EBC', '#CB997E', '#DDBEA9', '#B7B7A4', '#457B9D', '#A8DADC']
      }, {
        value: '彩虹',
        colors: ['#F4547A', '#FDA153', '#FFE141', '#ABD229', '#64C1AF', '#2787C3']
      }, {
        value: '复古',
        colors: ['#DC8874', '#B66C80', '#895B96', '#D1A776', '#DBD4A4', '#7A905B']
      }, {
        value: '鲜艳',
        colors: ['#16AEEF', '#946BE1', '#FF781E', '#FF9F21', '#F9CF57', '#5DC264']
      }, {
        value: '明亮',
        colors: ['#FF9707', '#FE006B', '#FEF8D4', '#012988', '#2362D4', '#FC9A99']
      }]
    },
    // 单颜色选择器的下拉列表
    singleColorOptionList() {
      return [{
        value: '浅蓝',
        color: '#ECF7FF'
      }, {
        value: '浅绿',
        color: '#EAF9EF'
      },  {
        value: '浅紫',
        color: '#F7F4FF'
      }, {
        value: '浅红',
        color: '#FFF0F0'
      }, {
        value: '线条',
        color: '#FFF',
        itemBackground: '#DCDFE6', // 下拉选项的颜色，如果没有则取 color
        border: '#DCDFE6' // 控制指标卡的边框颜色，如果没有则取 color
      }]
    }
  },
  methods: {
    // 给 title 的修改加一个防抖
    handleTitleInput() {
      if (this.titleInputTimer) {
        clearTimeout(this.titleInputTimer)
      }
      this.titleInputTimer = setTimeout(() => {
        this.updateGraphSettings()
      }, 300)
    },
    // 用于切换图表类型后，重置并获取默认的图形设置（只有切换到非组合图的图形时，该方法才会被调用）
    resetToDefaultGraphSetting(displayModeName) {
      this.title = undefined
      const newGraphSettings = {
        title: this.title
      }
      // 如果显示多颜色选择器
      if (this.getIfShowColorsSelect(displayModeName)) {
        this.selectedColorsName = this.colorsOptionList[0].value
        newGraphSettings.colors = this.colorsOptionList[0].colors
      }
      // 如果显示单颜色选择器
      if (this.getIfShowSingleColorSelect(displayModeName)) {
        this.selectedSingleColorName = this.singleColorOptionList[0].value
        newGraphSettings.singleColor = this.singleColorOptionList[0].color
        newGraphSettings.border = this.singleColorOptionList[0].border || this.singleColorOptionList[0].color
      }
      // 清空没用的数据
      this.$set(this, 'combinationChartTargetNames', [])
      this.$set(this, 'combinationTargetSetting', null)
      return newGraphSettings
    },
    // 修改图形设置的回调
    updateGraphSettings() {
      const graphSettings = {
        title: this.title
      }
      // 如果有多颜色选择器
      if (this.showColorsSelect) {
        graphSettings.colors = this.colorsOptionList.find(item => item.value === this.selectedColorsName)?.colors
      }
      // 如果有单颜色选择器
      if (this.showSingleColorSelect) {
        const selectColor = this.singleColorOptionList.find(item => item.value === this.selectedSingleColorName)
        if (selectColor) {
          graphSettings.singleColor = selectColor.color
          graphSettings.border = selectColor.border || selectColor.color
        }
      }
      // 如果在以 组合图 的形式展示
      if (this.isDisplayWithCombinationChart) {
        // 追加组合图的图形设置信息
        graphSettings.combinationTargetSetting = this.combinationTargetSetting
      }
      this.$emit('change', graphSettings)
    },
    // 根据接口响应的数据，生成组合图的 指标图标 图形设置
    generateCombinationChartSetting(respData) {
      // combinationChartTargetNames 用于 v-for
      this.$set(this, 'combinationChartTargetNames', respData.targets.map(item => item.name))
      const newCombinationTargetSetting = {}
      respData.targets.forEach(item => {
        newCombinationTargetSetting[item.name] = {
          yAxisIndex: item.yAxisIndex,
          type: item.type,
          stack: item.stack
        }
      })
      // combinationTargetSetting 用于让组件 v-model
      this.$set(this, 'combinationTargetSetting', newCombinationTargetSetting)
    },
    // 是否显示多颜色选择器
    getIfShowColorsSelect(displayModeName) {
      return ['表格', '指标卡', '交叉表'].indexOf(displayModeName) === -1
    },
    // 是否显示单颜色选择器
    getIfShowSingleColorSelect(displayModeName) {
      return displayModeName === '指标卡'
    }
  }
}
</script>

<style lang="scss" scoped>

.comp-container {
  padding: 24px;
}

.setting-item {
  .title {
    color: #606266;
    margin-bottom: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.colors-select-option {
  display: flex;
  align-items: center;
  color: #333333;

  .colors-block {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 4px;
  }

  .title {
    font-weight: 400;
    margin-left: 8px;
  }

  .single-color-block {
    flex: 1;
    height: 16px;
    border-radius: 2px;
  }

  &.selected, &.hover {
    color: #0D57BC;
    background-color: #F5F7FA;
  }
}

.target-icon-wrapper, .select-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.target-icon-wrapper {

  &:not(:first-child) {
    margin-top: 8px;
  }

  .target-name {
    padding: 6px;
    margin-left: 6px;
    border-radius: 3px;
    background-color: #F8F8F8;
    color: #333333;
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// 组合图的显示bar/line的下拉容器
.chart-type-select-wrapper {
  position: relative;
  margin-left: 6px;

  .img {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    height: 14px;
    width: 14px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  ::v-deep {
    .el-input__inner {
      color: transparent;
    }

    // .el-select-dropdown__item.selected {
    //   background-color: #F5F7FA;
    // }
  }
}

.target-icon-wrapper {
  ::v-deep {

    .el-input__inner {
      padding: 0 6px 0 8px;
    }
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
