<template>
  <div class="content-box">
    <template v-if="renderFullContent">
      <div class="filter-container">
        <div v-if="renderFullContent" v-html="context.content" class="content line-feed editorStyle" />
        <div class="filter filter2"
          v-if="renderFullContent && unAuthed && attachUrl == '/autoinfo/profile/upload/policy/originaInterpret/20240306/2024年《政府工作报告》解读.pptx'">
        </div>
      </div>
      <div class="tips"
        v-if="renderFullContent && unAuthed && attachUrl == '/autoinfo/profile/upload/policy/originaInterpret/20240306/2024年《政府工作报告》解读.pptx'">
        <div class="text">
          <span>【提示】{{ menuName.root }}内容需要开通本栏目后查看</span>
        </div>
        <div class="actions">
          <span class="btn" @click="sqsy('/sqSy?flag=1')">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span class="btn"
            @click="sqsy('/sqSy?flag=2')">直接联系我们</span>
        </div>
      </div>
    </template>

    <template v-if="!renderFullContent">
      <div class="filter-container">
        <div v-if="context.mode === 'image'" v-html="htmlImage" class="content line-feed editorStyle" />
        <div v-else v-html="context.content" class="content line-feed editorStyle preview" />
        <div class="filter"></div>
      </div>

      <div v-if="!logined" class="tips">
        <div class="text">
          <span>【提示】{{ menuName.root }}内容需要会员<span class="btn" @click="showLogin()">登录</span>后查看</span>
        </div>
        <div class="actions">
          没有账号？可<span class="btn" @click="sqsy('/sqSy?flag=1')">申请试用</span>
        </div>
      </div>

      <div v-else class="tips">
        <div class="text">
          <span>【提示】{{ menuName.root }}内容需要开通本栏目后查看</span>
        </div>
        <div class="actions">
          <span class="btn" @click="sqsy('/sqSy?flag=1')">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span class="btn"
            @click="sqsy('/sqSy?flag=2')">直接联系我们</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { htmlRestore } from '@/utils/htmlRestore';

export default {
  props: {
    htmlContent: {
      required: false,
      type: String,
      default: undefined,
    },
    name: {
      required: false,
      type: String,
      default: undefined,
    },
    menuName: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    logined: {
      required: false,
      type: Boolean,
      default: false,
    },
    unAuthed: {
      required: false,
      type: Boolean,
      default: false,
    },
    renderFullContent: {
      required: false,
      type: Boolean,
      default: false,
    },
    showLogin: {
      required: false,
      type: Function,
      default: () => () => null,
    },
    attachUrl: {
      required: false,
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    context() {
      const htmlStr = htmlRestore(this.htmlContent);
      let mode = 'rich';
      let content = htmlStr;
      let htmlLast;
      let matches = null;
      const images = [];

      const reLast = /<p[^>]*?>(?:<span[^>]*?>)?[^<>]+(?:<\/span>)?<\/p>$/i;
      matches = content.match(reLast);
      if (matches) {
        content = content.replace(reLast, '');
        htmlLast = matches[0];
      }
      const re = /<p[^>]*?>(?:<img[^>]*?\/?>|<br\/>|\s)*?<\/p>/ig;
      matches = content.match(re);
      if (matches) {
        content = content.replace(re, '');
      }
      if (matches && !content) {
        mode = 'image';
        const reImg = /<img[^>]*?\/?>/ig;

        matches.forEach((html) => {
          const matches = html.match(reImg);
          if (matches) {
            matches.forEach((img) => images.push(img));
          }
        });
      }
      return { mode, content: htmlStr, images, last: htmlLast };
    },
    htmlImage() {
      const { images, last } = this.context;
      const str = images.slice(0, 3).join('<br/>');
      return `<p style="background:#b3b3b3">${str}<br/></p>${last}`;
    },
  },
  watch: {
    context: {
      handler(newVal, oldVal) {
        if (this.unAuthed) {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = this.context.content;

          const imgElements = tempDiv.querySelectorAll('img');
          const brElements = tempDiv.querySelectorAll('BR');
          const sixthImg = imgElements[5];

          for (let i = 5; i < imgElements.length; i++) {
            imgElements[i].remove();
          }
          for (let i = 5; i < imgElements.length; i++) {
            brElements[i].remove();
          }
          this.context.content = tempDiv.innerHTML;

        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    sqsy(url) {
      const { href } = this.$router.resolve(url);
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  .preview {
    max-height: 800px;
    overflow: hidden;
  }

  .filter-container {
    position: relative;

    .filter {
      position: absolute;
      z-index: 1;
      left: -16px;
      right: -16px;
      bottom: -18px;
      height: 300px;
      filter: blur(10px);
      backdrop-filter: blur(10px);
      mask-image: linear-gradient(transparent 10%, rgba(255, 255, 255, 0.72) 50%, rgba(255, 255, 255, 0.94) 70%);
      pointer-events: none;
    }

    .filter2 {
      height: 525px;
    }
  }

  .tips {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 342px;
    font-size: 15px;
    font-weight: bold;
    background-color: #e0f9f9;
    box-shadow: 2px 2px 10px #d3cece;
    text-align: center;

    .btn {
      color: #0D57BC;
      cursor: pointer;
    }

    .actions {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
