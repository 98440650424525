<!--                批发数据-->
<template>
    <div>
        <div class="search-form">
            <el-form :model="form" ref="form" inline label-width="125px">
                <el-row>
                    <el-col>
                        <div class="query_field">
                            <div class="query_field_l">
                                查询字段：
                            </div>
                            <div class="query_field_r">
                                <el-checkbox v-model="nian" disabled class="isDisabled">年月</el-checkbox>
                                <el-checkbox v-model="form.qymc" @change="changeFun('qymcValue','qymc')">企业</el-checkbox>
                                <el-checkbox v-model="form.cllx" @change="changeFun('cllxValue','cllx')">车辆类型</el-checkbox>
                                <el-checkbox v-model="form.xflb" @change="changeFun('xfLbValue','xflb')" :disabled="!form.cllx">细分类别</el-checkbox>
                                <el-checkbox v-model="form.zlfl" @change="changeFun('zlflValue','zlfl')" :disabled="!form.xflb">质量分类</el-checkbox>
                                <el-checkbox v-model="form.rllb" @change="changeFun('rllbValue','rllb')" :disabled="!form.zlfl ||  form.qymc">燃料类型</el-checkbox>
                                <el-checkbox v-model="xfwd" @change="changeFun('xfwdValue','xfwd')" :disabled="!form.xflb ||  form.qymc">细分维度</el-checkbox>
                                <el-checkbox v-model="form.zuZhuangDi" @change="changeFun('zuZhuangDiValue','zuZhuangDi')" :disabled="!form.zlfl ||  form.qymc">组装地</el-checkbox>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-card>
                    <el-row>
                        <el-col>
                            <el-form-item>
                                <VdateEndStart v-model="times" @change="timeChange" :checkDate="checkDate" :close="false" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="企业名称：" class="no-position" prop="qymcValue">
                                <div class="select-checkbox">
                                    <div class="select-checkbox-top" :class="{'disabled':!form.qymc}" @mouseleave="removeClassFun" @mouseenter="addClassFun" style="width:194px">
                                        <span class="select-placeholder" v-if="form.qymcValue.length<=0">选择企业</span>
                                        <template v-else>
                                            <el-tag closable size="small" type="info" @close="selectClose('qymcValue')">
                                                {{ checkName(form.qymcValue)}}
                                            </el-tag>
                                            <el-tag type="info" size="small" style="margin-left:6px" v-if="form.qymcValue.length>1">+{{form.qymcValue.length-1}}</el-tag>
                                        </template>
                                        <i class="el-icon-arrow-down select-checkbox-icon"></i>
                                    </div>
                                    <div class="select-checkbox-bottom" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                                        <el-checkbox-group v-model="form.qymcValue">
                                            <el-checkbox :label="item.id" :key="item.id" v-for="item in qymcData">{{item.enterprise_name}}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </el-form-item>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="车辆类型：" prop="cllxValue">
                                <el-select v-model="form.cllxValue" placeholder="请选择" size="small" @change="selectChangeCllx">
                                    <el-option v-for="item in bj1" :key="item.value" :label="item.name" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="细分类别：" prop="xfLbValue">
                                <el-select v-model="form.xfLbValue" placeholder="请选择" size="small" multiple collapse-tags @change="selectChangeXflb" :disabled="!form.xflb">
                                    <el-option v-for="item in xflbData" :key="item.value" :label="item.name" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="质量分类：">
                                <el-select v-model="form.zlflValue" placeholder="请选择" size="small" :disabled="!form.zlfl">
                                    <el-option v-for="item in zlflData" :key="item.value" :label="item.name" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="细分维度：">
                                <el-select v-model="xfwdValue" placeholder="请选择" size="small" :disabled="!xfwd" @change="xfwdSelect">
                                    <el-option v-for="(item,index) in xfwdData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="燃料类型：">
                                <el-select v-model="form.rllbValue" placeholder="请选择" size="small" multiple collapse-tags :disabled="!form.rllb || form.qymc">
                                    <el-option v-for="(item,index) in rllxData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="组装地：">
                                <el-select v-model="form.zuZhuangDiValue" placeholder="请选择" size="small" multiple collapse-tags :disabled="!form.zuZhuangDi || form.qymc">
                                    <el-option v-for="(item,index) in zuZhuangDiData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" v-if="form.paiLiangDuan">
                            <el-form-item label="排量：">
                                <el-select v-model="form.paiLiangDuanValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in pailiangData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="档别：" v-if="form.dangBie">
                                <el-select v-model="form.dangBieValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in dangbieData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.cheMen">
                            <el-form-item label="车门：">
                                <el-select v-model="form.cheMenValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in chemenData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.dunWei">
                            <el-form-item label="吨位：">
                                <el-select v-model="form.dunWeiValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in dunweiData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.huoCheYongTu || form.keCheYongTu">
                            <el-form-item label="用途：">
                                <el-select v-model="form.yongtuValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in yongtuData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车长：" v-if="form.cheChang">
                                <el-select v-model="form.cheChangValue" placeholder="请选择" size="small" multiple collapse-tags>
                                    <el-option v-for="(item,index) in chechangData" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <div class="text-center" style="margin-top: 20px;">
                    <el-button @click="rest">重置</el-button>
                    <el-button type="primary" @click="search">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="search-form">
            <div flex="sb" style="margin-bottom: 16px">
                <span class="table_title"></span>
                <div>
                    <el-button v-has-role="'trialrole'" type="primary" plain>导出</el-button>
                </div>
            </div>
            <el-table v-loading="loading" width="100%" :data="tableData" style="width: 100%; margin-bottom: 20px" stripe :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
                <el-table-column label="指标名称">
                    <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop" :key="item.prop"></el-table-column>
                </el-table-column>
                <template v-for="(item,index) in tableHeaderTime">
                    <el-table-column label="完成" :key="'wc-'+index">
                        <el-table-column :prop="item.wc" :label="item.year1">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="销售" :key="'xs-'+index">
                        <el-table-column :prop="item.xs" :label="item.year1">
                        </el-table-column>
                    </el-table-column>
                </template>
            </el-table>
            <div class="text-right">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
                </el-pagination>
            </div>
        </div>
    </div>

</template>
<script>
import {
    YkbQcCxDataPage,
    lastTime,
    YkbQcCxDataEnterpriseList,
    YkbQcCxDataTimeLis,
} from "@/api/data.js";
import VdateEndStart from "@/components/dateEndStart";

export default {
    components: {
        VdateEndStart,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showTips: [
                {
                    title: "数据动态",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "数据库查询",
                    text: "权威数据库，多条件筛查关联比对",
                },
                {
                    title: "数据梳理",
                    text: "专家梳理整合，真正读懂数据脉络",
                },
                {
                    title: "数据大数据",
                    text: "用数据深度理解数据结构和趋势",
                },
            ],
            tableData: [],
            //车辆类型
            bj1: [
                {
                    value: "1",
                    name: "乘用车",
                    children: [
                        {
                            value: "3",
                            name: "MPV",
                        },
                        {
                            value: "4",
                            name: "SUV",
                        },
                        {
                            value: "5",
                            name: "轿车",
                        },
                        {
                            value: "6",
                            name: "交叉型乘用车",
                        },
                    ],
                },
                {
                    value: "2",
                    name: "商用车",
                    children: [
                        {
                            value: "7",
                            name: "货车",
                            children: [
                                {
                                    value: "8",
                                    name: "重型载货车",
                                },
                                {
                                    value: "9",
                                    name: "中型载货车",
                                },
                                {
                                    value: "10",
                                    name: "轻型载货车",
                                },
                                {
                                    value: "11",
                                    name: "微型载货车",
                                },
                            ],
                        },
                        {
                            value: "12",
                            name: "客车",
                            children: [
                                {
                                    value: "13",
                                    name: "大型客车",
                                },
                                {
                                    value: "14",
                                    name: "中型客车",
                                },
                                {
                                    value: "15",
                                    name: "轻型客车",
                                },
                            ],
                        },
                        {
                            value: "16",
                            name: "半挂牵引车",
                            children: [
                                {
                                    value: "26",
                                    name: "25吨<准拖挂车总质量≤40吨",
                                },
                                {
                                    value: "27",
                                    name: "40吨<准拖挂车总质量",
                                },
                                {
                                    value: "28",
                                    name: "准拖挂车总质量≤28吨",
                                },
                            ],
                        },
                        {
                            value: "17",
                            name: "非完整型货车",
                            children: [
                                {
                                    value: "19",
                                    name: "重型非完整型货车",
                                },
                                {
                                    value: "20",
                                    name: "中型非完整型货车",
                                },
                                {
                                    value: "21",
                                    name: "轻型非完整型货车",
                                },
                                {
                                    value: "22",
                                    name: "微型非完整型货车",
                                },
                            ],
                        },
                        {
                            value: "18",
                            name: "非完整型客车",
                            children: [
                                {
                                    value: "23",
                                    name: "重型非完整型客车",
                                },
                                {
                                    value: "24",
                                    name: "中型非完整型客车",
                                },
                                {
                                    value: "25",
                                    name: "轻型非完整型客车",
                                },
                            ],
                        },
                    ],
                },
            ],
            rllxData: [
                {
                    value: "1",
                    label: "柴油汽车",
                },
                {
                    value: "2",
                    label: "汽油汽车",
                },
                {
                    value: "3",
                    label: "其他燃料汽车",
                },
                {
                    value: "4",
                    label: "普通混合动力",
                },
                {
                    value: "5",
                    label: "插电式混合动力",
                },
                {
                    value: "6",
                    label: "纯电动",
                },
                {
                    value: "7",
                    label: "燃料电池",
                },
                {
                    value: "8",
                    label: "天然气",
                },
                {
                    value: "9",
                    label: "其他替代燃料",
                },
            ],
            loading: false,
            xflbData: [],
            zlflData: [],
            form: {
                nian: false,
                timeBegin: "",
                timeEnd: "",
                qymc: false,
                qymcValue: [],

                cllx: true,
                cllxValue: "1",
                cheMenValue: [],
                zlfl: false,
                zlflValue: [],
                xflb: false,
                xfLbValue: [],
                rllb: false,
                rllbValue: [],
                zuZhuangDi: false,
                zuZhuangDiValue: [],
                dangBie: false,
                dangBieValue: [],

                cheMen: false,
                cheMenValue: [],
                paiLiangDuan: false,
                paiLiangDuanValue: [],
                cheMen: false,
                cheMenValue: [],
                dangBie: false,
                dangBieValue: [],
                dunWei: false,
                dunWeiValue: [],
                huoCheYongTu: false,
                huoCheYongTuValue: [],
                keCheYongTu: false,
                keCheYongTuValue: [],
                cheChang: false,
                cheChangValue: [],
            },
            pageData: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            xfwdValue: "",
            xfwdData: [],
            xfwd: false,
            zuZhuangDiData: [
                {
                    value: "1",
                    label: "国内制造",
                },
                {
                    value: "2",
                    label: "CKD",
                },
            ],
            nian: true,
            times: [],
            checkDate: "month",
            qymcData: [],
            yongtuData: [],
            yongtuValue: [],
            pailiangData: [
                { value: "1", label: "排量≤1升" },
                { value: "2", label: "1升<排量≤1.6升" },
                { value: "3", label: "1.6升<排量≤2.0升" },
                { value: "4", label: "2.0升<排量≤2.5升" },
                { value: "5", label: "2.5升<排量≤3.0升" },
                { value: "6", label: "3.0升<排量≤4.0升" },
                { value: "7", label: "4.0升<排量" },
            ],
            dangbieData: [
                { value: "1", label: "手动挡" },
                { value: "2", label: "自动挡" },
                { value: "3", label: "其他档" },
            ],
            chemenData: [
                { value: "1", label: "三厢四门" },
                { value: "2", label: "二厢四门" },
                { value: "3", label: "三厢二门" },
                { value: "4", label: "二厢二门" },
                { value: "5", label: "其他" },
            ],
            dunweiData: [
                { value: "1", label: "总质量≤1.8吨" },
                { value: "2", label: "1.8吨<总质量≤3.5吨" },
                { value: "3", label: "3.5吨<总质量≤4.5吨" },
                { value: "4", label: "4.5吨<总质量≤6吨" },
                { value: "5", label: "6吨<总质量≤8吨" },
                { value: "6", label: "8吨<总质量≤10吨" },
                { value: "7", label: "10吨<总质量≤12吨" },
                { value: "8", label: "12吨<总质量≤14吨" },
                { value: "9", label: "14吨<总质量≤19吨" },
                { value: "10", label: "19吨<总质量≤26吨" },
                { value: "11", label: "26吨<总质量≤32吨" },
                { value: "12", label: "32吨<总质量" },
            ],
            yongtuKcData: [
                { value: "1", label: "城市客车" },
                { value: "2", label: "长途客车" },
                { value: "3", label: "旅游客车" },
                { value: "4", label: "铰接客车" },
                { value: "5", label: "无轨客车" },
                { value: "6", label: "越野客车" },
            ],
            yongtuHcData: [
                { value: "1", label: "普通货车" },
                { value: "2", label: "多用途货车" },
                { value: "3", label: "全挂牵引车" },
                { value: "4", label: "越野货车" },
                { value: "5", label: "专用作业车" },
                { value: "6", label: "专用货车" },
            ],
            chechangData: [
                { value: "1", label: "车长≤6米" },
                { value: "2", label: "6米<车长≤7米" },
                { value: "3", label: "67米<车长≤8米" },
                { value: "4", label: "8米<车长≤9米" },
                { value: "5", label: "9米<车长≤10米" },
                { value: "6", label: "10米<车长≤12米" },
                { value: "7", label: "12米<车≤7米" },
            ],
            tableHeader: [],
            tableHeaderTime: [],
        };
    },
    mounted() {
        if (this.show) {
            this.selectChangeCllx(this.form.cllxValue);
            this.init();
        }
    },
    methods: {
        init() {
            lastTime().then((res) => {
                if (res.ok) {
                    this.times = [res.data, res.data];
                    this.YkbQcCxDataPage();
                }
            });
            YkbQcCxDataEnterpriseList().then((res) => {
                if (res.ok) {
                    this.qymcData = res.data;
                }
            });
        },
        YkbQcCxDataPage() {
            let form = Object.assign({}, this.form, this.pageData);
            form.timeBegin = this.times[0];
            form.timeEnd = this.times[1];
            this.YkbQcCxDataTimeLis(
                this.getNewDate(this.times[0]),
                this.getNewDate(this.times[1]),
                form.nian
            );
            delete form.total;
            YkbQcCxDataPage(form).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.tableHeader = this.tableHeaderForm();
                    this.pageData.count = res.data.count;
                }
            });
        },
        search() {
            this.YkbQcCxDataPage();
        },
        selectChangeCllx(e) {
            let dstas = [];
            let data = this.bj1.filter((item) => item.value == e);
            if (data[0].children) {
                dstas = data[0].children;
            }
            if (e == 1) {
                this.xfwdData = [
                    { value: 1, label: "排量" },
                    { value: 2, label: "档别" },
                ];
                this.zuZhuangDiData = [
                    {
                        value: "1",
                        label: "国内制造",
                    },
                    {
                        value: "2",
                        label: "CKD",
                    },
                ];
            } else {
                this.xfwdData = [];
                this.zuZhuangDiData = [];
            }
            this.form.paiLiangDuan = false;
            this.form.paiLiangDuanValue = [];
            this.form.cheMen = false;
            this.form.cheMenValue = [];
            this.form.dangBie = false;
            this.form.dangBieValue = [];
            this.form.dunWei = false;
            this.form.dunWeiValue = [];
            this.form.huoCheYongTu = false;
            this.form.huoCheYongTuValue = [];
            this.form.keCheYongTu = false;
            this.form.keCheYongTuValue = [];
            this.form.cheChang = false;
            this.form.cheChangValue = [];
            this.form.xfLbValue = [];
            this.xflbData = dstas;
            this.xfwdValue = "";
            this.zuZhuangDiValue = [];
        },
        selectChangeXflb(e) {
            let isCM = e.includes("5");
            let isKC = e.includes("12");
            let isFKC = e.includes("18");
            let isHC = e.includes("7");
            let isFHC = e.includes("17");
            let isBGQY = e.includes("16");
            if (e.length <= 0) {
                this.form.paiLiangDuan = false;
                this.form.paiLiangDuanValue = [];
                this.form.cheMen = false;
                this.form.cheMenValue = [];
                this.form.dangBie = false;
                this.form.dangBieValue = [];
                this.form.dunWei = false;
                this.form.dunWeiValue = [];
                this.form.huoCheYongTu = false;
                this.form.huoCheYongTuValue = [];
                this.form.keCheYongTu = false;
                this.form.keCheYongTuValue = [];
                this.form.cheChang = false;
                this.form.cheChangValue = [];
            }
            if (this.form.cllxValue == 2) {
                if (!isHC && !isFHC && !isBGQY && (isFKC || isKC)) {
                    this.xfwdData = [
                        { value: 4, label: "车长" },
                        { value: 5, label: "用途" },
                    ];
                    this.yongtuData = this.yongtuKcData;
                } else if (!isFKC && !isKC && !isBGQY && (isHC || isFHC)) {
                    this.xfwdData = [
                        { value: 6, label: "吨位" },
                        { value: 7, label: "用途" },
                    ];
                    this.yongtuData = this.yongtuHcData;
                } else {
                    this.xfwdData = [];
                    this.xfwdValue = "";
                    this.yongtuData = [];
                    this.yongtuValue = [];
                }
            } else {
                if (e.length > 1) {
                    this.xfwdData = [
                        { value: 1, label: "排量" },
                        { value: 2, label: "档别" },
                    ];
                } else if (isCM && e.length == 1) {
                    this.xfwdData = [
                        { value: 1, label: "排量" },
                        { value: 2, label: "档别" },
                        { value: 3, label: "车门" },
                    ];
                } else {
                    this.xfwdData = [
                        { value: 1, label: "排量" },
                        { value: 2, label: "档别" },
                    ];
                    this.xfwdValue = "";
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                }
            }

            let dstas = [];
            for (let i = 0; i < e.length; i++) {
                for (let j = 0; j < this.xflbData.length; j++) {
                    if (e[i] == this.xflbData[j].value) {
                        if (this.xflbData[j].children) {
                            let children = this.xflbData[j].children;
                            dstas.push(children);
                        }
                    }
                }
            }
            let datas = [];
            for (let i = 0; i < dstas.length; i++) {
                for (let j = 0; j < dstas[i].length; j++) {
                    datas.push(dstas[i][j]);
                }
            }
            this.form.zlflValue = [];
            this.zlflData = datas;
        },
        //开始结束时间
        timeChange(e) {
            if (e.swatchDateType == "month") {
                this.form.nian = false;
                this.checkDate = "month";
            } else {
                this.form.nian = true;
                this.checkDate = "year";
            }
        },
        //删除
        selectClose(type) {
            this.form[type].splice(0, 1);
        },
        //鼠标移入添加类名
        addClassFun(e) {
            if (!e.target.classList.contains("disabled")) {
                e.target.parentNode.classList.add("hover-class");
            }
        },
        //鼠标移出添加类名
        removeClassFun(e) {
            e.target.parentNode.classList.remove("hover-class");
        },
        //切换清空值
        changeFun(type, check) {
            let isArr = Array.isArray(this.form[type]);
            if (check == "qymc" && this.form.qymc) {
                this.form.paiLiangDuan = false;
                this.form.paiLiangDuanValue = [];
                this.form.cheMen = false;

                this.form.cheMenValue = [];
                this.form.zlfl = false;
                this.form.zlflValue = [];
                this.form.xflb = false;
                this.form.xfLbValue = [];
                this.form.rllb = false;
                this.form.rllbValue = [];
                this.form.zuZhuangDi = false;
                this.form.zuZhuangDiValue = [];
                this.form.dangBie = false;
                this.form.dangBieValue = [];
                this.xfwdValue = "";
                this.xfwd = false;
                this.form.paiLiangDuan = false;
                this.form.paiLiangDuanValue = [];
                this.form.cheMen = false;
                this.form.cheMenValue = [];
                this.form.dangBie = false;
                this.form.dangBieValue = [];
                this.form.dunWei = false;
                this.form.dunWeiValue = [];
                this.form.huoCheYongTu = false;
                this.form.huoCheYongTuValue = [];
                this.form.keCheYongTu = false;
                this.form.keCheYongTuValue = [];
                this.form.cheChang = false;
                this.form.cheChangValue = [];
            }

            if (check == "xflb" && !this.form.xflb) {
                this.form.zlfl = false;
                this.form.zlflValues = [];
            }

            if (!this.form.cllx) {
                this.form.paiLiangDuan = false;
                this.form.paiLiangDuanValue = [];
                this.form.cheMen = false;

                this.form.cheMenValue = [];
                this.form.zlfl = false;
                this.form.zlflValue = [];
                this.form.xflb = false;
                this.form.xfLbValue = [];
                this.form.rllb = false;
                this.form.rllbValue = [];
                this.form.zuZhuangDi = false;
                this.form.zuZhuangDiValue = [];
                this.form.dangBie = false;
                this.form.dangBieValue = [];
                this.xfwdValue = "";
                this.xfwd = false;
                this.form.paiLiangDuan = false;
                this.form.paiLiangDuanValue = [];
                this.form.cheMen = false;
                this.form.cheMenValue = [];
                this.form.dangBie = false;
                this.form.dangBieValue = [];
                this.form.dunWei = false;
                this.form.dunWeiValue = [];
                this.form.huoCheYongTu = false;
                this.form.huoCheYongTuValue = [];
                this.form.keCheYongTu = false;
                this.form.keCheYongTuValue = [];
                this.form.cheChang = false;
                this.form.cheChangValue = [];
            }
            if (isArr) {
                this.$set(this.form, type, []);
            } else {
                if (type == "cllxValue") {
                    this.$set(this.form, type, "1");
                } else if (type == "xfwdValue") {
                    this.$set(this, type, "");
                } else {
                    this.$set(this.form, type, "");
                }
            }
        },
        xfwdSelect(e) {
            switch (e) {
                case 1:
                    this.form.paiLiangDuan = true;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                case 2:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = true;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                case 3:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = true;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                case 4:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = true;
                    this.form.cheChangValue = [];
                    break;
                case 5:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = true;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                case 6:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = true;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                case 7:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = true;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
                    break;
                default:
                    this.form.paiLiangDuan = false;
                    this.form.paiLiangDuanValue = [];
                    this.form.cheMen = false;
                    this.form.cheMenValue = [];
                    this.form.dangBie = false;
                    this.form.dangBieValue = [];
                    this.form.dunWei = false;
                    this.form.dunWeiValue = [];
                    this.form.huoCheYongTu = false;
                    this.form.huoCheYongTuValue = [];
                    this.form.keCheYongTu = false;
                    this.form.keCheYongTuValue = [];
                    this.form.cheChang = false;
                    this.form.cheChangValue = [];
            }
        },
        rest() {
            this.form.paiLiangDuan = false;
            this.form.paiLiangDuanValue = [];
            this.form.cheMen = false;
            this.form.cheMenValue = [];
            this.form.cllxValue = "1";
            this.form.cheMenValue = [];
            this.form.zlfl = false;
            this.form.zlflValue = [];
            this.form.xflb = false;
            this.form.xfLbValue = [];
            this.form.rllb = false;
            this.form.rllbValue = [];
            this.form.zuZhuangDi = false;
            this.form.zuZhuangDiValue = [];
            this.form.dangBie = false;
            this.form.dangBieValue = [];
            this.form.qymc = false
            this.form.qymcValue = []
            this.xfwdValue = "";
            this.xfwd = false;
            this.form.paiLiangDuan = false;
            this.form.paiLiangDuanValue = [];
            this.form.cheMen = false;
            this.form.cheMenValue = [];
            this.form.dangBie = false;
            this.form.dangBieValue = [];
            this.form.dunWei = false;
            this.form.dunWeiValue = [];
            this.form.huoCheYongTu = false;
            this.form.huoCheYongTuValue = [];
            this.form.keCheYongTu = false;
            this.form.keCheYongTuValue = [];
            this.form.cheChang = false;
            this.form.cheChangValue = [];
            this.YkbQcCxDataPage();
        },
        //时间头部
        getNewDate(nowdate) {
            let timeHeader = "";
            let date = new Date(),
                year = date.getFullYear(),
                month =
                    date.getMonth() + 1 >= 10
                        ? date.getMonth() + 1
                        : "0" + date.getMonth() + 1;
            if (nowdate) {
                if (this.form.nian) {
                    timeHeader = nowdate + "-01-01";
                } else {
                    timeHeader = nowdate + "-01";
                }
            } else {
                if (this.form.nian) {
                    timeHeader = year + "-01-01";
                } else {
                    timeHeader = year + "-" + month + "-01";
                }
            }

            return timeHeader;
        },
        //前面头部
        tableHeaderForm() {
            let tableArr = [];
            for (let key in this.form) {
                if (typeof this.form[key] == "boolean") {
                    if (this.form[key]) {
                        switch (key) {
                            case "qymc":
                                tableArr.push({
                                    label: "企业名称",
                                    prop: "qyName",
                                });
                                break;
                            case "cllx":
                                tableArr.push({
                                    label: "车型类别",
                                    prop: "cxlx",
                                });
                                break;
                            case "xflb":
                                tableArr.push({
                                    label: "细分类别",
                                    prop: "xflb",
                                });
                                break;
                            case "zuZhuangDi":
                                tableArr.push({
                                    label: "组装地",
                                    prop: "zuZhuangDi",
                                });
                                break;
                            case "rllb":
                                tableArr.push({
                                    label: "燃料类型",
                                    prop: "rllb",
                                });
                                break;
                        }
                    }
                }
            }
            return tableArr;
        },
        //分页
        handleSizeChange(val) {
            this.pageData.pageSize = val;
            this.YkbQcCxDataPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pageData.page = val;
            this.YkbQcCxDataPage();
        },
        YkbQcCxDataTimeLis(begin, end, isNian) {
            YkbQcCxDataTimeLis({ begin, end, isNian }).then((res) => {
                if (res.ok) {
                    this.tableHeaderTime = res.data;
                }
            });
        },
        checkName(value) {
            let id = value[0];
            let str = this.qymcData.filter((item) => item.id == id);
            if (str.length && str.length>0) {
                return str[0].enterprise_name
            }
        },
    },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
    .title {
        font-size: 30px;
        color: #0D57BC;
    }
}

.box {
    width: 50%;
    padding: 20px;
    cursor: pointer;
}

.is_active {
    background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
    color: #0D57BC;
}

::v-deep .el-tabs__active-bar {
    background-color: #0D57BC;
}

.search-form {
    padding: 28px;
    background: #fff;
    margin-bottom: 10px;
    /deep/ {
        .el-date-editor .el-range-separator {
            width: 12%;
        }
        .el-checkbox-group {
            line-height: 34px;
        }
        .el-checkbox {
            margin-right: 16px;
        }
        .el-form-item__content {
            width: 179px;
        }
        .el-card {
            overflow: visible;
        }
    }
}
</style>
