<template>
  <PageContainer breadcrumb>
     <Vcontent :data="data" :props="props" />
  </PageContainer>
</template>
<script>
import { ggPublicContent } from "@/api/announcement.js";
export default {
  data() {
    return {
      data: {},
       props:{
        htmlContent:'content',
        summary:'introduction'
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ggPublicContent(id);
  },
  methods: {
    ggPublicContent(params) {
      ggPublicContent(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
