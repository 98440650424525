<!--
 * @Author: your name
 * @Date: 2021-07-22 09:51:11
 * @LastEditTime: 2021-07-22 10:11:15
 * @LastEditors: Please set LastEditors
 * @Description: 整体销售预测
 * @FilePath: /information-vue/code/src/views/sale/salesForecast.vue
-->
<template>
    <PageContainer>
<!--        <VnotLogin v-if="!show" title="预测" text="预测栏目围绕预测本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新预测" :showTips="showTips" />-->
        <template >
            <!-- <div class="dynamic-top">
                <ItemTitle @click='chackMore' title="分析报告" />
                <div class="dynamic-top-original-box">
                    <router-link :key="index" v-for="(item, index) in fxList" :to="{ path: '/sale/salesForecast/detail', query: { id: item.id } }" class="dynamic-top-original">
                        <div class="dynamic-top-original-img">
                            <img :src="item.imgUrl" alt="" />
                        </div>
                        <div class="dynamic-top-original-inner">
                            <p>{{item.shortTitle?item.shortTitle:item.title}}</p>
                            <span>{{item.publishDate}}</span>
                        </div>
                    </router-link>
                </div>
            </div> -->
            <div class="g-content-table">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="nowYear+'年月度预测'" name="今年月度预测" >
                        <Vmonth />
                    </el-tab-pane>
                    <el-tab-pane :label="oldYear+'年度预测'" name="未来5年年度预测" >
                        <VallTime ref="cherts" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </template>
    </PageContainer>
</template>
<script>
/**
 * @description: 预测动态页面
 * @param {*}
 * @return {*}
 */
import { getSaleList } from "@/api/salesForecast.js";
import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import VallTime from "./views/allTime.vue";
import Vmonth from "./views/month.vue";
import { getYearMonth } from "@/utils/date.js";
export default {
    components: {
        // VnotLogin,
        VallTime,
        Vmonth,
    },
    data() {
        return {
            fxList: null,
            activeName: "今年月度预测",
            showTips: [
                {
                    title: "预测动态",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "预测库查询",
                    text: "权威数据库，多条件筛查关联比对",
                },
                {
                    title: "预测梳理",
                    text: "专家梳理整合，真正读懂预测脉络",
                },
                {
                    title: "预测大数据",
                    text: "用数据深度理解预测结构和趋势",
                },
            ],
            loading: false,
            tableData: [],
            pagination: {},
            currentPage: 0,
            show: false,
            nowYear: "",
            oldYear: "",
        };
    },
    created() {
      this.setDate();
        if (getUserInfo()) {
            this.show = true;
            // this.getSaleList();

        } else {
            this.show = false;
        }
    },

    methods: {
        chackMore() {
            this.$router.push({
                path: "/sale/salesForecast/list",
            });
        },
        getSaleList() {
            getSaleList({ pageNo: 1, pageSize: 3 }).then((res) => {
                if (res.ok) {
                    this.fxList = res.data.list;
                    // this.content = res.data[0];
                }
            });
        },
        handleClick(tab, event) {
            if (tab.name == "未来5年年度预测") {
                this.$nextTick(() => {
                    this.$refs.cherts.refInit();
                });
            }
        },
        setDate() {
            let date = new Date(),
                year = date.getFullYear();
            this.nowYear = year;
            this.oldYear = year +'-'+(year + 3);
        },
    },
};
</script>
<style lang="scss" scoped>
.dynamic-top {
    padding: 20px 32px;
    margin-bottom: 12px;
    background: #fff;
    .dynamic-top-original-box {
        display: flex;
        margin-top: 32px;
        margin-left: -26px;
        cursor: pointer;
        .dynamic-top-original {
            display: flex;
            flex: 0 0 33.333%;
            margin-left: 26px;
            .dynamic-top-original-img {
                width: 150px;
                height: 99px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    box-shadow: 0px 0px 7.31074px 3.65537px
                        rgba(225, 225, 225, 0.25);
                }
            }
            .dynamic-top-original-inner {
                width: 140px;
                margin-left: 6px;
                position: relative;
                p {
                    // line-height: 21px;
                    color: #000;
                    font-weight: 500;
                    // height: 64px;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                p:hover {
                    color: #00f;
                }
                span {
                    color: #828282;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
}
</style>
