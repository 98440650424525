<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:20:17
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-22 14:04:19
-->
<!--市场动态列表-->
<template>
  <PageContainer>
    <div class="container">
      <div class="search-box">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="内容分类">
                <el-radio-group v-model="form.releaseColumn">
                  <el-radio :label="null">全部</el-radio>
                  <el-radio
                    v-for="(item, index) in releaseColumnList"
                    :key="index"
                    :label="item.dictValue"
                  >{{ item.dictLabel }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="区域分类">
                <el-checkbox-group v-model="form.countryList">
                  <el-checkbox
                    v-for="(item, index) in countryDataList"
                    :key="index"
                    :label="item.dictValue"
                  >{{ item.dictLabel }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="5">
              <el-form-item label="关键字">
                <el-input
                  v-model="form.keyWord"
                  placeholder="请输入关键字"
                  size="small"
                />
              </el-form-item>
            </el-col>
            <el-col :span="19">
              <el-form-item>
                <div class="search-btn-box">
                  <el-button
                    size="small"
                    type="primary"
                    @click="getListData"
                  >查询</el-button>
                  <el-button size="small" @click="resize">重置</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="info-box">
        <div class="info-scroll-box">
          <massageItem
            v-for="(item, index) in cardList"
            :key="index"
            :data="item"
            log-records-column1="国际化"
            log-records-column2="国际资讯"
            log-records-table-name="tt_international_information"
            link-url="/international/details/"
          />
        </div>
        <div class="pages">
          <el-pagination
            v-if="pageShow"
            :current-page="pageData.pages.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <!-- <div class="right-box">
        <information class="information" />
        <advertisement class="advertisement" />
      </div> -->
    </div>
  </PageContainer>
</template>
<script>
import massageItem from '../common/massageItem'
import advertisement from '../common/advertisement'
import information from '../common/information'
import {
  gethoutInformation // 获取数据
} from '@/api/data_new.js'
// 字典管理
import { selectDictDataByType } from '@/api/dict.js'
import { getUserInfo, openSy } from '@/utils/auth.js'
import { buryPoint } from '@/api/common'
export default {
  components: {
    massageItem,
    advertisement,
    information
  },
  data() {
    return {
      form: {
        releaseColumn: null,
        countryList: [],
        keyWord: ''
      },
      cardList: [],
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      releaseColumnList: [],
      countryDataList: [],
      pageShow: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '公告',
        userId: ''
      },
    }
  },
  created() {
    this.getListData()
    this.getDict('international_state', 'countryDataList')
    this.getDict('internationalFl', 'releaseColumnList')
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 获取字典数据
    getDict(tyep, resDataName) {
      selectDictDataByType({ dictType: tyep }).then((res) => {
        if (res.ok) {
          this[resDataName] = res.data
        }
      })
    },
    /**
     * @description: 查询重置
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:28:38
     * @LastEditTime: Do not edit
     */
    resize() {
      this.form = {
        releaseColumn: null,
        countryList: [],
        keyWord: ''
      }
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      const parma = {
        releaseColumn: this.form.releaseColumn,
        keyWord: this.form.keyWord,
        countryList:
          this.form.countryList.length > 0 ? this.form.countryList : null
      }
      gethoutInformation(
        `?pageNo=${this.pageData.pages.pageNo}&pageSize=${this.pageData.pages.pageSize} `,
        parma
      ).then((res) => {
        if (res.ok) {
          this.cardList = res.data.list.map((item) => {
            item.coverUrl = 'https://www.autoinfo.org.cn' + item.coverUrl
            return item
          })
          // console.log(this.cardList);
          this.pageData.total = res.data.count
        }
      })
    },
    /**
     * @description: 页数切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:01:53
     * @LastEditTime: Do not edit
     */

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.getListData()
    },
    /**
     * @description: 页码切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:02:01
     * @LastEditTime: Do not edit
     */

    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.pageNo = val
        this.getListData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$BassZoom: 1;

.container {
  background-color: #fff;
}

.search-box {
  margin: calc(0.25rem * #{$BassZoom});
  // height: 7.666rem;
  padding: 1rem;
  background-color: #f0f4f9;
  .el-form /deep/ .el-form-item__label {
    text-align: start;
  }
  /deep/ .el-form-item {
    margin-bottom: calc(0.24rem * #{$BassZoom});
  }
  .search-btn-box {
    float: right;
  }
}
.info-box::-webkit-scrollbar {
  display: none;
}
.info-box {
  width: 100%;
  // height: 28.134rem;
  padding: calc(1rem * #{$BassZoom});
  // overflow-y: scroll;
  .info-scroll-box {
    width: 100%;
  }
  .pages {
    margin: calc(1rem * #{$BassZoom});
  }
}
</style>
