<template>
    <div class="'listBox'">
        <div class="box_box_title" v-for="(item) in tableData" :key="item.id" @click="toHref(item)">
            <div style="display: flex">
                <div class="text-center">
                    <img class="listImg" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt="" />
                </div>
                <div class="flex_col" flex="1">
                    <div class="index-new-link">
                        <div flex="c" class="flexc">
                            <span class="yc" v-if="item.contentCategory == 'yc'">原创</span>
                            <Tooltip bold :fontSize="16"
                                v-bind:title="htmlRestore(item.shortTitle ? item.shortTitle : item.title)">
                            </Tooltip>
                        </div>
                        <div class="content">
                            {{ htmlRestore(item.introduction) }}
                        </div>
                    </div>
                    <div v-if="showbutton" class="releaseColumn">
                        <span :class="['keywords', 'keywords' + indexs]"
                            v-for="(items, indexs) in item.keywords.split(/[，、]/)" :key="indexs">
                            {{ items }}
                        </span>
                    </div>
                    <div v-else class="releaseColumn">
                        <a href="javascript:;" style="color: #0D57BC">{{
                            item.keywords
                        }}</a>
                    </div>
                    <div class="extra">
                        <span class="date">{{ item.updateTime }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Tooltip from '@/components/toolTip.vue';
export default {
    components: {
        Tooltip
    },
    data() {
        return {
            defaultUrl: require("@/assets/img/default-img.png"),
        };
    },
    props: {
        tableData: {
            type: Array,
        },
        showbutton: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toHref(item) {
            this.$emit('toHref', item);
        },
    },
}
</script>
  
<style lang="scss" scoped>
.listBox {
    position: relative;
    z-index: 1;
    background: #ffffff;
}

.box_box_title {
    margin-bottom: 20px;
    cursor: pointer;

    .bold {
        font-size: 16px;
        color: #000;
    }

    p {
        font-weight: 500;
        color: #000;
        font-size: 16px;

        img {
            margin-top: -3px;
        }
    }

    .flex_col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .listImg {
        width: 267px;
        height: 150px;
        border-radius: 4px;
        margin-right: 26px;
    }

    .indexImg {
        width: 285px;
        height: 177px;
        border-radius: 4px;
        margin-right: 26px;
    }

    .flexc {
        margin-bottom: 6px;

        .yc {
            display: inline-flex;
            margin-top: 2px;
            margin-right: 8px;
            padding: 3px 4px;
            color: #fff;
            font-size: 12px;
            line-height: 1;
            white-space: nowrap;
            background: #ff6e4b;
            background-image: linear-gradient(136deg, rgb(255, 142, 93) 0%, rgba(255, 47, 47, 0.99608) 100%);
            border-radius: 2px;
        }
    }

    .releaseColumn {
        padding-bottom: 6px;
        font-size: 14px;

        span {
            margin-top: 6px;
            font-size: 16px;
            display: inline-flex;
            margin-right: 10px;
            padding: 3px 6px;
            line-height: 1;
            border-radius: 2px;
        }
    }

    .content {
        font-size: 14px;
        color: #828282;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .extra {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .date {
            display: inline-flex;
            padding: 4px 0;
            color: #bbbbbb;
            font-size: 12px;
        }

        ::v-deep .el-button {
            padding: 9px 18px;
            color: #999;
            font-size: 12px;
            border-radius: 4px;
            border-color: #f3f3f3;

            &:focus {
                border-color: #f3f3f3;
            }

            &:hover {
                border-color: #3f76ea;
            }

            &.el-button--primary {
                color: #3f76ea;
                border-color: #3f76ea;
            }

            .el-icon-download {
                margin-right: 6px;
            }
        }
    }
}

.keywords {
    padding: 5px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
    font-size: 14px;

}
.keywords0 {
    color: #8cc15c;
    background: #e3f9cf;
}

.keywords1 {
    color: #ff8080;
    background: #fef0f0;
}

.keywords2 {
    color: #4375ee;
    background: #eaf0fd;
}

.keywords3 {
    color: #58b2f0;
    background: #daf0ff;
}

.keywords4 {
    color: #9f9fa1;
    background: #e9e8eb;
}

.keywords5 {
    color: #ff5daf;
    background: #ffedf6;
}

.keywords6 {
    color: #9fb1fb;
    background: #ebeefd;
}

.keywords7 {
    color: #a48be3;
    background: #ded1ff;
}

.keywords8 {
    color: #16dbcc;
    background: #dcfaf8;
}

.keywords9 {
    color: #ffcb7a;
    background: #fff5d9;
}
</style>
