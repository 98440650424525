<template>
  <div
    v-clickoutside="handleClose"
    class="el-select"
    :class="[selectSize ? 'el-select--' + selectSize : '']"
    @click.stop="toggleMenu"
  >
    <div v-if="multiple" ref="tags" class="el-select__tags" :style="{ 'max-width': inputWidth - 32 + 'px' }">
      <span v-if="collapseTags && selected.length">
        <el-tag
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="selected[0].hitState"
          type="info"
          disable-transitions
          @close="deleteTag($event, selected[0])"
        >
          <span class="el-select__tags-text">{{ selected[0].currentLabel }}</span>
        </el-tag>
        <el-tag v-if="selected.length > 1" :closable="false" :size="collapseTagSize" type="info" disable-transitions>
          <span class="el-select__tags-text">+ {{ selected.length - 1 }}</span>
        </el-tag>
      </span>
      <transition-group v-if="!collapseTags" @after-leave="resetInputHeight">
        <el-tag
          v-for="item in selected"
          :key="getValueKey(item)"
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="item.hitState"
          type="info"
          disable-transitions
          @close="deleteTag($event, item)"
        >
          <span class="el-select__tags-text">{{ item.currentLabel }}</span>
        </el-tag>
      </transition-group>
    </div>
    <el-input
      :id="id"
      ref="reference"
      v-model="selectedLabel"
      type="text"
      :placeholder="currentPlaceholder"
      :name="name"
      :auto-complete="autoComplete"
      :size="selectSize"
      :disabled="selectDisabled"
      :readonly="readonly"
      :validate-event="false"
      :class="{ 'is-focus': visible }"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup.native="debouncedOnInputChange"
      @keydown.native.down.stop.prevent="navigateOptions('next')"
      @keydown.native.up.stop.prevent="navigateOptions('prev')"
      @keydown.native.enter.prevent="selectOption"
      @keydown.native.esc.stop.prevent="visible = false"
      @keydown.native.tab="visible = false"
      @paste.native="debouncedOnInputChange"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false"
    >
      <template v-if="$slots.prefix" slot="prefix">
        <slot name="prefix" />
      </template>
      <i
        slot="suffix"
        :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"
        @click="handleIconClick"
      />
    </el-input>
    <transition name="el-zoom-in-top" @after-leave="doDestroy">
      <el-select-menu v-show="visible && emptyText !== false" ref="popper" :append-to-body="popperAppendToBody">
        <!-- 下拉内容 -->
        <div v-show="cachedGroupArr.length > 0 && !loading" :class="['inner-container', showEmptyText ? 'show-empty-text': '']">
          <div class="search-input">
            <el-input v-model="searchText" :size="selectSize" placeholder="搜索" clearable @input="handleSearchInput">
              <i slot="suffix" class="el-icon-search el-input__icon" />
            </el-input>
          </div>
          <div class="operate-container">
            <el-checkbox
              v-if="groupArr.length > 0  && !showCurrentSelected"
              :value="checkAll"
              :indeterminate="checkIndeterminate"
              @change="handleCheckAllChange"
            >全选</el-checkbox>
            <div v-else />
            <div class="operate-btns">
              <div v-if="!showCurrentSelected" class="switch-select-btn" @click="switchCurrentSelected"><i class="el-icon-notebook-2 el-input__icon" />查看已选</div>
              <div v-else class="switch-select-btn" @click="switchCurrentSelected"><i class="el-icon-notebook-2 el-input__icon" />返回筛选</div>
              <div class="clear-btn" @click="handleClickClearSelected"><i class="el-icon-delete el-input__icon" />清空</div>
            </div>
          </div>
          <div class="main-container" :style="{ 'min-width': `${inputWidth * 2}px` }">
            <!-- 只有当结果数据 groupArr 有值时才渲染 -->
            <template v-if="groupArr.length > 0 && !showCurrentSelected">
              <!-- 左侧的选项 -->
              <div class="check-box-group-container">
                <el-checkbox-group ref="scrollContainerRef" :value="value">
                  <div v-for="group in getActiveGroups" :key="group.letter" class="group">
                    <div class="letter-title">{{ group.letter }}</div>
                    <div class="item-container">
                      <el-checkbox
                        v-for="optionItem in group.options"
                        :key="optionItem.value"
                        class="item"
                        :label="optionItem.value"
                        @change="handleCheckBoxChange($event, optionItem.value)"
                      >{{
                        optionItem.label
                      }}</el-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="showFilterResultBySearchText && selectedLetter === null && hasMoreFilterResultToShow"
                    class="load-more-search-result"
                    @click="filterResultPageNo++"
                  >加载更多</div>
                </el-checkbox-group>
              </div>
              <!-- 右侧的字母索引容器 -->
              <div class="letter-container">
                <div
                  v-show="showFilterResultBySearchText"
                  :class="[selectedLetter === null ? 'active' : '', 'letter']"
                  @click="handleClickLetter(null)"
                >全</div>
                <div
                  v-for="letter in letterArr"
                  :key="letter"
                  :class="[selectedLetter === letter ? 'active' : '', 'letter']"
                  @click="handleClickLetter(letter)"
                >
                  {{ letter }}
                </div>
              </div>
            </template>
            <template v-if="showCurrentSelected">
              <div class="selected-area">
                <div class="letter-title">已选内容</div>
                <span v-for="selection in selected" :key="selection">
                  <el-tag
                    :closable="!selectDisabled"
                    :size="collapseTagSize"
                    :hit="selection.hitState"
                    type="info"
                    disable-transitions
                    @close="deleteTag($event, selection)"
                  >
                    <span class="el-select__tags-text">{{ selection.currentLabel }}</span>
                  </el-tag>
                </span>
              </div>
            </template>
          </div>
        </div>
        <p v-if="showEmptyText && !showCurrentSelected" class="el-select-dropdown__empty">
          {{ emptyText }}
        </p>
      </el-select-menu>
    </transition>
  </div>
</template>

<script type="text/babel">
import pinyin from 'js-pinyin'
import Emitter from 'element-ui/src/mixins/emitter'
import Focus from 'element-ui/src/mixins/focus'
import Locale from 'element-ui/src/mixins/locale'
import ElSelectMenu from 'element-ui/packages/select/src/select-dropdown.vue'
import { debounce } from 'throttle-debounce'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import { addClass, removeClass, hasClass } from 'element-ui/src/utils/dom'
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event'
import { t } from 'element-ui/src/locale'
import scrollIntoView from 'element-ui/src/utils/scroll-into-view'
import { getValueByPath } from 'element-ui/src/utils/util'
import { valueEquals } from 'element-ui/src/utils/util'
import NavigationMixin from 'element-ui/packages/select/src/navigation-mixin.js'
import { isKorean } from 'element-ui/src/utils/shared'

const sizeMap = {
  'medium': 36,
  'small': 32,
  'mini': 28
}

export default {
  name: 'LetterSelect',
  components: {
    ElSelectMenu
  },
  directives: { Clickoutside },
  mixins: [Emitter, Locale, Focus('reference'), NavigationMixin],
  componentName: 'ElSelect',
  inject: {
    elForm: {
      default: ''
    },

    elFormItem: {
      default: ''
    }
  },
  provide() {
    return {
      'select': this
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    name: String,
    id: String,
    value: {
      required: true
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    automaticDropdown: Boolean,
    size: String,
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    loading: Boolean,
    popperClass: String,
    remote: Boolean,
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    remoteMethod: Function,
    filterMethod: Function,
    multipleLimit: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default() {
        return t('el.select.placeholder')
      }
    },
    reserveKeyword: Boolean,
    valueKey: {
      type: String,
      default: 'value'
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    popperAppendToBody: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      // 是否需要展示筛选结果
      showFilterResultBySearchText: false,
      // 搜索结果一共显示了多少次 加载更多
      filterResultPageNo: 1,
      // 搜索结果每次加载更多，加载多少条
      FilterResultPageSize: 50,
      multiple: true,
      searchText: '',
      copyOptionList: [],
      groupArr: [],
      cachedGroupArr: [],
      selectedLetter: '',
      cachedOptions: [],
      createdLabel: null,
      createdSelected: false,
      selected: [],
      inputLength: 20,
      inputWidth: 0,
      cachedPlaceHolder: '',
      optionsCount: 0,
      filteredOptionsCount: 0,
      visible: false,
      softFocus: false,
      selectedLabel: '',
      hoverIndex: -1,
      query: '',
      previousQuery: null,
      inputHovering: false,
      currentPlaceholder: '',
      menuVisibleOnFocus: false,
      isOnComposition: false,
      isSilentBlur: false,
      searchInputTimer: null,

      showCurrentSelected: false
    }
  },

  computed: {
    // 获取当前所点击的 字母选项组，用于渲染到组件上供用户选择
    getActiveGroups() {
      // 如果当前正在显示搜索结果，并且选中了 全部 搜索结果
      if (this.showFilterResultBySearchText && this.selectedLetter === null) {
        // 计算出要从 全部 的搜索结果中，先显示多少条
        const countToShow = this.filterResultPageNo * this.FilterResultPageSize
        // 用于计数：本次要显示的数量
        let count = 0
        const result = []
        for (let i = 0; i < this.groupArr.length; i++) {
          const group = this.groupArr[i]
          // 用于存放要显示的选项
          const optionsToShow = []
          for (let j = 0; j < group.options.length; j++) {
            // 如果选项数量还没到本次要显示的数量
            if (count < countToShow) {
              // 则加入该选项
              optionsToShow.push(group.options[j])
              count++
            } else {
              break
            }
          }
          // 如果当前选项的数量还没到本次要显示的数量
          if (optionsToShow.length > 0) {
            // 则将这个要显示的 group 放入结果数组
            const groupToShow = {
              letter: group.letter,
              options: optionsToShow
            }
            result.push(groupToShow)
          } else {
            break
          }
        }
        return result
      }
      // 从 groupArr 找到被激活的字符所对应的选项，或者如果没有所激活的字符，则返回第一个字符所对应的选项
      const result = this.groupArr.find(
        ({ letter }) => letter === this.selectedLetter
      ) || this.groupArr[0]
      /**
      返回数组是为了兼容上面显示全部搜索结果，因为组件是 v-for 每个字符来生成选项的
      v-for 逻辑采用的是下面的数据格式
      [
        {
          letter: string,
          options: [
            {
              value: string,
              label: string
            },
            ...
          ]
        },
        ...
      ]
      */
      return [result]
    },
    // 是否还有更多的搜索结果未展示
    hasMoreFilterResultToShow() {
      // 如果当前没有展示搜索结果，则返回 false
      if (!this.showFilterResultBySearchText) return false
      // 比较 getActiveGroups 和 groupArr 的数量
      const activeGroupsLen = this.getActiveGroups.length
      if (activeGroupsLen !== this.groupArr.length) {
        return true
      }
      // 获取最后一个正在展示的 group
      const lastActiveGroup = this.getActiveGroups[activeGroupsLen - 1]
      // 获取最后一个正在展示的 group 的选项的 length
      const lastActiveGroupsOptionsLen = lastActiveGroup.options.length
      // 返回 最后一个正在展示的 group 的选项的 length 是否和最后一个待展示的 group 的选项的 length 不相等
      // 如果不相等，则表示还有更多
      return lastActiveGroupsOptionsLen !== this.groupArr[this.groupArr.length - 1].options.length
    },
    showEmptyText() {
      return this.emptyText &&
        (!this.allowCreate || this.loading || (this.allowCreate && this.options.length === 0))
    },
    letterArr() {
      return this.groupArr.map(group => group.letter)
    },
    // 是否半选
    checkIndeterminate() {
      return this.value?.length > 0 && this.value.length < this.options.length
    },
    // 是否全选
    checkAll() {
      return this.value?.length === this.options.length
    },
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize
    },

    readonly() {
      // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
      const isIE = !this.$isServer && !isNaN(Number(document.documentMode))
      return !this.filterable || this.multiple || !isIE && !this.visible
    },

    iconClass() {
      const criteria = this.clearable &&
        !this.selectDisabled &&
        this.inputHovering &&
        !this.multiple &&
        this.value !== undefined &&
        this.value !== null &&
        this.value !== ''
      return criteria ? 'circle-close is-show-close' : (this.remote && this.filterable ? '' : 'arrow-up')
    },

    debounce() {
      return this.remote ? 300 : 0
    },

    emptyText() {
      if (this.loading) {
        return this.loadingText || this.t('el.select.loading')
      } else {
        if (this.remote && this.query === '' && this.options.length === 0) return false
        if (this.filterable && this.query && this.options.length > 0 && this.filteredOptionsCount === 0) {
          return this.noMatchText || this.t('el.select.noMatch')
        }
        if (this.options.length === 0 || this.groupArr.length === 0) {
          return this.noDataText || this.t('el.select.noData')
        }
      }
      return null
    },
    selectSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size
    },

    selectDisabled() {
      return this.disabled || (this.elForm || {}).disabled
    },

    collapseTagSize() {
      return ['small', 'mini'].indexOf(this.selectSize) > -1
        ? 'mini'
        : 'small'
    }
  },
  watch: {
    options: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          this.$set(this, 'groupArr', [])
          this.$set(this, 'cachedGroupArr', [])
          return
        }
        const copyOptionList = val.map(item => {
          let charts = pinyin.getFullChars(item.label)
          if (item.label.startsWith('重庆') && charts.startsWith('ZhongQing')) {
            charts = charts.replace('ZhongQing', 'ChongQing')
          }
          return {
            ...item,
            charts
          }
        })
        this.$set(this, 'copyOptionList', copyOptionList)
        const letterMap = {}
        const letterReg = /^[a-zA-Z]$/
        copyOptionList.forEach(item => {
          const firstChar = item.charts.charAt(0)
          // 如果是 26个字母, 则将其转为大写字母, 否则仍用原来的字符
          const firstLetter = letterReg.test(firstChar) ? firstChar.toUpperCase() : firstChar
          if (letterMap[firstLetter]) {
            letterMap[firstLetter].push(item)
          } else {
            letterMap[firstLetter] = [item]
          }
        })
        const allLetters = Object.keys(letterMap).sort()
        const letterArr = []
        const otherLetterArr = []
        allLetters.forEach(item => {
          // 如果是字母
          if (letterReg.test(item)) {
            letterArr.push(item)
          } else {
            otherLetterArr.push(item)
          }
        })
        const newGroupArr = []
        // 将字母索引放在前面，其他字符的索引放在后面
        Array.from([...letterArr, ...otherLetterArr]).forEach(letter => {
          newGroupArr.push({
            letter,
            options: letterMap[letter].sort((a, b) => {
              return a.charts > b.charts ? 1 : -1
            })
          })
        })
        this.$set(this, 'groupArr', newGroupArr)
        this.$set(this, 'cachedGroupArr', newGroupArr)
        // 选中第一个字母
        this.selectedLetter = newGroupArr[0].letter
        // 回到顶部
        this.$refs.scrollContainerRef?.$el?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        if (this.$isServer) return
        this.$nextTick(() => {
          this.broadcast('ElSelectDropdown', 'updatePopper')
        })
        if (this.multiple) {
          this.resetInputHeight()
        }
        const inputs = this.$el?.querySelectorAll('input') || []
        if ([].indexOf.call(inputs, document.activeElement) === -1) {
          this.setSelected()
        }
      }
    },
    selectDisabled() {
      this.$nextTick(() => {
        this.resetInputHeight()
      })
    },

    placeholder(val) {
      this.cachedPlaceHolder = this.currentPlaceholder = val
    },

    value(val, oldVal) {
      if (this.multiple) {
        this.resetInputHeight()
        if (val.length > 0 || (this.$refs.input && this.query !== '')) {
          this.currentPlaceholder = ''
        } else {
          this.currentPlaceholder = this.cachedPlaceHolder
        }
        if (this.filterable && !this.reserveKeyword) {
          this.query = ''
          this.handleQueryChange(this.query)
        }
      }
      this.setSelected()
      if (this.filterable && !this.multiple) {
        this.inputLength = 20
      }
      if (!valueEquals(val, oldVal)) {
        this.dispatch('ElFormItem', 'el.form.change', val)
      }
    },

    visible(val) {
      if (!val) {
        // 选中第一个字母
        if (this.groupArr[0]) {
          this.selectedLetter = this.groupArr[0].letter
        }
        // 回到顶部
        this.$refs.scrollContainerRef?.$el?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        // 清空搜索文字, 显示所有选项
        this.searchText = ''
        this.handleSearchInput()
        this.handleIconHide()
        this.broadcast('ElSelectDropdown', 'destroyPopper')
        if (this.$refs.input) {
          this.$refs.input.blur()
        }
        this.query = ''
        this.previousQuery = null
        this.selectedLabel = ''
        this.inputLength = 20
        this.resetHoverIndex()
        this.$nextTick(() => {
          if (this.$refs.input &&
            this.$refs.input.value === '' &&
            this.selected.length === 0) {
            this.currentPlaceholder = this.cachedPlaceHolder
          }
        })
        if (!this.multiple) {
          if (this.selected) {
            if (this.filterable && this.allowCreate &&
              this.createdSelected && this.createdLabel) {
              this.selectedLabel = this.createdLabel
            } else {
              this.selectedLabel = this.selected.currentLabel
            }
            if (this.filterable) this.query = this.selectedLabel
          }
        }
      } else {
        this.handleIconShow()
        this.broadcast('ElSelectDropdown', 'updatePopper')
        if (this.filterable) {
          this.query = this.remote ? '' : this.selectedLabel
          this.handleQueryChange(this.query)
          if (this.multiple) {
            this.$refs.input.focus()
          } else {
            if (!this.remote) {
              this.broadcast('ElOption', 'queryChange', '')
              this.broadcast('ElOptionGroup', 'queryChange')
            }
            this.broadcast('ElInput', 'inputSelect')
          }
        }
      }
      this.$emit('visible-change', val)
    }
  },

  created() {
    this.cachedPlaceHolder = this.currentPlaceholder = this.placeholder
    if (this.multiple && !Array.isArray(this.value)) {
      this.$emit('input', [])
    }
    if (!this.multiple && Array.isArray(this.value)) {
      this.$emit('input', '')
    }

    this.debouncedOnInputChange = debounce(this.debounce, () => {
      this.onInputChange()
    })

    this.$on('handleOptionClick', this.handleOptionSelect)
    this.$on('setSelected', this.setSelected)
  },

  mounted() {
    if (this.multiple && Array.isArray(this.value) && this.value.length > 0) {
      this.currentPlaceholder = ''
    }
    addResizeListener(this.$el, this.handleResize)
    if (this.remote && this.multiple) {
      this.resetInputHeight()
    }
    this.$nextTick(() => {
      if (this.$refs.reference && this.$refs.reference.$el) {
        this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width
      }
    })
    this.setSelected()
  },

  beforeDestroy() {
    if (this.$el && this.handleResize) removeResizeListener(this.$el, this.handleResize)
  },

  methods: {
    // 输入搜索关键字
    handleSearchInput() {
      if (!this.searchText) {
        // 表示不需要展示筛选结果
        this.showFilterResultBySearchText = false
        this.$set(this, 'groupArr', this.cachedGroupArr)
        // 选中第一个字母
        this.selectedLetter = this.groupArr[0].letter
        // 回到顶部
        this.$refs.scrollContainerRef?.$el?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        // 重新定位 dropdown
        this.$nextTick(() => {
          this.broadcast('ElSelectDropdown', 'updatePopper')
        })
        return
      }
      if (this.searchInputTimer) {
        clearTimeout(this.searchInputTimer)
      }
      this.searchInputTimer = setTimeout(() => {
        // 表示需要展示筛选结果
        this.showFilterResultBySearchText = true
        // 默认展示第一页的搜索结果
        this.filterResultPageNo = 1
        const letterMap = {}
        // 从所有的选项 list 中，筛选出符合关键字的选项 list
        const searchResult = this.copyOptionList.filter(item => item.label.indexOf(this.searchText) > -1)
        // 把筛选出的选项 按其每个选项的首字母为 key，存到 map 里
        searchResult.forEach(item => {
          const firstLetter = item.charts.charAt(0)
          if (letterMap[firstLetter]) {
            letterMap[firstLetter].push(item)
          } else {
            letterMap[firstLetter] = [item]
          }
        })
        // const allLetters = Object.keys(letterMap).sort()
        // 用于存储 搜索结果
        const newGroupArr = []
        this.cachedGroupArr.forEach(({ letter }) => {
          if (letterMap[letter]) {
            newGroupArr.push({
              letter,
              options: letterMap[letter].sort((a, b) => {
                return a.charts > b.charts ? 1 : -1
              })
            })
          }
        })
        this.$set(this, 'groupArr', newGroupArr)
        this.$forceUpdate()
        // 如果有搜索结果
        if (newGroupArr.length) {
          // 选中 全部（selectedLetter = null 表示全部）
          this.selectedLetter = null
        }
        this.$set(this, 'groupArr', newGroupArr)
        // 回到顶部
        this.$refs.scrollContainerRef?.$el?.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        // 重新定位 dropdown
        this.$nextTick(() => {
          this.broadcast('ElSelectDropdown', 'updatePopper')
        })
      }, 300)
    },
    handleClickClearSelected() {
      this.$emit('input', [])
    },
    switchCurrentSelected() {
      this.showCurrentSelected = !this.showCurrentSelected
    },
    handleClickLetter(letter) {
      if (this.selectedLetter === letter) return
      this.selectedLetter = letter
      this.$refs.scrollContainerRef?.$el.scrollTo({
        top: 0
        // behavior: 'smooth'
      })
      // const letterGroupDom = this.$refs[`group${letter}`]
      // if (letterGroupDom) {
      //   this.selectedLetter = letter
      // }
    },
    handleCheckAllChange() {
      // 如果处于半选状态，或未选任何选项
      if (this.checkIndeterminate || this.value.length === 0) {
        // 则选中所有
        const newCheckedList = []
        this.groupArr.forEach(group => {
          newCheckedList.push(...group.options.map(item => item.value))
        })
        this.$emit('input', newCheckedList)
      } else {
        this.$emit('input', [])
      }
    },
    handleCheckBoxChange(checked, val) {
      if (checked) {
        this.$emit('input', [...this.value, val])
      } else {
        this.$emit('input', this.value.filter(item => item !== val))
      }
    },
    handleComposition(event) {
      const text = event.target.value
      if (event.type === 'compositionend') {
        this.isOnComposition = false
        this.handleQueryChange(text)
      } else {
        const lastCharacter = text[text.length - 1] || ''
        this.isOnComposition = !isKorean(lastCharacter)
      }
    },
    handleQueryChange(val) {
      if (this.previousQuery === val || this.isOnComposition) return
      if (
        this.previousQuery === null &&
        (typeof this.filterMethod === 'function' || typeof this.remoteMethod === 'function')
      ) {
        this.previousQuery = val
        return
      }
      this.previousQuery = val
      this.$nextTick(() => {
        if (this.visible) this.broadcast('ElSelectDropdown', 'updatePopper')
      })
      this.hoverIndex = -1
      if (this.multiple && this.filterable) {
        const length = this.$refs.input.value.length * 15 + 20
        this.inputLength = this.collapseTags ? Math.min(50, length) : length
        this.managePlaceholder()
        this.resetInputHeight()
      }
      if (this.remote && typeof this.remoteMethod === 'function') {
        this.hoverIndex = -1
        this.remoteMethod(val)
      } else if (typeof this.filterMethod === 'function') {
        this.filterMethod(val)
        this.broadcast('ElOptionGroup', 'queryChange')
      } else {
        this.filteredOptionsCount = this.optionsCount
        this.broadcast('ElOption', 'queryChange', val)
        this.broadcast('ElOptionGroup', 'queryChange')
      }
    },

    handleIconHide() {
      const icon = this.$el.querySelector('.el-input__icon')
      if (icon) {
        removeClass(icon, 'is-reverse')
      }
    },

    handleIconShow() {
      const icon = this.$el.querySelector('.el-input__icon')
      if (icon && !hasClass(icon, 'el-icon-circle-close')) {
        addClass(icon, 'is-reverse')
      }
    },

    scrollToOption(option) {
      const target = Array.isArray(option) && option[0] ? option[0].$el : option.$el
      if (this.$refs.popper && target) {
        const menu = this.$refs.popper.$el.querySelector('.el-select-dropdown__wrap')
        scrollIntoView(menu, target)
      }
      this.$refs.scrollbar && this.$refs.scrollbar.handleScroll()
    },

    emitChange(val) {
      if (!valueEquals(this.value, val)) {
        this.$emit('change', val)
      }
    },

    getOption(value) {
      let option
      const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]'
      const isNull = Object.prototype.toString.call(value).toLowerCase() === '[object null]'

      for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
        const cachedOption = this.cachedOptions[i]
        const isEqual = isObject
          ? getValueByPath(cachedOption.value, this.valueKey) === getValueByPath(value, this.valueKey)
          : cachedOption.value === value
        if (isEqual) {
          option = cachedOption
          break
        }
      }
      if (option) return option
      const label = (!isObject && !isNull)
        ? value : ''
      const newOption = {
        value: value,
        currentLabel: label
      }
      if (this.multiple) {
        newOption.hitState = false
      }
      return newOption
    },

    setSelected() {
      if (!this.multiple) {
        const option = this.getOption(this.value)
        if (option.created) {
          this.createdLabel = option.currentLabel
          this.createdSelected = true
        } else {
          this.createdSelected = false
        }
        this.selectedLabel = option.currentLabel
        this.selected = option
        if (this.filterable) this.query = this.selectedLabel
        return
      }
      const result = []
      if (Array.isArray(this.value)) {
        this.value.forEach(value => {
          result.push(this.getOption(value))
        })
      }
      this.selected = result
      this.$nextTick(() => {
        this.resetInputHeight()
      })
    },

    handleFocus(event) {
      if (!this.softFocus) {
        if (this.automaticDropdown || this.filterable) {
          this.visible = true
          this.menuVisibleOnFocus = true
        }
        this.$emit('focus', event)
      } else {
        this.softFocus = false
      }
    },

    blur() {
      this.visible = false
      this.$refs.reference.blur()
    },

    handleBlur(event) {
      setTimeout(() => {
        if (this.isSilentBlur) {
          this.isSilentBlur = false
        } else {
          this.$emit('blur', event)
        }
      }, 50)
      this.softFocus = false
    },

    handleIconClick(event) {
      if (this.iconClass.indexOf('circle-close') > -1) {
        this.deleteSelected(event)
      }
    },

    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy()
    },

    handleClose() {
      this.visible = false
    },

    toggleLastOptionHitState(hit) {
      if (!Array.isArray(this.selected)) return
      const option = this.selected[this.selected.length - 1]
      if (!option) return

      if (hit === true || hit === false) {
        option.hitState = hit
        return hit
      }

      option.hitState = !option.hitState
      return option.hitState
    },

    deletePrevTag(e) {
      if (e.target.value.length <= 0 && !this.toggleLastOptionHitState()) {
        const value = this.value.slice()
        value.pop()
        this.$emit('input', value)
        this.emitChange(value)
      }
    },

    managePlaceholder() {
      if (this.currentPlaceholder !== '') {
        this.currentPlaceholder = this.$refs.input.value ? '' : this.cachedPlaceHolder
      }
    },

    resetInputState(e) {
      if (e.keyCode !== 8) this.toggleLastOptionHitState(false)
      this.inputLength = this.$refs.input.value.length * 15 + 20
      this.resetInputHeight()
    },

    resetInputHeight() {
      if (this.collapseTags && !this.filterable) return
      this.$nextTick(() => {
        if (!this.$refs?.reference) return
        const inputChildNodes = this.$refs.reference.$el.childNodes
        const input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0]
        const tags = this.$refs.tags
        const sizeInMap = sizeMap[this.selectSize] || 40
        input.style.height = this.selected.length === 0
          ? sizeInMap + 'px'
          : Math.max(
            tags ? (tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0)) : 0,
            sizeInMap
          ) + 'px'
        if (this.visible && this.emptyText !== false) {
          this.broadcast('ElSelectDropdown', 'updatePopper')
        }
      })
    },

    resetHoverIndex() {
      setTimeout(() => {
        if (!this.multiple) {
          this.hoverIndex = this.options.indexOf(this.selected)
        } else {
          if (this.selected.length > 0) {
            this.hoverIndex = Math.min.apply(null, this.selected.map(item => this.options.indexOf(item)))
          } else {
            this.hoverIndex = -1
          }
        }
      }, 300)
    },

    handleOptionSelect(option, byClick) {
      if (this.multiple) {
        const value = this.value.slice()
        const optionIndex = this.getValueIndex(value, option.value)
        if (optionIndex > -1) {
          value.splice(optionIndex, 1)
        } else if (this.multipleLimit <= 0 || value.length < this.multipleLimit) {
          value.push(option.value)
        }
        this.$emit('input', value)
        this.emitChange(value)
        if (option.created) {
          this.query = ''
          this.handleQueryChange('')
          this.inputLength = 20
        }
        if (this.filterable) this.$refs.input.focus()
      } else {
        this.$emit('input', option.value)
        this.emitChange(option.value)
        this.visible = false
      }
      this.isSilentBlur = byClick
      this.setSoftFocus()
      if (this.visible) return
      this.$nextTick(() => {
        this.scrollToOption(option)
      })
    },

    setSoftFocus() {
      this.softFocus = true
      const input = this.$refs.input || this.$refs.reference
      if (input) {
        input.focus()
      }
    },

    getValueIndex(arr = [], value) {
      const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]'
      if (!isObject) {
        return arr.indexOf(value)
      } else {
        const valueKey = this.valueKey
        let index = -1
        arr.some((item, i) => {
          if (getValueByPath(item, valueKey) === getValueByPath(value, valueKey)) {
            index = i
            return true
          }
          return false
        })
        return index
      }
    },

    toggleMenu() {
      if (!this.selectDisabled) {
        if (this.menuVisibleOnFocus) {
          this.menuVisibleOnFocus = false
        } else {
          this.visible = !this.visible
        }
        if (this.visible) {
          (this.$refs.input || this.$refs.reference).focus()
        }
      }
    },

    selectOption() {
      if (!this.visible) {
        this.toggleMenu()
      } else {
        if (this.options[this.hoverIndex]) {
          this.handleOptionSelect(this.options[this.hoverIndex])
        }
      }
    },

    deleteSelected(event) {
      event.stopPropagation()
      this.$emit('input', '')
      this.emitChange('')
      this.visible = false
      this.$emit('clear')
    },

    deleteTag(event, tag) {
      const index = this.selected.indexOf(tag)
      if (index > -1 && !this.selectDisabled) {
        const value = this.value.slice()
        value.splice(index, 1)
        this.$emit('input', value)
        this.emitChange(value)
        this.$emit('remove-tag', tag.value)
      }
      event.stopPropagation()
    },

    onInputChange() {
      if (this.filterable && this.query !== this.selectedLabel) {
        this.query = this.selectedLabel
        this.handleQueryChange(this.query)
      }
    },

    onOptionDestroy(index) {
      if (index > -1) {
        this.optionsCount--
        this.filteredOptionsCount--
        this.options.splice(index, 1)
      }
    },

    resetInputWidth() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width
    },

    handleResize() {
      this.resetInputWidth()
      if (this.multiple) this.resetInputHeight()
    },

    getValueKey(item) {
      if (Object.prototype.toString.call(item.value).toLowerCase() !== '[object object]') {
        return item.value
      } else {
        return getValueByPath(item.value, this.valueKey)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$letter-container-width: 20px;
$letter-container-margin-left-right: 6px;
$padding-left-right: 10px;
$main-container-padding-right: 4px;
$scroll-bar-width: 6px;

.inner-container {
  padding: 16px 0;

  &.show-empty-text {
    padding-bottom: 0;
  }
}

.search-input {
  padding: 0 $padding-left-right;

  ::v-deep {
    .el-input__inner {
      background-color: #F2F2F2;
    }
  }
}

.operate-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $padding-left-right;

  .operate-btns {
    display: flex;
  }

  .clear-btn {
    font-size: 14px;
    color: #0D57BC;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .switch-select-btn {
    font-size: 14px;
    color: #0D57BC;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 100px;
  }
}

.selected-area {
  max-width: 386px;
  .el-tag {
    margin: 2px 0 2px 6px;
  }
  .letter-title {
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #F6F8FD;
    color: #0D57BC;
    margin-bottom: 12px;
    min-width: 356px;
  }
}

.main-container {
  display: flex;
  padding: 0 $main-container-padding-right 0 $padding-left-right;

  .check-box-group-container {
    position: relative;
    width: 100%;
    min-height: 200px;

    ::v-deep .el-checkbox-group {
      position: absolute;
      left: 0;
      right: -#{2 * $letter-container-margin-left-right + $letter-container-width};
      top: 0;
      bottom: 0;
      padding-right: #{2 * $letter-container-margin-left-right + $letter-container-width - $scroll-bar-width};
      overflow: auto;

      &::-webkit-scrollbar {
        width: $scroll-bar-width;
        height: $scroll-bar-width;
        cursor: pointer !important;
      }

      &::-webkit-scrollbar-track {
        border-radius: #{$scroll-bar-width / 2};
        box-sizing: border-box;
        cursor: pointer !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.2);
        border-radius: 3px;
        cursor: pointer !important;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(144, 147, 153, 0.4);
        cursor: pointer !important;
      }
    }

    .group {
      .letter-title {
        padding: 2px 10px;
        border-radius: 4px;
        font-size: 14px;
        background-color: #F6F8FD;
        color: #0D57BC;
      }

      .item-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .item {
          width: 49%;
          margin: 0 0 10px;
          padding: 0 5px;
          box-sizing: border-box;
          transition: color 0.3s;
          display: flex;
          align-items: center;

          ::v-deep {
            .el-checkbox__label {
              display: block;
              flex: 1;
              width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &:hover {
            color: #0D57BC;
          }
        }
      }
    }

    .load-more-search-result {
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      padding: 4px 0;
      transition: background-color 0.3s;
      user-select: none;
      color: #0D57BC;

      &:hover {
        background-color: #F2F2F2;
      }
    }
  }

  .letter-container {
    width: $letter-container-width;
    font-size: 13px;
    color: #ccc;
    text-align: center;
    margin: 0 $letter-container-margin-left-right;
    user-select: none;
    z-index: 9;

    .letter {
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        color: #0D57BC;
      }

      &:hover {
        background-color: #0D57BC;
        color: #fff;
      }
    }
  }
}
</style>
