<!--行业资讯和分析报告-->
<template>
    <router-view v-if="$route.name == '行业资讯内容页'||$route.name == '分析报告内容页'" />
    <PageContainer v-else breadcrumb>
                <div class="box_box">
<!--                        <el-tabs v-model="articleCategory" @tab-click="handleClick">-->
<!--                            <el-tab-pane label="行业资讯" name="industry"></el-tab-pane>-->
<!--                            &lt;!&ndash; <el-tab-pane label="分析报告" name="report"></el-tab-pane> &ndash;&gt;-->
<!--                        </el-tabs>-->
                    <div style="overflow: hidden">
<!--                            <div style="float: left" flex="c">-->
<!--                                <el-checkbox-group v-model="keywords" size="small" @change="handleChange">-->
<!--                                    <el-checkbox :label="item.dictValue" border v-for="item in keywordsData" :key="item.dictValue">{{ item.dictLabel }}</el-checkbox>-->
<!--                                </el-checkbox-group>-->
<!--                            </div>-->
                        <div style="float: right">
                            <el-input placeholder="搜索名称" size="small" v-model="title" @keyup.enter.native="search">
                                <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
                            </el-input>
                        </div>
                    </div>
                </div>
                <Vlist :list="tableData" :pageData="pageData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
    </PageContainer>
</template>
<script>
import { selectDictDataByType } from "@/api/dict.js";
import { ttIndustryInformationPage, ttIndexAnalysisPage } from "@/api/Index.js";
import Vlist from "@/components/list";
export default {
    components: {
        Vlist,
    },
    data() {
        return {
            defaultUrl: require("@/assets/img/default-img.png"),
            articleCategory: "industry",
            keywordsData: [],
            keywords: [],
            tableData: [],
            title: "",
            pageData: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },
            query: {},
        };
    },
    created() {
        this.query = this.$route.query;
        this.articleCategory = this.$route.query.category;
        this.keywords.push(this.$route.query.releaseColumn);
        this.allFun(this.articleCategory);
        this.selectDictDataByType();
    },
    methods: {
        //数据字典
        selectDictDataByType() {
            selectDictDataByType({ dictType: "release_column" }).then((res) => {
                if (res.ok) {
                    this.keywordsData = res.data;
                }
            });
        },
        //切换
        handleClick(e) {
            this.keywords = [];
            this.allFun(e.name);
        },
        handleSizeChange(v) {
            this.pageData.pages.pageSize = v;
            this.allFun(this.articleCategory);
        },
        handleCurrentChange(v) {
            this.pageData.pages.page = v;
            this.allFun(this.articleCategory);
        },
        //行业资讯和分析报告
        allFun(type) {
            switch (type) {
                case "industry":
                    this.ttIndustryInformationPage();
                    break;
                case "report":
                    this.ttIndexAnalysisPage();
                    break;
            }
        },
        //行业资讯
        ttIndustryInformationPage() {
            let page = {
                ...this.pageData.pages,
                releaseColumn:'industry_syc',
                title: this.title,
            };
            ttIndustryInformationPage(page).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.pageData.total = res.data.count;
                }
            });
        },
        //分析报告
        ttIndexAnalysisPage() {
            let page = {
                ...this.pageData.pages,
                publishColumn: this.keywords.join(","),
                title: this.title,
            };
            ttIndexAnalysisPage(page).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.pageData.total = res.data.count;
                }
            });
        },
        //类别切换
        handleChange(e) {
            this.keywords = e;
            this.allFun(this.articleCategory);
        },
        //搜索
        search() {
            this.allFun(this.articleCategory);
        },
        toHref(item) {
            let to = "";
            if (this.articleCategory == "industry") {
                to = "/index/detail/sycIndustryDetail";
                let newUrl = this.$router.resolve({
                    path: to,
                    query: {
                        id: item.id,
                        releaseColumn: this.query.releaseColumn,
                        category: this.query.category,
                    },
                });
                window.open(newUrl.href, "_blank");
            } else {
                to = "/index/detail/ReportDetail";
                this.$router.push({
                    path: to,
                    query: {
                        id: item.id,
                        releaseColumn: this.query.releaseColumn,
                        category: this.query.category,
                    },
                });
            }

        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
    display: none;
}
::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
}
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
}
.box_box {
    padding: 10px;
    background: #ffffff;
    /deep/ .el-input__inner {
        width: 235px;
        border-radius: 20px;
    }
}
.box_box_title {
    margin-top: 10px;
    background: #ffffff;
    cursor: pointer;
    p {
        font-weight: 600;
        color: #000;
        font-size: 16px;
    }
    .flex_col {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>
