<template>
  <div
    v-if="isShowCarModel"
    class="enterpriseDetail"
    id="CarModel"
    data-name="车型平台"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="车型平台"
      :sourceData="sourceData"
    >
    <template #headPaginator>
        <paginator
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
  </TableCard>
  </div>
</template>

<script>
import { getPlatformData } from "@/api/enterpriseAnalysis.js";
import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";
export default {
  name: "CarModel",
  components: {
    Paginator,
    TableCard
  },
  data() {
    return {
      isShowCarModel: false,
      isLoading: false,
      totalCount: 0,
      sourceData: {},
      params: {
        pageSize: 10,
        pageNum: 1,
        enterpriseId: this.$route.params.id
      }
    };
  },
  created() {
    this.getPlatformData(this.params, "isShowCarModel");
  },
  mounted() {},

  methods: {
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getPlatformData(this.params);
    },
    getPlatformData(params, isShowCarModel) {
      getPlatformData(params).then(res => {
        if (res.ok) {
          if (isShowCarModel) {
            this.isShowCarModel = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
        }
      });
    }
  }
};
</script>
