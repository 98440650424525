import DataVersionDialog from './dataVersionDialog'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

const DataVersionDialogConstructor = Vue.extend(DataVersionDialog)

Vue.component(DataVersionDialog.name, DataVersionDialog)

const install = (o) => {
  const instance = new DataVersionDialogConstructor({
    store,
    router,
    el: document.createElement('div')
  })
  if (o.show) {
    document.body.appendChild(instance.$el)
  }
  instance.show = o.show
}

export default install
