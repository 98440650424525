<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-20 11:45:18
-->
<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="title">
        <span v-if="cionShow" class="icon" />
        {{ title }}
        <span v-if="subTitle" class="sub-title">{{ subTitle }}</span>
      </span>
      <span v-if="titleInfoShow" class="title-info" />
      <div class="right-btn-box">
        <div v-if="exportData" class="btn" @click="exportXls">
          <img :src="require('@/assets/img/newData/download.png')" />下载数据
        </div>
        <div v-if="exportPng" class="btn" @click="exportSvg">
          <img :src="require('@/assets/img/newData/export.png')" />导出图片
        </div>
      </div>
    </div>
    <div v-if="btnList.length > 0" class="btn-box">
      <el-button
        v-for="(btnItem, index) in btnList"
        :key="index"
        size="mini"
        type="primary"
        :class="`${btnItem.isClick ? 'isClick' : ''}`"
        @click="btnClick(btnItem)"
      >
        {{ btnItem.title }}
      </el-button>
    </div>
    <slot name="body" />
  </el-card>
</template>
<script>
import { exportSalesData } from "@/api/data_new.js";
export default {
  name: "Card",
  props: {
    // 标题后的小文字展示
    titleInfoShow: {
      type: Boolean,
      default: false,
    },
    // 卡片名称
    title: {
      type: String,
      default: "请传入参数title",
    },
    subTitle: {
      type: String,
      default: "",
    },
    // 导出数据
    exportData: {
      type: Boolean,
      default: false,
    },
    // 导出图片
    exportPng: {
      type: Boolean,
      default: false,
    },
    // 按钮id
    btnId: {
      type: String,
      default: "0",
    },
    // 图标显示
    cionShow: {
      type: Boolean,
      default: false,
    },
    // 按钮list
    titlebtn: {
      type: Array,
      default: () => [],
    },
    exportId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      btnList: [],
    };
  },
  // created() {
  //   this.btnList = JSON.parse(JSON.stringify(this.titlebtn))
  // },
  methods: {
    initbtnList(titlebtn) {
      this.btnList = JSON.parse(JSON.stringify(titlebtn));
    },
    /**
     * @description: 按钮点击事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 10:07:17
     * @LastEditTime: Do not edit
     */
    btnClick(data) {
      this.btnList = this.btnList.map((item) => {
        return {
          ...item,
          ...{ isClick: data.id === item.id ? 1 : 0 },
        };
      });
      this.$emit("cardBtnchange", { type: this.btnId, data: data });
    },
    /**
     * @description: 下载图片按钮
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:25:40
     * @LastEditTime: Do not edit
     */

    exportSvg() {
      this.$emit("exportSvg", { type: this.btnId, name: this.title });
    },
    /**
     * @description: 数据导出
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:08:45
     * @LastEditTime: Do not edit
     */

    exportXls() {
      if (!this.exportId) {
        return;
      }
      this.$emit("exportXls", { exportId: this.exportId, name: this.title });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  padding-bottom: 24px;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 4px 4px;
  .title-info {
    margin-left: 12px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #828282;
  }
  /deep/.el-card__header {
    padding: 20px;
    border: none;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  /deep/.el-card__body {
    padding: 0 20px 20px;
    border: none;
  }
  /deep/.clearfix {
    font-size: 15px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
  }
  /deep/.right-btn-box {
    float: right;
    height: 24px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
    display: flex;
    align-items: center;

    .btn {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:last-child {
        margin-left: 28px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .el-icon-download:before,
    .el-icon-picture-outline:before {
      margin-right: 8px;
      font-size: 16px;
    }
  }
  .btn-box {
    margin-bottom: 8px;
    /deep/.el-button {
      padding: 4px 12px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      background-color: #fff;
      border: 1px solid #e5e6eb;
      color: #000000;
    }
    .isClick {
      color: #ffffff;
      background-color: #0d57bc;
    }
  }
}
@media screen and (max-width: 1366px) {
  .box-card {
    /deep/.el-card__header {
      padding: 20px 9px !important;
      border: none;
    }
  }
}

.sub-title {
  color: #86909c;
  font-size: 14px;
  font-weight: 400;
}
</style>
