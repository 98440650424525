<!--
 * @Author: your name
 * @Date: 2021-07-22 09:51:11
 * @LastEditTime: 2021-07-22 10:11:15
 * @LastEditors: Please set LastEditors
 * @Description: 整体销售预测
 * @FilePath: /information-vue/code/src/views/sale/salesForecast.vue
-->
<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="预测" text="预测栏目围绕预测本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新预测" :showTips="showTips" />-->
    <template>
      <!-- <div class="dynamic-top">
          <ItemTitle @click='chackMore' title="分析报告" />
          <div class="dynamic-top-original-box">
              <router-link :key="index" v-for="(item, index) in fxList" :to="{ path: '/sale/salesForecast/detail', query: { id: item.id } }" class="dynamic-top-original">
                  <div class="dynamic-top-original-img">
                      <img :src="item.imgUrl" alt="" />
                  </div>
                  <div class="dynamic-top-original-inner">
                      <p>{{item.shortTitle?item.shortTitle:item.title}}</p>
                      <span>{{item.publishDate}}</span>
                  </div>
              </router-link>
          </div>
      </div> -->
      <div class="g-content-table">
        <template>
          <div>
            <div class="search-form">
              <div style="position: absolute;right: 36px;">

                <el-tooltip class="item" effect="dark" :content="sycTip" placement="top-start">
                  <a style="margin-right: 10px;color: #606266" href="javascript:; "><i class="el-icon-info"></i>预测说明</a>
                </el-tooltip>

                <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn2">
                  导出
                </el-button>
              </div>
            </div>


            <template>
              <div class="g-content-top">
                <div flex="1">
                  <!-- {{ sycz }} -->
                  <h3 class="text-center"><span>{{ nowYear }}年商用车月度预测&nbsp;</span></h3>

                </div>
              </div>
              <div id="sycChart"></div>
              <div class="g-content-bottom">
                <el-table :data="sycTable" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                          style="width: 100%; margin-bottom: 20px" row-key="id" default-expand-all
                          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe>
                  <template v-for="(item, index) in sycHeader">
                    <el-table-column :prop="item" :label="item" :key="'syc' + index" v-if="item == '类别'"
                                     min-width="130"/>
                    <el-table-column :prop="item" :label="item" :key="'syc' + index" v-else min-width="100"/>
                  </template>
                </el-table>
              </div>
            </template>

            <div class="search-form">
              <div style="position: absolute;right: 36px;">
                <!-- 更新于{{ nsycsj }}，查看详情 -->
                <el-tooltip class="item" effect="dark" :content="nsycTip" placement="top-start">
                  <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>
                </el-tooltip>

                <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn(2)">
                  导出
                </el-button>
              </div>
            </div>


            <template>
              <div class="g-content-top">
                <div flex="1">
                  <!-- {{ nsycz }} -->
                  <h3 class="text-center"><span>{{ oldYear }}年商用车年度预测&nbsp;</span></h3>

                </div>
              </div>
              <div id="nsycChart"></div>

              <el-table :data="sycTableYear" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                        style="width: 100%; margin-bottom: 20px" row-key="id" default-expand-all
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe>
                <template v-for="(item, index) in sycTableHeaderYear">
                  <el-table-column :prop="item" :label="item" :key="'nsyc' + index">
                  </el-table-column>
                </template>
              </el-table>
            </template>
          </div>
        </template>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 预测动态页面
 * @param {*}
 * @return {*}
 */
import {getSaleList} from "@/api/salesForecast.js";
import VallTime from "./views/allTime.vue";
import Vmonth from "./views/month.vue";
import {exportPredictSyc, selectMouseTotalSyc, yearData, yearDataExport} from "@/api/sale";
import {downloadFile} from "@/utils/download";
import * as echarts from "echarts";
import {getUserInfo,openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    VallTime,
    Vmonth,
  },
  data() {
    return {
      fxList: null,
      activeName: "今年月度预测",
      showTips: [
        {
          title: "预测动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "预测库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "预测梳理",
          text: "专家梳理整合，真正读懂预测脉络",
        },
        {
          title: "预测大数据",
          text: "用数据深度理解预测结构和趋势",
        },
      ],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
      nowYear: "",
      oldYear: "",
      sycTable: [],
      sycTableYear: [],
      sycupdateTime: "",
      sycz: "",
      sycTip: "商用车预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考",
      sycHeader: [],
      syctotal: [],
      leftTotalList: [],
      nsycTip: "商用车预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考",
      sycTableHeader: [],
      sycTableHeaderYear: [],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
    };
  },
  created() {
    let date = new Date(),
        year = date.getFullYear();
    this.nowYear = year;
    let date1 = new Date(),
        year1 = date1.getFullYear();
    this.oldYear = year1 + "-" + (year1 + 3);
    this.setDate();
    this.init();

  },

  methods: {
    init() {

      selectMouseTotalSyc().then((res) => {
        if (res.ok) {
          //时间
          let timeList = res.data.timeList;
          //更新时间
          this.sycupdateTime = res.data.updateTime;
          //日期
          let strTime = timeList[0];
          let endTime = timeList[timeList.length - 1];
          this.fromData(strTime);
          this.fromData(endTime);
          this.sycz =
              this.fromData(strTime) + "至" + this.fromData(endTime);
          //提示信息
          this.initChart2("sycChart", res.data);

          //初始化表格
          let table = [];
          //客车大类
          let dataResultKcMap = res.data.dataResultKcMap;
          //货车大类
          let dataResultHcMap = res.data.dataResultHcMap;
          //客车数据
          let dataKcMap = res.data.dataKcMap;
          //货车数据
          let dataHcMap = res.data.dataHcMap;
          //合计
          let totalList = res.data.totalList;

          //处理货车大类数据
          for (let key in dataResultHcMap) {
            let obj = {};
            obj["类别"] = key;
            for (let i = 0; i < timeList.length; i++) {
              if (dataResultHcMap[key][i]) {
                obj[timeList[i]] =
                    dataResultHcMap[key][i].toFixed(2);
              }
            }
            obj["合计"] = this.counts(dataResultHcMap[key]);
            obj.children = [];
            table.push(obj);
          }

          //处理客车大类数据
          for (let key in dataResultKcMap) {
            let obj = {};
            obj["类别"] = key;
            for (let i = 0; i < timeList.length; i++) {
              if (dataResultKcMap[key][i]) {
                obj[timeList[i]] =
                    dataResultKcMap[key][i].toFixed(2);
              }
            }
            obj["合计"] = this.counts(dataResultKcMap[key]);
            obj.children = [];
            table.push(obj);
          }
          //空数据处理
          let emptyData = []
          //处理客车数据
          let kcTable = [];
          for (let key in dataKcMap) {
            if (dataKcMap[key]) {
              let obj = {};
              obj["类别"] = key;
              for (let i = 0; i < timeList.length; i++) {
                obj[timeList[i]] = dataKcMap[key][i].toFixed(2);
              }
              obj["合计"] = this.counts(dataKcMap[key]);
              kcTable.push(obj);
            }
          }

          //处理货车数据
          let hcTable = [];
          for (let key in dataHcMap) {
            if (dataHcMap[key]) {
              let obj = {};
              obj["类别"] = key;
              for (let i = 0; i < timeList.length; i++) {
                obj[timeList[i]] = dataHcMap[key][i].toFixed(2);
              }
              obj["合计"] = this.counts(dataHcMap[key]);
              hcTable.push(obj);
            }
          }

          for (let i = 0; i < table.length; i++) {
            table[i]["id"] = i + 1;

            if (table[i].类别 == "客车") {
              for (let j = 0; j < kcTable.length; j++) {
                kcTable[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = kcTable;
            } else if (table[i].类别 == "货车") {
              for (let j = 0; j < hcTable.length; j++) {
                hcTable[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = hcTable;
            }
          }

          //处理合计数据
          let zjObj = {};
          zjObj["类别"] = "总计";
          for (let i = 0; i < timeList.length; i++) {
            zjObj[timeList[i]] = totalList[i].toFixed(2);
          }
          zjObj["合计"] = this.counts(totalList);
          table.push(zjObj);
          this.sycTable = table;
          this.sycHeader = ["类别"].concat(timeList, "合计");

          for (let i = 0; i < table.length; i++) {
            this.syctotal.push(table[i]["合计"]);
            if (table[i].children && table[i].children.length > 0) {
              for (let j = 0; j < table[i].children.length; j++) {
                this.syctotal.push(
                    table[i].children[j]["合计"]
                );
              }
            }
          }

          console.log(this.sycTable)
        }
      });
//年度商用车
      yearData({cartype: 2}).then((res) => {
        if (res.ok) {
          this.sychartData = res.data;
          this.initChart("nsycChart", this.sychartData);

          let data = res.data;
          let cxData = data.data;
          let year = data.year;
          let table = [];
          //货车数据
          let hyserMap = res.data.hyserMap;
          //客车数据
          let kyserMap = res.data.kyserMap;

          for (let key in cxData) {
            let obj = {类别: key, children: []};
            for (let i = 0; i < year.length; i++) {
              if (cxData[key][i]) {
                obj[year[i]] = cxData[key][i].toFixed(2);
              }
            }
            table.push(obj);
          }
          //货车数据
          let hcData = [];
          //客车数据
          let kcData = [];
          for (let key in hyserMap) {
            let obj = {类别: key};
            for (let i = 0; i < year.length; i++) {
              if (hyserMap[key][i]) {
                obj[year[i]] = hyserMap[key][i].toFixed(2);
              }
            }
            hcData.push(obj);
          }
          for (let key in kyserMap) {
            let obj = {类别: key};
            for (let i = 0; i < year.length; i++) {
              if (kyserMap[key][i]) {
                obj[year[i]] = kyserMap[key][i].toFixed(2);
              }
            }
            kcData.push(obj);
          }
          for (let i = 0; i < table.length; i++) {
            table[i]["id"] = i + 1;
            if (table[i]["类别"] == "货车") {
              for (let j = 0; j < hcData.length; j++) {
                hcData[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = hcData;
            } else if (table[i]["类别"] == "客车") {
              for (let j = 0; j < kcData.length; j++) {
                kcData[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = kcData;
            }
          }
          this.sycTableYear = table;
          this.sycTableHeaderYear = ["类别"].concat(year);
        }
      });
    },
    initChart(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      let xData = data.year;
      let cxdata = data.data;
      let cx = [];
      cx = data.car;
      let seriesData = [];
      let tbList = data.tbList.map((item) => Number(item));
      for (let key in cxdata) {
        seriesData.push({
          name: key,
          name: key,
          type: "bar",
          stack: "Search Engine",
          data: cxdata[key],
        });
      }

      seriesData.push({
        name: "同比增速",
        type: "line",
        yAxisIndex: 1,
        data: tbList,
      });
      var option = {
        title: {
          subtext: "万辆",
        },
        legend: {
          data: cx.concat("同比增速"),
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = datas[i].value
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: seriesData,
      };
      myChart.clear();
      myChart.setOption(option);
    },
    //计算数组的和
    counts(arr) {
      let count = 0;
      if (arr.length > 0) {
        arr.forEach((item) => {
          count += item;
        });
      }
      return count.toFixed(2);
    },
    initChart2(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var xData = data.timeList;
      var dataMap = Object.assign({}, data.dataHcMap, data.dataKcMap);
      var tbzsList = data.tbzsMap.map((item) => Number(item));
      var vehicleList = [];
      var seriesData = [];

      if (dataMap) {
        for (let key in dataMap) {
          vehicleList.push(key);
        }
      }

      for (let key in dataMap) {
        seriesData.push({
          name: key,
          type: "bar",
          stack: "Search Engine",
          data: dataMap[key],
        });
      }
      seriesData.push({
        name: "同比增速",
        type: "line",
        yAxisIndex: 1,
        data: tbzsList,
      });
      var option = {
        title: {
          subtext: "万辆",
        },

        legend: {
          data: vehicleList.concat("同比增速"),
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = datas[i].value
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: seriesData,
      };
      myChart.clear();
      myChart.setOption(option);
    },
    fromData(str) {
      let index = str.indexOf("F");
      let s = "";
      if (index != -1) {
        s = str.slice(0, index);
      } else {
        s = str;
      }
      return s;
    },
    excelbtn(type) {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == "trialrole") {
          openSy();
          this.insertBuryPoint();
        } else {
          let self = this;
          yearDataExport({cartype: type}).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                if (type == 1) {
                  downloadFile(res, "乘用车数据.xlsx");
                } else {
                  downloadFile(res, "商用车年度预测数据.xlsx");
                }
              }
            });
          });
        }
      }
    },

    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //商用车导出
    excelbtn2() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == "trialrole") {
          openSy();
          this.insertBuryPoint();
        } else {
          let self = this;
          let obj = {leftTotalList: this.syctotal};
          exportPredictSyc(obj).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "商用车月度预测数据.xlsx");
              }
            });
          });
        }
      }
    },
    chackMore() {
      this.$router.push({
        path: "/sale/salesForecast/list",
      });
    },
    getSaleList() {
      getSaleList({pageNo: 1, pageSize: 3}).then((res) => {
        if (res.ok) {
          this.fxList = res.data.list;
          // this.content = res.data[0];
        }
      });
    },
    handleClick(tab, event) {
      if (tab.name == "未来5年年度预测") {
        this.$nextTick(() => {
          this.$refs.cherts.refInit();
        });
      }
    },
    setDate() {
      let date = new Date(),
          year = date.getFullYear();
      this.nowYear = year;
      this.oldYear = year + '-' + (year + 3);
    },
  }
  ,
}
;
</script>
<style lang="scss" scoped>
.dynamic-top {
  padding: 20px 32px;
  margin-bottom: 12px;
  background: #fff;

  .dynamic-top-original-box {
    display: flex;
    margin-top: 32px;
    margin-left: -26px;
    cursor: pointer;

    .dynamic-top-original {
      display: flex;
      flex: 0 0 33.333%;
      margin-left: 26px;

      .dynamic-top-original-img {
        width: 150px;
        height: 99px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
        }
      }

      .dynamic-top-original-inner {
        width: 140px;
        margin-left: 6px;
        position: relative;

        p {
          // line-height: 21px;
          color: #000;
          font-weight: 500;
          // height: 64px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        p:hover {
          color: #00f;
        }

        span {
          color: #828282;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

#nsycChart {
  width: 100%;
  height: 500px;
}

#sycChart {
  width: 100%;
  height: 500px;
}
</style>
