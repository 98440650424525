<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" isContentTwo="bzjd" name="解读" />

    <template slot="right">
      <VcontentItem
        title="征求意见稿推荐"
        type="list"
        :list="relationComment"
        @click="tohref"
      />
      <VcontentItem
        title="标准动态推荐"
        type="list"
        :list="relationDynamic"
        @click="tohref"
      />
      <VcontentItem
        title="标准解读推荐"
        type="list"
        :list="relationInterpret"
        @click="tohref"
      />
      <VcontentItem
        title="标准主体推荐"
        type="list"
        :list="relationSelf"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { ttStandardUnscramble } from "@/api/standards.js";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "applicableProducts",
        htmlContent: "content",
        source:"unscrambleSource"
      },
      relationComment: [],
      relationDynamic: [],
      relationInterpret: [],
      relationSelf: [],
    };
  },
    watch: {
    $route(to, from) {
      let id = to.params.id;
      if (id) {
        this.ttStandardUnscramble(id);
      }
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.ttStandardUnscramble(id);
  },
  methods: {
    ttStandardUnscramble(params) {
      ttStandardUnscramble(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relationComment = res.data.relationComment;
          this.relationDynamic = res.data.relationDynamic;
          this.relationInterpret = res.data.relationInterpret;
          this.relationSelf = res.data.relationStandard;
        }
      });
    },
    tohref(item) {
      if (item.tableName == "tt_solicit_comments") {
        //征求意见稿
        this.$router.push("/standards/dynamic/contentComments/" + item.id);
      } else if (item.tableName == "tt_standard_dynamic") {
        //标准动态推荐
        this.$router.push("/standards/dynamic/contentDynamic/" + item.id);
      } else if (item.tableName == "tt_standard_unscramble") {
        //标准解读推荐
        this.$router.push("/standards/dynamic/contentUnscramble/" + item.id);
      } else {
        //标准主体推荐
        this.$router.push("/standards/dynamic/ttStandard/" + item.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
