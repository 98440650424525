<script>
export default {
    name: "newlistHead",
    props: {
        title: {
            type: String,
            default: "",
        },
        url: {
            type: String,
            default: "",
        },
        href: {
            type: [String, Object],
            default: "",
        },
        isMore: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        comUrl() {
            return require("@/" + this.url);
        },
    },
    methods: {
        onClick() {
            this.$emit("click");
        },
    },
    render() {
        return (
            <div
                class="list-head-color"
                style={
                    "background:url(" +
                    this.comUrl +
                    ")100% 100% no-repeat;background-size:cover"
                }
            >
                <div class="list-head-box">
                    <div class="list-head-box-l">{this.title}</div>
                    {this.isMore ? (
                        <div class="list-head-box-r" on-click={this.onClick}>
                            <router-link
                                to={this.href}
                                tag="a"
                                class="link-style"
                            >
                                查看更多<i class="el-icon-arrow-right"/>
                            </router-link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    },
};
</script>
<style lang="scss" scoped>
.list-head-color {
    border-radius: 3px;
}
.list-head-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 20px 0  30px;
    color: #fff;
    border-radius: 2px;
    color: #000;
    background: linear-gradient(to right, rgba(245,245,278,1) 0%, rgba(246,248,255,.1) 100%);

    .list-head-box-l {
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
    }
    .list-head-box-r {
        font-size: 14px;
        line-height: 14px;
    }
    .link-style {
        color: #999;
    }
}
</style>