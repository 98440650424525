<!--本网精选列表-->
<template>
  <router-view v-if="$route.name == '本网精选内容页面'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="本网精选" smallTip="权威专家 深度观点" :bgurl="bgurl" />
    <VlistImg :list="ttWebsiteSelectedPageData" :page="pageData" :pageData="pageData" :props="props" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" to="/index/detail/WebsiteSelectedDetail/" />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { ttWebsiteSelectedPage } from "@/api/Index.js";
import VlistImg from "@/components/listImg";
export default {
    components: {
        VlistTitle,
        VlistImg,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            ttWebsiteSelectedPageData: [],
            pageData: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },
            props: {
                img: "imgUrl",
                summary: "introduction",
            },
        };
    },
    created() {
        this.ttWebsiteSelectedPage(this.pageData.pages);
    },
    methods: {
        ttWebsiteSelectedPage(params) {
            ttWebsiteSelectedPage(params).then((res) => {
                if (res.ok) {
                    this.ttWebsiteSelectedPageData = res.data.list;
                    this.pageData.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.pageData.pages.pageSize = v;
            this.ttWebsiteSelectedPage(this.pageData.pages);
        },
        handleCurrentChange(v) {
            this.pageData.pages.page = v;
            this.ttWebsiteSelectedPage(this.pageData.pages);
        },
    },
};
</script>
