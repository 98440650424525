<template>
  <div class="category-list custom-scrollbar">
    <div
      v-for="item in list"
      :key="item"
      :class="[
        'category-item',
        activeName === item ? 'active' : '',
        isInternational ? 'heavy-active' : ''
      ]"
      @click="handleClickCategory(item)"
    >
      {{ item }}
    </div>
    <slot class="append" />
  </div>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    list: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      default: null
    },
    isInternational: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleClickCategory(item) {
      this.$emit('selectChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-list {
  flex: 1;
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-right: 24px;
  height: 100%;

  .category-item {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #4F4F4F;
    transition-property: color, background-color;
    transition-duration: 0.3s;
    cursor: pointer;
    margin-right: 48px;
    height: 100%;
    font-weight: bold;
    font-size: 15px;

    &.heavy-active {
      margin-right: 18px;
      padding: 0 15px;
    }

    &::before {
      content: '';
      opacity: 0;
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #0D57BC;
      transition-property: opacity, color;
      transition-duration: 0.3s;
    }

    &.active, &:hover {
      color: #0D57BC;
      font-weight: bold;

      // 加重激活效果
      &.heavy-active {
        background-color: #0D57BC;
        color: #fff;

        // 不再展示下划线
        &::before {
          opacity: 0;
        }
      }

      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
