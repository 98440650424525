<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-26 14:59:50
-->
<template>
  <div style="width:100%" :id="'barEchart' + echartKey">
    <div class="barEchart" :style="`height:${height}px`" ref="barEchart"></div>
  </div>
</template>
<script>
// 引入echarts
import * as echarts from "echarts";
//引入dom监听器
export default {
  name: "barEchart",
  data() {
    return {
      myChart: null, //echartDom对象
      echartKey: Math.random(),
      // Eechart数据
      echartData: {},
    };
  },
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
  },
  mounted() {},
  methods: {
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.echartData = data;
      this.initEchart();
      this.initLister();
    },
    /**
     * @description: 初始化监听器
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:18
     * @LastEditTime: Do not edit
     */
    initLister() {
      let elementResizeDetectorMaker = require("element-resize-detector");
      //监听元素变化
      let erd = elementResizeDetectorMaker();
      erd.listenTo(
        document.getElementById("barEchart" + this.echartKey),
        (element) => {
          this.$nextTick(() => {
            //使echarts尺寸重置
            this.myChart.resize();
          });
        }
      );
    },
    /**
     * @description: 初始化echart
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:08
     * @LastEditTime: Do not edit
     */
    initEchart(name) {
      this.myChart = echarts.init(this.$refs.barEchart);
      let option = {
        title: {
          text: name ? name : "",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     myTool: {
        //       show: true, //是否显示
        //       title: "按考试查询成绩", //鼠标悬空的提示文字
        //       icon:
        //          "path://M200.753 408.251c-57.062 0-103.749 46.687-103.749 103.749s46.687 103.749 103.749 103.749S304.502 569.062 304.502 512s-46.687-103.749-103.749-103.749z m622.494 0c-57.062 0-103.749 46.687-103.749 103.749s46.687 103.749 103.749 103.749S926.996 569.062 926.996 512s-46.687-103.749-103.749-103.749z m-311.247 0c-57.062 0-103.749 46.687-103.749 103.749S454.938 615.749 512 615.749 615.749 569.062 615.749 512 569.062 408.251 512 408.251z", //这个是阿里icon svg 后 b的全部字符串
        //       onclick: (o) => {
        //         alert(111);
        //       },
        //     },
        //   },
        // },
        // grid: {
        //   left: "10%",
        // },
        grid: {
          right: 5,
          left: 5,
          bottom: 25,
          containLabel: true,
        },
        legend: {
          right: 0,
          data: this.echartData.legendData,
        },
        xAxis: {
          type: "category",
          data: this.echartData.xAxisData,
        },

        yAxis: [
          {
            type: "value",
            name: "辆",
            max: this.echartData.y0Max,
            min: this.echartData.y0Min,
            splitNumber: 5,
            interval: (this.echartData.y0Max - this.echartData.y0Min) / 5
            // axisLabel: {
            //   formatter: "{value} ml",
            // },
          },
          {
            type: "value",
            name: "%",
            max: this.echartData.y1Max,
            min: this.echartData.y1Min,
            splitNumber: 5,
            interval: (this.echartData.y1Max - this.echartData.y1Min) / 5
            // axisLabel: {
            //   formatter: "{value} %",
            // },
          },
        ],
        series: this.echartData.seriesData,
      };
      // {
      //   value: 18203,
      //   itemStyle: {
      //     color: "red",
      //        borderRadius: 5
      //   },
      // }
      this.myChart.setOption(option);
    },
    /**
     * @description: echart图片导出
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:30:11
     * @LastEditTime: Do not edit
     */

    exportSvg(name) {
      if (this.myChart) {
        // this.initEchart(name);
        const fileName = name ? name + ".png" : "图表.png";
        const base64 = this.myChart.getDataURL({
          type: "png",
          pixelRatio: 2,
          backgroundColor: "#FFFFFF",
        });
        this.downloadFileByBase64(base64, fileName);
      } else {
        this.$message.error("无法导出");
      }
      // this.initEchart();
    },
    /**
     * @description: 转Blob
     * @param {*} dataurl
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:36
     * @LastEditTime: Do not edit
     */

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = window.atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    /**
     * @description: 下载文件
     * @param {*} url
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:47
     * @LastEditTime: Do not edit
     */

    downloadFile(url, name) {
      var a = document.createElement("a"); // 新建一个a链接
      a.setAttribute("href", url); // a链接的url为图片的url
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      const clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    /**
     * @description: base64转化为文件
     * @param {*} base64
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:53
     * @LastEditTime: Do not edit
     */

    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64);
      var myUrl = URL.createObjectURL(myBlob); // 创建图片的临时url
      this.downloadFile(myUrl, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.pieEchart {
  width: 100%;
  height: 100%;
}
</style>
