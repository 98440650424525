<template>
  <div
    class="enterpriseDetail"
    id="businessInfo"
    v-if="isShowBusinessInfo"
    data-name="经营情况"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="经营情况"
      :sourceData="sourceData"
    >
      <template #headPaginator>
        <paginator
          ref="paginator"
          :total-items="totalCount"
          :items-per-page="6"
          @page-change="handlePageChange"
        />
      </template>
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import {
  getBusinessCircumstance,
  getExportBusinessCircumstance
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";
export default {
  name: "BusinessInfo",
  components: {
    Paginator,
    TableCard
  },
  data() {
    return {
      exportState: false,
      isShowBusinessInfo: false,
      params: {
        pageSize: 6,
        pageNum: 1,
        enterpriseId: this.$route.params.id
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      totalCount: 0,
      sourceData: {},
      isLoading: false
    };
  },
  created() {
    this.getBusinessCircumstance(this.params, "isShowBusinessInfo");
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业信息", "3", "", "", "", "","经营情况", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              getExportBusinessCircumstance(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "经营情况.xlsx");
              });
            }
          }
        } else {
          getExportBusinessCircumstance(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "经营情况.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getBusinessCircumstance(this.params);
    },
    getBusinessCircumstance(params, isShowBusinessInfo) {
      this.isLoading = true;
      getBusinessCircumstance(params).then(res => {
        if (res.ok) {
          if (isShowBusinessInfo) {
            this.isShowBusinessInfo = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
          this.isLoading = false;
        }
      });
    }
  }
};
</script>
