<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" />

    <template slot="right">
      <VcontentItem
        title="相关政策推荐"
        type="list"
        :list="relationPolicy"
        @click="tohref"
      />
      <VcontentItem
        title="政策报道推荐"
        type="list"
        :list="relationReport"
        @click="tohref"
      />
      <VcontentItem
        title="政策解读推荐"
        type="list"
        :list="relationInterpret"
        @click="tohref"
      />
      <VcontentItem
        title="原创解读"
        type="list"
        :list="relationCourse"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { queryContentByIdPolicy } from "@/api/Index.js";
import { getUserInfo } from "@/utils/auth.js";
export default {
  data() {
    return {
      data: {},
      props: {
        htmlContent: "content",
        summary: "introduction",
      },
      relationPolicy: [],
      relationReport: [],
      relationInterpret: [],
      relationCourse: [],
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.queryContentByIdPolicy(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.queryContentByIdPolicy(id);
  },
  methods: {
    queryContentByIdPolicy(params) {
      queryContentByIdPolicy(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relationPolicy = res.data.relationPolicy.map((item) => {
            item.publishDate = item.publish_date;
            return item;
          });
          this.relationReport = res.data.relationReport.map((item) => {
            item.publishDate = item.publish_date;
            return item;
          });
          this.relationInterpret = res.data.relationInterpret.map((item) => {
            item.publishDate = item.publish_date;
            return item;
          });
          this.relationCourse = res.data.relationUnitInterpret.map((item) => {
            item.publishDate = item.publish_date;
            return item;
          });
        }
      });
    },
    tohref(item) {
      let userInfo = getUserInfo();
      if (!userInfo) {
        this.showLogin();
        return false;
      }
      if (item.tableName == "tt_policy_report") {
        //相关政策报道
        this.$router.push("/policy/dynamic/contentReport/" + item.id);
      } else if (item.tableName == "tt_policy_interpret") {
        //政策解读推荐
        this.$router.push("/policy/dynamic/contentExplain/" + item.id);
      } else {
        //政策主体推荐
        this.$router.push("/policy/dynamic/contentNewPolicy/" + item.id);
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin({ flag: true });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
