<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips" />-->
    <template>
      <div class="search-form">
        <form ref="form" class="g-form">
          <el-row style="margin-bottom: 28px">

            <el-col :span="8">

              <span style="display: inline-block; width: 90px">热门领域：</span>
              <el-cascader
                v-model="form.belongAreas"
                :options="belongAreas"
                :props="belongAreasProps"
                collapse-tags
                clearable
                style="width: 200px"
                size="small"
              />

            </el-col>
            <el-col :span="8">
              <span style="display: inline-block; width: 90px">标准类别：</span>
              <el-select v-model="form.category" multiple collapse-tags placeholder="请选择" size="small">
                <el-option
                  v-for="item in policyCategory"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-col>
            <el-col :span="8">
              <!--              <el-dropdown @command="handleCommand">-->
              <!--                                <span class="el-dropdown-link">-->
              <!--                                    {{-->
              <!--                                    dateTypeName-->
              <!--                                  }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--                                </span>-->
              <!--                <el-dropdown-menu slot="dropdown">-->
              <!--                  <el-dropdown-item command="1">发布日期</el-dropdown-item>-->
              <!--                  <el-dropdown-item command="2">实施日期</el-dropdown-item>-->
              <!--                  <el-dropdown-item command="3">作废日期</el-dropdown-item>-->
              <!--                </el-dropdown-menu>-->
              <!--              </el-dropdown>-->
              <span style="display: inline-block; width: 90px">发布日期：</span>

              <!-- (按月)时间区段: -->
              <el-date-picker
                v-model="dateVal"
                type="monthrange"
                range-separator="-"
                start-placeholder="开始月份"
                style="width: 200px;"
                end-placeholder="结束月份"
                value-format="yyyy-MM"
                size="small"
                @change="changeDate"
              />
            </el-col>
          </el-row>

          <div class="text-center">
            <el-button class="reset-button" @click="restForm">重置</el-button>
            <el-button class="search-button" @click="search">查询</el-button>
          </div>
        </form>
      </div>
      <div class="search-form">

        <el-row>
          <div style="font-weight: bold;text-align: center;font-size: 16px;padding-bottom: 10px;color: rgb(0, 0, 0)">{{ dateVal[0] }} 至 {{ dateVal[1] }} 标准数据
          </div>
          <el-col :span="8">
            <Histogram :value="map1" />
          </el-col>
          <el-col :span="8">
            <Histogram2 :value="map2" />
          </el-col>
          <el-col :span="8">
            <Histogram3 :value="map3" />
          </el-col>
        </el-row>

        <div style="float: right;margin: 4px 0 8px 0;">
          <span style="font-weight: bold;color: #000000;margin-right: 20px;">共计 <span style="color: red">{{ standardLibraryPage.total }}</span> 条</span>
          <el-button v-has-role="'freeusers'" class="search-button" style="width: 100px;" type="primary" plain :loading="excelLoading" @click="Export">导出</el-button>
        </div>
        <el-table
          ref="tableBox"
          v-loading="loading"
          :data="standardLibraryDate"
          style="width: 100%"
          :header-cell-style="{ background: '#e9f4ff', color: '#000' }"
          row-key="id"
          stripe
        >

          <el-table-column sortable="custom" prop="state" label="状态" min-width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.state == '未实施'" class="state">{{ scope.row.state }}</span>
              <span v-else-if="scope.row.state == '作废'" class="state red">{{ scope.row.state }}</span>
              <span v-else-if="scope.row.state == '现行'" class="state gre">{{ scope.row.state }}</span>
              <span v-else-if="scope.row.state == '被替代'" class="state blue">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="标准号" min-width="150">
            <template slot-scope="scope">
              <div
                style="cursor: pointer;color: #0D57BC"
                @click="viewDetail('/standards/dynamic/ttStandard/' , scope.row.id,scope.row.title)"
              >
                {{ htmlRestore(scope.row.code) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="categoryExtend" label="标准类别" min-width="100" />

          <el-table-column prop="title" label="标准名称" min-width="300">
            <template slot-scope="{row}">
              {{ htmlRestore(row.title) }}
            </template>
          </el-table-column>
          <el-table-column prop="publishDate" label="发布日期" min-width="100" sortable="custom" />
          <el-table-column prop="implementDate" label="实施日期" min-width="100" sortable="custom" />
          <el-table-column prop="voidDate" label="作废日期" min-width="100" />
        </el-table>
        <div class="text-right">
          <el-pagination
            v-if="pageShow"
            :current-page="standardLibraryPage.pages.page"
            :page-size="standardLibraryPage.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="standardLibraryPage.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>

      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 标准查询页面
 * @param {*}
 * @return {*}
 */
import Histogram from '@/components/G2Plot/Column.vue'
import Histogram2 from '@/components/G2Plot/Pie.vue'
import Histogram3 from '@/components/G2Plot/Pie2.vue'

import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { standardBigData, standardDataExport, standardLibrary } from '@/api/standards.js'
import { selectDictDataByType, ttDemension } from '@/api/dict.js'
import { download } from '@/utils/download.js'
import { buryPoint } from '@/api/common'

export default {
  components: { Histogram, Histogram2, Histogram3 },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: '标准动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '标准库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '标准梳理',
          text: '专家梳理整合，真正读懂标准脉络'
        },
        {
          title: '标准大数据',
          text: '用数据深度理解标准结构和趋势'
        }
      ],
      dateVal: '',
      checkAll: false,
      checkAll2: false,
      form: {
        category: '',
        // belongAreas: [],
        beginDate: '',
        endDate: '',
        province: '',
        dateType: '1',
        belongAreas: ''
      },
      // 所属领域
      belongAreas: [],
      policyCategory: [],
      belongAreasProps: {
        multiple: true,
        value: 'id',
        label: 'name'
      },
      map1: [],
      map2: [],
      map3: [],

      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '标准',
        userId: ''
      },
      loading: false,
      excelLoading: false,
      standardLibraryDate: [],
      standardLibraryPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      pageShow: true
    }
  },
  computed: {
    dateTypeName() {
      let name = '发布日期'
      switch (this.form.dateType) {
        case '1':
          name = '发布日期'
          break
        case '2':
          name = '实施日期'
          break
        case '3':
          name = '废止日期'
          break
      }
      return name
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.defaultDate()
    this.standardBigData(this.form)
    this.selectDictDataByType({ dictType: 'standard_type' })
    this.getbelongAreas()
    const form = Object.assign(
      {},
      this.form,
      this.standardLibraryPage.pages
    )
    this.standardLibrary(form)
  },
  mounted() {
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 多选
    handleSelectionChange(val) {
      if (val.length > this.limitNum) {
        this.limitFun(val, 'tableBox')
        return
      }
      this.selectTable = [...val]
    },
    standardBigData(params) {
      standardBigData(params).then((res) => {
        // console.log(res.data)
        this.map1 = res.data.map1
        this.map2 = res.data.map2
        this.map3 = res.data.map3
        // this.chartFilter(res.data);
      })
    },
    standardLibrary(params) {
      // let form = Object.assign(
      //     {},
      //     this.form,
      //     this.standardLibraryPage.pages
      // );
      // if (form.dateType == '1') {
      //   form.startPublishDate = this.form.beginDate+'-01';
      //   form.endPublishDate = this.form.endDate+'-31';
      // } else if (form.dateType == '2') {
      //   form.startImplementDate = this.form.beginDate+'-01';
      //   form.endImplementDate = this.form.endDate+'-31';
      // } else if (form.dateType == '3') {
      //   form.startVoidDate = this.form.beginDate+'-01';
      //   form.endVoidDate = this.form.endDate+'-31';
      // }
      params.startPublishDate = this.form.beginDate + '-01'
      params.endPublishDate = this.form.endDate + '-31'
      if (!getUserInfo()) {
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.standardLibraryPage.pages.page > 1) {
        this.showLogin()
      } else {
        this.loading = true

        standardLibrary(params).then((res) => {
          if (res.ok) {
            this.standardLibraryDate = res.data.list
            this.standardLibraryPage.total = res.data.count
          }
          this.loading = false
        })
      }
    },
    handleSizeChange(val) {
      this.standardLibraryPage.pages.pageSize = val
      const form = Object.assign(
        {},
        this.form,
        this.standardLibraryPage.pages
      )
      this.standardLibrary(form)
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.standardLibraryPage.pages.page = val
        const form = Object.assign(
          {},
          this.form,
          this.standardLibraryPage.pages
        )
        this.standardLibrary(form)
      }
    },
    viewDetail(path, id, title) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve({
        path: path + id
      })

      if (getUserInfo()) {
        logRecords('标准', '标准大数据', '1', 'tt_standard', id, '', title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 设置默认日期   写在methods: {}中
    defaultDate() {
      const date = new Date()
      const year = date.getFullYear().toString()
      const lastYear = (date.getFullYear() - 1).toString()
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
      const end = year + '-' + month
      const beg = lastYear + '-' + month
      this.dateVal = [beg, end] // 将值设置给插件绑定的数据
      this.form.beginDate = this.dateVal[0]
      this.form.endDate = this.dateVal[1]
    },
    // 数据字典
    selectDictDataByType(params) {
      selectDictDataByType(params).then((res) => {
        if (res.ok) {
          // console.log(res);
          this.policyCategory = res.data
        }
      })
    },
    // 标准类别
    handleCheckAllChange(val) {
      this.form.category = []
      if (val) {
        this.policyCategory.forEach((item) => {
          this.form.category.push(item.dictValue)
        })
      } else {
        this.form.category = []
      }
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.form.category = value
      this.checkAll = checkedCount === this.policyCategory.length
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.policyCategory.length
    },
    // 时间
    changeDate(v) {
      this.form.beginDate = v[0]
      this.form.endDate = v[1]
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 查询
    search() {
      const formCopy = Object.assign({}, this.form)
      if (formCopy.category) {
        formCopy.category = formCopy.category.join(',')
      }
      if (formCopy.belongAreas) {
        formCopy.belongAreas = this.unique(
          formCopy.belongAreas.join(',').split(',')
        ).join(',')
      }
      formCopy.beginDate = formCopy.beginDate + '-01'
      formCopy.endDate = formCopy.endDate + '-31'
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.standardBigData(formCopy)
            this.standardLibrary(formCopy)
          }
        } else {
          this.standardBigData(formCopy)
          this.standardLibrary(formCopy)
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 重置
    restForm() {
      this.form = {
        category: '',
        // belongAreas: [],
        beginDate: '',
        endDate: '',
        province: '',
        dateType: '1',
        belongAreas: ''
      }
      this.checkAll = false
      this.defaultDate()
      this.standardBigData(this.form)
      this.standardLibrary(this.form)
    },

    // 导出
    Export() {
      if (getUserInfo()) {
        logRecords('标准', '标准大数据', '3', 'tt_standard', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              this.excelLoading = true

              const formCopy = Object.assign({}, this.form)
              if (formCopy.belongAreas) {
                formCopy.belongAreas = this.unique(
                  formCopy.belongAreas.join(',').split(',')
                ).join(',')
              }
              if (formCopy.category) {
                formCopy.category = formCopy.category.join(',')
              }
              formCopy.beginDate = formCopy.beginDate + '-01'
              formCopy.endDate = formCopy.endDate + '-31'
              standardDataExport(formCopy).then((res) => {
                this.excelLoading = false

                download(res, '标准大数据', 'xls')
              })
            }
          }
        } else {
          this.excelLoading = true

          const formCopy = Object.assign({}, this.form)
          if (formCopy.belongAreas) {
            formCopy.belongAreas = this.unique(
              formCopy.belongAreas.join(',').split(',')
            ).join(',')
          }
          if (formCopy.category) {
            formCopy.category = formCopy.category.join(',')
          }
          formCopy.beginDate = formCopy.beginDate + '-01'
          formCopy.endDate = formCopy.endDate + '-31'
          standardDataExport(formCopy).then((res) => {
            this.excelLoading = false

            download(res, '标准大数据', 'xls')
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 下拉框选择
    handleCommand(command) {
      this.form.dateType = command
    },
    // 所属领域
    getbelongAreas() {
      ttDemension({ code: 'standardTagsThree' }).then((res) => {
        this.belongAreas = res.data
      })
    },
    // 数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      width: 85px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 185px;

      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.state {
  padding: 4px 6px;
  background: #f2c94c;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  font-weight: bold;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0D57BC;
  color: #fff;
}
.map-box {
  background-color: white;
  height: 422px;
  //padding: 0 40px
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f4f9ff;
}
::v-deep .el-table td, .el-table th.is-leaf {
  border-bottom: 1px solid #ffffff;
}
.borderStyle{
  border: solid 1px #cfdcf1;
  margin: 5px;
  padding: 10px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
