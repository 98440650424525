<template>
  <span class="investigate" @click="handleClick">
    <i class="icon el-icon-magic-stick"></i>
    <span>有奖调研</span>

    <el-dialog
      :visible.sync="visible"
      :top="'0'"
      style="height:auto;"
      append-to-body
    >
      <iframe
        id="bdIframe2"
        :src="iframeSrc2"
        style="width:100%;height:100%;"
        frameborder="0"
        scrolling="yes"
      >
      </iframe>

      <template slot="footer">
        <el-button type="primary" @click="visible = false">关 闭</el-button>
      </template>
    </el-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      iframeSrc2: "https://www.wjx.cn/vm/hyVeQ3K.aspx"
    };
  },
  methods: {
    handleClick() {
      this.visible = true

      setTimeout(function() {

        /**
         * iframe-宽高自适应显示
         */
        const oIframe = window.frames["bdIframe2"];
        const deviceWidth = document.documentElement.clientWidth;
        const deviceHeight = document.documentElement.clientHeight;
        // oIframe.style.width = Number(deviceWidth) - 220 + "px"; //数字是页面布局宽度差值
        oIframe.style.width = Number(deviceWidth); //数字是页面布局宽度差值
        oIframe.style.height = Number(deviceHeight) - 150 + "px"; //数字是页面布局高度差
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.investigate {
  cursor: pointer;
}
</style>
