<template>
  <div class="breadcrumb-box">
    <div class="max-container">
      <div class="box">
        <template v-for="(item, index) in levelList">
          <span :key="index" v-if="index == levelList.length - 1" class="item">{{ item.name }}</span>
          <template v-else>
            <a :key="index" class="item link" @click.prevent="handleLink(item)">{{ item.name}}</a>
            <span :key="`${index}-separator`" class="item separator">/</span>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "breadcrumb",
  data() {
    return {
      levelList: null,
      query: {},
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        this.query = route.query;
        this.getBreadcrumb(route);
      },
    },
  },
  methods: {
    getBreadcrumb(route) {
      let breadcrumbList = [];
      if (route.meta && route.meta.breadcrumb) {
        breadcrumbList = route.meta.breadcrumb;
      }

      // let matched = route.matched.filter(
      //   (item) => item.meta && item.meta.title && item.path != '#'
      // );
      // const first = matched[0];
      // if (!this.isDashboard(first)) {
      //   matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
      // }
      // this.levelList = matched.filter((item) => item.meta && item.meta.title);
      this.levelList = breadcrumbList;
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name === "首页";
    },
    handleLink(item) {
      const { url } = item;
      // if (redirect) {
      //   if (this.query) {
      //     delete this.query.id;
      //   }
      //   this.$router.push({ path: redirect, query: this.query });
      //   return;
      // }
      this.$router.push({ path: url, query: this.query });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.breadcrumb-box {
  background: #fff;

  .box {
    display: flex;
    align-items: center;
    height: $breadcrumb-height;

    .item {
      display: inline-flex;
      align-items: center;
      color: #999;
      font-size: 14px;
      font-weight: 500;
      height: 100%;

      &.link {
        color: #333;
        &:hover {
          color: #000;
        }
      }

      &.separator {
        margin: 0 12px;
        color: #999;
      }
    }
  }
}
</style>
