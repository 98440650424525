<template>

  <PageContainer footer>

    <!--    <div class="right-guide-container">-->
    <!--      <div class="right-guide-box">-->
    <!--        <el-popover placement="left" trigger="hover">-->

    <!--          <div>-->
    <!--            <span style="color: #0f0f0f;font-size: 14px">欢迎试用！<br>我们为您提供最全面、权威、专业的服务</span>-->
    <!--            <div style="color: #0f0f0f;padding: 10px;font-size: 14px">-->
    <!--              市场部-于经理<br>-->
    <!--              <img src="@/assets/img/dh.png" alt="" class="more-icon"/> <span style="color: #ff6a00;font-size: 14px">022-84379145/18846828756</span><br/>-->
    <!--              <img src="@/assets/img/yj.png" alt="" class="more-icon"/> <span style="font-size: 14px">yuhuiying@catarc.ac.cn</span>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <a class="right-guide-research" slot="reference" href="javascript:;"><i class="el-icon-phone"></i>免费试用</a>-->
    <!--        </el-popover>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="zcbz-box">
      <el-row :gutter="22" style="padding-top: 10px;">
        <el-col :span="12">
          <div class="block">
            <el-carousel height="340px">
              <el-carousel-item>
                <img src="@/assets/img/sycbk.jpg" style="width: 640px">
              </el-carousel-item>
              <el-carousel-item>
                <router-link to="/us" target="_blank" tag="a"><img src="@/assets/img/sycxx.jpg" style="width: 640px">
                </router-link>
              </el-carousel-item>

            </el-carousel>
          </div>
          <!--          <div>-->
          <!--            <img src="@/assets/img/sycbk.jpg">-->
          <!--            <router-link-->
          <!--                to="/us"-->
          <!--                target="_blank"-->
          <!--                class="index-banner-box-btn"-->
          <!--                tag="a"-->
          <!--            >了解更多<i style="padding-left:4px" class="el-icon-right"></i></router-link>-->
          <!--          </div>-->
        </el-col>
        <el-col :span="12" style="padding-left: 0px">
          <div class="index-right">

            <div class="index-banner-title">
              <div class="title-tag">
                <!--                <img src="@/assets/img/Group1.png" alt=""/>-->
                <h3 class="title-tag-text" style="margin-top: 3px">行业资讯</h3>
              </div>
              <router-link
                  :to="{
                    path: '/index/list/sycIndustryAndReportList',
                    query: {
                      releaseColumn: '',
                      category: 'industry',
                    },
                  }"
                  tag="div"
                  class="more"
              >查看更多<img
                  src="@/assets/img/jttb.png"
                  alt=""
                  class="more-icon"
              /></router-link>
            </div>
            <ul class="index-new-timeLine">
              <li
                  v-for="item in ttIndustryInformationPageSizeFive"
                  :key="item.id"
              >
                <div class="index-new-timeLine-content">
                  <router-link
                      :to="{
                      path: '/index/detail/sycIndustryDetail',
                      query: {
                        id: item.id,
                        releaseColumn: item.releaseColumn,
                        category: 'industry',
                      }
                  }"
                      class="index-new-link"
                  >
                    <div flex="c">
                      <span class="text-overflow" style="font-size: 16px;line-height: 20px;color: #000;"> {{
                          item.title
                        }}</span>
                      <span style="font-size: 14px;">{{ item.updateTime }}</span>
                    </div>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <div flex="c">
              <h1 style="margin-right:10px">政策标准</h1>

            </div>
            <router-link to="/policy/dynamic/sycIndex" tag="a" class="more"><span>查看更多</span><i
                class="el-icon-arrow-right"></i></router-link>

          </div>
        </VdataTitle>
        <div class="new-box">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="zcbz-item-bottom">
                <div flex="sb" class="zcbz-new-class">
                  <h1 class="title">政策</h1>

                </div>
                <div class="zcbz-item-bottom-tab-content">
                  <div class="zcbz-item-bottom-tab-list">
                    <a href="#" @click="viewDetail('/policy/dynamic/syc/contentNewPolicy/'+item.policyContentId)"
                       class="index-new-link" v-for="item in selectPolicyDate" :key="item.policyContentId">
                      <div class="newUl-areas" v-if="item.menu=='政策主体'">主体</div>
                      <div class="newUl-areas" v-else-if="item.menu=='政策解读'">解读</div>
                      <div class="newUl-areas" v-else-if="item.menu=='政策报道'">报道</div>

                      <span class="text-overflow text" style="padding-left: 5px;">{{
                          htmlRestore(item.title)
                        }}</span>
                      <span class="time">{{ item.publishTime }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="zcbz-item-bottom">
                <div flex="sb" class="zcbz-new-class">
                  <h1 class="title">标准</h1>

                </div>
                <div class="zcbz-item-bottom-tab-content">
                  <div class="zcbz-item-bottom-tab-list">
                    <a href="#" @click="viewDetail('/standards/dynamic/syc/ttStandard/'+item.id)"
                       class="index-new-link" v-for="item in standardLibraryDate" :key="item.id">
                      <div class="newUl-areas" v-if="item.menu=='标准征求意见稿'">意见稿</div>
                      <div class="newUl-areas" v-else-if="item.menu=='标准解读'">解读</div>
                      <div class="newUl-areas" v-else-if="item.menu=='标准动态'">动态</div>
                      <div class="newUl-areas" v-else-if="item.menu=='标准主体'">主体</div>

                      <span class="text-overflow text">{{
                          htmlRestore(item.title)
                        }}</span>
                      <span class="time">{{ item.updateTime }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <div flex="c">
              <h1 style="margin-right:10px">数据监测</h1>

            </div>
            <router-link to="/data/data/sycIndex" tag="a" class="more"><span>查看更多</span><i
                class="el-icon-arrow-right"></i></router-link>

          </div>
        </VdataTitle>
        <div class="new-box">

          <el-row :gutter="26">
            <el-col :span="12">
              <!--              <p class="echarts-title">商用车月度市场走势</p>-->
              <div class="echarts-inner">
                <div id="production"></div>
              </div>
              <!--              <p class="echarts-title">商用车年度市场走势</p>-->

              <div class="echarts-inner">
                <div id="sales"></div>
              </div>

            </el-col>
            <el-col :span="12" style="padding-left: 20px;padding-top: 10px;">
              <div @click="toHref('/data/dataTable/qccxhzb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份汽车(分车型)产销汇总表
              </div>
              <div @click="toHref('/data/dataTable/qcsczyqycxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份汽车生产主要企业产销情况表
              </div>
              <div @click="toHref('/data/dataTable/xnyqccxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份新能源汽车产销情况表
              </div>
              <div @click="toHref('/data/dataTable/qcfqyckqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份汽车分企业出口情况表
              </div>
              <div @click="toHref('/data/dataTable/sychzb/')"
                   class="list-item"><img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{
                  newTime[0]
                }}年{{ newTime[1] }}月份商用车产销汇总表
              </div>
              <div @click="toHref('/data/dataTable/sychzb/')"
                   class="list-item"><img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{
                  newTime[0]
                }}年{{ newTime[1] }}月份商用车产销汇总表
              </div>
              <div @click="toHref('/data/dataTable/sycscqycxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份商用车生产企业产销情况表
              </div>
              <div @click="toHref('/data/dataTable/wdqcqysyc/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年01-{{
                  newTime[1]
                }}月份五大汽车企业集团商用车产销情况表
              </div>
              <div @click="toHref('/data/dataTable/scqycxqkb/', '7')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份载货车生产企业产销情况表
              </div>
              <div @click="toHref('/data/dataTable/getZhcfcxCxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份载货车(分车型)产销情况表
              </div>
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '7')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份载货车(分燃料)产销情况表
              </div>
              <div @click="toHref('/data/dataTable/zhcfdwcxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份载货车(分吨位)产销情况表
              </div>
              <div @click="toHref('/data/dataTable/zhcfytcxqkb/')" class="list-item">
                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{
                  newTime[1]
                }}月份载货车(分用途)产销情况表
              </div>
              <!--              <div @click="toHref('/data/dataTable/scqycxqkb/', '12')" class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{-->
              <!--                  newTime[1]-->
              <!--                }}月份客车生产企业产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '12')"-->
              <!--                   class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{-->
              <!--                  newTime[1]-->
              <!--                }}月份客车(分燃料)产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/getKcfcxCxqkb/')" class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{-->
              <!--                  newTime[1]-->
              <!--                }}月份客车(分车型)产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '17')"-->
              <!--                   class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{-->
              <!--                  newTime[1]-->
              <!--                }}月份货车非完整车辆(分车型)产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/getHcfwzclscqycxqkb/')"-->
              <!--                   class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{-->
              <!--                  newTime[1]-->
              <!--                }}月份货车非完整车辆生产企业产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '18')"-->
              <!--                   class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{ newTime[1] }}月份客车非完整车辆(分车型)产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/getBgqycscqycxqkb/')"-->
              <!--                   class="list-item">-->
              <!--                <img src="@/assets/img/sjtb.png" style="padding-right: 5px;">{{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车生产企业产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/bgqycfdwcxqkb/')" class="list-item">-->
              <!--                <i class="el-icon-s-data"></i>{{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分吨位)产销情况表-->
              <!--              </div>-->
              <!--              <div @click="toHref('/data/dataTable/bgqycfcxcxhzb/')" class="list-item">-->
              <!--                <i class="el-icon-s-data"></i>{{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分车型)产销汇总表-->
              <!--              </div>-->
            </el-col>
          </el-row>

        </div>
      </div>
      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <div flex="c">
              <h1 style="margin-right:10px">洞察报告</h1>

            </div>
            <router-link to="/thematicAnalysis/syc/index" tag="a" class="more"><span>查看更多</span><i
                class="el-icon-arrow-right"></i></router-link>

          </div>
        </VdataTitle>
        <div class="new-box">
          <el-row :gutter="20">
            <el-col :span="12">

              <el-row :gutter="20">
                <el-col :span="12" v-for="item in tableDataYc" :key="item.id">
                  <div style="padding: 5px">
                    <a href="#" @click="toHrefDcbg(item)"
                       class="index-new-link">
                      <img style="width: 260px;height: 150px"
                           :src="item.imgUrl">
                    </a>
                  </div>

                </el-col>

              </el-row>
            </el-col>
            <el-col :span="12">
              <div class="zcbz-item-bottom">
                <div flex="sb" class="zcbz-new-class">
                  <h1 class="title">最新发布</h1>

                </div>
                <div class="zcbz-item-bottom-tab-content">
                  <div class="zcbz-item-bottom-tab-list">
                    <a href="#" @click="toHrefDcbg(item)"
                       class="index-new-link" v-for="item in tableDataZx" :key="item.id">
                    <span class="text-overflow text"><i class="el-icon-document"></i><span style="
                                        display: inline-block;
                                        color: #0D57BC;
                                        font-size: 14px;
                                        line-height: 16px;
                                        padding: 4px;
                                        background: rgba(13, 87, 188, 0.08);
                                        margin-right: 4px;
                                        "
                                                                                           v-if="item.contentCategory == 'yc'"
                    >原创</span>{{
                        htmlRestore(item.title)
                      }}</span>
                      <span class="time">{{ item.publishDate }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="list-box">
        <VdataTitle url="assets/img/charlie-deets-D1W5Qen.png" flex="sc">
          <div class="list-box-title">
            <div flex="c">
              <h1 style="margin-right:10px">企业情报</h1>

            </div>
            <router-link to="/workInformation/syc/analysis/index" tag="a" class="more"><span>查看更多</span><i
                class="el-icon-arrow-right"></i></router-link>

          </div>
        </VdataTitle>
        <div class="new-box">
          <el-row :gutter="20">
            <el-col :span="12">

              <div class="map-box" ref="chinaMap"/>

            </el-col>
            <el-col :span="12">
              <div class="new-class-btn">
                <router-link
                    to="/workInformation/syc/analysis/index"
                    tag="a"
                    target="_blank"
                    class="new-class-btn-c"
                >企业分析报告
                </router-link
                >
                <router-link
                    to="/workInformation/syc/financialReport/index"
                    tag="a"
                    target="_blank"
                    class="new-class-btn-c"
                >企业财报
                </router-link
                >
                <router-link
                    to="/workInformation/syc/information/workDetail"
                    tag="a"
                    target="_blank"
                    class="new-class-btn-c"
                >企业信息
                </router-link
                >
              </div>
              <ul class="newUl">
                <li v-for="item in tableAnalysisData" :key="item.id">
                  <div class="newUl-link" style="cursor: pointer"
                       @click="viewDetail('/workInformation/syc/analysis/analysisContent/'+item.id)">
                    <div class="newUl-title"><i class="el-icon-document"></i><span style="
                                        display: inline-block;
                                        color: #0D57BC;
                                        font-size: 14px;
                                        line-height: 16px;
                                        padding: 4px;
                                        background: rgba(13, 87, 188, 0.08);
                                        margin-right: 4px;
                                        "
                                                                                   v-if="item.contentCategory == 'yc'"
                    >原创</span>{{ item.title }}
                    </div>
                    <div class="newUl-time">{{ item.publishDate }}</div>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>



    <!-- <div class="alert-img">
            <a href="javascript:;" class="alert-link">
                <img src="@/assets/img/dbewm.jpg" alt="">
                <i class="el-icon-circle-close alert-colse" @click="alertClose"></i>
            </a>
        </div> -->
    <!--    <div style="float: right; display: flex;position: fixed;z-index: 100;">-->
    <!--      <button>-->
    <!--      免费试用-->
    <!--      </button>-->
    <!--    </div>-->

  </PageContainer>

</template>
<script>
import {getYearMonth, fmtDate} from "@/utils/date.js";
import VdataTitle from "@/components/dataTitle";

// 获取首页请求接口
import {
  ttIndexSpecialPage,
  ttHighInterviewPage,
  ttLocalWatchPage,
  ttIndustryInformationPage,
  ttVideoRecommendationPage,
  ttMeetingInfoPage,
  selectByGroup,
  ttIndexAnalysissGroup,
  ttOtherPage,
  ttWebsiteSelectedPage,
  ttAdvertisingManagementPage,
  queryNewStandard,
  queryNewPolicy,
  ttIndustryInformationHomepage,
  ttLinksPage, YkbQcCxDataChart,
} from "@/api/Index.js";
//字典管理
import {selectDictDataByType} from "@/api/dict.js";
import {getUserInfo} from "@/utils/auth.js";
import * as echarts from "echarts";
import {lastTimes} from "@/api/data";
import {pageSyc} from "@/api/thematicAnalysis";
import {getPolicySyc, selectPolicy} from "@/api/policy";
import {getStandardSyc, standardLibrary} from "@/api/standards";
import {AnalysisQueryByPage, getEnterpriseCn, getProvinceCn} from "@/api/enterpriseAnalysis";
import echarts4 from 'echarts4';
import china from '@/assets/jsondata/china_1.json'
import {DualAxes} from "@antv/g2plot";

echarts4.registerMap('china', china)
let dualAxes1;
let dualAxes2;
export default {
  components: {
    VdataTitle
  },
  data() {
    return {
      // 本网观察
      ttLocalWatchPageSizeOne: [],
      // 会议信息
      ttMeetingInfoPageSizeSix: [],
      // 最新快讯
      ttIndustryInformationPageSizeFive: [],
      // 视频
      ttVideoRecommendationPageSizeThree: [],
      //最新政策
      newPolicyData: [],
      //最新标准
      newStandardData: [],
      // 专题管理
      ttIndexSpecialPageSizeTwo: [],
      //广告管理
      ttAdvertisingManagementdData: [],
      //友情链接
      ttLinks: [],
      //会议字典
      meetingDict: [],
      //会议值
      meetingVal: "",
      activeName: " ",
      zcAcName: "政策动态",
      bzAcName: "标准动态",
      hyzxPage: {
        pageSize: 7,
        page: 1,
      },
      hyzxData: [],
      loadinginName: "点击加载更多",
      checkAlert: true,
      newTime: [],
      tableDataYc: [],
      tableDataZx: [],
      selectPolicyDate: [],
      standardLibraryDate: [],
      tableAnalysisData: []
    };
  },
  computed: {

    nowDate() {
      return getYearMonth(new Date());
    },
    nowDateOne() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 1));
    },
    nowDateTwo() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 2));
    },
  },
  created() {
    this.init();
  },
  // 在这里调用，就会展示一张中国地图啦
  mounted() {
    this.getProvinceCn();

  },
  methods: {
    toHrefDcbg(item) {
      let url = '/thematicAnalysis/details/' + item.id;
      if (item.tableName == '专题分析') {
        url = '/thematicAnalysis/details/' + item.id;
      } else if (item.tableName == '前沿技术') {
        url = '/cuttingEdgeTechnology/details/' + item.id;

      } else if (item.tableName == '市场洞察') {
        url = "/marketInsight/detail/insightReportDetail/" + item.id;
      } else if (item.tableName == '政策原创') {
        url = "/policy/dynamic/syc/LocalOriginalDetails/" + item.id;
      }
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");


    },
    getProvinceCn() {
      getProvinceCn().then((res) => {
        if (res.ok) {
          let data = res.data;
          this.chinaConfigure(data);
        }
      });
    },
    chinaConfigure(seriesData) {
      let myChart = echarts4.init(this.$refs.chinaMap) //这里是为了获得容器所在位置
      window.onresize = myChart.resize
      myChart.setOption({
        title: {
          text: '',
          subtext: '',
          left: 'right',
          subtextStyle: {
            align: 'left'
          }
        },
        grid: {

          bottom: '2%',//距离下边的距离
          top: '2%' //距离上边的距离
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b}'
          // formatter: '{b}<br/>{c} (个)'
          formatter: function (params) {

            // console.log(params)
            if (isNaN(params.value)) {
              return '无';

            } else {
              return params.name + ':' + params.value + '万辆';

            }
          }
        },
        visualMap: {
          min: 1,
          max: 350,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['#E4F8FA', '#88D3FE', '#297CCD']
          }
        },
        geo: {
          map: "china",
          roam: false,// 一定要关闭拖拽
          zoom: 1.25,
          // center: [105, 36], // 调整地图位置
          label: {


            show: true, //省份名展示
            fontSize: "10",
            color: "rgba(0,0,0,0.7)",
            emphasis: {
              show: false,

            }
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            geoIndex: 0, // 解决设置geo后地图重影问题
            zoom: 1.25, //这里是关键，一定要放在 series中
            // silent: false, //鼠标移入区域变色 如果设置为true则无法高亮
            // 鼠标移入高亮区域属性

            itemSytle: {
              normal: {
                areaColor: "rgba(0,255,255,.02)",
                borderColor: "#00ffff",
                borderWidth: 1.5,
                shadowColor: "#00ffff",
                shadowOffsetX: 0,
                shadowOffsetY: 4,
                shadowBlur: 10
              },
              emphasis: {
                show: true,
                areaColor: 'blue', //鼠标滑过区域颜色
                color: 'blue', //鼠标滑过区域颜色
              }
            },
            data: seriesData
          }
        ]
      })
      myChart.on('click', (params) => {//点击事件

        if (params.componentType === 'series') {

          this.$router.push('/workInformation/syc/capacity/index');

        }
      });
    },
    init() {
      this.lastTimes();

      ttIndustryInformationPage({
        pageSize: 7,
        page: 1,
        releaseColumn: "industry_syc",
      }).then((res) => {
        if (res.ok) {
          this.ttIndustryInformationPageSizeFive = res.data.list;
          this.ttIndustryInformationPageSizeFive.forEach((item) => {
            item.updateTime = fmtDate(
                "YYYY-mm-dd",
                item.updateTime
            );
          })

        }
      });
      YkbQcCxDataChart({cllx: 2, lb: 1}).then((res) => {
        if (res.ok) {
          this.ColumnLine1(res.data);
        }
      });
      YkbQcCxDataChart({cllx: 2, lb: 2}).then((res) => {
        if (res.ok) {
          this.ColumnLine2(res.data);
        }
      });
      this.pageRight("yc");
      this.pageRight("");
      getPolicySyc({pageSize: 5, page: 1}).then((res) => {
        if (res.ok) {
          this.selectPolicyDate = res.data;
          this.selectPolicyDate.forEach((item) => {
            item.publishTime = fmtDate(
                "YYYY-mm-dd",
                item.publishTime
            );
          });


        }
      });
      getStandardSyc({pageSize: 5, page: 1}).then((res) => {
        if (res.ok) {
          this.standardLibraryDate = res.data;
          this.standardLibraryDate.forEach(item => {
            item.updateTime = fmtDate(
                "YYYY-mm-dd",
                item.updateTime
            );
          })
        }
      });

      AnalysisQueryByPage({sycFlag: 'syc', pageSize: 11, page: 1}).then((res) => {
        if (res.ok) {
          this.tableAnalysisData = res.data.list;
          this.tableAnalysisData.forEach(item => {
            item.publishDate = fmtDate(
                "YYYY-mm-dd",
                item.updateTime
            );
          })
        }
      });


    },
    ColumnLine1(data) {
      dualAxes1 = new DualAxes('production', {
        data: [data, data],
        xField: 'time',
        yField: ['value', 'count'],
        geometryOptions: [
          {
            geometry: 'column',
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
          },
        ],
        meta: {
          value: {
            alias: '销量（万辆）',
          },
          count: {
            alias: '同比增速（%）',
          },
        },
      });

      dualAxes1.render();
    },
    ColumnLine2(data) {
      dualAxes2 = new DualAxes('sales', {
        data: [data, data],
        xField: 'time',
        yField: ['value', 'count'],
        geometryOptions: [
          {
            geometry: 'column',
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
          },
        ],
        meta: {
          value: {
            alias: '销量（万辆）',
          },
          count: {
            alias: '同比增速（%）',
          },
        },
      });

      dualAxes2.render();

    },
    //获取右侧列表
    pageRight() {
      let form = {page: 1, pageSize: 4, contentCategory: "yc"}
      let form2 = {page: 1, pageSize: 6, contentCategory: " "}

      pageSyc(form).then((res) => {
        this.tableDataYc = res.data.list;
        this.tableDataYc.forEach(item => {
          item.publishDate = fmtDate(
              "YYYY-mm-dd",
              item.publishDate
          );
        })
      });
      pageSyc(form2).then((res) => {
        this.tableDataZx = res.data.list;
        this.tableDataZx.forEach(item => {
          item.publishDate = fmtDate(
              "YYYY-mm-dd",
              item.publishDate
          );
        })
      });
    },
    viewDetail(path) {
      let newUrl = this.$router.resolve({
        path: path
      });

      window.open(newUrl.href, "_blank");

    },
    toHref(url, type) {
      let str = "";
      str = type
          ? (str = url + this.newTime.join("-") + "-01/" + type)
          : url + this.newTime.join("-") + "-01";
      let newUrl = this.$router.resolve({
        path: str
      });
      window.open(newUrl.href, "_blank");

    },

    chartsInit(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        legend: {
          show: false,
          data: data.legend,
          textStyle: {
            color: '#000000'
          }
        },
        title: {
          text: data.title,
          subtext: '',
          left: 'center',
          textStyle: {
            fontSize: 15
          }
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = Number(datas[i].value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: '100%',
          right: '100%',
          bottom: '10%',
          top: '15%',
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.xAxis3,
            axisTick: {
              inside: true,
            },
            axisLabel: {

              textStyle: {
                color: '#000000'
              }
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {

              textStyle: {
                color: '#000000'
              }
            }
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
              textStyle: {
                color: '#000000'
              }
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },

          },
        ],
        series: data.series3,
      };
      myChart.setOption(option);
    },
    lastTimes() {
      //获取最新年月
      lastTimes().then((res) => {
        if (res.ok) {
          this.newTime = res.data.split("-");
        }
      });
    },


    //会议切换
    handleDictClick() {
      this.ttMeetingInfoPage();
    },
    handleDateClick() {
      this.ttMeetingInfoPage();
    },
    //政策切换
    zcDongBtn(name) {
      this.zcAcName = name;
    },
    //标准切换
    bzDongBtn(name) {
      this.bzAcName = name;
    },
    //加载更多
    jzMoreBtn() {
      this.hyzxPage.page++;
      this.hyzx();
    },
    alertClose() {
    },
  },
};
</script>
<style lang="scss" scoped>
//.index-container {
//  background: {
//    color: #fff;
//  }
//}

.index-banner {
  width: 100%;
  background: {
    color: #0d57bc;
  }

  &-box {
    position: relative;
    max-width: 1260px;
    height: 363px;
    margin: 0 auto;
    background: {
      image: url("../../assets/img/Group897.png");
      repeat: no-repeat;
      size: contain;
    }

    &-btn {
      position: absolute;
      left: 68px;
      top: 272px;
      display: block;
      width: 100px;
      line-height: 28px;
      border: 1px solid #fff;
      border-radius: 10px;
      text-align: center;
      color: #0d57bc;
      font-size: 16px;
      background-color: #fff;
    }

    &-right {
      position: absolute;
      top: 45px;
      right: 0;
      width: 782px;
      height: 346px;
      padding: {
        top: 18px;
        left: 23px;
        right: 98px;
      }
      background: url("../../assets/img/Group8977.png") no-repeat right bottom,
      linear-gradient(188.9deg, #ffffff -3.68%, #e5edf8 120.21%);
      border-radius: 4px;
      box-shadow: 0px 1px 2px rgba($color: #0a34c4, $alpha: 0.3);
    }
  }
}

.index-banner-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-tag {
  display: flex;
  align-items: flex-start;

  .title-tag-text {
    font-size: 18px;
    line-height: 18px;
    color: #0d57bc;
    margin-top: 9px;
    margin-left: 15px;
    font-weight: bold;
  }
}

.more {
  display: flex;
  align-items: center;
  color: #0d57bc;
  font-size: 14px;
  font-weight: 500;

  &-icon {
    padding-left: 2px;
  }
}

.hy-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #0d57bc;
  cursor: pointer;
  margin: {
    top: 7px;
    bottom: 22px;
    left: 50px;
  }
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-top: 20px;
  margin-left: 15px;

  li {
    position: relative;

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid #0d57bc;
      z-index: 1;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      left: 3px;
      width: 1px;
      height: 100%;
      border-left: 1px dashed #dcdcdc;
    }

    .index-new-timeLine-content {
      position: relative;
      padding-left: 4px;
      padding-bottom: 11px;

      .index-new-link {
        display: block;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #dcdcdc;
        padding-left: 20px;

        div {
          span {
            font-size: 16px;
            line-height: 16px;

            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

// .zcbz-box,
.video-box,
.qy-xc,
.bottom-all {
  max-width: 1200px;
  margin: 29px auto 40px;
}

.zcbz-box {
  max-width: 1200px !important;
  margin: 0px auto;
}

.bottom-all {
  margin-bottom: 0;
}

.video-box {
  margin-top: 27px;

  .video-link {
    font-weight: bold;
    font-size: 24px;
    line-height: 19px;
    cursor: pointer;
    color: #0d57bc;
    margin-bottom: 24px;

    i {
      font-weight: bold;
      padding-left: 8px;
    }
  }

  .video-list {
    width: 100%;
    cursor: pointer;

    &-img {
      position: relative;
      width: 284px;
      height: 141px;
      background: linear-gradient(
              1.22deg,
              #000000 -55.49%,
              rgba(0, 0, 0, 0) 108.41%
      );

      img {
        width: 100%;
        height: 100%;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        content: "";
        background: {
          image: url("../../assets/img/Subtract.png");
          repeat: no-repeat;
          position: center;
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #333;
      margin-top: 10px;
    }
  }
}

.zcbz-item {
  width: 100%;
  height: 458px;
  background: #f4f5f6;
  padding: 31px 31px 0;

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;

    &-l {
      text-align: center;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 18px;
        color: #0d57bc;
        margin-top: 16px;
      }

      .img-box {
        width: 98px;
        height: 97px;
        line-height: 97px;
        text-align: center;

        img {
          vertical-align: middle;
        }
      }
    }

    &-r {
      display: flex;
      flex-direction: column;
      width: 391px;

      p {
        font-size: 16px;
        line-height: 23px;
        color: #2c2c2c;
      }

      a {
        border-radius: 48px;
        font-size: 16px;
        line-height: 20px;
        color: #0d57bc;
        margin-left: auto;
        margin-top: auto;
      }
    }
  }

  &-bottom-tab {
    display: flex;
    border-bottom: 1px solid #e0e0e0;

    li {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;

      &.ac {
        font-weight: bold;
        color: #0d57bc;

        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #0d57bc;
        }
      }
    }
  }

  &-bottom-tab-default {
    .tips {
      margin-top: 15px;
      font-size: 16px;
      line-height: 23px;
      text-indent: 36px;
      color: #2c2c2c;
    }
  }

  &-bottom-tab-content {
    width: 100%;
  }

  &-bottom-tab-list {
    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      padding-bottom: 7px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 14px;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .text {
        flex: 1;
      }

      .time {
        padding-left: 15px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .zcbz-new-class {
    border-bottom: 1px solid #e0e0e0;

    .title {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }
}

.qy-x {
  margin: {
    top: 40px;
    bottom: 44px;
  }
}

.bottom-all {
  display: flex;
  justify-content: space-between;

  &-left {
    width: 855px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 19px;
        color: #0d57bc;
      }
    }

    &-list {
      cursor: pointer;

      li {
        display: flex;
        padding: 17px 41px 17px 0;
        border-bottom: 1px solid #ebebeb;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 16px;
      overflow: hidden;

      h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000;
        padding-left: 8px;
        margin-bottom: 10px;
        margin-right: 13px;
        background: #efefef;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #000;
      }
    }

    &-text {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #000;
      margin-top: auto;
    }

    .jz-more {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #0d57bc;
      padding: 12px 0;
      background: url(../../assets/img/more-gd.png) no-repeat center;
      cursor: pointer;
    }
  }

  &-right {
    width: 298px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-content: center;

      h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 15px;
        color: #0d57bc;
      }
    }
  }

  .rdzt-box {
    margin-top: 16px;
    margin-bottom: 40px;

    .rdzt-img-box {
      display: block;
      position: relative;
      width: 100%;
      height: 94px;
      margin: 10px 0;
      cursor: pointer;

      .rdzt-img-tag {
        position: absolute;
        top: 18px;
        left: 12px;
        padding: 0px 10px;
        font-size: 14px;
        color: #0d57bc;
        background: #fff;
        font-weight: bold;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .zt-title {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        padding: 0 12px;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        color: #fff;
      }
    }

    .rdzt-img-line {
      width: 100%;
      height: 1px;
      background: #e0e0e0;
      margin: 20px 0;
    }
  }
}

.meeting-box {
  background: #fff;
  margin-top: 12px;

  .all-title {
    margin-bottom: 22px;
  }

  ::v-deep .ant-tabs-nav {
    .ant-tabs-tab:hover,
    .ant-tabs-tab-active {
      color: #0d57bc;
    }
  }

  ::v-deep .ant-tabs-ink-bar {
    background: #0d57bc;
  }

  .ant-dropdown-link {
    color: #333;
  }

  .meeting-list-box {
    margin-top: 20px;
    margin-bottom: -16px;

    .meeting-list {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      overflow: hidden;
      margin-bottom: 16px;

      .meeting-list-title {
        position: relative;
        // display: flex;
        // align-items: center;
        color: #333;
        padding-left: 8px;
        margin-right: 15px;
        overflow: hidden;
        float: left;
        width: 220px;
        font-size: 16px;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 0;
          width: 2px;
          height: 82%;
          background: #0d57bc;
        }

        a {
          color: #333;
          // flex: 1;
          // max-width: 134px;
          display: block;
        }

        .meeting-list-icon {
          display: inline-block;
          padding: 2px 4px;
          border: 1px solid #0d57bc;
          color: #0d57bc;
          line-height: 14px;
          font-style: normal;
          font-size: 12px;
          border-radius: 2px;
        }
      }

      .meeting-list-time {
        color: #828282;
        float: right;
      }
    }
  }

  /deep/ {
    .el-tabs__item {
      padding: 0 10px;
      height: 21px;
      line-height: 21px;
      font-weight: normal;
    }

    .el-tabs__nav {
      padding-bottom: 12px;
    }

    .is-active {
      font-weight: bold;
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    .el-input__suffix {
      right: 27px;
    }
  }
}

.mettingSelect {
  width: 107px;

  /deep/ .el-input__inner {
    border-width: 0;
    padding: 0;
    font-size: 14px;
    height: 20px;
    line-height: 20px;

    &::placeholder {
      color: #333;
    }
  }

  /deep/ .el-select .el-input .el-select__caret {
    color: #333;
  }

  /deep/ .el-input__icon {
    height: 20px;
    line-height: 20px;
  }
}

.index-friendly-link-box {
  width: 100%;
  background: #f8f8f8;
}

.index-friendly-link {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 38px;

  .index-friendly-link-img {
    display: flex;
    margin-left: -20px;
    margin-top: 21px;

    a {
      display: block;
      flex: 0 0 154px;
      margin-left: 20px;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #777777;

      img {
        width: 100%;
        height: 110px;
        border-radius: 4px;
        // box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
      }

      span {
        display: block;
        margin-top: 12px;
      }
    }
  }
}

.new-footer-t {
  .new-footer-inner {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.new-footer-t {
  width: 100%;
  background: #f8f8f8;
  padding: 24px 0 36px;

  .new-footer-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #777;
    margin-bottom: 12px;
  }
}

.yqlj-box {
  display: flex;

  .yqlj-item {
    width: 154px;
    margin-right: 20px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    .text {
      font-size: 12px;
      line-height: 12px;
      color: #777;
      margin-top: 12px;
    }
  }
}

.new-class-btn {
  margin-left: 7px;
  margin-bottom: 2px;

  .new-class-btn-c {
    display: inline-block;
    border: 2px solid #0d57bc;
    border-radius: 48px;
    font-size: 14px;
    line-height: 20px;
    color: #0d57bc;
    padding: 5px 15px;
    font-weight: bold;
    margin-left: 5px;
  }
}

.alert-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000, $alpha: 0.5);

  .alert-link {
    position: relative;
  }

  .alert-colse {
    position: absolute;
    top: -30px;
    right: -30px;
    font-size: 30px;
  }
}


.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.right-guide-research {
  display: block;
  width: 44px;
  border-radius: 24px;
  font-size: 14px;
  color: #000;
  text-align: center;
  letter-spacing: 4px;
  position: relative;
  height: auto;
  text-decoration: none;
  writing-mode: vertical-rl;
  line-height: 3.5;
  cursor: pointer;
  padding-bottom: 10px;
}

.right-guide-box {
  margin-top: 10px;
  width: 40px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px;
  background-clip: content-box, padding-box;
  transition: filter .5s ease;
}

.right-guide-container {
  position: fixed;
  right: 40px;
  width: 40px;
  min-height: 48px;
  bottom: 100px;
  transform: translateY(-50%);
  z-index: 998;
  box-shadow: 0 0 10px #bbb;
  border-radius: 8px;
  background-color: white;
}

.more-icon {
  width: 15px
}

.text-overflow-2 {
  -webkit-line-clamp: 3;
}

.index-right {

  width: 600px;
  padding: 10px 14px;
  height: 341px;
  background-color: #fff;
}

.list-box {
  // padding: 32px 32px 0;
  //background: #fff;
  //margin-bottom: 16px;

  &:nth-child(1) {
    height: 440px;
    padding-bottom: 28px;
  }

  &:nth-child(2) {
    //height: 740px;
    padding: 10px 0;
  }

  &:nth-child(3) {
    padding-bottom: 10px;
  }

  &:nth-child(4) {
    padding-bottom: 10px;
  }

  &-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
    }

    .more {
      font-size: 15px;
      line-height: 20px;
      color: #fff;
      text-decoration: none;

      i {
        font-size: 20px;
        padding-left: 7px;
        vertical-align: middle;
      }
    }
  }

  .new-box {
    padding: 20px 30px;
    background-color: white;
  }
}

#production,
#sales {
  width: 100%;
  height: 250px;

}

.list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 6px 0;
  text-decoration: none;
  overflow: hidden;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  cursor: pointer;

  .tags {
    .tag {
      display: inline-block;
      line-height: 16px;
      color: #0d57bc;
      padding: 4px;
      background: rgba(13, 87, 188, 0.08);
      margin-right: 6px;
    }
  }

  .text {
    flex: 1;
    line-height: 18px;
    padding-right: 15px;
  }
}

.echarts-title {
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  //color: #4f4f4f;
  font-weight: bold;
}

.newUl {

  padding: 10px;
}

.map-box {
  background-color: white;
  height: 500px;
  width: 560px;
}


.zcbz-item-bottom {
  width: 100%;
  height: 290px;
  //background: #f4f5f6;
  padding: 5px 10px 0;

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;

    &-l {
      text-align: center;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 18px;
        color: #0d57bc;
        margin-top: 16px;
      }

      .img-box {
        width: 98px;
        height: 97px;
        line-height: 97px;
        text-align: center;

        img {
          vertical-align: middle;
        }
      }
    }

    &-r {
      display: flex;
      flex-direction: column;
      width: 391px;

      p {
        font-size: 15px;
        line-height: 23px;
        color: #2c2c2c;
      }

      a {
        border-radius: 48px;
        font-size: 16px;
        line-height: 20px;
        color: #0d57bc;
        margin-left: auto;
        margin-top: auto;
      }
    }
  }

  &-bottom-tab {
    display: flex;
    border-bottom: 1px solid #e0e0e0;

    li {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;

      &.ac {
        font-weight: bold;
        color: #0d57bc;

        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #0d57bc;
        }
      }
    }
  }

  &-bottom-tab-default {
    .tips {
      margin-top: 15px;
      font-size: 15px;
      line-height: 23px;
      text-indent: 36px;
      color: #2c2c2c;
    }
  }

  &-bottom-tab-content {
    width: 100%;
  }

  &-bottom-tab-list {
    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      padding-bottom: 7px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 14px;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .text {
        flex: 1;
      }

      .time {
        padding-left: 15px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .zcbz-new-class {
    border-bottom: 1px solid #e0e0e0;

    .title {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }
}

.index-banner-box-btn {
  left: 210px;
}

.el-icon-s-data {
  padding-right: 4px;
}

.el-icon-document {
  padding-right: 4px;
}

.newUl-areas {
  display: inline-block;
  padding: 4px;
  font-size: 14px;
  line-height: 14px;
  color: #823d00;
  background: rgba(242, 153, 74, .08);
  margin-right: 4px;
}

.newUl-areas.df {
  background: rgba(33, 150, 83, .08);
  color: #00662c;
}
</style>
