<!--                零售数据-->
<template>
    <div>
        <div>
            <div class="search-form">
                <el-form :model="form" ref="form" inline label-width="135px">
                    <el-row>
                        <el-col>
                            <div class="query_field">
                                <div class="query_field_l">
                                    查询字段：
                                </div>
                                <div class="query_field_r">
                                    <el-form-item prop="qymc" style="display:none"></el-form-item>
                                    <el-form-item prop="cxlx" style="display:none"></el-form-item>
                                    <el-form-item prop="xflb" style="display:none"></el-form-item>
                                    <el-form-item prop="rllb" style="display:none"></el-form-item>
                                    <el-form-item prop="nian" style="display:none"></el-form-item>
                                    <el-checkbox v-model="nian" disabled class="isDisabled">年月</el-checkbox>
                                    <el-checkbox v-model="form.qymc" @change="changeFun('qymcValues')">企业名称</el-checkbox>
                                    <el-checkbox v-model="form.cxlx" @change="changeFun('cxlxValues')">车型名称</el-checkbox>
                                    <el-checkbox v-model="form.xflb" @change="changeFun('xflbValues')">细分类别</el-checkbox>
                                    <el-checkbox v-model="form.rllb" @change="changeFun('rllbValues')">燃料类型</el-checkbox>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-card>
                        <el-row>
                            <el-col>
                                <el-form-item>
                                    <VdateEndStart v-model="times" @change="timeChange" :checkDate="checkDate" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="企业名称：" class="no-position" prop="qymcValues">
                                    <div class="select-checkbox">
                                        <div class="select-checkbox-top" :class="{'disabled':!form.qymc}" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                                            <span class="select-placeholder" v-if="form.qymcValues.length<=0">选择企业</span>
                                            <template v-else>
                                                <el-tag closable size="small" type="info" @close="selectClose('qymcValues')">
                                                    {{form.qymcValues[0]}}
                                                </el-tag>
                                                <el-tag type="info" size="small" style="margin-left:6px" v-if="form.qymcValues.length>1">+{{form.qymcValues.length-1}}</el-tag>
                                            </template>
                                            <i class="el-icon-arrow-down select-checkbox-icon"></i>
                                        </div>
                                        <div class="select-checkbox-bottom" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                                            <el-checkbox-group v-model="form.qymcValues">
                                                <el-checkbox :label="item" :key="item" v-for="item in qymcData">{{item}}</el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="车型名称：" prop="cxlxValues">
                                    <el-input v-model="form.cxlxValues" :disabled="!form.cxlx" size="small" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="细分类别：" prop="xflbValues" size="small">
                                    <el-select v-model="form.xflbValues" placeholder="请选择" size="small" :disabled="!form.xflb" multiple collapse-tags>
                                        <el-option v-for="item in xflbData" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="燃料类型：" prop="rllbValues">
                                    <el-select v-model="form.rllbValues" placeholder="请选择" size="small" :disabled="!form.rllb" multiple collapse-tags>
                                        <el-option v-for="item in rllbData" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <div class="text-center" style="margin-top: 20px;">
                        <el-button @click="resetForm('form')">重置</el-button>
                        <el-button type="primary" @click="searchForm">查询</el-button>
                    </div>
                </el-form>
            </div>
            <div class="search-form">
                <div flex="sb" style="margin-bottom: 16px">
                    <span class="table_title"></span>
                    <div>
                        <el-button v-has-role="'trialrole'" type="primary" plain>导出</el-button>
                    </div>
                </div>
                <el-table v-loading="loading" width="100%" :data="tableData" style="width: 100%; margin-bottom: 20px" stripe :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
                    <el-table-column label="指标名称">
                        <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop" :key="item.prop"></el-table-column>
                    </el-table-column>

                    <template v-for="(item,index) in tableHeaderTime">
                        <el-table-column label="产量" :key="'cl-'+index">
                            <el-table-column :prop="item.cl" :label="item.year1">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="零售销量" :key="'ls-'+index">
                            <el-table-column :prop="item.ls" :label="item.year1">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="批发销量" :key="'pf-'+index">
                            <el-table-column :prop="item.pf" :label="item.year1">
                            </el-table-column>
                        </el-table-column>
                    </template>
                </el-table>
                <div class="text-right">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {
    enterPriseList,
    TtPassengerSaaocitionDataPage,
    AlltimeList,
} from "@/api/data.js";
import VdateEndStart from "@/components/dateEndStart";

export default {
    components: {
        VdateEndStart,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                nian: false,
                qymc: true,
                cxlx: true,
                xflb: true,
                rllb: true,
                qymcValues: [],
                rllbValues: [],
                xflbValues: [],
                timeBegin: "",
                timeEnd: "",
                cxlxValues: "",
            },
            //分页
            pageData: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            loading: false,
            nian: true,
            qymcData: [],
            xflbData: ["轿车", "SUV", "MPV", "交叉型乘用车"],
            rllbData: ["燃油", "BEV", "PHEV", "其他"],
            times: [],
            tableData: [],
            tableHeader: [],
            tableHeaderTime: [],
            checkDate: "month",
        };
    },
    mounted() {
        if (this.show) {
            this.init();
            this.TtPassengerSaaocitionDataPage();
        }
    },
    methods: {
        //企业名称
        init() {
            enterPriseList().then((res) => {
                if (res.ok) {
                    this.qymcData = res.data;
                }
            });
        },
        TtPassengerSaaocitionDataPage() {
            let form = Object.assign({}, this.form, this.pageData);
            form.timeBegin = this.times[0] || "";
            form.timeEnd = this.times[1] || "";

            delete form.total;
            this.AlltimeList(
                this.getNewDate(this.times[0]),
                this.getNewDate(this.times[1]),
                form.nian
            );
            TtPassengerSaaocitionDataPage(form).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.tableHeader = this.tableHeaderForm();
                    this.pageData.count = res.data.count;
                }
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        //重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.times = [];
            this.checkDate = "month";
            this.TtPassengerSaaocitionDataPage();
        },
        searchForm() {
            this.pageData.page = 1;
            if (this.times[0] && !this.times[1]) {
                this.$message.warning("结束时间不能为空");
                return false;
            } else if (!this.times[0] && this.times[1]) {
                this.$message.warning("开始时间不能为空");
                return false;
            }
            this.TtPassengerSaaocitionDataPage();
        },

        //分页
        handleSizeChange(val) {
            this.pageData.pageSize = val;
            this.TtPassengerSaaocitionDataPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pageData.page = val;
            this.TtPassengerSaaocitionDataPage();
        },
        //删除
        selectClose(type) {
            this.form[type].splice(0, 1);
        },
        //鼠标移入添加类名
        addClassFun(e) {
            if (!e.target.classList.contains("disabled")) {
                e.target.parentNode.classList.add("hover-class");
            }
        },
        //鼠标移出添加类名
        removeClassFun(e) {
            e.target.parentNode.classList.remove("hover-class");
        },
        //切换清空值
        changeFun(type) {
            let isArr = Array.isArray(this.form[type]);
            if (isArr) {
                this.$set(this.form, type, []);
            } else {
                this.$set(this.form, type, "");
            }
        },
        //开始结束时间
        timeChange(e) {
            if (e.swatchDateType == "month") {
                this.form.nian = false;
                this.checkDate = "month";
            } else {
                this.form.nian = true;
                this.checkDate = "year";
            }
        },
        //时间头部
        getNewDate(nowdate) {
            let timeHeader = "";
            let date = new Date(),
                year = date.getFullYear(),
                month =
                    date.getMonth() + 1 >= 10
                        ? date.getMonth() + 1
                        : "0" + date.getMonth() + 1;
            if (nowdate) {
                console.log(1);
                if (this.form.nian) {
                    timeHeader = nowdate + "-01-01";
                } else {
                    timeHeader = nowdate + "-01";
                }
            } else {
                if (this.form.nian) {
                    timeHeader = year + "-01-01";
                } else {
                    timeHeader = year + "-" + month + "-01";
                }
            }

            return timeHeader;
        },
        //前面头部
        tableHeaderForm() {
            let tableArr = [];
            for (let key in this.form) {
                if (typeof this.form[key] == "boolean") {
                    if (this.form[key]) {
                        switch (key) {
                            case "qymc":
                                tableArr.push({
                                    label: "企业名称",
                                    prop: "qyems",
                                });
                                break;
                            case "xflb":
                                tableArr.push({
                                    label: "细分类别",
                                    prop: "cxflei2",
                                });
                                break;
                            case "cxlx":
                                tableArr.push({
                                    label: "车型名称",
                                    prop: "cxing",
                                });
                                break;
                            case "rllb":
                                tableArr.push({
                                    label: "燃料类型",
                                    prop: "rlzl",
                                });
                                break;
                        }
                    }
                }
            }
            return tableArr;
        },
        AlltimeList(begin, end, isNian) {
            AlltimeList({ begin, end, isNian }).then((res) => {
                if (res.ok) {
                    this.tableHeaderTime = res.data;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
    .title {
        font-size: 30px;
        color: #0D57BC;
    }
}
.box {
    width: 50%;
    padding: 20px;
    cursor: pointer;
}

.is_active {
    background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
    color: #0D57BC;
}

::v-deep .el-tabs__active-bar {
    background-color: #0D57BC;
}

.search-form {
    padding: 28px;
    background: #fff;
    margin-bottom: 10px;
    /deep/ {
        .el-date-editor .el-range-separator {
            width: 12%;
        }
        .el-checkbox-group {
            line-height: 34px;
        }
        .el-checkbox {
            margin-right: 16px;
        }
        .el-form-item__content {
            width: 179px;
        }
        .el-card {
            overflow: visible;
        }
    }
}
</style>
