<script>
export default {
    name: "listHead",
    props: {
        title: {
            type: String,
            default: "",
        },
        url: {
            type: String,
            default: "",
        },
        href: {
            type: [String, Object],
            default: "",
        },
        isMore: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        comUrl() {
            return require("@/" + this.url);
        },
    },
    methods: {
        onClick() {
            this.$emit("click");
        },
    },
    render() {
        return (
            <div
                class="list-head-color"
                style={
                    "background:url(" +
                    this.comUrl +
                    ") no-repeat;background-size:cover"
                }
            >
                <div class="list-head-box">
                    <div class="list-head-box-l">{this.title}</div>
                    {this.isMore ? (
                        <div class="list-head-box-r" on-click={this.onClick}>
                            <router-link
                                to={this.href}
                                tag="a"
                                class="link-style"
                            >
                                查看更多<i class="el-icon-arrow-right"/>
                            </router-link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    },
};
</script>
<style lang="scss" scoped>
.list-head-color {
    border-radius: 3px;
}
.list-head-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    line-height: 64px;
    padding: 0 31px;
    color: #fff;
    border-radius: 2px;
    background: linear-gradient(
        90.63deg,
        #1768d5 3.19%,
        rgba(13, 87, 188, 0) 216.38%
    );
    .list-head-box-l {
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
    }
    .list-head-box-r {
        font-size: 14px;
        line-height: 14px;
    }
    .link-style {
        color: #fff;
        padding-right: 16px;
        // background: url(../../assets/img/Component123.png) center right
        //     no-repeat;
        // background: cover;
    }
}
</style>