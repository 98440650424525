<template>
  <PageContainer>
    <div class="header">
      <div class="title">比亚迪各配套主要供应商占比</div>
      <div class="sub-title">提供更多企业、车型等详细字段查询，可以 <span class="contact">联系我们</span> 购买获取完整数据表</div>
    </div>
    <div class="enterprise-tab">
      <div
        v-for="item in enterpriseList"
        :key="item"
        :class="['item', activeEnterprise === item ? 'active' : '']"
        @click="handleEnterpriseChange(item)"
      >
        {{ item }}
      </div>
    </div>
    <div class="main-container">
      <card ref="batteryCellRef" title="电芯主要供应商" style="margin-bottom: 16px;" />
      <card ref="bmsRef" title="BMS主要供应商" style="margin-bottom: 16px;" />
      <card ref="electricalMachineryRef" title="电机主要供应商" reverse-chart-type-list style="margin-bottom: 16px;" />
      <card ref="electricControlRef" title="电控主要供应商" reverse-chart-type-list />
    </div>
  </PageContainer>
</template>

<script>
import Card from './comp/card'

export default {
  name: 'ThreeElectricalSupportingFacilities',
  components: {
    Card
  },
  data() {
    return {
      enterpriseList: ['比亚迪', '一汽-大众', '长安汽车', '吉利汽车', '上海通用'],
      activeEnterprise: '比亚迪'
    }
  },
  mounted() {
    this.getEnterpriseData()
  },
  methods: {
    handleEnterpriseChange(enterprise) {
      this.activeEnterprise = enterprise
      this.getEnterpriseData()
    },
    getEnterpriseData() {
      const pieData = [
        { value: 1.05, name: '戴姆勒股份公司' },
        { value: 2.20, name: 'LGIT' },
        { value: 4.21, name: '北京新能源汽车股份有限公司' },
        { value: 7.26, name: 'DeutscheACCUmotive' },
        { value: 6.40, name: '深圳市比克动力电池有限公司' },
        { value: 10.00, name: '苏州安能新能源技术有限公司' },
        { value: 10.39, name: '上海奥威科技开发有限公司' },
        { value: 14.38, name: '北汽福田汽车股份有限公司山东多功能汽车电池有限公司' },
        { value: 14.55, name: '江西安驰新能源科技有限公司' },
        { value: 29.99, name: '比亚迪汽车工业有限公司' }
      ]
      this.$refs.batteryCellRef.setData(pieData)
      this.$refs.bmsRef.setData(pieData)
      this.$refs.electricalMachineryRef.setData(pieData)
      this.$refs.electricControlRef.setData(pieData)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 24px 24px 8px;
  background-color: #fff;
  border-radius: 3px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .title {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin: 0 12px 0 9px;
  }

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #0D57BC;
  }

  .sub-title {
    font-size: 12px;
    color: #828282;

    .contact {
      color: #0D57BC;
      cursor: pointer;
    }
  }
}

.enterprise-tab {
  display: flex;
  align-items: center;
  color: #4F4F4F;
  margin-bottom: 16px;

  .item {
    border: 1px solid #E5E6EB;
    border-radius: 3px;
    padding: 5px 14px;
    transition-property: background-color color;
    transition-duration: 0.3s;
    cursor: pointer;

    &.active, &:hover {
      background-color: #0D57BC;
      color: #fff;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.main-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
