<!--本网观察列表-->
<template>
  <router-view v-if="$route.name == '行业观察内容页'"/>
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="行业观察" smallTip="会员专享精华原创内容推荐" :bgurl="bgurl"/>
    <ListFiltering @search="search"/>
    <Vlist :list="ttLocalWatchPageData" :page="ttLocalWatchPagePage" :pageData="ttLocalWatchPagePage"
           @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import {ttLocalWatchPage} from "@/api/Index.js";
import Vlist from "@/components/list";
import ListFiltering from "@/components/ListFiltering.vue";

import {getUserInfo,logRecords} from "@/utils/auth";
import {accessRecords} from "@/api/common";

export default {
  components: {
    VlistTitle,
    Vlist,
    ListFiltering,
  },
  data() {
    return {
      bgurl: require("@/assets/img/bg-1.jpg"),
      ttLocalWatchPageData: [],
      ttLocalWatchPagePage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      title: "",
    };
  },
  created() {
    this.ttLocalWatchPage(this.ttLocalWatchPagePage.pages);
  },
  methods: {
    ttLocalWatchPage(params) {
      let form = Object.assign({}, params, {
        title: this.title,
      });
      console.log(form)
      ttLocalWatchPage(form).then((res) => {
        if (res.ok) {
          this.ttLocalWatchPageData = res.data.list;
          this.ttLocalWatchPagePage.total = res.data.count;
        }
      });
    },
    handleSizeChange(v) {
      this.ttLocalWatchPagePage.pages.pageSize = v;
      this.ttLocalWatchPage(this.ttLocalWatchPagePage.pages);
    },
    handleCurrentChange(v) {
      this.ttLocalWatchPagePage.pages.page = v;
      this.ttLocalWatchPage(this.ttLocalWatchPagePage.pages);
    },
    toHref(item) {
      logRecords('首页','行业观察','1','tt_local_watch',item.id,'',item.title,'')
      let newUrl = this.$router.resolve("/index/detail/LocalWatchDetail/" + item.id);
      window.open(newUrl.href, "_blank");
    },

    search(e) {
      this.ttLocalWatchPagePage.pages.page = 1;
      this.title = e;
      this.ttLocalWatchPage(this.ttLocalWatchPageData.pages);
     logRecords('首页','行业观察','4','tt_local_watch','','','',e)
    },

  },
};
</script>
