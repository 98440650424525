<template>
    <div class="page">
        <div class="proze-head">
            <img src="@/assets/img/Grouptitle.png" alt="" width="187" height="27">
            <h1 class="proze-head-title">有奖征文</h1>
            <div class="proze-head-box">
                <img src="@/assets/img/Image.png" alt="" width="300" height="300" class="img1">
                <img src="@/assets/img/Image (1).png" alt="" width="300" height="300" class="img2">
                <p class="proze-head-text">当前，汽车产业正在经历百年未有之大变局，行业发展可以用“瞬息万变”来形容。我们每天都被大量、繁杂的信息包围，
                    这些信息中有很多是无效、无价值的信息。中国汽车工业信息网一直致力于打造行业最权威的信息服务平台，但是作为第三方的研究机构，
                    我们也有看不清行业及技术发展趋势的时候，需要借助行业更多力量，从更深、更广的角度看汽车行业的变革趋势。
                    因此，中国汽车工业信息网诚挚欢迎行业和企业专家为我们供稿，稿件一旦录用，必有丰厚报酬。</p>
            </div>
        </div>
        <div class="proze-content">
            <div class="proze-center">
                <h1 class="proze-title">投稿文章要求</h1>
                <div class="proze-inner">
                    <h2>1 必须为原创</h2>
                    <p>我们只接受原创内容，不接受搬运内容或抄袭、洗稿内容。</p>
                    <h2>2 主题要求</h2>
                    <p>主题包括但不限于政策解读、标准法规解读、行业热点解析、前沿技术趋势分析等。</p>
                    <h2>3 格式要求</h2>
                    <p>最好是PPT，word文字版字数需在3000以上。</p>
                </div>
                <h1 class="proze-title">线索奖励</h1>
                <p class="proze-text">我们也非常欢迎各界人士为我们提供行业发展的相关线索，为厘清行业发展趋势贡献力量。同样，线索提供也是有相关金额奖励。</p>
                <h1 class="proze-title">读者群</h1>
                <p class="proze-text">为更好的服务行业、服务企业，中国汽车工业信息网建立了Autoinfo读者群，入群可及时获取行业政策、标准、战略发展动态、研究报告等，还有机会获取中国汽车工业信息网网主办的各类会议的免费参会名额。</p>
                <h1 class="proze-title">投稿、提供线索或入群方式</h1>
                <p class="proze-text">请扫描下面二维码添加工作人员企业微信，进行投稿或者入读者群。</p>
                <div class="text-center" style="margin-top:-8px">
                    <img src="@/assets/img/qywxewm.png" alt="" width="226" height="224">
                </div>
            </div>
        </div>

        <Vfooter />
    </div>
</template>
<script>
import { ttPrizeEssay } from "@/api/prizeEssay.js";
import { download } from "@/api/common.js";
import { downloadFile } from "@/utils/download.js";
import Vfooter from "@/components/footer.vue";
export default {
    components: {
      Vfooter,
    },
    data() {
        return {
            content: {},
        };
    },
    created() {},
    methods: {
        ttPrizeEssay() {
            ttPrizeEssay().then((res) => {
                if (res.ok) {
                    this.content = res.data[0];
                }
            });
        },
        //下载
        downBtn(item) {
            let self = this;
            download({ fileUrl: item.attachUrl }).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                    try {
                        JSON.parse(e.target.result);
                        let msg = JSON.parse(e.target.result);
                        self.$message({
                            showClose: true,
                            message: msg.message,
                            type: "error",
                        });
                    } catch (err) {
                        downloadFile(res, item.attachName);
                    }
                });
            });
        },
    },
};
</script>
<style  lang="scss" scoped>
.proze-head {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 585px;
    background: #0d57bc;
    overflow: hidden;
    .proze-head-title {
        font-weight: bold;
        font-size: 48px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #fff;
        margin-bottom: 53px;
    }
    .proze-head-box {
        position: relative;
        width: 732px;
        height: 300px;
        background: #f2f8ff;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 33px 33px 43px 45px;
        .img1,
        .img2 {
            position: absolute;
        }
        .img1 {
            left: -336px;
            bottom: 53px;
        }
        .img2 {
            right: -320px;
            bottom: 150px;
        }
        .proze-head-text {
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: #03295b;
        }
    }
}
.proze-content {
    width: 100%;
    background: #f2f8ff;
}
.proze-center {
    width: 732px;
    margin: 0 auto;
    background: #fff;
    padding: 29px 39px 63px;
    .proze-title {
        width: 100%;
        height: 56px;
        line-height: 56px;
        text-align: center;
        background: #f2f8ff;
        border-radius: 2px;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #0d57bc;
    }
    .proze-text {
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.03em;
        color: #000000;
        margin: 32px 0 40px;
    }
}
.proze-inner {
    margin: 32px 0 40px;
    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #000000;
        margin-bottom: 10px;
    }
    p {
        font-size: 20px;
        line-height: 160%;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 16px;
    }
}
</style>
