<template>
  <div class="category-list custom-scrollbar">
    <div
      v-for="item in list"
      :key="item.name"
      :class="['category-item', activeName === item.name ? 'active' : '']"
      @click="handleClickCategory(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    list: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClickCategory(item) {
      this.$emit('selectChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;
  -webkit-user-select: none;
  padding: 18px 24px 0;

  .category-item {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #4F4F4F;
    border-radius: 3px;
    padding: 0 16px;
    height: 32px;
    border: 1px solid #E5E6EB;
    box-sizing: border-box;
    transition-property: color, background-color, border;
    transition-duration: 0.3s;
    cursor: pointer;
    margin: 6px 0;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &.active, &:hover {
      color: #0D57BC;
      background-color: #F5F8FC;
      border: 1px solid #0D57BC;
    }
  }
}
</style>
