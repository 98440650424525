<!--本网原创内容页面-->
<template>
  <PageContainer breadcrumb>
     <Vcontent :data="data" :props="props" isContentTwo="bwyc"/>
  </PageContainer>
</template>
<script>
import { ttPolicyInterpret } from "@/api/policy.js";
export default {
  data() {
    return {
      data: {},
       props:{
        htmlContent:'content',
        summary:"introduction"
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttPolicyInterpret(id);
  },
  methods: {
    ttPolicyInterpret(params) {
      ttPolicyInterpret(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
