<!--最新快讯列表-->
<template>
  <PageContainer breadcrumb>
    <Vlist
      :list="list"
      :page="ttForecastChangeLog"
      :pageData="ttForecastChangeLog"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { ttForecastChangeLog } from "@/api/sale.js";
import Vlist from "@/components/list";
export default {
  components: {
    VlistTitle,
    Vlist,
  },
  data() {
    return {
      bgurl: require("@/assets/img/bg-1.jpg"),
      list: [],
      ttForecastChangeLog: {
        pages: {
          page: 1,
          pageSize: 10,
          latestNews: "是",
        },
        total: 0,
        currentPage: 0,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let modelName = this.$route.query.modelName
      ttForecastChangeLog({modelName:modelName,page:1,pages:10}).then((res) => {
        if (res.ok) {
          this.list = res.data.list;
          this.ttForecastChangeLog.total = res.data.count;
        }
      });
    },
    handleSizeChange(v) {
      this.ttForecastChangeLog.pages.pageSize = v;
      this.init(this.ttForecastChangeLog.pages);
    },
    handleCurrentChange(v) {
      this.ttForecastChangeLog.pages.page = v;
      this.init(this.ttForecastChangeLog.pages);
    },
    toHref(item) {
      this.$router.push({
        path: "/index/detail/industryInformationDetail/" + item.id,
      });
    },
  },
};
</script>
