<template>
  <div class="card-item">
    <div class="card-name" :title="info.cardName">{{ info.cardName }}</div>
    <div class="value" :title="info.value">{{ info.value }}</div>
    <div class="statistic-container">
      <div v-if="info.qoq != null" class="statistic-item">
        <div class="name">环比</div>
        <template v-if="isShowDecreaseArrow(info.qoq)">
          <img :src="require('@/assets/img/overview/down.png')" alt="">
          <div class="decrease" :title="getAbsValue(info.qoq)">{{ getAbsValue(info.qoq) }}</div>
        </template>
        <div v-else-if="info.qoq == 0">{{ info.qoq }}</div>
        <template v-else>
          <img :src="require('@/assets/img/overview/up.png')" alt="">
          <div class="increase" :title="info.qoq">{{ info.qoq }}</div>
        </template>
      </div>
      <div
        v-if="info.yoy != null"
        class="statistic-item"
      >
        <div class="name">同比</div>
        <template v-if="isShowDecreaseArrow(info.yoy)">
          <img :src="require('@/assets/img/overview/down.png')" alt="">
          <div class="decrease" :title="getAbsValue(info.yoy)">{{ getAbsValue(info.yoy) }}</div>
        </template>
        <div v-else-if="info.yoy == 0">{{ info.yoy }}</div>
        <template v-else>
          <img :src="require('@/assets/img/overview/up.png')" alt="">
          <div class="increase" :title="info.yoy">{{ info.yoy }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  methods: {
    isShowDecreaseArrow(val) {
      return `${val}`.startsWith('-')
    },
    getAbsValue(val) {
      return Math.abs(val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
