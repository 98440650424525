<template>
  <PageContainer breadcrumb>
    <div class="content">
      <table class="table" border="1" style="word-break: break-all">
        <tr>
          <td class="blackFont">产品商标：</td>
          <td>{{ tableData.cpsb }}</td>
          <td class="blackFont">产品型号：</td>
          <td>{{ tableData.cpxh }}</td>
          <td class="blackFont">产品名称：</td>
          <td>{{ tableData.cpmc }}</td>
        </tr>
        <tr>
          <td class="blackFont">企业名称：</td>
          <td>{{ tableData.qymc }}</td>
          <td class="blackFont">注册地址：</td>
          <td colspan="3">{{ tableData.zhcAdd }}</td>
        </tr>
        <tr>
          <td class="blackFont">目录序号：</td>
          <td>{{ tableData.mlxh }}</td>
          <td class="blackFont">生产地址：</td>
          <td colspan="3">{{ tableData.scdz }}</td>
        </tr>

        <tr>
          <td class="blackFont">右部照片：</td>
          <td class="text-center">
            <img v-viewer :src="tableData.ybzp?tableData.ybzp:defaultImg" height="200" title="点击放大" style="cursor: pointer" alt="">
          </td>
          <td class="blackFont">后部照片：</td>
          <td colspan="3" class="text-center">
            <img v-viewer :src="tableData.hbzp?tableData.hbzp:defaultImg" height="200" title="点击放大" style="cursor: pointer" alt="">
          </td>
        </tr>
        <tr>
          <td class="blackFont">选装照片1：</td>
          <td class="text-center">
            <img v-viewer :src="tableData.xzzp1?tableData.xzzp1:defaultImg" height="200" title="点击放大" style="cursor: pointer" alt="">
          </td>
          <td class="blackFont">选装照片2：</td>
          <td class="text-center">
            <img v-viewer :src="tableData.xzzp2?tableData.xzzp2:defaultImg" height="200" title="点击放大" style="cursor: pointer" alt="">
          </td>
          <td class="blackFont">选装照片3：</td>
          <td class="text-center">
            <img v-viewer :src="tableData.xzzp3?tableData.xzzp3:defaultImg" height="200" title="点击放大" style="cursor: pointer" alt="">
          </td>
        </tr>
        <tr>
          <td class="blackFont">外形尺寸(mm)：</td>
          <td colspan="5">{{ tableData.wxcc }}</td>
        </tr>
        <tr>
          <td class="blackFont">货厢栏板内尺寸(mm)：</td>
          <td colspan="5">{{ tableData.hxlbncc }}</td>
        </tr>
        <tr>
          <td class="blackFont">排放依据标准：</td>
          <td colspan="2">{{ tableData.yjbz }}</td>
          <td class="blackFont">燃料种类：</td>
          <td colspan="2">{{ tableData.rlzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">最高车速(km/h)：</td>
          <td colspan="2">{{ tableData.zgcs }}</td>
          <td class="blackFont">总质量(kg)：</td>
          <td colspan="2">{{ tableData.zzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">载质量利用系数：</td>
          <td colspan="2">{{ tableData.zzhl }}</td>
          <td class="blackFont">额定载质量(kg)：</td>
          <td colspan="2">{{ tableData.edzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">转向型式：</td>
          <td colspan="2">{{ tableData.zxxs }}</td>
          <td class="blackFont">整备质量：</td>
          <td colspan="2">{{ tableData.zbzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">轴数：</td>
          <td colspan="2">{{ tableData.zhsh }}</td>
          <td class="blackFont">准拖挂车总质量(kg)：</td>
          <td colspan="2">{{ tableData.gczl }}</td>
        </tr>
        <tr>
          <td class="blackFont">轴距(mm)：</td>
          <td colspan="2">{{ tableData.zhj }}</td>
          <td class="blackFont">轮胎规格：</td>
          <td colspan="2">{{ tableData.ltgg }}</td>
        </tr>
        <tr>
          <td class="blackFont">钢板弹簧片数（前/后）：</td>
          <td colspan="2">{{ tableData.thps }}</td>
          <td class="blackFont">半挂车鞍座最大允许承载质量(kg)：</td>
          <td colspan="2">{{ tableData.bgaz }}</td>
        </tr>
        <tr>
          <td class="blackFont">轮胎数：</td>
          <td colspan="2">{{ tableData.lts }}</td>
          <td class="blackFont">驾驶室准乘人数（人）：</td>
          <td colspan="2">{{ tableData.qpck }}</td>
        </tr>
        <tr>
          <td class="blackFont">额定载客（含驾驶员）（座位数）：</td>
          <td colspan="5">{{ tableData.edzk }}</td>
        </tr>
        <tr>
          <td class="blackFont">轮距（前/后)mm：</td>
          <td colspan="2">{{ tableData.lj }}</td>
          <td class="blackFont">接近角/离去角（度）：</td>
          <td colspan="2">{{ tableData.jjlqj }}</td>
        </tr>
        <tr>
          <td class="blackFont">反光标识生产企业：</td>
          <td colspan="2">{{ tableData.bsqy }}</td>
          <td class="blackFont">反光标识型号：</td>
          <td colspan="2">{{ tableData.bsxh }}</td>
        </tr>
        <tr>
          <td class="blackFont">反光标识商标：</td>
          <td colspan="2">{{ tableData.bssb }}</td>
          <td class="blackFont">防抱死制动系统：</td>
          <td colspan="2">{{ tableData.fbszd }}</td>
        </tr>
        <tr>
          <td class="blackFont">车辆识别代号（VIN）：</td>
          <td colspan="2">{{ tableData.sbdh }}</td>
          <td class="blackFont">前悬/后悬(mm)：</td>
          <td colspan="2">{{ tableData.qxhx }}</td>
        </tr>
        <tr>
          <td class="blackFont">其它：</td>
          <td colspan="5">{{ tableData.qt }}</td>
        </tr>
        <tr>
          <td class="blackFont">说明：</td>
          <td colspan="5">{{ tableData.sm }}</td>
        </tr>
        <tr>
          <td class="blackFont">油耗申报值(L/100km)：</td>
          <td colspan="5">{{ tableData.yhsb }}</td>
        </tr>
        <tr>
          <td class="blackFont">是否同期申报：</td>
          <td colspan="5">{{ tableData.tqsb }}</td>
        </tr>

        <tr>
          <td class="blackFont">底盘信息：</td>
          <td colspan="5">
            <table class="table" border="1">
              <tr>
                <td class="blackFont">底盘ID</td>
                <td class="blackFont">底盘型号</td>
                <td class="blackFont">底盘生产企业</td>
                <td class="blackFont">底盘类别</td>
              </tr>
              <tr>
                <td>{{ tableData.mdpid }}</td>
                <td>{{ tableData.mdpxh }}</td>
                <td>{{ tableData.mdpqy }}</td>
                <td>{{ tableData.mdplb }}</td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td class="blackFont">发动机信息：</td>
          <td colspan="5">
            <table class="table" border="1">
              <tr>
                <td class="blackFont">发动机型号</td>
                <td class="blackFont">发动机企业</td>
                <td class="blackFont">排量(ml)</td>
                <td class="blackFont">功率(kw)</td>
                <td class="blackFont">油耗(L/100km)</td>
              </tr>
              <tr>
                <td v-if=" tableData.mfdj">{{ tableData.mfdj }}</td>
                <td v-if=" tableData.mfqy">{{ tableData.mfqy }}</td>
                <td v-if=" tableData.mfpl">{{ tableData.mfpl }}</td>
                <td v-if=" tableData.mfgl">{{ tableData.mfgl }}</td>
                <td v-if=" tableData.yh">{{ tableData.yh }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </PageContainer>
</template>
<script>
import { ggPublicContent } from "@/api/announcement.js";
export default {
  data() {
    return {
      defaultImg:require('@/assets/img/nonecar.jpg'),
      tableData: {},
    };
  },
  created() {
    this.ggPublicContent();
  },
  methods: {
    ggPublicContent() {
      let id = this.$route.params.id;
      ggPublicContent(id).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
          console.log(res.data);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  background: #fff;
}
.img-group {
  border: 1px solid $allcolor;
  border-bottom: none;
  text-align: center;
  img {
    width: 220px;
    padding: 5px;
  }
}
.table {
  width: 100%;
  border-color: $allcolor;
  color: #000;
  tr {
    td {
      border-color: $allcolor;
      padding: 5px 10px;
    }
    // &:nth-child(odd) {
    //  background: #bbc8da;
    //}
  }
}
.blackFont{
  font-weight: bold;
  background: #bbc8da;
}
</style>
