<template>
  <div class="main">
    <!-- 只在已登录且可切换新旧版，且当前是旧版数据栏目展示 -->
    <div
      v-if="isData && isLogin && getIfCanViewNewVersion"
      class="go-to-new-data-tip"
      @click="handleClickGoToNewData"
    >
      前往新版
      <i class="el-icon-arrow-right" />
    </div>
    <el-scrollbar>
      <el-menu
        :default-active="activeMenuPath"
        unique-opened
        @select="handleSelect"
      >
        <template v-for="item in childrenMenu">
          <template v-if="item.children && item.children.length > 0">
            <el-submenu
              :key="item.path"
              :index="item.children[0].path"
              :class="`${getrouterName === item.name ? 'is-active' : ''}`"
            >
              <template slot="title">
                <i
                  :class="item.meta.icon ? item.meta.icon : 'el-icon-menu'"
                />
                <span slot="title">{{ item.name }}</span>
              </template>
              <el-menu-item-group>
                <template v-for="child in item.children">
                  <el-menu-item
                    :key="child.path"
                    style="padding-left: 50px;"
                    :class="
                      `${getrouterName === child.name ? 'is-active' : ''}`
                    "
                    :index="child.path"
                    @click="ceshi(child)"
                  >
                    <span
                      slot="title"
                    >{{ child.name }}
                      <span v-if="child.meta.img" class="img" /></span>
                  </el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item
              v-if="item.name !== '企业信息详细'"
              :key="item.name"
              :index="item.path"
              :class="`${getrouterName === item.name ? 'is-active' : ''}`"
              @click="ceshi(item)"
            >
              <template v-if="item.name.includes('大数据')">
                <i class="el-icon-menu" />
                <span slot="title">{{ item.name }}</span>
                <img
                  style="float: right;margin-top: 23px;width: 100px;margin-right: 45px;width: 16px;"
                  src="@/assets/img/xz-2.svg"
                >
              </template>
              <template v-else>
                <i
                  :class="item.meta.icon ? item.meta.icon : 'el-icon-menu'"
                />
                <span slot="title">{{ item.name }}</span>
              </template>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'SiderNormal',
  props: {
    siderDisappeared: {
      required: false,
      type: Boolean,
      default: false,
    },
    isData: {
      required: false,
      type: Boolean,
      default: false,
    },
    isLogin: {
      required: false,
      type: [Boolean, Number],
      default: false,
    },
    getIfCanViewNewVersion: {
      required: false,
      type: Boolean,
      default: false,
    },
    childrenMenu: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    getrouterName: {
      required: false,
      type: String,
      default: undefined,
    },
    activeMenuPath: {
      required: false,
      type: String,
      default: undefined,
    },
  },
  methods: {
    handleClickGoToNewData(data) {
      this.$emit('click', data);
    },
    handleSelect(data) {
      this.$emit('select', data);
    },
    ceshi(data) {
      this.$emit('ceshi', data);
    },
  },
}
</script>

<style lang="scss" scoped>
.img {
  display: inline-block;
  height: 20px;
  width: 20px;
  color: #0d57bc;
  background: url(~@/assets/img/gjhicon.png) no-repeat;
  background-size: 100% 100%;
}
.go-to-new-data-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  background-color: #0d57bc;
  cursor: pointer;
  transition: all .3s;
  i {
    margin-left: 10px;
  }
}
</style>
