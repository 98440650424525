<!--
 * @Author: your name
 * @Date: 2021-07-20 16:40:01
 * @LastEditTime: 2021-07-20 17:26:16
 * @LastEditors: Please set LastEditors
 * @Description: 展开收起插件
 * @FilePath: /information-vue/code/src/components/UnfurlAndFold.vue
-->
<template>
    <div>
        <div class="title">
            <div class="inner" :class="{ 'noInner': isShowExpand }" @click="isShow">
                {{ title }}
                <i v-if="!isShowExpand" :class="is ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
        </div>
        <div v-show="is" class="content-b">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String },
        toName: { type: String },
        visible: {
            type: Boolean,
            default: true,
        },
        isShowExpand: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            is: this.visible,
        };
    },
    methods: {
        /**
         * @description: 是否收起展开
         * @param {*}
         * @return {*}
         */
        isShow() {
            if (!this.isShowExpand){
              this.is = !this.is;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
    .inner {
        font-weight: 600;
        // margin: 10px 0;
        color: #0d57bc;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        i {
            color: #828282;
        }
    }
    .noInner{
      cursor: text;
    }
}
.content-b {
    margin-top: 10px;
}
</style>
