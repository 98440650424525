<!--
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2021-07-20 10:56:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/policy/carding/index.vue
-->
<template>
  <div class="outer-layer-box" style="height: 100%">
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips" />-->
    <template>
      <div flex="sb" style="height: 100%" :class="{'baes':leftShow}">
        <div class="carding_left" :class="{'leftShow':leftShow}">
          <div class="carding_left_title">政策树</div>
          <!-- <el-button style="margin: 9px 10px 25px" @click="allGetPolicyContent" class="showBtn">
              全部政策
          </el-button> -->
          <el-tree class="filter-tree" :data="data" :props="defaultProps" default-expand-all
                   :filter-node-method="filterNode" :expand-on-click-node="false" ref="tree"
                   @node-click="handleNodeClick">
          </el-tree>
          <div class="menuFold">
            <img src="@/assets/img/MenuFold.png" alt="" @click="hidenLeft">
          </div>
        </div>
        <div style="flex: 1" class="carding_right">
          <div class="table_title">{{ treeTitle }}</div>
          <div class="block" v-loading="load" element-loading-text="拼命加载中"
               element-loading-background="rgba(0, 0, 0, 0)">
            <el-timeline>
              <el-timeline-item placement="top" v-for="item in treeData" :key="item.policyContentId">
                <el-card>
                  <div class="carding_right_item_p">
                    <p v-html="item.summary" class="p"></p>
                  </div>
                  <div flex="sb">
                    <div class="carding_right_l">
                      <div class="tag"><img src="@/assets/img/menu-icon.png" alt="" class="icon">{{ item.menu }}</div>
                      <div style="color: #0D57BC;cursor: pointer" class="link-style"
                           @click="toHref('/policy/carding/details/'+item.policyContentId) ">{{ item.title }}
                      </div>

                    </div>
                    <p>{{ item.publishTime }}</p>
                  </div>
                </el-card>
              </el-timeline-item>

            </el-timeline>
            <div class="jz-more" v-if="btnShow" @click="jzMoreBtn()">{{ loadinginName }}</div>

          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
/**
 * @description: 2021-07-20 政策梳理页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {policyTree, getPolicyContent} from "@/api/policy.js";
import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";

export default {

  data() {
    return {
      show: false,
      showTips: [
        {
          title: "政策动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "政策库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "政策梳理",
          text: "专家梳理整合，真正读懂政策脉络",
        },
        {
          title: "政策大数据",
          text: "用数据深度理解政策结构和趋势",
        },
      ],
      filterText: "",
      treeTitle: "全部",
      keywords: "",
      data: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      leftShow: false,
      btnShow: false,
      load: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
      policyPage: {
        pageSize: 5,
        page: 1,
      },
      form: {
        title: "",
        treeId: "",
      },
      loadinginName: "点击加载更多",

    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
    this.policyTree();

  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //加载更多
    jzMoreBtn() {

      if(getUserInfo()){
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('政策')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.policyPage.page++;
            this.getPolicyContent();

          }
        } else {
          this.policyPage.page++;
          this.getPolicyContent();
        }

      }else{
        this.showLogin()
      }
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(url) {
      let newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('政策')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    viewDetail(path, id) {
      let newUrl = this.$router.resolve({
        path: path + id
      });
      window.open(newUrl.href, "_blank");
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    policyTree() {
      policyTree().then((res) => {
        if (res.ok) {
          this.data = res.data;
          let id = this.data[0].id;
          this.treeTitle = this.data[0].name;
          this.keywords = this.data[0].keywords;
          this.getPolicyContent(id);

        }
      });
    },
    handleNodeClick(data) {
      let id = data.id;
      this.treeTitle = data.name;
      this.keywords = data.keywords;

      // this.treeData
      this.treeData = []
      this.load = true

      this.getPolicyContent(id);
    },
    getPolicyContent(id) {
      this.btnShow = false;
      // this.loadinginName = "加载中......";
      let form = Object.assign(
          {},
          this.form,
          this.policyPage
      );
      form.title = this.keywords;
      form.treeId = id;

      getPolicyContent(form).then((res) => {
        if (res.ok) {


          if (res.data.list.length > 0) {
            this.loadinginName = "点击加载更多";
          } else {
            this.loadinginName = "已经没有啦";
          }
          // this.treeData = res.data.list;
          this.treeData = this.treeData.concat(res.data.list);

          this.treeData.forEach((item) => {
            item.contentText = htmlRestore(item.contentText);
            item.publishTime = fmtDate(
                "YYYY-mm-dd",
                item.publishTime
            );
          });
          this.btnShow = true;
          this.load = false
        } else {
          this.loadinginName = "加载失败";
        }

      });
    },
    allGetPolicyContent() {
      this.treeTitle = "全部";
      this.getPolicyContent();
    },
    hidenLeft() {
      this.leftShow = !this.leftShow;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.baes {
  align-items: baseline;
}

.carding_left {
  display: flex;
  flex-direction: column;
  height: 100%;
  // padding: 28px 20px;
  background: #fff;
  width: 207px;
  margin-right: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &_title {
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }

  .filter-tree {
    flex: 1;
    overflow-y: auto;
  }

  .menuFold {
    border: 1px solid #f0f0f0;
    padding: 14px 0 14px 14px;

    img {
      cursor: pointer;
    }
  }

  &.leftShow {
    width: 50px;
    height: initial;

    .filter-tree,
    .showBtn,
    .carding_left_title {
      display: none;
    }
  }
}

.carding_right {
  .table_title {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 30px;
  }

  &_item_p {
    border-bottom: 1px solid #efefef;
    padding: 15px 5px 17px 5px;
    margin-bottom: 10px;

    .p {
      padding: 0 16px;
    }
  }

  .carding_right_l {
    display: flex;
    align-items: center;
    padding: 0 14px;
    flex: 1;

    .icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 7px;
    }

    .tag {
      padding: 7px;
      background: rgba(13, 87, 188, 0.08);
      border-radius: 2px;
      margin-right: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #0d57bc;
      font-weight: bold;
    }

    .link-style {
      flex: 1;
      font-size: 14px;
      line-height: 22px;
      color: #154385;
    }
  }
}

::v-deep .el-timeline-item__node {
  border: 1px solid #0d57bc;
  background: #fff;
}

::v-deep .el-timeline-item__tail {
  border-left: 2px solid #6f8ab2;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

.jz-more {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0d57bc;
  padding: 12px 0;
  background: url(../../../assets/img/more-gd.png) no-repeat center;
  cursor: pointer;
}
</style>
