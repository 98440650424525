<!--市场动态列表-->
<template>
  <PageContainer>
    <!--        <div class="not-login-box" v-if="!show">-->
    <!--            <VnotLogin title="洞察报告" text="市场洞察栏目围绕市场现状本身从深度和广度全方位展开，分析市场现状、洞察市场走势。" tableTitle="最新洞察报告" :showTips="showTips" />-->
    <!--        </div>-->

    <VlistTitle bigTtitle="各方观点" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>

    <div class="box_box">
      <!-- <el-tabs v-model="articleCategory" @tab-click="handleClick">
          <el-tab-pane label="行业资讯" name="industry"></el-tab-pane>
      </el-tabs> -->
      <div style="overflow: hidden">
        <div style="float: left" flex="c">
          <el-checkbox-group v-model="keywords" size="small" @change="handleChange">
            <el-checkbox :label="item.dictValue" border v-for="item in keywordsData" :key="item.dictValue">
              {{ item.dictLabel }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="float: right">
          <el-input placeholder="搜索名称" size="small" v-model="title" @keyup.enter.native="search">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
          </el-input>
        </div>
      </div>
    </div>
    <Vlist :list="marketDynamicData" :page="marketDynamicPage" :pageData="marketDynamicPage"
           @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import VlistTitle from "@/components/listTitle.vue";
import {internationalPage, marketDynamic} from "@/api/Index.js";
import Vlist from "@/components/list";
import ListFiltering from "@/components/ListFiltering.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {selectDictDataByType} from "@/api/dict.js";

import {buryPoint} from "@/api/common";
import {fmtDate} from "@/utils/date";

export default {
  components: {
    VlistTitle,
    Vlist,
    ListFiltering,
    // VnotLogin
  },
  data() {
    return {
      showTips: [
        {
          title: "市场动态",
          text: "实时跟踪、全面扫描，最新权威市场动向",
        },
        {
          title: "洞察报告",
          text: "原创分析、深度洞察，AUTOINFO独家视点",
        },
        {
          title: "查看下载",
          text: "在线查看、下载编辑，支持原创报告PPT无限量下载",
        },
        {
          title: "全文检索",
          text: "精准检索、高效查询，支持标题、内容多种检索方式",
        }
      ],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "市场洞察",
        userId: ""
      },
      bgurl: require("@/assets/img/bg-1.jpg"),
      keywordsData: [],
      keywords: [],
      marketDynamicData: [],
      marketDynamicPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      show: false,
      title: "",
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.selectDictDataByType();
    this.marketDynamic();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "internationalCountryFl "}).then((res) => {
        if (res.ok) {
          this.keywordsData = res.data;
        }
      });
    },
    //类别切换
    handleChange(e) {
      this.keywords = e;
      this.marketDynamic();
    },
    marketDynamic() {
      // let form = Object.assign({}, params, {
      //     publishColumn: this.keywords.join(","),

      //     title: this.title,
      // });
      let form = {
        ...this.marketDynamicPage.pages,
        releaseColumn: 'gfgd',
        title: this.title,
        countryClassification: this.keywords.join(",")
      };
      internationalPage(form).then((res) => {
        if (res.ok) {
          this.marketDynamicData = res.data.list;
          this.marketDynamicData.forEach((item) => {
            item.releaseColumn = '';
          });
          this.marketDynamicPage.total = res.data.count;
        }
      });
    },
    handleSizeChange(v) {
      this.marketDynamicPage.pages.pageSize = v;
      this.marketDynamic();
    },
    handleCurrentChange(v) {
      this.marketDynamicPage.pages.page = v;
      this.marketDynamic();
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(item) {
      let newUrl = this.$router.resolve({
        path: "/international/detail/gdDetail",
        query: {
          id: item.id
        },
      });
      if (getUserInfo()) {

        logRecords('国际化','市场动态','1','tt_market_insight',item.id,'',item.title,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('市场洞察')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    search(e) {
      this.marketDynamicPage.pages.page = 1;
      this.title = e;
      this.marketDynamic();
      logRecords('市场洞察','市场动态','4','tt_market_insight','','','',e)

    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
