<template>
  <div class="top_nav">
    <div class="box max-container">
      <div class="left">
        <a href="https://eng.autoinfo.org.cn" target="_blank">English</a>
        <router-link to="/us" target="_blank">关于信息网</router-link>
        <!-- <router-link to="/prizeEssay" target="_blank">有奖征文</router-link> -->
        <a @click="command('feedback')">意见反馈</a>
        <a class="ai" v-if="enabledAI" @click="handleOpen">在线问答 <span class="new">NEW</span> </a>
      </div>
      <div class="right">
        <el-popover placement="bottom" width="210" trigger="hover">
          <div class="text-center">
            <img src="@/assets/img/xcx.jpg" alt="小程序" width="100%"/>
            <div style="font-weight: bold;color: #0f0f0f">微信扫码体验手机端内容</div>
            <div class="text-left" style="font-weight: bold;color: #0f0f0f">1）使用账号密码登录</div>
            <div class="text-left" style="font-weight: bold;color: #0f0f0f">2）账号密码绑定手机号登录</div>
            <div class="text-left">右侧头像下拉-->绑定手机号</div>
            <div class="text-left">仅限正式账号</div>
          </div>
          <span slot="reference" class="mini-program">
            <span>小程序</span>
            <img src="@/assets/images/new.png" />
          </span>
        </el-popover>
        <el-popover placement="bottom" width="130" trigger="hover">
          <div class="text-center">
            <img src="@/assets/img/ewm.jpg" alt="公众号" width="100%"/>
            <span>关注公众号</span>
          </div>
          <span slot="reference">公众号</span>
        </el-popover>
        <a href="https://forum.autoinfo.org.cn" target="_blank">泰达汽车论坛</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserInfo } from '@/utils/auth.js';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: "",
    },
  },
  created() {
    const userInfo = getUserInfo();

    if (userInfo) {
      this.userInfo = { ...userInfo };
    }
  },
  data() {
    return {
      userInfo: {},
    }
  },
  computed: {
    // 付费用户 和 内部用户可使用 AI
    enabledAI() {
      const { userId, roleKey } = this.userInfo;
      return userId && roleKey !== 'trialrole';
    },
  },
  methods: {
    ...mapActions({
      openAIService: 'AIService/openAIService',
    }),
    command(name) {
      this.$emit("command", name);
    },
    handleOpen() {
      this.openAIService();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.top_nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $top-nav-zindex;
  background: #fff;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: $top-nav-height;
    color: #7d7d93;

    .left,
    .right {
      >a + a,
      >a + span,
      >span + a,
      >span + span {
        margin-left: 24px;
      }

      >a, >span {
        color: inherit;
      }
    }
  }
}
.fixed-top-Banna {
  .top_nav {
    top: $top-banna-height;
  }
}
.mini-program {
  position: relative;
  img {
    position: absolute;
    left: 100%;
    bottom: 100%;
    margin-left: 2px;
    margin-bottom: -8px;
  }
}
.ai {
  position: relative;
  .new {
    position: absolute;
    top: -8px;
    right: -40px;
    padding: 0 5px;
    font-size: 12px;
    background: #ff6e4b;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
