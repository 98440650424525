/*
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2023-10-16 14:46:55
 * @LastEditors: 林子健
 * @Description: In User Settings Edit'
 * @FilePath: /information-vue/code/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import StrategyReport from '../strategyReport'
import Layout from '@/layout'
import RouterView from '@/layout/RouterView'
// import NewDataLayout from '@/layout/newDataLayout/index'
import Layout2 from '@/layout/twoIndex'

import Login from '@/views/login'
import { getUserInfo, getUserInfoNew, ifHidden } from '@/utils/auth.js'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    name: '首页',
    component: Layout,
    redirect: '/',
    meta: {
      hidden: ifHidden()
    },
    children: [
      {
        path: '/',
        component: () =>
          process.env.VUE_APP_CHECKLOGIN === 'zgqcgyxxw' && getUserInfoNew()
            ? import('@/views/index/index-syc')
            : process.env.VUE_APP_CHECKLOGIN === 'zgqcgyxxw'
              ? import('@/views/index/index')
              : import('@/views/indexfuao'),
        name: '首页',
        meta: {
          hidden: 1,
          acName: '首页'
        }
      },
      {
        path: '/index/list/LocalWatchList',
        name: '行业观察列表',
        component: () => import('@/views/index/list/LocalWatchList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '行业观察列表' }]
        }
      },
      {
        path: '/index/detail/LocalWatchDetail/:id',
        name: '行业观察内容页',
        component: () => import('@/views/index/detail/LocalWatchDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '行业观察列表', url: '/index/list/LocalWatchList' },
            { name: '行业观察内容页' }
          ]
        }
      },
      {
        path: '/index/list/industryInformationList',
        name: '最新快讯列表',
        component: () => import('@/views/index/list/industryInformationList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            {
              name: '最新快讯列表',
              url: '/index/list/industryInformationList'
            }
          ]
        }
      },
      {
        path: '/index/detail/industryInformationDetail/:id',
        name: '最新快讯内容页',
        component: () =>
          import('@/views/index/detail/industryInformationDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            {
              name: '最新快讯列表',
              url: '/index/list/industryInformationList'
            },
            { name: '最新快讯内容页' }
          ]
        }
      },

      {
        path: '/index/list/confidenceIndexList',
        name: '趋势报告列表',
        component: () => import('@/views/index/list/confidenceIndexList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '趋势报告列表', url: '/index/list/confidenceIndex' }
          ]
        }
      },
      {
        path: '/index/detail/confidenceIndexDetails/:id',
        name: '趋势报告内容页',
        component: () => import('@/views/index/detail/confidenceIndexDetails'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '趋势报告列表', url: '/index/list/confidenceIndexList' },
            { name: '趋势报告容页' }
          ]
        }
      },
      {
        path: '/index/list/newStandardList',
        name: '最新标准列表',
        component: () => import('@/views/index/list/newStandardList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '最新标准列表' }]
        }
      },
      {
        path: '/index/detail/newStandardDetail/:id',
        name: '首页标准内容页',
        component: () => import('@/views/index/detail/newStandardDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '最新标准列表', url: '/index/list/newStandardList' },
            { name: '首页标准内容页' }
          ]
        }
      },
      {
        path: '/index/list/newPolicyList',
        name: '首页政策列表',
        component: () => import('@/views/index/list/newPolicyList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '最新政策列表' }]
        }
      },
      {
        path: '/index/detail/newPolicyDetail/:id',
        name: '首页政策内容页',
        component: () => import('@/views/index/detail/newPolicyDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '首页政策列表', url: '/index/list/newPolicyList' },
            { name: '首页政策内容页' }
          ]
        }
      },
      {
        path: '/index/list/WebsiteSelectedList',
        name: '本网精选列表',
        component: () => import('@/views/index/list/WebsiteSelectedList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '本网精选列表' }]
        }
      },
      {
        path: '/index/detail/WebsiteSelectedDetail/:id',
        name: '本网精选内容页',
        component: () => import('@/views/index/detail/WebsiteSelectedDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '本网精选列表', url: '/index/list/WebsiteSelectedList' },
            { name: '本网精选内容页' }
          ]
        }
      },
      {
        path: '/index/list/specialList',
        name: '专题报道列表',
        component: () => import('@/views/index/list/specialList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '专题报道列表' }]
        }
      },
      {
        path: '/index/detail/specialDetail/:id',
        name: '专题报道内容页',
        component: () => import('@/views/index/detail/specialDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '专题报道列表', url: '/index/list/specialList' },
            { name: '专题报道内容页' }
          ]
        }
      },
      {
        path: '/index/detail/specialDetail/IndustryDetail/:id',
        name: '专题报道内容详情页',
        component: () => import('@/views/index/detail/IndustryDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '专题报道列表', url: '/index/list/specialList' },
            { name: '专题报道内容详情页' }
          ]
        }
      },
      {
        path: '/index/list/taidaList',
        name: '泰达论坛列表',
        component: () => import('@/views/index/list/taidaList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '泰达论坛列表' }]
        }
      },
      {
        path: '/index/detail/taidaDetail/:id',
        name: '泰达论坛内容页',
        component: () => import('@/views/index/detail/taidaDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '泰达论坛列表', url: '/index/list/taidaList' },
            { name: '泰达论坛内容页' }
          ]
        }
      },
      {
        path: '/index/list/IndustryAndReportList',
        name: '行业资讯列表',
        component: () => import('@/views/index/list/IndustryAndReportList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '行业资讯列表', url: '/index/list/IndustryAndReportList' }
          ]
        }
      },
      {
        path: '/index/list/sycIndustryAndReportList',
        name: '商用车行业资讯列表',
        component: () => import('@/views/index/list/sycIndustryAndReportList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            {
              name: '行业资讯列表',
              url: '/index/list/sycIndustryAndReportList'
            }
          ]
        }
      },
      {
        path: '/index/detail/IndustryDetail/:id',
        name: '首页行业资讯内容页',
        component: () => import('@/views/index/detail/IndustryDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '行业资讯列表', url: '/index/list/IndustryAndReportList' },
            { name: '行业资讯内容页' }
          ]
        }
      },
      {
        path: '/index/detail/sycIndustryDetail',
        name: '商用车行业资讯内容页',
        component: () => import('@/views/index/detail/sycIndustryDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            {
              name: '行业资讯列表',
              url: '/index/list/sycIndustryAndReportList'
            },
            { name: '行业资讯内容页' }
          ]
        }
      },
      {
        path: '/index/detail/ReportDetail',
        name: ' 分析报告内容页',
        component: () => import('@/views/index/detail/ReportDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '行业资讯列表', url: '/index/list/IndustryAndReportList' },
            { name: '分析报告内容页' }
          ]
        }
      },
      {
        path: '/index/list/videoList',
        name: '视频列表',
        component: () => import('@/views/index/list/videoList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '视频列表' }]
        }
      },
      {
        path: '/index/detail/videoDetail/:id',
        name: '视频内容页',
        component: () => import('@/views/index/detail/videoDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '视频列表', url: '/index/list/videoList' },
            { name: '视频内容页' }
          ]
        }
      },
      {
        path: '/index/list/highInterviewList',
        name: '高端访谈列表',
        component: () => import('@/views/index/list/highInterviewList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '高端访谈列表' }]
        }
      },
      {
        path: '/index/detail/highInterviewDetail/:id',
        name: '高端访谈内容页',
        component: () => import('@/views/index/detail/highInterviewDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '高端访谈列表', url: '/index/list/highInterviewList' },
            { name: '高端访谈内容页' }
          ]
        }
      },
      {
        path: '/index/list/meetingList',
        name: ' 会议信息列表',
        component: () => import('@/views/index/list/meetingList'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '会议信息列表' }]
        }
      },
      {
        path: '/index/detail/meetingDetail/:id',
        name: ' 会议信息内容页',
        component: () => import('@/views/index/detail/meetingDetail'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '会议信息列表', url: '/index/list/meetingList' },
            { name: '会议信息内容页' }
          ]
        }
      },
      {
        path: '/FullTextRetrieval',
        name: ' 全文检索',
        component: () => import('@/views/FullTextRetrieval'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [{ name: '首页', url: '/' }, { name: '全文检索' }],
          keepAlive: true
        }
      },
      {
        path: '/FullTextRetrieval/fullTextRetrievalContent',
        name: ' 全文检索详情',
        component: () =>
          import('@/views/FullTextRetrieval/fullTextRetrievalContent.vue'),
        meta: {
          hidden: 1,
          acName: '首页',
          breadcrumb: [
            { name: '首页', url: '/' },
            { name: '全文检索', url: '/FullTextRetrieval' },
            { name: '全文检索详情' }
          ]
        }
      }
    ]
  },
  {
    path: '/infoLogin',
    name: '信息网登陆',
    component: () => import('@/views/knowledgePlatform/login'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/us',
    name: '关于信息网',
    component: () => import('@/views/us/index'),
    meta: {
      hidden: 1
    }
  },
  // {
  //   path: '/pdf',
  //   name: 'pdf在线预览',
  //   component: () => import('@/components/vue-pdf/index'),
  //   meta: {
  //     hidden: 1
  //   }
  // },
  {
    path: '/sqSy',
    name: '申请试用',
    component: () => import('@/views/us/indexSqSy'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/sjSy',
    name: '手机端申请试用',
    component: () => import('@/views/us/indexSqSySj'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/usScjl',
    name: '联系我们',
    component: () => import('@/views/us/indexScjl'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/prizeEssay',
    name: '有奖征文',
    component: () => import('@/views/prizeEssay/index'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/notice',
    name: '通知',
    component: () => import('@/views/notice/index'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/notice/esc',
    name: '二手车上线通知',
    component: () => import('@/views/notice/index-esc'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/history',
    name: '历史',
    component: () => import('@/views/data/domesticData/components/history'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/announcement/announcementSearch/VehicleDetails/:id',
    name: '公告查询详情',
    component: () =>
      import('@/views/announcement/announcementSearch/VehicleDetails'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/recommendedVehicleCatalog/roadTransportVehicleModelsDetails/:id',
    name: '道路运输车辆达标车型详情',
    component: () => import('@/views/newData/recommendedVehicleCatalog/roadTransportVehicleModels/details'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/announcement/announcementSearch/optional/:id',
    name: '选装内容',
    component: () => import('@/views/announcement/announcementSearch/optional'),
    meta: {
      hidden: 1,
      acName: '公告'
    }
  },
  {
    path:
            '/announcement/announcementSearch/VehicleDetailsComparison/:id/hitoryPc/:hitoryPc',
    name: '整车比对',
    component: () =>
      import(
        '@/views/announcement/announcementSearch/VehicleDetailsComparison'
      ),
    meta: {
      hidden: 1,
      acName: '公告',
      breadcrumb: [
        { name: '公告', url: '/announcement/dynamic/index' },
        { name: '公告查询', url: '/announcement/announcementSearch/index' },
        { name: '公告查询详情' }
      ]
    }
  },
  {
    path: '/announcement/announcementSearch/VehicleDetailsHistory',
    name: '公告历史',
    component: () =>
      import('@/views/announcement/announcementSearch/VehicleDetailsHistory'),
    meta: {
      hidden: 1,
      acName: '公告'
    }
  },
  {
    path: '/announcement/announcementSearch/ChassisDetails/:id',
    name: '底盘内容',
    component: () =>
      import('@/views/announcement/announcementSearch/ChassisDetails'),
    meta: {
      hidden: 1,
      acName: '公告',
      breadcrumb: [
        { name: '公告', url: '/announcement/dynamic/index' },
        { name: '公告查询', url: '/announcement/announcementSearch/index' },
        { name: '公告查询详情' }
      ]
    }
  },
  {
    path: '/announcement/announcementSearch/MotorcyclesDetails/:id',
    name: '摩托车内容',
    component: () =>
      import('@/views/announcement/announcementSearch/MotorcyclesDetails'),
    meta: {
      hidden: 1,
      acName: '公告',
      breadcrumb: [
        { name: '公告', url: '/announcement/dynamic/index' },
        { name: '公告查询', url: '/announcement/announcementSearch/index' },
        { name: '公告查询详情' }
      ]
    }
  },
  {
    path: '/announcement/announcementSearch/ThirdWheelDetails/:id',
    name: '三轮车内容',
    component: () =>
      import('@/views/announcement/announcementSearch/ThirdWheelDetails'),
    meta: {
      hidden: 1,
      acName: '公告',
      breadcrumb: [
        { name: '公告', url: '/announcement/dynamic/index' },
        { name: '公告查询', url: '/announcement/announcementSearch/index' },
        { name: '公告查询详情' }
      ]
    }
  },
  // {
  //   path: '/syccxhz',
  //   name: "商用车产销汇总表",
  //   component: () => import('@/views/data/twenthySevenTable/syccxhz'),
  //   meta: {
  //     hidden: 1
  //   }
  // },
  {
    path: '/data/list',
    name: '数据概览列表',
    component: () => import('@/views/data/data/list'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/list-syc',
    name: '数据概览列表-商用车',
    component: () => import('@/views/data/data/list-syc'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/qccxhzb/:date',
    name: '汽车(分车型)产销汇总表',
    component: () => import('@/views/data/data/dataTable/qccxhzb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/cychzb/:date',
    name: '乘用车产销汇总表',
    component: () => import('@/views/data/data/dataTable/cychzb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/sychzb/:date',
    name: '商用车产销汇总表',
    component: () => import('@/views/data/data/dataTable/sychzb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/qgcyczyppcxqkb/:date/:bj2',
    name: '全国乘用车主要品牌产销情况表',
    component: () => import('@/views/data/data/dataTable/qgcyczyppcxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/wdqcqycyc/:date',
    name: '五大汽车企业集团乘用车产销情况表',
    component: () => import('@/views/data/data/dataTable/wdqcqycyc'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/wdqcqysyc/:date',
    name: '五大汽车企业集团商用车产销情况表',
    component: () => import('@/views/data/data/dataTable/wdqcqysyc'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/fdjscqy/:date',
    name: '发动机生产企业产销情况表 (台)',
    component: () => import('@/views/data/data/dataTable/fdjscqy'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/fdsp/:date',
    name: '发动机生产企业商品量情况表',
    component: () => import('@/views/data/data/dataTable/fdsp'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/fdzp/:date',
    name: '发动机生产企业自配量情况表',
    component: () => import('@/views/data/data/dataTable/fdzp'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/cycfcxcxqkb/:date',
    name: '乘用车(分车型)产销情况表',
    component: () => import('@/views/data/data/dataTable/cycfcxcxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/cycscqycxqkb/:date',
    name: '乘用车生产企业产销情况表',
    component: () => import('@/views/data/data/dataTable/cycscqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/sycscqycxqkb/:date',
    name: '商用车生产企业产销情况表',
    component: () => import('@/views/data/data/dataTable/sycscqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/scqycxqkb/:date/:bj4',
    name: '生产企业产销情况表',
    component: () => import('@/views/data/data/dataTable/scqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/qcfdjarlflscqyqkb/:date',
    name: '汽车发动机(按燃料分类)生产企业产销情况表 (台)',
    component: () => import('@/views/data/data/dataTable/qcfdjarlflscqyqkb'),
    meta: {
      hidden: 1
    }
  },

  {
    path: '/data/dataTable/cqsp/:date',
    name: '汽车发动机(按燃料分类)生产企业商品量情况表 ',
    component: () => import('@/views/data/data/dataTable/cqsp'),
    meta: {
      hidden: 1
    }
  },

  {
    path: '/data/dataTable/cqzp/:date',
    name: '汽车发动机(按燃料分类)生产企业自配量情况表',
    component: () => import('@/views/data/data/dataTable/cqzp'),
    meta: {
      hidden: 1
    }
  },

  {
    path: '/data/dataTable/xnyqccxqkb/:date',
    name: '新能源汽车产销情况表',
    component: () => import('@/views/data/data/dataTable/xnyqccxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/bgqycfcxcxhzb/:date',
    name: '半挂牵引车(分车型)产销汇总表',
    component: () => import('@/views/data/data/dataTable/bgqycfcxcxhzb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/qcfqyckqkb/:date',
    name: '汽车分企业出口情况表',
    component: () => import('@/views/data/data/dataTable/qcfqyckqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/qcsczyqycxqkb/:date',
    name: '汽车生产主要企业产销情况表',
    component: () => import('@/views/data/data/dataTable/qcsczyqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/xnysyccyc/:date/:bj1',
    name: '新能源商用车乘用车',
    component: () => import('@/views/data/data/dataTable/xnysyccyc'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/frlclfcxcxb/:date/:bj4',
    name: '分燃料分车型产销情况表',
    component: () => import('@/views/data/data/dataTable/frlclfcxcxb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/zhcfdwcxqkb/:date',
    name: '载货车(分吨位)产销情况表',
    component: () => import('@/views/data/data/dataTable/zhcfdwcxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/zhcfytcxqkb/:date',
    name: '载货车(分用途)产销情况表',
    component: () => import('@/views/data/data/dataTable/zhcfytcxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/getHcfwzclscqycxqkb/:date',
    name: '货车非完整车辆生产企业产销情况表',
    component: () => import('@/views/data/data/dataTable/getHcfwzclscqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/getBgqycscqycxqkb/:date',
    name: '半挂牵引车生产企业产销情况表',
    component: () => import('@/views/data/data/dataTable/getBgqycscqycxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/getKcfcxCxqkb/:date',
    name: '客车(分车型)产销情况表',
    component: () => import('@/views/data/data/dataTable/getKcfcxCxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/getZhcfcxCxqkb/:date',
    name: '载货车(分车型)产销情况表',
    component: () => import('@/views/data/data/dataTable/getZhcfcxCxqkb'),
    meta: {
      hidden: 1
    }
  },
  {
    path: '/data/dataTable/bgqycfdwcxqkb/:date',
    name: '半挂牵引车(分吨位)产销情况表',
    component: () => import('@/views/data/data/dataTable/bgqycfdwcxqkb'),
    meta: {
      hidden: 1
    }
  }
]

export const asyncRoutes = [
  {
    path: '/policy/dynamic/index',
    name: '政策',
    component: Layout,
    redirect: '/policy/dynamic/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/policy/dynamic/index',
        name: '政策原文及报道',
        component: () => import('@/views/policy/dynamic/index'),
        meta: {
          hidden: 0,
          acName: '政策'
        }
      },
      {
        path: '/policy/dynamic/newListPolicy',
        name: '最新政策列表',
        component: () => import('@/views/policy/dynamic/newListPolicy'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策原文及报道', url: '/policy/dynamic/index' },
            { name: '最新政策列表' }
          ]
        }
      },
      {
        path: '/policy/dynamic/contentNewPolicyDyn/:id',
        name: '最新政策内容页',
        component: () => import('@/views/policy/dynamic/contentNewPolicyDyn'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策原文及报道', url: '/policy/dynamic/index' },
            { name: '最新政策列表', url: '/policy/dynamic/newListPolicy' },
            { name: '最新政策内容页' }
          ]
        }
      },
      {
        path: '/policy/unscramble/index',
        name: '政策解读',
        component: () => import('@/views/policy/unscramble/index'),
        meta: {
          hidden: 0,
          acName: '政策'
        }
      },
      {
        path: '/policy/search/index',
        name: '政策库查询',
        component: () => import('@/views/policy/search/index'),
        meta: {
          hidden: 0,
          acName: '政策'
        }
      },
      {
        path: '/policy/bigdata/index',
        name: '政策大数据',
        component: () => import('@/views/policy/bigdata/index'),
        meta: {
          hidden: 0,
          acName: '政策',
          icon: 'el-icon-download'
        }
      },
      {
        path: '/policy/carding/index',
        name: '政策梳理',
        component: () => import('@/views/policy/carding/index'),
        meta: {
          hidden: 0,
          acName: '政策'
        }
      },

      {
        path: '/policy/dynamic/listLocalOriginal',
        name: '本网原创列表',
        component: () => import('@/views/policy/dynamic/listLocalOriginal'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            { name: '本网原创列表' }
          ]
        }
      },
      {
        path: '/policy/dynamic/LocalOriginalDetails/:id',
        name: '本网原创内容页',
        component: () => import('@/views/policy/dynamic/LocalOriginalDetails'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            { name: '本网原创列表', url: '/policy/dynamic/listLocalOriginal' },
            { name: '本网原创内容页' }
          ]
        }
      },
      {
        path: '/policy/dynamic/listPolicyAnalysis',
        name: '评价与趋势分析列表',
        component: () => import('@/views/policy/dynamic/listPolicyAnalysis'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            { name: '评价与趋势分析列表' }
          ]
        }
      },
      {
        path: '/policy/dynamic/policyAnalysisDetails/:id',
        name: '评价与趋势分析内容页',
        component: () => import('@/views/policy/dynamic/policyAnalysisDetails'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            {
              name: '评价与趋势分析列表',
              url: '/policy/dynamic/listPolicyAnalysis'
            },
            { name: '评价与趋势分析内容页' }
          ]
        }
      },
      {
        path: '/policy/dynamic/listReport',
        name: '政策报道列表',
        component: () => import('@/views/policy/dynamic/listReport'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策原文及报道', url: '/policy/dynamic/index' },
            { name: '政策报道列表' }
          ]
        }
      },
      {
        path: '/policy/dynamic/contentReport/:id',
        name: '政策报道内容页',
        component: () => import('@/views/policy/dynamic/contentReport'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策原文及报道', url: '/policy/dynamic/index' },
            { name: '政策报道列表', url: '/policy/dynamic/listReport' },
            { name: '政策报道内容页' }
          ]
        }
      },
      {
        path: '/policy/dynamic/listExplain',
        name: '政策解读列表',
        component: () => import('@/views/policy/dynamic/listExplain'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            { name: '政策解读列表' }
          ]
        }
      },
      {
        path: '/policy/dynamic/contentExplain/:id',
        name: '政策解读内容页',
        component: () => import('@/views/policy/dynamic/contentExplain'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策解读', url: '/policy/unscramble/index' },
            { name: '政策解读列表', url: '/policy/dynamic/listExplain' },
            { name: '政策解读内容页' }
          ]
        }
      },
      {
        path: '/policy/carding/details/:id',
        name: '政策树详情页面',
        component: () => import('@/views/policy/carding/details'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策原文及报道', url: '/policy/dynamic/index' },
            { name: '政策梳理', url: '/policy/carding/index' },
            { name: '政策梳理详情页面' }
          ]
        }
      },
      {
        path: '/policy/dynamic/contentNewPolicy/:id',
        name: '政策库内容页',
        component: () => import('@/views/policy/dynamic/contentNewPolicy'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '政策库查询', url: '/policy/search/index' },
            { name: '政策库内容页' }
          ]
        }
      }
    ]
  },

  {
    path: '/standards/dynamic/index',
    name: '标准',
    component: Layout,
    redirect: '/standards/dynamic/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/standards/dynamic/index',
        name: '标准原文',
        component: () => import('@/views/standards/dynamic/index'),
        meta: {
          hidden: 0,
          acName: '标准'
        }
      },
      {
        path: '/standards/dynamic/newStandards/list',
        name: '最新标准列表',
        component: () => import('@/views/standards/dynamic/newStandards/list'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准原文', url: '/standards/dynamic/index' },
            { name: '最新标准列表' }
          ]
        }
      },
      {
        path: '/standards/dynamic/newStandards/details/:id',
        name: '最新标准内容页',
        component: () =>
          import('@/views/standards/dynamic/newStandards/details'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准原文', url: '/standards/dynamic/index' },
            {
              name: '最新标准列表',
              url: '/standards/dynamic/newStandards/list'
            },
            { name: '最新标准内容页' }
          ]
        }
      },
      {
        path: '/standards/dynamic/index2',
        name: '标准动态及解读',
        component: () => import('@/views/standards/dynamic/index2'),
        meta: {
          hidden: 0,
          acName: '标准'
        }
      },
      {
        path: '/standards/dynamic/listDynamic',
        name: '标准动态列表',
        component: () => import('@/views/standards/dynamic/listDynamic'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准动态及解读', url: '/standards/dynamic/index2' },
            { name: '标准动态列表' }
          ]
        }
      },
      {
        path: '/standards/dynamic/contentDynamic/:id',
        name: '标准动态内容页',
        component: () => import('@/views/standards/dynamic/contentDynamic'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准动态及解读', url: '/standards/dynamic/index2' },
            { name: '标准动态列表', url: '/standards/dynamic/listDynamic' },
            { name: '标准动态内容页' }
          ]
        }
      },
      {
        path: '/standards/dynamic/newComments/list',
        name: '征求/报批意见稿列表',
        component: () => import('@/views/standards/dynamic/newComments/list'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准原文', url: '/standards/dynamic/index' },
            { name: '征求/报批意见稿列表' }
          ]
        }
      },
      {
        path: '/standards/dynamic/newComments/details/:id',
        name: '征求/报批意见稿内容页',
        component: () =>
          import('@/views/standards/dynamic/newComments/details'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准原文', url: '/standards/dynamic/index' },
            {
              name: '征求/报批意见稿列表',
              url: '/standards/dynamic/newComments/list'
            },
            { name: '征求/报批意见稿内容页' }
          ]
        }
      },
      {
        path: '/standards/search/index',
        name: '标准库查询',
        component: () => import('@/views/standards/search/index'),
        meta: {
          hidden: 0,
          acName: '标准'
        }
      },
      {
        path: '/standards/bigdata/index',
        name: '标准大数据',
        component: () => import('@/views/standards/bigdata/index'),
        meta: {
          hidden: 0,
          acName: '标准',
          icon: 'el-icon-download'
        }
      },
      {
        path: '/standards/carding/index',
        name: '标准梳理',
        component: () => import('@/views/standards/carding/index'),
        meta: {
          hidden: 0,
          acName: '标准'
        }
      },

      {
        path: '/standards/dynamic/listUnscramble',
        name: '标准解读列表',
        component: () => import('@/views/standards/dynamic/listUnscramble'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准动态及解读', url: '/standards/dynamic/index2' },
            { name: '标准解读列表', url: '/standards/dynamic/listUnscramble' }
          ]
        }
      },
      {
        path: '/standards/dynamic/contentUnscramble/:id',
        name: '标准解读内容页',
        component: () => import('@/views/standards/dynamic/contentUnscramble'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准动态及解读', url: '/standards/dynamic/index2' },
            { name: '标准解读列表', url: '/standards/dynamic/listUnscramble' },
            { name: '标准解读内容页' }
          ]
        }
      },
      {
        path: '/standards/dynamic/ttStandard/code',
        name: '标准解读内容页1',
        component: () => import('@/views/standards/dynamic/contentttStandard'),
        meta: {
          hidden: 1,
          acName: '标准'
        }
      },
      {
        path: '/standards/dynamic/listComments',
        name: ' 标准征求意见稿列表',
        component: () => import('@/views/standards/dynamic/listComments'),
        meta: {
          hidden: 1,
          acName: '标准'
        }
      },
      {
        path: '/standards/dynamic/contentComments/:id',
        name: '标准征求意见稿内容',
        component: () => import('@/views/standards/dynamic/contentComments'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准库查询', url: '/standards/search/index' },
            { name: '标准征求意见稿内容' }
          ]
        }
      },
      {
        path: '/standards/carding/details/:id',
        name: '标准梳理内容',
        component: () => import('@/views/standards/carding/details'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准原文', url: '/standards/dynamic/index' },
            { name: '标准梳理', url: '/standards/carding/index' },
            { name: '标准梳理内容' }
          ]
        }
      },
      {
        path: '/standards/dynamic/ttStandard/:id',
        name: '标准库内容页',
        component: () => import('@/views/standards/dynamic/contentttStandard'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '标准库查询', url: '/standards/search/index' },
            { name: '标准库内容页' }
          ]
        }
      }
    ]
  },
  {
    path: '/policy/dynamic/sycIndex',
    name: '商用车政策标准',
    component: Layout,
    redirect: '/policy/dynamic/sycIndex',
    meta: {
      hidden: 1
    },
    children: [
      {
        path: '/policy/dynamic/sycIndex',
        name: '政策',
        component: () => import('@/views/index'),
        meta: {
          hidden: 0,
          acName: '商用车政策标准'
        },
        children: [
          {
            path: '/policy/dynamic/sycIndex',
            name: '政策动态',
            component: () => import('@/views/policy/dynamic/syc/index'),
            meta: {
              hidden: 0,
              acName: '商用车政策标准'
            }
          },
          {
            path: '/policy/dynamic/syc/newListPolicy',
            name: '最新政策列表',
            component: () => import('@/views/policy/dynamic/syc/newListPolicy'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                // { name: "商用车政策", url: '/policy/dynamic/sycIndex' },
                { name: '政策动态', url: '/policy/dynamic/sycIndex' },
                { name: '最新政策列表' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/contentNewPolicyDyn/:id',
            name: '最新政策内容页',
            component: () =>
              import('@/views/policy/dynamic/syc/contentNewPolicyDyn'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策动态', url: '/policy/dynamic/sycIndex' },
                {
                  name: '最新政策列表',
                  url: '/policy/dynamic/syc/newListPolicy'
                },
                { name: '最新政策内容页' }
              ]
            }
          },
          {
            path: '/policy/unscramble/sycIndex',
            name: '政策解读',
            component: () => import('@/views/policy/unscramble/sycIndex'),
            meta: {
              hidden: 0,
              acName: '商用车政策标准'
            }
          },
          {
            path: '/policy/search/sycIndex',
            name: '政策库查询',
            component: () => import('@/views/policy/search/sycIndex'),
            meta: {
              hidden: 0,
              acName: '商用车政策标准'
            }
          },
          {
            path: '/policy/dynamic/syc/contentNewPolicy/:id',
            name: '最新政策内容页',
            component: () =>
              import('@/views/policy/dynamic/syc/contentNewPolicy'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策库查询', url: '/policy/search/sycIndex' },
                { name: '最新政策内容页' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/listLocalOriginal',
            name: '本网原创列表',
            component: () =>
              import('@/views/policy/dynamic/syc/listLocalOriginal'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策解读', url: '/policy/unscramble/sycIndex' },
                { name: '本网原创列表' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/LocalOriginalDetails/:id',
            name: '本网原创内容页',
            component: () =>
              import('@/views/policy/dynamic/syc/LocalOriginalDetails'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策解读', url: '/policy/unscramble/sycIndex' },
                {
                  name: '本网原创列表',
                  url: '/policy/dynamic/syc/listLocalOriginal'
                },
                { name: '本网原创内容页' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/listReport',
            name: '政策报道列表',
            component: () => import('@/views/policy/dynamic/syc/listReport'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '商用车政策', url: '/policy/dynamic/sycIndex' },
                { name: '政策报道列表' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/contentReport/:id',
            name: '政策报道内容页',
            component: () => import('@/views/policy/dynamic/syc/contentReport'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '商用车政策', url: '/policy/dynamic/sycIndex' },
                { name: '政策报道列表', url: '/policy/dynamic/listReport' },
                { name: '政策报道内容页' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/listExplain',
            name: '政策解读列表',
            component: () => import('@/views/policy/dynamic/syc/listExplain'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策解读', url: '/policy/unscramble/sycIndex' },
                { name: '政策解读列表' }
              ]
            }
          },
          {
            path: '/policy/dynamic/syc/contentExplain/:id',
            name: '政策解读内容页',
            component: () =>
              import('@/views/policy/dynamic/syc/contentExplain'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '政策解读', url: '/policy/unscramble/sycIndex' },
                {
                  name: '政策解读列表',
                  url: '/policy/dynamic/syc/listExplain'
                },
                { name: '政策解读内容页' }
              ]
            }
          }
        ]
      },
      {
        path: '/standards/dynamic/syc/index',
        name: '标准',
        component: () => import('@/views/index'),
        meta: {
          hidden: 0
        },
        children: [
          {
            path: '/standards/dynamic/syc/index',
            name: '标准动态及解读',
            component: () => import('@/views/standards/dynamic/syc/index'),
            meta: {
              hidden: 0,
              acName: '商用车政策标准'
            }
          },
          {
            path: '/standards/dynamic/syc/newStandards/list',
            name: '最新标准列表',
            component: () =>
              import('@/views/standards/dynamic/syc/newStandards/list'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                { name: '最新标准列表' }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/newStandards/details/:id',
            name: '最新标准内容页',
            component: () =>
              import('@/views/standards/dynamic/syc/newStandards/details'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                {
                  name: '最新标准列表',
                  url: '/standards/dynamic/syc/newStandards/list'
                },
                { name: '最新标准内容页' }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/listDynamic',
            name: '标准动态列表',
            component: () =>
              import('@/views/standards/dynamic/syc/listDynamic'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                { name: '标准动态列表' }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/contentDynamic/:id',
            name: '标准动态内容页',
            component: () =>
              import('@/views/standards/dynamic/syc/contentDynamic'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                {
                  name: '标准动态列表',
                  url: '/standards/dynamic/syc/listDynamic'
                },
                { name: '标准动态内容页' }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/newComments/list',
            name: '征求/报批意见稿列表',
            component: () =>
              import('@/views/standards/dynamic/syc/newComments/list'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                { name: '征求/报批意见稿列表' }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/newComments/details/:id',
            name: '征求/报批意见稿内容页',
            component: () =>
              import('@/views/standards/dynamic/syc/newComments/details'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                {
                  name: '征求/报批意见稿列表',
                  url: '/standards/dynamic/syc/newComments/list'
                },
                { name: '征求/报批意见稿内容页' }
              ]
            }
          },
          {
            path: '/standards/search/syc/index',
            name: '标准库查询',
            component: () => import('@/views/standards/search/syc/index'),
            meta: {
              hidden: 0,
              acName: '商用车政策标准'
            }
          },

          {
            path: '/standards/dynamic/syc/listUnscramble',
            name: '标准解读列表',
            component: () =>
              import('@/views/standards/dynamic/syc/listUnscramble'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                {
                  name: '标准解读列表',
                  url: '/standards/dynamic/syc/listUnscramble'
                }
              ]
            }
          },
          {
            path: '/standards/dynamic/syc/contentUnscramble/:id',
            name: '标准解读内容页',
            component: () =>
              import('@/views/standards/dynamic/syc/contentUnscramble'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准动态及解读', url: '/standards/dynamic/syc/index' },
                {
                  name: '标准解读列表',
                  url: '/standards/dynamic/syc/listUnscramble'
                },
                { name: '标准解读内容页' }
              ]
            }
          },

          {
            path: '/standards/dynamic/syc/listComments',
            name: ' 标准征求意见稿列表',
            component: () =>
              import('@/views/standards/dynamic/syc/listComments'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准'
            }
          },
          {
            path: '/standards/dynamic/syc/contentComments/:id',
            name: '标准征求意见稿内容',
            component: () =>
              import('@/views/standards/dynamic/syc/contentComments'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准库查询', url: '/standards/search/syc/index' },
                { name: '标准征求意见稿内容' }
              ]
            }
          },

          {
            path: '/standards/dynamic/syc/ttStandard/:id',
            name: '标准库内容页',
            component: () =>
              import('@/views/standards/dynamic/syc/contentttStandard'),
            meta: {
              hidden: 1,
              acName: '商用车政策标准',
              breadcrumb: [
                { name: '标准库查询', url: '/standards/search/syc/index' },
                { name: '标准库内容页' }
              ]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/announcement/dynamic/index',
    name: '公告',
    component: Layout,
    redirect: '/announcement/dynamic/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/announcement/dynamic/index',
        name: '公告动态',
        component: () => import('@/views/announcement/dynamic/index'),
        meta: {
          hidden: 0,
          acName: '公告'
        }
      },
      {
        path: '/announcement/dynamic/ggPublicList',
        name: '公告动态列表',
        component: () => import('@/views/announcement/dynamic/ggPublicList'),
        meta: {
          hidden: 1,
          acName: '公告',
          breadcrumb: [
            { name: '公告', url: '/announcement/dynamic/index' },
            { name: '公告动态列表' }
          ]
        }
      },
      {
        path: '/announcement/dynamic/dynamicApprovalContent/:id',
        name: '公告动态详情',
        component: () =>
          import('@/views/announcement/dynamic/dynamicApprovalContent'),
        meta: {
          hidden: 1,
          acName: '公告',
          breadcrumb: [
            { name: '公告', url: '/announcement/dynamic/index' },
            { name: '公告动态列表', url: '/announcement/dynamic/ggPublicList' },
            { name: '公告动态详情' }
          ]
        }
      },
      {
        path: '/announcement/dynamic/ggApprovalcList',
        name: '公告批文列表',
        component: () => import('@/views/announcement/dynamic/ggApprovalcList'),
        meta: {
          hidden: 1,
          acName: '公告',
          breadcrumb: [
            { name: '公告', url: '/announcement/dynamic/index' },
            { name: '公告批文列表' }
          ]
        }
      },
      {
        path: '/announcement/dynamic/ggApprovalcContent/:id',
        name: '公告批文详情',
        component: () =>
          import('@/views/announcement/dynamic/ggApprovalcContent'),
        meta: {
          hidden: 1,
          acName: '公告',
          breadcrumb: [
            { name: '公告', url: '/announcement/dynamic/index' },
            {
              name: '公告批文列表',
              url: '/announcement/dynamic/ggApprovalcList'
            },
            { name: '公告批文详情' }
          ]
        }
      },
      {
        path: '/announcement/search/index',
        name: '公告公示查询',
        component: () => import('@/views/announcement/search/index'),
        meta: {
          hidden: 0,
          acName: '公告'
        },
        children: [
          {
            path: '/announcement/search/details/:id',
            name: '公告公示详情',
            component: () => import('@/views/announcement/search/details'),
            meta: {
              hidden: 1,
              acName: '公告',
              breadcrumb: [
                { name: '公告', url: '/announcement/dynamic/index' },
                { name: '公告公示查询', url: '/announcement/search/index' },
                { name: '公告公示详情' }
              ]
            }
          },
          {
            path: '/announcement/search/optional/:id',
            name: '公告公示选装',
            component: () => import('@/views/announcement/search/optional'),
            meta: {
              hidden: 1,
              acName: '公告',
              breadcrumb: [
                { name: '公告', url: '/announcement/dynamic/index' },
                { name: '公告公示查询', url: '/announcement/search/index' },
                { name: '公告公示选装' }
              ]
            }
          }
        ]
      },
      {
        path: '/announcement/announcementSearch/index',
        name: '公告查询',
        component: () =>
          import('@/views/announcement/announcementSearch/index'),
        meta: {
          hidden: 0,
          acName: '公告'
        },
        children: [
          // {
          //   path: "/announcement/announcementSearch/optional/:id",
          //   name: "选装内容",
          //   component: () => import('@/views/announcement/announcementSearch/optional'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "选装内容" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/VehicleDetails/:id",
          //   name: "公告查询详情",
          //   component: () => import('@/views/announcement/announcementSearch/VehicleDetails'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "公告查询详情" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/VehicleDetailsComparison/:id/hitoryPc/:hitoryPc",
          //   name: "整车比对",
          //   component: () => import('@/views/announcement/announcementSearch/VehicleDetailsComparison'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "公告查询详情" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/ChassisDetails/:id",
          //   name: "底盘内容",
          //   component: () => import('@/views/announcement/announcementSearch/ChassisDetails'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "公告查询详情" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/MotorcyclesDetails/:id",
          //   name: "摩托车内容",
          //   component: () => import('@/views/announcement/announcementSearch/MotorcyclesDetails'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "公告查询详情" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/ThirdWheelDetails/:id",
          //   name: "三轮车内容",
          //   component: () => import('@/views/announcement/announcementSearch/ThirdWheelDetails'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "公告查询详情" },
          //     ]
          //   },
          // },
          // {
          //   path: "/announcement/announcementSearch/VehicleDetailsHistory",
          //   name: "历史",
          //   component: () => import('@/views/announcement/announcementSearch/VehicleDetailsHistory'),
          //   meta: {
          //     hidden: 1,
          //     acName: "公告",
          //     breadcrumb: [
          //       { name: "公告", url: '/announcement/dynamic/index' },
          //       { name: "公告查询", url: '/announcement/announcementSearch/index' },
          //       { name: "历史" },
          //     ]
          //   },
          // }
        ]
      }
    ]
  },

  {
    path: '/data',
    name: '数据',
    component: Layout,
    redirect: '/data/dataReport',
    meta: {
      hidden: 0
    },
    // 这里的 children 将由 vuex 中的方法生成，用于切换新旧版本
    children: []
  },
  {
    path: '/data/data/sycIndex',
    name: '商用车数据监测',
    component: Layout,
    redirect: '/data/data/sycIndex',
    meta: {
      hidden: 1
    },
    children: [
      {
        path: '/data/data/sycIndex',
        name: '数据概览',
        component: () => import('@/views/data/data/sycIndex'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },
      {
        path: '/data/domesticData/sycIndex1',
        name: '国内产销数据',
        component: () => import('@/views/data/domesticData/sycIndex1'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },
      {
        path: '/sale/salesForecast/sycIndex',
        name: '商用车预测',
        component: () => import('@/views/sale/salesForecast/sycIndex'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },
      {
        path: '/data/exportData/sycIndex',
        name: '出口数据',
        component: () => import('@/views/data/exportData/sycIndex'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },

      {
        path: '/data/engineData/sycIndex',
        name: '发动机数据',
        component: () => import('@/views/data/engineData/sycIndex'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },

      {
        path: '/data/exemptionFromPurchaseTax/sycIndex',
        name: '免征购置税目录',
        component: () =>
          import('@/views/data/exemptionFromPurchaseTax/sycIndex'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      },

      {
        path: '/data/macroEnvironment/sycIndex',
        name: '宏观环境',
        component: () => import('@/views/data/macroEnvironment/index'),
        meta: {
          hidden: 0,
          acName: '商用车数据监测'
        }
      }
    ]
  },
  {
    path: '/sale/cycPredict/index',
    name: '预测',
    redirect: '/sale/cycPredict/index',
    component: Layout,
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/sale/cycPredict/index',
        name: '乘用车预测',
        component: () => import('@/views/sale/cycPredict/index'),
        meta: {
          hidden: 0,
          acName: '预测'
        }
      },
      {
        path: '/sale/sycPredict/index',
        name: '商用车预测',
        component: () => import('@/views/sale/sycPredict/index'),
        meta: {
          hidden: 0,
          acName: '预测'
        }
      },

      {
        path: '/sale/qyPredict/index',
        name: '企业预测',
        component: () => import('@/views/sale/qyPredict/index'),
        meta: {
          hidden: 0,
          acName: '预测'
        }
      },
      {
        path: '/sale/batteryPredict/index',
        name: '动力电池退役量预测',
        component: () => import('@/views/sale/batteryPredict/index'),
        meta: {
          hidden: 0,
          acName: '预测'
        }
      },
      // {
      //     path: "/sale/salesForecast/index",
      //     name: "整体销量预测",
      //     component: () => import('@/views/sale/salesForecast/index'),
      //     meta: {
      //         hidden: 0,
      //         acName: "预测"
      //     },
      // },
      // {
      //     path: "/sale/workSalesForecast/index",
      //     name: "企业销量预测",
      //     component: () => import('@/views/sale/workSalesForecast/index'),
      //     meta: {
      //         hidden: 0,
      //         acName: "预测"
      //     },
      // },
      // {
      //     path: "/sale/report/detail/:id",
      //     name: "分析报告详情",
      //     component: () => import('@/views/sale/report/detail'),
      //     meta: {
      //         hidden: 1,
      //         breadcrumb: [
      //             {name: "分析报告列表", url: '/sale/report/index'},
      //             {name: "分析报告内容"},
      //         ]
      //     },
      // },
      // {
      //     path: "/sale/report/index",
      //     name: "分析报告",
      //     component: () => import('@/views/sale/report/index'),
      //     meta: {
      //         hidden: 0,
      //         acName: "预测"
      //     },
      // }
    ]
  },
  {
    path: '/marketInsight/insightReport',
    name: '市场洞察',
    component: Layout,
    redirect: '/marketInsight/insightReport',
    meta: {
      hidden: 0,
      icon: require('@/assets/img/jsmf.png')
    },
    children: [
      {
        path: '/marketInsight/index',
        name: '首页概览',
        component: () => import('@/views/marketInsight/insightReport'),
        meta: {
          hidden: 1,
          acName: '市场洞察'
        }
      },

      {
        path: '/marketInsight/detail/marketDynamicDetail',
        name: '市场动态详情',
        component: () =>
          import('@/views/marketInsight/detail/marketDynamicDetail'),
        meta: {
          hidden: 1,
          acName: '市场洞察',
          breadcrumb: [
            { name: '市场洞察', url: '/marketInsight/index' },
            // { name: '市场动态', url: '/marketInsight/marketDynamic' },
            { name: '市场动态详情' }
          ]
        }
      },
      {
        path: '/marketInsight/insightReport',
        name: '洞察报告',
        component: () => import('@/views/marketInsight/insightReport'),
        meta: {
          hidden: 1,
          acName: '市场洞察'
        }
      },
      {
        path: '/marketInsight/search',
        name: '洞察报告查询',
        component: () => import('@/views//marketInsight/search'),
        meta: {
          hidden: 1,
          acName: '市场洞察'
        },
        children: [
          {
            path: '/marketInsight/detail/insightReportDetail/:id',
            name: '洞察报告详情',
            component: () =>
              import('@/views/marketInsight/detail/insightReportDetail'),
            meta: {
              hidden: 1,
              acName: '市场洞察',
              breadcrumb:
                                process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
                                  ? [
                                    { name: '市场洞察', url: '/marketInsight/index' },
                                    // { name: '洞察报告查询', url: '/marketInsight/search' },
                                    { name: '洞察报告详情' }
                                  ]
                                  : [
                                    { name: '市场洞察', url: '/marketInsight/index' },
                                    { name: '洞察报告详情' }
                                  ]
            }
          }
        ]
      },
      {
        path: '/marketInsight/marketDynamic',
        name: '市场动态',
        component: () => import('@/views/marketInsight/marketDynamic'),
        meta: {
          hidden: 0,
          acName: '市场洞察'
        }
      }
    ]
  },

  {
    path: '/international/Information/index',
    name: '国际化',
    component: Layout,
    redirect: '/international/Information/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/international/Information/index',
        name: '国际资讯',
        component: () => import('@/views/international/Information/index'),
        meta: {
          hidden: 0,
          acName: '国际化',
          icon: 'el-icon-s-help'
        }
      },
      {
        path: '/international/Information/list',
        name: '国际资讯列表',
        component: () => import('@/views/international/Information/list'),
        meta: {
          hidden: 2,
          acName: '国际化',
          breadcrumb: [
            {
              name: '国际资讯',
              url: '/international/Information/index'
            },
            {
              name: '国际资讯列表',
              url: '/international/Information/list'
            },
            { name: '国际新闻详情' }
          ]
        }
      },
      {
        path: '/international/details/:id',
        name: '国际资讯详情',
        component: () => import('@/views/international/common/details'),
        meta: {
          hidden: 1,
          acName: '国际化',
          breadcrumb: [
            {
              name: '国际资讯',
              url: '/international/Information/index'
            },
            { name: '国际新闻详情' }
          ]
        }
      },
      {
        path: '/international/policy/index',
        name: '国际政策',
        component: () => import('@/views/international/policy/index'),
        meta: {
          hidden: getUserInfo() && getUserInfo().userId == 91141 ? 1 : 0,
          acName: '国际化',
          icon: 'el-icon-s-cooperation'
        },
      },
      {
        path: '/international/policy/detail/:id',
        name: '国际政策',
        component: () => import('@/views/international/policy/contentNewPolicy'),
        meta: {
          hidden: 1,
          acName: '政策',
          breadcrumb: [
            { name: '国际政策', url: '/international/policy/index' },
            { name: '政策内容页' }
          ]
        }
      },
      {
        path: '/international/standard/index',
        name: '国际法规',
        component: () => import('@/views/international/standard/index'),
        meta: {
          hidden: getUserInfo() && getUserInfo().userId == 91141 ? 1 : 0,
          acName: '国际化',
          icon: 'el-icon-s-opportunity'
        },
      },

      {
        path: '/international/standard/detail/:id',
        name: '国际法规',
        component: () =>
          import('@/views/standards/dynamic/contentttStandard'),
        meta: {
          hidden: 1,
          acName: '标准',
          breadcrumb: [
            { name: '国际法规', url: '/international/standard/index' },
            { name: '标准内容页' }
          ]
        }
      },

      {
        path: '/international/globalDataOverview/index',
        name: '国际数据',
        component: () => import('@/layout/gjhLayout'),
        meta: {
          hidden: getUserInfo() && getUserInfo().userId == 81054090 ? 1 : 0,
          acName: '国际化',
          icon: 'el-icon-s-marketing'
        },
        children: [
          {
            path: '/international/globalDataOverview/index',
            name: '数据地图',
            component: () =>
              import('@/views/international/globalDataOverview/index'),
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          {
            path:
                            '/international/chinaAutomotiveImportAndExportData/chinaExportData',
            name: '中国汽车出口数据',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'chinaExportData'
            },
            meta: {
              hidden: 0,
              acName: '国际化',
              img: 'gjhicon.png'
            }
          },

          {
            path:
                            '/international/productionDataByCountry/globalAutoIndustryProduction',
            name: '分国别产量数据',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'globalAutoIndustryProduction'
            },
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          {
            path: '/international/saleDataByCountry/globalAutoIndustrySales',
            name: '分国别整体销量数据',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'globalAutoIndustrySales'
            },
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          {
            path:
                            '/international/countrySpecificEconomicData/globalAutoIndustryNewEnergySales',
            name: '分国别新能源销量数据',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'globalAutoIndustryNewEnergySales'
            },
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          {
            path:
              '/international/countrySpecificEconomicData/newGlobalEconomyData',
            name: '全球宏观经济数据',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'newGlobalEconomyData'  //影响表格上方数据说明的显示，期望不要修改
            },
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          // {
          //   path:
          //                   '/international/countrySpecificEconomicData/globalEconomyData',
          //   name: '分国别经济数据',
          //   component: () => import('@/views/newData/draggingQuery'),
          //   props: {
          //     draggingTableName: 'globalEconomyData'
          //   },
          //   meta: {
          //     hidden: 0,
          //     acName: '国际化'
          //   }
          // },
          {
            path:
              '/international/countrySpecificEconomicData/roroshipPrice',
            name: '滚装船价格',
            component: () => import('@/views/newData/draggingQuery'),
            props: {
              draggingTableName: 'roroshipPrice'  //影响表格上方数据说明的显示，期望不要修改
            },
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },
          // {
          //   path:
          //     '/international/factoryPartsDistributor/index',
          //   name: '全球工厂及零部件布局',
          //   component: () => import('@/views/newData/factoryPartsDistributor/index'),
          //   meta: {
          //     hidden: 0,
          //     acName: '国际化'
          //   }
          // },
          {
            path:
              '/international/dealer/index',
            name: '全球经销商网络布局',
            component: () => import('@/views/newData/factoryPartsDistributor/index2'),
            meta: {
              hidden: 0,
              acName: '国际化'
            }
          },

        ]
      },
      {
        path: '/international/factoryPartsDistributor/info/:id',
        name: '工厂详情',
        component: () => import('@/views/newData/factoryPartsDistributor/info'),
        meta: {
          hidden: 1,
          acName: '国际化',
          breadcrumb: [
            { name: '工厂/经销商/零部件数据', url: '/international/factoryPartsDistributor/index' },
            { name: '工厂详情' }
          ]
        }
      },
      {
        path: '/international/factoryPartsDistributor/info1/:id',
        name: '零部件详情',
        component: () => import('@/views/newData/factoryPartsDistributor/info1'),
        meta: {
          hidden: 1,
          acName: '国际化',
          breadcrumb: [
            { name: '工厂/经销商/零部件数据', url: '/international/factoryPartsDistributor/index' },
            { name: '零部件详情' }
          ]
        }
      },
      {
        path: '/international/researchReport/index',
        name: '研究报告',
        component: () => import('@/views/international/researchReport/list'),
        meta: {
          hidden: getUserInfo() && getUserInfo().userId == 91141 ? 1 : 0,
          acName: '国际化',
          icon: 'el-icon-s-data'
        }
      },
      {
        path: '/international/researchReport/details/:id',
        name: '研究报告详情',
        component: () =>
          import('@/views/international/common/researchReportDetails'),
        meta: {
          hidden: 1,
          acName: '国际化',
          breadcrumb: [
            {
              name: '研究报告',
              url: '/international/researchReport/index'
            },
            { name: '研究报告详情' }
          ]
        }
      },
      {
        path: '/international/researchReport/list',
        name: '研究报告',
        component: () => import('@/views/international/researchReport/list'),
        meta: {
          hidden: 2,
          show: true,
          acName: '国际化',
          icon: 'el-icon-s-opportunity',
          breadcrumb: [
            {
              name: '研究报告',
              url: '/international/researchReport/index'
            },
            {
              name: '研究报告',
              url: '/international/researchReport/list'
            },
            { name: '研究报告详情' }
          ]
        }
      }
    ]
  },
  {
    path: '/usedCar/usedCarReport',
    name: '二手车',
    component: Layout,
    redirect: '/usedCar/usedCarReport',
    meta: {
      hidden: 0,
      icon: require('@/assets/img/hsmf.png')
    },
    children: [
      {
        path: '/usedCar/usedCarReport',
        name: '二手车报告',
        component: () => import('@/views/usedCar/usedCarReport'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-order'
        }
      },
      {
        path: '/usedCar/reportOne',
        name: '总体市场表现分析',
        component: () => import('@/views/usedCar/reportOne'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },
      {
        path: '/usedCar/reportTwo',
        name: '市场细分领域分析',
        component: () => import('@/views/usedCar/reportTwo'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },
      {
        path: '/usedCar/reportThree',
        name: '企业车型交易情况分析',
        component: () => import('@/views/usedCar/reportThree'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },
      {
        path: '/usedCar/reportFour',
        name: '区域分布情况分析',
        component: () => import('@/views/usedCar/reportFour'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },
      {
        path: '/usedCar/reportFive',
        name: '交易价格区间分析',
        component: () => import('@/views/usedCar/reportFive'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },

      {
        path: '/usedCar/reportSix',
        name: '使用年限区间分析',
        component: () => import('@/views/usedCar/reportSix'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },
      // {
      //   path: "/usedCar/reportDefined",
      //   name: "自定义分析",
      //   component: () => import('@/views/usedCar/reportDefined'),
      //   meta: {
      //     hidden: 0,
      //     acName: "二手车",
      //     icon: "el-icon-s-data"
      //   }
      // } ,
      {
        path: '/usedCar/reportDefinedSearch',
        name: '自定义分析查询',
        component: () => import('@/views/usedCar/reportDefinedSearch'),
        meta: {
          hidden: 0,
          acName: '二手车',
          icon: 'el-icon-s-data'
        }
      },

      {
        path: '/usedCar/search',
        name: '二手车查询',
        component: () => import('@/views//usedCar/search'),
        meta: {
          hidden: 1,
          acName: '二手车'
        },
        children: [
          {
            path: '/usedCar/detail/usedCarReportDetail/:id',
            name: '二手车报告详情',
            component: () =>
              import('@/views/usedCar/detail/usedCarReportDetail'),
            meta: {
              hidden: 1,
              acName: '二手车',
              breadcrumb:
                                process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
                                  ? [
                                    { name: '二手车', url: '/usedCar/usedCarReport' },
                                    // { name: "二手车报告查询", url: '/usedCar/search' },
                                    { name: '二手车报告详情' }
                                  ]
                                  : [
                                    { name: '二手车', url: '/usedCar/usedCarReport' },
                                    { name: '二手车报告详情' }
                                  ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/workInformation',
    name: '企业情报',
    redirect: '/workInformation/survey/index',
    component: Layout,
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/workInformation/survey/index',
        name: '概览',
        component: () => import('@/views/workInformation/survey/index.vue'),
        meta: {
          hidden: 1,
          acName: '企业情报'
        }
      },
      {
        path: 'enterpriseInfo',
        redirect: 'enterpriseInfo/list',
        name: '企业信息',
        component: () => import('@/views/workInformation/enterpriseInfo/index.vue'),
        children: [
          {
            path: 'list',
            name: 'contribute-list',
            component: () => import('@/views/workInformation/enterpriseInfo/list/index.vue'),
            meta: {
              hidden: 1,
              acName: '企业信息列表'
            }
          },
          {
            path: 'detail/:id',
            name: 'contribute-info',
            component: () => import('@/views/workInformation/enterpriseInfo/detail/index.vue'),
            meta: {
              hidden: 1,
              acName: '企业信息详情',
              breadcrumb: [
                { name: '企业信息', url: '/workInformation/enterpriseInfo/list' },
                { name: '企业详情' }
              ]
            },
            props: true
          },
          {
            path: 'news',
            name: 'contribute-news',
            component: () => import('@/views/workInformation/enterpriseInfo/entrepriseNews/index.vue'),
            meta: {
              hidden: 1,
              acName: '企业信息详情',
              breadcrumb: [
                { name: '企业信息', url: '/workInformation/enterpriseInfo/list' },
                { name: '企业动态' }
              ]
            },
            props: true
          },
          {
            path: '/workInformation/enterpriseInfo/entrepriseNews/enterDetail/:id',
            name: '企业情报行业资讯内容页',
            component: () => import('@/views/workInformation/enterpriseInfo/entrepriseNews/enterDetail.vue'),
            meta: {
              hidden: 1,
              acName: '企业信息详情',
              breadcrumb: [
                { name: '企业信息', url: '/workInformation/enterpriseInfo/list' },
                { name: '企业动态详情' }
              ]
            }
          },
          {
            path: 'compared',
            name: 'contribute-compared',
            component: () => import('@/views/workInformation/enterpriseInfo/compared/index.vue'),
            meta: {
              hidden: 1,
              acName: '企业对标报告',
              breadcrumb: [
                { name: '企业信息', url: '/workInformation/enterpriseInfo/list' },
                { name: '企业对比报告' }
              ]
            }
          }
        ]
      },
      {
        path: '/workInformation/analysis/index',
        name: '分析报告',
        component: () => import('@/views/workInformation/analysis/index'),
        meta: {
          hidden: 1,
          acName: '分析报告'
        },
        children: [
          {
            path: '/workInformation/analysis/analysisContent/:id',
            name: '企业分析详情',
            component: () =>
              import('@/views/workInformation/analysis/analysisContent'),
            meta: {
              hidden: 1,
              acName: '企业情报',
              breadcrumb: [
                {
                  name: '企业分析报告',
                  url: '/workInformation/analysis/index'
                },
                { name: '企业分析详情' }
              ]
            }
          }
        ]
      },
      {
        path: '/workInformation/industry/index',
        name: '产业布局',
        component: () => import('@/views/workInformation/industry/index.vue'),
        meta: {
          hidden: 1,
          acName: '产业布局'
        }
      },
      {
        path: '/workInformation/newcapacity/index',
        name: '产销产能',
        component: () => import('@/views/workInformation/newcapacity/index.vue'),
        meta: {
          hidden: 1,
          acName: '产销产能'
        }
      },
      {
        path: '/workInformation/financialReport/index',
        name: '财务报表',
        component: () => import('@/views/workInformation/financialReport/index.vue'),
        meta: {
          hidden: 1,
          acName: '财务报表'
        }
      },
      {
        path: '/workInformation/information/index',
        name: '企业信息',
        component: () => import('@/views/workInformation/information/index'),
        meta: {
          hidden: 0,
          acName: '企业情报'
        }
      },
      {
        path: '/workInformation/financialReport/index',
        name: '企业财报',
        component: () =>
          import('@/views/workInformation/financialReport/index'),
        meta: {
          hidden: 0,
          acName: '企业情报'
        }
      },
      {
        path: '/workInformation/capacity/index',
        name: '企业产能',
        component: () => import('@/views/workInformation/capacity/index'),
        meta: {
          hidden: 0,
          acName: '企业情报'
        }
      },
      {
        path: '/workInformation/information/workDetail',
        name: '企业信息详细',
        component: () =>
          import('@/views/workInformation/information/workDetail'),
        meta: {
          hidden: 0,
          acName: '企业情报'
        },
        children: [
          {
            path: '/workInformation/information/workDynamicList',
            name: '公司动态列表',
            component: () =>
              import('@/views/workInformation/information/workDynamicList'),
            meta: {
              hidden: 1,
              acName: '企业情报',
              breadcrumb: [
                { name: '企业情报', url: '/workInformation/analysis/index' },
                { name: '企业信息', url: '/workInformation/information/index' },
                {
                  name: '企业信息详细',
                  url: '/workInformation/information/workDetail'
                },
                { name: '公司动态列表' }
              ]
            },
            children: [
              {
                path: '/workInformation/information/workDynamicDetail',
                name: '公司动态内容页',
                component: () =>
                  import(
                    '@/views/workInformation/information/workDynamicDetail'
                  ),
                meta: {
                  hidden: 1,
                  acName: '企业情报',
                  breadcrumb: [
                    {
                      name: '企业情报',
                      url: '/workInformation/analysis/index'
                    },
                    {
                      name: '企业信息',
                      url: '/workInformation/information/index'
                    },
                    {
                      name: '企业信息详细',
                      url: '/workInformation/information/workDetail'
                    },
                    {
                      name: '公司动态列表',
                      url: '/workInformation/information/workDynamicList'
                    },
                    { name: '公司动态内容页' }
                  ]
                }
              }
            ]
          },
          {
            path: '/workInformation/information/historyInfo',
            name: '高管历史',
            component: () =>
              import('@/views/workInformation/information/historyInfo'),
            meta: {
              hidden: 1,
              acName: '企业情报'
            }
          }
        ]
      }
    ]
  },

  {
    path: '/workInformation/syc/analysis/index',
    name: '商用车企业情报',
    redirect: '/workInformation/syc/analysis/index',
    component: Layout,
    meta: {
      hidden: 1
    },
    children: [
      {
        path: '/workInformation/syc/analysis/index',
        name: '企业分析报告',
        component: () => import('@/views/workInformation/syc/analysis/index'),
        meta: {
          hidden: 0,
          acName: '商用车企业情报'
        },
        children: [
          {
            path: '/workInformation/syc/analysis/analysisContent/:id',
            name: '企业分析详情',
            component: () =>
              import('@/views/workInformation/syc/analysis/analysisContent'),
            meta: {
              hidden: 1,
              acName: '商用车企业情报',
              breadcrumb: [
                {
                  name: '企业分析报告',
                  url: '/workInformation/syc/analysis/index'
                },
                { name: '企业分析详情' }
              ]
            }
          }
        ]
      },
      // {
      //   path: "/workInformation/information/index",
      //   name: "企业信息",
      //   component: () => import('@/views/workInformation/information/index'),
      //   meta: {
      //     hidden: 0,
      //     acName: "商用车企业情报"
      //   }
      // },

      {
        path: '/workInformation/syc/information/workDetail',
        name: '企业信息',
        component: () =>
          import('@/views/workInformation/syc/information/workDetail'),
        meta: {
          hidden: 0,
          acName: '商用车企业情报'
        },
        children: [
          {
            path: '/workInformation/syc/information/workDynamicList',
            name: '公司动态列表',
            component: () =>
              import('@/views/workInformation/syc/information/workDynamicList'),
            meta: {
              hidden: 1,
              acName: '商用车企业情报',
              breadcrumb: [
                {
                  name: '企业信息',
                  url: '/workInformation/syc/information/workDetail'
                },
                { name: '公司动态列表' }
              ]
            },
            children: [
              {
                path: '/workInformation/syc/information/workDynamicDetail',
                name: '公司动态内容页',
                component: () =>
                  import(
                    '@/views/workInformation/syc/information/workDynamicDetail'
                  ),
                meta: {
                  hidden: 1,
                  acName: '商用车企业情报',
                  breadcrumb: [
                    {
                      name: '企业信息',
                      url: '/workInformation/syc/information/workDetail'
                    },
                    {
                      name: '公司动态列表',
                      url: '/workInformation/syc/information/workDynamicList'
                    },
                    { name: '公司动态内容页' }
                  ]
                }
              }
            ]
          },
          {
            path: '/workInformation/syc/information/historyInfo',
            name: '高管历史',
            component: () =>
              import('@/views/workInformation/syc/information/historyInfo'),
            meta: {
              hidden: 1,
              acName: '商用车企业情报',
              breadcrumb: [
                {
                  name: '企业信息',
                  url: '/workInformation/syc/information/workDetail'
                },
                { name: '高管历史' }
              ]
            }
          }
        ]
      },
      {
        path: '/workInformation/syc/financialReport/index',
        name: '企业财报',
        component: () =>
          import('@/views/workInformation/syc/financialReport/index'),
        meta: {
          hidden: 0,
          acName: '商用车企业情报'
        }
      },
      {
        path: '/workInformation/syc/capacity/index',
        name: '企业产能',
        component: () => import('@/views/workInformation/syc/capacity/index'),
        meta: {
          hidden: 0,
          acName: '商用车企业情报'
        }
        // children: [
        //     {
        //         path: "/workInformation/syc/capacity/indexGl",
        //         name: "产能分布概览",
        //         component: () => import('@/views/workInformation/syc/capacity/indexGl'),
        //         meta: {
        //             hidden: 0,
        //             acName: "商用车企业情报"
        //         },
        //     },
        //     {
        //         path: "/workInformation/syc/capacity/index",
        //         name: "产能分布详细",
        //         component: () => import('@/views/workInformation/syc/capacity/indexGl'),
        //         meta: {
        //             hidden: 0,
        //             acName: "商用车企业情报"
        //
        //         },
        //     }]
      }
    ]
  },

  {
    path: '/thematicAnalysis/index',
    name: '专题分析',
    component: Layout,
    redirect: '/thematicAnalysis/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/thematicAnalysis/index',
        name: '专题分析查询列表',
        component: () =>
          process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
            ? import('@/views/thematicAnalysis/index')
            : import('@/views/thematicAnalysis/indexfuao'),
        // component: () => import('@/views/thematicAnalysis/index'),
        meta: {
          hidden: 1,
          acName: '专题分析',
          keepAlive: true
        }
      },
      {
        path: '/thematicAnalysis/search',
        name: '分析查询',
        component: () => import('@/views/thematicAnalysis/search'),
        meta: {
          hidden: 1,
          acName: '专题分析'
        },
        children: [
          {
            path: '/thematicAnalysis/details/:id',
            name: '专题分析详情',
            component: () => import('@/views/thematicAnalysis/details'),
            meta: {
              hidden: 1,
              acName: '专题分析',
              breadcrumb:
                                process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
                                  ? [
                                    { name: '专题分析', url: '/thematicAnalysis/index' },
                                    // { name: '专题分析查询', url: '/thematicAnalysis/search' },
                                    { name: '专题分析详情' }
                                  ]
                                  : [
                                    { name: '专题分析', url: '/thematicAnalysis/index' },
                                    { name: '专题分析详情' }
                                  ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/thematicAnalysis/syc/index',
    name: '商用车洞察报告',
    component: Layout,
    redirect: '/thematicAnalysis/syc/index',
    meta: {
      hidden: 1
    },
    children: [
      {
        path: '/thematicAnalysis/syc/index',
        name: '洞察报告查询列表',
        component: () => import('@/views/thematicAnalysis/syc/index'),
        // component: () => import('@/views/thematicAnalysis/index'),
        meta: {
          hidden: 1,
          acName: '商用车洞察报告',
          keepAlive: true
        }
      },
      {
        path: '/thematicAnalysis/syc/search',
        name: '洞察报告查询',
        component: () => import('@/views/thematicAnalysis/syc/search'),
        meta: {
          hidden: 1,
          acName: '商用车洞察报告'
        },
        children: [
          {
            path: '/thematicAnalysis/syc/details/:id',
            name: '洞察报告详情',
            component: () => import('@/views/thematicAnalysis/syc/details'),
            meta: {
              hidden: 1,
              acName: '商用车洞察报告',
              breadcrumb: [
                { name: '洞察报告', url: '/thematicAnalysis/syc/index' },
                { name: '洞察报告查询', url: '/thematicAnalysis/syc/search' },
                { name: '洞察报告详情' }
              ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/cuttingEdgeTechnology/index',
    component: Layout,
    name: '前沿技术',
    redirect: '/cuttingEdgeTechnology/index',
    meta: {
      hidden: 0
    },
    children: [
      {
        path: '/cuttingEdgeTechnology/index',
        name: '前沿技术查询列表',
        component: () =>
          process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
            ? import('@/views/cuttingEdgeTechnology/index')
            : import('@/views/cuttingEdgeTechnology/indexfuao'),
        meta: {
          hidden: 1,
          acName: '前沿技术',
          keepAlive: true
        }
      },
      {
        path: '/cuttingEdgeTechnology/search',
        name: '技术查询',
        component: () => import('@/views/cuttingEdgeTechnology/search'),
        meta: {
          hidden: 1,
          acName: '前沿技术'
        },
        children: [
          {
            path: '/cuttingEdgeTechnology/details/:id',
            name: '前沿技术详情',
            component: () => import('@/views/cuttingEdgeTechnology/details'),
            meta: {
              hidden: 1,
              acName: '前沿技术',
              breadcrumb:
                                process.env.VUE_APP_CHECKLOGIN == 'zgqcgyxxw'
                                  ? [
                                    { name: '前沿技术', url: '/cuttingEdgeTechnology/index' },
                                    // {
                                    //   name: '前沿技术技术查询',
                                    //   url: '/cuttingEdgeTechnology/search'
                                    // },
                                    { name: '前沿技术详情' }
                                  ]
                                  : [
                                    { name: '前沿技术', url: '/cuttingEdgeTechnology/index' },
                                    { name: '前沿技术详情' }
                                  ]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/strategyReport/index',
    component: StrategyReport,
    name: '战略报告',
    redirect: '/strategyReport/index',
    meta: {
      hidden: 1
    },
    children: [
      {
        path: '/strategyReport/login',
        name: '战略报告登录',
        component: () => import('@/views/strategyReport/login'),
        meta: {
          hidden: 1,
          acName: '战略报告',
          keepAlive: true
        }
      },
      {
        path: '/strategyReport/index',
        name: '战略报告目录',
        component: () => import('@/views/strategyReport/index'),
        meta: {
          hidden: 1,
          acName: '战略报告',
          keepAlive: true
        }
      },
      {
        path: '/strategyReport/search',
        name: '战略报告搜索',
        component: () => import('@/views/strategyReport/search'),
        meta: {
          hidden: 1,
          acName: '战略报告',
          keepAlive: true
        }
      },
      {
        path: '/strategyReport/details/:id',
        name: '战略报告详情',
        component: () => import('@/views/strategyReport/details'),
        meta: {
          hidden: 1,
          acName: '战略报告',
          keepAlive: true
        }
      }
    ]
  }
]

// 旧版数据栏目的路由
export const oldDataRoutes = [
  {
    path: '/data/data/index',
    name: '数据概览',
    component: () => import('@/views/data/data/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/domesticData/index1',
    name: '国内产销数据',
    component: () => import('@/views/data/domesticData/index1'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  // {
  //   path: '/data/domesticData/index',
  //   name: '国内产销数据',
  //   component: () => import('@/views/data/domesticData/index'),
  //   meta: {
  //     hidden: 0,
  //     acName: '数据'
  //   }
  // },
  {
    path: '/data/cycCar/index',
    name: '乘用车分车型',
    component: () => import('@/views/data/cycCar/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/exportData/index',
    name: '出口数据',
    component: () => import('@/views/data/exportData/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/carOwnership/index',
    name: '汽车保有量',
    component: () => import('@/views/data/carOwnership/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/indicators/index',
    name: '经济指标',
    component: () => import('@/views/data/indicators/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/productionRegion/index',
    name: '分地区产量',
    component: () => import('@/views/data/productionRegion/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/engineData/index',
    name: '发动机数据',
    component: () => import('@/views/data/engineData/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/foreignSalesData/index',
    name: '世界汽车工业',
    component: () => import('@/views/data/foreignSalesData/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/recommendedModels/index',
    name: '推荐车型目录',
    component: () => import('@/views/data/recommendedModels/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/exemptionFromPurchaseTax/index',
    name: '免征购置税目录',
    component: () => import('@/views/data/exemptionFromPurchaseTax/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/roadTransportVehicle/index',
    name: '道路运输车辆达标车型',
    component: () => import('@/views/data/roadTransportVehicle/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/macroEnvironment/index',
    name: '宏观环境',
    component: () => import('@/views/data/macroEnvironment/index'),
    meta: {
      hidden: 0,
      acName: '数据'
    }
  },
  {
    path: '/data/historicalData/index',
    name: '历史数据',
    component: () => import('@/views/data/historicalData/index'),
    meta: {
      hidden: 1,
      acName: '数据'
    }
  }
]

// 新版数据栏目-数据查询 的所有非拖拉拽页面的路由配置
export const dataQueryConstantRoutesMap = {
  '新能源专区-充电基础设施': {
    path: '/data/newEnergyZone/chargingInfrastructure',
    component: () =>
      import('@/views/newData/newEnergyZone/chargingInfrastructure')
  },
  '产品价格-原材料价格': {
    path: '/data/vehicleModelPrice/materialPrice',
    component: () => import('@/views/newData/vehicleModelPrice/materialPrice')
  },
  '经济指标-经济指标': {
    path: '/data/associationEconomicIndicators',
    component: () => import('@/views/newData/associationEconomicIndicators')
  },
  '车型目录-推荐车型目录': {
    path: '/data/recommendedVehicleCatalog/recommendedVehicleCatalog',
    component: () =>
      import(
        '@/views/newData/recommendedVehicleCatalog/recommendedVehicleCatalog'
      )
  },
  '车型目录-免征购置税目录': {
    path: '/data/recommendedVehicleCatalog/exemptionPurchaseTaxCatalog',
    component: () =>
      import(
        '@/views/newData/recommendedVehicleCatalog/exemptionPurchaseTaxCatalog/index.vue'
      )
  },
  '车型目录-减免购置税目录': {
    path: '/data/recommendedVehicleCatalog/exemptionPurchaseTaxCatalog2',
    component: () =>
      import(
        '@/views/newData/recommendedVehicleCatalog/exemptionPurchaseTaxCatalog2'
      )
  },
  '车型目录-道路运输车辆达标车型': {
    path: '/data/recommendedVehicleCatalog/roadTransportVehicleModels',
    component: () =>
      import(
        '@/views/newData/recommendedVehicleCatalog/roadTransportVehicleModels'
      )
  },
  '宏观环境-宏观环境': {
    path: '/data/environment',
    component: () => import('@/views/newData/environment')
  },
  '分地区销量-分地区终端销量': {
    path: '/data/salesData',
    component: () => import('@/views/newData/salesData')
  },
  '新能源配套数据-三电配套': {
    path: '/data/threeElectricalSupportingFacilities',
    component: () =>
      import('@/views/newData/threeElectricalSupportingFacilities')
  }
}

// 新版数据栏目-数据报告 的路由
export const dataReportConstantRoute = {
  path: '/data/dataReport',
  name: '数据报告',
  // component: NewDataLayout,
  component: RouterView,
  redirect: '/data/dataReport/overview',
  meta: {
    activeIcon: 'report-active',
    deactiveIcon: 'report-deactive',
    // 用于 dataLayout 页面获取所有的模块导航
    moduleName: '数据报告'
  },
  children: [
    {
      path: '/data/dataReport/overview',
      name: '数据概览',
      component: () => import('@/views/newData/dataReport/overview'),
      meta: {
        // 这个 hidden 能控制左侧菜单是否展示，在 layout/index.vue 组件里能看到
        hidden: 1,
        acName: '数据'
      }
    },
    {
      path: '/data/dataReport/ppt',
      name: 'PPT报告',
      component: () => import('@/views/newData/dataReport/ppt'),
      meta: {
        // 这个 hidden 能控制左侧菜单是否展示，在 layout/index.vue 组件里能看到
        hidden: 1,
        acName: '数据'
      }
    }
  ]
}

// 新版数据栏目-数据表下载 的路由
export const dataTableDownloadConstantRoute = {
  path: '/data/dataTableDownload',
  name: '数据表下载',
  // component: NewDataLayout,
  component: RouterView,
  alwaysShow: false,
  redirect: '/data/dataTableDownload/index',
  meta: {
    activeIcon: 'table-download-active',
    deactiveIcon: 'table-download-deactive',
    // 用于 dataLayout 页面获取所有的模块导航
    moduleName: '数据表下载'
  },
  children: [
    {
      path: '/data/dataTableDownload/index',
      name: '数据表下载',
      component: () => import('@/views/newData/dataTableDownload'),
      meta: {
        // 这个 hidden 能控制左侧菜单是否展示，在 layout/index.vue 组件里能看到
        hidden: 1,
        acName: '数据'
      }
    }
  ]
}

const login = []
if (process.env.VUE_APP_CHECKLOGIN == 'fuao') {
  login.unshift(
    // 登录页面
    {
      path: '/login',
      component: Login,
      name: '登录',
      meta: {
        hidden: 0
      }
    }
  )
}

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    // routes: constantRoutes.concat(defaultRoutes)
    routes: login.concat(constantRoutes)
  })
const router = createRouter()

// 重置路由
export function resetRouter(newRoutes) {
  const newRouter = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: login.concat(newRoutes)
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
