<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-23 10:07:40
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-07 10:29:09
-->
<template>
  <PageContainer>
    <div class="sales-data-container">
      <not-logged-in-mask v-if="showNotLoggedInMask" />
      <unsubscribed-mask v-if="showUnsubscribedMask" />
      <div :class="['header', showMask ? 'show-mask' : '']">
        <div class="title-wrapper">
          {{ year }}年{{ month }}月终端数据
          <span class="sub-title"
            >提供更多时间、企业、车型等详细字段查询，可以<a
              @click="showContactUsDialog = true"
            >
              联系我们 </a
            >购买获取完整数据表</span
          >
        </div>
        <!-- 10.19号的下午，客户想要先把时间筛选去掉 -->
        <!-- <div class="">
          <span class="select-time">选择时间</span>
          <el-select
            size="mini"
            placeholder="请选择"
            style="width:100px"
            v-model="year"
            @change="dateChange"
          >
            <el-option
              v-for="(item, index) in yearData"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="year">年</span>
          <el-select
            v-model="month"
            size="mini"
            placeholder="请选择"
            style="width:100px"
            @change="initData"
          >
            <el-option
              v-for="(item, index) in monthData"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="month">月</span>
        </div> -->
      </div>
      <div :class="['overallProductionSalesBody', showMask ? 'show-mask' : '']">
        <el-row :gutter="24">
          <div class="card first-line">
            <card
              title="指标看板"
              exportId="needBasicData"
              @exportXls="exportXls"
            >
              <template slot="body">
                <bulletinBoard :data="indicatorBoardData" />
              </template>
            </card>
            <card
              title="区域饼图"
              sub-title="(辆)"
              style="margin-top:16px"
              exportId="needAreaData"
              @exportXls="exportXls"
              @exportSvg="exportSvg"
              btnId="needArea"
            >
              <template slot="body">
                <pieEchart :height="390" ref="needAreaEchart" />
              </template>
            </card>
          </div>
          <!-- 这里需要把 el-col 从 card 组件里拆出来，要不然上面的那个 el-col 里
          嵌套着 card 就形成了双层的 el-col，导致 el-col 的padding也出现了两次 -->
          <div class="top first-line">
            <card
              title="省份榜单"
              exportId="needProvinceData"
              @exportXls="exportXls"
            >
              <template slot="body">
                <top :height="636" roteShow :topNum="10" ref="needProvince" dataUnit="辆" />
              </template>
            </card>
          </div>
          <div class="top first-line">
            <card
              title="城市榜单"
              exportId="needCityData"
              @exportXls="exportXls"
            >
              <template slot="body">
                <top :height="636" roteShow :topNum="10" ref="needCity" dataUnit="辆" />
              </template>
            </card>
          </div>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <card
              class="bar-box"
              title="SUV各级别销量"
              style="margin-top:16px"
              :titlebtn="needSuvVehicleRankTitlebtn"
              exportId="needSuvVehicleRankData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needSuvVehicleRank"
              ref="needSuvVehicleRankCard"
            >
              <template slot="body">
                <barEchart ref="needSuvVehicleRankEchart" :height="332" />
              </template>
            </card>
          </el-col>
          <el-col :span="12">
            <card
              class="bar-box"
              title="MPV各级别销量"
              style="margin-top:16px"
              :titlebtn="needMpvVehicleRankTitlebtn"
              exportId="needMpvVehicleRankData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needMpvVehicleRank"
              ref="needMpvVehicleRankCard"
            >
              <template slot="body">
                <barEchart ref="needMpvVehicleRankEchart" :height="332" />
              </template>
            </card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <card
              class="bar-box"
              title="轿车各级别销量"
              style="margin-top:16px"
              :titlebtn="needCarVehicleRankTitlebtn"
              exportId="needCarVehicleRankData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needCarVehicleRank"
              ref="needCarVehicleRankCard"
            >
              <template slot="body">
                <barEchart ref="needCarVehicleRankEchart" :height="332" />
              </template>
            </card>
          </el-col>
          <el-col :span="12">
            <card
              class="bar-box"
              title="各细分类型销量"
              style="margin-top:16px"
              :titlebtn="needVehicleGenreTitlebtn"
              exportId="needVehicleGenreData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needVehicleGenre"
              ref="needVehicleGenreCard"
            >
              <template slot="body">
                <barEchart :height="332" ref="needVehicleGenreEchart" />
              </template>
            </card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <card
              class="bar-box"
              title="各派系销量"
              style="margin-top:16px"
              :titlebtn="needFactionsTitlebtn"
              exportId="needFactionsData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needFactions"
              ref="needFactionsCard"
            >
              <template slot="body">
                <barEchart :height="332" ref="needFactionsEchart" />
              </template>
            </card>
          </el-col>
          <el-col :span="12">
            <card
              class="bar-box"
              title="燃料类型"
              style="margin-top:16px"
              :titlebtn="needFuelTitlebtn"
              exportId="needFuelData"
              @exportXls="exportXls"
              @cardBtnchange="cardBtnchange"
              @exportSvg="exportSvg"
              btnId="needFuel"
              ref="needFuelCard"
            >
              <template slot="body">
                <barEchart :height="332" ref="needFuelEchart" />
              </template>
            </card>
          </el-col>
        </el-row>
      </div>
    </div>
    <ContactUsDialog dict-label="分地区销量" :show.sync="showContactUsDialog" />
  </PageContainer>
</template>

<script>
import card from "./common/card.vue"; // 卡片组件
import bulletinBoard from "./common/bulletinBoard.vue"; // 顶部数据看板
import barEchart from "./common/barEchart.vue"; // 柱状图
import pieEchart from "./common/pieEchart.vue"; // 饼图
import top from "./common/top.vue"; // top组件
import { NotLoggedInMask, UnsubscribedMask } from "@/components/newData/mask";
import { authMixin } from "@/mixin/newData/index.js";
import ContactUsDialog from "../commonComp/contactUsDialog.vue";
import { getUserInfo, openSy } from "@/utils/auth.js";
import { buryPoint } from '@/api/common'
import {
  getDisplayDataSaleData,
  zbQueryYear,
  ykbJjzbQueryMonth,
  exportSalesData,
} from "@/api/data_new.js";
import Utils from "./common/utils.js"; //工具类
export default {
  name: "SalesData",
  components: {
    NotLoggedInMask,
    UnsubscribedMask,
    ContactUsDialog,
    card, // 卡片组件
    bulletinBoard, // 顶部数据看板
    barEchart, // 柱状图
    top, // 排行榜组件
    pieEchart, // 饼图
  },
  mixins: [authMixin],
  data() {
    return {
      year: null, //选择年
      month: null, //选择月
      monthData: [],
      yearData: [], //年份选择范围
      indicatorBoardData: {}, //指标看板数据
      showContactUsDialog: false,
      // SUV各级别销量
      needSuvVehicleRankData: {},
      needSuvVehicleRankTitlebtn: [],
      // MPV各级别销量
      needMpvVehicleRankData: {},
      needMpvVehicleRankTitlebtn: [],
      // 轿车各级别销量
      needCarVehicleRankData: {},
      needCarVehicleRankTitlebtn: [],
      // // 各级别销量（已拆成上面三个）
      // needVehicleRankData: {},
      // needVehicleRankTitlebtn: [],
      // 各细分类型销量
      needVehicleGenreData: {},
      needVehicleGenreTitlebtn: [],
      // 各派系销量
      needFactionsData: {},
      needFactionsTitlebtn: [],
      // 燃料类型
      needFuelData: {},
      needFuelTitlebtn: [],
    };
  },
  mounted() {
    // 初始化年份
    zbQueryYear().then((res) => {
      if (res.ok) {
        this.yearData = res.data;
        this.year = res.data[0];
        this.ykbJjzbQueryMonth(this.year);
      }
    });
    // !!! 必须等年份和月份都获取到才能 初始化页面数据
    // // 初始化页面数据
    // this.initData();
  },
  computed: {
    getDateTim() {
      let parmaYear = this.year ? this.year : new Date().getFullYear();
      let parmaMonth = this.month ? this.month : new Date().getMonth() + 1;
      let month = parmaMonth;
      if (parmaMonth < 10) {
        parmaMonth = "0" + parmaMonth;
      }
      return {
        parmaData: parmaYear.toString() + parmaMonth.toString(),
        parmaYear: parmaYear,
        parmaMonth: month,
      };
    },
  },
  methods: {
    /**
     * @description: 初始化页面各个模块数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 09:21:26
     * @LastEditTime: Do not edit
     */
    initData() {
      // 获取指标看板数据
      this.getIndicatorBoardData();
      // 获取区域数据
      this.getNeedAreaData();
      // 获省份榜单数据
      this.getNeedProvinceData();
      // 获城市榜单数据
      this.getNeedCityData();
      // 获取SUV车型级别数据
      this.getBarEchartData("needSuvVehicleRank");
      // 获取MPV车型级别数据
      this.getBarEchartData("needMpvVehicleRank");
      // 获取轿车车型级别数据
      this.getBarEchartData("needCarVehicleRank");
      // // 获城车型级别数据（已拆成上面三个）
      // this.getBarEchartData("needVehicleRank");
      // 获各细分类型销量
      this.getBarEchartData("needVehicleGenre");
      // 获各派系销量
      this.getBarEchartData("needFactions");
      // 获燃料类型
      this.getBarEchartData("needFuel");
    },
    /**
     * @description: 初始化月份并获取数据
     * @param {*} year
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 09:15:04
     * @LastEditTime: Do not edit
     */

    ykbJjzbQueryMonth(year) {
      ykbJjzbQueryMonth({ year }).then((res) => {
        if (res.ok) {
          this.monthData = res.data;
          this.month = res.data[0];
          // !!! 必须等年份和月份都获取到才能 初始化页面数据
          this.initData();
        }
      });
    },
    /**
     * @description: 时间选择框切换事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 14:14:18
     * @LastEditTime: Do not edit
     */
    dateChange() {
      // 重新获取数据
      //
      this.ykbJjzbQueryMonth(this.year);

    },
    /**
     * @description: 获取指标看板数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 09:15:45
     * @LastEditTime: Do not edit
     */
    getIndicatorBoardData() {
      getDisplayDataSaleData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: "needBasicData",
      }).then((res) => {
        if (res.ok) {
          this.indicatorBoardData = res.data;
          this.indicatorBoardData.year = res.data.date.substring(0, 4);
          this.indicatorBoardData.month = res.data.date.substring(4, 6);
        }
      });
    },
    /**
     * @description: 获取区域数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 09:15:45
     * @LastEditTime: Do not edit
     */
    getNeedAreaData() {
      getDisplayDataSaleData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: "needAreaData",
      }).then((res) => {
        if (res.ok) {
          let echartData = [];
          res.data.areaKeyList.forEach((item, index) => {
            echartData.push({
              name: item,
              value: res.data.areaValueList[index],
            });
          });
          this.$refs.needAreaEchart.init(echartData);
        }
      });
    },
    /**
     * @description: 获省份榜单数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 09:15:45
     * @LastEditTime: Do not edit
     */
    getNeedProvinceData() {
      getDisplayDataSaleData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: "needProvinceData",
      }).then((res) => {
        if (res.ok) {
          let needProvinceData = [];
          let data = res.data;
          data.provinceKeyList.forEach((item, index) => {
            needProvinceData.push({
              name: item,
              value: data.provinceValueList[index],
              YoY: data.provinceYoyList[index],
              QoQ: data.provinceQoqList[index],
              rote:
                (data.provinceValueList[index] / data.provinceValueList[0]) *
                100,
            });
          });
          this.$refs.needProvince.init(needProvinceData);
        }
      });
    },
    /**
     * @description: 获城市榜单数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 10:28:59
     * @LastEditTime: Do not edit
     */
    getNeedCityData() {
      getDisplayDataSaleData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: "needCityData",
      }).then((res) => {
        if (res.ok) {
          let needCityData = [];
          let data = res.data;
          data.cityKeyList.forEach((item, index) => {
            needCityData.push({
              name: item,
              value: data.cityValueList[index],
              YoY: data.cityYoyList[index],
              QoQ: data.cityQoqList[index],
              rote: (data.cityValueList[index] / data.cityValueList[0]) * 100,
            });
          });
          this.$refs.needCity.init(needCityData);
        }
      });
    },
    /**
     * @description: 获城柱线混搭图数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 10:28:59
     * @LastEditTime: Do not edit
     */
    getBarEchartData(type) {
      getDisplayDataSaleData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: type + "Data", //"needVehicleRankData",
      }).then((res) => {
        if (res.ok) {
          let data = res.data;
          let btnType = "";
          switch (type) {
            case "needSuvVehicleRank":
              btnType = data.vehicleRankSuvVehicleGenreSelectionList[0];
              this.initTitleBtn(type, "vehicleRankSuvVehicleGenreSelectionList", data);
              this.initbarEchart(type, btnType, "vehicleRankSuvVehicleGenreDataList", data);
              break;
            case "needMpvVehicleRank":
              btnType = data.vehicleRankMpvVehicleGenreSelectionList[0];
              this.initTitleBtn(type, "vehicleRankMpvVehicleGenreSelectionList", data);
              this.initbarEchart(type, btnType, "vehicleRankMpvVehicleGenreDataList", data);
              break;
            case "needCarVehicleRank":
              btnType = data.vehicleRankCarVehicleGenreSelectionList[0];
              this.initTitleBtn(type, "vehicleRankCarVehicleGenreSelectionList", data);
              this.initbarEchart(type, btnType, "vehicleRankCarVehicleGenreDataList", data);
              break;
            // case "needVehicleRank":
            //   btnType = data.vehicleRankSelectionList[0];
            //   this.initTitleBtn(type, "vehicleRankSelectionList", data);
            //   this.initbarEchart(type, btnType, "vehicleRankDataList", data);
            //   break;
            case "needVehicleGenre":
              btnType = data.vehicleGenreSelectionList[0];
              this.initTitleBtn(type, "vehicleGenreSelectionList", data);
              this.initbarEchart(type, btnType, "vehicleGenreDataList", data);
              break;
            case "needFactions":
              btnType = data.factionsSelectionList[0];
              this.initTitleBtn(type, "factionsSelectionList", data);
              this.initbarEchart(type, btnType, "factionsDataList", data);
              break;
            case "needFuel":
              btnType = data.fuelSelectionList[0];
              this.initTitleBtn(type, "fuelSelectionList", data);
              this.initbarEchart(type, btnType, "fuelDataList", data);
              break;
          }
          this[type + "Data"] = JSON.parse(JSON.stringify(data));
        }
      });
    },
    /**
     * @description: 渲染主线混搭图切花按钮数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 10:53:23
     * @LastEditTime: Do not edit
     */
    initTitleBtn(type, titleListKey, data) {
      this[type + "Titlebtn"] = [];
      data[titleListKey].forEach((item, index) => {
        this[type + "Titlebtn"].push({
          title: item,
          isClick: !index ? 1 : 0,
          id: index + 1,
        });
      });
      this.$refs[type + "Card"].initbtnList(this[type + "Titlebtn"]);
    },
    /**
     * @description: 渲染主线混搭图数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 10:52:24
     * @LastEditTime: Do not edit
     */

    initbarEchart(type, btnType, dataKey, data) {
      let xAxisData = [];
      let seriesData = [];
      let legendData = ["销售量", "同比", "环比"];
      let filterData = [];
      let echartData = JSON.parse(JSON.stringify(data));
      let y0Max = 0
      let y0Min = 0
      let y1Max = 0
      let y1Min = 0
      filterData = echartData[dataKey].filter((item) => {
        return item.name === btnType;
      });
      if (filterData.length) {
        y0Max = Math.max(...filterData[0].valueList, y0Max)
        y0Max = Math.ceil(y0Max / 10) * 10
        y0Min = Math.min(...filterData[0].valueList, y0Min)
        y0Min = Math.floor(y0Min / 10) * 10

        y1Max = Math.max(...filterData[0].qoqList, ...filterData[0].yoyList)
        y1Max = Math.ceil(y1Max / 10) * 10
        y1Min = Math.min(...filterData[0].qoqList, ...filterData[0].yoyList)
        y1Min = Math.floor(y1Min / 10) * 10
        xAxisData = filterData[0].keyList.reverse().map((item) => {
          return Utils.insertStr(item, 4, ".");
        });
        seriesData = [
          {
            name: "销售量",
            type: "bar",
            color: "#165DFF",
            data: filterData[0].valueList.reverse(),
          },
          {
            name: "环比",
            type: "line",
            color: "#14C9C9",
            yAxisIndex: 1,
            data: filterData[0].qoqList.reverse(),
          },
          {
            name: "同比",
            type: "line",
            color: "#F7BA1E",
            yAxisIndex: 1,
            data: filterData[0].yoyList.reverse(),
          },
        ];
      }
      this.$refs[type + "Echart"].init({
        xAxisData: xAxisData,
        seriesData: seriesData,
        legendData: legendData,
        y0Max,
        y0Min,
        y1Max,
        y1Min
      });
    },
    /**
     * @description: 卡片按钮切换事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 13:45:30
     * @LastEditTime: Do not edit
     */
    cardBtnchange(e) {
      switch (e.type) {
        case "needSuvVehicleRank": // suv各级别销量
          this.initbarEchart(
            "needSuvVehicleRank",
            e.data.title,
            "vehicleRankSuvVehicleGenreDataList",
            this.needSuvVehicleRankData
          );
          break;
        case "needMpvVehicleRank": // mpv各级别销量
          this.initbarEchart(
            "needMpvVehicleRank",
            e.data.title,
            "vehicleRankMpvVehicleGenreDataList",
            this.needMpvVehicleRankData
          );
          break;
        case "needCarVehicleRank": // 轿车各级别销量
          this.initbarEchart(
            "needCarVehicleRank",
            e.data.title,
            "vehicleRankCarVehicleGenreDataList",
            this.needCarVehicleRankData
          );
          break;
        // case "needVehicleRank": //各级别销量（已经拆成上面三个）
        //   this.initbarEchart(
        //     "needVehicleRank",
        //     e.data.title,
        //     "vehicleRankDataList",
        //     this.needVehicleRankData
        //   );
        //   break;
        case "needVehicleGenre": //各细分类型销量
          this.initbarEchart(
            "needVehicleGenre",
            e.data.title,
            "vehicleGenreDataList",
            this.needVehicleGenreData
          );
          break;
        case "needFactions": //各派系销量
          this.initbarEchart(
            "needFactions",
            e.data.title,
            "factionsDataList",
            this.needFactionsData
          );
          break;
        case "needFuel": //燃料类型
          this.initbarEchart(
            "needFuel",
            e.data.title,
            "fuelDataList",
            this.needFuelData
          );
          break;
      }
    },
    /**
     * @description: 下载图片按钮
     * @param {*} type
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-19 13:45:30
     * @LastEditTime: Do not edit
     */

    exportSvg({ type, name }) {
      this.$refs[type + "Echart"].exportSvg(name);
    },
    /**
     * @description: 导出数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:32:45
     * @LastEditTime: Do not edit
     */

    exportXls({ exportId, name }) {
      const userInfo = getUserInfo();
      if (!userInfo) {
        this.$Vlogin2({ flag: true });
        return;
      }
      if (userInfo.roleKey == "paidrole" || userInfo.roleKey == "trialrole") {
        const unSubscribed =
          !userInfo.menuName || !userInfo.menuName.includes("数据");
        if (unSubscribed) {
          this.$Vlogin3({ flag: true });
          return;
        }
      }
      // 如果是试用用户
      if (userInfo.roleKey == "trialrole") {
        openSy()
        buryPoint({
          eventName: '开通卡片点击',
          eventType: '2',
          columnName: '数据',
          userId: getUserInfo()?.userId
        })
        return
      }
      exportSalesData({
        dateTime: this.getDateTim.parmaData,
        searchQuery: exportId,
      }).then((res) => {
        console.log(res);
        this.blobstamp(res, name);
      });
    },
    /**
     * @description: 文件导出转化 blob数据流=> 文件
     * @param {*} blob
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:37:13
     * @LastEditTime: Do not edit
     */

    blobstamp(blob, name) {
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      let temp = name + ".xls";
      // let temp = blob.headers["content-disposition"].split(
      //   "filename*=utf-8''"
      // )[1];
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE
        let IEblob = new Blob([blob.data], {
          type: "application/vnd.ms-excel",
        });
        window.navigator.msSaveOrOpenBlob(IEblob, decodeURIComponent(temp));
        return;
      } else {
        a.href = url;
        a.setAttribute("download", decodeURIComponent(temp));
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";

.sales-data-container {
  background-color: #fff;
  border-radius: 3px;
  position: relative;
}
.header {
  padding: 24px 24px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 16px;
    font-weight: bold;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #0d57bc;
      margin-right: 8px;
    }

    .sub-title {
      margin-left: 12px;
      color: #828282;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .select-time {
    margin-right: 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
  }
  .year,
  .month {
    margin-left: 8px;
    margin-right: 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
.bar-box {
  padding-bottom: 0 !important;
}
.overallProductionSalesBody {
  padding: 0 24px 24px;
}
.first-line {
  margin-top: 14px;
  display: inline-block;
  &.card {
    width: 25%;
  }
  &.top {
    padding-left: 14px;
    width: 37.5%;
  }

  @media screen and (max-width: 1780px) {
    &.card {
      width: 100%;
    }
    &.top {
      width: 100%;
    }
  }
}

</style>
