<!--最新快讯列表-->
<template>
    <router-view v-if="$route.name == '最新快讯内容'" />
    <PageContainer v-else breadcrumb>
      <VlistTitle bigTtitle="最新快讯" smallTip="精选每日要闻，实时更新" :bgurl="bgurl" />
      <Vlist :list="ttIndustryInformationPageData" :page="ttIndustryInformationPagePage" :pageData="ttIndustryInformationPagePage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
    </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { ttIndustryInformationPage } from "@/api/Index.js";
import Vlist from "@/components/list";
import {getUserInfo,logRecords} from "@/utils/auth";
import {accessRecords} from "@/api/common";
export default {
    components: {
        VlistTitle,
        Vlist,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            ttIndustryInformationPageData: [],
            ttIndustryInformationPagePage: {
                pages: {
                    page: 1,
                    pageSize: 10,
                    latestNews: "是",
                },
                total: 0,
                currentPage: 0,
            },

        };
    },
    created() {
        this.ttIndustryInformationPage(
            this.ttIndustryInformationPagePage.pages
        );
    },
    methods: {
        ttIndustryInformationPage(params) {
            ttIndustryInformationPage(params).then((res) => {
                if (res.ok) {
                    this.ttIndustryInformationPageData = res.data.list;
                    this.ttIndustryInformationPagePage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.ttIndustryInformationPagePage.pages.pageSize = v;
            this.ttIndustryInformationPage(
                this.ttIndustryInformationPagePage.pages
            );
        },
        handleCurrentChange(v) {
            this.ttIndustryInformationPagePage.pages.page = v;
            this.ttIndustryInformationPage(
                this.ttIndustryInformationPagePage.pages
            );
        },

        toHref(item) {
          logRecords('首页','最新快讯','1','tt_industry_information',item.id,'',item.title,'')

          let newUrl = this.$router.resolve({
                path: "/index/detail/industryInformationDetail/" + item.id,
            });
            window.open(newUrl.href, "_blank");
        },
    },
};
</script>
