<template>
  <div class="date-range-box">
    <el-dropdown trigger="click" @command="deteHandleCommand" v-if="isTimeSwitch">
            <span class="el-dropdown-link">
                ({{ swatchDateStr }})时间区段<i class="el-icon-arrow-down el-icon--right"></i>：
            </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="month">按月</el-dropdown-item>
        <el-dropdown-item command="year">按年</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="newDateRange" v-if="showP">
      <div :style="'width:'+width">
        <el-tooltip content="筛选日期仅限正式开通后使用" placement="top">
          <el-date-picker v-model="startTime" :type="swatchDateType" placeholder="请选择" size="small"
                          :value-format="newValueFormat" @change="starchange" :clearable="close" :editable="false"
                          disabled>
          </el-date-picker>
        </el-tooltip>
      </div>
      <span class="line-width">至</span>
      <div :style="'width:'+width">
        <el-tooltip content="筛选日期仅限正式开通后使用" placement="top">
          <el-date-picker v-model="endTime" :type="swatchDateType" placeholder="请选择" size="small"
                          :value-format="newValueFormat" @change="etarchange" :clearable="close" :editable="false"
                          disabled>
          </el-date-picker>
        </el-tooltip>
      </div>
    </div>
    <div class="newDateRange" v-else>
      <div :style="'width:'+width">
        <el-date-picker v-model="startTime" :type="swatchDateType" placeholder="请选择" size="small"
                        :value-format="newValueFormat" @change="starchange" :clearable="close" :editable="false">
        </el-date-picker>

      </div>
      <span class="line-width">至</span>
      <div :style="'width:'+width">

        <el-date-picker v-model="endTime" :type="swatchDateType" placeholder="请选择" size="small"
                        :value-format="newValueFormat" @change="etarchange" :clearable="close" :editable="false">
        </el-date-picker>

      </div>

    </div>
  </div>
</template>
<script>
import {fmtDate} from "@/utils/date.js";
import {getUserInfo} from "@/utils/auth";

export default {
  props: {
    value: [],
    checkDate: {
      type: String,
      default: "year",
    },
    isTimeSwitch: {
      type: Boolean,
      default: true,
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM",
    },
    close: true,
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      swatchDateStr: "按月",
      swatchDateType: this.checkDate,
      startTime: "",
      endTime: "",
      newValue: this.value,
      newValueFormat: this.valueFormat,
      showP: false
    };
  },
  watch: {
    value(val) {
      this.newValue = val;
      this.startTime = val[0];
      this.endTime = val[1];
    },
    checkDate(val) {
      this.deteHandleCommand(val);
    },
  },
  created() {
    if(getUserInfo()){
      if (getUserInfo().roleKey == "trialrole") {
        this.showP = true;

      }
    }

    if (this.newValue.length > 0) {
      this.startTime = this.newValue[0];
      this.endTime = this.newValue[1];
    }
    // this.deteHandleCommand('year')
  },
  methods: {
    //切换年月日
    deteHandleCommand(command, val) {
      let currentVal = JSON.parse(JSON.stringify(this.newValue))
      if (command == "month") {
        this.swatchDateStr = "按月";
        this.newValueFormat = "yyyy-MM";
        this.swatchDateType = "month";
        if (val) {
          if (this.newValue[0]) {
            currentVal[0] = val;
          }
          if (this.newValue[1]) {
            currentVal[1] = val;
          }
        } else {
          if (this.newValue[0]) {
            currentVal[0] = fmtDate("YYYY-mm", this.newValue[0]);
          }
          if (this.newValue[1]) {
            currentVal[1] = fmtDate("YYYY-mm", this.newValue[1]);
          }
        }


      } else if (command == "year") {
        this.swatchDateStr = "按年";
        this.swatchDateType = "year";
        this.newValueFormat = "yyyy";
        if (this.newValue[0]) {
          currentVal[0] = fmtDate("YYYY", this.newValue[0]);
        }
        if (this.newValue[1]) {
          currentVal[1] = fmtDate("YYYY", this.newValue[1]);
        }
      }
      this.$emit("change", {
        value: currentVal,
        swatchDateStr: this.swatchDateStr,
        swatchDateType: this.swatchDateType,
      });
    },
    //日期选择
    starchange(v) {
      if (this.startTime && this.endTime) {
        let sdate = Date.parse(v);
        let edate = Date.parse(this.endTime);
        this.startTime = sdate > edate ? this.endTime : v;
        this.endTime = sdate > edate ? v : this.endTime;
      }
      if (Array.isArray(this.newValue)) {
        if (this.swatchDateType == "month") {
          this.newValue[0] = this.startTime;
          this.newValue[1] = this.endTime;
        } else {
          this.newValue[0] = fmtDate("YYYY", this.startTime);
          this.newValue[1] = fmtDate("YYYY", this.endTime);
        }
      }


      this.$emit("change", {
        value: this.newValue,
        swatchDateStr: this.swatchDateStr,
        swatchDateType: this.swatchDateType,
      });
    },
    etarchange(v) {
      if (this.startTime && this.endTime) {
        let sdate = Date.parse(this.startTime);
        let edate = Date.parse(v);
        this.endTime = sdate > edate ? this.startTime : v;
        this.startTime = sdate > edate ? v : this.startTime;
      }
      if (Array.isArray(this.newValue)) {
        if (this.swatchDateType == "month") {
          this.newValue[0] = this.startTime;
          this.newValue[1] = this.endTime;
        } else {
          this.newValue[0] = fmtDate("YYYY", this.startTime);
          this.newValue[1] = fmtDate("YYYY", this.endTime);
        }
      }
      this.$emit("change", {
        value: this.newValue,
        swatchDateStr: this.swatchDateStr,
        swatchDateType: this.swatchDateType,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.date-range-box {
  display: flex;
  align-items: center;
  width: 175px;

  /deep/ {
    .el-dropdown {
      padding-right: 12px;
    }
  }
}

.newDateRange {
  display: inline-flex;
  align-items: center;
  width: 210px;

  .line-width {
    display: inline-block;
    padding: 0 5px;
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  /deep/ {
    .el-input__icon.el-icon-date {
      display: none;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 5px;
    }

    .el-input--suffix .el-input__inner {
      padding-right: 16px;
    }

    .el-input__suffix {
      right: -3px;
    }
  }
}
</style>
