<template>
  <div class="enterprise-dynamic-list">
    <el-card :body-style="{ padding: '0 22px 20px'}">
      <div slot="header" class="clearfix">
        <h3 class="title-tag-text">
          企业动态
        </h3>
        <span
          class="more"
          style="float: right;"
          @click="toHref('/workInformation/enterpriseInfo/news', '_blank')"
        >
          查看更多 <i class="el-icon-arrow-right"></i>
        </span>
      </div>

      <div class="content-box">
        <div
          class="items-content"
          v-for="(i, o) in dataSource"
          :key="i.id"
          style="cursor: pointer"
          @click="
            toHref(`/workInformation/enterpriseInfo/entrepriseNews/enterDetail/${i.id}`, '_blank', i.id)
          "
        >
          <i class="top icon-zhiding iconfont" v-if="o === 0"></i>
          <i
            v-else
            :class="{
              first: o === 1,
              second: o === 2,
              third: o === 3
            }"
            >{{ o === 0 ? "" : "0" + o }}</i
          >
          <TextOverTooltip
            refName="dynamicListSpan"
            className="title-span"
            :content="i.title"
          >
          </TextOverTooltip>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { logRecords } from "@/utils/auth.js";
import { getEnterpriseDynamicList } from "@/api/enterpriseAnalysis.js";
import TextOverTooltip from "../components/title/index";
export default {
  name: "enterpriseDynamic",
  components: {
    TextOverTooltip
  },
  data() {
    return {
      dynamicParams: {
        pageSize: 9,
        pageNum: 1
      },
      dataSource: []
    };
  },
  computed: {
    queryValueLength() {
      return text => {
        if (!text) return false;
        const chineseRegex = /[\u4e00-\u9fa5]/; // 匹配汉字的正则表达式
        const maxNum = chineseRegex.test(text) ? 19 : 21;
        const textNum = text.trim().length;
        return textNum >= maxNum;
      };
    }
  },
  methods: {
    toHref(routeUrl, openWay, id) {
      const newUrl = this.$router.resolve(routeUrl);
      if (id) {
        logRecords("企业情报", "企业动态", "", "", id, "", "", "");
      } else {
        logRecords("企业情报", "企业动态", "", "", "", "", "", "");
      }
      window.open(newUrl.href, openWay);
    },
    init() {
      getEnterpriseDynamicList(this.dynamicParams).then(res => {
        if (res.ok) {
          this.dataSource = res.data.itemList;
        }
      });
    }
  },
  created() {
    this.init();
  }
};
</script>
<style lang="scss">
.enterprise-dynamic-list {
  .el-card__header {
    border: none;
    padding: 30px 22px 20px;
  }
  .el-card {
    min-width: 400px;
  }
}
</style>
<style lang="scss" scoped>
.more {
  color: #999;
}
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}
.enterprise-dynamic-list {
  .content-box {
    .items-content {
      display: flex;
      align-items: center;
      .title-span {
        display: inline-block;
        width: calc(100% - 39px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:nth-child(n + 2) {
        margin-top: 9px;
      }
      i {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        color: #ccc;
        margin-right: 20px;
      }
      .top {
        font-size: 19px;
        color: #ff0f0f;
      }
      .first {
        color: #ff0000;
      }
      .second {
        color: #ff6000;
      }
      .third {
        color: #fcc660;
      }
    }
  }
}
</style>
<style src="../components/iconfont/iconfont.css"></style>
