<!--
 * @Author: your name
 * @Date: 2021-07-21 13:12:20
 * @LastEditTime: 2021-07-21 15:42:32
 * @LastEditors: Please set LastEditors
 * @Description: 新能源产业链
 * @FilePath: /information-vue/code/src/views/data/newEnergy/index.vue
-->

<template>
  <PageContainer>
    <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />
    <template v-else>
      <div>
        <div flex="sb">
          <div :class="'box'">
            <div flex="sb">
              <div class="title">新能源产业链数据</div>
              <div>2021-03-07 更新</div>
            </div>
            <div>已更新至2021-03-07</div>
          </div>
        </div>
        <div class="search-form">
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="电池" name="电池"></el-tab-pane>
              <el-tab-pane label="电机" name="电机"></el-tab-pane>
              <el-tab-pane label="电控" name="电控"></el-tab-pane>
            </el-tabs>
          </div>
          <div flex="sb">
            <div>
              <span>
                <el-dropdown>
                  <span class="el-dropdown-link"> 2021年01月<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>黄金糕</el-dropdown-item>
                    <el-dropdown-item>狮子头</el-dropdown-item>
                    <el-dropdown-item>螺蛳粉</el-dropdown-item>
                    <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                    <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <span style="margin-left:20px">
                <el-dropdown>
                  <span class="el-dropdown-link"> 当月<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>黄金糕</el-dropdown-item>
                    <el-dropdown-item>狮子头</el-dropdown-item>
                    <el-dropdown-item>螺蛳粉</el-dropdown-item>
                    <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                    <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>

            <div>
              <el-button type="primary" v-has-role="'trialrole'" plain>导出</el-button>
            </div>
          </div>
          <el-table :data="tableData" style="width: 100%;margin-bottom: 20px; margin-top:30px;" row-key="id" default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" stripe>
            <el-table-column prop="date" label="日期" sortable width="180"> </el-table-column>
            <el-table-column prop="name" label="姓名" sortable width="180"> </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
import VnotLogin from "@/components/notLogin.vue";

import { getUserInfo } from "@/utils/auth.js";
export default {
  components: {
    VnotLogin,
  },
  data() {
    return {
      value1: "",
      show: false,
      checkList: [],
      activeName: "电池",
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      checkList: [],
      tableData: [
        {
          id: 1,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 2,
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          id: 3,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          children: [
            {
              id: 31,
              date: "2016-05-01",
              name: "王小虎",
              address: "上海市普陀区金沙江路 1519 弄",
            },
            {
              id: 32,
              date: "2016-05-01",
              name: "王小虎",
              address: "上海市普陀区金沙江路 1519 弄",
            },
          ],
        },
        {
          id: 4,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #0D57BC;
}
.el-icon-arrow-down {
  font-size: 12px;
}
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0D57BC;
  }
}
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}
.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0D57BC;
}
::v-deep .el-tabs__active-bar {
  background-color: #0D57BC;
}
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}
.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;
  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    label {
      width: 85px;
    }
    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }
    &:nth-child(1) {
      float: left;
    }
    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(3) {
      float: right;
    }
    .g-form-input {
      flex: 1;
      width: 185px;
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
