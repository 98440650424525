<template>
  <div
    :class="[{
      'mode-absolute': mode === 'absolute',
      'mode-relative': mode === 'relative',
      'mode-fixed': mode === 'fixed',
      'column-left': position === 'left',
      'column-right': position === 'right',
    }]"
    :style="mode !== 'absolute' ? null : { top: `${top}px`, height }"
  >
    <template v-if="mode === 'relative'">
      <slot />
    </template>
    <el-scrollbar v-else>
      <slot />
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import * as scssVars from '@/styles/variables.module';

export default {
  name: 'ColumnBox',
  inject: {
    appContainerIns: {
      default: () => ({}),
    },
  },
  props: {
    mode: {
      required: false,
      type: String,
      default: 'relative',
    },
    position: {
      required: false,
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      top: scssVars.pageContainerPaddingVertical,
      height: undefined,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
    hasTopNav() {
      return true;
    },
    headerFixed() {
      return this.settings.header.fixed;
    },
    hasActionNav() {
      return !!this.appContainerIns.appSlots.actionNav;
    },
    hasBreadcrumb() {
      return this.settings.hasBreadcrumb;
    },
    breadcrumbTrail() {
      return this.settings.breadcrumbTrail;
    },
    hasTopbanna() {
      const { topbanna } = this.appContainerIns;
      return topbanna;
    },
    hasLinksFooter() {
      const { links, footer } = this.appContainerIns;
      return links || footer;
    },
    hasActionBar() {
      return !!this.appContainerIns.appSlots.actionBar;
    },
  },
  mounted() {
    if (this.mode === 'absolute') {
      document.addEventListener('scroll', this.scroll);
    }
  },
  beforeDestory() {
    document.removeEventListener('scroll', this.scroll);
  },
  methods: {
    scroll() {
      const {
        headerFixed,
        hasTopNav,
        hasBreadcrumb,
        breadcrumbTrail,
        hasTopbanna,
        hasActionNav,
        hasActionBar,
      } = this;
      const thershold = 1;

      let offset = 0;

      if (!headerFixed) {
        offset = scssVars.headerHeight;

        if (hasTopNav) {
          offset += scssVars.topNavHeight;
        }

        if (hasBreadcrumb) {
          offset += scssVars.breadcrumbHeight;
        }
        if (breadcrumbTrail) {
          offset += scssVars.breadcrumbHeight;
        }

        if (hasTopbanna) {
          offset += scssVars.topBannaHeight;
        }

        if (hasActionNav) {
          offset += scssVars.actionNavHeight;
        }
      }

      offset -= scssVars.pageContainerPaddingVertical;

      const scrollTop = document.documentElement.scrollTop ?? document.body.scrollTop;

      this.top = Math.max(0, scrollTop - offset + thershold);;

      if (!this.hasLinksFooter) {
        this.height = undefined;
        return;
      }

      const windowHeight = window.innerHeight;
      const appEle = document.getElementById('app');
      const linksEle = document.querySelector('.links');
      const footerEle = document.querySelector('.footer');

      const appHeight = appEle ? appEle.clientHeight : 0;
      const linksHeight = linksEle ? linksEle.clientHeight : 0;
      const footerHeight = footerEle ? footerEle.clientHeight : 0;
      const cutHeight = Math.max(0, windowHeight + scrollTop - (appHeight - linksHeight - footerHeight));

      let height = windowHeight;
      if (headerFixed) {
        height -= scssVars.headerHeight;

        if (hasTopNav) {
          height -= scssVars.topNavHeight;
        }

        if (hasBreadcrumb) {
          height -= scssVars.breadcrumbHeight;
        }
        if (breadcrumbTrail) {
          height -= scssVars.breadcrumbHeight;
        }

        if (hasTopbanna) {
          offset -= scssVars.topBannaHeight;
        }

        if (hasActionNav) {
          height -= scssVars.actionNavHeight;
        }

        if (hasActionBar) {
          height -= scssVars.actionBarHeight;
        }
      }
      height -= 2 * scssVars.pageContainerPaddingVertical;
      height -= cutHeight;

      this.height = `${height}px`;
    },
  },
};
</script>
