import { render, staticRenderFns } from "./VcontentItem.vue?vue&type=template&id=74acbbca&scoped=true"
import script from "./VcontentItem.vue?vue&type=script&lang=js"
export * from "./VcontentItem.vue?vue&type=script&lang=js"
import style0 from "./VcontentItem.vue?vue&type=style&index=0&id=74acbbca&prod&lang=scss&scoped=true"
import style1 from "@/views/workInformation/enterpriseInfo/components/iconfont/iconfont.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74acbbca",
  null
  
)

export default component.exports