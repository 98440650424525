<template>
  <div class="collection-box">
    <div class="collection-inner">
      <div class="collection-title">
        我的收藏 <i class="el-icon-close close-btn" @click="close"></i>
      </div>
      <div class="collection-content">
        <div class="collection-item-box">
          <div class="collection-item-l">
            <div
              class="collection-item-btn"
              v-for="item in asideData"
              :key="item.id"
              :class="{ act: item.id == actid }"
              @click="actFun(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="collection-item-r">
            <ul class="collection-list">
              <li v-for="item in list" :key="item.id">
                <div
                  class="collection-list-l text-overflow"
                  @click="toHref(item)"
                >
                  {{ item.title }}
                </div>
                <div class="collection-list-r">
                  <span>{{ item.publishDate }}</span>
                  <img
                    src="../assets/img/Union.png"
                    alt=""
                    width="16"
                    height="20"
                    @click="cancel(item)"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ttCollectionList, ttCollectiondel } from "@/api/common.js";
export default {
  props: {
    asideData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      textarea: "",
      actid: 0,
      actName: "",
      swatcName: "",
      list: [],
    };
  },
  created() {
    this.actName = this.asideData[0].name;
    this.actid = this.asideData[0].id;
    this.ttCollectionList({ menuId: this.actid });
  },
  methods: {
    //收藏查询
    ttCollectionList(params) {
      ttCollectionList(params).then((res) => {
        if (res.ok) {
          this.list = res.data;
        }
      });
    },
    //关闭
    close() {
      this.$emit("close");
    },
    //侧边栏切换
    actFun(item) {
      this.actid = item.id;
      this.actName = item.name;
      this.swatcName = "";
      this.ttCollectionList({ menuId: this.actid });
    },
    //类别切换
    swatchFun(name) {
      this.swatcName = name;
    },
    //关闭
    close() {
      this.$emit("close");
    },
    //跳转
    toHref(item) {
      this.$emit("toHref", item);
    },
    //取消
    cancel(item) {
      this.$confirm("是否取消收藏", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        ttCollectiondel(item.id).then((res) => {
          if (res.ok) {
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].id == item.id) {
                this.list.splice(i, 1);
              }
            }
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.collection-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba($color: #000, $alpha: 0.3);
  display: flex;
  .collection-inner {
    width: 1144px;
    border-radius: 8px;
    margin: auto;
    .collection-title {
      position: relative;
      width: 100%;
      height: 116px;
      line-height: 116px;
      background: url(../assets/img/collectionimg.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      color: #fff;
      .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;
        cursor: pointer;
      }
    }
    .collection-content {
      padding: 0 10px;
      height: 572px;
      background: #fff;
      .collection-item-box {
        display: flex;
        padding-top: 20px;
        .collection-item-l {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          width: 109px;
          margin-right: 40px;
          .collection-item-btn {
            height: 38px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
            &.act {
              background: #f3f8ff;
            }
          }
        }
        .collection-item-r {
          flex: 1;
          height: 525px;
          overflow: hidden;
          overflow-y: auto;
          .collection-list {
            li {
              padding: 24px;
              border-bottom: 1px solid #f2f2f2;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .collection-list-l {
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                color: #141414;
                flex: 1;
                padding-right: 20px;
                cursor: pointer;
              }
              .collection-list-r {
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 18px;
                color: #828282;
                span {
                  padding-right: 28px;
                }
                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>