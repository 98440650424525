<template>
  <div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">
        <!-- 更新于{{ ncycsj }}，查看详情 -->
        <el-tooltip class="item" effect="dark" :content="ncycTip" placement="top-start">
          <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>
        </el-tooltip>
        <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn(1)">
          导出
        </el-button>
      </div>
    </div>


    <template v-if="show && !showP">
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ ncycz }} -->
          <h3 class="text-center"><span>{{ oldYear }}年乘用车年度预测&nbsp;</span></h3>

        </div>
      </div>
      <div id="ncychart"></div>
      <el-table :data="cycTable" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                style="width: 100%; margin-bottom: 20px" stripe>
        <template v-for="(item, index) in cycTableHeader">
          <el-table-column :prop="item" :label="item" :key="'cyc' + index">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/cycndyc.jpg" alt=""/>
      <center style="height: 1px;">
        <div
            style="position:relative;width:300px;height: 94px;bottom:600px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
            v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>
          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>

    </div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">
        <!-- 更新于{{ nsycsj }}，查看详情 -->
        <el-tooltip class="item" effect="dark" :content="nsycTip" placement="top-start">
          <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>
        </el-tooltip>

        <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn(2)">
          导出
        </el-button>
      </div>
    </div>


    <template v-if="show && !showP">
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ nsycz }} -->
          <h3 class="text-center"><span>{{ oldYear }}年商用车年度预测&nbsp;</span></h3>

        </div>
      </div>
      <div id="nsycChart" ></div>

      <el-table :data="sycTable" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                style="width: 100%; margin-bottom: 20px" row-key="id" default-expand-all
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe>
        <template v-for="(item, index) in sycTableHeader">
          <el-table-column :prop="item" :label="item" :key="'nsyc' + index">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/sycndyc.jpg" alt=""/>
      <center style="height: 1px;">
        <div
            style="position:relative;width:300px;height: 94px;bottom:600px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
            v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>
          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:600px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>

    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {yearData, yearDataUpdataList, yearDataExport} from "@/api/sale.js";
import {downloadFile} from "@/utils/download.js";
import {getUserInfo, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  data() {
    return {
      cychartData: [],
      sychartData: [],
      cycTable: [],
      cycTableHeader: [],
      sycTable: [],
      sycTableHeader: [],
      ncycTip: "乘用车预测，基于乘用车市场新购、增购和换购趋势，结合宏观经济、政策、季节、厂商、库存等影响因素对未来销量进行预测。（数据来源：中汽协批发量数据）",
      ncycsj: "",
      nsycTip: "商用车预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考",
      nsycsj: "",
      ncycz: "",
      nsycz: "",
      oldYear: "",
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
    };
  },
  mounted() {
    let date = new Date(),
        year = date.getFullYear();
    this.oldYear = year + "-" + (year + 3);

    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        } else {
          if( getUserInfo().roleKey == "trialrole"){
            this.showP = true;

          }
          this.init();
        }
      } else {
        this.init();
      }
      this.show = true;


    } else {
      this.show = false;
    }
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    init() {
      //cartype    1是乘用车 2是商用车
      yearData({cartype: 1}).then((res) => {
        if (res.ok) {
          this.cychartData = res.data;
          let data = res.data;
          let cxData = data.data;
          let year = data.year;
          let table = [];
          for (let key in cxData) {
            let obj = {类别: key};
            for (let i = 0; i < year.length; i++) {
              if (cxData[key][i]) {
                obj[year[i]] = cxData[key][i].toFixed(2);
              }
            }
            table.push(obj);
          }
          this.cycTable = table;
          this.cycTableHeader = ["类别"].concat(year);
        }
      });
      yearData({cartype: 2}).then((res) => {
        if (res.ok) {
          this.sychartData = res.data;
          let data = res.data;
          let cxData = data.data;
          let year = data.year;
          let table = [];
          //货车数据
          let hyserMap = res.data.hyserMap;
          //客车数据
          let kyserMap = res.data.kyserMap;

          for (let key in cxData) {
            let obj = {类别: key, children: []};
            for (let i = 0; i < year.length; i++) {
              if (cxData[key][i]) {
                obj[year[i]] = cxData[key][i].toFixed(2);
              }
            }
            table.push(obj);
          }
          //货车数据
          let hcData = [];
          //客车数据
          let kcData = [];
          for (let key in hyserMap) {
            let obj = {类别: key};
            for (let i = 0; i < year.length; i++) {
              if (hyserMap[key][i]) {
                obj[year[i]] = hyserMap[key][i].toFixed(2);
              }
            }
            hcData.push(obj);
          }
          for (let key in kyserMap) {
            let obj = {类别: key};
            for (let i = 0; i < year.length; i++) {
              if (kyserMap[key][i]) {
                obj[year[i]] = kyserMap[key][i].toFixed(2);
              }
            }
            kcData.push(obj);
          }
          for (let i = 0; i < table.length; i++) {
            table[i]["id"] = i + 1;
            if (table[i]["类别"] == "货车") {
              for (let j = 0; j < hcData.length; j++) {
                hcData[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = hcData;
            } else if (table[i]["类别"] == "客车") {
              for (let j = 0; j < kcData.length; j++) {
                kcData[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = kcData;
            }
          }
          this.sycTable = table;
          this.sycTableHeader = ["类别"].concat(year);
        }
      });
      yearDataUpdataList({cartype: 1}).then((res) => {
        if (res.ok) {

          this.ncycsj = res.data.createTime;
        }
      });
      yearDataUpdataList({cartype: 2}).then((res) => {
        if (res.ok) {

          this.nsycsj = res.data.createTime;
        }
      });
    },
    initChart(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      let xData = data.year;
      let cxdata = data.data;
      let cx = [];
      cx = data.car;
      let seriesData = [];
      let tbList = data.tbList.map((item) => Number(item));
      for (let key in cxdata) {
        seriesData.push({
          name: key,
          name: key,
          type: "bar",
          stack: "Search Engine",
          data: cxdata[key],
        });
      }

      seriesData.push({
        name: "同比增速",
        type: "line",
        yAxisIndex: 1,
        data: tbList,
      });
      var option = {
        title: {
          subtext: "万辆",
        },
        legend: {
          data: cx.concat("同比增速"),
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = datas[i].value
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: seriesData,
      };
      myChart.clear();
      myChart.setOption(option);
    },
    refInit() {
      this.$nextTick(function () {
        this.initChart("ncychart", this.cychartData);
        this.initChart("nsycChart", this.sychartData);
      });
    },
    fromData(str) {
      let index = str.indexOf("F");
      let s = "";
      if (index != -1) {
        s = str.slice(0, index);
      } else {
        s = str;
      }
      return s;
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    excelbtn(type) {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            }else {
              let self = this;
              yearDataExport({cartype: type}).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    if (type == 1) {
                      downloadFile(res, "乘用车数据.xlsx");
                    } else {
                      downloadFile(res, "商用车数据.xlsx");
                    }
                  }
                });
              });            }
          }
        } else {
          let self = this;
          yearDataExport({cartype: type}).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                if (type == 1) {
                  downloadFile(res, "乘用车数据.xlsx");
                } else {
                  downloadFile(res, "商用车数据.xlsx");
                }
              }
            });
          });        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
  },
};
</script>
<style lang="scss" scoped>
h3 span {
  font-weight: bold;
}

#ncychart,
#nsycChart {
  width: 100%;
  height: 500px;
}

.g-content-bottom {
  margin-top: 20px;
}

.el-icon-info {
  margin-right: 2px;
}

.search-form {
  height: 30px;
}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
