// 数据
import $http from '@/plugins/http.js'

// 国内产销数据列表查询
export function getSaleData(params) {
  return $http.get('/api/salesDate/ttDomesticProductionSalesData/getSaleData', params)
}

// 数据-车辆类型
export function ttDataVehicleModel() {
  return $http.get('/api/vehicleModel/ttDataVehicleModel')
}
// 数据-企业信息
export function ttDataEnterprise() {
  return $http.get('/api/dataEnterprise/ttDataEnterprise')
}

// 国内产销数据加强版
export function getSaleDataZQ(params) {
  return $http.get('/api/salesData/saleController2/getSaleData', params)
}

// 国外销量数据
export function getSaleDataGW(params) {
  return $http.get('/api/data/ttAbroadSaleData/findSjqcTables', params)
}

// 国外产量数据
export function getProductionDataGW(params) {
  return $http.get('/api/data/ttAbroadProductionData/findSjqcTables', params)
}

// 分地区产量查询
export function getProductionRegionTable(params) {
  return $http.get('/api/data/productionRegion/findProductionRegion', params)
}

// 发动机数据
export function ykbFdjPage(params) {
  return $http.post('/salesData/ykbFdj/page', params)
}

// 保有量数据
export function cxbyl(params) {
  return $http.get('/api/data/ttCxBylZcl/cxbyl', params)
}
// 保有量年份
export function queryYearList() {
  return $http.get('/api/data/ttCxBylZcl/queryYearList')
}
// 城市
export function queryProvince() {
  return $http.get('/api/data/ttCxBylZcl/queryProvince')
}
// 保有量图表
export function qcbylchart(params) {
  return $http.get('/api/data/ttCxBylZcl/qcbylchart', params)
}
// 千保有量数据

// 保有量注册图表
export function qczclchart(params) {
  return $http.get('/api/data/ttCxBylZcl/qczclchart', params)
}
// 保有量注册图表
export function cxzcl(params) {
  return $http.get('/api/data/ttCxBylZcl/cxzcl', params)
}
// 千人保有量
export function queryQrbyl(params) {
  return $http.get('/api/data/ttCxQrbyl/queryQrbyl', params)
}
// 千人保有量年份
export function queryYearListq() {
  return $http.get('/api/data/ttCxQrbyl/queryYearList')
}
// 经济指标
export function jjzb(params) {
  return $http.get('/api/data/ykbJjzb/jjzb', params)
}
// 经济指标时间
export function zbQueryYear() {
  return $http.get('/api/data/ykbJjzb/queryYear')
}
// 经济指标月份
export function ykbJjzbQueryMonth(params) {
  return $http.get('/api/data/ykbJjzb/queryMonth', params)
}
// 获取国家搜索数据
export function getCountryData() {
  return $http.get('/api/data/ttAbroadSaleData/getCountryList')
}

// 获取国家搜索数据
export function getCountryProductionData() {
  return $http.get('/api/data/ttAbroadProductionData/getCountryList')
}

// 获取厂牌搜索数据
export function getPpaiData() {
  return $http.get('/api/data/ttAbroadSaleData/getPPList')
}

// 获取厂牌搜索数据
export function getPpaiProductionData() {
  return $http.get('/api/data/ttAbroadProductionData/getPPList')
}

// 获取细分市场搜索数据
export function getCzhongData(params) {
  return $http.get('/api/data/ttAbroadSaleData/getCZhongList', params)
}

// 获取细分市场搜索数据
export function getCzhongProductionData(params) {
  return $http.get('/api/data/ttAbroadProductionData/getCZhongList', params)
}

// 获取级别搜索数据
export function getJbieProductionData(params) {
  return $http.get('/api/data/ttAbroadProductionData/getJbieList', params)
}
// 获取车型搜索数据
export function getCxingProductionData(params) {
  return $http.get('/api/data/ttAbroadProductionData/getCxingList', params)
}
// 获取年份搜索数据
export function getYearData(params) {
  return $http.get('/api/data/ttAbroadSaleData/getYearList', params)
}
// 道路运输车辆达标车型
export function ttRoadTransportVehiclesPage(params) {
  return $http.get('/api/data/ttRoadTransportVehicles/page', params)
}
// 道路运输车辆达标车型详情
export function getRoadTransportVehicles(params) {
  return $http.get('/api/data/ttRoadTransportVehicles/' + params)
}
// 道路运输车辆达标车型燃料种类
export function getRlInfoList() {
  return $http.get('/api/data/ttRoadTransportVehicles/getRlInfoList')
}
// 道路运输车辆达标车型批次
export function getPCInfoList() {
  return $http.get('/api/data/ttRoadTransportVehicles/getPCInfoList')
}

// 新能源汽车免征购置税批次信息
export function getPCInfoListXny() {
  return $http.get('/api/data/ttXnyMzgzs/getPCInfoList')
}
// 新能源汽车免征购置税燃料种类
export function getRlInfoListXny() {
  return $http.get('/api/data/ttXnyMzgzs/getRlInfoList')
}
// 新能源汽车免征购置税细分市场
export function getXfscInfoListXny() {
  return $http.get('/api/data/ttXnyMzgzs/getXfscInfoList')
}
// 新能源商用车汽车免征购置税细分市场
export function getXfscInfoListXnySyc() {
  return $http.get('/api/data/ttXnyMzgzs/getXfscInfoListSyc')
}
// 新能源汽车免征购置税细分市场
export function ttXnyMzgzsPage(params) {
  return $http.get('/api/data/ttXnyMzgzs/page', params)
}

// 新能源汽车免征购置税批次信息
export function getPCInfoListXny2() {
  return $http.get('/api/data/ttXnyMzgzs2/getPCInfoList')
}
// 新能源汽车免征购置税燃料种类
export function getRlInfoListXny2() {
  return $http.get('/api/data/ttXnyMzgzs2/getRlInfoList')
}
// 新能源汽车免征购置税细分市场
export function getXfscInfoListXny2() {
  return $http.get('/api/data/ttXnyMzgzs2/getXfscInfoList')
}
// 新能源商用车汽车免征购置税细分市场
export function getXfscInfoListXnySyc2() {
  return $http.get('/api/data/ttXnyMzgzs2/getXfscInfoListSyc')
}
// 新能源汽车免征购置税细分市场
export function ttXnyMzgzsPage2(params) {
  return $http.get('/api/data/ttXnyMzgzs2/page', params)
}

// 推荐车型目录批次信息
export function getPCInfoListRecom() {
  return $http.get('/api/data/ttXnyTjcxml/getPCInfoList')
}
// 推荐车型目录燃料种类
export function getRlInfoListRecom() {
  return $http.get('/api/data/ttXnyTjcxml/getRlInfoList')
}
// 推荐车型目录电机类型
export function getdjlxInfoListRecom() {
  return $http.get('/api/data/ttXnyTjcxml/getQddjlxInfoList')
}
// 推荐车型目录电池类型
export function getddclxInfoListRecom() {
  return $http.get('/api/data/ttXnyTjcxml/getCnzzzlInfoList')
}
// 推荐车型目录公告状态
export function getggztInfoListRecom() {
  return $http.get('/api/data/ttXnyTjcxml/getGgztInfoList')
}
// 推荐车型目录查询
export function gettableListRecom(params) {
  return $http.get('/api/data/ttXnyTjcxml/page', params)
}
// 国内产销数据，零售数据企业名称
export function enterPriseList(params) {
  return $http.get('/api/data/TtPassengerSaaocitionData/enterPriseList', params)
}
// 国内产销数据数据分页，
export function TtPassengerSaaocitionDataPage(params) {
  return $http.post('/api/data/TtPassengerSaaocitionData/page', params)
}

// 获取时间序列
export function AlltimeList(params) {
  return $http.get('/api/data/TtPassengerSaaocitionData/timeList', params)
}
// 出口数据
export function ttQyCkDataPage(params) {
  return $http.post('/api/data/ttQyCkData/page', params)
}

// 出口数据企业名称列表
export function getRlInfoListCksj() {
  return $http.get('/api/data/ttQyCkData/getRlInfoList')
}
// 出口数据车型分类2列表
export function getCxflei2InfoList(params) {
  return $http.get('/api/data/ttQyCkData/getCxflei2InfoList', params)
}
// 出口数据年月列表
export function getYearMonthList(params) {
  return $http.get('/api/data/ttQyCkData/getYearMonthList', params)
}
// 出口数据默认年月

export function getDefaultDate() {
  return $http.get('/api/data/ttQyCkData/getDefaultDate')
}

// 国内产销数据批发量
export function YkbQcCxDataPage(params) {
  return $http.post('/api/data/YkbQcCxData/page', params)
}

// 世界工业汽车默认时间
export function getTtAbroadSaleData() {
  return $http.get('/api/data/ttAbroadSaleData/getDefaultDate')
}

// 世界工业汽车默认时间
export function getTtAbroadProductionData() {
  return $http.get('/api/data/ttAbroadProductionData/getDefaultDate')
}

// 分地区产量默认时间
export function getProductionRegionDefaultDate() {
  return $http.get('/api/data/productionRegion/getDefaultDate')
}

// 发动机数据企业
export function getQyInfoList(params) {
  return $http.get('/api/data/ykbFdj/getQyInfoList', params)
}
// 发动机数据企业列表
export function ykbFdjselectTable(params) {
  return $http.post('/api/data/ykbFdj/selectTable', params)
}

// 发动机数据年份
export function ykbFdjgetYearMonthList(params) {
  return $http.get('/api/data/ykbFdj/getYearMonthList', params)
}
// 发动机数据默认年份
export function ykbFdgetDefaultDate() {
  return $http.get('/api/data/ykbFdj/getDefaultDate')
}

// 发动机数据导出
export function ykbFdjDynamicExport(params) {
  return $http.DownloadDb('/api/data/ykbFdj/dynamicExport', params)
}

// 发动机数据导出全部
export function ykbFdjExportAll(params) {
  return $http.DownloadDb('/api/data/ykbFdj/exportAll', params)
}

// 获取乘用车最新数据月

export function lastTime() {
  return $http.get('/api/data/YkbQcCxData/lastTime')
}

// 获取批发企业列表
export function YkbQcCxDataEnterpriseList(params) {
  return $http.get('/api/data/YkbQcCxData/enterpriseList', params)
}
// 获取批发企业获取时间序列
export function YkbQcCxDataTimeLis(params) {
  return $http.get('/api/data/YkbQcCxData/timeList', params)
}

// 乘用车分车型
export function ykbQcPpSelectTable(params) {
  return $http.post('/api/data/ykbQcPp/selectTable', params)
}
// 乘用车分车型获取默认日期
export function ykbQcPpGetDefaultDate(params) {
  return $http.get('/api/data/ykbQcPp/getDefaultDate', params)
}
// 乘用车分车型获取年月列表
export function ykbQcPpGetYearMonthList(params) {
  return $http.get('/api/data/ykbQcPp/getYearMonthList', params)
}

// 乘用车分车型获取车型级别
export function getCxjbieList() {
  return $http.get('/api/data/ykbQcPp/getCxjbieList')
}

// 乘用车分车型获取国别
export function getGbList() {
  return $http.get('/api/data/ykbQcPp/getGbList')
}

// 获取中汽协车型名称列表
export function ykbQcPpGetPPList(params) {
  return $http.get('/api/data/ykbQcPp/getPPList', params)
}
// 根据企业ids 获取车型下拉
export function TtPassengerSaaocitionDataCxingList(params) {
  return $http.post('/api/data/TtPassengerSaaocitionData/cxingList', params)
}

// 零售数据获取乘用车最新数据月
export function TtPassengerSaaocitionDataLastTime() {
  return $http.get('/api/data/TtPassengerSaaocitionData/lastTime')
}
// 零售数据获取乘用车最新数据导出
export function TtPassengerSaaocitionDataExport(params) {
  return $http.DownloadDb('/api/data/TtPassengerSaaocitionData/export', params)
}

// 世界汽车导出
export function ttAbroadSaleDataDynamicExport(params) {
  return $http.Downloadget('/api/data/ttAbroadSaleData/dynamicExport', params)
}
// 世界汽车全部导出
export function ttAbroadSaleDataExportAll(params) {
  return $http.Downloadget('/api/data/ttAbroadSaleData/exportAll', params)
}

// 新能源推荐目录导出
export function ttXnyTjcxmlDynamicExport(params) {
  return $http.Downloadget('/api/data/ttXnyTjcxml/dynamicExport', params)
}
// 新能源推荐目录全部导出
export function ttXnyTjcxmlExportAll(params) {
  return $http.Downloadget('/api/data/ttXnyTjcxml/exportAll', params)
}

// 免征购置税目录导出
export function ttXnyMzgzsDynamicExport(params) {
  return $http.Downloadget('/api/data/ttXnyMzgzs/dynamicExport', params)
}
// 免征购置税目录全部数据导出
export function ttXnyMzgzsExportAll(params) {
  return $http.Downloadget('/api/data/ttXnyMzgzs/exportAll', params)
}

// 免征购置税目录导出
export function ttXnyMzgzsDynamicExport2(params) {
  return $http.Downloadget('/api/data/ttXnyMzgzs2/dynamicExport', params)
}
// 免征购置税目录全部数据导出
export function ttXnyMzgzsExportAll2(params) {
  return $http.Downloadget('/api/data/ttXnyMzgzs2/exportAll', params)
}

// 道路运输车辆达标车型导出
export function ttRoadTransportVehiclesDynamicExport(params) {
  return $http.Downloadget('/api/data/ttRoadTransportVehicles/dynamicExport', params)
}
// 道路运输车辆达标车型导出全部数据导出
export function ttRoadTransportVehiclesExportAll(params) {
  return $http.Downloadget('/api/data/ttRoadTransportVehicles/exportAll', params)
}

// 企业出口库数据导出
export function ttQyCkDataExport(params) {
  return $http.DownloadDb('/api/data/ttQyCkData/dynamicExport', params)
}
// 企业出口库全部数据导出
export function ttQyCkDataExportAll(params) {
  return $http.DownloadDb('/api/data/ttQyCkData/exportAll', params)
}

// 分车型批发量导出
export function ykbQcPpExport(params) {
  return $http.DownloadDb('/api/data/ykbQcPp/dynamicExport', params)
}

// 分车型批发量全部导出
export function ykbQcPpExportAll(params) {
  return $http.DownloadDb('/api/data/ykbQcPp/exportAll', params)
}

// 零售全部数据导出
export function TtPassengerSaaocitionDataExportAll(params) {
  return $http.DownloadDb('/api/data/TtPassengerSaaocitionData/exportAll', params)
}

// 获取下载
export function ttDataUpload(params) {
  return $http.get('/api/data/ttDataUpload', params)
}

// 乘用车分车型企业
export function ykbQcPpEnterpriseList(params) {
  return $http.get('/api/data/ykbQcPp/enterpriseList', params)
}

// 出口数据企业下拉框
export function ttQyCkDataEnterpriseList(params) {
  return $http.get('/api/data/ttQyCkData/enterpriseList', params)
}
// 出口数据车型下拉框
export function ttQyCkDataCxingList(params) {
  return $http.post('/api/data/ttQyCkData/cxingList', params)
}

// 国内产销数据导出
export function YkbQcCxDataDynamicExport(params) {
  return $http.DownloadDb('/api/data/YkbQcCxData/dynamicExport', params)
}
// 燃料类型
export function geRllxList() {
  return $http.get('/api/data/TtPassengerSaaocitionData/geRllxList')
}

// 批发数据燃料类型
export function geRllxListPf() {
  return $http.get('/api/data/ykbQcPp/geRllxList')
}

// 历史数据
export function getYearListHistory() {
  return $http.get('/api/data/ttDataHistory/getYearList')
}
// 历史数据月份
export function getYueListHistory(params) {
  return $http.get('/api/data/ttDataHistory/getMonthByYear/' + params)
}

// 历史数据列表
export function getHistoryPage(params) {
  return $http.get('/api/data/ttDataHistory/page', params)
}
// 动态页面
export function getYueListDynamic(params) {
  return $http.get('/api/data/dynamicPage/getMonthByYear/' + params)
}

// 汽车(分车型)产销汇总表（电子版）
export function getQcfcxCxhzb(params) {
  return $http.get('/api/data/dynamicPage/getQcfcxCxhzb', params)
}
// 乘用车产销汇总表
export function getCyccxhzb(params) {
  return $http.get('/api/data/dynamicPage/getCyccxhzb', params)
}
// 商用车产销汇总表
export function getSyccxhzb(params) {
  return $http.get('/api/data/dynamicPage/getSyccxhzb', params)
}
// 全国乘用车(bj2=3,4,5,6)主要品牌产销情况表
export function getQgCycZyppCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getQgCycZyppCxqkb', params)
}

// 五大汽车企业集团乘用车产销情况表
export function getFiveGroupPassengerCars(params) {
  return $http.get('/api/data/dynamicPage/getFiveGroupPassengerCars', params)
}

// 五大汽车企业集团商用车产销情况表
export function getFiveGroupCommercialVehicle(params) {
  return $http.get('/api/data/dynamicPage/getFiveGroupCommercialVehicle', params)
}

// 发动机生产企业产销情况表 (台)
export function getFdjScqyCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getFdjScqyCxqkb', params)
}

// 发动机生产企业商品量情况表 (台)
export function getFdjScqySplqkb(params) {
  return $http.get('/api/data/dynamicPage/getFdjScqySplqkb', params)
}

// 发动机生产企业自配量情况表 (台)
export function getFdjScqyZplqkb(params) {
  return $http.get('/api/data/dynamicPage/getFdjScqyZplqkb', params)
}

// 乘用车(分车型)产销情况表
export function getCycfcxCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getCycfcxCxqkb', params)
}

// 乘用车生产企业产销情况表
export function getCycScqyCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getCycScqyCxqkb', params)
}

// 商用车生产企业产销情况表
export function getSycScqyCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getSycScqyCxqkb', params)
}

// 客车bj4=12(货车bj4=7)生产企业产销情况表（电子版）
export function getKcHcScqyCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getKcHcScqyCxqkb', params)
}
// 汽车发动机(按燃料分类)生产企业产销情况表 (台)（电子版）
export function getFdjScqyCxqkbGroupByRl(params) {
  return $http.get('/api/data/dynamicPage/getFdjScqyCxqkbGroupByRl', params)
}

// 汽车发动机(按燃料分类)生产企业商品量产销情况表 (台)（电子版）
export function getFdjSplScqyCxqkbGroupByRl(params) {
  return $http.get('/api/data/dynamicPage/getFdjSplScqyCxqkbGroupByRl', params)
}

// 汽车发动机(按燃料分类)生产企业自配量产销情况表 (台)（电子版）
export function getFdjZplScqyCxqkbGroupByRl(params) {
  return $http.get('/api/data/dynamicPage/getFdjZplScqyCxqkbGroupByRl', params)
}

// 新能源汽车产销情况表
export function getXnyQccxqkb(params) {
  return $http.get('/api/data/dynamicPage/getXnyQccxqkb', params)
}

// 半挂牵引车(分车型)产销汇总表
export function getBgqycfcxCxhzb(params) {
  return $http.get('/api/data/dynamicPage/getBgqycfcxCxhzb', params)
}
// 汽车分企业出口情况表
export function getQcfqyckqkb(params) {
  return $http.get('/api/data/dynamicPage/getQcfqyckqkb', params)
}
// 汽车生产主要企业产销情况表
export function getQcsczyqyCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getQcsczyqyCxqkb', params)
}

// 新能源商用车bj1=2(乘用车bj1=1)产销情况表
export function getXnySyccxqkb(params) {
  return $http.get('/api/data/dynamicPage/getXnySyccxqkb', params)
}

// 客车bj4=12(载货车bj4=7)分燃料产销情况表、客车非完整车辆bj4=18(货车非完整车辆bj4=17)分车型产销情况表
export function getKcfrlCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getKcfrlCxqkb', params)
}
// 载货车(分吨位)产销情况表
export function getZhcfdwcxqkb(params) {
  return $http.get('/api/data/dynamicPage/getZhcfdwcxqkb', params)
}

// 载货车(分用途)产销情况表
export function getZhcfytcxqkb(params) {
  return $http.get('/api/data/dynamicPage/getZhcfytcxqkb', params)
}

// 货车非完整车辆生产企业产销情况表
export function getHcfwzclscqycxqkb(params) {
  return $http.get('/api/data/dynamicPage/getHcfwzclscqycxqkb', params)
}

// 半挂牵引车生产企业产销情况表
export function getBgqycscqycxqkb(params) {
  return $http.get('/api/data/dynamicPage/getBgqycscqycxqkb', params)
}

// 客车(分车型)产销情况表
export function getKcfcxCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getKcfcxCxqkb', params)
}

// 载货车(分车型)产销情况表
export function getZhcfcxCxqkb(params) {
  return $http.get('/api/data/dynamicPage/getZhcfcxCxqkb', params)
}
// 半挂牵引车(分吨位)产销情况表
export function getBgqycfdwcxqkb(params) {
  return $http.get('/api/data/dynamicPage/getBgqycfdwcxqkb', params)
}

export function getYearListTable(params) {
  return $http.get('/api/data/dynamicPage/getYearList', params)
}

// 日期
export function lastTimes() {
  return $http.get('/api/data/YkbQcCxData/lastTimes')
}

export function getYearListTables(params) {
  return $http.get('/api/data/dynamicPage/getYearLists', params)
}
export function getYueListDynamics(params) {
  return $http.get('/api/data/dynamicPage/getMonthByYears/' + params)
}
