<!--
 * @Author: your name
 * @Date: 2021-07-21 09:37:34
 * @LastEditTime: 2021-07-21 11:20:22
 * @LastEditors: Please set LastEditors
 * @Description: 底盘
 * @FilePath: /information-vue/code/src/views/announcement/announcementSearch/Chassis.vue
-->

<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="公告" text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新公告" :showTips="showTips" />-->
    <template>
      <div>
        <el-form class="g-form" :model="form" label-width="96px">
          <UnfurlAndFold title="公告信息">
            <el-row>
              <el-col :span="16">
                <el-form-item label="公示批次：">
                  <el-radio-group v-model="form.pc" @change="pcRadio">
                    <el-radio label="">不限</el-radio>
                    <el-radio v-for="item in pcData" :key="item" :label="item" />
                  </el-radio-group>
                  <el-input
                    v-model="pcinput"
                    size="small"
                    placeholder="输入批次"
                    style="width: 90px"
                    @input="pcFun"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="撤销情况：">
                  <el-radio-group v-model="form.cxbj">
                    <el-radio label="">不限</el-radio>
                    <el-radio v-for="item in cxlx" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="底盘信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="底盘企业：">
                  <el-input v-model="form.qymc" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘名称：">
                  <el-input v-model="form.dpmc" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘ID：">
                  <el-input v-model="form.dpid" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="底盘型号：">
                  <el-input v-model="form.dpxh" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘类别：">
                  <div style="display: flex">
                    <el-checkbox v-model="mdplb" label="不限" @change="mdplbAll" />
                    <el-checkbox-group v-model="form.mdplb" @change="mdplbcheck">
                      <el-checkbox v-for="item in dplbData" :key="item" :label="item" />
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘商标：">
                  <el-input v-model="form.dpsb" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="发动机信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="型号：" prop="mfdj">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.mfdj" size="small" placeholder="请输入内容" />
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="生产企业：" prop="mfqy">
                  <el-row type="flex" align="middle">
                    <el-input v-model="form.mfqy" size="small" placeholder="请输入内容" />
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="功率：">
                  <el-row type="flex" align="middle" class="small-input text-center">
                    <el-col :span="6">
                      <el-input v-model="form.mfglStart" size="small" />
                    </el-col>
                    <el-col :span="2">-</el-col>
                    <el-col :span="6">
                      <el-input v-model="form.mfglEnd" size="small" />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kw</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="参数信息">
            <el-row>
              <el-col :span="24">
                <el-form-item label="轴数：">
                  <div style="display: flex">
                    <el-checkbox v-model="ghzhsh" label="不限" @change="ghzhshAll" />
                    <el-checkbox-group v-model="form.ghzhsh" @change="ghzhshcheck">
                      <el-checkbox v-for="item in ghzhshData" :key="item" :label="item" />
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="其他参数：">
                  <el-select v-model="form.otherParam" placeholder="请选择" size="small">
                    <el-option v-for="item in other" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="参数值：">
                  <el-input v-model="form.otheParamValue" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>

          <div class="text-center" style="margin-top: 20px">
            <el-button class="reset-button" @click="reset">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div>
        <div flex="sb">
          <span class="table_title" />
          <div>
            <el-button type="primary" plain @click="contrast">一键对比（{{ contrastData.length }}）</el-button>
            <el-button v-has-role="'freeusers'" type="primary" plain @click="excelBtn">导出（{{
              exportData.length
            }}）
            </el-button>
            <el-button v-if="isShow" type="primary" plain @click="exportValidGGDp">导出有效底盘
            </el-button>
          </div>
        </div>
        <el-table
          ref="newTable"
          v-loading="loading"
          :data="tableData"
          :row-key="row => row.id"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
          stripe
          @select="selectionChange"
          @selection-change="selectionChange"
          @sort-change="tableSort"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" />
          <el-table-column sortable="custom" prop="qymc" label="企业名称" min-width="200" />
          <el-table-column prop="dpmc" label="底盘名称" min-width="100" />
          <el-table-column sortable="custom" prop="cph" label="产品号" min-width="100" />
          <el-table-column sortable="custom" prop="dpxh" label="底盘型号" min-width="100" />
          <el-table-column sortable="custom" prop="pc" label="批次" min-width="80" />
          <el-table-column sortable="custom" prop="sxrq" label="生效日期" min-width="100" />
          <!-- <el-table-column sortable="custom" prop="tsrq" label="停售日期" width="100" /> -->
          <el-table-column sortable="custom" prop="bgkz" label="产品状态" min-width="100">
            <template slot-scope="scope">
              {{ ggSate(scope.row.bgkz,scope.row.cxbj) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="160">
            <template slot-scope="scope">
              <div class="table-text-btn">
                <!-- <el-popover placement="left" width="300" trigger="hover">
  <img :src="scope.row.xzzp1" alt="" width="100%" />
  <el-button slot="reference" type="text" class=""
    >选装</el-button
  >
</el-popover>

<span class="line">|</span> -->
                <el-button
                  type="text"
                  @click="viewDetail('/announcement/announcementSearch/ChassisDetails/',scope.row.id)"
                >查看
                </el-button>
                <span class="line">|</span>
                <el-button type="text" @click="printFun(scope.row.id)">打印</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right">
          <el-pagination
            :current-page="pageData.pages.page"
            :page-sizes="[10, 20, 30, 40,100]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import UnfurlAndFold from '@/components/UnfurlAndFold.vue'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { download } from '@/utils/download.js'
import {
  pageDp,
  getAllDpPc,
  getDpQueryList,
  exportGGDp,
  exportValidGGDp
} from '@/api/announcement.js'
import ggcpzt from '@/mixin/ggcpzt.js'
import { buryPoint } from '@/api/common'

export default {
  components: {
    // VnotLogin,
    UnfurlAndFold
  },
  mixins: [ggcpzt],
  data() {
    return {
      input: '',
      show: false,
      showTips: [
        {
          title: '公告动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '公告库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '公告梳理',
          text: '专家梳理整合，真正读懂公告脉络'
        },
        {
          title: '公告大数据',
          text: '用数据深度理解公告结构和趋势'
        }
      ],
      checkList: [],
      tableData: [],
      form: {
        dpmc: '',
        // 底盘名称
        dpsb: '',
        // 底盘商标
        cxbj: '',
        // 撤销情况
        ghzhsh: [],
        // 轴数
        dpid: '',
        // 底盘ID
        mdplb: [],
        // 底盘类别
        mdpqy: '',
        // 底盘企业
        mdpxh: '',
        // 底盘型号
        pc: '',
        // 批次
        otherParam: '',
        // 其它参数
        otheParamValue: ''
        // 参数值
      },
      // 批次输入
      pcinput: '',
      // 车辆类型
      mdplb: true,
      // 轴数
      ghzhsh: true,
      cxbjData: [],
      dplbData: [],
      ghzhshData: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 100
        },
        total: 0
      },
      pcData: [],
      contrastData: [],
      exportData: [],
      other: [
        { value: 'chang', label: '长' },
        { value: 'kuan', label: '宽' },
        { value: 'gao', label: '高' },
        { value: 'yjbz', label: '排放依据标准' },
        { value: 'zhj', label: '轴距' },
        { value: 'thps', label: '钢板弹簧片数(前/后)' },
        { value: 'ltgg', label: '轮胎规格' },
        { value: 'lts', label: '轮胎数' },
        { value: 'qlj', label: '前轮距' },
        { value: 'hlj', label: '后轮距' },
        { value: 'zzl', label: '总质量' },
        { value: 'zbzl', label: '整备质量' },
        { value: 'gczl', label: '准拖挂车总质量' },
        { value: 'bgaz', label: '半挂车鞍座最大允许承载质量' },
        { value: 'qpck', label: '驾驶室准乘人数' },
        { value: 'jjlqj', label: '接近角/离去角' },
        { value: 'qxhx', label: '前悬/后悬' },
        { value: 'zgcs', label: '最高车速' },
        { value: 'sbdh', label: '车辆识别代号' },
        { value: 'qt', label: '其它' }
      ],
      cxlx: [
        { value: 'y', label: '已撤' },
        { value: 'n', label: '未撤' }
      ],
      loading: false,
      isShow: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '公告',
        userId: ''
      }
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
      if (getUserInfo().userName == '韩洪臣' || getUserInfo().userName == 'sqsyc2' || getUserInfo().userName == 'sqsyc1' || getUserInfo().userName == 'SQSYC' || getUserInfo().userName == '344655' || getUserInfo().userName == '170502') {
        this.isShow = true
      }
    } else {
      this.show = false
    }
    this.init()
    this.getAllDpPc()
    this.getDpQueryList()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    viewDetail(path, id) {
      const newUrl = this.$router.resolve({
        path: path + id
      })
      if (getUserInfo()) {
        logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '查看')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 获取列表
    pageDp(parmas) {
      this.loading = true
      pageDp(parmas).then((res) => {
        this.tableData = res.data.list
        this.pageData.total = res.data.count
        this.loading = false
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val
      this.init()
    },
    // 获取批次
    getAllDpPc() {
      getAllDpPc().then((res) => {
        this.pcData = res.data
      })
    },
    // 初始化
    init() {
      const data = Object.assign({}, this.form, this.pageData.pages)
      if (data.cxbj == '') {
        data.cxbj = ''
      }
      if (data.pc == '') {
        data.pc = ''
      }
      data.mdplb = data.mdplb.join(',')
      data.ghzhsh = data.ghzhsh.join(',')
      this.pageDp(data)
    },
    // 查询
    searchForm() {
      if (getUserInfo()) {
        logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '查询')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.pageData.pages.page = 1
            this.init()
          }
        } else {
          this.pageData.pages.page = 1
          this.init()
        }
      } else {
        this.showLogin()
      }
    },
    // 重置
    reset() {
      this.form = {
        dpmc: '',
        // 底盘名称
        dpsb: '',
        // 底盘商标
        cxbj: '',
        // 撤销情况
        ghzhsh: [],
        // 轴数
        dpid: '',
        // 底盘ID
        mdplb: [],
        // 底盘类别
        mdpqy: '',
        // 底盘企业
        mdpxh: '',
        // 底盘型号
        pc: ''
        // 批次
      }
      this.pcinput = ''
      this.mdplb = true
      this.ghzhsh = true
      this.pageData.pages.page = 1
      this.$refs.newTable.clearSort()
      this.init()
    },
    // 批次输入
    pcFun(e) {
      this.form.pc = e
    },
    // 批次选择
    pcRadio() {
      this.pcinput = ''
    },
    // 底盘类型
    mdplbAll() {
      this.mdplb = true
      this.form.mdplb = []
    },
    mdplbcheck(e) {
      if (e.length <= 0) {
        this.mdplb = true
      } else {
        this.mdplb = false
      }
    },
    // 轴数
    ghzhshAll() {
      this.ghzhsh = true
      this.form.ghzhsh = []
    },
    ghzhshcheck(e) {
      if (e.length <= 0) {
        this.ghzhsh = true
      } else {
        this.ghzhsh = false
      }
    },
    getDpQueryList() {
      getDpQueryList().then((res) => {
        const data = res.data
        this.cxbjData = data.cxbj
        this.dplbData = data.dplb
        this.ghzhshData = data.zhsh
      })
    },

    excelBtn() {
      if (getUserInfo()) {
        if (this.exportData.length <= 0) {
          this.$message({
            showClose: true,
            message: '请选择导出数据',
            type: 'error'
          })
          return false
        }
        if (this.exportData.length > 100) {
          this.$message({
            showClose: true,
            message: '最多选择100条数据',
            type: 'error'
          })
          return false
        }

        const ids = this.exportData.map((item) => {
          return item.id
        })
        logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              exportGGDp(ids).then((res) => {
                download(res, '底盘公告数据', 'xls')
              })
            }
          }
        } else {
          exportGGDp(ids).then((res) => {
            download(res, '底盘公告数据', 'xls')
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 选择
    selectionChange(selection) {
      this.contrastData = selection
      this.exportData = selection
    },
    // 一键比对
    contrast() {
      if (getUserInfo()) {
        if (this.contrastData.length <= 0) {
          this.$message({
            showClose: true,
            message: '请选择对比数据',
            type: 'error'
          })
          return false
        }
        if (this.contrastData.length > 10) {
          this.$message({
            showClose: true,
            message: '最多选择10条数据',
            type: 'error'
          })
          return false
        }
        const ids = this.contrastData.map((item) => {
          return item.id
        })
        const newUrl = this.$router.resolve({
          path: '/announcement/announcementSearch/ChassisDetails/' +
              ids.join(',')
        })
        logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '一键对比')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 打印printFun
    printFun(id) {
      if (getUserInfo()) {
        logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '打印')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open('/api/exportZcPdf/ggDpkPdf?dpId=' + id)
          }
        } else {
          window.open('/api/exportZcPdf/ggDpkPdf?dpId=' + id)
        }
      } else {
        this.showLogin()
      }
    },
    exportValidGGDp() {
      logRecords('公告', '底盘查询', '3', 'gg_dpk', '', '', '', '', '导出全部')
      const data = Object.assign({}, this.form, this.pageData.pages)
      if (data.cxbj == '') {
        data.cxbj = ''
      }
      if (data.pc == '') {
        data.pc = ''
      }
      data.mdplb = data.mdplb.join(',')
      data.ghzhsh = data.ghzhsh.join(',')
      exportValidGGDp(data).then((res) => {
        download(res, '有效底盘数据', 'xls')
      })
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-text-btn {
  .el-button--text {
    padding: 0;
  }

  .line {
    padding: 0 5px;
  }

  .el-button--text,
  .line {
    color: #0d57bc;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
