<template>
  <PageContainer breadcrumb footer>
    <Vcontent name='标准' :data="data" :props="props" />
  </PageContainer>
</template>
<script>
import { getStandardContentDetail } from "@/api/standards.js";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "applicableProducts",
        htmlContent: "contentText",
        summary: "summary",
        publishDate: "publishTime",
      },
    };
  },
  created() {
    let standardContentId = this.$route.params.id;
    this.getStandardContentDetail({ standardContentId });
  },
  methods: {
    getStandardContentDetail(params) {
      getStandardContentDetail(params).then((res) => {
        if (res.ok) {
          // console.log('res.data',res.data)
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
