<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-14 14:10:21
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-11 10:03:47
-->
<template> </template>
<script>
import { infoLogin } from "@/api/login.js";
export default {
  name: "login",
  created() {
    this.init();
  },
  methods: {
    init() {
      infoLogin().then((res) => {
        if (res.ok) {
          console.log(res);
          window.open("https://www.autoinfo.org.cn/shape.html?" + res.data);
        }
      });
      // https://idmtest.catarc.ac.cn/idp/authCenter/authenticate?response_type=code&state=1&redirect_uri=https://www.autoinfo.org.cn/infoLogin.html&client_id=autolib
    },
  },
};
</script>

<style scoped></style>
