<template>
  <div class="timeline">
    <!-- 遍历每年的数据 -->
    <div
      class="timeline-item"
      v-for="(yearData, index) in timelineData"
      :key="yearData.year"
    >
      <!-- 年份标题 -->
      <div class="timeline-year">{{ yearData.year }}</div>
      <div class="timeline-events">
        <!-- 遍历一年内的所有事件 -->
        <div
          class="timeline-event"
          :class="{ 'blue-button': eventIndex === 0 }"
          v-for="(event, eventIndex) in yearData.eventList"
          :key="`event-${index}-${eventIndex}`"
        >
          <!-- 事件月份和描述 -->
          <div class="timeline-event-content">
            <div v-if="event.month" class="timeline-event-month">
              {{ event.month }}
            </div>
            <div class="timeline-event-description">{{ event.event }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timelineData: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline {
  padding-left: 100px; /* 给年份和时间线留出空间 */
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 79px;
  top: 5px;
  bottom: 0;
  width: 1px;
  background: #e5e5e5; /* 时间线的颜色 */
}

.timeline-year {
  position: absolute;
  left: 16px; /* 年份位置 */
  padding-right: 20px; /* 年份和时间线之间的间距 */
  font-weight: bold;
  text-align: right;
  font-family: MicrosoftYaHei-Bold;
	font-size: 12px;
  color: #3f76ea;
}

.timeline-events {
  margin-bottom: 20px;
}

.timeline-event {
  position: relative;
  padding-bottom: 10px;
}

.timeline-event::before {
  content: "";
  position: absolute;
  left: -25px; /* 调整以对齐时间线 */
  top: 5px;
  height: 10px;
  width: 10px;
  background-color: #f9f9f9;
  border: solid 1px #e5e5e5;
  border-radius: 50%;
}
.blue-button.timeline-event::before {
  background-color: #3f76ea;
  box-shadow: 0 0 5px #3f76ea;
}

.timeline-event-month {
  font-family: MicrosoftYaHei-Bold;
  font-size: 12px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.timeline-event-description {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333;
}
</style>
