<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" :isCollection="false" />

    <template slot="right">
      <Recommend
        title="相关推荐"
        type="list"
        :list="relevant"
        @click="tohref"
      />
      <ContentItem
        title="洞察报告"
        type="list"
        :list="tableDataRight"
        @click="toAnalysis"
      />
    </template>
  </PageContainer>
</template>
<script>
import { ttIndustryInformation } from "@/api/Index.js";
import { fmtDate } from '@/utils/date'
import { queryByPage } from "@/api/insight.js";
import Recommend from '@/components/recommends.vue'
import ContentItem from '@/components/contentItem.vue'

export default {
  components: {
    Recommend,
    ContentItem
  },
  data() {
    return {
      data: {},
      props: {
        tag: "keywordsLabel",
        htmlContent: "content",
        summary: "introduction",
        publishDate:'updateTime'
      },
      relevant: [],
      tableDataRight: [],
    };
  },
  watch: {
    $route(to, from) {
      let id = to.query.id;
      this.ttIndustryInformation(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.query.id;
    this.ttIndustryInformation(id);
  },
  methods: {
    pageRight() {
      queryByPage(this.pages).then((res) => {
        if(res.ok){
          this.tableDataRight = res.data.list.map((item) => {
          item.updateTime = fmtDate("YYYY-mm-dd", item.updateTime);
          return item;
        });
        }
      })
    },
    ttIndustryInformation(params) {
      ttIndustryInformation(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relevant = res.data.list;
          this.pageRight()
        }
      });
    },
    tohref(item) {
      //相关推荐
      // "/index/detail/IndustryDetail/" + item.id
      this.$router.push({
        path: "/marketInsight/detail/marketDynamicDetail/",
        query: {
          id: item.id,
          releaseColumn: item.releaseColumn,
          category: "industry",
        },
      });
    },
    toAnalysis(item) {
      this.$router.push("/marketInsight/detail/insightReportDetail/" + item.id);
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
