<template>
  <el-dialog
    width="62.5%"
    top="70px"
    :visible="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div class="header">
      <div class="left">
        <img :src="require('@/assets/img/newData/driver-dialog-icon.png')" alt="">
        数据栏目全新改版
      </div>
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="tip-wrapper">
      <div class="title">
        <div class="order">01<span class="shadow">01</span></div>
        数据报告
      </div>
      <div class="content">大屏可视化报告全面展现市场走势，PPT月度分析报告可实现快速下载、编辑使用。</div>
    </div>
    <div class="tip-wrapper">
      <div class="title">
        <div class="order">02<span class="shadow">02</span></div>
        数据查询
      </div>
      <div class="content">多主题字段选择、拖拉拽灵活形式，支持自定义查询分析，可视化、多图表形式展示。</div>
    </div>
    <!-- TODO 这里缺少一张动图, 在等需求或ui提供 -->
    <div class="gift-wrapper">
      <div class="mask" />
      <video autoplay loop muted height="400" width="100%">
        <source :src="require('@/assets/video/newData/driver.mp4')" type="video/mp4">
      </video>
    </div>
    <div class="tip-wrapper">
      <div class="title">
        <div class="order">03<span class="shadow">03</span></div>
        数据下载
      </div>
      <div class="content">常用多主题统计报表生成，可实现直接下载。</div>
    </div>
    <div class="btn-wrapper">
      <div class="btn" @click="handleClickClose">知道了</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DriverDialog',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleClickClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family:  DingTalk JinBuTi;
  color: #333333;
  margin-bottom: 24px;

  .left {
    font-size: 22px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 23px;
      margin-right: 8px;
    }
  }

  i {
    font-size: 20px;
    font-weight: bold;
    color: #4E5969;
    cursor: pointer;
  }
}

.tip-wrapper {
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 8px;

    .order{
      margin-right: 8px;
      position: relative;
      font-family:  DingTalk JinBuTi;
      white-space: nowrap;
      color: #D8E4F4;
      user-select: none;
      padding-left: 3px;

      .shadow {
        position: absolute;
        color: #0D57BC;
        left: 0;
      }
    }
  }

  .content {
    color: #4E5969;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      margin-left: 4px;
      background-color: #4E5969;
      border-radius: 3px;
      margin-right: 16px;
    }

  }
}

.btn-wrapper {
  margin-top: 6px;
  display: flex;
  justify-content: center;

  .btn {
    width: 218px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #0D57BC;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    cursor: pointer;
  }
}

.gift-wrapper {
  position: relative;
  padding: 10px 0 5px;

  .mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
  }
}

::v-deep {
  .el-dialog {
    border-radius: 4px;
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body {
    padding: 24px;
  }
}

</style>
