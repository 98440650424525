<!--
 * @Author: your name
 * @Date: 2021-07-22 15:27:51
 * @LastEditTime: 2021-07-22 15:41:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/cuttingEdgeTechnology/index.vue
-->

<template>
    <PageContainer>
        <div class="not-login-box" v-if="!show">
            <VnotLogin title="前沿技术" text="前沿技术栏目围绕前沿技术本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新前沿技术"
                :showTips="showTips" />
        </div>
        <template v-else>
            <div class="box">
                <div class="box_box" flex="sb" style="background:#fff">
                    <ul class="dropdown-menu dropdown-menu-fw">
                        <template v-for="item in keywords">
                            <li class="dropdown more-dropdown-sub1" :class="{ 'active': item.id == activeName }"
                                v-if="item.children && item.children.length > 0" :key="item.id">
                                <a href="javascript:;" @click="toCategory(item)">{{ item.name }}<i
                                        class="el-icon-arrow-down" style="padding-left:10px"></i></a>
                                <ul class="dropdown-menu">
                                    <li v-for="citem in item.children" :key="citem.id">
                                        <a href="javascript:;" @click.prevent="toCategoryc(item, citem)">{{ citem.name
                                        }}</a>
                                    </li>
                                </ul>
                            </li>
                            <li :class="{ 'active': item.id == activeName }" v-else :key="item.id">
                                <a href="javascript:;" @click="toCategory(item)">{{ item.name }}</a>
                            </li>
                        </template>
                    </ul>
                    <div style="padding-right:20px;width: 13%;">
                        <div @click="$router.push('/cuttingEdgeTechnology/search')">
                            <el-input placeholder="搜索名称" size="small" readonly>
                                <i slot="suffix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="box_box_title" v-for="item in tableData" :key="item.id" @click="toHref(item)">
                    <div style="display: flex">
                        <div class="text-center" style="width: 267px; height: 150px">
                            <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl"
                                alt="" />
                        </div>
                        <div class="flex_col">
                            <div>
                                <div flex="c" style="margin: 10px 0">
                                    <!-- <span
                    style="
                      padding: 5px 10px;
                      background: #0D57BC;
                      color: #ffffff;
                      font-weight: 600;
                      margin-right: 10px;
                    "
                    >力荐</span
                  > -->
                                    <p class="">{{ htmlRestore(item.title) }}</p>
                                </div>
                                <div style="font-size: 14px; color: #828282">
                                    {{ htmlRestore(item.introduction) }}
                                </div>
                            </div>
                            <div style="font-size: 14px; color: #828282">
                                {{ item.updateTime }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="box_box_title">
          <div style="display: flex">
            <img
              style="width: 300px; height: 150px"
              src="@/assets/img/dnc-img.png"
              alt=""
            />
            <div class="flex_col">
              <div>
                <div flex="c" style="margin: 10px 0">
                  <span
                    style="
                      padding: 5px 10px;
                      background: #0D57BC;
                      color: #ffffff;
                      font-weight: 600;
                      margin-right: 10px;
                    "
                    >力荐</span
                  >
                  <p class="">大陆集团2020年财报分析</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">2021-03-17</div>
            </div>
          </div>
        </div> -->
                <div class="pages">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                        :total="pageData.total">
                    </el-pagination>
                </div>
            </div>
        </template>
    </PageContainer>
</template>
<script>
/**
 * @description: 前沿技术动态页面
 * @param {*}
 * @return {*}
 */
import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import { queryByPage } from "@/api/cuttingEdgeTechnology.js";
import { ttDemension } from "@/api/dict.js";
export default {
    components: {
        VnotLogin,
    },
    data() {
        return {
            activeName: "",
            defaultUrl: require("@/assets/img/default-img.png"),
            showTips: [
                {
                    title: "前沿技术动态",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "前沿技术库查询",
                    text: "权威数据库，多条件筛查关联比对",
                },
                {
                    title: "前沿技术梳理",
                    text: "专家梳理整合，真正读懂前沿技术脉络",
                },
                {
                    title: "前沿技术大数据",
                    text: "用数据深度理解前沿技术结构和趋势",
                },
            ],
            loading: false,
            tableData: [],
            show: false,
            form: {
                keywords: [],
            },
            keywords: [],
            pageData: {
                pages: {
                    pageNo: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },
            keywordAll: true,
        };
    },
    created() {
        if (getUserInfo()) {
            this.show = true;
            this.getKeyWords();
            this.queryByPage();
        } else {
            this.show = false;
        }
    },
    methods: {
        toHref(item) {
            let newUrl = this.$router.resolve("/cuttingEdgeTechnology/details/" + item.id);
            window.open(newUrl.href, "_blank");
        },
        handleSizeChange(val) {
            this.pageData.pages.pageSize = val;
            this.queryByPage();
        },
        handleCurrentChange(val) {
            this.pageData.pages.pageNo = val;
            this.queryByPage();
        },
        //查询类别
        getKeyWords() {
            ttDemension({ code: 'technology_type' }).then(res => {
                if (res.ok) {
                    this.keywords = res.data[0].children
                    this.keywords.unshift({ name: "全部", id: "" })
                }
            })
        },
        //列表查询
        queryByPage() {
            let form = Object.assign({}, this.form, this.pageData.pages);
            queryByPage(form).then((res) => {
                this.tableData = res.data.list;
                this.pageData.total = res.data.count;
            });
        },
        toCategory(item) {
            this.activeName = item.id;
            this.form.keywords = item.id;
            this.queryByPage();
        },
        toCategoryc(pitem, item) {
            this.activeName = pitem.id;
            this.form.keywords = item.id;
            this.queryByPage();
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === "前沿技术详情" || to.name === "前沿技术") {
            // 详情页路由
            from.meta.keepAlive = true;
        } else {
            from.meta.keepAlive = false;
        }
        next();
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
    display: none;
}

::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
}

::v-deep .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
}

.box {
    padding: 0 10%;

    .box_box {
        padding: 10px;
        background: #ffffff;

        /deep/ .el-input__inner {
            width: 100%;
            border-radius: 20px;
        }
    }

    .box_box_title {
        margin-top: 10px;
        background: #ffffff;
        cursor: pointer;

        p {
            font-weight: 600;
            color: #000;
            font-size: 16px;
        }

        .flex_col {
            padding: 5px 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
        }
    }
}

.lable-box {
    margin-bottom: -10px;

    .el-checkbox {
        margin-bottom: 10px;
    }
}
</style>
<style lang="scss" scoped>
.dropdown-menu-fw {
    position: relative;
    display: flex;
}

.dropdown-menu>li {
    display: block;
    position: relative;
    margin: 15px 0;
}

.dropdown-menu>li>a {
    color: #000;
    font-size: 14px;
    text-decoration: none;
    padding: 15px;
}

.dropdown-menu>li.active>a {
    color: #0D57BC;
}

.dropdown-menu {
    min-width: 175px;
    background: #fff;

    .dropdown-menu {
        position: absolute;
        z-index: 20;
        display: none;
    }

    & {

        .more-dropdown-sub1,
        .more-dropdown-sub2,
        .more-dropdown-sub3 {
            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }
    }
}
</style>
