<template>
  <div class="unsubscribed-mask">
    <div>
      <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
    </div>
    <div class="line2">
      <span
        class="link"
        @click="applyForTrial(1)"
      >在线申请试用</span>
      &nbsp;&nbsp;或&nbsp;&nbsp;
      <span
        class="link"
        @click="applyForTrial(2)"
      >直接联系我们</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnsubscribedMask',
  methods: {
    // 申请试用
    applyForTrial(flag) {
      const newUrl = this.$router.resolve(`/sqSy?flag=${flag}`)
      window.open(newUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.unsubscribed-mask {
  width:320px;
  height:94px;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 100;
  background-color: #e0f9f9;
  box-shadow: 2px 2px 10px #d3cece;

  .link {
    color: #0D57BC;
    cursor: pointer;
  }

  .line2 {
    margin-top: 10px;
  }
}
</style>
