//专题分析
import $http from '@/plugins/http.js'


//详情
export function ttSpecial(params) {
    return $http.get('/api/information/ttSpecial/find/',params)
}
//获取所有的标签
export function getAllTages(params) {
    return $http.get('/api/information/ttSpecial/getAllTages', params)
}
//分页查询
export function page(params) {
    return $http.get('/api/information/ttSpecial/page', params)
}
//分页查询
export function pageSyc(params) {
    return $http.get('/api/information/ttSpecial/selectSyc', params)
}
//分页查询
export function selectTop(params) {
    return $http.get('api/information/ttSpecial/selectTop10', params)
}
