<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" isContentTwo="qyjs" />

    <template slot="right">
      <VcontentItem title="相关推荐" type="list" :list="list" @click="tohref" />
    </template>
  </PageContainer>
</template>
<script>
import { getSaleDetail } from "@/api/salesForecast.js";
export default {
  data() {
    return {
      data: {},
      list: [],
      props: {
        tag: "keywords",
        summary:"introduction",
        publishDate:"updateTime"
      },
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.queryById({ id });
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.queryById({ id });
  },
  methods: {
    queryById(params) {
      getSaleDetail(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          // this.list = res.data.recommendEOList;
        }
      });
    },
    //跳转
    tohref(item) {
      if (item.tableName == "tt_special") {
        this.$router.push("/thematicAnalysis/details/" + item.id);
      } else {
        this.$router.push("/sale/report/detail/" + item.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
