<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-12 10:04:02
-->
<template>
  <div class="bulletinBoard">
    <el-col :span="24" class="board-info">
      <div class="title">{{ data.date }}整体产量</div>
      <div class="num">
        {{ toThousands(data.overallProductionTotalValue) }}
        <span class="unit">辆</span>
      </div>
      <div class="proportion">
        <div class="yoy">
          同比
          <span :class="war('icon', data.overallProductionTotalYOY)" />
          <span :class="war('num', data.overallProductionTotalYOY)">{{
            war("show", data.overallProductionTotalYOY)
          }}</span>
        </div>
        <div class="qoq">
          环比
          <span :class="war('icon', data.overallProductionTotalQOQ)" />
          <span :class="war('num', data.overallProductionTotalQOQ)">{{
            war("show", data.overallProductionTotalQOQ)
          }}</span>
        </div>
      </div>
    </el-col>
    <el-col :span="24" class="board-info">
      <div class="title">{{ data.date }}乘用车产量</div>
      <div class="num">
        {{ toThousands(data.overallProductionPassengerValue) }}
        <span class="unit">辆</span>
      </div>
      <div class="proportion">
        <div class="yoy">
          同比
          <span :class="war('icon', data.overallProductionPassengerYOY)" />
          <span :class="war('num', data.overallProductionPassengerYOY)">{{
            war("show", data.overallProductionPassengerYOY)
          }}</span>
        </div>
        <div class="qoq">
          环比
          <span :class="war('icon', data.overallProductionPassengerQOQ)" />
          <span :class="war('num', data.overallProductionPassengerQOQ)">{{
            war("show", data.overallProductionPassengerQOQ)
          }}</span>
        </div>
      </div>
    </el-col>
    <el-col :span="24" class="board-info">
      <div class="title">{{ data.date }}商用车产量</div>
      <div class="num">
        {{ toThousands(data.overallProductionCommercialValue) }}
        <span class="unit">辆</span>
      </div>
      <div class="proportion">
        <div class="yoy">
          同比
          <span :class="war('icon', data.overallProductionCommercialYOY)" />
          <span :class="war('num', data.overallProductionCommercialYOY)">{{
            war("show", data.overallProductionCommercialYOY)
          }}</span>
        </div>
        <div class="qoq">
          环比
          <span :class="war('icon', data.overallProductionCommercialQOQ)" />
          <span :class="war('num', data.overallProductionCommercialQOQ)">{{
            war("show", data.overallProductionCommercialQOQ)
          }}</span>
        </div>
      </div>
    </el-col>
  </div>
</template>
<script>
import { toThousands } from "@/utils/number";

export default {
  name: "BulletinBoard",
  props: {
    // 高度
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      toThousands,
    };
  },
  created() {},
  methods: {
    war(type, num) {
      switch (type) {
        case "num":
          if (num) {
            return parseFloat(num) >= 0 ? "text-up" : "text-down";
          }
          return "";
        case "icon":
          if (num) {
            return parseFloat(num) >= 0 ? "icon-up" : "icon-down";
          }
          return "";
        case "show":
          if (num) {
            return Math.abs(parseFloat(num));
          }
          return "--";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bulletinBoard {
  width: 100%;
  .board-info {
    height: 140px;
    background-color: #ecf7ff;
    padding: 0 24px !important;
    border-radius: 4px;
  }
  .board-info:nth-child(2) {
    margin: 16px 0;
    background-color: #eaf9ef;
  }
  .board-info:nth-child(3) {
    background-color: #f7f4ff;
  }
  .title {
    padding-top: 24px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
    line-height: 24px;
  }
  .num {
    margin: 12px 0 18px 0;
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    line-height: 28px;
  }
  .proportion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .yoy,
    .qoq {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #86909c;
      line-height: 16px;
      .icon-up {
        display: inline-block;
        height: 12px;
        width: 10px;
        background: url(~@/assets/img/overview/up.png) no-repeat;
        background-size: 100% 100%;
      }
      .icon-down {
        display: inline-block;
        height: 12px;
        width: 10px;
        background: url(~@/assets/img/overview/down.png) no-repeat;
        background-size: 100% 100%;
      }
      .text-up {
        margin-left: 4px;
        color: #f53f3f;
      }
      .text-down {
        margin-left: 4px;
        color: #00b42a;
      }
    }
  }
}

.unit {
  color: #86909c;
  font-size: 14px;
  font-weight: 400;
}
</style>
