<template>
  <div
    v-if="isShowSupporting"
    class="enterpriseDetail"
    id="supporting"
    data-name="配套关系"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="配套关系"
      :sourceData="sourceData"
      :propertyName="['deliveryStatus', 'partsMaker']"
    >
      <template #cardSelect>
        <SearchSelect
          popper-class="supporting-type"
          placeholder="配套分类"
          v-model="params.deliveryStatusList"
          :options="deliveryStatusList"
          @input="changeListQuery"
          size="small"
        />
        <SearchSelect
          placeholder="零部件公司"
          v-model="params.partsMakerList"
          :options="partsMakerList"
          @input="changeListQuery"
          size="small"
        />
        <SearchSelect
          popper-class="supporting-vehicle"
          placeholder="车型"
          v-model="params.vehicleModelList"
          :options="vehicleModelList"
          @input="changeListQuery"
          size="small"
        />
      </template>
      <template #headPaginator>
        <paginator
          ref="paginator"
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import {
  getDeliveryStatusSelections,
  getDeliveryStatus,
  postExportDeliveryStatus
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";

import SearchSelect from "@/components/searchSelect";
export default {
  name: "Supporting",
  components: {
    Paginator,
    TableCard,
    SearchSelect
  },
  data() {
    return {
      exportState: false,
      isShowSupporting: false,
      isLoading: false,
      totalCount: 0,
      sourceData: {},
      params: {
        pageSize: 10,
        pageNum: 1,
        enterpriseId: this.$route.params.id,
        deliveryStatusList: [],
        partsMakerList: [],
        vehicleModelList: []
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      deliveryStatusList: [],
      partsMakerList: [],
      vehicleModelList: []
    };
  },
  created() {
    const enterpriseSelections = {
      enterpriseId: this.$route.params.id
    };
    this.getDeliveryStatusSelections(enterpriseSelections);
    this.getDeliveryStatus(this.params, "isShowSupporting");
  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业信息" , "3", "", "", "", "","配套关系", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postExportDeliveryStatus(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "配套关系.xlsx");
              });
            }
          }
        } else {
          postExportDeliveryStatus(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "配套关系.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getDeliveryStatus(this.params);
    },
    changeListQuery() {
      this.$refs.paginator.resetNum();
      this.params.pageNum = 1;
      this.getDeliveryStatus(this.params);
    },
    getDeliveryStatusSelections(params) {
      getDeliveryStatusSelections(params).then(res => {
        if (res.ok) {
          const {
            deliveryStatusList,
            partsMakerList,
            vehicleModelList
          } = res.data;
          this.deliveryStatusList = deliveryStatusList;
          this.partsMakerList = partsMakerList;
          this.vehicleModelList = vehicleModelList;
        }
      });
    },
    getDeliveryStatus(params, isShowSupporting) {
      getDeliveryStatus(params).then(res => {
        if (res.ok) {
          if (isShowSupporting) {
            this.isShowSupporting = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
        }
      });
    }
  },
  watch: {
    // 观察路由的变化来更新userId
    "$route.params.id"(newId) {
      this.params.enterpriseId = newId;
    }
  }
};
</script>

<style lang="scss">
.supporting-type {
  .index-list-container {
    width: 360px;
  }
}
.supporting-vehicle {
  .index-list-container {
    width: 320px;
  }
}
</style>
