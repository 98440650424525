<template>
  <el-dialog
    :visible="show"
    append-to-body
    class="return-old-version-dialog"
    width="700px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="show = false"
    @closed="removeComp"
  >
    <div slot="title" class="title">
      是否确定返回旧版
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="content">
      <div class="tip-block">
        <img :src="require('@/assets/img/newData/info-circle-fill.png')" alt="">
        新版会有更多内容和功能体验，建议您使用新版
      </div>
      <el-form ref="formRef" :model="formData" :rules="formDataRules">
        <div class="title-wrapper">
          <div>新版意见反馈</div>
        </div>
        <el-form-item prop="detailedRequirements">
          <el-input
            v-model="formData.detailedRequirements"
            type="textarea"
            :rows="2"
            :maxlength="200"
            :autosize="{ minRows: 2, maxRows: 5 }"
            resize="none"
            placeholder="留下您的宝贵意见，我们会努力改进"
          />
        </el-form-item>
        <div class="form-row">
          <div class="title-wrapper">您的公司？</div>
          <el-form-item prop="company">
            <el-input
              v-model="formData.company"
              size="small"
              :maxlength="200"
              placeholder="请输入"
              style="width: 410px;"
            />
          </el-form-item>
        </div>
        <div class="form-row">
          <div class="title-wrapper">您的姓名？</div>
          <el-form-item prop="name">
            <el-input
              v-model="formData.name"
              size="small"
              :maxlength="200"
              placeholder="请输入"
              style="width: 410px;"
            />
          </el-form-item>
        </div>
        <div class="title-wrapper">
          <div>您的联系方式？</div>
          <div class="sub-title">(我们会选择优质意见送出礼品)</div>
        </div>
        <el-form-item prop="phone">
          <el-input
            v-model="formData.phone"
            size="small"
            :maxlength="11"
            placeholder="请输入"
            style="width: 500px;"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <div class="btn" @click="handleClickReturnOldVersion">
        旧版<i class="el-icon-arrow-right el-icon--right" />
      </div>
      <div class="btn" @click="handleClickGoToNewVersion">
        新版<i class="el-icon-arrow-right el-icon--right" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { insertRequirementRecord } from '@/api/data_new.js'
import { getUserInfo } from '@/utils/auth.js'
import { oldDataRoutes } from '@/router'
import { logRecords } from '@/utils/auth.js'

export default {
  name: 'DataVersionDialog',
  data() {
    const phoneValidator = (_rule, value, callback) => {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!value || reg.test(value)) {
        callback()
        return
      }
      callback(new Error('请输入正确的电话号码'))
    }
    return {
      show: false,
      toOldVersion: false,
      formData: {
        detailedRequirements: undefined,
        phone: undefined,
        company: undefined,
        name: undefined
      },
      formDataRules: {
        phone: [
          { validator: phoneValidator, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 点击返回旧版
    handleClickReturnOldVersion() {
      // 埋点
      logRecords(
        '数据', // column1 写死
        this.$route.name, // column2 数据表名称，传当前菜单的名称
        '3', // type 写死，3 表示操作
        '', // tableName 不传也行
        '', // articleId 不传也行
        '', // attachName 不传也行
        '', // articleName 不传也行
        '', // inputBox
        '确认旧版' // buttonName
      )
      this.validateAndSubmitInfo()
        .then(() => {
          this.show = false
          this.toOldVersion = true
        })
        .catch(() => {})
    },
    // 点击前往新版
    handleClickGoToNewVersion() {
      this.validateAndSubmitInfo()
        .then(() => {
          this.show = false
        })
        .catch(() => {})
    },
    // 提交反馈信息到后端
    validateAndSubmitInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          // 如果表单校验通过（均为填写或者手机号填写正确）
          if (valid) {
            resolve()
            const { detailedRequirements, phone, company, name } = this.formData
            // 如果都没填写 则结束
            if (!detailedRequirements && !phone && !company && !name) {
              return
            }
            const userInfo = getUserInfo()
            insertRequirementRecord({
              detailedRequirements,
              phone,
              company,
              name,
              userId: userInfo?.userId
            })
              .then(() => {})
              .catch(() => {})
          } else {
            reject()
          }
        })
      })
    },
    handleClickClose() {
      this.show = false
    },
    removeComp() {
      this.$destroy()
      this.$nextTick(() => {
        if (this.toOldVersion) {
          window.open(this.$router.resolve(oldDataRoutes[0].path).href + '?version=old', '_blank')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.return-old-version-dialog {

  .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #1D2129;

    i {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 20px;
      font-weight: bold;
      color: #4E5969;
      cursor: pointer;
    }
  }

  .content {
    .tip-block {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      color: #757575;
      background-color: #FEF6E4;
      border-radius: 4px;
      margin-bottom: 20px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .form-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .title-wrapper {
        margin-bottom: 0;
        margin-right: 20px;
      }

      ::v-deep {
        .el-form-item {
          margin-bottom: 0;
        }
      }
    }

    .title-wrapper {
      display: flex;
      margin: 0 0 8px;
      color: #333333;

      .sub-title {
        color: #828282;
      }

    }

  }

  ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 100px;
      height: 40px;
      display: flex;
      color: #0d57bc;
      background-color: #f0f4f9;
      justify-content: center;
      align-items: center;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      transition-property: background-color, color;
      transition-duration: 0.3s;
      user-select: none;
      font-size: 16px;

      i {
        margin-left: 4px;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:focus, &:hover {
        background-color: #0d57bc;
        color: #fff;
      }
    }
  }
}
</style>
