<template>
    <div id="map"></div>
</template>

<script>
import echarts from 'echarts4';
import china from '@/assets/jsondata/china_1.json'
echarts.registerMap('china', china)
import { getUserInfo } from "@/utils/auth.js";
export default {
    components: {},
    props: {
        mapList: {
            type: Array,
            default: [],
        },
        maxNum: {
            default: '',
        },
        chartTitle: {
            default: '',
        },
    },
    data() {
        return {
            showhover:true
        }
    },
    watch: {
        mapList: {
            deep: true,
            handler(newVal, oldVal) {
                this.renderMap()
            },
        },
    },
    computed: {},
    methods: {
        renderMap() {
            var myChart = echarts.init(document.getElementById('map'));
            var option = {
                title: [
                    {
                        text: this.chartTitle,
                        left: 'center',
                        textStyle: {
                            fontSize: 16
                        }
                    },
                ],
                tooltip: {
                    trigger: 'item',
                    backgroundColor: '#fff',
                    padding: [10],
                    formatter: (params) => {

                        if (params.value && this.showhover) {
                            return `<div style="font-size:14px;color:black">${params.name}：<span style="font-size:20px;">${params.value} </span>个企业</div>
                        `
                        } else if (this.showhover) {
                            return `<div style="font-size:14px;color:black">该区域暂无相关内容</div>
                        `
                        }
                    }
                },
                visualMap: {
                    min: 1,
                    max: this.maxNum,
                    left: 'left',
                    bottom: '35',
                    text: ['高', '低'],
                    calculable: true,
                    // seriesIndex: [1],
                    inRange: {
                        color: ["#e0e9f5", "#0069ea", "#233996"]
                    },
                    show: this.showhover
                },
                series: [
                    {
                        name: 'iphone5',
                        type: 'map',
                        mapType: 'china',
                        label: {
                            normal: {
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                areaColor: '#e6ebf2',
                                borderColor: '#fff',
                                borderWidth: 1,
                            },
                        },
                        data: this.mapList
                    }
                ]
            };
            myChart.setOption(option);
        },
        handleResize() {
            var myChart = echarts.init(document.getElementById("map"));
            myChart.resize();
        }
    },
    created() {

    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.renderMap()
        if (getUserInfo()) {
            if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                let menuName = getUserInfo().menuName;
                if (!menuName.includes('企业情报')) {
                    this.showhover = false
                } else {
                    if (getUserInfo().roleKey == 'trialrole') {
                        this.showhover = false
                    }
                }
            }
        } else {
            this.showhover = false
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    }
}
</script>
<style scoped lang="scss">
#map {
    width: 100%;
    padding: 0 70px 0 30px;
    height: 500px;
}
</style>