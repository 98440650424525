<template>
  <div>
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ sycz }} -->
          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">动力电池退役量预测&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="sycTip" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                  class="el-icon-info"></i>预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
    <template v-if="show && !showP">
      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;margin-bottom: 20px;">
        动力电池退役量预测
      </div>
      <div id="sycChart"></div>
      <div class="g-content-bottom">
        <div style="display: flex;align-items: center;justify-content: space-between; width: 100%;">
          <span>单位：GWh</span>
          <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn">
              导出
            </el-button>
        </div>
        <el-table :data="sycTable" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                  style="width: 100%; margin-bottom: 20px" row-key="id" default-expand-all
                  stripe id="tal">
          <template v-for="(item, index) in sycHeader">
            <el-table-column :prop="item" :label="item" :key="'syc' + index" v-if="item == '类别'" min-width="130"/>
            <el-table-column :prop="item" :label="item" :key="'syc' + index" v-else min-width="100"/>
          </template>
        </el-table>
      </div>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/syc3.png" alt=""/>
      <center style="height: 1px;">
        <div
            style="position:relative;width:300px;height: 94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
            v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>

    </div>

  </div>


</template>
<script>
import {
  selectMonthSycTotal,
  exportPredictCyc,
  selectMonthSycHc,
  selectMonthSycKc,
  exportPredictSyc, selectMouseTotalSyc,selecBatteryYear
} from "@/api/sale.js";
import * as echarts from "echarts";
import JsonExcel from "vue-json-excel";
import {downloadFile} from "@/utils/download.js";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {buryPoint} from "@/api/common";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import XLSXS from 'xlsx-style-medalsoft'

export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      cychartData: [],
      cycTable: [],
      cycHeader: [],
      cycupdateTime: "",
      cycz: "",
      leftTotalList: [],
      sycTable: [],
      sycupdateTime: "",
      sycz: "",
      sycTip: "动力电池退役量预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考。（数据来源：中汽协批发量数据，历史数据为实际销量）",
      sycHeader: [],
      syctotal: [],
      nowYear: "",
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
      mokuaiTableTitle: [],
      modelList: [],
      cycTitle: [],
      cycModelList: [],
      danWei:"单位：万辆，%"
    };
  },
  created() {

    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        }else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showP = true;
          }
        }
      }
      this.init();
      this.show = true;
    } else {
      this.show = false;
    }

  },
  methods: {


    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    init() {
      selecBatteryYear().then((res) => {
        if (res.ok) {
          this.initChart2("sycChart", res.data);
          this.sycHeader = ['类别'].concat(res.data.date);
          var phosphate_battery = ['磷酸铁锂电池退役量（GWh）'].concat(res.data.phosphate_battery);
          var ternary_battery = ['三元电池退役量（GWh）'].concat(res.data.ternary_battery);
          var hj = ['合计'].concat(res.data.hj);

          const lstlObject = {};
          this.sycHeader.forEach((year, index) => {
            lstlObject[year] = phosphate_battery[index];
          });
          const syObject = {};
          this.sycHeader.forEach((year, index) => {
            syObject[year] = ternary_battery[index];
          });
          const hjObject = {};
          this.sycHeader.forEach((year, index) => {
            hjObject[year] = hj[index];
          });

          this.sycTable = [lstlObject, syObject, hjObject];
        }
      });

    },

    initChart2(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var xData = data.date;
      var lstlList = ['磷酸铁锂电池退役量（GWh）', '三元电池退役量（GWh）'];
      var option = {
        title: {
          subtext: "GWh",
        },

        legend: {
          data: lstlList,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true }

          }
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: [
          {
            name: lstlList[0],
            type: "bar",
            stack: "Search Engine",
            data: data.phosphate_battery,
          },
          {
            name: lstlList[1],
            type: "bar",
            stack: "Search Engine",
            data: data.ternary_battery,
          }
        ],
      };
      myChart.clear();
      myChart.setOption(option);
    },

    fromData(str) {
      let index = str.indexOf("F");
      let s = "";
      if (index != -1) {
        s = str.slice(0, index);
      } else {
        s = str;
      }
      return s;
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },

    //计算数组的和
    counts(arr) {
      let count = 0;
      if (arr.length > 0) {
        arr.forEach((item) => {
          count += item;
        });
      }
      return count.toFixed(2);
    },
    excelbtn() {
      var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#tal').cloneNode(true)
      var wb = XLSX.utils.table_to_book(table, xlsxParam)
      const wbs = wb['Sheets']['Sheet1']
       // 每个单元格设置居中
      for (const key in wbs) {
        if (key.indexOf('!') === -1 && wbs[key].v) {
          wbs[key].s = {
            alignment: {
              horizontal: 'center',
              vertical: 'center',
              wrap_text: true
            }
          }
        }
      }
      var wbout = XLSXS.write(wb, { bookType: 'xlsx', bookSST: true, type: 'buffer' })
        try {
          FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '动力电池退役量预测.xlsx')
        } catch (e) {
          if (typeof console !== 'undefined') {
            console.log(e, wbout)
          }
        }
        return wbout
    }
  },
};
</script>
<style lang="scss" scoped>
h3 span {
  font-weight: bold;
}

#sycZtChart,
#sycChart {
  width: 100%;
  height: 500px;
}

#hcChart, #kcChart {
  width: 50%;
  height: 500px;
}

.g-content-bottom {
  margin-top: 20px;
}

.el-icon-info {
  margin-right: 2px;
}

//.search-form {
//  height: 30px;
//}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
