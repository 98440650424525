<template>
  <div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button class="text-right" type="primary" v-has-role="'freeusers'" plain @click="exportEvent">
          导出
        </el-button>

      </div>
    </div>
    <template>
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ cycz }} -->
          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">商用车整体情况预测&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="sycTip" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                  class="el-icon-info"></i>预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
      <template v-if="show && !showP">

      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;">商用车预测趋势分析</div>

      <div id="sycZtChart"></div>
      <div style="margin: 0 60px 60px 60px" v-has-role="'freeusers'">
        <!-- 表格数据 -->

        <div>
          <span>{{ this.danWei }}</span>
          <el-table :data="modelList" :border="true" size="small"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }" id="echartsTable">
            <el-table-column :label="item" v-for="(item, index) in mokuaiTableTitle" :key="item" align="center"
                             style="user-select: initial">
              <template slot-scope="scope">
                <span v-for="(item2, index2) in scope.row" :key="index2">
                  <span v-if="index2 == index">
                    {{ scope.row[index2] }}
                  </span>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/syc1.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height: 94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>

      </div>
    </template>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button type="primary" v-has-role="'freeusers'" plain @click="excelbtn2">
          导出
        </el-button>
      </div>
    </div>



      <div class="g-content-top">
        <div flex="1">
          <!-- {{ sycz }} -->
          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">商用车分车型整体预测情况&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="sycTip" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                  class="el-icon-info"></i>预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
    <template v-if="show && !showP">
      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;margin-bottom: 20px;">
        商用车分车型整体预测情况
      </div>
      <div id="sycChart"></div>
      <div class="g-content-bottom">
        <span>单位：万辆</span>
        <el-table :data="sycTable" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                  style="width: 100%; margin-bottom: 20px" row-key="id" default-expand-all
                  :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe>
          <template v-for="(item, index) in sycHeader">
            <el-table-column :prop="item" :label="item" :key="'syc' + index" v-if="item == '类别'" min-width="130"/>
            <el-table-column :prop="item" :label="item" :key="'syc' + index" v-else min-width="100"/>
          </template>
        </el-table>
      </div>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/syc2.jpg" alt=""/>
      <center style="height: 1px;">
        <div
            style="position:relative;width:300px;height: 94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
            v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>

    </div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button class="text-right" type="primary" v-has-role="'freeusers'" plain @click="exportCycFcx">
          导出
        </el-button>

      </div>
    </div>
    <template>
<!--      <div class="g-content-top">-->
<!--        <div flex="1">-->
<!--          &lt;!&ndash; {{ cycz }} &ndash;&gt;-->
<!--          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">商用车分车型情况预测&nbsp;</span>-->
<!--            <el-tooltip class="item" effect="dark" :content="sycTip" placement="top-start">-->
<!--              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i-->
<!--                  class="el-icon-info"></i>预测说明</a>-->
<!--            </el-tooltip>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;margin-bottom: 20px;    margin-top: 40px;">
        商用车各车型预测趋势分析
      </div>
      <template v-if="show && !showP">

      <div id="hcChart" style="float: left"></div>
      <div id="kcChart" style="float: right"></div>

      <div style="margin: 0 60px 60px 60px" v-has-role="'freeusers'">
        <!-- 表格数据 -->

        <div>
          <el-table :data="cycModelList" :border="true" size="small"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }" id="cycFcxTable"
                    style="display: none">
            <el-table-column :label="item" v-for="(item, index) in cycTitle" :key="item" align="center"
                             style="user-select: initial">
              <template slot-scope="scope">
                <span v-for="(item2, index2) in scope.row" :key="index2">
                  <span v-if="index2 == index">
                    {{ scope.row[index2] }}
                  </span>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/syc3.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height: 94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>

      </div>
    </template>
  </div>


</template>
<script>
import {
  selectMonthSycTotal,
  exportPredictCyc,
  selectMonthSycHc,
  selectMonthSycKc,
  exportPredictSyc, selectMouseTotalSyc,
} from "@/api/sale.js";
import * as echarts from "echarts";
import JsonExcel from "vue-json-excel";
import {downloadFile} from "@/utils/download.js";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {buryPoint} from "@/api/common";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      cychartData: [],
      cycTable: [],
      cycHeader: [],
      cycupdateTime: "",
      cycz: "",
      leftTotalList: [],
      cycTip: "乘用车预测，基于乘用车市场新购、增购和换购趋势，结合宏观经济、政策、季节、厂商、库存等影响因素对未来销量进行预测。（数据来源：中汽协批发量数据）",
      sycTable: [],
      sycupdateTime: "",
      sycz: "",
      sycTip: "商用车预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考。（数据来源：中汽协批发量数据，历史数据为实际销量）",
      sycHeader: [],
      syctotal: [],
      nowYear: "",
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
      mokuaiTableTitle: [],
      modelList: [],
      cycTitle: [],
      cycModelList: [],
      danWei:"单位：万辆，%"
    };
  },
  created() {

    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        }else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showP = true;
          }
        }
      }
      this.init();
      this.show = true;
    } else {
      this.show = false;
    }

  },
  methods: {


    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    init() {
      selectMonthSycTotal().then((res) => {
        if (res.ok) {
          // console.log(res)
          let myChart = echarts.init(document.getElementById("sycZtChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);

          data.xAxisData.unshift("分类");
          this.mokuaiTableTitle = data.xAxisData;
          this.modelList = [];
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.modelList.push(data.seriesData[x]);
          }
        }
      });
      selectMouseTotalSyc().then((res) => {
        if (res.ok) {
          //时间
          let timeList = res.data.timeList;
          //更新时间
          this.sycupdateTime = res.data.updateTime;
          //日期
          let strTime = timeList[0];
          let endTime = timeList[timeList.length - 1];
          this.fromData(strTime);
          this.fromData(endTime);
          this.sycz =
              this.fromData(strTime) + "至" + this.fromData(endTime);
          //提示信息
          this.initChart2("sycChart", res.data);

          //初始化表格
          let table = [];
          //客车大类
          let dataResultKcMap = res.data.dataResultKcMap;
          //货车大类
          let dataResultHcMap = res.data.dataResultHcMap;
          //客车数据
          let dataKcMap = res.data.dataKcMap;
          //货车数据
          let dataHcMap = res.data.dataHcMap;
          //合计
          let totalList = res.data.totalList;

          //处理货车大类数据
          for (let key in dataResultHcMap) {
            let obj = {};
            obj["类别"] = key;
            for (let i = 0; i < timeList.length; i++) {
              if (dataResultHcMap[key][i]) {
                obj[timeList[i]] =
                    dataResultHcMap[key][i].toFixed(2);
              }
            }
            obj["合计"] = this.counts(dataResultHcMap[key]);
            obj.children = [];
            table.push(obj);
          }

          //处理客车大类数据
          for (let key in dataResultKcMap) {
            let obj = {};
            obj["类别"] = key;
            for (let i = 0; i < timeList.length; i++) {
              if (dataResultKcMap[key][i]) {
                obj[timeList[i]] =
                    dataResultKcMap[key][i].toFixed(2);
              }
            }
            obj["合计"] = this.counts(dataResultKcMap[key]);
            obj.children = [];
            table.push(obj);
          }
          //空数据处理
          let emptyData = []
          //处理客车数据
          let kcTable = [];
          for (let key in dataKcMap) {
            if (dataKcMap[key]) {
              let obj = {};
              obj["类别"] = key;
              for (let i = 0; i < timeList.length; i++) {
                obj[timeList[i]] = dataKcMap[key][i].toFixed(2);
              }
              obj["合计"] = this.counts(dataKcMap[key]);
              kcTable.push(obj);
            }
          }

          //处理货车数据
          let hcTable = [];
          for (let key in dataHcMap) {
            if (dataHcMap[key]) {
              let obj = {};
              obj["类别"] = key;
              for (let i = 0; i < timeList.length; i++) {
                obj[timeList[i]] = dataHcMap[key][i].toFixed(2);
              }
              obj["合计"] = this.counts(dataHcMap[key]);
              hcTable.push(obj);
            }
          }

          for (let i = 0; i < table.length; i++) {
            table[i]["id"] = i + 1;

            if (table[i].类别 == "客车") {
              for (let j = 0; j < kcTable.length; j++) {
                kcTable[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = kcTable;
            } else if (table[i].类别 == "货车") {
              for (let j = 0; j < hcTable.length; j++) {
                hcTable[j]["id"] = i + 1 + "" + (j + 1);
              }
              table[i].children = hcTable;
            }
          }

          //处理合计数据
          let zjObj = {};
          zjObj["类别"] = "总计";
          for (let i = 0; i < timeList.length; i++) {
            zjObj[timeList[i]] = totalList[i].toFixed(2);
          }
          zjObj["合计"] = this.counts(totalList);
          table.push(zjObj);
          this.sycTable = table;
          this.sycHeader = ["类别"].concat(timeList, "合计");

          for (let i = 0; i < table.length; i++) {
            this.syctotal.push(table[i]["合计"]);
            if (table[i].children && table[i].children.length > 0) {
              for (let j = 0; j < table[i].children.length; j++) {
                this.syctotal.push(
                    table[i].children[j]["合计"]
                );
              }
            }
          }

        }
      });
      selectMonthSycHc().then((res) => {
        if (res.ok) {
          let myChart = echarts.init(document.getElementById("hcChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);

          data.xAxisData.unshift("分类");
          data.xAxisData.unshift("类别");
          this.cycTitle = data.xAxisData;
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            data.seriesData[x].unshift("货车");
            this.cycModelList.push(data.seriesData[x]);
          }
          // console.log(this.cycModelList)

        }
      });
      selectMonthSycKc().then((res) => {
        if (res.ok) {
          let myChart = echarts.init(document.getElementById("kcChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);


          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            data.seriesData[x].unshift("客车");
            this.cycModelList.push(data.seriesData[x]);
          }
          // console.log(this.cycModelList)

        }
      });

    },

    exportEvent() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        logRecords('预测','商用车月度预测','3','tt_year_month_predict_syc','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let grid = XLSX.utils.table_to_book(
                  document.querySelector("#echartsTable"),
                  {raw: true}
              );
              let workbook = XLSX.write(grid, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(
                    new Blob([workbook], {
                      type: "application/octet-stream",
                    }),
                    "商用车预测趋势分析.xlsx"
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, workbook);
              }
              return workbook;
            }

          }

        } else {
          let grid = XLSX.utils.table_to_book(
              document.querySelector("#echartsTable"),
              {raw: true}
          );
          let workbook = XLSX.write(grid, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                new Blob([workbook], {
                  type: "application/octet-stream",
                }),
                "商用车预测趋势分析.xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, workbook);
          }
          return workbook;
        }
      } else {
        this.showLogin();
      }


    },
    exportCycFcx() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        logRecords('预测','商用车月度预测','3','tt_year_month_predict_syc','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let grid = XLSX.utils.table_to_book(
                  document.querySelector("#cycFcxTable"),
                  {raw: true}
              );
              let workbook = XLSX.write(grid, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(
                    new Blob([workbook], {
                      type: "application/octet-stream",
                    }),
                    "商用车各车型情况预测.xlsx"
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, workbook);
              }
              return workbook;
            }

          }

        } else {
          let grid = XLSX.utils.table_to_book(
              document.querySelector("#cycFcxTable"),
              {raw: true}
          );
          let workbook = XLSX.write(grid, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                new Blob([workbook], {
                  type: "application/octet-stream",
                }),
                "商用车各车型情况预测.xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, workbook);
          }
          return workbook;
        }
      } else {
        this.showLogin();
      }


    },
    initChart(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var xData = data.timeList;
      var dataMap = data.dataMap;
      var tbzsList = data.tbzsList.map((item) => Number(item));
      // var vehicleList = data.vehicleList;
      var vehicleList = [];
      var seriesData = [];

      for (let key in dataMap) {
        vehicleList.push(key);
        seriesData.push({
          name: key,
          type: "bar",
          stack: "Search Engine",
          data: dataMap[key],
        });
      }
      seriesData.push({
        name: "同比增速",
        type: "line",
        yAxisIndex: 1,
        data: tbzsList,
      });

      var option = {
        title: {
          subtext: "万辆",
        },

        legend: {
          data: vehicleList.concat("同比增速"),
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = datas[i].value
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true }

          }
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: seriesData,
      };
      myChart.clear();
      return option && myChart.setOption(option, true);
    },
    initChart2(id, data) {
      var myChart = echarts.init(document.getElementById(id));
      var xData = data.timeList;
      var dataMap = Object.assign({}, data.dataHcMap, data.dataKcMap);
      // var tbzsList = data.tbzsMap.map((item) => Number(item));
      var vehicleList = [];
      var seriesData = [];

      if (dataMap) {
        for (let key in dataMap) {
          vehicleList.push(key);
        }
      }

      for (let key in dataMap) {
        seriesData.push({
          name: key,
          type: "bar",
          stack: "Search Engine",
          data: dataMap[key],
        });
      }
      // seriesData.push({
      //   name: "同比增速",
      //   type: "line",
      //   yAxisIndex: 1,
      //   data: tbzsList,
      // });
      var option = {
        title: {
          subtext: "万辆",
        },

        legend: {
          data: vehicleList,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = datas[i].value
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true }

          }
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          // {
          //   type: "value",
          //   axisLabel: {
          //     formatter: "{value} %",
          //   },
          //   splitLine: {
          //     show: false,
          //   },
          //   axisLine: {
          //     show: true,
          //   },
          //   axisTick: {
          //     show: true,
          //   },
          // },
        ],
        series: seriesData,
      };
      myChart.clear();
      myChart.setOption(option);
    },

    fromData(str) {
      let index = str.indexOf("F");
      let s = "";
      if (index != -1) {
        s = str.slice(0, index);
      } else {
        s = str;
      }
      return s;
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //乘用车导出
    excelbtn() {
      if (getUserInfo()) {

        logRecords('预测','商用车月度预测','3','tt_year_month_predict_syc','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let self = this;
              let obj = {leftTotalList: this.leftTotalList};
              exportPredictCyc(obj).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "乘用车数据.xlsx");
                  }
                });
              });
            }
          }
        } else {
          let self = this;
          let obj = {leftTotalList: this.leftTotalList};
          exportPredictCyc(obj).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "乘用车数据.xlsx");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //商用车导出
    excelbtn2() {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let self = this;
              let obj = {leftTotalList: this.syctotal};
              exportPredictSyc(obj).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "商用车分车型整体预测情况.xlsx");
                  }
                });
              });
            }
          }
        } else {
          let self = this;
          let obj = {leftTotalList: this.syctotal};
          exportPredictSyc(obj).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "商用车分车型整体预测情况.xlsx");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }

    },

    //计算数组的和
    counts(arr) {
      let count = 0;
      if (arr.length > 0) {
        arr.forEach((item) => {
          count += item;
        });
      }
      return count.toFixed(2);
    },
  },
};
</script>
<style lang="scss" scoped>
h3 span {
  font-weight: bold;
}

#sycZtChart,
#sycChart {
  width: 100%;
  height: 500px;
}

#hcChart, #kcChart {
  width: 50%;
  height: 500px;
}

.g-content-bottom {
  margin-top: 20px;
}

.el-icon-info {
  margin-right: 2px;
}

//.search-form {
//  height: 30px;
//}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
