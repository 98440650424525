<template>
  <!--  <section class="layout-container" id="layout-container"-->
  <!--           :style=" hfshow?'height:calc( 100% - 120px )' : 'height:calc( 100%  )' ">-->

  <section class="layout-container" id="layout-container">

    <template v-if="checkLoginName == 'zgqcgyxxw'">

      <!--      <transition name="slider">-->
      <!--        <div id="fadeId" v-if="hfshow">-->
      <!--          <span style="color: white;float: right;padding-right: 20px;cursor: pointer" @click="isShow">关闭</span>-->

      <!--        </div>-->

      <!--        &lt;!&ndash;        <div id="fadeId2" v-else></div>&ndash;&gt;-->

      <!--        &lt;!&ndash;        <a >&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img :class="fadeClass" src="@/assets/img/gqtz2.jpg" alt="" style="max-width:100%" v-if="hfshow">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="@/assets/img/gqtz3.jpg" alt="" style="max-width:100%" v-else @mouseover="mouseEnter()"&ndash;&gt;-->
      <!--        &lt;!&ndash;               @mouseleave="mouseLeave()">&ndash;&gt;-->
      <!--        &lt;!&ndash;        </a>&ndash;&gt;-->

      <!--      </transition>-->

      <!--      <transition name="slider">-->
      <!--        <div id="fadeId2" v-if="!hfshow"></div>-->

      <!--        &lt;!&ndash;        <a >&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img :class="fadeClass" src="@/assets/img/gqtz2.jpg" alt="" style="max-width:100%" v-if="hfshow">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="@/assets/img/gqtz3.jpg" alt="" style="max-width:100%" v-else @mouseover="mouseEnter()"&ndash;&gt;-->
      <!--        &lt;!&ndash;               @mouseleave="mouseLeave()">&ndash;&gt;-->
      <!--        &lt;!&ndash;        </a>&ndash;&gt;-->

      <!--      </transition>-->
    </template>
    <template v-if="checkLoginName == 'zgqcgyxxw'">
      <VtopNav @command="commandTopNav" @click="showLogin()" :show="show" :userName="userName"/>
    </template>
    <header class="layout-header">
    </header>
    <section class="layout-container" :class="
            checkLoginName == 'zgqcgyxxw'
                ? 'layout-container-has-sider'
                : 'layout-container-has-sider-fh'
        ">
      <section class="layout-container">
        <main class="layout-main" ref="layoutMain" id="layoutMain" @scroll="scrollFun" v-loading="loading"
              element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.3)">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </main>
      </section>
    </section>
    <Vfeedback :asideData="selectFirstMenuData" v-if="shows" @close="shows = false" @submit="feedbackBtn"/>
    <VSySq v-if="sySqShow" @close="sySqShow = false" @click="sySqBtn"/>

    <!--    <VloginImg v-if="loginFlag" @close="loginFlag = false"/>-->
    <Vcollection :asideData="selectFirstMenuData" v-if="colShow" @close="colShow = false"/>

    <el-backtop target=".layout-main" :bottom="80" :right="10">
      <div class="back-top-inner">
        <i class="el-icon-arrow-up" />
        TOP
      </div>
    </el-backtop>
    <el-dialog

        :visible.sync="dialogVisible"
        top=0
        style="height:auto;"
        :modal="false">
      <div style="height: auto">
        <iframe
            :src="iframeSrc"
            id="bdIframe"
            style="width:100%;height:100%;"
            frameborder="0"
            scrolling="yes">
        </iframe>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
  </span>
    </el-dialog>
    <el-dialog title="绑定手机号" :visible.sync="dialogFormVisible" width="600px">
      <el-form :model="form" ref="ruleForm" :rules="rules">
        <el-form-item label="账号：" :label-width="formLabelWidth">
          {{ userName }}
        </el-form-item>
        <el-form-item label="绑定手机号：" :label-width="formLabelWidth" prop="bindPhone">
          <el-input v-model="form.bindPhone" autocomplete="off" prefix-icon="el-icon-phone" placeholder="请输入手机号"></el-input>

        </el-form-item>
        <el-form-item label="短信验证码" prop="yzm" :label-width="formLabelWidth">
          <el-input style="width: 300px;"
                    v-model="form.yzm"
                    prefix-icon="el-icon-key"
                    placeholder="请输入6位短信验证码"
          >
          </el-input>
          <el-button style="height: 40px; width: 136px;margin-left: 4px;" type="primary" @click="getCode()"
                     :class="{'disabled-style':getCodeBtnDisable}" :disabled="getCodeBtnDisable">{{ codeBtnWord }}
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">绑 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>

      </div>
    </el-dialog>
    <el-dialog title="绑定手机号" :visible.sync="dialogFormVisible2" width="500px">
      <el-form>
        <el-form-item label="账号：" :label-width="formLabelWidth">
          {{ userName }}
        </el-form-item>
        <el-form-item label="绑定手机号：" :label-width="formLabelWidth">
          {{ bindPhone }}
          <!--          <a style="margin-left: 10px" @click="dialogFormVisible2 = false;dialogFormVisible = true"><i class="el-icon-edit"></i>修改</a>-->
          <el-button style="margin-left: 20px" type="text" icon="el-icon-edit"
                     @click="dialogFormVisible2 = false;dialogFormVisible = true">修改
          </el-button>

          <el-popover
              placement="top"
              width="230"
              v-model="visible">
            <p>确定解绑手机号 {{ bindPhone }} ?</p>
            <div style="text-align: right; margin-top: 10px">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="unBinding">确定</el-button>
            </div>
            <el-button style="color: red" type="text" icon="el-icon-delete" slot="reference">解绑</el-button>

          </el-popover>

          <!--          <el-button type="primary" icon="el-icon-edit" @click="dialogFormVisible2 = false;dialogFormVisible = true">修改</el-button>-->
          <!--          <el-button type="danger" icon="el-icon-delete">解绑</el-button>-->
        </el-form-item>

      </el-form>
      <!--      <div slot="footer" class="dialog-footer">-->
      <!--        <el-button type="primary" @click="dialogFormVisible2 = false;dialogFormVisible = true">修 改</el-button>-->
      <!--        <el-button type="danger" @click="submitForm('ruleForm')">解 绑</el-button>-->
      <!--        <el-button @click="dialogFormVisible2 = false">取 消</el-button>-->

      <!--      </div>-->
    </el-dialog>
  </section>

</template>
<script>
import $ from 'jquery'
import VtopNav from "../components/topNav.vue";
import Vnav from "../components/nav.vue";
import Vnavfuao from "@/components/navfuao.vue";
import VSySq from "@/components/sySqTk.vue";

import Vfeedback from "@/components/feedback.vue";
import VloginImg from "@/components/loginImg.vue";
import Vcollection from "@/components/collection.vue";

import {getUserInfo, removetUserInfo} from "@/utils/auth.js";
import {logout, sendBindPhoneMessage} from "@/api/login.js";
import {mapGetters} from "vuex";
import store from "@/store";
//菜单管理
import {selectFirstMenu} from "@/api/menu.js";
//意见反馈
import {ttFeedbackManagement} from "@/api/feedback.js";
import {getCurrentUser} from "@/api/login.js";

export default {
  components: {
    VtopNav,
    Vfeedback,
    VSySq,
    Vcollection,
  },
  data() {
    var validateUserPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写手机号码"));
      } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    var validatePhoneYzm = (rule, value, callback) => {
      // console.log(this.yzm)
      if (value === "") {
        callback(new Error("请填写验证码"));
      } else if (value != this.yzm) {
        callback(new Error("验证码不正确"));
      } else {
        callback();
      }
    };
    return {
      childrenMent: [],
      show: false,
      userName: "",
      hidden: 1,
      checkLoginName: "",
      //意见反馈菜单
      selectFirstMenuData: [],
      shows: false,
      visible: false,
      //收藏
      colShow: false,
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      hfshow: true,
      loginFlag: false,
      sySqShow: false,
      fjtzUrl: require("@/assets/img/fjtz.jpg"),
      dialogVisible: false,
      iframeSrc: "https://www.wjx.cn/vm/hyVeQ3K.aspx",
      dialogFormVisible: false,
      dialogFormVisible2: false,
      waitTime: 61, // 获取验证码按钮失效时间
      form: {
        bindPhone: '',
        userId: '',
        yzm: ''
      },
      formLabelWidth: '120px',
      rules: {
        bindPhone: [
          {required: true, trigger: 'blur', validator: validateUserPhone} //写入验证器
        ],
        yzm: [{required: true, validator: validatePhoneYzm, trigger: "blur"}],
      },
      yzm: "",
      bindPhone: "",
      timer:""
    };
  },
  computed: {
    ...mapGetters({routerMenu: "permission_routes", loading: "loading"}),
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          let reg = /^1[3456789]\d{9}$/
          if (reg.test(this.form.bindPhone)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {
      }
    },
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form.bindPhone)) {
        return false
      }
      return true
    },
  },
  created() {
    // this.hfplayer();

    // this.loginFlag = getUserInfo().loginFlag;

    this.checkLoginName = process.env.VUE_APP_CHECKLOGIN;
    let pId = this.$route.meta.acName || "";
    this.hidden = this.$route.meta.hidden;
    this.filterPath(pId);
    let userInfo = getUserInfo();
    if (this.checkLoginName == "zgqcgyxxw") {
      if (getUserInfo()) {
        this.getCurrentUser();

        this.form.userId = userInfo.userId;
        this.userName = userInfo.loginName;
        this.show = true;
      } else {
        this.show = false;
        // if(this.$route.name == "首页"){ //放假通知
        //   this.opens();
        // }
      }
    } else {
      if (!getUserInfo()) {
        this.$router.push("/login");

      } else {

        this.form.userId = userInfo.userId;

        this.userName = userInfo.loginName;
        this.show = true;
      }
    }
  },
  watch: {
    $route(to) {
      this.$refs.layoutMain.scrollTop = 0;
      this.hidden = to.meta.hidden;
      this.filterPath(to.meta.acName);
    },
  },
  mounted() {
    this.selectFirstMenu();
    store.commit("scoll/getPheight", this.$refs.layoutMain.offsetHeight);
    // this.hfplayer()
  },
  methods: {
    isvalidPhone(phone) {
      const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
      return reg.test(phone)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateBindPhone(this.form).then((res) => {
            if (res.ok) {
              this.dialogFormVisible = false
              this.$message.success("绑定成功");
              this.form.bindPhone='';
              this.form.yzm='';
              clearInterval(this.timer);
              this.codeBtnWord = '获取验证码'
              this.getCodeBtnDisable = false
              this.waitTime = 61
            }
          });
        } else {
          return false;
        }
      });
    },
    unBinding() {
      updateBindPhone({'userId': this.form.userId, 'bindPhone': ''}).then((res) => {
        if (res.ok) {
          this.$message.success("解绑成功");
          this.visible = false;
          this.dialogFormVisible2 = false
        }
      });
    },
    getCode() {
      if (this.phoneNumberStyle) {
        let params = {}
        params.receiver = this.form.bindPhone
        // 调用获取短信验证码接口
        sendBindPhoneMessage(params).then(res => {
          if (res.ok) {
            this.yzm = res.data;

            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success',
              center: true
            })
            // 因为下面用到了定时器，需要保存this指向
            let that = this
            that.waitTime--
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            this.timer = setInterval(function () {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(this.timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
              }
            }, 1000)
          }
        })

      }
    },
    opens() {
      this.showTz = false;
      this.$alert('', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
      }).catch(() => {
        this.showTz = true;
      });
      var el = document.querySelector('.el-message-box');
      if (el) el.style.cssText = 'width: 960px;height: 450px;background-image: url(' + this.fjtzUrl + ');background-size:960px 450px;'
      var el1 = document.querySelector(' .el-message-box__headerbtn .el-message-box__close');
      if (el1) el1.style.cssText = 'color: white;'
      var el2 = document.querySelector(' .el-message-box__headerbtn');
      if (el2) el2.style.cssText = ' top: 9px;right: 14px;'
    },
    isShow() {
      this.hfshow = false;
    },
    mouseEnter() {

      this.fadeClass = '';
      this.hfshow = true;
    },
    mouseLeave() {
      // this.fadeClass="animate__animated animate__fadeOutUp duration";
      // clearInterval();
      // this.tpImg = require('@/assets/img/gqtz3.jpg');
      this.fadeClass = '';

      this.hfshow = false;
    },
    //获取子菜单
    filterPath(name) {
      let filter = this.routerMenu.filter((item) => {
        return item.name == name;
      });

      if (filter.length > 0 && filter[0].children) {
        this.childrenMenu = filter[0].children.filter(
            (item) => item.meta.hidden == 0
        );

      } else {
        this.childrenMenu = [];
      }
      this.childrenMenu.forEach((item, index) => {
        if (item.children) {

          this.childrenMenu[index].children = item.children.filter(
              (item) => item.meta.hidden == 0
          )
        }

      })


    },
    getCurrentUser() {
      getCurrentUser().then((res) => {
        if (res.ok) {
          this.loginFlag = res.data.loginFlag;
          this.bindPhone = res.data.bindPhone;

          if (this.loginFlag && getUserInfo().roleKey == "paidrole" && getUserInfo().menuName.includes("数据")) {

            this.dialogVisible = true

            // setTimeout(() => {
            //   this.loading = false;
            // }, 2000);

            setTimeout(function () {
              /**
               * iframe-宽高自适应显示
               */
              const oIframe = document.getElementById("bdIframe");
              const deviceWidth = document.documentElement.clientWidth;
              const deviceHeight = document.documentElement.clientHeight;
              // oIframe.style.width = Number(deviceWidth) - 220 + "px"; //数字是页面布局宽度差值
              oIframe.style.width = Number(deviceWidth); //数字是页面布局宽度差值
              oIframe.style.height = Number(deviceHeight) - 150 + "px"; //数字是页面布局高度差
            }, 100);
          }
        }
      });
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin({flag: true});
    },
    //下拉菜单
    scrollFun(e) {
      store.dispatch("scoll/setPosition", e.target.scrollTop);
    },
    selectFirstMenu() {
      selectFirstMenu().then((res) => {
        if (res.ok) {
          this.selectFirstMenuData = res.data;

          this.selectFirstMenuData = res.data.filter(
              (item) => !item.name.includes("商用车")
          );
          // console.log(this.selectFirstMenuData)
        }
      });
    },
    commandTopNav(name) {

      if (name == "contact") {
        this.$refs.layoutMain.scrollTop =
            this.$refs.layoutMain.scrollHeight;
        return;
      }
      if (!getUserInfo()) {
        this.showLogin();
        return false;
      }
      if (name == "bindPhone") {
        getCurrentUser().then((res) => {
          if (res.ok) {
            this.bindPhone = res.data.bindPhone;
            if (this.bindPhone != "" && this.bindPhone != null) {
              this.dialogFormVisible2 = true;

            } else {
              this.dialogFormVisible = true;

            }
          }
        });


      }
      if (name == "feedback") {
        this.shows = true;
      }
      if (name == "sysq") {
        this.sySqShow = true;
      }
      if (name == "logout") {
        this.$confirm("是否退出登录", "提示", {
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          logout().then((res) => {
            if (res.ok) {
              sessionStorage.removeItem('version')
              if (this.checkLoginName == "zgqcgyxxw") {
                removetUserInfo();
                window.location = "/";
              } else {
                removetUserInfo();
                this.$router.push("/login");
              }
            }
          });
        });
        var el = document.querySelector('.el-message-box');
        if (el) el.style.cssText = ''
        var el1 = document.querySelector(' .el-message-box__headerbtn .el-message-box__close');
        if (el1) el1.style.cssText = ''
        var el2 = document.querySelector(' .el-message-box__headerbtn');
        if (el2) el2.style.cssText = ''
      }

      if (name == "collection") {
        this.colShow = true;
      }
    },
    feedbackBtn(item) {
      ttFeedbackManagement(item).then((res) => {
        if (res.ok) {
          this.$message({
            message: "反馈成功",
            type: "success",
          });
          this.shows = false;
        }
      });
    },
    sysqBtn() {

    },
    handleSelect() {
      this.$store.dispatch("notice/SET_PC", 0);
      this.$store.dispatch("notice/SET_CPLB", "");
      this.$store.dispatch("common/SET_qiehuan", "");
    },
    ceshi(item) {

      // if (!getUserInfo()) {
      //   if (item.name == '国内产销数据' || item.name == '乘用车分车型' || item.name == '出口数据' || item.name == '汽车保有量' || item.name == '经济指标' || item.name == '分地区产量'
      //       || item.name == '发动机数据' || item.name == '世界汽车工业' || item.name == '推荐车型目录' || item.name == '免征购置税目录' || item.name == '道路运输车辆达标车型' || item.name == '宏观环境'
      //   ) {
      //     this.showLogin2();
      //   }
      // }


      this.$router.push(item.path);
      // let name = this.$route.name;
      // console.log(name);
      // if(name=='产能分布详细'){
      //
      // }

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin2({flag: true});
    },
    searchBtn(e) {
      if (this.$route.path != "/FullTextRetrieval") {
        this.$router.push({
          path: "/FullTextRetrieval",
          query: {keywords: e},
        });
      }
    },


    // hfplayer() {
    //   setInterval(() => {
    //     $('#fadeId').slideUp('slow');
    //   }, 6000);
    //
    //   setTimeout(() => {
    //     this.hfshow = false;
    //   }, 6600);
    //   // setInterval(() => {
    //   //   $('#fadeId2').slideDown(2000);
    //   // }, 9000);
    // },
  },
};
</script>
<style lang="scss" scoped>
.layout-container {
  min-height: 0;
}

.layout-container {
  background: #f6f6f6;
}

.layout-main {
  position: relative;
  min-height: 0;
}

.layout-main.hide {
  margin: 0;
}

#layout-container .layout-header,
#layout-container .layout-sider {
  background: #fff;
}

#layout-container .layout-sider {
  float: left;
  margin-right: 24px;
  margin-top: 10px;
  overflow-y: auto;

  //  box-shadow: 0px 4px 8px rgba(214, 214, 214, 0.5);
  .el-menu-item.is-active,
  .el-menu-item:hover {
    background: rgba($color: #0d57bc, $alpha: 0.03);
    color: #0d57bc;
  }
}

#layout-container .layout-header {
  padding: 0;
}

#layout-container .el-menu {
  border-right: none;
}

#layout-container .el-menu .el-menu-item {
  font-weight: bold;
}


.slider-leave-active {
  transition: all 1s;
  transform: translate3d(0, -74px, 0);
}

.duration {
  animation-duration: 8s
}

#fadeId {
  height: 120px;
  width: 100%;
  background: url("../assets/img/xtwh1.jpg") center center no-repeat;
}

#fadeId2 {
  max-width: 100%;
  overflow: hidden;
  height: 100px;
  background: url("../assets/img/gqtz3.jpg") center center no-repeat;
}

#fadeId2:hover {
  max-width: 100%;
  overflow: hidden;
  height: 350px;
  background: url("../assets/img/gqtz2.jpg") center center no-repeat;
}


//.bounce-enter-active {
//  animation: bounce-on 5s;
//}
//.bounce-leave-active {
//  animation: bounce-on 5s reverse;
//}
//@keyframes bounce-on {
//  0% {
//    transform: scale(0);
//  }
//  50% {
//    transform: scale(1.5);
//  }
//  100% {
//    transform: scale(1);
//  }
//}

</style>
<style>
#layout-container .el-menu .el-submenu .el-submenu__title {
  font-weight: bold !important;

}

#layout-container .el-menu-item-group__title {
  padding: 0px 0 0px 20px !important;
}


</style>
