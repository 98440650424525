import { render, staticRenderFns } from "./listExplain.vue?vue&type=template&id=5ac4404c&scoped=true"
import script from "./listExplain.vue?vue&type=script&lang=js"
export * from "./listExplain.vue?vue&type=script&lang=js"
import style0 from "./listExplain.vue?vue&type=style&index=0&id=5ac4404c&prod&lang=scss&scoped=true"
import style1 from "./listExplain.vue?vue&type=style&index=1&id=5ac4404c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac4404c",
  null
  
)

export default component.exports