<template>
  <PageContainer breadcrumb>
    <VlistTitle big-ttitle="征求/报批意见稿" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />
    <div style="overflow: hidden" class="box_box">
      <!-- <div style="float: left" flex="c">
          <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
              <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">{{ item.dictLabel }}</el-checkbox>
          </el-checkbox-group>
      </div> -->
      <div style="float: right">
        <el-input v-model="title" placeholder="搜索名称" size="small" @keyup.enter.native="search">
          <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="search" />
        </el-input>
      </div>
    </div>

    <Vlist
      v-if="pageShow"
      :list="datas"
      :page-data="pageData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { solicitComments } from '@/api/standards.js'
import Vlist from '@/components/list'
import { getUserInfo, logRecords, openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'

export default {
  components: {
    VlistTitle,
    Vlist
  },
  data() {
    return {
      bgurl: require('@/assets/img/Group299.png'),
      datas: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    init() {
      if (!getUserInfo() && this.pageData.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.pageData.pages, {
          title: this.title
        })
        solicitComments(form).then((res) => {
          if (res.ok) {
            this.datas = res.data.list
            this.pageData.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.page = val
        this.init()
      }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve(
        '/standards/dynamic/newComments/details/' + item.id
      )
      if (getUserInfo()) {
        logRecords('标准', '征求意见稿', '1', 'tt_solicit_comments', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 切换
    handleChange(e) {
      this.init()
    },
    search() {
      this.pageData.pages.page = 1
      this.init()
      logRecords('标准', '征求意见稿', '4', 'tt_solicit_comments', '', '', '', this.title)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
