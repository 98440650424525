<template>
  <PageContainer>
    <template>
      <div class="FactoryPartsDistributor page-container" style="padding: 0;">
        <div class="column-main" style="background:#fff;">
            <div style="padding:24px;background:#fff;">
              <div class="query-option statistic-list">
                <div class="title" style="margin-top: 0px;"><img src="@/assets/images/icon21.png" style="width: 16px;" />类型</div>
                <div class="statistic-list-container" style="margin-left: 20px;">
                  <el-checkbox-group v-model="typeSearch" @change="changeTypes">
                    <el-checkbox :label="'工厂'">工厂</el-checkbox>
                    <el-checkbox :label="'零部件'">零部件</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="query-option statistic-list" style="width: 100%!important; margin-bottom: 20px; align-items: flex-start;">
                <div class="title" style="margin-top: 0px;"><img src="@/assets/images/icon20.png" style="width: 16px;" />国家</div>
                <div class="carType" style="margin-left: 15px;">
                  <div style="margin-bottom: 10px;" class="hotCountry">
                    <img src="@/assets/images/icon22.png" />
                    <el-checkbox-group v-model="countrysSearch">
                      <el-checkbox style="height: 24px; line-height: 24px;" v-for="(item,index) in countryList5" :key="index" :label="item.dictLabel">{{ item.dictLabel }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div style="display: flex; align-items: flex-start;">
                    <div class="statistic-list-container listBg" :class="{ ishide: !isupDown3}">
                      <div v-for="(item, index) in countryList4" :key="index" style="display: flex; align-items: flex-start">
                        <div style="width: 90px;" class="tabCheck">
                          <el-checkbox :key="item.name" :label="item.name" :indeterminate="item.checked1" v-model="item.checked" @change="changeCars1(item, index, 'countrysSearch')" style="color: #0D57BC;">{{ item.name }}:</el-checkbox>
                        </div>
                        <div style="flex: 1;">
                          <el-checkbox-group v-model="countrysSearch">
                            <el-checkbox v-for="(t, i) in item.children" @change="changeCars2(item,t, index, 'countrysSearch')" :key="i" :label="t.name" style="margin-bottom: 15px;">{{ t.name }}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                    </div>
                    <div class="upDowns" @click="changeUp('isupDown3')" style="margin-top: 10px;">
                      <img src="@/assets/images/icon18.png" v-if="!isupDown3" style="width: 14px;" />
                      <img src="@/assets/images/icon19.png" v-if="isupDown3" style="width: 14px;" />
                      <span>{{ isupDown3 ? '收起' : '展开' }}</span>
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="query-option statistic-list" style="align-items: flex-start; width: 100%;">
                <div class="title" style="margin-top: 10px;"><img src="@/assets/images/icon16.png" style="width: 16px;" />整车厂企业</div>
                <div class="carType" style="margin-left: 15px; width: 100%;">
                  <div class="statistic-list-container listBg" :class="{ ishide: !isupDown1}">
                    <div v-for="(item, index) in systemList1" :key="index" style="display: flex; align-items: flex-start">
                      <div style="width: 90px;" class="tabCheck">
                        <el-checkbox :key="item.name" :label="item.name" :indeterminate="item.checked1" v-model="item.checked" @change="changeCars1(item, index, 'enterpriseSearch1')" style="color: #0D57BC;">{{ item.name }}:</el-checkbox>
                      </div>
                      <div style="flex: 1;">
                        <el-checkbox-group v-model="enterpriseSearch1">
                          <el-checkbox v-for="(t, i) in item.children" @change="changeCars2(item,t, index, 'enterpriseSearch1')" :key="i" :label="t.name" style="margin-bottom: 15px;">{{ t.name }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upDowns" @click="changeUp('isupDown1')">
                  <img src="@/assets/images/icon18.png" v-if="!isupDown1" style="width: 14px;" />
                  <img src="@/assets/images/icon19.png" v-if="isupDown1" style="width: 14px;" />
                  <span>{{ isupDown1 ? '收起' : '展开' }}</span>
                </div>
              </div>
              <div class="query-option statistic-list" style="align-items: flex-start; width: 100%;">
                <div class="title" style="margin-top: 10px;"><img src="@/assets/images/icon17.png" style="width: 16px;" />零部件企业</div>
                <div class="carType" style="margin-left: 15px; width: 100%;">
                  <div class="statistic-list-container listBg" :class="{ ishide: !isupDown2}">
                    <div v-for="(item, index) in systemList2" :key="index" style="display: flex; align-items: flex-start">
                      <div style="width: 90px;" class="tabCheck">
                        <el-checkbox :key="item.name" :label="item.name" :indeterminate="item.checked1" v-model="item.checked" @change="changeCars1(item, index, 'enterpriseSearch2')" style="color: #0D57BC;">{{ item.name }}:</el-checkbox>
                      </div>
                      <div style="flex: 1;">
                        <el-checkbox-group v-model="enterpriseSearch2">
                          <el-checkbox v-for="(t, i) in item.children" @change="changeCars2(item,t, index, 'enterpriseSearch2')" :key="i" :label="t.name" style="margin-bottom: 15px;">{{ t.name }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upDowns" @click="changeUp('isupDown2')">
                  <img src="@/assets/images/icon18.png" v-if="!isupDown2" style="width: 14px;" />
                  <img src="@/assets/images/icon19.png" v-if="isupDown2" style="width: 14px;" />
                  <span>{{ isupDown1 ? '收起' : '展开' }}</span>
                </div>
              </div>
              <div class="text-center">
                <el-button class="reset-button" @click="resetForm1('form1')">重置</el-button>
                <el-button class="search-button" @click="searchForm1">查询</el-button>
              </div>
            </div>
            <!--          map-->
            <div style="margin: 0 auto;padding:24px;background:#fff; width: 100%;">
              <div id="mapDiv1" style="width:94%; height:700px;z-index: 1; margin-left: 3%;" />
            </div>
            <div style="height:10px;width:100%;background-color: #f3f5f7;" />
            <div id="mapDistributorList1" class="search-form" style="padding:24px; background:#fff;">
              <div class="right-btn-box" style="display: block;">
                <div style="float: left;" class="tabsList" :class="{ 'oneTab' : typeSearch.length === 1 }">
                  <el-tabs v-model="classificationName" stretch lazy v-if="typeSearch.length > 0">
                    <el-tab-pane label="工厂" name="name1" v-if="typeSearch.includes('工厂')"></el-tab-pane>
                    <el-tab-pane label="零部件" name="name2" v-if="typeSearch.includes('零部件')"></el-tab-pane>
                  </el-tabs>
                </div>
                <div v-if="typeSearch.length > 0" style="float: right; margin-top: 20px;" class="btn" @click="exportTtInternationalDealers">
                  <img :src="require('@/assets/img/newData/download.png')">下载数据
                </div>
              </div>
              <div v-show="classificationName == 'name1' && typeSearch.includes('工厂')">
                  <el-table
                  :class="{ 'show-mask': !getLogin()}"
                  ref="newTable"
                  v-loading="loading"
                  :data="selectDistributorData1"
                  style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                  border
                >
                  <el-table-column label="序号" type="index" align="center" width="80"/>
                  <el-table-column prop="vehicleManufacturer" label="整车厂" align="center" min-width="100" />
                  <el-table-column prop="continent" label="大洲" align="center" min-width="100" />
                  <el-table-column prop="country" label="国家" align="center" min-width="100" />
                  <el-table-column prop="province" label="州/市" align="center" min-width="100" />
                  <el-table-column prop="factoryName" label="工厂名称" align="center" min-width="150">
                    <template slot-scope="{row}">
                      <span style="cursor: pointer; color: #3f76ea;" @click="getDetails(row)">{{ row.factoryName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="factoryType" label="工厂类别" align="center" min-width="100" />
                  <el-table-column prop="address" label="工厂地址" align="center" min-width="100" />
                  <!-- <el-table-column label="生产车型" align="center" min-width="100">
                    <el-table-column prop="productModelOngoing" label="在产" align="center" min-width="100" />
                    <el-table-column prop="productModelPlan" label="计划" align="center" min-width="100" />
                  </el-table-column> -->
                  <el-table-column prop="supportingProduct" label="生产车型" align="center" min-width="130">
                    <template slot-scope="{row}">
                      <div style="white-space: pre-line; text-align: left;">{{ row.supportingProduct }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="capacity" label="产能(辆/年)" align="center" min-width="130">
                    <template slot-scope="{row}">
                      <div style="white-space: pre-line; text-align: left;">{{ row.capacity }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="totalOutput" label="总产量(辆/年)" align="center" min-width="130">
                    <template slot-scope="{row}">
                      <div style="white-space: pre-line; text-align: left;">{{ row.totalOutput }}</div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="总产量" align="center" min-width="100">
                    <el-table-column prop="totalOutputYear" label="年份" align="center" min-width="100" />
                    <el-table-column prop="totalOutput" label="产辆(辆/年)" align="center" min-width="100" />
                  </el-table-column> -->
                </el-table>
                <div class="text-right">
                  <el-pagination
                    v-if="pageShow"
                    background
                    :class="{ 'show-mask': !getLogin()}"
                    :current-page="selectPolicyPage1.pages.page"
                    :page-sizes="pageSizes"
                    :page-size="selectPolicyPage1.pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="selectPolicyPage1.total"
                    @size-change="handleSizeChange1"
                    @current-change="handleCurrentChange1"
                  />
                </div>
              </div>
              <div v-show="classificationName == 'name2' && typeSearch.includes('零部件')">
                  <el-table
                  :class="{ 'show-mask': !getLogin()}"
                  ref="newTable"
                  v-loading="loading"
                  :data="selectDistributorData2"
                  style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                  border
                >
                  <el-table-column label="序号" type="index" align="center" width="80"/>
                  <el-table-column prop="companyName" label="公司名称" align="center" min-width="100">
                    <template slot-scope="{row}">
                      <span style="cursor: pointer; color: #3f76ea;" @click="getDetails1(row)">{{ row.companyName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="continent" label="大洲" align="center" min-width="80" />
                  <el-table-column prop="country" label="国家" align="center" min-width="90" />
                  <el-table-column prop="city" label="州/市" align="center" min-width="100" />
                  <el-table-column prop="companyAddress" label="公司地址" align="center" min-width="120" />
                  <el-table-column prop="mainProducts" label="主营产品" align="center" min-width="120">
                    <template slot-scope="{row}">
                      <div style="white-space: pre-line;">{{ row.mainProducts }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="supportingCustomers" label="配套客户" align="center" min-width="120">
                    <template slot-scope="{row}">
                      <div style="white-space: pre-line;">{{ row.supportingCustomers }}</div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="text-right">
                  <el-pagination
                    background
                    :class="{ 'show-mask': !getLogin()}"
                    v-if="pageShow"
                    :current-page="selectPolicyPage2.pages.page"
                    :page-sizes="pageSizes"
                    :page-size="selectPolicyPage2.pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="selectPolicyPage2.total"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import {
  getMultipleChoiceCriteria, // 国际法规经销商筛选条件
  ttInternationalDealerList, // 国际法规经销商筛选
  ttInternationalDealerPage,
  exportTtInternationalDealer,
  getfactoryCountry, // 国际法规-工厂及零部件 工厂查所有国家
  getpartsCountry, // 国际法规-工厂及零部件 零部件查所有国家
  getDictionarys, //查询字典
  getfactoryMap, //工厂地图查询
  getpartsMap, //零部件地图查询
  getfactoryList, //工厂分页查询
  getpartsList, //零部件分页查询
  exportTtInternationalDealer1,
  exportTtInternationalDealer2
} from '@/api/data_new.js'
import { ttDemension } from '@/api/dict.js' // 获取所有的大洲和国家
import { getUserInfo, openSy, logRecords } from '@/utils/auth'
import info from './info.vue'
import { buryPoint } from '@/api/common'

export default {
  components: { info },
  name: 'FactoryPartsDistributor',
  data() {
    return {
      pageShow: true,
      mapLabel: null,
      markerLayer: null,
      activeName: 'FactoryParts', // todo wangying初始
      distributorSearchOption: {},
      countrySearch: [],
      countryList: [],
      brandSearch: [],
      brandList: [],
      networkTypeSearch: [],
      networkTypeList: [],
      pageSizes: [10, 20, 30, 40],
      loading: false,
      selectDistributorDate: [],
      selectPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      map: null,
      classificationName: 'name1',
      allNetworkTypeChecked: null,
      markers: null,
      typeSearch: ['工厂'],
      countrysSearch: [],
      countryList1: [],
      countryList2: [],
      countryList3: [],
      countryList4: [],
      countryList5: [], //热门国家
      systemList1: [],
      carList1: [],
      systemList2: [],
      carList2: [],
      allchecked1: false,
      allchecked2: false,
      enterpriseSearch1: [],
      enterpriseSearch2: [],
      map1: null,
      selectPolicyPage1: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      selectPolicyPage2: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      mapLabel1: null,
      selectDistributorData1: [],
      selectDistributorData2: [],
      baseName: '',
      isOnetype: false,
      isexpand: false, //国家是否是展开的状态
      carType1: '',
      carType2: '',
      carData1: {},
      carData2: {},
      allcheckeds1: false,
      allcheckeds2: false,
      isupDown1: false,
      isupDown2: false,
      isupDown3: false //国家的展开收起
    }
  },
  computed: {
    // 是否为国际化的拖拉拽查询页面
    isInternational() {
      return this.$route.meta.acName === '国际化'
    },
    // 当前的数据表菜单 name
    routeTableName() {
      return this.$route.name
    },
  },
  async mounted() {
     this.map1 = new T.Map('mapDiv1', {
        minZoom: 2.6,
        maxZoom: 11
      })
      const lnglat1 = new T.LngLat(0, 45)
      this.map1.centerAndZoom(lnglat1, 3)
      const bounds1 = [-180, -90, 180, 90]
      this.map1.setMaxBounds(new T.LngLatBounds(new T.LngLat(bounds1[0], bounds1[1]), new T.LngLat(bounds1[2], bounds1[3])))
      document.getElementById('mapDiv1').addEventListener('wheel', (e) => {
        let direction = e.deltaY > 0 ? 'up' : 'down';
        direction === 'up' ? this.map1.zoomOut() : this.map1.zoomIn();
      })
      //工厂及零部件初始化
      this.getfactoryLoad()
  },
  methods: {
    getLogin(){
      return getUserInfo()
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    changeTypes(val){
      if (val.length > 0){
        if (val.length == 1 && val[0] == '工厂'){
          this.$set(this, 'classificationName', 'name1')
          this.$set(this, 'enterpriseSearch2', [])
          this.systemList2.forEach(item => {
            this.$set(item, 'checked1', false)
            this.$set(item, 'checked', false)
          })
        }
        if (val.length == 1 && val[0] == '零部件'){
          this.$set(this, 'classificationName', 'name2')
          this.$set(this, 'enterpriseSearch1', [])
          this.systemList1.forEach(item => {
            this.$set(item, 'checked1', false)
            this.$set(item, 'checked', false)
          })
        }
      } else {
        this.systemList1.forEach(item => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'checked', false)
        })
        this.systemList2.forEach(item => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'checked', false)
        })
        this.$set(this, 'enterpriseSearch1', [])
        this.$set(this, 'enterpriseSearch2', [])
      }
    },
    // 国家的展开收起
    changeUp(str){
      this[str] = !this[str]
    },
    // 查询工厂详情
    getDetails(row){
      // factoryName
      const newUrl = this.$router.resolve('/international/factoryPartsDistributor/info/' + row.id + '?latitude=' + row.latitude + '&longitude=' + row.longitude)
      window.open(newUrl.href, '_blank')
    },
    // 查询零部件详情
    getDetails1(row){
      // companyName
      const newUrl = this.$router.resolve('/international/factoryPartsDistributor/info1/' + row.id + '?latitude=' + row.latitude + '&longitude=' + row.longitude)
      window.open(newUrl.href, '_blank')
    },
    searchForm1(num) {
      const newUrl = this.$router.resolve({
        path: '/international/factoryPartsDistributor/index'
      })
      if (getUserInfo() || num == 1) {
        this.selectPolicyPage1.pages.page = 1
        if (this.typeSearch.length == 1 && this.typeSearch.includes('工厂')){ //包含工厂
          this.ttFactoryList(1)
          this.ttFactoryPage1()
        }
        if (this.typeSearch.length == 1 && this.typeSearch.includes('零部件')){ //包含零部件
          this.ttFactoryList(2)
          this.ttFactoryPage2()
        }
        if (this.typeSearch.length == 0 || this.typeSearch.length == 2){ //默认是查所有
          this.ttFactoryList()
          this.ttFactoryPage1()
          this.ttFactoryPage2()
        }
        const anchorElement = document.getElementById('mapDistributorList1')
        if (anchorElement) {
          anchorElement.scrollIntoView({block: "start"})
          window.scrollBy(0, -100);
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    async resetForm1() {
      const newUrl = this.$router.resolve({
        path: '/international/factoryPartsDistributor/index'
      })
      if (getUserInfo()) {
        this.selectPolicyPage1.pages.page = 1
        this.selectPolicyPage2.pages.page = 1
        this.$set(this, 'typeSearch', ['工厂'])
        this.$set(this, 'countrysSearch', [])
        this.$set(this, 'carList1', {})
        this.$set(this, 'carList2', {})
        this.$set(this, 'allchecked1', false)
        this.$set(this, 'allchecked2', false)
        
        this.systemList1.forEach(item => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'checked', false)
        })
        this.countryList4.forEach(item => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'checked', false)
        })
        this.$set(this, 'enterpriseSearch1', []) //整车厂车企企业选中数据
        this.$set(this, 'enterpriseSearch2', []) //零部件企业选中数据
        this.$set(this, 'carType2', '')
        this.systemList2.forEach(item => {
          this.$set(item, 'checked2', false)
          this.$set(item, 'checked', false)
        })
        this.ttFactoryPage1()
        // this.ttFactoryPage2()
        await this.ttFactoryList(1)
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
      
    },
    handleSizeChange1(val) {
      const menu = this.$route.meta.acName
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        this.doDialogBuryPoint()
        openSy()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      this.selectPolicyPage1.pages.pageSize = val
      this.selectPolicyPage1.pages.page = 1
      this.ttFactoryPage1()
    },
    handleCurrentChange1(val) {
      const menu = this.$route.meta.acName //国际化
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.doDialogBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      this.selectPolicyPage1.pages.page = val
      this.ttFactoryPage1()
    },
    handleSizeChange2(val) {
      const menu = this.$route.meta.acName
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        this.doDialogBuryPoint()
        openSy()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      this.selectPolicyPage2.pages.pageSize = val
      this.selectPolicyPage2.pages.page = 1
      this.ttFactoryPage2()
    },
    handleCurrentChange2(val) {
      const menu = this.$route.meta.acName //国际化
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.doDialogBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      this.selectPolicyPage2.pages.page = val
      this.ttFactoryPage2()
    },
    setMarker1(list) {
      this.map1.clearOverLays()
      const arr = []
      let iconList = ['icon01', 'icon02', 'icon03', 'icon04', 'icon05', 'icon06', 'icon07', 'icon08', 'icon09', 'icon10', 'icon11', 'icon12', 'icon13', 'icon14', 'icon15']
      for (let i = 0; i < list.length; i++) {
        const point = new T.LngLat(list[i].longitude, list[i].latitude)
        let idIndex1 = i % iconList.length
        const icon = new T.Icon({
          iconUrl: require(`@/assets/images/${ list[i].isfactory ? 'icon02' : 'icon04' }.png`),
          iconSize: list[i].isfactory ? new T.Point(25, 29) : new T.Point(25, 29),
          iconAnchor: new T.Point(16, 30)
        })
        const marker = new T.Marker(point, {
          icon: icon
        })// 创建标注
        this.map1.addOverLay(marker)
        marker.addEventListener('mouseover', () => {
          const latlng = new T.LngLat(list[i].longitude, list[i].latitude)
          this.mapLabel1 = new T.Label({
            text: `<div id="MyDiv" style="border-radius: 4px;cursor:pointer; font-size: 12px;color: #fff;background:#493800;padding:0px 8px;">${list[i].brand}</div>`,
            position: latlng,
            offset: new T.Point(-28, -43)
          })
          this.mapLabel1.addEventListener("click", (e) => {
            let newUrl = ''
            if (list[i].isfactory){ //工厂
              newUrl = this.$router.resolve('/international/factoryPartsDistributor/info/' + list[i].id + '?latitude=' + list[i].latitude + '&longitude=' + list[i].longitude)
            } else { //零部件
              newUrl = this.$router.resolve('/international/factoryPartsDistributor/info1/' + list[i].id + '?latitude=' + list[i].latitude + '&longitude=' + list[i].longitude)
            }
            if (getUserInfo()){
              this.map1.removeOverLay(this.mapLabel1)
              window.open(newUrl.href, '_blank')
            } else {
              sessionStorage.setItem('toUrl', newUrl.href)
              this.$Vlogin2({ flag: true })
              return
            }
          })
          this.mapLabel1.addEventListener("mouseout", (e) => {
            this.map1.removeOverLay(this.mapLabel1)
          })
          this.map1.addOverLay(this.mapLabel1)
        })
        marker.addEventListener('mouseout', (e) => {
          if(e.originalEvent?.relatedTarget?.id === 'MyDiv') {
          } else {
             // 删除Line覆盖物
            this.map1.removeOverLay(this.mapLabel1)
          }
          this.map1.removeEventListener('mousemove')
        })
      }
    },
    async ttFactoryList(num) {
      let body = {}
      if (this.countrysSearch.length > 0) {
        if (this.countrysSearch.includes('全选')) {
          if(!this.isexpand){
            let arr = []
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '工厂'){
              arr = this.countryList1
            }
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '零部件'){
              arr = this.countryList2
            }
            if (this.typeSearch.length > 1){
              arr = this.countryList3
            }
            body.countryList = arr
          }
          if (this.isexpand){
            let arr1 = []
            arr1 = this.countryList4.flatMap(item => item.children.map(subItem => subItem.name))
            body.countryList = arr1
          }
        } else {
          body.countryList = this.countrysSearch
        }
        body.countryOperator = 'in'
      }
      if (num == 1){
        if (this.enterpriseSearch1.length > 0) { //整车厂查询
          body.vehicleManufacturerLikeList = this.enterpriseSearch1
        }
      }
      if (num == 2){
        if (this.enterpriseSearch2.length > 0) { //零部件查询
          body.companyNameList = this.enterpriseSearch2
          body.companyNameOperator = 'in'
        }
      }
      if (!num){
        if (this.enterpriseSearch1.length > 0) { //整车厂查询
          body.vehicleManufacturerLikeList = this.enterpriseSearch1
          // body.vehicleManufacturerOperator = 'in'
        }
        getfactoryMap(body).then(res => {
          if (res.ok){
            let data1 = res.data
            data1.forEach(item => {
              item.brand = item.factoryName
              item.isfactory = true
            })
            if (this.enterpriseSearch2.length > 0) { //零部件查询
              body.companyNameList = this.enterpriseSearch2
              body.companyNameOperator = 'in'
            }
            delete body.vehicleManufacturerLikeList
            getpartsMap(body).then(res => {
              if (res.ok){
                let data2 = res.data
                data2.forEach(item => {
                  item.brand = item.companyName
                  item.isfactory = false
                })
                let data3 = [ ...data1, ...data2 ]
                this.setMarker1(data3)
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            // this.$message.error(res.message)
          }
        })
      }
      if(num == 1){
        getfactoryMap(body).then(res => {
          if (res.ok){
            let data = JSON.parse(JSON.stringify(res.data))
            data.forEach((item) => {
              item.brand = item.factoryName
              item.isfactory = true
            })
            this.setMarker1(data)
          } else {
            this.$message.error(res.message)
          }
        })
      }
      if(num == 2){
        getpartsMap(body).then(res => {
          if (res.ok){
            let data = JSON.parse(JSON.stringify(res.data))
            data.forEach((item) => {
              item.brand = item.companyName
              item.isfactory = false
            })
            this.setMarker1(data)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    ttFactoryPage1() {
      const body = {
        page: this.selectPolicyPage1.pages.page,
        pageSize: this.selectPolicyPage1.pages.pageSize
      }
      let isSearch1 = true
      let isSearch2 = true
      if (this.countrysSearch.length > 0) {
        if (this.countrysSearch.includes('全选')) {
          if(!this.isexpand){
            let arr = []
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '工厂'){
              arr = this.countryList1
            }
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '零部件'){
              arr = this.countryList2
            }
            if (this.typeSearch.length > 1){
              arr = this.countryList3
            }
            body.countryList = arr
          }
          if (this.isexpand){
            let arr1 = []
            arr1 = this.countryList4.flatMap(item => item.children.map(subItem => subItem.name))
            body.countryList = arr1
          }
        } else {
          body.countryList = this.countrysSearch
        }
        body.countryOperator = 'in'
      } else {
        isSearch1 = false
      }
      if (this.enterpriseSearch1.length > 0) { //整车厂查询
        body.vehicleManufacturerLikeList = this.enterpriseSearch1
        // body.vehicleManufacturerOperator = 'in'
      } else {
        isSearch2 = false
      }
      // if (!isSearch1 && !isSearch2){
      //   this.selectDistributorData1 = []
      //   this.selectPolicyPage1.total = 0
      //   this.selectPolicyPage1.pages.page = 1
      //   return false
      // }
      // if (this.enterpriseSearch2.length > 0) { //零部件查询
      //   body.companyNameList = this.enterpriseSearch2
      //   body.companyNameOperator = 'in'
      // }
      getfactoryList(body).then(res => {
        if (res.ok) {
          this.selectDistributorData1 = res.data.list
          this.selectPolicyPage1.total = res.data.count
        } else {
          this.$message.error(res.message)
        }
      })
    },
    ttFactoryPage2(){
      const body = {
        page: this.selectPolicyPage2.pages.page,
        pageSize: this.selectPolicyPage2.pages.pageSize
      }
      let isSearch1 = true
      let isSearch2 = true
      if (this.countrysSearch.length > 0) {
        if (this.countrysSearch.includes('全选')) {
          if(!this.isexpand){
            let arr = []
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '工厂'){
              arr = this.countryList1
            }
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '零部件'){
              arr = this.countryList2
            }
            if (this.typeSearch.length > 1){
              arr = this.countryList3
            }
            body.countryList = arr
          }
          if (this.isexpand){
            let arr1 = []
            arr1 = this.countryList4.flatMap(item => item.children.map(subItem => subItem.name))
            body.countryList = arr1
          }
        } else {
          body.countryList = this.countrysSearch
        }
        body.countryOperator = 'in'
      } else {
        isSearch1 = false
      }
      if (this.enterpriseSearch2.length > 0) { //零部件查询
        body.companyNameList = this.enterpriseSearch2
        body.companyNameOperator = 'in'
      } else {
        isSearch2 = false
      }
      // if (!isSearch1 && !isSearch2){
      //   this.selectDistributorData2 = []
      //   this.selectPolicyPage2.total = 0
      //   this.selectPolicyPage2.pages.page = 1
      //   return false
      // }
      getpartsList(body).then(res => {
        if (res.ok) {
          this.selectDistributorData2 = res.data.list
          this.selectPolicyPage2.total = res.data.count
        } else {
          this.$message.error(res.message)
        }
      })
    },
    changeCars1(item, index, str){
      let arr = []
      let arr1 =[]
      arr = item.children.map(item => { return item.name })
      if (this[str].length > 0){
        arr1 = this[str].filter(item => !arr.includes(item))
      } else {
        arr1 = arr
      }
      this[str] = item.checked ? Array.from(new Set([...this[str], ...arr])) : arr1
      this.$set(item, 'checked1', false)
    },
    changeCars2(item, t, index, str){
      let length = item.children.length
      let arr = item.children.map(item => { return item.name })
      let arr1 =[]
      if (this[str].length > 0){
        arr1 = arr.filter(item => !this[str].includes(item))
      }
      if (arr1.length === 0){
        this.$set(item, 'checked1', false)
      } else if(arr1.length !== length){
        this.$set(item, 'checked1', true)
      } else if (arr1.length === length){
        this.$set(item, 'checked', true)
        this.$set(item, 'checked1', false)
      }
    },
    getArrs(arr, arr1) {
      let bo = true
      // 遍历arr中的每个对象
      if (arr && arr.length > 0){
        arr.forEach(obj => {
          if (!arr1.includes(obj.dictLabel)) {  
            bo = false;  
          } 
        })
      }
      return bo
    },
    changeCar1(val, num){
      if (num == 1){
        if (this.enterpriseSearch1.includes('全选')){
          this.$set(this, 'allchecked1', this.enterpriseSearch1.length - 1 === this.carList1.length)
          if (!this.getArrs(this.carData1[this.carType1], this.enterpriseSearch1)){
            let index = this.enterpriseSearch1.indexOf('全选')
            this.enterpriseSearch1.splice(index, 1);
          }
        } else {
          this.$set(this, 'allchecked1', this.enterpriseSearch1.length === this.carList1.length)
          if (this.getArrs(this.carData1[this.carType1], this.enterpriseSearch1)){
            this.enterpriseSearch1.push('全选')
          }
        }
      }
      if (num == 2){
        if (this.enterpriseSearch1.includes('全选')){
          this.$set(this, 'allchecked2', this.enterpriseSearch2.length - 1 === this.carList2.length)
          if (!this.getArrs(this.carData2[this.carType2], this.enterpriseSearch2)){
            let index = this.enterpriseSearch2.indexOf('全选')
            this.enterpriseSearch2.splice(index, 1);
          }
        } else {
          this.$set(this, 'allchecked2', this.enterpriseSearch2.length === this.carList2.length)
          if (this.getArrs(this.carData2[this.carType2], this.enterpriseSearch2)){
            this.enterpriseSearch2.push('全选')
          }
        }
        
      }
    },
    changeEnterprise(item, index, num){
      // 1是整车厂企业
      if (num == 1){
        this.carType1 = item.dictValue
        this.systemList1.forEach(t => { //单选
          if (item.dictLabel == t.dictLabel){
            this.$set(t, 'checked', true)
          } else {
            this.$set(t, 'checked', false)
          }
        })
        if (!item.checked){
          this.carType1 = ''
        }
        if (this.enterpriseSearch1.includes('全选')){
          if (!this.getArrs(this.carData1[this.carType1], this.enterpriseSearch1)){
            let index = this.enterpriseSearch1.indexOf('全选')
            this.enterpriseSearch1.splice(index, 1);
          }
        } else {
          if (this.getArrs(this.carData1[this.carType1], this.enterpriseSearch1)){
            this.enterpriseSearch1.push('全选')
          }
        }
        
        // this.$set(this, 'allchecked1', this.systemList1.filter(item => item.checked).length === this.systemList1.length)
        if(this.systemList1.filter(item => item.checked).length > 0){
          // this.getDictionary('carList1', this.systemList1.filter(item => item.checked).map(item => item.dictValue).join(','))
        } else {
          // this.$set(this, 'carList1', [])
        }
      }
      // 2是零部件企业
      if (num == 2){
        this.carType2 = item.dictValue
        this.systemList2.forEach(t => {
          if (item.dictLabel == t.dictLabel){
            this.$set(t, 'checked', true)
          } else {
            this.$set(t, 'checked', false)
          }
        })
        if (!item.checked){
          this.carType2 = ''
        }
        if (this.enterpriseSearch2.includes('全选')){
          if (!this.getArrs(this.carData2[this.carType2], this.enterpriseSearch2)){
            let index = this.enterpriseSearch2.indexOf('全选')
            this.enterpriseSearch2.splice(index, 1);
          }
        } else {
          if (this.getArrs(this.carData2[this.carType2], this.enterpriseSearch2)){
            this.enterpriseSearch2.push('全选')
          }
        }
        // this.$set(this, 'allchecked2', this.systemList2.filter(item => item.checked).length === this.systemList2.length)
        if (this.systemList2.filter(item => item.checked).length > 0){
          // this.getDictionary('carList2', this.systemList2.filter(item => item.checked).map(item => item.dictValue).join(','))
        } else {
          // this.$set(this, 'carList2', [])
        }
      }
    },
    changeAllCheck(str){
      this.$set(this, str, !this[str])
      if (str == 'allchecked1'){
        // this.systemList1.forEach(item => {
        //   this.$set(item, 'checked', this[str])
        // })
        this.enterpriseSearch1 = this.carData1
        if (this[str]){
          this.$set(this, 'enterpriseSearch1', [...Object.values(this.carData1).flatMap(arr => arr.map(item => item.dictLabel)), '全选'])
          // this.getDictionary('carList1', this.systemList1.map(item => item.dictValue).join(','))
        } else {
          // this.$set(this, 'carList1', [])
          this.$set(this, 'enterpriseSearch1', [])
        }
      }
      if (str == 'allchecked2'){
        // this.systemList2.forEach(item => {
        //   this.$set(item, 'checked', this[str])
        // })
        if (this[str]){
          this.$set(this, 'enterpriseSearch2', [...Object.values(this.carData2).flatMap(arr => arr.map(item => item.dictLabel)), '全选'])
          // this.getDictionary('carList2', this.systemList2.map(item => item.dictValue).join(','))
        } else {
          // this.$set(this, 'carList2', [])
          this.$set(this, 'enterpriseSearch2', [])
        }
      }
    },
    // 获取字典接口
    async getDictionary(list, str){
      let params = {
        code: str
      }
      await ttDemension(params).then(res => {
        if (res.ok) {
          this[list] = res.data
        }
      })
      // await getDictionarys(params).then(res => {
      //   if (res.ok) {
      //     this[list] = res.data
      //   }
      // })
    },
    async getfactoryLoad(load){
      this.ttFactoryList(1)
      if (!load){
        this.selectPolicyPage1.pages.page = 1
        this.selectPolicyPage2.pages.page = 1
        this.ttFactoryPage1()
        // this.ttFactoryPage2() //默认不查零部件
      }
      getDictionarys({ dictType: 'popular_countries' } ).then(res => { //热门国家
        if (res.ok) {
          this.countryList5 = res.data
        }
      })
      ttDemension({ code: 'factoryComponentsCountry' }).then(res => {
        if (res.ok){
          this.countryList4 = res.data
          this.countryList4.forEach(item => {
            this.$set(item, 'checked', false)
            this.$set(item, 'checked1', false)
          })
          let arr = res.data.flatMap(item => item.children.map(subItem => subItem.name))
          this.countryList1 = arr
          this.countryList2 = arr
          this.countryList3 = arr
        }
      })
      await this.getDictionary('systemList1', 'factoryEnterprise') //整车厂车系类型
      await this.getDictionary('systemList2', 'componentsEnterprise') //零部件车系类型
      if (this.systemList1.length > 0) {
        let itemToMove = this.systemList1.find(item => item.name == '中系')
        let otherItems = this.systemList1.filter(item => item.name !== '中系') || []
        if (itemToMove){
          this.systemList1 = [itemToMove, ...otherItems]
        }
        this.systemList1.forEach(item => {
          this.$set(item, 'checked', false)
          this.$set(item, 'checked1', false)
        })
      }
      if (this.systemList2.length > 0) {
        let itemToMove = this.systemList2.find(item => item.name == '中系')
        let otherItems = this.systemList2.filter(item => item.name !== '中系') || []
        if (itemToMove){
          this.systemList2 = [itemToMove, ...otherItems]
        }
        this.systemList2.forEach(item => {
          this.$set(item, 'checked', false)
          this.$set(item, 'checked1', false)
        })
      } 
    },
    // 弹窗埋点
    doDialogBuryPoint() {
      buryPoint({
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '数据',
        userId: getUserInfo()?.userId
      })
    },
    // 埋点
    doLogRecords(buttonName, inputBox = '') {
      logRecords(
        '数据', // column1 写死
        this.routeTableName, // column2 数据表名称
        '3', // type 写死，3 表示操作
        '', // tableName 不传也行
        '', // articleId 不传也行
        '', // attachName 不传也行
        '', // articleName 不传也行
        inputBox, // inputBox 如果是查询操作，则要传表格/饼图/折线图
        buttonName // buttonName 传查询/切换展示类型/下载数据/导出图片
      )
    },
    exportTtInternationalDealers(){
      // 如果未登录
      if (!getUserInfo()) {
        // 提示需要登录
        this.$Vlogin2({ flag: true })
        return
      }
      
      const body = {}
      if (this.countrysSearch.length > 0) {
        if (this.countrysSearch.includes('全选')) {
          if(!this.isexpand){
            let arr = []
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '工厂'){
              arr = this.countryList1
            }
            if (this.typeSearch.length === 1 && this.typeSearch[0] == '零部件'){
              arr = this.countryList2
            }
            if (this.typeSearch.length > 1){
              arr = this.countryList3
            }
            body.countryList = arr
          }
          if (this.isexpand){
            let arr1 = []
            arr1 = this.countryList4.flatMap(item => item.children.map(subItem => subItem.name))
            body.countryList = arr1
          }
        } else {
          body.countryList = this.countrysSearch
        }
        body.countryOperator = 'in'
      }
      if (this.enterpriseSearch1.length > 0) { //整车厂查询
        body.vehicleManufacturerLikeList = this.enterpriseSearch1
        // body.vehicleManufacturerOperator = 'in'
      }
      if (this.enterpriseSearch2.length > 0) { //零部件查询
        body.companyNameList = this.enterpriseSearch2
        body.companyNameOperator = 'in'
      }
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey === 'trialrole'
      ) {
        // 浏览埋点
        this.doLogRecords('下载数据')
        // 如果没有数据栏目/国际化权限
        if (userInfo.menuName && (this.isInternational ? !userInfo.menuName.includes('国际化') : !userInfo.menuName.includes('数据'))) {
          // 弹出申请试用的弹窗
          this.$Vlogin3({ flag: true })
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        } else if (userInfo.roleKey == 'trialrole') {
          // 否则如果有数据栏目权限，但是是试用用户
          // 弹窗 【提示】此操作需要正式开通该栏目后操作
          openSy()
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        }
        if (this.classificationName == 'name1'){
          exportTtInternationalDealer1(body).then(res => {
            this.blobstamp(res, '工厂')
          })
          return
        }
        if (this.classificationName == 'name2'){
          exportTtInternationalDealer2(body).then(res => {
            this.blobstamp(res, '零部件')
          })
          return
        }
      }
      if (this.classificationName == 'name1'){
        exportTtInternationalDealer1(body).then(res => {
          this.blobstamp(res, '工厂')
        })
      }
      if (this.classificationName == 'name2'){
        exportTtInternationalDealer2(body).then(res => {
          this.blobstamp(res, '零部件')
        })
      }
    },
    exportTtInternationalDealer() {
      const body = {}
      if (this.countrySearch.length > 0) {
        body.countryList = this.countrySearch
        body.countryOperator = 'in'
      }
      if (this.brandSearch.length > 0) {
        body.brandList = this.brandSearch
        body.brandOperator = 'in'
      }
      if (this.networkTypeSearch.length > 0) {
        body.networkTypeList = this.networkTypeSearch
        body.networkTypeOperator = 'in'
      }
      exportTtInternationalDealer(body).then(res => {
        this.blobstamp(res, '经销商')
      })
    },
    blobstamp(data, name) {
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const fileName = name + '.xls'
      // fileDownload(res, fileName)
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },
    ttInternationalDealerList() {
      const body = {}
      if (this.countrySearch.length > 0) {
        body.countryList = this.countrySearch
        body.countryOperator = 'in'
      }
      if (this.brandSearch.length > 0) {
        body.brandList = this.brandSearch
        body.brandOperator = 'in'
      }
      if (this.networkTypeSearch.length > 0) {
        body.networkTypeList = this.networkTypeSearch
        body.networkTypeOperator = 'in'
      }
      ttInternationalDealerList(body).then(res => {
        if (res.ok) {
          let num = 1;  
        let nameMap = {};  
        let arr = res.data.map(item => {  
            if (!nameMap[item.brand]) {
                if(num == 15){
                  num = 1
                }
                nameMap[item.brand] = num
                num++;
            }
            return { ...item, nums: nameMap[item.brand] < 10 ? ('0' + nameMap[item.brand]) :  nameMap[item.brand]}
        })
          this.setMarker(arr)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    setMarker(list) {
      // 获取覆盖物
      // const allOverlays = this.map.getOverlays()

      // 将图层增加到地图上
      // this.map.addLayer(lay)
      // if(this.markerLayer){
      //   this.map.removeOverLay(this.markerLayer)
      // }
      // this.markerLayer = new T.TileLayer({
      //   layerId: 'cva', // 请求的图层ID, 例如'vec', 'cva', 'ter'
      //   layerType: 'vec', // 图层类型, 例如'vec', 'cva', 'ter'
      //   map: this.map // 需要添加到的地图实例
      // });
      // if (this.markers) {
      //   this.markers.clearMarkers()
      //   this.markers = null
      // }
      this.map.clearOverLays()
      // const icon = new T.Icon({
      //   iconUrl: require('@/assets/img/newData/distributor-marker-Icon.png'),
      //   iconSize: new T.Point(32, 32),
      //   iconAnchor: new T.Point(16, 30)
      // })
      const arr = []
      let iconList = ['icon01', 'icon02', 'icon03', 'icon04', 'icon05', 'icon06', 'icon07', 'icon08', 'icon09', 'icon10', 'icon11', 'icon12', 'icon13', 'icon14', 'icon15']
      for (let i = 0; i < list.length; i++) {
        const point = new T.LngLat(list[i].longitude, list[i].latitude)
        let idIndex1 = i % iconList.length
        const icon = new T.Icon({
          // iconUrl: require(`@/assets/images/${ iconList[idIndex1] }.png`),
          iconUrl: require(`@/assets/images/icon${ list[i].nums }.png`),
          iconSize: new T.Point(25, 29),
          iconAnchor: new T.Point(16, 30)
        })
        const marker = new T.Marker(point, {
          icon: icon
        })// 创建标注
        // arr.push(marker)
        // marker.setZIndexOffset(5)
        this.map.addOverLay(marker)
        marker.addEventListener('mouseover', () => {
          const latlng = new T.LngLat(list[i].longitude, list[i].latitude)
          this.mapLabel = new T.Label({
            // text: `<div style="color:#1D2129; width:70px;background:#fff; height:17px; margin-top:5px;border-radius:20px; font-size: 12px;display:flex; align-items:center"><span style="width:5px; height:5px; display:inline-block;border:3px solid ${ colorList[idIndex] }; background:#fff; border-radius: 10px; padding:3px;margin:0 3px -1px 4px"></span><span style="display:inline-block;line-height:20px; margin-top:3px">${list[i].brand}</span></div>`,
            text: `<div style="border-radius: 4px;font-size: 12px;color: #fff;background:#493800;padding:0px 8px;">${list[i].brand}</div>`,
            position: latlng,
            offset: new T.Point(-28, -43)
          })
          // this.mapLabel.setZindex(50)
          // 创建地图文本对象
          this.map.addOverLay(this.mapLabel)
        })
        marker.addEventListener('mouseout', () => {
          // 删除Line覆盖物
          this.map.removeOverLay(this.mapLabel)
          // 取消监听地图的mousemove事件
          this.map.removeEventListener('mousemove')
        })
      }
      
      // this.map.addTileLayer(this.markerLayer);
      // this.markers = new T.MarkerClusterer(this.map, { markers: arr })
    },
    async getMultipleChoiceCriteria(load) {
      this.countryList = []
      this.brandList = []
      this.networkTypeList = []
      await getMultipleChoiceCriteria().then(res => {
        if (res.ok) {
          this.isOnetype = true
          this.countryList = res.data.countryList || []
          this.brandList = res.data.brandList || []
          this.networkTypeList = res.data.networkTypeList || []
          this.ttInternationalDealerList()
          if (!load){
            this.ttInternationalDealerPage()
          }
        } else {
          // this.$message(res.message)
        }
      })
    },
    handleClick(tab, event) {
      if (this.activeName == 'FactoryParts') {
        // this.ttFactoryPage1()
        // this.ttFactoryPage2()
        this.searchForm1(1)
        this.$nextTick(() => {
          this.map1.checkResize()
        })
      } else if (this.activeName == 'Distributor') {
        // 国际法规经销商筛选条件
        this.getMultipleChoiceCriteria('load')
        // this.ttInternationalDealerPage()
        this.$nextTick(() => {
          this.map.checkResize()
        })
      }
    },
    // allNetworkTypeChange(event) {
    //   if (event) {
    //     this.networkTypeSearch = JSON.parse(JSON.stringify(this.networkTypeList))
    //   } else {
    //     this.networkTypeSearch = []
    //   }
    // },
    networkTypeChange(event) {
      if (event.length == this.networkTypeList.length) {
        this.allNetworkTypeChecked = true
      } else {
        this.allNetworkTypeChecked = false
      }
    },

    ttInternationalDealerPage() {
      const body = {
        page: this.selectPolicyPage.pages.page,
        pageSize: this.selectPolicyPage.pages.pageSize
      }
      let isSearch1 = true
      let isSearch2 = true
      let isSearch3 = true
      if (this.countrySearch.length > 0) {
        body.countryList = this.countrySearch
        body.countryOperator = 'in'
      } else {
        isSearch1 = false
      }
      if (this.brandSearch.length > 0) {
        body.brandList = this.brandSearch
        body.brandOperator = 'in'
      } else {
        isSearch2 = false
      }
      if (this.networkTypeSearch.length > 0) {
        body.networkTypeList = this.networkTypeSearch
        body.networkTypeOperator = 'in'
      } else {
        isSearch3 = false
      }
      // if (!isSearch1 && !isSearch2 && !isSearch3){
      //   this.selectDistributorDate = []
      //   this.selectPolicyPage.total = 0
      //   this.selectPolicyPage.pages.page = 1
      //   return false
      // }
      ttInternationalDealerPage(body).then(res => {
        if (res.ok) {
          this.selectDistributorDate = res.data.list
          this.selectPolicyPage.total = res.data.count
        } else {
          this.$message.error(res.message)
        }
      })
    },
    searchForm() {
      const newUrl = this.$router.resolve({
        path: '/international/factoryPartsDistributor/index'
      })
      if (getUserInfo()) {
        this.selectPolicyPage.pages.page = 1
        this.ttInternationalDealerPage()
        this.ttInternationalDealerList()
        const anchorElement = document.getElementById('mapDistributorList')
        if (anchorElement) {
          anchorElement.scrollIntoView()
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
      
    },
    resetForm() {
      const newUrl = this.$router.resolve({
        path: '/international/factoryPartsDistributor/index'
      })
      if (getUserInfo()) {
        this.$set(this, 'countrySearch', [])
        this.$set(this, 'brandSearch', [])
        this.$set(this, 'networkTypeSearch', [])
        this.selectPolicyPage.pages.page = 1
        this.ttInternationalDealerPage()
        this.ttInternationalDealerList()
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    handleSizeChange(val) {
      this.selectPolicyPage.pages.pageSize = val
      this.selectPolicyPage.pages.page = 1
      this.ttInternationalDealerPage()
    },
    handleCurrentChange(val) {
      this.selectPolicyPage.pages.page = val
      this.ttInternationalDealerPage()
    }
  }
}
</script>
<style scoped lang="scss">
@import "~@/styles/newDataCommon.scss";
/deep/.el-checkbox__label{
  color: #1D2129!important;
  font-weight: 400;
}
.tabCheck{
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
    background: #D2ECFF!important;
    background-color: #D2ECFF!important;
    border-color: #3f76ea!important;
  }
  /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #D2ECFF!important;
    background-color: #D2ECFF!important;
  }
  /deep/.el-checkbox__inner::after{
    border-color: #3f76ea!important;
  }
  /deep/.el-checkbox__inner::before{
    background: #3f76ea!important;
  }
  /deep/.el-checkbox__label{
    font-weight: 600;
    color: #0D57BC!important;
  }
}
.hotCountry{
  background: rgba(255,156,9,0.12);
  border-radius: 30px;
  padding: 4px 15px;
  width: auto;
  display: inline-block;
  img{
    width: 50px;
    margin-right: 15px;
    float: left;
  }
  /deep/.el-checkbox-group{
    display: inline-block;
  }
}
.ishide{
  height: 46px;
  overflow: hidden;
}
.upDowns{
  flex-shrink: 0;
  margin: 10px 0 0 10px;
  color: #0D57BC;
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 15px;
    margin-right: 4px;
  }
}
.listBg{
  background: rgba(246,247,250,0.6);
  padding: 10px 16px 0;
  border-radius: 8px;
  display: block!important;
}
.tabsList{
  /deep/.el-tabs__header{
    margin-bottom: 0;
  }
}
.checks{
  color: rgba(57,116,222,1);
}
.isShow{
  position: absolute;
  z-index: -1;
  width: 100%;
  left: -100%;
  top: -100%;
}
.FactoryPartsDistributor{
  .countryItem{
    //color: #3f76ea!important;
    border-radius: 3px;
    background-color: #EFF7FF;
    border: 1px solid rgba(52, 145, 250, 0.3)!important;
    //height: 32px;
    padding: 0 12px;
  }
  /deep/.el-tabs__header{
    width: 300px;
    background:#fff;
  }
  .oneTab /deep/.el-tabs__header{
    width: 150px;
  }
  /deep/.el-tabs__item{
    line-height: 54px!important;
    height: 54px!important;
  }
  .countryList{
    margin-right: 30px;
    cursor: pointer;
    color: #606266;
    font-weight: 500;
    padding: 0 12px;
    border: 1px solid transparent;
  }
  .query-option {
    display: flex;
    margin-bottom: 24px;

    .title {
      width: 100px;
      //line-height: 32px;
      margin-right: 10px;
      color: #1D2129;
      white-space: nowrap;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      img{
        margin-right: 5px;
      }
    }

    &.statistic-list {
      width: fit-content;
    }
    .statistic-list-container{
      flex-shrink: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

  }
  .right-btn-box {
    padding: 14px 10px;
    font-size: 14px;
    color: #4f4f4f;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:last-child {
        margin-left: 28px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  /deep/.tdt-overlay-pane{
    z-index:999!important;
  }
  /deep/.tdt-control-copyright{
    display: none;
  }
  /deep/ .tdt-label{
    background: transparent;
    padding: 0;
    box-shadow: none;
    border:none;
  }
}
</style>
