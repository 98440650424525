<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />-->
    <div class="default-box">
      <el-tabs v-model="activeName" class="n-tabs" @tab-click="handleClick">
        <el-tab-pane label="汽车保有量" name="汽车保有量">
          <carList v-if="activeName == '汽车保有量'"/>
        </el-tab-pane>
<!--        <el-tab-pane label="千人汽车保有量" name="千人汽车保有量">-->
<!--          <thousand v-if="activeName == '千人汽车保有量' && show"/>-->

<!--        </el-tab-pane>-->
        <el-tab-pane label="汽车新注册量" name="汽车新注册量">
          <register v-if="activeName == '汽车新注册量' && show"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import carList from "./views/carList.vue";
import register from "./views/register.vue";
import thousand from "./views/thousand.vue";

export default {
  components: {
    // VnotLogin,
    carList,
    register,
    // thousand,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      activeName: "汽车保有量",
    };
  },
  mounted() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    handleClick(tab) {

      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole"  || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            if (tab.name == '千人汽车保有量' || tab.name == '汽车新注册量') {
              this.activeName = "汽车保有量";
              this.sqsyLogin();

              this.insertBuryPoint();
            }

          }

        }
      } else {
        if (tab.name == '千人汽车保有量' || tab.name == '汽车新注册量') {
          this.activeName = "汽车保有量";
          this.showLogin();
        }
      }

    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
  },

}
</script>

<style lang="scss" scoped>
.default-box {
  background: #fff;
}

.search-form {
  padding: 16px;
  background: #fff;
  margin-bottom: 10px;
}

.view-box {
  padding: 0 16px;
}
</style>
