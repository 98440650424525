<template>
  <PageContainer>
<!--                <VnotLogin v-if="!show" title="企业情报" text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新企业情报" :showTips="showTips" />-->
    <el-tabs v-model="fl" type="card" tab-position="left"
             style="float: left; display: flex;position: fixed;z-index: 1;left: 210px" @tab-click="tabClick">
      <el-tab-pane label="概览" name="gl" :key="'gl'"></el-tab-pane>
      <el-tab-pane label="详细" name="xl" :key="'xl'"></el-tab-pane>
    </el-tabs>



    <template>
      <div style="background-color: white;margin-bottom: 10px;" id="gl">

        <div style="font-size: 18px;padding: 20px 30px;font-weight: bold;color: #0f0f0f">产能分布概览
          <el-tooltip class="item" effect="dark" content="点击地区可以查看具体生产企业产能分布情况" placement="top-start">
            <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i></a>
          </el-tooltip>
        </div>
        <div class="map-box" ref="chinaMap"/>

      </div>

      <el-dialog :title="tableTitle" :visible.sync="showFlag" width="250px">
        <el-table :data="enterpriseCapacityDataList">
          <el-table-column property="name" label="生产企业" width="104"/>
          <el-table-column property="value" label="产能(万辆)" width="87"/>
        </el-table>
      </el-dialog>
<!--      <el-button type="text" @click="showFlag = true">打开嵌套表格的 Dialog{{ enterpriseCapacityDataList }}</el-button>-->
      <div class="search-form" id="xq">
        <div style="font-size: 18px;font-weight: bold;color: #0f0f0f;padding-bottom: 20px;">产能分布详细</div>
        <!--        <Vmap newMapData=""  />-->
        <el-form class="g-form" :model="form" ref="form">
          <ul class="g-form-item">
            <li>
              <el-form-item prop="enterprise">
                <label class="text-right">生产企业：</label>
                <el-input v-model="form.enterprise" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="province">
                <label class="text-right">省份：</label>
                <el-input v-model="form.province" placeholder="请输入内容" class="g-form-input"></el-input>

              </el-form-item>
            </li>
            <li>
              <el-form-item prop="city">
                <label class="text-right">市：</label>
                <el-input v-model="form.city" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
          </ul>
          <!--          <ul class="g-form-item">-->
          <!--            <li>-->

          <!--              <el-form-item prop="type">-->
          <!--                <label class="text-right">类型：</label>-->
          <!--                <el-select v-model="form.type" multiple collapse-tags placeholder="请选择" size="small"-->
          <!--                           class="g-form-input">-->
          <!--                  <el-option v-for="item in qycnlx" :key="item.dictValue" :label="item.dictLabel"-->
          <!--                             :value="item.dictValue">-->
          <!--                  </el-option>-->
          <!--                </el-select>-->
          <!--                &lt;!&ndash; <el-input v-model="strategicPlanningForm.types" placeholder="请输入内容" size="small" ></el-input> &ndash;&gt;-->
          <!--              </el-form-item>-->
          <!--            </li>-->
          <!--          </ul>-->

          <div class="text-center">
            <el-button class="reset-button" @click="resetForm('form')">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="search-form">


        <el-table :data="enterpriseCapacityData" style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" v-show="!viewSwitchFlag"
                  @sort-change="tableSort" stripe>
          <el-table-column sortable prop="type" label=" 类型" width="130"/>
          <el-table-column sortable prop="groups" label="集团" width="130"/>
          <el-table-column sortable prop="enterprise" label="生产企业"/>
          <el-table-column sortable prop="factory" label="工厂/基地"/>
          <el-table-column sortable prop="province" label="省份" width="130"/>
          <el-table-column sortable prop="city" label="市" width="130"/>
          <el-table-column sortable prop="capacity" label="产能(万辆)" width="120"/>
          <el-table-column sortable prop="year" label="年" width="108"/>
          <el-table-column sortable prop="remark" label="备注" width="108"/>
        </el-table>

        <div class="text-right">
          <el-pagination @size-change="enterpriseCapacityInfoChange" @current-change="enterpriseCapacityCurrentChange"
                         :current-page="enterpriseCapacityInfoChange.currentPage" :page-sizes="[10, 20, 30, 40]"
                         :page-size="enterpriseCapacityPage.pages.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="enterpriseCapacityPage.total">
          </el-pagination>
        </div>
      </div>

    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
// import { selectPolicy } from "@/api/policy.js";
import {selectDictDataByType} from "@/api/dict.js";
import {getUserInfo} from "@/utils/auth.js";

// import { fmtDate } from "@/utils/date.js";
import {
  enterpriseCapacityQueryByPage, getEnterpriseCn, getProvinceCn
} from "@/api/enterpriseAnalysis.js";
import {buryPoint} from "@/api/common";
// 在script便签内import
import echarts from 'echarts4';
import china from '@/assets/jsondata/china_1.json'

echarts.registerMap('china', china)
import Vmap from "../information/components/map.vue";

export default {
  components: {
    Vmap,

  },
  data() {
    return {

      show: false,
      showFlag: false,
      showTips: [
        {
          title: "企业信息",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "企业库查询",
          text: "权威企业库，多条件筛查关联比对",
        },
        {
          title: "企业梳理",
          text: "专家梳理整合，真正读懂企业脉络",
        },
        {
          title: "企业大数据",
          text: "用数据深度理解企业结构和趋势",
        },
      ],
      fl: 'gl',
      tableTitle:'',
      viewSwitchFlag: false,
      form: {
        enterprise: "",
        city: "",
        province: "",
        type: 'syc'
      },
      qycnlx: [],
      enterpriseCapacityData: [],
      enterpriseCapacityDataList: [],
      enterpriseCapacityPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "企业情报",
        userId: ""
      },
      showP: false,
      // 地图配置项
      option: {}
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      this.enterpriseCapacityQueryByPage();
      // this.tabClick() ;
    } else {
      this.show = false;
    }

  },


  // 在这里调用，就会展示一张中国地图啦
  mounted() {
    this.getProvinceCn();


  },
  methods: {
    tabClick(tab) {

      if (tab.name == "gl") {
        document.querySelector("#gl").scrollIntoView();
      } else if (tab.name == "xl") {
        document.querySelector("#xq").scrollIntoView();
      }


    },
    chinaConfigure(seriesData) {
      console.log(seriesData)
      let myChart = echarts.init(this.$refs.chinaMap) //这里是为了获得容器所在位置
      window.onresize = myChart.resize
      myChart.setOption({
        title: {
          text: '',
          subtext: '单位，万辆',
          left: 'right',
          subtextStyle:{
            align:'left'
          }
        },
        grid: {

          bottom: '2%',//距离下边的距离
          top: '2%' //距离上边的距离
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b}'
          // formatter: '{b}<br/>{c} (个)'
          formatter: function (params) {

            // console.log(params)
            if (isNaN(params.value)) {
              return '无';

            } else {
              return params.name + ':' + params.value + '万辆';

            }
          }
        },
        visualMap: {
          min: 1,
          max: 350,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['RGB(218,227,243)', 'RGB(143,170,220)', 'RGB(32,56,100)']
          }
        },
        geo: {
          map: "china",
          roam: false,// 一定要关闭拖拽
          zoom: 1.25,
          // center: [105, 36], // 调整地图位置
          label: {


            show: true, //省份名展示
            fontSize: "10",
            color: "rgba(0,0,0,0.7)",
            emphasis: {
              show: false,

            }
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            geoIndex: 0, // 解决设置geo后地图重影问题
            zoom: 1.25, //这里是关键，一定要放在 series中
            // silent: false, //鼠标移入区域变色 如果设置为true则无法高亮
            // 鼠标移入高亮区域属性

            itemSytle: {

              emphasis: {
                show: true,
                areaColor: 'blue', //鼠标滑过区域颜色
                color: 'blue', //鼠标滑过区域颜色
              }
            },
            data: seriesData
          }
        ]
      })
      myChart.on('click',  (params)=> {//点击事件

        if (params.componentType === 'series') {
          if(params.data !=undefined){
            let provinceName = params.data.name;
            this.tableTitle = provinceName;
            let data = {
              province: provinceName
            };
            getEnterpriseCn(data).then((res) => {
              if (res.ok) {
                this.enterpriseCapacityDataList = res.data;
                this.showFlag = true;

              }
            })
          }

        }
      });

    },

    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    tableSort(column) {
      if (this.otherzh(column)) {
        this.enterpriseCapacityPage.pages.orderType = this.otherzh(column)
      } else {
        delete this.enterpriseCapacityPage.pages.orderType
      }
      this.enterpriseCapacityQueryByPage()
    },
    otherzh(column) {
      if (column.order == 'ascending') {

        return column.prop
      } else if (column.order == 'descending') {

        return column.prop + ' DESC'
      } else {
        return ''
      }
    },
    enterpriseCapacityQueryByPage() {
      let form = Object.assign(
          {},
          this.form,

          this.enterpriseCapacityPage.pages,
      );
      enterpriseCapacityQueryByPage(form).then((res) => {
        if (res.ok) {
          this.enterpriseCapacityData = res.data.list;
          this.enterpriseCapacityPage.total = res.data.count;
        }
      });
    },

    getProvinceCn() {
      getProvinceCn().then((res) => {
        if (res.ok) {
          let data = res.data;
          this.chinaConfigure(data);
        }
      });
    },

    //分页
    enterpriseCapacityInfoChange(val) {
      this.enterpriseCapacityPage.pages.pageSize = val;
      this.init();
    },
    enterpriseCapacityCurrentChange(val) {
      this.enterpriseCapacityPage.pages.page = val;
      this.init();
    },
    //初始化
    init() {


      this.enterpriseCapacityQueryByPage();
    },
    //数据字典
    lxFl() {
      selectDictDataByType({dictType: "enterprise_capacity"}).then(
          (res) => {
            if (res.ok) {
              this.qycnlx = res.data;
            }
          }
      );
    },
    //表单查询
    searchForm() {


      this.enterpriseCapacityPage.pages.page = 1;
      this.init();


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.enterprise = "";
      this.province = "";
      this.city = "";
      this.type = "";
      this.init();
      // this.selectPolicy(this.selectPolicyPage.pages);
    },
    // //数组去重
    // unique(arr) {
    //     for (var i = 0; i < arr.length; i++) {
    //         for (var j = i + 1; j < arr.length; j++) {
    //             if (arr[i] == arr[j]) {
    //                 arr.splice(j, 1);
    //                 j--;
    //             }
    //         }
    //     }
    //     return arr;
    // },
    // //获取字典
    // getCategory() {
    //     selectDictDataByType({ dictType: "policy_hot_field" }).then(
    //         (res) => {
    //             if (res.ok) {
    //                 this.belongAreasData = res.data;
    //             }
    //         }
    //     );
    // },
  }
  ,
}
;
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  display: flex;
  align-content: center;
}

.search-form {
  padding: 20px 30px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      width: 85px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      width: 77px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 230px;

      /deep/ .el-input__inner,
      /deep/ .el-input__icon,
      /deep/ &.el-date-editor .el-range-separator,
      /deep/ &.el-range-editor.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.nature-tag {
  overflow: hidden;
  margin-left: -10px;

  li {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    padding: 0 2px;
    border: 1px solid #828282;
    color: #828282;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &.ac1 {
      color: #219653;
      border-color: #219653;
    }

    &.ac2 {
      color: #eb5757;
      border-color: #eb5757;
    }
  }
}

.nature-box {
  position: relative;
  padding-left: 14px;
  height: 22px;
  line-height: 22px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -3px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d9d9d9;
  }

  &.lh {
    &::after {
      background: #27ae60;
    }
  }

  &.lk {
    &::after {
      background: #eb5757;
    }
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 80px;
  width: 41px;
  padding-top: 26px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

.more-icon {
  width: 15px
}
::v-deep .el-dialog__body {
  padding: 0px 20px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

#mapChart {
  background-color: white;
  height: 800px;
  width: 100%;
  padding: 40px
}

.map-box {
  background-color: white;
  height: 600px;
  width: 100%;
  padding: 0 40px
}



.outer-layer-box {

  padding-left: 21px;
}
</style>
