<template>
  <PageContainer>
    <!--    <div class="not-login-box" v-if="!show">-->
    <!--      <VnotLogin title="洞察报告" text="市场洞察栏目围绕市场现状本身从深度和广度全方位展开，分析市场现状、洞察市场走势。" tableTitle="最新洞察报告" :showTips="showTips"/>-->
    <!--    </div>-->
    <template>
      <div class="box">
        <div class="box_box">
          <div style="overflow: hidden">
            <div style="float: left; margin-bottom: 10px" flex="c">
              <el-checkbox label="全部" border size="small" style="margin-right: 8px" @change="kckAll"
                           v-model="keywordAll"></el-checkbox>
              <el-checkbox-group v-model="form.articleTypeData" size="small" @change="kcheck" class="lable-box">
                <el-checkbox :label="item.dictValue" border v-for="item in articleTypeData" :key="item.dictValue">
                  {{ item.dictLabel }}
                </el-checkbox>

              </el-checkbox-group>
            </div>
            <div style="float: right" class="search-btn-box ">
              <el-input placeholder="市场洞察报告" v-model="values" size="small"></el-input>
              <div class="search-btn" @click="search">搜索</div>
            </div>
          </div>
        </div>
        <div class="box_box_title" v-for="item in list" :key="item.id" @click="toHref(item)">
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
            </div>
            <div class="flex_col">
              <div>
                <div flex="c" style="margin: 10px 0">
                                <span style="
                      display: inline-block;
                      color: #0D57BC;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    "
                                      v-if="item.contentCategory == 'yc'"
                                >原创</span>
                  <p class="">{{ htmlRestore(item.title) }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.updateTime }}
              </div>
            </div>
          </div>
        </div>

        <div class="pages">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="getSaleListPage.currentPage" :page-sizes="[10, 20, 30, 40]"
                         :page-size="getSaleListPage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                         :total="getSaleListPage.total">
          </el-pagination>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>

import {getSaleList} from "@/api/salesForecast.js";
import {selectDictDataByType} from "@/api/dict.js";

export default {
  components: {

  },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      list: [],
      values: "",
      keywordAll: true,
      getSaleListPage: {
        pages: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      form :{
        articleTypeData :[]
      },
      articleType: [],
      articleTypeData: [],
      title: "",
    };
  },
  created() {
    this.getSaleList();
    this.selectDictDataByType();
  },
  methods: {
    getSaleList() {
      let form = Object.assign({}, this.getSaleListPage.pages, {
        title: this.title,
        articleType: this.articleType,
      });
      form.articleType = form.articleType.join(",");
      getSaleList(form).then((res) => {
        if (res.ok) {
          this.list = res.data.list;
          this.getSaleListPage.total = res.data.count;
        }
      });
    },
    //关键字
    kcheck(e) {
      if (e.length <= 0) {
        this.keywordAll = true;
      } else {
        this.keywordAll = false;
      }
      this.getSaleList();
    },
    //关键字全选
    kckAll() {
      this.form.keywords = [];
      this.getSaleList();
    },
    handleSizeChange(val) {
      this.getSaleListPage.pages.pageSize = val;
      this.getSaleList(this.getSaleListPage.pages);
    },
    handleCurrentChange(val) {
      this.getSaleListPage.pages.pageNo = val;
      this.getSaleList(this.getSaleListPage.pages);
    },
    toHref(item) {
      this.$router.push({
        path: "/sale/salesForecast/detail",
        query: {
          id: item.id,
        },
      });
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "mng_cpfl"}).then((res) => {
        if (res.ok) {
          this.articleTypeData = res.data;
        }
      });
    },
    //切换
    handleChange(e) {
      this.getSaleListPage.pages.pageNo = 1;
      this.articleType = e;
      this.getSaleList();
    },
    search(e) {
      this.getSaleListPage.pages.pageNo = 1;
      this.title = e;
      this.getSaleList();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
