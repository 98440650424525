<template>
  <div>
    <ul class="list-box">
      <li v-for="item in newList" :key="getNodeKey(item)">
        <div class="list-img">
          <img :src="item.img" alt="" />
          <div class="list-tag" v-if="item.module">
            <img src="../assets/img/list-tag.png" alt="">
            {{item.module}}
          </div>
        </div>
        <router-link tag="div" class="list-inner" :to="to + getNodeKey(item)">
          <h1 class="text-overflow">
            {{ item.title }}
          </h1>
          <p>
            {{ item.summary }}
          </p>
          <span>{{ item.publishDate }}</span>
        </router-link>
      </li>
    </ul>
    <div class="pages">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pageData: {
      default() {
        return {
          pages: {
            page: 1,
            pageSize: 10,
          },
          total: 0,
          currentPage: 0,
        };
      },
    },
    to: {
      type: String,
      default: "",
    },
    props: {
      default() {
        return {
          title: "title",
          publishDate: "publishDate",
          img: "coverUrl",
          summary: "summary",
          module:"module"
        };
      },
    },
    nodeKey: String,
  },
  computed: {
    newList() {
      if (this.list.length > 0) {
        for (let key in this.props) {
          this.list.map((item) => {
            return (item[key] = item[this.props[key]]);
          });
        }
        return this.list;
      } else {
        return [];
      }
    },
  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    //绑定key
    getNodeKey(item) {
      if (!this.nodeKey) return item.id;
      return item[this.nodeKey];
    },
  },
};
</script>
<style lang="scss" scoped>
.list-box {
  margin-top: 16px;
  li {
    display: flex;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    .list-img {
      position: relative;
      width: 288px;
      height: 180px;
      line-height: 180px;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 180px;
      }
      .list-tag {
        position: absolute;
        top: 0;
        left: 0;
        width: 87px;
        height: 26px;
        line-height: 26px;
        padding-left: 25px;
        background: linear-gradient(183.55deg, #d1d9e6 42.59%, #ffffff 140.41%);
        border-radius: 4px;
        font-weight: 500;
        color: #0D57BC;
        img{
          position: absolute;
          left: 5px;
          top: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .list-inner {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      padding: 15px 28px 12px 20px;
      cursor: pointer;
      h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #141414;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        margin-top: auto;
      }
    }
  }
}
</style>