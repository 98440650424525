<template>
  <div class="loginImg-box">
    <div class="loginImg-inner">
      <div class="loginImg-title">
<!--        <h3 style="padding-top: 40px;padding-left: 40px;font-size: 22px;font-weight: bold"><span style="color: #0d57bc">//</span><span style="color: #0d57bc;">市场洞察栏目上线</span></h3>-->
<!--        <div class="content-img">基于多方数据分析市场现状，研判市场趋势，以AUTOINFO为视点，为客户提供前瞻、全面、深入的原创市场洞察报告，主要覆盖整体市场、乘用车、商用车、新能源、进出口、产业链等</div>-->
        <h3 style="padding-top: 20px;padding-left: 40px;font-size: 22px;font-weight: bold"><span style="color: #0d57bc">//</span><span style="color: #0d57bc;">温馨提示</span></h3>
        <div class="content-img"><a href="#" @click="submit('/usedCar/reportOne')">二手车栏目</a>、<a href="#" @click="submit('/marketInsight/index')">市场洞察栏目</a><span >限时免费已经关闭</span>，如需继续使用，可申请试用/正式订阅<br>电话：022-84379145/18846828756<br>邮箱：liwenjuan@catarc.ac.cn</div>
      </div>
      <div class="loginImg-content">
        <div class="btn">
<!--          <el-button plain @click="close">稍后再说</el-button>-->
<!--          <el-button type="primary" @click="submit">立即体验</el-button>-->
                    <el-button type="primary" @click="close">知道了</el-button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  created() {

  },
  methods: {
    //关闭
    close() {
      this.$emit("close");
    },
    //提交
    submit(href) {
      this.$router.push(href);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.loginImg-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba($color: #000, $alpha: 0.3);
  display: flex;
  .loginImg-inner {
    width: 600px;
    height: 375px;
    border-radius: 8px;
    margin: auto;


    .loginImg-title {
      border-top-left-radius:6px;
      border-top-right-radius:6px;
      width: 100%;
      height: 260px;
      //line-height: 446px;
      //background: url(../assets/img/tzdt1.png) no-repeat;
      //background-size: 100% 100%;
      background-color: white;
      text-align: left;
      //font-weight: bold;
      //font-size: 32px;
      //color: #fff;
    }
    .loginImg-content {
      border-bottom-right-radius:6px;
      border-bottom-left-radius:6px;
      background: #fff;
      //background: url(../assets/img/tzdt.png) no-repeat;
      //background-size: 100% 100%;
      h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #141414;
        padding-top: 26px;
        margin-bottom: 6px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        margin-bottom: 16px;
      }
      .loginImg-item-box {
        display: flex;
        .loginImg-item-l {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          width: 109px;
          margin-right: 40px;
          .loginImg-item-btn {
            height: 38px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
            &.act {
              background: #f3f8ff;
            }
          }
        }
        .loginImg-item-r {
          flex: 1;
          align-self: flex-end;
          .loginImg-item-select {
            display: flex;
            margin-bottom: 24px;
            a {
              display: block;
              margin-left: 10px;
              min-width: 100px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 14px;
              color: #828282;
              border: 1px solid #828282;
              border-radius: 4px;
              &.act {
                color: #0D57BC;
                border-color: #0D57BC;
              }
            }
          }
          .loginImg-item-content {
            /deep/ .el-textarea__inner {
              height: 160px;
            }
          }
          /deep/ {
            .el-form-item__content {
              width: 150px;
            }
            .el-form-item {
              margin-bottom: 20px;
            }
          }
        }
      }
      .btn {

        text-align: right;
        padding: 20px 0;
        padding-right: 30px;
      }
    }
  }
}
.content-img{
  margin: 10px 30px;
  padding: 30px;
  font-size: 16px;
  color: #000;
  background-color: rgb(219, 227, 253);
  background-size: 80% 80%;
  border-radius: 4px;
  text-align: justify;
  line-height: 34px;
}
</style>