<!--
 * @Author: your name
 * @Date: 2021-07-21 13:08:48
 * @LastEditTime: 2021-07-21 14:41:48
 * @LastEditors: Please set LastEditors
 * @Description: 国内产销数据页面
 * @FilePath: /information-vue/code/src/views/data/domesticData/index.vue
-->
<template>
  <PageContainer investigate>
    <div flex="sb">
      <div @click="isActive = '批发'" :class="'box ' + (isActive == '批发' ? 'is_active' : '')">
        <div class="data-top-title">
          <div class="data-top-title-inner" flex="c">
            <input type="radio" class="radio-style" :checked="isActive == '批发'?true:false" />
            <span class="text">批发数据</span>
            <span class="time">已更新至{{updataDate1}}</span>
          </div>
          <!-- <button class="title-down-btn" v-has-role="'trialrole'" @click="dialogVisibleFun"><i class="el-icon-download"></i>下载电子版本</button> -->
        </div>
      </div>
      <div @click="isActive = '零售'" :class="'box ' + (isActive == '零售' ? 'is_active' : '')">
        <div class="data-top-title">
          <div class="data-top-title-inner" flex="c">
            <input type="radio" class="radio-style" :checked="isActive == '零售'?true:false" />
            <span class="text">零售数据</span>
            <span class="time">已更新至{{updataDate}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 批发数据 -->
    <WholesaleData v-if="isActive=='批发'" :updataDate="updataDate1" :show="show" ref="WholesaleData"></WholesaleData>
    <!-- 零售数据 -->
    <RetailSalesData v-if="isActive=='零售'" :updataDate="updataDate" :show="show"></RetailSalesData>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import RetailSalesData from "./components/retailSalesData";
import WholesaleData from "./components/wholesaleData";

import {
    TtPassengerSaaocitionDataLastTime,
    ykbQcPpGetDefaultDate,
} from "@/api/data.js";

export default {
    components: {
        // VnotLogin,
        RetailSalesData,
        WholesaleData,
    },
    data() {
        return {
            show: false,
            isActive: "批发",
            showTips: [
                {
                    title: "数据动态",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "数据库查询",
                    text: "权威数据库，多条件筛查关联比对",
                },
                {
                    title: "数据梳理",
                    text: "专家梳理整合，真正读懂数据脉络",
                },
                {
                    title: "数据大数据",
                    text: "用数据深度理解数据结构和趋势",
                },
            ],
            dialogVisible: false,
            updataDate: "",
            updataDate1: "",
        };
    },
    mounted() {
        if (getUserInfo()) {
            this.show = true;

        } else {
            this.show = false;
        }
      this.TtPassengerSaaocitionDataLastTime();
      this.ykbQcPpGetDefaultDate();
    },
    methods: {
        dialogVisibleFun() {
            this.$refs.WholesaleData.dialogVisible = true;
        },
        TtPassengerSaaocitionDataLastTime() {
            TtPassengerSaaocitionDataLastTime().then((res) => {
                if (res.ok) {
                    this.updataDate = res.data;
                }
            });
        },
        ykbQcPpGetDefaultDate() {
            ykbQcPpGetDefaultDate().then((res) => {
                if (res.ok) {
                    this.updataDate1 = res.data;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: calc(50% - 8px);
    cursor: pointer;
    margin-bottom: 16px;
    .data-top-title {
        height: 64px;
        padding: 16px;
        background: #fff;
    }
    .text,
    .title-down-btn {
        color: #0d57bc;
    }
    .time {
        color: #333;
    }
    .title-down-btn {
        border: 1px solid #0d57bc;
    }
    .radio-style {
        width: 24px;
        height: 24px;
        appearance: none;
        position: relative;
        margin-right: 8px;
    }
    .radio-style:before {
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        background: rgba(13, 87, 188, 0.08);
        border: 1px solid #0d57bc;
    }
    .radio-style:checked:before {
        content: "";
        width: 24px;
        height: 24px;
        border: 1px solid #fff;
        background: #fff;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
    }
    .radio-style:checked:after {
        content: "";
        width: 11px;
        height: 7px;
        border: 2px solid #0d57bc;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 8px;
        left: 6px;
        transform: rotate(-45deg);
    }
    &.is_active {
        .data-top-title {
            background: linear-gradient(
                    90.79deg,
                    #1768d5 3.39%,
                    rgba(13, 87, 188, 0) 390.23%
                ),
                url("../../../assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png")
                    no-repeat;
            background-size: cover;
        }
        .text,
        .time {
            color: #fff;
        }
    }
}

::v-deep .el-tabs__item.is-active {
    color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
    background-color: #0d57bc;
}
</style>
