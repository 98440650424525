<template>
  <div class="map-box">

    <div id="Map" class="text-center">
      <div class="tips" v-show="tipsShow" :style="tipsStyle" ref="tips">
        <template v-for="(item, index) in tipsContent">
          <el-tag
            :key="index"
            class="tags"
            v-if="item.bsaeType == 'fuel_vehicle'"
            color="#0D57BC"
            >{{ item.city_name }}</el-tag
          >
          <el-tag :key="index" class="tags" v-else color="#27ae60">{{
            item.city_name
          }}</el-tag>
        </template>
        <!-- <el-tag type="success">标签二</el-tag> -->
        <!-- <span v-html="tipsContent"></span> -->
      </div>
    </div>
  </div>
</template>
<script>
import Raphael from "raphael";
import mapData from "@/assets/jsondata/china.json";
export default {
  name: "Map",
  props: {
    newMapData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      district: [],
      R: null, // Raphael对象
      // 设置每一个城市svg图片的属性
      pathAttr: {
        fill: "#fff", // 区地图颜色渐变色
        stroke: "#666", // 区地图描边颜色
        "stroke-width": 1, // 区地图描边宽度
        "stroke-linejoin": "round", // 画笔转折处连接点的画风
      },
      // 城市名称字体的属性
      textAttr: {
        fill: "#000",
        "font-size": "8px",
        cursor: "pointer",
      },
      newData: {},
      tipsShow: false, // 用于控制提示信息是否显示
      tipsContent: "", // 提示的信息内容
      leftPosition: 0, // 鼠标在svg图片上滑动过的OffsetX
      topPosition: 0, // 鼠标在svg图片上滑动过的OffsetY
    };
  },
  computed: {
    comNewMapData() {
      return this.duplicate(this.newMapData);
    },
    tipsStyle() {
      return {
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      };
    },
  },
  created() {
    this.newData = mapData.china;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 设置整体svg图片的高宽
      this.R = Raphael("Map", 1000, 500);
      // 设置svg的视图
      this.R.setViewBox(-150, 0, 1000, 50);
      // 调用绘制地图方法
      this.paintMap(this.R);
      for (let state in this.newData) {
        // 获取每一个svg路径的颜色
        this.district[state]["path"].color = Raphael.getColor(0.9);
        // 处理每一个svg路径的呈现的文字和事件
        // this.processPath(this.district[state]["path"], state);
        this.processPath(this.district[state]["path"], state);
      }
    },
    paintMap(R) {
      for (let key in this.newData) {
        this.district[key] = {
          name: this.newData[key].name,
          path: R.path(this.newData[key].path).attr(this.pathAttr),
          textPosition: this.newData[key].textPosition,
        };
      }
    },
    processPath(svgPath, itemName) {
      // 获取当前图形的中心坐标
      let xx = svgPath.getBBox().x + svgPath.getBBox().width / 1.6;
      let yy = svgPath.getBBox().y + svgPath.getBBox().height / 1.6;
      // 写入文字
      let newNames = this.district[itemName]["name"];
      //***修改部分地图文字偏移坐标
      switch (this.district[itemName]["name"]) {
        case "山西省":
          xx -= 5;
          yy -= 5;
          break;
        case "山东省":
          xx -= 15;
          yy -= 10;
          break;
        case "四川省":
          xx -= 15;
          yy -= 10;
          break;
        case "青海省":
          xx -= 15;
          yy -= 10;
          break;
        case "甘肃省":
          xx += 15;
          yy += 15;
          break;
        case "江西省":
          xx -= 10;
          yy -= 15;
          break;
        case "福建省":
          xx -= 5;
          yy -= 10;
          break;
        case "河北省":
          xx -= 15;
          yy -= 1;
          break;
        case "广东省":
          xx -= 10;
          yy -= 15;
          break;
        default:
      }

      for (let citem in this.comNewMapData) {
        if (newNames == this.comNewMapData[citem].province) {
          this.district[itemName]["text"] = this.R.text(
            xx,
            yy,
            this.comNewMapData[citem].province
          ).attr(this.textAttr);
          svgPath.animate({ fill: "#eee", stroke: "#0D57BC" }, 500);
          // this.district[itemName]["text"][0].append('<div style="position:absolute">465465465465</div>')
          this.district[itemName]["text"][0].addEventListener(
            "mouseover",
            (e) => {
              this.tipsShow = true;
              this.tipsContent = this.comNewMapData[citem].citys;

            }
          );

          this.district[itemName]["text"][0].addEventListener(
            "mouseout",
            (e) => {
              this.tipsShow = false;
            }
          );

          this.district[itemName]["text"][0].addEventListener(
            "mousemove",
            (e) => {
                console.log()
              this.leftPosition = e.clientX + document.body.scrollLeft;
              this.topPosition = e.clientY + document.body.scrollTop-this.$refs.tips.offsetHeight;
            }
          );
        }
      }
    },
    duplicate(arr) {
      var oldDataRule = [];
      arr.forEach((el) => {
        var oldObj = {
          province: el.province,
          citys: [],
        };
        var cityObj = {
          city_name: el.city,
          bsaeType: el.bsaeType,
        };
        oldObj.citys.push(cityObj);
        oldDataRule.push(oldObj);
      });

      var newData = [];
      var newObj = {};
      oldDataRule.forEach((el, i) => {
        if (!newObj[el.province]) {
          newData.push(el);
          newObj[el.province] = true;
        } else {
          newData.forEach((el) => {
            if (el.province === oldDataRule[i].province) {
              el.citys = el.citys.concat(oldDataRule[i].citys);
            }
          });
        }
      });
      return newData;
    },
  },
};
</script>
<style lang="scss" scoped>
.map-box {
  position: relative;
}
.map-grop {
  position: absolute;
  top: -80px;
  right: 28px;
  li {
    padding-left: 24px;
    position: relative;
    line-height: 16px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #4f4f4f;
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    &:nth-child(1) {
      &::after {
        background: #27ae60;
      }
    }
    &:nth-child(2) {
      &::after {
        background: #0D57BC;
      }
    }
  }
}
#Map {
  margin-top: 60px;
  // position: relative;
}

.tips {
  padding: 5px;
  border-radius: 5px;
  position: fixed;
  left: 0;
  top: 0;
  font-size: 14px;
  background-color: #fff;
  width: 300px;
  border: 1px solid #ccc;
  text-align: left;
  .tags {
    margin: 5px;
    color: #fff;
  }
}
</style>