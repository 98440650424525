<template>
    <div class="outer-layer-box">
        <!-- <div style="font-weight: 500;line-height: 16px;color:red ;margin-bottom: 20px;font-size: 16px">2017-2021 如需2022年以后的数据请<router-link to="/data/list" tag="a" target="_blank" ><span>点击此处</span></router-link></div> -->
        <!-- <span>时间：</span>
        <el-select v-model="hisForm.nian" placeholder="请选择" size="small" @change="selectNian">
            <el-option v-for="item in historyYear" :key="item.nian" :label="item.nian" :value="item.nian">
            </el-option>
        </el-select>
        <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px">
            <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-button size="small" type="primary" style="margin-left:5px" @click="hisSearch">查询</el-button>
        <div style="padding-top:10px;min-height: 300px" v-loading="loading" >
            <table border="1" style="width: 100%">
                <tr v-for="(td,index) in historyList" :key="index">
                    <td v-for="item in td" :key="item.id" style="padding:5px">
                        <a href="javascript:;" @click="openIframe(item)">{{item.title}}</a>
                    </td>
                </tr>
            </table>
            <div class="text-right">
                <el-pagination @size-change="hishandleSizeChange" @current-change="hishandleCurrentChange" :current-page="hisForm.page" :page-sizes="[30, 60, 90]" :page-size="hisForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="hisForm.count">
                </el-pagination>
            </div> -->
            <!--新增的内容-->
			<el-card>
				  <div class="addTab">
					  <!--新增的内容左侧-->
					  <div class="addTabLeft">
						  <!-- <p>2016年及以前历史数据</p> -->
						   <div style="font-weight: 500;line-height: 16px;color:red ;margin-bottom: 0px;font-size: 16px">2004年及以前,2005-至今<router-link to="/data/dataTableDownload/index" tag="a" target="_blank" ><span>点击此处</span></router-link></div>
					  </div>
					  <!--新增的内容右侧-->
					  <div class="addTableRight">
						  <el-row :gutter="10">
							  <el-col :span="3"><el-button type="text" @click="change(0)">汽车综合</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(1)">轿车</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(2)">多功能车</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(3)">新能源</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(4)">载货车</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(5)">客车</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(6)">专用汽车</el-button></el-col>
                              <el-col :span="3"><el-button type="text" @click="change(7)">发动机</el-button></el-col>
						  </el-row>
					  </div>
					  <div></div>
				  </div>
                <!--表格数据在每个div中写-->
				<div class="tableB">
                    <div v-show="0===number">
                        <table  border="1" style="width: 100%">
									              <tr>
										                <td><div align="center">2016年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2016/12/index.htm" target="_blank">12月</a></div></td>
									              </tr>
									              <tr>
										                <td><div align="center">2015年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2015/12/index.htm" target="_blank">12月</a></div></td>
									              </tr>
										              <tr>
										                <td><div align="center">2014年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2014/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2013年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2013/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2012年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2012/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2011年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2011/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2010年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2010/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2009年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2009/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2008年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2008/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2007年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2007/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2006年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2006/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2005年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2005/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2004年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2004/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2003年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/1/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/2/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/3/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/4/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/5/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/6/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/7/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/8/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/9/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2003/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2002年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/01/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/02/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/03/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/04/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/05/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/06/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/07/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/08/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/09/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2002/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2001年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/01/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/02/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/03/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/04/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/05/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/06/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/07/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/08/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/09/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2001/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">2000年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/01/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/02/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/03/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/04/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/05/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/06/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/07/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/08/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/09/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/2000/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">1999年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/01/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/02/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/03/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/04/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/05/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/06/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/07/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/08/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/09/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1999/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">1998年</div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/01/index.htm" target="_blank">1月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/02/index.htm" target="_blank">2月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/03/index.htm" target="_blank">3月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/04/index.htm" target="_blank">4月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/05/index.htm" target="_blank">5月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/06/index.htm" target="_blank">6月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/07/index.htm" target="_blank">7月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/08/index.htm" target="_blank">8月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/09/index.htm" target="_blank">9月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/10/index.htm" target="_blank">10月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/11/index.htm" target="_blank">11月</a></div></td>
										                <td><div align="center"><a href="qctjxx_ykb/1998/12/index.htm" target="_blank">12月</a></div></td>
										              </tr>
										              <tr>
										                <td><div align="center">1955-1997年</div></td>
										                <td colspan="12"><div align="center"><a onClick="document_onclick(this); return false;" href="qctjxx_ykb/1955/1997/index.htm" target="_blank">1955～1997年汽车产量</a></div></td>
										              </tr>
												</table>
                    </div>
                    <div v-show="1===number">
                        <table  border="1" style="width: 100%">
									              <tr>
													<!--	<td><div align="center"><a href="/autoinfo_cn/tjxx/cpfl/jc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>-->
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
									              </tr>
									              <tr>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div></td>
									              </tr>
												</table>
                    </div>
                    <div v-show="2===number">
                        <table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>

									              </tr>
									              <tr>
									                    <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_01-03).htm" target="_blank">2001年~2003年</a></div></td>

									              </tr>
												</table>
                    </div>
                    <div v-show="3===number">
                        <table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/xny/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/xny/index-tjxx(cx_2015).htm" target="_blank">2005年~2015年</a></div></td>
									              </tr>

												</table>
                    </div>
                    <div v-show="4===number">
                        <table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
									              </tr>
									              <tr>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div></td>
									              </tr>
												</table>
                    </div>
                    <div v-show="5===number">
                        <table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
									              </tr>
									              <tr>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div></td>
									              </tr>
												</table>
                    </div>
                    <div v-show="6===number">
                        <table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>

									              </tr>
									              <tr>
										                <td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
									              </tr>
												</table></div>
                    <div v-show="7===number">
                        <p>
                        	<table  border="1" style="width: 100%">
									              <tr>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div></td>
														<td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div></td>
									              </tr>
									              <tr>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div></td>
										                <td><div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div></td>
										                <td></td>
									              </tr>
												</table>
                        </p>
                    </div>
                </div>
			</el-card>
        </div>


    </div>
</template>
<script>
    import {
        getYearListHistory,
		getYueListHistory,
        getHistoryPage
    } from "@/api/data.js";

    export default {
        data() {
            return {
                number: 0,
                historyVisible: false,
                loading: false,
                historyYear: [],
                historyYue: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                ],
                hisForm: {
                    nian: "",
                    yue: "02",
                    page: 1,
                    pageSize: 30,
                    count: 0
                },
                historyList: [],
            };
        },
        created() {
            this.init()
        },
        mounted() {
        },
        methods: {
            change (index) {
                this.number = index; //重要处
            },
			selectNian(v){
				this.getYueListHistory(v)
			},
            init() {
                getYearListHistory().then((res) => {
                    if (res.ok) {
                        this.historyYear = res.data;
                        this.hisForm.nian = res.data[0].nian;
						this.getYueListHistory(res.data[0].nian)
                        this.getHistoryPage();
                    }
                })
            },
			getYueListHistory(nian){
				getYueListHistory(nian).then(res=>{
						this.historyYue = res.data
						this.hisForm.yue = res.data[res.data.length-1]
					})
			},
            getHistoryPage() {
                this.loading=true
                let form = Object.assign({}, this.hisForm);
                getHistoryPage(form).then((res) => {
                    this.loading=false
                    if (res.ok) {
                        let data = res.data.list;
                        var arr = [];
                        for (var i = 0; i < data.length; i++) {
                            if (i % 3 == 0) {
                                var a = [];
                                arr.push(a);
                            }
                            a.push(data[i]);
                        }
                        this.historyList = arr;
                        this.hisForm.count = res.data.count
                    }
                }).catch(e=>{
                    this.loading=false
                });
            },
            hishandleSizeChange(v) {
                this.hisForm.pageSize = v;
                this.getHistoryPage()
            },
            hishandleCurrentChange(v) {
                this.hisForm.page = v;
                this.getHistoryPage()
            },
            hisSearch() {
                this.getHistoryPage()
            },
            openIframe(item) {
                window.open(item.url)
                // this.iframeSrc = item.url;
                // this.iframeVisible = true;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .outer-layer-box {
        padding: 20px;
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #0d57bc;
    }

    .search-form {
        padding: 28px;
        background: #fff;
        margin-bottom: 10px;

        /deep/ {
            .el-date-editor .el-range-separator {
                width: 12%;
            }

            .el-checkbox-group {
                line-height: 34px;
            }

            .el-checkbox {
                margin-right: 16px;
            }

            .el-form-item__content {
                width: 179px;
            }

            .el-card {
                overflow: visible;
            }
        }

        .search-form-title {
            text-align: center;
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
    .addTab{
        width: 100%;
        height: 51px;
        margin-top: 20px;
        /*background-color: #0D57BC;*/
        border: 1px solid black;
    }
    .addTabLeft{
		display: flex;
		align-items: center;
        width: 30%;
        height: 50px;
        float: left;
        padding-left: 10px;
        line-height: 50px;
		color: red;
    }
    .addTableRight{
        width: 51%;
        height: 50px;
        float: right;
    }
    .tableB{
        width: 100%;
        float: right;
        margin-bottom: 10px;

    }
</style>
