<template>
  <PageContainer>
    <!-- 右侧的电梯组件 -->
    <elevator :floors="elevatorFloors" scroll-dom-selector="html" />
    <not-logged-in-mask v-if="showNotLoggedInMask" />
    <unsubscribed-mask v-if="showUnsubscribedMask" />
    <card
      ref="overallProductionSalesDataRef"
      :class="['box', showMask ? 'show-mask' : '']"
      title="总体产销数据"
      :titlebtn="overallProductionSalesDataBtnList"
      cion-show
      titleWidth="8%"
      btnCenter="left"
      btn-id="overallProductionSales"
      is-card-container
      @cardBtnchange="cardBtnchange"
    >
      <template slot="body">
        <div class="overallProductionSalesBody">
          <el-row :gutter="16">
            <card title="指标看板" class="indicator-board">
              <template slot="body">
                <bulletinBoardSalesVolume
                  v-if="bulletinBoardSalesVolumeFlag"
                  :data="overallSalesData"
                />
                <bulletinBoard v-else :data="overallSalesData" />
              </template>
            </card>
            <card
              :title="overallProductionSalesEchartName"
              class="changing-trends right-grid-reduce-gap"
              btn-id="overallProductionSalesEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <salesVolumeEchart
                  ref="overallProductionSalesEchart"
                  :height="452"
                  unit="辆"
                />
              </template>
            </card>
          </el-row>
        </div>
      </template>
    </card>
    <card
      ref="passengerCarMarketPerformanceRef"
      style="margin-top:12px"
      title="乘用车市场表现"
      cion-show
      is-card-container
      :class="[
        'box',
        showMask ? 'show-mask' : '',
        'passenger-car-market-performance-body',
      ]"
    >
      <template slot="body">
        <div class="passengerCarMarketPerformanceBody">
          <el-row :gutter="16">
            <card
              :title="
                `${date.passengerCarMarketPerformanceLeft}乘用车分地区销量`
              "
              sub-title="(辆)"
              :titlebtn="passengerCarMarketPerformanceBtnList"
              btn-id="passengerCarMarketPerformanceLeft"
              class="passenger-car-market-performance-left"
              style="margin-bottom: 16px;"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <pieEchart
                  ref="passengerCarMarketPerformanceLeftEchart"
                  :height="434"
                />
              </template>
            </card>
            <card
              :title="
                `${date.passengerCarMarketPerformanceCenter}乘用车分省份销量`
              "
              :titlebtn="passengerCarMarketPerformanceBtnList"
              btn-id="passengerCarMarketPerformanceCenter"
              class="passenger-car-market-performance-center"
              style="margin-bottom: 16px;"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <top
                  ref="passengerCarMarketPerformanceCenter"
                  dataUnit="辆"
                  :height="434"
                  rote-show
                  :top-num="7"
                  :top-item-height="65"
                />
              </template>
            </card>
            <card
              :title="
                `${date.passengerCarMarketPerformanceRight}乘用车分城市销量`
              "
              :titlebtn="passengerCarMarketPerformanceBtnList"
              btn-id="passengerCarMarketPerformanceRight"
              class="passenger-car-market-performance-right"
              style="margin-bottom: 16px;"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <top
                  ref="passengerCarMarketPerformanceRight"
                  dataUnit="辆"
                  :height="434"
                  rote-show
                  :top-num="7"
                  :top-item-height="65"
                />
              </template>
            </card>
            <card
              style="margin-bottom: 16px;"
              :title="
                `${date.passengerCarMarketPerformanceLeftbottom}企业销量榜单`
              "
              class="passenger-car-market-performance-left-bottom"
            >
              <template slot="body">
                <top
                  ref="passengerCarMarketPerformanceLeftbottom"
                  dataUnit="辆"
                  :height="434"
                  rote-show
                  :top-num="7"
                  :top-item-height="65"
                />
              </template>
            </card>
            <card
              style="margin-bottom: 16px;"
              :title="
                `${date.passengerCarMarketPerformanceRightbottom}车型销量榜单`
              "
              class="passenger-car-market-performance-right-bottom"
            >
              <template slot="body">
                <div v-if="btnList.length > 0" class="btn-box">
                  <el-button
                    v-for="(btnItem, index) in btnList"
                    :key="index"
                    size="mini"
                    type="primary"
                    :class="`${btnItem.isClick ? 'isClick' : ''}`"
                    @click="btnClick(btnItem)"
                  >
                    {{ btnItem.title }}
                  </el-button>
                  <!-- <el-select
                    v-model="passengerCarMarketPerformanceRightbottomSelect"
                    placeholder="请选择"
                    style="width:100px;margin-left:12px"
                    size="small"
                    @change="selectChange"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select> -->
                </div>
                <top
                  ref="passengerCarMarketPerformanceRightbottom"
                  dataUnit="辆"
                  :height="389"
                  rote-show
                  :top-num="6"
                  :top-item-height="69"
                />
              </template>
            </card>
          </el-row>
        </div>
      </template>
    </card>
    <card
      ref="newEnergyRef"
      style="margin-top:12px"
      title="新能源专区"
      cion-show
      is-card-container
      :class="['box', showMask ? 'show-mask' : '']"
    >
      <template slot="body">
        <div class="newEnergyBody">
          <el-row :gutter="16">
            <card
              style="margin-bottom: 16px"
              title="公共类充电桩保有量"
              class="right-grid-reduce-gap new-energy-charging-station"
              btn-id="newEnergyChargingStationOnlyEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <salesVolumeEchart
                  ref="newEnergyChargingStationOnlyEchart"
                  :height="452"
                />
              </template>
            </card>
            <card
              style="margin-bottom: 16px"
              title="动力电池装机量走势图"
              class="right-grid-reduce-gap new-energy-battery"
              btn-id="newEnergyBatteryEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <salesVolumeEchart ref="newEnergyBatteryEchart" :height="452" />
              </template>
            </card>
            <card
              :title="
                `${date.newEnergyLeftBottom}TOP10省份${newEnergyLeftBottomTitle}保有量`
              "
              :titlebtn="newEnergyBtnList"
              btn-id="newEnergyLeftBottom"
              class="new-energy-left-bottom"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <top
                  ref="newEnergyLeftBottom"
                  :dataUnit="newEnergyLeftBottomUnit"
                  :height="434"
                  rote-show
                  :top-item-height="69"
                  :top-num="9"
                  :yoy="false"
                  :qoq="false"
                />
              </template>
            </card>
            <card
              :title="
                `${date.newEnergyCenterBottom}TOP10运营商${newEnergyCenterBottomTitle}保有量`
              "
              :titlebtn="newEnergyBtnList2"
              btn-id="newEnergyCenterBottom"
              class="new-energy-center-bottom"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <top
                  ref="newEnergyCenterBottom"
                  :dataUnit="newEnergyCenterBottomUnit"
                  :height="434"
                  rote-show
                  :top-item-height="69"
                  :top-num="9"
                  :yoy="false"
                  :qoq="false"
                />
              </template>
            </card>
            <card
              :title="`${date.newEnergyRightBottom}动力电池装机量TOP10企业`"
              class="new-energy-right-bottom"
            >
              <template slot="body">
                <top
                  ref="newEnergyRightBottom"
                  dataUnit="GWh"
                  :height="434"
                  rote-show
                  :top-item-height="69"
                  :top-num="9"
                  :yoy="false"
                  :qoq="false"
                />
              </template>
            </card>
          </el-row>
        </div>
      </template>
    </card>
    <card
      ref="globalDataRef"
      style="margin-top:12px"
      title="全球数据"
      cion-show
      is-card-container
      :class="['box', showMask ? 'show-mask' : '']"
    >
      <template slot="body">
        <div class="globalDataBody">
          <el-row :gutter="16">
            <card
              title="出口量"
              style="margin-bottom: 16px"
              class="right-grid-reduce-gap global-data-car-export"
              btn-id="globalDataCarExportOnlyEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <salesVolumeEchart
                  ref="globalDataCarExportOnlyEchart"
                  :height="452"
                />
              </template>
            </card>
            <card
              title="进、出口金额"
              style="margin-bottom: 16px"
              class="right-grid-reduce-gap global-data-amount"
              btn-id="globalDataAmountOnlyEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <salesVolumeEchart
                  ref="globalDataAmountOnlyEchart"
                  :height="452"
                  unit="万美元"
                />
              </template>
            </card>
            <card
              :title="
                `${date.globalDataLeftBottom}出口量TOP10${globalDataBtnChickTitle}`
              "
              :titlebtn="globalDataBtnList"
              btn-id="globalDataLeftBottom"
              class="global-data-left-bottom"
              @cardBtnchange="cardBtnchange"
            >
              <template slot="body">
                <top
                  ref="globalDataLeftBottom"
                  dataUnit="辆"
                  :height="434"
                  rote-show
                  :top-num="7"
                  :top-item-height="65"
                />
              </template>
            </card>
            <card
              :title="`${date.globalDataLeftBottomEchart}主流国家汽车销量`"
              class="global-data-right-bottom"
              btn-id="globalDataLeftBottomEchart"
              @exportSvg="exportSvg"
            >
              <template slot="body">
                <barEchart ref="globalDataLeftBottomEchart" :height="434" />
              </template>
            </card>
          </el-row>
        </div>
      </template>
    </card>
  </PageContainer>
</template>

<script>
import { authMixin } from "@/mixin/newData/index.js";
import { NotLoggedInMask, UnsubscribedMask } from "@/components/newData/mask";
import card from "./common/card.vue"; // 卡片组件
import top from "./common/top.vue"; // 排行榜组件
import Utils from "./common/utils.js"; // 工具类
import bulletinBoard from "./common/bulletinBoard.vue"; // 顶部数据看板
import bulletinBoardSalesVolume from "./common/bulletinBoardSalesVolume.vue"; // 顶部数据看板
import salesVolumeEchart from "./common/salesVolumeEchart.vue"; // 折线图
import pieEchart from "./common/pieEchart.vue"; // 环图
import barEchart from "./common/barEchart.vue"; // 柱状图
import {
  getOverallSales, // 获取总体产销数据
  getPassengerMarketPerformanceAll, // 乘用车市场表现-全部数据
  getPassengerMarketPerformanceRegionOnly, // 乘用车市场表现-乘用车分地区销量-全部
  getPassengerMarketPerformanceRegionNewEnergy, // 乘用车市场表现-乘用车分地区销量-新能源
  getPassengerMarketPerformanceEnterpriseOnly, // 乘用车市场表现-企业榜单
  getPassengerMarketPerformanceVehicleModelOnly, // 乘用车市场表现-获取车型榜单
  getPassengerMarketPerformanceVehicleModelNewEnergy, // 乘用车市场表现-获取车型榜单-新能源
  getpassengerMarketPerformanceVehicleModelBev, // 乘用车市场表现-获取车型榜单-纯电动
  getpassengerMarketPerformanceVehicleModelPhev, // 乘用车市场表现-获取车型榜单-插电混
  getpassengerMarketPerformanceVehicleModelMethanol, // 乘用车市场表现-获取车型榜单-甲醇
  getpassengerMarketPerformanceVehicleModelFuelCell, // 乘用车市场表现-获取车型榜单-燃料电池
  getNewEnergyAll, // /新能源专区-全部数据
  getNewEnergyChargingStationOnly, // 新能源专区-公共类充电桩保有量
  getNewEnergyBatteryOnly, // 新能源专区-动力电池装机量走势图
  getNewEnergyProvinceChargingChargingPoint, // 新能源专区-省份充电桩保有量
  getNewEnergyProvinceChargingChargingStation, // 新能源专区-省份充电站保有量
  getNewEnergyProvinceChargingPowerExchangeStation, // 新能源专区-省份换电站保有量
  getNewEnergyEnterpriseChargingChargingPoint, // 新能源专区-运营商充电桩保有量
  getNewEnergyEnterpriseChargingChargingStation, // 新能源专区-运营商充电站保有量
  getNewEnergyEnterpriseChargingPowerExchangeStation, // 新能源专区-运营商换电站保有量
  getnewEnergyEnterpriseBatteryOnly, // 新能源专区-动力电池装机量TOP10企业
  getGlobalDataAll, // 全球数据-全部数据
  getGlobalDataAmountOnly, // 全球数据-进、出口金额
  getGlobalDataCarExportOnly, // 全球数据-进、出口量
  getGlobalDataExportOnly, // 全球数据-获取出口量TOP10数据
  getGlobalDataCountryOnly, // 全球数据-获取主流国家销量走势
  getGlobalDataExportEnterprise, // 全球数据-获取出口量TOP10数据下，筛选 企业
  getGlobalDataExportVehicleModel, // 全球数据-获取出口量TOP10数据下，筛选 车型
} from "@/api/data_new.js";
import Elevator from "@/components/newData/elevator/index";
import { log } from "@antv/g2plot/lib/utils";

export default {
  name: "Overview",
  components: {
    card, // 卡片组件
    top, // 排行榜组件
    bulletinBoard, // 顶部数据看板
    bulletinBoardSalesVolume, // 顶部数据看板
    salesVolumeEchart, // 折线图
    pieEchart, // 环图
    barEchart, // 柱状图
    NotLoggedInMask,
    UnsubscribedMask,
    Elevator,
  },
  mixins: [authMixin],
  data() {
    return {
      elevatorFloors: [
        {
          label: "总体产销数据",
          targetFloorRef: "overallProductionSalesDataRef",
          ready: false,
          offsetTop: 0,
          offsetHeight: 0,
        },
        {
          label: "乘用车市场表现",
          targetFloorRef: "passengerCarMarketPerformanceRef",
          ready: false,
          offsetTop: 0,
          offsetHeight: 0,
        },
        {
          label: "新能源专区",
          targetFloorRef: "newEnergyRef",
          ready: false,
          offsetTop: 0,
          offsetHeight: 0,
        },
        {
          label: "全球数据",
          targetFloorRef: "globalDataRef",
          ready: false,
          offsetTop: 0,
          offsetHeight: 0,
        },
      ],
      bulletinBoardSalesVolumeFlag: true, // 看板切换
      date: {
        default: null,
        globalDataLeftBottomEchart: "",
        globalDataLeftBottom: "",
        passengerCarMarketPerformanceRightbottom: "",
        passengerCarMarketPerformanceLeftbottom: "",
        passengerCarMarketPerformanceRight: "",
        passengerCarMarketPerformanceCenter: "",
        passengerCarMarketPerformanceLeft: "",
        newEnergyLeftBottom: "",
        newEnergyCenterBottom: "",
        newEnergyRightBottom: "",
      }, // 卡片显示年月
      overallProductionSalesEchartName: "",
      // 总体产销数据按钮
      overallProductionSalesDataBtnList: [
        {
          title: "产量",
          isClick: 1,
          id: 1,
        },
        {
          title: "销量",
          isClick: 0,
          id: 2,
        },
      ],
      // 总体产销数据
      overallSalesData: {},
      // 乘用车市场表现按钮
      passengerCarMarketPerformanceBtnList: [
        {
          title: "全部",
          isClick: 1,
          id: 1,
        },
        {
          title: "新能源",
          isClick: 0,
          id: 2,
        },
      ],
      // 新能源专区表现按钮
      newEnergyBtnList: [
        {
          title: "充电桩",
          isClick: 1,
          id: 1,
        },
        {
          title: "充电站",
          isClick: 0,
          id: 2,
        },
        {
          title: "换电站",
          isClick: 0,
          id: 3,
        },
      ],
      newEnergyBtnList2: [
        {
          title: "充电桩",
          isClick: 1,
          id: 1,
        },
        {
          title: "充电站",
          isClick: 0,
          id: 2,
        },
      ],
      newEnergyLeftBottomTitle: "充电桩",
      newEnergyCenterBottomTitle: "充电桩",
      // 全球数据表现按钮
      globalDataBtnList: [
        {
          title: "企业",
          isClick: 1,
          id: 1,
        },
        {
          title: "车型",
          isClick: 0,
          id: 2,
        },
      ],
      globalDataBtnChickTitle: "企业",
      btnList: [
        {
          title: "全部",
          isClick: 1,
          value: "",
          id: 1,
        },
        {
          title: "轿车",
          isClick: 0,
          value: "passengerMarketPerformance-vehicleModel-car",
          id: 2,
        },
        {
          title: "SUV",
          isClick: 0,
          value: "passengerMarketPerformance-vehicleModel-suv",
          id: 3,
        },
        {
          title: "MPV",
          isClick: 0,
          value: "passengerMarketPerformance-vehicleModel-mpv",
          id: 4,
        },
        {
          title: "交叉",
          isClick: 0,
          value: "passengerMarketPerformance-vehicleModel-crossover",
          id: 5,
        },
      ],
      passengerCarMarketPerformanceRightbottombtn: "",
      passengerCarMarketPerformanceRightbottomSelect: "",
      selectList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "新能源",
          value: "passengerMarketPerformance-vehicleModel-newEnergy",
        },
        {
          label: "纯电动",
          value: "passengerMarketPerformance-vehicleModel-bev",
        },
        {
          label: "插电混",
          value: "passengerMarketPerformance-vehicleModel-phev",
        },
        {
          label: "甲醇",
          value: "passengerMarketPerformance-vehicleModel-methanol",
        },
        {
          label: "燃料电池",
          value: "passengerMarketPerformance-vehicleModel-fuelCell",
        },
      ],
      newEnergyLeftBottomUnit: "台",
      newEnergyCenterBottomUnit: "台",
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.elevatorFloors.forEach((floor) => {
      const $ref = this.$refs[floor.targetFloorRef];
      floor.ready = true;
      floor.offsetTop = $ref.$el.offsetTop;
      floor.offsetHeight = $ref.$el.offsetHeight;
    });
  },
  methods: {
    /**
     * @description: 初始化数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 14:55:30
     * @LastEditTime: Do not edit
     */
    initData() {
      this.getOverallProductionSalesData("Production"); // 获取总体产销数据
      // this.getPassengerMarketPerformanceAll(); // 乘用车市场表现-全部数据
      this.getPassengerMarketPerformanceRegionOnly(); // 乘用车市场表现-乘用车分地区销量-全部
      // this.getPassengerMarketPerformanceRegionNewEnergy(); //乘用车市场表现-乘用车分地区销量-新能源
      this.getPassengerMarketPerformanceEnterpriseOnly(); // 乘用车市场表现-企业榜单
      this.getPassengerMarketPerformanceVehicleModelOnly(); // 乘用车市场表现-获取车型榜单
      // this.getPassengerMarketPerformanceVehicleModelNewEnergy(); //乘用车市场表现-获取车型榜单-新能源
      this.getpassengerMarketPerformanceVehicleModelBev(); // 乘用车市场表现-获取车型榜单-纯电动
      this.getpassengerMarketPerformanceVehicleModelPhev(); // 乘用车市场表现-获取车型榜单-插电混
      this.getpassengerMarketPerformanceVehicleModelMethanol(); // 乘用车市场表现-获取车型榜单-甲醇
      this.getpassengerMarketPerformanceVehicleModelFuelCell(); // 乘用车市场表现-获取车型榜单-燃料电池

      // this.getNewEnergyAll(); // /新能源专区-全部数据
      this.getNewEnergyChargingStationOnly(); // 新能源专区-公共类充电桩保有量
      this.getNewEnergyBatteryOnly(); // 新能源专区-动力电池装机量走势图
      this.getNewEnergyProvinceChargingChargingPoint(); // 新能源专区-省份充电桩保有量
      // this.getNewEnergyProvinceChargingChargingStation(); //新能源专区-省份充电站保有量
      // this.getNewEnergyProvinceChargingPowerExchangeStation(); //新能源专区-省份换电站保有量
      this.getNewEnergyEnterpriseChargingChargingPoint(); // 新能源专区-运营商充电桩保有量
      // this.getNewEnergyEnterpriseChargingChargingStation(); //新能源专区-运营商充电站保有量
      // this.getNewEnergyEnterpriseChargingPowerExchangeStation(); //新能源专区-运营商换电站保有量
      this.getnewEnergyEnterpriseBatteryOnly(); // 新能源专区-动力电池装机量TOP10企业

      // this.getGlobalDataAll(); // 全球数据--全部数据
      this.getGlobalDataAmountOnly(); // 全球数据--进、出口金额
      this.getGlobalDataCarExportOnly(); // 全球数据-进、出口量
      this.getGlobalDataExportOnly(); // 全球数据-获取出口量TOP10数据
      this.getGlobalDataCountryOnly(); // 全球数据-获取主流国家销量走势
      this.getGlobalDataExportEnterprise(); // 全球数据-获取出口量TOP10数据下，筛选 企业
    },
    /**
     * @description: 获取总体产销数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 14:56:00
     * @LastEditTime: Do not edit
     */
    getOverallProductionSalesData(type) {
      this.$nextTick(() => {
        this.$refs.overallProductionSalesEchart.loadingOpen();
      });
      getOverallSales().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装左侧看板数据渲染
          this.initOverallSalesData(data);
          this.bulletinBoardSalesVolumeFlag = type === "Sales";
          // 封装右侧折线图数据渲染
          this.initOverallProductionSalesEchartData(type);
          // console.log(data, "获取总体产销数据-已对接");
        }
      });
    },
    /**
     * @description: 封装左侧看板数据渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:54:36
     * @LastEditTime: Do not edit
     */

    initOverallSalesData(data) {
      // 封装左侧看板数据
      let date = Utils.insertStr(data.overallSalesKey, 4, "年");
      date = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月
      this.date.default = date; // 年月
      this.overallSalesData = { ...data, ...{ date: date } };
    },
    /**
     * @description:封装右侧折线图数据渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:56:07
     * @LastEditTime: Do not edit
     */
    initOverallProductionSalesEchartData(type) {
      // 封装右侧折线图数据
      const xAxisData = this.overallSalesData.overallSalesKeyList.map(
        (item) => {
          return Utils.insertStr(item, 4, ".");
        }
      );
      this.overallProductionSalesEchartName = `${
        type === "Sales" ? "销售量" : "产量"
      }变化趋势`;
      const overallProductionSalesEchartData = {
        xAxisData: xAxisData.reverse(), // x轴数据
        seriesData: [
          {
            name: `整体${type === "Sales" ? "销售量" : "产量"}`,
            type: "line",
            // stack: "Total",
            color: "#165DFF",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            data: this.overallSalesData[
              `overall${type}TotalValueList`
            ].reverse(),
          },
          {
            name: `乘用车${type === "Sales" ? "销售量" : "产量"}`,
            type: "line",
            // stack: "Total",
            color: "#F7BA1E",
            showSymbol: false, // 去掉点
            smooth: true, // 变得圆滑一点
            data: this.overallSalesData[
              `overall${type}PassengerValueList`
            ].reverse(),
          },
          {
            name: `商用车${type === "Sales" ? "销售量" : "产量"}`,
            type: "line",
            // stack: "Total",
            color: "#14C9C9",
            smooth: true, // 变得圆滑一点
            showSymbol: false, // 去掉点
            data: this.overallSalesData[
              `overall${type}CommercialValueList`
            ].reverse(),
          },
        ], // 展示数据
      };
      this.$refs.overallProductionSalesEchart.init(
        overallProductionSalesEchartData
      );
    },
    /**
     * @description: //乘用车市场表现-全部数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 09:25:03
     * @LastEditTime: Do not edit
     */
    getPassengerMarketPerformanceAll() {
      getPassengerMarketPerformanceAll().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-全部数据-未对接");
        }
      });
    },
    /**
     * @description: //乘用车市场表现-乘用车分地区销量-全部
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 09:25:03
     * @LastEditTime: Do not edit
     */
    getPassengerMarketPerformanceRegionOnly(type) {
      this.$nextTick(() => {
        if (type == null || type == "passengerCarMarketPerformanceLeft") {
          // 初始化乘用车分地区销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceLeftEchart.loadingOpen();
        }
        if (type == null || type == "passengerCarMarketPerformanceCenter") {
          // 乘用车分省份销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceCenter.loadingOpen();
        }
        if (type == null || type == "passengerCarMarketPerformanceRight") {
          // 乘用车分城市销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceRight.loadingOpen();
        }
      });
      getPassengerMarketPerformanceRegionOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          if (type == null || type == "passengerCarMarketPerformanceLeft") {
            // 初始化乘用车分地区销量数据封装渲染
            this.initPassengerCarMarketPerformanceLeftEchartData(data);
          }
          if (type == null || type == "passengerCarMarketPerformanceCenter") {
            // 乘用车分省份销量数据封装渲染
            this.initPassengerCarMarketPerformanceCenterData(data);
          }
          if (type == null || type == "passengerCarMarketPerformanceRight") {
            // 乘用车分城市销量数据封装渲染
            this.initPassengerCarMarketPerformanceRightData(data);
          }
          // console.log(res, "乘用车市场表现-乘用车分地区销量-全部-已对接");
        }
      });
    },
    /**
     * @description:乘用车分地区销量数据封装渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:47:36
     * @LastEditTime: Do not edit
     */
    initPassengerCarMarketPerformanceLeftEchartData(data) {
      // 乘用车分地区销量开始
      const passengerCarMarketPerformanceLeftEchartData = [];
      data.passengerMarketPerformanceAreaKeyList.forEach((item, index) => {
        passengerCarMarketPerformanceLeftEchartData.push({
          name: item,
          value: data.passengerMarketPerformanceAreaValueList[index],
        });
      });
      let date = Utils.insertStr(
        data.passengerMarketPerformanceAreaDate,
        4,
        "年"
      );
      this.date.passengerCarMarketPerformanceLeft = Utils.insertStr(
        date,
        8,
        "月"
      ); // 调用工具类插入年、月;

      // 调用子组件渲染
      this.$refs.passengerCarMarketPerformanceLeftEchart.init(
        passengerCarMarketPerformanceLeftEchartData
      );
    },
    /**
     * @description: 乘用车分省份销量数据封装渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:48:46
     * @LastEditTime: Do not edit
     */
    initPassengerCarMarketPerformanceCenterData(data) {
      // 乘用车分省份销量开始
      const passengerCarMarketPerformanceCenterData = [];
      data.passengerMarketPerformanceProvinceKeyList.forEach((item, index) => {
        const valueList = data.passengerMarketPerformanceProvinceValueList;
        const yoyList = data.passengerMarketPerformanceProvinceYoyList;
        const qoqList = data.passengerMarketPerformanceProvinceQoqList;
        const rote = (valueList[index] / valueList[0]) * 100;
        passengerCarMarketPerformanceCenterData.push({
          name: item + "省",
          ranking: index,
          rote: rote,
          value: valueList[index],
          YoY: yoyList[index],
          QoQ: qoqList[index],
        });
      });
      let date = Utils.insertStr(
        data.passengerMarketPerformanceProvinceDate,
        4,
        "年"
      );
      this.date.passengerCarMarketPerformanceCenter = Utils.insertStr(
        date,
        8,
        "月"
      ); // 调用工具类插入年、月;

      // 调用子组件渲染
      this.$refs.passengerCarMarketPerformanceCenter.init(
        passengerCarMarketPerformanceCenterData
      );
    },
    /**
     * @description:乘用车分城市销量数据封装渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:49:22
     * @LastEditTime: Do not edit
     */
    initPassengerCarMarketPerformanceRightData(data) {
      // 乘用车分城市销量
      const passengerCarMarketPerformanceRightData = [];
      data.passengerMarketPerformanceCityKeyList.forEach((item, index) => {
        const valueList = data.passengerMarketPerformanceCityValueList;
        const yoyList = data.passengerMarketPerformanceCityYoyList;
        const qoqList = data.passengerMarketPerformanceCityQoqList;
        const rote = (valueList[index] / valueList[0]) * 100;
        passengerCarMarketPerformanceRightData.push({
          name: item,
          ranking: index,
          rote: rote,
          value: valueList[index],
          YoY: yoyList[index],
          QoQ: qoqList[index],
        });
      });
      let date = Utils.insertStr(
        data.passengerMarketPerformanceCityDate,
        4,
        "年"
      );
      this.date.passengerCarMarketPerformanceRight = Utils.insertStr(
        date,
        8,
        "月"
      ); // 调用工具类插入年、月;

      // 调用子组件渲染
      this.$refs.passengerCarMarketPerformanceRight.init(
        passengerCarMarketPerformanceRightData
      );
    },
    /**
     * @description: //乘用车市场表现-乘用车分地区销量-新能源
     * @return {*}
     * @author: 林子健
     * @TODO:接口未返回数据
     * @Date: 2023-08-17 09:25:03
     * @LastEditTime: Do not edit
     */
    getPassengerMarketPerformanceRegionNewEnergy(type) {
      this.$nextTick(() => {
        if (type == null || type == "passengerCarMarketPerformanceLeft") {
          // 初始化乘用车分地区销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceLeftEchart.loadingOpen();
        }
        if (type == null || type == "passengerCarMarketPerformanceCenter") {
          // 乘用车分省份销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceCenter.loadingOpen();
        }
        if (type == null || type == "passengerCarMarketPerformanceRight") {
          // 乘用车分城市销量数据封装渲染
          this.$refs.passengerCarMarketPerformanceRight.loadingOpen();
        }
      });
      getPassengerMarketPerformanceRegionNewEnergy().then((res) => {
        if (res.ok) {
          const data = res.data;
          if (type == null || type == "passengerCarMarketPerformanceLeft") {
            // 初始化乘用车分地区销量数据封装渲染
            this.initPassengerCarMarketPerformanceLeftEchartData(data);
          }
          if (type == null || type == "passengerCarMarketPerformanceCenter") {
            // 乘用车分省份销量数据封装渲染
            this.initPassengerCarMarketPerformanceCenterData(data);
          }
          if (type == null || type == "passengerCarMarketPerformanceRight") {
            // 乘用车分城市销量数据封装渲染
            this.initPassengerCarMarketPerformanceRightData(data);
          }
          // console.log(res, "乘用车市场表现-乘用车分地区销量-新能源-已对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-乘用车分地区销量-企业榜单
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 09:57:12
     * @LastEditTime: Do not edit
     */
    getPassengerMarketPerformanceEnterpriseOnly() {
      this.$nextTick(() => {
        this.$refs.passengerCarMarketPerformanceLeftbottom.loadingOpen();
      });
      getPassengerMarketPerformanceEnterpriseOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          this.initPassengerCarMarketPerformanceLeftbottomData(data);
          // console.log(res, "乘用车市场表现-乘用车分地区销量-企业榜单-已对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-乘用车分地区销量-企业榜单数据封装渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:53:22
     * @LastEditTime: Do not edit
     */
    initPassengerCarMarketPerformanceLeftbottomData(data) {
      // 乘用车市场表现-乘用车分地区销量-企业榜单
      const passengerCarMarketPerformanceLeftbottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.passengerMarketPerformanceEnterpriseTableDataList.forEach(
        (item, index) => {
          if (!index) {
            firstRoteNum = item.value;
          }
          rote = (item.value / firstRoteNum) * 100;
          passengerCarMarketPerformanceLeftbottomData.push({
            name: item.key,
            ranking: index,
            value: item.value,
            rote: rote,
            YoY: item.yoy,
            QoQ: item.qoq,
          });
        }
      );
      let date = Utils.insertStr(
        data.passengerMarketPerformanceEnterpriseDate,
        4,
        "年"
      );
      this.date.passengerCarMarketPerformanceLeftbottom = Utils.insertStr(
        date,
        8,
        "月"
      ); // 调用工具类插入年、月;

      // 调用子组件渲染
      this.$refs.passengerCarMarketPerformanceLeftbottom.init(
        passengerCarMarketPerformanceLeftbottomData
      );
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:07:05
     * @LastEditTime: Do not edit
     */

    getPassengerMarketPerformanceVehicleModelOnly() {
      let data = "";
      if (this.passengerCarMarketPerformanceRightbottombtn) {
        data = `;${this.passengerCarMarketPerformanceRightbottombtn};${this.passengerCarMarketPerformanceRightbottomSelect}`;
      } else {
        data = `;${this.passengerCarMarketPerformanceRightbottomSelect}`;
      }
      this.$nextTick(() => {
        this.$refs.passengerCarMarketPerformanceRightbottom.loadingOpen();
      });
      getPassengerMarketPerformanceVehicleModelOnly(data).then((res) => {
        if (res.ok) {
          const data = res.data;
          // 乘用车市场表现-获取车型榜单数据封装渲染
          this.initPassengerCarMarketPerformanceRightbottomData(data);
          // console.log(res, "乘用车市场表现-获取车型榜单-已对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单数据封装渲染
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 13:52:13
     * @LastEditTime: Do not edit
     */
    initPassengerCarMarketPerformanceRightbottomData(data) {
      // 乘用车市场表现-获取车型榜单
      const passengerCarMarketPerformanceRightbottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.passengerMarketPerformanceVehicleModelTableDataList.forEach(
        (item, index) => {
          if (!index) {
            firstRoteNum = item.value;
          }
          rote = (item.value / firstRoteNum) * 100;
          passengerCarMarketPerformanceRightbottomData.push({
            name: item.key,
            ranking: index,
            value: item.value,
            rote: rote,
            YoY: item.yoy,
            QoQ: item.qoq,
          });
        }
      );
      let date = Utils.insertStr(
        data.passengerMarketPerformanceVehicleModelDate,
        4,
        "年"
      );
      this.date.passengerCarMarketPerformanceRightbottom = Utils.insertStr(
        date,
        8,
        "月"
      ); // 调用工具类插入年、月;

      // 调用子组件渲染
      this.$refs.passengerCarMarketPerformanceRightbottom.init(
        passengerCarMarketPerformanceRightbottomData
      );
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单-新能源
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:10:32
     * @LastEditTime: Do not edit
     */

    getPassengerMarketPerformanceVehicleModelNewEnergy() {
      getPassengerMarketPerformanceVehicleModelNewEnergy().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-获取车型榜单-新能源-未对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单-纯电动
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:12:10
     * @LastEditTime: Do not edit
     */

    getpassengerMarketPerformanceVehicleModelBev() {
      getpassengerMarketPerformanceVehicleModelBev().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-获取车型榜单-纯电动-未对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单-插电混
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:13:29
     * @LastEditTime: Do not edit
     */
    getpassengerMarketPerformanceVehicleModelPhev() {
      getpassengerMarketPerformanceVehicleModelPhev().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-获取车型榜单-插电混-未对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单-甲醇
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:14:14
     * @LastEditTime: Do not edit
     */

    getpassengerMarketPerformanceVehicleModelMethanol() {
      getpassengerMarketPerformanceVehicleModelMethanol().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-获取车型榜单-甲醇-未对接");
        }
      });
    },
    /**
     * @description: 乘用车市场表现-获取车型榜单-燃料电池
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 10:15:29
     * @LastEditTime: Do not edit
     */

    getpassengerMarketPerformanceVehicleModelFuelCell() {
      getpassengerMarketPerformanceVehicleModelFuelCell().then((res) => {
        if (res.ok) {
          // console.log(res, "乘用车市场表现-获取车型榜单-燃料电池-未对接");
        }
      });
    },
    /**
     * @description: 新能源专区-全部数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyAll() {
      getNewEnergyAll().then((res) => {
        if (res.ok) {
          const data = res.data;
          // console.log(data, "新能源专区-全部数据-未对接");
        }
      });
    },
    /**
     * @description: 新能源专区-公共类充电桩保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyChargingStationOnly() {
      this.$nextTick(() => {
        this.$refs.newEnergyChargingStationOnlyEchart.loadingOpen();
      });
      getNewEnergyChargingStationOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装折线图数据
          const xAxisData = data.newEnergyChargingStationKeyList.map((item) => {
            return Utils.insertStr(item, 4, ".");
          });
          const newEnergyChargingStationOnlyEchartData = {
            xAxisData: xAxisData.reverse(), // x轴数据
            seriesData: [
              {
                name: "保有量",
                type: "line",
                color: "#165DFF",
                showSymbol: false, // 去掉点
                smooth: true, // 变得圆滑一点
                areaStyleColor: [
                  "rgba(22, 93, 255,0.2)",
                  "rgba(22, 93, 255,0)",
                ], // 阴影
                data: data.newEnergyChargingStationValueList.reverse(),
              },
            ], // 展示数据
          };
          this.$refs.newEnergyChargingStationOnlyEchart.init(
            newEnergyChargingStationOnlyEchartData
          );
          // console.log(data, "新能源专区-公共类充电桩保有量-已对接");
        }
      });
    },

    /**
     * @description: 新能源专区-动力电池装机量走势图
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyBatteryOnly() {
      this.$nextTick(() => {
        this.$refs.newEnergyBatteryEchart.loadingOpen();
      });
      getNewEnergyBatteryOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装折线图数据
          const xAxisData = data.newEnergyBatteryKeyList.map((item) => {
            return Utils.insertStr(item, 4, ".");
          });
          const newEnergyBatteryEchartData = {
            xAxisData: xAxisData.reverse(), // x轴数据
            seriesData: [
              {
                name: "装机量",
                type: "line",
                color: "#14C9C9",
                showSymbol: false, // 去掉点
                smooth: true, // 变得圆滑一点
                areaStyleColor: [
                  "rgba(20, 201, 201,0.2)",
                  "rgba(20, 201, 201,0)",
                ], // 阴影
                data: data.newEnergyBatteryValueList.reverse(),
              },
            ], // 展示数据
          };
          this.$refs.newEnergyBatteryEchart.init(newEnergyBatteryEchartData);
          // console.log(data, "新能源专区-动力电池装机量走势图-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-省份充电桩保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyProvinceChargingChargingPoint() {
      this.$nextTick(() => {
        this.$refs.newEnergyLeftBottom.loadingOpen();
      });
      getNewEnergyProvinceChargingChargingPoint().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区-省份换电站数据封装渲染
          this.initNewEnergyLeftBottomData(data);
          // console.log(data, "新能源专区-省份充电桩保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-省份充电站保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyProvinceChargingChargingStation() {
      this.$nextTick(() => {
        this.$refs.newEnergyLeftBottom.loadingOpen();
      });
      getNewEnergyProvinceChargingChargingStation().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区-省份换电站数据封装渲染
          this.initNewEnergyLeftBottomData(data);
          // console.log(data, "新能源专区-省份充电站保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-省份换电站保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyProvinceChargingPowerExchangeStation() {
      this.$nextTick(() => {
        this.$refs.newEnergyLeftBottom.loadingOpen();
      });
      getNewEnergyProvinceChargingPowerExchangeStation().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区-省份换电站数据封装渲染
          this.initNewEnergyLeftBottomData(data);
          // console.log(data, "新能源专区-省份换电站保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-省份换电站数据封装渲染
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 17:06:30
     * @LastEditTime: Do not edit
     */
    initNewEnergyLeftBottomData(data) {
      const newEnergyLeftBottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.newEnergyProvinceChargingTableDataList.forEach((item, index) => {
        if (!index) {
          firstRoteNum = item.value;
        }
        rote = (item.value / firstRoteNum) * 100;
        newEnergyLeftBottomData.push({
          name: item.key,
          value: item.value,
          rote: rote,
          YoY: item.yoy,
          QoQ: item.qoq,
        });
      });
      let date = Utils.insertStr(data.newEnergyProvinceChargingDate, 4, "年");
      this.date.newEnergyLeftBottom = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月;
      this.$refs.newEnergyLeftBottom.init(newEnergyLeftBottomData);
    },
    /**
     * @description: 新能源专区-运营商充电桩保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyEnterpriseChargingChargingPoint() {
      this.$nextTick(() => {
        this.$refs.newEnergyCenterBottom.loadingOpen();
      });
      getNewEnergyEnterpriseChargingChargingPoint().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区 - 省运营商换电站数据封装渲染;
          this.initNewEnergyCenterBottomData(data);
          // console.log(data, "新能源专区-运营商充电桩保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-运营商充电站保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyEnterpriseChargingChargingStation() {
      this.$nextTick(() => {
        this.$refs.newEnergyCenterBottom.loadingOpen();
      });
      getNewEnergyEnterpriseChargingChargingStation().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区 - 省运营商换电站数据封装渲染;
          this.initNewEnergyCenterBottomData(data);
          // console.log(data, "新能源专区-运营商充电站保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-运营商换电站保有量
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getNewEnergyEnterpriseChargingPowerExchangeStation() {
      this.$nextTick(() => {
        this.$refs.newEnergyCenterBottom.loadingOpen();
      });
      getNewEnergyEnterpriseChargingPowerExchangeStation().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区 - 省运营商换电站数据封装渲染;
          this.initNewEnergyCenterBottomData(data);
          // console.log(data, "新能源专区-运营商换电站保有量-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-省运营商换电站数据封装渲染
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 17:06:30
     * @LastEditTime: Do not edit
     */
    initNewEnergyCenterBottomData(data) {
      const newEnergyCenterBottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.newEnergyEnterpriseChargingTableDataList.forEach((item, index) => {
        if (!index) {
          firstRoteNum = item.value;
        }
        rote = (item.value / firstRoteNum) * 100;
        newEnergyCenterBottomData.push({
          name: item.key,
          value: item.value,
          rote: rote,
          YoY: item.yoy,
          QoQ: item.qoq,
        });
      });
      let date = Utils.insertStr(data.newEnergyEnterpriseChargingDate, 4, "年");
      this.date.newEnergyCenterBottom = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月;
      this.$refs.newEnergyCenterBottom.init(newEnergyCenterBottomData);
    },
    /**
     * @description: 新能源专区-动力电池装机量TOP10企业
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 16:57:44
     * @LastEditTime: Do not edit
     */
    getnewEnergyEnterpriseBatteryOnly() {
      this.$nextTick(() => {
        this.$refs.newEnergyRightBottom.loadingOpen();
      });
      getnewEnergyEnterpriseBatteryOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 新能源专区-动力电池装机量TOP10企业数据封装渲染
          this.initNewEnergyRightBottomData(data);
          // console.log(data, "新能源专区-动力电池装机量TOP10企业-已对接");
        }
      });
    },
    /**
     * @description: 新能源专区-动力电池装机量TOP10企业数据封装渲染
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 17:19:01
     * @LastEditTime: Do not edit
     */
    initNewEnergyRightBottomData(data) {
      const newEnergyRightBottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.newEnergyEnterpriseBatteryTableDataList.forEach((item, index) => {
        if (!index) {
          firstRoteNum = item.value;
        }
        rote = (item.value / firstRoteNum) * 100;
        newEnergyRightBottomData.push({
          name: item.key,
          value: item.value,
          rote: rote,
          YoY: item.yoy,
          QoQ: item.qoq,
        });
      });
      let date = Utils.insertStr(data.newEnergyEnterpriseBatteryDate, 4, "年");
      this.date.newEnergyRightBottom = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月;
      this.$refs.newEnergyRightBottom.init(newEnergyRightBottomData);
    },
    /**
     * @description:获全球数据-全部数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 17:26:42
     * @LastEditTime: Do not edit
     */
    getGlobalDataAll() {
      getGlobalDataAll().then((res) => {
        // console.log(res, "获全球数据-全部数据-未对接");
      });
    },
    /**
     * @description: 全球数据-进、出口金额
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:57:39
     * @LastEditTime: Do not edit
     */
    getGlobalDataAmountOnly() {
      this.$nextTick(() => {
        this.$refs.globalDataAmountOnlyEchart.loadingOpen();
      });
      getGlobalDataAmountOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装右侧折线图数据
          const xAxisData = data.globalDataAmountKeyList;
          const globalDataAmountOnlyEchartData = {
            xAxisData: xAxisData.reverse(), // x轴数据
            seriesData: [
              {
                name: "进口金额",
                type: "line",
                // stack: "Total",
                color: "#165DFF",
                showSymbol: false, // 去掉点
                smooth: true, // 变得圆滑一点
                data: data.globalDataAmountImportList.reverse(),
              },
              {
                name: "出口金额",
                type: "line",
                // stack: "Total",
                color: "#14C9C9",
                showSymbol: false, // 去掉点
                smooth: true, // 变得圆滑一点
                data: data.globalDataAmountExportList.reverse(),
              },
            ], // 展示数据
          };
          this.$refs.globalDataAmountOnlyEchart.init(
            globalDataAmountOnlyEchartData
          );
          // console.log(data, "全球数据-进、出口金额-已对接");
        }
      });
    },
    /**
     * @description: 全球数据-进、出口量
     * @return {*}
     * @author: 林子健
     * @TODO:进口量数据没有给返回
     * @Date: 2023-08-16 17:29:08
     * @LastEditTime: Do not edit
     */
    getGlobalDataCarExportOnly() {
      this.$nextTick(() => {
        this.$refs.globalDataCarExportOnlyEchart.loadingOpen();
      });
      getGlobalDataCarExportOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装右侧折线图数据
          const xAxisData = data.globalDataCarExportKeyList.map((item) => {
            return Utils.insertStr(item, 4, ".");
          });
          const globalDataCarExportOnlyEchartData = {
            xAxisData: xAxisData.reverse(), // x轴数据
            seriesData: [
              // {
              //   name: "进口金额",
              //   type: "line",
              //   // stack: "Total",
              //   color: "#165DFF",
              //   showSymbol: false, //去掉点
              //   smooth: true, //变得圆滑一点
              //   data: data.globalDataAmountImportList.reverse(),
              // },
              {
                name: "出口量",
                type: "line",
                // stack: "Total",
                color: "#14C9C9",
                showSymbol: false, // 去掉点
                smooth: true, // 变得圆滑一点
                data: data.globalDataCarExportValueList.reverse(),
              },
            ], // 展示数据
          };
          this.$refs.globalDataCarExportOnlyEchart.init(
            globalDataCarExportOnlyEchartData
          );
          // console.log(data, "全球数据-进、出口量-已对接-缺少进口量数据源");
        }
      });
    },
    /**
     * @description: 全球数据-获取出口量TOP10数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 14:21:33
     * @LastEditTime: Do not edit
     */
    getGlobalDataExportOnly() {
      getGlobalDataExportOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // console.log(data, "全球数据-获取出口量TOP10数据-未对接");
        }
      });
    },
    /**
     * @description: 全球数据-获取主流国家销量走势
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 14:21:33
     * @LastEditTime: Do not edit
     */
    getGlobalDataCountryOnly() {
      this.$nextTick(() => {
        this.$refs.globalDataLeftBottomEchart.loadingOpen();
      });
      getGlobalDataCountryOnly().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装全球数据-获取主流国家销量走势图数据
          const yAxisData = data.globalDataCountryKeyList.reverse();
          // UI 让使用下面这套颜色，这套颜色和饼图的颜色是一样的
          const colorList = [
            "rgba(22, 93, 255, 0.8)",
            "rgba(20, 201, 201, 0.8)",
            "rgba(247, 186, 30, 0.8)",
            "rgba(246, 86, 86, 0.8)",
            "rgba(114, 46, 209, 0.8)",
            "rgba(77, 195, 103, 0.8)",
            "rgba(255, 105, 19, 0.8)",
          ];
          const seriesData = [];
          data.globalDataCountryValueList.reverse().forEach((item, index) => {
            seriesData.push({
              value: item,
              itemStyle: {
                color:
                  colorList[
                    (data.globalDataCountryValueList.length - index - 1) %
                      colorList.length
                  ],
                barBorderRadius: [0, 5, 5, 0],
              },
            });
          });
          const globalDataLeftBottomEchartData = {
            yAxisData: yAxisData, // y轴数据
            seriesData: seriesData, // 展示数据
          };
          let date = Utils.insertStr(data.globalDataCountryDate, 4, "年");
          this.date.globalDataLeftBottomEchart = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月;
          this.$refs.globalDataLeftBottomEchart.init(
            globalDataLeftBottomEchartData
          );
          // console.log(data, "全球数据-获取主流国家销量走势-已对接");
        }
      });
    },
    /**
     * @description: 全球数据-获取出口量TOP10数据下，筛选 企业
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 14:21:33
     * @LastEditTime: Do not edit
     */
    getGlobalDataExportEnterprise() {
      this.$nextTick(() => {
        this.$refs.globalDataLeftBottom.loadingOpen();
      });
      getGlobalDataExportEnterprise().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装渲染全球数据-出口量TOP10数据
          this.initGlobalDataLeftBottomData(data);
          // console.log(data, "全球数据-获取出口量TOP10数据下，筛选 企业-已对接");
        }
      });
    },
    /**
     * @description: 封装渲染全球数据-出口量TOP10数据
     * @param {*} data
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 15:38:04
     * @LastEditTime: Do not edit
     */
    initGlobalDataLeftBottomData(data) {
      const globalDataLeftBottomData = [];
      let firstRoteNum = 1;
      let rote;
      data.globalDataExportTableDataList.forEach((item, index) => {
        if (!index) {
          firstRoteNum = item.value;
        }
        rote = (item.value / firstRoteNum) * 100;
        globalDataLeftBottomData.push({
          name: item.key,
          value: item.value,
          rote: rote,
          YoY: item.yoy,
          QoQ: item.qoq,
        });
      });
      let date = Utils.insertStr(data.globalDataExportDate, 4, "年");
      this.date.globalDataLeftBottom = Utils.insertStr(date, 8, "月"); // 调用工具类插入年、月;
      // 调用子组件传参渲染
      this.$refs.globalDataLeftBottom.init(globalDataLeftBottomData);
    },
    /**
     * @description: 全球数据-获取出口量TOP10数据下，筛选 车型
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-17 14:21:33
     * @LastEditTime: Do not edit
     */
    getGlobalDataExportVehicleModel() {
      this.$nextTick(() => {
        this.$refs.globalDataLeftBottom.loadingOpen();
      });
      getGlobalDataExportVehicleModel().then((res) => {
        if (res.ok) {
          const data = res.data;
          // 封装渲染全球数据-出口量TOP10数据
          this.initGlobalDataLeftBottomData(data);
          // console.log(data, "全球数据-获取出口量TOP10数据下，筛选 车型-已对接");
        }
      });
    },
    /**
     * @description: 卡片按钮点击监听
     * @param {*} data
     * @param {*} type
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 14:55:38
     * @LastEditTime: Do not edit
     */
    cardBtnchange({ data, type }) {
      switch (type) {
        case "overallProductionSales":
          // 封装右侧折线图数据渲染
          if (data.title === "销量") {
            this.getOverallProductionSalesData("Sales");
          } else if (data.title === "产量") {
            this.getOverallProductionSalesData("Production");
          }
          break;
        case "newEnergyLeftBottom": // 省份充电桩保有量
          if (data.title == "充电桩") {
            this.getNewEnergyProvinceChargingChargingPoint();
            this.newEnergyLeftBottomUnit = "台";
          } else if (data.title == "充电站") {
            this.newEnergyLeftBottomUnit = "座";
            this.getNewEnergyProvinceChargingChargingStation();
          } else if (data.title == "换电站") {
            this.newEnergyLeftBottomUnit = "座";
            this.getNewEnergyProvinceChargingPowerExchangeStation();
          }
          this.newEnergyLeftBottomTitle = data.title;
          break;
        case "newEnergyCenterBottom": // 运营商充电桩保有量
          if (data.title == "充电桩") {
            this.newEnergyCenterBottomUnit = "台";
            this.getNewEnergyEnterpriseChargingChargingPoint();
          } else if (data.title == "充电站") {
            this.newEnergyCenterBottomUnit = "座";
            this.getNewEnergyEnterpriseChargingChargingStation();
          } else if (data.title == "换电站") {
            this.newEnergyCenterBottomUnit = "座";
            this.getNewEnergyEnterpriseChargingPowerExchangeStation();
          }
          this.newEnergyCenterBottomTitle = data.title;
          break;
        case "globalDataLeftBottom": // 全球数据-出口量TOP10企业
          if (data.title == "车型") {
            this.getGlobalDataExportVehicleModel();
          } else if (data.title == "企业") {
            this.getGlobalDataExportEnterprise();
          }
          this.globalDataBtnChickTitle = data.title;
          break;
        case "passengerCarMarketPerformanceLeft": // 乘用车-分地区销量
        case "passengerCarMarketPerformanceCenter": // 乘用车-分省份销量
        case "passengerCarMarketPerformanceRight": // 乘用车-分城市销量
          if (data.title == "全部") {
            this.getPassengerMarketPerformanceRegionOnly(type);
          } else if (data.title === "新能源") {
            this.getPassengerMarketPerformanceRegionNewEnergy(type);
          }
          break;
      }
      // console.log(data, type);
    },
    /**
     * @description: 按钮点击事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 10:07:17
     * @LastEditTime: Do not edit
     */
    btnClick(data) {
      this.passengerCarMarketPerformanceRightbottombtn = data.value;
      this.btnList = this.btnList.map((item) => {
        return {
          ...item,
          ...{ isClick: data.id === item.id ? 1 : 0 },
        };
      });
      this.getPassengerMarketPerformanceVehicleModelOnly();
    },
    /**
     * @description: 下拉框切换事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-31 10:46:06
     * @LastEditTime: Do not edit
     */
    selectChange() {
      this.getPassengerMarketPerformanceVehicleModelOnly();
    },
    exportSvg({ type, name }) {
      this.$refs[type].exportSvg(name);
      // console.log(type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";
.btn-box {
  /deep/.el-button {
    margin-bottom: 13px;
    padding: 5px 14px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
    border: 1px solid #e5e6eb;
    color: #000000;
  }
  .isClick {
    color: #ffffff;
    background-color: #0d57bc;
  }
}
.right-grid-reduce-gap {
  /deep/ .el-card {
    padding-right: 5px;
  }
}
</style>

<style lang="scss" scoped>
// 指标看板
.indicator-board {
  width: 33.33333%;
}
// 产量/销量变化趋势
.changing-trends {
  width: 66.66666%;
}

@media screen and (max-width: 1350px) {
  .indicator-board {
    width: 50%;
  }
  .changing-trends {
    width: 50%;
  }
}

// 乘用车市场表现
.passenger-car-market-performance-body {
  /deep/ & > .el-card {
    padding-bottom: 8px;
  }
}

// 乘用车市场表现-乘用车分地区销量
.passenger-car-market-performance-left {
  width: 33.33333%;
}
// 乘用车市场表现-乘用车分省份销量
.passenger-car-market-performance-center {
  width: 33.33333%;
}
// 乘用车市场表现-乘用车分城市销量
.passenger-car-market-performance-right {
  width: 33.33333%;
}

// 乘用车市场表现-企业销量榜单
.passenger-car-market-performance-left-bottom,
// 乘用车市场表现-车型销量榜单
.passenger-car-market-performance-right-bottom {
  width: 50%;
}

@media screen and (max-width: 1900px) {
  .passenger-car-market-performance-center,
  .passenger-car-market-performance-right {
    /deep/ {
      .progress {
        width: 50% !important;
      }
      .proportion {
        width: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .passenger-car-market-performance-left {
    width: 100%;
  }
  .passenger-car-market-performance-center,
  .passenger-car-market-performance-right {
    width: 50%;

    /deep/ {
      .progress {
        width: 60% !important;
      }
      .proportion {
        width: 40% !important;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .passenger-car-market-performance-left {
    width: 100%;
  }
  .passenger-car-market-performance-center,
  .passenger-car-market-performance-right {
    /deep/ {
      .progress {
        width: 50% !important;
      }
      .proportion {
        width: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .passenger-car-market-performance-left {
    width: 100%;
  }
  .passenger-car-market-performance-center,
  .passenger-car-market-performance-right {
    width: 100%;
    /deep/ {
      .progress {
        width: 75% !important;
      }
      .proportion {
        width: 25% !important;
      }
    }
  }

  .passenger-car-market-performance-left-bottom,
  .passenger-car-market-performance-right-bottom {
    width: 100%;
  }

  // 公共类充电桩保有量
  .new-energy-charging-station,
  // 动力电池装机量走势图
  .new-energy-battery {
    width: 100%;
  }
}

// 新能源专区-公共类充电桩保有量
.new-energy-charging-station,
// 新能源专区-动力电池装机量走势图
.new-energy-battery {
  width: 50%;
}

.new-energy-left-bottom,
.new-energy-center-bottom,
.new-energy-right-bottom {
  width: 33.33333%;
}

@media screen and (max-width: 1450px) {
  // 新能源专区
  .new-energy-charging-station,
  .new-energy-battery,
  .new-energy-left-bottom,
  .new-energy-center-bottom,
  .new-energy-right-bottom {
    width: 100%;
  }

  .new-energy-left-bottom,
  .new-energy-center-bottom {
    margin-bottom: 16px;
  }

  // 全球数据
  .global-data-car-export,
  .global-data-amount,
  .global-data-left-bottom,
  .global-data-right-bottom {
    width: 100% !important;
  }

  .global-data-left-bottom {
    margin-bottom: 16px;
  }
}

// 全球数据-进、出口量
.global-data-car-export {
  width: 50%;
}
// 全球数据-进、出口金额
.global-data-amount {
  width: 50%;
}

// 全球数据-出口量TOP10企业
.global-data-left-bottom,
// 全球数据-主流国家销量
.global-data-right-bottom {
  width: 50%;
}
</style>
