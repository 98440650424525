<template>
  <span :class="['cell', { up: isUp, down: isDown }]">
    <span class="text">{{ text }}</span>
    <img v-if="isUp" class="icon" src="@/assets/img/overview/up.png" />
    <img v-if="isDown" class="icon" src="@/assets/img/overview/down.png" />
    <span class="number">{{ number }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: Number,
      default: 0,
    },
    text: {
      required: false,
      type: String,
      default: undefined,
    },
  },
  computed: {
    isUp() {
      return this.value > 0;
    },
    isDown() {
      return this.value < 0;
    },
    number() {
      return this.value < 0 ? -this.value : this.value;
    },
  },
};
</script>
