<template>
  <div
    class="enterpriseDetail"
    id="MainModels"
    v-if="isShowMainModels"
    data-name="主要产品"
  >
    <TableCard
      compared="compared"
      :isLoading="isLoading"
      cardTitle="主要产品"
      :sourceData="dataSource"
      :notTooltipList="dataSource.tableHead.slice(1).map(item => item.prop)"
      :foldList="dataSource.tableHead.slice(1).map(item => item.prop)"
      :hiddenNum="80"
    >
    <template #headExport>
      <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import { getPartsMakerProduct,postComparisonExportPartsMakerProduct } from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import TableCard from "@/components/tableCard.vue";

export default {
  name: "MainModels",
  components: {
    TableCard
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isShowMainModels: false,
      isLoading: true,
      debouncedGetPartsMakerProduct: null,
      lastRouteChange: "",
      params: {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      },
      dataSource: {}
    };
  },
  created() {
    this.debouncedGetPartsMakerProduct = this.debounce(
      this.handleRouteChange,
      100
    );
    this.debouncedGetPartsMakerProduct();
  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业对标分析", "3", "", "", "", "", "主要产品", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postComparisonExportPartsMakerProduct(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "主要产品.xlsx");
              });
            }
          }
        } else {
          postComparisonExportPartsMakerProduct(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "主要产品.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    handleRouteChange() {
      if (
        this.lastRouteChange === "checkedList" ||
        this.lastRouteChange === ""
      ) {
        this.getPartsMakerProduct(this.params, "isShowMainModels");
      }
      this.lastRouteChange = "";
    },
    getPartsMakerProduct(params, isShowMainModels) {
      this.isLoading = true;
      getPartsMakerProduct(params).then(res => {
        if (res.ok) {
          this.isShowMainModels =
            res.data.tableData.length &&
            (this.$route.query.checkedList.includes("主要产品") ||
              this.$route.query.checkedList.includes("全部"));
          this.dataSource = res.data;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"(newId) {
      this.params.enterpriseIdList = JSON.parse(newId);
      this.lastRouteChange = "selectedEnterprise";
      this.debouncedGetPartsMakerProduct();
    },
    "$route.query.checkedList"() {
      this.lastRouteChange = "checkedList";
      this.debouncedGetPartsMakerProduct();
    }
  }
};
</script>
