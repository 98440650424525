<!--
 * @Author: your name
 * @Date: 2021-07-20 16:40:01
 * @LastEditTime: 2021-07-20 17:26:16
 * @LastEditors: Please set LastEditors
 * @Description: 展开收起插件
 * @FilePath: /information-vue/code/src/components/UnfurlAndFold.vue
-->
<template>
    <div>
        <div class="title">
            <div class="inner" @click="isShow">
                {{ title }}
                <i :class="is ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <div class="inner-r" v-if="more">
                <router-link :to="toHref">{{toName}} <i class="el-icon-arrow-right"></i></router-link>
            </div>
        </div>
        <div v-show="is" class="content-b">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String },
        more: {
            type: Boolean,
            default: false,
        },
        toName: { type: String },
        toHref: { type: String, default: "" },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            is: this.visible,
        };
    },
    methods: {
        /**
         * @description: 是否收起展开
         * @param {*}
         * @return {*}
         */
        isShow() {
            this.is = !this.is;
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    background: #F4F5F6;
    .inner {
        font-weight: 600;
        // margin: 10px 0;
        color: #0d57bc;
        cursor: pointer;
        font-size: 16px;
        line-height: 16px;
    }
    .inner-r {
        a {
            color: #828282;
            padding-right: 5px;
        }
    }
}
.content-b {
    margin-top: 10px;
}
</style>
