<template>
  <div class="news-box">
    <div class="header">
      <div class="tabs">
        <div
          :class="['tab', { active: activeName === 'policy' }]"
          @click="handleClick('policy')"
        >
          <span class="bg"></span>
          <img class="icon" src="@/assets/images/icon-01.png" />
          <span class="text">政策动态</span>
        </div>
        <div
          :class="['tab', { active: activeName === 'standard' }]"
          @click="handleClick('standard')"
        >
          <span class="bg"></span>
          <img class="icon" src="@/assets/images/icon-02.png" />
          <span class="text">标准动态</span>
        </div>
      </div>

      <More
        :to="moreLink"
        :name="activeName === 'policy' ? '政策动态' : '标准动态'"
        :logRecordsHomeMore="logRecordsHomeMore"
      />
    </div>

    <div class="body">
      <template v-if="activeName === 'policy'">
        <div class="desc">最新政策原文、政策解读、政策大数据、政策梳理，支持查询和导出</div>
        <div class="items">
          <router-link
            class="item"
            to="/policy/dynamic/index"
            target="_blank"
          >
            <span class="box">
              <span class="lable">原文</span>
              <span class="value">{{ policyCount.policyCount || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
          <router-link
            class="item"
            to="/policy/dynamic/index"
            target="_blank"
          >
            <span class="box">
              <span class="lable">报道</span>
              <span class="value">{{ policyCount.policyReport || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
          <router-link
            class="item"
            to="/policy/unscramble/index"
            target="_blank"
          >
            <span class="box">
              <span class="lable">解读</span>
              <span class="value">{{ policyCount.policyInterpret || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
        </div>
        <div class="list">
          <router-link
            v-for="item in newPolicyData"
            :key="item.id"
            :to="'/index/detail/newPolicyDetail/' + item.id"
            target="_blank"
            @click.native="logRecords('首页','政策动态','1','tt_first_policy',item.id,'',item.title,'')"
          >
          <Tooltip v-bind:title="htmlRestore(item.title)" :fontSize="14"/>
            <span class="time">{{ item.updateTime }}</span>
          </router-link>
        </div>
      </template>

      <template v-else>
        <div class="desc">最新标准原文、征求意见稿、标准动态及解读，支持查询和导出</div>
        <div class="items">
          <router-link
            class="item"
            to="/standards/dynamic/index"
            target="_blank"
          >
            <span class="box">
              <span class="lable">原文</span>
              <span class="value">{{ standardCount.standard || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
          <router-link
            class="item"
            to="/standards/dynamic/index"
            target="_blank"
          >
            <span class="box">
              <span class="lable">征求意见稿</span>
              <span class="value">{{ standardCount.comment || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
          <router-link
            class="item"
            to="/standards/dynamic/index2"
            target="_blank"
          >
            <span class="box">
              <span class="lable">动态</span>
              <span class="value">{{ standardCount.dynamic || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
          <router-link
            class="item"
            to="/standards/dynamic/index2"
            target="_blank"
          >
            <span class="box">
              <span class="lable">解读</span>
              <span class="value">{{ standardCount.unscramble || 0 }}<span class="unit">条</span></span>
            </span>
          </router-link>
        </div>
        <div class="list">
          <router-link
            v-for="item in newStandardData"
            :key="item.id"
            :to="'/index/detail/newStandardDetail/' + item.id"
            target="_blank"
            @click.native="logRecords('首页','标准动态','1','tt_first_standard',item.id,'',item.title,'')"
          >
            <Tooltip v-bind:title="htmlRestore(item.title)" :fontSize="14"/>
            <span class="time">{{ item.updateTime }}</span>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import More from './More';
import Tooltip from '@/components//toolTip.vue';

export default {
  props: {
    newPolicyData: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    newStandardData: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    policyCount: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    standardCount: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    logRecords: {
      required: false,
      type: Function,
      default: () => () => null,
    },
    logRecordsHomeMore: {
      required: false,
      type: Function,
      default: () => () => null,
    },
  },
  components: {
    More,
    Tooltip
  },
  data() {
    return {
      activeName: 'policy',
    };
  },
  computed: {
    moreLink() {
      return this.activeName === 'policy'
        ? '/policy/dynamic/index'
        : '/standards/dynamic/index';
    },
  },
  methods: {
    handleClick(activeName) {
      this.activeName = activeName;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-box {
  margin-bottom: 10px;
  .header {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;

    .more {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .tabs {
    display: flex;
    .tab {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 12px;
      }

      @media screen and (min-width: 1360px) {
        padding-left: 14px;
        padding-right: 14px;
        &:not(:first-child) {
          margin-left: 14px;
        }
      }
      @media screen and (min-width: 1380px) {
        padding-left: 16px;
        padding-right: 16px;
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
      @media screen and (min-width: 1400px) {
        padding-left: 18px;
        padding-right: 18px;
        &:not(:first-child) {
          margin-left: 18px;
        }
      }
      @media screen and (min-width: 1420px) {
        padding-left: 20px;
        padding-right: 20px;
        &:not(:first-child) {
          margin-left: 20px;
        }
      }
      @media screen and (min-width: 1460px) {
        padding-left: 22px;
        padding-right: 22px;
        &:not(:first-child) {
          margin-left: 22px;
        }
      }
      @media screen and (min-width: 1480px) {
        &:not(:first-child) {
          margin-left: 28px;
        }
      }

      $border-radius: 20px;
      .bg {
        position: absolute;
        top: 0;
        left: -16%;
        right: -16%;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        transform-origin: 50% 100%;
        transform: perspective(50px) scaleX(1) scaleY(1.686) rotateX(30deg);
        &:before,
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: $border-radius;
          height: $border-radius;
          background: inherit;
        }
        &:before {
          right: -$border-radius;
          mask: radial-gradient(circle at 100% 0, transparent, transparent $border-radius, #000 $border-radius, #000);
        }
        &:after {
          left: -$border-radius;
          mask: radial-gradient(circle at 0 0, transparent, transparent $border-radius, #000 $border-radius, #000);
        }
      }

      .icon {
        position: relative;
        margin-right: 10px;
      }
      .text {
        position: relative;
        color: #222;
        font-size: 20px;
        font-weight: bold;
      }

      &.active {
        cursor: default;
        .bg {
          background-color: #f1f5ff;
        }
      }
    }
  }
  .more {
    margin-bottom: 14px;
  }

  .body {
    padding: 10px 16px;
    background-image: linear-gradient(180deg, #f1f5ff, transparent);

    .desc {
      margin-bottom: 10px;
      color: #999;
      font-size: 12px;
    }

    .items {
      display: flex;
      padding: 14px 0;
      background-color: #fff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
      .item {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        &:not(:first-child):before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: #ebebeb;
        }

        .box {
          display: flex;
          flex-direction: column;
        }

        .lable,
        .unit {
          color: #333;
          font-size: 12px;
          font-weight: normal;
          line-height: 1;
        }
        .value {
          margin-top: 4px;
          color: #0069ea;
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
        }
      }
    }
    .list {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;
        // white-space: nowrap;
        color: #333;
        .time {
          margin-left: 20px;
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
