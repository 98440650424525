<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-12 10:55:30
-->
<template>
  <el-card
    class="box-card"
    :style="
      `padding-bottom: 24px;border:${noBorder ? 'none' : '1px solid #f0f0f0'}`
    "
  >
    <div slot="header" class="header">
      <span class="title"><span v-if="cionShow" class="icon" />{{ title }}</span>
      <div class="right-btn-box">
        <div v-if="btnflag" class="btn-item" @click="downloadAllData">
          <img :src="require('@/assets/img/newData/download.png')">
          导出
        </div>
        <!-- <div v-if="btnflag" class="btn-item" @click="downloadData">
          <img :src="require('@/assets/img/newData/download.png')">
          导出
        </div> -->
      </div>
    </div>
    <div v-if="btnList.length > 0" class="btn-box">
      <el-button
        v-for="(btnItem, index) in btnList"
        :key="index"
        size="mini"
        type="primary"
        :class="`${btnItem.isClick ? 'isClick' : ''}`"
        @click="btnClick(btnItem)"
      >
        {{ btnItem.title }}
      </el-button>
    </div>
    <slot name="body" />
  </el-card>
</template>
<script>
export default {
  name: 'Card',
  props: {
    // 卡片名称
    title: {
      type: String,
      default: '请传入参数title'
    },
    // 卡片边框样式
    noBorder: {
      type: Boolean,
      default: false
    },
    // 按钮开关
    btnflag: {
      type: Boolean,
      default: false
    },
    // 按钮id
    btnId: {
      type: String,
      default: '0'
    },
    // 图标显示
    cionShow: {
      type: Boolean,
      default: false
    },
    // 按钮list
    titlebtn: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      btnList: []
    }
  },
  created() {
    this.btnList = JSON.parse(JSON.stringify(this.titlebtn))
  },
  methods: {
    /**
     * @description: 下载数据事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-29 13:55:21
     * @LastEditTime: Do not edit
     */
    downloadData() {
      this.$emit('downloadData', {})
    },
    /**
     * @description: 下载全部数据事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-29 13:55:21
     * @LastEditTime: Do not edit
     */
    downloadAllData() {
      this.$emit('downloadAllData', {})
    },
    /**
     * @description: 按钮点击事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 10:07:17
     * @LastEditTime: Do not edit
     */
    btnClick(data) {
      this.btnList = this.btnList.map((item) => {
        return {
          ...item,
          ...{ isClick: data.id === item.id ? 1 : 0 }
        }
      })
      this.$emit('cardBtnchange', { type: this.btnId, data: data })
    },
    /**
     * @description: 下载图片按钮
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:25:40
     * @LastEditTime: Do not edit
     */

    exportSvg() {
      this.$emit('exportSvg', { type: this.btnId, name: this.title })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 4px 4px;
  .icon {
    display: inline-block;
    height: 9px;
    width: 9px;
    background-color: #0d57bc;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .title-info {
    margin-left: 12px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #828282;
  }
  /deep/.el-card__header {
    padding: 22px 24px 20px;
    border: none;
  }
  /deep/.el-card__body {
    padding: 0 24px;
    border: none;
  }
  /deep/.header {
    font-family: PingFang SC-Medium, PingFang SC;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  /deep/.right-btn-box {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #4f4f4f;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .btn-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:last-child {
        margin-left: 24px;
      }
    }
  }
  .btn-box {
    margin-bottom: 8px;
    /deep/.el-button {
      padding: 4px 12px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      background-color: #fff;
      border: 1px solid #e5e6eb;
      color: #000000;
    }
    .isClick {
      color: #ffffff;
      background-color: #0d57bc;
    }
  }
}
@media screen and (max-width: 1366px) {
  .box-card {
    /deep/.el-card__header {
      padding: 20px 9px !important;
      border: none;
    }
  }
}
</style>
