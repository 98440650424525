<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-14 15:55:16
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-17 09:37:49
-->
<template>
  <PageContainer>
    <card class="el-card" title="国内产销-协会产销数据" cion-show>
      <template slot="rightBtnBox">
        <div>
          <span class="seletc-time">选择时间:</span>
          <el-select
            v-model="hisForm.nian"
            placeholder="请选择"
            size="small"
            style="width:180px"
            @change="selectChang"
          >
            <el-option
              v-for="item in historyYear"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="year">年</span>
          <el-select
            v-model="hisForm.yue"
            placeholder="请选择"
            size="small"
            style="margin-left:5px;width:180px"
            @change="selectChangY"
          >
            <el-option
              v-for="item in historyYue"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="month">月</span>
        </div>
        <div class="more-data">
          2005年之后的数据可以在上方选择<i
            class="el-icon-top"
            style="color: #0d57bc;font-weight: 700; font-size:16px; margin-left:4px"
          />
          如需更早数据,请<span
            style="text-decoration: underline;cursor:pointer"
            @click="historyOpen"
          >点击此处</span>
        </div>
        <!-- <el-button plain size="mini" class="more-data" @click="historyOpen"
          >更多历史数据（2005以前）<i class="el-icon-arrow-right"
        /></el-button> -->
      </template>
      <template slot="body">
        <div class="data-sheet-box">
          <h1 class="data-sheet-item-title" style="margin-top:0">汇总表</h1>
          <div class="list-table">
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qccxhzb/','','',1)"
                >
                  <span class="icon" />
                  <span
                    class="text"
                  >{{ newTime[0] }}年{{
                    newTime[1]
                  }}月份汽车(分车型)产销汇总表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qcsczyqycxqkb/','',time.cx==time.qy,1)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车生产主要企业产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/xnyqccxqkb/','','',1)"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份新能源汽车产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qcfqyckqkb/','',time.cx==time.ck,1)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.ck ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车分企业出口情况表</span>
                </div>
              </el-col>
            </el-row>
          </div>
          <h1 class="data-sheet-item-title">乘用车</h1>
          <div class="list-table">
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/cychzb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{ newTime[1] }}月份乘用车产销汇总表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/xnysyccyc/', '1')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份新能源乘用车产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/cycscqycxqkb/','',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份乘用车生产企业产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/cycfcxcxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份乘用车(分车型)产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/wdqcqycyc/','',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年01-{{
                      newTime[1]
                    }}月份五大汽车企业集团乘用车产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '0',time.cx==time.pp)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.pp ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份全国乘用车主要品牌产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '5',time.cx==time.pp)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.pp ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份基本型乘用车主要品牌产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '3',time.cx==time.pp)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.pp ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份MPV主要品牌产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '4',time.cx==time.pp)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.pp ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份SUV主要品牌产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '6',time.cx==time.pp)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.pp ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份交叉型乘用车主要品牌产销情况表</span>
                </div>
              </el-col>
            </el-row>
          </div>
          <h1 class="data-sheet-item-title">商用车</h1>
          <div class="list-table">
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/sychzb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{ newTime[1] }}月份商用车产销汇总表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/xnysyccyc/', '2')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份新能源商用车产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/sycscqycxqkb/','',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份商用车生产企业产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/wdqcqysyc/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年01-{{
                      newTime[1]
                    }}月份五大汽车企业集团商用车产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/scqycxqkb/', '7',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份载货车生产企业产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/getZhcfcxCxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份载货车(分车型)产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/frlclfcxcxb/', '7')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份载货车(分燃料)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/zhcfdwcxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份载货车(分吨位)产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/zhcfytcxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份载货车(分用途)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/scqycxqkb/', '12',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份客车生产企业产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/frlclfcxcxb/', '12')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份客车(分燃料)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/getKcfcxCxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份客车(分车型)产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/frlclfcxcxb/', '17')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份货车非完整车辆(分车型)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/getHcfwzclscqycxqkb/','',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份货车非完整车辆生产企业产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/frlclfcxcxb/', '18')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份客车非完整车辆(分车型)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/getBgqycscqycxqkb/','',time.cx==time.qy)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.qy ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份半挂牵引车生产企业产销情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/bgqycfdwcxqkb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份半挂牵引车(分吨位)产销情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/bgqycfcxcxhzb/')"
                >
                  <span class="icon" />
                  <span class="text">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份半挂牵引车(分车型)产销汇总表</span>
                </div>
              </el-col>
            </el-row>
          </div>
          <h1 class="data-sheet-item-title">发动机</h1>
          <div class="list-table">
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/fdjscqy/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份发动机生产企业产销情况表 (台)</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/qcfdjarlflscqyqkb/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车发动机(按燃料分类)生产企业产销情况表 (台)</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/fdsp/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车发动机生产企业商品量情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/cqsp/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车发动机(按燃料分类)生产企业商品量情况表</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="data-sheet-item-info">
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/fdzp/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车发动机生产企业自配量情况表</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="data-sheet-item-down"
                  @click="toHref('/data/dataTable/cqzp/','',time.cx==time.fdj)"
                >
                  <span class="icon" />
                  <span class="text" :style="time.cx==time.fdj ? '':'color:rgb(233 205 205)' ">
                    {{ newTime[0] }}年{{
                      newTime[1]
                    }}月份汽车发动机(按燃料分类)生产企业自配量情况表</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <div class="data-sheet-box">
          <div
            class="data-sheet-item"
            v-for="(item, index) in data"
            :key="index"
          >
            <div class="data-sheet-item-title">{{ item.title }}</div>
            <div class="data-sheet-item-info">
              <el-row :gutter="24">
                <el-col :span="12" v-for="(ele, i) in item.fileList" :key="i">
                  <div class="data-sheet-item-down">
                    <span class="icon"></span>
                    <span class="text">{{ ele.name }}</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div> -->
        <div class="botton-massage">
          更多数据表下载敬请期待 (可先在数据查询模块选择数据主题导出数据)
        </div>
      </template>
    </card>
  </PageContainer>
</template>

<script>
import card from './common/card.vue' // 卡片组件
import { getYearListTables, getYueListDynamics } from '@/api/data.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'
export default {
  name: 'DataTableDownload',
  components: {
    card // 卡片组件
  },
  data() {
    return {
      historyYear: [],
      historyYue: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ],
      hisForm: {
        nian: '',
        yue: '02'
      },
      historyList: [],
      hv: '',
      newTime: ['2021', '02'],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '数据',
        userId: ''
      },
      time: {}
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },

    selectChang(v) {
      this.time = {
        cx: 1,
        fdj: 1,
        ck: 1,
        qy: 1,
        pp: 1
      }
      const userInfo = getUserInfo()
      if (!userInfo) {
        this.hisForm.nian = this.historyYear[0]
        this.$Vlogin2({ flag: true })
      } else if (userInfo.menuName && !userInfo.menuName.includes('数据')) {
        this.hisForm.nian = this.historyYear[0]
        this.$Vlogin3({ flag: true })
      } else if (userInfo.roleKey == 'trialrole') {
        this.hisForm.nian = this.historyYear[0]
        openSy()
        this.insertBuryPoint()
      } else {
        this.getYueListDynamics(v)
        this.$set(this.newTime, [0], v)
      }
    },
    selectChangY(v) {
      this.time = {
        cx: 1,
        fdj: 1,
        ck: 1,
        qy: 1,
        pp: 1
      }
      const userInfo = getUserInfo()
      if (!userInfo) {
        this.hisForm.yue = this.historyYue[this.historyYue.length - 1]
        this.$Vlogin2({ flag: true })
      } else if (userInfo.menuName && !userInfo.menuName.includes('数据')) {
        this.hisForm.nian = this.historyYear[0]
        this.$Vlogin3({ flag: true })
      } else if (userInfo.roleKey == 'trialrole') {
        this.hisForm.yue = this.historyYue[this.historyYue.length - 1]
        openSy()
        this.insertBuryPoint()
      } else {
        this.$set(this.newTime, [1], v)
      }
    },
    init() {
      getYearListTables().then((res) => {
        if (res.ok) {
          this.historyYear = res.data
          this.hisForm.nian = res.data[0]
          this.$set(this.newTime, [0], this.hisForm.nian)
          this.getYueListDynamics(res.data[0])
        }
      })
      // lastTime().then((res) => {
      //     if (res.ok) {
      //         this.newTime = res.data.split("-");
      //         this.hisForm.yue = this.newTime[1];
      //     }
      // });
    },
    getYueListDynamics(nian) {
      getYueListDynamics(nian).then((res) => {
        this.time = res.data.time

        this.historyYue = res.data.list
        this.hisForm.yue = res.data.list[res.data.list.length - 1]

        this.$set(this.newTime, [1], this.hisForm.yue)

        if (this.newTime[0] != res.data.time.cx.slice(0, 4) || this.newTime[1] != res.data.time.cx.slice(5, 7)) {
          this.time = {
            cx: 1,
            fdj: 1,
            ck: 1,
            qy: 1,
            pp: 1
          }
        }
      })
    },
    hisSearch() {
      this.$set(this.newTime, [0], this.hisForm.nian)
      this.$set(this.newTime, [1], this.hisForm.yue)
    },

    historyOpen() {
      if (getUserInfo()) {
        logRecords(
          '数据',
          '数据概览',
          '3',
          'gg_yzk',
          '',
          '',
          '',
          '',
          '历史数据'
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              const newUrl = this.$router.resolve({
                path: '/history'
              })
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          const newUrl = this.$router.resolve({
            path: '/history'
          })
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    toHref(url, type, flag, auth) {
      let str = ''
      str = type
        ? (str = url + this.newTime.join('-') + '-01/' + type)
        : url + this.newTime.join('-') + '-01'
      const newUrl = this.$router.resolve({
        path: str
      })

      if (flag === false) {
        this.$message({
          message: '数据正在加急更新中，部分数据表还未上线，请稍加等待',
          offset: 500,
          type: 'error'
        })
        return
      }
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'trialrole' && auth != 1) {
          openSy()
          return
        }
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  /deep/ .el-card__header {
    padding-bottom: 5px !important;
  }
}
.seletc-time {
  margin-right: 16px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
}
.year,
.month {
  margin-left: 8px;
  margin-right: 16px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.more-data {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
}
.data-sheet-box {
  width: 100%;
  .data-sheet-item-title {
    margin-top: 16px;
    height: 56px;
    padding-left: 20px;
    background: #f6f6f6;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #0d57bc;
    line-height: 56px;
  }
  .el-col {
    padding: 0 !important;
  }
  .data-sheet-item-info {
    .data-sheet-item-down {
      height: 56px;
      font-size: 14px;
      margin-left: 20px;
      margin-right: 20px;
      cursor: pointer;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      .icon {
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-left: 20px;
        margin-right: 16px;
        background: url(~@/assets/img/overview/data-sheet-item-down-icon.png)
          no-repeat;
        background-size: 100% 100%;
      }
      .text {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.n-title {
  height: 56px;
  background: #f6f6f6;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 56px;
}
.botton-massage {
  width: 100%;
  margin-top: 16px;
  height: 56px;
  background: rgba(13, 87, 188, 0.12);
  border-radius: 3px 3px 3px 3px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0d57bc;
  line-height: 56px;
  text-align: center;
}
</style>
