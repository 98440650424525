<template>
  <PageContainer>
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs actionNavInformation="Information" />
      </div>
    </template>
    <EnterpriseSearch />
    <template slot="right">
      <ComparativeAnalysis style="margin-bottom: 10px;" />
      <enterpriseDynamic />
      <enterpriseReport />
    </template>
  </PageContainer>
</template>
<script>
import ComparativeAnalysis from "./comparativeAnalysis.vue";
import EnterpriseSearch from "./enterpriseSearch.vue";
import enterpriseDynamic from "./enterpriseDynamic.vue";
import enterpriseReport from "./enterpriseReport.vue";
import actionTabs from "../../components/actionTabs.vue"

export default {
  name: "InformationVueEnterpriseInfo",
  components: {
    ComparativeAnalysis,
    EnterpriseSearch,
    enterpriseReport,
    enterpriseDynamic,
    actionTabs
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
