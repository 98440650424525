<!--会议信息-->
<template>
  <PageContainer breadcrumb>
    <div class="box_box">
      <!--                    <div class="newTabs">-->
      <!--                        <el-tabs v-model="identification" @tab-click="handleClick">-->
      <!--                            <el-tab-pane label="全部会议" name="all"></el-tab-pane>-->
      <!--                            <el-tab-pane :label="item" :name="item" v-for="item in identificationData" :key="item"></el-tab-pane>-->
      <!--                        </el-tabs>-->
      <!--                    </div>-->
      <div style="overflow: hidden; padding:11px 16px 11px">
        <div style="float: left" flex="c">
          <el-checkbox-group v-model="keywords" size="small" @change="handleChange">
            <el-checkbox :label="item.dictValue" border v-for="item in keywordsData" :key="item.dictValue">
              {{ item.dictLabel }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="tabs-bot">
        <ul class="tabs-new">
          <li class="tab-new-item" :class="{ act: dateCategory == '' }" @click="tabsNewFun()">
            全部时间
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDate.dictValue }"
              @click="tabsNewFun(nowDate.dictValue)">
            {{ nowDate.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateOne.dictValue }"
              @click="tabsNewFun(nowDateOne.dictValue)">
            {{ nowDateOne.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateTwo.dictValue }"
              @click="tabsNewFun(nowDateTwo.dictValue)">
            {{ nowDateTwo.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateThree.dictValue }"
              @click="tabsNewFun(nowDateThree.dictValue)">
            {{ nowDateThree.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateFour.dictValue }"
              @click="tabsNewFun(nowDateFour.dictValue)">
            {{ nowDateFour.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateFive.dictValue }"
              @click="tabsNewFun(nowDateFive.dictValue)">
            {{ nowDateFive.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateSix.dictValue }"
              @click="tabsNewFun(nowDateSix.dictValue)">
            {{ nowDateSix.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateSeven.dictValue }"
              @click="tabsNewFun(nowDateSeven.dictValue)">
            {{ nowDateSeven.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateEight.dictValue }"
              @click="tabsNewFun(nowDateEight.dictValue)">
            {{ nowDateEight.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateNine.dictValue }"
              @click="tabsNewFun(nowDateNine.dictValue)">
            {{ nowDateNine.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateTen.dictValue }"
              @click="tabsNewFun(nowDateTen.dictValue)">
            {{ nowDateTen.dictLabel }}
          </li>
          <li class="tab-new-item" :class="{ act: dateCategory == nowDateEleven.dictValue }"
              @click="tabsNewFun(nowDateEleven.dictValue)">
            {{ nowDateEleven.dictLabel }}
          </li>
        </ul>
      </div>
    </div>
    <div class="box_box_title" v-for="item in tableData" :key="item.id">
      <div style="display: flex">
        <div class="flex_col" flex="1">
          <div style="cursor: pointer" @click="toHref(item)">
            <div flex="c" style="margin: 10px 0">
              <p class="">{{ htmlRestore(item.conferenceTitle) }}</p>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ htmlRestore(item.sessionDescription) }}
            </div>
          </div>
          <div flex="sb" style="font-size: 14px">
            <a href="javascript:;" style="color: #0D57BC;margin-top: 10px;">{{
                item.conferenceOrganizers
              }}</a>
            <span>{{ item.dateConference }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                     :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                     :total="pageData.total">
      </el-pagination>
    </div>
  </PageContainer>
</template>
<script>
import {selectDictDataByType} from "@/api/dict.js";
import {getMeetingMain, ttMeetingInfoPage} from "@/api/Index.js";
import {getYearMonth} from "@/utils/date.js";
import VlistSum from "@/components/listSum";
import {getUserInfo} from "@/utils/auth";

export default {
  components: {
    VlistSum,
  },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      dateCategory: "",
      articleCategory: "all",
      articleData: [],
      identificationData: ["中汽中心", "非中汽中心"],
      identification: "all",
      keywordsData: [],
      keywords: [],
      tableData: [],
      title: "",
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      query: {},
      props: {
        title: "conferenceTitle",
        date: "dateConference",
        summary: "sessionDescription",
      },
    };
  },
  computed: {
    nowDate() {
      return getYearMonth(new Date());
    },
    nowDateOne() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 1));
    },
    nowDateTwo() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 2));
    },
    nowDateThree() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 3));
    },
    nowDateFour() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 4));
    },
    nowDateFive() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 5));
    },
    nowDateSix() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 6));
    },
    nowDateSeven() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 7));
    },
    nowDateEight() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 8));
    },
    nowDateNine() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 9));
    },
    nowDateTen() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 10));
    },
    nowDateEleven() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 11));
    },
    nowDateTwenty() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 12));
    },
  },
  created() {
    this.query = this.$route.query;
    if (this.$route.query.category) {
      this.identification = this.$route.query.category;
    }
    if (this.$route.query.releaseColumn) {
      this.keywords.push(this.$route.query.releaseColumn);
    }
    this.selectDictDataByType();
    // this.getMeetingMain();
    this.ttMeetingInfoPage();
  },
  methods: {
    toHref(item) {
      let newUrl = this.$router.resolve("/index/detail/meetingDetail/" + item.id);
      window.open(newUrl.href, "_blank");


    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "meeting_information"}).then(
          (res) => {
            if (res.ok) {
              this.keywordsData = res.data;
            }
          }
      );
    },
    //获取主办方
    getMeetingMain() {
      getMeetingMain().then((res) => {
        if (res.ok) {
          this.articleData = res.data;
        }
      });
    },
    // 会议信息
    ttMeetingInfoPage() {
      ttMeetingInfoPage({
        pageSize: this.pageData.pages.pageSize,
        page: this.pageData.pages.page,
        meetField: this.keywords.join(","),
        dateConference: this.dateCategory,
        identification:
            this.identification == "all" ? "" : this.identification,
      }).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.pageData.total = res.data.count;
        }
      });
    },
    //切换
    handleClick(e) {
      this.ttMeetingInfoPage();
    },
    handleSizeChange(v) {
      this.pageData.pages.pageSize = v;
      this.ttMeetingInfoPage();
    },
    handleCurrentChange(v) {
      this.pageData.pages.page = v;
      this.ttMeetingInfoPage();
    },
    //类别切换
    handleChange() {
      this.ttMeetingInfoPage();
    },
    //时间切换
    tabsNewFun(val) {
      let nowVal = val ? val : "";
      this.dateCategory = nowVal;
      this.ttMeetingInfoPage();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.newTabs {
  /deep/ .el-tabs__nav-wrap {
    padding: 0 16px;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #e4e7ed;
  }

  /deep/ .el-tabs__item {
    font-size: 16px;
    padding: 16px 16px;
    height: auto;
    line-height: initial;
  }
}

.tabs-new {
  display: flex;

  .tab-new-item {
    position: relative;
    padding: 18px 16px 18px;
    cursor: pointer;
    color: #333;

    &.act {
      font-weight: bold;

      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #0D57BC;
      }
    }
  }
}
</style>
