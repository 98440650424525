<template>
  <PageContainer class="container">
    <div id="chart" ref="chart" class="chart" />
    <div class="search-box">
      <div class="tip-box">
        <el-tooltip class="item" placement="right">
          <div slot="content" style="width: 100px;">
            请点击目标国查看数据或者搜索目标国查询数据!
          </div>
          <i class="el-icon-warning-outline" />
        </el-tooltip>
      </div>
      <!-- filterable remote reserve-keyword -->
      <el-select
        v-model="input2"
        clearable
        filterable
        placeholder="请输入要搜索的国家名称"
        :remote-method="remoteMethod"
        :loading="loading"
        style="width:100%"
        @change="inputchange"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div v-if="input2" v-show="dataShow" class="data-info">
        <div class="name">
          {{ input2 }}
          <i
            class="close el-icon-close"
            @click="
              () => {
                this.input2 = '';
                this.inputchange();
              }
            "
          />
        </div>
        <div class="data">
          <el-tabs v-model="activeName" stretch lazy @tab-click="handleClick">

            <el-tab-pane
              label="销量"
              name="second"
            ><div v-if="activeName == 'second'" class="echart-box">
               <salesVolumeEchart
                 v-if="empty.globalDataAmountOnlyEchart2"
                 ref="globalDataAmountOnlyEchart2"
                 :height="260"
               />
               <el-empty v-else description="暂无数据" />
             </div>
              <div class="explain">
                <!-- {{
                  input2 == "中国"
                    ? "数据来源：中国海关总署"
                    : "数据来源：各国海关"
                }} -->
              </div>
              <div class="moreLink">
                <span
                  @click="getShowflag()"
                ><i class="el-icon-arrow-up" /> 收起数据</span><span
                  @click="
                    linkTo(
                      '/international/saleDataByCountry/globalAutoIndustrySales'
                    )
                  "
                ><i class="el-icon-more" /> 查看更多</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="产量" name="first">
              <div v-if="activeName == 'first'" class="echart-box">
                <salesVolumeEchart
                  v-if="empty.globalDataAmountOnlyEchart1"
                  ref="globalDataAmountOnlyEchart1"
                  :height="260"
                />
                <el-empty v-else description="暂无数据" />
              </div>
              <div class="explain">
                <!-- {{
                  input2 == "中国"
                    ? "数据来源：中国海关总署"
                    : "数据来源：各国海关"
                }} -->
              </div>
              <div class="moreLink">
                <span
                  @click="getShowflag()"
                ><i class="el-icon-arrow-up" /> 收起数据</span><span
                  @click="
                    linkTo(
                      '/international/productionDataByCountry/globalAutoIndustryProduction'
                    )
                  "
                ><i class="el-icon-more" /> 查看更多</span>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="宏观经济(GDP)"
              name="third"
            ><div v-if="activeName == 'third'" class="echart-box">
               <salesVolumeEchart
                 v-if="empty.globalDataAmountOnlyEchart3"
                 ref="globalDataAmountOnlyEchart3"
                 :height="260"
               />
               <el-empty v-else description="暂无数据" />
             </div>
              <div class="explain">
                <!-- {{
                  input2 == "中国"
                    ? "数据来源：中国海关总署"
                    : "数据来源：各国海关"
                }} -->
              </div>
              <div class="moreLink">
                <span
                  @click="getShowflag()"
                ><i class="el-icon-arrow-up" /> 收起数据</span><span
                  @click="
                    linkTo(
                      '/international/countrySpecificEconomicData/globalEconomyData'
                    )
                  "
                ><i class="el-icon-more" /> 查看更多</span>
              </div>
            </el-tab-pane>
            <el-tab-pane
              :label="`${input2 == '中国' ? '中国出口' : '从中国进口量'}`"
              name="fourth"
            ><div v-if="activeName == 'fourth'" class="echart-box">
               <salesVolumeEchart
                 v-if="empty.globalDataAmountOnlyEchart4"
                 ref="globalDataAmountOnlyEchart4"
                 :height="260"
               />
               <el-empty v-else description="暂无数据" />
             </div>
              <div class="explain">
                {{
                  input2 == "中国"
                    ? "数据来源：中国海关总署"
                    : "数据来源：各国海关"
                }}
              </div>
              <div class="moreLink">
                <span
                  @click="getShowflag()"
                ><i class="el-icon-arrow-up" /> 收起数据</span><span
                  @click="
                    linkTo(
                      '/international/chinaAutomotiveImportAndExportData/chinaExportData'
                    )
                  "
                ><i class="el-icon-more" /> 查看更多</span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div v-show="!dataShow" class="data-info">
        <div class="open-data">
          <span
            @click="getShowflag()"
          ><i class="el-icon-arrow-down" /> 展开数据</span>
        </div>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import * as echarts from 'echarts'
import '../common/world.js' // 引入世界地图
import salesVolumeEchart from '../common/salesVolumeEchart.vue' // 折线图
import {
  internationalGetDate, // 全球数据-进、出口金额
  getSaleData, // 获取全球国家热力图-销量
  getProdData, // 获取全球国家热力图-产量
  getMacroscopicData, // 获取全球国家热力图-宏观经济
  getExportData // 获取全球国家热力图-中国出口
} from '@/api/data_new.js'
import { getUserInfo, logRecords } from '@/utils/auth.js'
export default {
  components: {
    salesVolumeEchart
  },
  data() {
    return {
      input2: '中国',
      activeName: 'second',
      dataShow: true,
      dataObj: {},
      dataObj1: {},
      dataObj2: {},
      dataObj3: {},
      nameMap: {
        Afghanistan: '阿富汗',
        Singapore: '新加坡',
        Angola: '安哥拉',
        Albania: '阿尔巴尼亚',
        'United Arab Emirates': '阿拉伯酋长国',
        Argentina: '阿根廷',
        Armenia: '亚美尼亚',
        'French Southern and Antarctic Lands': '法属南半球和南极领地',
        Australia: '澳大利亚',
        Austria: '奥地利',
        Azerbaijan: '阿塞拜疆',
        Burundi: '布隆迪',
        Belgium: '比利时',
        Benin: '贝宁',
        'Burkina Faso': '布基纳法索',
        Bangladesh: '孟加拉国',
        Bulgaria: '保加利亚',
        'The Bahamas': '巴哈马',
        'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
        Belarus: '白俄罗斯',
        Belize: '伯利兹',
        Bermuda: '百慕大',
        Bolivia: '玻利维亚',
        Brazil: '巴西',
        Brunei: '文莱',
        Bhutan: '不丹',
        Botswana: '博茨瓦纳',
        'Central African Republic': '中非共和国',
        Canada: '加拿大',
        Switzerland: '瑞士',
        Chile: '智利',
        China: '中国',
        'Ivory Coast': '象牙海岸',
        Cameroon: '喀麦隆',
        'Democratic Republic of the Congo': '刚果民主共和国',
        'Republic of the Congo': '刚果共和国',
        Colombia: '哥伦比亚',
        'Costa Rica': '哥斯达黎加',
        Cuba: '古巴',
        'Northern Cyprus': '北塞浦路斯',
        Cyprus: '塞浦路斯',
        'Czech Republic': '捷克共和国',
        Germany: '德国',
        Djibouti: '吉布提',
        Denmark: '丹麦',
        'Dominican Republic': '多明尼加共和国',
        Algeria: '阿尔及利亚',
        Ecuador: '厄瓜多尔',
        Egypt: '埃及',
        Eritrea: '厄立特里亚',
        Spain: '西班牙',
        Estonia: '爱沙尼亚',
        Ethiopia: '埃塞俄比亚',
        Finland: '芬兰',
        Fiji: '斐',
        'Falkland Islands': '福克兰群岛',
        France: '法国',
        Gabon: '加蓬',
        'United Kingdom': '英国',
        Georgia: '格鲁吉亚',
        Ghana: '加纳',
        Guinea: '几内亚',
        Gambia: '冈比亚',
        'Guinea Bissau': '几内亚比绍',
        Greece: '希腊',
        Greenland: '格陵兰',
        Guatemala: '危地马拉',
        'French Guiana': '法属圭亚那',
        Guyana: '圭亚那',
        Honduras: '洪都拉斯',
        Croatia: '克罗地亚',
        Haiti: '海地',
        Hungary: '匈牙利',
        Indonesia: '印度尼西亚',
        India: '印度',
        Ireland: '爱尔兰',
        Iran: '伊朗',
        Iraq: '伊拉克',
        Iceland: '冰岛',
        Israel: '以色列',
        Italy: '意大利',
        Jamaica: '牙买加',
        Jordan: '约旦',
        Japan: '日本',
        Kazakhstan: '哈萨克斯坦',
        Kenya: '肯尼亚',
        Kyrgyzstan: '吉尔吉斯斯坦',
        Cambodia: '柬埔寨',
        Kosovo: '科索沃',
        Kuwait: '科威特',
        Laos: '老挝',
        Lebanon: '黎巴嫩',
        Liberia: '利比里亚',
        Libya: '利比亚',
        'Sri Lanka': '斯里兰卡',
        Lesotho: '莱索托',
        Lithuania: '立陶宛',
        Luxembourg: '卢森堡',
        Latvia: '拉脱维亚',
        Morocco: '摩洛哥',
        Moldova: '摩尔多瓦',
        Madagascar: '马达加斯加',
        Mexico: '墨西哥',
        Macedonia: '马其顿',
        Mali: '马里',
        Myanmar: '缅甸',
        Montenegro: '黑山',
        Mongolia: '蒙古',
        Mozambique: '莫桑比克',
        Mauritania: '毛里塔尼亚',
        Malawi: '马拉维',
        Malaysia: '马来西亚',
        Namibia: '纳米比亚',
        'New Caledonia': '新喀里多尼亚',
        Niger: '尼日尔',
        Nigeria: '尼日利亚',
        Nicaragua: '尼加拉瓜',
        Netherlands: '荷兰',
        Norway: '挪威',
        Nepal: '尼泊尔',
        'New Zealand': '新西兰',
        Oman: '阿曼',
        Pakistan: '巴基斯坦',
        Panama: '巴拿马',
        Peru: '秘鲁',
        Philippines: '菲律宾',
        'Papua New Guinea': '巴布亚新几内亚',
        Poland: '波兰',
        'Puerto Rico': '波多黎各',
        'North Korea': '北朝鲜',
        Portugal: '葡萄牙',
        Paraguay: '巴拉圭',
        Qatar: '卡塔尔',
        Romania: '罗马尼亚',
        Russia: '俄罗斯',
        Rwanda: '卢旺达',
        // "Western Sahara": "西撒哈拉",
        'Saudi Arabia': '沙特阿拉伯',
        Sudan: '苏丹',
        'S. Sudan': '南苏丹',
        Senegal: '塞内加尔',
        'Solomon Islands': '所罗门群岛',
        'Sierra Leone': '塞拉利昂',
        'El Salvador': '萨尔瓦多',
        Somaliland: '索马里兰',
        Somalia: '索马里',
        'Republic of Serbia': '塞尔维亚',
        Suriname: '苏里南',
        Slovakia: '斯洛伐克',
        Slovenia: '斯洛文尼亚',
        Sweden: '瑞典',
        Swaziland: '斯威士兰',
        Syria: '叙利亚',
        Chad: '乍得',
        Togo: '多哥',
        Thailand: '泰国',
        Tajikistan: '塔吉克斯坦',
        Turkmenistan: '土库曼斯坦',
        'East Timor': '东帝汶',
        'Trinidad and Tobago': '特里尼达和多巴哥',
        Tunisia: '突尼斯',
        Turkey: '土耳其',
        'United Republic of Tanzania': '坦桑尼亚',
        Uganda: '乌干达',
        Ukraine: '乌克兰',
        Uruguay: '乌拉圭',
        'United States': '美国',
        Uzbekistan: '乌兹别克斯坦',
        Venezuela: '委内瑞拉',
        Vietnam: '越南',
        Vanuatu: '瓦努阿图',
        'West Bank': '西岸',
        Yemen: '也门',
        'South Africa': '南非',
        Zambia: '赞比亚',
        Korea: '韩国',
        Tanzania: '坦桑尼亚',
        Zimbabwe: '津巴布韦',
        Congo: '刚果',
        'Central African Rep.': '中非',
        Serbia: '塞尔维亚',
        'Bosnia and Herz.': '波黑',
        'Czech Rep.': '捷克',
        'W. Sahara': '西撒哈拉',
        'Lao PDR': '老挝',
        'Dem. Rep. Korea': '朝鲜',
        'Falkland Is.': '福克兰群岛',
        'Timor-Leste': '东帝汶',
        'Solomon Is.': '所罗门群岛',
        Palestine: '巴勒斯坦',
        'N. Cyprus': '北塞浦路斯',
        Aland: '奥兰群岛',
        'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
        Mauritius: '毛里求斯',
        Comoros: '科摩罗',
        'Eq. Guinea': '赤道几内亚',
        'Guinea-Bissau': '几内亚比绍',
        'Dominican Rep.': '多米尼加',
        'Saint Lucia': '圣卢西亚',
        Dominica: '多米尼克',
        'Antigua and Barb.': '安提瓜和巴布达',
        'U.S. Virgin Is.': '美国原始岛屿',
        Montserrat: '蒙塞拉特',
        Grenada: '格林纳达',
        Barbados: '巴巴多斯',
        Samoa: '萨摩亚',
        Bahamas: '巴哈马',
        'Cayman Is.': '开曼群岛',
        'Faeroe Is.': '法罗群岛',
        'IsIe of Man': '马恩岛',
        Malta: '马耳他共和国',
        Jersey: '泽西',
        'Cape Verde': '佛得角共和国',
        'Turks and Caicos Is.': '特克斯和凯科斯群岛',
        'St. Vin. and Gren.': '圣文森特和格林纳丁斯',
        'Dem. Rep. Congo': '刚果民主共和国'
      },
      list: [],
      options: [],
      value: [],
      loading: false,
      states: [],
      empty: {
        globalDataAmountOnlyEchart1: false,
        globalDataAmountOnlyEchart2: false,
        globalDataAmountOnlyEchart3: false,
        globalDataAmountOnlyEchart4: false
      }
    }
  },
  computed: {},
  mounted() {
    this.getnameMap()
    this.getSaleData()
    this.getProdData() //获取产量的地图数据
    this.getMacroscopicData() //获取宏观经济的地图数据
    this.getExportData() //获取中国产量的地图数据
    this.options = this.list
    // this.drawChart();
  },
  methods: {
    getShowflag() {
      this.dataShow = !this.dataShow
    },
    getnameMap() {
      Object.keys(this.nameMap).forEach((element) => {
        this.list.push({
          name: this.nameMap[element],
          value: this.nameMap[element]
        })
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.options = this.list.filter((item) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.options = this.list
      }
    },
    inputchange(type, isLoading) {
      if (!getUserInfo() && !isLoading) {
        this.input2 = '中国'
        this.$Vlogin2({ flag: true })
        return
      }
      this.dataShow = true
      if (this.input2) {
        this.drawChart(this.input2)
        if (this.activeName == 'first'){
          this.activeName = 'first'
          this.getGlobalDataAmountOnly(
            '/international/abroadProduction/getData/',
            this.input2,
            'globalDataAmountOnlyEchart1'
          )
        } 
        if (this.activeName == 'second'){
          this.activeName = 'second'
          this.getGlobalDataAmountOnly(
            '/international/abroadSale/getData/',
            this.input2,
            'globalDataAmountOnlyEchart2'
          )
        }
        if (this.activeName == 'third'){
          this.activeName = 'third'
          this.getGlobalDataAmountOnly(
            '/international/macroeconomics/getData/',
            this.input2,
            'globalDataAmountOnlyEchart3'
          )
        }
        if (this.activeName == 'fourth'){
          this.activeName = 'fourth'
          this.getGlobalDataAmountOnly(
            '/international/chinaExport/getData/',
            this.input2,
            'globalDataAmountOnlyEchart4'
          )
        }
        
      } else {
        this.input2 = null
        this.drawChart()
      }
    },
    handleClick(tab, event) {
      if (!getUserInfo()) {
        this.$Vlogin2({ flag: true })
        this.$nextTick(() => {
          this.activeName = 'second'
          this.getGlobalDataAmountOnly(
            '/international/abroadSale/getData/',
            this.input2,
            'globalDataAmountOnlyEchart2'
          )
        })
        return
      }
      this.drawChart(this.input2)
      switch (tab.name) {
        case 'first':
          // this.$refs.globalDataAmountOnlyEchart1.dispose();
          this.getGlobalDataAmountOnly(
            '/international/abroadProduction/getData/',
            this.input2,
            'globalDataAmountOnlyEchart1'
          )
          break
        case 'second':
          // this.$refs.globalDataAmountOnlyEchart2.dispose();
          this.getGlobalDataAmountOnly(
            '/international/abroadSale/getData/',
            this.input2,
            'globalDataAmountOnlyEchart2'
          )
          break
        case 'third':
          // this.$refs.globalDataAmountOnlyEchart3.dispose();
          this.getGlobalDataAmountOnly(
            '/international/macroeconomics/getData/',
            this.input2,
            'globalDataAmountOnlyEchart3'
          )
          break
        case 'fourth':
          this.getGlobalDataAmountOnly(
            '/international/chinaExport/getData/',
            this.input2,
            'globalDataAmountOnlyEchart4'
          )
          break
      }
    },
    getSaleData() {
      getSaleData().then((res) => {
        if (res.ok) {
          if (res.data) {
            res.data.forEach((e) => {
              this.dataObj[e.country] = e.total
            })
          }
          this.inputchange(null, 'Loading')
        }
      })
    },
    getProdData() {
      getProdData().then((res) => {
        if (res.ok) {
          if (res.data) {
            res.data.forEach((e) => {
              this.dataObj1[e.country] = e.total
            })
          }
          this.inputchange(null, 'Loading')
        }
      })
    },
    getMacroscopicData() {
      getMacroscopicData().then((res) => {
        if (res.ok) {
          if (res.data) {
            res.data.forEach((e) => {
              this.dataObj2[e.country] = e.total
            })
          }
          this.inputchange(null, 'Loading')
        }
      })
    },
    getExportData() {
      getExportData().then((res) => {
        if (res.ok) {
          if (res.data) {
            res.data.forEach((e) => {
              this.dataObj3[e.country] = e.total
            })
          }
          this.inputchange(null, 'Loading')
        }
      })
    },
    drawChart(name) {
      // 基于准备好的dom，初始化echarts实例
      let chart = echarts.init(this.$refs.chart)
      // 监听屏幕变化自动缩放图表
      window.addEventListener('resize', function() {
        chart.resize()
      })
      const data = []
      let obj = {}
      if (this.activeName == 'first'){
        obj = this.dataObj1
      }
      if (this.activeName == 'second'){
        obj = this.dataObj
      }
      if (this.activeName == 'third'){
        obj = this.dataObj2
      }
      if (this.activeName == 'fourth'){
        obj = this.dataObj3
      }
      Object.keys(this.nameMap).forEach((element) => {
        data.push({
          name: this.nameMap[element],
          value: obj[this.nameMap[element]],
          selected: false
        })
      })

      // data[0] = [{ name: "阿富汗", value: 1, selected: true }];
      const option = {
        backgroundColor: '#F6F6F6',
        // 图表主标题
        title: {
          text: '', // 主标题文本，支持使用 \n 换行
          // //top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          // left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: {
            // 文本样式
            fontSize: 18,
            fontWeight: 600,
            color: 'black'
          }
        },
        // visualMap: {
        //   min: 0,
        //   max: 2000000,
        //   text: ['High', 'Low'],
        //   realtime: false,
        //   calculable: true,
        //   inRange: {
        //     // color: ['lightskyblue', '#0025ae'],
        //     color: ['#b1d4e5', '#799eb2', '#00aee6', '#2754ba']
        //   }
        // },
        visualMap: [{
          type: 'piecewise',
          show: true,
          pieces: [
            { min: 0, max: 99999 },
            { min: 100000, max: 999999 },
            { min: 1000000, max: 1999999 },
            { min: 2000000 }
          ],
          textStyle: {
            color: '#828994'
          },
          itemWidth: 64, // 每个图元的宽度
          itemHeight: 12,
          // top: "top",
          // right: "0",
          inRange: {
            borderRadius: 4,
            color: [
              '#b1d4e5',
              '#799eb2',
              '#00aee6',
              '#2754ba',

            ]
          }
        }],
        series: [
          {
            name: 'World Population (2010)',
            type: 'map',
            mapType: 'world',
            zoom: 1.2,
            roam: 'scale', // 支持缩放
            scaleLimit: { min: 1, max: 6 },
            // roam: false,
            // 默认样式
            itemStyle: {
              areaColor: '#DDE2E8', // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
              borderWidth: 0.5, // 描边线宽 为 0 时无描边
              borderColor: '#FFF', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
              borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
            },
            label: {
              show: false
            },
            // 鼠标移入样式
            emphasis: {
              // 高亮的显示设置
              label: {
                show: true, // 是否显示标签
                padding: [6, 20],
                backgroundColor: 'rgba(255, 255, 255,.8)',
                fontSize: 12,
                color: '#333333',
                borderRadius: 5
              },
              itemStyle: {
                areaColor: '#CFDDF2', // 地图区域的颜色
                borderWidth: 1, // 描边线宽 为 0 时无描边
                borderColor: '#0D57BC', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
                borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
              }
            },
            // 选中样式
            select: {
              // 高亮的显示设置
              label: {
                show: true, // 是否显示标签
                padding: [6, 20],
                backgroundColor: 'rgba(255, 255, 255,.8)',
                fontSize: 12,
                color: '#333333',
                borderRadius: 5
              },
              itemStyle: {
                areaColor: '#fae059', // 地图区域的颜色
                borderWidth: 1, // 描边线宽 为 0 时无描边
                borderColor: '#fff', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
                borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
              }
            },
            data: data,
            nameMap: this.nameMap
          }
        ]
      }
      if (name) {
        option.series[0].data = data.map((e, i) => {
          if (e.name == name) {
            e.selected = true
          } else {
            e.selected = false
          }
          return e
        })
        chart.setOption(option, true)
      } else {
        chart.dispose()
        chart = echarts.init(this.$refs.chart)
        chart.setOption(option)
      }
      chart.off('click')
      chart.on('click', (param) => {
        if (!getUserInfo()) {
          option.series[0].data = data.map((e, i) => {
            if (e.name == '中国') {
              e.selected = true
            } else {
              e.selected = false
            }
            return e
          })
          chart.setOption(option, true)
          this.$Vlogin2({ flag: true })
          return
        }
        // 获取自定义变量barIds的值,barIds要和option的series里自定义的一样
        this.input2 = param.data.name
        this.inputchange()
      })
    },
    getGlobalDataAmountOnly(url, name, type) {
      if (!name) {
        return
      }
      internationalGetDate(url + name).then((res) => {
        if (res.ok) {
          const data = res.data
          if (!data) {
            if (this.$refs[type]) {
              this.$refs[type].dispose()
            }
            this.empty[type] = false
          }
          // 封装右侧折线图数据
          const xAxisData = data?.dateList
          const globalDataAmountOnlyEchartData = {
            xAxisData: xAxisData, // x轴数据
            seriesData: [] // 展示数据
          }
          let echartType1 = 'line'
          let echartType2 = 'line'
          let echartType3 = 'line'
          let barName = '产量'
          let yAxisColor = ['#999', '#999']
          switch (url) {
            case '/international/macroeconomics/getData/':
              echartType1 = 'line'
              echartType2 = 'line'
              echartType3 = 'bar'
              barName = '百万美元'
              globalDataAmountOnlyEchartData.leftName = '百万美元'
              yAxisColor = ['#FFF', '#999']
              break
            case '/international/abroadProduction/getData/':
              echartType1 = 'line'
              echartType2 = 'line'
              echartType3 = 'bar'
              barName = '产量'
              data.dataList = data.dataList.map((e) => {
                return (e / 10000).toFixed(2)
              })
              break
            case '/international/abroadSale/getData/':
              echartType1 = 'line'
              echartType2 = 'line'
              echartType3 = 'bar'
              barName = '销量'
              data.dataList = data.dataList.map((e) => {
                return (e / 10000).toFixed(2)
              })
              break
            case '/international/chinaExport/getData/':
              echartType1 = 'line'
              echartType2 = 'line'
              echartType3 = 'bar'
              barName = '出口量'
              data.dataList = data.dataList.map((e) => {
                return (e / 10000).toFixed(2)
              })
              break
          }

          if (data.yoyList) {
            const yoyList = data.yoyList.map((item) => {
              return parseFloat(item).toFixed(2)
            })
            globalDataAmountOnlyEchartData.seriesData.push({
              name: '同比',
              type: echartType1,
              color: '#0474bc',
              yAxisIndex: 1,
              showSymbol: false, // 去掉点
              smooth: true, // 变得圆滑一点
              data: yoyList
            })
          }

          if (data.qoqList) {
            const qoqList = data.qoqList.map((item) => {
              return parseFloat(item).toFixed(2)
            })
            globalDataAmountOnlyEchartData.seriesData.push({
              name: '环比',
              type: echartType2,
              color: '#09a3e0',
              yAxisIndex: 1,
              showSymbol: false, // 去掉点
              smooth: true, // 变得圆滑一点
              data: qoqList
            })
          }
          if (data.dataList) {
            globalDataAmountOnlyEchartData.seriesData.push({
              name: barName,
              type: echartType3,
              color: '#d6e4f1',
              showSymbol: false, // 去掉点
              smooth: true, // 变得圆滑一点
              data: data.dataList,
              leftName: '百万美元'
            })
          }
          globalDataAmountOnlyEchartData.yAxisColor = yAxisColor
          if (!data.dateList || data.dateList.length == 0) {
            if (this.$refs[type]) {
              this.$refs[type].dispose()
            }
            this.empty[type] = false
          } else {
            this.empty[type] = true
            this.$nextTick(() => {
              console.log(this.$refs, type)
              this.$refs[type].init(globalDataAmountOnlyEchartData)
            })
          }
        }
      })
    },
    /**
     * @description: 更多跳转
     * @param {*} path
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-26 10:59:59
     * @LastEditTime: Do not edit
     */

    linkTo(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1366px) {
  .main-html {
    font-size: calc(16px * #{$BassZoom}) !important;
  }
}
</style>

<style lang="scss" scoped>
$BassZoom: 1;

.container {
  ::v-deep .column-main {
    position: relative;
  }
}

.chart {
  width: 100%;
  margin: 0 auto;
  height: 33.6rem;
  border: 1px solid #eeeeee;
  /* background: url(../../public/static/bg.png) no-repeat; 背景图设置*/
  background-size: 100% 100%;
}
.search-box {
  position: absolute;
  top: calc(0rem * #{$BassZoom});
  left: calc(0rem * #{$BassZoom});
  width: calc(16.666rem * #{$BassZoom});
  background-color: transparent;
  /deep/ .el-select {
    // border: 1px solid rebeccapurple !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 4px 4px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #fff !important;
  }
  .tip-box {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    right: -60px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 4px 4px;
  }
  .data-info {
    margin-top: 12px;
    width: 100%;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 2.333rem;
      padding: calc(0.4rem * #{$BassZoom}) calc(1rem * #{$BassZoom});
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background-color: #0d57bc;
      .close {
        float: right;
        cursor: pointer;
      }
    }
    .data {
      width: 100%;
      // height: 21.2rem;
      /deep/.el-tabs__item {
        padding: 0;
        height: 2.08rem;
        line-height: 2.08rem;
      }
      .echart-box {
        width: 100%;
        height: 15.666rem;
      }
      .explain {
        width: 100%;
        margin-top: calc(0.8rem * #{$BassZoom});
        padding: 0 10%;
        text-align: start;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        // color: #0d57bc;
      }
      .moreLink {
        width: 100%;
        margin-top: calc(0.8rem * #{$BassZoom});
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0d57bc;
        // text-decoration: underline;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 1rem 3rem;
        padding-top: 0;
        .el-icon-more {
          color: transparent;
          background: url(~@/assets/img/overview/more.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .open-data {
      width: 100%;
      padding: 0.5rem;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #0d57bc;
      // text-decoration: underline;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1366px) {
  .echart-box{
    zoom: 0.9;
  }
  .name {
    font-size: calc(12px * #{$BassZoom}) !important;
  }
  .moreLink,
  .open-data {
    font-size: calc(10px * #{$BassZoom}) !important;
  }
  .moreLink {
    padding: 1rem 3rem !important;
    padding-top: 0 !important;
  }
  .data {
    /deep/.el-tabs__item {
      font-size: 12px !important;
    }
  }
}
// @media screen and (max-width: 1366px) {
//   .name {
//     font-size: 12px * #{$BassZoom} !important;
//   }
//   .moreLink,
//   .open-data {
//     font-size: 10px * #{$BassZoom} !important;
//   }
//   .data {
//     /deep/.el-tabs__item {
//       font-size: 12px !important;
//     }
//     // .echart-box {
//     //   height: 10.666rem !important;
//     // }
//   }
// }
</style>
