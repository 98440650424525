<template>
    <div class="swiper">
        <div class="slide" v-for="(item,index) in list" :key="item.id">
            <p class="speciaImg">
                <img :src="item.specialUrl" :alt="item.specialName" />
            </p>
            <div class="special-car-inner">
                <h1 class="text-overflow">{{ item.specialName }}</h1>
            </div>
            <div class="special-car-foot">
                <p @mouseover="handleMouseover(index)" @mouseout="handleMouseout(index)">
                    <el-button  @click="toHref(item.id)" size="medium" class="button">查看专题
                        <img class="el-icon--right" :ref="'img' + index" src="@/assets/img/special/right.png" alt="">
                    </el-button>
                </p>
                <p>
                    <img src="@/assets/img/special/article.png" alt="">
                    <span class="blueNumber"> {{ item.relationCount }}</span>篇文章
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            imgPaths: [
                require('@/assets/img/special/right.png'),
                require('@/assets/img/special/action-right.png'),
            ],
        }
    },
    methods: {
        handleMouseover(index) {
            this.$refs['img' + index][0].src = this.imgPaths[1];
        },
        handleMouseout(index) {
            this.$refs['img' + index][0].src = this.imgPaths[0];
        },
        toHref(id){
            this.$router.push('/index/detail/specialDetail/' + id);
        }
    },
};
</script>
  
<style lang="scss" scoped>
.swiper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .slide {
        position: relative;
        flex-shrink: 0;
        width: calc(50% - 20px);
        min-height: 0;
        margin-top: 32px;
        border-radius: 4px;
        background-color: #fbfbfb;

        .speciaImg {
            display: flex;
            flex-direction: column;
            padding: 12.9% 0;
            width: 100%;
            color: #666;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
    }
}
.special-car-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 26px 20px;

  ::v-deep .el-button {
    font-weight: normal;
    color: #999;
    font-size: 12px;
    padding: 13px 42px;
    border-radius: 4px;
    border-color: #e2e2e2;

    &:hover {
      border-color: #3f76ea;
      color: #3f76ea;
      background: #fff;
    }

    .el-icon-download {
      margin-right: 6px;
    }
  }
}
.special-car-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;

  h1 {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    flex: 1;
  }

  h2 {
    font-size: 16px;
    line-height: 25px;
    color: #828282;

    strong {
      font-size: 22px;
    }
  }
}
.blueNumber {
  padding-left: 2px;
  color: #5888ec;
}
</style>
  