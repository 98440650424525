<template>
  <PageContainer breadcrumbTrail>
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs actionNavInformation="Information" />
      </div>
    </template>
    <ComparedModal />
    <BasicInfo style="margin-top: 10px;" />
    <span v-if="isShowParts">
      <MainModels style="margin-top: 10px;" />
      <SupportingSituationParts style="margin-top: 10px;" />
      <Performance style="margin-top: 10px;" />
      <PartsHistory style="margin-top: 10px;" />
    </span>
    <span v-else>
      <ProductionSalesComparison style="margin-top: 10px;" />
      <Capacity style="margin-top: 10px;" />
      <SupportingSituation style="margin-top: 10px;" />
      <MainCar style="margin-top: 10px;" />
      <BusinessConditions style="margin-top: 10px;" />
    </span>
    <div v-if="activities.length === 0">
      <el-empty :image-size="160" description="暂无对比数据"></el-empty>
    </div>

    <template slot="right">
      <NavList title="企业详情目录" />
    </template>
  </PageContainer>
</template>

<script>
import actionTabs from "../../components/actionTabs.vue";
import NavList from "../components/navList.vue";

import ComparedModal from "./comparedModal.vue";
import BasicInfo from "./basicInfo.vue";
import Capacity from "./base/capacity.vue";
import ProductionSalesComparison from "./base/productionSalesComparison.vue";
import SupportingSituation from "./base/supportingSituation.vue";
import MainCar from "./base/mainCar.vue";
import BusinessConditions from "./base/businessConditions.vue";
import MainModels from "./parts/mainModels.vue";
import SupportingSituationParts from "./parts/supportingSituationParts.vue";
import Performance from "./parts/performance.vue";
import PartsHistory from "./parts/partsHistory.vue";
export default {
  name: "InformationVueIndex",
  components: {
    actionTabs,
    NavList,
    ComparedModal,
    BasicInfo,
    Capacity,
    ProductionSalesComparison,
    SupportingSituation,
    MainCar,
    BusinessConditions,
    MainModels,
    SupportingSituationParts,
    Performance,
    PartsHistory
  },
  data() {
    return {
      activities: [],
      intervalId: null,
      isShowParts: false,
      initialUpdateInterval: 800, // 初始更新频率（.8秒）
      regularUpdateInterval: 5000 // 常规更新频率（5秒）
    };
  },
  mounted() {
    this.updateActivities();
    this.setInitialUpdateInterval();
  },
  methods: {
    updateActivities() {
      let elements = document.getElementsByClassName("enterpriseDetail");
      let newActivities = Array.from(elements).map(el => ({
        value: el.id,
        label: el.dataset.name
      }));
      if (JSON.stringify(newActivities) !== JSON.stringify(this.activities)) {
        this.activities = newActivities;
      }
    },
    setInitialUpdateInterval() {
      let sTime = 0;
      this.intervalId = setInterval(() => {
        this.updateActivities();
        if (sTime > 7) {
          clearInterval(this.intervalId);
          this.setRegularUpdateInterval();
          sTime = 0;
        } else {
          sTime++;
        }
      }, this.initialUpdateInterval);
    },
    setRegularUpdateInterval() {
      let sTime = 0;
      this.intervalId = setInterval(() => {
        this.updateActivities();
        if (sTime > 7) {
          clearInterval(this.intervalId);
          sTime = 0;
        } else {
          sTime++;
        }
      }, this.regularUpdateInterval);
    }
  },
  created() {
    this.isShowParts =
      this.$route.query.selectedEnterpriseType === "零部件企业";
  },
  watch: {
    "$route.query.selectedEnterpriseType"(selectedEnterpriseType) {
      this.isShowParts = selectedEnterpriseType === "零部件企业";
    },
    "$route.query.checkedList"() {
      this.setInitialUpdateInterval();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>
