<!--行业资讯和分析报告-->
<template>
  <router-view v-if="$route.name == '行业资讯内容页' || $route.name == '分析报告内容页'" />
  <PageContainer v-else>
    <template slot="actionNav">
      <div class="tabs">
        <el-tabs v-model="articleCategory" @tab-click="handleClick" :before-leave="leaveTab">
          <el-tab-pane label="全部资讯" name=" " />
          <el-tab-pane label="推荐资讯" name="0" />
          <el-tab-pane label="技术领域" name="2" />
          <el-tab-pane label="企业动态" name="3" />
          <el-tab-pane label="国际化" name="4" />
        </el-tabs>
      </div>
    </template>

    <div class="bigbox">
      <div v-for="(item, index) in typeList" :key="index">
        <div v-show="item === '行业类型'" class="flexC" flex="c">
          <span class="checkTitle">行业类型</span>
          <div class="lable-box">
            <el-checkbox-button class="allBtn" label="全部" size="small" @change="hyAllchange" v-model="hyAll">
            </el-checkbox-button>
          </div>
          <el-checkbox-group v-model="keywords" size="small" @change="handleChange">
            <el-checkbox-button v-for="item in keywordsData" :key="item.dictValue" :label="item.dictValue">{{
              item.dictLabel }}
              <span v-if="isClear(keywords, item.dictValue)"><i class="el-icon-close"></i> </span></el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div v-show="item === '技术领域'" class="flexC" flex="c">
          <span class="checkTitle">技术领域</span>
          <div class="">
            <el-checkbox-button class="allBtn" label="全部" size="small" @change="jsAllchange" v-model="jsAll">
            </el-checkbox-button>
          </div>
          <el-checkbox-group v-model="technology" size="small" @change="handleChangejs">
            <el-checkbox-button :label="item.dictValue" v-for="item in technologyData" :key="item.dictValue">{{
              item.dictLabel }}
              <span v-if="isClear(technology, item.dictValue)"><i class="el-icon-close"></i> </span></el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div v-show="item === '国际化'" class="flexC" flex="c">
          <span class="checkTitle">国际化</span>
          <div class="lable-box">
            <el-checkbox-button class="allBtn" label="全部" size="small" @change="gjAllchange" v-model="gjAll">
            </el-checkbox-button>
          </div>
          <el-checkbox-group v-model="internationalize" size="small" @change="handleChangegj">
            <el-checkbox-button :label="item.dictValue" v-for="item in internationalDate" :key="item.dictValue">{{
              item.dictLabel }}
              <span v-if="isClear(internationalize, item.dictValue)"><i class="el-icon-close"></i>
              </span></el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div v-show="item === '属性分类'" class="flexC" flex="c">
          <span class="checkTitle">属性分类</span>
          <div class="lable-box">
            <el-checkbox-button class="allBtn" label="全部" size="small" @change="sxAllchange" v-model="sxAll">
            </el-checkbox-button>
          </div>
          <el-checkbox-group v-model="keyword" size="small" @change="handleChangesx">
            <el-checkbox-button :label="item.dictValue" v-for="item in keywordData" :key="item.dictValue">{{
              item.dictLabel }}
              <span v-if="isClear(keyword, item.dictValue)"><i class="el-icon-close"></i> </span> </el-checkbox-button>
          </el-checkbox-group>
        </div>

        <el-form v-show="item === '所属企业'" :ref="form[index]" :model="form" :inline="true" class="demo-form-inline"
          label-width="120px">
          <el-form-item prop="cycEnterpriseGroup">
            <span class="checkTitle">所属企业</span>
            <IndexSelect
              v-model="form.cycEnterpriseGroup"
              :options="cycEnterpriseGroupData"
              size="small"
              placeholder="选择乘用车企业集团"
            />
          </el-form-item>
          <el-form-item prop="cycEnterprise">
            <!-- <el-select v-model="form.cycEnterprise" collapse-tags multiple placeholder="选择乘用车企业" size="small" class="g-form-input"
                      clearable filterable>
              <el-option v-for="item in cycEnterpriseData" :key="item.dictValue" :label="item.dictLabel"
                        :value="item.dictValue">
              </el-option>
            </el-select> -->
            <IndexSelect placeholder="选择乘用车企业" v-model="form.cycEnterprise" :options="cycEnterpriseData" size="small" />
          </el-form-item>
          <el-form-item prop="sycEnterprise">
            <!-- <el-select v-model="form.sycEnterprise" collapse-tags multiple placeholder="选择商用车企业" size="small" class="g-form-input"
                      clearable filterable>
              <el-option v-for="item in sycEnterpriseData" :key="item.dictValue" :label="item.dictLabel"
                        :value="item.dictValue">
              </el-option>
            </el-select> -->
            <IndexSelect placeholder="选择商用车企业" v-model="form.sycEnterprise" :options="sycEnterpriseData" size="small" />
          </el-form-item>
          <el-form-item prop="lbjEnterprise">
            <!-- <el-select v-model="form.lbjEnterprise" collapse-tags multiple placeholder="选择零部件企业" size="small" class="g-form-input"
                      clearable filterable>
              <el-option v-for="item in lbjEnterpriseData" :key="item.dictValue" :label="item.dictLabel"
                        :value="item.dictValue">
              </el-option>
            </el-select> -->
            <IndexSelect placeholder="选择零部件企业" v-model="form.lbjEnterprise" :options="lbjEnterpriseData" size="small" />
          </el-form-item>
        </el-form>
      </div>

      <div class="formFoot">
        <div class="rest-set">
          <el-button size="mini" class="rest" @click="resetForm()"> <i class="el-icon-refresh-right"></i> 重置筛选</el-button>
          <el-button size="mini" @click="setLike(true)"> <i class="el-icon-setting"></i> 关注领域设置</el-button>
        </div>
        <div class="search-btn-box">
          <el-input v-model="title" size="medium" clearable prefix-icon="el-icon-search" placeholder="搜索关键词"
            @clear="resetForm()" @keyup.enter.native="search">
            <template slot="append" class="search-btn">
              <span @click="search">搜索</span>
            </template>
          </el-input>
        </div>
      </div>
    </div>

    <searchList flag="0" v-loading="loading" class="list-container" noshowDefImg showReleaseColumn tableUpdateTimet
      :table-data="tableData" :page-data="pageData" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      @toHref="toHref">
    </searchList>

    <el-dialog title="选择你重点关注的新闻资讯" :visible.sync="dialogVisible" width="850px">
      <likeDialog :keywordsData="keywordsData" :backUserData="backUserData" :technologyData="technologyData"
        :internationalDate="internationalDate" :sycEnterpriseData="sycEnterpriseData" :keywordData="keywordData"
        :cycEnterpriseGroupData="cycEnterpriseGroupData" :cycEnterpriseData="cycEnterpriseData"
        :lbjEnterpriseData="lbjEnterpriseData" @close="close"></likeDialog>
    </el-dialog>
  </PageContainer>
</template>
<script>
import Vlist from '@/components/list'
import { logRecords } from '@/utils/auth'
import { getUserInfo } from '@/utils/auth.js'
import { selectUserSettings } from '@/api/Index.js'
import { selectDictDataByType } from '@/api/dict.js'
import searchList from "@/components/searchList.vue";
import likeDialog from "@/components/likeDialog.vue";
import IndexSelect from '@/components/IndexSelect'
import { ttIndustryInformationPage, ttIndexAnalysisPage, getFirstUser, IndustryEnterprise } from '@/api/Index.js'
export default {
  components: {
    Vlist,
    searchList,
    likeDialog,
    IndexSelect,
  },
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      articleCategory: ' ',
      hyAll: true,
      jsAll: true,
      gjAll: true,
      sxAll: true,
      loading: false,
      dialogVisible: false,
      keyword: [],
      typeList: ['行业类型', '技术领域', '国际化', '属性分类', '所属企业'],
      // 属性分类
      keywords: [],
      // 行业类型
      tableData: [],
      technology: [],
      // 技术领域
      keywordData: [],
      backUserData: {},
      keywordsData: [],
      technologyData: [],
      internationalize: [],
      internationalDate: [],
      cycEnterpriseData: [],
      sycEnterpriseData: [],
      lbjEnterpriseData: [],
      cycEnterpriseGroupData: [],
      title: '',
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      query: {},
      form: {
        cycEnterpriseGroup: [],
        cycEnterprise: [],
        sycEnterprise: [],
        lbjEnterprise: []
      }
    }
  },
  created() {
    this.query = this.$route.query
    this.title = this.$route.query.title ? this.$route.query.title : ""
    // this.articleCategory = this.$route.query.category;
    // this.keywords.push(this.$route.query.releaseColumn);
  },
  mounted() {
    this.allFun(this.articleCategory)
    this.selectDictDataByType()
    if (getUserInfo()) {
      const userId = getUserInfo().userId
      getFirstUser({ flag: '0', userId: userId.toString() }).then(res => {
        if (res.data) {
          this.setLike()
        }
      })
    }
  },
  watch: {
    keywords: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.hyAll = newVal.length <= 0 ? true : false
      },
    },
    technology: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.jsAll = newVal.length <= 0 ? true : false
      },
    },
    keyword: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.sxAll = newVal.length <= 0 ? true : false
      },
    },
    internationalize: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.gjAll = newVal.length <= 0 ? true : false
      },
    },
    form: {
      deep: true,
      handler(newVal, oldVal) {
        this.allFun(this.articleCategory)
      },
    },
  },
  computed: {
    isClear() {
      return (arr, id) => {
        return arr.includes(id);
      };
    },
  },
  methods: {
    // 数据字典
    selectDictDataByType() {
      Promise.all([
        selectDictDataByType({ dictType: 'release_column' }),
        selectDictDataByType({ dictType: 'international_state' }),
        selectDictDataByType({ dictType: 'industry_technology' }),
        selectDictDataByType({ dictType: 'industry_keyword' }),
      ]).then(([releaseColumnRes, internationalStateRes, industryTechnologyRes, industryKeywordRes,]) => {
        if (releaseColumnRes.ok) {
          this.keywordsData = releaseColumnRes.data;
        }

        if (internationalStateRes.ok) {
          this.internationalDate = internationalStateRes.data;
        }

        if (industryTechnologyRes.ok) {
          this.technologyData = industryTechnologyRes.data;
        }

        if (industryKeywordRes.ok) {
          this.keywordData = industryKeywordRes.data;
        }

      })
      IndustryEnterprise().then(res => {
        this.cycEnterpriseGroupData = this.addLabelAndValue(res.data.zc);

        this.cycEnterpriseData = this.addLabelAndValue(res.data.cyc);

        this.sycEnterpriseData = this.addLabelAndValue(res.data.syc);

        this.lbjEnterpriseData = this.addLabelAndValue(res.data.lbj);
      })
    },
    // 添加value,label
    addLabelAndValue(data) {
      return data.map(item => {
        return {
          ...item,
          label: item.dictLabel,
          value: item.dictLabel
        };
      });
    },
    leaveTab(activeName, oldActiveName) {

    },
    close() {
      this.dialogVisible = false
    },
    async setLike(flag = false) {
      if (getUserInfo()) {
        const userInfo = getUserInfo();
        const res = await selectUserSettings({ userId: userInfo.userId });
        this.backUserData = res.data;
        this.dialogVisible = (res.data === null || Object.keys(res.data).length === 0 || flag) ? true : false;
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 切换
    handleClick(e) {
      if (e.label === '技术领域') {
        this.typeList = ['技术领域', '国际化', '行业类型', '属性分类', '所属企业']
      } else if (e.label === '企业动态') {
        this.typeList = ['所属企业', '属性分类', '行业类型', '技术领域', '国际化']
      } else if (e.label === '国际化') {
        this.typeList = ['国际化', '行业类型', '技术领域', '属性分类', '所属企业']
      } else {
        this.typeList = ['行业类型', '技术领域', '国际化', '属性分类', '所属企业']
      }
      this.allFun(e.name)
      // this.form.contentCategory = "yc"
    },
    handleSizeChange(v) {
      this.pageData.pages.pageSize = v
      this.allFun(this.articleCategory)
    },
    handleCurrentChange(v) {
      this.pageData.pages.page = v
      this.allFun(this.articleCategory)
    },
    // 行业资讯和分析报告
    allFun(type) {
      this.ttIndustryInformationPage()
      // switch (type) {
      //   case 'industry':
      //     this.ttIndustryInformationPage()
      //     break
      //   case 'report':
      //     this.ttIndexAnalysisPage()
      //     break
      // }
    },
    // 行业资讯
    ttIndustryInformationPage() {
      const page = {
        ...this.pageData.pages,
        title: this.title,
        flag: this.articleCategory.trim(),
        keyword: this.keyword.join(','),
        technology: this.technology.join(','),
        releaseColumn: this.keywords.join(','),
        internationalize: this.internationalize.join(','),
        cycEnterprise: this.getDictLabels(this.cycEnterpriseData, this.form.cycEnterprise).join(','),
        sycEnterprise: this.getDictLabels(this.sycEnterpriseData, this.form.sycEnterprise).join(','),
        lbjEnterprise: this.getDictLabels(this.lbjEnterpriseData, this.form.lbjEnterprise).join(','),
        cycEnterpriseGroup: this.getDictLabels(this.cycEnterpriseGroupData, this.form.cycEnterpriseGroup).join(','),
      }
      this.loading = true;
      ttIndustryInformationPage(page).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list
          this.pageData.total = res.data.count
        }
        this.loading = false;
      })
    },
    // 分析报告
    ttIndexAnalysisPage() {
      const page = {
        ...this.pageData.pages,
        publishColumn: this.keywords.join(','),
        title: this.title
      }
      this.loading = true;
      ttIndexAnalysisPage(page).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list
          this.pageData.total = res.data.count
        }
        this.loading = false;
      })
    },
    getDictLabels(data, searchList) {
      return data
        .filter(item => searchList.includes(item.dictLabel))
        .map(item => item.dictValue);
    },
    handleChange(e) {
      this.keywords = e
      this.allFun(this.articleCategory)
    },
    hyAllchange(e) {
      this.hyAll = true
      this.keywords = []
      this.allFun(this.articleCategory)
    },
    handleChangejs(e) {
      this.technology = e
      this.allFun(this.articleCategory)
    },
    jsAllchange(e) {
      this.jsAll = true
      this.technology = []
      this.allFun(this.articleCategory)
    },
    handleChangesx(e) {
      this.keyword = e
      this.allFun(this.articleCategory)
    },
    sxAllchange(e) {
      this.sxAll = true
      this.keyword = []
      this.allFun(this.articleCategory)
    },
    handleChangegj(e) {
      this.internationalize = e
      this.allFun(this.articleCategory)
    },
    gjAllchange(e) {
      this.gjAll = true
      this.internationalize = []
      this.allFun(this.articleCategory)
    },
    // 搜索
    search() {
      logRecords('首页', '行业资讯', '4', 'tt_industry_information', '', '', '', this.title)
      this.allFun(this.articleCategory)
    },
    resetForm() {
      this.title = ''
      this.keyword = []
      this.keywords = []
      this.technology = []
      this.internationalize = []
      this.form = {
        cycEnterpriseGroup: [],
        cycEnterprise: [],
        sycEnterprise: [],
        lbjEnterprise: []
      }
      this.allFun(this.articleCategory)
    },
    toHref(url, item) {
      let to = ''
      // if (this.articleCategory == 'industry') {
      to = '/index/detail/IndustryDetail/' + item.id
      logRecords('首页', '行业资讯', '1', 'tt_industry_information', item.id, '', item.title, '')
      const newUrl = this.$router.resolve({
        path: to
      })
      window.open(newUrl.href, '_blank')
      // } else {
      //   to = '/index/detail/ReportDetail'
      //   this.$router.push({
      //     path: to,
      //     query: {
      //       id: item.id,
      //       releaseColumn: this.query.releaseColumn,
      //       category: this.query.category
      //     }
      //   })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      padding: 0 24px;
      color: #666;
      height: 48px;
      line-height: 48px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #3f76ea;
        font-weight: bold;
      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
  }
}

.bigbox {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 22px 20px;

  ::v-deep .el-select .el-input__inner::placeholder {
    color: rgb(51, 51, 51);
  }

  .flexC {
    margin-bottom: 20px;
  }

  .checkTitle {
    width: 60px;
    margin-right: 16px;
    display: inline-block;
    color: rgb(153, 153, 153);
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 4px;
    }

    .el-checkbox-button {
      margin-right: 10px;

      .el-checkbox-button__inner {
        padding: 7px 13px;
        min-width: 58px;
        color: #333;
        font-size: 14px;
        font-weight: normal;
        border-radius: 22px;
        border: 0;

        span {
          margin-top: 1px;
          margin-right: -4px;
        }
      }
    }

    .el-checkbox-button .el-checkbox-button__inner:hover {
      color: #3f76ea;
    }

    .is-checked .el-checkbox-button__inner {
      background: #3f76ea;
      color: #FFF !important;
    }

    .el-form--inline .el-form-item__content {
      line-height: normal;
    }
  }
}

.formFoot {
  padding-top: 19px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e6eb;

  .rest-set {
    .el-button {
      color: rgb(51, 51, 51);
      font-weight: normal;
      border-radius: 4px;
    }

    .el-button:hover {
      border: 1px solid #DCDFE6;
      background: none;
    }

    .el-icon-setting:before {
      font-size: 13px;
    }

    .el-icon-refresh-right {
      font-size: 13px;
    }
  }

  .search-btn-box {
    display: flex;
    position: relative;

    ::v-deep .el-input__prefix {
      font-size: 16px;
    }

    //margin-top: 9px;
    ::v-deep {
      .el-input__inner {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 0;
      }

      .el-input__inner {
        background: #f8f8f8;
        border-right: 1px solid #f3f5f7;
      }

      .el-input-group__append {
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        background-color: #3f76ea;
        border-color: #3f76ea;
        font-weight: normal;
        cursor: pointer;

        .el-button {
          margin-left: -16px;
          margin-right: -16px;
          padding-left: 16px;
          padding-right: 16px;
          border: none;
        }
      }

      .el-icon-search:before {
        color: #aaaaaa;
        font-weight: 600;
      }

      .el-icon-circle-close:before {
        color: #aaaaaa;
        font-weight: 600;
      }

      .el-input-group__prepend {
        position: relative;
        border: 0;
        color: rgb(51, 51, 51);
        font-size: 14px;
      }

      .el-input-group__prepend:hover {
        border: 0;
        background: #fff;
      }

      .el-input-group__prepend::after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
        width: 1px;
        background-color: #f3f5f7;
      }
    }

    .el-input-group {
      width: 470px;
    }
  }

}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}

.list-container {
  position: relative;
  z-index: 1;
}
</style>
