<!--template>
    <div>
        <ul class="list-box">
            <li v-for="item in newList" :key="getNodeKey(item)">
                <div class="list-content">
                    <a href="javascript:;" class="list-l text-overflow" @click="toHref(item)">
                        {{ item.title }}
                    </a>
                    <span class="list-r">{{ item.date }}</span>
                </div>
            </li>
        </ul>
        <div class="pages">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>
        </div>
    </div>
</template-->
<script>
import { htmlRestore } from "@/utils/htmlRestore.js";
import {  fmtDate } from "@/utils/date.js";
export default {
    props: {
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        pageData: {
            default() {
                return {
                    pages: {
                        page: 1,
                        pageSize: 10,
                    },
                    total: 0,
                    currentPage: 0,
                };
            },
        },
        to: {
            type: String,
            default: "",
        },
        props: {
            default() {
                return {
                    title: "title",
                    date: "updateTime",
                };
            },
        },
        nodeKey: String,
    },
    computed: {
        newList() {
            if (this.list.length > 0) {
                for (let key in this.props) {
                    this.list.map((item) => {
                        item.title = htmlRestore(item.title);
                        item.updateTime = fmtDate("YYYY-mm-dd",item.updateTime)
                        return (item[key] = item[this.props[key]]);
                    });
                }
                return this.list;
            } else {
                return [];
            }
        },
    },
    render() {
        return (
            <div>
                <ul class="list-box">
                    {this.newList.map((item) => {
                        return (
                            <li key={this.getNodeKey(item)}>
                                <div class="list-content">
                                    <a
                                        href="javascript:;"
                                        class="list-l text-overflow"
                                        onClick={() => this.toHref(item)}
                                    >
                                        <span v-if="item.code">【{item.code}】</span>{item.title}
                                    </a>
                                    <span class="list-r"> {item.implementDate} </span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div class="pages">
                    <el-pagination
                        currentPage={this.pageData.pages.page}
                        pageSizes={[10, 20, 30, 40]}
                        pageSize={this.pageData.pages.pageSize}
                        total={this.pageData.total}
                        layout={"total, sizes, prev, pager, next, jumper"}
                        on-size-change={this.handleSizeChange}
                        on-current-change={this.handleCurrentChange}
                    ></el-pagination>
                </div>
            </div>
        );
    },
    methods: {
        //分页
        handleSizeChange(val) {
            this.$emit("handleSizeChange", val);
        },
        handleCurrentChange(val) {
            this.$emit("handleCurrentChange", val);
        },
        //绑定key
        getNodeKey(item) {
            if (!this.nodeKey) return item.id;
            return item[this.nodeKey];
        },
        //跳转页面
        toHref(item) {
            this.$emit("toHref", item);
        },
    },
};
</script>
<style lang="scss" scoped>
.list-box {
    margin-top: 16px;
    li {
        background: #fff;
        padding: 24px;
        border-bottom: 1px solid #f2f2f2;
        &:last-child {
            border-bottom: none;
        }
        .list-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .list-l {
                padding-right: 20px;
                flex: 1;
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                color: #141414;
            }
            .list-r {
                font-size: 16px;
                line-height: 18px;
                color: #828282;
            }
        }
    }
}
</style>
