<template>
    <router-view v-if="$route.name == '产销产能详情'" />
    <PageContainer v-else>
        <template slot="actionNav">
            <div class="tabs">
                <actionTabs actionNavInformation="newcapacity" />
            </div>
        </template>

        <div class="capacity">
            <div class="topRadio">
                <el-radio-group v-model="radioValue" size="small">
                    <el-radio-button label="国内汽车产能布局"></el-radio-button>
                    <el-radio-button label="国内企业产量销量"></el-radio-button>
                    <el-radio-button label="中国车企海外产能"></el-radio-button>
                    <el-radio-button label="核心车企全球工厂布局"></el-radio-button>
                </el-radio-group>
            </div>
            <div class="content-box">
                <yields v-if="radioValue === '国内企业产量销量'" />
                <capacity v-if="radioValue === '国内汽车产能布局'" />
                <worldMap v-if="radioValue === '中国车企海外产能'" />
                <globalFactories v-if="radioValue === '核心车企全球工厂布局'" />
            </div>
        </div>
    </PageContainer>
</template>

<script>
import actionTabs from "../components/actionTabs.vue"
import capacity from "./components/capacity"
import yields from "./components/yield"
import worldMap from "./components/worldMap"
import globalFactories from "./components/globalFactories"
import { logRecords } from '@/utils/auth'
export default {
    components: {
        actionTabs,
        capacity,
        yields,
        worldMap,
        globalFactories
    },
    props: {},
    data() {
        return {
            radioValue: "国内汽车产能布局",
        }
    },
    watch: {
        radioValue: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                logRecords('企业情报', newVal, '3', '', '', '', '', '查询')
            },
        },
    },
    computed: {},
    methods: {

    },
    created() {
        const radioValue = this.$route.params.radioValue;
        if (radioValue) {
            this.radioValue = radioValue
        }
    },
    mounted() {

    },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap::after {
    content: none;
}

.topRadio {
    padding-bottom: 22px;

    ::v-deep {
        .el-radio-button {
            border: 0;
            border-radius: 4px;
        }

        .el-radio-button__inner {
            border: 0;
            border-radius: 4px;
        }

        .el-radio-button__inner:hover {
            color: #606266;
        }

        .el-radio-button{
            margin-right: 12px;
        }

        .is-active .el-radio-button__inner {
            background: #3f76ea;
        }

        .is-active :hover {
            color: #fff;
        }
    }
}
</style>