<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" isContentTwo="zcbd" />

    <template slot="right">
      <Vcontent-item title="相关政策推荐" type="list" :list="relationPolicy" @click="tohref" />
      <Vcontent-item title="原创解读推荐" type="list" :list="tableDataRight" @click="toHref" />
    </template>
  </PageContainer>
</template>
<script>
import {localOriginal, ttPolicyReport} from "@/api/policy.js";
import {htmlRestore} from "@/utils/htmlRestore";
import {getUserInfo} from "@/utils/auth";
export default {
    data() {
        return {
            data: {},
            props: {
                htmlContent: "content",
            },
            relationPolicy: [],
            tableDataRight:[],
        };
    },
    watch: {
        $route(to, from) {
            let id = to.params.id;
            this.ttPolicyReport(id);
            // 对路由变化作出响应...
        },
    },
    created() {
        let id = this.$route.params.id;
        this.ttPolicyReport(id);
      this.localOriginalRight({page: 1, pageSize: 10,unscrambleUnit:'1'});

    },
    methods: {
        ttPolicyReport(params) {
            ttPolicyReport(params).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                    if (res.data.relationPolicy) {
                        this.relationPolicy = res.data.relationPolicy.map(
                            (item) => {
                                item.publishDate = item.publishDate;
                                return item;
                            }
                        );
                    }else{
                      this.relationPolicy = []
                    }
                }
            });
        },
      //本网原创
      localOriginalRight(params) {
        localOriginal(params).then((res) => {
          if (res.ok) {
            this.tableDataRight = res.data.list;
            this.tableDataRight.forEach((element) => {
              element.title = htmlRestore(element.title);
              element.publishDate = element.updateTime;
            });
          }
        });
      },
        tohref(item) {
            //政策主体推荐
            this.$router.push("/policy/dynamic/contentNewPolicy/" + item.id);
        },
      toHref(item) {
        let newUrl = this.$router.resolve(
            "/policy/dynamic/LocalOriginalDetails/" + item.id
        );
        if (getUserInfo()) {

          if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
            let menuName = getUserInfo().menuName;
            if (!menuName.includes('政策')) {
              this.sqsyLogin();
              this.insertBuryPoint();
            } else {
              window.open(newUrl.href, "_blank");

            }
          } else {
            window.open(newUrl.href, "_blank");
          }
        } else {
          this.showLogin();
        }
      },
    },
};
</script>
