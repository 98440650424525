<template>
  <div
    v-if="isShow"
    class="enterpriseDetail salesAmount-detail"
    id="salesAmount"
    data-name="产销量"
  >
    <el-card>
      <div slot="header" class="clearfix">
        <h3 class="title-tag-text">
          产销量
        </h3>
      </div>
      <AmountChart :dataSource="dataSource">
        <template #rightExport>
          <el-button
            type="primary"
            round
            size="small"
            :loading="salesInfoExportState"
            @click="exportFile('salesInfo')"
            >导出</el-button
          >
        </template>
        <template #leftExport>
          <el-button
            type="primary"
            round
            size="small"
            :loading="searchProductionExportState"
            @click="exportFile('searchProduction')"
            >导出</el-button
          >
        </template>
      </AmountChart>
    </el-card>
  </div>
</template>

<script>
import {
  getEnterpriseVehicleData,
  getExportEnterpriseVehicleProductionData,
  getExportEnterpriseVehicleSalesData
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import AmountChart from "./amountChart.vue";

export default {
  name: "SalesAmount",
  components: {
    AmountChart
  },
  data() {
    return {
      salesInfoExportState: false,
      searchProductionExportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isShow: false,
      params: {
        enterpriseId: this.$route.params.id
      },
      dataSource: {}
    };
  },

  created() {
    this.getEnterpriseVehicleData(this.params);
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile(type) {
      if (getUserInfo()) {
        if (type === "salesInfo") {
          logRecords("企业情报", "企业信息" , "3", "", "", "", "", "产量", "导出");
          this.salesInfoExportState = true;
        } else {
          logRecords("企业情报", "企业信息" , "3", "", "", "", "", "销量", "导出");
          this.searchProductionExportState = true;
        }
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {


          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              if (type === "salesInfo") {
                this.salesInfoExportState = false;
              } else {
                this.searchProductionExportState = false;
              }
              this.insertBuryPoint();
              openSy();
            } else {
              if (type === "salesInfo") {
                getExportEnterpriseVehicleProductionData(this.params).then(
                  res => {
                    this.salesInfoExportState = false;
                    fileDownload(res, "产销量-产量.xlsx");
                  }
                  );
                } else {
                getExportEnterpriseVehicleSalesData(this.params).then(res => {
                  this.searchProductionExportState = false;
                  fileDownload(res, "产销量-销量.xlsx");
                });
              }
            }
          }
        } else {
          if (type === "salesInfo") {
            getExportEnterpriseVehicleProductionData(this.params).then(res => {
              this.salesInfoExportState = false;
              fileDownload(res, "产销量-产量.xlsx");
            });
          } else {
            getExportEnterpriseVehicleSalesData(this.params).then(res => {
              this.searchProductionExportState = false;
              fileDownload(res, "产销量-销量.xlsx");
            });
          }
        }
      } else {
        this.showLogin();
      }
    },
    getEnterpriseVehicleData(params) {
      getEnterpriseVehicleData(params).then(res => {
        if (res.ok) {
          this.dataSource = res.data;
          this.isShow =
            res.data.salesInfoList.length &&
            res.data.searchProductionResult.length
              ? true
              : false;
        } else {
          this.isShow = false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.salesAmount-detail {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}
</style>
