<template>
  <PageContainer breadcrumb>
    <!--      <VlistTitle bigTtitle="未实施标准" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>-->
    <!--      <div style="overflow: hidden" class="box_box">-->
    <!--        &lt;!&ndash; <div style="float: left" flex="c">-->
    <!--            <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">-->
    <!--                <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">{{ item.dictLabel }}</el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--        </div> &ndash;&gt;-->
    <!--        <div style="float: right">-->
    <!--          <el-input placeholder="搜索名称" size="small" v-model="title" @keyup.enter.native="search">-->
    <!--            <i slot="suffix" class="el-input__icon el-icon-search" @click="search" style="cursor: pointer;"></i>-->
    <!--          </el-input>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <Vlist :list="datas" :pageData="pageData" @handleSizeChange="handleSizeChange"-->
    <!--             @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>-->

    <div class="list-filter" style="width: 100%;background: #fff;">
      <el-row>

        <!--          <el-col :span="24">-->
        <!--            <div style="margin: 20px">-->

        <!--              <div class="zcTitle">标准类别-->
        <!--                <span style="float: right">-->
        <!--                    <el-input style="float: right" placeholder="搜索名称" size="small" v-model="title"-->
        <!--                              @keyup.enter.native="init">-->
        <!--                      <i slot="suffix" class="el-input__icon el-icon-search" @click="init"-->
        <!--                         style="cursor: pointer;"></i>-->
        <!--                    </el-input>-->
        <!--                </span>-->

        <!--              </div>-->

        <!--              <el-checkbox-group v-model="standardTypeString" size="small" @change="handleChange">-->
        <!--                <el-checkbox :label="item.dictValue" border v-for="item in standardType"  :key="item.dictValue" >-->
        <!--                    {{ item.dictLabel }}-->
        <!--                </el-checkbox>-->
        <!--              </el-checkbox-group>-->
        <!--            </div>-->

        <!--          </el-col>-->
        <el-col :span="24">
          <div style="margin: 20px">

            <div class="zcTitle">热门领域</div>
            <!--              <el-row>-->
            <!--                <el-col :span="8">-->
            <!--                  <el-form-item prop="category" :label="item.name" v-for="item in belongAreas" :key="item.id">-->
            <!--                    <el-select v-model="value" placeholder="请选择" style="width: 200px" size="small"-->
            <!--                                clearable multiple>-->
            <!--                      <el-checkbox style=" text-align: right;width: 100%;padding-right: 10px;" v-model="checked" @change='selectAll(item.children)'>全选</el-checkbox>-->

            <!--                      <el-option-->
            <!--                          v-for="items in item.children"-->
            <!--                          :key="items.id"-->
            <!--                          :label="items.name"-->
            <!--                          :value="items.id"-->
            <!--                      >-->
            <!--                      </el-option>-->
            <!--                    </el-select>-->
            <!--                  </el-form-item>-->
            <!--                </el-col>-->
            <!--              </el-row>-->
            <el-form>
              <el-row>
                <el-col v-for="(item,index) in belongAreas" :key="item.id" :span="8">
                  <el-form-item :label="item.name+'：'" label-width="90px">

                    <el-select v-model="value[index]" multiple clearable collapse-tags placeholder="请选择" @change="selectChange()">
                      <!--                    <el-checkbox style=" text-align: right;width: 100%;padding-right: 20px;" v-model="checked[index]" @change='selectAll(item.children,index)'>全选</el-checkbox>-->
                      <!--                    <el-option  value="" @change='selectAll(item.children,index)'>全选</el-option>-->
                      <!--                    <el-option-->
                      <!--                        v-for="items in item.children"-->
                      <!--                        :key="items.id"-->
                      <!--                        :label="items.name"-->
                      <!--                        :value="items.id"-->
                      <!--                        >-->
                      <!--                    </el-option>-->
                      <el-option label="全选" value="全选" @click.native="selectAll(item.children,index)" />
                      <el-option v-for="item in item.children" :key="item.id" :label="item.name" :value="item.id" />

                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>

        </el-col>
      </el-row>
    </div>

    <div style="width: 100%;background: #fff;padding-left: 20px;font-weight: bold;text-align: center">
      <span>共计<span style="color: red">{{ pageData.total }}</span>条</span>
      <el-button style="margin-left: 10px;height: 30px;" @click="reset">重置</el-button>
    </div>
    <div class="box">
      <ul class="tab-box">
        <li
          v-for="item in state"
          :key="item.dictValue"
          class="tab-list"
          :class="{'ac':ac == item.dictValue}"
          @click="tabBtn(item.dictValue)"
        >{{ item.dictLabel }}
        </li>

      </ul>
    </div>

    <!--      <Vlist :list="newPolicyDate" :pageData="newPolicyPage" @handleSizeChange="handleSizeChange"-->
    <!--             @handleCurrentChange="handleCurrentChange" @toHref="toHref" class="list" v-loading="loading"/>-->

    <ul v-loading="loading" class="newUl">
      <li v-for="item in datas" :key="item.id">
        <div class="newUl-link" style="cursor: pointer" @click="toHref('/standards/dynamic/newStandards/details/'+item.id,item)">
          <template v-if="item.state">
            <span v-if="item.state == '未实施'" class="state" style="margin-bottom: 10px">{{ item.state }}</span>
            <span v-else-if="item.state == '作废'" class="state red" style="margin-bottom: 10px">{{ item.state }}</span>
            <span v-else-if="item.state == '现行'" class="state gre" style="margin-bottom: 10px">{{ item.state }}</span>
            <span v-else-if="item.state == '被替代'" class="state blue" style="margin-bottom: 10px">{{ item.state }}</span>
          </template>
          <!--              <template v-if="item.belongAreas">-->
          <!--                <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
          <!--              </template>-->
          <!--              <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

          <div class="newUl-title" style="margin-bottom: 10px">{{ item.title }}</div>
          <!--              </el-tooltip>-->
          <div class="newUl-time" style="margin-bottom: 10px">{{ item.publishDate }}</div>
        </div>
        <div class="newUl-link" style="margin-top: 4px;display: inline;color: grey">

          标准号：{{ item.code }}&nbsp;&nbsp;|&nbsp;&nbsp;类别：{{ item.categoryExtend }}
          <span v-if="item.belongAreas!='' && item.belongAreas!=null">
            &nbsp;&nbsp;|&nbsp;&nbsp;领域：{{ item.belongAreas }}
          </span>

        </div>
      </li>
    </ul>
    <div class="pages">
      <el-pagination
        v-if="pageShow"
        :current-page="pageData.pages.page"
        :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </PageContainer>
</template>
<script>
// import VlistTitle from '@/components/listTitle.vue'
import { newStandard } from '@/api/standards.js'
// import Vlist from '@/components/listTwo'
import { getUserInfo, logRecords, ydlTk,openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'
import { selectDictDataByType, ttDemension } from '@/api/dict'

export default {
  // components: {
  //   VlistTitle,
  //   Vlist
  // },
  data() {
    return {
      bgurl: require('@/assets/img/Group299.png'),
      datas: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },

      ac: '',
      loading: false,
      belongAreas: [],
      standardType: [],
      standardTypeString: [],
      state: [],
      carryCurrentRowCode: {},
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      },
      value: [],
      belongAreasData: '',
      checked: [false, false, false, false, false, false],
      pageShow: true
    }
  },
  created() {
    const title = this.$route.query.title
    if (title != undefined) {
      this.standardTypeString.push(title)
    }

    //     let val = this.$route.query.val;
    this.carryCurrentRowCode = this.$route.query.carryCurrentRowCode
    const ind = this.$route.query.ind
    if (this.carryCurrentRowCode != undefined) {
      if (this.carryCurrentRowCode[0] != this.carryCurrentRowCode[1]) {
        this.checked[ind] = true
        this.selectAll(this.carryCurrentRowCode, ind)
      }
    }

    // this.getCategory()
    this.getState()
    this.init()
    this.ttDemension({ code: 'standardTagsThree' })
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    init() {
      if (!getUserInfo() && this.pageData.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.pageData.pages, {
          title: this.title,
          state: this.ac,
          standardType: this.standardTypeString.join(','),
          belongAreas: this.belongAreasData,
          homeFlag: 1
        })
        this.loading = true
        newStandard(form).then((res) => {
          if (res.ok) {
            this.datas = res.data.list
            this.pageData.total = res.data.count
            this.loading = false
          }
        })
      }
    },
    // selectAll(item,index) {
    //
    //   this.value[index] = []
    //   if (this.checked[index]) {
    //     item.map((item) => {
    //       this.value[index].push(item.id)
    //     })
    //   } else {
    //     this.value[index] = []
    //   }
    //   this.selectChange();
    // },
    selectAll(item, index) {
      if (this.value[index] != undefined) {
        if (this.value[index].length < item.length) {
          // this.value[index] = []
          item.map((item) => {
            this.value[index].push(item.id)
          })
        } else {
          this.value[index] = []
        }
      } else {
        this.value[index] = []
        item.map((item) => {
          this.value[index].push(item.id)
        })
        this.value[index].unshift('全选')
      }

      this.selectChange()
    },
    changeSelect(val) {
      if (!val.includes('全选') && val.length === this.options.length) {
        this.selectedArray.unshift('全选')
      } else if (val.includes('全选') && (val.length - 1) < this.options.length) {
        this.selectedArray = this.selectedArray.filter((item) => {
          return item !== '全选'
        })
      }
    },
    removeTag(val) {
      if (val === '全选') {
        this.selectedArray = []
      }
    },

    // 获取字典
    getCategory() {
      selectDictDataByType({ dictType: 'standard_type' }).then(
        (res) => {
          if (res.ok) {
            this.standardType = res.data
            this.standardType = this.standardType.filter((item) => item.dictLabel != '无')
          }
        }
      )
    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.belongAreas = res.data
        }
      })
    },
    // 标准状态
    getState() {
      selectDictDataByType({ dictType: 'mng_bz_standard_state' }).then(
        (res) => {
          if (res.ok) {
            res.data.unshift({ 'dictLabel': '全部', 'dictValue': '' })
            this.state = res.data
          }
        }
      )
    },
    selectChange() {
      let belongAreas = ''
      this.value.forEach((v, i) => {
        const v1 = v.join(',')
        if (belongAreas != '') {
          if (v1 != '') {
            belongAreas += ',' + v1
          }
        } else {
          if (v1 != '') {
            belongAreas = v1
          }
        }
      })
      this.belongAreasData = belongAreas
      this.init()
    },
    reset() {
      // this.standardType = [];
      this.standardTypeString = []
      this.state = []
      this.title = ''
      this.checked = [false, false, false, false, false, false]
      this.carryCurrentRowCode = {}
      this.value = []
      this.belongAreasData = ''
      this.init()
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e
        this.init()
      } else {
        this.showLogin()
      }
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.page = val
        this.init()
      }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('标准', '最新标准', '1', 'tt_standard', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 切换
    handleChange(e) {
      if (getUserInfo()) {
        if (e.length <= 0) {
          this.all = true
        } else {
          this.all = false
        }
        this.init()
      } else {
        this.showLogin()
      }
    },
    search() {
      this.pageData.pages.page = 1
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>

.el-select-allTop {
  width: 100%;
  border-bottom: 1px solid rgba(204, 204, 204, 0.596);
}
.allPosition {
  overflow: auto;
  height: 200px;
}

.list {
  /deep/ .list-box {
    margin-top: 0;
  }
}

.list-filter {
  //padding: 24px;
  background: #ffffff;

  ::v-deep .el-checkbox__input {
    display: none;
  }

  ::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box {
  padding: 0 24px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 15px;
    line-height: 10px;
    color: #4f4f4f;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.zcTitle {
  margin: 20px 0;
  font-weight: bold;
  font-size: 16px
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

::v-deep .el-checkbox__label {
  padding-left: 5px;
}

::v-deep .el-button {
  padding: 5px 20px;
  background-color: #0d57bc;
  color: #fff;
}

.list-box {
  //margin-top: 16px;
  li {
    background: #fff;
    padding: 24px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-l {
        padding-right: 20px;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #141414;
      }

      .list-r {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}

.state {
  //padding: 4px;
  //background: #f2c94c;
  //border-radius: 2px;
  //font-size: 14px;
  //line-height: 22px;
  //color: #000;
  //font-weight: bold;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 4px;
  background: #ffd450;
  margin-right: 4px;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0D57BC;
  color: #fff;
}
.newUl .newUl-title {
  font-weight: bold;
}
.newUl .newUl-time {
  font-size: 16px;

}
.newUl {
  position: relative;
  z-index: 1;
  padding-top: 10px;
}
::v-deep .list-filter[data-v-6a955904] .el-input__inner {
  border-radius: 5px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
