<template>
  <div class="tab-header custom-scrollbar">
    <div
      v-for="item in list"
      :key="item.name"
      :class="['tab-item', activeName === item.name ? 'active' : '']"
      @click="handleClickTab(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabHeader',
  props: {
    list: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClickTab(item) {
      this.$emit('selectChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-header {
  display: flex;
  align-items: center;
  overflow: auto;

  .tab-item {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #4F4F4F;
    transition-property: color, background-color;
    transition-duration: 0.3s;
    cursor: pointer;
    margin-right: 48px;

    &::before {
      content: '';
      opacity: 0;
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #0D57BC;
      transition-property: opacity, color;
      transition-duration: 0.3s;
    }

    &.active, &:hover {
      color: #0D57BC;
      font-weight: bold;

      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
