<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-11 13:36:07
-->
<template>
  <el-col :span="col">
    <el-card class="box-card" style="padding-bottom: 24px;">
      <div slot="header" class="header">
        <span class="title"><span v-if="cionShow" class="icon" />{{ title }}</span>
        <div class="right-btn-box">
          <slot name="rightBtnBox" />
        </div>
      </div>
      <slot name="body" />
    </el-card>
  </el-col>
</template>
<script>
export default {
  name: 'Card',
  props: {
    // 布局大小
    col: {
      type: Number,
      default: 24
    },
    // 卡片名称
    title: {
      type: String,
      default: '请传入参数title'
    },
    // 图标显示
    cionShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      btnList: []
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.box-card {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 4px 4px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .icon {
      display: inline-block;
      height: 6px;
      width: 6px;
      background-color: #0d57bc;
      border-radius: 50%;
      margin-bottom: 2px;
      margin-right: 12px;
    }
  }
  /deep/.el-card__header {
    padding: 24px;
    border: none;

  }
  /deep/.el-card__body {
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
  }
  /deep/.clearfix {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
}
</style>
