<template>
  <router-view v-if="$route.name == '政策本网原创内容页'"/>
  <PageContainer v-else breadcrumb>
    <div class="box">
      <!--        <VlistTitle :bgurl="bgurl"/>-->
      <!--        <div class="list-title-bg" >-->
      <a href="https://www.wjx.cn/vm/tHyKIrE.aspx#" target="_blank" title="点击进入中国汽车企业家信心指数调研系统" class="list-title-bg">

        <!--            <img :src="bgurl">-->
      </a>

      <!--        </div>-->

      <div class="box_box">
        <div style="overflow: hidden">
          <!-- <div style="float: left; margin-bottom: 10px" flex="c">
              <el-checkbox label="全部" border size="small" style="margin-right: 8px" @change="kckAll" v-model="keywordAll"></el-checkbox>
              <el-checkbox-group v-model="form.keywords" size="small" @change="kcheck" class="lable-box">
                  <el-checkbox :label="item.dictValue" border v-for="item in keywords" :key="item.dictValue">{{item.dictLabel}}</el-checkbox>

              </el-checkbox-group>
          </div> -->
          <div style="float: right" class="search-btn-box ">
            <el-input placeholder="信心指数" v-model="title" size="small"></el-input>
            <div class="search-btn" @click="search">搜索</div>
          </div>
        </div>
      </div>
      <div class="box_box_title" v-for="(item,index) in confidenceIndexData" :key="item.id" @click="toHref(item)">
        <div style="display: flex">
          <div class="text-center" style="width: 267px; height: 150px">
            <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
          </div>
          <div class="flex_col">
            <div>
              <div flex="c" style="margin: 10px 0">

                <p>{{ htmlRestore(item.title) }}</p>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ htmlRestore(item.introduction) }}
              </div>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ item.updateTime }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box_box_title">
<div style="display: flex">
  <img
    style="width: 300px; height: 150px"
    src="@/assets/img/dnc-img.png"
    alt=""
  />
  <div class="flex_col">
    <div>
      <div flex="c" style="margin: 10px 0">
        <span
          style="
            padding: 5px 10px;
            background: #0D57BC;
            color: #ffffff;
            font-weight: 600;
            margin-right: 10px;
          "
          >力荐</span
        >
        <p class="">大陆集团2020年财报分析</p>
      </div>
      <div style="font-size: 14px; color: #828282">
        本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开
      </div>
    </div>
    <div style="font-size: 14px; color: #828282">2021-03-17</div>
  </div>
</div>
</div> -->
      <div class="pages">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="localOriginalPage.currentPage" :page-sizes="[10, 20, 30, 40]"
                       :page-size="localOriginalPage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="localOriginalPage.total">
        </el-pagination>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
// import Vlist from "@/components/list.vue";
import {confidenceIndex} from "@/api/Index.js";
// import ListFiltering from "@/components/ListFiltering.vue";
import {selectDictDataByType} from "@/api/dict.js";
import {getUserInfo} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
    // Vlist,
    // ListFiltering,
  },
  data() {
    return {
      bgurl: require("@/assets/img/xxzs_2.jpg"),
      confidenceIndexData: [],
      tableDataRight: [],
      defaultUrl: require("@/assets/img/default-img.png"),
      localOriginalPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreasData: [],
      belongAreas: [],
      title: "",
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
    };
  },
  created() {
    this.confidenceIndex();
    // this.localOriginalRight({page: 1, pageSize: 10,unscrambleUnit:'4'});

    this.selectDictDataByType();

  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    // //评价与趋势分析
    // localOriginalRight(params) {
    //   localOriginal(params).then((res) => {
    //     if (res.ok) {
    //       this.tableDataRight = res.data.list;
    //       this.tableDataRight.forEach((element) => {
    //         element.title = htmlRestore(element.title);
    //       });
    //     }
    //   });
    // },
    confidenceIndex() {

      let form = Object.assign({}, this.localOriginalPage.pages, {
        title: this.title
      });
      confidenceIndex(form).then((res) => {
        if (res.ok) {
          this.confidenceIndexData = res.data.list;
          this.localOriginalPage.total = res.data.count;
        }
      });

    },
    handleSizeChange(val) {
      this.localOriginalPage.pages.pageSize = val;
      this.localOriginal(this.localOriginalPage.pages);
    },
    handleCurrentChange(val) {
      this.localOriginalPage.pages.page = val;
      this.localOriginal(this.localOriginalPage.pages);
    },
    toHref(item) {
      let newUrl = this.$router.resolve(
          "/index/detail/confidenceIndexDetails/" + item.id
      );

      window.open(newUrl.href, "_blank");


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "mng_zc_policy_type"}).then(
          (res) => {
            if (res.ok) {
              this.belongAreasData = res.data;
            }
          }
      );
    },
    //切换
    handleChange(e) {
      this.belongAreas = e;
      this.localOriginal();
    },
    search() {
      this.localOriginalPage.pages.page = 1;
      this.confidenceIndex();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box {
  padding: 0 10%;
  //width: 1200px;
  //margin: 0 auto 30px;
  //padding-top: 10px;

  .box_box {
    padding: 10px;
    background: #ffffff;

    /deep/ .el-input__inner {
      width: 235px;
      border-radius: 20px;
    }
  }

  .box_box_title {
    margin-top: 10px;
    background: #ffffff;
    cursor: pointer;

    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }

    .flex_col {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

.search-btn-box {
  display: flex;
  //margin-top: 9px;
  /deep/ .el-input__inner {
    height: 35px;
    line-height: 35px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .el-input {
    right: -54px;
  }

  .search-btn {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
}

.index-banner-title {
  // display: flex;
  justify-content: space-between;
  // align-items: center;
}

.title-tag {
  // display: flex;
  align-items: flex-start;

  .title-tag-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    // color: #0d57bc;
    margin-top: -30px;
    margin-left: 20px;
  }
}

.more {
  // display: flex;
  margin-top: 10px;
  align-items: center;
  color: #0d57bc;
  font-size: 14px;
  font-weight: 500;
  // &-icon {
  //   padding-left: 2px;
  // }
  margin-left: 230px;
  margin-bottom: 20px;
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 12px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      left: 3px;
      width: 1px;
      height: 100%;
      border-left: 1px dashed #dcdcdc;
    }

    .index-new-timeLine-content {
      position: relative;
      padding-left: 4px;
      padding-bottom: 11px;

      .index-new-link {
        display: block;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #dcdcdc;

        // padding-left: 20px;
        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.list-title-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/img/xxzs_1.jpg");

  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #fff;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #fff;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
