<template>
  <router-view v-if="$route.name == '公告动态详情'"/>
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="公告动态" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>
    <ul class="list-box">
      <li v-for="item in list" :key="item.id">
        <div class="list-content">
          <a class="list-l text-overflow" @click="toHref(item)">
            {{ item.dynamicTitle }}
          </a>
          <span class="list-r">{{ item.publishDate }}</span>
        </div>
      </li>
    </ul>
    <div class="pages">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                     :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                     :total="pageData.total">
      </el-pagination>
    </div>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import {ggPublic} from "@/api/announcement.js";
import {getUserInfo, ydlTk} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
  },
  data() {
    return {
      bgurl: require("@/assets/img/bg-1.jpg"),
      list: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "公告",
        userId: ""
      },
    };
  },
  created() {
    this.ggPublic(this.pageData.pages);
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(item) {
      let newUrl = this.$router.resolve(
          '/announcement/dynamic/dynamicApprovalContent/' + item.id
      );
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //公告公示
    ggPublic(parmas) {
      ggPublic(parmas).then((res) => {
        this.list = res.data.list;
        this.pageData.total = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.ggPublic(this.pageData.pages);
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.ggPublic(this.pageData.pages);
    },
  },
};
</script>
<style lang="scss" scoped>
.list-box {
  margin-top: 16px;

  li {
    background: #fff;
    padding: 24px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-l {
        padding-right: 20px;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #141414;
      }

      .list-r {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
