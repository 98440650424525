<template>
  <PageContainer breadcrumb>
    <Vcontent name='预测' :data="data" :props="props">
    </Vcontent>
  </PageContainer>
</template>
<script>
 import { getSaleDetail } from "@/api/salesForecast.js";
export default {
  data() {
    return {
      data: {},
      list: [],
      props: {
        tag: "keywords",
        summary: "introduction",
      },
  
    };
  },

  created() {
    let id = this.$route.query.id;
    console.log('id',id)
    this.getSaleDetail({ id });
  },
  watch: {
   
  },
  methods: {
    getSaleDetail(params) {
      getSaleDetail(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  
  },
};
</script>
<style lang="scss" scoped>
</style>
