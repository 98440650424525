<template>
  <div class="chart-container">
    <span class="chart-title">最近 1 年中国出口汽车趋势（万辆）</span>
    <div class="chart-box">
      <div ref="chartRef" class="chart"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as echarts from 'echarts';

export default {
  props: {
    internationalData: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    logRecords: {
      required: false,
      type: Function,
      default: () => () => null,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    data() {
      const data = this.internationalData || [];

      const lables = [];
      const values = [];
      data.forEach((item) => {
        lables.push(moment(item.date).format('YYYY.MM'));
        values.push({
          value: item.sales,
          itemStyle: {
            borderRadius: [6, 6, 0, 0],
          },
        });
      });

      return { lables, values };
    },
  },
  watch: {
    data: {
      deep: true,
      handler(data) {
        this.updateChart({ chart: this.chart, data });
      },
    },
    chart: {
      deep: false,
      handler(chart) {
        this.updateChart({ chart, data: this.data });
      },
    },
  },
  mounted() {
    this.initChart();
  },
  beforeDestory() {
    if (this.chart) {
      window.removeEventListener('resize', this.chart.resize, false);
    }
  },
  methods: {
    initChart() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: false,
        grid: {
          left: '12px',
          right: '12px',
          bottom: '10px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: '出口',
            type: 'bar',
            barWidth: '22%',
            itemStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#fe8099',
                },
                {
                  offset: 1,
                  color: '#ffefea',
                },
              ]),
            },
            showBackground: true,
            backgroundStyle: {
              color: '#f3f3f3',
              borderRadius: [6, 6, 0, 0],
            },
            data: [],
          },
        ],
      };

      const chart = echarts.init(this.$refs.chartRef, {
        width: 'auto',
        height: 'auto',
      });

      chart.setOption(option);

      this.chart = chart;
      window.addEventListener('resize', this.chart.resize, false);
    },
    updateChart({ chart, data }) {
      if (!chart || !data || !data.values) {
        return;
      }

      chart.setOption({
        xAxis: { data: data.lables },
        series: [
          { data: data.values },
        ],
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  margin-bottom: 40px;
  padding-top: 46.1224%;

  .chart-title {
    position: absolute;
    z-index: 2;
    top: 13px;
    left: 10px;
    color: #333;
  }

  .chart-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fbfbfb;

    .chart {
      height: 100%;
    }
  }
}
</style>
