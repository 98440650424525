<template>
  <router-view v-if="$route.name == '标准动态内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle big-ttitle="标准动态" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />
    <ListFiltering :list="belongAreasData" @handleChange="handleChange" @search="search" />
    <Vlist
      v-if="pageShow"
      :list="standardDynamicData"
      :page-data="standardDynamicPage"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { standardDynamic } from '@/api/standards.js'
import Vlist from '@/components/list'
import ListFiltering from '@/components/ListFiltering.vue'
import { selectDictDataByType } from '@/api/dict.js'
import { getUserInfo, logRecords,openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'

export default {
  components: {
    VlistTitle,
    Vlist,
    ListFiltering
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      standardDynamicData: [],
      standardDynamicPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.standardDynamic()
    this.selectDictDataByType()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    standardDynamic() {
      if (!getUserInfo() && this.standardDynamicPage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.standardDynamicPage.pages, {
          title: this.title,
          belongAreas: this.belongAreas
        })
        form.belongAreas = form.belongAreas.join(',')
        standardDynamic(form).then((res) => {
          if (res.ok) {
            this.standardDynamicData = res.data.list
            this.standardDynamicPage.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.standardDynamicPage.pages.pageSize = val
      this.standardDynamic(this.standardDynamicPage.pages)
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.standardDynamicPage.pages.page = val
        this.standardDynamic(this.standardDynamicPage.pages)
      }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve('/standards/dynamic/contentDynamic/' + item.id)
      if (getUserInfo()) {
        logRecords('标准', '标准动态', '1', 'tt_standard_dynamic', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({
        dictType: 'mng_bz_standard_tagsThree'
      }).then((res) => {
        if (res.ok) {
          this.belongAreasData = res.data
        }
      })
    },
    // 切换
    handleChange(e) {
      this.belongAreas = e
      this.standardDynamic()
    },
    search(e) {
      this.standardDynamicPage.pages.page = 1
      this.title = e
      this.standardDynamic()
      logRecords('标准', '标准动态', '4', 'tt_standard_dynamic', '', '', '', e)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
