<!--企业动态内容-->
<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props"/>
  </PageContainer>
</template>
<script>
import { queryByPageDynamicById } from "@/api/enterpriseAnalysis.js";
export default {
  data() {
    return {
      data: {},
      props:{
          title:'tilte',
      }
    };
  },
  created() {
    let id = this.$route.query.id;
    this.queryByPageDynamicById({id});
  },
  methods: {
    queryByPageDynamicById(params) {
      queryByPageDynamicById(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
