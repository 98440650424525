<template>
  <div class="list-title-bg" :style="{backgroundImage: 'url('+bgurl+')'}">
    <h1>{{bigTtitle}}</h1>
    <p>{{smallTip}}</p>
  </div>
</template>
<script>
export default {
    name:"listTitle",
    props:{
        bigTtitle:"",
        smallTip:"",
        bgurl:""
    }
};
</script>
<style lang="scss" scoped>
.list-title-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 116px;
  border-radius: 4px;
  text-align: center;
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #fff;
  }
  p {
    font-size: 18px;
    line-height: 27px;
    color: #fff;
  }
}
</style>