<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" isContentTwo="bzzqyjg" />
  </PageContainer>
</template>
<script>
import { ttSolicitComments } from "@/api/standards.js";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "applicableProducts",
        htmlContent: "content",
        summary:"introduction"
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttSolicitComments(id);
  },
  methods: {
    ttSolicitComments(params) {
      ttSolicitComments(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
