<template>
  <el-dialog
    :visible="visible"
    append-to-body
    width="428px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <div class="title">
      是否确认保存当前设置为查询模板？
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="content">
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-width="80px"
        label-position="left"
      >
        <el-form-item prop="name" label="查询名称">
          <el-input v-model="formData.name" size="small" :maxlength="20" placeholder="请输入" />
        </el-form-item>
        <el-form-item prop="keepLatestDate" label-width="0">
          <el-checkbox v-model="formData.keepLatestDate" label="时间筛选每次打开更新为最新日期" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button size="small" @click="handleClickClose">取消</el-button>
      <el-button type="primary" size="small" :loading="submitLoading" @click="handleClickSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getUserInfo } from '@/utils/auth.js'

export default {
  name: 'QueryTemplateSaveDialog',
  props: {
    saveMethod: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      // 提示用于 当前保存的查询条件可能无法查询 的消息组件实例
      unableToQueryMessageInstance: null,
      queryParams: null,
      formData: {
        name: undefined,
        keepLatestDate: false
      },
      formRules: {
        name: [{ required: true, message: '请输入查询名称', trigger: 'change' }],
        keepLatestDate: [{ required: true, message: '请选择是否保持最新日期', trigger: 'change' }]
      },
      submitLoading: false
    }
  },
  methods: {
    show(queryParams, canDoQuery) {
      this.visible = true
      // 保存查询条件的时候，不用保存分页参数，打开某个查询条件的时候，自动使用初始的分页参数
      delete queryParams.pageSize
      delete queryParams.pageNo
      this.$set(this, 'queryParams', JSON.parse(JSON.stringify(queryParams)))
      // 如果该查询条件无法直接进行查询
      if (!canDoQuery) {
        // 如果当前正在提示，则关闭提示
        this.unableToQueryMessageInstance?.close()
        // 提示用户
        this.unableToQueryMessageInstance = this.$message({
          type: 'warning',
          message: '当前设置的查询条件可能无法查询，建议修改后再保存',
          duration: 8000,
          showClose: true,
          customClass: 'message-z-index',
          onClose: () => {
            this.unableToQueryMessageInstance = null
          }
        })
      }
    },
    handleClickSubmit() {
      if (this.queryParams == null) {
        return
      }
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const { name, keepLatestDate } = this.formData
          // 如果 时间筛选每次打开更新为最新日期，那就要把 结束时间 改成 undefined 再调接口
          if (keepLatestDate) {
            this.$set(this.queryParams.detailParams, 'endDatetime', undefined)
          }
          // btoa()和atob()方法只适用于ASCII字符，如果需要编码中文或其他非ASCII字符，需要用encodeURIComponent()方法进行编码和解码
          const optionJson = window.btoa(window.encodeURIComponent(JSON.stringify(this.queryParams)))
          const userInfo = getUserInfo()
          // 行分类的英文标识
          const category = this.queryParams.categoryName
          this.saveMethod({
            userId: userInfo?.userId,
            // 数据表的英文标识
            table: this.queryParams.draggingTableName,
            // 行分类名称(只有少部分的数据表才有行分类, 没有的话就传 null)
            category,
            // 查询模板名称
            name,
            // 查询参数json字符串
            optionJson
          }).then(res => {
            if (res && res.ok) {
              this.$message.success('保存成功')
              this.$emit('saveSuccess', category, res.data.queryOptionId, name, optionJson)
            }
          })
            .catch(() => {})
            .finally(() => {
              this.submitLoading = false
              this.handleClose()
            })
        }
      })
    },
    // 点击取消或关闭
    handleClickClose() {
      this.handleClose()
    },
    // 处理关闭的逻辑
    handleClose() {
      // 关闭提示
      this.unableToQueryMessageInstance?.close()
      // 清空这些值: 1. 减少内存; 2.防止 show 方法少传参数而导致提交错误
      this.$set(this, 'queryParams', null)
      this.$set(this, 'formData', {
        name: undefined,
        keepLatestDate: false
      })
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
      })
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1D2129;
  margin-bottom: 24px;

  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #4E5969;
    cursor: pointer;
  }
}

::v-deep {

  .el-dialog__header{
    display: none;
  }

  .el-dialog__body {
    padding: 20px 24px 10px;
  }

  .el-input {
    width: 300px;
  }
}
</style>
