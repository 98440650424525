<template>
  <div class="paginator-common" v-if="itemsPerPage < totalItems">
    <el-button
      type="text"
      @click="setCurrentPage(currentPage - 1)"
      style="padding-left:0;"
      :disabled="currentPage <= 1"
    >
      <i class="el-icon-arrow-left"></i>
    </el-button>
    <el-input
    v-if="isFocus"
    class="el-pagination__editor is-in-pagination"
    v-model="inputPage"
    min="1"
    :max="totalPages"
    size="mini"
    number
    @change="goToPage(inputPage)"
    ></el-input>
    <span class="textPage" v-else @click="inputFocus">{{ inputPage }}</span>
    <div class="delimiter">/</div>
    <span class="text">{{ totalPages }}</span>

    <el-button
      type="text"
      @click="setCurrentPage(currentPage + 1)"
      style="padding-right:0;"
      :disabled="currentPage >= totalPages"
    >
      <i class="el-icon-arrow-right"></i>
    </el-button>
  </div>
</template>

<script>
export default {
  name: "Paginator",
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      inputPage: "1",
      isFocus:false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  methods: {
    inputFocus(){
      this.isFocus =true;
    },
    resetNum() {
      this.currentPage =1
    },
    setCurrentPage(page) {
      const numPage = Number(page);
      if (numPage >= 1 && numPage <= this.totalPages) {
        this.currentPage = numPage;
        this.inputPage = numPage.toString(); // Synchronize the input field with the current page
        this.$emit("page-change", this.currentPage);
        this.isFocus =false;
      }
    },
    goToPage(page) {
      let pageNumber = parseInt(page, 10);
      if (!isNaN(pageNumber)) {
        if (pageNumber < 1) {
          this.setCurrentPage(1);
        } else if (pageNumber > this.totalPages) {
          this.setCurrentPage(this.totalPages);
        } else {
          this.setCurrentPage(pageNumber);
        }
      }
    }
  },
  watch: {
    // 监视 inputPage 的变化
    inputPage(newValue) {
      const pageNumber = parseInt(newValue, 10);
      if (isNaN(pageNumber)) {
        // 如果用户清空输入，则不做处理
        return;
      }
      if (pageNumber < 1) {
        this.inputPage = "1";
      } else if (pageNumber > this.totalPages) {
        this.inputPage = this.totalPages.toString();
      }
    },
    currentPage(newPage) {
      this.inputPage = newPage.toString();
    }
  }
};
</script>

<style scoped>
.paginator-common {
  display: flex;
  align-items: center;
}
.el-icon-arrow-left,
.el-icon-arrow-right {
  font-weight: 700;
  font-size: 18px;
}
.el-pagination__editor {
  width: 50px;
  text-align: center;
  margin: 0 5px;
}

.delimiter {
  margin: 0 5px;
  font-size: 16px;
}

.text ,
.textPage{
  margin: 0 5px;
  font-size: 16px;
}
.textPage {
  padding: 0 15px;
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #3f76ea;
}
.textPage:hover{
  cursor: pointer;
}
</style>
