<template>
    <PageContainer breadcrumb footer>
        <Vcontent :data="data" :props="props" :isCollection="false" />
    </PageContainer>
</template>
<script>
import { ttIndexAnalysis } from "@/api/Index.js";
export default {
    data() {
        return {
            data: {},
            props: {
                tag: "keywordTags",
                summary: "remarks",
                htmlContent: "content",
                link: "originalLink",
                publishDate: "updateTime",
            },
        };
    },
    created() {
        let id = this.$route.query.id;
        this.ttIndexAnalysis(id);
    },
    methods: {
        ttIndexAnalysis(params) {
            ttIndexAnalysis(params).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
