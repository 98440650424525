<template>
  <PageContainer breadcrumb>
    <div class="column-main" style="background:#fff;">
      <div>
        <div style="width: 40%; float: left; padding:0px 0 20px 0;">
          <div class="contName" style="font-size: 16px; color: #333; font-weight: bold;">{{ data.factoryName }}</div>
          <div style="border-top: 1px solid #eee;">
            <div class="contName" style="font-size: 16px; color: #333; display: flex; align-items: center;">
              <!-- <img :src="require('@/assets/images/icon1.png')"> -->
               <span class="lines"></span>
              <span class="names">位置·概况</span>
            </div>
            <div class="list1" style="padding-top: 10px;">
              <div v-if="data.address"><span>所在地：</span><span class="titles">{{ data.address }}</span></div>
              <div v-if="data.phone"><span>电话：</span><span class="titles">{{ data.phone }}</span></div>
              <div v-if="data.fax"><span>传真：</span><span class="titles">{{ data.fax }}</span></div>
              <div v-if="data.webUrl"><span>网址：</span><span style="cursor: pointer; color: rgb(63, 118, 234);text-decoration: underline;" @click="hrefs(data.webUrl)">{{ data.webUrl }}</span></div>
            </div>
          </div>
          <div>
            <div class="contName" style="font-size: 16px; color: #333; display: flex; align-items: center; margin-top: 10px;">
              <!-- <img :src="require('@/assets/images/icon2.png')"> -->
              <span class="lines"></span>
              <span class="names">业务摘要</span>
            </div>
            <div class="list1" style="padding-top: 10px;">
              <div v-if="data.capitalRelations"><span>资本关系：</span><span class="titles">{{ data.capitalRelations }}</span></div>
              <div v-if="data.supportingProduct"><span>配套生产：</span><span class="titles brText">{{ data.supportingProduct }}</span></div>
              <div v-if="data.productModelOngoing"><span>生产车型·在产：</span><span class="titles brText">{{ data.productModelOngoing }}</span></div>
              <div v-if="data.productModelPlan"><span>生产车型·计划：</span><span class="titles brText">{{ data.productModelPlan }}</span></div>
              <div v-if="data.capacity"><span>产能：</span><span class="titles brText">{{ data.capacity }}</span></div>
              <div v-if="data.newEnergyModels"><span>新能源车型：</span><span class="titles brText">{{ data.newEnergyModels }}</span></div>
              <div v-if="data.totalOutput"><span>总产量：</span><span class="titles brText">{{ data.totalOutput }}</span></div>
              <div v-if="data.remark"><span>备注：</span><span class="titles">{{ data.remark }}</span></div>  
            </div>
          </div>
        </div>
        <div style="width: 60%; float: right;">
          <div ref="mapContainer" id="myMap" style="width: 100%; margin-right: 0; height: calc(100vh - 187px); margin-top: 0px;"></div> 
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import {
  getfactoryinfo 
} from '@/api/data_new.js'
// import BMap from 'bmap'
export default {
  data() {
    return {
      data: {},
      latitude: '',
      longitude: ''
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.getDetail(id);
    },
  },
  mounted(){
    this.$nextTick(() => {
      this.latitude = this.$route.query.latitude
      this.longitude = this.$route.query.longitude
      this.initMap()
    })
  },
  created() {
    let id = this.$route.params.id;
    this.getDetail(id);
  },
  methods: {
    initMap() {  
      let map = new BMap.Map(this.$refs.mapContainer)
      let point = new BMap.Point(this.longitude, this.latitude); // 设置经纬度  
      // 创建图标实例，设置图标样式  
      let myIcon = new BMap.Icon(require(`@/assets/images/icon02.png`), new BMap.Size(25, 29), {  
        // 图标图片偏移量  
        anchor: new BMap.Size(16, 30),  
        // 图标大小  
        imageSize: new BMap.Size(25, 29)
      }); 
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      map.centerAndZoom(point, 3); // 初始化地图,设置中心点坐标和地图级别
      // 添加标注  
      var marker = new BMap.Marker(point, {icon: myIcon});
      map.addOverlay(marker);
      // 可选：添加标注的点击事件  
      marker.addEventListener("click", function(){
        map.centerAndZoom(marker.point, 5); // 放大到18级  
        // alert("你点击了标注点");
      })
    },
    hrefs(url){
      window.open(url, '_blank')
    },
    getDetail(id) {
      getfactoryinfo(id).then(res => {
        if (res.ok){
          this.data = res.data || {}
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.brText{
  white-space: pre-line;
}
.list1{
  div{
    padding: 5px 20px;
    font-size: 12px;
    color: #86909C;
    margin-bottom: 6px;
    display: flex;
  }
  span:nth-child(1){
    display: inline-block;
    width: 106px;
    font-size: 14px;
    flex-shrink: 0;
  }
  .titles{
    color: #1D2129;
    font-size: 14px;
    display: block;
    line-height: 26px;
  }
}
.contName{
  padding: 10px 20px;
  .lines{
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #0D57BC;
    border-radius: 10px;
    margin-right: 8px;
  }
  img{
    width: 16px;
    margin-right: 3px;
  }
}
</style>
