<template>
  <PageContainer>
    <not-logged-in-mask v-if="showNotLoggedInMask" />
    <unsubscribed-mask v-if="showUnsubscribedMask" />
    <div class="header">
      <tab-header
        :list="tabs"
        :active-name="activeTabName"
        @selectChange="handleTabSelectChange"
      />
    </div>
    <div class="main">
      <category-list :list="categoryList" :active-name="activeCategoryName" @selectChange="handleCategorySelectChange" />
      <div :class="['iframe-wrapper', showMask ? 'show-mask' : '']">
        <iframe id="iframe" class="hidden-scrollbar" :src="iframePath" width="100%" frameborder="0" />
      </div>
    </div>
  </PageContainer>
</template>

<script>
import TabHeader from './comp/tabHeader'
import CategoryList from './comp/categoryList'
import { NotLoggedInMask, UnsubscribedMask } from '@/components/newData/mask'
import { authMixin } from '@/mixin/newData/index.js'
import tabs from './comp/tabs.js'
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { buryPoint } from '@/api/common'

export default {
  name: 'Environment',
  components: {
    NotLoggedInMask,
    UnsubscribedMask,
    TabHeader,
    CategoryList
  },
  mixins: [authMixin],
  data() {
    return {
      tabs,
      activeTabName: undefined,
      categoryList: [],
      activeCategoryName: undefined,
      iframePath: undefined
    }
  },
  computed: {
    headerTabList() {
      return ['国民经济', '采购经理指数', '价格', '金融', '人口', '人民生活', '交通运输', '能源']
    }
  },
  created() {
    this.handleTabSelectChange(tabs[0], true)
  },
  // mounted() {
  //   document.querySelector('#iframe').onload = this.setIframeHeight
  // },
  methods: {
    handleTabSelectChange(tab, isTriggerByCreated = false) {
      this.activeTabName = tab.name
      this.$set(this, 'categoryList', tab.categoryList)
      this.handleCategorySelectChange(tab.categoryList[0])
      // 如果不是由 created 钩子调用的该方法
      if (!isTriggerByCreated) {
        const userInfo = getUserInfo()
        // 如果登录了
        if (userInfo?.userId) {
          logRecords('数据', this.$route.name, '3', '', '', '', '', '', '筛选')
          if (userInfo.roleKey == 'paidrole' || userInfo.roleKey == 'trialrole') {
            // 浏览埋点
            if (!userInfo.menuName.includes('数据')) {
              // 显示申请试用弹窗
              this.$Vlogin3({ flag: true })
              // 弹窗埋点
              buryPoint({
                eventName: '开通卡片点击',
                eventType: '2',
                columnName: '数据',
                userId: userInfo?.userId
              })
            }
          }
        } else {
          // 显示登录弹窗
          this.$Vlogin2({ flag: true })
        }
      }
    },
    handleCategorySelectChange(category) {
      this.activeCategoryName = category.name
      // 如果未登录或者未订阅
      if (this.showMask) {
        // 则展示假数据的页面
        this.iframePath = '/iframes/newData/fake_data.html'
      } else {
        this.iframePath = category.path
      }
    }
    // setIframeHeight() {
    //   const iframe = document.querySelector('#iframe')
    //   if (iframe) {
    //     const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
    //     if (iframeWin.document.body) {
    //       iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.environment-container {
  position: relative;
  border-radius: 30px;
}

.header {
  height: 55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0 24px;
  border-bottom: 1px solid #E5E6EB;
  border-radius: 3px 3px 0 0;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .iframe-wrapper {
    position: relative;
    flex: 1;
  }

  iframe {
    height: 100%;
  }
}
</style>
