<template>
  <div
    class="enterpriseDetail"
    id="ProductionSalesComparison"
    v-if="isShowProductionSalesComparison"
    data-name="企业产销情况对比"
  >
    <chartCard cardTitle="企业产销情况对比" :chartData="sourceData">
      <template #cardSelect>
        <el-radio-group
          v-model="changeQuery"
          size="small"
          @input="changeQueryFn"
        >
          <el-radio-button
            v-for="(item, index) in selectOption"
            :key="index"
            :label="getKey(item)"
            >{{ item[getKey(item)] }}</el-radio-button
          >
        </el-radio-group>
      </template>
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </chartCard>
  </div>
</template>

<script>
import chartCard from "../chart.vue";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import {
  getSalesData,
  getProductionData,
  postComparisonExportSalesData,
  postComparisonExportProductionData
} from "@/api/enterpriseAnalysis.js";

import Vue from "vue";

export default {
  name: "ProductionSalesComparison",
  components: {
    chartCard
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      debouncedChangeQueryFn: null,
      lastRouteChange: "",
      isShowProductionSalesComparison: false,
      isLoading: true,
      changeQuery: "salesData", // 用于存储选中的 radio button 的值
      selectOption: [{ salesData: "销量" }, { productionData: "产量" }],
      sourceData: {},
      params: {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      }
    };
  },

  created() {
    // 初始化防抖函数
    this.debouncedChangeQueryFn = this.debounce(this.handleRouteChange, 100);
    // 初始调用
    this.debouncedChangeQueryFn("isShowProductionSalesComparison");
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业对标分析", "3", "", "", "", "", "企业产销", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              if (this.changeQuery === "salesData") {
                postComparisonExportSalesData(this.params).then(res => {
                  this.exportState = false;
                  fileDownload(res, "企业产销-销量.xlsx");
                });
              } else {
                postComparisonExportProductionData(this.params).then(res => {
                  this.exportState = false;
                  fileDownload(res, "企业产销-产量.xlsx");
                });
              }
            }
          }
        } else {
          if (this.changeQuery === "salesData") {
            postComparisonExportSalesData(this.params).then(res => {
              this.exportState = false;
              fileDownload(res, "企业产销-销量.xlsx");
            });
          } else {
            postComparisonExportProductionData(this.params).then(res => {
              this.exportState = false;
              fileDownload(res, "企业产销-产量.xlsx");
            });
          }
        }
      } else {
        this.showLogin();
      }
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    handleRouteChange() {
      if (
        this.lastRouteChange === "checkedList" ||
        this.lastRouteChange === ""
      ) {
        this.changeQueryFn("isShowProductionSalesComparison");
      }
      this.lastRouteChange = "";
    },
    getKey(obj) {
      return Object.keys(obj)[0];
    },
    changeQueryFn(isShowProductionSalesComparison) {
      if (this.changeQuery === "salesData") {
        this.getSalesData(this.params, isShowProductionSalesComparison);
      } else {
        this.getProductionData(this.params, isShowProductionSalesComparison);
      }
    },
    getSalesData(params, isShowProductionSalesComparison) {
      this.isLoading = true;
      getSalesData(params).then(res => {
        if (res.ok) {
          const { seriesList, xAxisList } = res.data;
          if (isShowProductionSalesComparison) {
            this.isShowProductionSalesComparison =
              seriesList.length !== 0 &&
              xAxisList.length !== 0 &&
              (this.$route.query.checkedList.includes("产销量") ||
                this.$route.query.checkedList.includes("全部"))
                ? true
                : false;
          }
          Vue.set(this, "sourceData", res.data || {});
          this.isLoading = false;
        }
      });
    },
    getProductionData(params, isShowProductionSalesComparison) {
      this.isLoading = true;
      getProductionData(params).then(res => {
        if (res.ok) {
          if (isShowProductionSalesComparison) {
            this.isShowProductionSalesComparison = res.data ? true : false;
          }
          Vue.set(this, "sourceData", res.data || {});
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"() {
      this.params.enterpriseIdList = JSON.parse(
        this.$route.query.selectedEnterprise
      );
      this.lastRouteChange = "selectedEnterprise";
      this.debouncedChangeQueryFn();
    },
    "$route.query.checkedList"() {
      this.lastRouteChange = "checkedList";
      this.debouncedChangeQueryFn();
    }
  }
};
</script>
