<template>
  <PageContainer>
<!--    <div style="margin:25px 0 20px 33px;text-align: center">-->
<!--      <img src="../../../assets/img/logoSvg.svg" />-->
<!--    </div>-->
    <div
      class="content"
      ref="content"
      @mousedown="contentDown"
      @mouseup="contentUp"
      @mousemove="contentMove"
    >
      <table class="table" border="1">
        <tr>
          <td align="right" style="width: 200px; min-width: 200px"></td>
          <td
            style="position: relative;text-align: center"
             :style="styleFlag?'width: 200px; min-width: 200px':''"
            v-for="(item, index) in tableData.ybzp"
            :key="item + index"
          >
            <img :src="item" width="200" />

            <a class="del_btn" @click="del(index)" v-if="tableData.pc.length > 1"
              ><i class="fa fa-times"></i
            ></a>
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>产品型号名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpxhmc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>产品商标：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpsb" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>企业名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.qymc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>生产地址：</strong>
          </td>

          <td v-for="(item, index) in tableData.scdz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>注册地址：</strong>
          </td>
          <td v-for="(item, index) in tableData.zhcAdd" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr class="div-info">
          <td align="right">
            <strong>电话号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.telephone" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr class="div-info">
          <td align="right">
            <strong>传真号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.fax" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr class="div-info">
          <td align="right">
            <strong>邮政编码：</strong>
          </td>

          <td v-for="(item, index) in tableData.postcode" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>外形尺寸（mm）<br />长*宽*高 ：</strong>
          </td>

          <td v-for="(item, index) in tableData.ckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>燃料种类：</strong>
          </td>

          <td v-for="(item, index) in tableData.rlzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>油耗：</strong>
          </td>
          <td v-for="(item, index) in tableData.yh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排放依据标准：</strong>
          </td>

          <td v-for="(item, index) in tableData.yjbz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>发动机型号及生产企业： </strong>
          </td>
          <td v-for="(item, index) in tableData.mfdjqy" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排量（ml）/功率（kw）：</strong>
          </td>

          <td v-for="(item, index) in tableData.mfplgl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轴距（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轮胎规格：</strong>
          </td>

          <td v-for="(item, index) in tableData.ltgg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮胎数：</strong>
          </td>

          <td v-for="(item, index) in tableData.lts" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮距前/后（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qhlj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>额定载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.ghedzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>整备质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zbzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>额定载客（含驾驶员）（人）：</strong>
          </td>

          <td v-for="(item, index) in tableData.edzk" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>最高车速（km/h）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zgcs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>前轮制动型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zdq" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>后轮制动型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zdh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>前轮制动操作型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zcq" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>后轮制动操作型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zch" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>车辆识别代号（vin）：</strong>
          </td>

          <td v-for="(item, index) in tableData.sbdh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>其他：</strong>
          </td>
          <td v-for="(item, index) in tableData.qt" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>操作：</strong>
          </td>

          <td v-for="(item, index) in tableData.id" :key="item + index">
            <template v-if="item != 'null'">
              <el-button type="text" @click="printFun(item)">打印技术参数</el-button>
            </template>

          </td>
        </tr>
      </table>
    </div>
  </PageContainer>
</template>
<script>
import { ggZcCompare } from "@/api/announcement.js";
export default {
  data() {
    return {
      tableData: [],
      filterData: {
        qyId: [],
        cpId: [],
        cpxh: [],
        cpsb: [],
        cpmc: [],
        cpxhmc: [],
        cpsbxhmc: [],
        cplb: [],
        qlj: [],
        hlj: [],
        mdpid: [],
        mdpxh: [],
        mdpqy: [],
        mdplb: [],
        ybzp: [],
        hbzp: [],
        xzzp1: [],
        xzzp2: [],
        xzzp3: [],
        pc: [],
        scdz: [],
        chang: [],
        kuan: [],
        gao: [],
        rlzl: [],
        yjbz: [],
        zxxs: [],
        hxc: [],
        hxk: [],
        hxg: [],
        zhsh: [],
        zhj: [],
        zhh: [],
        thps: [],
        lts: [],
        ltgg: [],
        zzl: [],
        edzl: [],
        zbzl: [],
        gczl: [],
        zzhl: [],
        bgaz: [],
        edzk: [],
        qpck: [],
        jjlqj: [],
        qxhx: [],
        jssh: [],
        cdxs: [],
        zgcs: [],
        zdq: [],
        zdh: [],
        zcq: [],
        zch: [],
        lsh: [],
        tqsb: [],
        fbrq: [],
        mfdj: [],
        mfqy: [],
        mfpl: [],
        mfgl: [],
        mfsb: [],
        sbdh: [],
        yh: [],
        qt: [],
        sm: [],
        qymc: [],
        zwpp: [],
        ywpp: [],
        address: [],
        zhcAdd: [],
        email: [],
        telephone: [],
        fax: [],
        postcode: [],
        lxr: [],
        frdb: [],
        chpsc: [],
        mfpfsp: [],
        mlxh: [],
        xhml: [],
        cxpc: [],
        cxsj: [],
        cxbj: [],
        chfhzp: [],
        fbszd: [],
        bsxh: [],
        bssb: [],
        bsqy: [],
        dp1: [],
        dp2: [],
        dp3: [],
        dp4: [],
        dpqyxh1: [],
        dpqyxh2: [],
        dpqyxh3: [],
        dpqyxh4: [],
        mjian: [],
        bgkz: [],
        dmnhl: [],
        csbg: [],
        bgpc: [],
        cxlb: [],
        tcrq: [],
        tsrq: [],
        cph: [],
        sxrq: [],
        ghzhsh: [],
        ghchang: [],
        ghkuan: [],
        ghgao: [],
        ghzzl: [],
        ghzbzl: [],
        ghedzl: [],
        ghgl: [],
        ghcpmc: [],
        ghcpsb: [],
        ghpc: [],
        cpzl: [],
        ggpcsj: [],
        ghyjbz: [],
        ghdpxh: [],
        bdzs: [],
        ckg: [],

        plgl: [],
        hxlbckg: [],
        ljqh: [],
        dpsclb: [],
        fdjscqy: [],
      },
      startObj: {
        scrollLeft: 0,
        startX: 0,
        scrollD: 0,
        moveFlag: false,
      },
      styleFlag:false
    };
  },
  created() {
       let arr = this.$route.params.id.split(',')
  if(arr.length>1){
    this.styleFlag = true
  }
    this.ggZcCompare();
  },
  methods: {
    //打印printFun
    printFun(id) {
      window.open("/api/exportZcPdf/ggYzkPdf?cpId=" + id);
    },
    returNull(str) {
      if (str) {
        let newStr = str.replace(/null/g, "-");
        return newStr;
      } else {
        return "-";
      }
    },
    ggZcCompare() {
      let ids = this.$route.params.id;
      ggZcCompare({ cpIdArrayString: ids }).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
        }
      });
    },
    filterFun(data) {
      data.forEach((item) => {
        for (let key in item) {
          this.filterData[key].push(item[key]);
        }
        this.filterData.ckg.push(
          item.chang || "" + "*" + item.kuan || "" + "*" + item.gao == null
            ? ""
            : item.gao || ""
        );
        this.filterData.plgl.push(item.mfpl || "" + "/" + item.mfgl || "");

        this.filterData.hxlbckg.push(
          item.hxc || "" + "*" + item.hxk || "" + "*" + item.hxg || ""
        );

        this.filterData.ljqh.push(item.qlj || "" + "/" + item.hlj || "");

        this.filterData.dpsclb.push(item.dpqyxh1 || "" + "/" + item.cplb || "");

        this.filterData.fdjscqy.push(item.mfdj || "" + "/" + item.mfqy || "");
      });
    },
    del(index) {
      let filterData = this.tableData;
      for (let key in filterData) {
        filterData[key].splice(index, 1);
      }
    },
    //按下事件
    contentDown(e) {
      this.startObj.scrollLeft = this.$refs.content.scrollLeft;
      this.startObj.startX = e.pageY;
      this.startObj.moveFlag = true;
    },
    //松开鼠标
    contentUp(e) {
      this.startObj.moveFlag = false;
    },
    //移动鼠标e
    contentMove(e) {
      if (this.startObj.moveFlag) {
        this.startObj.scrollD - e.clientX > 0
          ? this.startObj.scrollLeft++
          : this.startObj.scrollLeft--;
        this.startObj.scrollD = e.clientX;
        this.$refs.content.scrollLeft = this.startObj.scrollLeft;
        e.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  background: #fff;
}
.img-group {
  border: 1px solid $allcolor;
  border-bottom: none;
  text-align: center;
  img {
    width: 220px;
    padding: 5px;
  }
}
.table {
  width: 100%;
   border: none;
  color: #000;
   word-break: break-all;
  tr {
    border: none;
    td {
      padding: 5px 10px;
       border-color: $allcolor;
        word-break: break-all;
    }
      &:nth-child(odd) {
      background: #bbc8da;
    }
  }
  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
td, th {
  border: 1px solid #DDD;
}
</style>
