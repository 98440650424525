var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{
    'mode-absolute': _vm.mode === 'absolute',
    'mode-relative': _vm.mode === 'relative',
    'mode-fixed': _vm.mode === 'fixed',
    'column-left': _vm.position === 'left',
    'column-right': _vm.position === 'right',
  }],style:(_vm.mode !== 'absolute' ? null : { top: `${_vm.top}px`, height: _vm.height })},[(_vm.mode === 'relative')?[_vm._t("default")]:_c('el-scrollbar',[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }