<template>
  <PageContainer>
<!--    <div style="margin:25px 0 20px 33px;">-->
<!--      <img src="../../../assets/img/logoSvg.svg"/>-->
<!--    </div>-->
    <div class="content" ref="content">
      <table class="table">
        <tr>
          <td align="right" style="width: 200px; min-width: 200px">
            <strong>底盘ID：</strong>
          </td>
          <td v-for="(item, index) in tableData.dpid" :key="item + index"
              style="position: relative;"
              :style="styleFlag?'width: 200px; min-width: 200px':''">
            {{ returNull(item) }}
            <a class="del_btn" @click="del(index)" v-if="tableData.pc.length > 1"
            ><i class="fa fa-times"></i
            ></a>
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>批次：</strong>
          </td>
          <td v-for="(item, index) in tableData.pc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>底盘型号：</strong>
          </td>

          <td v-for="(item, index) in tableData.dpxh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>底盘名称：</strong>
          </td>

          <td v-for="(item, index) in tableData.dpmc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>底盘商标：</strong>
          </td>
          <td v-for="(item, index) in tableData.dpsb" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>底盘类别：</strong>
          </td>
          <td v-for="(item, index) in tableData.dplb" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>企业名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.qymc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>生产地址：</strong>
          </td>

          <td v-for="(item, index) in tableData.scdz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>注册地址：</strong>
          </td>
          <td v-for="(item, index) in tableData.zhcAdd" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>电话号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.telephone" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>传真号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.fax" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>邮政编码：</strong>
          </td>

          <td v-for="(item, index) in tableData.postcode" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>外形尺寸（mm）<br/>长*宽*高 ：</strong>
          </td>

          <td v-for="(item, index) in tableData.ckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>燃料种类：</strong>
          </td>

          <td v-for="(item, index) in tableData.rlzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排放依据标准：</strong>
          </td>

          <td v-for="(item, index) in tableData.yjbz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>发动机型号及生产企业： </strong>
          </td>
          <td v-for="(item, index) in tableData.mfdjqy" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排量（ml）/功率（kw）：</strong>
          </td>
          <td v-for="(item, index) in tableData.mfplgl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>转向型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zxxs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轴数：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhsh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轴距（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>钢板弹簧片数：</strong>
          </td>

          <td v-for="(item, index) in tableData.thps" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轮胎规格：</strong>
          </td>

          <td v-for="(item, index) in tableData.ltgg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮胎数：</strong>
          </td>

          <td v-for="(item, index) in tableData.lts" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮距前/后（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qhlj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>额定载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.edzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>整备质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zbzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>准拖挂车总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.gczl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>半挂车鞍座最大允许承载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.bgaz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right"><strong>驾驶室准乘人数：</strong></td>
          <td v-for="(item, index) in tableData.qpck" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>接近角/离去角（°）：</strong>
          </td>
          <td v-for="(item, index) in tableData.jjlqj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>前悬/后悬（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qxhx" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>最高车速（km/h）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zgcs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>车辆识别代号（vin）：</strong>
          </td>

          <td v-for="(item, index) in tableData.sbdh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right"><strong>防抱死系统：</strong></td>
          <td v-for="(item, index) in tableData.fbszd" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>其他：</strong>
          </td>

          <td v-for="(item, index) in tableData.qt" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>操作：</strong>
          </td>

          <!--                <td >-->
          <!--                    <template >-->
          <!--                        <el-button type="text" @click="printFun(tableData.id )">打印技术参数</el-button>-->
          <!--                    </template>-->

          <!--                </td>-->
          <td v-for="(item, index) in tableData.id" :key="item + index">
            <template v-if="item != 'null'">
              <el-button type="text" @click="printFun(item)">打印技术参数</el-button>
            </template>

          </td>
        </tr>
      </table>
    </div>
  </PageContainer>
</template>
<script>
import {
  checkDpDetail
} from "@/api/announcement.js";
import {ggDpCompare} from "@/api/announcement.js";

export default {
  data() {
    return {
      tableData: {},
      startObj: {
        scrollLeft: 0,
        startX: 0,
        scrollD: 0,
        moveFlag: false,
      },
      styleFlag: false

    };
  },

  created() {
    if (this.$route.query.isZheng) {
      // let id = this.$route.params.id;
      this.turnXh()
    }
    if (!this.$route.query.isZheng) {
      // let id = this.$route.params.id;
      let arr = this.$route.params.id.split(',')
      if (arr.length > 1) {
        this.styleFlag = true
      }
      this.ggDpCompare();
    }
  },
  methods: {
    //打印printFun
    printFun(id) {
      window.open("/api/exportZcPdf/ggDpkPdf?dpId=" + id);
    },
    turnXh() {
      // console.log('msg',msg)
      // var msg='JX1032PSB6 二类 江铃汽车股份有限公司'
      // JX1032PSB6 二类   江铃汽车股份有限公司
      // console.log(msg.trim().split(/\s+/))
      // console.log()
      checkDpDetail(`/api/customerNotice/ggDp`, {
        dpid: this.$route.query.dpid,
        pc: this.$route.query.pc
      }).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
        }
      });
    },
    del(index) {
      let filterData = this.tableData;
      for (let key in filterData) {
        filterData[key].splice(index, 1);
      }
    },
    ggDpCompare() {
      let ids = this.$route.params.id;
      ggDpCompare({dpIdArrayString: ids}).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
        }
      });
    },
    //按下事件
    contentDown(e) {
      this.startObj.scrollLeft = this.$refs.content.scrollLeft;
      this.startObj.startX = e.pageY;
      this.startObj.moveFlag = true;
    },
    //松开鼠标
    contentUp(e) {
      this.startObj.moveFlag = false;
    },
    //移动鼠标e
    contentMove(e) {
      if (this.startObj.moveFlag) {
        this.startObj.scrollD - e.clientX > 0
            ? this.startObj.scrollLeft++
            : this.startObj.scrollLeft--;
        this.startObj.scrollD = e.clientX;
        this.$refs.content.scrollLeft = this.startObj.scrollLeft;
        e.preventDefault();
      }
    },
    returNull(str) {
      if (str) {
        let newStr = str.replace(/null/g, "-");
        return newStr;
      } else {
        return "-";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  margin: 20px;
  background: #fff;
}

.table {
  width: 100%;
  border-right: none;
  color: #000;
  word-break: break-all;

  tr {
    border: none;

    td {
      padding: 5px 10px;
      border-color: $allcolor;
      word-break: break-all;
    }

    &:nth-child(odd) {
      background: #bbc8da;
    }
  }

  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

td, th {
  border: 1px solid #DDD;
}
</style>
