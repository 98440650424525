<template>
  <div style="background: #fff;">
    <div class="old-ver-container">
      <div style="font-weight: bold;text-align: center;color: #0f0f0f;padding: 40px 0 20px;font-size: 18px">
        道路运输车辆达标车型配置、参数表
      </div>
      <table class="table" border="1" style="word-break: break-all">
        <tr>
          <td colspan="5" align="center">
            <img v-viewer :src="tableData.xzzp?tableData.xzzp:defaultImg" height="250" title="点击放大" style="cursor: pointer;padding: 10px" alt="">
            <img v-viewer :src="tableData.xzzp2?tableData.xzzp2:defaultImg" height="250" title="点击放大" style="cursor: pointer;padding: 10px" alt="">
            <img v-viewer :src="tableData.xzzp3?tableData.xzzp3:defaultImg" height="250" title="点击放大" style="cursor: pointer;padding: 10px" alt="">
          </td>

        </tr>
        <tr>
          <td class="blackFont" colspan="5" style="text-align: center">达标车型编号 {{ tableData.dbcxbh }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>生产企业：</td>
          <td colspan="4">{{ tableData.scqy }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>产品型号：</td>
          <td colspan="2">{{ tableData.cpxh }}</td>
          <td class="blackFont">产品名称：</td>
          <td colspan="2">{{ tableData.cpmc }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>底盘型号：</td>
          <td colspan="2">{{ tableData.dpxh }}</td>
          <td class="blackFont">发动机型号：</td>
          <td colspan="2">{{ tableData.fdjxh }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>外廓尺寸（长×宽×高）（mm）：</td>
          <td colspan="2">{{ tableData.wkcc }}</td>
          <td class="blackFont">货厢栏板内尺寸（长×宽×高）（mm）或容积（m³）或搅动容量（m³）：</td>
          <td colspan="2">{{ tableData.hxlbncc }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>载客人数（含驾驶员位）（人）：</td>
          <td colspan="2">{{ tableData.zkrs }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>客车类型：</td>
          <td colspan="2">{{ tableData.kclx }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>等级：</td>
          <td colspan="2">{{ tableData.grade }}</td>
          <td class="blackFont">总质量（kg）：</td>
          <td colspan="2">{{ tableData.zzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">最大总质量（kg）：</td>
          <td colspan="2">{{ tableData.zdzzl }}</td>
          <td class="blackFont">整备质量(kg)：</td>
          <td colspan="2">{{ tableData.zbzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">准拖挂车总质量（kg）：</td>
          <td colspan="2">{{ tableData.ztgczzl }}</td>
          <td class="blackFont">牵引座最大允许承载质量（kg）：</td>
          <td colspan="2">{{ tableData.qyzzdyxczzl }}</td>
        </tr>
        <tr>
          <td class="blackFont">变速器型号：</td>
          <td colspan="2">{{ tableData.bsqxh }}</td>
          <td class="blackFont">主减速器速比驱动桥速比：</td>
          <td colspan="2">{{ tableData.zjsqsb }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>轮胎规格：</td>
          <td colspan="2">{{ tableData.ltggxh }}</td>
          <td class="blackFont">轮胎数量前后：</td>
          <td colspan="2">{{ tableData.ltsl }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>制动器型式：</td>
          <td colspan="2">{{ tableData.zdqxs }}</td>
          <td class="blackFont">载荷布置标识：</td>
          <td colspan="2">{{ tableData.zhbzbs }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>悬架类型：</td>
          <td colspan="2">{{ tableData.xjlx }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>车外行李架：</td>
          <td colspan="2">{{ tableData.cwxlj }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>乘员座位数标识：</td>
          <td colspan="2">{{ tableData.cyzwsbs }}</td>
          <td class="blackFont">行李舱净高（m）：</td>
          <td colspan="2">{{ tableData.xlcjg }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>人均行李舱容积（m³/人）≥：</td>
          <td colspan="2">{{ tableData.rjxlcrj }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>行李舱约束装置：</td>
          <td colspan="2">{{ tableData.xlcyszz }}</td>
        </tr>
        <tr>
          <td class="blackFont">燃油箱数量（个）：</td>
          <td colspan="2">{{ tableData.tcqsl }}</td>
          <td class="blackFont">油箱侧面防护：</td>
          <td colspan="2">{{ tableData.yxcmfh }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>停车楔数量（≥2个）：</td>
          <td colspan="2">{{ tableData.ryxsl }}</td>
          <td class="blackFont">油箱距前端距离（≥600mm）：</td>
          <td colspan="2">{{ tableData.yxjqdjl }}</td>
        </tr>
        <tr>
          <td class="blackFont">油箱距后端距离（≥300mm）：</td>
          <td colspan="2">{{ tableData.ryxsl }}</td>
          <td class="blackFont">加气口仪表和阀件防护装置（燃气汽车）：</td>
          <td colspan="2">{{ tableData.jqkybhfjfhzz }}</td>
        </tr>
        <tr>
          <td class="blackFont">燃气瓶数量及位置（燃气汽车）：</td>
          <td colspan="2">{{ tableData.rqpsljwz }}</td>
          <td class="blackFont">气压制动系统压缩空气干燥、油水分离装置：</td>
          <td colspan="2">{{ tableData.qyzdxtyskqgzysflzz }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>制动间隙自动调整装置：</td>
          <td colspan="2">{{ tableData.zdjxzdtzzz }}</td>
          <td class="blackFont">传动轴防护装置：</td>
          <td colspan="2">{{ tableData.cdzfhzz }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>底盘集中润滑系统：</td>
          <td colspan="2">{{ tableData.dpjzrhxt }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>发动机位置：</td>
          <td colspan="2">{{ tableData.fdjwz }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>发动机舱自动灭火装置：</td>
          <td colspan="2">{{ tableData.fdjczdmhzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>缓速器型式及型号：</td>
          <td colspan="2">{{ tableData.hsqxsjxh }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>动力电池箱内具有报警功能的自动灭火装置：</td>
          <td colspan="2">{{ tableData.dldcxnjybjgndzdmhzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>电涡流缓速器报警系统：</td>
          <td colspan="2">{{ tableData.dwlhsqbjxt }}</td>
        </tr>
        <tr>
          <td class="blackFont">电涡流缓速器隔热装置：</td>
          <td colspan="2">{{ tableData.dwlhsqgrzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>电磁风扇离合器或其他节能风扇散热系统：</td>
          <td colspan="2">{{ tableData.dcfslhqhqtjnfssrxt }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>随动转向机构：</td>
          <td colspan="2">{{ tableData.sdzxjg }}</td>
          <td class="blackFont">转向轴数量：</td>
          <td colspan="2">{{ tableData.zxzsl }}</td>
        </tr>
        <tr>
          <td class="blackFont">踏步区座椅布置：</td>
          <td colspan="2">{{ tableData.tbqzybz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>驾驶员上方地板：</td>
          <td colspan="2">{{ tableData.jsysfdb }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>乘客门数量及位置：</td>
          <td colspan="2">{{ tableData.ckmsljwz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>安全标志：</td>
          <td colspan="2">{{ tableData.aqbz }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>乘客门结构：</td>
          <td colspan="2">{{ tableData.ckmjg }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>乘客门应急控制器：</td>
          <td colspan="2">{{ tableData.ckmyjkzq }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>外推式应急窗数量（左/右）（个）：</td>
          <td colspan="2">{{ tableData.wtsyjcsl }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>应急锤数量（个）：</td>
          <td colspan="2">{{ tableData.yjcsl }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>应急锤型号：</td>
          <td colspan="2">{{ tableData.yjcxh }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>应急锤声响信号报警装置：</td>
          <td colspan="2">{{ tableData.yjcsxxhbjzz }}</td>
        </tr>
        <tr>
          <td class="blackFont">后围应急窗布置：</td>
          <td colspan="2">{{ tableData.hwyjcbz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>安全顶窗数量及位置：</td>
          <td colspan="2">{{ tableData.aqdcsljwz }}</td>
        </tr>
        <tr>
          <td class="blackFont">应急门数量及位置：</td>
          <td colspan="2">{{ tableData.yjmsljwz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>安全出口数量（个）：</td>
          <td colspan="2">{{ tableData.aqcksl }}</td>
        </tr>
        <tr>
          <td class="blackFont">应急门引道宽度：</td>
          <td colspan="2">{{ tableData.yjmydkd }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>客舱内通道宽（mm）≥：</td>
          <td colspan="2">{{ tableData.kcntdk }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>座间距（同向）（mm）≥：</td>
          <td colspan="2">{{ tableData.zjj }}</td>
          <td class="blackFont">车内通道折叠座椅：</td>
          <td colspan="2">{{ tableData.cntdzdzy }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>座椅深（mm）≥：</td>
          <td colspan="2">{{ tableData.zys }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>靠背角度可调15°～30°：</td>
          <td colspan="2">{{ tableData.kbg }}</td>
        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>座垫宽（mm）≥：</td>
          <td colspan='2'>{{ tableData.zdk }}</td>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>靠背高（mm）≥：</td>
          <td colspan='2'>{{ tableData.kbg }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>座椅横移（向通道）（mm）≥：</td>
          <td colspan="2">{{ tableData.zyhy }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>扶手（靠通道处）：</td>
          <td colspan="2">{{ tableData.fs }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>汽车安全带：</td>
          <td colspan="2">{{ tableData.qcaqd }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>座椅脚蹬：</td>
          <td colspan="2">{{ tableData.zyjd }}</td>
        </tr>
        <tr>
          <td class="blackFont">安全带提醒装置：</td>
          <td colspan="2">{{ tableData.aqdtxzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>车内行李架：</td>
          <td colspan="2">{{ tableData.cnxlj }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>视频监控系统型号：</td>
          <td colspan="2">{{ tableData.cpjkxtxh }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>卫星定位系统车载终端型号：</td>
          <td colspan="2">{{ tableData.wxdwxtczzdxh }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>空气调节装置：</td>
          <td colspan="2">{{ tableData.kqtjzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>空气净化装置：</td>
          <td colspan="2">{{ tableData.kqjhzz }}</td>
        </tr>
        <tr>
          <td class="blackFont">通风换气装置：</td>
          <td colspan="2">{{ tableData.hqtfzz }}</td>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>影音播放及麦克风设备：</td>
          <td colspan="2">{{ tableData.yybfjmkfsb }}</td>
        </tr>
        <tr>
          <td class="blackFont"><span v-if="isKeche" class="red">*</span>卫生间：</td>
          <td colspan="2">{{ tableData.wsj }}</td>
          <td class="blackFont">前墙：</td>
          <td colspan="2">{{ tableData.xgdslqq }}</td>
        </tr>
        <tr>
          <td class="blackFont">水平承载面：</td>
          <td colspan="2">{{ tableData.xgdslspczm }}</td>
          <td class="blackFont">前下部防护装置：</td>
          <td colspan="2">{{ tableData.qxbfhzz }}</td>
        </tr>
        <tr>
          <td class="blackFont">后下部防护装置：</td>
          <td colspan="2">{{ tableData.hxbfhzz }}</td>
          <td class="blackFont">侧面防护装置：</td>
          <td colspan="2">{{ tableData.cmfhzz }}</td>
        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>CAN总线：</td>
          <td colspan='2'>{{ tableData.can }}</td>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>自动破玻器数量及开关位置：</td>
          <td colspan='2'>{{ tableData.zdpbqsljkgwz }}</td>

        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>轮胎爆胎应急安全装置型号：</td>
          <td colspan='2'>{{ tableData.ltbtyjaqzzxh }}</td>
          <td class='blackFont'>制动气压显示及限压装置：</td>
          <td colspan='2'>{{ tableData.zdqyxsjxyzz }}</td>

        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>轮胎气压监测系统型号：</td>
          <td colspan='2'>{{ tableData.ltqyjcxtxh }}</td>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>制动储气筒额定工作气压（kPa）：</td>
          <td colspan='2'>{{ tableData.zdcqtedgzqy }}</td>

        </tr>
        <tr>
          <td class='blackFont'>制动器衬片更换报警装置：</td>
          <td colspan='2'>{{ tableData.zdqcpghbjzz }}</td>
          <td class='blackFont'>驾驶室轮胎爆胎应急安全装置标示：</td>
          <td colspan='2'>{{ tableData.jssltbtyjaqzzbs }}</td>

        </tr>
        <tr>
          <td class='blackFont'>冷藏车温度监控装置：</td>
          <td colspan='2'>{{ tableData.lccwdjkzz }}</td>
          <td class='blackFont'>起重尾板警示标识：</td>
          <td colspan='2'>{{ tableData.qzwbjsbs }}</td>

        </tr>
        <tr>
          <td class='blackFont'>汽车导静电橡胶拖地带燃气汽车：</td>
          <td colspan='2'>{{ tableData.qcdjdxjtdd }}</td>
          <td class='blackFont'>燃气气体泄漏报警装置型号燃气汽车：</td>
          <td colspan='2'>{{ tableData.rqqtxlbjzzxh }}</td>

        </tr>
        <tr>
          <td class='blackFont'>压力测试连接器数量储气筒：</td>
          <td colspan='2'>{{ tableData.ylcsljqslcqt }}</td>
          <td class='blackFont'>压力测试连接器数量制动气室：</td>
          <td colspan='2'>{{ tableData.ylcsljqslzdqs }}</td>

        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>电子稳定性控制系统（ESC）型号：</td>
          <td colspan='2'>{{ tableData.escxh }}</td>
          <td class='blackFont'>防抱制动装置（ABS）信号报警装置：</td>
          <td colspan='2'>{{ tableData.adsxhbjzz }}</td>

        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>车道偏离预警系统（LDWS）型号：</td>
          <td colspan='2'>{{ tableData.ldwsxh }}</td>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>防抱制动装置（ABS）：</td>
          <td colspan='2'>{{ tableData.abs }}</td>

        </tr>
        <tr>
          <td class='blackFont'><span v-if="isKeche" class="red">*</span>自动紧急制动系统（AEBS）型号：</td>
          <td colspan='2'>{{ tableData.aebsxh }}</td>
          <td class='blackFont'>车辆前向碰撞预警系统型号：</td>
          <td colspan='2'>{{ tableData.clqxpzyjxtxh }}</td>

        </tr>
        <tr>
          <td class='blackFont'>综合燃料消耗量（L/100km）：</td>
          <td colspan='2'>{{ tableData.zhrlxhl }}</td>
          <td class='blackFont'>驱动型式：</td>
          <td colspan='2'>{{ tableData.qdxs }}</td>

        </tr>

        <tr>
          <td class="blackFont">备注：</td>
          <td colspan="5">{{ tableData.remark }}</td>
        </tr>
        <tr v-if="isKeche">
          <td class="blackFont">说明：</td>
          <td colspan="5">标注"<span class="red">*</span>"的项目为《营运客车类型划分及等级评定》（JT/T 325）标准要求项目。</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import {
  getRoadTransportVehicles
} from '@/api/data.js'

export default {
  data() {
    return {
      defaultImg: require('@/assets/img/nonecar.jpg'),
      tableData: {
        cpmc: '',
      }
    }
  },
  computed: {
    isKeche() {
      return this.tableData.cpmc.indexOf('客车') !== -1;
    },
  },
  created() {
    this.getRoadTransportVehicles()
  },
  methods: {
    getRoadTransportVehicles() {
      const id = this.$route.params.id
      getRoadTransportVehicles(id).then((res) => {
        if (res.ok) {
          this.tableData = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.img-group {
  border: 1px solid $allcolor;
  border-bottom: none;
  text-align: center;

  img {
    width: 220px;
    padding: 5px;
  }
}

.table {
  width: 100%;
  border-color: $allcolor;
  color: #000;

  tr {
    td {
      width: 300px;
      border-color: $allcolor;
      padding: 5px 10px;
    }

    // &:nth-child(odd) {
    //  background: #bbc8da;
    //}
  }
}

.blackFont {
  font-weight: bold;
  background: #bbc8da;
}
.red{
  color: red;
}
</style>
