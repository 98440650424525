<template>
  <div class="title-icon" flex="c" @click="click">
      <img v-if="imgSrc" :src="require('../'+imgSrc)" />
    <span :style="'color:'+colors">{{name}}</span>
  </div>
</template>
<script>
export default {
    props:{
        name:"",
        imgSrc:"",
        colors:{
            type:String,
            default:'#333'
        }
    },
    methods:{
        click(){
            this.$emit('click')
        }
    }
};
</script>
<style lang="scss" scoped>
.title-icon {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #333;
  img{
      padding-right: 4px;
  }
}
</style>