<template>
  <!--  <PageContainer>-->
  <div>
    <h1 class="title">{{ tableData.ggYzkEO.cpmc }}</h1>
  <div class="img-grop">
    <div class="img-box">
      <h2 class="img-title">右部照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.ybzp || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">后部照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.hbzp || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">选装照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.xzzp1 || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">选装照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.xzzp2 || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">选装照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.xzzp3 || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">其他照片</h2>
      <div class="img-list">
        <img v-viewer :src="tableData.ggYzkEO.chfhzp || defaultImg" title="点击放大" style="cursor: pointer" alt="">
      </div>
    </div>
  </div>
  </div>
<!--  </PageContainer>-->
</template>
<script>
import { ggYzk } from '@/api/announcement.js'

export default {
  data() {
    return {
      defaultImg: require('@/assets/img/nonecar.jpg'),
      tableData: {}
    }
  },
  created() {
    this.ggYzk()
  },
  methods: {
    ggYzk() {
      const id = this.$route.params.id
      ggYzk(id).then((res) => {
        this.tableData = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  color: #333;
  margin: 16px 0 32px;
  text-align: center;
}

.img-grop {
  overflow: hidden;
  margin-left: -16px;
  .img-box {
    width: 572px;
    height: 395px;
    float: left;
    padding: 16px;
    background: #fff;
    margin-left: 16px;
    margin-bottom: 15px;

    .img-list {
      text-align: center;
      width: 540px;
      height: 316px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .img-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333;
      margin-bottom: 32px;
    }
  }
}
</style>
