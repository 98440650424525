<template>
    <div class="yieid-contain">
        <div class="yidid-top">
            <div class="yeiid-box">
                <div class="yieidtitle">
                    {{ this.firmList.date }}各企业产销量前10名榜单
                </div>
                <div class="topRadio">
                    <el-radio-group v-model="firmValue" size="mini" @change="firmChange()">
                        <el-radio-button label="销量"></el-radio-button>
                        <el-radio-button label="产量"></el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="chart">
                <div class="leftChart">
                    <div class="chartTOP">
                        <div> <span class="title">{{ firmList.enterpriseProductionDateStr ?
                            firmList.enterpriseProductionDateStr : firmList.enterpriseSaleDateStr }}</span>
                            <span class="text">单位/辆</span>
                        </div>
                    </div>
                    <Chart :list="firmList.enterpriseProductionList ?
                        firmList.enterpriseProductionList : firmList.enterpriseSaleList" roteShow />
                </div>
                <div class="rightChart">
                    <div class="chartTOP">
                        <div><span class="title">{{ cartypeList.vehicleProductionDateStr ?
                            cartypeList.vehicleProductionDateStr : cartypeList.vehicleSalesDateStr }}</span>
                            <span class="text">单位/辆</span>
                        </div>
                    </div>
                    <Chart :list="cartypeList.vehicleProductionList ?
                        cartypeList.vehicleProductionList : cartypeList.vehicleSalesList" roteShow />
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="list">
                <div class="list-sele">
                    <div class="item">
                        <span class="checkTitle">企业</span>
                        <SearchSelect placeholder="请选择企业" v-model="form.enterpriseList" :options="enterpriseList"
                            size="small" />
                    </div>
                    <div class="item item-model">
                        <span class="checkTitle">车型</span>
                        <SearchSelect placeholder="请选择车型" v-model="form.vehicleModelList" :options="vehicleModelList"
                            size="small" />
                    </div>
                    <div class="item">
                        <span class="checkTitle">细分类别</span>
                        <!-- <SearchSelect placeholder="请选择细分类别" v-model="form.vehicleTypeList" :options="vehicleTypeList"
                            size="small" /> -->
                            <el-select v-model="form.vehicleTypeList" collapse-tags multiple placeholder="请选择细分类别" size="small"
                            class="g-form-input" clearable filterable>
                            <el-option v-for="item in vehicleTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="list-title">
                    {{ this.firmList.date }}各企业产销量详细情况
                </div>
                <div class="tablelist">
                    <list :loading="listloading" :tableHead="tableHead" :tableData="tableData" />
                </div>
            </div>
        </div>
        <div class="pages" v-if="operate">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageData.pages.pageNum" :page-sizes="[10, 20, 30, 40, 100]"
                :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {
    getVehicleSalesData,
    getEnterpriseSalesData,
    getVehicleProductionData,
    getEnterpriseProductionData,
    getEnterpriseProductionAndSalesDetails,
    getEnterpriseProductionAndSalesSelections,
} from "@/api/yieid.js"
import Chart from "./charts.vue"
import list from "./list.vue"
import SearchSelect from '@/components/searchSelect'
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
export default {
    components: {
        list,
        Chart,
        SearchSelect
    },
    props: {},
    data() {
        return {
            operate: true,
            listloading: false,
            typefirstLabel: '',
            modelfirstLabel: '',
            enterfirstLabel: '',
            firmList: {},
            cartypeList: {},
            showP: false,
            show: false,
            tableHead: [],
            tableData: [],
            enterpriseList: [],
            vehicleTypeList: [],
            vehicleModelList: [],
            firmValue: '销量',
            pageData: {
                pages: {
                    pageNum: 1,
                    pageSize: 20,
                },
                total: 0,
            },
            form: {
                enterpriseList: [],
                vehicleTypeList: [],
                vehicleModelList: [],
            },
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
        }
    },
    computed: {},
    created() {
        this.permissionSetting()
    },
    watch: {
        form: {
            deep: true,
            handler(newVal, oldVal) {
                if (getUserInfo()) {

                    if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                        let menuName = getUserInfo().menuName;
                        if (!menuName.includes('企业情报')) {
                            this.sqsyLogin();
                            this.insertBuryPoint();
                            return
                        } else {
                            if (getUserInfo().roleKey == 'trialrole') {
                                this.formLog.userId = getUserInfo().userId
                                buryPoint(this.formLog)
                                openSy()
                                return
                            }
                        }
                    }
                } else {
                    this.showLogin();
                    return
                }
                this.pageData.pages.pageNum = 1;
                this.getTableList()
            },
        },
        firmValue: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                logRecords('企业情报', '国内企业产量销量', '3', '', '', '', '', newVal)
            },
        },
    },
    mounted() {
        this.getcartypeData()
        this.getselectData()
        this.getTableList()
    },
    methods: {
        // 数据请求
        firmChange() {
            this.getcartypeData()
        },
        getcartypeData() {
            if (this.firmValue === '产量') {
                getVehicleProductionData().then(res => {
                    this.cartypeList = res.data
                })
                getEnterpriseProductionData().then(res => {
                    this.firmList = res.data
                })
            } else {
                getVehicleSalesData().then(res => {
                    this.cartypeList = res.data
                })
                getEnterpriseSalesData().then(res => {
                    this.firmList = res.data
                })
            }
        },
        getselectData() {
            getEnterpriseProductionAndSalesSelections().then(res => {
                this.enterpriseList = res.data.enterpriseList
                this.vehicleTypeList = res.data.vehicleTypeList
                this.vehicleModelList = res.data.vehicleModelList
            })
        },
        getTableList() {
            this.listloading = true
            let form = {
                ...this.form,
                ...this.pageData.pages
            }
            getEnterpriseProductionAndSalesDetails(form).then(res => {
                this.listloading = false
                this.tableHead = res.data.tableHead
                this.tableData = res.data.tableData
                this.pageData.total = res.data.totalCount
            })
        },
        // 分页功能
        handleSizeChange(val) {
            this.pageData.pages.pageSize = val;
            this.getTableList()
        },
        handleCurrentChange(val) {
            this.pageData.pages.pageNum = val;
            this.getTableList()
        },
        permissionSetting() {
            if (getUserInfo()) {

                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.operate = false
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.operate = false
                        }
                    }
                }
            } else {
                this.operate = false
            }
        },
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
        sqsy(url) {
            const newUrl = this.$router.resolve(url);

            window.open(newUrl.href, "_blank");
        },
    },
}
</script>
<style lang="scss" scoped>
.yieid-contain {
    width: 100%;

    .yidid-top {
        width: 100%;
        background: #fff;
        margin-bottom: 10px;
        padding-bottom: 56px;
        .yeiid-box {
            padding-bottom: 20px;
            display: flex;
            align-items: flex-end;
        }

        .yieidtitle {
            font-size: 20px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            padding: 30px 60px 0 30px;
        }
        .topRadio {
            padding-bottom: 3px;
        }

        .chart {
            display: flex;
            padding: 0 60px 0 30px;

            .title {
                font-size: 16px;
                color: rgb(51, 51, 51);
                font-weight: bold;
            }

            .chartTOP {
                display: flex;
                justify-content: space-between;
            }

            .leftChart {
                width: 50%;
                padding-left: 12px;
                padding-right: 60px;
                border-right: 1px solid #f3f5f7;
            }

            .rightChart {
                width: 50%;
                padding-left: 40px;
                padding-right: 23px;
            }

            .text {
                font-size: 12px;
                padding-left: 15px;
                color: rgb(51, 51, 51);
            }
        }
    }

    .bottom {
        width: 100%;
        background: #fff;

        .list {
            width: 100%;

            .tablelist {
                padding: 0 60px 0 30px;
            }

            .list-sele {
                display: flex;
                border-bottom: 1px solid #f3f5f7;
                padding: 20px 60px 20px 30px;

                .item {
                    padding-right: 30px;
                }

                .el-select {
                    width: 210px;
                    padding-left: 10px;
                }
            }

            .list-title {
                font-size: 16px;
                color: rgb(34, 34, 34);
                font-weight: bold;
                padding: 20px 60px 20px 30px;
            }
        }
    }

    .pages {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        background: #fff;
    }
}

::v-deep {
    .el-radio-button__inner:hover {
        color: #606266;
    }

    .is-active .el-radio-button__inner {
        border-color: #3f76ea;
        background: none;
        color: #3f76ea;
    }

    .is-active :hover {
        color: #3f76ea;
    }
}
</style>