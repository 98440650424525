export function toThousands(num) {
  if (num == null) {
    return '--'
  }
  num = Number(num || 0).toString()
  const arr = num.split('.')
  let integerNum = arr[0]
  const floatNum = arr[1]
  let result = ''
  while (integerNum.length > 3) {
    result = ',' + integerNum.slice(-3) + result
    integerNum = integerNum.slice(0, integerNum.length - 3)
  }
  if (integerNum) {
    result = integerNum + result
  }
  return result + (floatNum ? `.${floatNum}` : '')
}
