<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-08 11:34:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-14 09:15:14
-->
<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" isContentTwo="qyjs" />

    <template slot="right">
      <VcontentItem title="相关推荐" type="list" :list="list" @click="tohref" />
    </template>
  </PageContainer>
</template>
<script>
import {
  getPortInformationInfo, // 获取公告数据
} from "@/api/data_new.js";
export default {
  data() {
    return {
      data: {},
      list: [],
      props: {
        tag: "keywords",
        summary: "introduction",
        publishDate: "updateTime",
      },
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.getInformationInfo(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.getInformationInfo(id);
  },
  methods: {
    getInformationInfo(id) {
      getPortInformationInfo(id).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.list = res.data.recommendEOList;
        }
      });
    },
    //跳转
    tohref(item) {
      if (item.tableName == "tt_special") {
        this.$router.push("/thematicAnalysis/details/" + item.id);
      } else {
        this.$router.push("/cuttingEdgeTechnology/details/" + item.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
