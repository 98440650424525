<template>
  <PageContainer breadcrumb>
    <div class="column-main" style="background:#fff;">
      <div>
        <div style="width: 40%; float: left; padding:0px 0 20px 0;">
          <div class="contName" style="font-size: 16px; color: #333; font-weight: bold;">{{ data.companyName }}</div>
          <div>
            <!-- <div class="contName" style="font-size: 14px; color: #333; display: flex; align-items: center;">
              <img :src="require('@/assets/images/icon1.png')">
              企业信息
            </div> -->
            <div class="list1" style="border-top: 1px solid #eee; padding-top: 10px;">
              <div v-if="data.companyName"><span>公司名称：</span><span class="titles">{{ data.companyName }}</span></div>
              <div v-if="data.companyAddress"><span>地址：</span><span class="titles">{{ data.companyAddress }}</span></div>
              <div v-if="data.phone"><span>电话：</span><span class="titles">{{ data.phone }}</span></div>
              <div v-if="data.establishmentTime"><span>成立时间：</span><span class="titles">{{ data.establishmentTime }}</span></div>
              <div v-if="data.companyLeader"><span>公司负责人：</span><span class="titles">{{ data.companyLeader }}</span></div>
              <div v-if="data.registeredCapital"><span>注册资本：</span><span class="titles">{{ data.registeredCapital }}</span></div>
              <div v-if="data.annualSalesRevenue"><span>年销售额：</span><span class="titles">{{ data.annualSalesRevenue }}</span></div>
              <div v-if="data.employee"><span>员工人数：</span><span class="titles">{{ data.employee }}</span></div>
              <div v-if="data.webUrl"><span>网址：</span><span style="cursor: pointer; color: rgb(63, 118, 234); text-decoration: underline;" @click="hrefs(data.webUrl)">{{ data.webUrl }}</span></div>
              <div v-if="data.certificate"><span>质量认证：</span><span class="titles">{{ data.certificate }}</span></div>
              <div v-if="data.environmentalCertification"><span>环境认证：</span><span class="titles">{{ data.environmentalCertification }}</span></div>
            </div>
          </div>
        </div>
        <div style="width: 60%; float: right;">
          <div ref="mapContainer" id="myMap" style="width: 100%; margin-right: 0; height: calc(100vh - 187px); margin-top: 0px;"></div> 
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script type="text/javascript" src="https://api.map.baidu.com/api?v=2.0&ak=RZZ2gObry82Y2BSzZ1xzMhfm"></script>
<script>
import {
  getfactoryinfo1
} from '@/api/data_new.js'
// import BMap from 'bmap'
export default {
  data() {
    return {
      data: {},
      latitude: '',
      longitude: ''
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.getDetail(id);
    },
  },
  mounted(){
    this.$nextTick(() => {
      this.initMap()
    })
  },
  mounted(){
    this.$nextTick(() => {
      this.latitude = this.$route.query.latitude
      this.longitude = this.$route.query.longitude
      this.initMap()
    })
  },
  created() {
    let id = this.$route.params.id;
    this.getDetail(id);
  },
  methods: {
    initMap() {
      let map = new BMap.Map(this.$refs.mapContainer)
      let point = new BMap.Point(this.longitude, this.latitude); // 设置经纬度  
      // 创建图标实例，设置图标样式  
      let myIcon = new BMap.Icon(require(`@/assets/images/icon04.png`), new BMap.Size(25, 29), {  
        // 图标图片偏移量  
        anchor: new BMap.Size(16, 30),  
        // 图标大小  
        imageSize: new BMap.Size(25, 29)
      }); 
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      map.centerAndZoom(point, 3); // 初始化地图,设置中心点坐标和地图级别
      // 添加标注  
      var marker = new BMap.Marker(point, {icon: myIcon});
      map.addOverlay(marker);
      // 可选：添加标注的点击事件  
      marker.addEventListener("click", function(){  
        map.centerAndZoom(marker.point, 5); // 放大到18级
      })
    },
    hrefs(url){
      window.open( url, '_blank')
    },
    getDetail(id) {
      getfactoryinfo1(id).then(res => {
        if (res.ok){
          this.data = res.data || {}
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.list1{
  div{
    padding: 5px 20px;
    font-size: 12px;
    color: #86909C;
    margin-bottom: 6px;
    display: flex;
  }
  span:nth-child(1){
    display: inline-block;
    width: 90px;
    font-size: 14px;
    flex-shrink: 0;
  }
  .titles{
    color: #1D2129;
    font-size: 14px;
    display: block;
  }
}
.contName{
  padding: 10px 20px;
  img{
    width: 16px;
    margin-right: 3px;
  }
}
</style>
