<template>
  <div class="login">
    <div class="login-inner">
      <img src="@/assets/img/login-logo3.png" alt=""/>
      <div class="login-inner">
        <el-form ref="form" :model="form" :rules="rules" class="login-form">
          <el-form-item prop="email">
            <el-input
                v-model="form.email"
                prefix-icon="el-icon-message"
                placeholder="请输入邮箱"
                autocomplete='on'
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
                v-model="form.code"
                prefix-icon="el-icon-s-claim"
                placeholder="请输入验证码"
                autocomplete='on'
                style="width: 45%"
            ></el-input>
            <el-button type="primary"  style="width: 45%; margin-left: 10%" :loading="loading" @click="showDialog('form'); " :class="{'disabled-style':getCodeBtnDisable}" :disabled="getCodeBtnDisable">{{ codeBtnWord }}</el-button>
          </el-form-item>

          <el-form-item class="text-center">
            <el-button type="primary"  style="width: 100%" :loading="loading" @click="loginStrategyReport()" >进入报告系统</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
        :visible.sync=this.dialogVisible
        title="获取短信验证码"
        width="30%"
        :before-close="handleClose"
    >
      <span style="font-size: 18px">{{ this.dialogText }}</span>
      <el-input v-model="newPhone" prefix-icon="el-icon-mobile" :placeholder=dialogPlaceholder autocomplete='on' style="width: 45%"></el-input>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button @click="newGetMsg(true)">发送至新手机号</el-button>
        <el-button :style="{ display: dialogConfirmBtnVisible }" type="primary" @click="newGetMsg(false)">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getMsg, setEmailAccountInfo, getPhone } from "@/api/strategyReport.js";

export default {
  name: "login",

  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (!/^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/.test(value)) {
        callback(new Error("邮箱格式不正确"));
      } else {
        callback();
      }
    };
    var validateUserPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: "",
        code: "",
      },
      rules: {
        email: [{ validator: validateEmail, trigger: "blur" }],
        phone: [{ validator: validateUserPhone, trigger: "blur" }],
      },
      flag: false,
      loading: false,
      returnCode: "",
      dateList: undefined,
      waitTime: 61, // 获取验证码按钮失效时间
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      dialogVisible: false,
      newPhone: "",
      currentPhone: "",
      dialogText: "",
      dialogConfirmBtnVisible: "initial",
      dialogPlaceholder: "",
      customerId: "",
    };
  },
  methods: {
    handleClose(){
      this.dialogVisible = false;
    },
    showDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getMsg(this.form, 'form')
        }
      })
    },
    getMsg(params,formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getPhone(this.form.email).then((res) => {
            if (res.data.Rcode == "1") {
              this.currentPhone = res.data.phone;
              this.customerId = res.data.customerId;

              this.dialogText = "确认发送验证码至 "+this.currentPhone+" ？";
              this.dialogPlaceholder = "如需更改手机号请填写在此";
              this.dialogVisible = true;
            } else if (res.data.Rcode == "2") {
              this.customerId = res.data.customerId;
              this.dialogText = "请绑定手机号："
              this.dialogPlaceholder = "请填写"
              this.dialogConfirmBtnVisible = "none";
              this.dialogVisible = true;
            } else if (res.data.Rcode == "0") {
              this.$message({
                message: res.data.Rmsg,
                type: 'error',
                center: true
              })
            }
          })

          this.dialogVisible = true;
        }
      })
    },
    newGetMsg(action) {
      if (this.newPhone === "" && action) {
        this.$message({
          message: "请填写新手机号！",
          type: 'error',
          center: true
        })
      } else if ((!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.newPhone)) && action) {
        this.$message({
          message: "新手机号格式不正确！",
          type: 'error',
          center: true
        })
      } else {
        let phone = action? this.newPhone : this.currentPhone;
        getMsg(this.customerId, this.form.email, phone, action).then((res) => {
          if (res.data.Rcode == "1") {
            this.$message({
              message: res.data.Rmsg,
              type: 'success',
              center: true
            })
            this.returnCode = res.data.code;
            this.dateList = res.data.dateList;

            // 因为下面用到了定时器，需要保存this指向
            let that = this
            that.waitTime--
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            let timer = setInterval(function () {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
              }
            }, 1000)
            this.dialogVisible = false;
          } else if (res.data.Rcode == "0") {
            this.$message({
              message: res.data.Rmsg,
              type: 'error',
              center: true
            })
          }
        })
      }
    },
    loginStrategyReport() {
      if (this.form.code == this.returnCode && this.returnCode != "") {
        setEmailAccountInfo(this.dateList);
        this.$router.push("/strategyReport/index");
      } else {
        this.$message({
          message: "验证码不正确！",
          type: 'error',
          center: true
        })
      }
    }
  },
  computed: {
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          let emailReg = /^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
          if (emailReg.test(this.form.email)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {
      }
    },
  }
}
</script>

<style scoped>
img {
  display: block;
  width: 182px;
  height: 80px;
  margin: 80px auto 60px;
}
.login-form {
  width: 360px;
  margin: 0 auto;
}
.el_dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
</style>