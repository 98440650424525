<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-27 13:47:30
-->
<template>
  <el-col>
    <el-card :class="['box-card', isCardContainer ? 'card-container' : '']">
      <div slot="header" class="clearfix">
        <span class="title" :style="`min-width: ${titleWidth};float: left;`">
          <span v-if="cionShow" class="icon" />
          {{ title }}
          <span v-if="subTitle" class="sub-title">{{ subTitle }}</span>
        </span>
        <span class="right-btn-box">
          <span style="float: right;font-size:14px;color:grey">{{ unit }}</span>
          <el-button-group :style="`float: ${btnCenter};margin-left:10px`">
            <el-button
              v-for="(btnItem, index) in btnList"
              :key="index"
              size="mini"
              type="primary"
              :class="`${btnItem.isClick ? 'isClick' : ''}`"
              @click="btnClick(btnItem)"
            >
              {{ btnItem.title }}
            </el-button>
          </el-button-group>
        </span>
        <div style="float: right;">
          <i
            v-if="exportData"
            class="btn-item el-icon-download"
            @click="exportXls"
            >下载数据</i
          >
          <i
            v-if="exportPng"
            class="btn-item el-icon-picture-outline"
            @click="exportSvg"
            >导出图片</i
          >
        </div>
      </div>
      <slot name="body" />
    </el-card>
  </el-col>
</template>

<script>
export default {
  name: "Card",
  props: {
    titleWidth: {
      type: String,
      default: "74%",
    },
    btnCenter: {
      type: String,
      default: "right",
    },
    isCardContainer: {
      type: Boolean,
      default: false,
    },
    // 单位名称
    unit: {
      type: String,
      default: "",
    },
    // 卡片名称
    title: {
      type: String,
      default: "请传入参数title",
    },
    subTitle: {
      type: String,
      default: "",
    },
    // 按钮list
    titlebtn: {
      type: Array,
      default: () => [],
    },
    // 按钮id
    btnId: {
      type: String,
      default: "0",
    },
    // 图标显示
    cionShow: {
      type: Boolean,
      default: false,
    },
    exportId: {
      type: String,
      default: null,
    },
    // 下载图片按钮开关
    exportPng: {
      type: Boolean,
      default: false,
    },
    // 导出按钮开关
    exportData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      btnList: [],
    };
  },
  created() {
    this.btnList = JSON.parse(JSON.stringify(this.titlebtn));
  },
  methods: {
    /**
     * @description: 按钮点击事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 10:07:17
     * @LastEditTime: Do not edit
     */
    btnClick(data) {
      this.btnList = this.btnList.map((item) => {
        return {
          ...item,
          ...{ isClick: data.id === item.id ? 1 : 0 },
        };
      });
      this.$emit("cardBtnchange", { type: this.btnId, data: data });
    },
    /**
     * @description: 下载图片按钮
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:25:40
     * @LastEditTime: Do not edit
     */

    exportSvg() {
      this.$emit("exportSvg", { type: this.btnId, name: this.title });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;

  &.card-container {
    padding: 24px;
    ::v-deep {
      .el-card__header {
        padding: 0;
        margin-bottom: 20px;
        border: none;
      }
    }
  }
  .title {
    display: inline-block;
    // width: 74%;
  }
  /deep/.el-card__header {
    // padding: 20px;
    border: none;
  }
  /deep/.el-card__body {
    padding: 0;
    border: none;
  }
  /deep/.clearfix {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
  }
  /deep/.clearfix,
  /deep/.right-btn-box {
    height: 24px;
    .icon {
      display: inline-block;
      height: 6px;
      width: 6px;
      background-color: #0d57bc;
      border-radius: 50%;
      margin-bottom: 2px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
  .btn-item {
    margin: 0 14px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
  }
  .el-icon-download:before,
  .el-icon-picture-outline:before {
    margin-right: 8px;
    font-size: 16px;
  }
  .right-btn-box {
    /deep/.el-button {
      padding: 2px 11px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      background-color: #fff;
      border: 1px solid #e5e6eb;
      color: #000000;
    }
    .isClick {
      color: #ffffff;
      background-color: #0d57bc;
    }
  }
}

.sub-title {
  color: #86909c;
  font-size: 14px;
  font-weight: 400;
}
</style>
