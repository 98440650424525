import $http from '@/plugins/http.js'


//全文检索
export function searchData(params) {
    return $http.post('/api/search/data', params)
}

//全文检索
export function statistics(params) {
    return $http.post('/api/search/statistics', params)
}

//全文检索查询详情
export function detail(params) {
    return $http.post('/api/search/detail?id='+params)
}

//获取菜单

export function searchtypes() {
    return $http.get('/api/search/user/searchtypes')
}


