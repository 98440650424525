export default {
    methods: {
        //公告状态返回
        ggSate(sate,cxbj) {
            let str = ''
            if(cxbj!='W'){
                str = '撤销'
            }else{
                switch (sate) {
                    case 'G':
                        str = '变更'
                        break;
                    case 'K':
                        str = '扩展'
                        break;
                    case 'W':
                        str = '勘误'
                        break;
                    default:
                        str = '新产品'
                }
            }

            return str
        }
    },
}