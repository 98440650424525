/*
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2023-09-09 17:17:45
 * @LastEditors: 林子健
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/plugins/http.js
 */
'use strict'
import { Message } from 'element-ui'
import { removetUserInfo } from '@/utils/auth.js'
import store from '@/store'
import axios from 'axios'
import qs from 'qs'
import Vlogin from '@/components/login/index.js'
import router from '@/router'
// 上传请求地址
const imgURL = ''
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const service = axios.create({
  baseURL: process.env.baseURL || '',
  timeout: 5 * 60 * 100000, // Timeout
  withCredentials: true // Check cross-site Access-Control
})

// 封装get请求
const get = (url, params = {}) => {
  return service({
    url: url,
    method: 'get',
    params: { ...params, ts: new Date().getTime() }
  })
}
// 封装post请求
const post = (url, data = {}, headers = { 'Content-type': 'application/json' }) => {
  return service({
    url: url,
    method: 'post',
    headers: headers,
    data: JSON.stringify(data)
  })
}
// 删除请求
const del = (url, data = {}) => {
  return service({
    url: url,
    method: 'delete',
    data: JSON.stringify(data)
  })
}
// 封装post下载请求
const DownloadDb = (url, data = {}) => {
  return service({
    url: url,
    method: 'post',
    headers: { 'Content-type': 'application/json' },
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}
const Downloadget = (url, params = {}) => {
  return service({
    url: url,
    method: 'get',
    headers: { 'Content-type': 'application/octet-stream' },
    params: params,
    responseType: 'blob'
  })
}
// 封装de请求
const login = (url, data = {}) => {
  return service({
    url: url,
    method: 'post',
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data)
  })
}

// 上传请求
const upload = (url, data = {}) => {
  service({
    method: 'post',
    url: url,
    headers: {
      'Content-type': 'multipart/form-data'
    },
    data: data
  })
}

const loadCounter = {
  count: 0,
  show: () => {
    loadCounter.count++
    if (loadCounter.count > 0) {
      store.dispatch('loading/loading', true)
    }
  },
  hide: () => {
    loadCounter.count--
    if (loadCounter.count <= 0) {
      store.dispatch('loading/loading', false)
    }
  }
}

let messageIndex = 0
service.interceptors.request.use(
  function(config) {
    // loadCounter.show();
    // Do something before request is sent
    messageIndex = 0
    return config
  },
  function(error) {
    // loadCounter.hide();
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
service.interceptors.response.use(
  function(response) {
    // loadCounter.hide();
    if (response.status == 200) {
      if (response.data) {
        if (!response.data.ok) {
          messageIndex++
          if (response.data.message == '返回登录页') {
            if (messageIndex == 1) {
              Message({
                showClose: true,
                message: '请重新登陆！',
                type: 'error'
              })
              removetUserInfo()
              setTimeout(() => {
                if (process.env.VUE_APP_CHECKLOGIN == 'fuao') {
                  window.location = '#/login'
                } else {
                  sessionStorage.setItem('newUrl', router.currentRoute.fullPath)
                  Vlogin({ flag: true })
                }
              }, 1000)
            }
            return false
          }
          const isBolb = response.data instanceof Blob
          if (!isBolb) {
            Message({
              showClose: true,
              message: response.data.message,
              type: 'error'
            })
            return false
          }
        }
      }
      return Promise.resolve(response.data)
    } else {
      Message({
        showClose: true,
        message: response.data.message,
        type: 'error'
      })
      // loadCounter.hide();
      return Promise.reject(response)
    }
  },
  function(error) {
    Message({
      showClose: true,
      message: error,
      type: 'error'
    })
    // loadCounter.hide();
    return Promise.reject(error)
  }
)

//eslint -disable-next-line
export default {
  DownloadDb,
  Downloadget,
  get,
  post,
  del,
  login,
  upload,
  imgURL,
  axios
}
