<template>
  <div class="capacity-contain">
    <div class="worldMap">
      <div id="chart" ref="chart" class="chart" />
    </div>
    <div class="bottom" ref="bottomElement">
      <div class="list">
        <div class="list-sele">
          <div class="item item-model">
            <span class="checkTitle">国家</span>
            <SearchSelect placeholder="请选择国家" v-model="form.countryList" :options="countryList" size="small" />
            <span class="checkTitles">生产企业</span>
            <!-- <SearchSelect placeholder="请选择生产企业" v-model="form.enterpriseList" :options="enterpriseList" size="small" /> -->
            <el-select  v-model="form.enterpriseList" collapse-tags
                multiple placeholder="请选择生产企业" size="small" class="g-form-input" clearable filterable style="width: 370px;">
                <el-option v-for="item in enterpriseList" :key="item.value" :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
          </div>
          <div class="item">
          </div>
          <div class="itembutton" flex="c">
            <el-button type="primary" size="mini" @click="search()">查询</el-button>
            <span><el-button type="primary" size="mini" @click="reset()">重置</el-button></span>
            <!-- <el-button type="primary" plain @click="exportExcel" v-has-role="'freeusers'" :loading="excelLoading">导出
            </el-button> -->
          </div>
        </div>
        <div class="table-title">
          {{ listtitle }}
        </div>
        <div class="tablelist" v-loading="loading">
          <list @custom-sort-change="handleCustomSortChange" :loading="listloading" :tableHead="tableHead"
            :tableData="tableData" />
        </div>
      </div>
    </div>
    <div class="pages" v-if="operate">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageData.pages.pageNum" :page-sizes="[10, 20, 30, 40, 100]" :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import '@/views/international/common/world' // 引入世界地图
import {
  internationalGetDate, // 全球数据-进、出口金额
} from '@/api/data_new.js'
// import { abroadMapData } from '@/api/yieid.js'
// import {
//   abroadTableData,
//   abroadSelections,
//   abroadExportTableData
// } from "@/api/yieid.js"
import {
    abroadMapData,
    abroadSelections,
    abroadTableData,
} from "@/api/industry.js"
import list from "./list.vue"
import SearchSelect from '@/components/searchSelect'
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
import {
  downloadFile
} from "@/utils/download.js";
export default {
  name: 'worldMap',
  components: {
    list,
    SearchSelect
  },
  data() {
    return {
      input2: '中国',
      activeName: 'second',
      dataShow: true,
      dataObj: {},
      nameMap: {
        Afghanistan: '阿富汗',
        Singapore: '新加坡',
        Angola: '安哥拉',
        Albania: '阿尔巴尼亚',
        'United Arab Emirates': '阿拉伯酋长国',
        Argentina: '阿根廷',
        Armenia: '亚美尼亚',
        'French Southern and Antarctic Lands': '法属南半球和南极领地',
        Australia: '澳大利亚',
        Austria: '奥地利',
        Azerbaijan: '阿塞拜疆',
        Burundi: '布隆迪',
        Belgium: '比利时',
        Benin: '贝宁',
        'Burkina Faso': '布基纳法索',
        Bangladesh: '孟加拉国',
        Bulgaria: '保加利亚',
        'The Bahamas': '巴哈马',
        'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
        Belarus: '白俄罗斯',
        Belize: '伯利兹',
        Bermuda: '百慕大',
        Bolivia: '玻利维亚',
        Brazil: '巴西',
        Brunei: '文莱',
        Bhutan: '不丹',
        Botswana: '博茨瓦纳',
        'Central African Republic': '中非共和国',
        Canada: '加拿大',
        Switzerland: '瑞士',
        Chile: '智利',
        China: '中国',
        'Ivory Coast': '象牙海岸',
        Cameroon: '喀麦隆',
        'Democratic Republic of the Congo': '刚果民主共和国',
        'Republic of the Congo': '刚果共和国',
        Colombia: '哥伦比亚',
        'Costa Rica': '哥斯达黎加',
        Cuba: '古巴',
        'Northern Cyprus': '北塞浦路斯',
        Cyprus: '塞浦路斯',
        'Czech Republic': '捷克共和国',
        Germany: '德国',
        Djibouti: '吉布提',
        Denmark: '丹麦',
        'Dominican Republic': '多明尼加共和国',
        Algeria: '阿尔及利亚',
        Ecuador: '厄瓜多尔',
        Egypt: '埃及',
        Eritrea: '厄立特里亚',
        Spain: '西班牙',
        Estonia: '爱沙尼亚',
        Ethiopia: '埃塞俄比亚',
        Finland: '芬兰',
        Fiji: '斐',
        'Falkland Islands': '福克兰群岛',
        France: '法国',
        Gabon: '加蓬',
        'United Kingdom': '英国',
        Georgia: '格鲁吉亚',
        Ghana: '加纳',
        Guinea: '几内亚',
        Gambia: '冈比亚',
        'Guinea Bissau': '几内亚比绍',
        Greece: '希腊',
        Greenland: '格陵兰',
        Guatemala: '危地马拉',
        'French Guiana': '法属圭亚那',
        Guyana: '圭亚那',
        Honduras: '洪都拉斯',
        Croatia: '克罗地亚',
        Haiti: '海地',
        Hungary: '匈牙利',
        Indonesia: '印度尼西亚',
        India: '印度',
        Ireland: '爱尔兰',
        Iran: '伊朗',
        Iraq: '伊拉克',
        Iceland: '冰岛',
        Israel: '以色列',
        Italy: '意大利',
        Jamaica: '牙买加',
        Jordan: '约旦',
        Japan: '日本',
        Kazakhstan: '哈萨克斯坦',
        Kenya: '肯尼亚',
        Kyrgyzstan: '吉尔吉斯斯坦',
        Cambodia: '柬埔寨',
        Kosovo: '科索沃',
        Kuwait: '科威特',
        Laos: '老挝',
        Lebanon: '黎巴嫩',
        Liberia: '利比里亚',
        Libya: '利比亚',
        'Sri Lanka': '斯里兰卡',
        Lesotho: '莱索托',
        Lithuania: '立陶宛',
        Luxembourg: '卢森堡',
        Latvia: '拉脱维亚',
        Morocco: '摩洛哥',
        Moldova: '摩尔多瓦',
        Madagascar: '马达加斯加',
        Mexico: '墨西哥',
        Macedonia: '马其顿',
        Mali: '马里',
        Myanmar: '缅甸',
        Montenegro: '黑山',
        Mongolia: '蒙古',
        Mozambique: '莫桑比克',
        Mauritania: '毛里塔尼亚',
        Malawi: '马拉维',
        Malaysia: '马来西亚',
        Namibia: '纳米比亚',
        'New Caledonia': '新喀里多尼亚',
        Niger: '尼日尔',
        Nigeria: '尼日利亚',
        Nicaragua: '尼加拉瓜',
        Netherlands: '荷兰',
        Norway: '挪威',
        Nepal: '尼泊尔',
        'New Zealand': '新西兰',
        Oman: '阿曼',
        Pakistan: '巴基斯坦',
        Panama: '巴拿马',
        Peru: '秘鲁',
        Philippines: '菲律宾',
        'Papua New Guinea': '巴布亚新几内亚',
        Poland: '波兰',
        'Puerto Rico': '波多黎各',
        'North Korea': '北朝鲜',
        Portugal: '葡萄牙',
        Paraguay: '巴拉圭',
        Qatar: '卡塔尔',
        Romania: '罗马尼亚',
        Russia: '俄罗斯',
        Rwanda: '卢旺达',
        // "Western Sahara": "西撒哈拉",
        'Saudi Arabia': '沙特阿拉伯',
        Sudan: '苏丹',
        'S. Sudan': '南苏丹',
        Senegal: '塞内加尔',
        'Solomon Islands': '所罗门群岛',
        'Sierra Leone': '塞拉利昂',
        'El Salvador': '萨尔瓦多',
        Somaliland: '索马里兰',
        Somalia: '索马里',
        'Republic of Serbia': '塞尔维亚',
        Suriname: '苏里南',
        Slovakia: '斯洛伐克',
        Slovenia: '斯洛文尼亚',
        Sweden: '瑞典',
        Swaziland: '斯威士兰',
        Syria: '叙利亚',
        Chad: '乍得',
        Togo: '多哥',
        Thailand: '泰国',
        Tajikistan: '塔吉克斯坦',
        Turkmenistan: '土库曼斯坦',
        'East Timor': '东帝汶',
        'Trinidad and Tobago': '特里尼达和多巴哥',
        Tunisia: '突尼斯',
        Turkey: '土耳其',
        'United Republic of Tanzania': '坦桑尼亚',
        Uganda: '乌干达',
        Ukraine: '乌克兰',
        Uruguay: '乌拉圭',
        'United States': '美国',
        Uzbekistan: '乌兹别克斯坦',
        Venezuela: '委内瑞拉',
        Vietnam: '越南',
        Vanuatu: '瓦努阿图',
        'West Bank': '西岸',
        Yemen: '也门',
        'South Africa': '南非',
        Zambia: '赞比亚',
        Korea: '韩国',
        Tanzania: '坦桑尼亚',
        Zimbabwe: '津巴布韦',
        Congo: '刚果',
        'Central African Rep.': '中非',
        Serbia: '塞尔维亚',
        'Bosnia and Herz.': '波黑',
        'Czech Rep.': '捷克',
        'W. Sahara': '西撒哈拉',
        'Lao PDR': '老挝',
        'Dem. Rep. Korea': '朝鲜',
        'Falkland Is.': '福克兰群岛',
        'Timor-Leste': '东帝汶',
        'Solomon Is.': '所罗门群岛',
        Palestine: '巴勒斯坦',
        'N. Cyprus': '北塞浦路斯',
        Aland: '奥兰群岛',
        'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
        Mauritius: '毛里求斯',
        Comoros: '科摩罗',
        'Eq. Guinea': '赤道几内亚',
        'Guinea-Bissau': '几内亚比绍',
        'Dominican Rep.': '多米尼加',
        'Saint Lucia': '圣卢西亚',
        Dominica: '多米尼克',
        'Antigua and Barb.': '安提瓜和巴布达',
        'U.S. Virgin Is.': '美国原始岛屿',
        Montserrat: '蒙塞拉特',
        Grenada: '格林纳达',
        Barbados: '巴巴多斯',
        Samoa: '萨摩亚',
        Bahamas: '巴哈马',
        'Cayman Is.': '开曼群岛',
        'Faeroe Is.': '法罗群岛',
        'IsIe of Man': '马恩岛',
        Malta: '马耳他共和国',
        Jersey: '泽西',
        'Cape Verde': '佛得角共和国',
        'Turks and Caicos Is.': '特克斯和凯科斯群岛',
        'St. Vin. and Gren.': '圣文森特和格林纳丁斯',
        'Dem. Rep. Congo': '刚果民主共和国'
      },
      list: [],
      options: [],
      value: [],
      loading: false,
      states: [],
      empty: {
        globalDataAmountOnlyEchart1: false,
        globalDataAmountOnlyEchart2: false,
        globalDataAmountOnlyEchart3: false,
        globalDataAmountOnlyEchart4: false
      },
      maxNum: 0,
      mapData: [],
      excelLoading: false,
      operate: true,
      listloading: false,
      listtitle: '',
      typefirstLabel: '',
      modelfirstLabel: '',
      enterfirstLabel: '',
      orderType: '',
      typeList: [],
      cityList: [],
      tempcityList: [],
      tableHead: [],
      tableData: [],
      provinceList: [],
      enterpriseList: [],
      countryList: [],
      pageData: {
        pages: {
          pageNum: 1,
          pageSize: 20,
        },
        total: 0,
      },
      form: {
        countryList: [],
        enterpriseList: [],
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      loading: false,
    }
  },
  mounted() {
    this.getabroadMapData()

    this.getTableData()
    this.getSelections()
  },
  created() {
    this.permissionSetting()
  },
  methods: {
    getabroadMapData() {
      abroadMapData({industryType: '海外零部件'}).then((res) => {
        this.maxNum = res.data.maxNum
        this.mapData = res.data.mapData
        this.drawChart()
      })
    },
    drawChart(name) {
      // 基于准备好的dom，初始化echarts实例
      let chart = echarts.init(this.$refs.chart)
      // 监听屏幕变化自动缩放图表
      window.addEventListener('resize', function () {
        chart.resize()
      })
      const data = this.mapData
      const option = {
        backgroundColor: '#fff',
        // 图表主标题
        title: [
          {
            text: '中国零部件企业海外分布概览',
            left: 'left',
          },
          {
            subtext: '单位：个',
            left: 'right',
            subtextStyle: {
              color: 'rgb(34, 34, 34)'
            }
          },
        ],
        tooltip: {
          trigger: 'item',
          backgroundColor: '#fff',
          padding: [10],
          formatter: (params) => {
            if (params.value && this.operate) {
              return `<div style="font-size:14px;color:black">${params.name}：<span style="font-size:20px;">${params.value} </span>个企业</div>
                        <div style="color: #9999;">点击地图区域查看具体名单</div>`
            } else if (this.operate) {
              return `<div style="font-size:14px;color:black">该区域暂无相关内容</div>
                        `
            }
          }
        },
        visualMap: {
          min: 0,
          max: this.maxNum,
          left: '10',
          bottom: '0',
          text: ['高', '低'],
          calculable: true,
          // seriesIndex: [1],
          inRange: {
            color: ["#e0e9f5", "#0069ea", "#233996"]
          },
          show: this.operate
        },
        series: [
          {
            name: 'World Population (2010)',
            type: 'map',
            mapType: 'world',
            // zoom: 1,
            // roam: 'scale', // 支持缩放
            scaleLimit: { min: 1, max: 6 },
            // roam: false,
            // 默认样式
            // itemStyle: {
            //   areaColor: '#e6ebf2', // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
            //   borderWidth: 0.5, // 描边线宽 为 0 时无描边
            //   borderColor: '#FFF', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
            //   borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
            // },
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            // 鼠标移入样式
            emphasis: {
              // 高亮的显示设置
              label: {
                show: true, // 是否显示标签
                padding: [6, 20],
                backgroundColor: 'rgba(255, 255, 255,.8)',
                fontSize: 12,
                color: '#333333',
                borderRadius: 5
              },
              itemStyle: {
                areaColor: '#CFDDF2', // 地图区域的颜色
                borderWidth: 1, // 描边线宽 为 0 时无描边
                borderColor: '#0D57BC', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
                borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
              }
            },
            // 选中样式
            select: {
              // 高亮的显示设置
              label: {
                show: true, // 是否显示标签
                padding: [6, 20],
                backgroundColor: 'rgba(255, 255, 255,.8)',
                fontSize: 12,
                color: '#333333',
                borderRadius: 5
              },
              itemStyle: {
                areaColor: '#0D57BC', // 地图区域的颜色
                borderWidth: 1, // 描边线宽 为 0 时无描边
                borderColor: '#0D57BC', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
                borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
              }
            },
            data: data,
            nameMap: this.nameMap,
            itemStyle: {
              normal: {
                areaColor: '#e6ebf2',
                borderColor: '#fff',
                borderWidth: 1,
              },
            },
          }
        ]
      }
      if (name) {
        option.series[0].data = data.map((e, i) => {
          if (e.name == name) {
            e.selected = true
          } else {
            e.selected = false
          }
          return e
        })
        chart.setOption(option, true)
      } else {
        chart.dispose()
        chart = echarts.init(this.$refs.chart)
        chart.setOption(option)
      }
      chart.on('click', (param) => {
        // if (!getUserInfo()) {
        //   option.series[0].data = data.map((e, i) => {
        //     if (e.name == '中国') {
        //       e.selected = true
        //     } else {
        //       e.selected = false
        //     }
        //     return e
        //   })
        //   chart.setOption(option, true)
        //   this.$Vlogin2({ flag: true })
        //   return
        // }
        // // 获取自定义变量barIds的值,barIds要和option的series里自定义的一样
        // this.input2 = param.data.name
        this.inputchange(param)
      })
    },
    getSelections() {
      abroadSelections({industryType:'海外零部件'}).then(res => {
        this.countryList = res.data.countryList
        this.enterpriseList = res.data.enterpriseList
      })
    },
    getTableData() {
      this.listloading = true
      let form = {
        ...this.form,
        ...this.pageData.pages,
        orderType: this.orderType,
        industryType: "海外零部件"
      }
      abroadTableData(form).then(res => {
        this.tableHead = res.data.tableHead
        this.tableData = res.data.tableData
        this.listtitle = res.data.tableInfoStr
        this.pageData.total = res.data.totalCount
        this.listloading = false
      })
    },
    handleCustomSortChange(orderType) {
      this.orderType = orderType
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNum = val;
      this.getTableData();
    },
    inputchange(params) {
      if (!this.operate) {
        return
      }
      if (!isNaN(params.value)) {
        const offsetTop = this.$refs.bottomElement.offsetTop;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
        this.form.countryList = []
        this.form.countryList.push(params.name)
        this.search()
      } else {
        this.$message({
          message: '该区域暂无企业分布数据',
          type: 'error',
          center: true
        })
      }
    },
    permissionSetting() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('企业情报')) {
            this.operate = false
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.operate = false
            }
          }
        }
      } else {
        this.operate = false
      }
    },
    search() {
      this.loading = true;
      if (getUserInfo()) {
        logRecords('企业情报', '中国车企海外产能', '4', 'tt_enterprise_capacity', '', '', '', '查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin();
            this.insertBuryPoint();
            this.loading = false;
            return
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.formLog.userId = getUserInfo().userId
              buryPoint(this.formLog)
              openSy()
              this.loading = false;
              return
            } else {
              this.loading = false;
            }
          }
        }
      } else {
        this.showLogin();
        this.loading = false;
        return
      }

      this.pageData.pages.pageNum = 1
      this.getTableData();
      this.loading = false;
    },
    reset() {
      this.pageData.pages = {
        pageNum: 1,
        pageSize: 20,
      }
      this.form = {
        enterpriseList: [],
        countryList: [],
      }
      this.getTableData();
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
      this.loading = false;
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
      this.loading = false;
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    sqsy(url) {
      const newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");
    },
    exportExcel() {
      this.excelLoading = true
      let form = {
        ...this.form,
        ...this.pageData.pages,
        orderType: this.orderType
      }
      let self = this;
      abroadExportTableData(form).then((res) => {
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            console.log(msg);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "产能分布详情.xlsx");
          }
        });
      });
      this.excelLoading = false


    },
  }
}
</script>

<style lang="scss" scoped>
.worldMap {
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  margin-top: 20px;
}

.chart {
  width: 100%;
  margin: 0 auto;
  // height: 570px;
  height: 570px;
  border: 1px solid #eeeeee;
  /* background: url(../../public/static/bg.png) no-repeat; 背景图设置*/
  background-color: #fff;
  background-size: 100% 100%;
  padding: 20px 70px 20px 40px;
}
.capacity-contain {
  width: 100%;

  .bottom {
    width: 100%;
    background: #fff;

    .list {
      width: 100%;
      padding: 0 60px 10px 40px;

      .list-sele {
        display: flex;
        flex-wrap: wrap;
      }

      .item {
        padding-right: 30px;
        padding-top: 15px;
      }

      .itembutton {
        padding-top: 15px;

        button {
          width: 120px;
        }

        span button {
          background: #fff;
          border: 0;
          font-size: 12px;
          color: rgb(153, 153, 153);
        }
      }

      .el-select {
        width: 210px;
        padding-left: 10px;
      }

      .table-title {
        font-size: 14px;
        color: rgb(34, 34, 34);
        font-weight: bold;
        padding: 30px 0 20px 0;
      }
    }

    .list-title {
      font-size: 18px;
      color: rgb(34, 34, 34);
      font-weight: bold;
      padding: 24px 60px 0 30px;
    }
  }

  .pages {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background: #fff;
  }
}
.checkTitles{
  margin-left: 25px;
}
</style>
