<template>
  <div class="not-logged-in-mask">
    <div>
      <span>【提示】数据内容需要会员<span class="link" @click="showLogin">登录</span>后查看</span>
    </div>
    <div class="line2">
      没有账号？可<span class="link" @click="applyForTrial(1)">申请试用</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotLoggedInMask',
  methods: {
    // 申请试用
    applyForTrial(flag) {
      const newUrl = this.$router.resolve(`/sqSy?flag=${flag}`)
      window.open(newUrl.href, '_blank')
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin({ flag: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.not-logged-in-mask {
  position: absolute;
  width:300px;
  height:94px;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 100;
  background-color: #e0f9f9;
  box-shadow: 2px 2px 10px #d3cece;

  .link {
    color: #0D57BC;
    cursor: pointer;
  }

  .line2 {
    margin-top: 10px;
  }
}
</style>

