<!--
 * @Author: your name
 * @Date: 2021-07-21 13:13:06
 * @LastEditTime: 2021-07-21 17:11:36
 * @LastEditors: Please set LastEditors
 * @Description: 宏观环境页面
 * @FilePath: /information-vue/code/src/views/data/macroEnvironment/index.vue
-->

<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />-->
    <el-tabs class="search-form n-tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="国民经济" name="国民经济">
        <el-radio-group v-model="radio" size="small" @change="radioChange()">
          <el-radio label="/iframes/fzhj_gmjj_gdp.html" border>生产总值(GDP)</el-radio>
          <el-radio label="/iframes/fzhj_gmjj_gdzctz.html" border>工业增加值</el-radio>
          <el-radio label="/iframes/fzhj_gmjj_shxfplsze.html" border>社会消费品零售总额</el-radio>
          <el-radio label="/iframes/fzhj_gmjj_cci.html" border>消费者信心指数(CCI)</el-radio>
          <el-radio label="/iframes/fzhj_gmjj_jckze.html" border>进出口总额</el-radio>
          <el-radio label="/iframes/fzhj_gmjj_fdi.html" border>外商直接投资(FDI)</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="采购经理指数" name="采购经理指数">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_cgjlzs_zzy.html" border>制造业采购经理指数</el-radio>
          <el-radio label="/iframes/fzhj_cgjlzs_fzzy.html" border>非制造业经理采购指数</el-radio>
          <el-radio label="/iframes/fzhj_cgjlzs_pmi.html" border>综合PMI产出指数</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="价格" name="价格">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_jg_cpi.html" border>居民消费价格指数(CPI)</el-radio>
          <el-radio label="/iframes/fzhj_jg_splsjg.html" border>商品零售价格指数</el-radio>
          <el-radio label="/iframes/fzhj_jg_ppi.html" border>工业生产者出厂价格指数(PPI)</el-radio>
          <el-radio label="/iframes/fzhj_jg_gysczgjjj.html" border>工业生产者购进价格指数</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="金融" name="金融">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_jr_hbgyl.html" border>货币供应量</el-radio>
          <el-radio label="/iframes/fzhj_jr_cdkjzll.html" border>存贷款基准利率</el-radio>
          <el-radio label="/iframes/fzhj_jr_rmbhl.html" border>人民币汇率</el-radio>
          <el-radio label="/iframes/fzhj_jr_rmbdk.html" border>人民币贷款</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="人口" name="人口">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_rk_axbfrks.html" border>按性别分人口数</el-radio>
          <el-radio label="/iframes/fzhj_rk_anlfrks.html" border>按年龄分人口数</el-radio>
          <el-radio label="/iframes/fzhj_rk_acxfrks.html" border>按城乡分人口数</el-radio>
          <el-radio label="/iframes/fzhj_rk_rkcydc.html" border>人口抽样调查样本数据</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="人民生活" name="人民生活">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_rmsh_rjgzpsr.html" border>城乡居民人均可支配收入</el-radio>
          <el-radio label="/iframes/fzhj_rmsh_rjxfzc.html" border>城乡居民人均消费支出</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="交通运输" name="交通运输">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_jtys_lpi.html" border>物流景气指数(LPI)</el-radio>
          <el-radio label="/iframes/fzhj_jtys_glyslc.html" border>公路运输里程</el-radio>
          <el-radio label="/iframes/fzhj_jtys_hwysl.html" border>货物运输量</el-radio>
          <el-radio label="/iframes/fzhj_jtys_hwzzl.html" border>货物周转量</el-radio>
          <el-radio label="/iframes/fzhj_jtys_lkysl.html" border>旅客运输量</el-radio>
          <el-radio label="/iframes/fzhj_jtys_lkzzl.html" border>旅客周转量</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <el-tab-pane label="能源" name="能源">
        <el-radio-group v-model="radio" size="small">
          <el-radio label="/iframes/fzhj_ny_ymcl.html" border>原煤产量</el-radio>
          <el-radio label="/iframes/fzhj_ny_yycl.html" border>原油产量</el-radio>
          <el-radio label="/iframes/fzhj_ny_trqcl.html" border>天然气产量</el-radio>
          <el-radio label="/iframes/fzhj_ny_fdlhydl.html" border>发电量和用电量</el-radio>
        </el-radio-group>
      </el-tab-pane>
    </el-tabs>
    <div class="main iframe-box" v-if="show && !showP">
      <iframe :src="radio" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div v-else style="position: relative; ">
      <img style="width:100%" src="@/assets/img/hghjb.jpg" alt=""/>
      <center>
        <div
            style="position:relative;width:300px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";

import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      radio: "/iframes/fzhj_gmjj_gdp.html",
      show: false,
      checkList: [],
      activeName: "国民经济",
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },

      ],
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }
      }
    } else {
      this.show = false;
    }
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    radioChange(){
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          }
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    handleClick(tab) {
      if (getUserInfo()) {

        logRecords('数据','宏观数据','3','','','','','','筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {

            if(tab.name!='国民经济'){
              this.sqsyLogin();
              this.insertBuryPoint();
            }
          }
        }
      } else {
        if(tab.name!='国民经济'){
          this.showLogin();
        }
      }

      switch (tab.name) {
        case "国民经济":
          this.radio = "/iframes/fzhj_gmjj_gdp.html";
          break;
        case "采购经理指数":
          this.radio = "/iframes/fzhj_cgjlzs_zzy.html";
          break;
        case "价格":
          this.radio = "/iframes/fzhj_jg_cpi.html";
          break;
        case "金融":
          this.radio = "/iframes/fzhj_jr_hbgyl.html";
          break;
        case "人口":
          this.radio = "/iframes/fzhj_rk_axbfrks.html";
          break;
        case "人民生活":
          this.radio = "/iframes/fzhj_rmsh_rjgzpsr.html";
          break;
        case "交通运输":
          this.radio = "/iframes/fzhj_jtys_lpi.html";
          break;
        case "能源":
          this.radio = "/iframes/fzhj_ny_ymcl.html";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-layer-box {
  display: flex;
  flex-direction: column;

  .iframe-box {
    flex: 1;
    background: #fff;
  }
}

::v-deep .el-date-editor {
  width: 100%;
}

.table-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

.el-dropdown-link {
  cursor: pointer;
  color: #0d57bc;
}

.el-icon-arrow-down {
  font-size: 12px;
}

[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

.search-form {
  background: #fff;
  margin-bottom: 10px;

  /deep/ .el-tab-pane {
    padding: 0 24px 24px;
  }
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      width: 85px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 185px;

      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
