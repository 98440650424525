<template>
    <PageContainer breadcrumb footer>
        <Vcontent :data="data" :props="props" :isCollection="false" />
        <template slot="right">
            <VcontentItems/>
        </template>
    </PageContainer>
</template>
<script>
import { ttIndustryInformation } from "@/api/Index.js";
import VcontentItems from './VcontentItem.vue'
export default {
    components: {
        VcontentItems
    },
    data() {
        return {
            data: {},
            props: {
                tag: "keywordsLabel",
                htmlContent: "content",
                summary: "introduction",
                publishDate: 'updateTime'
            },
            relevant: [],
        };
    },
    watch: {
      $route(to, from) {
        let id = this.$route.params.id;
        this.ttIndustryInformation(id);
        // 对路由变化作出响应...
      },
    },
    created() {
        let id = this.$route.params.id;
        this.ttIndustryInformation(id);
    },
    methods: {
        ttIndustryInformation(params) {
            ttIndustryInformation(params).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                    this.relevant = res.data.list;
                }
            });
        },
        tohref(item) {
            //相关推荐
            this.$router.push({
                path: "/workInformation/enterpriseInfo/entrepriseNews/enterDetail/"+ item.id,
                query: {
                    releaseColumn: item.releaseColumn,
                    category: "industry",
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped></style>
  