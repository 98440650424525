<template>
  <div
    class="banner-box"
    ref="bannerBox"
    @mouseenter="clearAutoPlay()"
    @mouseleave="autoPlay()"
  >
    <ul
      class="banner-silde"
      :style="'transform: translateX(' + translateX + 'px);'"
    >
      <li v-for="item in banner" :key="item.title">
        <a :href="item.urlLink" target="_blank">
          <img :src="item.imgUrl" :alt="item.imgName" />
          <h1>{{ item.title }}</h1>
        </a>
      </li>
    </ul>
    <div class="banner-btn">
      <span
        v-for="(item, index) in banner"
        :key="index"
        :class="{ ac: index == indexPaly }"
        @mouseover="bannerBtn(index)"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    banner: [],
    autoTime: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      indexPaly: 0,
      translateX: 0,
      pWidth: 0,
      playTime: null,
    };
  },
  watch:{
    banner(){
        this.autoPlay()
    }
  },
  mounted() {
    this.autoPlay();
  },
  methods: {
    bannerBtn(index) {
      this.indexPaly = index;
      this.translateX = -this.pWidth * index;
    },
    autoPlay() {
      this.pWidth = this.$refs.bannerBox.clientWidth;
      if (this.banner && this.banner.length > 0) {
        let index = 0;
        this.playTime = setInterval(() => {
          index++;
          if (index >= this.banner.length) {
            index = 0;
          }
          this.bannerBtn(index);
        }, this.autoTime);
      }
    },
    clearAutoPlay() {
      clearInterval(this.playTime);
    },
  },
  destroyed() {
    this.clearAutoPlay();
  },
};
</script>
<style lang="scss" scoped>
$heightH: 100%;
$widthW: 100%;
.banner-box {
  position: relative;
  width: $widthW;
  height: $heightH;
  background: #000;
  border-radius: 4px;
  overflow: hidden;
  .banner-silde {
    list-style: none;
    width: $widthW;
    height: $heightH;
    margin: 0;
    padding: 0;
    display: flex;
    transition: all 0.5s;
    li {
      position: relative;
      width: $widthW;
      height: $heightH;
      flex: 0 0 100%;
      img {
        width: $widthW;
        height: $heightH;
      }
      h1 {
        position: absolute;
        bottom: 43px;
        left: 20px;
        font-size: 20px;
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
  .banner-btn {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    span {
      display: block;
      width: 24px;
      height: 2px;
      margin-right: 4px;
      background: #fff;
      opacity: 0.4;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.ac {
         opacity: 0.8;
      }
    }
  }
}
</style>