<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-26 10:29:43
-->
<template>
  <div class="top" :style="`width:100%;height:${height}px`">
    <div
      v-for="(item, index) in data"
      :key="index"
      :style="
        `height: ${index === data.length - 1 ? 'unset' : topItemHeight + 'px'}`
      "
      :class="`top-item ${roteShow ? '' : 'top-item-rote-not-show'}`"
    >
      <div class="top-item-title">
        <div class="title">
          <div class="icon" />
          {{ item.name || "--" }}
        </div>
        <div class="value">
          {{ toThousands(item.value) }}
          <span style="margin-left:0px">{{ dataUnit }}</span>
        </div>
      </div>
      <div v-if="roteShow" class="top-item-info">
        <el-progress
          class="progress"
          color="#3491FA"
          :show-text="false"
          :percentage="item.rote"
        />
        <div class="proportion">
          <div class="yoy">
            同比
            <template v-if="parseFloat(item.YoY)">
              <span :class="` ${item.YoY >= 0 ? 'icon-up' : 'icon-down'}`" />
              <span :class="` ${item.YoY >= 0 ? 'text-up' : 'text-down'}`">{{
                Math.abs(item.YoY) || "--"
              }}%</span>
            </template>
            <span v-else>--</span>
          </div>
          <div class="qoq">
            环比
            <template v-if="parseFloat(item.QoQ)">
              <span :class="`${item.QoQ >= 0 ? 'icon-up' : 'icon-down'}`" />
              <span :class="` ${item.QoQ >= 0 ? 'text-up' : 'text-down'}`">{{
                Math.abs(item.QoQ) || "--"
              }}%</span>
            </template>
            <span v-else>--</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toThousands } from "@/utils/number";

// 引入dom监听器
export default {
  name: "Top",
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
    // 是否展示比例
    roteShow: {
      type: Boolean,
      default: false,
    },
    // 展示数量
    topNum: {
      type: Number,
      default: 10,
    },
    // 每一条数据的高度
    topItemHeight: {
      type: Number,
      default: 85,
    },
    dataUnit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      data: [],
      toThousands,
    };
  },
  mounted() {},
  methods: {
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.data = data.slice(0, this.topNum);
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .top-item {
    width: 100%;
    .top-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      .title {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        .icon {
          display: inline-block;
          height: 24px;
          width: 24px;
          margin-right: 12px;
        }
      }
      .value {
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: bold;
        color: #0d57bc;
      }
    }
    .top-item-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progress {
        width: 60%;
        margin-right: 24px;
      }
      .proportion {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .qoq {
          margin-left: 10px;
        }
        .yoy,
        .qoq {
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #86909c;
          line-height: 16px;
          .icon-up {
            display: inline-block;
            height: 12px;
            width: 10px;
            background: url(~@/assets/img/overview/up.png) no-repeat;
            background-size: 100% 100%;
          }
          .icon-down {
            display: inline-block;
            height: 12px;
            width: 10px;
            background: url(~@/assets/img/overview/down.png) no-repeat;
            background-size: 100% 100%;
          }
          .text-up {
            margin-left: 4px;
            color: #f53f3f;
          }
          .text-down {
            margin-left: 4px;
            color: #00b42a;
          }
        }
      }
    }
  }
  .top-item-rote-not-show {
    // flex: 1;
    border-bottom: 1px solid #f0f0f0;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .top-item:nth-child(1) .icon {
    background: url(~@/assets/img/overview/top-1.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(2) .icon {
    background: url(~@/assets/img/overview/top-2.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(3) .icon {
    background: url(~@/assets/img/overview/top-3.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(4) .icon {
    background: url(~@/assets/img/overview/top-4.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(5) .icon {
    background: url(~@/assets/img/overview/top-5.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(6) .icon {
    background: url(~@/assets/img/overview/top-6.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(7) .icon {
    background: url(~@/assets/img/overview/top-7.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(8) .icon {
    background: url(~@/assets/img/overview/top-8.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(9) .icon {
    background: url(~@/assets/img/overview/top-9.png) no-repeat;
    background-size: 100% 100%;
  }
  .top-item:nth-child(10) .icon {
    background: url(~@/assets/img/overview/top-10.png) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
