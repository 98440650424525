<!--
 * @Author: your name
 * @Date: 2021-07-21 13:11:42
 * @LastEditTime: 2021-07-21 14:59:47
 * @LastEditors: Please set LastEditors
 * @Description: 国外销量数据
 * @FilePath: /information-vue/code/src/views/data/foreignSalesData/index.vue
-->

<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />-->
    <template>
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">汽车分地区产量</span>
            <span class="time">已更新至{{ upDataDate }}</span>
          </div>
        </div>
      </Vtitle>
      <el-form :model="form" ref="form" inline label-width="90px" class="n-data-content">

        <el-row>
          <el-col :span="8">
            <el-form-item label="时间">
              <VdateEndStart v-model="times" :close="false" :isTimeSwitch="false" valueFormat='yyyyMM'
                             style="width:180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="省份：" prop="province">
              <el-input size="small" placeholder="请输入省份" v-model="form.province" style="width:180px"/>
            </el-form-item>
          </el-col>

        </el-row>
        <div class="text-center" style="margin-top: 28px;">
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </div>

      </el-form>

      <div style="margin-top:10px">
        <div class="search-form">
          <div flex="sb" style="margin-bottom: 16px">
            <span class="table_title"></span>
            <div>
              <!-- <el-button type="primary" plain @click="ExportAll"  :loading="excelAllLoading">导出全部Excel</el-button> -->
              <el-button type="primary" plain v-has-role="'freeusers'" @click="Export" :loading="excelLoading">导出
              </el-button>
            </div>
          </div>
          <div v-if="show && !showP">
            <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" ref="tableRef" v-loading="loading" id="tal"
                      stripe
                      border :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
              <el-table-column v-for="item in headerDate" :label="getLabel(item)" :prop="item"
                               :key="item"></el-table-column>
            </el-table>
            <div>单位：万辆</div>
            <div>数据来源：国家统计局</div>
            <div class="text-right">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                             :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                             layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
          <div v-else style="position: relative; ">
            <img style="width:100%" src="@/assets/img/fdqclb.jpg" alt=""/>
            <center>
              <div
                  style="position:relative;width:300px;height:94px;bottom:400px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
                  v-if="!show">

                <div style="padding-top: 20px">
                  <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
                </div>

                <div style="margin: 10px">
                  没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

                </div>
              </div>
              <div
                  style="position:relative;width:320px;height:94px;bottom:400px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                  v-else>
                <div style="padding-top: 20px;padding-left: 22px;">
                  <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
                </div>
                <div style="padding: 10px 20px;text-align: center">
                  <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
                </div>
              </div>
            </center>
          </div>
        </div>

      </div>

    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {
  getProductionRegionTable,
  getProductionRegionDefaultDate,
  ttAbroadSaleDataDynamicExport,
  ttAbroadSaleDataExportAll,
} from "@/api/data.js";
import {downloadFile} from "@/utils/download.js";
import VdateEndStart from "@/components/dateEndStart";
import pinyin from "js-pinyin";
import Vtitle from "../components/title.vue";
import {buryPoint} from "@/api/common";
import * as XLSX from "xlsx";
import FileSaver from 'file-saver'
export default {
  components: {
    // VnotLogin,
    VdateEndStart,
    Vtitle,
  },
  data() {
    return {
      headerDate: [],
      hoverCss: false,
      show: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],

      form: {
        //省份
        province: "",
        startDate: "",
        endDate: "",
      },
      yearList: [],
      countryList: [],
      ppaiList: [],
      czhongList: [],
      jbieList: [],
      cxingList: [],
      swatchDateStr: "按月",
      swatchDateType: "month",
      valueFormat: "yyyy-MM",
      //分页
      pageData: {
        page: 1,
        pageSize: 40,
        total: 0,
      },
      headerNumber: 0,
      tableLablebefore: [],
      loading: false,
      tableDate: "month",
      times: [],
      excelLoading: false,
      excelAllLoading: false,
      upDataDate: "",
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }
      }
      // this.getCountryList();
      // this.getPpaiList();
      this.getProductionRegionDefaultDate();
      this.init();

    } else {
      this.show = false;
    }
  },
  mounted() {
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    getProductionRegionDefaultDate() {
      getProductionRegionDefaultDate().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.upDataDate = res.data;
          this.init();
        }
      });
    },
    init() {
      // let form = Object.assign({}, this.form, this.pageData);
      // for (let key in form) {
      //     if (typeof form[key] == "boolean") {
      //         if (!form[key]) {
      //             delete form[key];
      //         } else {
      //             form[key] = "1";
      //         }
      //     }
      // }

      this.loading = true;
      this.tableDate = this.swatchDateType;
      this.form.startDate = this.times[0];
      this.form.endDate = this.times[1];

      getProductionRegionTable({
        ...this.form,
        ...this.pageData,
      }).then((res) => {
        if (res.ok) {
          this.tableData = res.data.tableData.list;
          this.headerDate = res.data.tableFiles;
          this.pageData.total = res.data.tableData.count;
          // this.lineTableData();
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    all(type) {
      this.$set(this.form, type, [""]);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //查询
    search() {

      if (getUserInfo()) {

        logRecords('数据','分地区产量','3','production_region','','','','','时间筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.page = 1;
            this.init();
          }
        } else {
          this.pageData.page = 1;
          this.init();
        }
      } else {
        this.showLogin();
      }
    },
    //重置
    resetForm() {
      this.$refs.form.resetFields();
      this.form = {
        //省份
        province: "",
        startDate: "",
        endDate: "",
      },

          //分页
          this.pageData = {
            page: 1,
            pageSize: 10,
            total: 0,
          };
      this.times = [];
      this.getProductionRegionDefaultDate();

      this.init();
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.init();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.init();
    },


    getLabel(val) {
      switch (val) {

        case "province":
          return "省份";
        default:
          return val;
      }
    },
    //导出
    Export() {
      if (getUserInfo()) {

        logRecords('数据','分地区产量','3','production_region','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.sqsyLogin();
              this.insertBuryPoint();
            } else {
              /* generate workbook object from table */
              var xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
              var table = document.querySelector('#tal').cloneNode(true)
              var wb = XLSX.utils.table_to_book(table, xlsxParam)
              /* get binary string as output */
              var wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
              try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '分地区产量.xlsx')
              } catch (e) {
                if (typeof console !== 'undefined') {
                  console.log(e, wbout)
                }
              }
              return wbout
            }
          }
        } else {
          /* generate workbook object from table */
           xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
           table = document.querySelector('#tal').cloneNode(true)
           wb = XLSX.utils.table_to_book(table, xlsxParam)
          /* get binary string as output */
           wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
          try {
            FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '分地区产量.xlsx')
          } catch (e) {
            if (typeof console !== 'undefined') {
              console.log(e, wbout)
            }
          }
          return wbout
        }
      } else {
        this.showLogin();
      }

    },
    ExportAll() {
      this.form.startDate = this.times[0];
      this.form.endDate = this.times[1];
      let _self = this;
      this.excelAllLoading = true;
      ttAbroadSaleDataExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "世界汽车全部数据.xls");
          }
        });
      });
    },


  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }
  }
}

.date-range-box {
  display: flex;
  align-items: center;

  .el-dropdown {
    margin-bottom: 20px;
  }
}

.newDateRange {
  display: inline-flex;
  align-items: center;
  width: 210px;

  .line-width {
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 20px;
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  /deep/ {
    .el-input__icon.el-icon-date {
      display: none;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 5px;
    }

    .el-input--suffix .el-input__inner {
      padding-right: 16px;
    }

    .el-input__suffix {
      right: -3px;
    }
  }
}

/deep/ .checkDisCss .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #0d57bc;
}

/deep/
.checkDisCss
.el-checkbox__input.is-disabled.is-checked
.el-checkbox__inner {
  background-color: #0d57bc;
  border-color: #0d57bc;
}

.textInput {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
  padding-right: 12px;
  height: 32px;
  line-height: 32px;
  width: 90px;
  margin-bottom: 20px;
}

.mulSelectCss {
  cursor: default;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 179px;
  border: 1px solid #dcdfe6;
  height: 33px;
  line-height: 32px;
  margin-bottom: 20px;
  border-radius: 4px;
  z-index: 10;
  /*border-radius:4px;*/
}

/*下拉多选*/
.mulSelectCss .iconArrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #dcdfe6;
}

.visCss.mulSelectCss:hover {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 4px 4px 0 0;
  z-index: 1000;
}

.mulSelectCss .chooseText {
  padding-left: 12px;
  font-size: 13px;
  color: #ccc;
}

.hoverCss {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 4px 4px 0 0;
  z-index: 1000;
}

.disCss.mulSelectCss {
  background-color: #f5f7fa;
  cursor: not-allowed;
}

.smallBox .visCss.mulSelectCss:hover + .checkSelect {
  display: block;
}

.smallBox .checkSelect.visCssVis:hover {
  display: block;
}

.smallBox .checkSelect.visCssVis:hover .visCss {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff !important;
  z-index: 1000;
}

.smallBox .checkSelect {
  border-radius: 4px;
  position: absolute;
  display: none;
  left: 0px;
  padding: 10px 20px;
  top: 32px;
  width: 100%;
  min-height: 40px;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #0d57bc;
  z-index: 100;
  background-color: #fff;
}

.elCardOne,
.el-message {
  overflow: visible;
}

/deep/ .checkSelect .el-checkbox {
  /*width: 11%;*/
  font-weight: 400;
}

.timeSelect {
  width: 179px;
  margin-right: 5px;
}

.chooseTextVal {
  /*display: inline-block;*/
  /*height: 32px;*/
  /*line-height: 32px;*/
  margin-top: -2px;
}

.chooseBox {
  margin-left: 3px;
  margin-right: 3px;
  /*max-width: 130px;*/
  /*overflow: hidden;*/
  /*text-overflow:ellipsis;*/
  /*white-space: nowrap;*/
}

/deep/ .chooseTextVal .el-tag--small {
  height: 22px;
  line-height: 20px;
}

/deep/ .chooseTextVal .el-tag.el-tag--info .el-tag__close {
  margin-top: 2px;
}

.letters-box {
  .el-button {
    padding: 0;

    &.act {
      color: #000;
    }
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
