<template>
  <PageContainer breadcrumb footer>
     <Vcontent :data="data" :props="props" />
  </PageContainer>
</template>
<script>
import { getPolicyContentDetail } from "@/api/policy.js";
export default {
  data() {
    return {
      data: {},
       props:{
        tag:'applicableProducts',
        htmlContent:'contentText',
        summary:'summary',
        publishDate:'publishTime'
      }
    };
  },
  created() {
    let policyContentId = this.$route.params.id;
    this.getPolicyContentDetail({ policyContentId });
  },
  methods: {
    getPolicyContentDetail(params) {
      getPolicyContentDetail(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
