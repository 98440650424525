/*
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2021-07-20 14:58:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/main.js
 */
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import App from './App.vue'
import store from './store'
import router from './router'
import './permission'
import permission from './directive/permission'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import './assets/css/style.css'
import './plugins/element.js'
import './plugins/video.js'
// 字体图标
// http://www.fontawesome.com.cn/地址
import 'font-awesome/css/font-awesome.css'
import More from './components/more/index.js'
import ItemTitle from './components/itemTitle/index.js'
import ItemList from './components/itemList/index.js'
import Vlogin from './components/login/index.js'
import Vlogin2 from './components/login/index2.js'
import Vlogin3 from './components/login/index3.js'
import Vlogin4 from './components/login/index4xcx.js'
import Vlogin5 from './components/login/index5xcx.js'
import DataVersionDialog from './components/newData/dataVersionDialog/index.js'

import Vcontent from './components/content/index.js'
import VcontentItem from './components/contentItem/index.js'
import listHead from './components/listHead/index.js'
import newlistHead from './components/newListHead/index'
import PageContainer from './layout/PageContainer'
import './mixin/global.js'
// 引入图表工具  以及组织架构图标工具
import * as G2 from '@antv/g2'
import * as X6 from '@antv/x6'
// 引入动画库
import animated from 'animate.css'
// 引入新人引导css库
import 'driver.js/dist/driver.css'
import './styles/newDataGlobal.scss'


// 引入图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

const fpPromise = FingerprintJS.load()

;(async() => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise
  const result = await fp.get()
  // console.log(result.visitorId, 'result')
  store.dispatch('common/SET_browserId', result.visitorId)
})()
const bus = new Vue()
Vue.use(VueScrollTo)
Vue.use(More)
Vue.use(ItemTitle)
Vue.use(ItemList)
Vue.use(Vcontent)
Vue.use(VcontentItem)
Vue.use(listHead)
Vue.use(newlistHead)
Vue.use(permission)
Vue.use(Vlogin)
Vue.use(Vlogin2)
Vue.use(Vlogin3)
Vue.use(Vlogin4)
Vue.use(Vlogin5)
Vue.use(DataVersionDialog)
Vue.use(animated)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999 // 设置图片预览组件的层级，确保能在其他组件之上
  }
})
Vue.component(PageContainer.name, PageContainer)
Vue.prototype.G2 = G2
Vue.prototype.X6 = X6
Vue.prototype.$Vlogin = Vlogin
Vue.prototype.$Vlogin2 = Vlogin2
Vue.prototype.$Vlogin3 = Vlogin3
Vue.prototype.$Vlogin4 = Vlogin4
Vue.prototype.$Vlogin5 = Vlogin5
Vue.prototype.$DataVersionDialog = DataVersionDialog
Vue.prototype.$bus = bus
Vue.config.productionTip = false


new Vue({
  store,
  router,
  beforeCreate() {
    // 添加事件总线
    Vue.prototype.$bus = this
  },
  render: h => h(App)
}).$mount('#app')
