<template>
  <div class="meeting-box">
    <div class="actions">
      <el-radio-group v-model="activeName" @input="handleDateClick">
        <el-radio label=" ">全部时间</el-radio>
        <el-radio v-if="shownowDate" :label="nowDate.dictValue">{{ nowDate.dictLabel }}</el-radio>
        <el-radio v-if="shownowDateOne" :label="nowDateOne.dictValue">{{ nowDateOne.dictLabel }}</el-radio>
        <el-radio v-if="shownowDateTwo" :label="nowDateTwo.dictValue">{{ nowDateTwo.dictLabel }}</el-radio>
      </el-radio-group>

      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <span>{{ meetingLabel }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu class="meeting-popper" slot="dropdown">
          <el-dropdown-item
            :key="'default'"
            :command="{
              dictLabel: '全部领域',
              dictValue: '',
            }"
            :class="{ active: meetingLabel === '全部领域' }"
          >全部领域</el-dropdown-item>
          <el-dropdown-item
            v-for="item in meetingDict"
            :key="item.dictValue"
            :command="item"
            :class="{ active: meetingLabel === item.dictLabel }"
          >{{ item.dictLabel }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="meeting-list-box">
      <div
        v-for="item in ttMeetingInfoPageSizeSix"
        slot="reference"
        :key="item.id"
        :class="['meeting-list', { hilite: item.sortWeight > 0 }]"
      >
        <span class="meeting-list-time">{{ item.dateConference }}</span>
        <div class="meeting-list-title">
          <router-link
            class="text-overflow-2"
            :to="'/index/detail/meetingDetail/' + item.id"
          >
            <!-- <span v-if="item.sortWeight > 0" class="icon">
              <img src="@/assets/img/fire16_16.png" />
            </span> -->
            <span>{{ item.conferenceTitle }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getYearMonth, fmtDate } from '@/utils/date.js'
import { ttMeetingInfoPage } from '@/api/Index.js'
import { selectDictDataByType } from '@/api/dict.js'

export default {
  data() {
    return {
      meetingDict: [],
      ttMeetingInfoPageSizeSix: [],
      activeName: ' ',
      meetingVal: '',
      meetingLabel: '全部领域',
      shownowDate: true,
      shownowDateOne: true,
      shownowDateTwo: true
    };
  },
  computed: {
    nowDate() {
      return getYearMonth(new Date())
    },
    nowDateOne() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 1))
    },
    nowDateTwo() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 2))
    },
  },
  mounted() {
    this.showDate();
    this.selectDictDataByType();
    this.ttMeetingInfoPage();
  },
  methods: {
    async showDate() {
      const dateValue = getYearMonth(new Date()).dictValue;
      const dateOneValue = getYearMonth(
        new Date().setMonth(new Date().getMonth() + 1)
      ).dictValue;
      const dateTwoValue = getYearMonth(
        new Date().setMonth(new Date().getMonth() + 2)
      ).dictValue;

      const [res1, res2, res3] = await Promise.all([
        ttMeetingInfoPage({
          pageSize: 5,
          page: 1,
          meetField: "",
          dateConference: dateValue
        }),
        ttMeetingInfoPage({
          pageSize: 5,
          page: 1,
          meetField: "",
          dateConference: dateOneValue
        }),
        ttMeetingInfoPage({
          pageSize: 5,
          page: 1,
          meetField: "",
          dateConference: dateTwoValue
        })
      ]);

      this.shownowDate = res1.data.list.length > 0;
      this.shownowDateOne = res2.data.list.length > 0;
      this.shownowDateTwo = res3.data.list.length > 0;
    },
    handleDateClick() {
      this.ttMeetingInfoPage()
    },
    handleCommand(command) {
      this.meetingLabel = command.dictLabel;
      this.meetingVal = command.dictValue;

      this.$nextTick(() => {
        this.ttMeetingInfoPage()
      })
    },

    // 获取会议字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'meeting_information' }).then((res) => {
        if (res.ok) {
          this.meetingDict = res.data
        }
      })
    },
    // 会议信息  取前6个数据
    ttMeetingInfoPage() {
      ttMeetingInfoPage({
        pageSize: 5,
        page: 1,
        meetField: this.meetingVal,
        dateConference: this.activeName,
      }).then((res) => {
        if (res.ok) {
          this.ttMeetingInfoPageSizeSix = res.data.list
          this.ttMeetingInfoPageSizeSix.forEach((item) => {
            item.dateConference = fmtDate('mm月dd日', item.dateConference)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f7faff;
  padding: 12px 14px;

  ::v-deep .el-radio-group {
    .el-radio {
      &:not(:last-child) {
        margin-right: 16px;
        @media screen and (min-width: 1440px) {
          margin-right: 18px;
        }
        @media screen and (min-width: 1480px) {
          margin-right: 20px;
        }
      }

      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding-left: 0;
        color: #222;
        font-weight: normal;
      }

      &.is-checked {
        .el-radio__label {
          color: #3f76ea;
        }
      }
    }
  }
  ::v-deep .el-dropdown {
    .el-dropdown-link {
      color: #222;
    }
  }
}

.meeting-list-box {
  margin-top: 28px;
  padding: 0 24px;

  .meeting-list {
    position: relative;
    padding-left: 10px;
    padding-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      bottom: 0;
      border-left: dashed 1px #e3e3e3;
    }
    &::after {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      width: 7px;
      height: 7px;
      background-color: #0069ea;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &:first-child:before {
      top: 10px;
    }

    .meeting-list-time {
      position: relative;
      margin-bottom: 8px;
      padding: 2px 4px;
      color: #5e84ea;
      font-size: 12px;
      background-color: #ebf1fd;
      border-radius: 2px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-color: #ebf1fd;
        transform: translateY(-50%);
      }
    }

    .meeting-list-title {
      width: 100%;
      overflow: hidden;
      a {
        display: block;
        color: #222;
        font-size: 16px;
        .icon {
          line-height: 1;
          img {
            margin-right: 2px;
            margin-bottom: 3px;
          }
        }
      }
    }

    &.hilite {
      .meeting-list-time {
        color: #fff;
        background-color: #3f76ea;
        &:before {
          border-right-color: #3f76ea;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.meeting-popper {
  .el-dropdown-menu__item {
    &.active {
      color: #3f76ea;
    }
  }
}
</style>
