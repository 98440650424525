<template>
    <PageContainer breadcrumb>
        <Vcontent name='' :data="data" :props="props" :isCollection="false" />
    </PageContainer>
</template>
<script>
import { detail } from "@/api/FullTextRetrieval.js";
export default {
    data() {
        return {
            data: {},
            props: {
                htmlContent: "contentAll",
                summary: "summary",
                publishDate: "publishTime",
            },
        };
    },
    mounted() {
        this.detail();
    },
    methods: {
        detail() {
            detail(this.$route.query.id).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                    this.data.updateTime = this.data.publishTime
                }
            });
        },
    },
};
</script>
