 /**
 * 角色权限处理
 */

 import { getUserInfo } from "@/utils/auth.js";

export default {
  inserted(el, binding) {
    const { value } = binding
    if(getUserInfo()){
      const roles = getUserInfo().roleKey
      if(roles==value || roles == 'freeusers'){
        el.parentNode && el.parentNode.removeChild(el)
      }
    }

  }
}
