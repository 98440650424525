<template>
<!--  <div class="text-over-tooltip-components">-->
    <el-tooltip effect="light" :disabled="isDisabledTooltip" :content="content" placement="top" :open-delay="300">
      <div class="ellipsis" :class="className" @mouseover="onMouseOver(refName)">
        <span :ref="refName">{{content}}</span>
      </div>
    </el-tooltip>
<!--  </div>-->
</template>

<script>
export default {
  name: 'TextOverTooltip',
  props: {
    // 显示的文字内容
    content: String,
    // 设置父元素的样式：比如宽度字体等，需可以自己在组件内部配置样式比如字体大小20：fs20
    className: String,
    // 子元素标识（如在同一页面中调用多次组件，此参数不可重复）
    refName: String,
    // 默认提供的主题 dark/light
    effect: {
      type: String,
      default: () => {
        return 'dark';
      }
    },
    offset:{
      type: Number,
      default: () => 0
    },
    // Tooltip 的出现位置top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end
    placement: {
      type: String,
      default: () => {
        return 'bottom';
      }
    },
  },
  data() {
    return {
      isDisabledTooltip: true // 是否需要禁止提示
    };
  },
  methods: {
    // 移入事件: 判断内容的宽度contentWidth是否大于父级的宽度
    onMouseOver(str) {
      let parentWidth = this.$refs[str].parentNode.offsetWidth;
      let contentWidth = this.$refs[str].offsetWidth;
      // 判断是否禁用tooltip功能
      this.isDisabledTooltip = contentWidth <= parentWidth - this.offset;
    }
  }
};
</script>
<style lang="scss">
/* 修改弹窗边框 这里用的是light主题，所以是is-light */
.el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

/* 修改箭头边框 这里方位是right，所以 x-placement^="right" 并且是设置 border-right-color 的颜色*/
.el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow {
  border-top-color: #eaeaea !important;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #eaeaea !important;
}

/* 修改箭头背景颜色
.el-tooltip__popper[x-placement^=right] .popper__arrow {
  border-right-color: #c02460!important;
}

.el-tooltip__popper[x-placement^=right] .popper__arrow:after {
  border-right-color: #c02460!important;
} */
</style>
