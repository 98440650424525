var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('table',{staticClass:"table",attrs:{"border":"1"}},[_c('tr',[_vm._m(0),_c('td',{attrs:{"width":"70%"}},[_vm._v(" "+_vm._s(_vm.tableData.vehicleModelNumber || '-')+" ")])]),_c('tr',[_vm._m(1),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.pc || '-')+" ")])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.enterpriseName || '-')+" ")])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.commonName || '-')+" ")])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.xfsc || '-')+" ")])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.curbWeight || '-')+" ")])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.rlzl || '-')+" ")])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.cddxslc || '-')+" ")])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.fuleType || '-')+" ")])]),_c('tr',[_vm._m(9),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.engineDisplacement || '-')+" ")])]),_c('tr',[_vm._m(10),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.batteryWeight || '-')+" ")])]),_c('tr',[_vm._m(11),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.dlxdcznl || '-')+" ")])]),_c('tr',[_vm._m(12),_c('td',[_vm._v(" "+_vm._s(_vm.tableData.isCancel || '-')+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right","width":"30%"}},[_c('strong',[_vm._v("产品型号：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("批次：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("生产企业：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("通用名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("细分市场：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("整车整备质量（kg）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("燃料种类：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("纯电动续驶里程（km）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("燃料消耗量（L/100km）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("发动机排量（mL）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("动力蓄电池总质量（kg）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("动力蓄电池总能量（kWh）：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"align":"right"}},[_c('strong',[_vm._v("是否撤销：")])])
}]

export { render, staticRenderFns }