<template>
  <router-view v-if="$route.name == '洞察报告详情'" />
  <PageContainer v-else>
    <div class="sticky-container search-top" :class="{'h':hflag}">
      <div class="search-input-box">
        <div>
          检索方式：
          <el-radio v-model="content" label="title">按标题</el-radio>
          <el-radio v-model="content" label="htmlContent">按内容</el-radio>
        </div>
        <div class="search-btn-box">
          <el-input v-model="values" style="width: 650px" />
          <div class="search-btn" @click="search">检索</div>
        </div>
      </div>
    </div>

    <div v-if="hflag" class="search-bottom">
      <div class="box">
        <div
          v-for="item in list"
          :key="item.id"
          class="box_box_title"
          @click="toHref('/marketInsight/detail/insightReportDetail/'+item.id,item)"
        >
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt="">
            </div>
            <div class="flex_col" flex="1">
              <div>
                <div flex="c" style="margin: 10px 0">
                  <!-- <span
style="
padding: 5px 10px;
background: #0D57BC;
color: #ffffff;
font-weight: 600;
margin-right: 10px;
"
>力荐</span
> -->
                  <p class="">{{ item.title }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ item.introduction }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.updateTime }}
              </div>
            </div>
          </div>
          <div v-if="item.fileInfo" class="check" :class="{ ac: item.check }" @click.stop="checkFun(item)">
            <i class="fa fa-check" />
          </div>
        </div>
        <div class="pages">
          <el-pagination
            :current-page="pageData.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <template slot="right" v-has-role="'freeusers'">
      <div class="file-box">
        <div class="file-title">批量下载队列</div>
        <div class="file-content">
          <div v-for="item in filterFile(checkData)" :key="item.fileName+item.id" class="file-item">
            {{ item.fileName }}
          </div>
          <div v-if="checkData.length>0 && show && !showP " class="file-btn" @click="downAll">一键下载</div>
          <div v-else class="file-btn" @click="down">一键下载</div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import { queryByPage } from '@/api/insight.js'
import fileMixin from '@/mixin/file.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'

export default {
  mixins: [fileMixin],
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      content: 'title',
      list: [],
      values: '',
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      checkData: [],
      hflag: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '市场洞察',
        userId: ''
      },
      show: false,
      showP: false
    }
  },
  created() {
    const title = this.$route.query.title
    this.values = title
    this.queryByPage()
    if (getUserInfo()) {
      this.show = true
      if (getUserInfo().roleKey == 'trialrole') {
        this.showP = true
      } else if (getUserInfo().roleKey == 'paidrole') {
        const menuName = getUserInfo().menuName
        if (!menuName.includes('市场洞察')) {
          this.showP = true
        }
      }
    }
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },

    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('市场洞察', '洞察报告', '1', 'tt_market_insight', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('市场洞察')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    down() {
      this.formLog.eventType = '2'
      if (getUserInfo()) {
        logRecords('市场洞察', '洞察报告', '3', 'tt_market_insight', '', '', '', '', '一键下载')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('市场洞察')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            }
          }
        }
      } else {
        this.showLogin()
      }
    },
    queryByPage() {
      const form = Object.assign({}, this.pageData.pages)
      form[this.content] = this.values
      queryByPage(form).then((res) => {
        const list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i].check = false
          if (this.checkData.length > 0) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.checkData[j].id == list[i].id) {
                list[i].check = true
              }
            }
          }
        }
        this.list = list
        this.pageData.total = res.data.count
      })
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.queryByPage()
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val
      this.queryByPage()
    },

    search() {
      // this.hflag = true;
      logRecords('市场洞察', '洞察报告', '4', 'tt_market_insight', '', '', '', this.values)

      this.queryByPage()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}

.search-top {
  z-index: 1;
  display: flex;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);

  .search-input-box {
    margin: auto;
  }

  &.h {
    height: 130px;
  }
}

.search-btn-box {
  display: flex;
  margin-top: 9px;

  /deep/ .el-input__inner {
    height: 44px;
    line-height: 44px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-btn {
    width: 109px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #0d57bc;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

.search-bottom {
  display: block;
  position: relative;
}

.box_box_title {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.file-box {
  background: #fff;
  border-radius: 4px;
}

.file-title {
  padding: 15px 0 15px 16px;
  font-size: 16px;
  color: #0d57bc;
  border-bottom: 1px solid #f2f2f2;
}

.file-content {
  padding: 24px 16px;

  .file-item {
    line-height: 21px;
    color: #333;
    margin-bottom: 24px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.file-btn {
  width: 168px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0d57bc;
  border: 1px solid #0d57bc;
  margin: 0 auto;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(21, 67, 133, 0.2);
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  color: #fff;

  .fa {
    margin-right: 0;
  }

  &.ac {
    background: #0d57bc;
  }
}
</style>
