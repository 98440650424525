import $http from '@/plugins/http.js'

// 产业布局-地图数据
export function MapData(query) {
    return $http.get('/newEnterpriseIndustryLayout/getMapData',query)
}

// 产业布局-筛选列表
export function Selections(query) {
    return $http.get('/newEnterpriseIndustryLayout/getSelections',query)
}

// 产业布局-表格数据
export function TableData(params) {
    return $http.post('/newEnterpriseIndustryLayout/getTableData', params)
}
// 产业布局-地图数据-海外
export function abroadMapData(query) {
    return $http.get('/newEnterpriseIndustryLayout/getAbroadMapData',query)
}

// 产业布局-筛选列表-海外
export function abroadSelections(query) {
    return $http.get('/newEnterpriseIndustryLayout/getAbroadSelections',query)
}

// 产业布局-表格数据-海外
export function abroadTableData(params) {
    return $http.post('/newEnterpriseIndustryLayout/getAbroadTableData', params)
}
// 产业布局-地图数据-国内零部件
export function partsMakerMapData(query) {
    return $http.get('/newEnterpriseIndustryLayout/getPartsMakerMapData',query)
}

// 产业布局-筛选列表-国内零部件
export function partsMakerSelections(query) {
    return $http.get('/newEnterpriseIndustryLayout/getPartsMakerSelections',query)
}

// 产业布局-表格数据-国内零部件
export function partsMakerTableData(params) {
    return $http.post('/newEnterpriseIndustryLayout/getPartsMakerTableData', params)
}
