<template>
    <div>
        <table border="1" style="width: 100%;padding: 5px" class="hisTable" id="tal">
            <thead>
            <tr class="headerClass" >
                <td colspan="2" rowspan="2">车型</td>
                <td colspan="6">生产</td>
                <td colspan="6">销售</td>
                <td rowspan="2">本月产销率</td>
                <td rowspan="2">本期产销率</td>
            </tr>
            <tr class="headerClass">
                <td>本月完成</td>
                <td>本期止累计</td>
                <td>同期止累计</td>
                <td>比上月增长</td>
                <td>比同期增长</td>
                <td>比同期累计增长</td>
                <td>本月完成</td>
                <td>本期止累计</td>
                <td>同期止累计</td>
                <td>比上月增长</td>
                <td >比同期增长</td>
                <td>比同期累计增长</td>
            </tr>
            </thead>
        </table>
    </div>
</template>

<script>
    export default {
        name: "tableHeader"
    }
</script>

<style scoped lang="css">
    .hisTable{
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
    }
    .headerClass{
        font-size: 16px;
        color: white;
        background-color: #0d57bc ;
    }
    .trOne{
        font-weight:bold;
    }
    .syccxhzHeader{
        width: 100%;
        height: 90px;

        font-size: 22px;
        text-align: center;
    }
    .sycBottom{
        width: 100%;
        height: 70px;
        line-height: 70px;

        float: right;
        font-weight:bold;
    }
    .sycDan{
        width: 10%;
        height: 20px;

        float: right;
        font-size: 13px;
    }
</style>
