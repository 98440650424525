<template>
  <div class="container">
    <div class="container-top">
      <h1 class="container-top-title">
        {{ contentFotm(newData.title) }}
      </h1>
      <template v-if="isContentTwo == 'bz'">
        <div flex="sb">
          <p class="bh">
            <span>标准编号：</span
            ><span>{{ contentFotm(newData.code) }}</span>
          </p>
          <span class="time">{{ newData.updateTime }}</span>
        </div>

        <ul class="fj-list">
          <li v-for="(item, index) in newData.fileInfo" :key="index">
            <div class="down-btn" @click="downBtn(item, newData)">
              <img
                src="@/assets/img/download.png"
                alt=""
                class="down-icon"
              />下载附件{{ index + 1 }}
            </div>
            <div class="down-title">
              {{ index + 1 }}.{{ item.fileName }}
            </div>
          </li>
        </ul>
      </template>
      <!-- <template v-else-if="isContentTwo == 'zc' || isContentTwo == 'bzdt' || isContentTwo == 'bzjd'"> -->
      <template v-else>
        <p class="laiyuan">
          <span style="padding-right:10px">{{ newData.updateTime }}</span>
          <template
            v-if="isContentTwo == 'qyjs' || isContentTwo == 'ztfx'"
          >
            <span>作者：</span><span>{{ newData.author }}</span>
          </template>
          <template v-if="newData.source">
            <span>来源：</span><span>{{ newData.source }}</span>
          </template>
        </p>
        <ul class="fj-list">
          <li v-for="(item, index) in newData.fileInfo" :key="index">
            <div class="down-btn" @click="downBtn(item, newData)">
              <img
                src="@/assets/img/download.png"
                alt=""
                class="down-icon"
              />下载附件{{ index + 1 }}
            </div>
            <div class="down-title">
              {{ index + 1 }}.{{ item.fileName }}
            </div>
          </li>
        </ul>
      </template>
    </div>
    <div class="container-bottom">
      <template v-if="isContentTwo == 'bz'">
        <VtimeLine
          style="margin-bottom:39px"
          :times="newData"
          :timeData="[
            { name: '发布', time: 'publishDate', code: 'replaceCode' },
            { name: '实施', time: 'implementDate' },
            {
              name: '被替代',
              time: 'beReplacedDate',
              code: 'beReplaceCode',
            },
            { name: '废止', time: 'voidDate' },
          ]"
          @commond="commond"
        />
        <UnfurlAndFoldBG :title="name + '简介'" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div style="margin-left:16px">
            <p><b>英文名称</b>：{{ newData.titleEnglish }}</p>
            <p><b>归口管理单位</b>：{{ newData.managerUnit }}</p>
            <p><b>起草单位</b>：{{ newData.draftUnit }}</p>
            <p><b>发布单位</b>：{{ newData.publishUnit }}</p>
            <p><b>标准类别</b>：{{ newData.category }}</p>
            <p><b>影响主体</b>：{{ newData.standardSystem }}</p>
            <p><b>热门领域</b>：{{ newData.belongAreas }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>
      <template v-else-if="isContentTwo == 'zc'">
        <VtimeLine
          style="margin-bottom:39px"
          :times="newData"
          :timeData="[
            { name: '成文', time: 'transactionDate' },
            { name: '公开', time: 'publicDate' },
            { name: '实施', time: 'implementDate' },
            { name: '终止', time: 'endDate' },
          ]"
        />
        <UnfurlAndFoldBG :title="name + '简介'" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div>
            <p><b>发布类型</b>：{{ newData.publishTypeDes }}</p>
            <p v-if="newData.areas == '1'"><b>区域范围</b>：国家</p>
            <p v-else-if="newData.areas == '2'">
              <b>区域范围</b>：{{ newData.provinceDes }}-{{
                newData.city
              }}
            </p>
            <p><b>颁布单位</b>：{{ newData.certificateUnit }}</p>
            <p><b>政策类别</b>：{{ newData.policyCategory }}</p>
            <p><b>热门领域</b>：{{ newData.belongAreas }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>

      <template
        v-else-if="isContentTwo == 'bzdt' || isContentTwo == 'bzjd'"
      >
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div>
            <p>录入时间：{{ newData.updateTime }}</p>
            <p>标准类型：{{ newData.category }}</p>
            <p>涉及的标准：{{ newData.designCriteria }}</p>
            <p v-if="isContentTwo == 'bzjd'">
              解读单位：{{ newData.unscrambleUnit }}
            </p>
            <p>热门领域：{{ newData.belongAreas }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>

      <template v-else>
        <UnfurlAndFoldBG title="简介" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG
          :title="name ? name + '信息' : '信息'"
          class="grou"
          v-if="name == '专题'"
        >
          <div>
            <p>作者：{{ newData.author }}</p>
            <p>细分领域：{{ newData.categoryIdsDes }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>

      <UnfurlAndFoldBG
        :title="name ? name + '正文' : '正文'"
        class="grou"
        v-if="newData.htmlContent != null && newData.htmlContent != ''"
      >
        <div
          class="content line-feed editorStyle"
          v-html="contentFotm(newData.htmlContent)"
        ></div>
      </UnfurlAndFoldBG>

      <div class="collection" v-if="newisCollection">
        <div
          class="collection-btn"
          @click="cancelCollectionBtn"
          v-if="newData.isCollection > 0"
        >
          <img
            src="../../../assets/img/Union.png"
            alt=""
            width="16"
            height="20"
          />
          <p>取消收藏</p>
        </div>
        <div class="collection-btn" @click="collectionBtn" v-else>
          <img
            src="../../../assets/img/stroke.png"
            alt=""
            width="16"
            height="20"
          />
          <p>收藏</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { htmlRestore, htmlString } from "@/utils/htmlRestore.js";
import Vbreadcrumb from "@/components/breadcrumb.vue";
import { ttCollectionAdd, deleteByMessageId } from "@/api/common.js";
import { getUserInfo, logRecords } from "@/utils/auth.js";
import store from "@/store";
import { mapGetters } from "vuex";
import enclosure from "@/components/enclosure.vue";
import { download } from "@/api/common.js";
import { downloadFile } from "@/utils/download.js";
import VtimeLine from "@/components/timeLine.vue";
import UnfurlAndFoldBG from "@/components/UnfurlAndFoldBG.vue";

export default {
  name: "Vcontent",
  components: {
    Vbreadcrumb,
    enclosure,
    VtimeLine,
    UnfurlAndFoldBG,
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      },
    },
    props: {
      default() {
        return {
          tag: "tag",
          title: "title",
          publishDate: "updateTime",
          source: "source",
          summary: "summary",
          link: "link",
          htmlContent: "htmlContent",
          author: "author",
        };
      },
    },
    isCollection: {
      type: Boolean,
      default: true,
    },
    isContentTwo: "",
    name: "",
  },
  computed: {
    ...mapGetters({
      ListFlag: "ListFlag",
      pRouterId: "pRouterId",
    }),
    newData() {
      if (JSON.stringify(this.data) != "{}") {
        for (let key in this.props) {
          this.data[key] = this.data[this.props[key]];
        }
        return this.data;
      } else {
        return {};
      }
    },
  },
  data() {
    return {
      newisCollection: this.isCollection,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch("common/SET_ListRest");
    },
    data(to, form) {
      if (JSON.stringify(this.data) != "{}") {
        recordWatch({ title: to.title });
      }
    },
  },
  created() {
    // this.$store.state.common.ListFlag = false
    this.$store.dispatch("common/SET_ListRest");
    let userInfo = getUserInfo();
    if (!userInfo) {
      this.newisCollection = false;
    }
  },
  methods: {
    splitArr(data) {
      if (data && data.length > 0) {
        return data.split(",");
      } else {
        return [];
      }
    },
    contentFotm(data) {
      if (data) {
        return htmlRestore(data);
      }
    },
    collectionBtn() {
      let data = Object.assign({}, this.data);
      data.htmlContent = htmlString(this.data.htmlContent);
      data.menuId = this.pRouterId;
      let obj = {
        menuId: data.menuId,
        title: data.title,
        content: data.htmlContent,
        publishDate: data.updateTime,
        messageId: data.id,
      };
      ttCollectionAdd(obj).then((res) => {
        if (res.ok) {
          this.$message.success("收藏成功");
          this.newData.isCollection = 1;
        }
      });
      // this.$emit('collection',this.data)
    },
    cancelCollectionBtn() {
      this.$confirm("是否取消收藏", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteByMessageId(this.newData.id).then((res) => {
          if (res.ok) {
            this.newData.isCollection = 0;
          }
        });
      });
    },
    downBtnYc() {
      let self = this;
      download({
        fileUrl: this.newData.attachUrl,
        articleId: this.newData.id,
      }).then((res) => {
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function(e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, self.newData.acctachName);
          }
        });
      });
    },
    downBtns() {
      let self = this;
      download({
        fileUrl: this.newData.attachUrl,
        articleId: this.newData.standardContentId,
      }).then((res) => {
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function(e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, self.newData.acctachName);
          }
        });
      });
    },
    //下载
    downBtn(item, obj) {
      // logRecords('企业情报','企业产能','2','tt_enterprise_capacity',obj.id,item.url,'',this.form.enterprise)
      let self = this;
      download({ fileUrl: item.url, articleId: obj.id }).then((res) => {
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function(e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, item.fileName);
          }
        });
      });
    },
    //时间轴跳转
    commond(v) {
      //console.log(v.code.code);
      this.$emit("commond", v);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .container-tag {
    margin-bottom: 10px;
    margin-left: -10px;
    .tags {
      margin-left: 10px;
    }
  }
  .container-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #333;
    margin-bottom: 10px;
  }
  .container-summary,
  .container-tip {
    font-size: 16px;
    line-height: 24px;
    color: #828282;
  }
  .container-summary {
    margin-bottom: 16px;
  }
  .container-tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .container-tip-l {
      span {
        padding-right: 15px;
      }
    }
  }
}
.collection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0 24px;
  height: 106px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  .collection-btn {
    font-size: 16px;
    line-height: 26px;
    color: #000;
    text-align: center;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 15px 0;
}
.fj {
  font-size: 13px;
  line-height: 13px;
  color: #828282;
}

.xgtj-box {
  // background: #fff;
  min-height: 200px;
  h1 {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 0 16px;
    color: #0d57bc;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
  }
}
.zwtj {
  text-align: center;
  line-height: 150px;
}

//新样式S
.container-top {
  background: #fff;
  padding: 0 16px 24px;
  margin-bottom: 12px;
  .container-top-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #333;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 24px;
  }
  .bh {
    display: inline-block;
    padding: 10px 8px 10px 16px;
    //background: #f4f5f6;
    border-radius: 2px;
    margin-bottom: 14px;
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: red;
    }
  }
  .time {
    font-size: 14px;
    line-height: 20px;
    color: #4f4f4f;
  }
  .laiyuan {
    padding-left: 16px;
    font-size: 16px;
    line-height: 16px;
    color: #4f4f4f;
    margin-bottom: 24px;
  }
  .down-btn {
    display: inline-block;
    padding: 7px 12px;
    font-size: 12px;
    line-height: 12px;
    background: #0d57bc;
    border-radius: 2px;
    border-color: #0d57bc;
    color: #fff;
    cursor: pointer;
    .down-icon {
      vertical-align: sub;
    }
  }
  .fj-list li {
    display: flex;
    margin-bottom: 8px;
  }
  .down-title {
    flex: 1;
    position: relative;
    font-size: 13px;
    line-height: 31px;
    color: #333;
    margin-left: 12px;
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      bottom: -4px;
      left: 0;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .fj-list li:last-child {
    margin-bottom: 0px;
  }
  .fj-list li:last-child .down-title::after {
    display: none;
  }
}
.container-bottom {
  background: #fff;
  padding: 24px 16px 16px;
  .grou {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
