<template>
  <div class="page-header">
    <!-- 模块导航 -->
    <div :class="['module-nav-wrapper', showShadow ? 'show-shadow' : '']">
      <template v-for="(moduleName, index) in moduleNameList">
        <div
          v-if="index !== 0"
          :key="moduleName + 'line'"
          :class="['nav-split-line', isShowSplitLine(index) ? 'show' : '']"
        />
        <div
          :id="moduleName === '数据表下载' ? 'tableDownload' : undefined"
          :key="moduleName"
          :class="['item', activeModuleName === moduleName ? 'active' : '']"
          @click="handleClickModuleNav(moduleName)"
        >
          {{ moduleName }}
          <i v-if="moduleName === '数据表下载'" class="el-icon-download" />
        </div>
      </template>
    </div>
    <!-- 右侧的其他功能 -->
    <div :class="['header-right-wrapper', showShadow ? 'show-shadow' : '']">
      <div class="link-wrapper">
        <div class="link" @click="handleOpenDataCustomizationDialog('定制')">
          数据定制
        </div>
        <div class="split-line">/</div>
        <div class="link" @click="handleOpenDataCustomizationDialog('API')">
          API接入
        </div>
      </div>
      <div class="return-old-version" @click="handleClickReturnOldVersion">
        返回旧版
        <i class="el-icon-arrow-right" />
      </div>
    </div>
    <DataCustomizationDialog ref="dataCustomizationDialogRef" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataCustomizationDialog from './dataCustomizationDialog.vue'
import {getUserInfo, logRecords} from '@/utils/auth.js'

export default {
  name: 'ModuleNavBar',
  components: {
    DataCustomizationDialog
  },
  data() {
    return {
      // 模块导航名称列表
      moduleNameList: [],
      // 是否展示数据定制弹窗
      showDataCustomizationDialog: false,
      showShadow: false,
    }
  },
  computed: {
    ...mapGetters(['permission_routes']),
    // 数据栏目下的所有子菜单
    dataChildrenRoutes() {
      return (
        this.permission_routes.find((item) => item.name === '数据')?.children ||
        []
      )
    },
    activeModuleName() {
      // 返回第二层路由的 meta.moduleName，该属性用于标识当前路由属于 数据报告、数据查询、数据表下载 的哪一个
      return process.env.VUE_APP_CHECKLOGIN === 'fuao' ? this.$route.matched[1].meta?.moduleName: this.$route.matched[2].meta?.moduleName
    }
  },
  created() {
    // 根据 数据栏目 的第二层路由表，动态获取模块名称列表，用于 v-for 生成上方的 模块导航
    const allModuleName = this.dataChildrenRoutes.map(
      (item) => item.meta.moduleName
    )
    this.$set(
      this,
      'moduleNameList',
      [...new Set(allModuleName)].filter((item) => item)
    )
  },
  mounted() {
    document.addEventListener('scroll', this.scrollFun);
  },
  beforeDestory() {
    document.removeEventListener('scroll', this.scrollFun);
  },
  methods: {
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 点击某个模块的导航
    handleClickModuleNav(moduleName) {
      if (moduleName === '数据表下载' && !getUserInfo()) {
        this.showLogin()
        return
      }
      // 如果点击的是 数据查询 模块
      if (moduleName === '数据查询') {
        const themeRoute = this.dataChildrenRoutes.find(
          (item) => item.hasDefaultOpenChildMenu
        )
        // 如果找到了有 默认打开的子菜单 的数据主题路由
        if (themeRoute) {
          // 则跳转到该默认打开的子菜单对应的路由
          this.$router.push(themeRoute.path)
        } else {
          // 否则打开 数据查询模块的第一个数据主题下的第一个子菜单
          // 找到属于 数据查询 模块的第一个数据主题的路由对象
          const firstDataQueryThemeRoute = this.dataChildrenRoutes.find(
            (item) => item.meta.moduleName === moduleName
          )
          if (firstDataQueryThemeRoute) {
            this.$router.push(firstDataQueryThemeRoute.path)
          }
        }
        return
      }
      // 点击的是 数据报告或数据表下载 模块
      // 找到对应的二级菜单路由对象
      const secondaryMenu = this.dataChildrenRoutes.find(
        (item) => item.name === moduleName
      )
      if (!secondaryMenu) {
        return
      }
      // 直接跳转二级菜单的 path 即可，如果有三级菜单的话，会根据路由配置的 redirect 属性跳转到指定的三级路由
      this.$router.push(secondaryMenu.path)
    },
    // 点击数据定制或API接入的回调
    handleOpenDataCustomizationDialog(keyWord) {
      this.$refs.dataCustomizationDialogRef.open(keyWord)
    },
    // 点击返回旧版
    handleClickReturnOldVersion() {
      // 调起弹窗
      this.$DataVersionDialog({
        show: true
      })
      // 埋点
      logRecords(
        '数据', // column1 写死
        this.$route.name, // column2 数据表名称，传当前菜单的名称
        '3', // type 写死，3 表示操作
        '', // tableName 不传也行
        '', // articleId 不传也行
        '', // attachName 不传也行
        '', // articleName 不传也行
        '', // inputBox
        '旧版入口' // buttonName
      )
    },
    isShowSplitLine(index) {
      const activeModuleNameIndex = this.moduleNameList.findIndex(
        (item) => item === this.activeModuleName
      )
      return (
        activeModuleNameIndex === index + 1 ||
        Math.abs(activeModuleNameIndex - index) > 1
      )
    },

    scrollFun() {
      const scrollTop = document.documentElement.scrollTop ?? document.body.scrollTop;
      this.showShadow = scrollTop > 10;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.show-shadow {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

// 横向模块导航的样式
.page-header {
  z-index: 101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  transition: box-shadow 0.5s;
}

.module-nav-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  font-size: 16px;
  background-color: #fff;
  color: #333333;
  margin-right: 16px;
  border-radius: 3px;
  transition: all 0.3s;

  .item {
    width: 33.333%;
    height: $module-nav-bar-height;
    line-height: $module-nav-bar-height;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s;
    color: #0D57BC;
    font-weight: 600;

    &.active,
    &:hover {
      background-color: #3469dd;
      color: #fff;
    }
  }

  .nav-split-line {
    width: 1px;
    height: 1.5rem;
    background-color: #e5e6eb;
    opacity: 0;
    margin: 0 6px;
    transition: opacity 0.3s;

    &.show {
      opacity: 1;
    }
  }
}

.header-right-wrapper {
  display: flex;
  height: $module-nav-bar-height;
  align-items: center;
  color: #606266;
  background-color: #fff;
  padding: 0 24px;
  border-radius: 3px;
  transition: all 0.3s;

  .link-wrapper {
    display: flex;
    align-items: center;

    .split-line {
      padding: 0 8px;
      user-select: none;
      -webkit-user-select: none;
    }

    .link {
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #0d57bc;
      }
    }

    ::v-deep .el-divider {
      background-color: #606266;
    }
  }

  .split-line {
    padding: 0 10px;
  }

  // 返回旧版
  .return-old-version {
    padding: 10px 10px 10px 14px;
    margin: 0 0 0 32px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 700;
    color: #0d57bc;
    background-color: #f0f4f9;
    cursor: pointer;
  }
}
</style>
