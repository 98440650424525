<!--
 * @Author: your name
 * @Date: 2021-07-21 13:10:05
 * @LastEditTime: 2021-07-21 14:57:55
 * @LastEditors: Please set LastEditors
 * @Description: 发动机数据页面
 * @FilePath: /information-vue/code/src/views/data/engineData/index.vue
-->

<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据"-->
    <!--            :showTips="showTips" />-->
    <template>
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">发动机数据</span>
            <span class="time">已更新至{{ upDataDate }}</span>
          </div>
          <!-- <button class="title-down-btn" v-has-role="'trialrole'" @click="dialogVisible = true"><i
                  class="el-icon-download"></i>下载电子版本</button> -->
        </div>
      </Vtitle>
      <el-form :model="form" ref="form" inline label-width="135px" class="n-data-content">
        <h1 class="n-title">显示字段</h1>
        <el-row style="margin:0px 0 10px;">
          <el-col class="n-check-m">
            <el-form-item prop="qymcShow" style="display:none"></el-form-item>
            <el-form-item prop="bjShow" style="display:none"></el-form-item>
            <el-form-item prop="zpShow" style="display:none"></el-form-item>
            <el-form-item prop="spShow" style="display:none"></el-form-item>
            <el-form-item prop="scShow" style="display:none"></el-form-item>
            <el-form-item prop="xsShow" style="display:none"></el-form-item>
            <el-checkbox v-model="year" disabled class="isDisabled">年</el-checkbox>
            <el-checkbox v-model="month" @change="changeMonth">月</el-checkbox>
            <el-checkbox v-model="form.qymcShow" @change="changeFun('qymcArray')">企业名称</el-checkbox>
            <el-checkbox v-model="form.bjShow" @change="changeFun('bjArray')">燃料种类</el-checkbox>

          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">查询字段</h1>
        <el-row>
          <el-col :span="8">
            <el-form-item label="时间：">
              <VdateEndStart ref="VdateEndStart" v-model="times" @change="timeChange"
                             :isTimeSwitch="false" :checkDate="checkDate" :close="false" width="180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业名称：" class="no-position" prop="qymcArray">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.qymcShow">
                <selectCheck v-model="form.qymcArray" :datas="qymcData" :disabled="!form.qymcShow"
                             :props="props"></selectCheck>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="燃料种类：" prop="bjArray">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.bjShow">

                <el-select v-model="form.bjArray" placeholder="请选择" size="small" :disabled="!form.bjShow"
                           multiple collapse-tags class="new-width">
                  <el-option v-for="item in rlzlData" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">统计结果</h1>
        <el-row>
          <el-col class="n-check-m">
            <el-checkbox v-model="form.scShow">生产量</el-checkbox>
            <el-checkbox v-model="form.xsShow">销售量</el-checkbox>
            <el-checkbox v-model="form.zpShow">自配量</el-checkbox>
            <el-checkbox v-model="form.spShow">商品量</el-checkbox>
            <el-checkbox v-model="form.tjShow">统计</el-checkbox>
          </el-col>
        </el-row>
        <div class="text-center" style="margin-top: 28px;">
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button type="primary" @click="searchForm">查询</el-button>
        </div>
      </el-form>
      <div style="margin-top:10px">
        <div class="search-form">
          <div flex="sb" style="margin-bottom: 16px">
            <span class="table_title"></span>
            <div>
              <el-button type="primary" plain @click="Export" v-has-role="'freeusers'"
                         :loading="excelLoading">导出
              </el-button>
            </div>
          </div>
          <div v-if="show && !showP">
            <el-table :span-method="arraySpanMethod" v-loading="loading" width="100%" :data="tableData"
                      style="width: 100%; margin-bottom: 20px" stripe border
                      :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
              <template v-for="item in tableHeader">
                <el-table-column align="center" :label="item.label" :prop="item.prop" :key="item.prop"
                                 v-if="item.prop == 'bj'" width="100px"></el-table-column>
                <el-table-column align="left" :label="item.label" :prop="item.prop" :key="item.prop"
                                 width="200px" v-else></el-table-column>
              </template>

              <template v-for="item in timeList">
                <el-table-column :label="item" :key="item" align="center">
                  <el-table-column label="生产量" align="center" v-if="scShow">
                    <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-sc'" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-sc']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item+'-bqzlj1'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj1']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item+'-tqzlj1'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj1']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item+'-schb'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-schb']) }}
                      </template>

                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item+'-sctb'" v-if="tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-sctb']) }}
                      </template>

                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item+'-scbtqljzz'" v-if="monthShow && tjShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-scbtqljzz']) }}
                      </template>

                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="销售量" align="center" v-if="xsShow">
                    <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-xs'" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-xs']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item+'-bqzlj3'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj3']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item+'-tqzlj3'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj3']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item+'-xshb'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xshb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item+'-xstb'" v-if="tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xstb']) }}
                      </template>

                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item+'-xsbtqljzz'" v-if="monthShow && tjShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xsbtqljzz']) }}
                      </template>

                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="自配量" align="center" v-if="zpShow">
                    <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-zp'" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-zp']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item+'-bqzlj7'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj7']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item+'-tqzlj7'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj7']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item+'-zphb'" v-if="monthShow && tjShow" align="center">

                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-zphb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item+'-zptb'" v-if="tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-zptb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item+'-zpbtqljzz'" v-if="monthShow && tjShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-zpbtqljzz']) }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="商品量" align="center" v-if="spShow">
                    <el-table-column :label="monthShow?'本月完成':'本年完成'" :prop="item+'-sp'" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-sp']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item+'-bqzlj5'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj5']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item+'-tqzlj5'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj5']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item+'-sphb'" v-if="monthShow && tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-sphb']) }}
                      </template>

                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item+'-sptb'" v-if="tjShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-sptb']) }}
                      </template>

                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item+'-spbtqljzz'" v-if="monthShow && tjShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-spbtqljzz']) }}
                      </template>
                    </el-table-column>
                  </el-table-column>

                </el-table-column>
              </template>


            </el-table>
            <div>单位：辆，%</div>
            <div class="text-right">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                             :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                             layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
              </el-pagination>
            </div>

          </div>
          <div v-else style="position: relative; ">
            <img style="width:100%" src="@/assets/img/fdjsjb.jpg" alt=""/>
            <center>
              <div
                  style="position:relative;width:300px;height:94px;bottom:400px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
                  v-if="!show">

                <div style="padding-top: 20px">
                  <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
                </div>

                <div style="margin: 10px">
                  没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

                </div>
              </div>
              <div
                  style="position:relative;width:320px;height:94px;bottom:400px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                  v-else>
                <div style="padding-top: 20px;padding-left: 22px;">
                  <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
                </div>
                <div style="padding: 10px 20px;text-align: center">
                  <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
                </div>
              </div>
            </center>
          </div>
        </div>
        <el-dialog title="发动机数据电子版下载" :visible.sync="dialogVisible" width="800px">
          <div style="height:338px;overflow-x:auto">
            <el-table :data="uploadList" class="rest_table">
              <el-table-column property="attachName" label="名称"></el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button size="small" icon="el-icon-download"
                             @click="handleDown(scope.$index, scope.row)">下载
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="dialog-footer text-center">
            <el-button @click="dialogVisible = false" type="primary" plain style="width:204px">关 闭
            </el-button>
          </div>

        </el-dialog>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 发动机数据页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {
  getQyInfoList,
  ykbFdjselectTable,
  ykbFdjgetYearMonthList,
  ykbFdgetDefaultDate,
  ykbFdjDynamicExport,
  ttDataUpload,
  ykbFdjExportAll,
} from "@/api/data.js";
import {
  getUserInfo, logRecords, openSy
} from "@/utils/auth.js";
import VdateEndStart from "@/components/dateEndStartTwo";
import {
  buryPoint,
  download,
  newdownload
} from "@/api/common.js";
import {
  downloadFile
} from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";
import Vtitle from "../components/title.vue";
import {
  getMonthBetween,
  getYearArr
} from "@/utils/date.js";

export default {
  components: {
    // VnotLogin,
    VdateEndStart,
    selectCheck,
    Vtitle,
  },
  data() {
    return {
      show: false,
      showTips: [{
        title: "数据动态",
        text: "最新权威动向和独家原创解读",
      },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      //分页
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      form: {
        checkYearMonth: false,
        endTime: "",
        startTime: "",
        qymcShow: true,
        qymcArray: [],
        bjShow: true,
        bjArray: [],
        zpShow: false,
        spShow: false,
        scShow: true,
        xsShow: true,
        tjShow: true,
      },
      timesCheck: true,
      year: true,
      month: true,
      times: [],
      rlzlData: [{
        value: "cy",
        label: "柴油"
      },
        {
          value: "qy",
          label: "汽油"
        },
        {
          value: "qt",
          label: "其他"
        },
      ],
      uploadList: [],
      qymcData: [],
      checkDate: "month",
      tableHeader: [],
      tableHeaderTime: [],
      loading: false,
      tableData: [],
      excelLoading: false,
      excelAllLoading: false,
      props: {
        label: "id",
        key: "id",
        name: "enterprise_name",
      },
      upDataDate: "",
      showTotal: false,
      dialogVisible: false,
      timeList: [],
      scShow: true,
      xsShow: true,
      zpShow: false,
      spShow: false,
      tjShow: false,
      monthShow: true,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }
      }
      this.ttDataUpload();

      this.scShow = this.form.scShow
      this.xsShow = this.form.xsShow
      this.zpShow = this.form.zpShow
      this.spShow = this.form.spShow
      this.tjShow = this.form.tjShow
      this.monthShow = this.month
    } else {
      this.show = false;
    }
    this.ykbFdgetDefaultDate();

  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    toLocaleString(val) {
      if (val == null || val == '') {
        return val;
      } else {
        return val.toLocaleString();
      }

    },
    toFixed(val) {

      if (val == null || val == '') {
        return val;
      } else {
        return val.toFixed(2);
      }

    },
    //点击月份
    changeMonth(e) {
      this.form.timesCheck = !e
      if (e) {
        this.$refs.VdateEndStart.deteHandleCommand('month', this.updataDate)
      } else {
        this.$refs.VdateEndStart.deteHandleCommand('year')
      }


    },
    //附件列表
    ttDataUpload() {
      ttDataUpload({
        modelName: "data_upload_4"
      }).then((res) => {
        if (res.ok) {
          this.uploadList = res.data;
        }
      });
    },
    //下载附件
    handleDown(index, row) {
      this.download(row);
    },
    download(item) {
      let self = this;
      newdownload({
        fileUrl: item.attachUrl,
        articleId: item.id
      }).then(
          (res) => {
            var reader = new FileReader();
            reader.readAsText(res.data);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                let contentDisposition =
                    res.headers["content-disposition"];
                let patt = new RegExp(
                    "filename=([^;]+\\.[^\\.;]+);*"
                );
                let result = patt.exec(contentDisposition);
                let filename = decodeURI(result[1]);
                downloadFile(res.data, filename);
              }
            });
          }
      );
    },
    ykbFdgetDefaultDate() {
      ykbFdgetDefaultDate().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.upDataDate = res.data;
          this.timeList = [res.data]
          if (this.show) {
            this.ykbFdjselectTable();

          }
          this.getQyInfoList();
        }
      });
    },
    getQyInfoList() {
      let form = {
        isYear: this.form.checkYearMonth,
        startTime: this.times[0],
        endTime: this.times[1],
      };
      getQyInfoList(form).then((res) => {
        if (res.ok) {
          this.qymcData = res.data;
        }
      });
    },
    ykbFdjgetYearMonthList(
        startTime,
        endTime,
        isYear,
        zpShow,
        spShow,
        scShow,
        tjShow,
        xsShow
    ) {
      ykbFdjgetYearMonthList({
        startTime,
        endTime,
        isYear,
        zpShow,
        spShow,
        scShow,
        tjShow,
        xsShow,
      }).then((res) => {
        if (res.ok) {
          this.tableHeaderTime = this.catgoryGrop(res.data);
        }
      });
    },
    ykbFdjselectTable() {
      this.loading = true;
      let form = Object.assign({}, this.form, this.pageData);
      form.endTime = this.times[1];
      form.startTime = this.times[0];
      if (form.startTime == form.endTime) {
        this.showTotal = false
      } else {
        this.showTotal = true
      }
      delete form.total;
      this.ykbFdjgetYearMonthList(
          this.getNewDate(this.times[0]),
          this.getNewDate(this.times[1]),
          form.checkYearMonth,
          form.zpShow,
          form.spShow,
          form.scShow,
          form.xsShow,
          form.tjShow
      );
      ykbFdjselectTable(form).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.tableHeader = this.tableHeaderForm();
          this.pageData.count = res.data.count;
          if (this.month) {
            this.timeList = getMonthBetween(form.startTime, form.endTime)
          } else {
            this.timeList = getYearArr(form.startTime, form.endTime)
          }
          this.monthShow = this.month


          this.scShow = this.form.scShow
          this.xsShow = this.form.xsShow
          this.zpShow = this.form.zpShow
          this.spShow = this.form.spShow
          this.tjShow = this.form.tjShow
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    changeFun(type) {
      let isArr = Array.isArray(this.form[type]);
      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        this.$set(this.form, type, "");
      }
    },
    //时间头部
    getNewDate(nowdate) {
      let timeHeader = "";
      let date = new Date(),
          year = date.getFullYear(),
          month =
              date.getMonth() + 1 >= 10 ?
                  date.getMonth() + 1 :
                  "0" + date.getMonth() + 1;
      if (nowdate) {
        if (this.form.checkYearMonth) {
          timeHeader = nowdate + "-01-01";
        } else {
          timeHeader = nowdate + "-01";
        }
      } else {
        if (this.form.checkYearMonth) {
          timeHeader = year + "-01-01";
        } else {
          timeHeader = year + "-" + month + "-01";
        }
      }

      return timeHeader;
    },
    //开始结束时间
    timeChange(e) {
      this.times = e.value
      if (e.swatchDateType == "month") {
        this.form.checkYearMonth = false;
        this.checkDate = "month";
      } else {
        this.form.checkYearMonth = true;
        this.checkDate = "year";
      }
      this.getQyInfoList();
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.ykbFdjselectTable();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.ykbFdjselectTable();
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.checkDate = "month";
      this.month = true
      this.ykbFdgetDefaultDate();
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    searchForm() {
      if (this.times[0] && !this.times[1]) {
        this.$message.warning("结束时间不能为空");
        return false;
      } else if (!this.times[0] && this.times[1]) {
        this.$message.warning("开始时间不能为空");
        return false;
      }
      if (getUserInfo()) {

        logRecords('数据','发动机数据','3','ykb_fdj','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.page = 1;
            this.ykbFdjselectTable();
          }
        } else {
          this.pageData.page = 1;
          this.ykbFdjselectTable();
        }
      } else {
        this.showLogin();
      }

    },
    //前面头部
    tableHeaderForm() {
      let tableArr = [];
      for (let key in this.form) {
        if (typeof this.form[key] == "boolean") {
          if (this.form[key]) {
            switch (key) {
              case "qymcShow":
                tableArr.push({
                  label: "企业名称",
                  prop: "qymc",
                });
                break;
              case "bjShow":
                tableArr.push({
                  label: "燃料类型",
                  prop: "bj",
                });
                break;
            }
          }
        }
      }
      return tableArr;
    },
    checkName(value) {
      let id = value[0];
      let str = this.qymcData.filter((item) => item.id == id);
      if (str.length && str.length > 0) {
        return str[0].enterprise_name;
      }
    },
    //分组表头
    catgoryGrop(data) {
      let newData = data;
      let sc = [];
      let sctb = [];
      let schb = [];
      let xs = [];
      let xstb = [];
      let xshb = [];
      let sp = [];
      let sptb = [];
      let sphb = [];
      let zp = [];
      let zptb = [];
      let zphb = [];
      let bqzlj1 = [];
      let tqzlj1 = [];
      let bqzlj5 = [];
      let tqzlj5 = [];
      let bqzlj3 = [];
      let tqzlj3 = [];
      let bqzlj7 = [];
      let tqzlj7 = [];
      newData.forEach((el) => {
        sc.push({
          sc: el.sc,
          dateStr: el.dateStr
        });
        bqzlj1.push({
          bqzlj1: el.bqzlj1,
          dateStr: el.dateStr
        });
        tqzlj1.push({
          tqzlj1: el.tqzlj1,
          dateStr: el.dateStr
        });
        sctb.push({
          sctb: el.sctb,
          dateStr: el.dateStr
        });
        schb.push({
          schb: el.schb,
          dateStr: el.dateStr
        });
        sp.push({
          sp: el.sp,
          dateStr: el.dateStr
        });
        bqzlj5.push({
          bqzlj5: el.bqzlj5,
          dateStr: el.dateStr
        });
        tqzlj5.push({
          tqzlj5: el.tqzlj5,
          dateStr: el.dateStr
        });
        sptb.push({
          sptb: el.sptb,
          dateStr: el.dateStr
        });
        sphb.push({
          sphb: el.sphb,
          dateStr: el.dateStr
        });
        xs.push({
          xs: el.xs,
          dateStr: el.dateStr
        });
        bqzlj3.push({
          bqzlj3: el.bqzlj3,
          dateStr: el.dateStr
        });
        tqzlj3.push({
          tqzlj3: el.tqzlj3,
          dateStr: el.dateStr
        });
        xstb.push({
          xstb: el.xstb,
          dateStr: el.dateStr
        });
        xshb.push({
          xshb: el.xshb,
          dateStr: el.dateStr
        });
        zp.push({
          zp: el.zp,
          dateStr: el.dateStr
        });
        bqzlj7.push({
          bqzlj7: el.bqzlj7,
          dateStr: el.dateStr
        });
        tqzlj7.push({
          tqzlj7: el.tqzlj7,
          dateStr: el.dateStr
        });
        zptb.push({
          zptb: el.zptb,
          dateStr: el.dateStr
        });
        zphb.push({
          zphb: el.zphb,
          dateStr: el.dateStr
        });
      });
      return {
        sc,
        sctb,
        schb,
        sp,
        sptb,
        sphb,
        xs,
        xstb,
        xshb,
        zp,
        zptb,
        zphb,
        bqzlj1,
        tqzlj1,
        bqzlj5,
        tqzlj5,
        bqzlj3,
        tqzlj3,
        bqzlj7,
        tqzlj7,
        zpShow: this.form.zpShow,
        spShow: this.form.spShow,
        scShow: this.form.scShow,
        xsShow: this.form.xsShow,
        tjShow: this.form.tjShow,
      };
    },

    //导出
    Export() {
      if (getUserInfo()) {

        logRecords('数据','发动机数据','3','ykb_fdj','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            } else {
              this.form.endTime = this.times[1];
              this.form.startTime = this.times[0];
              let self = this;
              this.excelLoading = true;
              ykbFdjDynamicExport(this.form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "发动机数据.xlsx");
                  }
                });
              });
            }
          }
        } else {
          this.form.endTime = this.times[1];
          this.form.startTime = this.times[0];
          let self = this;
          this.excelLoading = true;
          ykbFdjDynamicExport(this.form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "发动机数据.xlsx");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }

    },
    //导出全部
    ExportAll() {
      this.form.endTime = this.times[1];
      this.form.startTime = this.times[0];
      let self = this;
      this.excelAllLoading = true;
      ykbFdjExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "发动机全部数据.xls");
          }
        });
      });
    },
    arraySpanMethod({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      let col = this.tableHeader.length;

      if (this.pageData.page == 1) {
        if (col > 0) {
          if (rowIndex === 0) {
            if (columnIndex === 0) {
              return [1, this.tableHeader.length];
            } else if (columnIndex < col) {
              return [1, 0];
            }
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }


    },
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
