/*
 * @Description: 工具类
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-16 15:01:26
 * @LastEditors: 林子健
 * @LastEditTime: 2023-08-16 15:05:57
 */
const Utils = {
  /**
   * @description: 字符串指定位置插入字符
   * @return {*} 插入后的字符串
   * @author: 林子健
   * @TODO:入参 原字符串、插入位置、需插入的字符串
   * @Date: 2023-08-16 15:04:38
   * @LastEditTime: Do not edit
   */
  insertStr: (source, start, newStr) => {
    return source.slice(0, start) + newStr + source.slice(start);
  },
};

export default Utils;
