<template>
  <div v-loading="loading" class="syccxhz">
    <div class="syccxhzHeader">
      <!--          <div></div>-->
      <div class="sycBottom">
        <p>{{ date[0] }}年{{ date[1] }}月份汽车发动机生产企业自配量情况表</p>
      </div>
      <div class="sycDan">
        <p>单位：台，%</p>
      </div>
      <div class="sycDan1">
        <el-button v-has-role="'freeusers'" type="primary" icon="el-icon-upload" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <div>
      <table id="tal" border="1" style="width: 100%;padding: 5px" class="hisTable">
        <thead>
          <tr class="headerClass">
            <td colspan="7" rowspan="1" align="center" style="display: none">{{ date[0] }}年{{ date[1] }}月份汽车发动机生产企业自配量情况表</td>
          </tr>
          <tr class="headerClass">
            <td rowspan="2">企业名称</td>
            <td colspan="6">台</td>

          </tr>
          <tr class="headerClass">
            <td>本月</td>
            <td>本期止累计</td>
            <td>同期止累计</td>
            <td>比上月增长</td>
            <td>比同期增长</td>
            <td>比同期累计增长</td>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in tableData" :key="index">
            <td v-html="returnFont(item,'zhibiao')" />
            <td v-html="returnFont(item,'scBywc')" />
            <td v-html="returnFont(item,'scBqzlj')" />
            <td v-html="returnFont(item,'scTqzlj')" />
            <td v-html="returnFont(item,'scBsyzz')" />
            <td v-html="returnFont(item,'scBtqzz')" />
            <td v-html="returnFont(item,'scBtqljzz')" />

          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>
<script>
import { getFdjScqyZplqkb } from '@/api/data'
import { combineCell } from '@/utils/tableData.js'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { getUserInfo } from '@/utils/auth'
import XLSXS from 'xlsx-style-medalsoft'
export default {
  data() {
    return {
      tableData: [],
      date: null,
      bj2: null,
      loading: true
    }
  },
  created() {
    const date = this.$route.params.date
    this.date = date.split('-')
    this.getFdjScqyZplqkb({ dataDate: date.split('-').join('') })
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #ffffff;color:#000000;padding:8px;')
  },
  methods: {

    exportExcel() {
      if (getUserInfo().roleKey == 'trialrole') {
        openSy()
      } else {
        /* generate workbook object from table */
        var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
        var table = document.querySelector('#tal').cloneNode(true)
        var wb = XLSX.utils.table_to_book(table, xlsxParam)
        const wbs = wb['Sheets']['Sheet1']
        // 每个单元格设置居中
        for (const key in wbs) {
          if (key.indexOf('!') === -1 && wbs[key].v) {
            wbs[key].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrap_text: true
              }
            }
          }
        }

        /* get binary string as output */
        var wbout = XLSXS.write(wb, { bookType: 'xlsx', bookSST: true, type: 'buffer' })
        try {
          FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '汽车发动机生产企业自配量情况表.xlsx')
        } catch (e) {
          if (typeof console !== 'undefined') {
            console.log(e, wbout)
          }
        }
        return wbout
      }
    },
    getFdjScqyZplqkb(date) {
      getFdjScqyZplqkb(date).then((res) => {
        if (res.ok) {
          this.tableData = combineCell(res.data)
        }
        this.loading = false
      })
    },
    returnFont(item, sd) {
      if (item.zhibiao.includes('总计')) {
        return '<strong>' + item[sd] + '</strong>'
      } else {
        return item[sd]
      }
    }
  }
}
</script>
<style scoped lang="css">
.hisTable {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}
.headerClass {
    font-size: 16px;
    color: white;
    background-color: #0d57bc;
}
.trOne {
    font-weight: bold;
}
.syccxhzHeader {
    width: 100%;
    height: 90px;

    font-size: 22px;
    text-align: center;
}
.sycBottom {
    width: 100%;
    height: 70px;
    line-height: 70px;

    float: right;
    font-weight: bold;
}
.sycDan {
  width: 10%;
  height: 20px;

  float: left;
  font-size: 13px;
}

.sycDan1 {

  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}
table {
  border-color: #cccccc;
}
</style>
