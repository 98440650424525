<!--专题列表-->
<template>
  <PageContainer breadcrumb>
    <div class="special-list-top">
      <h1>{{ info.specialName }}</h1>
      <p>共{{ info.relationCount }}篇相关文章</p>
    </div>
    <!-- 列表文章开始 -->
    <div class="special-list-content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="行业资讯" name="first">
          <Vlist
            :list="industryData"
            :pageData="industryPageData"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            @toHref="toHref"
          />
        </el-tab-pane>
        <!-- <el-tab-pane label="分析报告" name="second">
          <Vlist
            :list="analysisData"
            :pageData="analysisPageData"
            @handleSizeChange="analysisSizeChange"
            @handleCurrentChange="analysisCurrentChange"
            @toHref="toHref2"
          />
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <!-- 列表文章结束 -->
  </PageContainer>
</template>
<script>
import {
  getAnalysisDetail,
  getIndustryDetail,
  ttIndexSpecial,
} from "@/api/Index.js";
import Vlist from "@/components/listSczs.vue";
import {getUserInfo,logRecords} from "@/utils/auth";
import {accessRecords} from "@/api/common";
export default {
  components: {
    Vlist,
  },
  data() {
    return {
      activeName: "first",
      list: [],
      info: {},
      // 分析报告
      analysisData: [],
      analysisPageData: {
        pages: {
          page: 1,
          pageSize: 10,
          id: "",
        },
        total: 0,
        currentPage: 0,
      },
      //行业咨询
      industryData: [],
      industryPageData: {
        pages: {
          page: 1,
          pageSize: 10,
          id: "",
        },
        total: 0,
        currentPage: 0,
      },

    };
  },
  created() {
    let id = this.$route.params.id;
    this.industryPageData.pages.id = id;
    this.analysisPageData.pages.id = id;
    this.ttIndexSpecial(id);
    this.getAnalysisDetail();
    this.getIndustryDetail();
  },
  methods: {
    ttIndexSpecial(params) {
      ttIndexSpecial(params).then((res) => {
        if (res.ok) {
          this.info = res.data;
        }
      });
    },
    // 查看分析报告关联
    getAnalysisDetail() {
      getAnalysisDetail(this.analysisPageData.pages).then((res) => {
        if (res.ok) {
          this.analysisData = res.data.list;
          this.analysisPageData.total = res.data.count;
        }
      });
    },
    // 查看行业资讯关联
    getIndustryDetail() {
      getIndustryDetail(this.industryPageData.pages).then((res) => {
        if (res.ok) {
          this.industryData = res.data.list;
          this.industryPageData.total = res.data.count;
        }
      });
    },
    //分析报告
    analysisSizeChange(v) {
      this.analysisPageData.pages.pageSize = v;
      this.getAnalysisDetail();
    },
    //分析报告
    analysisCurrentChange(v) {
      this.analysisPageData.pages.page = v;
      this.getAnalysisDetail();
    },
    //行业资讯
    handleSizeChange(v) {
      this.industryPageData.pages.pageSize = v;
      this.getIndustryDetail();
    },
    //行业资讯
    handleCurrentChange(v) {
      this.industryPageData.pages.page = v;
      this.getIndustryDetail();
    },

    toHref(item) {
      logRecords('首页','热点专题','1','tt_industry_information',item.id,'',item.title,'')

      let to = ''
      // if (this.articleCategory == 'industry') {
      to = '/index/detail/specialDetail/IndustryDetail/' + item.id
      const newUrl = this.$router.resolve({
        path: to
      })
      window.open(newUrl.href, '_blank')

    },
    toHref2(item) {
    this.$router.push({
        path: "/index/detail/ReportDetail",
        query: {
             id: item.id,
          releaseColumn: item.releaseColumn,
          category: "report",
        },
      });
    },
  },
};
</script>
<style lang="scss" scope>
.special-list-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 142px;
  text-align: center;
  background: #fff;
  h1 {
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    color: #333;
    margin-bottom: 14px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #828282;
  }
}
// 列表文章开始
.special-list-content {
  background-color: #fff;
  margin-top: 15px;
}
.special-list_item {
  width: 100%;
  height: 67px;
  line-height: 67px;
  border-bottom: 1px solid #e5e5e5;
  font-family: Noto Sans S Chinese;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-left: 24px;
  span:nth-child(1) {
    float: left;
  }
  span:nth-child(2) {
    float: right;
    margin-right: 24px;
    color: #828282;
    font-size: 15px;
    font-family: Noto Sans S Chinese;
  }
}

.special-list-content {
  .el-tabs__active-bar {
    height: 0px;
  }
  .el-tabs__nav {
    margin-left: 16px;
  }
  .el-tabs__header {
    margin-bottom: 0px;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
}
// 列表文章结束
</style>
