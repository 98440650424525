<template>
  <div
    class="enterpriseDetail"
    id="SupportingSituation"
    v-if="isShowSupportingSituation"
    data-name="配套关系"
  >
    <TableCard
      compared="compared"
      :isLoading="isLoading"
      cardTitle="配套关系"
      :sourceData="dataSource"
    >
      <template #cardSelect>
        <SearchSelect
          popper-class="supporting-parts-comparedtype"
          placeholder="配套分类"
          v-model="params.deliveryList"
          :options="deliveryList"
          @input="changeListQuery"
          size="small"
        />
      </template>
      <template #headPaginator>
        <paginator
          ref="paginator"
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import {
  getComparisonDeliveryStatusSelections,
  getComparisonDeliveryStatus,
  postComparisonExportDeliveryStatus
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";
import SearchSelect from "@/components/searchSelect";

export default {
  name: "SupportingSituation",
  components: {
    Paginator,
    TableCard,
    SearchSelect
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isShowSupportingSituation: false,
      isLoading: true,
      totalCount: 0,
      debouncedGetComparisonDeliveryStatus: null,
      debouncedGetComparisonDeliveryStatusSelections: null,
      lastRouteChange: '',
      params: {
        pageSize: 10,
        pageNum: 1,
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise),
        deliveryList: []
      },
      dataSource: {},
      deliveryList: []
    };
  },
  created() {
    this.debouncedGetComparisonDeliveryStatus = this.debounce(
      this.handleRouteChange,
      100
    );
    this.debouncedGetComparisonDeliveryStatusSelections = this.debounce(
      this.getComparisonDeliveryStatusSelections,
      100
    );
    this.handleRouteChange();
  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业对标分析", "3", "", "", "", "", "配套关系", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postComparisonExportDeliveryStatus(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "配套关系.xlsx");
              });
            }
          }
        } else {
          postComparisonExportDeliveryStatus(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "配套关系.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    handleRouteChange() {
      if (this.lastRouteChange === 'checkedList' || this.lastRouteChange === '') {
        this.getComparisonDeliveryStatus(this.params, "isShowSupportingSituation");
        this.getComparisonDeliveryStatusSelections();
      }
      this.lastRouteChange = '';
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getComparisonDeliveryStatus(this.params);
    },
    changeListQuery() {
      this.$refs.paginator.resetNum();
      this.params.pageNum = 1;
      this.getComparisonDeliveryStatus(this.params);
    },
    getComparisonDeliveryStatusSelections() {
      const params = {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      };
      this.isLoading = true;
      getComparisonDeliveryStatusSelections(params).then(res => {
        if (res.ok) {
          this.deliveryList = res.data.deliveryList;
        }
      });
    },
    getComparisonDeliveryStatus(params, isShowSupportingSituation) {
      this.isLoading = true;
      getComparisonDeliveryStatus(params).then(res => {
        if (res.ok) {
          if (isShowSupportingSituation) {
            this.isShowSupportingSituation =
              res.data.tableData.length &&
              (this.$route.query.checkedList.includes("配套关系") ||
                this.$route.query.checkedList.includes("全部"))
                ? true
                : false;
          }
          this.dataSource = res.data;
          this.totalCount = res.data.totalCount || 0;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"(newId) {
      this.params.enterpriseIdList = JSON.parse(newId);
      this.lastRouteChange = 'selectedEnterprise';
      this.debouncedGetComparisonDeliveryStatus();
    },
    "$route.query.checkedList"() {
      this.lastRouteChange = 'checkedList';
      this.debouncedGetComparisonDeliveryStatus();
    }
  }
};
</script>

<style lang="scss">
.supporting-parts-comparedtype {
  .index-list-container {
    width: 400px;
  }
}
</style>
