<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" :isCollection="false"/>
  </PageContainer>
</template>
<script>
import { ttTaidaiBbsById } from "@/api/Taidai.js";
export default {
  data() {
    return {
      data: {},
           props:{
        link:'url',
        summary:'sumary'
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttTaidaiBbsById(id);
  },
  methods: {
    ttTaidaiBbsById(params) {
      ttTaidaiBbsById(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
