<!--泰达论坛列表-->
<template>
  <router-view v-if="$route.name == '泰达论坛内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="泰达论坛" smallTip="泰达论坛，实时更新" :bgurl="bgurl" />
    <Vlist :list="ttTaidaiBbsPageData" :page="ttTaidaiBbsPagePage" :pageData="ttTaidaiBbsPagePage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { ttTaidaiBbsPage } from "@/api/Taidai.js";
import Vlist from "@/components/list";
export default {
    components: {
        VlistTitle,
        Vlist,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            ttTaidaiBbsPageData: [],
            ttTaidaiBbsPagePage: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },
        };
    },
    created() {
        this.ttTaidaiBbsPage(this.ttTaidaiBbsPagePage.pages);
    },
    methods: {
        ttTaidaiBbsPage(params) {
            ttTaidaiBbsPage(params).then((res) => {
                if (res.ok) {
                    this.ttTaidaiBbsPageData = res.data.list;
                    this.ttTaidaiBbsPagePage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.ttTaidaiBbsPagePage.pages.pageSize = v;
            this.ttTaidaiBbsPage(this.ttTaidaiBbsPagePage.pages);
        },
        handleCurrentChange(v) {
            this.ttTaidaiBbsPagePage.pages.page = v;
            this.ttTaidaiBbsPage(this.ttTaidaiBbsPagePage.pages);
        },
        toHref(item) {
            this.$router.push("/index/detail/taidaDetail/" + item.id);
        },
    },
};
</script>
