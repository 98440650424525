import { render, staticRenderFns } from "./sycIndustryAndReportList.vue?vue&type=template&id=7913574d&scoped=true"
import script from "./sycIndustryAndReportList.vue?vue&type=script&lang=js"
export * from "./sycIndustryAndReportList.vue?vue&type=script&lang=js"
import style0 from "./sycIndustryAndReportList.vue?vue&type=style&index=0&id=7913574d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7913574d",
  null
  
)

export default component.exports