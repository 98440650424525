<template>
  <PageContainer breadcrumb footer>
    <VcontentTwo :data="data" :props="props" :isCollection="false" />

    <template slot="right">
      <VcontentItem
        title="相关推荐"
        type="list"
        :list="relevant"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { ttIndustryInformation } from "@/api/Index.js";
import VcontentTwo from "@/components/contentTwo/index.js";
export default {
  components: {
    VcontentTwo
  },
  data() {
    return {
      data: {},
      props: {
        tag: "keywordsLabel",
        htmlContent: "content",
        summary: "introduction",
        publishDate:'updateTime'
      },
      relevant: [],
    };
  },
  watch: {
    $route(to, from) {
      let id = to.query.id;
      this.ttIndustryInformation(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.query.id;
    this.ttIndustryInformation(id);
  },
  methods: {
    ttIndustryInformation(params) {
      ttIndustryInformation(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relevant = res.data.list;
        }
      });
    },
    tohref(item) {
      //相关推荐
      // "/index/detail/IndustryDetail/" + item.id
      this.$router.push({
        path: "/index/detail/IndustryDetail",
        query: {
          id: item.id,
          releaseColumn: item.releaseColumn,
          category: "industry",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
