
//行业洞察
import $http from '@/plugins/http.js'


//获取所有关键字
export function getKeyWords() {
    return $http.get('/marketInsight/getKeyWords')
}
//根据id查询
export function queryById(params) {
    return $http.get('/marketInsight/queryById', params)
}
//根据条件查询
export function queryByPage(params) {
    return $http.get('/marketInsight/queryByPage',params)
}

// //洞察报告列表
// export function insightReport(params) {
//     return $http.get('/marketInsight/queryInsightReport',params)
// }

//洞察报告列表
export function insightReport(params) {
    return $http.get('/marketInsight/queryByPage',params)
}

// 行业洞察-行业动态
export function marketDynamicExplain(params) {
    return $http.get('/homepage/ttIndustryInformation/marketDynamicPage',params)
}

