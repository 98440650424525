<template>
  <div
    :title="getTitle"
    :class="['outside-drag-block', disabled ? 'disabled' : '']"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <span>{{ field.name }}</span>
    <img :src="disabled ? require('@/assets/img/newData/plus-gray.png') : require('@/assets/img/newData/plus.png')" alt="">
    <div v-if="onlyPlusCanUse" class="plus">PLUS</div>
  </div>
</template>

<script>
import Vue from 'vue'
import DraggingBlock from './DraggingBlock'

export default {
  name: 'OutsideDragBlock',
  props: {
    field: {
      type: Object,
      required: true
    },
    // 目标拖拽区域的名称标识
    targetAreaName: {
      type: [String, Array],
      required: true,
      validator(value) {
        return value.length > 0
      }
    },
    // 点击块的时候，优先向哪个 areaKey 所代表的区域添加该块
    preferredTargetAreaKeyWhenClick: {
      type: String,
      default: ''
    },
    canUsePlusBlock: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isMouseDown: false,
      mousedownPosition: [0, 0],
      draggingPosition: [0, 0],
      vm: null
    }
  },
  computed: {
    getTitle() {
      if (this.disabled) {
        return '已添加到查询区'
      }
      return this.field.name
    },
    onlyPlusCanUse() {
      return !!this.field.onlyPlusCanUse
    },
    getTargetAreaNameStr() {
      const { targetAreaName } = this
      return targetAreaName instanceof Array ? targetAreaName.join('_') : targetAreaName
    },
    outsideBlockClickEventChannel() {
      return 'outsideBlockClick_' + this.getTargetAreaNameStr
    },
    outsideBlockDragEndEventChannel() {
      return 'outsideBlockDragEnd_' + this.getTargetAreaNameStr
    },
    outsideBlockDraggingEventChannel() {
      return 'outsideBlockDragging_' + this.getTargetAreaNameStr
    }
  },
  methods: {
    handleMouseDown({ offsetX, offsetY }) {
      if (this.disabled) return
      // 如果该字段是 plus 字段，且无法使用 plus 字段
      if (this.onlyPlusCanUse && !this.canUsePlusBlock) {
        // 则在点击的时候弹窗提示
        this.$emit('alertNeedPlus')
        return
      }
      this.isMouseDown = true
      this.$set(this, 'mousedownPosition', [offsetX, offsetY])
      document.removeEventListener('mouseup', this.handleMouseUp)
      document.removeEventListener('mousemove', this.handleDragging)
      document.addEventListener('mouseup', this.handleMouseUp)
      document.addEventListener('mousemove', this.handleDragging)
    },
    handleMouseUp(e) {
      // 如果鼠标还没按下
      if (!this.isMouseDown) {
        // 则结束该方法
        return
      }
      this.isMouseDown = false
      document.removeEventListener('mouseup', this.handleMouseUp)
      document.removeEventListener('mousemove', this.handleDragging)
      // 如果此时还没创建跟随鼠标移动的块
      if (!this.vm) {
        // 则认为此次触发的是 点击事件
        this.$bus.$emit(this.outsideBlockClickEventChannel, {
          field: this.field,
          preferredTargetAreaKeyWhenClick: this.preferredTargetAreaKeyWhenClick
        })
      } else { // 否则就认为触发的是 拖拽结束事件
        this.vm.remove()
        this.vm = null
        this.$bus.$emit(this.outsideBlockDragEndEventChannel, { field: this.field })
      }
    },
    // 处理鼠标按下并开始移动
    handleDragging(e) {
      // 如果鼠标还没按下
      if (!this.isMouseDown) {
        // 则结束该方法
        return
      }
      // 如果还没创建可移动的块
      if (!this.vm) {
        // 并且如果当前拖拽的点和down的点之间的距离不超过 触发拖动的鼠标移动距离阈值
        if (
          Math.pow(e.offsetX - this.mousedownPosition[0], 2) +
          Math.pow(e.offsetY - this.mousedownPosition[1], 2) < 165
        ) {
          // 则认为当前还没触发 块的拖动
          return
        }
        // 否则就认为已经触发了块的拖动, 创建一个 跟随鼠标移动的块
        this.createMovingBlock()
      }
      // 实时记录鼠标移动的位置
      this.$nextTick(() => {
        // 要想获取 this.vm.$el, 必须用 nextTick
        this.$set(this, 'draggingPosition', [e.pageX - this.vm.$el.offsetWidth / 2, e.pageY - this.vm.$el.offsetHeight / 2])
        this.$bus.$emit(this.outsideBlockDraggingEventChannel, {
          // 鼠标的坐标
          mousePosition: [e.pageX, e.pageY],
          // // vm 的宽高 没有用了，注释掉
          // outsideMovingBlockSize: [this.vm.$el.offsetWidth, this.vm.$el.offsetHeight],
          // // 左上角的坐标 没有用了，注释掉
          // draggingPosition: this.draggingPosition,
          field: this.field
        })
      })
    },
    createMovingBlock() {
      if (this.vm) return
      this.vm = new Vue({
        // h是createElement函数，它可以返回虚拟dom
        render: (h) => {
          // 将Component作为根组件渲染出来
          // h(标签名称或组件配置对象，传递属性、事件等，孩子元素)
          return h(DraggingBlock, {
            props: {
              field: this.field,
              // 用于实时修改 DraggingBlock 的位置
              draggingPosition: this.draggingPosition
            }
          })
        }
      }).$mount() // 挂载是为了把虚拟dom变成真实dom
      this.vm.remove = () => {
        document.body.removeChild(this.vm.$el)
        this.vm.$destroy()
      }
      document.body.appendChild(this.vm.$el)
    }
  }
}
</script>

<style lang="scss" scoped>
.outside-drag-block {
  position: relative;
  width: calc(50% - 5px);
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  padding: 0 12px;
  box-sizing: border-box;
  color: #4F4F4F;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  transition-property: color, background;;
  transition-duration: 0.3s;
  background-color: #EFF7FF;
  border: 1px solid rgba(52,145,250,0.3);

  &.disabled {
    cursor: no-drop;
    color: #C0C4CD;
    background-color: #F8F8F8;
    border: 1px solid #DCDFE6;
  }
}

// 文字超出后折叠
span {
  flex: 1;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

img {
  width: 10px;
  height: 10px;
  pointer-events: none;
}

.plus {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #F53F3F;
  color: #fff;
  border-radius: 3px;
  font-size: 12px !important;
  line-height: 1;
  padding: 1px 2px;
  transform: translate(25%, -50%);
}
</style>
