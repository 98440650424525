<template>
  <div class="wrapper">
    <div class="menu-toggle-trigger" @click="handleMenuToggle">
      <div class="trigger">
        <i :class="['el-icon-caret-left', isMenuCollapse ? 'collapse' : '']" />
      </div>
    </div>
    <el-aside
      :width="`${asideWidth}px`"
      class="aside"
    >
      <!-- 顶部图片 -->
      <img id="img" :width="`${initAsideWidth}px`" src="@/assets/img/newData/menu-top.png" alt="">
      <div ref="menuContainerRef" class="menu-wrapper hidden-scrollbar">
        <el-menu
          ref="menuRef"
          :default-active="activeMenuPath"
          unique-opened
          :default-openeds="defaultOpeneds"
          @select="handleSelectMenu"
        >
          <MenuItem :menu="dataReportRoutes" />
          <MenuItem id="data-query-menus" :menu="dataQueryRoutes" />
          <MenuItem id="data-download-menus" :menu="dataDownloadRoutes" />
        </el-menu>
      </div>
    </el-aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from './menuItem.vue'

export default {
  name: 'SideBar',
  components: {
    MenuItem
  },
  props: {
    // 是否正在进行新人引导
    isDriving: {
      type: Boolean,
      required: true
    }
  },
  data() {
    // 定义菜单的宽度
    const initAsideWidth = 280
    return {
      initAsideWidth,
      asideWidth: initAsideWidth,
      // 菜单是否收起
      isMenuCollapse: false,
      hideTimeout: undefined,
      selectedOtherMenu: false
    }
  },
  computed: {
    ...mapGetters(['permission_routes']),
    // 数据栏目下的所有子菜单
    dataChildrenRoutes() {
      return this.permission_routes.find(item => item.name === '数据')?.children || []
    },
    defaultOpeneds() {
      if (this.selectedOtherMenu || this.isDriving) {
        return []
      }
      return [this.dataReportRoutes[0].path, this.dataQueryRoutes[0]?.path]
    },
    // 获取 数据报告 的所有路由
    dataReportRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据报告')
    },
    // 获取 数据查询 的所有路由
    dataQueryRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据查询')
    },
    // 获取 数据表下载 的路由
    dataDownloadRoutes() {
      return this.dataChildrenRoutes.filter(item => item.meta.moduleName === '数据表下载')
    },
    activeMenuPath() {
      // name 有重复的，比如数据栏目下的国内产销数据和新能源专区都有 乘用车-批发量和零售量
      // 所以这里用 path
      return this.$route.path
    }
  },
  methods: {
    openActiveMenu() {
      const path = this.findActiveMenu()
      if (path) {
        this.$refs.menuRef.open(path)
      }
    },
    closeActiveMenu() {
      const path = this.findActiveMenu()
      if (path) {
        this.$refs.menuRef.close(path)
        this.$refs.menuContainerRef.scrollTo(0, 0)
      }
    },
    // 找到当前激活的子级菜单的父级菜单的 path
    findActiveMenu() {
      for (let i = 0; i < this.dataChildrenRoutes.length; i++) {
        const subMenu = this.dataChildrenRoutes[i]
        for (let j = 0; j < subMenu.children.length; j++) {
          const menu = subMenu.children[j]
          if (menu.path === this.activeMenuPath) {
            return subMenu.path
          }
        }
      }
    },
    // 左侧菜单的 select 事件
    handleSelectMenu() {
      this.selectedOtherMenu = true
      this.$store.dispatch('notice/SET_PC', 0)
      this.$store.dispatch('notice/SET_CPLB', '')
      this.$store.dispatch('common/SET_qiehuan', '')
    },
    // 切换菜单折叠收起
    handleMenuToggle() {
      this.isMenuCollapse = !this.isMenuCollapse
      this.asideWidth = this.isMenuCollapse ? 0 : this.initAsideWidth
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";

.wrapper {
  position: relative;
}

.aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all .3s;
  overflow-x: hidden;
}

.menu-toggle-trigger {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  z-index: 9;

  .trigger {
    width: 12px;
    height: 68px;
    cursor: pointer;
    background-color: #0D57BC;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;

    &::before, &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid #F6F6F6;
      border-bottom: 12px solid transparent;
      border-top: 12px solid transparent;
    }

    &::before {
      top: -12px;
    }

    &::after {
      bottom: -12px;
    }

    .el-icon-caret-left {
      transition: transform .3s;
    }

    .collapse {
      transform: rotateY(180deg);
    }
  }
}

.menu-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  background-color: #fff;
}
</style>

<style lang="scss">
/* 菜单项左侧tooltip图标的弹出框 */
.menu-icon-popper {
  width: 260px;
  /* 把 \n 表现为换行 */
  white-space: pre-wrap;
  font-size: 12px !important;

  .tooltip-item {
    word-break: break-all;

    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}
</style>
