//企业栏目
import $http from "@/plugins/http.js";

//企业分析
export function AnalysisQueryByPage(params) {
  return $http.get("/enterpriseAnalysis/queryByPage", params);
}
//企业详情
export function AnalysisQueryById(params) {
  return $http.get("/enterpriseAnalysis/queryById", params);
}
// 分析报告-原创报告列表
export function OriginalEnterpriseReport(params) {
  return $http.get("/newEnterpriseReport/getOriginalEnterpriseReport", params);
}
//根据类型获取企业
export function selectListByType(params) {
  return $http.get("/enterpriseInfo/selectListByType", params);
}
//以字母为组, 返回企业
export function queryGroupByInitial(params) {
  return $http.get("/enterpriseInfo/queryGroupByInitial", params);
}
//根据年份输出企业财报
export function archiveReport(params) {
  return $http.get("/enterpriseFinancialReport/archiveReport", params);
}
//获取所有的年份
export function queryGroupYears() {
  return $http.get("/enterpriseFinancialReport/queryGroupYears");
}

//根据id查询企业
export function queryByIdEnterpriseInfo(params) {
  return $http.get("/enterpriseInfo/queryById", params);
}

//企业动态分页
export function queryByPageDynamic(params) {
  return $http.get("/enterpriseDynamic/queryByPage", params);
}

//企业动态详情
export function queryByPageDynamicById(params) {
  return $http.get("/enterpriseDynamic/queryById/", params);
}

//根据id获取企业分布图
export function selectEnterpriseMap(params) {
  return $http.get("/enterpriseInfo/selectEnterpriseMap", params);
}

//高管信息分页查询
export function executiveInfoQueryByPage(params) {
  return $http.get("/enterpriseExecutiveInfo/queryByPage", params);
}
//企业产能分页查询
export function enterpriseCapacityQueryByPage(params) {
  return $http.get("/enterpriseCapacity/queryByPage", params);
}

//分省份产能查询
export function getProvinceCn() {
  return $http.get("/enterpriseCapacity/getProvinceCn");
}

//分企业产能查询
export function getEnterpriseCn(params) {
  return $http.get("/enterpriseCapacity/getEnterpriseCn", params);
}

//战略信息分页查询
export function strategicPlanningByPage(params) {
  return $http.get("/strategicPlanning/queryByPage", params);
}
//经营情况
export function businessCircumstance(params) {
  return $http.get("/businessCircumstance/queryByPage", params);
}
//经营情况导出
export function businessCircumstanceExport(params) {
  return $http.Downloadget("/businessCircumstance/export", params);
}
//产销量车型
export function getCarTypes() {
  return $http.get("/api/information/ttSalesTarget/getAllVehicleModel");
}
//产销量条件查询
export function ttSalesTarget(params) {
  return $http.get("/api/information/ttSalesTarget", params);
}
//产销量分页查询
export function ttSalesTargetPage(params) {
  return $http.get("/api/information/ttSalesTarget/page", params);
}
//产销量导出
export function ttSalesExport(params) {
  return $http.Downloadget("/api/information/ttSalesTarget/export", params);
}
//生产基地
export function productionBaseQueryByPage(params) {
  return $http.get("/api/productionBase", params);
}
//车型平台
export function TypePlatformQueryByPage(params) {
  return $http.get("/TypePlatform/queryByPage", params);
}
//车型平台导出
export function TypePlatformExport(params) {
  return $http.Downloadget("/TypePlatform/export", params);
}

//部门架构
export function getDeptByEnterPrise(params) {
  return $http.get("/api/enterprise/ttOrgan/getDeptByEnterPrise", params);
}

//历史变更
export function getHistroyChange(params) {
  return $http.get("/enterpriseExecutiveInfo/queryHistoryByPage", params);
}

//企业财报
export function queryFinancialReport(params) {
  return $http.get("/enterpriseFinancialReport/queryFinancialReport", params);
}
//车型说明

export function getRemarkCx(params) {
  return $http.get("/TypePlatform/getRemark", params);
}

//产销量最新

export function newselectTable(params) {
  return $http.post("/api/information/ttSalesTarget/selectTable", params);
}

//产销量最新时间表头
export function newgetYearMonthList(params) {
  return $http.get("/api/information/ttSalesTarget/getYearMonthList", params);
}
//获取最新年月
export function newgetDefaultDate(params) {
  return $http.post("/api/information/ttSalesTarget/getDefaultDate", params);
}
//产销量导出

export function newdynamicExport(params) {
  return $http.DownloadDb(
    "/api/information/ttSalesTarget/dynamicExport",
    params
  );
}

//企业类型-企业列表
export function getEnterpriseSelectionsList() {
  return $http.get("/newEnterpriseInfo/getEnterpriseSelectionsList");
}
//企业动态
export function getEnterpriseDynamicList(params) {
  return $http.get("/newEnterpriseDynamic/getEnterpriseDynamicList", params);
}

//企业信息查询列表
export function getEnterpriseList(params) {
  return $http.get("/newEnterpriseInfo/getEnterpriseList", params);
}

//企业详情-基本信息
export function getEnterpriseBasicInfo(params) {
  return $http.get("/newEnterpriseInfo/getEnterpriseBasicInfo", params);
}

//企业详情-企业产能-筛选列表
export function getProductionCapacitySelections(params) {
  return $http.get(
    "/newEnterpriseInfo/getProductionCapacitySelections",
    params
  );
}

//企业详情-企业产能
export function getProductionCapacity(params) {
  return $http.post("/newEnterpriseInfo/getProductionCapacity", params);
}

//企业详情-产销量
export function getEnterpriseVehicleData(params) {
  return $http.get("/newEnterpriseInfo/getEnterpriseVehicleData", params);
}

//企业详情-配套关系-筛选列表
export function getDeliveryStatusSelections(params) {
  return $http.get("/newEnterpriseInfo/getDeliveryStatusSelections", params);
}

//企业详情-配套关系
export function getDeliveryStatus(params) {
  return $http.post("/newEnterpriseInfo/getDeliveryStatus", params);
}

//企业详情-主要车型-筛选列表
export function getVehicleModelSelections(params) {
  return $http.get("/newEnterpriseInfo/getVehicleModelSelections", params);
}

//企业详情-主要车型
export function getVehicleModel(params) {
  return $http.post("/newEnterpriseInfo/getVehicleModel", params);
}

//企业详情-经营情况
export function getBusinessCircumstance(params) {
  return $http.get("/newEnterpriseInfo/getBusinessCircumstance", params);
}

//企业详情-生产基地
export function getProductionBase(params) {
  return $http.get("/newEnterpriseInfo/getProductionBase", params);
}

//企业详情-车型平台
export function getPlatformData(params) {
  return $http.get("/newEnterpriseInfo/getPlatformData", params);
}

//企业详情-战略规划
export function getStrategyPlanning(params) {
  return $http.get("/newEnterpriseInfo/getStrategyPlanning", params);
}

//企业详情-零部件主要产品
export function getMainProduct(params) {
  return $http.get("/newEnterpriseInfo/getMainProduct", params);
}

//企业详情-零部件配套关系-筛选列表
export function getPartsMakerDeliveryStatusSelections(params) {
  return $http.get("/newEnterpriseInfo/getPartsMakerDeliveryStatusSelections", params);
}

//企业详情-零部件配套关系
export function getPartsMakerDeliveryStatus(params) {
  return $http.post("/newEnterpriseInfo/getPartsMakerDeliveryStatus", params);
}

//企业详情-零部件业绩情况
export function getPartsMakerFinancialData(params) {
  return $http.get("/newEnterpriseInfo/getPartsMakerFinancialData", params);
}

//企业详情-零部件发展历史
export function getEnterpriseDevelopmentHistory(params) {
  return $http.get("/newEnterpriseInfo/getEnterpriseDevelopmentHistory", params);
}

//企业对比-基本信息
export function getBasicInfo(params) {
  return $http.post("/newEnterpriseComparison/getBasicInfo", params);
}

//企业对比-企业产能
export function getProductionRegionData(params) {
  return $http.post("/newEnterpriseComparison/getProductionRegionData", params);
}

//企业对比-企业产销-销量
export function getSalesData(params) {
  return $http.post("/newEnterpriseComparison/getSalesData", params);
}

//企业对比-企业产销-产量
export function getProductionData(params) {
  return $http.post("/newEnterpriseComparison/getProductionData", params);
}

//企业对比-配套情况-筛选列表
export function getComparisonDeliveryStatusSelections(params) {
  return $http.post("/newEnterpriseComparison/getDeliveryStatusSelections", params);
}

//企业对比-配套情况
export function getComparisonDeliveryStatus(params) {
  return $http.post("/newEnterpriseComparison/getDeliveryStatus", params);
}

//企业对比-主要车型
export function getComparisonVehicleModel(params) {
  return $http.post("/newEnterpriseComparison/getVehicleModel", params);
}

//企业对比-主要车型
export function getBusinessCircumstanceComparison(params) {
  return $http.post("/newEnterpriseComparison/getBusinessCircumstance", params);
}

//企业对比-零部件主要产品
export function getPartsMakerProduct(params) {
  return $http.post("/newEnterpriseComparison/getPartsMakerProduct", params);
}

//企业对比-零部件配套情况-筛选列表
export function getComparisonPartsMakerDeliveryStatusSelections(params) {
  return $http.post("/newEnterpriseComparison/getPartsMakerDeliveryStatusSelections", params);
}

//企业对比-零部件配套情况
export function getComparisonPartsMakerDeliveryStatus(params) {
  return $http.post("/newEnterpriseComparison/getPartsMakerDeliveryStatus", params);
}

//企业对比-零部件业绩情况
export function getComparisonPartsMakerFinancialData(params) {
  return $http.post("/newEnterpriseComparison/getPartsMakerFinancialData", params);
}

//企业详情-企业产能-导出
export function postExportProductionCapacity(params) {
  return $http.DownloadDb("/newEnterpriseInfo/exportProductionCapacity", params);
}
//企业详情-配套情况-导出
export function postExportDeliveryStatus(params) {
  return $http.DownloadDb("/newEnterpriseInfo/exportDeliveryStatus", params);
}
//企业详情-主要车型-导出
export function postExportVehicleModel(params) {
  return $http.DownloadDb("/newEnterpriseInfo/exportVehicleModel", params);
}
//企业详情-经营情况-导出
export function getExportBusinessCircumstance(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportBusinessCircumstance", params);
}
//企业详情-产销量-产量-导出
export function getExportEnterpriseVehicleProductionData(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportEnterpriseVehicleProductionData", params);
}
//企业详情-产销量-销量-导出
export function getExportEnterpriseVehicleSalesData(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportEnterpriseVehicleSalesData", params);
}

//企业详情-主营产品-导出
export function getExportMainProduct(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportMainProduct", params);
}
//企业详情-配套关系-导出
export function postExportPartsMakerDeliveryStatus(params) {
  return $http.DownloadDb("/newEnterpriseInfo/exportPartsMakerDeliveryStatus", params);
}
//企业详情-业绩情况-导出
export function getExportPartsMakerFinancialData(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportPartsMakerFinancialData", params);
}
//企业详情-公司历程-导出
export function getExportEnterpriseDevelopmentHistory(params) {
  return $http.Downloadget("/newEnterpriseInfo/exportEnterpriseDevelopmentHistory", params);
}

//企业对比-企业产能-导出
export function postExportProductionRegionData(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportProductionRegionData", params);
}
//企业对比-企业产销-销量
export function postComparisonExportSalesData(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportSalesData", params);
}
//企业对比-企业产销-产量
export function postComparisonExportProductionData(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportProductionData", params);
}
//企业对比-配套关系
export function postComparisonExportDeliveryStatus(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportDeliveryStatus", params);
}
//企业对比-主要车型
export function postComparisonExportVehicleModel(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportVehicleModel", params);
}
//企业对比-经营情况
export function postComparisonExportBusinessCircumstance(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportBusinessCircumstance", params);
}


//企业对比-零部件-主要产品
export function postComparisonExportPartsMakerProduct(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportPartsMakerProduct", params);
}
//企业对比-零部件-配套情况
export function postComparisonExportPartsMakerDeliveryStatus(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportPartsMakerDeliveryStatus", params);
}
//企业对比-零部件-业绩情况
export function postComparisonExportComparisonPartsMakerFinancialData(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportPartsMakerFinancialData", params);
}
//企业对比-零部件-公司历程
export function postComparisonExportEnterpriseDevelopmentHistory(params) {
  return $http.DownloadDb("/newEnterpriseComparison/exportEnterpriseDevelopmentHistory", params);
}
