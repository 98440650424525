<template>
  <div :class="['center-tip', visible ? 'show' : '']">
    <img :src="require('@/assets/img/newData/error-circle-fill.png')" alt="">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'CenterTip',
  props: {
    // 展示时长
    showDuration: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      visible: false,
      timer: null,
      text: null
    }
  },
  methods: {
    show(text) {
      if (!text) return
      this.visible = true
      this.text = text
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.visible = false
      }, this.showDuration)
    },
    hidden() {
      this.visible = false
      clearTimeout(this.timer)
      this.timer = null
    }
  }
}
</script>

<style lang="scss" scoped>
.center-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  padding: 6px 12px;
  align-items: center;
  display: flex;
  opacity: 0;
  transition: opacity 0.3s;
  color: #333333;
  background-color: #FEE8E8;
  border-radius: 4px;
  pointer-events: none;

  &.show {
    pointer-events: auto;
    opacity: 1;
    box-shadow: 0px 6px 16px 0px rgba(51,51,51,0.16);
  }
}

img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
</style>
