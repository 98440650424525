<template>
  <div id="ColumnLine" ref="ColumnLine" class="borderStyle"></div>
</template>

<script>
import { DualAxes } from '@antv/g2plot'
let columnPlot
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    Height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radarPlot: null,
    }
  },
  // 监听
  watch: {
    value: {
      handler(newVal, oldVal) {

        // 方式一 有新数据从新渲染图表
        columnPlot.changeData(newVal)
        // 方式二 先销毁图表 在创建图表
        // chartChange.destroy()
        // this.initRadarPlot()
      },
      deep: true, //深度监听
      // immediate: true,
    },
  },
  mounted() {
    this.initRadarPlot()
  },
  methods: {
    initRadarPlot() {
      const data = [
        { time: '2019-03', value: 350, count: 800 },
        { time: '2019-04', value: 900, count: 600 },
        { time: '2019-05', value: 300, count: 400 },
        { time: '2019-06', value: 450, count: 380 },
        { time: '2019-07', value: 470, count: 220 },
      ];
      // 使用ref的方式组件可以多次使用
      // const chartChange = new Column(this.$refs.Histogram, {
      columnPlot = new DualAxes (this.$refs.ColumnLine, {
        data: [this.value, this.value],
        xField: 'time',
        yField: ['value', 'count'],
        geometryOptions: [
          {
            geometry: 'column',
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
          },
        ],
      });

      columnPlot.render();
    },
    // 图表高度
    getHeight() {
      let height = parseInt(this.Height)
      return { height: height + 'px' }
    },
  },
}
</script>

<style lang="scss" scoped>
.borderStyle{
  border: solid 5px #92beff;
  margin: 10px;
  padding: 10px;
}
</style>