//预测
import $http from '@/plugins/http.js'

//乘用车企业销量预测
export function getGraphData(){
    return $http.get('/api/ForecastEnterprise/ttEnterpriseForecastDataUpload/getGraphData')
}
export function getRemark(){
    return $http.get('/api/ForecastEnterprise/ttEnterpriseForecastDataUpload/getRemark')
}
//整体销量月度预测
export function selectMouseTotalCyc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMouseTotalCyc')
}
//整体销量月度预测乘用车导出
export function exportPredictCyc(params){
    return $http.DownloadDb('/api/predict/ttYearMonthPredict/exportPredictCyc',params)
}
//商用车导出
export function exportPredictSyc(params){
    return $http.DownloadDb('/api/predict/ttYearMonthPredict/exportPredictSyc',params)
}
//更新说明
export function ttForecastChangeLog(params){
    return $http.post('/api/forecastChange/ttForecastChangeLog/page ',params)
}
//整体销量未来五年
export function yearData(pagams){
    return $http.get('/api/predict/ttYearData/yearData',pagams)
}
//整体销量未来五年table
export function yearDataTable(pagams){
    return $http.get('/api/predict/ttYearData/yearDataTable',pagams)
}
export function yearDataUpdataList(pagams){
    return $http.get('/api/predict/ttYearData/yearDataUpdataList',pagams)
}
//乘用车导出
export function yearDataExport(pagams){
    return $http.Downloadget('/api/predict/ttYearData/export',pagams)
}
//商用车
export function selectMouseTotalSyc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMouseTotalSyc')
}
//动力电池退役量预测
export function selecBatteryYear(){
    return $http.get('/api/predict/ttYearData/getBatteryYear')
}

//展示预测企业
export function showForecastEnterPrise(){
    return $http.get('/api/forecastChange/ttForecastChangeLog/showForecastEnterPrise')
}
//根据预测企业展示对应车型
export function showModelByEnterPrise(params){
    return $http.get('/api/forecastChange/ttForecastChangeLog/showModelByEnterPrise',params)
}
//根据车型展示月度图表
export function showGraphData(params){
    return $http.get('/api/forecastChange/ttForecastChangeLog/showGraphData',params)
}

//根据车型展示年度度图表
export function showGraphDataByYear(params){
    return $http.get('/api/forecastChange/ttForecastChangeLog/showGraphDataByYear',params)
}

//获取乘用车整体情况预测
export function selectMonthCycTotal(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthCycTotal')
}

//获取乘用车年度整体情况预测
export function selectYearCycTotal(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearCycTotal')
}

//获取乘用车轿车情况预测
export function selectMonthCycCar(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthCycCar')
}

//获取乘用车SUV情况预测
export function selectMonthCycSuv(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthCycSuv')
}

//获取乘用车Mpv情况预测
export function selectMonthCycMpv(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthCycMpv')
}
//获取乘用车交叉型乘用车情况预测
export function selectMonthCycJc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthCycJc')
}


//获取乘用车轿车年度情况预测
export function selectYearCycCar(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearCycCar')
}

//获取乘用车SUV年度情况预测
export function selectYearCycSuv(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearCycSuv')
}

//获取乘用车Mpv年度情况预测
export function selectYearCycMpv(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearCycMpv')
}
//获取乘用车交叉型乘用车年度情况预测
export function selectYearCycJc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearCycJc')
}

//获取商用车整体情况预测
export function selectMonthSycTotal(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthSycTotal')
}

//获取商用车货车情况预测
export function selectMonthSycHc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthSycHc')
}

//获取商用车客车情况预测
export function selectMonthSycKc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectMonthSycKc')
}

//获取商用车年度整体情况预测
export function selectYearSycTotal(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearSycTotal')
}

//获取商用车货车年度情况预测
export function selectYearSycHc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearSycHc')
}

//获取商用车客车年度情况预测
export function selectYearSycKc(){
    return $http.get('/api/predict/ttYearMonthPredict/selectYearSycKc')
}


//获取企业情况预测
export function selectQyPredict(){
    return $http.get('/api/ForecastEnterprise/ttEnterpriseForecastDataUpload/selectQyPredict')
}

//获取企业预测销量
export function selectQyYcSales(){
    return $http.get('/api/ForecastEnterprise/ttEnterpriseForecastDataUpload/selectQyYcSales')
}


//获取企业已完成情况
export function selectQyYwcSales(){
    return $http.get('/api/ForecastEnterprise/ttEnterpriseForecastDataUpload/selectQyYwcSales')
}
