<template>
    <div class="special-container">
        <swiper :options="swiperOption">
            <swiper-slide v-for="(item, index) in list" :key="item.id">
                <div class="swiper-link" @click="toHref(item.id)">
                    <div :class="['navImg', 'navImg' + index]">
                        <img :src="require('@/assets/img/special/' + imgList[index])" alt="">
                    </div>
                    <div class="navText">
                        <p class="hiddenText">
                            <span>{{ item.specialName }}</span>
                        </p>
                        <p class="navRelationCount">{{ item.relationCount }}篇文章</p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <div class="swiper-button-prev swiper-special-prev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="swiper-button-next swiper-special-next">
            <i class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>
  
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import OfficialSwiper, { Autoplay, Navigation } from 'swiper';
import { ttIndexSpecialPage } from "@/api/Index.js";
import 'swiper/swiper-bundle.min.css';

OfficialSwiper.use([Autoplay, Navigation]);

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        allCount: {
            type: Number,
            default: 10
        },
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 60,
                navigation: {
                    nextEl: '.swiper-special-next',
                    prevEl: '.swiper-special-prev',
                },
            },
            list: [],
            imgList: ['specialNav1.png',
                'specialNav2.png',
                'specialNav3.png',
                'specialNav4.png',
                'specialNav5.png',
                'specialNav6.png',
                'specialNav7.png',
                'specialNav8.png'
            ],
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            ttIndexSpecialPage({
                page: 1,
                pageSize: this.allCount,
            }).then((res) => {
                if (res.ok) {
                    this.list = res.data.list;
                }
            });
        },
        toHref(id) {
            this.$router.push('/index/detail/specialDetail/' + id);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.special-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0 25px;

    .navImg {
        display: grid;
        place-items: center;
        width: 44px;
        height: 44px;
        color: #fff;
        border-radius: 6px;
        flex-shrink: 0;
    }

    .navText {
        padding-left: 15px;
    }

    ::v-deep .swiper-container {
        height: 100%;

        .swiper-slide {
            white-space: nowrap;
            width: auto;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .swiper-link {
            display: flex;
            align-items: center;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transition: opacity .12s ease-out;
      pointer-events: none;
    }
    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;
        pointer-events: auto;
        &.swiper-button-disabled {
          opacity: 0.35;
        }
      }
    }
}

.navRelationCount {
    color: rgb(153, 153, 153);
    font-size: 12px;
}

.navImg0 {
    background-color: #3262ff;
}

.navImg1 {
    background-color: #8e94ff;
}

.navImg2 {
    background-color: #06beb6;
}

.navImg3 {
    background-color: #dc3536;
}

.navImg4 {
    background-color: #a2acc5;
}

.navImg5 {
    background-color: #f8b500;
}

.navImg6 {
    background-color: #00d857;
}

.navImg7 {
    background-color: #ff4b1f;
}
</style>
  