<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:29:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-12 09:34:38
-->
<!--市场动态列表-->
<template>
  <div class="information">
    <div :class="`banner banner-${iconType}`" />
    <div class="info-box">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="info-item"
        @click="toHref(item)"
      >
        <div :class="`info-item-icon info-item-icon-${index + 1}`" />
        <div class="info-item-title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, logRecords } from '@/utils/auth.js'
export default {
  name: 'Information',
  components: {},
  props: {
    iconType: {
      type: String,
      default: '1'
    },
    data: {
      type: Array,
      default: () => []
    },
    linkUrl: {
      type: String,
      default: '/international/government/details/'
    },
    logRecordsColumn1: {
      type: String,
      default: ''
    },
    logRecordsColumn2: {
      type: String,
      default: ''
    },
    logRecordsTableName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      if (!getUserInfo() && this.logRecordsColumn2 == '研究报告') {
        this.$Vlogin2({ flag: true })
        return
      }
      // debugger;
      const newUrl = this.$router.resolve(this.linkUrl + item.id)
      // debugger;
      if (getUserInfo()) {
        logRecords(
          this.logRecordsColumn1,
          this.logRecordsColumn2,
          '1',
          this.logRecordsTableName,
          item.id,
          '',
          item.title,
          ''
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
            getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.$Vlogin2({ flag: true })
        return
      }
    },
  }
}
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;
.information {
  background-color: #fff;
  .banner {
    width: 100%;
    height: 2.433rem;
    cursor: pointer;
  }
  .banner-1 {
    background: url(~@/assets/img/overview/information-1.png) no-repeat;
    background-size: 100% 100%;
  }
  .banner-2 {
    background: url(~@/assets/img/overview/information-2.png) no-repeat;
    background-size: 100% 100%;
  }
  .banner-3 {
    background: url(~@/assets/img/overview/information-3.png) no-repeat;
    background-size: 100% 100%;
  }
  .banner-4 {
    background: url(~@/assets/img/overview/information-4.png) no-repeat;
    background-size: 100% 100%;
  }
  .banner-5 {
    background: url(~@/assets/img/overview/information-5.png) no-repeat;
    background-size: 100% 100%;
  }
  .info-box {
    height: calc(100% - 2.333rem);
    width: 100%;
    padding: 0.5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .info-item {
    height: calc(100% / 10);
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
    .info-item-icon {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.6rem;
      background-size: 100% 100% !important;
      background-color: aqua;
    }
    .info-item-icon-1 {
      background: url(~@/assets/img/overview/info-item-icon-1.png) no-repeat;
    }
    .info-item-icon-2 {
      background: url(~@/assets/img/overview/info-item-icon-2.png) no-repeat;
    }
    .info-item-icon-3 {
      background: url(~@/assets/img/overview/info-item-icon-3.png) no-repeat;
    }
    .info-item-icon-4 {
      background: url(~@/assets/img/overview/info-item-icon-4.png) no-repeat;
    }
    .info-item-icon-5 {
      background: url(~@/assets/img/overview/info-item-icon-5.png) no-repeat;
    }
    .info-item-icon-6 {
      background: url(~@/assets/img/overview/info-item-icon-6.png) no-repeat;
    }
    .info-item-icon-7 {
      background: url(~@/assets/img/overview/info-item-icon-7.png) no-repeat;
    }
    .info-item-icon-8 {
      background: url(~@/assets/img/overview/info-item-icon-8.png) no-repeat;
    }
    .info-item-icon-9 {
      background: url(~@/assets/img/overview/info-item-icon-9.png) no-repeat;
    }
    .info-item-icon-10 {
      background: url(~@/assets/img/overview/info-item-icon-10.png) no-repeat;
    }
    .info-item-title {
      width: 100%;
      // height: 40%;
      font-size: calc(14px * #{$BassZoom});
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: calc(400 * #{$BassZoom});
      color: #333333;
      overflow: hidden; /*超出的部分隐藏起来。*/
      white-space: nowrap; /*不显示的地方用省略号...代替*/
      text-overflow: ellipsis; /* 支持 IE */
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // display: -moz-box;
      // -moz-line-clamp: 1;
      // -moz-box-orient: vertical;
      // overflow-wrap: break-word;
      // word-break: break-all;
      // white-space: normal;
      // overflow: hidden;
    }
  }
}
@media screen and (max-width: 1366px) {
  .info-item-title {
    font-size: 14px !important;
    // height: 45% !important;
  }
}
</style>
