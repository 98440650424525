<template>
  <PageContainer>
    <div class="old-ver-container">
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">全部概览数据</span>
          </div>
          <div class="time-boxs">
            <el-select v-model="hisForm.nian" placeholder="请选择" size="small" @change="selectChang">
              <el-option v-for="item in historyYear" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            年
            <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px"
                       @change="selectChangY">
              <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            月
          </div>
        </div>
      </Vtitle>
      <div class="table-list">
        <h1 class="n-title" style="margin-bottom:0">汇总表</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qccxhzb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车(分车型)产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcsczyqycxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车生产主要企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnyqccxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源汽车产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcfqyckqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车分企业出口情况表
              </div>
            </el-col>
          </el-row>
        </div>
        <h1 class="n-title" style="margin-bottom:0">乘用车</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div  @click="toHref('/data/dataTable/cychzb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份乘用车产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnysyccyc/', '1')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源乘用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cycscqycxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份乘用车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cycfcxcxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份乘用车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/wdqcqycyc/')"  class="link">
                {{ newTime[0] }}年01-{{ newTime[1] }}月份五大汽车企业集团乘用车产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '0')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份全国乘用车主要品牌产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '5')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份基本型乘用车主要品牌产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '3')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份MPV主要品牌产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '4')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份SUV主要品牌产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qgcyczyppcxqkb/', '6')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份交叉型乘用车主要品牌产销情况表
              </div>
            </el-col>
          </el-row>
        </div>
        <h1 class="n-title" style="margin-bottom:0">商用车</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sychzb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份商用车产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnysyccyc/', '2')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sycscqycxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份商用车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/wdqcqysyc/')"  class="link">
                {{ newTime[0] }}年01-{{ newTime[1] }}月份五大汽车企业集团商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '7')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getZhcfcxCxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '7')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfdwcxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分吨位)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfytcxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分用途)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '12')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '12')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getKcfcxCxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '17')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getHcfwzclscqycxqkb/')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '18')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getBgqycscqycxqkb/')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfdwcxqkb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分吨位)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfcxcxhzb/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分车型)产销汇总表
              </div>
            </el-col>
          </el-row>


        </div>
        <h1 class="n-title" style="margin-bottom:0">发动机</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdjscqy/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份发动机生产企业产销情况表 (台)
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcfdjarlflscqyqkb/')"
                           class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业产销情况表 (台)
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdsp/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机生产企业商品量情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cqsp/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业商品量情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdzp/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机生产企业自配量情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cqzp/')"  class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业自配量情况表
              </div>
            </el-col>
          </el-row>
        </div>


      </div>

    </div>

  </PageContainer>
</template>
<script>
import {getYearListTables, getYueListDynamics} from "@/api/data.js";
import Vtitle from "../components/title.vue";
import {getUserInfo, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    Vtitle,
  },
  data() {
    return {
      historyYear: [],
      historyYue: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      hisForm: {
        nian: "",
        yue: "02",
      },
      historyList: [],
      hv: "",
      newTime: ["2021", "02"],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },

    selectChang(v) {

      this.getYueListDynamics(v)
      this.$set(this.newTime, [0], v);

    },
    selectChangY(v) {

      this.$set(this.newTime, [1], v);

    },
    init() {
      getYearListTables().then((res) => {
        if (res.ok) {
          this.historyYear = res.data;
          this.hisForm.nian = res.data[0];
          this.$set(this.newTime, [0], this.hisForm.nian);
          this.getYueListDynamics(res.data[0]);
        }
      });
      // lastTime().then((res) => {
      //     if (res.ok) {
      //         this.newTime = res.data.split("-");
      //         this.hisForm.yue = this.newTime[1];
      //     }
      // });
    },
    getYueListDynamics(nian) {
      getYueListDynamics(nian).then((res) => {
        this.historyYue = res.data.list;
        this.hisForm.yue = res.data.list[res.data.list.length - 1];
        this.$set(this.newTime, [1], this.hisForm.yue);
      });
    },
    hisSearch() {
      this.$set(this.newTime, [0], this.hisForm.nian);
      this.$set(this.newTime, [1], this.hisForm.yue);
    },
    toHref(url, type) {
      let str = "";
      str = type
          ? (str = url + this.newTime.join("-") + "-01/" + type)
          : url + this.newTime.join("-") + "-01";
      let newUrl = this.$router.resolve({
        path: str
      });
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
  },
};
</script>
<style lang="scss" scoped>
.table-list {
  padding: 24px;
}

.list-table {
  .link-box {
    &:last-child {
      .link {
        border-bottom: none;
      }
    }
  }

  .link {
    cursor: pointer;
    display: block;
    line-height: 43px;
    border-bottom: 1px solid #F2F2F2;
    color: #000;
    font-size: 15px;
  }
}

.time-boxs {
  color: #fff;

  .el-select {
    width: 80px;
  }

  /deep/ .el-input__inner {
    background: transparent;
    color: #fff;
  }

  /deep/ .el-icon-arrow-up:before {
    color: #fff;
  }
}


/*** */
.hisTable {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #0d57bc;
  font-size: 14px;
}

.hisTable td {
  padding: 5px;
  cursor: pointer;
}

.outer-layer-new {
  padding: 20px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
