<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />-->
    <div>

      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner" flex="c">
            <span class="text">经济指标</span>
            <div class="select-bg" v-if="showW">
              <el-select v-model="year" placeholder="请选择" size="small" @change="selectChangeYear" disabled style="width:80px">
                <el-option v-for="item in yearData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <span style="padding:0 12px">年</span>
            </div>
            <div class="select-bg" v-else>
              <el-select v-model="year" placeholder="请选择" size="small" @change="selectChangeYear" style="width:80px">
                <el-option v-for="item in yearData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <span style="padding:0 12px">年</span>
            </div>
            <div class="select-bg" v-if="showW">
              <el-select v-model="month" placeholder="请选择" size="small" @change="selectChange" disabled style="width:80px">
                <el-option v-for="item in monthData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <span style="padding:0 12px">月</span>
            </div>
            <div class="select-bg" v-else>
              <el-select v-model="month" placeholder="请选择" size="small" @change="selectChange" style="width:80px">
                <el-option v-for="item in monthData" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <span style="padding:0 12px">月</span>
            </div>
          </div>
        </div>
      </Vtitle>
      <div class="search-form" v-if="show && !showP">
        <h1 class="search-form-title"> {{ title }}</h1>
        <div style="float: right">
          <!-- <el-button type="primary" plain @click="ExportAll"  :loading="excelAllLoading">导出全部Excel</el-button> -->
          <el-button type="primary" plain v-has-role="'freeusers'" @click="Export" :loading="excelLoading">导出
          </el-button>
        </div>
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" stripe :span-method="arraySpanMethod" id="tal"
                  border :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
          <el-table-column prop="zbmc" label="指标名称"></el-table-column>
          <el-table-column prop="dw" label="单位" width="100"></el-table-column>
          <el-table-column prop="bqzlj" label="本期累计">
            <template slot-scope="scope">
              <div v-if="scope.row.zbmc == '工业经济效益综合指数'" class="text-center">{{ scope.row.bqzlj }}</div>
              <div v-else>{{ scope.row.bqzlj }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="tqzlj" label="同期累计"></el-table-column>
          <el-table-column prop="zzl" label="增长率，%"></el-table-column>
          <el-table-column prop="zze" label="增长额/提高百分点"></el-table-column>
        </el-table>
        <p class="table-tip">注：1.本期汇总的重点汽车企业(集团)共17家，包括北汽、中国长安、华晨、一汽、上汽、吉利、江淮、奇瑞、东南(福建)、厦门金龙、郑州宇通、重汽、东风、广汽、庆铃、陕汽和比亚迪。
          <br/>2.本期数据为企业快报数，仅供参考。
        </p>
      </div>
      <div v-else style="position: relative; ">
        <img style="width:100%" src="@/assets/img/jjzbb.jpg" alt=""/>
        <center>
          <div
              style="position:relative;width:300px;height:94px;bottom:1110px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:1110px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>
      </div>
    </div>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {jjzb, zbQueryYear, ykbJjzbQueryMonth} from "@/api/data.js";
import Vtitle from "../components/title.vue";
import {buryPoint} from "@/api/common";
import * as XLSX from "xlsx";
import FileSaver from 'file-saver'

export default {
  components: {
    // VnotLogin,
    Vtitle,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      excelLoading: false,
      month: "",
      monthData: [],
      yearData: [],
      year: "",
      title: "",
      showP: false,
      showW: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  mounted() {
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        }else{
          if(getUserInfo().roleKey == "trialrole"){
            this.showW = true;

          }
        }
      }
    } else {
      this.show = false;
    }
    this.zbQueryYear();

  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //查询
    jjzb() {
      let monthSplit = this.month.split("-");
      let monthNum = Number(monthSplit[0]) + Number(monthSplit[1]);
      let month = monthNum > 12 ? "1" : monthNum;
      let year = monthNum > 12 ? Number(this.year) + 1 : this.year;
      let yearMonth =
          year +
          "" +
          (month.toString().length == 1 ? "0" + month : month);
      this.title =
          this.year +
          "年" +
          this.month +
          "月份汽车工业重点企业（集团）经济指标汇总表";
      jjzb({yearMonth}).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
        }
      });
    },
    //初始化年份
    zbQueryYear() {
      zbQueryYear().then((res) => {
        if (res.ok) {
          this.yearData = res.data;
          this.year = res.data[0];
          this.ykbJjzbQueryMonth(res.data[0]);

        }
      });
    },
    //月份
    ykbJjzbQueryMonth(year) {
      ykbJjzbQueryMonth({year}).then((res) => {
        if (res.ok) {
          this.monthData = res.data;
          this.month = res.data[0];
          if (this.show) {
            this.jjzb();
          }
        }
      });
    },
    //导出
    Export() {
      if (getUserInfo()) {

        logRecords('数据','经济指标','3','ykb_jjzb','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.sqsyLogin();
              this.insertBuryPoint();
            } else {
              /* generate workbook object from table */
              var xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
              var table = document.querySelector('#tal').cloneNode(true)
              var wb = XLSX.utils.table_to_book(table, xlsxParam)
              /* get binary string as output */
              var wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
              try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '经济指标.xlsx')
              } catch (e) {
                if (typeof console !== 'undefined') {
                  console.log(e, wbout)
                }
              }
              return wbout
            }
          }
        } else {
          /* generate workbook object from table */
          xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
          table = document.querySelector('#tal').cloneNode(true)
          wb = XLSX.utils.table_to_book(table, xlsxParam)
          /* get binary string as output */
          wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
          try {
            FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '经济指标.xlsx')
          } catch (e) {
            if (typeof console !== 'undefined') {
              console.log(e, wbout)
            }
          }
          return wbout
        }
      } else {
        this.showLogin();
      }

    },
    //重置
    rest() {
      this.year = this.yearData[0];
      this.month = this.monthData[0];
      if (this.show) {
        this.jjzb();
      }
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    selectChange() {

      if (getUserInfo()) {

        logRecords('数据','经济指标','3','ykb_jjzb','','','','','时间筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.jjzb();
          }
        } else {
          this.jjzb();
        }
      } else {
        this.showLogin();
      }
    },
    selectChangeYear(e) {

      if (getUserInfo()) {

        logRecords('数据','经济指标','3','ykb_jjzb','','','','','时间筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.ykbJjzbQueryMonth(e);
          }
        } else {
          this.ykbJjzbQueryMonth(e);
        }
      } else {
        this.showLogin();
      }
    },
    arraySpanMethod({row, column, rowIndex, columnIndex}) {
      if (rowIndex === this.tableData.length - 1) {
        if (columnIndex === 2) {
          return [1, 4];
        } else if (columnIndex === 5) {
          return [0, 0];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.table-tip {
  width: 760px;
  margin: 0 auto;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.select-bg {
  /deep/ .el-input__inner {
    background: linear-gradient(90.79deg, #1768D5 3.39%, rgba(13, 87, 188, 0) 390.23%);
    color: #fff;
    border: 2px solid #fff;
  }

  /deep/ .el-select .el-input .el-select__caret {
    color: #fff;
  }

  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #fff;
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
