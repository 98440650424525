<template>
  <div class="breadcrumb-Box">
    <div class="max-container">
      <div class="breadcrumb-list" v-if="!bzFlag">
        <span class="breadcrumb-style">行业信息 > 政策</span> |
        <router-link tag="a" to="/standards/dynamic/syc/index" target="_blank" class="breadcrumb-style ac">标准</router-link>
      </div>
      <div class="breadcrumb-list" v-else>
        <span class="breadcrumb-style" >行业信息 > </span>
        <router-link tag="a" to="/policy/dynamic/sycIndex" target="_blank" class="breadcrumb-style ac">政策</router-link> |
        <span class="breadcrumb-style">标准</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "breadcrumb",
  data() {
    return {
      levelList: null,
      query: {},
      bzFlag:false
    };
  },
  created() {
    this.query = this.$route.query;
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let path = this.$route.name;
      if(path.includes("标准")){
        this.bzFlag = true;
      }else if(path.includes("政策")){
        this.bzFlag = false;

      }
      console.log(path)
      let breadcrumbList = [];
      if (this.$route.meta && this.$route.meta.breadcrumb) {
        breadcrumbList = this.$route.meta.breadcrumb;
      }

      // let matched = this.$route.matched.filter(
      //   (item) => item.meta && item.meta.title && item.path != '#'
      // );
      // const first = matched[0];
      // if (!this.isDashboard(first)) {
      //   matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
      // }
      // this.levelList = matched.filter((item) => item.meta && item.meta.title);
      this.levelList = breadcrumbList;
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name === "首页";
    },
    handleLink(name) {
      let url;
      if(name == 'zc'){
        url = "/policy/dynamic/sycIndex";
      }else{
        url = "/standards/dynamic/syc/index";
      }
      this.$router.push( url);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.breadcrumb-Box {
  background: #fff;

  .breadcrumb-list {
    display: flex;
    align-items: center;
    height: $breadcrumb-height;

    .breadcrumb-style {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: #000;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 500;
      height: 100%;
      overflow: hidden;
    }

    .breadcrumb-style.ac {
      color: grey;

    }

    .breadcrumb-style.ac:hover {
      color: #0d57bc;
    }

    .breadcrumb-icon {
      padding-left: 32px;
      align-items: center;
    }
  }
}
</style>
