<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips" />-->
    <template>
      <div class="search-form">
        <el-form ref="form" class="g-form" :model="form" >
          <ul class="g-form-item conditionFrom1">
            <li>
              <el-form-item prop="title">
                <label class="text-right">政策名称：</label>
                <el-input
                  v-model="form.title"
                  placeholder="请输入内容"
                  class="g-form-input"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="certificateUnit">
                <label class="text-right">颁布单位：</label>
                <el-input
                  v-model="form.certificateUnit"
                  placeholder="请输入内容"
                  class="g-form-input"
                />
                <!-- <div @click="treeVisible = true">
  <el-input
    v-model="form.certificateUnit"
    placeholder="请输入内容"
    class="g-form-input"
    readonly
  ></el-input>
</div> -->
              </el-form-item>
            </li>

            <li>
              <el-form-item>
                <label class="text-right">公开日期：</label>
                <el-date-picker
                  v-model="publicDate"
                  unlink-panels
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  class="g-form-input"
                />
              </el-form-item>
            </li>
          </ul>
          <ul class="g-form-item conditionFrom">
            <li >
              <!-- <el-form-item prop="internationalPolicyType">
                <label class="text-right">政策类型：</label>
                <el-select
                  v-model="form.internationalPolicyType"
                  multiple
                  placeholder="请选择"
                  size="small"
                  class="g-form-input"
                  clearable
                >
                  <el-option
                    v-for="item in internationalPolicyTypeData"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item> -->
              <label class="text-right">行业类型：</label>
              <div class="bigbox">
                <div class="flexC" >
                  <div class="lable-box">
                    <el-checkbox-button class="allBtn" label="全部" size="small" @change="hylxchange" v-model="hylx">
                    </el-checkbox-button>
                  </div>
                  <el-checkbox-group v-model="form.internationalPolicyType" size="small" @change="handleChange">
                    <el-checkbox-button v-for="item in internationalPolicyTypeData" :key="item.dictValue"
                      :label="item.dictValue">{{
                      item.dictLabel }}
                      <span v-if="isClear(form.internationalPolicyType, item.dictValue)"><i class="el-icon-close"></i> </span></el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>
            </li>
            <li>
              <!-- <el-form-item prop="state">
                <label class="text-right">区域：</label>
                <el-select
                  v-model="form.state"
                  multiple
                  placeholder="请选择"
                  size="small"
                  class="g-form-input"
                  clearable
                >
                  <el-option
                    v-for="item in stateData"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item> -->
              <label class="text-right">区域：</label>
              <div class="bigbox">
                <div class="flexC" >
                  <div class="lable-box">
                    <el-checkbox-button class="allBtn" label="全部" size="small" @change="qylxchange" v-model="qylx">
                    </el-checkbox-button>
                  </div>
                  <el-checkbox-group v-model="form.state" size="small" @change="qyhandleChange">
                    <el-checkbox-button v-for="item in stateData" :key="item.dictValue"
                      :label="item.dictValue">{{
                      item.dictLabel }}
                      <span v-if="isClear(form.state, item.dictValue)"><i class="el-icon-close"></i> </span></el-checkbox-button>
                  </el-checkbox-group>
                </div>
              </div>
            </li>
            <li>
              <el-form-item prop="country">
                <label class="text-right">国家/经济体：</label>
                <!-- <el-select
                  v-model="form.country"
                  multiple
                  placeholder="请选择"
                  size="small"
                  class="g-form-input"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in countryData"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select> -->
                <letter-select
                    v-model="form.country"
                    :options="countryData"
                    size="small"
                  />
              </el-form-item>
            </li>
          </ul>

<!--          <ul class="g-form-item">-->
<!--            <li>-->
<!--              <el-form-item prop="tag">-->
<!--                <label class="text-right">关键字：</label>-->
<!--                <el-input-->
<!--                  v-model="form.tag"-->
<!--                  placeholder="请输入内容"-->
<!--                  class="g-form-input"-->
<!--                />-->
<!--              </el-form-item>-->
<!--            </li>-->
<!--          </ul>-->
          <div class="text-center">
            <el-button
              class="reset-button"
              @click="resetForm('form')"
            >重置
            </el-button>
            <el-button
              class="search-button"
              @click="searchForm"
            >查询
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="search-form">
        <el-table
          v-show="!viewSwitchFlag"
          ref="newTable"
          v-loading="loading"
          :data="selectPolicyDate"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
          stripe
          @sort-change="tableSort"
        >
          <el-table-column prop="state" label="区域" min-width="100" />
          <el-table-column prop="country" label="国家" min-width="100" />

          <el-table-column prop="title" label="政策名称" min-width="300">
            <template slot-scope="scope">
              <div
                style="color: #0D57BC;cursor: pointer"
                @click="
                  toHref(
                    '/international/policy/detail/' + scope.row.id,
                    scope.row.id,
                    scope.row.title
                  )
                "
              >
              <div>
                <div style="display: flex; ">
                  {{ htmlRestore(scope.row.title) }}
                  <span v-if="scope.row.interpret" class="title-tag">
                    解读
                  </span>
                </div>
              </div>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--            prop="policyCategory"-->
          <!--            label="政策类别"-->
          <!--            min-width="150"-->
          <!--          />-->
          <el-table-column
            sortable="custom"
            prop="publicDate"
            label="公开日期"
            min-widt="100"
          />
          <!--          <el-table-column-->
          <!--            sortable="custom"-->
          <!--            prop="transactionDate"-->
          <!--            label="成文日期"-->
          <!--            min-width="100"-->
          <!--          />-->
          <!--          <el-table-column-->
          <!--            sortable="custom"-->
          <!--            prop="implementDate"-->
          <!--            label="实施日期"-->
          <!--            min-width="100"-->
          <!--          />-->
          <!--          <el-table-column-->
          <!--            sortable="custom"-->
          <!--            prop="endDate"-->
          <!--            label="终止日期"-->
          <!--            min-width="100"-->
          <!--          />-->
        </el-table>

        <div class="text-right">
          <el-pagination
            v-if="pageShow"
            :current-page="selectPolicyPage.pages.page"
            :page-sizes="pageSizes"
            :page-size="selectPolicyPage.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="selectPolicyPage.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策查询页面
 * @param {*}
 * @return {*}
 */
import { selectPolicyGjh } from '@/api/policy.js'
import { selectDictDataByType, ttDemension} from '@/api/dict.js'
import { getUserInfo, logRecords,openSy  } from '@/utils/auth.js'

import { buryPoint } from '@/api/common'
import LetterSelect from '@/components/newData/letterSelect/index.vue'
export default {
  components: {
    LetterSelect,
  },
  data() {
    return {
      visible: false,
      visible2: false,
      treeVisible: false,
      show: false,
      sandType: [
        {
          lable: '征求意见稿',
          value: '2'
        },
        {
          lable: '正式发布稿',
          value: '1'
        }
      ],
      showTips: [
        {
          title: '政策动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '政策库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '政策梳理',
          text: '专家梳理整合，真正读懂政策脉络'
        },
        {
          title: '政策大数据',
          text: '用数据深度理解政策结构和趋势'
        }
      ],
      viewSwitchFlag: false,
      form: {
        tag: '',
        input: '',
        publicDateStart: '',
        publicDateEnd: '',
        policyCategory: [],
        voidDate: '',
        certificateUnit: '',
        status: '',
        nature1: [],
        nature2: [],
        areas: '',
        city: '',
        title: '',
        implementDateStart: '',
        implementDateEnd: '',
        publishType: '',
        transactionDateStart: '',
        transactionDateEnd: '',
        belongAreas: [],
        orderType: '',
        state: [],
        country: [],
        internationalPolicyType: []
      },
      belongAreasData: [],
      stateData: [],
      countryData: [],
      internationalPolicyTypeData: [],
      implementDate: '',
      publicDate: '',
      transactionDate: '',
      // 颁布单位
      certificateUnit: '',
      selectPolicyDate: [],
      selectPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      pageSizes: [10, 20, 30, 40],
      natureData: {},
      natureName1: [],
      natureStr1: '',
      natureName2: [],
      natureStr2: '',
      areas: [
        { dictValue: '1', dictLabel: '国家' },
        { dictValue: '2', dictLabel: '地方' }
      ],
      policyCategoryData: [],
      policyCategoryProps: {
        multiple: true,
        value: 'id',
        label: 'name'
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '国际化-政策',
        userId: ''
      },
      loading: false,
      pageShow: true,
      hylx: true,
      qylx: true,
      countryList:[]

    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.hylx = newVal.internationalPolicyType.length <= 0 ? true : false
        this.qylx = newVal.state.length <= 0 ? true : false
      },
    },
  },
  computed: {
    isClear() {
      return (arr, id) => {
        return arr.includes(id);
      };
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.selectPolicy(this.selectPolicyPage.pages)
    // this.getNature({ code: 'nature' })
    // this.ttDemension({ code: 'policyType' })
    this.getCategory()
  },
  methods: {
    hylxchange(e) {
      this.hylx = true
      this.form.internationalPolicyType = []
      this.searchForm()
    },
    handleChange(e) {
      this.form.internationalPolicyType = e
      this.searchForm()
    },
    qylxchange(e) {
      this.qylx = true
      this.form.state = []
      this.searchForm()
    },
    qyhandleChange(e) {
      this.form.state = e
      this.searchForm()
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    //   viewDetail(path,id){
    //     let newUrl = this.$router.resolve({
    //         path: path + id
    //     });
    //     window.open(newUrl.href, "_blank");
    // },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, id, title) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('国际化', '政策', '1', 'tt_policy', id, '', title, '')
        if (
          getUserInfo().roleKey == 'paidrole' ||
            getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    selectPolicy(params) {
      if (!getUserInfo()) {
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.selectPolicyPage.pages.page > 1) {
        this.showLogin()
      } else {
        if (this.form.title != '' && this.form.title != null) {
          logRecords(
            '国际化',
            '政策',
            '4',
            'tt_policy',
            '',
            '',
            '',
            this.form.title
          )
        }
        this.loading = true
        params.policyCategory = 'fd42e2cc9fbe49c88fe508ce25f453a5'
        selectPolicyGjh(params).then((res) => {
          if (res.ok) {
            this.selectPolicyDate = res.data.list

            this.selectPolicyPage.total = res.data.count
          }
          this.loading = false
        })
      }
    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.policyCategoryData = res.data
        }
      })
    },
    viewSwitch() {
      this.viewSwitchFlag = !this.viewSwitchFlag
    },
    handleSizeChange(val) {
      this.selectPolicyPage.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.selectPolicyPage.pages.page = val
        this.init()
      }
    },
    // 查询利好利空车型
    getNature(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.natureData = this.getDataGrop(res.data)
        }
      })
    },
    getDataGrop(data) {
      const datas = {
        cyc: [],
        kc: [],
        hc: []
      }
      data.forEach((item) => {
        if (item.name == '乘用车') {
          datas.cyc.push({
            dictValue: item.id,
            dictLabel: item.name
          })
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.cyc.push({
                dictValue: citem.id,
                dictLabel: citem.name
              })
            })
          }
        } else if (item.name == '客车') {
          datas.kc.push({
            dictValue: item.id,
            dictLabel: item.name
          })
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.kc.push({
                dictValue: citem.id,
                dictLabel: citem.name
              })
            })
          }
        } else if (item.name == '货车') {
          datas.hc.push({
            dictValue: item.id,
            dictLabel: item.name
          })
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.hc.push({
                dictValue: citem.id,
                dictLabel: citem.name
              })
            })
          }
        }
      })
      return datas
    },
    // 树形结构获取
    getNode(v) {
      v.nodeDataName.map((item) => {
        return item.name
      })
      this.treeVisible = false
    },
    natureBtn1(item) {
      if (this.form.nature1.indexOf(item.dictValue) != -1) {
        this.natureName1.splice(this.natureName1.indexOf(item.dictLabel), 1)
        this.form.nature1.splice(this.form.nature1.indexOf(item.dictValue), 1)
      } else {
        this.natureName1.push(item.dictLabel)
        this.form.nature1.push(item.dictValue)
        if (this.form.nature2.indexOf(item.dictValue) != -1) {
          this.natureName2.splice(this.natureName2.indexOf(item.dictLabel), 1)
          this.form.nature2.splice(
            this.form.nature2.indexOf(item.dictValue),
            1
          )
          this.natureStr2 = this.natureName2.join(',')
        }
      }

      this.natureStr1 = this.natureName1.join(',')
    },
    natureBtn2(item) {
      if (this.form.nature2.indexOf(item.dictValue) != -1) {
        this.natureName2.splice(this.natureName2.indexOf(item.dictLabel), 1)
        this.form.nature2.splice(this.form.nature2.indexOf(item.dictValue), 1)
      } else {
        this.natureName2.push(item.dictLabel)
        this.form.nature2.push(item.dictValue)
        if (this.form.nature1.indexOf(item.dictValue) != -1) {
          this.natureName1.splice(this.natureName1.indexOf(item.dictLabel), 1)
          this.form.nature1.splice(
            this.form.nature1.indexOf(item.dictValue),
            1
          )
          this.natureStr1 = this.natureName1.join(',')
        }
      }
      this.natureStr2 = this.natureName2.join(',')
    },
    // 政策区域切换
    areasChange(e) {
      this.form.city = ''
    },
    //输入国家输出对应val
    countryChange(val) {
      const foundItem = this.countryList.find((item) => {
        return item.dictLabel === val;
      });
      return foundItem ? foundItem.dictValue : null; // 如果找到了，返回dictValue，否则返回null
    },
    // 初始化
    init() {
      const form = Object.assign({}, this.form, this.selectPolicyPage.pages)
      form.nature1 = form.nature1.join(',')
      form.nature2 = form.nature2.join(',')
      form.implementDateStart = this.implementDate[0]
      form.implementDateEnd = this.implementDate[1]

      form.publicDateStart = this.publicDate[0]
      form.publicDateEnd = this.publicDate[1]

      form.transactionDateStart = this.transactionDate[0]
      form.transactionDateEnd = this.transactionDate[1]
      form.belongAreas = form.belongAreas.join(',')
      form.state = form.state.join(',')

      form.country = form.country.map((item) => {
        return this.countryChange(item)
      }).join(',')
      form.internationalPolicyType = form.internationalPolicyType.join(',')

      // if (form.policyCategory) {
      //   form.policyCategory = this.unique(
      //     form.policyCategory.join(',').split(',')
      //   ).join(',')
      // }

      // console.log(form)
      this.selectPolicy(form)
    },
    // 表单查询
    searchForm() {
      this.formLog.eventType = '2'
      if (getUserInfo()) {
        if (
          getUserInfo().roleKey == 'paidrole' ||
            getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.selectPolicyPage.pages.page = 1
            this.init()
          }
        } else {
          this.selectPolicyPage.pages.page = 1
          this.init()
        }
      } else {
        this.showLogin()
      }
    },
    // 表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.natureName1 = []
      this.natureStr1 = ''
      this.natureName2 = []
      this.natureStr2 = ''
      this.form.nature1 = []
      this.form.nature2 = []
      this.form.internationalPolicyType = []
      this.form.state = []
      this.form.orderType = ''
      this.selectPolicyPage.pages.page = 1
      this.implementDate = ''
      this.publicDate = ''
      this.transactionDate = ''
      this.$refs.newTable.clearSort()
      this.init()
      // this.selectPolicy(this.selectPolicyPage.pages);
    },
    // 数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    // 获取字典
    getCategory() {
      selectDictDataByType({ dictType: 'policy_hot_field' }).then((res) => {
        if (res.ok) {
          this.belongAreasData = res.data
        }
      })

      selectDictDataByType({ dictType: 'international_state' }).then((res) => {
        if (res.ok) {
          this.stateData = res.data
        }
      })

      selectDictDataByType({ dictType: 'international_country' }).then((res) => {
        if (res.ok) {
          this.countryList = res.data
          this.countryData = res.data.map(item => {
            return {
              label: item.dictLabel,
              value: item.dictLabel
            }
          })
        }
      })
      selectDictDataByType({ dictType: 'international_policy_type' }).then((res) => {
        if (res.ok) {
          this.internationalPolicyTypeData = res.data
        }
      })
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'publicDate') {
          return 'public_date'
        } else if (column.prop == 'transactionDate') {
          return 'transaction_date'
        } else if (column.prop == 'implementDate') {
          return 'implement_date'
        } else if (column.prop == 'endDate') {
          return 'end_date'
        } else if (column.prop == 'policyCategory') {
          return 'policy_category'
        } else {
          return column.prop
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'publicDate') {
          return 'public_date' + ' DESC'
        } else if (column.prop == 'transactionDate') {
          return 'transaction_date' + ' DESC'
        } else if (column.prop == 'implementDate') {
          return 'implement_date' + ' DESC'
        } else if (column.prop == 'endDate') {
          return 'end_date' + ' DESC'
        } else if (column.prop == 'policyCategory') {
          return 'policy_category' + ' DESC'
        } else {
          return column.prop + ' DESC'
        }
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.outer-layer-box {
  padding-top: 0.666rem;
  padding-right: 0.666rem;
  padding-bottom: 0.666rem;
}

/deep/ .el-form-item__content {
  display: flex;
  align-content: center;
}

.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  // clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;

    .text-right {
      display: inline-block;
      width: 100px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      width: 60px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -82px;
    }

    // &:nth-child(1) {
    //   float: left;
    // }

    // &:nth-child(2) {
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }

    // &:nth-child(3) {
    //   float: right;
    // }

    .g-form-input {
      flex: 1;
      width: 230px;

      /deep/ .el-input__inner,
      /deep/ .el-input__icon,
      /deep/ &.el-date-editor .el-range-separator,
      /deep/ &.el-range-editor.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.nature-tag {
  overflow: hidden;
  margin-left: -10px;

  li {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    padding: 0 2px;
    border: 1px solid #828282;
    color: #828282;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &.ac1 {
      color: #219653;
      border-color: #219653;
    }

    &.ac2 {
      color: #eb5757;
      border-color: #eb5757;
    }
  }
}

.nature-box {
  position: relative;
  padding-left: 14px;
  height: 22px;
  line-height: 22px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -3px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d9d9d9;
  }

  &.lh {
    &::after {
      background: #27ae60;
    }
  }

  &.lk {
    &::after {
      background: #eb5757;
    }
  }
}
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;
}

.el-message-box__message p {
  line-height: 14px;
}
.title-tag {
  width: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  display: -moz-box;
  -moz-line-clamp: 1;
  padding: 0px 10px;
  height: 20px;
  color: #fff;
  border-radius: 14px 0 14px 0;
  font-size: 12px;
  margin: 0 8px;
  user-select: none;
  background-color: rgb(54, 66, 172);
  line-height: 20px;
}
.conditionFrom{
  display: flex;
  flex-direction: column;
}
.conditionFrom1{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.conditionFrom{

  .bigbox {
    background: #ffffff;
    padding: 22px 20px;

    /deep/.el-select .el-input__inner::placeholder {
      color: rgb(51, 51, 51);
    }

    .flexC {
      // margin-bottom: 20px;
      display: flex;
      align-items: center;
      label {
        display: inline-block;
        padding: 0 10px;
      }
    }

    .checkTitle {
      width: 60px;
      margin-right: 16px;
      display: inline-block;
      color: rgb(153, 153, 153);
    }

    ::v-deep {
      .el-input__inner {
        border-radius: 4px;
      }

      .el-checkbox-button {
        // margin-right: 30px;

        .el-checkbox-button__inner {
          padding: 7px 13px;
          min-width: 58px;
          color: #333;
          font-size: 14px;
          font-weight: normal;
          border-radius: 22px !important;
          border: 0;

          span {
            margin-top: 1px;
            margin-right: -4px;
          }
        }
      }

      .el-checkbox-button .el-checkbox-button__inner:hover {
        color: #3f76ea;
      }

      .is-checked .el-checkbox-button__inner {
        background: #3f76ea !important;
        color: #FFF !important;
        border-color: #fff !important;
      }

      .el-form--inline .el-form-item__content {
        line-height: normal;
      }
    }
  }
}
</style>
