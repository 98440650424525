//政策栏目
import $http from '@/plugins/http.js'

//获取预约分析报告列表
export function getSaleList(params) {
    return $http.get('/api/analysisReport/queryByPage', params)
}
//获取预约分析报告详情
export function getSaleDetail(params) {
  return $http.get('/api/analysisReport/queryById', params)
}

//获取预约分析报告详情
export function getSaleType(params) {
  return $http.get('/api/analysisReport/queryType', params)
}


