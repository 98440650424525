<template>
  <section class="layout-container" id="layout-container">
    <el-aside width="208px" class="layout-sider layout-container-has-sider-fh">
      <el-menu :default-active="menuList['0']" @select="handleSelect">
        <template v-for="item in menuList">
          <el-menu-item :index="item" :key="item" >
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title">{{ item }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-col :span="19" style="margin-top: 10px">
      <div class="list-filter" flex="c" style="width: 100%;background: #fff;">
        <el-row style="width: 100%">
          <el-col :span="24" style="display: flex; justify-content: space-between">
            <div style="display: block">
              <div style="margin: 20px 20px 20px 30px">
                <div class="zcTitle">领域</div>
                <el-checkbox-group v-model="fieldListData" size="small" @change="handleChange">
                  <el-checkbox :label="item" border v-for="item in fieldList" :key="item">
                    {{ item }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="margin: 20px 20px 20px 30px">
                <div class="zcTitle">类别</div>
                <el-checkbox-group v-model="classifyListData" size="small" @change="handleChange">
                  <el-checkbox :label="item" border v-for="item in classifyList" :key="item">
                    {{ item }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div style="margin: 20px 20px 20px 30px">
              <div class="nav-box-r">
                <div class="nav-search">
                  <input type="text" placeholder="搜索周月报内容" v-model="searchValue" class="search-input" @keyup.enter="searchBtn">
                  <img src="@/assets/img/Group 506.png" alt="" class="search-icon" @click="searchBtn">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="box_box_title" v-for="item in itemList" :key="item.id" @click="toHref('/strategyReport/details/'+item.id)">
        <div style="display: flex">
          <div class="text-center" style="width: 267px; height: 150px">
            <img
                style="width: 267px; height: 150px"
                :src="item.imgUrl ? item.imgUrl : defaultUrl"
                alt=""
            />
          </div>
          <div class="flex_col" flex="1">
            <div>
              <div flex="c" style="margin: 10px 0">
                <span style="
                      display: inline-block;
                      color: #0d57bc;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    ">{{ item.classify }}</span>
                <span style="
                      display: inline-block;
                      color: #823d00;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    ">{{ item.field }}</span>
                <p class="" v-html="item.title"></p>
              </div>
              <div style="font-size: 16px; font-weight: bold">{{ item.specialTitle }}</div>
              <div style="font-size: 14px; color: #828282">
                {{ item.introduction }}
              </div>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ item.publishDate }}
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </section>
</template>

<script>
import { getEmailAccountInfo } from "@/api/strategyReport.js";
import { getSearchList } from "@/api/strategyReport.js";
import { getClassifyList } from "@/api/strategyReport.js";

export default {
  name: "index",

  data() {
    return {
      dateList : undefined,
      menuList : [],
      itemList : [],
      fieldList : undefined,
      fieldListData : [],
      classifyList : undefined,
      classifyListData : [],
      loading: false,
      searchValue: "",
      defaultUrl: "",
      weekProduct: "",
    };
  },

  methods: {
    toHref(url) {
      let newUrl = this.$router.resolve(url);
      window.open(newUrl.href, "_blank");
    },
    searchBtn() {
      let newUrl = this.$router.resolve('/strategyReport/search?title=' + this.searchValue + "&menu=" + this.menuList);
      if (this.values == "") {
        alert("请输入搜索内容")
      } else {
        window.open(newUrl.href, "_blank");
      }
    },
    handleSelect(key) {
      this.weekProduct = key;
      getSearchList(key, this.dateList, this.classifyListData, this.fieldListData).then((res) => {
        this.itemList = res.data;
      })
    },
    handleChange() {
      getSearchList(this.weekProduct, this.dateList, this.classifyListData, this.fieldListData).then((res) => {
        this.itemList = res.data;
      })
    },
    handleSearch() {
      getSearchList(this.weekProduct, this.dateList, this.classifyListData, this.fieldListData).then((res) => {
        this.itemList = res.data;
      })
    }
  },
  created() {

    getClassifyList().then((res) => {
      this.classifyList = res.data.classifyList;
      this.fieldList = res.data.fieldList;
    })

  },
  activated() {
    this.dateList = getEmailAccountInfo();

    if (this.dateList == undefined) {
      this.$router.push("/strategyReport/login");
    } else {
      for (let i=0; i<this.dateList.length; i++) {
        if (this.menuList.indexOf(this.dateList[i.toString()].weekProducts) == -1) {
          this.menuList.push(this.dateList[i.toString()].weekProducts);
        }
      }
      this.weekProduct = this.menuList[0];
      getSearchList(this.menuList[0], this.dateList, this.classifyListData, this.fieldListData).then((res) => {
        this.itemList = res.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  //overflow-y: hidden;
  height: 100%;
  min-height: 0;
}

.layout-container {
  background: #f6f6f6;
}

.layout-main {
  position: relative;
  height: 100%;
  min-height: 0;
  overflow-x: hidden;
  // padding: 0 24px;
}

.layout-main.hide {
  margin: 0;
}

#layout-container .layout-header,
#layout-container .layout-sider {
  background: #fff;
}

#layout-container .layout-sider {
  float: left;
  margin-right: 24px;
  margin-top: 10px;
  overflow-y: auto;

  //  box-shadow: 0px 4px 8px rgba(214, 214, 214, 0.5);
  .el-menu-item.is-active,
  .el-menu-item:hover {
    background: rgba($color: #0d57bc, $alpha: 0.03);
    color: #0d57bc;
  }
}

#layout-container .layout-header {
  padding: 0;
}

#layout-container .el-menu {
  border-right: none;
}

#layout-container .el-menu .el-menu-item {
  font-weight: bold;
}


.slider-leave-active {
  transition: all 1s;
  transform: translate3d(0, -74px, 0);
}

.duration {
  animation-duration: 8s
}

.zcTitle {
  margin: 12px 0;
  font-weight: bold;
  font-size: 16px
}

::v-deep .el-checkbox {
  margin-right: 0;
}

.search-btn-box {
  display: flex;
  margin-top: 9px;

  ::v-deep .el-input__inner {
    height: 44px;
    line-height: 44px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-btn {
    width: 109px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

.newUl .newUl-title {
  font-weight: bold;
}
.newUl .newUl-time {
  font-size: 16px;

}
.newUl {

  padding-top: 10px;
}

.box {
  height: 100%;
  overflow-y: auto;
}

.box_box_title {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.file-box {
  position: absolute;
  top: 10px;
  right: 0;
  width: 208px;
  background: #fff;
  border-radius: 4px;
  max-height: calc(100% - 130px);
  overflow-y: auto;
}

.nav-search {
  position: relative;
  width: 290px;
  height: 44px;
  background: rgba(13, 87, 188, 0.03);
  padding: 0 35px 0 17px;
  margin-right: 20px;

  .search-input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }

  .search-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 23px;
    margin-top: -8px;
    cursor: pointer;
  }

  .search-input:focus {
    outline: 0px;
  }
}

</style>
