<template>
  <div class="amountChart-detail">
    <div class="left">
      <div class="left-head">
        <span class="title-content">
          {{ dataSource.salesDateStr }}
        </span>
        <span>单位: 辆</span>
        <span style="float: right;" v-if="$slots.leftExport">
          <slot name="leftExport"></slot>
        </span>
      </div>
      <div class="left-content">
        <div
          class="content-item"
          v-for="({ vehicle_model_name, num, qoq, yoy },
          o) in dataSource.salesInfoList"
          :key="o"
        >
          <div
            class="content-index"
            :class="{
              first: o === 0,
              second: o === 1,
              third: o === 2
            }"
          >
            {{ o < 9 ? `0${o + 1}` : o + 1 }}
          </div>
          <div class="content-box">
            <div class="chart-box">
              <span class="car-name">{{ vehicle_model_name }}</span>

              <div class="chart">
                <el-progress
                  class="progress"
                  :class="{
                    first: o === 0,
                    second: o === 1,
                    third: o === 2
                  }"
                  :show-text="false"
                  :percentage="maxNumInSalesInfoPercentage(num)"
                />
              </div>
            </div>
            <div class="content-number">
              <span>{{ toThousands(num) }}</span>
              <div class="compared">
                <div class="yoy">
                  同比
                  <template v-if="parseFloat(yoy)">
                    <span :class="` ${yoy >= 0 ? 'icon-up' : 'icon-down'}`" />
                    <span :class="` ${yoy >= 0 ? 'text-up' : 'text-down'}`"
                      >{{ Math.abs(yoy) || "--" }}%</span
                    >
                  </template>
                  <span v-else>--</span>
                </div>
                <div class="qoq">
                  环比
                  <template v-if="parseFloat(qoq)">
                    <span :class="`${qoq >= 0 ? 'icon-up' : 'icon-down'}`" />
                    <span :class="` ${qoq >= 0 ? 'text-up' : 'text-down'}`">
                      {{ Math.abs(qoq) || "--" }}%
                    </span>
                  </template>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="segmentation"></div>
    <div class="right">
      <div class="right-head">
        <span class="title-content">
          {{ dataSource.productionDateStr }}
        </span>
        <span>单位: 辆</span>
        <span style="float: right;" v-if="$slots.rightExport">
          <slot name="rightExport"></slot>
        </span>
      </div>
      <div class="right-content">
        <div
          class="content-item"
          v-for="({ vehicle_model_name, num, qoq, yoy },
          o) in dataSource.searchProductionResult"
          :key="o"
        >
          <div
            class="content-index"
            :class="{
              first: o === 0,
              second: o === 1,
              third: o === 2
            }"
          >
            {{ o < 9 ? `0${o + 1}` : o + 1 }}
          </div>
          <div class="content-box">
            <div class="chart-box">
              <span class="car-name">{{ vehicle_model_name }}</span>
              <div class="chart">
                <el-progress
                  class="progress"
                  :class="{
                    first: o === 0,
                    second: o === 1,
                    third: o === 2
                  }"
                  :show-text="false"
                  :percentage="maxNumInSearchProductionResultPercentage(num)"
                />
              </div>
            </div>
            <div class="content-number">
              <span>{{ toThousands(num) }}</span>
              <div class="compared">
                <div class="yoy">
                  同比
                  <template v-if="parseFloat(yoy)">
                    <span :class="` ${yoy >= 0 ? 'icon-up' : 'icon-down'}`" />
                    <span :class="` ${yoy >= 0 ? 'text-up' : 'text-down'}`"
                      >{{ Math.abs(yoy) || "--" }}%</span
                    >
                  </template>
                  <span v-else>--</span>
                </div>
                <div class="qoq">
                  环比
                  <template v-if="parseFloat(qoq)">
                    <span :class="`${qoq >= 0 ? 'icon-up' : 'icon-down'}`" />
                    <span :class="` ${qoq >= 0 ? 'text-up' : 'text-down'}`">
                      {{ Math.abs(qoq) || "--" }}%
                    </span>
                  </template>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toThousands } from "@/utils/number";
export default {
  name: "AmountChart",
  props: {
    dataSource: {
      type: Object,
      default: () => ({
        salesInfoList: [],
        searchProductionResult: [],
        productionDateStr: "",
        salesDateStr: ""
      })
    }
  },
  data() {
    return {
      toThousands
    };
  },
  computed: {
    maxNumInSalesInfoList() {
      return this.calculateMaxNum(this.dataSource.salesInfoList);
    },
    maxNumInSearchProductionResult() {
      return this.calculateMaxNum(this.dataSource.searchProductionResult);
    }
  },
  methods: {
    calculateMaxNum(array) {
      if (!array.length) {
        return 1; // 默认值，避免除以零
      }
      const maxNum = Math.max(...array.map(item => item.num || 0));
      return maxNum > 0 ? maxNum : 1; // 如果最大值为零，返回默认值
    },
    maxNumInSalesInfoPercentage(capacity) {
      if (!capacity) return 0;
      if (this.maxNumInSalesInfoList > 0) {
        // 使用线性比例计算宽度
        return Math.min(
          Math.max((capacity / this.maxNumInSalesInfoList) * 100, 0),
          100
        );
      }
      return 0;
    },
    maxNumInSearchProductionResultPercentage(capacity) {
      if (!capacity) return 0;
      if (this.maxNumInSearchProductionResult > 0) {
        // 使用线性比例计算宽度
        return Math.min(
          Math.max((capacity / this.maxNumInSearchProductionResult) * 100, 0),
          100
        );
      }
      return 0;
    }
  }
};
</script>

<style lang="scss">
.amountChart-detail {
  .el-progress {
    .el-progress-bar__outer {
      height: 10px !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      .el-progress-bar__inner {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background-image: linear-gradient(
            90deg,
            #b4b4b4 0%,
            rgba(100, 100, 100, 1) 100%
          ),
          linear-gradient(#ffefea, #ffefea);
        background-blend-mode: normal, normal;
      }
    }
    &.first .el-progress-bar__inner {
      background-image: linear-gradient(
          90deg,
          #ffbcbc 0%,
          rgba(255, 45, 45, 1) 100%
        ),
        linear-gradient(#ffefea, #ffefea);
      background-blend-mode: normal, normal;
    }
    &.second .el-progress-bar__inner {
      background-image: linear-gradient(
          90deg,
          #ffceb0 0%,
          rgba(255, 96, 0, 1) 100%
        ),
        linear-gradient(#ffefea, #ffefea);
      background-blend-mode: normal, normal;
    }
    &.third .el-progress-bar__inner {
      background-image: linear-gradient(
          90deg,
          #ffefd2 0%,
          rgba(252, 201, 105, 1) 100%
        ),
        linear-gradient(#ffefea, #ffefea);
      background-blend-mode: normal, normal;
    }
  }
}
</style>
<style lang="scss" scoped>
.first {
  color: #ff0000 !important;
}
.second {
  color: #ff6000 !important;
}
.third {
  color: #fcc660 !important;
}
.car-name {
  display: inline-block;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #222;
  margin-bottom: 8px;
}
.amountChart-detail {
  display: flex;
  justify-content: space-between;
  .left,
  .right {
    flex: 1;
    .left-head,
    .right-head {
      margin-bottom: 20px;
      .title-content {
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-right: 18px;
        & + span {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #333;
        }
      }
    }
    .left-content,
    .right-content {
      .content-item {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        .content-box {
          flex: 1;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.chart-box {
  flex: 1;
}
.chart {
  width: calc(100% - 20px);
}
.content-number > span {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #222;
  font-weight: 600;
  margin-bottom: 9px;
}
.content-number {
  min-width: 220px;
  display: flex;
  flex-direction: column;
}
.content-index {
  color: #ccc;
  font-size: 18px;
  margin-right: 16px;
}
.segmentation {
  width: 1px;
  background-color: #f3f5f7;
  margin: 0 28px;
}
.compared {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .qoq {
    margin-left: 23px;
  }

  .yoy,
  .qoq {
    flex: 1;
    white-space: nowrap;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #86909c;
    line-height: 16px;
    .icon-up {
      display: inline-block;
      height: 12px;
      width: 10px;
      background: url(~@/assets/img/overview/up.png) no-repeat;
      background-size: 100% 100%;
    }

    .icon-down {
      display: inline-block;
      height: 12px;
      width: 10px;
      background: url(~@/assets/img/overview/down.png) no-repeat;
      background-size: 100% 100%;
    }

    .text-up {
      margin-left: 4px;
      color: #f53f3f;
    }

    .text-down {
      margin-left: 4px;
      color: #00b42a;
    }
  }
}
</style>
