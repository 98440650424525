import clsx from 'clsx';
import { Scrollbar } from 'element-ui';

import { genAlphabet, getScroller, getScrollTop, setScrollTop } from './utils';

import './index.scss';

export default {
  name: 'IndexList',
  props: {
    indexs: {
      type: Array,
      default: genAlphabet,
    },
  },
  data() {
    return {
      activeAnchorIndex: null,
    };
  },
  computed: {
    rootTarget() {
      return this.$refs.scrollbarRef.$refs.wrap || window;
    },
    children() {
      return this.$children[0].$children.filter((vNode) => !!vNode.getRect);
    },
  },
  watch: {
    indexs() {
      this.$nextTick(this.onScroll);
    },
    activeAnchorIndex(value) {
      if (value) {
        this.$emit('change', value);
      }
    },
  },
  mounted() {
    if (!this.scroller) {
      this.scroller = getScroller(this.$el, this.rootTarget);
    }
    this.scroller.addEventListener('scroll', this.onScroll, false);
  },
  beforeDestory() {
    if (this.scroller) {
      this.scroller.removeEventListener('scroll', this.onScroll, false);
    }
  },
  methods: {
    onScroll() {
      const scrollTop = getScrollTop(this.scroller);
      const scrollerRect = this.getScrollerRect();
      const rects = this.children.map((item) =>
        item.getRect(this.scroller, scrollerRect)
      );

      const active = this.getActiveAnchorIndex(scrollTop, rects);

      this.activeAnchorIndex = this.indexs[active];

      this.children.forEach((item, index) => {
        if (index === active || index === active - 1) {
          const rect = item.$el.getBoundingClientRect();
          item.left = rect.left;
          item.width = rect.width;
        } else {
          item.left = null;
          item.width = null;
        }

        if (index === active) {
          item.active = true;
          item.top = Math.max(rects[index].top - scrollTop) + scrollerRect.top;
        } else if (index === active - 1) {
          const activeItemTop = rects[active].top - scrollTop;
          item.active = activeItemTop > 0;
          item.top = activeItemTop + scrollerRect.top - rects[index].height;
        } else {
          item.active = false;
        }
      });
    },

    getScrollerRect() {
      if (this.scroller.getBoundingClientRect) {
        return this.scroller.getBoundingClientRect();
      }

      return {
        top: 0,
        left: 0,
      };
    },

    getActiveAnchorIndex(scrollTop, rects) {
      for (let i = this.children.length - 1; i >= 0; i--) {
        const prevHeight = i > 0 ? rects[i - 1].height : 0;
        const reachTop = prevHeight;

        if (scrollTop + reachTop >= rects[i].top) {
          return i;
        }
      }
      return -1;
    },

    scrollTo(index) {
      const match = this.children.filter((item) => String(item.index) === index);

      if (match[0]) {
        this.scrollIntoView(index, match[0]);
        this.$emit('select', match[0].index);
      }
    },

    scrollIntoView(index, vNode) {
      const element = vNode.$el;
      this.setRootScrollTop(element.offsetTop)
    },

    getRootScrollTop() {
      return this.rootTarget ? this.rootTarget.scrollTop : 0;
    },
    setRootScrollTop(value) {
      const rootElement = this.rootTarget || window;
      setScrollTop(rootElement, value);
    },

    scrollToElement(element) {
      const { index } = element.dataset;
      this.scrollTo(index);
    },

    setActive(index) {
      let activeAnchorIndex = this.activeAnchorIndex || null;

      for (let i = 0; i < this.children.length; i++) {
        const item = this.children[i];
        const anchorIndex = String(item.index);

        if (!index) {
          if (i === 0 && !activeAnchorIndex) {
            activeAnchorIndex = anchorIndex;
            break;
          }
        } else {
          if (anchorIndex === index) {
            activeAnchorIndex = anchorIndex;
            break;
          }
        }
      };

      // https://github.com/ElemeFE/element/blob/290e68ea6aa6c56b7d83182b650e3be4f77ff1b0/packages/theme-chalk/src/common/transition.scss#L25
      const timeout = 100;
      this.activeAnchorIndex = activeAnchorIndex;
      this.$nextTick(() => {
        setTimeout(() => {
          if (activeAnchorIndex) {
            this.scrollTo(activeAnchorIndex);
          }
        }, timeout);
      });
    },

    onClick(event) {
      this.scrollToElement(event.target);
    },
  },
  render() {
    return (
      <div class="index-list-container">
        <div class="index-list-scroll-container">
          <Scrollbar ref="scrollbarRef" class="index-list-scroll-main">
            <div class="index-list-main">
              {this.$slots.default}
            </div>
          </Scrollbar>
        </div>

        <div class="index-list-bar" onClick={this.onClick}>
          {this.indexs.map((index) => {
            const active = index === this.activeAnchorIndex;

            return (
              <span
                class={clsx('index-list-bar-item', { active })}
                data-index={index}
              >
                {index}
              </span>
            );
          })}
        </div>
      </div>
    );
  },
};
