<template>
  <PageContainer breadcrumb footer>
    <div class="container-box">
      <div class="container-l" ref="containerl" :class="{ 'w-100': !isAside }">
        <!--新样式S-->
        <div class="container-top">

          <h1 class="container-top-title">{{ contentFotm(this.data.title) }} </h1>

          <template>
            <div style="margin: 0 0 20px 20px">
              <div style="font-weight: bold; font-size: 18px">专题：{{ this.data.specialTitle }}</div>
              <span style="padding-right:10px">{{ this.data.updateTime }}</span>
            </div>
            <ul class="fj-list">
              <li v-for="(item, index) in this.data.fileInfo" :key="index">
                <div class="down-btn" @click="downBtn(item, index)">
                  <el-button type="primary" size="mini" :loading="loadObj['loading_' + index]">下载附件{{ index + 1 }}</el-button>
                </div>

                <div class="down-title">{{ index + 1 }}.{{ item.fileName }}</div>
              </li>
            </ul>
          </template>

        </div>
        <div class="container-bottom">
          <template>
            <div style="margin:0 0 20px 20px">
              <div style="display: flex;" v-if="this.data.summary!=null && this.data.summary!=''"><span class="colorStyle">【简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介】</span><span style="width: 800px">{{ htmlRestore(this.data.summary) }}</span></div>

            </div>
          </template>
          <template>
            <div class="content line-feed editorStyle" v-html="contentFotm(this.data.htmlContent)"></div>
          </template>
        </div>


      </div>

      <div class="container-r" v-if="isAside">
        <div class="container-r-position-box"  ref="containerPosition">
          <!--                        <div class="xgtj-box" style="background:#fff" >-->
          <!--                            <h1>相关推荐</h1>-->
          <!--                            <div class="zwtj">暂无推荐</div>-->
          <!--                        </div>-->
          <div class="xgtj-box">
            <div>
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import {downloadStrategy, ttStrategyReport} from "@/api/strategyReport";
import {htmlRestore,} from "@/utils/htmlRestore.js";
import store from "@/store";
import scroll from "@/store/modules/scoll.js";
import {download} from "@/api/common.js";
import {downloadFile} from "@/utils/download.js";
import UnfurlAndFoldBG from "@/components/UnfurlAndFoldBG.vue";
export default {
  name: "Vcontent",
  components: {
    UnfurlAndFoldBG,
  },
  data() {
    return {
      data:{},
      bgurl: require("@/assets/img/Group299.png"),
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreasData: [],
      belongAreas: [],
      tabData: [
        {value: "", label: "全部政策"},
        {value: "1", label: "国家政策"},
        {value: "2", label: "地方政策"},
      ],
      ac: "",
      areas: "",
      all: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
      pdfUrl:"http://localhost:8080/ISO 11154-2023.pdf",
      src:"",
      loadObj: {
        loading_0: false,
        loading_1: false,
        loading_2: false,
        loading_3: false,
        loading_4: false,
        loading_5: false,
        loading_6: false,
        loading_7: false,
        loading_8: false,
        loading_9: false,
        loading_10: false
      },
      previewShow: false,
      previewUrl: "",
      newisCollection: this.isCollection,
      aa: this.$route.meta.breadcrumb,
      numPages:""
    };
  },
  props: {
    props: {
      default() {
        return {
          title: "title",
          publishDate: "updateTime",
          source: "source",
          summary: "summary",
          link: "link",
          htmlContent: "htmlContent",
          author: "author",


        };
      },
    },
    isAside: {
      type: Boolean,
      default: false,
    },
    isCollection: {
      type: Boolean,
      default: true,
    },

  },
  computed: {
  },
  watch: {
  },
  created() {
    let id = this.$route.params.id;
    this.ttStrategyReport({ id });

  },
  methods: {
    ttStrategyReport(params) {
      ttStrategyReport(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.data.updateTime = this.data.publishDate;
          console.log(this.data)
        }
      });
    },

    bindLoading(index){
      this.$set(this.loadObj, 'loading_' + index, false);
      return this.loadObj['loading_' + index];
    },

    splitArr(data) {
      if (data && data.length > 0) {
        return data.split(",");
      } else {
        return [];
      }
    },
    contentFotm(data) {
      if (data) {
        return htmlRestore(data);
      }
    },
    downBtns() {
      let self = this;
      download({
        fileUrl: this.data.attachUrl,
        articleId: this.data.standardContentId,
      }).then((res) => {
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, self.data.acctachName);
          }
        });
      });
    },

    downBtn(item, index) {


          this.loadObj['loading_' + index] = true;
          let self = this;
          downloadStrategy({fileUrl: item.url, articleId: this.data.id, column1: "战略报告", column2: undefined}).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, item.fileName);
              }
            });
            this.loadObj['loading_' + index] = false;
          });

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //时间轴跳转
    commond(v) {
      //console.log(v.code.code);
      this.$emit("commond", v);
    },
  },
  updated() {
    if (this.isAside) {
      this.$nextTick(function () {
        store.commit(
            "scoll/getheightL",
            this.$refs.containerl.offsetHeight
        );
        store.commit(
            "scoll/getHeiht",
            this.$refs.containerPosition.offsetHeight
        );
      });
    }
  },
  destroyed() {
    scroll.state.scrollFlag = false;
  },
};
</script>
<style lang="scss" scoped>
/*.el-button{*/
/*    padding: 0 0*/
/*}*/

.container-box {
  display: flex;
  align-items: flex-start;
}

.container-l {
  width: 981px;
  // background: #fff;
  // padding: 24px;
  &.w-100 {
    width: 100%;
  }
}

.container-r {
  border-radius: 4px;
  margin-left: 10px;
  float: left;
  position: relative;
  width: 308px;
}

.container-tag {
  margin-bottom: 10px;
  margin-left: -10px;

  .tags {
    margin-left: 10px;
  }
}

.container-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #333;
  margin-bottom: 10px;
}

.container-summary,
.container-tip {
  font-size: 16px;
  line-height: 24px;
  color: #828282;
}

.container-summary {
  margin-bottom: 16px;
}

.container-tip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .container-tip-l {
    span {
      padding-right: 15px;
    }
  }
}

.collection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0 24px;
  height: 106px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;

  .collection-btn {
    font-size: 16px;
    line-height: 26px;
    color: #000;
    text-align: center;
    cursor: pointer;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 15px 0;
}

.fj {
  font-size: 13px;
  line-height: 13px;
  color: #828282;
}

.xgtj-box {
  // background: #fff;
  min-height: 200px;

  h1 {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 0 16px;
    color: #0d57bc;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
  }
}

.zwtj {
  text-align: center;
  line-height: 150px;
}

//新样式S
.container-top {
  background: #fff;
  padding: 0 16px 24px;
  margin-bottom: 12px;

  .container-top-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #333;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .bh {
    display: inline-block;
    padding: 10px 8px 10px 16px;
    //background: #f4f5f6;
    border-radius: 2px;
    //margin-bottom: 14px;

    span {
      font-weight: bold;
      font-size: 16px;
      //line-height: 16px;
      color: red;
    }
  }

  .time {
    font-size: 14px;
    line-height: 20px;
    color: #4f4f4f;
  }

  .laiyuan {
    padding-left: 16px;
    font-size: 16px;
    line-height: 16px;
    color: #4f4f4f;
    margin-bottom: 24px;
  }

  .down-btn {
    display: inline-block;
    /*padding: 7px 12px;*/
    font-size: 12px;
    line-height: 12px;
    background: #0d57bc;
    border-radius: 2px;
    border-color: #0d57bc;
    color: #fff;
    cursor: pointer;

    .down-icon {
      vertical-align: sub;
    }
  }

  .fj-list li {
    display: flex;
    margin-bottom: 8px;
  }

  .down-title {
    flex: 1;
    position: relative;
    font-size: 13px;
    line-height: 31px;
    color: #333;
    margin-left: 12px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      bottom: -4px;
      left: 0;
      border-bottom: 1px solid #f2f2f2;
    }
  }

  .fj-list li:last-child {
    margin-bottom: 0px;
  }

  .fj-list li:last-child .down-title::after {
    display: none;
  }
}

.container-bottom {
  background: #fff;
  padding: 24px 16px 16px;

  .grou {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.colorStyle{
  color: #0D57BC;
  font-weight: bold;
  width: 100px;
}
.el-divider--horizontal {

  margin: 12px 0;
}
.el-divider {
  background-color: #f4f4f4;
}
.orangeButton {
  border-color: #f49802;
  background: #f49802;
  color: #fff;
  margin-right: 20px;
  margin-left: 14px;
}
.marginLeft{
  margin-left: 16px;
}
//新样式E
</style>
<style>
/*.el-message-box {*/
/*  width: 440px;*/
/*}*/

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
