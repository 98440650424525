<template>
    <div>
        <p class="title">(我们会优先为您展示您感兴趣的领域资讯)</p>
        <div>
            <p class="title">行业类型</p>
            <el-checkbox-group v-model="keywords" size="small">
                <el-checkbox v-for="item in keywordsData" :key="item.dictValue" :label="item.dictValue" border>{{
                    item.dictLabel }}
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <div>
            <p class="title">技术领域</p>
            <el-checkbox-group v-model="technology" size="small">
                <el-checkbox :label="item.dictValue" border v-for="item in technologyData" :key="item.dictValue">{{
                    item.dictLabel }}
                </el-checkbox>
            </el-checkbox-group>
        </div>

        <div>
            <p class="title">国际化</p>
            <el-checkbox-group v-model="internationalize" size="small">
                <el-checkbox :label="item.dictValue" border v-for="item in internationalDate" :key="item.dictValue">{{
                    item.dictLabel }}
                </el-checkbox>
            </el-checkbox-group>
        </div>

        <div>
            <p class="title">属性分类</p>
            <el-checkbox-group v-model="keyword" size="small">
                <el-checkbox :label="item.dictValue" border v-for="item in keywordData" :key="item.dictValue">{{
                    item.dictLabel }}
                </el-checkbox>
            </el-checkbox-group>
        </div>

        <el-form ref="form" :model="form" :inline="true" class="demo-form-inline" label-width="120px">
            <p class="title">所属企业</p>
            <el-form-item prop="cycEnterpriseGroup">
                <IndexSelect placeholder="选择乘用车企业集团" v-model="form.cycEnterpriseGroup" :options="cycEnterpriseGroupData"
                    size="small" />
            </el-form-item>
            <el-form-item prop="cycEnterprise">
                <IndexSelect placeholder="选择乘用车企业" v-model="form.cycEnterprise" :options="cycEnterpriseData"
                    size="small" />
            </el-form-item>
            <el-form-item prop="sycEnterprise">
                <IndexSelect placeholder="选择商用车企业" v-model="form.sycEnterprise" :options="sycEnterpriseData"
                    size="small" />
            </el-form-item>
            <el-form-item prop="lbjEnterprise">
                <IndexSelect placeholder="选择零部件企业" v-model="form.lbjEnterprise" :options="lbjEnterpriseData"
                    size="small" />
            </el-form-item>
        </el-form>
        <p class="footerBtn">
            <el-button type="text" @click="close">取 消</el-button>
            <el-button type="text" @click="setHobby">确 定</el-button>
        </p>
    </div>
</template>
<script>
import IndexSelect from '@/components/IndexSelect'
import { UserSettings } from '@/api/Index.js'
import { getUserInfo } from '@/utils/auth.js'
import { Message } from "element-ui";
export default {
    components: {
        IndexSelect,
    },
    props: {
        keywordsData: {
            type: Array,
        },
        // 行业分类
        technologyData: {
            type: Array,
        },
        // 技术领域
        internationalDate: {
            type: Array,
        },
        //  国际化         
        keywordData: {
            type: Array,
        },
        //  属性分类  
        cycEnterpriseGroupData: {
            type: Array,
        },
        //  乘用车企业集团 
        cycEnterpriseData: {
            type: Array,
        },
        //   乘用车企业 
        sycEnterpriseData: {
            type: Array,
        },
        //  商用车企业   
        lbjEnterpriseData: {
            type: Array,
        },
        //  零部件企业
        backUserData: {
            type: Object,
        }
    },
    data() {
        return {
            keyword: [],
            keywords: [],
            technology: [],
            internationalize: [],
            form: {
                cycEnterpriseGroup: [],
                cycEnterprise: [],
                sycEnterprise: [],
                lbjEnterprise: []
            }
        }
    },
    created() {
        if (this.backUserData && Object.keys(this.backUserData).length !== 0) {
            this.keyword = this.splitIfNotEmpty(this.backUserData, 'keyword');
            this.technology = this.splitIfNotEmpty(this.backUserData, 'technology');
            this.keywords = this.splitIfNotEmpty(this.backUserData, 'releaseColumn');
            this.internationalize = this.splitIfNotEmpty(this.backUserData, 'internationalize');
            this.form.cycEnterprise = this.getDictValue(this.cycEnterpriseData, this.backUserData.cycEnterprise, 'cycEnterprise');
            this.form.sycEnterprise = this.getDictValue(this.sycEnterpriseData, this.backUserData.sycEnterprise, 'sycEnterprise');
            this.form.lbjEnterprise = this.getDictValue(this.lbjEnterpriseData, this.backUserData.lbjEnterprise, 'lbjEnterprise');
            this.form.cycEnterpriseGroup = this.getDictValue(this.cycEnterpriseGroupData, this.backUserData.cycEnterpriseGroup, 'cycEnterpriseGroup');
        }
    },
    methods: {
        setHobby() {
            const page = {
                userId: getUserInfo().userId,
                keyword: this.keyword.join(','),
                technology: this.technology.join(','),
                releaseColumn: this.keywords.join(','),
                internationalize: this.internationalize.join(','),
                cycEnterprise: this.getDictLabels(this.cycEnterpriseData, this.form.cycEnterprise).join(','),
                sycEnterprise: this.getDictLabels(this.sycEnterpriseData, this.form.sycEnterprise).join(','),
                lbjEnterprise: this.getDictLabels(this.lbjEnterpriseData, this.form.lbjEnterprise).join(','),
                cycEnterpriseGroup: this.getDictLabels(this.cycEnterpriseGroupData, this.form.cycEnterpriseGroup).join(','),
            }
            UserSettings(page).then((res) => {
                if (res.ok) {
                    this.$emit('close');
                    Message({
                        showClose: true,
                        message: '设置成功',
                        type: "success",
                    });
                }
            })
        },
        getDictLabels(data, searchList) {
            return data
                .filter(item => searchList.includes(item.dictLabel))
                .map(item => item.dictValue);
        },
        getDictValue(data, searchList, property) {
            if (data && searchList && property) {
                const searchArray = searchList?.split(',').map(item => item.trim());
                return data
                    .filter(item => searchArray.includes(item.dictValue))
                    .map(item => item.dictLabel);
            }
            return [];
        },
        splitIfNotEmpty(data, property) {
            return data?.[property]?.length !== 0 ? data[property].split(',') : [];
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
.title {
   margin-bottom: 10px;
}
.footerBtn {
    display: flex;
    justify-content: flex-end;
}

::v-deep {
    .el-checkbox.is-bordered+.el-checkbox.is-bordered {
        margin-left: 0;
    }

    .el-checkbox.is-bordered {
        border: 0;
    }

    .el-checkbox {
        margin-right: 0;
    }

    .el-checkbox.is-bordered.el-checkbox--small {
        padding: 0;
    }

    .el-select .el-input__inner::placeholder {
        color: rgb(51, 51, 51);
    }

    .el-checkbox__label {
        padding-left: 5px;
    }
}

.el-checkbox {
    width: 105px;
}

.title {
    font-weight: 500;
    margin-bottom: 10px;
}
</style>
  