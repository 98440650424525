<template>
  <div v-if="items.length > 0" class="index-banner-hot">
    <div class="title-tag">
      <h3 class="title-tag-text" style=""><span><img src="@/assets/img/rankingList.png" alt="">
      </span>&emsp;{{ title }}</h3>
    </div>
    <ul v-if="type == 'list'" class="index-new-timeLine">
      <li v-for="(item) in items" :key="item.id" @click="clickFun(item)">
        <TextOverTooltip :font-size="20" :title="htmlRestore(item.title)" />
        <span class="timeLine">{{ fmtDate("mm-dd", item.updateTime) }}</span>
      </li>
    </ul>

    <ul v-if="type == 'file'" class="container-r-content">
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
    </ul>
  </div>
</template>
<script>
import TextOverTooltip from '@/components/toolTip.vue'
export default {
  name: 'VcontentItem',
  components: {
    TextOverTooltip
  },
  props: {
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
    title: '',
    type: ''
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    items() {
      return this.list || []
    }
  },
  watch: {
    items(v) {
      if (v.length <= 0) {
        this.$store.dispatch('common/SET_ListFlagIndex')
      }
      this.$store.dispatch('common/SET_ListFlag')
    }
  },
  created() {
    this.$store.dispatch('common/SET_ListIndex')
    // if (this.items.length <= 0) {
    //     this.$store.dispatch("common/SET_ListFlagIndex");
    //        this.$store.dispatch("common/SET_ListIndex");
    // this.$store.dispatch("common/SET_ListFlag");
    // }
  },
  methods: {
    clickFun(item) {
      this.$emit('click', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.index-banner-hot {
    margin-top: 10px;
    margin-bottom: 18px;
    background: #fff;

    .title-tag {
        background: linear-gradient(#fff3ee, #fffbf9);
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 22px 20px 10px;

        .title-tag-text {
            display: inline-flex;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            span {
                padding-right: 10px;
            }
        }
    }

    .index-new-timeLine {
        padding-left: 20px;
        padding-bottom: 10px;
        overflow: hidden;

        li {
            cursor: pointer;
            padding-bottom: 14px;
            display: flex;
            // white-space: nowrap;
            justify-content: space-between;
        }

        .text-overflow {
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            display: inline-block;
            word-break: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .timeLine {
            color: #bdbdbd;
            font-size: 14px;
            padding-left: 20px;
            padding-right: 10px;
            text-align: right;
        }
    }
}
</style>
