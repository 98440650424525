//过滤时间
export function fmtDate(fmt, datestr) {
    if (datestr) {
        let ret;
        let date = new Date(datestr)
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    } else {
        return ""
    }
}
//当前时间
export function getYearMonth(nowDate) {
    let date = new Date(nowDate),
        Y = date.getFullYear(),
        M = date.getMonth() + 1;
    return {
        dictValue: Y + '-' + M,
        dictLabel: Y + '/' + M
    }
}

//获取开始结束之间的时间
export function getMonthBetween(start, end) {//传入的格式YYYY-MM
    var result = [];
    var s = start.split("-");
    var e = end.split("-");
    var min = new Date();
    var max = new Date();
    min.setFullYear(s[0], s[1] * 1 - 1, 1);//开始日期
    max.setFullYear(e[0], e[1] * 1 - 1, 1);//结束日期
    var curr = min;
    while (curr <= max) {
        var month = curr.getMonth();
        var year = curr.getFullYear();
        result.push(year + '-' + filtersO(month + 1));
        curr.setMonth(month + 1);
    }
    return result;
}

function filtersO(number) {
    let str = number < 10 ? '0' + number : number
    return str
}
//获取开始结束之间的年份
export function getYearArr(startYear, endYear) {
    var yearArr = [],
      prDate = new Date(),
      presentYear = prDate.getFullYear();//当前年份
    if (!endYear) { //为空为当前年份
      endYear = presentYear;
    }
    if (!startYear) { //为空为当前年前5年
      startYear = presentYear - 5;
    }
    for (var i = startYear; i <= endYear; i++) {
      yearArr.push(i.toString());
    }
    return yearArr;

  }