<template>
  <PageContainer breadcrumb footer>
     <Vcontent :data="data" :props="props" isContentTwo="qyfx"/>
  </PageContainer>
</template>
<script>
import { AnalysisQueryById } from "@/api/enterpriseAnalysis.js";
export default {
  data() {
    return {
      data: {},
       props:{
        tag:'keywords',
        summary:"introduction",
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.AnalysisQueryById({id});
  },
  methods: {
    AnalysisQueryById(params) {
      AnalysisQueryById(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.data.updateTime = this.data.publishDate
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
