export const genAlphabet = () => {
  const indexs = [];
  const charCodeOfA = 'A'.charCodeAt(0);

  for (let i = 0; i < 26; i++) {
    indexs.push(String.fromCharCode(charCodeOfA + i));
  }

  return indexs;
};

export const getScroller = (el, root = window) => {
  let node = el;

  while (
    node &&
    node.tagName !== 'HTML' &&
    node.tagName !== 'BODY' &&
    node.nodeType === 1 &&
    node !== root
  ) {
    const { overflowY } = window.getComputedStyle(node);
    if (/scroll|auto|overlay/i.test(overflowY)) {
      return node;
    }
    node = node.parentNode;
  }

  return root;
};

export const getScrollTop = (el) => {
  const top = 'scrollTop' in el ? el.scrollTop : el.pageYOffset;

  return Math.max(top, 0);
};

export const setScrollTop = (el, value) => {
  if ('scrollTop' in el) {
    el.scrollTop = value;
  } else {
    el.scrollTo(el.scrollX, value);
  }
};

export const getRootScrollTop = (el) => {
  return el ? getScrollTop(el) : (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
};

export const setRootScrollTop = (value, el) => {
  if (el) {
    setScrollTop(el, value);
  } else {
    setScrollTop(window, value);
    setScrollTop(document.body, value);
  }
};
