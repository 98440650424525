<template>
  <router-view v-if="$route.name == '专题分析详情'"/>
  <PageContainer v-else>
    <div class="search-top" :class="{'h':hflag}">
      <div class="search-input-box">
        <div>
          检索方式：
          <el-radio v-model="content" label="title">按专题</el-radio>
          <el-radio v-model="content" label="htmlContent">按内容</el-radio>
        </div>
        <div class="search-btn-box">
          <el-input style="width: 650px" v-model="values"/>
          <div class="search-btn" @click="search">检索</div>
        </div>
      </div>
    </div>
    <div class="search-bottom" v-if="hflag">

      <div class="box"  v-loading="loading">
        <div class="box_box_title" v-for="item in list" :key="item.id"
             @click="toHref('/strategyReport/details/'+item.id)">
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img
                  style="width: 267px; height: 150px"
                  :src="item.imgUrl ? item.imgUrl : defaultUrl"
                  alt=""
              />
            </div>
            <div class="flex_col" flex="1">
              <div>
                <div flex="c" style="margin: 5px 0">
                  <p class="" v-html="item.source"></p>
                </div>
                <div flex="c" style="margin: 2px 0; font-size: 13px; font-weight: bold">
                  <p class="" v-html="item.title"></p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ item.summary }}
                  <!--                    {{ item.introduction }}-->
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.publishTime }}
                <!--                  {{ item.publishDate }}-->
              </div>
            </div>
          </div>
          <div
              class="check"
              @click.stop="checkFun(item)"
              :class="{ ac: item.check }"
              v-if="item.fileInfo"
          >
            <i class="fa fa-check"></i>
          </div>
        </div>
        <div class="pages">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageData.currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageData.pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import fileMixin from '@/mixin/file.js'
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";

import {searchData} from "@/api/FullTextRetrieval.js";

export default {
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      content: "title",
      list: [],
      values: "",
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      checkData: [],
      hflag: true,
      show:true,
      showP:true,
      loading: false,
      typeParams: undefined,
    };
  },
  created() {
    let title = this.$route.query.title;
    let typeParams = this.$route.query.menu;
    this.values = title;
    this.typeParams = typeParams;
    this.page();
  },
  mixins: [fileMixin],
  methods: {
    leaveTab() {
      if(!getUserInfo()){
        return false;
      }
    },
    tabClick(tab) {
      if (getUserInfo()) {
        this.form.contentCategory = tab.name;
        this.page();
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin();
      }

    },
    toHref(url) {
      let newUrl = this.$router.resolve(url);
      window.open(newUrl.href, "_blank");
    },

    page() {
      let param = {
        // 搜索关键字
        keywords: this.values,
        // 页码
        pageNum: this.pageData.pages.page,
        // 每页条数
        pageSize: this.pageData.pages.pageSize,
        // 标题0 全文1
        title: this.content === "title" ? "0" : "1",
        sortFile: "_score",
        categoryTys: ["战略报告"],
        // 全部类型0 指定类型1
        all: 1,
        // 指定周月报产品
        typeParams: this.typeParams.split(","),
      };
      this.loading = true;
      searchData(param).then(res => {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].check = false;
          if (this.checkData.length > 0) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.checkData[j].id == list[i].id) {
                list[i].check = true;
              }
            }
          }
        }
        this.list = list;
        this.pageData.total = res.data.total;
        this.loading = false;
      });
    },

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.page();
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.page();
    },
    //搜索
    search() {
      this.page()
    },

  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}

.search-top {
  display: flex;
  width: 100%;
  background: #fff;

  .search-input-box {
    margin: auto;
  }

  &.h {
    height: 130px;
  }
}

.search-btn-box {
  display: flex;
  margin-top: 9px;

  /deep/ .el-input__inner {
    height: 44px;
    line-height: 44px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-btn {
    width: 109px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

.search-bottom {
  display: block;
  position: relative;
  width: 87%;
  height: calc(100% - 130px);
  padding-right: 218px;
  margin: 0 168px;
}

.box_box_title {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.file-box {
  position: absolute;
  top: 10px;
  right: 0;
  width: 208px;
  background: #fff;
  border-radius: 4px;
  max-height: calc(100% - 130px);
  overflow-y: auto;
}

.file-title {
  padding: 15px 0 15px 16px;
  font-size: 16px;
  color: #0D57BC;
  border-bottom: 1px solid #f2f2f2;
}

.file-content {
  padding: 24px 16px;

  .file-item {
    line-height: 21px;
    color: #333;
    margin-bottom: 24px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.file-btn {
  width: 168px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0D57BC;
  border: 1px solid #0D57BC;
  margin: 0 auto;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(21, 67, 133, 0.2);
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  color: #fff;

  .fa {
    margin-right: 0;
  }

  &.ac {
    background: #0D57BC;
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}
</style>
