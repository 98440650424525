<template>
  <div
    class="enterpriseDetail"
    id="capacity"
    v-if="isShowCapacity"
    data-name="企业产能"
  >
    <chartCard cardTitle="企业产能" :chartData="sourceData">
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </chartCard>
  </div>
</template>

<script>
import chartCard from "../chart.vue";

import {
  getProductionRegionData,
  postExportProductionRegionData
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Vue from "vue";

export default {
  name: "Capacity",
  components: {
    chartCard
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      debouncedGetProductionRegionData: null,
      lastRouteChange: "",
      isShowCapacity: false,
      isLoading: true,
      sourceData: {},
      params: {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      }
    };
  },

  created() {
    this.debouncedGetProductionRegionData = this.debounce(
      this.handleRouteChange,
      100
    );
    this.debouncedGetProductionRegionData();
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业对标分析", "3", "", "", "", "", "企业产能", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postExportProductionRegionData(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "企业产能.xlsx");
              });
            }
          }
        } else {
          postExportProductionRegionData(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "企业产能.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    handleRouteChange() {
      if (
        this.lastRouteChange === "checkedList" ||
        this.lastRouteChange === ""
      ) {
        this.getProductionRegionData(this.params, "isShowCapacity");
      }
      this.lastRouteChange = "";
    },
    getProductionRegionData(params, isShowCapacity) {
      this.isLoading = true;
      getProductionRegionData(params).then(res => {
        if (res.ok) {
          const { seriesList, xAxisList } = res.data;
          if (isShowCapacity) {
            this.isShowCapacity =
              seriesList.length !== 0 &&
              xAxisList.length !== 0 &&
              (this.$route.query.checkedList.includes("企业产能") ||
                this.$route.query.checkedList.includes("全部"));
          }
          Vue.set(this, "sourceData", res.data || {});
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"(newId) {
      this.params.enterpriseIdList = JSON.parse(newId);
      this.lastRouteChange = "selectedEnterprise";
      this.debouncedGetProductionRegionData();
    },
    "$route.query.checkedList"() {
      this.lastRouteChange = "checkedList";
      this.debouncedGetProductionRegionData();
    }
  }
};
</script>
