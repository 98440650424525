
<template>
  <PageContainer>
    <div class="g-content-table">
          <Vmonth/>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 预测动态页面
 * @param {*}
 * @return {*}
 */
import { getSaleList } from "@/api/salesForecast.js";
import { getUserInfo } from "@/utils/auth.js";
import Vmonth from "../sycPredict/monthBettery.vue";
export default {
    components: {
        Vmonth,
    },
    data() {
        return {
            fxList: null,
            loading: false,
            tableData: [],
            pagination: {},
            currentPage: 0,
            show: false,
            nowYear: "",
            oldYear: "",
        };
    },
    created() {
      this.setDate();
        if (getUserInfo()) {
            this.show = true;
        } else {
            this.show = false;
        }
    },

    methods: {
        setDate() {
            let date = new Date(),
                year = date.getFullYear();
            this.nowYear = year;
            this.oldYear = year +'-'+(year + 4);
        },
    },
};
</script>
<style lang="scss" scoped>
.dynamic-top {
    padding: 20px 32px;
    margin-bottom: 12px;
    background: #fff;
    .dynamic-top-original-box {
        display: flex;
        margin-top: 32px;
        margin-left: -26px;
        cursor: pointer;
        .dynamic-top-original {
            display: flex;
            flex: 0 0 33.333%;
            margin-left: 26px;
            .dynamic-top-original-img {
                width: 150px;
                height: 99px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    box-shadow: 0px 0px 7.31074px 3.65537px
                        rgba(225, 225, 225, 0.25);
                }
            }
            .dynamic-top-original-inner {
                width: 140px;
                margin-left: 6px;
                position: relative;
                p {
                    // line-height: 21px;
                    color: #000;
                    font-weight: 500;
                    // height: 64px;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                p:hover {
                    color: #00f;
                }
                span {
                    color: #828282;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
}
</style>
