<template>
    <div class="yieid-contain">
        <div class="yidid-top">
            <span v-if="show && !showP" />
            <not-logged-in-mask v-else />
            <div :class="['chart',
                (show && !showP) ? '' : 'show-mask',
                'passenger-car-market-performance-body',
            ]">
                <div class="leftChart">
                    <div class="chartTOP">
                        <div>
                            <span class="title">
                                {{ firmList.enterpriseProductionDateStr ?
                                    firmList.enterpriseProductionDateStr : firmList.enterpriseSaleDateStr }}
                            </span>
                            <span class="text">单位/辆</span>
                        </div>
                        <div flex="sb">
                            <div class="topRadio">
                                <el-radio-group v-model="firmValue" size="mini" @change="firmChange()">
                                    <el-radio-button label="产量"></el-radio-button>
                                    <el-radio-button label="销量"></el-radio-button>
                                </el-radio-group>
                            </div>
                            <p class="box-text" @click="moreClick('国内企业产量销量')">
                                查看更多
                                <i class="el-icon-arrow-right"></i>
                            </p>
                        </div>
                    </div>
                    <capacChart :list="firmList.enterpriseProductionList ?
                        firmList.enterpriseProductionList : firmList.enterpriseSaleList" roteShow />
                </div>
                <div class="rightChart">
                    <div class="chartTOP">
                        <div>
                            <span class="title">
                                {{ yieidtitle }}各省份产能
                            </span>
                            <span class="text">单位/万辆</span>
                        </div>
                        <div flex="sb">
                            <div class="topRadio righttopRadio">
                                <el-radio-group v-model="cartypeValue" size="mini">
                                    <el-radio-button label="产量"></el-radio-button>
                                    <el-radio-button label="销量"></el-radio-button>
                                </el-radio-group>
                            </div>
                            <p class="box-text" @click="moreClick('国内汽车产能布局')">
                                查看更多
                                <i class="el-icon-arrow-right"></i>
                            </p>
                        </div>
                    </div>
                    <Chart :list="mapList" :maxNum="maxNum" roteShow />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getEnterpriseSalesData,
    getEnterpriseProductionData,
    MapData,
} from "@/api/yieid.js";
import Chart from "./components/yieidchart.vue";
import capacChart from "./components/capactionchart.vue";
import SearchSelect from "@/components/searchSelect";
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy  } from "@/utils/auth.js";
import { NotLoggedInMask } from "@/components/newData/mask";

export default {
    components: {
        Chart,
        SearchSelect,
        capacChart,
        NotLoggedInMask,
    },
    props: {},
    data() {
        return {
            showP: false,
            show: false,
            listloading: false,
            yieidtitle: '',
            typefirstLabel: '',
            modelfirstLabel: '',
            enterfirstLabel: '',
            firmList: {},
            cartypeList: {},
            mapList: [],
            maxNum: 0,
            firmValue: "产量",
            cartypeValue: "产量",
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
        };
    },
    // watch: {
    //     firmValue: {
    //         deep: true,
    //         immediate: true,
    //         handler(newVal, oldVal) {
    //             logRecords("企业情报", "国内企业产量销量", "3", "", "", "", "", newVal);
    //         }
    //     }
    // },
    created() {
        if (getUserInfo()) {
            this.show = true;
            if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                let menuName = getUserInfo().menuName;
                if (!menuName.includes('企业情报')) {
                    this.showP = true;

                }
            }
        } else {
            this.show = false;
        }
    },
    mounted() {
        this.getfirmData();
        this.getcartypeData();
    },
    methods: {
        // 数据请求
        firmChange() {
            // if (getUserInfo()) {

            //     if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
            //         let menuName = getUserInfo().menuName;
            //         if (!menuName.includes('企业情报')) {
            //             this.sqsyLogin();
            //             this.insertBuryPoint();
            //             this.firmValue = '产量'
            //             return
            //         } else {
            //             if (getUserInfo().roleKey == 'trialrole') {
            //                 this.formLog.userId = getUserInfo().userId
            //                 buryPoint(this.formLog)
            //                 this.firmValue = '产量'
            //                 openSy()
            //                 return
            //             } 
            //         }
            //     }
            // } else {
            //     this.showLogin();
            //     this.firmValue = '产量'
            //     return
            // }
            this.getfirmData();
        },
        getcartypeData() {
            MapData({isOverview:true}).then(res => {
                this.mapList = res.data.mapData
                this.maxNum = res.data.maxNum
                this.yieidtitle = res.data.year
            });
        },
        getfirmData() {
            if (this.firmValue === "产量") {
                getEnterpriseProductionData().then(res => {
                    this.firmList = res.data;
                });
            } else {
                getEnterpriseSalesData().then(res => {
                    this.firmList = res.data;
                });
            }
        },
        moreClick(value) {
            if (getUserInfo()) {
                logRecords('企业情报', value, '3', 'tt_enterprise_analysis', '', '', '', '', '查看更多')
                if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
                    const menuName = getUserInfo().menuName
                    if (!menuName.includes('企业情报')) {
                        this.sqsyLogin()
                        this.insertBuryPoint()
                    } else {
                        this.$router.push({
                            name: "产销产能",
                            params: {
                                radioValue: value
                            }
                        });
                    }
                } else {
                    this.$router.push({
                        name: "产销产能",
                        params: {
                            radioValue: value
                        }
                    });
                }
            } else {
                this.showLogin()
            }
        },
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
    }
};
</script>
<style lang="scss" scoped>
.yieid-contain {
    width: 100%;
    margin-top: 10px;

    .yidid-top {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        .yieidtitle {
            font-size: 20px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            padding: 30px 60px 20px 30px;
        }

        .chart {
            display: flex;

            .title {
                font-size: 18px;
                color: rgb(51, 51, 51);
                font-weight: bold;
            }

            .chartTOP {

                .topRadio {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    // padding-left: 33px;
                }

                .righttopRadio {
                    visibility: hidden;
                }

                .box-text {
                    padding-left: 10px;
                    font-size: 12px;
                    cursor: pointer;
                    color: #999999;
                    // padding-left: 14px;
                }
            }

            @media screen and (max-width: 1520px) {
                .chartTOP {
                    display: inline;
                }

            }

            @media screen and (min-width: 1520px) {
                .chartTOP {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                }

            }

            .leftChart {
                width: 50%;
                padding: 25px 20px 0 22px;
                margin-right: 5px;
                background: #fff;
            }

            .rightChart {
                width: 50%;
                margin-left: 5px;
                padding: 25px 20px 0 22px;
                background: #fff;
            }

            .text {
                font-size: 12px;
                padding-left: 12px;
                color: rgb(51, 51, 51);
            }
        }
    }

    .bottom {
        width: 100%;
        background: #fff;

        .list {
            width: 100%;

            .tablelist {
                padding: 0 60px 0 30px;
            }

            .list-sele {
                display: flex;
                border-bottom: 1px solid #f3f5f7;
                padding: 20px 60px 20px 30px;

                .item {
                    padding-right: 30px;
                }

                .el-select {
                    width: 210px;
                    padding-left: 10px;
                }
            }

            .list-title {
                font-size: 16px;
                color: rgb(34, 34, 34);
                font-weight: bold;
                padding: 20px 60px 20px 30px;
            }
        }
    }

    .pages {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        background: #fff;
    }
}


::v-deep {
    .el-radio-button__inner:hover {
        color: #606266;
    }

    .is-active .el-radio-button__inner {
        border-color: #3f76ea;
        background: none;
        color: #3f76ea;
    }

    .is-active :hover {
        color: #3f76ea;
    }
}

.show-mask {
    filter: blur(3px);
    user-select: none;
    -webkit-user-select: none;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }
}

.not-logged-in-mask {
    z-index: 1;
    top: 30%;
}</style>