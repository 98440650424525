<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="content" :props="props" :isCollection="false"/>
  </PageContainer>
</template>
<script>

import { ttWebsiteSelectedFind } from "@/api/Index.js";
export default {
  data() {
    return {
      content: {},
      props: {
        tag: "keywords",
        summary: "introduction",
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttWebsiteSelectedFind(id);
  },
  methods: {
    ttWebsiteSelectedFind(params) {
      ttWebsiteSelectedFind(params).then((res) => {
        this.content = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
