<template>
  <div class="enterpriseDetail" id="basicInfo" data-name="基本信息">
    <el-card :body-style="{ padding: '20px 22px 33px' }">
      <div class="enterprise-basic">
        <img
          class="news-image"
          style="width: 144px; height: 144px;border-radius: 4px;"
          :src="dataSource.enterpriseLogoUrl"
        />
        <div class="enterprise-info">
          <div class="content-box">
            <div class="title-box">
              <span class="name">{{ dataSource.enterpriseName }}</span>
              <span
                v-for="(o, d) in enterpriseLabelList"
                :key="d"
                class="title-type"
                :class="getKeyByValue(o)"
              >
                {{ o }}
              </span>
            </div>
            <div v-if="(dataSource.synopsis && dataSource.synopsis.length > 140)" class="content">
              <div v-if="isCollapsed">
                {{ dataSource.synopsis.slice(0, 140) + "..." }}
                <!-- 显示文本的前100个字符 -->
                <el-button type="text" @click="toggle"
                  ><span
                    :style="{
                      fontSize: '12px',
                      color: '#3f76ea',
                      marginRight: '10px'
                    }"
                    >展开</span
                  >
                  <i
                    :style="{ color: '#a9b9dc', fontSize: '12px' }"
                    class="el-icon-arrow-down"
                  ></i
                ></el-button>
              </div>
              <div v-else>
                {{ dataSource.synopsis }}
                <el-button type="text" @click="toggle"
                  ><span
                    :style="{
                      fontSize: '12px',
                      color: '#3f76ea',
                      marginRight: '10px'
                    }"
                    >收起</span
                  >
                  <i
                    :style="{ color: '#a9b9dc', fontSize: '12px' }"
                    class="el-icon-arrow-up"
                  ></i
                ></el-button>
              </div>
            </div>
            <div v-else>{{ dataSource.synopsis }}</div>
          </div>
          <div class="salesAmount">
            <div class="found-date" v-if="dataSource.foundDate">
              成立时间: <span>{{ dataSource.foundDate }}</span>
            </div>
            <span
              class="segmentation"
              v-if="dataSource.supervisor && dataSource.foundDate"
            ></span>
            <div class="web-link" v-if="dataSource.supervisor">
              公司负责人: <span>{{ dataSource.supervisor }}</span>
            </div>
            <span
              class="segmentation"
              v-if="dataSource.webLink && dataSource.supervisor"
            ></span>
            <div class="web-link" v-if="dataSource.webLink">
              官方网址: <span>{{ dataSource.webLink }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getEnterpriseBasicInfo } from "@/api/enterpriseAnalysis.js";

export default {
  data() {
    return {
      params: {
        enterpriseId: this.$route.params.id
      },
      isCollapsed: true, // 控制文本是展开还是收起
      changeBGC: true,
      randomIndex: null,
      dataSource: {},
      enterpriseLabelList: [],
      enterpriseType: {
        "type-gy": "国营",
        "type-my": "民营",
        "type-hz": "合资",
        "type-wz": "外资",
        "type-Hongkong": "中国香港企业",
        "type-Macao": "中国澳门企业",
        "type-Taiwan": "中国台湾企业",
        "type-sydw": "事业单位/科研机构",
        "type-fgs": "分公司/代表处",
        "type-other": "其他"
      },
      bgcClass: [
        "bgc-eaf6ff",
        "bgc-fef0f0",
        "bgc-fff5d9",
        "bgc-eaf0fd",
        "bgc-ffeee4",
        "bgc-ebeefd",
        "bgc-dcfaf8",
        "bgc-e9e8eb",
        "bgc-e9faf2",
        "bgc-ffedf6"
      ]
    };
  },

  created() {
    this.getEnterpriseBasicInfo(this.params);
  },
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    },
    getEnterpriseBasicInfo(params) {
      getEnterpriseBasicInfo(params).then(res => {
        if (res.ok) {
          this.dataSource = res.data;
          this.enterpriseLabelList = res.data.enterpriseLabel?.split(",");
        }
      });
    },
    getKeyByValue(value) {
      // 使用对象中已定义的类型
      for (let key in this.enterpriseType) {
        if (
          this.enterpriseType.hasOwnProperty(key) &&
          this.enterpriseType[key] === value
        ) {
          return key;
        }
      }

      // 如果标签不在预定义类型中，则基于标签的内容来分配颜色
      if (value) {
        let hash = 0;
        for (let i = 0; i < value.length; i++) {
          hash = value.charCodeAt(i) + ((hash << 5) - hash);
        }
        let index = Math.abs(hash % this.bgcClass.length);
        return this.bgcClass[index];
      }
      return "";
    }
  },
  watch: {
    // 观察路由的变化来更新userId
    "$route.params.id"(newId) {
      this.params.enterpriseId = newId;
    }
  }
};
</script>

<style lang="scss" scoped>
.enterprise-basic {
  display: flex;
  align-items: center;
  .el-button {
    padding-left: 0;
    padding-right: 0;
  }
  .news-image {
    margin-right: 30px;
  }

  .bgc-eaf6ff {
    background-color: #eaf6ff;
    color: #45affe;
  }
  .bgc-fef0f0 {
    background-color: #fef0f0;
    color: #ff2f2f;
  }
  .bgc-fff5d9 {
    background-color: #fff5d9;
    color: #ffaf3a;
  }
  .bgc-eaf0fd {
    background-color: #eaf0fd;
    color: #3168ec;
  }
  .bgc-ffeee4 {
    background-color: #ffeee4;
    color: #ff6300;
  }
  .bgc-ebeefd {
    background-color: #ebeefd;
    color: #3058f7;
  }
  .bgc-dcfaf8 {
    background-color: #dcfaf8;
    color: #16dbcc;
  }
  .bgc-e9e8eb {
    background-color: #e9e8eb;
    color: #333;
  }
  .bgc-e9faf2 {
    background-color: #e9faf2;
    color: #2cd28a;
  }
  .bgc-ffedf6 {
    background-color: #ffedf6;
    color: #ff5daf;
  }

  .enterprise-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 144px;
    .content-box {
      .title-box {
        margin-bottom: 19px;
        .name {
          font-family: MicrosoftYaHei-Bold;
          font-size: 18px;
          font-weight: 600;
          color: #222222;
          margin-right: 19px;
        }
        .title-type {
          padding: 3px 6px;
          font-size: 16px;
          border-radius: 2px;
          display: inline-flex;
          margin-right: 10px;
          line-height: 1;
        }
        .type-gy,
        .type-my,
        .type-hz,
        .type-wz {
          background-color: #fef0f0;
          color: #ff2f2f;
        }
        .type-hongkong,
        .type-macao,
        .type-taiwan {
          background-color: #fff5d9;
          color: #ffaf3a;
        }
        .type-sydw {
          background-color: #ebeefd;
          color: #3058f7;
        }
        .type-fgs {
          background-color: #e9faf2;
          color: #2cd28a;
        }
        .type-other {
          background-color: #e9e8eb;
          color: #333333;
        }
      }
      .content {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #666;
        font-weight: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0px;
      }
    }
    .salesAmount {
      display: flex;
      align-items: center;
      .found-date,
      .web-link {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #bbb;
        span {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          font-weight: 600;
          color: #222222;
        }
      }
      .segmentation {
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: #bbb;
        margin: 0 16px;
      }
    }
  }
}
</style>
