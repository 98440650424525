<template>
  <el-dialog
    :visible="show"
    append-to-body
    width="600px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClickClose"
  >
    <div class="title">
      选择查询模板
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="content">
      <div v-if="queryOptionList.length > 0" class="template-list custom-scrollbar">
        <div v-for="item in queryOptionList" :key="item.queryOptionId" class="item">
          <div class="name" :title="item.name">{{ item.name }}</div>
          <div class="operate">
            <div class="select-btn" @click="handleSelectTemplate(item)">打开</div>
            <div class="del-btn" @click="handleClickDelete(item)">删除</div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="name" title="查询模板1">查询模板1</div>
          <div class="operate">
            <div class="select-btn" @click="handleSelectTemplate">打开</div>
            <el-popconfirm title="确定删除该查询模板？">
              <div slot="reference" class="del-btn">删除</div>
            </el-popconfirm>
          </div>
        </div> -->
      </div>
      <div v-else class="empty-text">
        <div>暂未添加模板</div>
        <div>可在查询页面先保存查询为常用模板</div>
      </div>
    </div>
    <div slot="footer" class="footer">
      <el-button size="small" @click="handleClickClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'QueryTemplateSelectDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    queryOptionList: {
      type: Array,
      required: true
    },
    deleteMethod: {
      type: Function,
      required: true
    }
  },
  methods: {
    // 选中某个模板的回调
    handleSelectTemplate(template) {
      // 将其转换为 object
      // btoa()和atob()方法只适用于ASCII字符，如果需要编码中文或其他非ASCII字符，需要用encodeURIComponent()方法进行编码和解码
      const queryParams = JSON.parse(window.decodeURIComponent(window.atob(template.optionJson)))
      this.$emit('select', queryParams)
      this.handleClickClose()
    },
    handleClickClose() {
      this.$emit('update:show', false)
    },
    // 点击删除按钮
    handleClickDelete({ queryOptionId }) {
      this.$confirm('是否删除该查询模板？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteMethod({ queryOptionId })
          .then(() => {
            this.$message.success('删除成功！')
            // 因为没有重新获取查询条件列表的接口可调，
            // 所以只能让父组件根据查询条件的 queryOptionId 去 filter 一下查询条件列表
            this.$emit('delete', queryOptionId)
          })
          .catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1D2129;
  margin-bottom: 24px;

  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #4E5969;
    cursor: pointer;
  }
}

.content {
  height: 220px;

  .empty-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #A0A4AD;
    font-size: 15px;
  }
}

.template-list {
  height: 100%;
  overflow: auto;

  .item {
    padding: 8px 10px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color .25s ease;
    border-radius: 4px;
    border-bottom: 1px solid #efefef;

    &:hover {
      background-color: #F5F7FA;
    }
  }

  .name {
    flex: 1;
    width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-size: 15px;
  }

  .operate {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .select-btn, .del-btn {
      padding: 2px 10px;
      cursor: pointer;
      transition-property: background-color, color, border-color;
      transition-duration: 0.3s;
      border-radius: 4px;
    }

    .select-btn {
      margin-right: 10px;
      background-color: #ecf5ff;
      color: #0d57bc;
      border: 1px solid #b3d8ff;

      &:hover {
        background-color: #0d57bc;
        border-color: #0d57bc;
        color: #fff;
      }
    }

    .del-btn {
      background-color: #fef0f0;
      color: #f56c6c;
      border: 1px solid #fbc4c4;

      &:hover {
        background-color: #f56c6c;
        border-color: #f56c6c;
        color: #fff;
      }
    }
  }
}

::v-deep {

  .el-dialog__header{
    display: none;
  }

  .el-dialog__body {
    padding: 20px 24px 10px;
  }

  .el-input {
    width: 300px;
  }

  // .el-dialog__footer {
  //   padding: 10px 34px 20px;
  // }
}
</style>
