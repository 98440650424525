var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterpriseDetail",attrs:{"id":"basicInfo","data-name":"基本信息"}},[_c('el-card',{attrs:{"body-style":{ padding: '20px 22px 33px' }}},[_c('div',{staticClass:"enterprise-basic"},[_c('img',{staticClass:"news-image",staticStyle:{"width":"144px","height":"144px","border-radius":"4px"},attrs:{"src":_vm.dataSource.enterpriseLogoUrl}}),_c('div',{staticClass:"enterprise-info"},[_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"title-box"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.dataSource.enterpriseName))]),_vm._l((_vm.enterpriseLabelList),function(o,d){return _c('span',{key:d,staticClass:"title-type",class:_vm.getKeyByValue(o)},[_vm._v(" "+_vm._s(o)+" ")])})],2),((_vm.dataSource.synopsis && _vm.dataSource.synopsis.length > 140))?_c('div',{staticClass:"content"},[(_vm.isCollapsed)?_c('div',[_vm._v(" "+_vm._s(_vm.dataSource.synopsis.slice(0, 140) + "...")+" "),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.toggle}},[_c('span',{style:({
                    fontSize: '12px',
                    color: '#3f76ea',
                    marginRight: '10px'
                  })},[_vm._v("展开")]),_c('i',{staticClass:"el-icon-arrow-down",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1):_c('div',[_vm._v(" "+_vm._s(_vm.dataSource.synopsis)+" "),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.toggle}},[_c('span',{style:({
                    fontSize: '12px',
                    color: '#3f76ea',
                    marginRight: '10px'
                  })},[_vm._v("收起")]),_c('i',{staticClass:"el-icon-arrow-up",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1)]):_c('div',[_vm._v(_vm._s(_vm.dataSource.synopsis))])]),_c('div',{staticClass:"salesAmount"},[(_vm.dataSource.foundDate)?_c('div',{staticClass:"found-date"},[_vm._v(" 成立时间: "),_c('span',[_vm._v(_vm._s(_vm.dataSource.foundDate))])]):_vm._e(),(_vm.dataSource.supervisor && _vm.dataSource.foundDate)?_c('span',{staticClass:"segmentation"}):_vm._e(),(_vm.dataSource.supervisor)?_c('div',{staticClass:"web-link"},[_vm._v(" 公司负责人: "),_c('span',[_vm._v(_vm._s(_vm.dataSource.supervisor))])]):_vm._e(),(_vm.dataSource.webLink && _vm.dataSource.supervisor)?_c('span',{staticClass:"segmentation"}):_vm._e(),(_vm.dataSource.webLink)?_c('div',{staticClass:"web-link"},[_vm._v(" 官方网址: "),_c('span',[_vm._v(_vm._s(_vm.dataSource.webLink))])]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }