import { render, staticRenderFns } from "./zhcfytcxqkb.vue?vue&type=template&id=254e7904&scoped=true"
import script from "./zhcfytcxqkb.vue?vue&type=script&lang=js"
export * from "./zhcfytcxqkb.vue?vue&type=script&lang=js"
import style0 from "./zhcfytcxqkb.vue?vue&type=style&index=0&id=254e7904&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254e7904",
  null
  
)

export default component.exports