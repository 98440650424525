<!--
 * @Author: your name
 * @Date: 2021-07-21 09:17:06
 * @LastEditTime: 2021-07-21 09:51:19
 * @LastEditors: Please set LastEditors
 * @Description: 公告查询
 * @FilePath: /information-vue/code/src/views/announcement/announcementSearch/index.vue
-->
<template>
  <router-view
    v-if="
      $route.name == '选装内容' ||
      $route.name == '公告查询详情' ||
      $route.name == '整车比对' ||
      $route.name == '底盘内容' ||
      $route.name == '摩托车内容' ||
      $route.name == '三轮车内容' ||
      $route.name == '历史'
    "
  />
  <PageContainer v-else>
<!--      <VnotLogin-->
<!--        v-if="!show"-->
<!--        title="公告"-->
<!--        text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。"-->
<!--        tableTitle="最新公告"-->
<!--        :showTips="showTips"-->
<!--      />-->
    <template >
      <div class="search-form">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="整车" name="整车">
            <Vehicle v-if="activeName == '整车'" :category="activeValue" />
          </el-tab-pane>
          <el-tab-pane label="底盘" name="底盘">
            <Chassis v-if="activeName == '底盘'" />
          </el-tab-pane>
          <el-tab-pane label="摩托车" name="摩托车">
            <Motorcycles
              v-if="activeName == '摩托车'"
              :category="activeValue"
            />
          </el-tab-pane>
          <el-tab-pane label="三轮汽车" name="三轮汽车">
            <ThirdWheel
              v-if="activeName == '三轮汽车'"
              :category="activeValue"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";

import Vehicle from "./Vehicle.vue"; // 整车
import Chassis from "./Chassis.vue"; // 底盘
import Motorcycles from "./Motorcycles.vue"; // 摩托
import ThirdWheel from "./ThirdWheel.vue"; // 三轮

import { getUserInfo } from "@/utils/auth.js";

export default {
  components: {
    // VnotLogin,
    Vehicle,
    Chassis,
    Motorcycles,
    ThirdWheel,
  },
  data() {
    return {
      show: false,
      activeName: "整车",
      activeValue: "1",
      showTips: [
        {
          title: "公告动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "公告库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "公告梳理",
          text: "专家梳理整合，真正读懂公告脉络",
        },
        {
          title: "公告大数据",
          text: "用数据深度理解公告结构和趋势",
        },
      ],
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    if (this.$store.state.notice.cplb) {
      this.activeName = this.$store.state.notice.cplb;
      this.switchVal(this.activeName)
    }
  },
  methods: {
    handleClick(tab, event) {
      this.switchVal(tab.name)
    },
    //筛选值
    switchVal(name){
      switch (name) {
        case "整车":
          this.activeValue = "1";
          break;
        case "底盘":
          this.activeValue = "4";
          break;
        case "摩托车":
          this.activeValue = "2";
          break;
        case "三轮汽车":
          this.activeValue = "3";
          break;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item.is-active {
  color: #0D57BC;
}
::v-deep .el-tabs__active-bar {
  background-color: #0D57BC;
}
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}
/deep/ {
  .g-form {
    .el-radio,
    .el-checkbox {
      margin-right: 8px;
    }
    .el-input {
      width: 200px;
    }
    .small-input {
      .el-input {
        width: 100%;
      }
    }
  }
}
</style>
