<template>
  <div style="background: #fff; padding: 20px 0">
    <div class="content">
      <div class="img-group">
        <img :src="tableData.hbzp" alt="" v-if="tableData.hbzp" />
        <img :src="tableData.xzzp1" alt="" v-if="tableData.xzzp1" />
        <img :src="tableData.xzzp2" alt="" v-if="tableData.xzzp2" />
        <img :src="tableData.xzzp3" alt="" v-if="tableData.xzzp3" />
        <img :src="tableData.ybzp" alt="" v-if="tableData.ybzp" />
      </div>
      <table class="table" border="1">
        <tr>
          <td width="200">产品号</td>
          <td>{{ tableData.cph }}</td>
          <td width="200">产品ID</td>
          <td>{{ tableData.cpId }}</td>
        </tr>
        <tr>
          <td>批次</td>
          <td>{{ tableData.pc }}</td>
          <td>发布日期</td>
          <td>{{ tableData.fbrq }}</td>
        </tr>
        <tr>
          <td>企业名称</td>
          <td>{{ tableData.qymc }}</td>
          <td>产品商标</td>
          <td>{{ tableData.ghcpsb }}</td>
        </tr>
        <tr>
          <td>生产地址</td>
          <td colspan="3">{{ tableData.scdz }}</td>
        </tr>
        <tr>
          <td>车辆型号</td>
          <td>{{ tableData.cpxh }}</td>
          <td>车辆名称</td>
          <td>{{ tableData.cpmc }}</td>
        </tr>
        <tr>
          <td>外形尺寸长</td>
          <td>{{ tableData.tsrq }}</td>
          <td>外形尺寸宽</td>
          <td>{{ tableData.kuan }}</td>
        </tr>
        <tr>
          <td>外形尺寸高</td>
          <td>{{ tableData.gao }}</td>
        </tr>
        <tr>
          <td>总质量</td>
          <td>{{ tableData.zzl }}</td>
          <td>整备质量</td>
          <td>{{ tableData.zbzl }}</td>
        </tr>

        <tr>
          <td>额定最大载质量</td>
          <td>{{ tableData.edzl }}</td>
          <td>最高车速</td>
          <td>{{ tableData.zgcs }}</td>
        </tr>

        <tr>
          <td>额定载客(含驾驶员)</td>
          <td>{{ tableData.edzk }}</td>
          <td>轴距</td>
          <td>{{ tableData.zhj }}</td>
        </tr>

        <tr>
          <td>轮胎规格</td>
          <td>{{ tableData.ltgg }}</td>
          <td>转向形式</td>
          <td>{{ tableData.zxxs }}</td>
        </tr>

        <tr>
          <td>前轮制动方式</td>
          <td>{{ tableData.zdq }}</td>
          <td>后轮制动方式</td>
          <td>{{ tableData.zdh }}</td>
        </tr>

        <tr>
          <td>前轮制动操作方式</td>
          <td>{{ tableData.zcq }}</td>
          <td>后轮制动操作方式</td>
          <td>{{ tableData.zch }}</td>
        </tr>

        <tr>
          <td>车辆识别代号(VIN)</td>
          <td colspan="3">{{ tableData.sbdh }}</td>
        </tr>

        <tr>
          <td>燃料种类</td>
          <td>{{ tableData.rlzl }}</td>
          <td>排放依据标准</td>
          <td>{{ tableData.ghyjbz }}</td>
        </tr>

        <tr>
          <td>发动机生产企业</td>
          <td>{{ tableData.mfqy }}</td>
          <td>发动机型号</td>
          <td>{{ tableData.mfdj }}</td>
        </tr>

        <tr>
          <td>排量</td>
          <td>{{ tableData.mfpl }}</td>
          <td>发动机功率</td>
          <td>{{ tableData.mfgl }}</td>
        </tr>
        <tr>
          <td>发动机商标</td>
          <td>{{ tableData.mfsb }}</td>
        </tr>
        <tr>
          <td>其它</td>
          <td colspan="3">{{ tableData.qt }}</td>
        </tr>
        <tr>
          <td>停产日期</td>
          <td>{{ tableData.tcrq }}</td>
          <td>停售日期</td>
          <td>{{ tableData.tsrq }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { ggYzk } from "@/api/announcement.js";
export default {
  data() {
    return {
      tableData: {},
    };
  },
  created() {
    this.ggYzk();
  },
  methods: {
    ggYzk() {
      let id = this.$route.params.id;
      ggYzk(id).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
          console.log(res.data);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$allcolor: #0D57BC;
.content {
  width: 720px;
  margin: 0 auto;
  background: #fff;
}
.img-group {
  border: 1px solid $allcolor;
  border-bottom: none;
  text-align: center;
  img {
    width: 220px;
    padding: 5px;
  }
}
.table {
  width: 100%;
  border-color: $allcolor;
  color: #000;
  tr {
    td {
      padding: 5px 10px;
    }
  }
}
</style>