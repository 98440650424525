<!--推荐车型目录-->
<template>
  <PageContainer>
    <!-- <div class="header">
      <div class="title">推荐车型目录</div>
    </div> -->
    <el-form
      ref="formRecom"
      :model="form"
      inline
      label-width="100px"
      class="n-data-content"
    >
      <!-- </el-col>
      </el-row> -->
      <div class="form-item-container el-form-item">
        <div>
          <el-form-item
            label="批次"
            class="no-position first-position"
            prop="pc"
          >
            <selectCheck
              v-model="form.pc"
              :datas="pcData"
              :props="props"
              :is-letters="false"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="车辆型号" prop="clxh">
            <el-input
              v-model="form.clxh"
              size="small"
              placeholder="请输入车辆型号"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="产品名称" prop="cpmc">

            <el-input
              v-model="form.cpmc"
              size="small"
              placeholder="请输入产品名称"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="生产企业" prop="qymc">

            <el-input
              v-model="form.qymc"
              size="small"
              placeholder="请输入生产企业"
              class="new-width"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="公告状态" prop="ggzt">
            <el-select
              v-model="form.ggzt"
              class="new-width"
              size="small"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in ggztData"
                :key="item"
                :label="item"
                :value="item"
              >{{ item }}</el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="text-center">
        <el-button type="primary" @click="jjzb()">查询</el-button>
        <el-button @click="rest()">清空</el-button>
      </div>
    </el-form>

    <card
      title=" "
      :btnflag="show && !showP"
      style="border:none;border-top:1px solid #f0f0f0;"
      @downloadData="Export"
      @downloadAllData="ExportAll"
    >
      <template slot="body">
        <div class="search-form">
          <div v-if="show && !showP">
            <el-table
              v-loading="loading"
              width="100%"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              stripe
              border
              :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
            >
              <el-table-column prop="pc" label="批次" min-width="60" />
              <el-table-column prop="clxh" label="车辆型号" min-width="120"/>
              <el-table-column prop="qymc" label="生产企业" min-width="200" />
              <el-table-column prop="cpmc" label="产品名称" min-width="120" />
              <el-table-column prop="rlzl" label="燃料种类" min-width="80" />
              <el-table-column prop="cnzzzl" label="电池类型" min-width="80" />
              <el-table-column prop="qddjlx" label="电机类型" min-width="80" />
              <el-table-column prop="ggzt" label="公告状态" min-width="80" />
              <!-- <el-table-column label="合计" prop="sumData" key="hj"></el-table-column> -->
            </el-table>
            <div class="text-right">
              <el-pagination
                :current-page="pageData.page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.count"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
          <div v-else style="position: relative; ">
            <img style="width:100%" src="@/assets/img/tjcxmlb.jpg" alt="" />
            <center>
              <div
                v-if="!show"
                style="position:relative;width:300px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              >
                <div style="padding-top: 20px">
                  <span>【提示】数据内容需要会员<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="showLogin2()"
                  >登录</span>后查看</span>
                </div>

                <div style="margin: 10px">
                  没有账号？可<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 1)"
                  >申请试用</span>
                </div>
              </div>
              <div
                v-else
                style="position:relative;width:320px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              >
                <div style="padding-top: 20px;padding-left: 22px;">
                  <span
                    style="font-size: 15px;"
                  >【提示】数据内容需要开通本栏目后查看</span>
                </div>
                <div style="padding: 10px 20px;text-align: center">
                  <span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 1)"
                  >在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span
                    style="color: #0D57BC;cursor: pointer"
                    @click="sqsy('/sqSy?flag=' + 2)"
                  >直接联系我们</span>
                </div>
              </div>
            </center>
          </div>
        </div>
      </template>
    </card>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";
import {
  getPCInfoListRecom,
  getRlInfoListRecom,
  getdjlxInfoListRecom,
  getddclxInfoListRecom,
  getggztInfoListRecom,
  gettableListRecom,
  ttXnyTjcxmlDynamicExport,
  ttXnyTjcxmlExportAll,
} from "@/api/data.js";
import { downloadFile } from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";
// import Vtitle from "../components/title.vue";
import { buryPoint } from "@/api/common";
import card from "./common/card.vue"; // 卡片组件

export default {
  name: "RecommendedVehicleCatalog",
  components: {
    // VnotLogin,
    selectCheck,
    // Vtitle,
    card,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: '数据动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '数据库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '数据梳理',
          text: '专家梳理整合，真正读懂数据脉络'
        },
        {
          title: '数据大数据',
          text: '用数据深度理解数据结构和趋势'
        }
      ],
      tableData: [],
      checked: true,
      rl: [],
      addClass: false,
      checkedCities: [],
      pageData: {
        page: 1,
        pageSize: 10,
        count: 0
      },
      loading: false,
      form: {
        cnzzzlShow: true,
        cnzzzl: [],
        ggztShow: true,
        ggzt: [],
        clxhShow: true,
        clxh: '',
        rlzlShow: true,
        rlzl: [],
        qddjlxShow: true,
        qddjlx: [],
        pcShow: true,
        pc: [],
        qymcShow: true,
        qymc: '',
        cpmcShow: true,
        cpmc: ''
      },
      pcData: [],
      rlzlData: [],
      djlxData: [],
      dclxData: [],
      ggztData: [],
      tableHead: [],
      excelLoading: false,
      excelAllLoading: false,
      props: {
        label: 'zwpc',
        key: 'zwpc',
        name: 'zwpc'
      },
      showP: false,
      showW: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '数据',
        userId: ''
      }
    };
  },
  mounted() {
    this.getPCInfoListRecom();
    this.createdMethod();

    if (getUserInfo()) {
      this.show = true;
      if (
        getUserInfo().roleKey == "paidrole" ||
        getUserInfo().roleKey == "trialrole"
      ) {
        const menuName = getUserInfo().menuName;
        if (!menuName.includes("数据")) {
          this.showP = true;
        } else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showW = true;
          }
        }
      }
    } else {
      this.show = false;
    }
  },
  methods: {
    // 申请试用
    sqsy(url) {
      const newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 显示登录弹窗
    showLogin2() {
      this.$Vlogin({ flag: true });
    },
    createdMethod() {
      //    获取燃料种类
      getRlInfoListRecom().then((res) => {
        if (res.ok) {
          this.rlzlData = res.data;
        }
      });
      //    获取电机类型
      getdjlxInfoListRecom().then((res) => {
        if (res.ok) {
          this.djlxData = res.data;
        }
      });
      //    获取电池类型
      getddclxInfoListRecom().then((res) => {
        if (res.ok) {
          this.dclxData = res.data;
        }
      });
      //    获取公告状态
      getggztInfoListRecom().then((res) => {
        if (res.ok) {
          this.ggztData = res.data;
        }
      });
    },
    getPCInfoListRecom() {
      // 获取批次
      getPCInfoListRecom().then((res) => {
        if (res.ok) {
          this.form.pc = [res.data[0].zwpc];
          this.pcData = res.data;

          if (getUserInfo()) {
            if (
              getUserInfo().roleKey == "paidrole" ||
              getUserInfo().roleKey == "trialrole"
            ) {
              const menuName = getUserInfo().menuName;
              if (!menuName.includes("数据")) {
                //
              } else {
                this.jjzb();
              }
            } else {
              this.jjzb();
            }
          }
        }
      });
    },

    // 查询
    gettableList() {
      const form = Object.assign({}, this.form, this.pageData)
      for (const key in form) {
        const isArr = Array.isArray(form[key])
        if (isArr) {
          form[key] = form[key].join(',')
        }
      }
      this.loading = true
      gettableListRecom(form).then((res) => {
        this.loading = false
        if (res.ok) {
          this.tableData = res.data.list
          this.pageData.count = res.data.count
        }
      })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    jjzb() {
      if (getUserInfo()) {
        logRecords(
          "数据",
          "推荐车型目录",
          "3",
          "tt_xny_tjcxml",
          "",
          "",
          "",
          "",
          "查询"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {

          const menuName = getUserInfo().menuName;
          if (!menuName.includes("数据")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.page = 1;
            this.gettableList();
          }
        } else {
          this.pageData.page = 1;
          this.gettableList();
        }
      } else {
        this.showLogin();
      }
    },
    // 重置
    rest() {
      this.$refs.formRecom.resetFields();
      this.getPCInfoListRecom();
    },
    changeFun(type, statu) {
      if (statu == "arr" && !this[type]) {
        this.$set(this.form, type, []);
      } else if (!this[type]) {
        this.$set(this.form, type, "");
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.gettableList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.gettableList();
    },
    // 导出
    Export() {
      if (getUserInfo()) {
        logRecords(
          "数据",
          "推荐车型目录",
          "3",
          "tt_xny_tjcxml",
          "",
          "",
          "",
          "",
          "导出"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {

          const menuName = getUserInfo().menuName;
          if (!menuName.includes("数据")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            } else {
              const _self = this;
              const form = this.form;
              for (const key in form) {
                const isArray = Array.isArray(form[key]);
                if (isArray) {
                  form[key] = form[key].join(",");
                }
              }
              this.excelLoading = true;
              ttXnyTjcxmlDynamicExport(form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function(e) {
                  try {
                    JSON.parse(e.target.result);
                    const msg = JSON.parse(e.target.result);
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "推荐车型目录数据.xls");
                  }
                });
              });
            }
          }
        } else {
          const _self = this;
          const form = this.form;
          for (const key in form) {
            const isArray = Array.isArray(form[key]);
            if (isArray) {
              form[key] = form[key].join(",");
            }
          }
          this.excelLoading = true;
          ttXnyTjcxmlDynamicExport(form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function(e) {
              try {
                JSON.parse(e.target.result);
                const msg = JSON.parse(e.target.result);
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "推荐车型目录数据.xls");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }
    },

    // 全部导出
    ExportAll() {
      if (getUserInfo()) {
        logRecords(
          "数据",
          "推荐车型目录",
          "3",
          "tt_xny_tjcxml",
          "",
          "",
          "",
          "",
          "导出全部"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {

          const menuName = getUserInfo().menuName;
          if (!menuName.includes("数据")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            } else {
              const _self = this;
              const form = this.form;
              for (const key in form) {
                const isArray = Array.isArray(form[key]);
                if (isArray) {
                  form[key] = form[key].join(",");
                }
              }
              this.excelAllLoading = true;
              ttXnyTjcxmlExportAll(form).then((res) => {
                this.excelAllLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function(e) {
                  try {
                    JSON.parse(e.target.result);
                    const msg = JSON.parse(e.target.result);
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "推荐车型目录全部数据.xls");
                  }
                });
              });
            }
          }
        } else {
          const _self = this;
          const form = this.form;
          for (const key in form) {
            const isArray = Array.isArray(form[key]);
            if (isArray) {
              form[key] = form[key].join(",");
            }
          }
          this.excelAllLoading = true;
          ttXnyTjcxmlExportAll(form).then((res) => {
            this.excelAllLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function(e) {
              try {
                JSON.parse(e.target.result);
                const msg = JSON.parse(e.target.result);
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "推荐车型目录全部数据.xls");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-layer-box {
  padding-right: 0;
  background-color: #fff;
}

.n-check-m {
  margin-bottom: 10px;
}
.first-position {
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
}
.el-form {
  .el-form-item {
    margin-bottom: 0px !important;
  }
}
.header {
  padding: 24px;

  .title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #0d57bc;
      margin-right: 8px;
    }
  }
}
.el-checkbox {
  margin-right: 16px;
  // margin-left: 16px;
}
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.n-data-content {
  padding: 6px 24px 0;
}
.text-center {
  margin: 14px 0 24px;
}
.form-item-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  // .el-form-item {
  //   margin-bottom: 0px !important;
  // }
}

.table-tip {
  width: 760px;
  margin: 0 auto;
}

.search-form {
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}
.more-icon {
  width: 15px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;
}

.el-message-box__message p {
  line-height: 14px;
}
</style>
