<template>
    <el-card class="common-table-card">
        <div slot="header" class="clearfix">
            <div class="left">
                <h3 class="title-tag-text" :style="{
                    fontFamily: 'MicrosoftYaHei-Bold',
                    fontSize: '18px',
                    fontWeight: 600,
                    display: 'inline-block'
                }">
                    {{ cardTitle }}
                </h3>
                <span v-if="$slots.cardSelect">
                    <slot name="cardSelect" />
                </span>
            </div>
            <span v-if="$slots.headPaginator">
                <slot name="headPaginator" />
            </span>
        </div>
        <div v-if="(sourceData.tableInfoStr && showStr) || $slots.tablePaginator" class="table-head">
            <div v-if="showStr" class="table-title">
                {{ sourceData.tableInfoStr }}
            </div>

            <div v-if="$slots.tablePaginator">
                <slot name="tablePaginator" />
            </div>
        </div>
        <div class="table-content">
            <el-table v-loading="isLoading" class="custom-table-style" :data="sourceData.tableData" border
                :cell-style="cellStyle" :header-cell-style="headerCellStyle"
                :span-method="mergeAdjacentRowsByDeliveryStatus" :style="{ width: '100%', borderRadius: '4px' }">
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
                <el-table-column v-for="head in sourceData.tableHead" :align="'center'" :key="head.prop" :prop="head.prop"
                    :label="head.label" :show-overflow-tooltip="(progressBar && head.prop !== progressBar) ||
                        !notTooltipList.includes(head.prop)
                        " >
                    <template slot-scope="scope">
                        <!-- table设置进度条专用 -->
                        <div class="progressBar-content" v-if="progressBar && head.prop === progressBar">
                            <div class="progressBar-num">
                                {{ scope.row[`${progressBar}`] }}
                            </div>
                            <div class="progress-bar-container">
                                <!-- 使用计算出的比例来设置宽度 -->
                                <div class="progress-bar" :style="{
                                    width: calculateWidth(scope.row[`${progressBar}`]) + '%'
                                }"></div>
                            </div>
                        </div>
                        <div v-else-if="foldList.includes(head.prop) &&
                            scope.row[`${head.prop}`] &&
                            scope.row[`${head.prop}`].length > 25
                            " class="content">
                            <div v-if="isCollapsed || toggleIndex !== scope.$index">
                                {{ scope.row[`${head.prop}`].slice(0, 25) + "..." }}
                                <!-- 显示文本的前100个字符 -->
                                <el-button type="text" @click="toggle(scope)"><span :style="{
                                    fontSize: '12px',
                                    color: '#3f76ea',
                                    marginRight: '10px'
                                }">展开</span>
                                    <i :style="{ color: '#a9b9dc', fontSize: '12px' }"
                                        class="el-icon-arrow-down"></i></el-button>
                            </div>
                            <div v-else>
                                {{ scope.row[`${head.prop}`] }}
                                <el-button type="text" @click="toggle(scope)">
                                    <span :style="{
                                        fontSize: '12px',
                                        color: '#3f76ea',
                                        marginRight: '10px'
                                    }">
                                        收起
                                    </span>
                                    <i :style="{ color: '#a9b9dc', fontSize: '12px' }" class="el-icon-arrow-up">
                                    </i></el-button>
                            </div>
                        </div>
                        <span class="hover-text" v-else>
                            {{ scope.row[`${head.prop}`] }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </el-card>
</template>
  
<script>
export default {
    name: "TableCard",
    props: {
        cardTitle: {
            type: String,
            default: () => ""
        },
        progressBar: {
            type: String,
            default: () => ""
        },
        compared: {
            type: String,
            default: () => ""
        },
        propertyName: {
            type: Array,
            default: () => []
        },
        sourceData: {
            type: Object,
            default: () => ({ tableData: [], tableHead: [], tableInfoStr: "" })
        },
        maxCapacity: {
            type: Number,
            default: () => 0
        },
        notTooltipList: {
            type: Array,
            default: () => []
        },
        foldList: {
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        showStr: {
            type: Boolean,
            default: () => true
        },
        columnWidth: {
            type: Function,
            default: () => {
                return "30%";
            }
        }
    },
    data() {
        return {
            rowLength: 0,
            toggleIndex: null,
            spanCache: null,
            isCollapsed: true // 控制文本是展开还是收起
        };
    },

    computed: {
        maxNumInSalesInfoList() {
            return this.calculateMaxNum(this.dataSource.salesInfoList);
        },
        maxNumInSearchProductionResult() {
            return this.calculateMaxNum(this.dataSource.searchProductionResult);
        }
    },

    methods: {
        resetSpanCache() {
            this.spanCache = {}; // 重置缓存
            // 如果需要，可以在这里添加其他逻辑来处理数据更新
        },
        mergeAdjacentRowsByDeliveryStatus({ row, column, rowIndex, columnIndex }) {
            // 仅对 "选中" 列应用合并逻辑
            if (!this.propertyName.includes(column.property)) {
                return { rowspan: 1, colspan: 1 };
            }

            // 使用缓存避免重复计算
            if (this.spanCache && this.spanCache[rowIndex] !== undefined) {
                return this.spanCache[rowIndex];
            }

            let rowspan = 1;
            let colspan = 1;

            // 从当前行开始向下查找相邻的具有相同 "选中" 值的行
            for (let i = rowIndex + 1; i < this.sourceData.tableData.length; i++) {
                if (
                    row[column.property] === this.sourceData.tableData[i][column.property]
                ) {
                    rowspan++;
                } else {
                    break; // 遇到不同的 "选中" 值，停止查找
                }
            }

            // 更新缓存
            if (!this.spanCache) {
                this.spanCache = {};
            }
            for (let i = rowIndex; i < rowIndex + rowspan; i++) {
                this.spanCache[i] =
                    i === rowIndex ? { rowspan, colspan } : { rowspan: 0, colspan: 0 };
            }

            return { rowspan, colspan };
        },
        toggle(item) {
            this.isCollapsed = !this.isCollapsed;
            this.toggleIndex = item.$index;
        },
        cellStyle({ column, columnIndex }) {
            let style = { height: "34px", padding: "0" };
            if (columnIndex === this.rowLength) {
                style["border-right"] = "none";
            }
            return style;
        },
        headerCellStyle({ row, columnIndex }) {
            this.rowLength = row.length - 1;
            const baseStyle = {
                border: "none",
                padding: 0,
                height: "34px",
                "background-color": "#f1f5ff",
                "font-family": "MicrosoftYaHei",
                "font-size": "12px",
                color: "#3f76ea"
            };

            const comparedStyle = {
                color: "#222",
                border: "1px solid #fff",
                "border-radius": "4px"
            };

            const backgroundColors = {
                1: "#fcefe9",
                2: "#f7edff",
                3: "#effaf0",
                default: "#f1f5ff"
            };

            const style = this.compared
                ? { ...baseStyle, ...comparedStyle }
                : { ...baseStyle };

            if (this.compared) {
                style["background-color"] =
                    backgroundColors[columnIndex] || backgroundColors.default;
                if (columnIndex === 0) {
                    style["color"] = "#3f76ea";
                }
            }

            return style;
        },
        calculateWidth(capacity) {
            if (!capacity) return 0;
            if (this.maxCapacity > 0) {
                // 使用线性比例计算宽度
                return (capacity / this.maxCapacity) * 100;
            }
            return 0;
        }
    },
    watch: {
        "sourceData.tableData": {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.resetSpanCache();
                }
            }
        }
    }
};
</script>
<style lang="scss">
.common-table-card {
    .el-table__empty-block {
        width: auto !important;
    }

    .el-select {
        margin-left: 10px;
    }

    .el-table {
        border: none;

        &::after {
            display: none;
        }

        &::before {
            display: none;
        }

        .el-table__header,
        .el-table__body {
            width: 100% !important;
        }

        .cell {
            width: auto !important;
        }
    }

    .el-card__header {
        border-bottom: none;
        padding-bottom: 0;
    }

    .content .el-button {
        padding: 0;
    }
}
</style>
<style lang="scss" scoped>
.clearfix {
    display: flex;
    justify-content: space-between;

    &::after,
    &::before {
        display: none;
    }
}

.left {
    display: flex;
    align-items: center;
}

.table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;

    .table-title {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666;
    }
}

.progressBar-content {
    display: flex;
    align-items: center;

    .progressBar-num {
        flex: none;
        width: 70px;
    }
}

.progress-bar-container {
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    display: inline-block;
}

.progress-bar {
    height: 10px;
    background-color: #409eff;
    transition: width 0.6s ease;
    background-image: linear-gradient(90deg,
            #76b3ff 0%,
            rgba(0, 105, 234, 1) 100%),
        linear-gradient(#3f76ea, #3f76ea);
    background-blend-mode: normal, normal;
    border-radius: 5px;
}

.hover-text:hover {
    color: #3f76ea;
}

::v-deep .el-card__body {
    padding-bottom: 0px;
}

::v-deep .el-card {
    border-bottom: 0;
    border-radius: 0;
}
</style>
  