<template>
 <!-- 国际化栏目所有子页面的父级布局组件 -->
 <div>
 <router-view></router-view>
 </div>
</template>

<script>
export default {
  name: '',
}
</script>

<style lang="scss" scoped>
</style>