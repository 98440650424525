<template>
  <div style="overflow: hidden" class="list-filter">
    <div style="float: left" flex="c">
      <el-checkbox-group v-model="newModel" size="small" @change="handleChange">
        <el-checkbox
          :label="item.dictValue"
          border
          v-for="item in list"
          :key="item.dictValue"
          >{{ item.dictLabel }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div style="float: right">
      <el-input placeholder="搜索名称" size="small" v-model="newTitle"  @keyup.enter.native="search">
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="search"
          style="cursor: pointer"
        ></i>
      </el-input>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    model: {
      type: Array,
      default() {
        return [];
      },
    },
    title: "",
  },
  data() {
    return {
      newTitle: this.title,
      newModel: this.model,
    };
  },
  methods: {
    search() {
      this.$emit("search", this.newTitle);
    },
    handleChange(e) {
      this.$emit("handleChange", e);
    },
  },
};
</script>
 <style lang="scss" scoped>
.list-filter {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;
  ::v-deep .el-checkbox__input {
    display: none;
  }
  ::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }
  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }
  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
