<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-17 16:57:39
-->
<template>
  <el-card
    class="box-card"
    :style="
      `padding-bottom: 24px;border:${noBorder ? 'none' : '1px solid #f0f0f0'}`
    "
  >
    <div slot="header" class="clearfix">
      <span class="title"
        ><span v-if="cionShow" class="icon"></span>{{ title }}</span
      >
      <span class="right-btn-box">
        <slot name="rightBtnBox"></slot>
        <i
          v-if="exportData"
          class="btn-item el-icon-download"
          @click="exportXls"
          >下载数据</i
        >
        <i
          v-if="exportPng"
          class="btn-item el-icon-picture-outline"
          @click="exportSvg"
          >导出图片</i
        >
      </span>
    </div>
    <div class="btn-box" v-if="btnList.length > 0">
      <el-button
        size="mini"
        type="primary"
        :class="`${btnItem.isClick ? 'isClick' : ''}`"
        v-for="(btnItem, index) in btnList"
        :key="index"
        @click="btnClick(btnItem)"
      >
        {{ btnItem.title }}
      </el-button>
    </div>
    <slot name="body"></slot>
  </el-card>
</template>
<script>
export default {
  name: "card",
  data() {
    return {
      btnList: [],
    };
  },
  props: {
    // 卡片名称
    title: {
      type: String,
      default: "请传入参数title",
    },
    // 卡片边框样式
    noBorder: {
      type: Boolean,
      default: false,
    },
    // 下载图片按钮开关
    exportPng: {
      type: Boolean,
      default: false,
    },
    // 导出按钮开关
    exportData: {
      type: Boolean,
      default: false,
    },
    // 按钮id
    btnId: {
      type: String,
      default: "0",
    },
    // 图标显示
    cionShow: {
      type: Boolean,
      default: false,
    },
    // 按钮list
    titlebtn: {
      type: Array,
      default: () => [],
    },
    exportId: {
      type: String,
      default: null,
    },
  },
  created() {
    this.btnList = JSON.parse(JSON.stringify(this.titlebtn));
  },
  methods: {
    /**
     * @description: 按钮点击事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 10:07:17
     * @LastEditTime: Do not edit
     */
    btnClick(data) {
      this.btnList = this.btnList.map((item) => {
        return {
          ...item,
          ...{ isClick: data.id === item.id ? 1 : 0 },
        };
      });
      this.$emit("cardBtnchange", { type: this.btnId, data: data });
    },
    /**
     * @description: 下载图片按钮
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:25:40
     * @LastEditTime: Do not edit
     */

    exportSvg() {
      this.$emit("exportSvg", { type: this.btnId, name: this.title });
    },
    /**
     * @description: 数据导出
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:08:45
     * @LastEditTime: Do not edit
     */

    exportXls() {
      if (!this.exportId) {
        return;
      }
      this.$emit("exportXls", { exportId: this.exportId, name: this.title });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 4px 4px;
  .icon {
    display: inline-block;
    height: 9px;
    width: 9px;
    background-color: #0d57bc;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .title-info {
    margin-left: 12px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #828282;
  }
  /deep/.el-card__header {
    padding: 20px 15px;
    border: none;
  }
  /deep/.el-card__body {
    padding: 0 24px;
    border: none;
  }
  /deep/.clearfix {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
  }
  /deep/.right-btn-box {
    float: right;
    height: 24px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
    .btn-item {
      margin: 0 14px;
      cursor: pointer;
    }
    .el-icon-download:before,
    .el-icon-picture-outline:before {
      margin-right: 8px;
      font-size: 16px;
    }
  }
  .btn-box {
    margin-bottom: 8px;
    /deep/.el-button {
      padding: 4px 12px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      background-color: #fff;
      border: 1px solid #e5e6eb;
      color: #000000;
    }
    .isClick {
      color: #ffffff;
      background-color: #0d57bc;
    }
  }
}
@media screen and (max-width: 1366px) {
  .box-card {
    /deep/.el-card__header {
      padding: 20px 9px !important;
      border: none;
    }
  }
}
</style>
