import { render, staticRenderFns } from "./compCapacity.vue?vue&type=template&id=05061858&scoped=true"
import script from "./compCapacity.vue?vue&type=script&lang=js"
export * from "./compCapacity.vue?vue&type=script&lang=js"
import style0 from "./compCapacity.vue?vue&type=style&index=0&id=05061858&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05061858",
  null
  
)

export default component.exports