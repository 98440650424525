<template>
  <div id="Histogram" ref="Histogram" class="borderStyle"></div>
</template>

<script>
import { Column } from '@antv/g2plot'
let columnPlot
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    Height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radarPlot: null,
    }
  },
  // 监听
  watch: {
    value: {
      handler(newVal, oldVal) {
        console.log(newVal)
        // 方式一 有新数据从新渲染图表
        columnPlot.changeData(newVal)
        // 方式二 先销毁图表 在创建图表
        // chartChange.destroy()
        // this.initRadarPlot()
      },
      deep: true, //深度监听
      // immediate: true,
    },
  },
  mounted() {
    this.initRadarPlot()
  },
  methods: {
    initRadarPlot() {
      // 使用ref的方式组件可以多次使用
      // const chartChange = new Column(this.$refs.Histogram, {
      columnPlot = new Column(this.$refs.Histogram, {
              title: {
                visible: true,
                text: '基础柱状图',
              },
              forceFit: true,
              padding: 'auto',
              data: this.value,
              xField: 'time',
              yField: 'value',
              label:{
                visible: true
              },
              yAxis: {
                grid: {
                  line: null
                }
              },
              meta: {
                time: {
                  alias: '类别',
                },
                value: {
                  alias: '数量',
                },
              },
            });

            columnPlot.render();
    },
    // 图表高度
    getHeight() {
      let height = parseInt(this.Height)
      return { height: height + 'px' }
    },
  },
}
</script>

<style lang="scss" scoped>
.borderStyle{
  border: solid 5px #92beff;
  margin: 10px;
  padding: 10px;
}
</style>