<template>
  <PageContainer>
    <div v-loading="loading" class="full-box">
      <div class="full-top">
        <div class="full-search-top">
          <div class="full-search-top-l">
            <div class="full-search-l-inner">
              范围全部：{{ this.selectName }}
            </div>
            <!--弹窗  S-->
            <div class="sx-box">
              <div class="sx-box-t">
                <div class="check-list-box">
                  <div class="check-list-l">
                    <el-checkbox v-model="allCheck" @change="onAllCheck">全部</el-checkbox>
                  </div>
                </div>
                <div v-for="(item,index) in menuData" :key="item.value" class="check-list-box">
                  <div class="check-list-l">
                    <el-checkbox v-model="item.check" @change="onchange(item,index,$event)">
                      {{ item.label }}</el-checkbox>
                  </div>
                  <div v-if="item.children && item.children.length>0" class="check-list-r">
                    <template v-for="(citem,cindex) in item.children">
                      <el-checkbox
                        v-if="!citem.hidden"
                        :key="citem.value"
                        v-model="citem.check"
                        :class="item.check?'acs':''"
                        @change="onchangeSon(citem,index,cindex,$event)"
                      >{{ citem.label }}</el-checkbox>
                    </template>
                  </div>
                </div>
              </div>
              <div class="check-list-box-b" />
              <div class="sx-box-b">
                <div class="text-center" style="margin-top:32px">
                  <el-button class="reset-button" @click="reset">重置</el-button>
                  <el-button class="search-button" @click="search">查询</el-button>
                </div>
              </div>
            </div>
            <!--弹窗  E-->
          </div>
          <div class="full-search-line" />
          <div class="full-search-top-r">
            <input v-model="keywords" placeholder="输入搜索内容" class="search-input">
            <button class="search-btn" @click="search"> <img
              src="@/assets/img/Group 509.png"
              alt=""
              class="search-btn-icon"
            > 搜索</button>
          </div>
        </div>
        <div class="full-search-h-line" />
        <div class="full-search-bottom">
          <div class="full-search-bottom-l" flex="c">
            检索方式：
            <el-radio v-model="title" label="0" @change="selectChange"> 标题</el-radio>
            <el-radio v-model="title" label="1" @change="selectChange"> 全文</el-radio>
          </div>
          <div class="full-search-bottom-c" flex="c">
            日期筛选：
            <div class="time-box">
              <el-date-picker
                v-model="allDate"
                unlink-panels
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @input="selectChange"
              />
            </div>
          </div>
          <div class="full-search-bottom-r">
            排序方式：
            <el-select v-model="sortFile" class="search-select" @change="selectChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
      </div>
      <div class="full-bottom">
        <div class="full-bottom-l">
          <!--                <h1 class="title">全部搜索结果({{this.numAll}})</h1>-->
          <div class="full-tree">
            <ul class="full-tree-list">
              <li>
                <h1 :class="{'ac':typeName == '全部'}">全部搜索结果({{ this.numAll }})</h1>

              </li>
            </ul>
            <ul v-for="item in aside" :key="item.typeName" class="full-tree-list">
              <li>
                <h1 :class="{'ac':typeName == item.typeName}" @click="onclick(item, '')"> <img
                  :src="typeName == item.typeName? defaultIcon : defaultIconH"
                  alt=""
                  width="11"
                  height="14"
                > {{ item.typeName }}({{ item.num }})</h1>
                <ul class="full-tree-list" :class="{'full-tree-list-h':pTypeName != item.typeName}">
                  <li v-for="citem in item.children" :key="citem.typeName">
                    <h1 :class="{'ac':typeName == citem.typeName}" @click="onclick(citem, item)">
                      {{ citem.typeName }}({{ citem.num }})</h1>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- <ul class="full-tree-list">
                          <li>
                              <h1> <img src="@/assets/img/Vector12-h.png" alt="" width="11" height="14">标准(321)</h1>
                          </li>
                      </ul>
                      <ul class="full-tree-list">
                          <li>
                              <h1> <img src="@/assets/img/Vector12-h.png" alt="" width="11" height="14">公告(97)</h1>
                          </li>
                      </ul> -->
          </div>
        </div>
        <div class="full-bottom-r">
          <ul class="full-link-box">
            <li
              v-for="item in list"
              :key="item.id"
              class="full-link"
              @click="toHref('/FullTextRetrieval/fullTextRetrievalContent?id='+item.id,item)"
            >

              <h1 class="full-link-title" flex="sb">
                <span class="text-overflow" style="flex:1;display:block" v-html="item.title" />
                <span style="font-size:14px;color:#666;font-weight: normal;">{{ item.publishTime }}</span>
              </h1>
              <p v-if="titleTag == '0'" class="full-link-inner" v-html="item.summary" />
              <p v-else class="full-link-inner text-overflow-2" v-html="item.content" />
            </li>
          </ul>
          <el-pagination
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import {
  searchData,
  statistics,
  searchtypes
} from '@/api/FullTextRetrieval.js'
import {
  fmtDate
} from '@/utils/date'
import { getUserInfo, logRecords } from '@/utils/auth'
export default {
  data() {
    return {
      checked1: true,
      checked: false,
      newCheck: {},
      allDate: '',
      value: '选项1',
      defaultIcon: require('@/assets/img/Vector12.png'),
      defaultIconH: require('@/assets/img/Vector12-h.png'),
      options: [{
        value: '_score',
        label: '按相关度'
      },
      {
        value: 'publishTime',
        label: '按时间'
      }
      ],
      allCheck: true,
      menuData: [],
      typeParams: [],
      categoryTys: [],
      keywords: '',
      checkList: [],
      list: [],
      aside: [],
      numAll: 0,
      title: '0',
      titleTag: '0',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      selectName: '全部栏目',
      typeName: '全部',
      pTypeName: '',
      sortFile: '_score',
      loading: false
    }
  },
  created() {
    // var timestamp = +new Date() + 24*60*60*1000
    this.allDate = [
      this.getPastHalfYear(),
      fmtDate('YYYY-mm-dd', new Date())
    ]
  },
  mounted() {
    this.$nextTick(() => {
      this.keywords = this.$route.query.keywords
      this.search()
      this.searchtypes()
    })
  },
 watch: {
    $route: { //这里是要监听的变量名
      handler(newValue, oldValue) {
        this.numAll = 0
        this.aside = []
        this.keywords = this.$route.query.keywords
        this.search()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('全文检索', item.categoryTy, 1, '', item.id, '', item.title, '', '');
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          if (getUserInfo().menuName.includes(item.categoryTy) || item.categoryTy =='行业资讯') {
            window.open(newUrl.href, '_blank')
          } else {
            this.sqsyLogin()
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    search() {
      if (this.keywords == '') {
        this.$notify({
          title: '提示',
          message: '请输入搜索内容',
          type: 'warning',
          duration: 1800,
          offset: 23
        });
        return false
      }
      if (getUserInfo()) {
        logRecords('全文检索', '全文检索', '3', '', '', '', '', this.keywords, '查询')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        }
      }
      this.pageNum = 1
      this.typeParams = this.getChildren(this.checkList)
      this.categoryTys = this.getParent(this.checkList)
      this.searchData()
      this.statistics()
    },
    selectChange() {
      this.pageNum = 1
      this.categoryTys = this.getParent(this.checkList)
      this.typeParams = this.getChildren(this.checkList)
      this.searchData()
      this.statistics()
    },
    reset() {
      this.pageNum = 1
      this.checkList = []
      this.menuData.forEach((item) => {
        item.check = false
        item.children.forEach((citem) => {
          citem.check = false
        })
      })
      this.allCheck = true
      this.selectName = '全部栏目'
      this.searchData()
      this.statistics()
    },
    // 选择全部
    onAllCheck(e) {
      if (e) {
        this.checkList = []
        this.menuData.forEach((item) => {
          item.check = false
          item.children.forEach((citem) => {
            citem.check = false
          })
        })
        this.selectName = '全部栏目'
      }
    },
    onchange(item, index, e) {
      this.menuData[index].check = e
      if (e) {
        this.checkList.push(JSON.parse(JSON.stringify(item)))
      } else {
        for (let i = 0; i < this.checkList.length; i++) {
          if (item.label == this.checkList[i].label) {
            this.checkList.splice(i, 1)
          }
        }
        this.menuData[index].children.forEach((el) => {
          el.check = false
        })
      }

      if (this.checkList.length > 0) {
        this.allCheck = false
      } else {
        this.allCheck = true
      }

      let num = 0
      this.checkList.forEach((el) => {
        num += el.children.length
      })
      this.selectName = '已选(' + num + ')'
    },
    onchangeSon(item, pindex, index, e) {
      this.menuData[pindex].children[index].check = e
      let indexs = 0
      const newMenuData = JSON.parse(JSON.stringify(this.menuData))
      const strs = newMenuData[pindex].children.filter(
        (item) => item.check
      )
      const newObj = newMenuData[pindex]
      newMenuData[pindex].children = strs
      if (this.checkList.length > 0) {
        for (let j = 0; j < this.checkList.length; j++) {
          if (
            this.menuData[pindex].label == this.checkList[j].label
          ) {
            this.checkList.splice(j, 1)
          }
        }
      }
      this.checkList.push(newObj)
      if (e) {
        this.menuData[pindex].check = e
      } else {
        for (let i = 0; i < this.checkList.length; i++) {
          if (
            this.menuData[pindex].label == this.checkList[i].label
          ) {
            this.checkList.splice(i, 1)
          }
        }

        this.menuData[pindex].children.forEach((el) => {
          if (el.check) {
            indexs++
          }
        })
        if (indexs == 0) {
          this.menuData[pindex].check = e
        }
      }

      if (this.checkList.length > 0) {
        this.allCheck = false
      } else {
        this.allCheck = true
      }
      let num = 0
      this.checkList.forEach((el) => {
        num += el.children.length
      })
      this.selectName = '已选(' + num + ')'
    },
    getChildren(obj) {
      const str = []
      obj.forEach((item) => {
        item.children.forEach((citem) => {
          str.push(citem.label)
        })
      })
      return str
    },
    getParent(obj) {
      const str = []
      obj.forEach((item) => {
        str.push(item.label)
      })
      return str
    },
    searchData() {
      this.loading = true
      searchData(this.getForm()).then((res) => {
        if (res.ok) {
          this.titleTag = this.title
          this.list = res.data.data
          this.total = res.data.total
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    statistics() {
      statistics(this.getForm()).then((res) => {
        if (res.ok && res.data.list.length > 0) {
          const aside = res.data.list

          const target = aside[0]
          if (target.typeName === '行业资讯') {
            delete target.children
          }

          this.aside = aside
          this.numAll = res.data.total
          // if (res.data.list.length > 0) {
          //     this.pTypeName = this.typeName =
          //         res.data.list[0].typeName;
          // }
        }
      })
    },
    getForm() {
      const publishTimeEnd = fmtDate('YYYY-mm-dd', +new Date(this.allDate[1]) + 24 * 60 * 60 * 1000)
      const form = {
        all: this.allCheck ? 0 : 1,
        keywords: this.keywords,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        publishTimeBegin: this.allDate[0],
        publishTimeEnd,
        title: this.title,
        typeParams: this.typeParams,
        categoryTys: this.categoryTys,
        sortFile: this.sortFile

      }
      // let form = {
      //     all: this.allCheck ? 0 : 1,
      //     keywords: "是",
      //     pageNum: 1,
      //     pageSize: 10,
      //     publishTimeBegin: "2021-01-01",
      //     publishTimeEnd: "2021-12-01",
      //     title: 0,
      //     typeParams: ["行业咨询"],
      // };
      return form
    },
    // 获取半年前时间
    getPastHalfYear() {
      // 先获取当前时间
      var curDate = new Date().getTime()
      // 将半年的时间单位换算成毫秒
      var halfYear = 365 * 24 * 3600 * 1000
      var pastResult = curDate - halfYear // 半年前的时间（毫秒单位）

      // 日期函数，定义起点为半年前
      var pastDate = new Date(pastResult)
      var pastYear = pastDate.getFullYear()
      var pastMonth = pastDate.getMonth() + 1
      var pastDay = pastDate.getDate()
      if (pastMonth < 10) {
        pastMonth = '0' + pastMonth
      }
      if (pastDay < 10) {
        pastDay = '0' + pastDay
      }

      return pastYear + '-' + pastMonth + '-' + pastDay
    },
    // 选择
    onclick(item, pNode) {
      this.typeName = item.typeName
      this.categoryTys = pNode ? [pNode.typeName] : [item.typeName]
      if (item.children) {
        this.pTypeName = item.typeName
        this.typeParams = item.children.map((el) => el.typeName)
      } else {
        this.typeParams = [item.typeName]
      }
      this.pageNum = 1
      this.allCheck = false
      this.searchData()
    },
    handleSizeChange(e) {
      this.pageSize = e
      this.searchData()
    },
    handleCurrentChange(e) {
      this.pageNum = e
      this.searchData()
    },
    searchtypes() {
      searchtypes().then((res) => {
        if (res.ok) {
          const menuData = res.data

          const target = menuData[0]
          if (target.label === '行业资讯') {
            target.children?.forEach((item) => {
              item.hidden = true
            })
          }

          this.menuData = menuData
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/FullTextRetrieval/fullTextRetrievalContent') {
      // 详情页路由
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
    .full-box {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;

        .full-top {
            position: relative;
            align-items: center;
            width: 100%;
            background: #fff;
            margin-top: 16px;
            padding: 0 24px 20px;
            border-radius: 2px;
        }

        .full-search-top {
            display: flex;
            align-items: center;
            height: 64px;
            color: #000;

            .full-search-top-l {
                display: flex;
                align-items: center;
                height: 100%;

                .full-search-l-inner {
                    position: relative;
                    font-size: 14px;
                    line-height: 15px;
                    cursor: pointer;
                    padding-right: 19px;

                    &::after {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -3px;
                        content: "";
                        width: 0;
                        height: 0;
                        border-width: 4px;
                        border-color: #000 transparent transparent;
                        border-style: solid;
                    }
                }
            }

            .full-search-top-r {
                flex: 1;
                display: flex;
                align-items: center;

                .search-input {
                    flex: 1;
                    border: none;
                    font-size: 16px;
                    padding-right: 15px;

                    &:focus {
                        outline: none;
                    }
                }

                .search-btn {
                    width: 112px;
                    height: 32px;
                    background: #0d57bc;
                    border-radius: 2px;
                    border: none;
                    font-size: 14px;
                    color: #fff;
                    font-weight: bold;
                    cursor: pointer;
                }

                .search-btn-icon {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .full-search-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .full-bottom {
            flex: 1;
            display: flex;
            margin-top: 16px;

            .full-bottom-l,
            .full-bottom-r {
                height: 100%;
                background: #fff;
                overflow: hidden;
            }

            .full-bottom-l {
                width: 165px;
                margin-right: 12px;

                .title {
                    font-size: 14px;
                    line-height: 14px;
                    color: #000;
                    margin: 13px 16px 21px;
                    margin-bottom: 21px;
                    font-weight: normal;
                }
            }

            .full-bottom-r {
                flex: 1;
                padding: 32px;
            }
        }
    }

    .full-search-line {
        width: 1px;
        height: 32px;
        background: #c4c4c4;
        margin-left: 29px;
        margin-right: 38px;
    }

    .full-search-h-line {
        width: 100%;
        height: 1px;
        background: #f2f2f2;
        margin: 0 0 20px;
    }

    .full-search-bottom-l {
        .el-checkbox {
            display: flex;
            align-items: center;
            margin-right: 4px;
        }

        /deep/ .el-checkbox__label {
            padding-left: 4px;
        }
    }

    .check-list-r {
        .el-checkbox {
            width: 94px;
            margin-right: 16px;
        }
    }

    .time-box {
        width: 200px;

        /deep/ .el-range-editor.el-input__inner {
            display: flex;
        }

        /deep/ .el-input__icon {
            display: none;
        }

        /deep/ .el-date-editor .el-range-separator {
            padding: initial;
            width: 8%;
            line-height: 25px;
        }

        /deep/ .el-date-editor--daterange.el-input__inner {
            width: 100%;
        }

        /deep/ .el-range-input {
            flex: 1;
        }

        /deep/ .el-input__inner {
            height: 30px;
            line-height: 30px;
        }
    }

    .search-select {
        /deep/ .el-input__inner {
            height: 30px;
            line-height: 30px;
        }

        /deep/ .el-input__suffix {
            display: none;
        }
    }

    .full-tree {
        .full-tree-list {
            ul{
              cursor: pointer;
            }
            li {
                cursor: pointer;

                h1 {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    line-height: 22px;
                    color: #333;
                    padding: 0 19px;
                    transition: all 0.2s;
                    overflow: hidden;
                    cursor: pointer;

                    img {
                        margin-right: 15px;
                    }

                    &.ac {
                        background: rgba(13, 87, 188, 0.08);
                        color: #1d6cd6;
                    }
                }
            }

            .full-tree-list {
                li {
                    h1 {
                        padding-left: 44px;
                    }
                }
            }

            &.full-tree-list-h {
                li {
                    h1 {
                        height: 0;
                    }
                }
            }
        }
    }

    .sx-box {
        position: absolute;
        top: 64px;
        left: 0;
        z-index: 10;
        display: none;
        flex-direction: column;
        min-width: 704px;
        background: #fff;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 2px;

        .sx-box-t {
            // min-height: 327px;
        }

        .sx-box-b {
            height: 96px;
        }
    }

    .check-list-box {
        display: flex;
        width: 100%;
        height: 41px;
        line-height: 40px;

        .check-list-l {
            width: 172px;
            background: #f7f7f7;
            border-bottom: 1px solid #fff;
            padding-left: 16px;
        }

        .check-list-r {
            flex: 1;
            margin: 0 20px;
            border-bottom: 1px solid #f2f2f2;
        }

        &:last-child {
            .check-list-r {
                border-bottom: none;
            }
        }
    }

    .check-list-box-b {
        width: 172px;
        height: 16px;
        background: #f7f7f7;
    }

    .full-search-top .full-search-top-l:hover .sx-box {
        display: flex;
    }

    .acs {
        /deep/ .el-checkbox__inner {
            background: #bdbdbd;
            border-color: #bdbdbd;
        }

        /deep/ .el-checkbox__inner {
            &::after {
                transform: rotate(45deg) scaleY(1);
            }
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: #0d57bc;
            background: #0d57bc;
        }
    }

    .full-link-box {
        .full-link {
            display: block;
            margin-bottom: 30px;
            cursor: pointer;

            .full-link-title {
                font-weight: bold;
                font-size: 18px;
                line-height: 18px;
                color: #000000;
                margin-bottom: 10px;
            }

            .full-link-inner {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #828282;
            }
        }
    }
</style>
