<template>
  <div
    class="enterpriseDetail"
    id="basicInfo"
    v-if="isShowBasicInfo"
    data-name="基本信息"
  >
    <TableCard
      compared="compared"
      :isLoading="isLoading"
      cardTitle="基本信息"
      :sourceData="dataSource"
    />
  </div>
</template>

<script>
import { getBasicInfo } from "@/api/enterpriseAnalysis.js";
import TableCard from "@/components/tableCard.vue";
export default {
  name: "BasicInfo",
  components: {
    TableCard
  },
  data() {
    return {
      debouncedGetBasicInfo: null,
      isShowBasicInfo: false,
      isLoading: false,
      params: {
        enterpriseIdList: JSON.parse(this.$route.query.selectedEnterprise)
      },
      dataSource: {}
    };
  },
  created() {
    this.debouncedGetBasicInfo = this.debounce(this.getBasicInfo, 100);
    this.debouncedGetBasicInfo(this.params, "isShowBasicInfo");
  },
  methods: {
    debounce(fn, delay) {
      let timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
    getBasicInfo(params, isShowBasicInfo) {
      this.isLoading = true;
      getBasicInfo(params).then(res => {
        if (res.ok) {
          if (isShowBasicInfo) {
            this.isShowBasicInfo =
              res.data.tableData.length &&
              (this.$route.query.checkedList.includes("基本信息") ||
                this.$route.query.checkedList.includes("全部"))
                ? true
                : false;
          }
          this.dataSource = res.data;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
    "$route.query.selectedEnterprise"(newId) {
      this.params.enterpriseIdList = JSON.parse(newId);
      this.debouncedGetBasicInfo(this.params);
    },
    "$route.query.checkedList"() {
      this.debouncedGetBasicInfo(this.params, "isShowBasicInfo");
    }
  }
};
</script>
