<template>
  <div>
    <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips" />
    <template v-else>
      <div class="search-form">
        <p class="search-form_title">即将实施的标准</p>
        <div flex="sb">
          <div class="search-form_box">
            <div>Search User<i class="el-icon-warning-outline"></i></div>
            <span style="font-size:30px;">12,321</span> <span style="margin-left:30px">17.1 <i style="color:#5ac624;" class="el-icon-caret-top"></i> </span>
            <div><img style="width:100%;" src="../../../assets/img/bolang.jpeg" alt="" /></div>
          </div>
          <div class="search-form_box">
            <div>Per Capita Search<i class="el-icon-warning-outline"></i></div>
            <span style="font-size:30px;">2.7</span> <span style="margin-left:30px">26.2 <i style="color:#5ac624;" class="el-icon-caret-top"></i> </span>
            <div><img style="width:100%;" src="../../../assets/img/bolang.jpeg" alt="" /></div>
          </div>
        </div>
      </div>
      <div class="search-form">
        <!-- <div flex="sb">
          <span class="table_title">统计结果</span>
          <el-button type="primary" plain>导出</el-button>
        </div> -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column sortable prop="name" label="Rank" width="180"> </el-table-column>
          <el-table-column prop="address" label="Keyword"> </el-table-column>
          <el-table-column sortable prop="address" label="Users" width="180"> </el-table-column>
          <el-table-column sortable prop="date" label="Weekly Range" width="180"> </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400" />
      </div>
    </template>
  </div>
</template>
<script>
/**
 * @description: 标准查询页面
 * @param {*}
 * @return {*}
 */
import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
export default {
  components: {
    VnotLogin,
  },
  data() {
    return {
      show: false,
      currentPage4: 4,
      showTips: [
        {
          title: "政策动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "政策库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "政策梳理",
          text: "专家梳理整合，真正读懂政策脉络",
        },
        {
          title: "政策大数据",
          text: "用数据深度理解政策结构和趋势",
        },
      ],
      checked: false,
      checkList: ["选中且禁用", "复选框 A"],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
  &_title {
    margin: 0 0 20px 0;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    font-size: 20px;
  }
  &_box {
    width: 240px;
  }
}
.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;
  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    label {
      width: 85px;
    }
    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }
    &:nth-child(1) {
      float: left;
    }
    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(3) {
      float: right;
    }
    .g-form-input {
      flex: 1;
      width: 185px;
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
