<template>
  <div
    v-if="isShowPerformance"
    class="enterpriseDetail"
    id="Performance"
    data-name="业绩情况"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="业绩情况"
      :showStr="false"
      :sourceData="sourceData"
    >
      <template #headExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
    </TableCard>
  </div>
</template>

<script>
import { getPartsMakerFinancialData,getExportPartsMakerFinancialData } from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import TableCard from "@/components/tableCard.vue";
export default {
  name: "Performance",
  components: {
    TableCard
  },
  data() {
    return {
      exportState: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isShowPerformance: false,
      params: {
        enterpriseId: this.$route.params.id
      },
      sourceData: {},
      isLoading: false
    };
  },
  created() {
    this.getPartsMakerFinancialData(this.params, "isShowPerformance");
  },
  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报", "企业信息", "3", "", "", "", "", "业绩情况", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              getExportPartsMakerFinancialData(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "业绩情况.xlsx");
              });
            }
          }
        } else {
          getExportPartsMakerFinancialData(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "业绩情况.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    getPartsMakerFinancialData(params, isShowPerformance) {
      this.isLoading = true;
      getPartsMakerFinancialData(params).then(res => {
        if (res.ok) {
          if (isShowPerformance) {
            this.isShowPerformance = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.isLoading = false;
        }
      });
    }
  }
};
</script>
