<template>
  <PageContainer>
    <div style="padding:0 24px">
      <div class="search-box">
        <div flex="sb">
          <h1 class="yshxh1">按类别查询</h1>
          <div @click="exports()">
            <JsonExcel :data="chartsTable" v-has-role="'trialrole'" v-if="show && !showW" :fields="charts_fields"
                       class="excelBtn"
                       name="汽车保有量图表.xls">导出
            </JsonExcel>
            <div v-else class="excelBtn">导出
            </div>
          </div>
        </div>
        <el-form inline label-width="100px">
          <el-form-item label="地区">
            <el-select v-model="cityValue" placeholder="请选择" size="small" @change="cityValueChange">
              <el-option v-for="item in city" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="细分市场">
            <el-select v-model="cxlbie1" placeholder="请选择" size="small" @change="cxlbie1Change">
              <el-option v-for="item in xfsc" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="cxlbie1 == '1'||cxlbie1 == '2'">
            <el-select v-model="cxlbie2" placeholder="请选择" size="small" @change="cxlbie2Change">
              <el-option v-for="item in xfsczl" :key="item.label" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div id="carCharts" v-if="show && !showP"></div>
      <div v-else style="position: relative; ">
        <img style="width:100%;" src="@/assets/img/byltb.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height:94px;bottom:150px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>
            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:150px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>
      </div>
      <h2 class="car-title">{{ title3 }}</h2>
    </div>
    <div style="padding:16px 24px 0px;background:#fff;border-top:10px solid #f6f6f6">
      <div class="search-box">
        <div flex="sb">
          <h1 class="yshxh1">按时间查询</h1>
          <div @click="exports()">
            <JsonExcel :data="cxbylTable" v-if="show && !showW " :fields="json_fields" class="excelBtn" v-has-role="'freeusers'"
                       name="汽车保有量.xls">导出
            </JsonExcel>
            <div v-else class="excelBtn">导出
            </div>
          </div>
        </div>
        <el-form>
          <el-form-item label="年份">
            <el-select v-model="year" placeholder="请选择" size="small" @change="yearChange" style="width:90px">
              <el-option v-for="item in years" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            年分省份民用汽车保有量（万辆）
          </el-form-item>
        </el-form>
      </div>
      <!-- <h2 class="car-title">{{year}}</h2> -->
      <div v-if="show && !showP">
        <el-table :data="cxbylTable" style="width: 100%; margin-bottom: 20px" stripe border
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
          <el-table-column prop="provice" label="年份" align="center" header-align="center">
          </el-table-column>
          <el-table-column prop="zz" label="民用汽车" align="center" header-align="center">
          </el-table-column>
          <el-table-column prop="zkz" label="载客汽车" align="center" header-align="center">
          </el-table-column>
          <el-table-column>
            <el-table-column prop="zk1" label="大型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zk2" label="中型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zk3" label="小型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zk4" label="微型" align="center" header-align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column prop="zhz" label="载货汽车" align="center" header-align="center">
          </el-table-column>
          <el-table-column>
            <el-table-column prop="zh1" label="重型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zh2" label="中型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zh3" label="轻型" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="zh4" label="微型" align="center" header-align="center">
            </el-table-column>
          </el-table-column>
          <el-table-column prop="qt" label="其他汽车" align="center" header-align="center">
          </el-table-column>
        </el-table>
      </div>
      <div v-else style="position: relative; ">
        <img style="width:100%;" src="@/assets/img/qcbylbg.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height:94px;bottom:1100px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:1100px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>

        </center>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import * as echarts from "echarts";
import {cxbyl, queryYearList, queryProvince, qcbylchart} from "@/api/data.js";
import JsonExcel from "vue-json-excel";
import {getUserInfo, logRecords, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      show: "",
      cxbylTable: [],
      year: "",
      years: [],
      city: [],
      cityValue: "全国",
      cxlbie1: "",
      cxlbie2: "",
      xfsc: [
        {
          value: "",
          label: "民用汽车",
        },
        {
          value: "1",
          label: "载客汽车",
          children: [
            {
              value: "",
              label: "不限",
            },
            {
              value: "1",
              label: "大型",
            },
            {
              value: "2",
              label: "中型",
            },
            {
              value: "3",
              label: "小型",
            },
            {
              value: "4",
              label: "微型",
            },
          ],
        },
        {
          value: "2",
          label: "载货汽车",
          children: [
            {
              value: "",
              label: "不限",
            },
            {
              value: "1",
              label: "重型",
            },
            {
              value: "2",
              label: "中型",
            },
            {
              value: "3",
              label: "轻型",
            },
            {
              value: "4",
              label: "微型",
            },
          ],
        },
        {
          value: "3",
          label: "其他汽车",
        },
      ],
      xfsczl: [],
      title3: "",
      json_fields: {
        年份: "provice",
        民用汽车: "zz",
        载客汽车: "zkz",
        大型: "zk1",
        中型: "zk2",
        小型: "zk3",
        微型: "zk4",
        载货汽车: "zhz",
        重型: "zh1",
        中型: "zh2",
        轻型: "zh3",
        微型: "zh4",
        其他汽车: "qt",
      },
      chartsTable: [],
      charts_fields: {
        年份: "year",
        保有量: "byl",
        同比增速: "tbzs",
      },
      showP: false,
      showW: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  mounted() {
    this.init();
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('数据')) {
          this.showP = true;

        } else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showW = true;
          }

        }
      }
      this.qcbylchart();

    } else {
      this.show = false;

    }
  }
  ,
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //初始化数据
    init() {
      //年份
      queryYearList().then((res) => {
        if (res.ok) {
          this.years = res.data;
          this.year = res.data[0];
          if (getUserInfo()) {
            this.cxbyl(this.year);
          }
        }
      });
      queryProvince().then((res) => {
        if (res.ok) {
          let city = ["全国"].concat(res.data);
          this.city = city;
        }
      });
    },

    exports() {

      if (getUserInfo()) {

        logRecords('数据','汽车保有量','3','tt_cx_byl_zcl','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            }
          }

        }
      } else {
        this.showLogin();
      }
    }
    ,
    //表格
    cxbyl(year) {
      cxbyl({year}).then((res) => {
        if (res.ok) {
          this.cxbylTable = res.data;

          this.cxbylTable.forEach((item) => {
            item.zz = item.zz.toFixed(2);
            item.zkz = item.zkz.toFixed(2);
            item.zk1 = item.zk1.toFixed(2);
            item.zk2 = item.zk2.toFixed(2);
            item.zk3 = item.zk3.toFixed(2);
            item.zk4 = item.zk4.toFixed(2);
            item.zhz = item.zhz.toFixed(2);
            item.zh1 = item.zh1.toFixed(2);
            item.zh2 = item.zh2.toFixed(2);
            item.zh3 = item.zh3.toFixed(2);
            item.zh4 = item.zh4.toFixed(2);
            item.qt = item.qt.toFixed(2);
          });
        }
      });
    }
    ,
    //时间下拉框选择
    yearChange(e) {
      if (getUserInfo()) {

        logRecords('数据','汽车保有量','3','tt_cx_byl_zcl','','','','','时间筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            this.cxbyl(e);
          }
        } else {
          this.cxbyl(e);
        }
      } else {
        this.showLogin();
      }
    }
    ,
    //细分类别下拉
    cxlbie1Change(e) {
      if (getUserInfo()) {

        logRecords('数据','汽车保有量','3','tt_cx_byl_zcl','','','','','类别筛选')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();

            this.insertBuryPoint();
            this.cxlbie1 = "民用汽车";

          } else {
            let filterData = this.xfsc.filter((item) => item.value == e);
            this.cxlbie2 = "";
            if (filterData[0].children && filterData[0].children.length > 0) {
              this.xfsczl = filterData[0].children;
            }
            this.cxlbie1 = e;
            this.qcbylchart();
          }
        } else {
          let filterData = this.xfsc.filter((item) => item.value == e);
          this.cxlbie2 = "";
          if (filterData[0].children && filterData[0].children.length > 0) {
            this.xfsczl = filterData[0].children;
          }
          this.cxlbie1 = e;
          this.qcbylchart();
        }
      } else {
        this.cxlbie1 = "民用汽车";
        this.showLogin();
      }
    }
    ,
    qcbylchart() {
      let obj = {
        provice: this.cityValue == "全国" ? "" : this.cityValue,
        cxlbie1: this.cxlbie1,
        cxlbie2: this.cxlbie2,
      };
      qcbylchart(obj).then((res) => {
        if (res.ok) {
          this.title3 = res.data.title3;
          let xAxis3 = res.data.xAxis3;
          let series3 = res.data.series3;
          let table = [];

          xAxis3.forEach((item, index) => {
            let obj = {};
            obj["year"] = item;
            series3.forEach((citem, cindex) => {
              if (citem.name == "保有量") {
                obj["byl"] = citem.data[index];
              } else if (citem.name == "同比增速") {
                obj["tbzs"] = citem.data[index];
              }
            });
            table.push(obj);
          });
          this.chartsTable = table;
          this.echartsInit(res.data);
        }
      });
    }
    ,
    //echarts
    echartsInit(data) {
      var myChart = echarts.init(document.getElementById("carCharts"));
      // console.log(JSON.stringify(data.series3))
      var option = {
        title: {
          subtext: "万辆",
        },
        legend: {
          data: ["保有量", "同比增速"],
        },
        tooltip: {
          trigger: "axis",
          formatter: function (datas) {
            let res = datas[0].name + "<br/>";
            let val;
            let length = datas.length;
            let i = 0;
            for (; i < length; i++) {
              val = Number(datas[i].value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  "：" +
                  val +
                  "<br/>";
            }
            return res;
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.xAxis3,
            axisTick: {
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: data.series3,
      };
      myChart.setOption(option);
    }
    ,
    cityValueChange(e) {

      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.cityValue = "全国";
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            this.cityValue = e;
            this.qcbylchart();
          }
        } else {
          this.cityValue = e;
          this.qcbylchart();
        }
      } else {
        this.cityValue = "全国";

        this.showLogin();
      }
    }
    ,
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    }
    ,
    cxlbie2Change(e) {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.cityValue = "全国";
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            this.cxlbie2 = e;
            this.qcbylchart();
          }
        } else {
          this.cxlbie2 = e;
          this.qcbylchart();
        }
      } else {
        this.cityValue = "全国";

        this.showLogin();
      }
    }
    ,
  }
  ,
}
;
</script>
<style lang="scss" scope>
.yshxh1 {
  font-size: 16px;
  margin-bottom: 15px;
}

.search-box,
.search-form {
  margin-bottom: 15px;
}

#carCharts {
  width: 829px !important;
  height: 268px !important;
  margin: 0 auto;
}

.car-title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 15px;
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
