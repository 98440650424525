<template>
    <div class="outer-layer-box outer-layer-new">
        <!-- <div style="font-weight: 700;line-height: 30px;margin-bottom: 20px;font-size: 16px">更多数据</div> -->
        <span>时间：</span>
        <el-select v-model="hisForm.nian" placeholder="请选择" size="small" @change="selectChang">
            <el-option v-for="item in historyYear" :key="item" :label="item" :value="item">
            </el-option>
        </el-select>
        <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px">
            <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-button size="small" type="primary" style="margin:5px" @click="hisSearch">查询</el-button>
        <div style="padding-top:10px;min-height: 300px">
            <table border="1" style="width: 100%;padding: 5px" class="hisTable">
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qccxhzb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车(分车型)产销汇总表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qcsczyqycxqkb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车生产主要企业产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/cychzb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份乘用车产销汇总表</router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/sychzb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份商用车产销汇总表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/xnyqccxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份新能源汽车产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qgcyczyppcxqkb/','0')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份全国乘用车主要品牌产销情况表</router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/wdqcqycyc/')" target="_blank">{{newTime[0]}}年01-{{newTime[1]}}月份五大汽车企业集团乘用车产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/wdqcqysyc/')" target="_blank">{{newTime[0]}}年01-{{newTime[1]}}月份五大汽车企业集团商用车产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qcfqyckqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份汽车分企业出口情况表
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/getBgqycscqycxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份半挂牵引车生产企业产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/cycfcxcxqkb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份乘用车(分车型)产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/cycscqycxqkb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份乘用车生产企业产销情况表</router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/sycscqycxqkb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份商用车生产企业产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qgcyczyppcxqkb/','5')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份基本型乘用车主要品牌产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qgcyczyppcxqkb/','3')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份MPV主要品牌产销情况表</router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qgcyczyppcxqkb/','4')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份SUV主要品牌产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/qgcyczyppcxqkb/','6')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份交叉型乘用车主要品牌产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/scqycxqkb/','12')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份客车生产企业产销情况表</router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/scqycxqkb/','7')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份载货车生产企业产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/frlclfcxcxb/','7')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份载货车(分燃料)产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/zhcfdwcxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份载货车(分吨位)产销情况表
                        </router-link>
                    </td>

                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/xnysyccyc/','1')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份新能源乘用车产销情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/frlclfcxcxb/','12')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份客车(分燃料)产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/bgqycfdwcxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份半挂牵引车(分吨位)产销情况表
                        </router-link>
                    </td>

                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/frlclfcxcxb/','18')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份客车非完整车辆(分车型)产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/bgqycfcxcxhzb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份半挂牵引车(分车型)产销汇总表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/frlclfcxcxb/','17')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份货车非完整车辆(分车型)产销情况表
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <router-link :to="toHref('/data/dataTable/xnysyccyc/','2')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份新能源商用车产销情况表</router-link>
                    </td>

                    <td>
                        <router-link :to="toHref('/data/dataTable/zhcfytcxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份载货车(分用途)产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/getHcfwzclscqycxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份货车非完整车辆生产企业产销情况表
                        </router-link>
                    </td>
                </tr>

                <tr>

                    <td>
                        <router-link :to="toHref('/data/dataTable/qcfdjarlflscqyqkb/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车发动机(按燃料分类)生产企业产销情况表 (台）</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/cqsp/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车发动机(按燃料分类)生产企业商品量情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/cqzp/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车发动机(按燃料分类)生产企业自配量情况表</router-link>
                    </td>
                </tr>
                <tr>

                    <td>
                        <router-link :to="toHref('/data/dataTable/fdjscqy/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份发动机生产企业产销情况表 (台)</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/fdsp/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车发动机生产企业商品量情况表</router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/fdzp/')" target="_blank">{{newTime[0]}}年{{newTime[1]}}月份汽车发动机生产企业自配量情况表</router-link>
                    </td>
                </tr>
                <tr>

                    <td>
                        <router-link :to="toHref('/data/dataTable/getKcfcxCxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份客车(分车型)产销情况表
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="toHref('/data/dataTable/getZhcfcxCxqkb/')" target="_blank">
                            {{newTime[0]}}年{{newTime[1]}}月份载货车(分车型)产销情况表
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>
<script>
import { getYearListTable, lastTime, getYueListDynamic } from "@/api/data.js";
export default {
    data() {
        return {
            historyYear: [],
            historyYue: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ],
            hisForm: {
                nian: "",
                yue: "02",
            },
            historyList: [],
            hv: "",
            newTime: ["2021", "02"],
        };
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        selectChang(v){
            this.getYueListDynamic(v)
        },
        init() {
            getYearListTable().then((res) => {
                if (res.ok) {
                    this.historyYear = res.data;
                    this.hisForm.nian = res.data[0];
                    this.$set(this.newTime, [0], this.hisForm.nian);
                    this.getYueListDynamic(res.data[0]);
                }
            });
            // lastTime().then((res) => {
            //     if (res.ok) {
            //         this.newTime = res.data.split("-");
            //         this.hisForm.yue = this.newTime[1];
            //     }
            // });
        },
        getYueListDynamic(nian) {
            getYueListDynamic(nian).then((res) => {
                this.historyYue = res.data;
                this.hisForm.yue = res.data[res.data.length - 1];
                this.$set(this.newTime, [1], this.hisForm.yue);
            });
        },
        hisSearch() {
            this.$set(this.newTime, [0], this.hisForm.nian);
            this.$set(this.newTime, [1], this.hisForm.yue);
        },
        toHref(url, type) {
            let str = "";
            str = type
                ? (str = url + this.newTime.join("-") + "-01/" + type)
                : url + this.newTime.join("-") + "-01";
            return str;
        },
    },
};
</script>
<style lang="scss" scoped>
.hisTable {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #0d57bc;
    font-size: 14px;
}
.hisTable td {
    padding: 5px;
    cursor: pointer;
}
.outer-layer-new {
    padding: 20px;
}
</style>
