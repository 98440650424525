<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" :isCollection='false' isContentTwo="hyxx" />
  </PageContainer>
</template>

<script>
import { ttMeetingInformationManagementDetail } from "@/api/Index.js";
export default {
  data() {
    return {
      data: {},
      props: {
        title:"conferenceTitle",
        summary: "sessionDescription",
        source:"conferenceOrganizers"
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttMeetingInformationManagementDetail(id);
  },
  methods: {
    ttMeetingInformationManagementDetail(params) {
      ttMeetingInformationManagementDetail(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.data.updateTime = res.data.dateConference
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
