<template>
    <div class="content">
        <table class="table" border="1">
            <tr>
              <td align="right" width="30%"><strong>达标车型编号:</strong></td>
                <td >{{ tableData.dbcxbh || '-'}}</td>
            </tr>
            <tr>
                <td align="right" width="30%"><strong>产品型号:</strong></td>
                <td width="70%">{{ tableData.cpxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>生产企业:</strong></td>
                <td>{{ tableData.scqy || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>产品名称:</strong></td>
                <td>{{ tableData.cpmc || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>底盘型号:</strong></td>
                <td>{{ tableData.dpxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>发动机型号:</strong></td>
                <td>{{ tableData.fdjxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>燃料种类:</strong></td>
                <td>{{ tableData.rlzl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>最高车速(km/h):</strong></td>
                <td>{{ tableData.zgcs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>外廓尺寸(长×宽×高)mm:</strong></td>
                <td>{{ tableData.wkcc || '-'}}</td>
            </tr>

            <tr>
                <td align="right">
                    <strong>货厢栏板内尺寸(长×宽×高)
                        mm或容积m³或搅动容量m³或有效容积m³:</strong>
                </td>
                <td>{{ tableData.hxlbncc || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>客车类型:</strong></td>
                <td>{{ tableData.kclx || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>等级:</strong></td>
                <td>{{ tableData.grade || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>总质量kg:</strong></td>
                <td>{{ tableData.zzl || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>最大总质量kg:</strong></td>
                <td>{{ tableData.zdzzl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>整备质量kg:</strong></td>
                <td>{{ tableData.zbzl || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>轮胎规格型号:</strong></td>
                <td>{{ tableData.ltggxh || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>制动器型式(前/后):</strong></td>
                <td>{{ tableData.ltsl || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>悬架类型:</strong></td>
                <td>{{ tableData.xjlx || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>车外行李架:</strong></td>
                <td>{{ tableData.cwxlj || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>乘员座位数标识:</strong></td>
                <td>{{ tableData.cyzwsbs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>行李舱净高m:</strong></td>
                <td>{{ tableData.xlcjg || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>人均行李舱容积 m³/人≥:</strong></td>
                <td>{{ tableData.rjxlcrj || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>行李舱约束装置:</strong></td>
                <td>{{ tableData.xlcyszz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>停车楔数量≥2(个):</strong></td>
                <td>{{ tableData.tcqsl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>油箱侧面防护:</strong></td>
                <td>{{ tableData.yxcmfh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>燃油箱数量:</strong></td>
                <td>{{ tableData.ryxsl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>油箱距前端距离(≥600mm):</strong></td>
                <td>{{ tableData.yxjqdjl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>油箱距后端距离(≥300mm):</strong></td>
                <td>{{ tableData.yxjhdjl || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>加气口仪表和阀件防护装置(燃气汽车):</strong>
                </td>
                <td>{{ tableData.jqkybhfjfhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>燃气瓶数量及位置(燃气汽车):</strong></td>
                <td>{{ tableData.rqpsljwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>气压制动系统压缩空气干燥、油水分离装置:</strong>
                </td>
                <td>{{ tableData.qyzdxtyskqgzysflzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>制动间隙自动调整装置:</strong></td>
                <td>{{ tableData.zdjxzdtzzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>传动轴防护装置:</strong></td>
                <td>{{ tableData.cdzfhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>底盘集中润滑系统:</strong></td>
                <td>{{ tableData.dpjzrhxt || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>发动机位置:</strong></td>
                <td>{{ tableData.fdjwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>发动机舱自动灭火装置:</strong></td>
                <td>{{ tableData.fdjczdmhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>缓速器型式及型号:</strong></td>
                <td>{{ tableData.hsqxsjxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>动力电池箱内具有报警功能的自动灭火装置:</strong>
                </td>
                <td>{{ tableData.dldcxnjybjgndzdmhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>电涡流缓速器报警系统:</strong></td>
                <td>{{ tableData.dwlhsqbjxt || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>电涡流缓速器隔热装置:</strong></td>
                <td>{{ tableData.dwlhsqgrzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>电磁风扇离合器或其他节能风扇散热系统:</strong>
                </td>
                <td>{{ tableData.dcfslhqhqtjnfssrxt || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>随动转向机构:</strong></td>
                <td>{{ tableData.sdzxjg || '-'}}</td>
            </tr>

            <tr>
                <td align="right"><strong>踏步区座椅布置:</strong></td>
                <td>{{ tableData.tbqzybz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>驾驶员上方地板:</strong></td>
                <td>{{ tableData.jsysfdb || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>乘客门数量及位置:</strong></td>
                <td>{{ tableData.ckmsljwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>安全标志:</strong></td>
                <td>{{ tableData.aqbz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>乘客门结构:</strong></td>
                <td>{{ tableData.ckmjg || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>乘客门应急控制器:</strong></td>
                <td>{{ tableData.ckmyjkzq || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>外推式应急窗数量(左/右):</strong></td>
                <td>{{ tableData.wtsyjcsl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>应急锤数量:</strong></td>
                <td>{{ tableData.yjcsl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>应急锤型号:</strong></td>
                <td>{{ tableData.yjcxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>应急锤声响信号报警装置:</strong></td>
                <td>{{ tableData.yjcsxxhbjzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>后围应急窗布置:</strong></td>
                <td>{{ tableData.hwyjcbz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>安全顶窗数量及位置:</strong></td>
                <td>{{ tableData.aqdcsljwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>应急门数量及位置:</strong></td>
                <td>{{ tableData.yjmsljwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>安全出口数量:</strong></td>
                <td>{{ tableData.aqcksl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>应急门引道宽度:</strong></td>
                <td>{{ tableData.yjmydkd || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>客舱内通道宽mm ≥:</strong></td>
                <td>{{ tableData.kcntdk || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>座间距(同向)mm ≥:</strong></td>
                <td>{{ tableData.zjj || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>车内通道折叠座椅:</strong></td>
                <td>{{ tableData.cntdzdzy || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>座椅深 mm ≥:</strong></td>
                <td>{{ tableData.zys || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>靠背角度可调(15°～30°):</strong></td>
                <td>{{ tableData.kbjdkt || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>座垫宽 mm ≥:</strong></td>
                <td>{{ tableData.zdk || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>靠背高 mm ≥:</strong></td>
                <td>{{ tableData.kbg || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>座椅横移(向通道)mm≥:</strong></td>
                <td>{{ tableData.fdjxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>扶手(靠通道处):</strong></td>
                <td>{{ tableData.zyhy || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>汽车安全带:</strong></td>
                <td>{{ tableData.qcaqd || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>座椅脚蹬:</strong></td>
                <td>{{ tableData.zyjd || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>安全带提醒装置:</strong></td>
                <td>{{ tableData.aqdtxzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>车内行李架:</strong></td>
                <td>{{ tableData.cnxlj || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>视频监控系统型号:</strong></td>
                <td>{{ tableData.cpjkxtxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>卫星定位系统车载终端型号:</strong></td>
                <td>{{ tableData.wxdwxtczzdxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>空气调节装置:</strong></td>
                <td>{{ tableData.kqtjzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>空气净化装置:</strong></td>
                <td>{{ tableData.kqjhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>通风换气装置:</strong></td>
                <td>{{ tableData.hqtfzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>影音播放及麦克风设备:</strong></td>
                <td>{{ tableData.yybfjmkfsb || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>卫生间:</strong></td>
                <td>{{ tableData.wsj || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>系固点数量(前墙):</strong></td>
                <td>{{ tableData.xgdslqq || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>系固点数量(水平承载面):</strong></td>
                <td>{{ tableData.xgdslspczm || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>前下部防护装置:</strong></td>
                <td>{{ tableData.qxbfhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>后下部防护装置:</strong></td>
                <td>{{ tableData.hxbfhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>侧面防护装置:</strong></td>
                <td>{{ tableData.cmfhzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>CAN总线:</strong></td>
                <td>{{ tableData.can || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>自动破玻器数量及开关位置:</strong></td>
                <td>{{ tableData.zdpbqsljkgwz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>轮胎爆胎应急安全装置型号:</strong></td>
                <td>{{ tableData.ltbtyjaqzzxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>制动气压显示及限压装置:</strong></td>
                <td>{{ tableData.zdqyxsjxyzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>轮胎气压监测系统型号:</strong></td>
                <td>{{ tableData.ltqyjcxtxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>制动储气筒额定工作气压kPa:</strong></td>
                <td>{{ tableData.zdcqtedgzqy || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>制动器衬片更换报警装置:</strong></td>
                <td>{{ tableData.zdqcpghbjzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>驾驶室轮胎爆胎应急安全装置标示:</strong>
                </td>
                <td>{{ tableData.jssltbtyjaqzzbs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>冷藏车温度监控装置:</strong></td>
                <td>{{ tableData.lccwdjkzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>起重尾板警示标识:</strong></td>
                <td>{{ tableData.qzwbjsbs || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>汽车导静电橡胶拖地带(燃气汽车):</strong>
                </td>
                <td>{{ tableData.qcdjdxjtdd || '-'}}</td>
            </tr>
            <tr>
                <td align="right">
                    <strong>燃气气体泄漏报警装置型号(燃气汽车):</strong>
                </td>
                <td>{{ tableData.rqqtxlbjzzxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>压力测试连接器数量(储气筒):</strong></td>
                <td>{{ tableData.ylcsljqslcqt || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>压力测试连接器数量(制动气室):</strong></td>
                <td>{{ tableData.ylcsljqslzdqs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>电子稳定性控制系统(ESC)型号:</strong></td>
                <td>{{ tableData.adsxhbjzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>防抱制动装置(ABS)信号报警装置:</strong></td>
                <td>{{ tableData.adsxhbjzz || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>车道偏离预警系统(LDWS)型号:</strong></td>
                <td>{{ tableData.ldwsxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>防抱制动装置(ABS):</strong></td>
                <td>{{ tableData.abs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>自动紧急制动系统(AEBS)型号:</strong></td>
                <td>{{ tableData.aebsxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>车辆前向碰撞预警系统型号 :</strong></td>
                <td>{{ tableData.clqxpzyjxtxh || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>综合燃料消耗量L/100km:</strong></td>
                <td>{{ tableData.zhrlxhl || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>驱动型式:</strong></td>
                <td>{{ tableData.qdxs || '-'}}</td>
            </tr>
            <tr>
                <td align="right"><strong>备注:</strong></td>
                <td>
                    {{ tableData.remark || '-'}}
                </td>
            </tr>
            <tr>
                <td align="right"><strong>说明:</strong></td>
                <td>{{ tableData.summary || '-'}}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        tableData: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {};
    },
    created() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
$allcolor: #0d57bc;
.content {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    overflow-y: auto;
}
.table {
    width: 100%;
    border-color: $allcolor;
    color: #000;
    tr {
        td {
            padding: 5px 10px;
            word-break: break-all;
        }
        &:nth-child(even) {
            background: #bbc8da;
        }
    }
    .del_btn {
        position: absolute;
        top: 0;
        right: 0;
    }
    td,
    th {
        border: 1px solid #ddd;
    }
}
</style>