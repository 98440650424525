<template>
  <div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button v-has-role="'freeusers'" class="text-right" type="primary" plain @click="exportEvent">
          导出
        </el-button>

      </div>
    </div>
    <template>
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ cycz }} -->
          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">乘用车整体情况预测&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="cycTip" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                class="el-icon-info"
              />预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
      <template v-if="show && !showP">
        <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;">乘用车预测趋势分析</div>

        <div id="cyZtChart" />
        <div v-has-role="'freeusers'" style="margin: 0 60px 60px 60px">
          <!-- 表格数据 -->

          <div>
            <!--          <span style="color: red;font-size: 12px">*</span><span style="color: grey;font-size: 12px">历史数据为实际销量</span>-->
            <span>{{ this.danWei }}</span>
            <el-table
              id="echartsTable"
              :data="modelList"
              :border="true"
              size="small"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
            >
              <el-table-column
                v-for="(item, index) in mokuaiTableTitle"
                :key="item"
                :label="item"
                align="center"
                style="user-select: initial"
              >
                <template slot-scope="scope">
                  <span v-for="(item2, index2) in scope.row" :key="index2">
                    <span v-if="index2 == index">
                      {{ scope.row[index2] }}
                    </span>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/cyc1.jpg" alt="">
        <center style="height: 1px;">
          <div
            v-if="!show"
            style="position:relative;width:300px;height: 94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
          >

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
            v-else
            style="position:relative;width:320px;height:94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
          >
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span
                style="color: #0D57BC;cursor: pointer"
                @click="sqsy('/sqSy?flag='+2)"
              >直接联系我们</span>
            </div>
          </div>
        </center>

      </div>
    </template>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button v-has-role="'freeusers'" type="primary" plain @click="excelbtn">
          导出
        </el-button>
      </div>
    </div>
    <template v-if="show && !showP">
      <div class="g-content-top">
        <div flex="1">
          <!-- {{ cycz }} -->

          <div><span class="text-left" style="font-size: 18px;color: #0f0f0f;font-weight: bold">乘用车分车型情况预测&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="cycTip" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                class="el-icon-info"
              />预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;margin-bottom: 20px;">
        乘用车分车型整体预测情况
      </div>

      <div id="cychart" />
      <div class="g-content-bottom">
        <span>单位：万辆</span>
        <el-table
          :data="cycTable"
          :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
          style="width: 100%; margin-bottom: 20px"
          stripe
        >
          <el-table-column prop="类别" label="类别" min-width="130" />
          <template v-for="(item, index) in cycHeader">
            <el-table-column v-if="item !='类别'" :key="'cyc' + index" :prop="item" :label="item" min-width="100" />
          </template>
        </el-table>
      </div>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/cyc2.jpg" alt="">
      <center style="height: 1px;">
        <div
          v-if="!show"
          style="position:relative;width:300px;height: 94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
        >

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

          </div>
        </div>
        <div
          v-else
          style="position:relative;width:320px;height:94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
        >
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span
              style="color: #0D57BC;cursor: pointer"
              @click="sqsy('/sqSy?flag='+2)"
            >直接联系我们</span>
          </div>
        </div>
      </center>

    </div>
    <div class="search-form">
      <div style="position: absolute;right: 0;">

        <el-button v-has-role="'freeusers'" class="text-right" type="primary" plain @click="exportCycFcx">
          导出
        </el-button>

      </div>
    </div>
    <template>

      <div class="text-center" style="font-size: 16px;color: #0f0f0f;font-weight: bold;margin-bottom: 20px;    margin-top: 40px;">
        乘用车各车型预测趋势分析
      </div>
      <template v-if="show && !showP">
        <div id="carChart" style="float: left" />
        <div id="suvChart" style="float: right" />
        <div id="mpvChart" style="float: left" />
        <div id="jcChart" style="float: right" />
        <div v-has-role="'freeusers'" style="margin: 0 60px 60px 60px">
          <!-- 表格数据 -->

          <div>
            <el-table
              id="cycFcxTable"
              :data="cycModelList"
              :border="true"
              size="small"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              style="display: none"
            >
              <el-table-column
                v-for="(item, index) in cycTitle"
                :key="item"
                :label="item"
                align="center"
                style="user-select: initial"
              >
                <template slot-scope="scope">
                  <span v-for="(item2, index2) in scope.row" :key="index2">
                    <span v-if="index2 == index">
                      {{ scope.row[index2] }}
                    </span>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/cyc3.jpg" alt="">
        <center style="height: 1px;">
          <div
            v-if="!show"
            style="position:relative;width:300px;height: 94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
          >

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
            v-else
            style="position:relative;width:320px;height:94px;bottom:550px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
          >
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span
                style="color: #0D57BC;cursor: pointer"
                @click="sqsy('/sqSy?flag='+2)"
              >直接联系我们</span>
            </div>
          </div>
        </center>

      </div>
    </template>
  </div>

</template>
<script>
import {
  selectMonthCycTotal,
  exportPredictCyc,
  selectMonthCycCar,
  selectMonthCycSuv,
  selectMonthCycMpv,
  selectMonthCycJc,
  exportPredictSyc, selectMouseTotalCyc
} from '@/api/sale.js'
import * as echarts from 'echarts'
import JsonExcel from 'vue-json-excel'
import { downloadFile } from '@/utils/download.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  components: {
    JsonExcel
  },
  data() {
    return {
      cychartData: [],
      cycTable: [],
      cycHeader: [],
      cycupdateTime: '',
      cycz: '',
      leftTotalList: [],
      cycTip: '乘用车预测，基于乘用车市场新购、增购和换购趋势，结合宏观经济、政策、季节、厂商、库存等影响因素对未来销量进行预测。（数据来源：中汽协批发量数据，历史数据为实际销量）',
      sycTable: [],
      sycupdateTime: '',
      sycz: '',
      sycTip: '商用车预测，根据宏观经济形势、运输结构、客运量等关键影响因素，结合行业政策影响，采用专家预测法，预测数据仅供参考',
      sycHeader: [],
      syctotal: [],
      nowYear: '',
      show: false,
      showP: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '0',
        columnName: '预测',
        userId: ''
      },
      danWei: '单位：万辆，%',
      mokuaiTableTitle: [],
      modelList: [],
      cycTitle: [],
      cycModelList: []
    }
  },
  created() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        const menuName = getUserInfo().menuName
        if (!menuName.includes('预测')) {
          this.showP = true
        }
      }
      this.init()
      this.show = true
    } else {
      this.show = false
    }
  },
  methods: {

    // 申请试用
    sqsy(url) {
      const newUrl = this.$router.resolve(url)

      window.open(newUrl.href, '_blank')
    },
    // 显示登录弹窗
    showLogin2() {
      this.$Vlogin({ flag: true })
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    init() {
      selectMonthCycTotal().then((res) => {
        if (res.ok) {
          const myChart = echarts.init(document.getElementById('cyZtChart'))
          const data = res.data
          const cycTemplateFile = data.cycTemplateFile
          eval(cycTemplateFile)

          data.xAxisData.unshift('分类')
          this.mokuaiTableTitle = data.xAxisData
          this.modelList = []
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x])
            this.modelList.push(data.seriesData[x])
          }
        }
      })
      selectMouseTotalCyc().then((res) => {
        if (res.ok) {
          this.cycupdateTime = res.data.updateTime
          this.initChart('cychart', res.data)
          const table = []
          const tabs = []
          const timeList = res.data.timeList
          const dataMap = res.data.dataMap
          const total = res.data.dataResultTotalMap.total
          dataMap['总计'] = total

          for (const key in dataMap) {
            table.push(dataMap[key])
          }
          for (const key in dataMap) {
            const obj = {}
            for (let i = 0; i < table.length; i++) {
              obj['类别'] = key
            }
            tabs.push(obj)
          }
          // 处理时间
          for (let i = 0; i < table.length; i++) {
            for (let j = 0; j < table[i].length; j++) {
              for (let k = 0; k < timeList.length; k++) {
                tabs[i][timeList[k]] = table[i][k].toFixed(2)
              }
            }
          }
          // 处理合计数据
          for (let i = 0; i < table.length; i++) {
            let s = 0
            for (let j = 0; j < table[i].length; j++) {
              s += table[i][j]
              this.leftTotalList[i] = s.toFixed(2)
              tabs[i]['合计'] = s.toFixed(2)
            }
          }
          // 处理表格头部
          this.cycHeader = ['类别'].concat(timeList, '合计')
          this.cycTable = tabs
          // 更新时间
          const strTime = timeList[0]
          const endTime = timeList[timeList.length - 1]
          this.fromData(strTime)
          this.fromData(endTime)
          this.cycz =
              this.fromData(strTime) + '至' + this.fromData(endTime)
        }
      })
      selectMonthCycCar().then((res) => {
        if (res.ok) {
          const myChart = echarts.init(document.getElementById('carChart'))
          const data = res.data
          const cycTemplateFile = data.cycTemplateFile
          eval(cycTemplateFile)

          data.xAxisData.unshift('分类')
          data.xAxisData.unshift('类别')
          this.cycTitle = data.xAxisData
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x])
            data.seriesData[x].unshift('轿车')
            this.cycModelList.push(data.seriesData[x])
          }
          // console.log(this.cycModelList)
        }
      })
      selectMonthCycSuv().then((res) => {
        if (res.ok) {
          const myChart = echarts.init(document.getElementById('suvChart'))
          const data = res.data
          const cycTemplateFile = data.cycTemplateFile
          eval(cycTemplateFile)

          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x])
            data.seriesData[x].unshift('SUV')
            this.cycModelList.push(data.seriesData[x])
          }
          // console.log(this.cycModelList)
        }
      })
      selectMonthCycMpv().then((res) => {
        if (res.ok) {
          const myChart = echarts.init(document.getElementById('mpvChart'))
          const data = res.data
          const cycTemplateFile = data.cycTemplateFile
          eval(cycTemplateFile)

          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x])
            data.seriesData[x].unshift('MPV')

            this.cycModelList.push(data.seriesData[x])
          }
          // console.log(this.cycModelList)
        }
      })
      selectMonthCycJc().then((res) => {
        if (res.ok) {
          const myChart = echarts.init(document.getElementById('jcChart'))
          const data = res.data
          const cycTemplateFile = data.cycTemplateFile
          eval(cycTemplateFile)

          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x])
            data.seriesData[x].unshift('交叉型乘用车')

            this.cycModelList.push(data.seriesData[x])
          }
          // console.log(this.cycModelList)
        }
      })
    },

    exportEvent() {
      this.formLog.eventType = '0'
      if (getUserInfo()) {
        logRecords('预测', '乘用车月度预测', '3', 'tt_year_month_predict_cyc', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('预测')) {
            this.insertBuryPoint()
            this.sqsyLogin()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              const grid = XLSX.utils.table_to_book(
                document.querySelector('#echartsTable'),
                { raw: true }
              )
              const workbook = XLSX.write(grid, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array'
              })
              try {
                FileSaver.saveAs(
                  new Blob([workbook], {
                    type: 'application/octet-stream'
                  }),
                  '乘用车预测趋势分析.xlsx'
                )
              } catch (e) {
                if (typeof console !== 'undefined') console.log(e, workbook)
              }
              return workbook
            }
          }
        } else {
          const grid = XLSX.utils.table_to_book(
            document.querySelector('#echartsTable'),
            { raw: true }
          )
          const workbook = XLSX.write(grid, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
          })
          try {
            FileSaver.saveAs(
              new Blob([workbook], {
                type: 'application/octet-stream'
              }),
              '乘用车预测趋势分析.xlsx'
            )
          } catch (e) {
            if (typeof console !== 'undefined') console.log(e, workbook)
          }
          return workbook
        }
      } else {
        this.showLogin()
      }
    },
    exportCycFcx() {
      this.formLog.eventType = '0'
      if (getUserInfo()) {
        logRecords('预测', '乘用车月度预测', '3', 'tt_year_month_predict_cyc', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('预测')) {
            this.insertBuryPoint()
            this.sqsyLogin()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              const grid = XLSX.utils.table_to_book(
                document.querySelector('#cycFcxTable'),
                { raw: true }
              )
              const workbook = XLSX.write(grid, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array'
              })
              try {
                FileSaver.saveAs(
                  new Blob([workbook], {
                    type: 'application/octet-stream'
                  }),
                  '乘用车分车型情况预测.xlsx'
                )
              } catch (e) {
                if (typeof console !== 'undefined') console.log(e, workbook)
              }
              return workbook
            }
          }
        } else {
          const grid = XLSX.utils.table_to_book(
            document.querySelector('#cycFcxTable'),
            { raw: true }
          )
          const workbook = XLSX.write(grid, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
          })
          try {
            FileSaver.saveAs(
              new Blob([workbook], {
                type: 'application/octet-stream'
              }),
              '乘用车分车型情况预测.xlsx'
            )
          } catch (e) {
            if (typeof console !== 'undefined') console.log(e, workbook)
          }
          return workbook
        }
      } else {
        this.showLogin()
      }
    },
    initChart(id, data) {
      var myChart = echarts.init(document.getElementById(id))
      var xData = data.timeList
      var dataMap = data.dataMap
      var tbzsList = data.tbzsList.map((item) => Number(item))
      // var vehicleList = data.vehicleList;
      var vehicleList = []
      var seriesData = []

      for (const key in dataMap) {
        vehicleList.push(key)
        seriesData.push({
          name: key,
          type: 'bar',
          stack: 'Search Engine',
          data: dataMap[key]
        })
      }
      // seriesData.push({
      //   name: "同比增速",
      //   type: "line",
      //   yAxisIndex: 1,
      //   data: tbzsList,
      // });

      var option = {
        title: {
          subtext: '万辆'
        },

        legend: {
          data: vehicleList
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(datas) {
            let res = datas[0].name + '<br/>'
            let val
            const length = datas.length
            let i = 0
            for (; i < length; i++) {
              val = datas[i].value
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  '：' +
                  val +
                  '<br/>'
            }
            return res
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true }

          }
        },
        grid: {
          left: '100%',
          right: '100%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              inside: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            }
          }
          // {
          //   type: "value",
          //   axisLabel: {
          //     formatter: "{value} %",
          //   },
          //   splitLine: {
          //     show: false,
          //   },
          //   axisLine: {
          //     show: true,
          //   },
          //   axisTick: {
          //     show: true,
          //   },
          // },
        ],
        series: seriesData
      }
      myChart.clear()
      return option && myChart.setOption(option, true)
    },
    initChart2(id, data) {
      var myChart = echarts.init(document.getElementById(id))
      var xData = data.timeList
      var dataMap = Object.assign({}, data.dataHcMap, data.dataKcMap)
      var tbzsList = data.tbzsMap.map((item) => Number(item))
      var vehicleList = []
      var seriesData = []

      if (dataMap) {
        for (const key in dataMap) {
          vehicleList.push(key)
        }
      }

      for (const key in dataMap) {
        seriesData.push({
          name: key,
          type: 'bar',
          stack: 'Search Engine',
          data: dataMap[key]
        })
      }
      seriesData.push({
        name: '同比增速',
        type: 'line',
        yAxisIndex: 1,
        data: tbzsList
      })
      var option = {
        title: {
          subtext: '万辆'
        },

        legend: {
          data: vehicleList.concat('同比增速')
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(datas) {
            let res = datas[0].name + '<br/>'
            let val
            const length = datas.length
            let i = 0
            for (; i < length; i++) {
              val = datas[i].value
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              res +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  datas[i].color +
                  ';"></span>' +
                  datas[i].seriesName +
                  '：' +
                  val +
                  '<br/>'
            }
            return res
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true }

          }
        },
        grid: {
          left: '100%',
          right: '100%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              inside: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            }
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            }
          }
        ],
        series: seriesData
      }
      myChart.clear()
      myChart.setOption(option)
    },

    fromData(str) {
      const index = str.indexOf('F')
      let s = ''
      if (index != -1) {
        s = str.slice(0, index)
      } else {
        s = str
      }
      return s
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 乘用车导出
    excelbtn() {
      if (getUserInfo()) {
        logRecords('预测', '乘用车月度预测', '3', 'tt_year_month_predict_cyc', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('预测')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              const self = this
              const obj = { leftTotalList: this.leftTotalList }
              exportPredictCyc(obj).then((res) => {
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '乘用车数据.xlsx')
                  }
                })
              })
            }
          }
        } else {
          const self = this
          const obj = { leftTotalList: this.leftTotalList }
          exportPredictCyc(obj).then((res) => {
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '乘用车数据.xlsx')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 商用车导出
    excelbtn2() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('预测')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              const self = this
              const obj = { leftTotalList: this.syctotal }
              exportPredictSyc(obj).then((res) => {
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '商用车数据.xlsx')
                  }
                })
              })
            }
          }
        } else {
          const self = this
          const obj = { leftTotalList: this.syctotal }
          exportPredictSyc(obj).then((res) => {
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '商用车数据.xlsx')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },

    // 计算数组的和
    counts(arr) {
      let count = 0
      if (arr.length > 0) {
        arr.forEach((item) => {
          count += item
        })
      }
      return count.toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
h3 span {
  font-weight: bold;
}

#cyZtChart,
#cychart {
  width: 100%;
  height: 500px;
}

#carChart, #suvChart, #mpvChart, #jcChart {
  width: 50%;
  height: 500px;
}

.g-content-bottom {
  margin-top: 20px;
}

.el-icon-info {
  margin-right: 2px;
}

//.search-form {
//  height: 30px;
//}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
