export default [
  {
    name: '国民经济',
    categoryList: [
      {
        name: '生产总值(GDP)',
        path: '/iframes/newData/fzhj_gmjj_gdp.html'
      },
      {
        name: '工业增加值',
        path: '/iframes/newData/fzhj_gmjj_gyzjz.html'
      },
      {
        name: '社会消费品零售总额',
        path: '/iframes/newData/fzhj_gmjj_shxfplsze.html'
      },
      {
        name: '消费者信心指数(CCI)',
        path: '/iframes/newData/fzhj_gmjj_cci.html'
      },
      {
        name: '进出口总额',
        path: '/iframes/newData/fzhj_gmjj_jckze.html'
      },
      {
        name: '外商直接投资(FDI)',
        path: '/iframes/newData/fzhj_gmjj_fdi.html'
      }
    ]
  },
  {
    name: '采购经理指数',
    categoryList: [
      {
        name: '制造业采购经理指数',
        path: '/iframes/newData/fzhj_cgjlzs_zzy.html'
      },
      {
        name: '非制造业经理采购指数',
        path: '/iframes/newData/fzhj_cgjlzs_fzzy.html'
      },
      {
        name: '综合PMI产出指数',
        path: '/iframes/newData/fzhj_cgjlzs_pmi.html'
      }
    ]
  },
  {
    name: '价格',
    categoryList: [
      {
        name: '居民消费价格指数(CPI)',
        path: '/iframes/newData/fzhj_jg_cpi.html'
      },
      {
        name: '商品零售价格指数',
        path: '/iframes/newData/fzhj_jg_splsjg.html'
      },
      {
        name: '工业生产者出厂价格指数(PPI)',
        path: '/iframes/newData/fzhj_jg_ppi.html'
      },
      {
        name: '工业生产者购进价格指数',
        path: '/iframes/newData/fzhj_jg_gysczgjjj.html'
      }
    ]
  },
  {
    name: '金融',
    categoryList: [
      {
        name: '货币供应量',
        path: '/iframes/newData/fzhj_jr_hbgyl.html'
      },
      {
        name: '存贷款基准利率',
        path: '/iframes/newData/fzhj_jr_cdkjzll.html'
      },
      {
        name: '人民币汇率',
        path: '/iframes/newData/fzhj_jr_rmbhl.html'
      },
      {
        name: '人民币贷款',
        path: '/iframes/newData/fzhj_jr_rmbdk.html'
      }
    ]
  },
  {
    name: '人口',
    categoryList: [
      {
        name: '按性别分人口数',
        path: '/iframes/newData/fzhj_rk_axbfrks.html'
      },
      {
        name: '按年龄分人口数',
        path: '/iframes/newData/fzhj_rk_anlfrks.html'
      },
      {
        name: '按城乡分人口数',
        path: '/iframes/newData/fzhj_rk_acxfrks.html'
      },
      {
        name: '人口抽样调查样本数据',
        path: '/iframes/newData/fzhj_rk_rkcydc.html'
      }
    ]
  },
  {
    name: '人民生活',
    categoryList: [
      {
        name: '城乡居民人均可支配收入',
        path: '/iframes/newData/fzhj_rmsh_rjgzpsr.html'
      },
      {
        name: '城乡居民人均消费支出',
        path: '/iframes/newData/fzhj_rmsh_rjxfzc.html'
      }
    ]
  },
  {
    name: '交通运输',
    categoryList: [
      {
        name: '物流景气指数(LPI)',
        path: '/iframes/newData/fzhj_jtys_lpi.html'
      },
      {
        name: '公路运输里程',
        path: '/iframes/newData/fzhj_jtys_glyslc.html'
      },
      {
        name: '货物运输量',
        path: '/iframes/newData/fzhj_jtys_hwysl.html'
      },
      {
        name: '货物周转量',
        path: '/iframes/newData/fzhj_jtys_hwzzl.html'
      },
      {
        name: '旅客运输量',
        path: '/iframes/newData/fzhj_jtys_lkysl.html'
      },
      {
        name: '旅客周转量',
        path: '/iframes/newData/fzhj_jtys_lkzzl.html'
      }
    ]
  },
  {
    name: '能源',
    categoryList: [
      {
        name: '原煤产量',
        path: '/iframes/newData/fzhj_ny_ymcl.html'
      },
      {
        name: '原油产量',
        path: '/iframes/newData/fzhj_ny_yycl.html'
      },
      {
        name: '天然气产量',
        path: '/iframes/newData/fzhj_ny_trqcl.html'
      },
      {
        name: '发电量和用电量',
        path: '/iframes/newData/fzhj_ny_fdlhydl.html'
      }
    ]
  }
]
