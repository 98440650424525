<template>
  <PageContainer>

    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips"> </VnotLogin>-->
    <template>
      <el-row style="margin-bottom:16px">
        <el-col :span="24">
          <listHead title="最新政策" url="assets/img/11153157zuln.png" href="/policy/dynamic/syc/newListPolicy"/>
          <ul class="newUl">
            <li v-for="item in newPolicyDate" :key="item.id">
              <div class="newUl-link" @click="toHref('/policy/dynamic/syc/contentNewPolicyDyn/'+item.id)">
                <template v-if="item.areas">
                  <div class="newUl-areas" v-if="item.areas == '1'">国家</div>
                  <div class="newUl-areas df" v-else>地方</div>
                </template>
                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ item.title }}</div>
                <div class="newUl-time">{{ item.publicDate }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row style="margin-bottom:16px">
        <el-col :span="24">
          <listHead title="政策报道" url="assets/img/11153157zuln.png" href="/policy/dynamic/syc/listReport"/>
          <ul class="newUl newUl-cloum">
            <li v-for="item in policyReportData" :key="item.id">
              <div class="newUl-link" @click="toHref('/policy/syc/dynamic/contentReport/'+item.id)">
                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ item.title }}</div>
                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {
  policyReport,
  newPolicy,
} from "@/api/policy.js";
import {getUserInfo,ydlTk} from "@/utils/auth.js";
import {fmtDate} from "@/utils/date.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {buryPoint} from "@/api/common";

export default {
  data() {
    return {
      showTips: [
        {
          title: "政策动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "政策库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "政策梳理",
          text: "专家梳理整合，真正读懂政策脉络",
        },
        {
          title: "政策大数据",
          text: "用数据深度理解政策结构和趋势",
        },
      ],
      defaultImg: require("@/assets/img/dnc-img.png"),
      loading: false,
      tableData: [],
      pagination: {},
      show: false,
      policyReportData: [],
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 12,
        },
        total: 0,
        currentPage: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
    };
  },
  computed: {
    newDate() {
      return fmtDate("YY年mm月", new Date());
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.init();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //初始化加载
    init() {
      this.policyReport({page: 1, pageSize: 12,sycFlag:'syc'});
      this.newPolicy();
    },
    // ydlTk(){
    //   this.$alert('<div style="font-size: 16px;margin-top: -15px">您的账号暂未正式开通此栏目，可申请<span style="font-weight: bold">免费试用/正式开通</span></div></br><div>电话：022-84379145/18846828756</div></br><div>邮箱：yuhuiying@catarc.ac.cn</div>', {
    //     confirmButtonText: '知道了',
    //     showClose:false,
    //   });
    //
    // },
    toHref(url) {
      let newUrl = this.$router.resolve(url);
      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //政策报道
    policyReport(params) {
      policyReport(params).then((res) => {
        if (res.ok) {
          this.policyReportData = res.data.list;
          this.policyReportData.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
        }
      });
    },
    //最新政策
    newPolicy() {
      newPolicy({
        page: 1,
        pageSize: 6,
        sycFlag:'syc',
        flag : 0,
      }).then((res) => {
        if (res.ok) {
          this.newPolicyDate = res.data.list;
          this.newPolicyDate.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
          this.newPolicyPage.total = res.data.count;
        }
      });
    },
    //字符串转数组
    strSplit(str) {
      return str.split(",");
    },
  },
};
</script>
<style lang="scss" scoped>
.dynamic-top {
  padding: 20px 32px;
  margin-bottom: 12px;
  background: #fff;

  .dynamic-top-original-box {
    display: flex;
    margin-top: 32px;
    margin-left: -26px;
    cursor: pointer;

    .dynamic-top-original {
      display: flex;
      flex: 1;
      margin-left: 26px;

      .dynamic-top-original-img {
        width: 177px;
        height: 99px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
        }
      }

      .dynamic-top-original-inner {
        flex: 1;
        margin-left: 6px;

        p {
          line-height: 21px;
          color: #000;
          font-weight: 500;
          height: 64px;
          overflow: hidden;
        }

        span {
          color: #828282;
        }
      }
    }
  }
}

.newUl-link {
  cursor: pointer;
}

</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
