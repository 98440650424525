<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:29:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-13 17:43:25
-->
<!--市场动态列表-->
<template>
  <PageContainer>
    <div class="search-box">
      <div class="logo-text"></div>
      <div class="btn-box">
        <div
          class="btn"
          @click="linkTo(item)"
          v-for="(item, index) in releaseColumnList"
          :key="index"
        >
          {{ item.dictLabel }}
          <span
            v-if="index != releaseColumnList.length - 1"
            style="margin-left:12px;color:rgba(255, 255, 255,0.6)"
            >|</span
          >
        </div>
      </div>
      <div class="search">
        <el-input placeholder="请输入您要搜索的内容..." v-model="input">
          <template slot="append"
            ><el-button
              slot="append"
              icon="el-icon-search"
              @click="linkTo()"
            ></el-button
          ></template>
        </el-input>
      </div>
    </div>
    <div class="massage-box">
      <information
        class="information"
        iconType="2"
        :data="HotList1"
        logRecordsColumn1="国际化"
        logRecordsColumn2="研究报告"
        logRecordsTableName="tt_international_report"
        linkUrl="/international/researchReport/details/"
      />
      <information
        class="information"
        iconType="3"
        :data="HotList2"
        logRecordsColumn1="国际化"
        logRecordsColumn2="研究报告"
        logRecordsTableName="tt_international_report"
        linkUrl="/international/researchReport/details/"
      />
      <information
        class="information"
        iconType="4"
        :data="HotList3"
        logRecordsColumn1="国际化"
        logRecordsColumn2="研究报告"
        logRecordsTableName="tt_international_report"
        linkUrl="/international/researchReport/details/"
      />
    </div>
  </PageContainer>
</template>
<script>
import information from "../common/information";
//字典管理
import { selectDictDataByType } from "@/api/dict.js";
import { getRank } from "@/api/data_new.js";
export default {
  components: {
    information,
  },
  data() {
    return {
      input: null,
      releaseColumnList: "",
      HotList1: [],
      HotList2: [],
      HotList3: [],
    };
  },
  created() {
    this.getRank();
    this.getDict("international_report_type", "releaseColumnList");
  },
  methods: {
    /**
     * @description: 获取三个榜单
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 15:16:37
     * @LastEditTime: Do not edit
     */

    getRank() {
      getRank().then((res) => {
        if (res.ok) {
          this.HotList1 = res.data[1].dates;
          this.HotList2 = res.data[0].dates;
          this.HotList3 = res.data[2].dates;
        }
      });
    },
    // 获取字典数据
    getDict(tyep, resDataName) {
      selectDictDataByType({ dictType: tyep }).then((res) => {
        if (res.ok) {
          this[resDataName] = res.data;
        }
      });
    },
    /**
     * @description: 点击跳转
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-06 09:41:27
     * @LastEditTime: Do not edit
     */

    linkTo(data) {
      this.$router.push({
        path: "/international/researchReport/list",
        query: {
          articleCategory: data ? data.dictValue : "",
          title: this.input,
        },
      });
    },
  },
};
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1366px) {
  .main-html {
    font-size: calc(16px * #{$BassZoom}) !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;

.search-box {
  width: 100%;
  // width: 69.5rem;
  height: 25rem;
  padding-top: 6.666rem;
  background: url(~@/assets/img/overview/researchReport-bg.png) no-repeat;
  background-size: 100% 100%;
  .logo-text,
  .btn-box,
  .search {
    margin-left: 14.166rem;
    /deep/ .el-input__inner {
      font-size: 16px;
      color: #757575;
      font-weight: 400;
    }
    /deep/input::-webkit-input-placeholder {
      /* placeholder颜色 */
      color: #ccc;
      /* placeholder字体大小 */
      font-size: 16px;
      font-weight: 400;
    }
  }
  .logo-text {
    width: 14.708rem;
    height: 3.416rem;
    background: url(~@/assets/img/overview/researchReport-bg-title.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .btn-box {
    margin-top: 1.333rem;
    // height: 28px;
    .btn {
      display: inline-block;
      height: 100%;
      padding: 0 0.4166rem;
      font-size: calc(18px * #{$BassZoom});
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: calc(500 * #{$BassZoom});
      color: #ffffff;
      // border-right: 1px solid #fff;
      cursor: pointer;
    }
    .btn:last-child {
      border-right: none;
    }
  }
  .search {
    margin-top: 2.9166rem;
    width: 29.166rem;
    /deep/ .el-input__inner {
      height: 2.833rem;
    }
    /deep/ .el-input-group__append {
      padding: 0 calc(1rem * #{$BassZoom});
      background-color: #148eff;
      border: none;
    }
    /deep/ .el-icon-search {
      font-size: 24px;
      color: #ffffff;
    }
  }
}
.massage-box {
  width: 100%;
  // width: 69.5rem;
  height: 32.916rem;
  padding: 2.5rem 5.833rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .information {
    width: 30%;
    // width: 16.25rem;
    height: 27.916rem;
    box-shadow: 0px 6px 16px 0px rgba(51, 51, 51, 0.16);
    border-radius: 4px 4px 4px 4px;
  }
}
</style>
