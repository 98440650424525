<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-17 15:46:46
-->
<template>
  <div
    :id="'salesVolumeEchart' + echartKey"
    style="width:100%"
    v-loading="loading"
  >
    <div
      ref="salesVolumeEchart"
      class="salesVolumeEchart"
      :style="`height:${height}px`"
    />
  </div>
</template>
<script>
import { toThousands } from "@/utils/number";
// 引入echarts
import * as echarts from "echarts";
// 引入dom监听器
export default {
  name: "SalesVolumeEchart",
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
    unit: {
      type: String,
      default: "辆",
    },
  },
  data() {
    return {
      myChart: null, // echartDom对象
      echartKey: Math.random(),
      // Eechart数据
      echartData: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    /**
     * @description: 打开页面加载
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-26 11:17:58
     * @LastEditTime: Do not edit
     */

    loadingOpen() {
      this.loading = true;
    },
    loadingClose() {
      this.loading = false;
    },
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.echartData = data;
      this.initEchart();
      this.initLister();
      this.loadingClose();
    },
    /**
     * @description: 初始化监听器
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:18
     * @LastEditTime: Do not edit
     */
    initLister() {
      const elementResizeDetectorMaker = require("element-resize-detector");
      // 监听元素变化
      const erd = elementResizeDetectorMaker();
      erd.listenTo(
        document.getElementById("salesVolumeEchart" + this.echartKey),
        (element) => {
          this.$nextTick(() => {
            // 使echarts尺寸重置
            this.myChart.resize();
          });
        }
      );
    },
    /**
     * @description: 初始化echart
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:08
     * @LastEditTime: Do not edit
     */
    initEchart(name) {
      let startValue = this.echartData.xAxisData.length - 12;
      let endValue = this.echartData.xAxisData.length - 1;
      startValue = startValue < 0 ? 0 : startValue;
      endValue = endValue < 0 ? 0 : endValue;
      this.myChart = echarts.init(this.$refs.salesVolumeEchart);
      const legendData = [];
      // let yMax = 0;
      this.echartData.seriesData.forEach((element) => {
      //   if (element.data) {
      //     yMax = Math.max(...element.data, yMax);
      //   }
        legendData.push(element.name);
      });
      this.echartData.seriesData.forEach((item) => {
        if (item.areaStyleColor) {
          item["areaStyle"] = {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: item.areaStyleColor[0],
              },
              {
                offset: 1,
                color: item.areaStyleColor[1] || item.areaStyleColor[0],
              },
            ]),
          };
        }
      });
      const option = {
        title: {
          text: name ? name : "",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            let html = "";
            for (let i = 0; i < params.length; i++) {
              const dataItem = params[i];
              html += `
                <div style='display: flex; justify-content: space-between;'>
                  <div>
                    ${dataItem.marker}
                    <span>${dataItem.seriesName}</span>
                  </div>
                  <span style='margin-left: 10px; font-weight: bold;'>${toThousands(
                    dataItem.value
                  )}${this.unit}</span>
                </div>`;
            }
            return (
              `<div style='margin-bottom: 4px;'>${params[0].axisValueLabel}</div>` +
              html
            );
          },
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: this.echartData.xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#C9CDD4", // 左边线的颜色
              width: 2, // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#86909C",
          },
        },
        yAxis: {
          type: "value",
          // max: yMax,
          // boundaryGap: [0, "100%"],
          name: this.unit,
          nameTextStyle: {
            color: "#86909C",
            align: "left",
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#C9CDD4", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E5E6EB",
            },
          },
          axisLabel: {
            color: "#86909C",
          },
        },
        dataZoom: [
          {
            type: "slider",
            startValue: startValue,
            endValue: endValue,
            zoomOnMouseWheel: false,
          },
          {
            type: "slider",
            zoomOnMouseWheel: false,
            startValue: startValue,
            endValue: endValue,
            dataBackground: {
              areaStyle: {
                color: "#c2d0ee",
              },
              lineStyle: {
                color: "#BBD0FC",
              },
            },
            selectedDataBackground: {
              areaStyle: {
                color: "#D3E0FF",
              },
            },
            // backgroundColor: "#C9CDD4", //组件的背景颜色
            // fillerColor: "red", //选中范围的填充颜色。
            // borderColor: "#ddd", //边框颜色。
          },
        ],
        grid: {
          right: 20,
          left: 0,
          // 为了让 y 轴的单位和图例水平对齐
          top: 34,
          containLabel: true,
        },
        legend: {
          data: legendData,
          right: 15,
        },
        series: this.echartData.seriesData,
      };
      this.myChart.setOption(option);
    },
    /**
     * @description: echart图片导出
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:30:11
     * @LastEditTime: Do not edit
     */

    exportSvg(name) {
      if (this.myChart) {
        // this.initEchart(name);
        let fileName = name ? name + ".png" : "图表.png";
        const base64 = this.myChart.getDataURL({
          type: "png",
          pixelRatio: 2,
          backgroundColor: "#FFFFFF",
        });
        this.downloadFileByBase64(base64, fileName);
      } else {
        this.$message.error("无法导出");
      }
      // this.initEchart();
    },
    /**
     * @description: 转Blob
     * @param {*} dataurl
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:36
     * @LastEditTime: Do not edit
     */

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    /**
     * @description: 下载文件
     * @param {*} url
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:47
     * @LastEditTime: Do not edit
     */

    downloadFile(url, name) {
      var a = document.createElement("a"); //新建一个a链接
      a.setAttribute("href", url); // a链接的url为图片的url
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    /**
     * @description: base64转化为文件
     * @param {*} base64
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:53
     * @LastEditTime: Do not edit
     */

    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64);
      var myUrl = URL.createObjectURL(myBlob); //创建图片的临时url
      this.downloadFile(myUrl, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.salesVolumeEchart {
  width: 100%;
  height: 100%;
}
</style>
