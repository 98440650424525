<template>

    <div :style="'height:' + height + ';width:' + width + ';' + (this.comUrl ? 'background:url(' + this.comUrl + ') no-repeat;background-size:cover' : '' )">
        <div class="title" style="background: linear-gradient(90.79deg, #1768D5 3.39%, rgba(13, 87, 188, 0) 390.23%);" :flex="flex">
           <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        height: {
            type: String,
            default: "64px",
        },
        width: {
            type: String,
            default: "100%",
        },
        url: {
            type: String,
            default: "",
        },
        flex: {
            type: String,
            default: "c",
        },
    },
    computed: {
        comUrl() {
            if (this.url) {
                return require("@/" + this.url);
            } else {
                return "";
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    &[flex="c"] {
        align-items: center;
    }
    &[flex="sc"] {
        align-items: center;
        justify-content: space-between;
    }
}
</style>