//前沿技术
import $http from '@/plugins/http.js'


//获取所有关键字
export function getKeyWords() {
    return $http.get('/technology/getKeyWords')
}
//根据id查询
export function queryById(params) {
    return $http.get('/technology/queryById', params)
}
//根据条件查询
export function queryByPage(params) {
    return $http.get('/technology/queryByPage',params)
}

