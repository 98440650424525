<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" isContentTwo="zcbd" />

    <template slot="right">
      <Vcontent-item title="相关政策推荐" type="list" :list="relationPolicy" @click="tohref" />
    </template>
  </PageContainer>
</template>
<script>
import { ttPolicyReport } from "@/api/policy.js";
export default {
    data() {
        return {
            data: {},
            props: {
                htmlContent: "content",
            },
            relationPolicy: [],
        };
    },
    watch: {
        $route(to, from) {
            let id = to.params.id;
            this.ttPolicyReport(id);
            // 对路由变化作出响应...
        },
    },
    created() {
        let id = this.$route.params.id;
        this.ttPolicyReport(id);
    },
    methods: {
        ttPolicyReport(params) {
            ttPolicyReport(params).then((res) => {
                if (res.ok) {
                    this.data = res.data;
                    if (res.data.relationPolicy) {
                        this.relationPolicy = res.data.relationPolicy.map(
                            (item) => {
                                item.publishDate = item.publishDate;
                                return item;
                            }
                        );
                    }else{
                      this.relationPolicy = []
                    }
                }
            });
        },
        tohref(item) {
            //政策主体推荐
            this.$router.push("/policy/dynamic/contentNewPolicy/" + item.id);
        },
    },
};
</script>
