<template>
    <div class="compList">
        <el-table cellspacing="110" :header-cell-style="{ background: '#e7eefe', color: '#000000' }"
            @sort-change="tableSort" :data="tableData" v-loading="loading" ref="newTable" style="width: 100%">
            <el-table-column sortable="custom" align="center" v-for="(column, index) in tableHead" :key="index"
                :prop="column.prop" :label="column.label">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    // components: {
    //     VnotLogin,
    // },
    props: {
        tableData: {
            type: Array,
            default: [],
        },
        tableHead: {
            type: Array,
            default: [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        tableSort(column) {
            let orderType = this.otherzh(column)
            this.$emit("custom-sort-change", orderType);
        },
        otherzh(column) {
            if (column.order == 'ascending') {
                return column.prop
            } else if (column.order == 'descending') {
                return column.prop + ' DESC'
            } else {
                return ''
            }
        }
    },
};
</script>

<style scoped lang="scss"></style>
