<template>
    <router-view v-if="$route.name == '产业布局详情'" />
    <PageContainer v-else>
        <template slot="actionNav">
            <div class="tabs">
                <actionTabs actionNavInformation="industry" />
            </div>
        </template>
        <div class="industry">
            <div class="topRadio">
                <el-radio-group @change="radioChange" v-model="industryType" size="small">
                    <el-radio-button label="半导体芯片设计"></el-radio-button>
                    <el-radio-button label="半导体芯片制造"></el-radio-button>
                    <el-radio-button label="电机电控"></el-radio-button>
                    <el-radio-button label="动力电池"></el-radio-button>
                    <el-radio-button label="燃料电池系统及电堆"></el-radio-button>
                    <el-radio-button label="雷达"></el-radio-button>
                    <el-radio-button label="汽车座椅"></el-radio-button>
                    <el-radio-button label="汽车热管理"></el-radio-button>
                    <el-radio-button label="智能座舱"></el-radio-button>
                    <el-radio-button label="自动驾驶"></el-radio-button>
                    <el-radio-button label="底盘系统-传动"></el-radio-button>
                    <el-radio-button label="底盘系统-悬架"></el-radio-button>
                    <el-radio-button label="内外饰"></el-radio-button>
                    <el-radio-button label="海外零部件"></el-radio-button>
                    <!--<el-radio-button label="国内零部件"></el-radio-button> -->
                </el-radio-group>
            </div>
            <worldMap v-if="industryType === '海外零部件'" />
            <chinaMapParts v-if="industryType === '国内零部件'" />
            <div class="top" v-if="industryType !== '海外零部件'&& industryType !== '国内零部件'" >
                <div class="mapTitle">
                    {{ mapdate }}国内{{ mapTitle }}
                    <p class="mapContent">
                        <img src="@/assets/img/exclamation.png" alt="">
                        {{ introduce }}
                    </p>
                </div>
                <china :operate="operate" :mapList="mapList" :chartTitle="chartTitle" :maxNum="maxNum"
                    @map-click="handleMapClick" />
            </div>
            <div class="bottom" ref="bottomElement" v-if="industryType !== '海外零部件'&& industryType !== '国内零部件'">
                <TableCard :isLoading="isLoading" :sourceData="tableList">
                    <template #cardSelect>
                        <div class="select-box">
                            <span class="checkTitle">筛选地区</span>
                            <SearchSelect placeholder="省份" v-model="form.provinceList" :options="provinceList"
                                size="small" />
                            <SearchSelect :isEmpty="cityList.length === 0" placeholder="城市" v-model="form.cityList"
                                :options="cityList" size="small" />
                            <span v-show="mainProductList.length" class="checkTitle seleleft">主营产品</span>
                            <!-- <SearchSelect v-show="mainProductList.length" placeholder="请选择主营产品"
                                v-model="form.mainProductList" :options="mainProductList" size="small" /> -->
                            <el-select v-show="mainProductList.length" v-model="form.mainProductList" collapse-tags
                                multiple placeholder="请选择主营产品" size="small" class="g-form-input" clearable filterable>
                                <el-option v-for="item in mainProductList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </template>
                    <!-- <template v-if="operate" #headPaginator>
                        <paginator ref="paginator" :total-items="pageData.total" :items-per-page="pageData.pages.pageSize"
                            @page-change="handlePageChange" />
                    </template> -->
                </TableCard>
                <div class="pages" v-if="operate">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageData.pages.pageNum" :page-sizes="[10, 20, 30, 40, 100]"
                        :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                        :total="pageData.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </PageContainer>
</template>

<script>
import {
    MapData,
    TableData,
    Selections,
} from "@/api/industry.js"
import china from "./charts.vue"
import TableCard from "./tableCard.vue";
import actionTabs from "../components/actionTabs.vue"
import SearchSelect from '@/components/searchSelect'
import Paginator from "@/components/paginator/index.vue";
import { buryPoint } from "@/api/common";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import worldMap from "./worldMap"
import chinaMapParts from "./chinaMapParts"

export default {
    components: {
        china,
        TableCard,
        Paginator,
        actionTabs,
        SearchSelect,
        worldMap,
        chinaMapParts
    },
    props: {},
    data() {
        return {
            isLoading: false,
            introduce: '布局图中覆盖中国主流半导体芯片设计企业，不包含代理商和分销商，涉及的企业产品按照产品类别进行列举，仅供参考。',
            operate: true,
            industryType: '半导体芯片设计',
            maxNum: '',
            mapList: [],
            cityList: [],
            provinceList: [],
            tempcityList: [],
            mainProductList: [],
            tableList: {

            },
            form: {
                cityList: [],
                provinceList: [],
                mainProductList: [],
            },
            pageData: {
                pages: {
                    pageNum: 1,
                    pageSize: 20,
                },
                total: 0,
            },
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
            mapTitle: '半导体芯片设计企业布局图',
            chartTitle: '导体芯片设计',
            mapdate: '',
        }
    },
    watch: {
        form: {
            deep: true,
            handler(newVal, oldVal) {
                if (getUserInfo()) {

                    if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                        let menuName = getUserInfo().menuName;
                        if (!menuName.includes('企业情报')) {
                            this.sqsyLogin();
                            this.insertBuryPoint();
                            return
                        } else {
                            if (getUserInfo().roleKey == 'trialrole') {
                                this.formLog.userId = getUserInfo().userId
                                buryPoint(this.formLog)
                                openSy()
                                return
                            }
                        }
                    }
                } else {
                    this.showLogin();
                    return
                }
                this.pageData.pages.pageNum = 1
                this.getTableData()
            },
        },
        'form.provinceList': {
            deep: true,
            handler(newVal, oldVal) {
                if(newVal.length === 0) {
                    this.cityList = this.tempcityList
                    return
                }
                this.form.cityList = []
                this.cityList = this.tempcityList.filter(city =>
                    newVal.includes(city.linkageValue)
                );
            },
        },
    },
    computed: {},
    created() {
        this.permissionSetting()
    },
    mounted() {
        this.getMapData()
        this.getSelections()
        this.getTableData()
    },
    methods: {
        handleSizeChange(val) {
            this.pageData.pages.pageSize = val;
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.pageData.pages.pageNum = val;
            this.getTableData()
        },
        radioChange() {
            if (getUserInfo()) {

                logRecords('企业情报', '产业布局', '3', 'tt_enterprise_analysis', '', '', '', '', '电机电控')
                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.sqsyLogin();
                        this.insertBuryPoint();
                        this.industryType = '电机电控'
                        return
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.formLog.userId = getUserInfo().userId
                            buryPoint(this.formLog)
                            this.industryType = '电机电控'
                            openSy()
                            return
                        } else {
                        }
                    }
                }
            } else {
                this.showLogin();
                this.industryType = '电机电控'
                return
            }
            let mapTitle = ''
            let chartTitle = ''
            let introduce = ''
            this.form.mainProductList = []
            if (this.industryType === '海外零部件'||this.industryType === '国内零部件') {
                return
            }
            switch (this.industryType) {
                case '电机电控':
                    introduce = '布局图中企业主要为第三方电驱系统集成、驱动电机及电控企业。';
                    mapTitle = '主要电动汽车驱动电机及电控企业分布图';
                    chartTitle = '电动汽车驱动电机及电控';
                    break;
                case '动力电池':
                    introduce = '布局图中企业包含主流电池系统集成、电池模组、电芯相关企业。';
                    mapTitle = '主要电动汽车动力锂电池企业分布图';
                    chartTitle = '电动汽车动力锂电池';
                    break;
                case '半导体芯片设计':
                    introduce = '布局图中覆盖中国主流半导体芯片设计企业，不包含代理商和分销商，涉及的企业产品按照产品类别进行列举，仅供参考。';
                    mapTitle = '半导体芯片设计企业布局图';
                    chartTitle = '导体芯片设计';
                    break;
                case '半导体芯片制造':
                    introduce = '布局图中覆盖中国主流半导体芯片制造企业，不包含代理商和分销商，涉及的企业产品按照产品类别进行列举，仅供参考。';
                    mapTitle = '半导体芯片制造企业布局图';
                    chartTitle = '半导体芯片制造';
                    break;
                case '燃料电池系统及电堆':
                    introduce = '布局图主要体现国内主要氢燃料电池系统及电堆模块生产企业地域分布情况。';
                    mapTitle = '主要燃料电池系统及电堆企业分布图';
                    chartTitle = '燃料电池系统及电堆';
                    break;
                case '雷达':
                    introduce = '布局图主要体现国内主要雷达生产企业分布情况，覆盖雷达企业包括激光雷达、毫米波雷达、超声波雷达主要企业。';
                    mapTitle = '主要雷达企业分布图';
                    chartTitle = '雷达';
                    break;
                case '汽车座椅':
                    introduce = '布局图主要体现国内主要汽车座椅模块生产企业地域分布情况。';
                    mapTitle = '主要汽车座椅企业分布图';
                    chartTitle = '汽车座椅';
                    break;
                case '汽车热管理':
                    introduce = '布局图主要覆盖汽车热管理系统包含发动机热管理、空调系统、电机热管理、电池热管理系统生产企业。';
                    mapTitle = '主要汽车热管理系统企业分布图';
                    chartTitle = '汽车热管理系统';
                    break;
                case '智能座舱':
                    introduce = '布局图主要体现智能座舱产品企业主要分布情况。';
                    mapTitle = '主要智能座舱系统生产企业分布图';
                    chartTitle = '智能座舱系统';
                    break;
                case '自动驾驶':
                    introduce = '布局图主要体现国内主要汽车自动驾驶（L3+）企业分布情况。';
                    mapTitle = '主流汽车自动驾驶(L3+)企业布局图';
                    chartTitle = '自动驾驶';
                    break;
                case '底盘系统-传动':
                    introduce = '布局图主要体现国内主要汽车底盘系统-传动企业分布情况。';
                    mapTitle = '主流汽车底盘系统-传动企业布局图';
                    chartTitle = '底盘系统-传动';
                    break;
                case '底盘系统-悬架':
                    introduce = '布局图主要体现国内主要汽车地盘系统-悬架企业分布情况。';
                    mapTitle = '主流汽车地盘系统-悬架企业布局图';
                    chartTitle = '底盘系统-悬架';
                    break;
                case '内外饰':
                    introduce = '布局图主要体现国内主要汽车内外饰企业分布情况。';
                    mapTitle = '主流汽车内外饰企业布局图';
                    chartTitle = '内外饰';
                    break;
            }
            this.introduce = introduce
            this.mapTitle = mapTitle
            this.chartTitle = chartTitle
            this.pageData.pages.pageNum = 1
            this.getMapData()
            this.getSelections()
            this.getTableData()
        },
        permissionSetting() {
            if (getUserInfo()) {

                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.operate = false
                    } else {
                        if (getUserInfo().roleKey == 'trialrole') {
                            this.operate = false
                        }
                    }
                }
            } else {
                this.operate = false
            }
        },
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
        getMapData() {
            MapData({ industryType: this.industryType }).then((res) => {
                this.mapList = res.data.mapData
                this.maxNum = res.data.maxNum
                this.mapdate = res.data.year
            })
        },
        getSelections() {
            Selections({ industryType: this.industryType }).then((res) => {
                this.tempcityList = res.data.cityList
                this.cityList = res.data.cityList
                this.provinceList = res.data.provinceList
                this.mainProductList = res.data.mainProductList
            })
        },
        getTableData() {
            this.isLoading = true
            let form = {
                ...this.pageData.pages,
                ...this.form,
                industryType: this.industryType
            }
            TableData(form).then((res) => {
                this.tableList.tableData = res.data.tableData
                this.tableList.tableHead = res.data.tableHead
                this.pageData.total = res.data.totalCount
                this.isLoading = false
            })
        },
        handlePageChange(val) {
            this.pageData.pages.pageNum = val;
            this.getTableData()
        },
        handleMapClick(params) {
            if (!this.operate) {
                return
            }
            if (params.value) {
                // this.$message({
                //     message: '请在下方列表查看该区域企业分布数据',
                //     type: 'success',
                //     center: true
                // })
                const offsetTop = this.$refs.bottomElement.offsetTop;
                window.scrollTo({ top: offsetTop, behavior: "smooth" });
                this.form.provinceList = []
                this.form.provinceList.push(params.name)
            } else {
                this.$message({
                    message: '该区域暂无企业分布数据',
                    type: 'error',
                    center: true
                })
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.industry {
    .top {
        background: #fff;
        margin: 20px 0 10px 0;

        .mapTitle {
            font-size: 24px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            margin: 0 30px 10px 30px;
            padding-top: 30px;
            border-bottom: 1px solid rgb(221, 221, 221);
        }

        .mapContent {
            display: flex;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            padding: 10px 0 15px 0;
            color: rgb(102, 102, 102);

            img {
                margin-right: 7px;
            }
        }
    }
}

::v-deep {
    .clearfix {
        align-items: center;
    }

    .el-radio-button {
        border: 0;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .el-radio-button__inner {
        border: 0;
        border-radius: 4px;
    }

    .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 4px;
    }

    .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 4px;
    }

    .el-radio-button__inner:hover {
        color: #606266;
    }

    .el-radio-button {
        margin-right: 12px;
    }

    .is-active .el-radio-button__inner {
        background: #3f76ea;
        box-shadow: none
    }

    .is-active :hover {
        color: #fff;
    }

    .el-tabs__nav-wrap::after {
        content: none;
    }

    .seleleft {
        padding-left: 30px;
    }
}

.pages {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background: #fff;
}
</style>