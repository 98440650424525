const state = {
    pc: 0,
    cplb:''
}

const mutations={
    SET_PC(state,n){
        state.pc = n
    },
    SET_CPLB(state,n){
        state.cplb = n
    }
}
const actions = {
    SET_PC({commit},n){
        commit('SET_PC',n)
    },
    SET_CPLB({commit},n){
        commit('SET_CPLB',n)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}