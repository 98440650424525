<template>
  <el-card class="navlist-common" style="width: 250px;">
    <h3>{{ title }}</h3>
    <el-timeline v-if="activities.length !== 0">
      <el-timeline-item
        v-for="(activity, index) in activities"
        :class="{ active: index === activeIndex }"
        :key="activity.value"
      >
        <a @click="scrollTo(activity.value, index)">{{ activity.label }}</a>
      </el-timeline-item>
    </el-timeline>
    <div v-else>
      <el-empty :image-size="80" description="暂无对比数据"></el-empty>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "NavList",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activities: [],
      activeIndex: 0,
      intervalId: null,
      isScrolling: false,
      initialUpdateInterval: 800, // 初始更新频率（.8秒）
      regularUpdateInterval: 5000 // 常规更新频率（5秒）
    };
  },
  mounted() {
    this.updateActivities();
    this.setInitialUpdateInterval();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.isScrolling) return;

      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      if (scrolledToBottom) {
        // 当滚动到底部时，选取最后一个活动
        this.activeIndex = this.activities.length - 1;
      } else {
        // 如果没有滚动到底部，则正常计算最接近顶部的活动
        let closestIndex = -1;
        let closestDistance = Infinity;

        this.activities.forEach((activity, index) => {
          const element = document.getElementById(activity.value);
          if (element) {
            const rect = element.getBoundingClientRect();
            const distance = rect.top;

            if (distance >= 0 && distance < closestDistance) {
              closestDistance = distance;
              closestIndex = index;
            }
          }
        });

        if (closestIndex !== -1) {
          this.activeIndex = closestIndex;
        }
      }
    },
    scrollTo(value, index) {
      this.isScrolling = true; // 设置滚动标志
      this.activeIndex = index;
      const element = document.getElementById(value);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = !index
          ? 0
          : elementPosition + window.scrollY - 118;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
      setTimeout(() => (this.isScrolling = false), 1000); // 重置标志
    },
    updateActivities() {
      let elements = document.getElementsByClassName("enterpriseDetail");
      let newActivities = Array.from(elements).map(el => ({
        value: el.id,
        label: el.dataset.name
      }));
      if (JSON.stringify(newActivities) !== JSON.stringify(this.activities)) {
        this.activities = newActivities;
      }
    },
    setInitialUpdateInterval() {
      let sTime = 0;
      this.intervalId = setInterval(() => {
        this.updateActivities();
        if (sTime > 7) {
          clearInterval(this.intervalId);
          this.setRegularUpdateInterval();
          sTime = 0;
        } else {
          sTime++;
        }
      }, this.initialUpdateInterval);
    },
    setRegularUpdateInterval() {
      let sTime = 0;
      this.intervalId = setInterval(() => {
        this.updateActivities();
        if (sTime > 7) {
          clearInterval(this.intervalId);
          sTime = 0;
        } else {
          sTime++;
        }
      }, this.regularUpdateInterval);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    "$route.query.checkedList"() {
      this.activeIndex = 0;
      this.setInitialUpdateInterval();
    }
  }
};
</script>

<style lang="scss">
.navlist-common {
  .active {
    .el-timeline-item__node {
      width: 9px;
      height: 9px;
      background-color: #3f76ea;
      box-shadow: 0 0 6px #3f76ea;
    }
    .el-timeline-item__node--normal {
      left: 2px;
      top: 3px;
    }
    .el-timeline-item__content a {
      color: #3f76ea;
    }
  }
  .el-timeline-item__wrapper {
    padding-left: 40px;
  }
  .el-timeline-item__content {
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    color: #999;
  }
  .el-timeline-item__node {
    width: 5px;
    height: 5px;
  }
  .el-timeline-item__tail {
    width: 1px;
    top: 7px;
    left: 6px;
  }
  .el-timeline-item__node--normal {
    left: 4px;
    top: 5px;
  }
}
</style>
<style lang="scss" scoped>
a {
  color: #333;
}
h3 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 23px;
}
</style>
