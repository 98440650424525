import { getValueByPath } from 'element-ui/src/utils/util';

export const getValueIndex = (arr = [], value, valueKey) => {
  const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]';
  if (!isObject) {
    return arr.indexOf(value);
  } else {
    let index = -1;
    arr.some((item, i) => {
      if (getValueByPath(item, valueKey) === getValueByPath(value, valueKey)) {
        index = i;
        return true;
      }
      return false;
    });
    return index;
  }
};
