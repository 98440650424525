//代码转义
export function htmlRestore(str) {
    var s = "";
    // str.length === 0
    if (!str) {
        return "";
    }
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/'/g, "\'");
    s = s.replace(/&quot;/g, "\"");
    s = s.replace(/&ldquo;/g, "\“");
    s = s.replace(/&rdquo;/g, "\”");
    s = s.replace(/&mdash;/g, "—");
    
    return s;
}

export function htmlString(str) {
    var s = "";
    // str.length === 0
    if (!str) {
        return "";
    }
    s = str.replace(/&/g, "&amp;"); 
    s = s.replace(/</g, "&lt;"); 
    s = s.replace(/>/g, "&gt;"); 
    s = s.replace(/ /g, "&nbsp;"); 
    s = s.replace(/\'/g, "&#39;"); 
    s = s.replace(/\"/g, "&quot;");
    s = s.replace(/\//g, "&frasl;");
    return s;
}