<template>
  <div class="links">
    <div class="max-container">
      <h4 class="title">友情链接</h4>

      <div class="items">
        <a class="item" :href="item.link" target="_blank" v-for="(item) in ttLinks" :key="item.id">
            <p class="speciaImg">
                <img :src="item.imgUrl" :alt="item.title" />
            </p>
            <span>
               {{ item.title }}
            </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ttLinksPage } from '@/api/Index.js'

export default {
  data() {
    return {
      ttLinks: [],
    };
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      ttLinksPage({ page: 1, pageSize: 8 }).then((res) => {
        if (res.ok) {
          this.ttLinks = res.data.list
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.links {
  padding-top: 36px;
  padding-bottom: 60px;
  .title {
    margin-bottom: 20px;
    color: #222;
    font-size: 20px;
    font-weight: bold;
  }

  .items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item {
        position: relative;
        flex-shrink: 0;
        width: calc(12.5% - 10px);
        min-height: 0;
        text-align: center;
        .speciaImg {
            display: flex;
            flex-direction: column;
            padding-top:40% ;
            width: 100%;
            color: #666;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
        span {
              color: #777;
              display: inline-block;
              margin-top: 10px;
        }
    }
}
</style>
