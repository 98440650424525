<template>
    <div class="us-page">
        <!-- <div style="width: 1200px; margin: 0 auto; padding: 20px 0">
      <h1 style="margin-bottom: 20px">关于信息网</h1>
      <div v-html="content.htmlContent"></div>
    </div> -->
        <div class="us-head">
            <div class="us-banner">
                <div class="us-banner-l">
                    <img src="@/assets/img/Groupus.png" alt="" width="131">
                    <h1 class="title">
                        中国汽车工业信息网<br />
                        一站式的汽车行业信息服务平台
                    </h1>
                    <p class="des">
                        中国汽车工业信息网是在原国家经贸委、原机械工业部等部门的指导和支持下
                        建立的汽车行业专业化网络信息服务平台。
                        服务内容涉及行业资讯、政策解读、标准动向、市场形势、前沿技术、
                        企业情报等领域，全面梳理汽车行业信息、数据资源，全方位展现行业发展动态及未来走势。
                    </p>
                    <button class="us-head-btn" @click="toView">联系我们<i style="padding-left:14px" class="el-icon-right"></i></button>
                </div>
                <div class="us-banner-r">
                    <img src="@/assets/img/Device - Macbook Air.png" alt="" width="1008">
                </div>
            </div>
        </div>
        <div class="us-center">
            <div class="us-number">
                <h1 class="title">1996</h1>
                <p class="tip">网站创建于1996年10月</p>
            </div>
            <div class="us-number">
                <h1 class="title">2000+</h1>
                <p class="tip">网员2000余家，覆盖政府、机构、整车、零部件</p>
            </div>
            <div class="us-number">
                <h1 class="title">10</h1>
                <p class="tip">10个核心栏目，涵盖产业方方面面</p>
            </div>
            <div class="us-number">
                <h1 class="title">1000000+</h1>
                <p class="tip">拥有数据超过100万条</p>
            </div>
            <div class="us-number">
                <h1 class="title">10000+</h1>
                <p class="tip">拥有原创分析解读等文章超过1万篇</p>
            </div>
        </div>
        <div class="us-container">
            <div class="us-container-t">
                <h1 class="title">强大的信息工具库和独家内容库</h1>
                <p class="tips">它首先是一个信息库与工具库，是一个查询和分析工具，支持全文内容（尤其是PPT）检索，可海量下载和多字段查询、收藏等。
                    其次，是一个“去伪存真”、“去粗取精”的行业趋势洞察库，可以看出行业政策、法规、市场、车型的演变过程，同时还有内部专家的独家解读。</p>
                <div class="tips-box">
                    <div class="tips-item">
                        <img src="@/assets/img/Document.png" alt="">
                        <h1 class="tips-title">全面信息</h1>
                        <p class="tips-content">行业政策、法规动态、特点专题、数据等的全面聚合，可全维度查询分析。</p>
                    </div>
                    <div class="tips-item">
                        <img src="@/assets/img/save-24px (1) 1.png" alt="">
                        <h1 class="tips-title">可靠数据</h1>
                        <p class="tips-content">所有数据、动态、资讯均经过精选和深加工，“去伪存真”、“去粗取精”</p>
                    </div>
                    <div class="tips-item">
                        <img src="@/assets/img/Dashboard.png" alt="">
                        <h1 class="tips-title">深入分析</h1>
                        <p class="tips-content">拥有众多原创行业趋势洞察和内部专家的独家解读</p>
                    </div>
                </div>
            </div>
            <ul class="us-article-box">
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">手机端小程序</h1>
                            <ul class="us-ul">
                                <li>推陈出新，信息网小程序应运而生，为提升信息网用户体验，带来全新增值服务，突出 “您身边的汽车行业专家” 定位，构建掌上汽车知识平台。</li>
                                <li>可视化的掌上数据查询体验、海量原创权威行业分析报告、便捷的企业信息查询能力。</li>
                            </ul>
                            <div class="miniProgram"> <h2 class="contentTitle">扫码体验小程序 <i class="el-icon-right"></i></h2>
                                <el-image
                                style="width: 84px; height: 84px"
                                :src="require('@/assets/img/xcx.jpg')"
                                :preview-src-list="[require('@/assets/img/xcx.jpg')]">
                                </el-image>
                            </div>
                            <!-- <div class="miniProgram"> <h2 class="contentTitle">扫码体验小程序 <i class="el-icon-right"></i></h2>
                                <el-popover placement="right" width="210" trigger="hover">
                                    <div class="text-center">
                                        <img src="@/assets/img/xcx.jpg" alt="小程序" width="100%"/>
                                        <div style="font-weight: bold;color: #0f0f0f">微信扫码体验手机端内容</div>
                                        <div class="text-left" style="font-weight: bold;color: #0f0f0f">1）使用账号密码登录</div>
                                        <div class="text-left" style="font-weight: bold;color: #0f0f0f">2）账号密码绑定手机号登录</div>
                                    </div>
                                    <span slot="reference" class="mini-program">
                                        <img class="img" src="@/assets/img/xcx.jpg" alt="小程序" width="100%"/>
                                    </span>
                                </el-popover>
                            </div> -->
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/miniProgram.jpg" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">国际化
                                <h2 class="contentTitle">高质量多元化的信息数据库和独家的内容资产</h2>
                            </h1>
                            <ul class="us-ul">
                                <li> <span class="internationalLi">国际资讯无时差：</span>快速获得来自世界各地的信息，覆盖行业动态、车企信息、产业链情况与前瞻技术布局</li>
                                <li> <span class="internationalLi">出海数据全知晓：</span>将企业最想了解的全球汽车产业基本情况进行集中收录，全方位打造一个企业了解世界的多维数据聚合窗口</li>
                                <li> <span class="internationalLi">政策法规紧跟随：</span>国际标准、行业标准体系及时更新，缺失登记，快速反馈，服务为先行业报告一键查：全面覆盖汽车行业研究报告、宏观策略报告、投行分析报告、蓝白皮书等，致力于提供便捷全面、实时及专业的信息共享服务</li>
                            </ul>
                            <router-link tag="button" to="/international/Information/index" class="us-article-btn">前往国际化栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/international.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">数据栏目</h1>
                            <ul class="us-ul">
                                <li>汇集发展环境数据库、行业数据库及产品数据库</li>
                                <li>按照不同行业对各个数据库进行分类，简洁清晰</li>
                                <li>各个数据库来自权威渠道，根据发布频次定期及时更新</li>
                                <li>数据展现涵盖统计报表、可视化图表、动态查询等多种形式</li>
                            </ul>
                            <router-link tag="button" to="/data/domesticData/index" class="us-article-btn">前往数据栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/sjus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">企业情报栏目</h1>
                            <ul class="us-ul">
                                <li>打造汽车企业信息数据库</li>
                                <li>涵盖企业动态、发展战略、产销量、产能布局、平台等</li>
                                <li>详实的企业对标的分析报告</li>
                            </ul>
                            <router-link tag="button" to="/workInformation/analysis/index" class="us-article-btn">前往企业情报栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/qyqbus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">专题分析栏目</h1>
                            <ul class="us-ul">
                                <li>对市场、产品、新能源、智能网联等行业热点深入分析</li>
                                <li>专题报告：对当前行业热点的全方位深度剖析</li>
                                <li>原创文章：第三方专业视角提出信息网的观点</li>
                            </ul>
                            <router-link tag="button" to="/thematicAnalysis/index" class="us-article-btn">前往专题分析栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/ztfxus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">前沿技术栏目</h1>
                            <ul class="us-ul">
                                <li>智能网联(环境感知、智能决策、高精地图）</li>
                                <li>新能源（大三电、小三电）</li>
                                <li>轻量化、节能减排、汽车安全</li>
                            </ul>
                            <router-link tag="button" to="/cuttingEdgeTechnology/index" class="us-article-btn">前往前沿技术栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/qyjsus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list" id="zczl">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">政策栏目</h1>
                            <ul class="us-ul">
                                <li>全面政策原文数据库、解读及影响分析</li>
                                <li>国家、地方、行业政策全覆盖</li>
                                <li>权威、专业、及时解读，并给出应对策略</li>
                                <li>打通原文、解读、报道信息流，实现双向关联互查</li>
                                <li>政策大数据可研判热点趋势</li>
                            </ul>
                            <router-link tag="button" to="/policy/dynamic/index" class="us-article-btn">前往政策栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/zcus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list" id="bzzl">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">标准栏目</h1>
                            <ul class="us-ul">
                                <li>国家标准、行业标准目录体系</li>
                                <li>权威、专业、全面、更新及时</li>
                                <li>标准解读、标准梳理、标准大数据可看清发展脉络</li>
                                <li>缺失登记，快速反馈，服务为先</li>
                            </ul>
                            <router-link tag="button" to="/standards/dynamic/index" class="us-article-btn">前往标准栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/bzsus.png" alt="" width="460">

                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">公告栏目</h1>
                            <ul class="us-ul">
                                <li>多维度精准搜索</li>
                                <li>权威、专业、全面、更新及时</li>
                                <li>缺失登记，快速反馈，服务为先</li>
                            </ul>
                            <router-link tag="button" to="/announcement/dynamic/index" class="us-article-btn">前往公告栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/ggus.png" alt="" width="460">
                    </div>
                </li>
                <li class="us-article-list">
                    <div class="us-article-list-l">
                        <div class="us-article-inner">
                            <h1 class="title">预测栏目</h1>
                            <ul class="us-ul">
                                <li>对汽车各细分市场和企业分车型销量细粒度预测</li>
                                <li>12个月短期滚动以及5年中长期全时段预测</li>
                                <li>对产品销量走势、全生命周期进行多维度对标分析</li>
                            </ul>
                            <router-link tag="button" to="/sale/salesForecast/index" class="us-article-btn">前往预测栏目</router-link>
                        </div>
                    </div>
                    <div class="us-article-list-r">
                        <img src="@/assets/img/ycus.png" alt="" width="460">
                    </div>
                </li>
            </ul>
            <div class="us-container-t us-container-b">
                <h1 class="title">定制化系统</h1>
                <p class="tips">可基于信息网的内容，辅助企业打造定制化的、专属的竞争情报系统，实现系统的本地部署、本地管理</p>
                <div class="tips-box">
                    <div class="tips-item">
                        <img src="@/assets/img/Document.png" alt="">
                        <h1 class="tips-title">专属定制</h1>
                        <p class="tips-content">可根据企业具体需求定制不同于信息网现有功能的专属功能</p>
                    </div>
                    <div class="tips-item">
                        <img src="@/assets/img/save-24px (1) 1-l.png" alt="">
                        <h1 class="tips-title">本地部署</h1>
                        <p class="tips-content">所有代码和数据可根据企业需求本地部署，保证安全性和自主性</p>
                    </div>
                    <div class="tips-item">
                        <img src="@/assets/img/Dashboard-l.png" alt="">
                        <h1 class="tips-title">本地管理</h1>
                        <p class="tips-content">企业可对所有数据进行本地管理，所有账号和权限系统亦为企业专属。</p>
                    </div>
                </div>
                <img src="@/assets/img/dzhxt.png" alt="" width="787" style="margin-top:60px" @load="imgOnload">
                <div class="us-contact" id="usContact">
                    <h1 class="us-contact-title">开通试用，请联系市场经理</h1>
                    <div class="d-line"></div>
                    <div class="us-contact-item-box">
                        <!-- <div class="us-contact-item">
                            <img src="@/assets/img/Group 983.png" alt="" width="64" height="64">
                            <div class="photo-info">
                                <h1 class="photo-info-title">董莹</h1>
                                <p class="photo-info-tip">电话：13302019303 022-84379229</p>
                                <p class="photo-info-tip">邮箱：dongying@catarc.ac.cn</p>
                                <div class="line"></div>
                                <p class="photo-info-tip">服务区域：上海、浙江、江苏、江西、福建、安徽、山东、河南、湖北、湖南、四川、重庆、云南、贵州、西藏、台湾</p>
                            </div>
                        </div> -->
                        <div class="us-contact-item" >
                            <img src="@/assets/img/Group 984.png" alt="" width="64" height="64">
                            <div class="photo-info">
                                <h1 class="photo-info-title">李文娟</h1>
                                <p class="photo-info-tip">电话：18322511031 022-84370000-分机1582</p>
                                <p class="photo-info-tip">邮箱：liwenjuan@catarc.ac.cn</p>
                                <!-- <div class="line"></div>
                                <p class="photo-info-tip">服务区域：黑龙江、吉林、辽宁、北京、天津、河北、山西、陕西、甘肃、内蒙古、宁夏、青海、新疆、广西、广东、海南、香港、澳门</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Vfooter />
    </div>
</template>
<script>
import { us } from "@/api/us.js";
import Vfooter from "@/components/footer.vue";
export default {
    components: {
      Vfooter,
    },
    data() {
        return {
            content: {},
        };
    },
    // updated() {
    //     let id = this.$route.query.id;
    //     if (id) {
    //         this.$nextTick(() => {
    //             document.querySelector("#" + id).scrollIntoView();
    //         });
    //     }
    // },
    mounted() {
        let id = this.$route.query.id;
        if (id) {
            this.$nextTick(() => {
                document.querySelector("#" + id).scrollIntoView();
            });
        }
    },
    methods: {
        imgOnload(){
            let id = this.$route.query.id;
            document.querySelector("#" + id)?.scrollIntoView();
        },
        us() {
            us().then((res) => {
                if (res.ok) {
                    this.content = res.data[0];
                }
            });
        },
        toView() {
            document.querySelector("#usContact").scrollIntoView();
        },
    },
};
</script>
<style lang="scss" scoped>
.miniProgram {
    display: flex;
    align-items: center;
   .img {
        height: 84px;
        width: 84px;
        margin-left: 30px;
        cursor: pointer;
    }
}
.us-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 676px;
    background: #0d57bc;
}
.us-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background: url(../../assets/img/Illustration.png) right top no-repeat;
    background-size: 820px;
    .us-banner-l {
        flex: 0 0 652px;
        margin-right: -51px;
        .title {
            font-weight: bold;
            font-size: 46px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #fff;
            margin-top: 14px;
        }
        .des {
            width: 532px;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: #fff;
            margin: 24px 0;
            text-align: justify;
        }
    }
    .us-banner-r {
        align-self: flex-end;
        margin-right: -214px;
    }
    .us-head-btn {
        width: 144px;
        height: 63px;
        font-size: 16px;
        background: #fff;
        border-radius: 10px;
        border: none;
        color: #0d57bc;
        cursor: pointer;
    }
}
.us-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 254px;
    background: #f4f5f6;
    .us-number {
        width: 217px;
        text-align: center;
        margin-right: 21px;
        .title {
            position: relative;
            font-weight: 500;
            font-size: 42px;
            line-height: 42px;
            text-align: center;
            color: #0d57bc;
            padding-bottom: 11px;
            margin-bottom: 10px;
            &::after {
                position: absolute;
                left: 50%;
                bottom: 0;
                margin-left: -25px;
                content: "";
                display: block;
                width: 52px;
                height: 1px;
                background: #0d57bc;
            }
        }
        .tip {
            font-size: 20px;
            height: 60px;
            line-height: 160%;
            text-align: center;
            letter-spacing: -0.03em;
            color: #717171;
        }
        &:last-child {
            margin-right: -21px;
        }
    }
}
.us-container {
    width: 100%;
}
.us-container-t {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 76px 0 72px;
    .title {
        font-weight: 500;
        font-size: 48px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #000;
    }
    .tips {
        width: 898px;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.03em;
        color: #333333;
        margin: 32px 0;
    }
    .tips-box {
        display: flex;
        .tips-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 380px;
            height: 304px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 24px;
            .tips-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
                text-align: center;
                letter-spacing: -0.03em;
                color: #000;
                margin: 20px 0 16px;
            }
            .tips-content {
                width: 298px;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: rgba(0, 0, 0, 0.8);
            }
            &:nth-child(2) {
                margin: 0 16px;
            }
        }
    }
    &.us-container-b {
        background: #fbfbfb;
        padding: 96px 0 60px;
        .tips {
            margin: 32px 0 36px;
        }
        .tips-box {
            display: flex;
            .tips-item {
                height: 232px;
            }
        }
    }
}
.us-article-box {
    .us-article-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .us-article-list-l,
        .us-article-list-r {
            width: 50%;
            max-width: 600px;
        }
        .us-article-list-l {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .us-article-inner {
                margin-left: calc(100% - 580px);
            }
        }
        .us-article-list-r {
            text-align: center;
        }
        &:nth-child(odd) {
            flex-direction: row-reverse;
            background: #fbfbfb;
        }
        &:nth-child(even) {
          .us-article-list-l {
              .us-article-inner {
                  margin-left: calc(100% - 550px);
              }
          }
        }
    }
}
.us-article-inner {
    min-width: 470px;
    padding: 100px 0;
    .title {
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        color: #000;
        margin-bottom: 26px;
        .contentTitle {
            font-size: 24px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            padding-top:3px;
        }
    }
    .us-ul {
        margin-bottom: 34px;
        .internationalLi {
            font-size: 20px;
            color: rgb(34, 34, 34);
            font-weight: bold;
        }
        li {
            font-size: 20px;
            line-height: 20px;
            color: #000;
            margin-bottom: 20px;
            padding-left: 30px;
            background: url(../../assets/img/Vectorus.png) center left no-repeat;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .us-article-btn {
        min-width: 199px;
        height: 56px;
        border: 1.4px solid #0d57bc;
        border-radius: 55px;
        background: transparent;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: #0d57bc;
        cursor: pointer;
    }
}
.us-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(13, 87, 188, 0.06);
    border-radius: 2px;
    .us-contact-title {
        margin: 46px 0 15px;
    }
    .d-line {
        width: 64px;
        height: 2px;
        background: #0d57bc;
        margin-bottom: 50px;
    }
    .us-contact-item-box {
        display: flex;
        justify-content: center;
        .us-contact-item {
            display: flex;
            width: 414px;
            // height: 243px;
            height: 140px;
            padding: 34px 24px 28px;
            margin-right: 40px;
            margin-bottom: 68px;
            background: #fff;
            border: 1px solid #f2f2f2;
            border-radius: 2px;
            .photo-info-tip {
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 0.04em;
                color: #333333;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.photo-info {
    margin-left: 27px;

    .photo-info-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.06em;
        color: #000;
    }
    .line {
        width: 100%;
        height: 1px;
        background: #f2f2f2;
        margin: 18px 0 20px;
    }
}
@media screen and (max-width: 1440px) {
    .us-banner {
        .us-banner-l {
            margin-right: -51px;
        }
        .us-banner-r {
            margin-right: -390px;
        }
    }
}
</style>
