/*
 * @Author: your name
 * @Date: 2021-07-23 18:07:36
 * @LastEditTime: 2021-07-23 18:07:47
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/store/modules/permission.js
 */
import { constantRoutes } from '@/router'
import Layout from '@/layout'

export function filterAsyncRoutes(routerlist, id) {
    // console.log('routerlist',routerlist)
    const router = []
    try {
        routerlist.forEach(e => {
            let e_new = {
                path: e.urlPath,
                name: e.name,
                meta: { title: e.name, id: e.id }
            }
            if (e.pId == id) {
                e_new.meta.parentName = e.parentName
                e_new.component = () => import(`@/views${e.frontPath}.vue`)
            } else {
                e_new.component = Layout
                if (e.children && e.children.length > 0) {
                    e_new = { ...e_new, path: e.urlPath}
                } else {
                    // e_new = { ...e_new, redirect: e.urlPath }
                    e_new = { ...e_new, path: e.urlPath }
                    e_new = {
                        ...e_new, children: [{
                            path: e.urlPath,
                            component: () => import(`@/views${e.frontPath}.vue`),
                            name: e.name,
                            meta: {
                               hidden:e.visible,
                                parentName: e.name,
                            },
                        }]
                    }
                }

            }

            if (e.children && e.children.length > 0) {
                const children = filterAsyncRoutes(e.children, e.id)
                // 保存权限
                e_new = { ...e_new, children: children }
            }


            if (e.icon && e.icon !== '' && e.icon !== '#') {
                e_new.meta.icon = e.icon
            }
            e_new.meta.hidden = e.visible
            router.push(e_new)
        })
    } catch (error) {
        console.error(error)
        return []
    }
    return router
}



// export function filterAsyncRoutess(routers, newRoute) {
//     const res = []

//     routers.forEach(e => {
//         let filterObj = newRoute.filter(item => item.name == e.name)
//         if (filterObj.some(item => item.name == e.name)) {
//             const tmp = {
//                 path: e.urlPath,
//                 name: e.name,
//                 meta: { title: e.name,hidden: e.visible}
//             }
//             if (e.children) {
//                 tmp.children = filterAsyncRoutess(e.children, filterObj[0].children)
//             }
//             if (e.icon && e.icon !== '' && e.icon !== '#') {
//                 tmp.meta.icon = e.icon
//             }
//             res.push(tmp)
//         }

//     })

//     return res
// }


const state = {
    routes: [],
    addRoutes: [],
    pRouterId: ''
}
const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    SET_ROUTER_ID: (state, id) => {
        state.pRouterId = id
    }
}

const actions = {
    generateRoutes({ commit }, asyncRoutes) {
        return new Promise(resolve => {
            let accessedRoutes = filterAsyncRoutes(asyncRoutes)

            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
        })
    },
    getRouter({ commit, state }, id) {
        // let obj = state.addRoutes.filter(item => item.name == name)
        commit('SET_ROUTER_ID', id)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}