
const state = {
    height: 0,
    pHeight: 0,
    heightL: 0,
    flag: 0,
    scrollFlag: false
}

const mutations = {
    getPheight(state, n) {
        state.pHeight = n
    },
    getHeiht(state, n) {
        state.height = n
    },
    getheightL(state, n) {
        state.heightL = n
    }
}

const actions = {
    setPosition({ commit, state }, n) {
        let newHeight = n + state.pHeight
        if (state.scrollFlag) {
            if (newHeight > state.pHeight) {
                if (state.heightL > state.pHeight && state.heightL > state.height && state.height < state.pHeight) {
                    state.flag = 3
                } else if (state.heightL > state.pHeight && state.heightL > state.height && state.height > state.pHeight) {
                    state.flag = 1
                } else {
                    state.flag = 2
                }
            }else{
                state.flag = 2
            }

        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}