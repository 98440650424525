<template>
  <router-view v-if="$route.name == '标准解读内容页'"/>
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="标准解读" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>

    <div style="overflow: hidden" class="box_box">
      <div style="float: left" flex="c">
        <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
          <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">
            {{ item.dictLabel }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div style="float: right">
        <el-input placeholder="搜索名称" size="small" v-model="title" @keyup.enter.native="search">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search" style="cursor: pointer;"></i>
        </el-input>
      </div>
    </div>

    <Vlist :list="standardUnscrambleData" :page="standardUnscramblePage" :pageData="standardUnscramblePage"
           @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import {standardUnscramble} from "@/api/standards.js";
import Vlist from "@/components/list";
import {selectDictDataByType} from "@/api/dict.js";
import {getUserInfo, ydlTk} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
    Vlist,
  },
  data() {
    return {
      bgurl: require("@/assets/img/bg-1.jpg"),
      standardUnscrambleData: [],
      standardUnscramblePage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreasData: [],
      belongAreas: [],
      title: "",
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "标准",
        userId: ""
      },
    };
  },
  created() {
    this.standardUnscramble();
    this.selectDictDataByType();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    standardUnscramble() {
      if (!getUserInfo() && this.standardUnscramblePage.pages.page > 1) {
        this.showLogin();
      } else {
        let form = Object.assign({}, this.standardUnscramblePage.pages, {
          title: this.title,
          belongAreas: this.belongAreas,
        });
        form.belongAreas = form.belongAreas.join(",");
        standardUnscramble(form).then((res) => {
          if (res.ok) {
            this.standardUnscrambleData = res.data.list;
            this.standardUnscramblePage.total = res.data.count;
          }
        });
      }
    },
    handleSizeChange(val) {
      this.standardUnscramblePage.pages.pageSize = val;
      this.standardUnscramble();
    },
    handleCurrentChange(val) {
      this.standardUnscramblePage.pages.page = val;
      this.standardUnscramble();
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(item) {
      let newUrl = this.$router.resolve(
          "/standards/dynamic/contentUnscramble/" + item.id
      );
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('标准')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({
        dictType: "standard_hot_field",
      }).then((res) => {
        if (res.ok) {
          this.belongAreasData = res.data;
        }
      });
    },
    //切换
    handleChange(e) {
      if (getUserInfo()) {
        this.belongAreas = e;
        this.standardUnscramble();
      } else {
        this.showLogin();
      }
    },
    search() {
      this.standardUnscramblePage.pages.page = 1;
      this.standardUnscramble();
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
