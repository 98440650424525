<template>
  <div
    :class="['inside-drag-block', block.field.noClose ? 'no-close' : '', block.isSorting ? 'is-sorting' : '']"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <span>{{ block.field.name }}</span>
    <img
      v-show="!block.field.noClose"
      ref="closeRef"
      :src="require('@/assets/img/newData/remove.png')"
      alt=""
      @click.stop.prevent="handleClickRemove"
    >
  </div>
</template>

<script>
export default {
  name: 'InsideDragBlock',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isMouseDown: false
      // mousedownPosition: [0, 0]
    }
  },
  methods: {
    handleMouseDown(e) {
      // 如果点击的是 关闭图标
      if (e.target === this.$refs.closeRef) {
        // 则跳过该方法，让事件捕获到 关闭图标 dom 的 click 事件上
        return
      }
      this.isMouseDown = true
      // this.$set(this, 'mousedownPosition', [e.offsetX, e.offsetY])
      document.removeEventListener('mouseup', this.handleMouseUp)
      document.removeEventListener('mousemove', this.handleDragging)
      document.addEventListener('mouseup', this.handleMouseUp)
      document.addEventListener('mousemove', this.handleDragging)
      // 内部块 mousedown 时，立即触发拖动
      this.handleDragging(e)
    },
    handleMouseUp(e) {
      if (!this.isMouseDown) {
        return
      }
      this.isMouseDown = false
      document.removeEventListener('mouseup', this.handleMouseUp)
      document.removeEventListener('mousemove', this.handleDragging)
      // // 如果鼠标移动了
      // if (
      //   e.offsetX !== this.mousedownPosition[0] ||
      //     e.offsetY !== this.mousedownPosition[1]
      // ) {
      this.$emit('dragEnd', {
        mousePosition: [e.pageX, e.pageY]
      })
      // }
    },
    handleDragging(e) {
      if (!this.isMouseDown) {
        return
      }
      this.$emit('dragging', {
        mousePosition: [e.pageX, e.pageY],
        draggingPosition: [e.pageX - this.$el.offsetWidth / 2, e.pageY - this.$el.offsetHeight / 2]
      })
    },
    // 点击删除按钮
    handleClickRemove() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.inside-drag-block {
  position: absolute;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #EFF7FF;
  border: 1px solid rgba(52,145,250,0.3);
  cursor: all-scroll;
  transition-property: transform,  color, background;
  transition-duration: 0.3s;
  user-select: none;
  -webkit-user-select: none;
  font-size: 14px;
  font-weight: bold;
  color: #333333;

  &.is-sorting {
    background-color: #0D57BC !important;
    color: #fff !important;
  }

  &.no-close {
    background-color: #fff;
  }
}

img {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
