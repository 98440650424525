<template>
  <div class="item-title-box">
    <h1>{{ title }}</h1>
    <More :toHref="toHref" @click="toclick"/>
  </div>
</template>
<script>
export default {
  name:"ItemTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    toHref: {
      type: String,
      default: "",
    },
  },
  methods:{
    toclick(){
      this.$emit("click")
    }
  }
};
</script>
<style lang="scss" scoped>
.item-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    margin-bottom: 0;
  }
}
</style>