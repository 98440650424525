<template>
  <PageContainer breadcrumb>
    <div class="bigbox">
      <el-form ref="form" :model="form" :inline="true" class="demo-form-inline" label-width="120px">
        <el-form-item prop="cycEnterpriseGroup">
          <span class="checkTitle">所属企业</span>
          <searchSelect placeholder="选择乘用车企业集团" v-model="form.cycEnterpriseGroup" :options="cycEnterpriseGroupData"
            size="small" />
        </el-form-item>
        <el-form-item prop="cycEnterprise">
          <searchSelect placeholder="选择乘用车企业" v-model="form.cycEnterprise" :options="cycEnterpriseData" size="small" />
        </el-form-item>
        <el-form-item prop="sycEnterprise">
          <searchSelect placeholder="选择商用车企业" v-model="form.sycEnterprise" :options="sycEnterpriseData" size="small" />
        </el-form-item>
        <el-form-item prop="lbjEnterprise">
          <searchSelect placeholder="选择零部件企业" v-model="form.lbjEnterprise" :options="lbjEnterpriseData" size="small" />
        </el-form-item>
      </el-form>

      <div class="formFoot">
        <div class="rest-set">
          <el-button size="mini" class="rest" @click="resetForm()">
            <i class="el-icon-refresh-right"></i> 重置筛选
          </el-button>
        </div>
        <div class="search-btn-box">
          <el-input v-model="title" size="medium" clearable prefix-icon="el-icon-search" placeholder="搜索关键词"
            @keyup.enter.native="search">
            <template slot="append" class="search-btn">
              <span @click="search">搜索</span>
            </template>
          </el-input>
        </div>
      </div>
    </div>

    <searchList flag="0" listLabel v-loading="loading" class="list-container" :showFire="showFire" noshowDefImg
      tableCreateTimet :table-data="tableData" :page-data="pageData" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" @toHref="toHref"></searchList>
  </PageContainer>
</template>
<script>
import Vlist from "@/components/list";
import { logRecords } from "@/utils/auth";
import searchList from "@/components/searchList.vue";
import searchSelect from "@/components/IndexSelect";
import { IndustryEnterprise } from "@/api/Index.js";
import { getEnterpriseDynamicList } from "@/api/enterpriseAnalysis.js";
export default {
  components: {
    Vlist,
    searchList,
    searchSelect
  },
  props: {
    titles: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      loading: false,
      showFire: false,
      tableData: [],
      cycEnterpriseData: [],
      sycEnterpriseData: [],
      lbjEnterpriseData: [],
      cycEnterpriseGroupData: [],
      title: "",
      pageData: {
        pages: {
          pageNum: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      form: {
        cycEnterpriseGroup: [],
        cycEnterprise: [],
        sycEnterprise: [],
        lbjEnterprise: []
      }
    };
  },
  mounted() {
    this.query = this.$route.query;
    this.title = this.$route.query.title ? this.$route.query.title : "";
    this.getselectDictDataByType();
  },
  watch: {
    form: {
      deep: true,
      handler(newVal, oldVal) {
        this.ttIndustryInformationPage();
      }
    }
  },
  methods: {
    async getselectDictDataByType() {
      const res = await IndustryEnterprise();
      this.cycEnterpriseGroupData = this.addLabelAndValue(res.data.zc);
      this.cycEnterpriseData = this.addLabelAndValue(res.data.cyc);
      this.sycEnterpriseData = this.addLabelAndValue(res.data.syc);
      this.lbjEnterpriseData = this.addLabelAndValue(res.data.lbj);
      if (this.$route.query.enterpriseType) {
        const type = this.$route.query.enterpriseType
        const name = this.$route.query.enterpriseName
        if (type == "乘用车企业") {
          this.form.cycEnterprise.push(name);
        } else if (type == "乘用车企业集团") {
          this.form.cycEnterpriseGroup.push(name);
        } else if (type == "商用车企业 ") {
          this.form.sycEnterprise.push(name);
        } else if (type == "零部件企业") {
          this.form.lbjEnterprise.push(name);
        }
      } else {
        this.ttIndustryInformationPage();
      }
    },


    // 添加value,label
    addLabelAndValue(data) {
      return data.map(item => {
        return {
          ...item,
          label: item.dictLabel,
          value: item.dictLabel
        };
      });
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    handleSizeChange(v) {
      this.pageData.pages.pageSize = v;
      this.ttIndustryInformationPage();
    },
    handleCurrentChange(v) {
      this.pageData.pages.pageNum = v;
      this.ttIndustryInformationPage();
    },
    // 行业资讯
    ttIndustryInformationPage() {
      const cycEnterprise = this.getDictLabels(this.cycEnterpriseData, this.form.cycEnterprise);
      const sycEnterprise = this.getDictLabels(this.sycEnterpriseData, this.form.sycEnterprise);
      const lbjEnterprise = this.getDictLabels(this.lbjEnterpriseData, this.form.lbjEnterprise);
      const cycEnterpriseGroup = this.getDictLabels(this.cycEnterpriseGroupData, this.form.cycEnterpriseGroup);

      const mergedArray = cycEnterprise.concat(sycEnterprise, lbjEnterprise, cycEnterpriseGroup);
      const enterpriseId = mergedArray.join(',');
      const form = {
        ...this.pageData.pages,
        searchValue: this.title,
        enterpriseId: enterpriseId
      }
      this.loading = true;
      if (this.title.length === 0 && enterpriseId.length === 0) {
        this.showFire = true
      } else {
        this.showFire = false
      }
      getEnterpriseDynamicList(form).then(res => {
        if (res.ok) {
          this.tableData = res.data.itemList;
          this.pageData.total = res.data.totalCount;
        }
        this.loading = false;
      });
    },
    getDictLabels(data, searchList) {
      return data
        .filter(item => searchList.includes(item.dictLabel))
        .map(item => item.dictValue);
    },
    // 搜索
    search() {
      logRecords(
        "企业情报",
        "企业动态",
        "4",
        "tt_industry_information",
        "",
        "",
        "",
        this.title
      );
      this.ttIndustryInformationPage();
    },
    resetForm() {
      this.form = {
        cycEnterpriseGroup: [],
        cycEnterprise: [],
        sycEnterprise: [],
        lbjEnterprise: []
      };
    },
    toHref(url, item) {
      let to = "";
      to = "/workInformation/enterpriseInfo/entrepriseNews/enterDetail/" + item.id;
      logRecords(
        "企业情报",
        "企业动态",
        "1",
        "tt_industry_information",
        item.id,
        "",
        item.title,
        ""
      );
      const newUrl = this.$router.resolve({
        path: to
      });
      window.open(newUrl.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      padding: 0 24px;
      color: #666;
      height: 48px;
      line-height: 48px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #3f76ea;
        font-weight: bold;
      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
  }
}

.bigbox {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 22px 20px;

  ::v-deep .el-select .el-input__inner::placeholder {
    color: rgb(51, 51, 51);
  }

  .flexC {
    margin-bottom: 20px;
  }

  .checkTitle {
    width: 60px;
    margin-right: 16px;
    display: inline-block;
    color: rgb(153, 153, 153);
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 4px;
    }

    .el-checkbox-button {
      margin-right: 10px;

      .el-checkbox-button__inner {
        padding: 7px 13px;
        min-width: 58px;
        color: #333;
        font-size: 14px;
        font-weight: normal;
        border-radius: 22px;
        border: 0;

        span {
          margin-top: 1px;
          margin-right: -4px;
        }
      }
    }

    .el-checkbox-button .el-checkbox-button__inner:hover {
      color: #3f76ea;
    }

    .is-checked .el-checkbox-button__inner {
      background: #3f76ea;
      color: #fff !important;
    }

    .el-form--inline .el-form-item__content {
      line-height: normal;
    }
  }
}

.formFoot {
  padding-top: 19px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e6eb;

  .rest-set {
    .el-button {
      color: rgb(51, 51, 51);
      font-weight: normal;
      border-radius: 4px;
    }

    .el-button:hover {
      border: 1px solid #dcdfe6;
      background: none;
    }

    .el-icon-setting:before {
      font-size: 13px;
    }

    .el-icon-refresh-right {
      font-size: 13px;
    }
  }

  .search-btn-box {
    display: flex;
    position: relative;

    ::v-deep .el-input__prefix {
      font-size: 16px;
    }

    //margin-top: 9px;
    ::v-deep {
      .el-input__inner {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 0;
      }

      .el-input__inner {
        background: #f8f8f8;
        border-right: 1px solid #f3f5f7;
      }

      .el-input-group__append {
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        background-color: #3f76ea;
        border-color: #3f76ea;
        font-weight: normal;
        cursor: pointer;

        .el-button {
          margin-left: -16px;
          margin-right: -16px;
          padding-left: 16px;
          padding-right: 16px;
          border: none;
        }
      }

      .el-icon-search:before {
        color: #aaaaaa;
        font-weight: 600;
      }

      .el-icon-circle-close:before {
        color: #aaaaaa;
        font-weight: 600;
      }

      .el-input-group__prepend {
        position: relative;
        border: 0;
        color: rgb(51, 51, 51);
        font-size: 14px;
      }

      .el-input-group__prepend:hover {
        border: 0;
        background: #fff;
      }

      .el-input-group__prepend::after {
        content: "";
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
        width: 1px;
        background-color: #f3f5f7;
      }
    }

    .el-input-group {
      width: 470px;
    }
  }
}

.list-container {
  position: relative;
  z-index: 1;
}
</style>
