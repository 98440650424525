<template>
  <div>

    <div class="search-form">
      <div style="position: absolute;right: 0;top: 10px;">
        <el-tooltip class="item" effect="dark" :content="remark" placement="top-start">
          <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>
        </el-tooltip>
        <div @click="exports()" v-has-role="'freeusers'" style="display: inline-block;">
          <JsonExcel :data="chartData" v-if="show && !showP " :fields="json_fields" class="excelBtn">导出</JsonExcel>
          <div v-else class="excelBtn">导出
          </div>
        </div>
      </div>
    </div>

    <template v-if="show && !showP">
      <div id="monthChart"></div>
    </template>
    <div v-else>
      <img style="width:100%" src="@/assets/img/qyxlyc.jpg" alt=""/>
      <center style="height: 1px;">
        <div
            style="position:relative;width:300px;height: 94px;bottom:300px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
            v-if="!show">

          <div style="padding-top: 20px">
            <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
          </div>

          <div style="margin: 10px">
            没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

          </div>
        </div>
        <div
            style="position:relative;width:320px;height:94px;bottom:300px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
            v-else>
          <div style="padding-top: 20px;padding-left: 22px;">
            <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
          </div>
          <div style="padding: 10px 20px;text-align: center">
            <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
          </div>
        </div>
      </center>

    </div>
  </div>
</template>
<script>
import {getGraphData, getRemark} from "@/api/sale.js";
import * as echarts from "echarts";
import JsonExcel from "vue-json-excel";
import {getUserInfo, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      chartData: [],
      json_fields: {
        企业: "shortName",
        已完成销量: {
          field: "finishedSales",
          callback: (value) => {
            return `${value}万辆`;
          },
        },
        全年预测销量: {
          field: "forecastSales",
          callback: (value) => {
            return `${value}万辆`;
          },
        },
      },
      remark: "",
      time: "",
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
    };
  },
  mounted() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        }else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showP = true;
          }
        }
      }
      this.show = true;


    } else {
      this.show = false;
    }
    this.getGraphData();
    this.getRemark();
  },

  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    exports() {

      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole"  || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          }else{
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            }
          }

        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    initChart(data) {
      var myChart = echarts.init(document.getElementById("monthChart"));
      let xData = data.map((item) => item.shortName);
      let finishedSales = data.map((item) => item.finishedSales);
      let forecastSales = data.map((item) => item.forecastSales - item.finishedSales);

      var option = {
        color: ["#2DC6C8", "#B6A2DD"],
        title: {
          subtext: "万辆",
        },
        legend: {
          data: ['已完成销量', '全年预测销量'],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (datas) {
            var res = datas[0].name + "<br/>",
                val,
                cas;
            for (
                var i = 0, length = datas.length;
                i < length;
                i++
            ) {
              if (datas[i].seriesName == "已完成销量") {

                cas = datas[i].value;
                val =
                    datas[i].marker +
                    "已完成销量" +
                    datas[i].value +
                    "万辆" +
                    "<br>";
                res += val;
              }
              if (datas[i].seriesName == "全年预测销量") {
                cas = cas + datas[i].value;
                val =
                    datas[i].marker +
                    "全年预测销量" +
                    cas +
                    "万辆" +
                    "<br>";
                res += val;
              }
            }
            return res;
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLabel: {
              interval: 0,
              rotate: 40,
              margin: 8,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {color: "#7DABB0"}, // y轴坐标轴颜色
            },
          },
        ],
        series: [
          {
            name: "已完成销量",
            type: "bar",
            stack: "Search Engine",
            data: finishedSales,
          },
          {
            name: "全年预测销量",
            type: "bar",
            stack: "Search Engine",
            data: forecastSales,
          },
        ],
      };
      myChart.clear();
      myChart.setOption(option, true);
    },
    getGraphData() {
      getGraphData().then((res) => {
        if (res.ok) {
          this.chartData = res.data;
          if (this.show) {
            this.initChart(res.data);

          }
        }
      });
    },
    getRemark() {
      getRemark().then((res) => {
        this.remark = '企业销量预测采取专家预测法，重点参考了各企业年度销量目标，新产品推出力度等关键指标，仅供参考';
        this.time = res.data.time;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#monthChart {
  width: 100%;
  height: 500px;
}

//.excelBtn {
//  padding: 7px 20px;
//  display: inline;
//}

.search-form {
  height: 40px;
}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
