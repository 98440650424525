<template>
  <div
    class="enterpriseDetail"
    id="capacity"
    v-if="isShowCapacity"
    data-name="企业产能"
  >
    <TableCard
      :isLoading="isLoading"
      cardTitle="企业产能"
      :sourceData="sourceData"
      :maxCapacity="maxCapacity"
      :column-width="columnWidth"
      unitText="单位: 万辆"
    >
      <template #cardSelect>
        <SearchSelect
          placeholder="省份"
          v-model="params.provinceList"
          :options="provinceSelectionList"
          @input="handleProvinceChange"
          size="small"
        />
        <SearchSelect
          placeholder="城市"
          v-model="params.cityList"
          :options="filteredCities"
          @input="changeListQuery"
          size="small"
        >
        </SearchSelect>
        <SearchSelect
          placeholder="类型"
          v-model="params.factoryTypeList"
          :options="typeSelectionList"
          @input="changeListQuery"
          size="small"
        />
      </template>
      <template #tableExport>
        <el-button
          type="primary"
          round
          size="small"
          :loading="exportState"
          @click="exportFile"
          >导出</el-button
        >
      </template>
      <template #tablePaginator>
        <paginator
          ref="paginator"
          :total-items="totalCount"
          :items-per-page="10"
          @page-change="handlePageChange"
        />
      </template>
    </TableCard>
  </div>
</template>

<script>
import {
  getProductionCapacitySelections,
  getProductionCapacity,
  postExportProductionCapacity
} from "@/api/enterpriseAnalysis.js";

import { buryPoint } from "@/api/common";
import { fileDownload } from "@/utils/download.js";
import { getUserInfo, logRecords, openSy } from "@/utils/auth.js";

import Paginator from "@/components/paginator/index.vue";
import TableCard from "@/components/tableCard.vue";

import SearchSelect from "@/components/searchSelect";
export default {
  name: "Capacity",
  components: {
    Paginator,
    TableCard,
    SearchSelect
  },
  data() {
    return {
      exportState: false,
      params: {
        pageSize: 10,
        pageNum: 1,
        enterpriseId: this.$route.params.id,
        factoryTypeList: [],
        provinceList: [],
        cityList: []
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
      isLoading: true,
      totalCount: 0,
      isShowCapacity: false,
      maxCapacity: 0, // 存储最大产能值
      sourceData: {},
      // 省份和城市的选择列表
      provinceSelectionList: [],
      citySelectionList: [],
      typeSelectionList: []
    };
  },

  created() {
    const enterpriseSelections = {
      enterpriseId: this.$route.params.id
    };
    this.getProductionCapacitySelections(enterpriseSelections);
    this.getProductionCapacity(this.params, "isShowCapacity");
  },

  methods: {
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    exportFile() {
      if (getUserInfo()) {
        logRecords("企业情报","企业信息" , "3", "", "", "", "", "企业产能", "导出");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          this.exportState = true;
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.exportState = false;
              this.insertBuryPoint();
              openSy();
            } else {
              postExportProductionCapacity(this.params).then(res => {
                this.exportState = false;
                fileDownload(res, "企业产能.xlsx");
              });
            }
          }
        } else {
          postExportProductionCapacity(this.params).then(res => {
            this.exportState = false;
            fileDownload(res, "企业产能.xlsx");
          });
        }
      } else {
        this.showLogin();
      }
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.getProductionCapacity(this.params);
    },
    changeListQuery() {
      this.$refs.paginator.resetNum();
      this.params.pageNum = 1;
      this.getProductionCapacity(this.params);
    },
    columnWidth(prop) {
      let returnWidth = null;
      switch (prop) {
        case "factory":
          returnWidth = "30%";
          break;
        case "remark":
          returnWidth = "30%";
          break;
        case "capacity":
          returnWidth = "40%";
          break;
        default:
          returnWidth = "15%";
          break;
      }
      return returnWidth;
    },
    getProductionCapacitySelections(params) {
      getProductionCapacitySelections(params).then(res => {
        if (res.ok) {
          const {
            provinceSelectionList,
            citySelectionList,
            typeSelectionList
          } = res.data;
          this.provinceSelectionList = provinceSelectionList;
          this.citySelectionList = citySelectionList;
          this.typeSelectionList = typeSelectionList;
        }
      });
    },
    getProductionCapacity(params, isShowCapacity) {
      this.isLoading = true;
      getProductionCapacity(params).then(res => {
        if (res.ok) {
          if (isShowCapacity) {
            this.isShowCapacity = res.data.tableData.length ? true : false;
          }
          this.sourceData = res.data;
          this.totalCount = res.data.totalCount || 0;
          // 在组件创建时计算最大的产能值
          this.maxCapacity = Math.max(
            ...res.data.tableData.map(item =>
              Number(item.capacity) ? Number(item.capacity) : 0
            )
          );
          // 如果最大值为零，就赋一个默认值，避免除以零的情况
          if (this.maxCapacity === 0) {
            this.maxCapacity = 1;
          }
          this.isLoading = false;
        }
      });
    },
    handleProvinceChange() {
      // 当省份选择变化时，重置城市选择
      this.params.cityList = this.params.cityList.filter(cityValue =>
        this.filteredCities.some(city => city.value === cityValue)
      );
      this.changeListQuery()
    }
  },
  computed: {
    // 根据选择的省份过滤城市列表
    filteredCities() {
      if (this.params.provinceList.length === 0) return this.citySelectionList;
      return this.citySelectionList.filter(city =>
        this.params.provinceList.includes(city.linkageValue)
      );
    }
  },
  watch: {
    // 观察路由的变化来更新userId
    "$route.params.id"(newId) {
      this.dynamicParams.enterpriseId = newId;
    }
  }
};
</script>
