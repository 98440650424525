<template>
  <PageContainer breadcrumb>
    <h1 class="title">{{ tableData.cpmc }}</h1>
    <div class="img-grop">
      <div class="img-box">
        <h2 class="img-title">右部照片</h2>
        <img :src="tableData.ybzp || defaultImg" alt="" />
      </div>
       <div class="img-box">
        <h2 class="img-title">后部照片</h2>
        <img :src="tableData.hbzp || defaultImg" alt="" />
      </div>
       <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <img :src="tableData.xzzp1 || defaultImg" alt="" />
      </div>
       <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <img :src="tableData.xzzp2 || defaultImg" alt="" />
      </div>
       <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <img :src="tableData.xzzp3 || defaultImg" alt="" />
      </div>
    </div>
    <div class="img-box">
      <h2 class="img-title">其他照片</h2>
      <img :src="tableData.chfhzp || defaultImg" alt="" />
    </div>
  </PageContainer>
</template>
<script>
import { ggPublicContent } from "@/api/announcement.js";
export default {
  data() {
    return {
         defaultImg:require("@/assets/img/nonecar.jpg"),
      tableData: {},
    };
  },
  created() {
    this.ggPublicContent();
  },
  methods: {
    ggPublicContent() {
      let id = this.$route.params.id;
      ggPublicContent(id).then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  color: #333;
  margin: 16px 0 32px;
}
.img-grop {
  overflow: hidden;
  margin-left: -16px;
  .img-box {
    width: 572px;
    float: left;
    padding: 16px;
    background: #fff;
    margin-left: 16px;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
    .img-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333;
      margin-bottom: 32px;
    }
  }
}
</style>
