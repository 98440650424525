<template>
  <PageContainer breadcrumb>
    <VlistTitle bigTtitle="征求/报批意见稿" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>
    <div style="overflow: hidden" class="box_box">
      <!-- <div style="float: left" flex="c">
          <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
              <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">{{ item.dictLabel }}</el-checkbox>
          </el-checkbox-group>
      </div> -->
      <div style="float: right">
        <el-input placeholder="搜索名称" size="small" v-model="title" @keyup.enter.native="search">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search" style="cursor: pointer;"></i>
        </el-input>
      </div>
    </div>

    <Vlist :list="datas" :pageData="pageData" @handleSizeChange="handleSizeChange"
           @handleCurrentChange="handleCurrentChange" @toHref="toHref"/>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import {solicitComments} from "@/api/standards.js";
import Vlist from "@/components/list";
import {getUserInfo, ydlTk} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
    Vlist,
  },
  data() {
    return {
      bgurl: require("@/assets/img/Group299.png"),
      datas: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreas: [],
      title: "",
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "标准",
        userId: ""
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    init() {

      let form = Object.assign({}, this.pageData.pages, {
        title: this.title,
        sycFlag: 'syc',
      });
      solicitComments(form).then((res) => {
        if (res.ok) {
          this.datas = res.data.list;
          this.pageData.total = res.data.count;
        }
      });

    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.init();
    },
    toHref(item) {
      let newUrl = this.$router.resolve(
          "/standards/dynamic/syc/newComments/details/" + item.id
      );

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //切换
    handleChange(e) {
      this.init();
    },
    search() {
      this.pageData.pages.page = 1;
      this.init();
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
