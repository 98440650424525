// 企业情报 产销产能
import $http from '@/plugins/http.js'

// 产量产销 图表
// 产量销量-企业产量榜单
export function getEnterpriseProductionData() {
    return $http.get('/newEnterpriseProductionCapacity/getEnterpriseProductionData')
}

// 产量销量-企业销量榜单
export function getEnterpriseSalesData() {
    return $http.get('/newEnterpriseProductionCapacity/getEnterpriseSalesData')
}

// 产量销量-车型产量榜单
export function getVehicleProductionData() {
    return $http.get('/newEnterpriseProductionCapacity/getVehicleProductionData')
}

// 产量销量-车型销量榜单
export function getVehicleSalesData() {
    return $http.get('/newEnterpriseProductionCapacity/getVehicleSalesData')
}

// 表格
// 产能产销-产量销量-详细表格
export function getEnterpriseProductionAndSalesDetails(params) {
    return $http.post('/newEnterpriseProductionCapacity/getEnterpriseProductionAndSalesDetails', params)
}

// 产能产销-产量销量-详细表格-筛选列表
export function getEnterpriseProductionAndSalesSelections() {
    return $http.get('/newEnterpriseProductionCapacity/getEnterpriseProductionAndSalesSelections')
}

// 企业产能 地图数据
// 产能产销-企业产能
export function MapData(params) {
    return $http.get('/newEnterpriseProductionCapacity/getMapData', params)
}
// 世界地图
export function abroadMapData(params) {
    return $http.get('/newEnterpriseProductionCapacity/getAbroadMapData', params)
}
//筛选
export function getAbroadSelections() {
    return $http.get('/newEnterpriseProductionCapacity/getAbroadSelections')
}
//表格
export function getAbroadTableData(params) {
    return $http.post('/newEnterpriseProductionCapacity/getAbroadTableData',params)
}
// 世界地图
export function abroadGlobalMapData(params) {
    return $http.get('/newEnterpriseProductionCapacity/getAbroadGlobalMapData', params)
}
//筛选
export function abroadSelections() {
    return $http.get('/newEnterpriseProductionCapacity/getAbroadSelections')
}
//表格
export function abroadGlobalTableData(params) {
    return $http.post('/newEnterpriseProductionCapacity/getAbroadGlobalTableData',params)
}
// 导出
export function abroadExportTableData(params) {
    return $http.DownloadDb('/newEnterpriseProductionCapacity/exportAbroadTableData', params)
}

// 产能产销-筛选列表
export function Selections() {
    return $http.get('/newEnterpriseProductionCapacity/getSelections')
}

// 产能产销-产量销量-详细表格
export function TableData(params) {
    return $http.post('/newEnterpriseProductionCapacity/getTableData', params)
}

// 产能产销-产量销量-详细表格导出
export function exportTableData(params) {
    return $http.DownloadDb('/newEnterpriseProductionCapacity/exportTableData', params)
}
