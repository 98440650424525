<template>
  <router-view v-if="$route.name == '标准动态列表' || $route.name == '标准动态内容页'"/>
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips"> </VnotLogin>-->
    <template>
      <el-row :gutter="16" style="margin-bottom:16px">
        <el-col :span="12">
          <listHead title="未实施标准" url="assets/img/11153157zuln.png" href="/standards/dynamic/syc/newStandards/list"/>
          <ul class="newUl">
            <li v-for="item in newStandardData" :key="item.id">
              <div style="cursor: pointer" @click="toHref('/standards/dynamic/syc/newStandards/details/' + item.id)"
                   class="newUl-link">
                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ htmlRestore(item.title) }}</div>
                <div class="newUl-time">{{ item.implementDate }}</div>
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :span="12">
          <listHead title="征求/报批意见稿" url="assets/img/6377233672246183546.png" @click="toClick"/>
          <ul class="newUl">
            <li v-for="item in solicitCommentsData" :key="item.id">
              <div style="cursor: pointer" @click="toHref('/standards/dynamic/syc/newComments/details/' + item.id)"
                   class="newUl-link">
                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ htmlRestore(item.title) }}</div>
                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="12">
          <listHead title="标准动态" url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png"
                    href="/standards/dynamic/syc/listDynamic"/>
          <ul class="newUl">
            <li v-for="item in standardDynamicData" :key="item.id">
              <div style="cursor: pointer" @click="toHref('/standards/dynamic/syc/contentDynamic/' + item.id)"
                   class="newUl-link">
                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ htmlRestore(item.title) }}</div>
                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :span="12">
          <listHead title="标准解读" url="assets/img/charlie-deets-D1W5QenBzlU-unsplash1.png"
                    href="/standards/dynamic/syc/listUnscramble"/>
          <ul class="newUl">
            <li v-for="item in standardUnscrambleData" :key="item.id">
              <div style="cursor: pointer" @click="toHref('/standards/dynamic/syc/contentUnscramble/' + item.id)"
                   class="newUl-link">

                <template v-if="item.belongAreas">
                  <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>
                </template>
                <div class="newUl-title">{{ htmlRestore(item.title) }}</div>
                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 标准动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {
  standardDynamic,
  standardUnscramble,
  solicitComments,
  newStandard,
} from "@/api/standards.js";
import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";
import Vbreadcrumb from "@/components/breadcrumbSyc.vue";

export default {
  components: {
    Vbreadcrumb
  },
  data() {
    return {
      showTips: [
        {
          title: "标准动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "标准库查询",
          text: "权威标准库，多条件筛查关联比对",
        },
        {
          title: "标准梳理",
          text: "专家梳理整合，真正读懂标准脉络",
        },
        {
          title: "标准大数据",
          text: "用数据深度理解标准结构和趋势",
        },
      ],
      standardDynamicData: [],
      standardUnscrambleData: [],
      solicitCommentsData: [],
      newStandardData: [],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
      newStandardPage: {
        pages: {
          page: 1,
          pageSize: 6,
          sycFlag: 'syc'
        },
        total: 0,
        currentPage: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "标准",
        userId: ""
      },
    };
  },
  computed: {
    newDate() {
      return fmtDate("YY年mm月", new Date());
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.newStandard(this.newStandardPage.pages);
    this.init();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    init() {
      this.standardDynamic({page: 1, pageSize: 6, sycFlag: 'syc'});
      this.standardUnscramble({page: 1, pageSize: 6, sycFlag: 'syc'});
      this.solicitComments({page: 1, pageSize: 6, sycFlag: 'syc'});
    },
    //标准动态
    standardDynamic(params) {
      standardDynamic(params).then((res) => {
        if (res.ok) {
          this.standardDynamicData = res.data.list;
        }
      });
    },
    //标准解读
    standardUnscramble(params) {
      standardUnscramble(params).then((res) => {
        if (res.ok) {
          this.standardUnscrambleData = res.data.list;
        }
      });
    },
    //标准征求意见/报批稿
    solicitComments(params) {
      solicitComments(params).then((res) => {
        if (res.ok) {
          this.solicitCommentsData = res.data.list;
        }
      });
    },
    //最新标准
    newStandard(params) {
      newStandard(params).then((res) => {
        if (res.ok) {
          this.newStandardData = res.data.list;
          this.newStandardPage.total = res.data.count;
        }
      });
    },
    toClick() {
      // this.$store.dispatch("common/SET_qiehuan", "zqyjg");
      this.$router.push("/standards/dynamic/syc/newComments/list");
    },
    //字符串转数组
    strSplit(str) {
      return str.split(",");
    },
  },
};
</script>
<style lang="scss" scoped>
//.newUl {
//
//  height: 304px;
//}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

</style>
