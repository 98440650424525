<!--高端访谈列表-->
<template>
  <router-view v-if="$route.name == '高端访谈内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="高端访谈" smallTip="权威专家 深度观点" :bgurl="bgurl" />
    <VlistImg :list="ttHighInterviewPageData" :page="ttHighInterviewPagePage" :pageData="ttHighInterviewPagePage" :props="props" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" to="/index/detail/highInterviewDetail/" />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { ttHighInterviewPage } from "@/api/Index.js";
import VlistImg from "@/components/listImg";
export default {
    components: {
        VlistTitle,
        VlistImg,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            ttHighInterviewPageData: [],
            ttHighInterviewPagePage: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },
            props: {
                img: "url",
            },
        };
    },
    created() {
        this.ttHighInterviewPage(this.ttHighInterviewPagePage.pages);
    },
    methods: {
        ttHighInterviewPage(params) {
            ttHighInterviewPage(params).then((res) => {
                if (res.ok) {
                    this.ttHighInterviewPageData = res.data.list;
                    this.ttHighInterviewPagePage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.ttHighInterviewPagePage.pages.pageSize = v;
            this.ttHighInterviewPage(this.ttHighInterviewPagePage.pages);
        },
        handleCurrentChange(v) {
            this.ttHighInterviewPagePage.pages.page = v;
            this.ttHighInterviewPage(this.ttHighInterviewPagePage.pages);
        },
    },
};
</script>
