<template>
  <div>
    <el-card class="file-box">
      <div slot="header">
        <span>附件下载</span>
      </div>
      <div v-if="enclosure">
        <ul class="file">
          <li v-for="(item, index) in enclosure" :key="index">
            <a href="javascript:;" @click="downBtn(item)">{{
              item.fileName
            }}</a>
          </li>
        </ul>
      </div>
    </el-card>
  </div>
</template>
<script>
import { download } from "@/api/common.js";
import { downloadFile } from "@/utils/download.js";

export default {
  props: {
    enclosure: [],
    articleId:{
      type:String,
      default:""
    }
  },
  methods: {
    //下载
    downBtn(item) {
      download({ fileUrl: item.url, articleId: this.articleId }).then((res) => {
        downloadFile(res, item.fileName);
      }).catch(err=>{
        this.$message({
          // showClose: true,
          message: "未找到文件",
          type: "error",
        });
        console.log('err',err)
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.file-box{
    margin-bottom: 40px;
}
.file {
  li {
    margin-bottom: 15px;
    &:last-child{
        margin-bottom: 0;
    }
  }
  a {
    font-size: 15px;
  }
}
</style>

