<template>
    <div class="select-checkbox">
        <template v-if="isNullValue">
            <div class="select-checkbox-top" :class="{'disabled':disabled}" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                <span class="select-placeholder" v-if="newValue.length<=0">请选择</span>
                <template v-else>
                    <el-tag closable size="small" type="info" @close="selectClose(returnName(newValue))">
                        {{returnName(newValue)}}
                    </el-tag>
                    <el-tag type="info" size="small" style="margin-left:6px" v-if="newValue.length>1">+{{newValue.length-1}}</el-tag>
                </template>
                <i class="el-icon-arrow-down select-checkbox-icon"></i>
            </div>
            <div class="select-checkbox-bottom" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                <div flex="sb" v-if="isLetters">
                    <div flex="c">
                        <div class="letters-box">
                            <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName == item}" @click="actBtn(item)">{{item}}</el-button>
                        </div>
                        <el-input v-model="searchValue" size="small" style="width:150px;margin-left:10px" @input="filterInput" clearable></el-input>
                    </div>
                   <div>
                        <el-button type="primary" size="mini" @click="selectAll" v-if="allSelect">全选</el-button>
                    <el-button type="primary" size="mini" @click="clear">清空</el-button>
                   </div>
                </div>
                <el-checkbox-group v-model="newValue" @change="onChange">
                    <template v-for="item in filterValue">
                        <el-checkbox v-if="actName == '全部'" :label="item.label" :key="item.key" >{{item.name}}</el-checkbox>
                        <el-checkbox v-else-if="item.letter == actName" :label="item.label" :key="item.key">{{item.name}}</el-checkbox>
                    </template>
                </el-checkbox-group>
            </div>
        </template>
        <template v-else>
            <div class="select-checkbox-top" :class="{'disabled':disabled}" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                <span class="select-placeholder" v-if="newValue.length<=0">请选择</span>
                <template v-else>
                    <el-tag closable size="small" type="info" @close="selectClose(newValue[0])">
                        {{newValue[0]}}
                    </el-tag>
                    <el-tag type="info" size="small" style="margin-left:6px" v-if="newValue.length>1">+{{newValue.length-1}}</el-tag>
                </template>
                <i class="el-icon-arrow-down select-checkbox-icon"></i>
            </div>
            <div class="select-checkbox-bottom" @mouseleave="removeClassFun" @mouseenter="addClassFun">
                <div flex="sb" v-if="isLetters">
                    <div flex="c">
                        <div class="letters-box">
                            <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName == item}" @click="actBtn(item)">{{item}}</el-button>
                        </div>
                        <el-input v-model="searchValue" size="small" style="width:150px;margin-left:10px" @input="filterInput" clearable></el-input>
                    </div>
                    <div>
                        <el-button type="primary" size="mini" @click="selectAll2" v-if="allSelect">全选</el-button>
                    <el-button type="primary" size="mini" @click="clear">清空</el-button>
                    </div>
                </div>

                <el-checkbox-group v-model="newValue" @change="onChange">
                    <template v-for="item in filterValue">
                        <el-checkbox v-if="actName == '全部'" :label="item.name" :key="item.name">{{item.name}}</el-checkbox>
                        <el-checkbox v-else-if="item.letter == actName" :label="item.name" :key="item.name">{{item.name}}</el-checkbox>
                    </template>
                </el-checkbox-group>
            </div>
        </template>
    </div>
</template>
<script>
import pinyin from "js-pinyin";
export default {
    model: {
        prop: "checked",
        event: "change",
    },
    props: {
        checked: [],
        disabled: false,
        datas: [],
        isLetters: {
            type: Boolean,
            default: true,
        },
        props: {
            label: "label",
            key: "id",
            name: "name",
        },
        isNullValue: {
            type: Boolean,
            default: true,
        },
        allSelect: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            newValue: this.checked,
            letters: [
                "全部",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ],
            actName: "全部",
            searchValue: "",
            filterValue: [],
        };
    },
    computed: {
        comData() {
            let props = this.props;
            let newDatas = [];
            if (this.isNullValue) {
                for (const key in props) {
                    for (let i = 0; i < this.datas.length; i++) {
                        const el = this.datas[i];
                        el[key] = el[props[key]];
                    }
                }
                this.datas.forEach((el) => {
                    el.letter = pinyin
                        .getFullChars(el.name)
                        .charAt(0)
                        .toUpperCase();
                        if(el.name == '长安汽车'){
                                       

                        }
                });
                newDatas = this.datas;
            } else {
                this.datas.forEach((el) => {
                    newDatas.push({
                        name: el,
                        letter: pinyin
                            .getFullChars(el)
                            .charAt(0)
                            .toUpperCase(),
                    });
                });
            }
            return newDatas;
        },
    },
    watch: {
        checked(v) {
            this.newValue = v;
        },
        comData(oldValue) {
            this.filterValue = oldValue;
        },
    },
    methods: {
        addClassFun(e) {
            if (!e.target.classList.contains("disabled")) {
                e.target.parentNode.classList.add("hover-class");
            }
        },
        removeClassFun(e) {
            e.target.parentNode.classList.remove("hover-class");
        },
        onChange(e) {
            this.$emit("change", e);
        },
        selectClose(e) {
            this.newValue.splice(0, 1);
            this.$emit("close", e);
        },
        returnName(value) {
            let id = value[0];
            let str = this.datas.filter((item) => item.key == id);
            if (str.length && str.length > 0) {
                return str[0].name;
            }
        },
        actBtn(item) {
            this.actName = item;
        },
        clear() {
            this.newValue.splice(0, this.newValue.length);
            this.searchValue = "";
            this.actName = "全部";
            this.filterValue = this.comData;
            this.$emit("clear");
        },
        //全选
        selectAll(){
            this.newValue.splice(0, this.newValue.length);
            this.comData.forEach(item=>{
                this.newValue.push(item.key)
            })
        },
        selectAll2(){
              this.newValue.splice(0, this.newValue.length);
            this.comData.forEach(item=>{
                this.newValue.push(item.name)
            })
        },
        filterInput(e) {
            // let letter = "全部";
            // if (e) {
            //     letter = pinyin.getFullChars(e).charAt(0);
            // }
            // this.actName = letter.toUpperCase();
            this.filterValue = this.fuzzyQuery(this.comData, e.toUpperCase());
        },
        fuzzyQuery(list, keyWord) {
            var reg = new RegExp(keyWord);
            var arr = [];
            for (var i = 0; i < list.length; i++) {
                if (reg.test(list[i].name.toUpperCase())) {
                    arr.push(list[i]);
                }
            }
            return arr;
        },
    },
};
</script>
<style lang="scss" scoped>
.letters-box {
    .el-button {
        padding: 0;
        &.act {
            color: #000;
        }
    }
}
</style>