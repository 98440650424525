import { download } from '@/api/common.js'
import { downloadFile } from '@/utils/download.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'
export default {
  methods: {
    // 选中
    checkFun(item) {
      if (!item.check) {
        this.checkData.push({
          id: item.id,
          fileInfo: item.fileInfo
        })
      } else {
        for (let i = 0; i < this.checkData.length; i++) {
          if (this.checkData[i].id == item.id) {
            this.checkData.splice(i, 1)
          }
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (item.id == this.list[i].id) {
          this.list[i].check = !this.list[i].check
        }
      }
    },
    // 过滤文件
    filterFile(files) {
      const fileArr = []
      files.forEach(item => {
        if (item.fileInfo.length > 0) {
          item.fileInfo.forEach(citem => {
            fileArr.push({ ...citem, id: item.id })
          })
        }
      })
      return fileArr
    },
    // 全部下载
    downAll(title, type) {
      this.formLog.eventType = '2'
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes(title)) {
            this.$Vlogin3({ flag: true })
            this.formLog.userId = getUserInfo().userId
            buryPoint(this.formLog)
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.formLog.userId = getUserInfo().userId
              buryPoint(this.formLog)
            } else {
              const files = this.filterFile(this.checkData)
              const self = this
              files.forEach(item => {
                logRecords(title, type, '2', '', item.id, item.fileName, '', '')

                download({ fileUrl: item.url }).then(res => {

                  var reader = new FileReader()
                  reader.readAsText(res)
                  reader.addEventListener('loadend', function(e) {
                    try {
                      JSON.parse(e.target.result)
                      const msg = JSON.parse(e.target.result)
                      self.$message({
                        showClose: true,
                        message: msg.message,
                        type: 'error'
                      })
                    } catch (err) {
                      downloadFile(res, item.fileName)
                    }
                  })
                })
              })
              self.downText = '下载中，请到游览器下载内查看'
              self.downdisable = true
              self.downIcon = 'el-icon-loading'
              setTimeout(() => {
                self.downText = '立即下载'
                self.downdisable = false
                self.downdisable = false
                self.downId = []
                self.downIcon = 'el-icon-download'
              }, 2000)
            }
          }
        } else {
          const files = this.filterFile(this.checkData)
          const self = this
          files.forEach(item => {
            download({ fileUrl: item.url }).then(res => {
              var reader = new FileReader()
              reader.readAsText(res)
              reader.addEventListener('loadend', function(e) {
                try {
                  JSON.parse(e.target.result)
                  const msg = JSON.parse(e.target.result)
                  self.$message({
                    showClose: true,
                    message: msg.message,
                    type: 'error'
                  })
                } catch (err) {
                  downloadFile(res, item.fileName)
                }
              })
            })
          })
          self.downText = '下载中，请到游览器下载内查看'
          self.downdisable = true
          self.downIcon = 'el-icon-loading'
          setTimeout(() => {
            self.downText = '立即下载'
            self.downdisable = false
            self.downdisable = false
            self.downId = []
            self.downIcon = 'el-icon-download'
          }, 2000)
        }
      } else {
        this.$Vlogin2({ flag: true })
      }
    }
  }
}
