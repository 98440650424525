<template>
  <div>
    <router-link :to="toHref" class="more" v-if="toHref">
      <span>查看更多</span>
      <i class="el-icon-arrow-right"></i>
    </router-link>
    <div class="more" v-else @click="toclick">
      <span>查看更多</span>
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "More",
  props: {
    toHref: {
      type: [String, Object],
      default: "",
    },
  },
  methods: {
    toclick() {
      this.$emit("click");
    },
  },
};
</script>

<style scope>
.more {
 color: #BDBDBD;
  cursor: pointer;
  font-size: 12px;
}
</style>