<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" is-content-two="bzzqyjg" name="标准" />

    <template slot="right">
      <VcontentItem
        title="最新标准推荐"
        type="list"
        :list="tableDataRight"
        @click="toHref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { newStandard, ttSolicitComments } from '@/api/standards.js'
export default {
  data() {
    return {
      data: {},
      props: {
        tag: 'applicableProducts',
        htmlContent: 'content',
        summary: 'introduction'
      },
      tableDataRight: []
    }
  },
  created() {
    const id = this.$route.params.id
    this.ttSolicitComments(id)
    this.localOriginalRight({ page: 1, pageSize: 10 })
  },
  methods: {
    ttSolicitComments(params) {
      ttSolicitComments(params).then((res) => {
        if (res.ok) {
          this.data = res.data
        }
      })
    },
    // 最新标准
    localOriginalRight(param) {
      newStandard(param).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list
          this.loading = false
        }
      })
    },
    toHref(item) {
      this.$router.push('/standards/dynamic/newStandards/details/' + item.id)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
