<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" is-content-two="gjhzc" name="国际化政策" :lang="lang"/>

    <!-- <template slot="right">
      <VcontentItem
        title="相关政策推荐"
        type="list"
        :list="relationPolicy"
        @click="tohref"
      />
      <VcontentItem
        title="相关政策报道"
        type="list"
        :list="relationReport"
        @click="tohref"
      />
      <VcontentItem
        title="相关政策解读"
        type="list"
        :list="relationInterpret"
        @click="tohref"
      />
      <VcontentItem
        title="相关原创解读"
        type="list"
        :list="relationCourse"
        @click="tohref"
      />
      <VcontentItem
        title="原创解读推荐"
        type="list"
        :list="tableDataRight"
        @click="toHref"
      />
    </template> -->
  </PageContainer>
</template>
<script>
import { localOriginal, ttPolicy } from '@/api/policy.js'
import { htmlRestore } from '@/utils/htmlRestore'
import { getUserInfo } from '@/utils/auth'
import { runLanguageDetection } from '@/api/common'
export default {
  data() {
    return {
      data: {},
      props: {
        htmlContent: 'content',
        summary: 'introduction',
        interpret:'',
      },
      relationPolicy: [],
      relationReport: [],
      relationInterpret: [],
      relationCourse: [],
      tableDataRight: [],
      lang: 'zh'
    }
  },
  watch: {
    $route(to, from) {
      const id = to.params.id
      this.ttPolicy(id)
      // 对路由变化作出响应...
    }
  },
  created() {
    const id = this.$route.params.id
    this.ttPolicy(id)
    this.localOriginalRight({ page: 1, pageSize: 15, unscrambleUnit: '1' })
    this.LanguageDetection(id, '国际化政策')
  },
  methods: {
    ttPolicy(params) {
      ttPolicy(params).then((res) => {
        if (res.ok) {
          this.data = res.data
          this.props.interpret = res.data.interpret?res.data.interpret:''
          this.relationPolicy = res.data.relationPolicy.map(item => {
            item.publishDate = item.publish_date
            return item
          })
          this.relationReport = res.data.relationReport.map(item => {
            item.publishDate = item.publish_date
            return item
          })
          this.relationInterpret = res.data.relationInterpret.map(item => {
            item.publishDate = item.publish_date
            return item
          })
          this.relationCourse = res.data.relationUnitInterpret.map(item => {
            item.publishDate = item.publish_date
            return item
          })
        }
      })
    },
    // 本网原创
    localOriginalRight(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list
          this.tableDataRight.forEach((element) => {
            element.title = htmlRestore(element.title)
            element.publishDate = element.updateTime
          })
        }
      })
    },
    LanguageDetection(id, name) {
      const data = {
        id: id,
        name: name
      }
      runLanguageDetection(data).then((res) => {
        if (res.ok) {
          this.lang = res.data
        }
      })
    },
    tohref(item) {
      if (item.tableName == 'tt_policy_report') {
        // 相关政策报道
        this.$router.push('/policy/dynamic/contentReport/' + item.id)
      } else if (item.tableName == 'tt_policy_interpret') {
        // 政策解读推荐
        this.$router.push('/policy/dynamic/contentExplain/' + item.id)
      } else {
        // 政策主体推荐
        this.$router.push('/policy/dynamic/contentNewPolicy/' + item.id)
      }
    },
    toHref(item) {
      const newUrl = this.$router.resolve(
        '/policy/dynamic/LocalOriginalDetails/' + item.id
      )
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
