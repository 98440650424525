<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:29:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-12 19:29:49
-->
<!--市场动态列表-->
<template>
  <div class="advertisement"></div>
</template>
<script>
export default {
  name: "advertisement",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;
.advertisement {
  width: 100%;
  height: 100%;
  background: url(~@/assets/img/overview/advertisement.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
</style>
