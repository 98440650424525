<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-26 11:28:22
-->
<template>
  <div :id="'pieEchart' + echartKey" style="width:100%" v-loading="loading">
    <div ref="pieEchart" class="pieEchart" :style="`height:${height}px`" />
  </div>
</template>
<script>
import { toThousands } from "@/utils/number";
// 引入echarts
import * as echarts from "echarts";
// 引入dom监听器
export default {
  name: "PieEchart",
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
    unit: {
      type: String,
      default: "辆",
    },
  },
  data() {
    return {
      myChart: null, // echartDom对象
      echartKey: Math.random(),
      // Eechart数据
      echartData: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    /**
     * @description: 打开页面加载
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-26 11:17:58
     * @LastEditTime: Do not edit
     */

    loadingOpen() {
      this.loading = true;
    },
    loadingClose() {
      this.loading = false;
    },
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.echartData = data;
      this.initEchart();
      this.initLister();
      this.loadingClose();
    },
    /**
     * @description: 初始化监听器
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:18
     * @LastEditTime: Do not edit
     */
    initLister() {
      const elementResizeDetectorMaker = require("element-resize-detector");
      // 监听元素变化
      const erd = elementResizeDetectorMaker();
      erd.listenTo(
        document.getElementById("pieEchart" + this.echartKey),
        (element) => {
          this.$nextTick(() => {
            // 使echarts尺寸重置
            this.myChart.resize();
          });
        }
      );
    },
    /**
     * @description: 初始化echart
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:08
     * @LastEditTime: Do not edit
     */
    initEchart() {
      this.myChart = echarts.init(this.$refs.pieEchart);
      const option = {
        color: [
          "rgba(22, 93, 255, 0.8)",
          "rgba(20, 201, 201, 0.8)",
          "rgba(247, 186, 30, 0.8)",
          "rgba(246, 86, 86, 0.8)",
          "rgba(114, 46, 209, 0.8)",
          "rgba(77, 195, 103, 0.8)",
          "rgba(255, 105, 19, 0.8)",
        ],
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const { marker, name, value } = params;
            return `
              ${marker}<span style="margin-right: 10px">${name}地区</span>
              <span style='font-weight: bold;'>${toThousands(value)}${
              this.unit
            }</span>`;
          },
        },
        legend: {
          top: "5%",
          right: 0,
          icon: "circle",
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "55%"],
            avoidLabelOverlap: false,
            // minAngle: 15, //最小角度
            // startAngle: 270, //起始角度
            label: {
              show: true,
              position: "outer",
              // alignTo: 'edge',
              margin: 0,
              formatter: "{d|{d}}{percent|%} \n {b|{b}地区}",
              rich: {
                d: {
                  color: "#333333",
                  fontSize: 18,
                  fontWeight: "bold",
                  lineHeight: 24,
                  height: 24,
                  align: "center",
                },
                percent: {
                  color: "#333333",
                  fontSize: 12,
                  lineHeight: 24,
                  height: 24,
                  align: "center",
                },
                b: {
                  color: "#757575",
                  fontSize: 12,
                  align: "center",
                },
              },
            },
            labelLine: {
              normal: {
                length: 20,
                // length2: 30,
                // maxSurfaceAngle: 80,
                lineStyle: {
                  width: 1,
                },
              },
            },
            // labelLayout: (params) => {
            //   const isLeft =
            //     params.labelRect.x < this.curEChartObj.getWidth() / 2
            //   const centerX = this.curEChartObj.getWidth() / 2

            //   let newX = 0
            //   if (isLeft) {
            //     newX = centerX - 50 - params.labelRect.width
            //   } else {
            //     newX = centerX + 50 + params.labelRect.width
            //   }
            //   const points = params.labelLinePoints

            //   points[2][0] = isLeft ? newX + 10 : newX

            //   return {
            //     x: newX,
            //     labelLinePoints: points
            //   }
            // },
            data: this.echartData,
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.pieEchart {
  width: 100%;
  height: 100%;
}
</style>
