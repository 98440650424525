export  function  combineCell(list) {
    for (var field in list[0]) {  // 获取数据中的字段，也就是table中的column，只需要取其中一条记录的就可以了
        // 定义数据list的index
        var k = 0;
        while (k < list.length) {
        
            // 增加字段-用于统计有多少重复值
            list[k][field + 'span'] = 1;
            // 增加字段-用于控制显示与隐藏
            list[k][field + 'dis'] = '';
            for (var i = k + 1; i <= list.length - 1; i++) {
               
                // 判断第k条数据的field字段，与下一条是否重复
                if (list[k][field] === list[i][field] && list[k][field] !== '') {
                    // 如果重复，第k条数据的字段统计+1
                    list[k][field + 'span']++;
                    // 设置为显示
                    list[k][field + 'dis'] = '';
                    // 重复的记录，则设置为1，表示不跨行
                    list[i][field + 'span'] = 1;
                    // 并且该字段设置为隐藏
                    list[i][field + 'dis'] = 'none';
                } else {
                    break;
                }
            }
            // 跳转到第i条数据的索引
            k = i;
        }
    }
    list.forEach(item => {
        for(let key in item){
            if(item[key] === null || item[key] ===''){
                item[key] ='*'
            }
        }
    });



  return list
}