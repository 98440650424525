<template>
  <div class="container">
    <div class="orgchart">
      <ul class="nodes">
        <li class="hierarchy" v-for="item in filterFun(record)" :key="item.id">
          <div class="node top-node">
            <div class="title" :title="item.enterpriseName">
              {{ item.enterpriseName }}
            </div>
          </div>
          <orgTreeC v-if="item.children" :record="item.children" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import orgTreeC from "./orgTreeC";
export default {
  props: {
    record: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      treeData: [],
    };
  },
  components: {
    orgTreeC,
  },
  methods: {
    //过滤元素
    filterFun(data) {
      if (data && data.length > 0) {
        if (data[0].children) {
          data[0].children = [];
        }
        let obj = {},
          newarr = [];
        for (let i = 0; i < data.length; i++) {
          if (!data[i].parentId) {
            newarr.push(data[i]);
          }
          obj[data[i].id] = data[i];
        }

        for (let i = 0; i < data.length; i++) {
          let pid = data[i].parentId;
          if (pid in obj) {
            if (!obj[pid].hasOwnProperty("children")) {
              obj[pid].children = [];
            }
            obj[pid].children.push(data[i]);
          }
        }
        return newarr;
      }else{
        return []
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: 420px;
  margin: 0.5rem;
  overflow: auto;
  text-align: center;
}
.orgchart {
  display: inline-block;
}
.orgchart .nodes {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.orgchart .hierarchy {
  position: relative;
}

/* styles of link lines */
.orgchart .hierarchy::before {
  content: "";
  position: absolute;
  top: -22px;
  left: 0;
  width: 100%;
  border-top: 2px solid #e0e0e0;
  box-sizing: border-box;
}

.orgchart .nodes:not(:first-child) .hierarchy .node .title {
  text-align: left;
}

.orgchart .hierarchy:first-child::before {
  left: calc(50% - 1px);
  width: calc(50% + 1px);
}

.orgchart .hierarchy:last-child::before {
  width: calc(50% + 1px);
}

.orgchart .hierarchy:not(.hidden):only-child::before {
  width: 2px;
}

.orgchart > .nodes > .hierarchy::before,
.orgchart .isCollapsedSibling::before,
.orgchart .isCollapsedSibling .hierarchy::before,
.orgchart .isChildrenCollapsed > .node:not(:only-child)::after,
.orgchart .isCollapsedDescendant::before,
.orgchart .isCollapsedDescendant > .node::before,
.orgchart .isCollapsedDescendant > .node::after,
.orgchart .isAncestorsCollapsed:only-child::before,
.orgchart .isAncestorsCollapsed > .node::before {
  content: none;
}

/* excluding leaf node */
.orgchart .node:not(:only-child)::after {
  content: "";
  position: absolute;
  bottom: -21px;
  left: calc(50% - 1px);
  width: 2px;
  height: 20px;
  background-color: #e0e0e0;
}

/* excluding root node */
.orgchart > ul > li > ul li > .node::before {
  content: "";
  position: absolute;
  top: -21px;
  left: calc(50% - 1px);
  width: 2px;
  height: 20px;
  background-color: #e0e0e0;
}

/* node styling */
.orgchart .node {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  margin: 0 20px 65px 20px;
  padding: 3px;
  border: 1px solid #828282;
  text-align: center;
  padding: 10px 20px;
  border-radius: 2px;
}
.orgchart .node .title {
  box-sizing: border-box;
  padding: 2px;
  width: 130px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4f4f4f;
  border-radius: 4px 4px 0 0;
}

.orgchart .node .content {
  margin-top: 4px;
  margin-left: -4px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.orgchart .node .content .tag {
  display: inline-block;
  padding: 1px 2px;
  line-height: 19px;
  margin-left: 4px;
  background: #e2e2e2;
  color: #4f4f4f;
  border-radius: 2px;
}

.orgchart .node.top-node {
  border-color: #0D57BC;
  background: rgba(21, 67, 133, 0.06);
  color: #0D57BC;
}
</style>