<!--
 * @Author: your name
 * @Date: 2021-07-21 13:08:48
 * @LastEditTime: 2021-07-21 14:41:48
 * @LastEditors: Please set LastEditors
 * @Description: 国内产销数据页面
 * @FilePath: /information-vue/code/src/views/data/domesticData/index.vue
-->
<template>
    <PageContainer>

      <template >
            <div>
                <div flex="sb" style="margin-left:-16px">
                    <div @click="isActive = '销量'" :class="'box ' + (isActive == '销量' ? 'is_active' : '')">
                        <div class="data-top-title">
                            <div class="data-top-title-inner" flex="c">
                                <input type="radio" class="radio-style" :checked="isActive == '销量'?true:false" />
                                <span class="text">销量数据</span>
                                <span class="time">已更新至{{updataDate}}</span>
                            </div>
                            <!-- <button class="title-down-btn" v-has-role="'trialrole'" @click="dialogVisibleFun"><i class="el-icon-download"></i>下载电子版本</button> -->
                        </div>
                    </div>
                    <div @click="isActive = '产量'" :class="'box ' + (isActive == '产量' ? 'is_active' : '')">
                        <div class="data-top-title">
                            <div class="data-top-title-inner" flex="c">
                                <input type="radio" class="radio-style" :checked="isActive == '产量'?true:false" />
                                <span class="text">产量数据</span>
                                <span class="time">已更新至{{updataDate1}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                批发数据-->
                <salesData v-if="isActive=='销量'" :upDataDate="updataDate" :show="show" ref="salesData"></salesData>
                <!--                零售数据-->
                <productionData v-if="isActive=='产量'" :upDataDate="updataDate1" :show="show"></productionData>

            </div>
        </template>
    </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import salesData from "./components/salesData";
import productionData from "./components/productionData";

import {
  getTtAbroadProductionData,
  getTtAbroadSaleData,
} from "@/api/data.js";

export default {
    components: {
        // VnotLogin,
      salesData,
      productionData,
    },
    data() {
        return {
            show: false,
            isActive: "销量",
            showTips: [
                {
                    title: "数据动态",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "数据库查询",
                    text: "权威数据库，多条件筛查关联比对",
                },
                {
                    title: "数据梳理",
                    text: "专家梳理整合，真正读懂数据脉络",
                },
                {
                    title: "数据大数据",
                    text: "用数据深度理解数据结构和趋势",
                },
            ],
            dialogVisible: false,
            updataDate: "",
            updataDate1: "",
          dialogVisible2: false,
          iframeSrc2: "https://www.wjx.cn/vm/hyVeQ3K.aspx"
        };
    },
    mounted() {
        if (getUserInfo()) {
            this.show = true;

        } else {
            this.show = false;
        }
      this.getTtAbroadProductionData();
      this.getTtAbroadSaleData();
    },
    methods: {
        dialogVisibleFun() {
            this.$refs.WholesaleData.dialogVisible = true;
        },
      getTtAbroadProductionData() {
        getTtAbroadProductionData().then((res) => {
          if (res.ok) {
            this.updataDate1 = res.data;

          }
        });
      },
      getTtAbroadSaleData() {
        getTtAbroadSaleData().then((res) => {
          if (res.ok) {
            this.updataDate = res.data;

          }
        });
      },
      yjDy() {
        this.dialogVisible2 = true

        setTimeout(function() {

          /**
           * iframe-宽高自适应显示
           */
          const oIframe = window.frames["bdIframe2"];
          const deviceWidth = document.documentElement.clientWidth;
          const deviceHeight = document.documentElement.clientHeight;
          // oIframe.style.width = Number(deviceWidth) - 220 + "px"; //数字是页面布局宽度差值
          oIframe.style.width = Number(deviceWidth); //数字是页面布局宽度差值
          oIframe.style.height = Number(deviceHeight) - 150 + "px"; //数字是页面布局高度差
        }, 100);
      },
        ykbQcPpGetDefaultDate() {
            ykbQcPpGetDefaultDate().then((res) => {
                if (res.ok) {
                    this.updataDate1 = res.data;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: 50%;
    padding-left: 16px;
    cursor: pointer;
    margin-bottom: 16px;
    .data-top-title {
        height: 64px;
        padding: 16px;
        background: #fff;
    }
    .text,
    .title-down-btn {
        color: #0d57bc;
    }
    .time {
        color: #333;
    }
    .title-down-btn {
        border: 1px solid #0d57bc;
    }
    .radio-style {
        width: 24px;
        height: 24px;
        appearance: none;
        position: relative;
        margin-right: 8px;
    }
    .radio-style:before {
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        background: rgba(13, 87, 188, 0.08);
        border: 1px solid #0d57bc;
    }
    .radio-style:checked:before {
        content: "";
        width: 24px;
        height: 24px;
        border: 1px solid #fff;
        background: #fff;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
    }
    .radio-style:checked:after {
        content: "";
        width: 11px;
        height: 7px;
        border: 2px solid #0d57bc;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 8px;
        left: 6px;
        transform: rotate(-45deg);
    }
    &.is_active {
        .data-top-title {
            background: linear-gradient(
                    90.79deg,
                    #1768d5 3.39%,
                    rgba(13, 87, 188, 0) 390.23%
                ),
                url("../../../assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png")
                    no-repeat;
            background-size: cover;
        }
        .text,
        .time {
            color: #fff;
        }
    }
}

::v-deep .el-tabs__item.is-active {
    color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
    background-color: #0d57bc;
}
</style>
