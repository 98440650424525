/*
 * @Author: your name
 * @Date: 2021-07-19 10:48:59
 * @LastEditTime: 2021-07-19 14:08:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
//  * /api@FilePath: 
 * /information-vue/code/src/Index.js
 */

/**
 * @description: 首页接口
 * @param {*}
 * @return {*}
 */

import $http from '@/plugins/http.js'

export function login(query) {
    return $http.post('/lo/login', query)
}

// 首页-专题管理 分页查询
export function ttIndexSpecialPage(params) {
    return $http.get('/homepage/ttIndexSpecial/page', params)
}
// 首页-专题管理 详情
export function ttIndexSpecial(params) {
    return $http.get('/homepage/ttIndexSpecial/' + params)
}
// 首页-专题管理-详情-查看分析报告关联
export function getAnalysisDetail(params) {
    return $http.get('/homepage/ttIndexSpecial/getAnalysisDetail', params)
}
// 首页-专题管理-详情-查看行业资讯关联
export function getIndustryDetail(params) {
    return $http.get('/homepage/ttIndexSpecial/getIndustryDetail', params)
}

// 首页-会议信息 分页查询
export function ttMeetingInfoPage(params) {
    // /api
    return $http.get('/homepage/ttMeetingInformationManagement/page', params)
}
// 首页-分析报告 分页查询
export function ttIndexAnalysisPage(params) {
    return $http.get('/homepage/ttIndexAnalysis/page', params)
}
// 首页-最新快讯 分页查询
export function ttIndustryInformationPage(params) {
    return $http.post('/homepage/ttIndustryInformation/page', params)
}

// 首页-本网观察 分页查询
export function ttLocalWatchPage(params) {
    return $http.get('/homepage/ttLocalWatch/page', params)
}
// 首页-视频 分页查询
export function ttVideoRecommendationPage(params) {
    return $http.get('/homepage/ttVideoRecommendation/page', params)
}
// 首页-高端访谈 分页查询
export function ttHighInterviewPage(params) {
    return $http.get('/homepage/ttHighInterview/page', params)
}

// 首页-本网观察 详情
export function ttLocalWatch(params) {
    return $http.get('/homepage/ttLocalWatch/' + params)
}

// 首页-视频 详情
export function ttVideoRecommendation(params) {
    return $http.get('/homepage/ttVideoRecommendation/' + params)
}

// 行业洞察-行业动态
export function marketDynamic(params) {
    return $http.get('/homepage/ttIndustryInformation/marketDynamicPage',params)
}

// 国际资讯
export function internationalPage(params) {
    return $http.get('/homepage/ttInternational/internationalPage',params)
}

// 首页-最新快讯（行业资讯）
export function ttIndustryInformationHomepage(params) {
    return $http.get('/homepage/ttIndustryInformation/homePage',params)
}

// 首页-最新快讯（flag）
export function queryHomePage(params) {
    return $http.get('/homepage/ttIndustryInformation/queryHomePage',params)
}

// 行业资讯列表 用户偏好设置
export function UserSettings(params) {
    return $http.post('/homepage/ttIndustryInformation/insertUserPreferencesSettings', params)
}

// 行业资讯列表 用户偏好查询
export function selectUserSettings(params) {
    return $http.get('/homepage/ttIndustryInformation/selectUserPreferencesSettings', params)
}

// 获取行业资讯企业
export function IndustryEnterprise(params) {
    return $http.get('/enterpriseInfo/getIndustryEnterprise', params)
}

// 首页-最新快讯（行业资讯）详情
export function ttIndustryInformation(params) {
    return $http.get('/homepage/ttIndustryInformation/' + params)
}

// 首页-国际资讯详情
export function ttInternationalInformation(params) {
    return $http.get('/homepage/ttInternational/' + params)
}
// 首页-分析报告 详情
export function ttIndexAnalysis(params) {
    return $http.get('/homepage/ttIndexAnalysis/' + params)
}
// 首页-高端访谈 详情
export function ttHighInterview(params) {
    return $http.get('/homepage/ttHighInterview/' + params)
}

// 首页-行业咨询 分组查询
export function selectByGroup() {
    return $http.get('/homepage/ttIndustryInformation/selectByGroup')
}
// 首页-分析报告 分组查询
export function ttIndexAnalysissGroup() {
    return $http.get('/homepage/ttIndexAnalysis/selectByGroup')
}
// 首页-置顶视频
export function getTopVideoList() {
    return $http.get('/homepage/ttVideoRecommendation/getTopVideoList')
}
//首页轮播图
export function ttOtherPage(params) {
    return $http.get('/homepage/rotation/ttOther/page', params)
}
//会议主办方
export function getMeetingMain() {
    // /api
    return $http.get('/homepage/ttMeetingInformationManagement/getMeetingMain')
}

//问卷调查
export function confidenceIndex(params) {
    return $http.get('/homepage/confidenceIndex/page', params)
}

//问卷调查详情
export function confidenceIndexDetail(params) {
    return $http.get('/homepage/confidenceIndex/queryById/'+params)
}

//本网精选 分页查询
export function ttWebsiteSelectedPage(params) {
    // /api
    return $http.get('/homePage/ttWebsiteSelected/page', params)
}
//本网精选 详情
export function ttWebsiteSelectedFind(params) {
    // /api
    return $http.get('/homePage/ttWebsiteSelected/find/' + params)
}
//广告管理
export function ttAdvertisingManagementPage(params) {
    // /api
    return $http.get('/homePage/ttAdvertisingManagement/page/', params)
}
//全部专题统计数量
export function selectCount() {
    return $http.get('/homepage/ttIndexSpecial/selectCount')
}
//友情链接
export function ttLinksPage(params) {
    // /api
    return $http.get('/homepage/ttLinks/page', params)
}
//市场人员列表
// export function UserRole() {
//     return $http.get('/api/sys/user/UserRole')
// }
//查询已推到首页的标准
export function queryNewStandard(params) {
    return $http.get('/api/standard/ttStandard/queryNewStandard', params)
}
//查询已推到首页的标准详情
export function queryContentById(params) {
    return $http.get('/api/standard/ttStandard/queryContentById/' + params)
}

//查询已推到首页的政策
export function queryNewPolicy(params) {
    return $http.get('/api/policy/ttPolicy/queryNewPolicy', params)
}
//查询已推到首页的政策详情
export function queryContentByIdPolicy(params) {
    return $http.get('/api/policy/ttPolicy/queryContentById/' + params)
}
//国内产销数据批发量/零售量
export function YkbQcCxDataChart(params) {
    return $http.get('/api/english/YkbQcCxData/chartChinese', params)
}

//分车型数据
export function getTableData(params) {
    return $http.get('/api/english/YkbQcPPData/getTableDataChinese', params)
}

//会议信息详情
export function ttMeetingInformationManagementDetail(params) {
    return $http.get('/homepage/ttMeetingInformationManagement/' + params)
}

//首页政策指标数
export function fetchHomePolicyCount() {
    return $http.get('/api/policy/ttPolicy/queryHomePolicyCount')
}

//首页标准指标数
export function fetchHomeStandardCount() {
    return $http.get('/api/standard/ttStandard/queryHomeStandardCount')
}

//首页数据看板
export function fetchHomeReportData(params) {
    return $http.post('/api/newData/displayReport/displayReportData', params)
}

//首页国际化出口数据
export function fetchHomeInternational() {
    return $http.get('/api/international/chinaExport/homeData')
}

//行业资讯弹窗
export function getFirstUser(params) {
    return $http.post('/lo/getFirstUser', params)
}
