<template>
    <div class="index-container">
        <div class="bottom-all">
            <div class="bottom-all-left">
                <div class="bottom-all-left-title">
                    <h1>行业资讯</h1>
<!--                    <router-link :to="{-->
<!--                        path: '/index/list/IndustryAndReportList',-->
<!--                        query: {-->
<!--                          releaseColumn: '',-->
<!--                          category: 'industry',-->
<!--                        },-->
<!--                      }" tag="div" class="more">查看更多<img src="@/assets/img/Component123h.png" alt="" class="more-icon"></router-link>-->
                </div>
                <ul class="bottom-all-left-list">
                    <router-link :to="{
                          path: '/index/detail/IndustryDetail/' + item.id,
                          query: {
                            id: item.id,
                            releaseColumn: item.releaseColumn,
                            category: 'industry',
                          },
                        }" tag="li" v-for="item in hyzxData" :key="'hyzx'+item.id">
                        <img :src="item.coverUrl" alt="" width="285" height="177">
                        <div class="bottom-all-left-inner">
                            <h1 class="text-overflow" v-if="item.shortTitle !=null && item.shortTitle !=''"> {{ htmlRestore(item.shortTitle) }}</h1>
                            <h1 class="text-overflow" v-else> {{ htmlRestore(item.title) }}</h1>
                            <p class="text-overflow-2">{{htmlRestore(item.introduction)}}</p>
                            <div class="bottom-all-left-text">
                                <span>所属领域：{{item.releaseColumn}}</span>
                                <span>{{item.updateTime}}</span>
                            </div>
                        </div>
                    </router-link>
                </ul>
              <div class="jz">
                <div class="jz-more" @click="jzMoreBtn()">
                  <span>{{ loadinginName }} <i :class="loadingIcon"></i></span>
                </div>
              </div>
            </div>
        </div>


        <div class="footer">
            <img src="@/assets/img/Group 446.png" alt="" width="201">
            <h1>富奥公司版权所有，未经书面授权，所有页面内容不得以任何介质擅自进行复制或镜像。</h1>
        </div>
    </div>
</template>

<script>
import More from "@/components/more";
import { getYearMonth, fmtDate } from "@/utils/date.js";

// 获取首页请求接口
import { selectByGroup, ttIndexAnalysissGroup,ttIndustryInformationHomepage } from "@/api/Index.js";
export default {
    components: {
        More,
    },
    data() {
        return {
            selectByGroupData: [],
            ttIndexAnalysissGroupData: [],
            hyzxPage: {
                pageSize: 5,
                page: 1,
            },
            hyzxData: [],
            loadinginName: "点击加载更多",
          loadingIcon:'el-icon-refresh',
        };
    },
    created() {
        this.init();
         this.hyzx();
    },
    methods: {
        //初始化数据
        init() {
            //行业咨询分组
            selectByGroup().then((res) => {
                if (res.ok) {
                    this.selectByGroupData = res.data;
                }
            });
            //分析报告分组
            ttIndexAnalysissGroup().then((res) => {
                if (res.ok) {
                    this.ttIndexAnalysissGroupData = res.data;
                }
            });

        },
        //行业咨询
        hyzx() {
            let page = { ...this.hyzxPage };
            this.loadinginName = "加载中......";
            ttIndustryInformationHomepage(page).then((res) => {
                if (res.ok) {
                    if (res.data.list.length > 0) {
                        this.loadinginName = "点击加载更多";
                    } else {
                        this.loadinginName = "已经没有啦";
                    }
                    this.hyzxData = this.hyzxData.concat(res.data.list);
                    this.hyzxData.forEach((item) => {
                        item.updateTime = fmtDate(
                            "YYYY-mm-dd",
                            item.updateTime
                        );
                    });
                } else {
                    this.loadinginName = "加载失败";
                }
            });
        },
         //加载更多
        jzMoreBtn() {
            this.hyzxPage.page++;
            this.hyzx();
        },
    },
};
</script>
<style lang="scss" scoped>
.bottom-all {
    max-width: 1200px;
    margin: 29px auto 40px;
}
.bottom-all {
    margin-bottom: 0;
}
.more {
    display: flex;
    align-items: center;
    color: #828282;
    font-size: 14px;
    font-weight: 500;
    &-icon {
        padding-left: 2px;
    }
}
.index-container {
    padding: 0 30px;
    .title {
        font-size: 22px;
        line-height: 24px;
        color: #333;
        margin: 25px 0 25px 28px;
    }
}
// .list-item-container {
//     display: flex;
//     flex-wrap: wrap;
//     background: #fff;
//     padding: 14px 10px 22px 19px;
//     height: 538px;
//     .list-item-box {
//         width: 33.3333%;
//         padding: 0 8px 6px;
//     }
// }
// .list-item {
//     // padding-left: 27px;
//     // padding-right: 34px;
//     .list-head {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         h1 {
//             font-size: 22px;
//             line-height: 24px;
//             color: #333;
//             margin-top: 15px;
//             margin-bottom: 26px;
//         }
//     }
//     .list-content {
//         li {
//             font-size: 14px;
//             line-height: 21px;
//             color: #4f4f4f;
//             border-bottom: 1px solid #f2f2f2;
//             padding-bottom: 2px;
//             margin-bottom: 12px;
//             cursor: pointer;
//         }
//     }
// }
.bottom-all {
    display: flex;
    justify-content: space-between;
    &-left {
        width: 1200px;
        &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-weight: bold;
                font-size: 24px;
                line-height: 19px;
                color: #0d57bc;
            }
        }
        &-list {
            cursor: pointer;
            li {
                display: flex;
                padding: 17px 41px 17px 0;
                border-bottom: 1px solid #ebebeb;
            }
        }
        &-inner {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 16px;
            overflow: hidden;
            h1 {
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                color: #000;
                padding-left: 8px;
                margin-bottom: 10px;
                margin-right: 13px;
                background: #efefef;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: #000;
            }
        }
        &-text {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #000;
            margin-top: auto;
        }
      .jz {
        display: flex;
        justify-content: center;
        margin: 30px 0 10px 0;
      }
      .jz-more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 185px;
        height: 42px;
        text-align: center;
        font-weight: 500;
        color: #999999;
        cursor: pointer;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
      }
    }
    //&-right {
    //    width: 298px;
    //    &-title {
    //        display: flex;
    //        justify-content: space-between;
    //        align-content: center;
    //        h1 {
    //            font-weight: bold;
    //            font-size: 20px;
    //            line-height: 15px;
    //            color: #0d57bc;
    //        }
    //    }
    //}
    .rdzt-box {
        margin-top: 16px;
        margin-bottom: 40px;
        .rdzt-img-box {
            display: block;
            position: relative;
            width: 100%;
            height: 94px;
            margin: 10px 0;
            cursor: pointer;
            .rdzt-img-tag {
                position: absolute;
                top: 18px;
                left: 12px;
                padding: 0px 10px;
                font-size: 14px;
                color: #0d57bc;
                background: #fff;
                font-weight: bold;
            }
            img {
                width: 100%;
                height: 100%;
            }
            .zt-title {
                position: absolute;
                top: 50px;
                left: 0;
                width: 100%;
                padding: 0 12px;
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;
                color: #fff;
            }
        }
        .rdzt-img-line {
            width: 100%;
            height: 1px;
            background: #e0e0e0;
            margin: 20px 0;
        }
    }
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 176px;
    background: #f2f2f2;
    margin-top: 25px;
    h1 {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #4f4f4f;
    }
}
</style>
