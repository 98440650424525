import Vue from "vue"
const state = {
    loading: false,
}
const mutations = {
    SET_LOADING(state, loading) {
        let layoutMain = document.getElementById('layoutMain')

        if (loading) {
            Vue.nextTick(function () {
                layoutMain.style.overflow = 'hidden'
            })
        } else {
            Vue.nextTick(function () {
                layoutMain.style.overflow = ''
            })
        }
        state.loading = loading
    },
}

const actions = {
    loading({ commit }, loading) {
        commit('SET_LOADING', loading)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}