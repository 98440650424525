<template>
  <PageContainer>
    <div class="g-content-table">
      <div class="search-form">
        <div style="position: absolute;right: 20px;">

          <el-button class="text-right" style="padding-right: 20px" type="primary" v-has-role="'freeusers'" plain
                     @click="exportEvent('echartsTable','企业预测完成情况和趋势分析')">
            导出
          </el-button>

        </div>
      </div>
      <div class="g-content-top" style="padding-bottom: 20px">
        <div flex="1">
          <!-- {{ cycz }} -->
          <div><span class="text-left"
                     style="font-size: 18px;color: #0f0f0f;font-weight: bold">{{ new Date().getFullYear() }}年企业预测完成情况和趋势分析&nbsp;</span>
            <el-tooltip class="item" effect="dark" :content="remark" placement="top-start">
              <a style="margin-right: 10px;color: #606266;font-size: 12px;" href="javascript:;"><i
                  class="el-icon-info"></i>预测说明</a>
            </el-tooltip>

          </div>
        </div>
      </div>
      <template v-if="show && !showP">
        <div id="qyChart"></div>
        <div style="margin: 0 60px 60px 60px" v-has-role="'freeusers'">
          <!-- 表格数据 -->

          <div>
            <el-table :data="qyList" :border="true" size="small"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }" id="echartsTable">
              <el-table-column :label="item" v-for="(item, index) in qyTitle" :key="item" align="center"
                               style="user-select: initial">
                <template slot-scope="scope">
                  <span v-for="(item2, index2) in scope.row" :key="index2">
                    <span v-if="index2 == index">
                      {{ scope.row[index2] }}
                    </span>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/qy1.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height: 94px;bottom:500px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:300px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 10px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>

            </div>
          </div>
        </center>

      </div>

      <template>
        <div class="g-content-top" style="height: 50px;">
          <!--        <div flex="1">-->
          <!-- {{ cycz }} -->
          <el-button class="text-right" style="float: right;" type="primary" v-has-role="'freeusers'" plain
                     @click="exportEvent('echartsYcTable','预测销量企业排行榜')">
            导出
          </el-button>
          <!--        </div>-->
        </div>
        <template v-if="show && !showP">

          <div id="qyYcChart" style="float: left"></div>
          <div id="qyYwcChart" style="float: right"></div>

          <div style="margin: 0 60px 60px 60px;display: none" v-has-role="'freeusers'">
            <!-- 表格数据 -->

            <div>
              <el-table :data="qyYcList" :border="true" size="small"
                        :header-cell-style="{ background: '#eef1f6', color: '#606266' }" id="echartsYcTable">
                <el-table-column :label="item" v-for="(item, index) in qyYcTitle" :key="item" align="center"
                                 style="user-select: initial">
                  <template slot-scope="scope">
                  <span v-for="(item2, index2) in scope.row" :key="index2">
                    <span v-if="index2 == index">
                      {{ scope.row[index2] }}
                    </span>
                  </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
        </template>
        <div v-else>
          <img style="width:100%" src="@/assets/img/qy2.jpg" alt=""/>
          <center style="height: 1px;">
            <div
                style="position:relative;width:300px;height: 94px;bottom:400px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
                v-if="!show">

              <div style="padding-top: 20px">
                <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
              </div>

              <div style="margin: 10px">
                没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

              </div>
            </div>
            <div
                style="position:relative;width:320px;height:94px;bottom:300px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                v-else>
              <div style="padding-top: 20px;padding-left: 22px;">
                <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
              </div>
              <div style="padding: 10px 20px;text-align: center">
                <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>

              </div>
            </div>
          </center>

        </div>
      </template>
    </div>
  </PageContainer>
</template>
<script>
import {getGraphData, getRemark, selectQyPredict, selectQyYcSales, selectQyYwcSales} from "@/api/sale.js";
import * as echarts from "echarts";
import JsonExcel from "vue-json-excel";
import {getUserInfo, logRecords, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      chartData: [],
      qyTitle: [],
      qyList: [],
      qyYcTitle: [],
      qyYcList: [],
      qyYwcTitle: [],
      qyYwcList: [],
      json_fields: {
        企业: "shortName",
        已完成销量: {
          field: "finishedSales",
          callback: (value) => {
            return `${value}万辆`;
          },
        },
        全年预测销量: {
          field: "forecastSales",
          callback: (value) => {
            return `${value}万辆`;
          },
        },
      },
      remark: "企业销量预测采取专家预测法，重点参考了各企业年度销量目标，新产品推出力度等关键指标，仅供参考",
      time: "",
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "0",
        columnName: "预测",
        userId: ""
      },
    };
  },
  mounted() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        } else {
          if (getUserInfo().roleKey == "trialrole") {
            this.showP = true;
          }
        }
      }
      this.show = true;
      this.init()

    } else {
      this.show = false;
    }

  },

  methods: {
    init() {
      selectQyPredict().then((res) => {
        if (res.ok) {
          let myChart = echarts.init(document.getElementById("qyChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);

          data.xAxisData.unshift("分类");
          this.qyTitle = data.xAxisData;
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.qyList.push(data.seriesData[x]);
          }

        }
      });

      selectQyYcSales().then((res) => {
        if (res.ok) {
          let myChart = echarts.init(document.getElementById("qyYcChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);

          data.xAxisData.unshift("分类");
          this.qyYcTitle = data.xAxisData;
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.qyYcList.push(data.seriesData[x]);
          }

        }
      });

      selectQyYwcSales().then((res) => {
        if (res.ok) {
          let myChart = echarts.init(document.getElementById("qyYwcChart"));
          let data = res.data;
          let cycTemplateFile = data.cycTemplateFile;
          eval(cycTemplateFile);

          data.xAxisData.unshift("分类");
          this.qyYwcTitle = data.xAxisData;
          for (var x = 0; x < data.seriesData.length; x++) {
            data.seriesData[x].unshift(data.seriesDataName[x]);
            this.qyYwcList.push(data.seriesData[x]);
          }

        }
      });
    },

    exportEvent(id, title) {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        logRecords('预测','企业预测','3','tt_enterprise_forecast_data_upload','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              let grid = XLSX.utils.table_to_book(
                  document.querySelector("#" + id),
                  {raw: true}
              );
              let workbook = XLSX.write(grid, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(
                    new Blob([workbook], {
                      type: "application/octet-stream",
                    }),
                    title + ".xlsx"
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, workbook);
              }
              return workbook;
            }

          }

        } else {
          let grid = XLSX.utils.table_to_book(
              document.querySelector("#" + id),
              {raw: true}
          );
          let workbook = XLSX.write(grid, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                new Blob([workbook], {
                  type: "application/octet-stream",
                }),
                title + ".xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, workbook);
          }
          return workbook;
        }
      } else {
        this.showLogin();
      }


    },
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    exports() {

      if (getUserInfo()) {

        logRecords('预测','企业预测','3','tt_enterprise_forecast_data_upload','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            }
          }

        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    initChart(data) {
      var myChart = echarts.init(document.getElementById("monthChart"));
      let xData = data.map((item) => item.shortName);
      let finishedSales = data.map((item) => item.finishedSales);
      let forecastSales = data.map((item) => item.forecastSales - item.finishedSales);

      var option = {
        color: ["#2DC6C8", "#B6A2DD"],
        title: {
          subtext: "万辆",
        },
        legend: {
          data: ['已完成销量', '全年预测销量'],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (datas) {
            var res = datas[0].name + "<br/>",
                val,
                cas;
            for (
                var i = 0, length = datas.length;
                i < length;
                i++
            ) {
              if (datas[i].seriesName == "已完成销量") {

                cas = datas[i].value;
                val =
                    datas[i].marker +
                    "已完成销量" +
                    datas[i].value +
                    "万辆" +
                    "<br>";
                res += val;
              }
              if (datas[i].seriesName == "全年预测销量") {
                cas = cas + datas[i].value;
                val =
                    datas[i].marker +
                    "全年预测销量" +
                    cas +
                    "万辆" +
                    "<br>";
                res += val;
              }
            }
            return res;
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {show: true},
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true}

          }
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLabel: {
              interval: 0,
              rotate: 40,
              margin: 8,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {color: "#7DABB0"}, // y轴坐标轴颜色
            },
          },
        ],
        series: [
          {
            name: "已完成销量",
            type: "bar",
            stack: "Search Engine",
            data: finishedSales,
          },
          {
            name: "全年预测销量",
            type: "bar",
            stack: "Search Engine",
            data: forecastSales,
          },
        ],
      };
      myChart.clear();
      myChart.setOption(option, true);
    },
    getGraphData() {
      getGraphData().then((res) => {
        if (res.ok) {
          this.chartData = res.data;
          if (this.show) {
            this.initChart(res.data);

          }
        }
      });
    },
    getRemark() {
      getRemark().then((res) => {
        this.remark = '企业销量预测采取专家预测法，重点参考了各企业年度销量目标，新产品推出力度等关键指标，仅供参考';
        this.time = res.data.time;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#qyChart, #qyYcChart, #qyYwcChart {
  width: 100%;
  height: 500px;
}

#qyYcChart, #qyYwcChart {
  width: 50%;
  height: 500px;
}

//.excelBtn {
//  padding: 7px 20px;
//  display: inline;
//}

//.search-form {
//  height: 40px;
//}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}
</style>
