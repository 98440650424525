<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" :isCollection="false" />

    <template slot="right">
      <VcontentItem title="相关推荐" type="list" :list="kx" @click="tohref" />
    </template>
  </PageContainer>
</template>
<script>
import { ttIndustryInformation } from "@/api/Index.js";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "keywordsLabel",
        htmlContent: "content",
        summary: "introduction",
      },
      kx: [],
    };
  },
    watch: {
    $route(to, from) {
      let id = to.params.id;
      this.ttIndustryInformation(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.ttIndustryInformation(id);
  },
  methods: {
    ttIndustryInformation(params) {
      ttIndustryInformation(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.kx = res.data.list;
        }
      });
    },
    tohref(item) {
      this.$router.push( '/index/detail/industryInformationDetail/' + item.id );
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
