<!--
 * @Author: your name
 * @Date: 2021-07-23 09:01:08
 * @LastEditTime: 2021-07-23 13:28:01
 * @LastEditors: Please set LastEditors
 * @Description: 企业分析页面
 * @FilePath: /information-vue/code/src/views/workInformation/analysis/index.vue
-->

<template>
  <router-view v-if="$route.name == '企业分析详情'" />
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="企业情报" text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新企业情报" :showTips="showTips" />-->
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs action-nav-information="Analysis" />
      </div>
    </template>
    <!--    <template slot="actionNav">-->
    <!--      <div class="tabs">-->
    <!--        <el-tabs v-model="contentCategory" :before-leave="leaveTab" @tab-click="tabClick">-->
    <!--          <el-tab-pane :key="'qb'" label="全部(原创+第三方)" name=" " />-->
    <!--          <el-tab-pane :key="'yc'" label="精选原创" name="yc" />-->

    <!--        </el-tabs>-->
    <!--      </div>-->
    <!--    </template>-->
    <template>
      <template slot="right">
        <div class="index-banner-title">
          <div class="index-banner-hot">
            <div class="title-tag">
              <h3 class="title-tag-text" style=""><span><img src="@/assets/img/tjicon.png" alt=""></span>原创推荐报告
              </h3>
              <div class="more" @click="moreClick2">查看更多 <i class="el-icon-arrow-right" /></div>
            </div>
            <ul class="index-new-timeLine">
              <li v-for="(item, index) in ycnewList" :key="item.id">
                <div class="index-new-timeLine-content">
                  <div id="index-new-link" style="cursor: pointer" class="index-new-link" @click="toHref(item)">
                    <Tooltip :font-size="12" :title="htmlRestore(item.title)">
                      <template v-if="index == 0" slot="img">
                        <img src="@/assets/img/fire16_16.png" alt="">
                      </template>
                    </Tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div id="index-banner-new" class="index-banner-new">
            <div class="title-tag">
              <h3 class="title-tag-text"><span><img src="@/assets/img/financialReport.png" alt=""></span>最新企业财报
              </h3>
              <div class="more" @click="moreClick">查看更多 <i class="el-icon-arrow-right" /></div>
            </div>
            <ul class="index-new-timeLine">
              <li v-for="item in finaList" :key="item.id">
                <div class="index-new-timeLine-content">
                  <div style="cursor: pointer" class="index-new-link" @click="toHreffile(item.fileUrl, item)">
                    <div flex="c">
                      <div style="padding-right: 10px;"><img src="@/assets/img/fileicon.png"></div>
                      <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.fileName" />
                      <div class="publishDate">{{ item.createTime }}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <el-tabs v-model="contentCategory" :before-leave="leaveTab" @tab-click="tabClick">
        <el-tab-pane :key="'qb'" label="全部(原创+第三方)" name=" " />
        <el-tab-pane :key="'yc'" label="精选原创" name="yc" />

      </el-tabs>
      <div class="box">

        <div class="box_box">


          <div class="search-input-box" flex="sb">
            <div class="topRadio">
              <el-radio-group v-model="activeName" size="small" @change="handleClick">
                <el-radio-button label="" value="">全部企业</el-radio-button>
                <el-radio-button v-for="item in analysistypeData" :key="item.dictValue" :label="item.dictValue">{{
                    item.dictLabel }}</el-radio-button>
              </el-radio-group>
            </div>
            <div class="left-input">
              <el-input v-model="title" placeholder="输入标题" clearable size="small" @keyup.enter.native="searchForm">
                <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="searchForm" />
              </el-input>
            </div>
          </div>

        </div>
        <searchList :table-data="tableData" @toHref="toHref" />
        <div class="pages">
          <el-pagination
            v-if="pageShow"
            :current-page="pageData.pages.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageData.pages.pageSize"
            layout="total,  prev, pager, next,sizes, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, openSy} from '@/utils/auth.js'
import { selectDictDataByType } from '@/api/dict.js'
import { AnalysisQueryByPage, archiveReport, OriginalEnterpriseReport } from '@/api/enterpriseAnalysis.js'
import { fmtDate } from '@/utils/date.js'
import { buryPoint } from '@/api/common'
import actionTabs from '../components/actionTabs.vue'
import TextOverTooltip from '@/components/title/index'
import Tooltip from '@/components/toolTip.vue'
import searchList from './searchlist.vue'

export default {
  components: {
    // VnotLogin,
    actionTabs,
    TextOverTooltip,
    Tooltip,
    searchList
  },
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      activeName: '',
      showTips: [
        {
          title: '企业情报动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '企业情报库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '企业情报梳理',
          text: '专家梳理整合，真正读懂企业情报脉络'
        },
        {
          title: '企业情报大数据',
          text: '用数据深度理解企业情报结构和趋势'
        }
      ],
      data1: [
        {
          id: 1,
          title: '两会政府工作报告中的六大发发发府工作报告中的六大两会政',
          time: '2021-3-27',
          important: true
        },
        {
          id: 2,
          title: '两会政府工作报告中的六大发发发府工作报告中的六大发',
          time: '2021-3-27'
        },
        {
          id: 3,
          title: '两会政府工作报告中的六大发发发府工作报告中的六大发',
          time: '2021-3-27',
          important: true
        },
        {
          id: 4,
          title: '两会政府工作报告中的六大发发发府工作报告中的六大发',
          time: '2021-3-27'
        }
      ],
      finaList: [],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
      mngEnterpriseType: [],
      ycnewList: [],
      catagery: '',
      title: '',
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },

      contentCategory: ' ',

      analysistypeData: [],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.selectDictDataByType({ dictType: 'mng_enterprise_type' })
    this.analysistype()
    this.AnalysisQueryByPage(this.pageData.pages)
    this.searchForm()
    this.getarchiveReport()
    this.getOriginalEnterpriseReport()
  },
  methods: {
    leaveTab(activeName, oldActiveName) {
      if (!getUserInfo()) {
        return false
      }
    },
    moreClick2() {
      if (getUserInfo()) {
        this.contentCategory = 'yc'
        this.reset()
        this.searchForm()
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin()
      }
    },
    tabClick(tab) {
      if (getUserInfo()) {
        this.reset()
        this.searchForm()
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin()
      }
    },
    reset() {
      this.pageData = {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      }
      this.catefory = ''
    },
    toHreffile(newUrl, item) {
      if (getUserInfo()) {
        logRecords('企业情报', '企业财报', '2', 'tt_enterprise_financial_report', '', item.fileName, '', '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              window.open('https://www.autoinfo.org.cn/' + newUrl, '_blank')
            }
          }
        } else {
          window.open('https://www.autoinfo.org.cn/' + newUrl, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    moreClick() {
      const newUrl = this.$router.resolve('/workInformation/financialReport/index')
      window.open(newUrl.href, '_blank')
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve('/workInformation/analysis/analysisContent/' + item.id)
      if (getUserInfo()) {
        logRecords('企业情报', '企业分析报告', '1', 'tt_enterprise_analysis', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    getarchiveReport() {
      archiveReport().then((res) => {
        if (res.ok) {
          this.finaList = res.data.map(item => item.fileInfo).flat().splice(0, 10)
        }
      })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 字典管理
    selectDictDataByType(params) {
      selectDictDataByType(params).then((res) => {
        if (res.ok) {
          this.mngEnterpriseType = res.data
        }
      })
    },
    analysistype() {
      selectDictDataByType({ dictType: 'mng_ent_analysis_type' }).then(
        (res) => {
          if (res.ok) {
            this.analysistypeData = res.data
          }
        }
      )
    },
    // 企业分析
    AnalysisQueryByPage(parmas) {
      parmas.contentCategory = this.contentCategory
      AnalysisQueryByPage(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list
          this.tableData.forEach(item => {
            item.publishDate = fmtDate(
              'YYYY-mm-dd',
              item.publishDate
            )
          })
          this.pageData.total = res.data.count
        }
      })
    },
    // 原创最新报告
    getOriginalEnterpriseReport() {
      OriginalEnterpriseReport().then((res) => {
        this.ycnewList = res.data
      })
    },
    searchForm() {
      const form = Object.assign(
        { catagery: this.catagery, title: this.title },
        this.pageData.pages
      )
      if (this.title != null & this.title != '') {
        logRecords('企业情报', '企业分析报告', '4', 'tt_enterprise_analysis', '', '', '', this.values)
      }
      this.AnalysisQueryByPage(form)
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.searchForm()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {

        this.pageData.pages.pageNo = val
        this.searchForm()
      }
    },
    handleClick(e) {
      this.catagery = this.activeName
      this.pageData.pages.pageNo = 1
      this.searchForm()
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  background: #ffffff;
  padding: 20px 30px 8px 20px;

  .search-input-box {
    .box-title {
      font-size: 18px;
      color: rgb(34, 34, 34);
      font-weight: bold;
    }
  }

  .box_box_title {
    padding-bottom: 20px;

    img {
      border-radius: 4px;
    }

    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }

    .flex_col {
      height: 150px;
      padding-left: 28px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .box_yc {
        display: inline-flex;
        margin-top: 2px;
        margin-right: 8px;
        padding: 3px 4px;
        color: #fff;
        font-size: 12px;
        line-height: 1;
        white-space: nowrap;
        background: #ff6e4b;
        background-image: linear-gradient(136deg, rgb(255, 142, 93) 0%, rgba(255, 47, 47, 0.99608) 100%);
        border-radius: 2px;
      }

      .box_content {
        font-size: 14px;
        color: #828282;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .box_time {
        font-size: 12px;
        color: rgb(187, 187, 187);
      }

    }
  }

  .pages {
    display: flex;
    justify-content: center;
    padding: 26px 0;
    margin-bottom: 73px;
  }
}

::v-deep {
  .el-tabs__nav {
    float: none;
    // margin: 0 10px;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .search-input-box .el-input__inner {
    width: 350px;
  }
  .left-input {
    padding-bottom: 20px;
    padding-top: 7px;
  }
  .left-input .el-input__inner {
    background: #f3f3f3;
    border: 0;
  }
  .left-input .el-input__clear {
    position: absolute;
    right: 25px;
  }
  .el-checkbox__input {
    display: none;
  }

  .el-radio-button {
    margin-right: 10px;

    .el-radio-button__inner {
      border-radius: 22px;
      padding: 8px 25px;
      color: #999999;
      border: 1px solid #d5d5d5;
    }
  }

  .is-active .el-radio-button__inner {
    border: 1px solid rgb(63, 118, 234);
    background: #fff;
    color: rgb(63, 118, 234);
    box-shadow: none
  }
}

.topRadio {
  padding-bottom: 20px;
  padding-top: 7px;

  ::v-deep {
    .el-radio-button {
      border: 0;
      border-radius: 4px;
    }

    .el-radio-button__inner {
      border: 0;
      border-radius: 4px;
      color: #333333;
      background: #f3f3f3;
    }

    .el-radio-button:first-child {
      margin-right: 12px;
    }

    .is-active .el-radio-button__inner {
      background: #3f76ea;
      color: #fff;
    }
  }
}

.index-banner-title {
  .index-banner-hot {
    padding-bottom: 18px;
    background: #f6f6f6;

    .title-tag {
      display: flex;
      align-items: center;
      height: 55px;
      background: linear-gradient(#fff3ee, #fffbf9);
      background-size: cover;
      background-repeat: no-repeat;
    }
    #index-new-link {
      font-size: 15px;
      padding-top: 4px;
      padding-bottom: 16px;
    }
    .title-tag-text {
      flex: 1;
      height: 100%;
      padding: 0 20px;
    }

    .triangleImg {
      position: relative;
      height: 55px;
      width: 55px;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        right: -3px;
        top: 0;
        object-fit: cover;
      }

      @media screen and (min-width: 1450px) {
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 3px;
          top: 0;
          object-fit: cover;
        }
      }

      @media screen and (min-width: 1650px) {
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 7px;
          top: 0;
          object-fit: cover;
        }
      }
    }
  }

  #index-banner-new {
    background: #fff;
    padding-bottom: 18px;
    margin-top: 10px;

    .index-new-link {
      padding: 12px 0;
      border-bottom: 1px solid #f4f4f4;
    }

    .title-tag {
      padding: 22px 20px 12px 20px;
      background: linear-gradient(#cbd5f2, #fff);
    }
  }

}

.title-tag {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: baseline;

  .title-tag-text {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    span {
      padding-right: 10px;
      font-weight: bold;
      color: #0d57bc;
    }
  }

  //.more {
  //  color: #999999;
  //}
}

.index-new-timeLine {
  padding: 0 20px 17px 20px;
  background: #fff;

  li {
    .index-new-timeLine-content {
      position: relative;

      .index-new-link {
        padding-bottom: 16px;
        display: block;
        color: #000;

        // padding-left: 20px;
        .publishDate {
          color: #e6e6e6;
        }

        span {
          font-size: 16px;
          padding-right: 10px;
          font-weight: 600;
        }

        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.newUl-title {
  flex: 1;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding-right: 24px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}</style>
