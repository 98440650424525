<template>
  <div>
    <router-view></router-view>
    <PageContainer breadcrumb footer>
      <Vcontent :data="data" :props="props" isContentTwo="bz" name="标准" @commond="commond" />

      <template slot="right">
        <VcontentItem
            title="相关征求意见稿"
            type="list"
            :list="relationComment"
            @click="tohref"
        />
        <VcontentItem
            title="相关标准动态"
            type="list"
            :list="relationDynamic"
            @click="tohref"
        />
        <VcontentItem
            title="相关标准解读"
            type="list"
            :list="relationInterpret"
            @click="tohref"
        />
        <VcontentItem
            title="相关标准主体"
            type="list"
            :list="relationSelf"
            @click="tohref"
        />
        <VcontentItem
            title="最新标准推荐"
            type="list"
            :list="tableDataRight"
            @click="toHref"
        />
      </template>
    </PageContainer>
  </div>
</template>
<script>
import {newStandard, ttStandard, ttStandardCode} from "@/api/standards.js";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "applicableProducts",
        htmlContent: "content",
      },
      relationComment: [],
      relationDynamic: [],
      relationInterpret: [],
      relationSelf: [],
      tableDataRight: []

    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      let code = to.query.code;
      //console.log(to)
      if (id) {
        this.ttStandard(id);
      } else {
        this.ttStandardCode({code});
      }

      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    let code = this.$route.query.code;
    if (id) {
      this.ttStandard(id);
    } else {
      this.ttStandardCode({code});
    }
    this.localOriginalRight({page: 1, pageSize: 10});

  },
  methods: {
    commond(v) {
      //console.log(v);
     this.$router.push('/standards/dynamic/ttStandard/code?code=' + v.code.code )
    },

    ttStandard(params) {
      ttStandard(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relationComment = res.data.relationComment;
          this.relationDynamic = res.data.relationDynamic;
          this.relationInterpret = res.data.relationInterpret;
          this.relationSelf = res.data.relationSelf;
        }
      });
    },
    ttStandardCode(code) {
      ttStandardCode(code).then((res) => {
        //console.log(res);
        this.data = res.data;
        this.relationComment = res.data.relationComment;
        this.relationDynamic = res.data.relationDynamic;
        this.relationInterpret = res.data.relationInterpret;
        this.relationSelf = res.data.relationSelf;

      });
    },

    tohref(item) {
      if (item.tableName == "tt_solicit_comments") {
        //征求意见稿
        this.$router.push("/standards/dynamic/contentComments/" + item.id);
      } else if (item.tableName == "tt_standard_dynamic") {
        //标准动态推荐
        this.$router.push("/standards/dynamic/contentDynamic/" + item.id);
      } else if (item.tableName == "tt_standard_unscramble") {
        //标准解读推荐
        this.$router.push("/standards/dynamic/contentUnscramble/" + item.id);
      } else {
        //标准主体推荐
        this.$router.push("/standards/dynamic/newStandards/details/" + item.id);
      }
    },
    //最新标准
    localOriginalRight(param) {

      newStandard(param).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list;
          this.loading = false;
        }
      });
    },
    toHref(item) {
      this.$router.push("/standards/dynamic/newStandards/details/" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
