<template>
    <PageContainer>
        <div class="search-box">
            <div flex="sb">
                <h1  class="yshxh1">按时间查询</h1>
                <JsonExcel :data="chartsTable" v-has-role="'trialrole'" :fields="charts_fields" class="excelBtn" name="千人汽车保有量图表.xls">导出</JsonExcel>
            </div>
            <el-form >
                <el-form-item label="年份">
                    <el-select v-model="year" placeholder="请选择" size="small" @change="yearChange" style="width:90px">
                        <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    全国分省份千人汽车保有量
                </el-form-item>
            </el-form>
        </div>
        <!-- <h2 class="car-title">{{title5}}</h2> -->
        <div id="carCharts2"></div>
    </PageContainer>
</template>
<script>
import * as echarts from "echarts";
import { queryQrbyl, queryYearListq } from "@/api/data.js";
import JsonExcel from "vue-json-excel";
export default {
    components: {
        JsonExcel,
    },
    data() {
        return {
            yearList: [],
            year: "",
            title5: "",
            chartsTable: [],
            charts_fields: {
                地区: "dq",
                千人保有量: "qrbyl",
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            queryYearListq().then((res) => {
                if (res.ok) {
                    this.yearList = res.data;
                    this.year = res.data[0];
                    this.queryQrbyl(this.year);
                }
            });
        },
        queryQrbyl(year) {
            queryQrbyl({ year }).then((res) => {
                if (res.ok) {
                    let xAxis5 = res.data.xAxis5;
                    let series5All = res.data.series5;
                    this.title5 = res.data.title5;
                    let table = [];
                    xAxis5.forEach((item, index) => {
                        let obj = {};
                        obj["dq"] = item;
                        series5All.forEach((citem, cindex) => {
                            if (citem.data && citem.data.length > 0) {
                                obj["qrbyl"] = citem.data[index];
                            }
                        });
                        table.push(obj);
                    });
                    this.chartsTable = table;
                    this.echartsInit(res.data);
                }
            });
        },
        //echarts
        echartsInit(data) {
            var myChart = echarts.init(document.getElementById("carCharts2"));
            let index = data.xAxis5.indexOf("全国");
            let series5 = data.series5[0].data;
            series5[index] = {
                itemStyle: { color: "red" },
                value: series5[index],
            };
            var option = {
                title: {
                    subtext: "辆/千人",
                },
                legend: {
                    data: ["千人保有量"],
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                grid: {
                    left: 3,
                    right: 3,
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: data.xAxis5,
                        axisTick: {
                            inside: true,
                        },
                        axisLabel: {
                            rotate: 40,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                    {
                        type: "value",
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                ],
                series: data.series5,
            };
            myChart.setOption(option);
        },
        //时间下拉框选择
        yearChange(e) {
            this.queryQrbyl(e);
        },
    },
};
</script>
<style lang="scss" scope>
.yshxh1 {
    font-size: 16px;
    margin-bottom: 15px;
}
.search-box {
    margin-bottom: 15px;
}
#carCharts2 {
    width: 100%;
    height: 300px;
}
.car-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 15px;
}
</style>

