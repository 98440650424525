<template>
  <div id="app">
     <router-view />
  </div>
</template>


<script>
import './utils/ban.js';
export default {
  name: 'app',
}
</script>
