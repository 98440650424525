<template>
  <el-card class="chart-compared">
    <div slot="header" class="clearfix">
      <h3
        class="title-tag-text"
        :style="{
          fontFamily: 'MicrosoftYaHei-Bold',
          fontSize: '18px',
          fontWeight: 600,
          display: 'inline-block'
        }"
      >
        {{ cardTitle }}
      </h3>
      <span style="display: flex;">

        <span style="float: left;" v-if="$slots.cardSelect">
          <slot name="cardSelect" />
        </span>
        <div
          style="float: right;margin-left: 10px;display: flex;flex-direction: column;justify-content: center;"
          v-if="$slots.headExport"
        >
          <slot name="headExport" />
        </div>
      </span>
    </div>
    <div ref="barChart" style="width: 100%; height: 300px;"></div>
  </el-card>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      chartInstance: null
    };
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    cardTitle: {
      type: String,
      default: () => ""
    }
  },
  mounted() {
    // 确保 DOM 元素已经被渲染
    this.$nextTick(() => {
      this.chartInstance = echarts.init(this.$refs.barChart);
      this.initChart(this.chartInstance);

      // 监听窗口大小变化
      window.addEventListener("resize", () => {
        this.chartInstance.resize();
      });
    });
  },
  methods: {
    initChart(chartInstance) {
      const options = {
        title: {
          text: this.getRichTitle(),
          left: 0,
          textStyle: {
            color: "#666", // 标题颜色，需根据图片调整
            fontSize: 14, // 标题字体大小，需根据图片调整
            fontWeight: 400,
            rich: {
              a: { color: "#3f76ea" }
            }
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: this.chartData.seriesList.map(item => item.name),
          right: 0,
          selectedMode: false, // 图例不可点击
          textStyle: {
            color: "#333", // 图例文字颜色，需根据图片调整
            fontSize: 12 // 图例文字字体大小，需根据图片调整
          }
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "10%",
          bottom: "0%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: this.chartData.xAxisList,
          axisLine: {
            lineStyle: {
              color: "#ddd" // X轴线颜色，需根据图片调整
            }
          },
          axisLabel: {
              color: "#222", // X轴文字颜色，需根据图片调整
              fontSize: 12 // X轴文字字体大小，需根据图片调整
          },
          axisTick: {
            show: false // 取消 x 轴刻度线
          },
          splitLine: {
            show: false // 取消 x 轴在 grid 区域中的分割线
          }
        },
        yAxis: {
          show: false
        },
        series: this.chartData.seriesList.map((series, index) => ({
          name: series.name,
          type: "bar",
          data: series.data,
          barCategoryGap: 0, // 设置类目间隔为 0
          barWidth: "10px", // 柱状图宽度，需根据图片调整
          itemStyle: {
            // normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.getGradientColor(index)[0] },
              { offset: 1, color: this.getGradientColor(index)[1] }
            ])
            // }
          }
          // ... 其他系列配置
        }))
      };
      chartInstance.setOption(options, true); // 第二个参数设置为 true 以避免合并
    },
    getRichTitle() {
      let title = this.chartData.chartsInfoStr;
      this.chartData.seriesList.forEach(series => {
        // 替换标题中的系列名为富文本格式
        title = title.replace(
          new RegExp(series.name, "g"),
          `{a|${series.name}}`
        );
      });
      return title;
    },
    getGradientColor(index) {
      const colorMap = {
        0: ["#ff6a25", "#fcefe9"],
        1: ["#8225cd", "#f7edff"], // 示例：不同的车型可以有不同的颜色
        2: ["#5cbf65", "#effaf0"] // 示例：不同的车型可以有不同的颜色
        // ... 为其他 seriesName 定义颜色
      };
      return colorMap[index] || ["#fff", "#000"]; // 默认渐变色
    }
  },
  watch: {
    chartData: {
    deep: true,
    handler(newData) {
      if (this.chartInstance) {
        this.chartInstance.clear();
        this.initChart(this.chartInstance);
      }
    }
  }
  },

  beforeDestroy() {
  if (this.chartInstance) {
    window.removeEventListener("resize", this.chartInstance.resize);
    this.chartInstance.dispose();
  }
}
};
</script>
<style lang="scss">
.chart-compared {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
  &::after,
  &::before {
    display: none;
  }
}
</style>
