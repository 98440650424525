<template >
  <PageContainer>
    <div v-loading="loading" class="box_box">
      <!-- <el-tag
      :key="tag"
      v-for="tag in dynamicTags"
      closable
      :disable-transitions="false"
      @close="handleClose(tag.id)">
      {{tag.nodeName}}
    </el-tag> -->
      <el-tabs
        v-model="valueId"
        type="card"
        closable
        @tab-remove="removeTab"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="item in dynamicTags"
          :key="item.id + ''"
          :label="item.nodeName"
          :name="item.id + ''"
        >
        </el-tab-pane>
      </el-tabs>
      <!-- <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input> -->
      <el-button size="small" @click="addTab(valueId)"> + 新增 </el-button>
      <!-- <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >+ 新增</el-button
      > -->
      <el-select v-model="yearMonth" size="small" @change="selectChange">
        <el-option
          v-for="item in yearMonthData"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <div
        id="echartsId"
        style="width: 100%; height: 580px; margin-top: 20px"
      ></div>
    </div>

    <el-dialog title="新增模板" :visible.sync="dialogFormVisible" width="800px">
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item
          label="模板名称"
          :label-width="formLabelWidth"
          prop="textName"
        >
          <el-input
            v-model="form.textName"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="模板顺序"
          :label-width="formLabelWidth"
          prop="txtNumber"
        >
          <el-input
            v-model="form.txtNumber"
            controls-position="right"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="图表标题" :label-width="formLabelWidth">
          <el-input
            v-model="form.txtTitle"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="图表副标题" :label-width="formLabelWidth">
          <el-input
            v-model="form.txtSubTitle"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="X轴标题" :label-width="formLabelWidth">
          <el-input
            v-model="form.txtXAxisTitle"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Y轴标题" :label-width="formLabelWidth">
          <el-input
            v-model="form.txtYAxisTitle"
            autocomplete="off"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分析字段1"
          :label-width="formLabelWidth"
          prop="projectType1"
        >
          <el-select
            v-model="form.projectType1"
            placeholder="请选择分析字段"
            style="width: 500px"
          >
            <el-option label="省" value="province.keyword"></el-option>
            <el-option label="市" value="city.keyword"></el-option>
            <el-option
              label="合资自主"
              value="jointVentureAutonomy.keyword"
            ></el-option>
            <el-option label="品牌" value="brand.keyword"></el-option>
            <el-option label="车型" value="models.keyword"></el-option>
            <el-option
              label="车型分类"
              value="vehicleTypeClassification.keyword"
            ></el-option>
            <el-option
              label="车型细分"
              value="vehicleModelSegmentation.keyword"
            ></el-option>
            <el-option
              label="国产进口"
              value="domesticImports.keyword"
            ></el-option>
            <el-option
              label="排放标准"
              value="emissionStandardsInterval.keyword"
            ></el-option>
            <el-option
              label="交易价格区间"
              value="transactionPriceInterval.keyword"
            ></el-option>
            <el-option
              label="车龄区间"
              value="carAgeInterval.keyword"
            ></el-option>
            <el-option
              label="区域"
              value="provinceInterval.keyword"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="分析字段2" :label-width="formLabelWidth">
          <el-select
            v-model="form.projectType2"
            placeholder="请选择分析字段"
            style="width: 500px"
          >
            <el-option label="省" value="province.keyword"></el-option>
            <el-option label="市" value="city.keyword"></el-option>
            <el-option
              label="合资自主"
              value="jointVentureAutonomy.keyword"
            ></el-option>
            <el-option label="品牌" value="brand.keyword"></el-option>
            <el-option label="车型" value="models.keyword"></el-option>
            <el-option
              label="车型分类"
              value="vehicleTypeClassification.keyword"
            ></el-option>
            <el-option
              label="车型细分"
              value="vehicleModelSegmentation.keyword"
            ></el-option>
            <el-option
              label="国产进口"
              value="domesticImports.keyword"
            ></el-option>
            <el-option
              label="排放标准"
              value="emissionStandardsInterval.keyword"
            ></el-option>
            <el-option
              label="交易价格区间"
              value="transactionPriceInterval.keyword"
            ></el-option>
            <el-option
              label="车龄区间"
              value="carAgeInterval.keyword"
            ></el-option>
            <el-option
              label="区域"
              value="provinceInterval.keyword"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="图标类型"
          :label-width="formLabelWidth"
          prop="charsType"
        >
          <el-select
            v-model="form.charsType"
            placeholder="请选择图标类型"
            style="width: 500px"
          >
            <el-option label="柱形图" value="柱形图"></el-option>
            <el-option label="折线图" value="折线图"></el-option>

            <el-option label="饼图" value="饼图"></el-option>
            <el-option label="堆叠条形图" value="堆叠条形图"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </PageContainer>
</template>

<script>
/**
 * @description: 二手车图表
 * @param {*}
 * @return {*}
 */
import * as echarts from "echarts";
import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo } from "@/utils/auth.js";
import {
  getYearMonth,
  getEchars,
  getDefinedTags,
  saveSubmit,
  removeTab,
} from "@/api/usedCar.js";

export default {
  components: {
    VnotLogin,
  },
  data() {
    return {
      dynamicTags: [],
      // inputVisible: false,
      // inputValue: "",
      showTips: [
        {
          title: "专题分析动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "专题分析库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "专题分析梳理",
          text: "专家梳理整合，真正读懂专题分析脉络",
        },
        {
          title: "专题分析大数据",
          text: "用数据深度理解专题分析结构和趋势",
        },
      ],
      loading: false,
      yearMonthData: [],
      yearMonth: "",
      valueId: "",
      dialogFormVisible: false,
      form: {
        textName: "",
        txtNumber: "",
        txtTitle: "",
        txtSubTitle: "",
        txtXAxisTitle: "",
        txtYAxisTitle: "",
        projectType1: [],
        projectType2: [],
        charsType: [],
      },
      rules: {
        textName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        txtNumber: [
          { required: true, message: "请输入模板顺序", trigger: "blur" },
          {
            type: "number",
            min: 0,
            message: "请输入数字",
            transform(value) {
              return Number(value);
            },
            trigger: "blur",
          },
        ],
        projectType1: [
          { required: true, message: "请选择分析项目", trigger: "change" },
        ],
        charsType: [
          { required: true, message: "请选择图表类型", trigger: "change" },
        ],
      },
      formLabelWidth: "120px",
    };
  },

  created() {
    if (getUserInfo()) {
      this.show = true;
      this.init();
    } else {
      this.show = false;
    }
  },
  mounted() {
    // 页面加载设置高度自适应
    this.resizeDom();
  },
  methods: {
    addTab() {
      this.dialogFormVisible = true;
    },
    removeTab(targetName) {
      console.log(targetName);
      removeTab({ id: targetName }).then((result) => {
        if (result.ok) {
          let tabs = this.dynamicTags;
          let activeName = this.valueId;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.id + "" === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.id + "";
                }
              }
            });
          }

          this.valueId = activeName;
          this.dynamicTags = tabs.filter((tab) => tab.id + "" !== targetName);
          this.getEchars(this.valueId);
        }
      });
    },

    // handleClose(tag) {
    //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    // },

    // showInput() {
    //   this.inputVisible = true;
    //   this.$nextTick((_) => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    // },

    // handleInputConfirm() {
    //   let inputValue = this.inputValue;
    //   if (inputValue) {
    //     this.dynamicTags.push(inputValue);
    //   }
    //   this.inputVisible = false;
    //   this.inputValue = "";
    // },
    handleClick(tab, event) {
      this.valueId = tab.name;
      this.getEchars(tab.name);
    },

    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = {};

          form.textName = this.form.textName;
          form.txtNumber = this.form.txtNumber;
          form.txtTitle = this.form.txtTitle;
          form.txtSubTitle = this.form.txtSubTitle;
          form.txtXAxisTitle = this.form.txtXAxisTitle;
          form.txtYAxisTitle = this.form.txtYAxisTitle;
          form.projectType1 = this.form.projectType1;
          form.projectType2 = this.form.projectType2;
          form.charsType = this.form.charsType;
          console.log(form);
          saveSubmit(form).then((result) => {
            if (result.ok) {
              this.dialogFormVisible = false;
              // let newTabName = ++this.tabIndex + "";
              this.dynamicTags.push(result.data);
              console.log(result.data);
              this.valueId = result.data.id + "";
              this.loading = false;
              this.getEchars(result.data.id);
            }
          });
        }
      });
    },

    selectChange(yearMonth) {
      this.yearMonth = yearMonth;
      this.getEchars(this.valueId);
    },

    async init() {
      this.loading = true;
      await getDefinedTags().then((result) => {
        if (result.ok) {
          let data = result.data;
          this.dynamicTags = data;

          if (data.length != 0) {
            this.valueId = data[0].id + "";
          }
        }
      });
      //获取年月
      await getYearMonth().then((result) => {
        if (result.ok) {
          this.yearMonthData = result.data;
          this.yearMonth = this.yearMonthData[0];
        }
      });
      if (this.valueId != "" && this.valueId != 0) {
        //获取图表
        await getEchars({ id: this.valueId, nianYue: this.yearMonth }).then(
          (result) => {
            let myChart = echarts.init(document.getElementById("echartsId"));

            if (result.ok) {
              let data = result.data;
              let templateFile = data.templateFile;
              eval(templateFile);
              this.loading = false;
            }
          }
        );
      }else{

         this.loading = false;
      }
    },
    //获取年月
    getYearMonth() {
      getYearMonth().then((result) => {
        if (result.ok) {
          this.yearMonthData = result.data;
          this.yearMonth = this.yearMonthData[0];
        }
      });
    },
    //获取图表
    getEchars(id) {
      this.loading = true;
      getEchars({ id: id, nianYue: this.yearMonth }).then((result) => {
        let myChart = echarts.init(document.getElementById("echartsId"));

        if (result.ok) {
          let data = result.data;
          let templateFile = data.templateFile;
          eval(templateFile);
          this.loading = false;
        }
      });
    },
    // //获取自定义标签
    // getDefinedTags() {
    //   getDefinedTags().then((result) => {
    //     if (result.ok) {
    //       let data = result.data;
    //       this.dynamicTags = data;
    //       this.valueId = data[0].id;
    //     }
    //   });
    // },
    //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度以达到自适应目的
    resizeDom() {
      var backPersonDom = document.getElementById("echartsId");
      const boxWidth = document.getElementById("echartsId").clientWidth;
      backPersonDom.style.height = boxWidth / 3 + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.box_box {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: #ffffff;
  /deep/ .el-input__inner {
    width: 90px;
    margin-left: 10px;
  }
}
.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;
  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }
  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.lable-box {
  margin-bottom: -10px;
  .el-checkbox {
    margin-bottom: 10px;
  }
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
