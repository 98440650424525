<!--新增历史数据表格-->
<template>
    <PageContainer>
        <div style="font-weight: 700;line-height: 30px;margin-bottom: 20px;font-size: 16px">历史数据</div>
        <span>时间：</span>
        <el-select v-model="hisForm.nian" placeholder="请选择" size="small">
            <el-option v-for="item in historyYear" :key="item.nian" :label="item.nian" :value="item.nian">
            </el-option>
        </el-select>
        <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px">
            <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-button size="small" type="primary" style="margin-left:5px" @click="hisSearch">查询</el-button>
        <div style="padding-top:10px;min-height: 300px" v-loading="loading" >
            <table border="1" style="width: 100%;padding: 5px" class="hisTable">

                <tr >
                    <td >{{year}}年{{month}}月份汽车(分车型)产销汇总表（电子版）</td>
                    <td >{{year}}年{{month}}月份乘用车产销汇总表</td>
                    <td >{{year}}年{{month}}月份乘用车(分车型)产销情况表 </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份乘用车生产企业产销情况表（电子版）</td>
                    <td  @click="syccxhz(hv)">{{year}}年{{month}}月份商用车产销汇总表123</td>
                    <td >{{year}}年{{month}}月份商用车生产企业产销情况表（电子版） </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份全国乘用车主要品牌产销情况表</td>
                    <td >{{year}}年{{month}}月份基本型乘用车主要品牌产销情况表（电子版）</td>
                    <td >{{year}}年{{month}}月份MPV主要品牌产销情况表（电子版） </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份SUV主要品牌产销情况表（电子版）</td>
                    <td >{{year}}年{{month}}月份交叉型乘用车主要品牌产销情况表（电子版））</td>
                    <td >{{year}}年{{month}}月份客车生产企业产销情况表（电子版） </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份载货车生产企业产销情况表（电子版）</td>
                    <td >{{year}}年{{month}}月份发动机生产企业产销情况表 (台)</td>
                    <td >{{year}}年{{month}}月份汽车发动机(按燃料分类)生产企业产销情况表 (台)（电子版） </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份新能源汽车产销情况表</td>
                    <td >{{year}}年{{month}}月份新能源商用车产销情况表</td>
                    <td >{{year}}年{{month}}月份新能源乘用车产销情况表 </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份客车(分燃料)产销情况表</td>
                    <td >{{year}}年{{month}}月份客车非完整车辆(分车型)产销情况表</td>
                    <td >{{year}}年{{month}}月份半挂牵引车(分车型)产销汇总表 </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份载货车(分燃料)产销情况表</td>
                    <td >{{year}}年{{month}}月份货车非完整车辆(分车型)产销情况表</td>
                    <td >{{year}}年{{month}}月份汽车分企业出口情况表 </td>
                </tr>
                <tr >
                    <td >{{year}}年{{month}}月份五大汽车企业集团乘用车产销情况表</td>
                    <td >{{year}}年{{month}}月份五大汽车企业集团商用车产销情况表</td>
                    <td >{{year}}年{{month}}月份汽车生产主要企业产销情况表</td>
                </tr>

            </table>
        </div>


    </PageContainer>
</template>
<script>
    import {
        getYearListHistory,
        getHistoryPage,
        getAdd,
    } from "@/api/data.js";

    export default {
        data() {
            return {
                year: '2020',
                month: '12',
                number: 0,
                historyVisible: false,
                loading: false,
                historyYear: [],
                historyYue: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                ],
                hisForm: {
                    nian: "",
                    yue: "02",
                    page: 1,
                    pageSize: 30,
                    count: 0
                },
                historyList: [],
                hv:'',
            };
        },
        created() {
            this.init();
            this.getA();// 实验的用法
           // this.tdSlectionClick();
            var tableTds= $("td");
            tableTds.click(this.tdSlectionClick())
        },
        mounted() {
        },
        methods: {
            getA() {
                getAdd().then((res) => {
                    console.log('res')
                })
            },
            change(index) {
                this.number = index; //重要处
            },

            init() {
                getYearListHistory().then((res) => {
                    // console.log('获取的后台数据为', res);
                    if (res.ok) {
                        this.historyYear = res.data;
                        this.hisForm.nian = res.data[0].nian;
                        // this.getHistoryPage();
                    }
                });
            },
            getHistoryPage() {
                this.loading = true;
                let form = Object.assign({}, this.hisForm);
                getHistoryPage(form).then((res) => {
                    c
                    this.loading = false;
                    if (res.ok) {
                        let data = res.data.list;
                        var arr = [];
                        for (var i = 0; i < data.length; i++) {
                            if (i % 3 === 0) {
                                var a = [];
                                arr.push(a);
                            }
                            a.push(data[i]);
                        }
                        this.historyList = arr;
                        this.hisForm.count = res.data.count
                    }
                }).catch(e => {
                    this.loading = false
                });
            },
            hishandleCurrentChange(v) {
                this.hisForm.page = v;
                this.getHistoryPage()
            },
            hisSearch() {
                // console.log('this.hisForm', this.hisForm);
                this.year = this.hisForm.nian;
                this.month = this.hisForm.yue;
                 this.hv = this.hisForm.nian + this.hisForm.yue+"01";
                console.log('hv', this.hv);
                // this.getHistoryPage()
            },
            openIframe(item) {
                console.log('111111');
               // window.open(item.url)
                // this.iframeSrc = item.url;
                // this.iframeVisible = true;
            },
            syccxhz(){
                this.hv = this.hisForm.nian + this.hisForm.yue+"01";
                this.$router.push(
                    {
                        path: '/syccxhz',
                        query: {
                            data:this.hv
                        },
                    }
                );
                // console.log('this.hv',this.hv)

            },
            tdSlectionClick() {
                var row= $(this).parent("tr").prevAll().length;
                var column = $(this).prevAll().length;
                console.log(Number(row)+1,Number(column)+1);
            },
        }
    }
</script>
<style lang="scss" scoped>
    .outer-layer-box {
        padding: 20px;
        background-color: white;
        margin-top: 20px;
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #0d57bc;
    }

    .search-form {
        padding: 28px;
        background: #fff;
        margin-bottom: 10px;

        /deep/ {
            .el-date-editor .el-range-separator {
                width: 12%;
            }

            .el-checkbox-group {
                line-height: 34px;
            }

            .el-checkbox {
                margin-right: 16px;
            }

            .el-form-item__content {
                width: 179px;
            }

            .el-card {
                overflow: visible;
            }
        }

        .search-form-title {
            text-align: center;
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
.hisTable{
        margin-top: 20px;
        margin-bottom: 20px;
        color:  #0d57bc;
        font-size: 14px;
}
 .hisTable td{
     padding: 5px;
     cursor:pointer;
 }
</style>
