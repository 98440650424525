//战略报告
import $http from '@/plugins/http.js'
import cookie from '@/plugins/cookie.js'

//通用下载请求
export function downloadStrategy(params){
    return $http.Downloadget('/api/strategyReport/download',params)
}

export function ttStrategyReport(params) {
    return $http.get('/api/strategyReport/find/',params)
}
export function getPhone(email) {
    return $http.get('api/strategyReport/phone', {email: email})
}

export function getMsg(customerId, email, phone, action) {
    let params = {
        customerId: customerId,
        email: email,
        phone: phone,
        action: action,
    }
    return $http.get('/api/strategyReport/msg', params)
}

export function getSearchList(weekProduct, dateList, classifyList, fieldList) {
    let strategyReportDate = {
        weekProduct: weekProduct,
        dateList: dateList,
        classifyList: classifyList,
        fieldList: fieldList,
    }
    return $http.post('/api/strategyReport/getSearchList', strategyReportDate)
}

export function getClassifyList() {
    return $http.post('/api/strategyReport/getClassifyList')
}

export function getEmailAccountInfo() {
    return JSON.parse(cookie.get('emailAccountInfo'))
}
export function setEmailAccountInfo(data) {
    return cookie.set('emailAccountInfo', JSON.stringify(data))
}