<template>
  <!-- 如果是只有一级表头 -->
  <el-table-column
    v-if="!columnItem.columnList"
    :label="columnItem.label"
    :prop="columnItem.prop"
    :resizable="false"
    show-overflow-tooltip
    :min-width="100"
    :fixed="columnItem.categorical"
    :class-name="columnItem.categorical ? 'categorical' : ''"
  >
    <template slot-scope="{ row, column }">
      <template v-if="row[column.property] == null">
        --
      </template>
      <!-- 否则如果该数据值不为 null, 且该列需要展示箭头 -->
      <template v-else-if="columnItem.showArrow">
        <div v-if="isShowDecreaseArrow(row[column.property])" class="arrow-cell decrease">
          <img :src="require('@/assets/img/overview/down.png')" alt=""> {{ getAbsValue(row[column.property]) }}
        </div>
        <div v-else-if="row[column.property] == 0">
          {{ row[column.property] }}
        </div>
        <div v-else class="arrow-cell">
          <img :src="require('@/assets/img/overview/up.png')" alt=""> {{ row[column.property] }}
        </div>
      </template>
      <!-- 不展示箭头的话，则直接展示内容 -->
      <template v-else>
        {{ row[column.property] }}
      </template>
    </template>
  </el-table-column>
  <!-- 否则如果是多级表头 -->
  <el-table-column
    v-else
    :label="columnItem.label"
    :resizable="false"
    show-overflow-tooltip
    :min-width="100"
    :fixed="columnItem.categorical"
    :class-name="columnItem.categorical ? 'categorical' : ''"
  >
    <table-child-column
      v-for="item in columnItem.columnList"
      :key="draggingTableName + resultDataSeq + item.label"
      :result-data-seq="resultDataSeq"
      :dragging-table-name="draggingTableName"
      :column-item="item"
    />
  </el-table-column>
</template>

<script>
export default {
  name: 'TableChildColumn',
  props: {
    columnItem: {
      type: Object,
      required: true
    },
    resultDataSeq: {
      type: Number,
      required: true
    },
    // v-for 的 key 会用到
    draggingTableName: {
      type: String,
      required: true
    }
  },
  methods: {
    // 是否展示 减少 的箭头
    isShowDecreaseArrow(value) {
      return value && `${value}`.startsWith('-')
    },
    getAbsValue(value) {
      return value && Math.abs(value)
    }
  }
}
</script>

<style lang="scss" scoped></style>
