<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips"></VnotLogin>-->
    <template>

      <div class="box" v-loading="loading">
        <div class="box_box">
          <div style="float: left" flex="c">
            <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
              <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">
                {{ item.dictLabel }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div style="overflow: hidden">

            <div style="float: right" class="search-btn-box ">
              <el-input placeholder="政策解读" v-model="title" size="small"></el-input>
              <div class="search-btn" @click="search">搜索</div>
            </div>
          </div>
        </div>
        <div class="box_box_title" v-for="item in policyExplainData" :key="item.id"
             @ @click="toHref('/policy/dynamic/syc/contentExplain/'+item.id)">
          <div style="display: flex">
            <!--            <div class="text-center" style="width: 267px; height: 150px">-->
            <!--              <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>-->
            <!--            </div>-->
            <div class="flex_col" flex="1">
              <div>
                <div flex="c" style="margin: 10px 0">
                  <div class="newUl-areas" v-if="item.unscrambleUnit">{{ item.unscrambleUnit }}</div>

                  <p class="">{{ htmlRestore(item.title) }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.updateTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="pages">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                         :page-size="pageData.pages.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
          </el-pagination>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {localOriginal, policyExplain} from "@/api/policy.js";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {fmtDate} from "@/utils/date.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {buryPoint} from "@/api/common";
import {selectDictDataByType} from "@/api/dict";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      showTips: [
        {
          title: "政策动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "政策库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "政策梳理",
          text: "专家梳理整合，真正读懂政策脉络",
        },
        {
          title: "政策大数据",
          text: "用数据深度理解政策结构和趋势",
        },
      ],
      defaultImg: require("@/assets/img/dnc-img.png"),
      loading: false,
      tableData: [],
      pagination: {},
      show: false,
      localOriginalData: [],
      policyReportData: [],
      policyExplainData: [],
      newPolicyDate: [],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      title: "",
      belongAreasData: [],
      belongAreas: [],
    };
  },
  computed: {
    newDate() {
      return fmtDate("YY年mm月", new Date());
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;
    } else {
      this.show = false;
    }
    this.init();

  },
  methods: {
    //切换
    handleChange(e) {
      this.belongAreas = e;
      this.policyExplain();

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //初始化加载
    init() {
      // this.localOriginal({page: 1, pageSize: 4, sycFlag: 'syc'});
      this.policyExplain();
      this.selectDictDataByType();
    },
    toHref(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.policyExplain({page: 1, pageSize: 12, sycFlag: 'syc'});
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.policyExplain({sycFlag: 'syc'});
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //搜索
    search() {
      this.policyExplain();

    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "policy_hot_field"}).then(
          (res) => {
            if (res.ok) {
              this.belongAreasData = res.data;
            }
          }
      );
    },
    //本网原创
    localOriginal(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.localOriginalData = res.data.list;
          this.localOriginalData.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
        }
      });
    },
    //政策解读
    policyExplain() {
      let form = Object.assign({}, this.pageData.pages, {
        title: this.title,
        belongAreas: this.belongAreas,
        sycFlag: 'syc'
      });
      form.belongAreas = form.belongAreas.join(",");

      policyExplain(form).then((res) => {
        if (res.ok) {
          this.policyExplainData = res.data.list;
          this.policyExplainData.forEach((element) => {
            element.title = htmlRestore(element.title);
          });
          this.pageData.total = res.data.count;
        }
      });
    },
    //字符串转数组
    strSplit(str) {
      return str.split(",");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box {
  // position: relative;
  //padding-left: 100px;
  //padding-right: 100px;

  .box_box {
    padding: 10px;
    background: #ffffff;

    /deep/ .el-input__inner {
      width: 235px;
      border-radius: 20px;
    }
  }

  .box_box_title {
    margin-top: 10px;
    background: #ffffff;
    cursor: pointer;

    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }

    .flex_col {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}

.index-banner-title {
  // display: flex;
  justify-content: space-between;
  // align-items: center;
}

.title-tag {
  // display: flex;
  align-items: flex-start;

  .title-tag-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    // color: #0d57bc;
    margin-top: -30px;
    margin-left: 20px;
  }
}

.more {
  // display: flex;
  margin-top: 10px;
  align-items: center;
  color: #0d57bc;
  font-size: 14px;
  font-weight: 500;
  // &-icon {
  //   padding-left: 2px;
  // }
  margin-left: 230px;
  margin-bottom: 20px;
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 12px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      left: 3px;
      width: 1px;
      height: 100%;
      border-left: 1px dashed #dcdcdc;
    }

    .index-new-timeLine-content {
      position: relative;
      padding-left: 4px;
      padding-bottom: 11px;

      .index-new-link {
        display: block;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #dcdcdc;

        // padding-left: 20px;
        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.search-btn-box {
  display: flex;
  //margin-top: 9px;
  /deep/ .el-input__inner {
    height: 35px;
    line-height: 35px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .el-input {
    right: -54px;
  }

  .search-btn {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
}

</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

.newUl-areas {
  display: inline-block;
  padding: 4px;
  font-size: 14px;
  line-height: 14px;
  color: #823d00;
  background: rgba(242, 153, 74, .08);
  margin-right: 4px;
}
</style>
