<template>
  <PageContainer breadcrumb>
    <VlistTitle bigTtitle="最新政策" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>
    <div class="list-filter" flex="c">
      <el-checkbox label="" border @change="allbtn" v-model="all" size="small" style="margin-right: 8px">全部领域
      </el-checkbox>
      <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
        <el-checkbox :label="item.dictValue" border v-for="item in belongAreasData" :key="item.dictValue">
          {{ item.dictLabel }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="box">
      <ul class="tab-box">
        <li class="tab-list" v-for="item in tabData" :key="item.value" @click="tabBtn(item.value)"
            :class="{'ac':ac == item.value}">{{ item.label }}
        </li>
        <!-- <li class="tab-list">国家政策</li>
        <li class="tab-list">地方政策</li> -->
      </ul>
    </div>
    <Vlist :list="newPolicyDate" :pageData="newPolicyPage" @handleSizeChange="handleSizeChange"
           @handleCurrentChange="handleCurrentChange" @toHref="toHref" class="list"/>
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import Vlist from "@/components/list.vue";
import {newPolicy} from "@/api/policy.js";
import ListFiltering from "@/components/ListFiltering.vue";
import {selectDictDataByType} from "@/api/dict.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {getUserInfo, ydlTk} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    VlistTitle,
    Vlist,
    ListFiltering,
  },
  data() {
    return {
      bgurl: require("@/assets/img/Group299.png"),
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      belongAreasData: [],
      belongAreas: [],
      tabData: [
        {value: "", label: "全部政策"},
        {value: "1", label: "国家政策"},
        {value: "2", label: "地方政策"},
      ],
      ac: "",
      areas: "",
      all: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "政策",
        userId: ""
      },
    };
  },
  created() {
    this.newPolicy();
    this.selectDictDataByType();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //最新政策
    newPolicy() {
      if (!getUserInfo() && this.newPolicyPage.pages.page > 1) {
        this.showLogin();
      } else {
        let form = {
          ...this.newPolicyPage.pages,
          areas: this.areas,
          sycFlag: 'syc',
          flag : 0,
          belongAreas: this.belongAreas.join(","),
        };
        newPolicy(form).then((res) => {
          if (res.ok) {
            this.newPolicyDate = res.data.list;
            this.newPolicyDate.forEach((element) => {
              element.title = htmlRestore(element.title);
              element.updateTime = element.publicDate;
            });
            this.newPolicyPage.total = res.data.count;
          }
        });
      }
    },
    handleSizeChange(val) {
      this.newPolicyPage.pages.pageSize = val;
      this.newPolicy();
    },
    handleCurrentChange(val) {
      this.newPolicyPage.pages.page = val;
      this.newPolicy();
    },
    toHref(item) {
      let newUrl = this.$router.resolve("/policy/dynamic/syc/contentNewPolicyDyn/" + item.id);


      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //数据字典
    selectDictDataByType() {
      selectDictDataByType({dictType: "policy_hot_field"}).then(
          (res) => {
            if (res.ok) {
              this.belongAreasData = res.data;
            }
          }
      );
    },
    //切换
    handleChange(e) {
      if (getUserInfo()) {
        if (e.length <= 0) {
          this.all = true;
        } else {
          this.all = false;
        }
        this.newPolicy();
      } else {
        this.showLogin();
      }

    },
    allbtn() {
      this.belongAreas = [];
      this.newPolicy();
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e;
        this.areas = e;
        this.newPolicy();
      } else {
        this.showLogin();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  /deep/ .list-box {
    margin-top: 0;
  }
}

.list-filter {
  padding: 24px;
  background: #ffffff;

  ::v-deep .el-checkbox__input {
    display: none;
  }

  ::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box {
  padding: 0 24px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    color: #4f4f4f;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
