<template>
  <div
    v-if="isShowStrategyPlanning"
    class="enterpriseDetail strategyPlanning-detail"
    id="StrategyPlanning"
    data-name="战略规划"
  >
    <el-card :body-style="{ paddingTop: '20px', paddingBottom: '34px' }">
      <div slot="header" class="clearfix">
        <div class="title-tag-text">
          <span>战略规划</span>
        </div>
        <paginator
          :total-items="totalCount"
          :items-per-page="3"
          @page-change="handlePageChange"
        />
      </div>
      <div
        :class="sourceData.length > 2 ? 'card-content' : 'card-content-start'"
      >
        <div
          class="list-item"
          :class="
            sourceData.length > 2
              ? { 'add-border': a === 1 }
              : { 'add-border-before': a === 1 }
          "
          v-for="(o, a) in sourceData"
        >
          <div class="content">
            <div class="title">
              <span class="label" v-for="i in o.label">{{ i }}</span>
              <el-tooltip
                effect="light"
                :disabled="isDisabledTooltip"
                :content="o.title"
                placement="top"
                :open-delay="300"
              >
                <div class="title-text" @mouseover="onMouseOver($event, a)">
                  <span>{{ o.title }}</span>
                  <!-- 隐藏的元素用于宽度计算 -->
                  <div class="hidden-title" ref="hiddenTitle">
                    {{ o.title }}
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div>
              <div v-if="o.summary.length > 280" class="textInfo">
                <div v-if="!toggleStates[a] || !toggleStates[a][o.summary]">
                  {{ o.summary.slice(0, 280) + "..." }}
                  <el-button
                    type="text"
                    @click="toggle(a, o.summary)"
                    :style="{
                      fontSize: '12px',
                      color: '#3f76ea',
                      marginRight: '10px'
                    }"
                  >
                    展开
                    <i
                      :style="{ color: '#a9b9dc', fontSize: '12px' }"
                      class="el-icon-arrow-down"
                    ></i>
                  </el-button>
                </div>
                <div v-else>
                  {{ o.summary }}
                  <el-button
                    type="text"
                    @click="toggle(a, o.summary)"
                    :style="{
                      fontSize: '12px',
                      color: '#3f76ea',
                      marginRight: '10px'
                    }"
                  >
                    收起
                    <i
                      :style="{ color: '#a9b9dc', fontSize: '12px' }"
                      class="el-icon-arrow-up"
                    ></i>
                  </el-button>
                </div>
              </div>
              <div v-else class="textInfo">{{ o.summary }}</div>
            </div>
          </div>
          <div class="date">{{ o.publishDate }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getStrategyPlanning } from "@/api/enterpriseAnalysis.js";
import Paginator from "@/components/paginator/index.vue";
export default {
  name: "StrategyPlanning",
  components: {
    Paginator
  },
  data() {
    return {
      toggleStates: {}, // 用于存储每个条目的展开/收起状态
      isShowStrategyPlanning: false,
      isDisabledTooltip: true, // 是否需要禁止提示
      isLoading: false,
      totalCount: 0,
      sourceData: [],
      params: {
        pageSize: 3,
        pageNum: 1,
        enterpriseId: this.$route.params.id
      }
    };
  },
  created() {
    this.getStrategyPlanning(this.params, "isShowStrategyPlanning");
  },

  methods: {
    toggle(index, propName) {
      // 检查并初始化行状态对象
      if (!this.toggleStates[index]) {
        this.$set(this.toggleStates, index, {});
      }
      // 切换特定行和列的状态
      this.$set(
        this.toggleStates[index],
        propName,
        !this.toggleStates[index][propName]
      );
    },
    // 移入事件: 判断内容的宽度contentWidth是否大于父级的宽度
    onMouseOver(event, index) {
      let targetElement = event.target;

      // 确保 targetElement 是你想要检查的 span 元素
      if (targetElement.nodeName !== "SPAN") {
        targetElement = targetElement.querySelector("span");
      }

      let parentWidth = targetElement.parentNode.offsetWidth;
      let contentWidth = this.$refs.hiddenTitle[index].offsetWidth;

      // 检查内容宽度是否超出了父元素宽度
      this.isDisabledTooltip = contentWidth <= parentWidth;
    },
    handlePageChange(e) {
      this.params.pageNum = e;
      this.toggleStates= {}
      this.getStrategyPlanning(this.params);
    },
    getStrategyPlanning(params, isShowStrategyPlanning) {
      getStrategyPlanning(params).then(res => {
        if (res.ok) {
          if (isShowStrategyPlanning) {
            this.isShowStrategyPlanning = res.data.data.length ? true : false;
          }
          this.sourceData = res.data.data;
          this.totalCount = res.data.totalCount || 0;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.strategyPlanning-detail {
  .el-button.el-button--text {
    padding: 0;
    float: right;
    margin-right:0 !important;
  }
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
    .clearfix {
      display: flex;
      justify-content: space-between;
      &::after,
      &::before {
        display: none;
      }
    }
  }
  .hidden-title {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
}
.card-content {
  display: flex;
  justify-content: space-between;
}
.card-content-start {
  display: flex;
}
.add-border-before {
  margin: 0 45px;
}
.add-border-before::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%; /* 根据需要调整高度 */
  background-color: #f3f5f7;
  position: absolute;
  top: 0;
  left: -23px;
}
.add-border {
  margin: 0 45px;
}
.add-border::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%; /* 根据需要调整高度 */
  background-color: #f3f5f7;
  position: absolute;
  top: 0;
  right: -23px;
}
.add-border::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%; /* 根据需要调整高度 */
  background-color: #f3f5f7;
  position: absolute;
  top: 0;
  left: -23px;
}
.list-item {
  flex: 1;
  position: relative;
  min-width: 230px;
  max-width: 300px;
  min-height: 118px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    .label {
      min-height: 18px;
      white-space: nowrap;
      display: inline-block;
      border: solid 1px #29b07f;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #29b07f;
      padding: 2px 4px;
      margin-right: 10px;
    }
    .title-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #222;
    }
  }
  .textInfo {
    margin-top: 14px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #999;
  }
  .date {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #bbb;
  }
}
</style>
