<template>
  <div class="enterprise-search-list">
    <el-card :body-style="{ padding: '0 22px 20px' }">
      <div slot="header" class="enterprise-search-clearfix">
        <div class="enterprise-info">
          <span>企业信息查询</span>
          <div class="type-select">
            <el-radio-group
              v-model="selectedEnterpriseType"
              @input="querySearch"
            >
              <el-radio-button label="整车企业">整车企业</el-radio-button>
              <el-radio-button label="零部件企业">零部件企业</el-radio-button>
            </el-radio-group>

            <div v-if="selectedEnterpriseType === '整车企业'">
              <el-radio-group
                v-model="selectedCarType"
                @change="onCarTypeChange"
                @input="querySearch"
              >
                <el-radio class="text-button" label="乘用车企业">
                  乘用车
                </el-radio>
                <el-radio class="text-button" label="商用车企业">
                  商用车
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <el-input
          v-model="enterpriseListParams.searchValue"
          size="small"
          placeholder="输入关键词"
          clearable
          @keyup.enter.native="toList"
          @clear="toList"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="toList"
          ></i>
        </el-input>
      </div>
      <div class="container" v-loading="loading">
        <el-row :gutter="20" v-if="Object.keys(indexedEnterprises).length">
          <el-col :span="23">
            <!-- 企业列表 -->
            <div
              v-for="(enterprises, index) in indexedEnterprises"
              :key="index"
              :id="index"
              class="enterprise-list"
            >
              <h2 style="display: none;">{{ index }}</h2>
              <div
                v-for="(enterprise, option) in enterprises"
                :key="enterprise.enterpriseId"
                class="enterprise-item"
                style="cursor: pointer;"
                @click="
                  toHref(
                    `/workInformation/enterpriseInfo/detail/${enterprise.enterpriseId}`,
                    '_self',
                    enterprise.enterpriseId,
                    enterprise.enterpriseName
                  )
                "
                :class="{
                  'padding-hide':
                    option === 0 && index === Object.keys(indexedEnterprises)[0]
                }"
              >
                <!-- 企业信息展示部分 -->
                <img
                  class="news-image"
                  style="min-width: 144px; width: 144px; height: 144px;border-radius: 4px;"
                  :src="enterprise.enterpriseLogoUrl"
                />
                <div class="enterprise-info">
                  <div class="enterprise-box">
                    <div class="title-box">
                      <span class="name">{{ enterprise.enterpriseName }}</span>
                      <span
                        v-for="(o, d) in enterprise.enterpriseLabelList"
                        :key="d"
                        class="title-type"
                        :class="getKeyByValue(o)"
                      >
                        {{ o }}
                      </span>
                    </div>
                    <div class="content">{{ enterprise.synopsis }}</div>
                  </div>
                  <div class="salesAmount">
                    <div class="sales" v-if="enterprise.production">
                    {{`${enterprise.date}产量:`}} <span>{{ enterprise.production }}</span>
                    </div>
                    <span
                      class="segmentation"
                      v-if="enterprise.production && enterprise.sales"
                    ></span>
                    <div class="production" v-if="enterprise.sales">
                      {{`${enterprise.date}销量:`}} <span>{{ enterprise.sales }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="1">
            <!-- 字母索引导航 -->
            <div class="index-list-bar" :class="{'fixed': isFixed}" v-if="sortedIndexes.length">
              <span class="index-list-bar-item-noSel">选</span>
              <span
                v-for="i in sortedIndexes"
                class="index-list-bar-item selected"
                :class="{ active: i === selectIndex }"
                @click="scrollTo(i), i === selectIndex"
              >
                {{ i }}
              </span>
            </div>
          </el-col>
        </el-row>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from "@/utils/auth.js";
import { getEnterpriseList } from "@/api/enterpriseAnalysis.js";
import { buryPoint } from "@/api/common";
export default {
  name: "EnterpriseSearch",

  data() {
    return {
      isFixed:false,
      loading: false,
      selectedEnterpriseType: "整车企业",
      selectedCarType: "乘用车企业",
      enterpriseListParams: {
        enterpriseType: "乘用车企业",
        searchValue: ""
      },
      enterprisesData: {
        // ... 按照接口返回的结构填充数据
      },
      selectIndex: "",
      enterpriseType: {
        "type-gy": "国营",
        "type-my": "民营",
        "type-hz": "合资",
        "type-wz": "外资",
        "type-Hongkong": "中国香港企业",
        "type-Macao": "中国澳门企业",
        "type-Taiwan": "中国台湾企业",
        "type-sydw": "事业单位/科研机构",
        "type-fgs": "分公司/代表处",
        "type-other": "其他"
      },
      bgcClass: [
        "bgc-eaf6ff",
        "bgc-fef0f0",
        "bgc-fff5d9",
        "bgc-eaf0fd",
        "bgc-ffeee4",
        "bgc-ebeefd",
        "bgc-dcfaf8",
        "bgc-e9e8eb",
        "bgc-e9faf2",
        "bgc-ffedf6"
      ],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      }
    };
  },
  methods: {
    handleScroll() {
      const enterpriseSearchList = document
        .querySelector(".enterprise-search-list")
        .getBoundingClientRect().top;
        this.isFixed = enterpriseSearchList <= 20;
    },

    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toList() {
      if (getUserInfo()) {
        logRecords(
          "企业情报",
          "企业信息查询",
          "4",
          "",
          "",
          "",
          "",
          this.enterpriseListParams.searchValue,
          "查询"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.getEnterpriseList(this.enterpriseListParams);
          }
        } else {
          this.getEnterpriseList(this.enterpriseListParams);
        }
      } else {
        this.showLogin();
      }
    },
    toHref(routeUrl, openWay, id, name) {
      const routeUrlQuery = {
        path: routeUrl,
        query: {
          enterpriseType: this.enterpriseListParams.enterpriseType
        }
      };
      const newUrl = this.$router.resolve(routeUrlQuery);
      if (getUserInfo()) {
        logRecords("企业情报", "企业信息查询", "", "", id, "", name, "");
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, openWay);
          }
        } else {
          window.open(newUrl.href, openWay);
        }
      } else {
        this.showLogin();
      }
    },
    scrollTo(index) {
      this.selectIndex = index;
      const element = document.getElementById(index);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 118; // 减去118px的偏移量

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    querySearch(e) {
      switch (e) {
        case "整车企业":
          this.enterpriseListParams.enterpriseType = "乘用车企业";
          break;
        default:
          this.enterpriseListParams.enterpriseType = e;
          break;
      }
      this.selectIndex = "";
      this.$bus.$emit('valueChangelist',e );
      this.getEnterpriseList(this.enterpriseListParams);
    },
    onCarTypeChange() {
      // 当子选项被选中时，自动选择父选项
      this.selectedEnterpriseType = "整车企业";
      this.$bus.$emit('valueChangelisttype',this.selectedCarType );
    },
    getEnterpriseList(params) {
      this.loading = true;
      getEnterpriseList(params).then(res => {
        if (res.ok) {
          this.enterprisesData = res.data;
          this.loading = false;
        }
      });
    },
    getKeyByValue(value) {
      // 使用对象中已定义的类型
      for (let key in this.enterpriseType) {
        if (
          this.enterpriseType.hasOwnProperty(key) &&
          this.enterpriseType[key] === value
        ) {
          return key;
        }
      }

      // 如果标签不在预定义类型中，则基于标签的内容来分配颜色
      if (value) {
        let hash = 0;
        for (let i = 0; i < value.length; i++) {
          hash = value.charCodeAt(i) + ((hash << 5) - hash);
        }
        let index = Math.abs(hash % this.bgcClass.length);
        return this.bgcClass[index];
      }
      return "";
    }
  },
  computed: {
    // 生成排序后的索引数组
    sortedIndexes() {
      return Object.keys(this.enterprisesData).sort();
    },
    // 生成索引数据对象
    indexedEnterprises() {
      const sortedKeys = Object.keys(this.enterprisesData).sort();
      const sortedData = {};
      sortedKeys.forEach(key => {
        sortedData[key] = this.enterprisesData[key];
      });
      return sortedData;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
      this.$bus.$on('valueChangecard', (data) => {
      if (data === '乘用车' || data === '商用车') {
        this.selectedCarType = data + '企业'
        this.selectedEnterpriseType = '整车企业'
      } else if(data === '整车企业'){
        this.selectedCarType = '乘用车企业'
        this.selectedEnterpriseType = '整车企业'
      }else {
        this.selectedCarType = data
        this.selectedEnterpriseType = data
      }
      if (data === '整车企业') {
        this.enterpriseListParams.enterpriseType = '乘用车企业'
      }else {
        this.enterpriseListParams.enterpriseType = this.selectedCarType
      }
        this.getEnterpriseList(this.enterpriseListParams);
    });
    });
  },
  beforeDestroy() {
    this.$bus.$off('valueChangecard')
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    selectedEnterpriseType(value) {
      if (value !== "整车企业") {
        this.selectedCarType = null;
      } else if (!this.selectedCarType) {
        this.selectedCarType = "乘用车企业";
      }
    }
  },
  created() {
    this.getEnterpriseList(this.enterpriseListParams);
  }
};
</script>
<style lang="scss">
.enterprise-search-list {
  .el-card__header {
    border: none;
    padding: 30px 22px 20px;
  }
  .enterprise-search-clearfix {
    .el-input {
      width: 351px;
      height: 32px;
      .el-input__inner {
        background-color: #f3f3f3;
        font-family: MicrosoftYaHei;
        font-size: 12px;
      }
      .el-input__suffix .el-icon-search {
        font-size: 17px;
      }
    }
    .el-radio-group:nth-child(1) {
      margin-right: 30px;
    }
    .text-button {
      margin: 0;
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
    .el-radio .el-radio__input {
      display: none;
    }
    .el-radio-button .el-radio-button__inner {
      padding: 7px 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
.fixed {
  position: fixed !important;
  top: 100px;
}

.enterprise-search-list {
  .enterprise-search-clearfix {
    display: flex;
    justify-content: space-between;
    &::after,
    &::before {
      display: none;
    }
    span {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      font-weight: 700;
      color: #222222;
      margin-right: 67px;
    }
    .enterprise-info {
      display: flex;
      min-width: 536px;
    }

    .type-select {
      display: flex;
      align-items: center;
    }
  }
}

.padding-hide {
  border: none !important;
  padding-top: 0 !important;
}
.enterprise-item {
  display: flex;
  align-items: center;
  padding: 19px 0;
  border-top: 1px solid #ddd;
  .news-image {
    margin-right: 30px;
  }

  .bgc-eaf6ff {
    background-color: #eaf6ff;
    color: #45affe;
  }
  .bgc-fef0f0 {
    background-color: #fef0f0;
    color: #ff2f2f;
  }
  .bgc-fff5d9 {
    background-color: #fff5d9;
    color: #ffaf3a;
  }
  .bgc-eaf0fd {
    background-color: #eaf0fd;
    color: #3168ec;
  }
  .bgc-ffeee4 {
    background-color: #ffeee4;
    color: #ff6300;
  }
  .bgc-ebeefd {
    background-color: #ebeefd;
    color: #3058f7;
  }
  .bgc-dcfaf8 {
    background-color: #dcfaf8;
    color: #16dbcc;
  }
  .bgc-e9e8eb {
    background-color: #e9e8eb;
    color: #333;
  }
  .bgc-e9faf2 {
    background-color: #e9faf2;
    color: #2cd28a;
  }
  .bgc-ffedf6 {
    background-color: #ffedf6;
    color: #ff5daf;
  }

  .enterprise-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 144px;
    .enterprise-box {
      .title-box {
        margin-bottom: 19px;
        .name {
          font-family: MicrosoftYaHei-Bold;
          font-size: 18px;
          font-weight: 600;
          color: #222222;
          margin-right: 19px;
        }
        .title-type {
          padding: 3px 6px;
          font-size: 16px;
          border-radius: 2px;
          display: inline-flex;
          margin-right: 10px;
          line-height: 1;
        }
        .type-gy,
        .type-my,
        .type-hz,
        .type-wz {
          background-color: #fef0f0;
          color: #ff2f2f;
        }
        .type-hongkong,
        .type-macao,
        .type-taiwan {
          background-color: #fff5d9;
          color: #ffaf3a;
        }
        .type-sydw {
          background-color: #ebeefd;
          color: #3058f7;
        }
        .type-fgs {
          background-color: #e9faf2;
          color: #2cd28a;
        }
        .type-other {
          background-color: #e9e8eb;
          color: #333333;
        }
      }
      .content {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #666;
        font-weight: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0px;
      }
    }
    .salesAmount {
      display: flex;
      align-items: center;
      .sales,
      .production {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #bbb;
        span {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          font-weight: 600;
          color: #222222;
        }
      }
      .segmentation {
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: #bbb;
        margin: 0 16px;
      }
    }
  }
}

.index-list-bar {
  position: relative;
  padding: 14px 8px 14px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.index-list-bar-item,
.index-list-bar-item-noSel {
  display: inline-block;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  padding: 2px;
  color: #9b9595;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
  &.selected:hover {
    color: #fff;
    background-image: linear-gradient(
        128deg,
        #76b3ff 0%,
        rgba(0, 105, 234, 1) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 4px;
  }
}
.index-list-bar-item.active {
  color: #fff;
  background-image: linear-gradient(
      128deg,
      #76b3ff 0%,
      rgba(0, 105, 234, 1) 100%
    ),
    linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  border-radius: 4px;
}
</style>
