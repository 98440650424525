import { render, staticRenderFns } from "./Announcement.vue?vue&type=template&id=7a9eb8c1&scoped=true"
import script from "./Announcement.vue?vue&type=script&lang=js"
export * from "./Announcement.vue?vue&type=script&lang=js"
import style0 from "./Announcement.vue?vue&type=style&index=0&id=7a9eb8c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9eb8c1",
  null
  
)

export default component.exports