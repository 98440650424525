<!--企业动态列表-->
<template>
  <router-view v-if="$route.name == '公司动态内容页'" />
  <PageContainer v-else breadcrumb>
    <Vlist :list="queryByPageDynamicData" :page="queryByPageDynamicPage" :pageData="queryByPageDynamicPage" :props="props" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
  </PageContainer>
</template>
<script>
import { queryByPageDynamic } from "@/api/enterpriseAnalysis.js";
import Vlist from "@/components/list";
export default {
    components: {
        Vlist,
    },
    data() {
        return {
            queryByPageDynamicData: [],
            queryByPageDynamicPage: {
                pages: {
                    pageNo: 1,
                    pageSize: 10,
                    enterpriseId: "",
                },
                total: 0,
                currentPage: 0,
            },
            props: {
                title: "tilte",
                date: "publishDate",
            },
        };
    },
    created() {
        this.queryByPageDynamicPage.pages.enterpriseId =
            this.$route.query.enterpriseId;
        this.queryByPageDynamic(this.queryByPageDynamicPage.pages);
    },
    methods: {
        queryByPageDynamic(params) {
            queryByPageDynamic(params).then((res) => {
                if (res.ok) {
                    this.queryByPageDynamicData = res.data.list;
                    this.queryByPageDynamicPage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.queryByPageDynamicPage.pages.pageSize = v;
            this.queryByPageDynamic(this.queryByPageDynamicPage.pages);
        },
        handleCurrentChange(v) {
            this.queryByPageDynamicPage.pages.pageNo = v;
            this.queryByPageDynamic(this.queryByPageDynamicPage.pages);
        },
        toHref(item) {
            this.$router.push({
                path: "/workInformation/information/workDynamicDetail/",
                query: {
                    id: item.id,
                    enterpriseType: this.$route.query.enterpriseType,
                    enterpriseId: this.$route.query.enterpriseId,
                },
            });
        },
    },
};
</script>
