<template>
  <div id="Histogram3" ref="Histogram3" class="borderStyle"></div>
</template>

<script>
import {Column, Pie} from '@antv/g2plot'
let piePlot
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    Height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radarPlot: null,
    }
  },
  // 监听
  watch: {
    value: {
      handler(newVal, oldVal) {
        // 方式一 有新数据从新渲染图表
        piePlot.changeData(newVal)
        // 方式二 先销毁图表 在创建图表
        // chartChange.destroy()
        // this.initRadarPlot()
      },
      deep: true, //深度监听
      // immediate: true,
    },
  },
  mounted() {
    this.initRadarPlot()
  },
  methods: {
    initRadarPlot() {
      // 使用ref的方式组件可以多次使用
      // const chartChange = new Column(this.$refs.Histogram, {
      piePlot = new Pie(this.$refs.Histogram3, {
              forceFit: true,
              radius: 0.8,
              data: this.value,
              angleField: 'value',
              colorField: 'name',
              label: {
                visible: true,
                type: 'inner',
              },
              legend: {
                flipPage: false,
                itemSpacing:5,
                position: 'bottom'
              },
              theme: {
                colors10: ['#59a4ff', '#ffbd2a', '#b37feb', '#4ace82', '#ff745c', '#26d0ff', '#f6cc00', '#c04ee6', '#3ed7ad','#ff957c'],
                colors20: ['#59a4ff', '#ffbd2a',  '#b37feb', '#4ace82', '#ff745c', '#26d0ff', '#f6cc00', '#c04ee6', '#3ed7ad','#ff957c', '#59bfff','#ffac3d','#936cff','#88d73e','#ff8da2'],

              }
            });

            piePlot.render();
    },
    // 图表高度
    getHeight() {
      let height = parseInt(this.Height)
      return { height: height + 'px' }
    },
  },
}
</script>

<style lang="scss" scoped>
.borderStyle{
  border: solid 5px #92beff;
  margin: 10px;
  padding: 10px;
}
</style>