
import Vlogin2 from './src/main2'
import Vue from 'vue'

let VloginConstructor2 = Vue.extend(Vlogin2)

Vue.component(Vlogin2.name,Vlogin2)
const install = (o) => {
    const instance2 = new VloginConstructor2({
        el: document.createElement('div')
    })
    document.body.appendChild(instance2.$el);
    instance2.flag = o.flag;
}

export default install;
