<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips" />-->
    <template>
      <div class="search-form search-form-top">
        <el-form class="g-form" :model="form" ref="form" label-width="130px">
          <searchGrop title="基本字段">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="standardForm" label="标准形式：">
                  <el-select v-model="form.standardForm" placeholder="请选择" style="width: 200px" size="small" clearable>
                    <el-option v-for="item in standardForm" :key="item.dictLabel" :label="item.dictLabel"
                               :value="item.dictValue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="title" label="中文名称：">
                  <el-input v-model="form.title" placeholder="请输入内容" style="width: 200px" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="titleEnglish" label="英文名称：">
                  <el-input v-model="form.titleEnglish" placeholder="请输入内容" style="width: 200px"
                            size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item prop="category" label="标准类别：">
                  <el-select v-model="form.category" placeholder="请选择" style="width: 200px" size="small"
                             @change="categoryChange" clearable>
                    <el-option v-for="item in category" :key="item.dictLabel" :label="item.dictLabel"
                               :value="item.dictValue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="热门领域：" prop="belongAreas">
                  <el-cascader :options="belongAreas" :props="belongAreasProps" collapse-tags clearable size="small"
                               style="width: 200px" v-model="form.belongAreas" ref="belongAreas"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="影响主体：" prop="standardSystem">
                  <el-cascader :options="standardSystem" :props="categoryProps" clearable size="small"
                               style="width: 200px" :show-all-levels="false"
                               v-model="form.standardSystem"></el-cascader>
                </el-form-item>
              </el-col>

            </el-row>
          </searchGrop>
          <searchGrop title="标准出版稿查询字段">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="code" label="标准编号：">
                  <el-input v-model="form.code" placeholder="请输入内容" style="width: 200px" size="small"
                            class="import-input"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="state" label="标准状态：">
                  <el-select v-model="form.state" placeholder="请选择" style="width: 200px" size="small" clearable>
                    <el-option v-for="item in state" :key="item.dictLabel" :label="item.dictLabel"
                               :value="item.dictValue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="inspectionItems" label="检测项目：">
                  <el-checkbox-group v-model="form.inspectionItems">
                    <el-checkbox :label="item.dictValue" v-for="item in inspectionItems" :key="item.dictValue">
                      {{ item.dictLabel }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="发布日期：" class="shijian">
                  <el-date-picker unlink-panels v-model="publishDate" type="daterange" start-placeholder="开始日期"
                                  end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 200px" size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实施日期：" class="shijian">
                  <el-date-picker unlink-panels v-model="implementDate" type="daterange" start-placeholder="开始日期"
                                  end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 200px" size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="作废日期：" class="shijian">
                  <el-date-picker unlink-panels v-model="voidDate" type="daterange" start-placeholder="开始日期"
                                  end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 200px" size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="发布单位："  prop="publishUnit">
                  <el-input v-model="form.publishUnit" placeholder="请输入内容" style="width: 200px" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="归口单位："  prop="managerUnit">
                  <el-input v-model="form.managerUnit" placeholder="请输入内容" style="width: 200px" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="起草单位："  prop="draftUnit">
                  <el-input v-model="form.draftUnit" placeholder="请输入内容" style="width: 200px" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </searchGrop>

          <searchGrop title="征求意见稿/报批稿查询字段">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="manageUnit" label="归口单位：">
                  <el-input v-model="form.manageUnit" placeholder="请输入内容" style="width: 200px" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="征求开始日期：" class="shijian">
                  <el-date-picker v-model="startBeginDate" type="daterange" start-placeholder="开始日期"
                                  end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 200px" size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="征求结束日期：" class="shijian">
                  <el-date-picker v-model="overStartDate" type="daterange" start-placeholder="开始日期"
                                  end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 200px" size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </searchGrop>

          <!-- <el-row>
              <el-col :span="24">
                  <el-form-item prop="applicableProducts" label="适用产品：">
                      <el-checkbox-group v-model="form.applicableProducts">
                          <el-checkbox :label="item.dictValue" v-for="item in applicableProducts" :key="item.dictValue">{{ item.dictLabel }}</el-checkbox>
                      </el-checkbox-group>
                  </el-form-item>
              </el-col>
          </el-row> -->

          <div class="text-center">
            <el-button class="reset-button" @click="resetForm">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>

      <div class="search-form">
        <div style="position:relative">
          <div flex="sb" style="position:absolute;right:0;z-index:2">
            <span class="table_title"></span>
            <el-button type="primary" plain @click="excelBtn"  v-has-role="'freeusers'" v-show="activeName == 'bzzt'">
              导出
            </el-button>
            <el-button type="primary" plain @click="excelBtn2" v-has-role="'freeusers'" v-show="activeName == 'zqyjg'">
              导出
            </el-button>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="标准主体" name="bzzt">
              <el-table :data="standardLibraryDate" style="width: 100%"
                        :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                        @selection-change="handleSelectionChange" row-key="id" ref="tableBox" stripe
                        @sort-change="tableSort" v-loading="loading">
                <el-table-column type="selection" width="55" reserve-selection :selectable="checkSelectTable">
                </el-table-column>
                <el-table-column sortable="custom" prop="state" label="状态" width="80">
                  <template slot-scope="scope">
                    <span v-if="scope.row.state == '未实施'" class="state">{{ scope.row.state }}</span>
                    <span v-else-if="scope.row.state == '作废'" class="state red">{{ scope.row.state }}</span>
                    <span v-else-if="scope.row.state == '现行'" class="state gre">{{ scope.row.state }}</span>
                    <span v-else-if="scope.row.state == '被替代'" class="state blue">{{ scope.row.state }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="code" label="标准编号" width="180">
                  <template slot-scope="scope">
                    <div style="cursor: pointer;color: #0D57BC"
                         @click="viewDetail('/standards/dynamic/syc/ttStandard/' , scope.row.id)">
                      {{ htmlRestore(scope.row.code) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="标准名称">
                  <template slot-scope="{row}">
                    {{ htmlRestore(row.title) }}
                  </template>
                </el-table-column>
                <el-table-column prop="publishDate" label="发布日期" width="130" sortable="custom"/>
                <el-table-column prop="implementDate" label="实施日期" width="108"/>
                <el-table-column prop="voidDate" label="作废日期" width="108"/>
              </el-table>
              <div class="text-right">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="standardLibraryPage.pages.page" :page-sizes="pageSizes"
                               :page-size="standardLibraryPage.pages.pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="standardLibraryPage.total">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="标准征求意见/报批稿" name="zqyjg">
              <!-- <div flex="sb" style="margin-bottom: 10px">
                  <span class="table_title"></span>

              </div> -->
              <el-table :data="solicitCommentsData" style="width: 100%"
                        :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                        @selection-change="handleSelectionChange2" row-key="id" ref="tableBox2" stripe>
                <el-table-column type="selection" width="55" reserve-selection :selectable="checkSelectTable2">
                </el-table-column>
                <el-table-column prop="title" label="标题">
                  <template slot-scope="scope">
                    <div style="cursor: pointer;color: #0D57BC"
                         @click="viewDetail('/standards/dynamic/syc/contentComments/' , scope.row.id)">
                      {{ scope.row.title }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="录入时间" width="130"/>
                <el-table-column prop="requireStartDate" label="征求开始日期" width="130"/>
                <el-table-column prop="requireEndDate" label="征求结束日期" width="130"/>
              </el-table>
              <div class="text-right">
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                               :current-page="solicitCommentsPage.pages.page" :page-sizes="pageSizes"
                               :page-size="solicitCommentsPage.pages.pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="solicitCommentsPage.total">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 标准查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, openSy} from "@/utils/auth.js";
import {
  standardLibrary,
  standardDataExportZip,
  solicitComments,
  solicitCommentsExportZip,
} from "@/api/standards.js";
import {selectDictDataByType, ttDemension} from "@/api/dict.js";
import {downloadFile} from "@/utils/download.js";
import searchGrop from "@/components/searchGrop.vue";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    searchGrop,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: "标准动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "标准库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "标准梳理",
          text: "专家梳理整合，真正读懂标准脉络",
        },
        {
          title: "标准大数据",
          text: "用数据深度理解标准结构和趋势",
        },
      ],
      viewSwitchFlag: false,
      checked: [],
      form: {
        sycFlag:'syc',
        // applicableProducts: [],
        //适用产品
        category: "",
        //标准类别
        code: "",
        //标准号
        state: "",
        inspectionItems: [],
        //检测项目
        title: "",
        //标准名称
        startImplementDate: "",
        //实施时间开始
        endImplementDate: "",
        //实施时间结束
        standardForm: "",
        //标准形式
        standardSystem: "",
        belongAreas: "",
        startPublishDate: "",
        //颁布开始时间
        endPublishDate: "",
        //颁布结束时间
        startVoidDate: "",
        //废止开始时间
        endVoidDate: "",
        //废止结束时间
        manageUnit: "",
        //归口单位
        startBeginDate: "",
        startEndDate: "",
        overStartDate: "",
        overEndDate: "",
        publishUnit:"",
        draftUnit:"",
        managerUnit:""
      },
      standardLibraryDate: [],
      standardLibraryPage: {
        pages: {
          sycFlag:'syc',
          page: 1,
          pageSize: 10,
        },
        total: 0,
      },
      category: [],
      applicableProducts: [],
      inspectionItems: [],
      selectTable: [],
      selectTable2: [],
      limitNum: 10,
      activeName: "bzzt",
      //征求意见稿
      solicitCommentsData: [],
      solicitCommentsPage: {
        pages: {
          page: 1,
          pageSize: 10,
          sycFlag:'syc'
        },
        total: 0,
      },
      //标准形式
      standardForm: [],
      //标准状态
      state: [],
      //标准分类目录
      standardSystem: [],
      categoryProps: {
        value: "id",
        label: "name",
        checkStrictly: true,
        emitPath: false,
      },
      //所属领域
      belongAreas: [],
      belongAreasProps: {
        multiple: true,
        value: "id",
        label: "name",
      },
      publishDate: "",
      implementDate: "",
      voidDate: "",
      startBeginDate: [],
      overStartDate: [],
      loading: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "标准",
        userId: ""
      },
      pageSizes:[10, 20, 30, 40],
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    if (this.$store.state.common.qiehuan) {
      this.activeName = this.$store.state.common.qiehuan;
    }

    this.standardLibrary(this.standardLibraryPage.pages);
    this.solicitComments(this.solicitCommentsPage.pages);
    this.getCategory();
    this.getStandardForm();
    this.getState();
    this.getBelongAreas();
    // this.getApplicableProducts();
    this.getInspectionItems();
    this.ttDemension();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    viewDetail(path, id) {
      let newUrl = this.$router.resolve({
        path: path + id
      });

      window.open(newUrl.href, "_blank");

    },
    standardLibrary(params) {

        this.loading = true

        standardLibrary(params).then((res) => {
          if (res.ok) {
            this.standardLibraryDate = res.data.list;
            this.standardLibraryPage.total = res.data.count;
          }
          this.loading = false
        });

    },
    handleSizeChange(val) {
      this.standardLibraryPage.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.standardLibraryPage.pages.page = val;
      this.init();
    },
    //表单查询
    init() {
      let form = Object.assign(
          {},
          this.form,
          this.standardLibraryPage.pages
      );
      // form.applicableProducts = form.applicableProducts.join(",");
      form.inspectionItems = form.inspectionItems.join(",");
      form.startImplementDate = this.implementDate[0];
      form.endImplementDate = this.implementDate[1];
      form.startPublishDate = this.publishDate[0];
      form.endPublishDate = this.publishDate[1];
      form.startVoidDate = this.voidDate[0];
      form.endVoidDate = this.voidDate[1];
      if (form.belongAreas) {
        form.belongAreas = this.unique(
            form.belongAreas.join(",").split(",")
        ).join(",");
      }

      delete form.startBeginDate;
      delete form.startEndDate;
      delete form.overStartDate;
      delete form.overEndDate;
      delete form.manageUnit;
      this.standardLibrary(form);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    searchForm() {
      this.formLog.eventType = "2";

      this.standardLibraryPage.pages.page = 1;
      this.solicitCommentsPage.pages.page = 1;
      this.init();
      this.init2();


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //视图切换
    viewSwitch() {
      this.viewSwitchFlag = !this.viewSwitchFlag;
    },
    //标准类别字典查询
    getCategory() {
      // mng_bz_standard_typeOne
      selectDictDataByType({dictType: "standard_type"}).then((res) => {
        if (res.ok) {
          this.category = res.data;
        }
      });
    },
    //标准形式字典查询
    getStandardForm() {
      selectDictDataByType({dictType: "mng_bz_standard_modality"}).then(
          (res) => {
            if (res.ok) {
              this.standardForm = res.data;
            }
          }
      );
    },
    ttDemension() {
      ttDemension({code: 'standardAffectBody'}).then((res) => {
        this.standardSystem = res.data;
      });
    },
    //标准状态
    getState() {
      selectDictDataByType({dictType: "mng_bz_standard_state"}).then(
          (res) => {
            if (res.ok) {
              this.state = res.data;
            }
          }
      );
    },
    //所属领域
    getBelongAreas() {
      // tagsThree
      ttDemension({code: "standardTagsThree"}).then((res) => {
        this.belongAreas = res.data;
      });
    },
    //适用产品
    getApplicableProducts() {
      selectDictDataByType({dictType: "mng_cpfl"}).then((res) => {
        if (res.ok) {
          this.applicableProducts = res.data;
        }
      });
    },
    //检测项目
    getInspectionItems() {
      selectDictDataByType({dictType: "mng_jcxm"}).then((res) => {
        if (res.ok) {
          this.inspectionItems = res.data;
        }
      });
    },
    //重置表单
    resetForm() {
      this.$refs.form.resetFields();
      this.form.orderType = ''
      this.standardLibraryPage.pages.page = 1;
      this.solicitCommentsPage.pages.page = 1;
      this.implementDate = "";
      this.publishDate = "";
      this.voidDate = "";
      this.startBeginDate = [];
      this.overStartDate = [];
      this.$refs.tableBox.clearSort()
      this.init();
      this.init2();
    },
    //选择条数
    limitFun(list, form) {
      this.$refs[form].clearSelection();
      for (let i = 0; i < this.limitNum; i++) {
        this.$refs[form].toggleRowSelection(list[i]);
      }
    },
    //多选
    handleSelectionChange(val) {
      if (val.length > this.limitNum) {
        this.limitFun(val, "tableBox");
        return;
      }
      this.selectTable = [...val];
    },
    handleSelectionChange2(val) {
      if (val.length > this.limitNum) {
        this.limitFun(val, "tableBox2");
        return;
      }
      this.selectTable2 = [...val];
    },
    //选中回调
    checkSelectTable(row) {
      let index = this.selectTable.findIndex((v) => v.id == row.id);
      if (this.selectTable.length >= this.limitNum) {
        if (index != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkSelectTable2(row) {
      let index = this.selectTable2.findIndex((v) => v.id == row.id);
      if (this.selectTable2.length >= this.limitNum) {
        if (index != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    //导出
    excelBtn() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('标准')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              if (this.selectTable.length <= 0) {
                this.$message({
                  message: "请选择导出数据",
                  type: "warning",
                });
                return false;
              }
              let ids = this.selectTable.map((item) => item.id).join(",");
              let self = this;
              standardDataExportZip({ids}).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "标准库.zip");
                  }
                });
              });
            }
          }
        } else {
          if (this.selectTable.length <= 0) {
            this.$message({
              message: "请选择导出数据",
              type: "warning",
            });
            return false;
          }
          let ids = this.selectTable.map((item) => item.id).join(",");
          let self = this;
          standardDataExportZip({ids}).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "标准库.zip");
              }
            });
          });        }
      } else {
        this.showLogin();
      }

    },
    handleSizeChange2(val) {
      this.solicitCommentsPage.pages.pageSize = val;
      this.init2();
    },
    handleCurrentChange2(val) {
      this.solicitCommentsPage.pages.page = val;
      this.init2();
    },
    init2() {
      let form = Object.assign(
          {},
          this.form,
          this.solicitCommentsPage.pages
      );
      // form.applicableProducts = form.applicableProducts.join(",");
      form.inspectionItems = form.inspectionItems.join(",");
      delete form.code;
      delete form.state;
      delete form.inspectionItems;
      delete form.startImplementDate;
      delete form.endImplementDate;
      delete form.startPublishDate;
      delete form.endPublishDate;
      delete form.startVoidDate;
      delete form.endVoidDate;
      delete form.orderType
      form.startBeginDate = this.startBeginDate[0] || "";
      form.startEndDate = this.startBeginDate[1] || "";
      form.overStartDate = this.overStartDate[0] || "";
      form.overEndDate = this.overStartDate[1] || "";

      if (form.belongAreas) {
        form.belongAreas = this.unique(
            form.belongAreas.join(",").split(",")
        ).join(",");
      }
      this.solicitComments(form);
    },
    //标准征求意见/报批稿
    solicitComments(params) {

        solicitComments(params).then((res) => {
          if (res.ok) {
            this.solicitCommentsData = res.data.list;
            this.solicitCommentsPage.total = res.data.count;
          }
        });

    },
    //标准分类目录切换
    categoryChange(val) {
      let categoryType = "";
      if (val == 1 || val == 2 || val == 4 || val == 6) {
        categoryType = "system";
      }
      if (val == 3) {
        categoryType = "city";
      }
      if (val == 5) {
        categoryType = "team";
      }
    },
    //导出2
    excelBtn2() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('标准')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              if (this.selectTable2.length <= 0) {
                this.$message({
                  message: "请选择导出数据",
                  type: "warning",
                });
                return false;
              }
              let ids = this.selectTable2.map((item) => item.id).join(",");
              let self = this;
              solicitCommentsExportZip({ids}).then((res) => {
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "标准征求意见/报批稿.zip");
                  }
                });
              });
            }
          }
        } else {
          if (this.selectTable2.length <= 0) {
            this.$message({
              message: "请选择导出数据",
              type: "warning",
            });
            return false;
          }
          let ids = this.selectTable2.map((item) => item.id).join(",");
          let self = this;
          solicitCommentsExportZip({ids}).then((res) => {
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "标准征求意见/报批稿.zip");
              }
            });
          });        }
      } else {
        this.showLogin();
      }

    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //tab切换
    handleClick() {
      this.resetForm();
      this.checkSelectTable({id: 0});
      this.checkSelectTable2({id: 0});
      this.selectTable2 = [];
      this.selectTable = [];
      this.$refs.tableBox.clearSelection();
      this.$refs.tableBox2.clearSelection();
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'publishDate') {
          return 'publish_date'
        } else {
          return column.prop
        }

      } else if (column.order == 'descending') {
        if (column.prop == 'publishDate') {
          return 'publish_date' + ' DESC'
        } else {
          return column.prop + ' DESC'
        }

      } else {
        return ''
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;

  .el-form-item {
    margin-bottom: 16px;
  }
}

.shijian {
  /deep/ .el-date-editor .el-range-input {
    width: 44%;
  }
}

.state {
  padding: 4px 6px;
  background: #f2c94c;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  font-weight: bold;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0D57BC;
  color: #fff;
}

.import-input {
  position: relative;

  /deep/ .el-input__inner {
    background: rgba(13, 87, 188, 0.03);
    border: 1px solid rgba(13, 87, 188, 0.7);
    border-radius: 2px;
    padding-right: 40px;

    &::placeholder {
      color: rgba(13, 87, 188, 0.5);
    }
  }

  &::after {
    position: absolute;
    right: 5px;
    top: 10px;
    display: block;
    content: "常用";
    text-align: center;
    width: 30px;
    height: 20px;
    line-height: 20px;
    background: rgba(13, 87, 188, 0.5);
    border-radius: 1px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
