<template>
    <PageContainer>
        <div style="padding:0 24px">
            <div class="search-box">
                <div flex="sb">
                    <h1 class="yshxh1">按类别查询</h1>
                    <JsonExcel :data="chartsTable" v-has-role="'trialrole'"  :fields="charts_fields" class="excelBtn" name="汽车新注册量图表.xls">导出</JsonExcel>

                </div>
                <el-form inline label-width="100px">
                    <el-form-item label="地区">
                        <el-select v-model="cityValue" placeholder="请选择" size="small" @change="cityValueChange">
                            <el-option v-for="item in city" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="细分市场">
                        <el-select v-model="cxlbie1" placeholder="请选择" size="small" @change="cxlbie1Change">
                            <el-option v-for="item in xfsc" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="cxlbie1 == '1'||cxlbie1 == '2'">
                        <el-select v-model="cxlbie2" placeholder="请选择" size="small" @change="cxlbie2Change">
                            <el-option v-for="item in xfsczl" :key="item.label" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div id="carCharts"></div>
            <h2 class="car-title">{{title3}}</h2>
        </div>
        <div style="padding:16px 24px 0px;background:#fff;border-top:10px solid #f6f6f6">
            <div class="search-box">
                <div flex="sb">
                    <h1 class="yshxh1">按时间查询</h1>
                    <JsonExcel :data="cxzclTable" v-has-role="'trialrole'" :fields="json_fields" class="excelBtn" name="汽车新注册量.xls">导出</JsonExcel>

                </div>
                <el-form>
                    <el-form-item label="年份">
                        <el-select v-model="year" placeholder="请选择" size="small" @change="yearChange" style="width:90px">
                            <el-option v-for="item in years" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        年分省份民用汽车新注册量（辆）
                    </el-form-item>
                </el-form>
            </div>
            <!-- <h2 class="car-title">{{year}}</h2> -->

            <el-table :data="cxzclTable" style="width: 100%; margin-bottom: 20px" stripe border :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
                <el-table-column prop="provice" label="年份" align="center" header-align="center">
                </el-table-column>
                <el-table-column prop="zz" label="民用汽车" align="center" header-align="center">
                </el-table-column>
                <el-table-column prop="zkz" label="载客汽车" align="center" header-align="center">
                </el-table-column>
                <el-table-column>
                    <el-table-column prop="zk1" label="大型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zk2" label="中型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zk3" label="小型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zk4" label="微型" align="center" header-align="center">
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="zhz" label="载货汽车" align="center" header-align="center">
                </el-table-column>
                <el-table-column>
                    <el-table-column prop="zh1" label="重型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zh2" label="中型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zh3" label="轻型" align="center" header-align="center">
                    </el-table-column>
                    <el-table-column prop="zh4" label="微型" align="center" header-align="center">
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="qt" label="其他汽车" align="center" header-align="center">
                </el-table-column>
            </el-table>
        </div>
    </PageContainer>
</template>
<script>
import * as echarts from "echarts";
import { cxzcl, queryYearList, queryProvince, qczclchart } from "@/api/data.js";
import JsonExcel from "vue-json-excel";
import {logRecords} from "@/utils/auth";
export default {
    components: {
        JsonExcel,
    },
    data() {
        return {
            cxzclTable: [],
            year: "",
            years: [],
            city: [],
            cityValue: "全国",
            cxlbie1: "",
            cxlbie2: "",
            xfsc: [
                {
                    value: "",
                    label: "民用汽车",
                },
                {
                    value: "1",
                    label: "载客汽车",
                    children: [
                        {
                            value: "",
                            label: "不限",
                        },
                        {
                            value: "1",
                            label: "大型",
                        },
                        {
                            value: "2",
                            label: "中型",
                        },
                        {
                            value: "3",
                            label: "小型",
                        },
                        {
                            value: "4",
                            label: "微型",
                        },
                    ],
                },
                {
                    value: "2",
                    label: "载货汽车",
                    children: [
                        {
                            value: "",
                            label: "不限",
                        },
                        {
                            value: "1",
                            label: "重型",
                        },
                        {
                            value: "2",
                            label: "中型",
                        },
                        {
                            value: "3",
                            label: "轻型",
                        },
                        {
                            value: "4",
                            label: "微型",
                        },
                    ],
                },
                {
                    value: "3",
                    label: "其他汽车",
                },
            ],
            xfsczl: [],
            title3: "",
            json_fields: {
                年份: "provice",
                民用汽车: "zz",
                载客汽车: "zkz",
                大型: "zk1",
                中型: "zk2",
                小型: "zk3",
                微型: "zk4",
                载货汽车: "zhz",
                重型: "zh1",
                中型: "zh2",
                轻型: "zh3",
                微型: "zh4",
                其他汽车: "qt",
            },
            chartsTable: [],
            charts_fields: {
                年份: "year",
                新注册量: "zcl",
                同比增速: "tbzs",
            },
        };
    },
    mounted() {
        this.init();
        this.qczclchart();
    },
    methods: {
        //初始化数据
        init() {
            //年份
            queryYearList().then((res) => {
                if (res.ok) {
                    this.years = res.data;
                    this.year = res.data[0];
                    this.cxzcl(this.year);
                }
            });
            queryProvince().then((res) => {
                if (res.ok) {
                    let city = ["全国"].concat(res.data);
                    this.city = city;
                }
            });
        },
        //表格
        cxzcl(year) {
            cxzcl({ year }).then((res) => {
                if (res.ok) {
                    this.cxzclTable = res.data;
                }
            });
        },
        //时间下拉框选择
        yearChange(e) {
          logRecords('数据','汽车新注册量','3','tt_cx_byl_zcl','','','','','时间筛选')

          this.cxzcl(e);
        },
        //细分类别下拉
        cxlbie1Change(e) {
          logRecords('数据','汽车新注册量','3','tt_cx_byl_zcl','','','','','类别筛选')

          let filterData = this.xfsc.filter((item) => item.value == e);
            this.cxlbie2 = "";
            if (filterData[0].children && filterData[0].children.length > 0) {
                this.xfsczl = filterData[0].children;
            }
            this.cxlbie1 = e;
            this.qczclchart();
        },
        qczclchart() {
            let obj = {
                provice: this.cityValue == "全国" ? "" : this.cityValue,
                cxlbie1: this.cxlbie1,
                cxlbie2: this.cxlbie2,
            };
            qczclchart(obj).then((res) => {
                if (res.ok) {
                    this.title3 = res.data.title3;
                    let xAxis3 = res.data.xAxis3;
                    let series3 = res.data.series3;
                    let table = [];

                    xAxis3.forEach((item, index) => {
                        let obj = {};
                        obj["year"] = item;
                        series3.forEach((citem, cindex) => {
                            if (citem.name == "新注册量") {
                                obj["zcl"] = citem.data[index];
                            } else if (citem.name == "同比增速") {
                                obj["tbzs"] = citem.data[index];
                            }
                        });
                        table.push(obj);
                    });
                    this.chartsTable = table;
                    this.echartsInit(res.data);
                }
            });
        },
        //echarts
        echartsInit(data) {
            var myChart = echarts.init(document.getElementById("carCharts"));
            // console.log(JSON.stringify(data.series3))
            var option = {
                title: {
                    subtext: "万辆",
                },
                legend: {
                    data: ["新注册量", "同比增速"],
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (datas) {
                        let res = datas[0].name + "<br/>";
                        let val;
                        let length = datas.length;
                        let i = 0;
                        for (; i < length; i++) {
                            if (datas[i].seriesType == "line") {
                                val = Number(datas[i].value)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                                res +=
                                    '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                                    datas[i].color +
                                    ';"></span>' +
                                    datas[i].seriesName +
                                    "：" +
                                    val +
                                    "<br/>";
                            } else {
                                val = datas[i].value;
                                res +=
                                    '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                                    datas[i].color +
                                    ';"></span>' +
                                    datas[i].seriesName +
                                    "：" +
                                    val +
                                    "<br/>";
                            }
                        }
                        return res;
                    },
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                grid: {
                    left: "100%",
                    right: "100%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: data.xAxis3,
                        axisTick: {
                            inside: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                    {
                        type: "value",
                        axisLabel: {
                            formatter: "{value} %",
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                ],
                series: data.series3,
            };
            myChart.setOption(option);
        },
        cityValueChange(e) {
            this.cityValue = e;
            this.qczclchart();
        },
        cxlbie2Change(e) {
            this.cxlbie2 = e;
            this.qczclchart();
        },
    },
};
</script>
<style lang="scss" scope>
.yshxh1 {
    font-size: 16px;
    margin-bottom: 15px;
}
.search-box,
.search-form {
    margin-bottom: 15px;
}
#carCharts {
    width: 100%;
    height: 500px;
}
.car-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 15px;
}
</style>

