<template>
  <div class="comparative-analysis-compared">
    <el-card :body-style="{ padding: '0 30px 28px' }">
      <div
        slot="header"
        v-if="selectedEnterprise.length"
        class="selected-enterprise"
      >
        <span class="title">已选企业</span>
        <span v-for="(item, index) in selectedEnterprise" :key="index">
          <el-button class="selected-btn" round>
            {{ findlabelByIds(item, enterpriseOption) }}
            <i class="el-icon-close" @click="delSelected(index)"></i>
          </el-button>
        </span>
      </div>
      <div class="enterprise-Select">
        <span class="filter-name">企业类型</span>
        <div class="select-btn" v-for="i in buttons">
          <span v-for="o in i">
            <el-button
              v-if="
                changeBtnSelect(o.type) ||
                  (o.type === '整车企业' && selectedCarType)
              "
              :key="o.type"
              class="round-select"
              :type="buttonType(o)"
              round
              @click="selected(o.type)"
            >
              {{ o.label }}
            </el-button>
            <el-button v-else type="text" @click="selected(o.type)">
              {{ o.label }}
            </el-button>
          </span>
        </div>
      </div>
      <div class="enterprise-name" style="margin: 23px 0;">
        <span class="filter-name">企业名称</span>
        <SearchSelect
          ref="enterpriseSelect"
          popper-class="enterprise-name-type"
          placeholder="请选择企业名称"
          :filterable="true"
          :multiple="true"
          :collapseTags="false"
          :multipleLimit="0"
          v-model="selectedEnterprise"
          :options="enterpriseOption"
          promptText="最多支持3家同时对比"
        />
      </div>
      <div class="contrast-dimensions" style="margin-bottom: 34px;">
        <span class="filter-name">对比维度</span>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedList">
          <el-checkbox-button label="全部" @change="checkedAll" />
          <el-checkbox-button
            v-for="city in compareOptions"
            :label="city"
            :key="city"
            :name="city"
            @change="deselectAll"
          >
            {{ city }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>

      <div class="submit-box">
        <el-tooltip
          v-if="disabledType"
          :content="
            selectedEnterprise.length === 0
              ? '请选择对比企业'
              : '一次至少选择2家企业'
          "
          placement="top"
        >
          <el-button
            type="info"
            :style="{
              width: '447px',
              height: '53px',
              fontSize: '14px',
              color: '#999',
              backgroundColor: '#f3f3f3',
              border: 0
            }"
            :class="[{ submit: !disabledType }]"
            plain
            :disabled="disabledType"
          >
            <span :style="{ lineHeight: '36px', marginRight: '18px' }">
              一键生成对比报告
            </span>
            <i
              class="icon-xiangyoujiaohuan iconfont"
              :style="{ fontSize: '36px' }"
            ></i>
          </el-button>
        </el-tooltip>
        <el-button
          v-else
          type="info"
          :style="{
            width: '447px',
            height: '53px',
            fontSize: '14px',
            color: '#999',
            backgroundColor: '#f3f3f3',
            border: 0
          }"
          :class="[{ submit: !disabledType }]"
          plain
          :disabled="disabledType"
          @click="changeQuery"
        >
          <span :style="{ lineHeight: '36px', marginRight: '18px' }">
            一键生成对比报告
          </span>
          <i
            class="icon-xiangyoujiaohuan iconfont"
            :style="{ fontSize: '36px' }"
          ></i>
        </el-button>

        <el-button class="reset" type="text" @click="resetData">重置</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from "@/utils/auth.js";
import { getEnterpriseSelectionsList } from "@/api/enterpriseAnalysis.js";
import { buryPoint } from "@/api/common";

import SearchSelect from "@/components/searchSelect";
export default {
  name: "ComparedModal",
  components: {
    SearchSelect
  },
  data() {
    return {
      selectedEnterpriseType: "整车企业",
      selectedCarType: true,
      isCheckedAll: true,
      disabledType: false,
      enterpriseData: {},
      compareOptions: [],
      selectedEnterprise: [],
      checkedList: ["全部"],
      queryRouteData: {},
      buttons: [
        [
          { type: "整车企业", label: "整车企业" },
          { type: "乘用车", label: "乘用车" },
          { type: "商用车", label: "商用车" }
        ],
        [{ type: "零部件企业", label: "零部件企业" }]
      ],
      completeVehicle: [
        "基本信息",
        "产销量",
        "企业产能",
        "配套关系",
        "主要车型",
        "经营数据"
        // "资产结构"
      ],
      parts: ["基本信息", "主要产品", "配套关系", "业绩情况", "公司历程"],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      }
    };
  },
  methods: {
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    changeQuery() {
      if (getUserInfo()) {
        logRecords(
          "企业情报",
          "企业对比分析",
          "3",
          "",
          this.selectedEnterprise.join(", "),
          "",
          "",
          "",
          "一键生成对比报告"
        );
        if (
          getUserInfo().roleKey == "paidrole" ||
          getUserInfo().roleKey == "trialrole"
        ) {
          const menuName = getUserInfo().menuName;
          if (!menuName.includes("企业情报")) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.$router
              .replace({
                query: {
                  selectedEnterpriseType: this.selectedEnterpriseType,
                  selectedCarType: this.selectedCarType,
                  checkedList: this.checkedList,
                  selectedEnterprise: JSON.stringify(this.selectedEnterprise)
                }
              })
              .catch(err => {
                if (err.name !== "NavigationDuplicated") {
                  // 非冗余导航错误，可能需要进一步处理
                  throw err;
                }
                // 对于冗余导航错误，可以选择忽略
              });
          }
        } else {
          this.$router
            .replace({
              query: {
                selectedEnterpriseType: this.selectedEnterpriseType,
                selectedCarType: this.selectedCarType,
                checkedList: this.checkedList,
                selectedEnterprise: JSON.stringify(this.selectedEnterprise)
              }
            })
            .catch(err => {
              if (err.name !== "NavigationDuplicated") {
                // 非冗余导航错误，可能需要进一步处理
                throw err;
              }
              // 对于冗余导航错误，可以选择忽略
            });
        }
      } else {
        this.showLogin();
      }
    },
    delSelected(index) {
      this.selectedEnterprise.splice(index, 1);
    },
    findlabelByIds(id, userArray) {
      const user = userArray.find(user => user.value.toString() === id);
      return user ? user.label : null;
    },
    resetData() {
      this.selectedEnterpriseType = "整车企业";
      this.selectedCarType = true;
      this.isCheckedAll = true;
      this.disabledType = false;
      this.selectedEnterprise = [];
      this.checkedList = ["全部"];
    },
    modifyObjectArray(array, keyMap) {
      if (!keyMap || typeof keyMap !== "object") {
        return [];
      }
      // 通用的函数来修改对象数组的键名
      return array.map(item => {
        const modifiedItem = {};
        for (const oldKey in item) {
          if (keyMap.hasOwnProperty(oldKey)) {
            modifiedItem[keyMap[oldKey]] = item[oldKey];
          } else {
            modifiedItem[oldKey] = item[oldKey];
          }
        }
        return modifiedItem;
      });
    },
    getEnterpriseSelectionsList() {
      getEnterpriseSelectionsList().then(res => {
        if (res.ok) {
          // 新的键名映射
          const newKeyMap = {
            enterpriseId: "value",
            enterpriseType: "type",
            enterpriseName: "label",
            enterpriseLogoUrl: "logoUrl"
          };
          this.enterpriseData = {
            commercialManufacturerList: this.modifyObjectArray(
              res.data.commercialManufacturerList,
              newKeyMap
            ),
            passengerManufacturerList: this.modifyObjectArray(
              res.data.passengerManufacturerList,
              newKeyMap
            ),
            partsMakerList: this.modifyObjectArray(
              res.data.partsMakerList,
              newKeyMap
            )
          };
          this.initializeDataFromQuery("init");
        }
      });
    },
    checkedAll() {
      this.isCheckedAll = !this.isCheckedAll;
    },
    deselectAll() {
      this.isCheckedAll = false;
    },
    selected(type) {
      if (
        type === "零部件企业" ||
        this.selectedEnterpriseType === "零部件企业"
      ) {
        this.isCheckedAll = true;
      }
      this.selectedEnterpriseType = type;
      this.selectedCarType = "乘用车".includes(type) || "商用车".includes(type);
      // 当选择或离开零部件企业时，自动选择全部对比维度
    },
    buttonType(val) {
      if (val.type === "乘用车" || val.type === "商用车") {
        return "text";
      }
      return "整车企业".includes(val.type) || "零部件企业".includes(val.type)
        ? "primary"
        : "text";
    },
    changeBtnSelect(val) {
      return val.includes(this.selectedEnterpriseType);
    },
    initializeDataFromQuery(isInit) {
      // 使用路由参数初始化数据
      if (isInit) {
        this.queryRouteData = this.$route.query;
      } else {
        this.$router
          .replace({
            query: { ...this.queryRouteData }
          })
          .catch(err => {
            if (err.name !== "NavigationDuplicated") {
              // 非冗余导航错误，可能需要进一步处理
              throw err;
            }
            // 对于冗余导航错误，可以选择忽略
          });
      }
      if (this.queryRouteData) {
        this.selectedEnterpriseType =
          this.queryRouteData.selectedEnterpriseType || "整车企业";
        this.checkedList = Array.isArray(this.queryRouteData.checkedList)
          ? this.queryRouteData.checkedList
          : [this.queryRouteData.checkedList];
        this.selectedCarType = this.queryRouteData.selectedCarType === "true";
        setTimeout(() => {
          this.selectedEnterprise = JSON.parse(
            this.queryRouteData.selectedEnterprise
          );
        }, 200);
      }
    }
  },
  computed: {
    enterpriseOption() {
      this.selectedEnterprise = [];
      let selectOptinData = [];
      const passengerManufacturerList = Array.isArray(
        this.enterpriseData.passengerManufacturerList
      )
        ? this.enterpriseData.passengerManufacturerList
        : [];
      const commercialManufacturerList = Array.isArray(
        this.enterpriseData.commercialManufacturerList
      )
        ? this.enterpriseData.commercialManufacturerList
        : [];
      const partsMakerList = Array.isArray(this.enterpriseData.partsMakerList)
        ? this.enterpriseData.partsMakerList
        : [];

      switch (this.selectedEnterpriseType) {
        case "乘用车":
          selectOptinData = passengerManufacturerList;
          break;
        case "商用车":
          selectOptinData = commercialManufacturerList;
          break;
        case "整车企业":
          selectOptinData = Array.from(passengerManufacturerList).concat(
            commercialManufacturerList
          );
          break;

        default:
          selectOptinData = partsMakerList;
          break;
      }
      return selectOptinData;
    }
  },
  watch: {
    isCheckedAll(newVal) {
      if (newVal) {
        this.checkedList = ["全部"];
      } else {
        this.checkedList = this.checkedList.filter(item => item !== "全部");
      }
    },
    selectedEnterprise: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.disabledType = newVal.length > 1 ? false : true;
        if (newVal.length > 3) {
          this.selectedEnterprise = newVal.slice(0, 3);
          this.$refs.enterpriseSelect.triggerChildBlur();
          this.$alert("一次最多仅支持选择3家企业", "", {
            confirmButtonText: "确定"
          });
        }
      }
    },
    checkedList: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal.length) {
          this.isCheckedAll = true;
        }
      }
    },
    selectedEnterpriseType: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.compareOptions =
          newVal === "零部件企业" ? this.parts : this.completeVehicle;
      }
    }
  },
  created() {
    this.getEnterpriseSelectionsList();
  }
};
</script>
<style lang="scss">
.comparative-analysis-compared {
  .enterprise-name-type {
    min-width: 470px;
  }
  .el-card__header {
    padding-left: 30px;
  }

  .el-select {
    min-width: 470px;
  }
  .el-button {
    padding: 8px 14px;
    &.el-button--text {
      color: #333;
      &.is-round {
        color: #3f76ea;
      }
    }
  }
  .selected-btn {
    padding: 8px 10px;
    border: solid 1px #3f76ea;
    margin-right: 10px;
    span {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #3f76ea;
      i {
        font-size: 12px;
        color: #3f76ea;
      }
    }
  }
  .submit-box {
    .submit {
      background-color: #3f76ea !important;
      color: #fefefe !important;
    }
    .reset {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #999 !important;
    }
    .el-button--info > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .el-checkbox-button__inner {
    padding: 8px 14px;
    border-radius: 20px !important;
    border: 0 !important;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: #3f76ea;
  }
  .rounnd-select.is-round,
  .text-select.el-button--text {
    padding: 8px 14px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.selected-enterprise {
  .title {
    margin-right: 20px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #333;
  }
}

.contrast-dimensions {
  display: flex;
  align-items: center;
}

.enterprise-Select {
  padding-top: 20px;
  display: flex;
  align-items: center;

  .select-btn:nth-last-child(1)::before {
    content: "";
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #d8d8d8;
  }
}

.filter-name {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  margin-right: 20px;
  color: #999;
}
</style>
<style src="../components/iconfont/iconfont.css"></style>
