<!--
 * @Author: your name
 * @Date: 2021-07-25 13:23:13
 * @LastEditTime: 2021-07-26 08:47:20
 * @LastEditors: Please set LastEditors
 * @Description: 车型详细页面
 * @FilePath: /information-vue/code/src/views/workInformation/information/workDetail.vue
-->
<template>
        <router-view v-if="
        $route.name === '公司动态列表' ||
        $route.name === '公司动态内容页' ||
        $route.name === '高管历史'
      " />

        <PageContainer v-else>
            <!-- <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips" /> -->
            <template>
                <div class="search-form">
<!--                    <h1 style="font-size: 24px">{{ info.enterpriseName }}</h1>-->
<!--                    <div style="margin-top: 10px">-->
<!--                        <el-tag v-for="(item, index) in info.tag" :key="index">{{ item }}</el-tag>-->
<!--                    </div>-->
                  <span style="font-weight: bold">选择企业：</span>
                  <el-select v-model="enterpriseName" @change="qySelect">
                    <el-option
                        v-for="item in list"
                        :key="item.org[0].id"
                        :label="item.org[0].enterpriseName"
                        :value="item.org[0].id">
                    </el-option>
                  </el-select>
                </div>
                <!-- <div flex="c" class="g-list-box clearfix">
          <div class="g-list1">
            <ItemTitle
              title="公司动态"
              class="title"
              :toHref="
                '/workInformation/information/workDynamicList?enterpriseId=' +
                enterpriseId +
                '&enterpriseType=' +
                $route.query.enterpriseType
              "
            />
            <div class="g-list-item-box">
              <div class="g-list-item" v-for="item in dynamic" :key="item.id">
                <ItemList
                  :to="
                    '/workInformation/information/workDynamicDetail?id=' +
                    item.id +
                    '&enterpriseType=' +
                    $route.query.enterpriseType +
                    '&enterpriseId=' +
                    $route.query.enterpriseId
                  "
                >
                  <div class="tags">
                    <span v-for="citem in item.keyWord" :key="citem">{{
                      citem
                    }}</span>
                  </div>
                  <slot slot="title">{{ item.tilte }}</slot>
                  <slot slot="time">{{ item.publishDate }}</slot>
                </ItemList>
              </div>
            </div>
          </div>
        </div> -->

                <div class="search-form">
                    <UnfurlAndFold title="公司动态" :more="true" toName="查看更多" :toHref="
              '/workInformation/syc/information/workDynamicList?enterpriseId=' +
              id +
              '&enterpriseType=' +
              enterpriseType
            ">
                        <div class="dynamics-list">
                            <div class="g-list-item-box">
                                <div class="g-list-item" v-for="item in dynamic" :key="item.id">
                                    <ItemList :to="
                      '/workInformation/syc/information/workDynamicDetail?id=' +
                      item.id +
                      '&enterpriseType='+enterpriseType+'&enterpriseId=' +
                      id
                    ">
                                        <div class="tags">
                                            <span v-for="citem in item.keyWord" :key="citem">{{
                        citem
                      }}</span>
                                        </div>
                                        <slot slot="title">{{ item.tilte }}</slot>
                                        <slot slot="time">{{ item.updateTime }}</slot>
                                    </ItemList>
                                </div>
                            </div>
                        </div>
                    </UnfurlAndFold>
                </div>

                <!-- <div class="search-form">
                    <UnfurlAndFold :title="enterpriseTypeSwitch == true ? '部门架构' : '子公司'">
                        <orgTree :record="record" />
                    </UnfurlAndFold>
                </div> -->
                <div class="search-form" v-if="isShowExecutive === '1'">
                    <UnfurlAndFold title="高管信息" :more="true" toName="变更历史" :toHref="histroyHref">
                        <el-table :data="executiveInfoData" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px" @sort-change="tableSort">
                            <el-table-column prop="position" label="职位" width="300" sortable="custom" />
                            <el-table-column prop="name" label="姓名" width="108" sortable="custom" />
                            <el-table-column prop="briefIntro" label="简介" fit >
                                <template slot-scope="scope">
                                    {{matchReg(scope.row.briefIntro)}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="text-right">
                            <el-pagination @size-change="executiveInfoChange" @current-change="executiveInfoCurrentChange" :current-page="executiveInfoPage.currentPage" :page-sizes="[6, 15, 20, 25]" :page-size="executiveInfoPage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="executiveInfoPage.total">
                            </el-pagination>
                        </div>
                    </UnfurlAndFold>
                </div>
                <div class="search-form" v-if="isShowStrategic === '1'">
                    <UnfurlAndFold title="战略规划">
                        <el-form label-width="110px" :model="strategicPlanningForm" ref="strategicPlanningForm">
                            <div flex="sb">
                                <el-form-item label="关键字" prop="companyName">
                                    <el-row type="flex" align="middle">
                                        <el-input v-model="strategicPlanningForm.companyName" size="small" placeholder="请输入内容" class="g-form-input"></el-input>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="所属类别：" prop="types">
                                    <el-select v-model="strategicPlanningForm.types" multiple collapse-tags placeholder="请选择" size="small" class="g-form-input">
                                        <el-option v-for="item in sbxz" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                                        </el-option>
                                    </el-select>
                                    <!-- <el-input v-model="strategicPlanningForm.types" placeholder="请输入内容" size="small" ></el-input> -->
                                </el-form-item>
                                <el-form-item label="时间区段：">
                                    <el-date-picker v-model="allDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" size="small" style="width:222px">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="text-center" style="margin-bottom: 38px">
                                <el-button class="reset-button" @click="strategicPlanningRest">重置</el-button>
                                <el-button class="search-button" @click="strategicPlanningByPage">查询</el-button>
                            </div>
                        </el-form>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 10px">
                            <div style="flex: 0 0 50%; width: 50%" v-for="item in strategicPlanningData" :key="item.id">
                                <el-card style="margin: 0 10px 10px 0">
                                    <div flex="sb-noa" style="width: 100%">
                                        <div style="flex: 1; display: flex" flex="c">
                                            <div class="tags">
                                                <span v-for="citem in item.type" :key="citem">{{ citem}}</span>
                                            </div>
                                            <h1 class="titles" :title="`${item.title}`">
                                                {{ item.title }}
                                            </h1>
                                        </div>
                                        <span style="padding-top:3px; width: 55px">{{item.publishDate}}</span>
                                    </div>
                                    <p style=" font-size: 14px;line-height: 24px;color: #606266;height: 98px;margin-top: 10px;">
                                        {{ item.summary }}
                                    </p>
                                </el-card>
                            </div>
                        </div>
                        <div class="text-right">
                            <el-pagination @size-change="strategicPlanningSizeChange" @current-change="strategicPlanningCurrentChange" :current-page="strategicPlanningPage.currentPage" :page-sizes="[6, 15, 20, 25]" :page-size="strategicPlanningPage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="strategicPlanningPage.total">
                            </el-pagination>
                        </div>
                    </UnfurlAndFold>
                </div>
                <div class="search-form" v-if="isShowOperation === '1'">
                    <UnfurlAndFold title="经营情况">
                        <div class="g-content-top" flex="sb">
                            <h1>近年财务数据</h1>
                            <div>
                                <!-- <el-button type="primary" plain> 查看财报 </el-button> -->
                                <el-button type="primary" v-has-role="'freeusers'" plain @click="businessCircumstanceExport">
                                    导出
                                </el-button>
                            </div>
                        </div>
                        <el-table :data="businessCircumstanceData" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px; margin-top: 20px">
                              <el-table-column prop="reportPeriod" label="报告期" width="80" />
                            <el-table-column prop="salesRevenue" label="营业收入（亿元）" />
                            <el-table-column prop="retainedProfit" label="净利润（亿元）" />
                            <el-table-column prop="rossProfit" label="毛利润（亿元）" />
                            <el-table-column prop="shareholderProfits" label="归属母公司股东净利润（亿元）" width="200" />
                            <el-table-column prop="profitGrowth" label="归属母公司股东净利润同比增长" />
                            <!-- <el-table-column prop="rossProfit" label="销售毛利率" />
                            <el-table-column prop="retainedProfit" label="销售净利率" /> -->
                            <el-table-column prop="developExpenses" label="研发费用（亿元）" />
                            <el-table-column prop="saleExpenses" label="销售费用（亿元）" />
                    
                        </el-table>
                        <div class="text-right">
                            <el-pagination @size-change="businessCircumstanceChange" @current-change="businessCircumstanceCurrentChange" :current-page="businessCircumstancePage.currentPage" :page-sizes="[6, 15, 20, 25]" :page-size="businessCircumstancePage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="businessCircumstancePage.total">
                            </el-pagination>
                        </div>
                    </UnfurlAndFold>
                </div>
                <div class="search-form" v-if="isShowSales === '1'">
                    <UnfurlAndFold title="产销量">
                        <el-form label-width="110px" :model="salesForm" ref="salesForm" inline>
                            <el-row>
<!--                                <el-col :span="8">-->
<!--                                    <el-form-item label="车辆类型：" prop="category">-->
<!--                                        <el-select v-model="salesForm.category" placeholder="请选择车型" size="small" @change="leixqiehuan">-->
<!--                                            <el-option v-for="carItem in carTypes" :value="carItem.value" :key="carItem.value" :label="carItem.label"></el-option>-->
<!--                                        </el-select>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
                                <el-col :span="8">
                                    <VdateEndStart v-model="salesForm.times" @change="timeChange" :checkDate="salesForm.checkDate" :close="false" />
                                </el-col>
                            </el-row>
                            <div class="text-center" style="margin-top: 20px">
                                <el-button @click="resetFilter('salesForm')">重置</el-button>
                                <el-button @click="searchFilter('salesForm')" type="primary">查询</el-button>
                            </div>
                        </el-form>
                        <div class="g-content-top" flex="sb">
                            <h1></h1>
                            <div>
                                <el-button type="primary" v-has-role="'freeusers'" plain @click="ttSalesDownload">
                                    导出
                                </el-button>
                            </div>
                        </div>
                        <el-table :data="tableData" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px; margin-top: 20px">
                            <el-table-column label="车型" prop="vehicleModelName"></el-table-column>
                            <template v-for="nitem in tableHeaderTime">
                                <el-table-column :label="nitem.dateStr" :key="nitem.dateStr">
                                    <el-table-column :prop="nitem.sc" label="生产">
                                    </el-table-column>
                                    <el-table-column :prop="nitem.xs" label="销售">
                                    </el-table-column>
                                </el-table-column>
                            </template>

                            <el-table-column prop="total" label="合计">
                            </el-table-column>

                        </el-table>
                        <div class="text-right">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="tableParams.page" :page-sizes="[6, 15, 20, 25]" :page-size="tableParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="tableParams.count">
                            </el-pagination>
                        </div>
                    </UnfurlAndFold>
                </div>

                <div class="search-form" v-if="enterpriseTypeSwitch && isShowBase === '1'">
                    <UnfurlAndFold title="生产基地">
                        <Vmap :newMapData="baseData.data" v-if="baseData.data.length > 0" />
                        <div class="production-tab-box">
                            <div class="production-tab">
                                <a href="javascript:;" :class="{ ac: productionTabIndex == 1 }" @click="productionTabClick(1, '')">全部({{ baseData.allTotal }})</a>
                                <template v-for="item in baseType">
                                    <a href="javascript:;" :class="{ ac: productionTabIndex == 2 }" @click="productionTabClick(2, item.dictValue)" :key="item.dictValue" v-if="item.dictValue == 'tt_new_energy'">新能源车基地({{ baseData.newEnergyCount }})</a>
                                    <a href="javascript:;" :class="{ ac: productionTabIndex == 3 }" @click="productionTabClick(3, item.dictValue)" :key="item.dictValue" v-if="item.dictValue == 'fuel_vehicle'">燃油车基地({{ baseData.fuelVehicleCount }})</a>
                                </template>
                            </div>
                            <div class="production-tab-str">
                                <span>更新时间：{{ nowTime }}</span>
                            </div>
                        </div>
                        <el-table :data="baseData.data" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px; margin-top: 20px">
                            <el-table-column prop="province" label="省份" width="108" />
                            <el-table-column prop="city" label="城市" width="108">
                                <template slot-scope="scope">
                                    <span style="color: #0D57BC">{{ scope.row.city }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="baseState" label="状态 " width="108"/>
                            <el-table-column prop="produceStatus" label="投入车型"/>
                            <el-table-column prop="bsaeType" label="基地类型" width="108">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.bsaeType == 'fuel_vehicle'">燃油车</span>
                                    <span v-if="scope.row.bsaeType == 'tt_new_energy'">新能源</span>
                                    <span v-if="scope.row.bsaeType == 'tt_new_energy,fuel_vehicle'">新能源,燃油车</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="maxCapacity" label="最大产能(万辆)"  width="108"   />
                            <el-table-column prop="briefIntro" label="备注说明" width="200" />
                        </el-table>
                    </UnfurlAndFold>
                </div>

                <div class="search-form" v-if="enterpriseTypeSwitch  && isShowPlatform === '1'">
                    <UnfurlAndFold title="车型平台">
                        <!-- <div class="production-tab-box">
         
            <div class="production-tab-str">
              <span>更新时间：{{ nowTime }}</span>
            </div>
          </div> -->
                        <div class="car-history">
                            <div>
                                <h2>当前及规划车型(带框为规划车型)</h2>
                                <p>
                                    更新于{{ nowTime }}，查看
                                    <el-popover placement="top-start" title="更新说明" width="484" trigger="hover" :content="cxremake">
                                        <span slot="reference">更新说明</span>
                                    </el-popover>
                                </p>
                            </div>
                            <div>
                                <el-button type="primary" v-has-role="'freeusers'" plain @click="TypePlatformExport">
                                    导出
                                </el-button>
                            </div>
                        </div>
                        <el-table :data="carHistoryData" :header-cell-style="{ background: '#FAFAFA', color: '#000' }" style="width: 100%; margin-bottom: 20px; margin-top: 20px">
                            <el-table-column prop="platformType" label="平台性质" width="76" />
                            <el-table-column prop="platformName" label="平台名称" width="122"></el-table-column>
                            <el-table-column prop="rllx" label="燃料类型" width="200"/>
                            <el-table-column prop="existingModels" label="现有车型" />
                            <el-table-column prop="planningModels" label="规划车型" />
                        </el-table>
                    </UnfurlAndFold>
                </div>
            </template>
        </PageContainer>
</template>
<script>
/**
 * @description: 标准动态页面
 * @param {*}
 * @return {*}
 */
import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo,openSy } from "@/utils/auth.js";
import UnfurlAndFold from "@/components/UnfurlAndFold.vue";
import {
  queryByPageDynamic,
  queryByIdEnterpriseInfo,
  selectEnterpriseMap,
  executiveInfoQueryByPage,
  strategicPlanningByPage,
  businessCircumstance,
  businessCircumstanceExport,
  productionBaseQueryByPage,
  TypePlatformQueryByPage,
  TypePlatformExport,
  getDeptByEnterPrise,
  ttSalesTarget, //产销条件
  // ttSalesTargetPage, //产销量分页
  newdynamicExport, //产销量导出
  getRemarkCx,
  newselectTable, //产销量分页新
  newgetYearMonthList,
  newgetDefaultDate, queryGroupByInitial,
} from "@/api/enterpriseAnalysis.js";
import orgTree from "./components/orgTree.vue";
import { downloadFile } from "@/utils/download.js";
import { fmtDate } from "@/utils/date.js";
import { selectDictDataByType } from "@/api/dict.js";
import Vmap from "./components/map.vue";
import VdateEndStart from "@/components/dateEndStart";
export default {
    components: {
        VnotLogin,
        UnfurlAndFold,
        orgTree,
        Vmap,
        VdateEndStart,
    },
    data() {
        return {
            histroyHref: "",
            input: "",
            showTips: [
                {
                    title: "企业信息",
                    text: "最新权威动向和独家原创解读",
                },
                {
                    title: "企业库查询",
                    text: "权威企业库，多条件筛查关联比对",
                },
                {
                    title: "企业梳理",
                    text: "专家梳理整合，真正读懂企业脉络",
                },
                {
                    title: "企业大数据",
                    text: "用数据深度理解企业结构和趋势",
                },
            ],
            salesForm: {
                category: "2",
                checkYearMonth: false,
                checkDate: "month",
                times: [],
                startTime: "",
                endTime: "", //产销量日期
            },
            carTypes: [
                {
                    label: "乘用车",
                    value: "1",
                },
                {
                    label: "商用车",
                    value: "2",
                },
            ],
          enterpriseType:'商用车企业',
            tableData: [],
            tableParams: {
                count: 0,
                page: 1,
                pageSize: 10,
            },
            enterpriseName:'',
            pagination: {},
            currentPage: 0,
            show: false,
            info: {},
            list:[],
            dynamic: [],
            record: [],
            horizontal: false,
            collapsable: true,
            expandAll: false,
            //高管信息
            executiveInfoData: [],
            executiveInfoPage: {
                pages: {
                    pageNo: 1,
                    pageSize: 6,
                    enterpriseId: "",
                    orderType:""
                },
                total: 0,
                currentPage: 0,
            },
            //战略信息
            allDate: "",
            strategicPlanningForm: {
                types: [],
                endDate: "",
                startDate: "",
                companyName: "",
            },
            strategicPlanningData: [],
            strategicPlanningPage: {
                pages: {
                    pageNo: 1,
                    pageSize: 6,
                    enterpriseId: "",
                },
                total: 0,
                currentPage: 0,
            },
            businessCircumstanceData: [],
            businessCircumstancePage: {
                pages: {
                    page: 1,
                    pageSize: 6,
                    enterpriseId: "",
                },
                total: 0,
                currentPage: 0,
            },
            enterpriseTypeSwitch: null,
            enterpriseId: "",
            //生产企业
            productionTabIndex: 1,
            baseData: {
                allTotal: 0,
                fuelVehicleCount: 0,
                newEnergyCount: 0,
                data: [],
            },
            baseType: [],
            //车型历史
            carHistoryData: [],
            form: {
                startTime: "",
                endTime: "",
            },
            swatchDateStr: "按月",
            swatchDateType: "month",
            valueFormat: "yyyy-MM",
            sbxz: [],
            cxremake: "",
            isShowOperation: "1",
            isShowPlatform: "1",
            isShowSales: "1",
            isShowExecutive: "1",
            isShowBase: "1",
            isShowStrategic: "1",
            timesCheck: true,
            tableHeaderTime: [],
            id:''
        };
    },
    computed: {
        nowTime() {
            return fmtDate("YYYY-mm-dd", new Date());
        },
    },
    mounted() {
        if (getUserInfo()) {
            this.show = true;
            this.queryGroupByInitial();

        } else {
            this.show = false;
        }
    },

  methods: {
      qySelect(id){
        this.init(id);
      },
      init(id){
        this.histroyHref =
            "/workInformation/syc/information/historyInfo?enterpriseId=" +
            this.id;

        this.tableParams.enterpriseId =
            this.executiveInfoPage.pages.enterpriseId =
                this.strategicPlanningPage.pages.enterpriseId =
                    this.businessCircumstancePage.pages.enterpriseId =
                        id;
        this.queryByIdEnterpriseInfo({ id });
        this.queryByPageDynamic({
          pageNo: 1,
          pageSize: 6,
          enterpriseId: id,
        });
        this.executiveInfoQueryByPage();
        this.strategicPlanningByPage();
        this.businessCircumstance();
        let enterpriseType = '商用车企业';
        // this.ttSalesTargetPage({
        //     ...this.tableParams,
        // });
        this.newgetDefaultDate();
        if (enterpriseType === "整车集团") {
          this.selectEnterpriseMap({ id });
          this.enterpriseTypeSwitch = false;
        }

        if (
            enterpriseType === "乘用车企业" ||
            enterpriseType === "商用车企业" ||
            enterpriseType === "零部件企业"
        ) {
          this.productionBaseQueryByPage({
            enterpriseId: id,
            baseType: "",
          });
          this.selectDictDataByType({ dictType: "car_base_type" });
          this.TypePlatformQueryByPage({
            enterpriseId: id,
            pageNo: 1,
            pageSize: 20,
          });
          this.getDeptByEnterPrise({ enterpriseId: id });
          this.getRemarkCx({ enterpriseId: id });

          this.enterpriseTypeSwitch = true;
        }
        this.ttStrategicPlanningFl();
      },
      //字母获取列表
      queryGroupByInitial() {
        let data = {
          enterpriseType: '商用车企业',
        };

        queryGroupByInitial(data).then((res) => {
          if (res.ok) {

            this.list = res.data.map((item,index) => {
              item.org = JSON.parse(item.org);
              // item.org.map(citem=>citem.name = item.firstChar)
              return item;
            });
            this.enterpriseName = this.list[0].org[0].enterpriseName;
            this.id = this.list[0].org[0].id;
            this.init(this.id);
          }
        });
      },
        resetFilter(formName) {
            this.$refs[formName].resetFields();
            this.salesForm.checkDate = "month";
            this.newgetDefaultDate();
        },
        searchFilter(formName) {
            this.newselectTable();
        },
        ttSalesDownload() {
          if(getUserInfo().roleKey == "trialrole"){
            openSy();
          }else {
            let self = this;
            let form = Object.assign({}, this.tableParams, this.salesForm);
            form.startTime = form.times[0];
            form.endTime = form.times[1];
            delete form.times;
            delete form.count;
            newdynamicExport(form).then((res) => {
              var reader = new FileReader();
              reader.readAsText(res);
              reader.addEventListener("loadend", function (e) {
                try {
                  JSON.parse(e.target.result);
                  let msg = JSON.parse(e.target.result);
                  self.$message({
                    showClose: true,
                    message: msg.message,
                    type: "error",
                  });
                } catch (err) {
                  downloadFile(res, "产销量.xlsx");
                }
              });
            });
          }
        },
        handleSizeChange(val) {
            this.tableParams.pageSize = val;
            this.newselectTable();
        },
        handleCurrentChange(val) {
            this.tableParams.page = val;
            this.newselectTable();
        },
        //根据企业获取信息
        queryByIdEnterpriseInfo(params) {
            queryByIdEnterpriseInfo(params).then((res) => {
                if (res.ok) {
                    this.info = res.data;
                    this.info.tag = res.data.tag ? res.data.tag.split(",") : [];
                    this.isShowOperation = res.data.isShowOperation;
                     this.isShowPlatform = res.data.isShowPlatform;
                      this.isShowSales = res.data.isShowSales;
                       this.isShowExecutive = res.data.isShowExecutive;
                        this.isShowBase = res.data.isShowBase;
                         this.isShowStrategic = res.data.isShowStrategic;
                }
            });
        },
        //企业动态
        queryByPageDynamic(params) {
            queryByPageDynamic(params).then((res) => {
                if (res.ok) {
                    this.dynamic = res.data.list.map((item) => {
                        if (item.keyWord) {
                            item.keyWord = item.keyWord.split(",");
                        }
                        item.createTime = fmtDate(
                            "YYYY-mm-dd",
                            item.createTime
                        );
                        return item;
                    });
                }
            });
        },
        //企业图
        selectEnterpriseMap(params) {
            selectEnterpriseMap(params).then((res) => {
                if (res.ok) {
                    this.record = res.data;
                }
            });
        },
        //部门图
        getDeptByEnterPrise(params) {
            getDeptByEnterPrise(params).then((res) => {
                if (res.ok) {
                    this.record = res.data;
                }
            });
        },
        //高管信息分页查询
        executiveInfoQueryByPage() {
            executiveInfoQueryByPage(this.executiveInfoPage.pages).then(
                (res) => {
                    if (res.ok) {
                        this.executiveInfoData = res.data.list;
                        this.executiveInfoPage.total = res.data.count;
                    }
                }
            );
        },
        //高管分页
        executiveInfoChange(val) {
            this.executiveInfoPage.pages.pageSize= val;
            this.executiveInfoQueryByPage(); // 修改的内容
        },
        executiveInfoCurrentChange(val) {
            this.executiveInfoPage.pages.pageNo = val;
            this.executiveInfoQueryByPage(); // 修改的内容
        },

        //战略信息分页查询
        strategicPlanningByPage() {
            let form = Object.assign(
                {},
                this.strategicPlanningForm,
                this.strategicPlanningPage.pages
            );
            form.types = form.types.join(",");
            form.startDate = this.allDate[0];
            form.endDate = this.allDate[1];
            strategicPlanningByPage(form).then((res) => {
                if (res.ok) {
                    this.strategicPlanningData = res.data.list.map((item) => {
                        if (item.type) {
                            item.type = item.type.split(",");
                        }
                        return item;
                    });
                    this.strategicPlanningPage.total = res.data.count;
                }
            });
        },
        //重置战略规划
        strategicPlanningRest() {
            this.strategicPlanningForm.startDate = "";
            this.strategicPlanningForm.endDate = "";
            this.allDate = "";
            this.$refs.strategicPlanningForm.resetFields();
            this.strategicPlanningByPage();
        },
        strategicPlanningSizeChange(val) {
            this.strategicPlanningPage.pages.pageSize = val;
            this.strategicPlanningByPage();
        },
        strategicPlanningCurrentChange(val) {
            this.strategicPlanningPage.pages.pageNo = val;
            this.strategicPlanningByPage();
        },
        //经营情况
        businessCircumstance() {
            let page = this.businessCircumstancePage.pages;
            businessCircumstance(page).then((res) => {
                this.businessCircumstanceData = res.data.list;
                this.businessCircumstancePage.total = res.data.count;
            });
        },
        businessCircumstanceChange(val) {
            this.businessCircumstancePage.pages.pageSize = val;
            this.businessCircumstance();
        },
        businessCircumstanceCurrentChange(val) {
            this.businessCircumstancePage.pages.page = val;
            this.businessCircumstance();
        },

        //导出
        businessCircumstanceExport() {
          if(getUserInfo().roleKey == "trialrole"){
            openSy();
          }else {
            let enterpriseId = {
              enterpriseId: this.enterpriseId,
            };
            let self = this;
            businessCircumstanceExport(enterpriseId).then((res) => {
              var reader = new FileReader();
              reader.readAsText(res);
              reader.addEventListener("loadend", function (e) {
                try {
                  JSON.parse(e.target.result);
                  let msg = JSON.parse(e.target.result);
                  self.$message({
                    showClose: true,
                    message: msg.message,
                    type: "error",
                  });
                } catch (err) {
                  downloadFile(res, "经营情况.xlsx");
                }
              });
            });
          }
        },
        //产销量
        ttSalesTargetPage(params) {
            ttSalesTargetPage({
                ...params,
            }).then((res) => {
                this.tableData = res.data.list;
                this.tableParams.count = res.data.count;
                this.tableParams.page = res.data.pageNo;
                this.tableParams.pageSize = res.data.pageSize;
            });
        },
        //生产基地
        productionTabClick(index, val) {
            this.productionTabIndex = index;
            this.productionBaseQueryByPage({
                enterpriseId: this.enterpriseId,
                baseType: val,
            });
        },
        productionBaseQueryByPage(params) {
            productionBaseQueryByPage(params).then((res) => {
                if (res.ok) {
                    this.baseData.allTotal = res.data.allTotal;
                    this.baseData.fuelVehicleCount = res.data.fuelVehicleCount;
                    this.baseData.data = res.data.data;
                    this.baseData.newEnergyCount = res.data.newEnergyCount;
                }
            });
        },
        //生产基地字典
        selectDictDataByType(params) {
            selectDictDataByType(params).then((res) => {
                if (res.ok) {
                    this.baseType = res.data;
                }
            });
        },
        //车型历史
        TypePlatformQueryByPage(params) {
            TypePlatformQueryByPage(params).then((res) => {
                if (res.ok) {

                    this.carHistoryData = res.data.list;

                }
            });
        },
        //车型导出
        TypePlatformExport() {
          if(getUserInfo().roleKey == "trialrole"){
            openSy();
          }else {
            let enterpriseId = {
              enterpriseId: this.enterpriseId,
            };
            let self = this;
            TypePlatformExport(enterpriseId).then((res) => {
              var reader = new FileReader();
              reader.readAsText(res);
              reader.addEventListener("loadend", function (e) {
                try {
                  JSON.parse(e.target.result);
                  let msg = JSON.parse(e.target.result);
                  self.$message({
                    showClose: true,
                    message: msg.message,
                    type: "error",
                  });
                } catch (err) {
                  downloadFile(res, "车型导出.xlsx");
                }
              });
            });
          }
        },
        //数据字典
        ttStrategicPlanningFl() {
            selectDictDataByType({ dictType: "tt_strategic_planning_fl" }).then(
                (res) => {
                    if (res.ok) {
                        this.sbxz = res.data;
                    }
                }
            );
        },
        getRemarkCx(form) {
            getRemarkCx(form).then((res) => {
                if (res.ok) {
                    this.cxremake = res.data;
                }
            });
        },
        //开始结束时间
        timeChange(e) {
            if (e.swatchDateType == "month") {
                this.salesForm.checkYearMonth = false;
                this.salesForm.checkDate = "month";
            } else {
                this.salesForm.checkYearMonth = true;
                this.salesForm.checkDate = "year";
            }
            // this.newselectTable();
        },
        newselectTable() {
            let form = Object.assign({}, this.tableParams, this.salesForm);
            form.startTime = form.times[0];
            form.endTime = form.times[1];
            this.newgetYearMonthList(
                this.getNewDate(form.times[0]),
                this.getNewDate(form.times[1]),
                form.checkYearMonth
            );
            delete form.times;
            delete form.count;
            newselectTable(form).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.tableParams.count = res.data.count;
                }
            });
        },
        //动态表头
        newgetYearMonthList(startTime, endTime, isYear) {
            newgetYearMonthList({ startTime, endTime, isYear }).then((res) => {
                if (res.ok) {
                    this.tableHeaderTime = res.data;
                }
            });
        },
        newgetDefaultDate() {
            let form = {
                enterpriseId: this.id,
                category: this.salesForm.category,
            };
            newgetDefaultDate(form).then((res) => {
                if (res.ok) {
                    this.salesForm.times = [res.data, res.data];
                    this.newselectTable();
                }
            });
        },
        getNewDate(nowdate) {
            let timeHeader = "";
            let date = new Date(),
                year = date.getFullYear(),
                month =
                    date.getMonth() + 1 >= 10
                        ? date.getMonth() + 1
                        : "0" + date.getMonth() + 1;
            if (nowdate) {
                if (this.salesForm.checkYearMonth) {
                    timeHeader = nowdate + "-01-01";
                } else {
                    timeHeader = nowdate + "-01";
                }
            } else {
                if (this.salesForm.checkYearMonth) {
                    timeHeader = year + "-01-01";
                } else {
                    timeHeader = year + "-" + month + "-01";
                }
            }

            return timeHeader;
        },
        //分组表头
        catgoryGrop(data) {
            let newData = data;
            console.log(data, "newData");
            let sc = [];
            let xs = [];
            newData.forEach((el) => {
                sc.push({ sc: el.sc, dateStr: el.dateStr });
                xs.push({ xs: el.xs, dateStr: el.dateStr });
            });
            return {
                sc,
                xs,
            };
        },
        leixqiehuan(e) {
            this.salesForm.category = e;
            this.newgetDefaultDate();
        },
        //清标签
        matchReg(str){
            if(str!=null && str != ''){
                let reg=/<\/?.+?\/?>/g;
                return str.replace(reg,'')
            }else{
               return str
            }
          
        },
        tableSort( column){
              this.executiveInfoPage.pages.orderType = this.otherzh(column)
             this.executiveInfoQueryByPage()
        },
        otherzh(column){
            if(column.order == 'ascending'){
           
                    return column.prop
            }else if(column.order =='descending'){
            
                    return column.prop+' DESC'
            }else{
                return ''
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.search-form {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 10px;
}
.el-tag {
    margin-right: 10px;
}
.dynamics-list {
    // flex: 1;
    // padding: 0 32px 20px;
    overflow: hidden;
    background: #fff;
    .g-list-item-box {
        margin-left: -71px;
    }
    .g-list-item {
        width: 50%;
        float: left;
        padding-left: 71px;
    }
}

.dynamic-top {
    padding: 20px 32px;
    margin-bottom: 12px;
    background: #fff;
    .dynamic-top-original-box {
        display: flex;
        margin-top: 32px;
        margin-left: -26px;
        cursor: pointer;
        .dynamic-top-original {
            display: flex;
            flex: 1;
            margin-left: 26px;
            .dynamic-top-original-img {
                width: 177px;
                height: 99px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    box-shadow: 0px 0px 7.31074px 3.65537px
                        rgba(225, 225, 225, 0.25);
                }
            }
            .dynamic-top-original-inner {
                margin-left: 6px;
                p {
                    line-height: 21px;
                    color: #000;
                    font-weight: 500;
                    height: 64px;
                }
                span {
                    color: #828282;
                }
            }
        }
    }
}
.g-form-input {
    width: 185px;
    /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
    }
}
.flex_top {
    width: 50%;
    border-right: 1px solid #efefef;
    padding-right: 30px;
    &_num {
        font-size: 30px;
        color: #0d57bc;
        font-weight: 600;
    }
    &_text {
        color: #989898;
    }
    &_car {
        margin: 20px 20px 0 0;
        span {
            font-size: 20px;
            font-weight: 600;
        }
        p {
            color: #0d57bc;
        }
    }
}
.title-padding {
    padding: 20px 30px;
}
.hover:hover {
    color: #0d57bc;
    cursor: pointer;
}
.tags {
    span {
        display: inline-block;
        padding: 2px 1px;
        color: #0d57bc;
        border: 1px solid #0d57bc;
        margin-right: 4px;
        line-height: 14px;
        font-size: 12px;
    }
}
.titles {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #141414;
    flex: 1;
    overflow: hidden;
    font-weight: bold;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    cursor: pointer;
}
.car-history,
.production-tab-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.production-tab-box {
    .production-tab {
        display: flex;
        align-items: center;
        margin-left: -16px;
        a {
            display: block;
            font-size: 16px;
            line-height: 19px;
            color: #4f4f4f;
            padding-bottom: 8px;
            margin-left: 16px;
            &.ac {
                font-weight: bold;
                color: #333;
                border-bottom: 1px solid #333;
            }
        }
    }
}
.car-history {
    margin-top: 50px;
    h2 {
        font-size: 16px;
        line-height: 16px;
        color: #000;
    }
    p {
        font-size: 13px;
        margin-top: 10px;
        span {
            color: #0d57bc;
        }
    }
}
</style>
