<template>
  <div class="more" @click="handleClick">查看更多<i class="el-icon-arrow-right"></i></div>
</template>

<script>
export default {
  props: {
    to: {
      required: false,
      type: [String, Object],
      default: undefined,
    },
    name: {
      required: false,
      type: String,
      default: undefined,
    },
    logRecordsHomeMore: {
      required: false,
      type: Function,
      default: () => () => null,
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      const { href } = this.$router.resolve(this.to);
      this.logRecordsHomeMore(this.name);
      window.open(href, '_blank');
    },
  },
};
</script>
