<template>
  <PageContainer>
    <not-logged-in-mask v-if="showNotLoggedInMask" />
    <unsubscribed-mask v-if="showUnsubscribedMask" />
    <div :class="['ChargingInfrastructure', showMask ? 'show-mask' : '']">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="月度趋势" name="monthlyTrend">
          <card title="月度趋势" cionShow noBorder>
            <template slot="body">
              <card
                title="公共类充电桩保有量变化趋势"
                exportData
                btnId="publicChargingPointsNumEchart"
                exportId="monthlyData-publicChargingPointsNum"
                @exportXls="exportXls"
                @exportSvg="exportSvg"
              >
                <template slot="body">
                  <salesVolumeEchart
                    :height="452"
                    ref="publicChargingPointsNumEchart"
                  />
                </template>
              </card>
              <card
                style="margin-top:12px"
                title="公共充电设施充电电量变化趋势"
                exportData
                btnId="publicChargingPointsCapacityEchart"
                exportId="monthlyData-publicChargingPointsCapacity"
                @exportXls="exportXls"
                @exportSvg="exportSvg"
              >
                <template slot="body">
                  <salesVolumeEchart
                    :height="452"
                    ref="publicChargingPointsCapacityEchart"
                  />
                </template>
              </card>
              <card
                style="margin-top:12px"
                title="随车配建充电桩保有量变化趋势"
                exportData
                btnId="vehicleChargingPointsNumEchart"
                exportId="monthlyData-vehicleChargingPointsNum"
                @exportXls="exportXls"
                @exportSvg="exportSvg"
              >
                <template slot="body">
                  <salesVolumeEchart
                    :height="452"
                    ref="vehicleChargingPointsNumEchart"
                  />
                </template>
              </card>
            </template>
          </card>
        </el-tab-pane>
        <el-tab-pane label="省份" name="province">
          <card
            :title="`${provinceCardDate}各省份表现情况`"
            cionShow
            noBorder
            exportData
            exportId="provinceData"
            @exportXls="exportXls"
          >
            <template slot="body">
              <tableData ref="provinceTable" />
            </template>
          </card>
        </el-tab-pane>
        <el-tab-pane label="运营商" name="operator">
          <card
            :title="`${enterpriseCardDate}主要运营商表现情况`"
            cionShow
            noBorder
            exportData
            exportId="enterpriseData"
            @exportXls="exportXls"
          >
            <template slot="body">
              <tableData ref="enterpriseTable" />
            </template>
          </card>
        </el-tab-pane>
      </el-tabs>
    </div>
  </PageContainer>
</template>

<script>
import { authMixin } from "@/mixin/newData/index.js";
import { NotLoggedInMask, UnsubscribedMask } from "@/components/newData/mask";
import card from "./common/card.vue"; //卡片组件
import salesVolumeEchart from "./common/salesVolumeEchart.vue"; //折线图组件组件
import tableData from "./common/tableData.vue"; //数据表格
import { getUserInfo, openSy } from "@/utils/auth.js";
import { buryPoint } from '@/api/common'
import {
  getChargingInfrastructure, //月度趋势
  getProvinceData, //省份
  getEnterpriseData, //运营商
  exportChargingPoints, //公共导出接口
} from "@/api/data_new.js";
import Utils from "./common/utils.js"; //工具类

export default {
  name: "ChargingInfrastructure",
  components: {
    NotLoggedInMask,
    UnsubscribedMask,
    card,
    salesVolumeEchart,
    tableData,
  },
  mixins: [authMixin],
  data() {
    return {
      activeName: "monthlyTrend",
      provinceCardDate: "",
      enterpriseCardDate: "",
    };
  },
  created() {
    this.initData();
  },
  computed: {},
  methods: {
    /**
     * @description: 初始化数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:14:45
     * @LastEditTime: Do not edit
     */

    initData() {
      // 获取月度趋势数据展示
      this.getChargingInfrastructure();
    },
    /**
     * @description: 获取月度趋势数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:25:57
     * @LastEditTime: Do not edit
     */
    getChargingInfrastructure() {
      getChargingInfrastructure().then((res) => {
        if (res.ok) {
          let data = res.data;
          // 初始化公共类充电桩保有量变化趋势
          this.initPublicChargingPointsNumEchartData(data);
          // 初始化公共充电设施充电电量变化趋势
          this.initPublicChargingPointsCapacityEchartData(data);
          // 初始化随车配建充电桩保有量变化趋势
          this.initVehicleChargingPointsNumEchartData(data);
        }
      });
    },
    /**
     * @description: 初始化公共类充电桩保有量变化趋势
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:15:14
     * @LastEditTime: Do not edit
     */

    initPublicChargingPointsNumEchartData(data) {
      // 封装折线图数据
      let xAxisData = data.dateLists.map((item) => {
        return Utils.insertStr(item, 4, ".");
      });
      let publicChargingPointsNumEchartData = {
        xAxisData: xAxisData.reverse(), //x轴数据
        seriesData: [
          {
            name: "充电桩数量",
            type: "line",
            color: "#165DFF",
            showSymbol: false, //去掉点
            smooth: true, //变得圆滑一点
            areaStyleColor: ["rgba(22, 93, 255,0.2)", "rgba(22, 93, 255,0)"], //阴影
            data: data.publicChargingPointsNumList.reverse(),
          },
        ], //展示数据
        yAxis: [
          {
            type: "value",
            name: "台",
            boundaryGap: [0, "100%"],
          },
        ], //y轴
      };
      this.$refs.publicChargingPointsNumEchart.init(
        publicChargingPointsNumEchartData
      );
      console.log(data, "月度趋势-公共类充电桩保有量变化趋势-已对接");
    },
    /**
     * @description: 初始化公共充电设施充电电量变化趋势
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:16:07
     * @LastEditTime: Do not edit
     */

    initPublicChargingPointsCapacityEchartData(data) {
      // 封装折线图数据
      let xAxisData = data.dateLists.map((item) => {
        return Utils.insertStr(item, 4, ".");
      });
      let publicChargingPointsCapacityEchartData = {
        xAxisData: xAxisData.reverse(), //x轴数据
        seriesData: [
          {
            name: "公共充电设施充电电量",
            type: "line",
            color: "#14C9C9",
            showSymbol: false, //去掉点
            smooth: true, //变得圆滑一点
            areaStyleColor: ["rgba(20, 201, 201,0.2)", "rgba(20, 201, 201,0)"], //阴影
            data: data.publicChargingPointsCapacityList.reverse(),
          },
        ], //展示数据
        yAxis: [
          {
            type: "value",
            name: "千万kW·h",
            boundaryGap: [0, "100%"],
          }, //y轴
        ],
      };
      this.$refs.publicChargingPointsCapacityEchart.init(
        publicChargingPointsCapacityEchartData
      );
      console.log(data, "月度趋势-公共充电设施充电电量变化趋势-已对接");
    },
    /**
     * @description: 初始化随车配建充电桩保有量变化趋势
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 09:16:40
     * @LastEditTime: Do not edit
     */
    initVehicleChargingPointsNumEchartData(data) {
      // 封装折线图数据
      let xAxisData = data.dateLists.map((item) => {
        return Utils.insertStr(item, 4, ".");
      });
      data.vehicleChargingPointsPercentage = data.vehicleChargingPointsPercentage.map(
        (item) => {
          return (item * 100).toFixed(2);
        }
      );
      let vehicleChargingPointsNumEchartData = {
        xAxisData: xAxisData.reverse(), //x轴数据
        seriesData: [
          {
            name: "随车配建充电桩保有量数量",
            type: "bar",
            color: "#165DFF",
            showSymbol: false, //去掉点
            barWidth: 30,
            data: data.vehicleChargingPointsNumList.reverse(),
          },
          {
            name: "随车配建充电桩保有量占比",
            type: "line",
            color: "#14C9C9",
            yAxisIndex: 1,
            showSymbol: false, //去掉点
            smooth: true, //变得圆滑一点
            // areaStyleColor: ["rgba(20, 201, 201,1)", "rgba(20, 201, 201,0)"], //阴影
            data: data.vehicleChargingPointsPercentage.reverse(),
          },
        ], //展示数据
        yAxis: [
          {
            type: "value",
            name: "个",
          },
          {
            type: "value",
            name: "%",
          },
        ], //y轴
      };
      this.$refs.vehicleChargingPointsNumEchart.init(
        vehicleChargingPointsNumEchartData
      );
      console.log(data, "月度趋势-随车配建充电桩保有量变化趋势-已对接");
    },
    /**
     * @description: 获取省份数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 10:25:27
     * @LastEditTime: Do not edit
     */

    getProvinceData() {
      getProvinceData().then((res) => {
        let data = res.data;
        this.provinceCardDate = data.date;
        this.$refs.provinceTable.init(data);
      });
    },
    /**
     * @description: 获取运营商数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 10:58:55
     * @LastEditTime: Do not edit
     */
    getEnterpriseData() {
      getEnterpriseData().then((res) => {
        let data = res.data;
        let date = Utils.insertStr(data.date, 4, "年");
        this.enterpriseCardDate = Utils.insertStr(date, 8, "月"); //调用工具类插入年、月
        this.$refs.enterpriseTable.init(data);
      });
    },
    /**
     * @description: tab切换事件
     * @param {*} tab
     * @param {*} event
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 10:27:33
     * @LastEditTime: Do not edit
     */

    handleClick(tab, event) {
      switch (tab.name) {
        case "monthlyTrend":
          this.getChargingInfrastructure(); //获取月度趋势数据展示
          break;
        case "province":
          this.getProvinceData(); //获取省份数据
          break;
        case "operator":
          this.getEnterpriseData(); //获取运营商数据
          break;
      }
    },
    /**
     * @description: 下载图片按钮
     * @param {*} type
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:27:06
     * @LastEditTime: Do not edit
     */

    exportSvg({ type, name }) {
      this.$refs[type].exportSvg(name);
      console.log(type);
    },
    /**
     * @description: 导出数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:32:45
     * @LastEditTime: Do not edit
     */

    exportXls({ exportId, name }) {
      const userInfo = getUserInfo();
      if (!userInfo) {
        this.$Vlogin2({ flag: true });
        return;
      }
      if (userInfo.roleKey == "paidrole" || userInfo.roleKey == "trialrole") {
        const unSubscribed =
          !userInfo.menuName || !userInfo.menuName.includes("数据");
        if (unSubscribed) {
          this.$Vlogin3({ flag: true });
          return;
        }
      }
      // 如果是试用用户
      if (userInfo.roleKey == "trialrole") {
        openSy()
        buryPoint({
          eventName: '开通卡片点击',
          eventType: '2',
          columnName: '数据',
          userId: getUserInfo()?.userId
        })
        return
      }
      exportChargingPoints({
        classify: exportId,
      }).then((res) => {
        console.log(res);
        this.blobstamp(res, name);
      });
    },
    /**
     * @description: 文件导出转化 blob数据流=> 文件
     * @param {*} blob
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 11:37:13
     * @LastEditTime: Do not edit
     */

    blobstamp(blob, name) {
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      let temp = name + ".xls";
      // let temp = blob.headers["content-disposition"].split(
      //   "filename*=utf-8''"
      // )[1];
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE
        let IEblob = new Blob([blob.data], {
          type: "application/vnd.ms-excel",
        });
        window.navigator.msSaveOrOpenBlob(IEblob, decodeURIComponent(temp));
        return;
      } else {
        a.href = url;
        a.setAttribute("download", decodeURIComponent(temp));
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";
.ChargingInfrastructure {
  /deep/.el-tabs__header {
    background: #ffffff;
    padding: 0px 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #e5e6eb;
  }
  /deep/.el-tabs__item {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
  }
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }
  /deep/.el-tab-pane {
    background: #ffffff;
    // padding: 17px 24px;
    padding-top: 0px;
  }
}
</style>
