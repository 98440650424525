<template>
  <div class="us-page">
    <!-- <div style="width: 1200px; margin: 0 auto; padding: 20px 0">
  <h1 style="margin-bottom: 20px">关于信息网</h1>
  <div v-html="content.htmlContent"></div>
</div> -->
    <div flex="1">
      <!--      <img style="" src="@/assets/img/login-logo.png" alt=""/>-->
      <center>
        <el-menu
          style="width: 325px; margin: 12px 0;"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#0d57bc"
          text-color="black"
          @select="handleSelect"
        >
          <el-menu-item index="1">在线申请试用</el-menu-item>
          <el-menu-item index="3" disabled>|</el-menu-item>
          <el-menu-item index="2">直接联系开通</el-menu-item>
        </el-menu>
      </center>
    </div>
    <template v-if="activeIndex == '1'">
      <div class="tip">
        <span style="font-weight: bold">您可以直接联系我们进行购买/试用，也可以直接选择如下方式申请试用：<br></span>
        <span>根据要求填写真实工作信息，我们会在一个工作日之内完成审核。<br>审核完成后您将收到邮件和短信通知。</span>

      </div>
      <el-form ref="form" :model="form" :rules="rules" class="login-form" label-width="100px">
        <el-form-item label="开通栏目" prop="product">
          <el-select
            v-model="form.product"
            multiple
            placeholder="选择您想开通的栏目"
            style="width: 660px;"
            @change="handleChange"
          >
            <el-option
              v-for="item in options"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="enterprise">
          <el-input
            v-model="form.enterprise"
            placeholder="请填写您的公司名称（全称）"
          />
        </el-form-item>
        <!--        <el-form-item prop="province">-->
        <!--          <el-input-->
        <!--              v-model="form.province"-->
        <!--              placeholder="您的公司所在省份？"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item prop="city">-->
        <!--          <el-input-->
        <!--              v-model="form.city"-->
        <!--              placeholder="您的公司所在城市？"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="部门名称" prop="dept">
          <el-input
            v-model="form.dept"
            placeholder="您所在部门？"
          />
        </el-form-item>

        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="form.name"
            placeholder="您的姓名？"
          />
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="您的手机号码？"
          />
        </el-form-item>
        <el-form-item label="短信验证码" prop="yzm">
          <el-input
            v-model="form.yzm"
            style="width: 520px;"
            placeholder="请输入6位短信验证码"
          />
          <el-button
            style="height: 40px; width: 136px;margin-left: 4px;"
            type="primary"
            :class="{'disabled-style':getCodeBtnDisable}"
            :disabled="getCodeBtnDisable"
            @click="getCode()"
          >{{ codeBtnWord }}
          </el-button>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="email">
          <el-input
            v-model="form.email"
            placeholder="您的企业邮箱？"
          />
        </el-form-item>
        <!--        <el-form-item  label="邮箱验证码" prop="yxYzm">-->
        <!--          <el-input style="width: 520px;"-->
        <!--                    v-model="form.yxYzm"-->
        <!--                    placeholder="请输入6位邮箱验证码"-->
        <!--          >-->
        <!--          </el-input>-->
        <!--          <el-button style="height: 40px; width: 136px;margin-left: 4px;" type="primary" @click="getCodeYx()"-->
        <!--                     :class="{'disabled-style':getCodeYxBtnDisable}" :disabled="getCodeYxBtnDisable">{{ codeBtnYxWord }}-->
        <!--          </el-button>-->
        <!--        </el-form-item>-->

        <!--        <el-form-item prop="syyy">-->
        <!--          <el-input-->
        <!--              type="textarea"-->
        <!--              v-model="form.syyy"-->
        <!--              placeholder="简述您开通试用的原因是为了完成什么工作？"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item prop="imgUrl">
          <el-upload
            name="myfile"
            class="avatar-uploader"
            accept=".jpg,.png"
            :limit="1"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <div class="tpbz">请上传图片（必填）<br>上传文件只能是 jpg，png格式<br>任意可以证明您所填信息的真实性材料<br>如：名片、邮件信息、工作聊天软件上的个人资料截图等</div>
        </el-form-item>

        <el-form-item class="text-center">
          <el-button
            type="primary"
            style="width: 100%"
            :loading="loading"
            @click="onSubmit('form')"
          >立即提交
          </el-button>
          <!-- <el-button type="text" @click="loginSwitch()">{{
            swicthName
          }}</el-button> -->
        </el-form-item>

      </el-form>
    </template>
    <template v-else>
      <div style="text-align: center;">
        <img style="width: 800px;height: auto;" src="@/assets/img/lxwmtp.png">
      </div>
    </template>
  </div>
</template>
<script>
import { sqSy, sendEmailCode, sendPhoneMessage } from '@/api/login'
import { selectDictDataByType } from '@/api/dict'

export default {
  data() {
    var validateUserPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号码'))
      } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }

    var validateEmailYzm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写验证码'))
      } else if (value != this.yxYzm) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    var validatePhoneYzm = (rule, value, callback) => {
      // console.log(this.yzm)
      if (value === '') {
        callback(new Error('请填写验证码'))
      } else if (value != this.yzm) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    return {
      form: {
        enterprise: '',
        province: '',
        city: '',
        dept: '',
        post: '',
        name: '',
        phone: '',
        email: '',
        product: [],
        productZw: '',
        syyy: '',
        imgUrl: '',
        imgName: '',
        yzm: '',
        yxYzm: ''
      },
      yzm: '',
      yxYzm: '',
      options: [],
      rules: {
        enterprise: [
          { required: true, message: '请填写公司名称（全称）', trigger: 'blur' }
        ],
        // province: [
        //   {required: true, message: '请填写公司所在省份', trigger: 'blur'}
        // ],
        // city: [
        //   {required: true, message: '请填写公司所在城市', trigger: 'blur'}
        // ],
        // dept: [
        //   {required: true, message: '请填写所在部门', trigger: 'blur'}
        // ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        yxYzm: [{ required: true, validator: validateEmailYzm, trigger: 'blur' }],

        // post: [
        //   {required: true, message: '请填写所在岗位', trigger: 'blur'}
        // ],
        name: [
          { required: true, message: '请填写姓名', trigger: 'blur' }
        ],
        phone: [{ required: true, validator: validateUserPhone, trigger: 'blur' }],
        yzm: [{ required: true, validator: validatePhoneYzm, trigger: 'blur' }],

        product: [
          { type: 'array', required: true, message: '请选择开通的栏目', trigger: 'change' }
        ],
        imgUrl: [
          { required: true, message: '请上传文件', trigger: 'change' }
        ]
      },
      flag: false,
      loading: false,
      swicthName: '手机号登录',
      activeIndex: '1',
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      codeBtnYxWord: '获取验证码', // 获取验证码按钮文字
      waitTime: 61, // 获取验证码按钮失效时间
      waitTimeYx: 61,
      imageUrl: '',
      uploadUrl: process.env.VUE_APP_PROXY + '/lo/common/upload'
    }
  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form.phone)) {
        return false
      }
      return true
    },
    // 用于校验手机号码格式是否正确
    emailStyle() {
      const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!reg.test(this.form.email)) {
        return false
      }
      return true
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          const reg = /^1[3456789]\d{9}$/
          if (reg.test(this.form.phone)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {
      }
    },
    getCodeYxBtnDisable: {
      get() {
        if (this.waitTimeYx == 61) {
          const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if (reg.test(this.form.email)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {
      }
    }
  },
  created() {
    const flag = this.$route.query.flag
    if (flag != undefined) {
      this.activeIndex = flag
    }
    this.selectDictDataByType()
  },
  // updated() {
  //     let id = this.$route.query.id;
  //     if (id) {
  //         this.$nextTick(() => {
  //             document.querySelector("#" + id).scrollIntoView();
  //         });
  //     }
  // },

  methods: {

    handleChange(val) {
      let checkLabels = ''
      val.forEach((itemVal) => {
        const checkLabel = this.options.find(item => item.dictValue == itemVal).dictLabel
        if (checkLabels == '') {
          checkLabels = checkLabel
        } else {
          checkLabels = checkLabels + ',' + checkLabel
        }
      })
      this.form.productZw = checkLabels
    },
    handleAvatarSuccess(res, file) {
      this.form.imgName = res.data.fileName
      this.form.imgUrl = res.data.url

      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'subscription_products' }).then(
        (res) => {
          if (res.ok) {
            this.options = res.data.filter((item) => {
              return item.dictLabel != '商用车' && item.dictLabel != '二手车'
            })
          }
        }
      )
    },
    beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)

      const whiteList = ['jpg', 'png']

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.warning('上传文件只能是 jpg，png格式')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    getCode() {
      if (this.phoneNumberStyle) {
        const params = {}
        params.receiver = this.form.phone
        // 调用获取短信验证码接口
        sendPhoneMessage(params).then(res => {
          if (res.ok) {
            this.yzm = res.data

            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success',
              center: true
            })
            // 因为下面用到了定时器，需要保存this指向
            const that = this
            that.waitTime--
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            const timer = setInterval(function() {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
              }
            }, 1000)
          }
        })
      }
    },
    getCodeYx() {
      if (this.emailStyle) {
        const params = {}
        params.email = this.form.email
        // 调用获取短信验证码接口
        sendEmailCode(params).then(res => {
          if (res.ok) {
            this.yxYzm = res.data
            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success',
              center: true
            })
          }
        })
        // 因为下面用到了定时器，需要保存this指向
        const that = this
        that.waitTimeYx--
        that.getCodeYxBtnDisable = true
        this.codeBtnYxWord = `${this.waitTimeYx}s 后重新获取`
        const timer = setInterval(function() {
          if (that.waitTimeYx > 1) {
            that.waitTimeYx--
            that.codeBtnYxWord = `${that.waitTimeYx}s 后重新获取`
          } else {
            clearInterval(timer)
            that.codeBtnYxWord = '获取验证码'
            that.getCodeYxBtnDisable = false
            that.waitTimeYx = 61
          }
        }, 1000)
      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const form = Object.assign(
            {},
            this.form
          )
          form.product = form.product.join(',')
          sqSy(form).then((res) => {
            // console.log(res)
            if (res.ok) {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: '<div style="font-size: 16px;color: #0f0f0f">提交完成！<br><br>您将在一个工作日内收到邮件和短信通知申请结果<br><br><span style="font-size: 14px">即将跳转至信息网首页...</span></div>',
                duration: 4000,
                offset: 200
              })
              setTimeout(() => {
                window.location.href = 'https://www.autoinfo.org.cn'
              }, 5000)
              this.loading = false
            } else {
              this.loading = false
              // this.$message('这是一条消息提示');
              // this.$message.error({
              //   message: res.message,
              //   duration: 10000
              // });
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.us-page {
  width: 800px;
  margin: 0 auto;
  background: #fff;
}
.login-mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

img {
  //display: block;
  width: 213px;
  height: 71px;
  margin: 20px 0px;
  //float: left;

}

.login-form {
  //width: 360px;
  margin: 0 20px;

  .yzm-btn {
    position: absolute;
    right: 9px;
  }
}

.el-menu-item {
  font-size: 16px;
  font-weight: bold;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

}

.el-button.disabled-style {
  background-color: #EEEEEE;
  color: #CCCCCC;
}

.avatar-uploader {
  float: left;

}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.tpbz {
  font-size: 16px;
  //margin-top: 30px;
  margin-left: 254px;
  color: #b9becb;
}

</style>
<style>
.el-message .el-icon-info {
  padding-bottom: 62px;
}
</style>
