<template>
  <div v-show="visible" ref="chartRef" class="chart" />
</template>

<script>
import * as echarts from 'echarts'
import ResizeListener from 'element-resize-detector'
import { draggingResultContainerExternalWidth } from '@/styles/newDataVariables.module.scss'
import { merge } from 'lodash'

export default {
  name: 'Chart',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    // 是否正在展示假数据
    fake: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartInstance: null,
      resizeListenerInstance: null,
      chartOption: null,
      chartType: null,
      graphSettingOption: null
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        // 当组件隐藏时，清空echarts
        this.chartInstance?.clear()
      }
    }
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chartRef)
    this.resizeListenerInstance = ResizeListener({
      strategy: 'scroll',
      callOnAdd: false
    })
    this.resizeListenerInstance.listenTo(this.$el, this.handleChartResize)
  },
  beforeDestroy() {
    this.resizeListenerInstance.removeAllListeners(this.$el)
  },
  methods: {
    loadView(chartType, respData, graphSettingOption) {
      this.chartType = chartType
      this.$set(this, 'graphSettingOption', graphSettingOption)
      if (chartType === '折线图' || chartType === '柱状图' || chartType === '面积图') {
        this.$set(this, 'chartOption', this.generateBarLineAreaOption(chartType, respData, graphSettingOption))
      } else if (chartType === '堆积柱状图') {
        this.$set(this, 'chartOption', this.generateStackBarOption(respData, graphSettingOption))
      } else if (chartType === '饼图') {
        this.$set(this, 'chartOption', this.generatePieOption(respData, graphSettingOption))
      } else if (chartType === '组合图') {
        this.$set(this, 'chartOption', this.generateMixOption(respData, graphSettingOption))
      }
      this.chartInstance.clear()
      this.chartInstance.setOption(this.chartOption)
    },
    // 用于手动修改图形设置后，更新显示效果
    updateViewByGraphSetting(graphSettingOption) {
      const newOption = {
        title: {
          text: graphSettingOption.title,
          left: draggingResultContainerExternalWidth,
          padding: [5, 0]
        },
        // 复制两遍以增加长度，防止 echarts 的默认 colors 追加进来
        color: this.copyArr(graphSettingOption.colors, 2)
      }
      // 如果展示的是组合图
      if (this.chartType === '组合图') {
        const { combinationTargetSetting } = graphSettingOption
        // 记录在图形设置中，一共有哪些系列被调整到主轴和次轴上
        const mainYAxisSeriesArr = []
        const secondaryYAxisSeriesArr = []
        this.chartOption.series.forEach(item => {
          const setting = combinationTargetSetting[item.targetName]
          if (setting.yAxisIndex === 0) {
            mainYAxisSeriesArr.push(item)
          } else if (setting.yAxisIndex === 1) {
            secondaryYAxisSeriesArr.push(item)
          }
        })
        // 根据图形设置，修改每个系列的 type 和 yIndexAxis
        const newSeries = this.chartOption.series.map(item => {
          const setting = combinationTargetSetting[item.targetName]
          return {
            ...item,
            type: setting.type,
            // 如果没有主轴，则所有图例都使用 0 作为 yAxisIndex，后面的逻辑会把 yAxis 的第一个元素改成 position: right
            yAxisIndex: mainYAxisSeriesArr.length === 0 ? 0 : setting.yAxisIndex,
            // 只当显示为柱状图时才进行堆积
            stack: setting.type === 'bar' ? setting.stack : null
          }
        })
        const mainYAxisNameArr = mainYAxisSeriesArr.map(item => item.unit)
        // 获取主轴的名称
        const mainYAxisName = Array.from(new Set(mainYAxisNameArr)).join(' / ')
        const secondaryYAxisNameArr = secondaryYAxisSeriesArr.map(item => item.unit)
        // 获取次轴的名称
        const secondaryYAxisName = Array.from(new Set(secondaryYAxisNameArr)).join(' / ')
        const newYAxisToMerge = [{
          name: mainYAxisName
        }]
        if (secondaryYAxisSeriesArr.length > 0) { // 如果需要显示 次y轴
          // 则添加第2个y轴
          newYAxisToMerge.push({
            type: 'value',
            name: secondaryYAxisName,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#ccc'
              }
            },
            axisLabel: {
              margin: 8,
              color: '#86909C',
              fontSize: 12
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#E5E6EB'
              }
            },
            nameTextStyle: {
              color: '#86909C',
              fontSize: 12,
              align: 'right'
            }
          })
        }
        if (mainYAxisSeriesArr.length === 0) { // 如果不需要显示 主y轴
          newOption.yAxis = [merge(this.chartOption.yAxis, newYAxisToMerge, [null, { position: 'right' }])[1]]
        } else if (secondaryYAxisSeriesArr.length === 0) { // 如果不需要显示 次y轴
          newOption.yAxis = [merge(this.chartOption.yAxis, newYAxisToMerge, [{ position: 'left' }])[0]]
        } else {
          newOption.yAxis = merge(this.chartOption.yAxis, newYAxisToMerge)
        }
        newOption.series = newSeries
      }
      this.$set(this, 'chartOption', {
        ...this.chartOption,
        ...newOption
      })
      this.chartInstance.setOption(this.chartOption, true)
    },
    // 生成折线图、柱状图和面积图的配置项
    generateBarLineAreaOption(chartType, respData, graphSettingOption) {
      const xAxis = []
      // 如果有多层 x 轴
      if (respData.xAxisArr.length > 1) {
        for (let i = 0, len = respData.xAxisArr.length - 1; i < len; i++) {
          xAxis.push({
          // 控制是否显示 hover shadow
            axisPointer: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: '#86909C'
            },
            axisTick: {
              show: false
            },
            position: 'bottom',
            offset: 20 * (len - i),
            data: i === 0 ? respData.xAxisArr[i] : this.copyArr(respData.xAxisArr[i], xAxis[i - 1].data.length)
          })
        }
        xAxis.push({
          type: 'category',
          // boundaryGap: false, // 用于取消图和坐标区域左右的边界距离
          axisLine: {
            lineStyle: {
              color: '#ccc',
              width: 2
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            color: '#86909C'
          },
          position: 'bottom',
          offset: 0,
          data: this.copyArr(respData.xAxisArr[respData.xAxisArr.length - 1], xAxis[respData.xAxisArr.length - 2].data.length)
        })
        xAxis.reverse()
      } else { // 否则如果只有一层 x 轴
        xAxis.push({
          type: 'category',
          // boundaryGap: false, // 用于取消图和坐标区域左右的边界距离
          axisLine: {
            lineStyle: {
              color: '#ccc',
              width: 2
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            color: '#86909C',
            rotate: 40
          },
          position: 'bottom',
          offset: 0,
          data: respData.xAxisArr[0]
        })
      }
      // 保存最后一层的x轴类目的tooltip title
      const tooltipTitleArr = []
      // 计算每个类目的title
      xAxis[0].data.forEach((item, index) => {
        const title = []
        for (let i = xAxis.length - 1; i > -1; i--) {
          title.push(
            xAxis[i].data[Math.floor(index / xAxis[0].data.length * xAxis[i].data.length)]
          )
        }
        tooltipTitleArr.push(title.join('-'))
      })
      const seriesNameUnitMap = {}
      respData.series.forEach(item => {
        seriesNameUnitMap[item.name] = item.unit
      })
      const yAxis = respData.yAxis.map((item, index) => {
        return {
          type: 'value',
          name: item,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            margin: 8,
            color: '#86909C',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E5E6EB'
            }
          },
          nameTextStyle: {
            color: '#86909C',
            fontSize: 12,
            align: index === 0 ? 'left' : 'right'
          }
        }
      })
      const series = respData.series.map(item => {
        const { name, yAxisIndex, data } = item
        return {
          type: chartType === '折线图' || chartType === '面积图' ? 'line' : 'bar',
          name,
          barMaxWidth: 31,
          yAxisIndex,
          smooth: true,
          symbolSize: 5,
          areaStyle: chartType === '面积图' ? {} : undefined,
          // showSymbol: false,
          data
        }
      })
      return {
        title: {
          text: graphSettingOption.title,
          left: draggingResultContainerExternalWidth,
          padding: [5, 0]
        },
        // 复制两遍以增加长度，防止 echarts 的默认 colors 追加进来
        color: this.copyArr(graphSettingOption.colors, 2),
        tooltip: {
          trigger: 'axis',
          confine: true,
          appendToBody: true,
          axisPointer: {
            type: chartType === '折线图' || chartType === '面积图' ? 'line' : 'shadow',
            lineStyle: {
              color: '#57617B'
            }
          },
          formatter: (params) => {
            let html = ''
            for (let i = 0; i < params.length; i++) {
              const dataItem = params[i]
              if (dataItem.value == null) continue
              html += `
                <div style='display: flex; justify-content: space-between;'>
                  <div>
                    ${dataItem.marker}
                    <span>${dataItem.seriesName}</span>
                  </div>
                  <span style='margin-left: 10px; font-weight: bold;'>${dataItem.value}${seriesNameUnitMap[dataItem.seriesName]}</span>
                </div>`
            }
            return `<div style='margin-bottom: 4px;'>${tooltipTitleArr[params[0].dataIndex]}</div>` + html
          }
        },
        legend: {
          type: 'scroll',
          padding: [5, 10, 5, 320],
          icon: 'circle',
          itemHeight: 12,
          itemGap: 16,
          top: 0,
          right: 0,
          textStyle: {
            fontSize: 12,
            color: '#1D2129'
          }
        },
        grid: {
          top: 70,
          left: draggingResultContainerExternalWidth,
          right: draggingResultContainerExternalWidth,
          bottom: 5,
          containLabel: true
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: series
        // dataZoom: [
        //   // {
        //   //   type: 'inside',
        //   //   // 如果是在展示假数据，则直接把所有的数据都展示出来
        //   //   start: this.fake ? 0 : 90,
        //   //   end: 100
        //   // },
        //   {
        //     // 如果是在展示假数据，则直接把所有的数据都展示出来
        //     start: this.fake ? 0 : 90,
        //     end: 100,
        //     textStyle: {
        //       color: 'transparent'
        //     },
        //     dataBackground: {
        //       areaStyle: {
        //         color: '#c2d0ee'
        //       },
        //       lineStyle: {
        //         color: '#BBD0FC'
        //       }
        //     },
        //     selectedDataBackground: {
        //       areaStyle: {
        //         color: '#D3E0FF'
        //       }
        //     }
        //   }
        // ]
      }
    },
    // 生成堆积柱状图的配置项
    generateStackBarOption(respData, graphSettingOption) {
      const yAxis = respData.yAxis.map((item, index) => {
        return {
          type: 'value',
          name: item,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            margin: 8,
            color: '#86909C',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E5E6EB'
            }
          },
          nameTextStyle: {
            color: '#86909C',
            fontSize: 12,
            align: index === 0 ? 'left' : 'right'
          }
        }
      })
      const seriesNameUnitMap = {}
      respData.series.forEach(({ name, unit }) => {
        seriesNameUnitMap[name] = unit
      })
      const series = respData.series.map(item => {
        const { name, yAxisIndex, stack, data } = item
        return {
          name,
          yAxisIndex,
          stack,
          data,
          type: 'bar',
          barMaxWidth: 31
          // emphasis: {
          //   // none series self
          //   focus: 'self',
          //   // coordinateSystem series global
          //   blurScope: 'coordinateSystem'
          // }
        }
      })
      return {
        title: {
          text: graphSettingOption.title,
          left: draggingResultContainerExternalWidth,
          padding: [5, 0]
        },
        // 复制两遍以增加长度，防止 echarts 的默认 colors 追加进来
        color: this.copyArr(graphSettingOption.colors, 2),
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B'
            }
          },
          formatter: ({ name, marker, seriesName, value }) => {
            return `
              <div style='margin-bottom: 4px;'>${name}</div>
              <div style='display: flex; justify-content: space-between;'>
                <div>
                  ${marker}
                  <span>${seriesName}</span>
                </div>
                <span style='margin-left: 10px; font-weight: bold;'>${value}${seriesNameUnitMap[seriesName]}</span>
              </div>`
          }
        },
        legend: {
          type: 'scroll',
          padding: [5, 10, 5, 320],
          icon: 'circle',
          itemHeight: 12,
          itemGap: 16,
          top: 0,
          right: 0,
          textStyle: {
            fontSize: 12,
            color: '#1D2129'
          }
        },
        grid: {
          top: 70,
          left: draggingResultContainerExternalWidth,
          right: draggingResultContainerExternalWidth,
          bottom: 5,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 2
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: '#86909C',
              rotate: 40
            },
            data: respData.xAxis
          }
        ],
        yAxis: yAxis,
        series: series
        // dataZoom: [
        //   // {
        //   //   type: 'inside',
        //   //   // 如果是在展示假数据，则直接把所有的数据都展示出来
        //   //   start: this.fake ? 0 : 90,
        //   //   end: 100
        //   // },
        //   {
        //     // 如果是在展示假数据，则直接把所有的数据都展示出来
        //     start: this.fake ? 0 : 90,
        //     end: 100,
        //     textStyle: {
        //       color: 'transparent'
        //     },
        //     dataBackground: {
        //       areaStyle: {
        //         color: '#c2d0ee'
        //       },
        //       lineStyle: {
        //         color: '#BBD0FC'
        //       }
        //     },
        //     selectedDataBackground: {
        //       areaStyle: {
        //         color: '#D3E0FF'
        //       }
        //     }
        //   }
        // ]
      }
    },
    // 生成饼图的配置项
    generatePieOption(respData, graphSettingOption) {
      // 一维度的 tooltip formatter
      const tooltipFormatterWithOneDimension = (params) => {
        const { data, marker, seriesName } = params
        return `
                ${marker}${data.name}
                <div>${seriesName}：${data.value}${data.unit}</div>`
      }
      // 零维度的 tooltip formatter
      const tooltipFormatterWithZeroDimension = ({ data, marker }) => {
        const { name, value, unit } = data
        return `${marker}${name}：${value}${unit}`
      }
      return {
        title: {
          text: graphSettingOption.title,
          left: draggingResultContainerExternalWidth,
          padding: [5, 0]
        },
        // 复制两遍以增加长度，防止 echarts 的默认 colors 追加进来
        color: this.copyArr(graphSettingOption.colors, 2),
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          // name 为 null 时，使用零维度的formatter，否则使用一维度的formatter
          formatter: respData.series[0].name === null ? tooltipFormatterWithZeroDimension : tooltipFormatterWithOneDimension
        },
        legend: {
          type: 'scroll',
          padding: [5, 10, 5, 320],
          itemGap: 16,
          top: 0,
          right: 0,
          textStyle: {
            fontSize: 12,
            color: '#1D2129'
          }
        },
        series: [
          {
            name: respData.series[0].name,
            type: 'pie',
            radius: ['35%', '60%'],
            center: ['50%', '53%'],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 4
            },
            label: {
              // show: false,
              formatter: ({ data }) => {
                return [`{value|${data.value}}{percent|${data.unit}}`, `{seriesName|${data.name}}`].join('\n')
              },
              rich: {
                value: {
                  color: '#333333',
                  fontSize: 20,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  padding: [0, 4, 0, 0]
                },
                percent: {
                  color: '#333333',
                  fontSize: 12,
                  lineHeight: 20
                },
                seriesName: {
                  color: '#757575',
                  fontSize: 12,
                  height: 40,
                  lineHeight: 20,
                  align: 'left'
                }
              }
            },
            labelLine: {
              // show: false,
              length: 30,
              length2: 30
            },
            // emphasis: {
            //   label: {
            //     show: true
            //   },
            //   labelLine: {
            //     show: true,
            //     lineStyle: {
            //       color: '#909399'
            //     }
            //   }
            // },
            avoidLabelOverlap: false,
            data: respData.series[0].data
          }
        ]
      }
    },
    // 生成组合图的配置项
    generateMixOption(respData, graphSettingOption) {
      const yAxis = respData.yAxis.map((item, index) => {
        return {
          type: 'value',
          name: item,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            margin: 8,
            color: '#86909C',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E5E6EB'
            }
          },
          nameTextStyle: {
            color: '#86909C',
            fontSize: 12,
            align: index === 0 ? 'left' : 'right'
          }
        }
      })
      // 根据返回的 targets 数组生成一个 map
      const targetsMap = {}
      respData.targets.map(item => {
        targetsMap[item.name] = item
      })
      const series = respData.series.map((item) => {
        const { name, data, targetName, unit } = item
        const setting = targetsMap[targetName]
        return {
          name,
          type: setting.type,
          yAxisIndex: setting.yAxisIndex,
          // 只当显示为柱状图时才进行堆积
          stack: setting.type === 'bar' ? setting.stack : null,
          // 这里多存一个 targetName 字段，用于手动修改图形设置后，从对应的设置项中获取该系列的设置
          targetName,
          // 多存一个 unit 字段，用于修改图形设置后，动态修改 y 轴的名称
          unit,
          data,
          barMaxWidth: 31,
          smooth: true,
          symbolSize: 5,
          // showSymbol: false,
          emphasis: {
            // none series self
            focus: 'none',
            // coordinateSystem series global
            blurScope: 'coordinateSystem'
          }
        }
      })
      // 生成系列名和单位的map
      const seriesNameUnitMap = {}
      respData.series.forEach((item) => {
        seriesNameUnitMap[item.name] = item.unit
      })
      return {
        title: {
          text: graphSettingOption.title,
          left: draggingResultContainerExternalWidth,
          padding: [5, 0]
        },
        // 复制两遍以增加长度，防止 echarts 的默认 colors 追加进来
        color: this.copyArr(graphSettingOption.colors, 2),
        tooltip: {
          trigger: 'axis',
          appendToBody: true,
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: '#57617B'
            }
          },
          formatter: (params) => {
            let seriesHtml = ''
            for (let i = 0; i < params.length; i++) {
              const { marker, seriesName, value } = params[i]
              if (value == null) continue
              seriesHtml += `
                  <div style="display: flex; justify-content: space-between;">
                    <span>${marker}${seriesName}：</span>
                    <span style="font-weight: bold;">${value}${seriesNameUnitMap[seriesName]}</span>
                  </div>`
            }
            return `<div style='margin-bottom: 4px;'>${params[0].name}</div>${seriesHtml}`
          }
        },
        legend: {
          type: 'scroll',
          padding: [5, 10, 5, 320],
          icon: 'circle',
          itemHeight: 12,
          itemGap: 16,
          top: 0,
          right: 0,
          textStyle: {
            fontSize: 12,
            color: '#1D2129'
          }
        },
        grid: {
          top: 70,
          left: draggingResultContainerExternalWidth,
          right: draggingResultContainerExternalWidth,
          bottom: 5,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 2
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: '#86909C',
              rotate: 40
            },
            data: respData.xAxis
          }
        ],
        yAxis: yAxis,
        series: series
        // dataZoom: [
        //   // {
        //   //   type: 'inside',
        //   //   // 如果是在展示假数据，则直接把所有的数据都展示出来
        //   //   start: this.fake ? 0 : 90,
        //   //   end: 100
        //   // },
        //   {
        //     // 如果是在展示假数据，则直接把所有的数据都展示出来
        //     start: this.fake ? 0 : 90,
        //     end: 100,
        //     textStyle: {
        //       color: 'transparent'
        //     },
        //     dataBackground: {
        //       areaStyle: {
        //         color: '#c2d0ee'
        //       },
        //       lineStyle: {
        //         color: '#BBD0FC'
        //       }
        //     },
        //     selectedDataBackground: {
        //       areaStyle: {
        //         color: '#D3E0FF'
        //       }
        //     }
        //   }
        // ]
      }
    },
    exportImg() {
      if (this.chartInstance) {
        const base64 = this.chartInstance.getDataURL({
          type: 'png',
          pixelRatio: 2,
          backgroundColor: '#FFFFFF'
        })
        const blob = this.dataURLtoBlob(base64)
        const url = URL.createObjectURL(blob) // 创建图片的临时url
        this.downloadFile(url, this.graphSettingOption.title || this.chartType)
      } else {
        this.$message.error('无法导出')
      }
    },
    dataURLtoBlob(url) {
      const arr = url.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = window.atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    downloadFile(url, name) {
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', name)
      a.setAttribute('target', '_blank')
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
    },
    copyArr(arr, len) {
      let i = 0
      const result = []
      while (i < len) {
        result.push(...arr)
        i++
      }
      return result
    },
    handleChartResize() {
      this.visible && this.chartInstance?.resize()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.chart {
  height: $dragging-result-chart-height;
  overflow: hidden;
}
</style>
