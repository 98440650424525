<template>
  <PageContainer>
    <div style="width:1200px;background:#fff;margin:0 auto">
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">全部概览数据</span>
          </div>
          <div class="time-boxs">
            <el-select v-model="hisForm.nian" placeholder="请选择" size="small" @change="selectChang">
              <el-option v-for="item in historyYear" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            年
            <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px"
                       @change="selectChangY">
              <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            月
          </div>
        </div>
      </Vtitle>
      <div class="table-list">
        <h1 class="n-title" style="margin-bottom:0">汇总表</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qccxhzb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车(分车型)产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcsczyqycxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车生产主要企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnyqccxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源汽车产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcfqyckqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车分企业出口情况表
              </div>
            </el-col>
          </el-row>
        </div>

        <h1 class="n-title" style="margin-bottom:0">商用车</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sychzb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份商用车产销汇总表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/xnysyccyc/', '2')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份新能源商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/sycscqycxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份商用车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/wdqcqysyc/')" class="link">
                {{ newTime[0] }}年01-{{ newTime[1] }}月份五大汽车企业集团商用车产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '7')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车生产企业产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getZhcfcxCxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '7')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfdwcxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分吨位)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/zhcfytcxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份载货车(分用途)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/scqycxqkb/', '12')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '12')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分燃料)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getKcfcxCxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车(分车型)产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '17')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getHcfwzclscqycxqkb/')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份货车非完整车辆生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/frlclfcxcxb/', '18')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份客车非完整车辆(分车型)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/getBgqycscqycxqkb/')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车生产企业产销情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfdwcxqkb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分吨位)产销情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/bgqycfcxcxhzb/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份半挂牵引车(分车型)产销汇总表
              </div>
            </el-col>
          </el-row>


        </div>
        <h1 class="n-title" style="margin-bottom:0">发动机</h1>
        <div class="list-table">
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdjscqy/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份发动机生产企业产销情况表 (台)
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/qcfdjarlflscqyqkb/')"
                   class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业产销情况表 (台)
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdsp/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机生产企业商品量情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cqsp/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业商品量情况表
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="link-box">
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/fdzp/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机生产企业自配量情况表
              </div>
            </el-col>
            <el-col :span="12">
              <div @click="toHref('/data/dataTable/cqzp/')" class="link">
                {{ newTime[0] }}年{{ newTime[1] }}月份汽车发动机(按燃料分类)生产企业自配量情况表
              </div>
            </el-col>
          </el-row>
        </div>


      </div>

    </div>
    <div style="width:1200px;background:#fff;margin:0 auto">
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">历史数据（2004年及以前历史数据）</span>
          </div>

        </div>
      </Vtitle>

      <el-card>
        <div class="addTab">
          <!--新增的内容左侧-->

          <!--新增的内容右侧-->
          <div class="addTableRight">
            <el-row :gutter="10">
              <el-col :span="3">
                <el-button type="text" @click="change(0)">汽车综合</el-button>
              </el-col>
<!--              <el-col :span="3">-->
<!--                <el-button type="text" @click="change(1)">轿车</el-button>-->
<!--              </el-col>-->
<!--              <el-col :span="3">-->
<!--                <el-button type="text" @click="change(2)">多功能车</el-button>-->
<!--              </el-col>-->
<!--              <el-col :span="3">-->
<!--                <el-button type="text" @click="change(3)">新能源</el-button>-->
<!--              </el-col>-->
              <el-col :span="3">
                <el-button type="text" @click="change(4)">载货车</el-button>
              </el-col>
              <el-col :span="3">
                <el-button type="text" @click="change(5)">客车</el-button>
              </el-col>
              <el-col :span="3">
                <el-button type="text" @click="change(6)">专用汽车</el-button>
              </el-col>
              <el-col :span="3">
                <el-button type="text" @click="change(7)">发动机</el-button>
              </el-col>
            </el-row>
          </div>
          <div></div>
        </div>
        <!--表格数据在每个div中写-->
        <div class="tableB">
          <div v-show="0===number">
            <table border="1" style="width: 100%">
              <!--              <tr>-->
              <!--                <td><div align="center">2016年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2016/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2015年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2015/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2014年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2014/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2013年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2013/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2012年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2012/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2011年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2011/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2010年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2010/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2009年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2009/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2008年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2008/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2007年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2007/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2006年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2006/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td><div align="center">2005年</div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/1/index.htm" target="_blank">1月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/2/index.htm" target="_blank">2月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/3/index.htm" target="_blank">3月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/4/index.htm" target="_blank">4月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/5/index.htm" target="_blank">5月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/6/index.htm" target="_blank">6月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/7/index.htm" target="_blank">7月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/8/index.htm" target="_blank">8月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/9/index.htm" target="_blank">9月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/10/index.htm" target="_blank">10月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/11/index.htm" target="_blank">11月</a></div></td>-->
              <!--                <td><div align="center"><a href="qctjxx_ykb/2005/12/index.htm" target="_blank">12月</a></div></td>-->
              <!--              </tr>-->
              <tr>
                <td>
                  <div align="center">2004年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/1/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/2/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/3/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/4/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/5/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/6/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/7/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/8/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/9/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2004/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">2003年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/1/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/2/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/3/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/4/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/5/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/6/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/7/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/8/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/9/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2003/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">2002年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/01/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/02/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/03/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/04/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/05/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/06/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/07/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/08/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/09/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2002/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">2001年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/01/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/02/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/03/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/04/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/05/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/06/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/07/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/08/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/09/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2001/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">2000年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/01/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/02/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/03/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/04/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/05/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/06/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/07/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/08/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/09/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/2000/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">1999年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/01/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/02/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/03/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/04/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/05/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/06/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/07/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/08/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/09/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1999/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">1998年</div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/01/index.htm" target="_blank">1月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/02/index.htm" target="_blank">2月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/03/index.htm" target="_blank">3月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/04/index.htm" target="_blank">4月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/05/index.htm" target="_blank">5月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/06/index.htm" target="_blank">6月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/07/index.htm" target="_blank">7月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/08/index.htm" target="_blank">8月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/09/index.htm" target="_blank">9月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/10/index.htm" target="_blank">10月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/11/index.htm" target="_blank">11月</a></div>
                </td>
                <td>
                  <div align="center"><a href="qctjxx_ykb/1998/12/index.htm" target="_blank">12月</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center">1955-1997年</div>
                </td>
                <td colspan="12">
                  <div align="center"><a onClick="document_onclick(this); return false;"
                                         href="qctjxx_ykb/1955/1997/index.htm" target="_blank">1955～1997年汽车产量</a></div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="1===number">
            <table border="1" style="width: 100%">
              <tr>
                <!--	<td><div align="center"><a href="/autoinfo_cn/tjxx/cpfl/jc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div></td>-->
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/jc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="2===number">
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>

              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/pk/index-tjxx(cx_01-03).htm" target="_blank">2001年~2003年</a>
                  </div>
                </td>

              </tr>
            </table>
          </div>
          <div v-show="3===number">
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/xny/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/xny/index-tjxx(cx_2015).htm" target="_blank">2005年~2015年</a>
                  </div>
                </td>
              </tr>

            </table>
          </div>
          <div v-show="4===number">
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zhc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="5===number">
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/kc/index-tjxx(cx_2001).htm" target="_blank">2001年</a></div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="6===number">
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>

              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/zyc/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="7===number">
            <p>
            <table border="1" style="width: 100%">
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2016).htm" target="_blank">2016年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2015).htm" target="_blank">2015年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2014).htm" target="_blank">2014年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2013).htm" target="_blank">2013年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2012).htm" target="_blank">2012年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2011).htm" target="_blank">2011年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2010).htm" target="_blank">2010年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2009).htm" target="_blank">2009年</a></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2008).htm" target="_blank">2008年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2007).htm" target="_blank">2007年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2006).htm" target="_blank">2006年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2005).htm" target="_blank">2005年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2004).htm" target="_blank">2004年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2003).htm" target="_blank">2003年</a></div>
                </td>
                <td>
                  <div align="center"><a href="tjxx/cpfl/fdj/index-tjxx(cx_2002).htm" target="_blank">2002年</a></div>
                </td>
                <td></td>
              </tr>
            </table>
            </p>
          </div>
        </div>
      </el-card>
    </div>
  </PageContainer>
</template>
<script>
import {getYearListTables, getYueListDynamics} from "@/api/data.js";
import Vtitle from "../components/title.vue";
import {getUserInfo, openSy} from "@/utils/auth";
import {buryPoint} from "@/api/common";

export default {
  components: {
    Vtitle,
  },
  data() {
    return {
      historyYear: [],
      historyYue: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      hisForm: {
        nian: "",
        yue: "02",
      },
      number: 0,
      historyList: [],
      hv: "",
      newTime: ["2021", "02"],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    change(index) {
      this.number = index; //重要处
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },

    selectChang(v) {

      this.getYueListDynamics(v)
      this.$set(this.newTime, [0], v);


    },
    selectChangY(v) {

      this.$set(this.newTime, [1], v);

    },
    init() {
      getYearListTables().then((res) => {
        if (res.ok) {
          this.historyYear = res.data;
          this.hisForm.nian = res.data[0];
          this.$set(this.newTime, [0], this.hisForm.nian);
          this.getYueListDynamics(res.data[0]);
        }
      });
      // lastTime().then((res) => {
      //     if (res.ok) {
      //         this.newTime = res.data.split("-");
      //         this.hisForm.yue = this.newTime[1];
      //     }
      // });
    },
    getYueListDynamics(nian) {
      getYueListDynamics(nian).then((res) => {
        this.historyYue = res.data.list;
        this.hisForm.yue = res.data.list[res.data.list.length - 1];
        this.$set(this.newTime, [1], this.hisForm.yue);
      });
    },
    hisSearch() {
      this.$set(this.newTime, [0], this.hisForm.nian);
      this.$set(this.newTime, [1], this.hisForm.yue);
    },
    toHref(url, type) {
      let str = "";
      str = type
          ? (str = url + this.newTime.join("-") + "-01/" + type)
          : url + this.newTime.join("-") + "-01";
      let newUrl = this.$router.resolve({
        path: str
      });

      window.open(newUrl.href, "_blank");


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
  },
};
</script>
<style lang="scss" scoped>
.table-list {
  padding: 24px;
}

.list-table {
  .link-box {
    &:last-child {
      .link {
        border-bottom: none;
      }
    }
  }

  .link {
    cursor: pointer;
    display: block;
    line-height: 43px;
    border-bottom: 1px solid #F2F2F2;
    color: #000;
    font-size: 15px;
  }
}

.time-boxs {
  color: #fff;

  .el-select {
    width: 80px;
  }

  /deep/ .el-input__inner {
    background: transparent;
    color: #fff;
  }

  /deep/ .el-icon-arrow-up:before {
    color: #fff;
  }
}


/*** */
.hisTable {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #0d57bc;
  font-size: 14px;
}

.hisTable td {
  padding: 5px;
  cursor: pointer;
}

.outer-layer-new {
  padding: 20px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
