<!--免征购置税目录-->
<template>
  <PageContainer>
    <!--    <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据"-->
    <!--               :showTips="showTips"/>-->
    <div>
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">免征购置税目录</span>
          </div>

        </div>
      </Vtitle>
      <el-form :model="form" inline label-width="100px" ref="formName" class="n-data-content">
        <h1 class="n-title">显示字段</h1>
        <el-row style="margin:0px 0 10px;">
          <el-col class="n-check-m">
            <el-form-item prop="enterpriseNameShow" style="display:none"></el-form-item>
            <el-form-item prop="commonNameShow" style="display:none"></el-form-item>
            <el-form-item prop="pcShow" style="display:none"></el-form-item>
            <el-form-item prop="cpxhShow" style="display:none"></el-form-item>
            <el-form-item prop="rlzlShow" style="display:none"></el-form-item>
            <el-form-item prop="xfscShow" style="display:none"></el-form-item>
            <el-checkbox v-model="form.pcShow" @change="changeFun('pc')" disabled class="isDisabled">批次</el-checkbox>
            <el-checkbox v-model="form.cpxhShow" @change="changeFun('vehicleModelNumber')">车辆型号</el-checkbox>
            <el-checkbox v-model="form.commonNameShow" @change="changeFun('commonName')">通用名称</el-checkbox>
            <el-checkbox v-model="form.enterpriseNameShow" @change="changeFun('enterpriseName')">生产企业</el-checkbox>
            <el-checkbox v-model="form.rlzlShow" @change="changeFun('rlzl')">燃料种类</el-checkbox>
            <el-checkbox v-model="form.xfscShow" @change="changeFun('xfsc')">细分市场</el-checkbox>
          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">查询字段</h1>
        <el-row>
          <el-col :span="8">
            <el-form-item label="批次：" class="no-position" prop="pc">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.pcShow">

                <selectCheck v-model="form.pc" :datas="pcData" :disabled="!form.pcShow" :props="props"
                             :isLetters="false"></selectCheck>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车辆型号：" prop="vehicleModelNumber">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cpxhShow">

                <el-input size="small" :disabled="!form.cpxhShow" v-model="form.vehicleModelNumber" class="new-width"/>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="通用名称" prop="cpmc">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.commonNameShow">
                <el-input size="small" :disabled="!form.commonNameShow" v-model="form.commonName" class="new-width"/>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="生产企业：" prop="scqy">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.enterpriseNameShow">

                <el-input size="small" :disabled="!form.enterpriseNameShow" v-model="form.enterpriseName"
                          class="new-width"/>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="燃料种类：" prop="rlzl">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.rlzlShow">

                <el-select class="new-width" size="small" :disabled="!form.rlzlShow" v-model="form.rlzl" multiple
                           collapse-tags>
                  <el-option :value="item" :label="item" :key="item" v-for="item in rlzlData"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="细分市场：" prop="xfsc">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.xfscShow">

                <el-select class="new-width" size="small" :disabled="!form.xfscShow" v-model="form.xfsc" multiple
                           collapse-tags>
                  <el-option :value="item" :label="item" :key="item" v-for="item in xfscData"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="text-center">
          <el-button @click="resetForm">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </div>
      </el-form>
      <div class="search-form" style="margin-top:10px">
        <div flex="sb" style="margin-bottom: 16px">
          <span class="table_title"></span>
          <div>
            <el-button type="primary" v-has-role="'freeusers'" plain @click="ExportAll" :loading="excelAllLoading">
              导出全部
            </el-button>
            <el-button type="primary" v-has-role="'freeusers'" plain @click="Export" :loading="excelLoading">导出
            </el-button>
          </div>
        </div>
        <div >
          <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" stripe border
                    :header-cell-style="{ background: '#FAFAFA', color: '#000' }" v-loading="loading">
            <template v-for="(item,index) in tableHeader">
              <el-table-column :prop="item" :label="returnName(item)" :key="index"></el-table-column>
            </template>
          </el-table>
          <div class="text-right">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
            </el-pagination>
          </div>
        </div>

      </div>
    </div>
  </PageContainer>
</template>
<script>
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import Vtitle from "../components/title.vue";
import {
  getPCInfoListXny,
  getRlInfoListXny,
  getXfscInfoListXny,
  ttXnyMzgzsPage,
  ttXnyMzgzsDynamicExport,
  ttXnyMzgzsExportAll, getXfscInfoListXnySyc,
} from "@/api/data.js";
import {downloadFile} from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    selectCheck,
    Vtitle,
  },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      tableHeader: [],
      form: {
        enterpriseNameShow: true,
        commonNameShow: true,
        pcShow: true,
        cpxhShow: true,
        rlzlShow: true,
        xfscShow: true,
        commonName: "",
        enterpriseName: "",
        vehicleModelNumber: "",
        pc: [],
        rlzl: [],
        xfsc: [],
        cllx: "2",
      },
      pageData: {
        page: 1,
        pageSize: 10,
        count: 0,
      },
      pcData: [],
      rlzlData: [],
      xfscData: [],
      loading: false,
      excelLoading: false,
      excelAllLoading: false,
      props: {
        label: "pc",
        key: "pc",
        name: "pc",
      },
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  mounted() {

    if (getUserInfo()) {
      this.show = true;
      this.init();
      this.getPCInfoListXny()
    } else {
      this.show = false;
    }
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //初始化类型
    init() {

      getRlInfoListXny().then((res) => {
        if (res.ok) {
          this.rlzlData = res.data;
        }
      });
      getXfscInfoListXnySyc().then((res) => {
        if (res.ok) {
          this.xfscData = res.data;
        }
      });
    },
    getPCInfoListXny() {
      getPCInfoListXny().then((res) => {
        if (res.ok) {
          this.form.pc = [res.data[0].pc];
          this.pcData = res.data;
          this.ttXnyMzgzsPage();

        }
      });
    },
    //查询
    ttXnyMzgzsPage() {
      let form = Object.assign({}, this.form, this.pageData);
      for (let key in form) {
        let isArr = Array.isArray(form[key]);
        if (isArr) {
          form[key] = form[key].join(",");
        }
      }
      delete form.count;
      this.loading = true;
      ttXnyMzgzsPage(form).then((res) => {
        if (res.ok) {
          this.tableHeader = res.data.tableFiles;
          this.tableData = res.data.data.list;
          this.pageData.count = res.data.data.count;
        }
        this.loading = false;
      });
    },
    //查询
    search() {

      this.pageData.page = 1;
      this.ttXnyMzgzsPage();


    },
    //重置
    resetForm() {
      this.$refs.formName.resetFields();
      this.getPCInfoListXny();
    },
    //切换清空
    changeFun(type) {
      let isArr = Array.isArray(this.form[type]);
      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        this.$set(this.form, type, "");
      }
    },
    //返回名称
    returnName(label) {
      let str = "";
      switch (label) {
        case "cpxh":
          str = "车辆型号";
          break;
        case "enterpriseName":
          str = "生产企业";
          break;
        case "commonName":
          str = "通用名称";
          break;
        case "pc":
          str = "批次";
          break;
        case "rlzl":
          str = "燃料种类";
          break;
        case "xfsc":
          str = "细分市场";
          break;


      }

      return str;
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.ttXnyMzgzsPage();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.ttXnyMzgzsPage();
    },

    //导出
    Export() {

      let _self = this;
      let form = Object.assign({}, this.form, this.pageData);
      for (let key in form) {
        let isArr = Array.isArray(form[key]);
        if (isArr) {
          form[key] = form[key].join(",");
        }
      }
      delete form.count;
      this.excelLoading = true;
      ttXnyMzgzsDynamicExport(form).then((res) => {
        this.excelLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "免征购置税目录数据.xls");
          }
        });
      });


    },
    //导出全部
    ExportAll() {

      let _self = this;
      let form = Object.assign({}, this.form, this.pageData);
      for (let key in form) {
        let isArr = Array.isArray(form[key]);
        if (isArr) {
          form[key] = form[key].join(",");
        }
      }
      delete form.count;
      this.excelAllLoading = true;
      ttXnyMzgzsExportAll(form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "免征购置税目录全部数据.xls");
          }
        });
      });


    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.table-tip {
  width: 760px;
  margin: 0 auto;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
