<template>
    <div class="container-r-position" v-if="list.length>0">
        <div class="title">{{ title }}</div>
        <ul class="container-r-content" v-if="type == 'list'">
            <li v-for="item in list" :key="item.id" @click="clickFun(item)">
                <div class="inner">{{ item.title }}</div>
                <p class="time">{{ item.publishDate }}</p>
            </li>
        </ul>
        <ul class="container-r-content" v-if="type == 'file'">
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "VcontentItem",
    props: {
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        title: "",
        type: "",
    },
    data() {
        return {
            data: {},
        };
    },
    created() {
         this.$store.dispatch("common/SET_ListIndex");
        // if (this.list.length <= 0) {
        //     this.$store.dispatch("common/SET_ListFlagIndex");
        //        this.$store.dispatch("common/SET_ListIndex");
        // this.$store.dispatch("common/SET_ListFlag");
        // }
    },
    watch: {
        list(v) {
            if (v.length <= 0) {
                this.$store.dispatch("common/SET_ListFlagIndex");
            }
            this.$store.dispatch("common/SET_ListFlag");
        },
    },
    methods: {
        clickFun(item) {
            this.$emit("click", item);
        },
    },
};
</script>
<style lang="scss" scoped>
.container-r-position {
    width: 100%;
    // width: 14.5%;
    // position:fixed;
    background: #fff;
    border-bottom: 10px solid #f6f6f6;
    border-radius: 4px;
    padding: 0 16px;
    // float: left;
}
.title {
    padding: 15px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: #0D57BC;
    border-bottom: 1px solid #f2f2f2;
}
.container-r-content {
    padding: 24px 0 0;
    li {
        padding-bottom: 24px;
        cursor: pointer;
        .inner {
            line-height: 21px;
            margin-bottom: 16px;
            color: #000;
            &:hover {
                color: #0D57BC;
            }
        }
        .time {
            color: #828282;
        }
    }
}
</style>
