const SETTINGS = 'SETTINGS';

const defaultState = {
  header: {
    fixed: true,
    hasMenu: true,
  },
  hasBreadcrumb: false,
  breadcrumbTrail: false,
  sider: {
    fixed: true,
    collapsed: false,
  },
};

const initialState = (() => {
  try {
    const state = JSON.parse(localStorage.getItem(SETTINGS));
    if (!state) {
      throw new Error('Invalid settings state');
    }
    return state;
  } catch {
    return { ...defaultState };
  }
})();

const state = {
  ...initialState,
};

const mutations = {
  SET_HEADER(state, payload) {
    state.header.fixed = payload;
  },
  SET_BREADCRUMB(state, payload) {
    state.hasBreadcrumb = payload;
  },
  SET_BREADCRUMB_TRAIL(state, payload) {
    state.breadcrumbTrail = payload;
  },
  SET_SIDER(state, payload) {
    state.sider.collapsed = payload;
  },
};

const actions = {
  fixedHeader({ commit }) {
    commit('SET_HEADER', true);
  },
  unfixedHeader({ commit }) {
    commit('SET_HEADER', false);
  },
  toggleFixedHeader({ commit, state }) {
    commit('SET_HEADER', !state.header.fixed);
  },

  enableBreadcrumb({ commit }) {
    commit('SET_BREADCRUMB', true);
  },
  disableBreadcrumb({ commit }) {
    commit('SET_BREADCRUMB', false);
  },
  toggleBreadcrumb({ commit, state }) {
    commit('SET_BREADCRUMB', !state.hasBreadcrumb);
  },

  enableBreadcrumbTrail({ commit }) {
    commit('SET_BREADCRUMB_TRAIL', true);
  },
  disableBreadcrumbTrail({ commit }) {
    commit('SET_BREADCRUMB_TRAIL', false);
  },
  toggleBreadcrumbTrail({ commit, state }) {
    commit('SET_BREADCRUMB_TRAIL', !state.breadcrumbTrail);
  },

  openSider({ commit }) {
    commit('SET_SIDER', false);
  },
  closeSider({ commit }) {
    commit('SET_SIDER', true);
  },
  toggleSider({ commit, state }) {
    commit('SET_SIDER', !state.sider.collapsed);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

