<template>
  <div class="container-r-position" v-if="items.length>0">
    <div class="title">{{ title }}</div>
    <ul class="container-r-content" v-if="type == 'list'">
      <li v-for="item in items" :key="item.id" @click="clickFun(item)" >
        <img  src="../../../assets/img/dot_grey.gif" style="height: 6px;margin-top: 6px;"/>
       <div class="text-box"> <TextOverTooltip :font-size="20" v-bind:title="htmlRestore(item.title)" /></div>
        <div class="timeLine">{{ fmtDate("mm-dd",item.publishDate) }}</div>
      </li>
    </ul>
    <ul class="container-r-content" v-if="type == 'file'">
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
      <li>
        <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
      </li>
    </ul>
  </div>
</template>
<script>
import TextOverTooltip from "@/components/toolTip.vue";

export default {
  name: "VcontentItem",
  components: {
        TextOverTooltip,
    },
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: "",
    type: "",
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    items() {
      return this.list || [];
    },
  },
  created() {
    this.$store.dispatch("common/SET_ListIndex");
    // if (this.items.length <= 0) {
    //     this.$store.dispatch("common/SET_ListFlagIndex");
    //        this.$store.dispatch("common/SET_ListIndex");
    // this.$store.dispatch("common/SET_ListFlag");
    // }
  },
  watch: {
    items(v) {
      if (v.length <= 0) {
        this.$store.dispatch("common/SET_ListFlagIndex");
      }
      this.$store.dispatch("common/SET_ListFlag");
    },
  },
  methods: {
    clickFun(item) {
      this.$emit("click", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.container-r-position {
  width: 100%;
  // width: 14.5%;
  // position:fixed;
  background: #fff;
  border-bottom: 10px solid #f6f6f6;
  border-radius: 4px;
  padding: 0 16px;
  // float: left;
}

.title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #0D57BC;
  border-bottom: 1px solid #f2f2f2;
}

.container-r-content {
  padding: 12px 0 0;

  li {
    position: relative;
    padding-bottom: 12px;
    cursor: pointer;
    display: flex;
    .inner {
      line-height: 21px;
      margin-bottom: 16px;
      color: #000;

      &:hover {
        color: #0D57BC;
      }
    }

    .time {
      color: #828282;
    }
  }
}

.text-overflow {
  padding-left: 6px;
  font-size: 14px;
  line-height: 20px;
  padding-right: 40px;
  color: #000;
  display: inline-block;
  word-break: normal;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.text-box {
  flex: 1;
  padding-left: 10px;
}
.timeLine {
  color: #bdbdbd;
  //padding-left: 10px;
  font-size: 14px;
  //line-height: 16px;
  text-align: right;
  width: 50px;
  top: 0;
  right: 10px;
}
</style>
