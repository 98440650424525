<template>
  <div>
    <router-view></router-view>
    <PageContainer breadcrumb footer>
      <Vcontent :data="data" :props="props" />

      <template slot="right">
        <VcontentItem
          title="征求意见稿推荐"
          type="list"
          :list="relationComment"
          @click="tohref"
        />
        <VcontentItem
          title="标准动态推荐"
          type="list"
          :list="relationDynamic"
          @click="tohref"
        />
        <VcontentItem
          title="标准解读推荐"
          type="list"
          :list="relationInterpret"
          @click="tohref"
        />
        <VcontentItem
          title="标准主体推荐"
          type="list"
          :list="relationSelf"
          @click="tohref"
        />
        <VcontentItem
            title="最新标准推荐"
            type="list"
            :list="tableDataRight"
            @click="toHref"
        />
      </template>
    </PageContainer>
  </div>
</template>
<script>
import { queryContentById } from "@/api/Index.js";
import { getUserInfo } from "@/utils/auth.js";
import {newStandard} from "@/api/standards";
export default {
  data() {
    return {
      data: {},
      props: {
        tag: "applicableProducts",
        htmlContent: "content",
      },
      relationComment: [],
      relationDynamic: [],
      relationInterpret: [],
      relationSelf: [],
      tableDataRight: []

    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      if (id) {
        this.queryContentById(id);
      }
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.queryContentById(id);
    this.localOriginalRight({page: 1, pageSize: 10});

  },
  methods: {
    queryContentById(params) {
      queryContentById(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relationComment = res.data.relationComment;
          this.relationDynamic = res.data.relationDynamic;
          this.relationInterpret = res.data.relationInterpret;
          this.relationSelf = res.data.relationSelf;
        }
      });
    },
    tohref(item) {
      let userInfo = getUserInfo();
      if (!userInfo) {
        this.showLogin();
        return false;
      }
      if (item.tableName == "tt_solicit_comments") {
        //征求意见稿
        this.$router.push("/standards/dynamic/contentComments/" + item.id);
      } else if (item.tableName == "tt_standard_dynamic") {
        //标准动态推荐
        this.$router.push("/standards/dynamic/contentDynamic/" + item.id);
      } else if (item.tableName == "tt_standard_unscramble") {
        //标准解读推荐
        this.$router.push("/standards/dynamic/contentUnscramble/" + item.id);
      } else {
        //标准主体推荐
        this.$router.push("/standards/dynamic/ttStandard/" + item.id);
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin({ flag: true });
    },
    //最新标准
    localOriginalRight(param) {

      newStandard(param).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list;
          this.loading = false;
        }
      });
    },
    toHref(item) {
      this.$router.push("/standards/dynamic/newStandards/details/" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
