<!--商用车产销汇总表-->
<template>
  <PageContainer>
      <div class="syccxhzHeader">
<!--          <div></div>-->
          <div class="sycBottom"><p>2022年1月份商用车产销汇总表</p></div>
          <div class="sycDan">
                 <p>单位：辆 %</p>
          </div>
      </div>
      <div>
          <table border="1" style="width: 100%;padding: 5px" class="hisTable" id="tal">
              <thead>
                  <tr class="headerClass" >
                      <td colspan="2" rowspan="2">车型</td>
                      <td colspan="6">生产</td>
                      <td colspan="6">销售</td>
                      <td rowspan="2">本月产销率</td>
                      <td rowspan="2">本期产销率</td>
                  </tr>
                  <tr class="headerClass">
                      <td>本月完成</td>
                      <td>本期止累计</td>
                      <td>同期止累计</td>
                      <td>比上月增长</td>
                      <td>比同期增长</td>
                      <td>比同期累计增长</td>
                      <td>本月完成</td>
                      <td>本期止累计</td>
                      <td>同期止累计</td>
                      <td>比上月增长</td>
                      <td >比同期增长</td>
                      <td>比同期累计增长</td>
                  </tr>

              </thead>
              <tbody>
              <tr v-for="item in datalist2" class="trOne" style="height: 50px">
                  <td class="trOne" colspan="2">{{item.zhibiao}}</td>
                  <td >{{item.scBywc}}</td>
                  <td>{{item.scBqzlj}}</td>
                  <td>{{item.scTqzlj}}</td>
                  <td>{{item.scBsyzz}}</td>
                  <td>{{item.scBtqzz}}</td>
                  <td>{{item.scBtqljzz}}</td>
                  <td>{{item.xsBywc}}</td>
                  <td>{{item.xsBqzlj}}</td>
                  <td>{{item.xsTqzlj}}</td>
                  <td>{{item.xsBsyzz}}</td>
                  <td>{{item.xsBtqzz}}</td>
                  <td>{{item.xsBtqljzz}}</td>
                  <td>{{item.bycxl}}</td>
                  <td>{{item.bqcxl}}</td>
              </tr>
                  <tr v-for="item in newDataList">
                  <td class="trOne" :rowspan="item.qzspan" :style="{ display: item.qzdis }" > {{ item.qz }}</td>
                  <td >{{item.zhibiao}}</td>
                  <td >{{item.scBywc}}</td>
                  <td>{{item.scBqzlj}}</td>
                  <td>{{item.scTqzlj}}</td>
                  <td>{{item.scBsyzz}}</td>
                  <td>{{item.scBtqzz}}</td>
                  <td>{{item.scBtqljzz}}</td>
                  <td>{{item.xsBywc}}</td>
                  <td>{{item.xsBqzlj}}</td>
                  <td>{{item.xsTqzlj}}</td>
                  <td>{{item.xsBsyzz}}</td>
                  <td>{{item.xsBtqzz}}</td>
                  <td>{{item.xsBtqljzz}}</td>
                  <td>{{item.bycxl}}</td>
                  <td>{{item.bqcxl}}</td>
              </tr>
              </tbody>
          </table>
      </div>
  </PageContainer>
</template>

<script>
    import {getAdd} from "@/api/data.js";
    import tableHeader from "./commonHeader/tableHeader";
    export default {
        components:{
            tableHeader
        },
        name: "syccxhz",
        data(){
            return{
                ddd:2,
                cc:0,
                datalist2:[],
                dataList: [],
                newDataList:[],
                data3:[
                    {
                        "qz": "",
                        "zhibiao": "商用车总计",
                        "scBywc": 278818,
                        "scBqzlj": 623940,
                        "scTqzlj": 820449,
                        "scBsyzz": -19.21,
                        "scBtqzz": -18.07,
                        "scBtqljzz": -23.95,
                        "xsBywc": 249944,
                        "xsBqzlj": 594181,
                        "xsTqzlj": 758833,
                        "xsBsyzz": -27.39,
                        "xsBtqzz": -16.38,
                        "xsBtqljzz": -21.7,
                        "bycxl": 89.64,
                        "bqcxl": 95.23
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "柴油汽车",
                        "scBywc": 166644,
                        "scBqzlj": 383052,
                        "scTqzlj": 617410,
                        "scBsyzz": -23,
                        "scBtqzz": -36.49,
                        "scBtqljzz": -37.96,
                        "xsBywc": 166405,
                        "xsBqzlj": 387559,
                        "xsTqzlj": 577019,
                        "xsBsyzz": -24.76,
                        "xsBtqzz": -25.28,
                        "xsBtqljzz": -32.83,
                        "bycxl": 99.86,
                        "bqcxl": 101.18
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "汽油汽车",
                        "scBywc": 97374,
                        "scBqzlj": 209591,
                        "scTqzlj": 179293,
                        "scBsyzz": -13.21,
                        "scBtqzz": 46.4,
                        "scBtqljzz": 16.9,
                        "xsBywc": 69360,
                        "xsBqzlj": 177219,
                        "xsTqzlj": 156166,
                        "xsBsyzz": -35.69,
                        "xsBtqzz": 7.28,
                        "xsBtqljzz": 13.48,
                        "bycxl": 71.23,
                        "bqcxl": 84.55
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "普通混合动力",
                        "scBywc": 0,
                        "scBqzlj": 0,
                        "scTqzlj": 0,
                        "scBsyzz": null,
                        "scBtqzz": null,
                        "scBtqljzz": null,
                        "xsBywc": 0,
                        "xsBqzlj": 0,
                        "xsTqzlj": 0,
                        "xsBsyzz": null,
                        "xsBtqzz": null,
                        "xsBtqljzz": null,
                        "bycxl": null,
                        "bqcxl": null
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "插电式混合动力",
                        "scBywc": 531,
                        "scBqzlj": 947,
                        "scTqzlj": 288,
                        "scBsyzz": 27.64,
                        "scBtqzz": 247.06,
                        "scBtqljzz": 228.82,
                        "xsBywc": 296,
                        "xsBqzlj": 681,
                        "xsTqzlj": 306,
                        "xsBsyzz": -23.12,
                        "xsBtqzz": 106.99,
                        "xsBtqljzz": 122.55,
                        "bycxl": 55.74,
                        "bqcxl": 71.91
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "纯电动",
                        "scBywc": 11954,
                        "scBqzlj": 25174,
                        "scTqzlj": 10403,
                        "scBsyzz": -9.58,
                        "scBtqzz": 220.83,
                        "scBtqljzz": 141.99,
                        "xsBywc": 11196,
                        "xsBqzlj": 22491,
                        "xsTqzlj": 9580,
                        "xsBsyzz": -0.88,
                        "xsBtqzz": 241.65,
                        "xsBtqljzz": 134.77,
                        "bycxl": 93.66,
                        "bqcxl": 89.34
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "燃料电池",
                        "scBywc": 205,
                        "scBqzlj": 353,
                        "scTqzlj": 64,
                        "scBsyzz": 39.46,
                        "scBtqzz": 688.46,
                        "scBtqljzz": 451.56,
                        "xsBywc": 169,
                        "xsBqzlj": 367,
                        "xsTqzlj": 96,
                        "xsBsyzz": -14.21,
                        "xsBtqzz": 482.76,
                        "xsBtqljzz": 282.29,
                        "bycxl": 82.44,
                        "bqcxl": 103.97
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "天然气",
                        "scBywc": 2110,
                        "scBqzlj": 4823,
                        "scTqzlj": 12909,
                        "scBsyzz": -22.23,
                        "scBtqzz": -71.75,
                        "scBtqljzz": -62.64,
                        "xsBywc": 2489,
                        "xsBqzlj": 5835,
                        "xsTqzlj": 15566,
                        "xsBsyzz": -25.61,
                        "xsBtqzz": -69.25,
                        "xsBtqljzz": -62.51,
                        "bycxl": 117.96,
                        "bqcxl": 120.98
                    },
                    {
                        "qz": "其中1",
                        "zhibiao": "其他替代燃料",
                        "scBywc": 0,
                        "scBqzlj": 0,
                        "scTqzlj": 82,
                        "scBsyzz": null,
                        "scBtqzz": -100,
                        "scBtqljzz": -100,
                        "xsBywc": 29,
                        "xsBqzlj": 29,
                        "xsTqzlj": 100,
                        "xsBsyzz": null,
                        "xsBtqzz": 0,
                        "xsBtqljzz": -71,
                        "bycxl": null,
                        "bqcxl": null
                    },
                    {
                        "qz": "其中2",
                        "zhibiao": "客车",
                        "scBywc": 23860,
                        "scBqzlj": 52462,
                        "scTqzlj": 59262,
                        "scBsyzz": -16.54,
                        "scBtqzz": -6.52,
                        "scBtqljzz": -11.47,
                        "xsBywc": 22510,
                        "xsBqzlj": 51926,
                        "xsTqzlj": 58717,
                        "xsBsyzz": -23.49,
                        "xsBtqzz": -4.94,
                        "xsBtqljzz": -11.57,
                        "bycxl": 94.34,
                        "bqcxl": 98.98
                    },
                    {
                        "qz": "其中2",
                        "zhibiao": "半牵引挂车",
                        "scBywc": 20545,
                        "scBqzlj": 58652,
                        "scTqzlj": 162198,
                        "scBsyzz": -46.09,
                        "scBtqzz": -73.74,
                        "scBtqljzz": -63.84,
                        "xsBywc": 25238,
                        "xsBqzlj": 68270,
                        "xsTqzlj": 150448,
                        "xsBsyzz": -41.35,
                        "xsBtqzz": -61.16,
                        "xsBtqljzz": -54.62,
                        "bycxl": 122.84,
                        "bqcxl": 116.4
                    },
                    {
                        "qz": "其中2",
                        "zhibiao": "非完整型货车",
                        "scBywc": 30916,
                        "scBqzlj": 72288,
                        "scTqzlj": 131336,
                        "scBsyzz": -25.27,
                        "scBtqzz": -43.7,
                        "scBtqljzz": -44.96,
                        "xsBywc": 28230,
                        "xsBqzlj": 68202,
                        "xsTqzlj": 136070,
                        "xsBsyzz": -29.38,
                        "xsBtqzz": -38.23,
                        "xsBtqljzz": -49.88,
                        "bycxl": 91.31,
                        "bqcxl": 94.35
                    },
                    {
                        "qz": "其中2",
                        "zhibiao": "非完整型客车",
                        "scBywc": 797,
                        "scBqzlj": 1869,
                        "scTqzlj": 1666,
                        "scBsyzz": -25.58,
                        "scBtqzz": 55.36,
                        "scBtqljzz": 12.18,
                        "xsBywc": 798,
                        "xsBqzlj": 1856,
                        "xsTqzlj": 1650,
                        "xsBsyzz": -24.5,
                        "xsBtqzz": 58.65,
                        "xsBtqljzz": 12.48,
                        "bycxl": 100.13,
                        "bqcxl": 99.3
                    },
                    {
                        "qz": "其中2",
                        "zhibiao": "货车",
                        "scBywc": 202700,
                        "scBqzlj": 438669,
                        "scTqzlj": 465987,
                        "scBsyzz": -14.1,
                        "scBtqzz": 11.89,
                        "scBtqljzz": -5.86,
                        "xsBywc": 173168,
                        "xsBqzlj": 403927,
                        "xsTqzlj": 411948,
                        "xsBsyzz": -24.96,
                        "xsBtqzz": 5.55,
                        "xsBtqljzz": -1.95,
                        "bycxl": 85.43,
                        "bqcxl": 92.08
                    }
                ],
                hv:'',
            }
        },
        created() {
           /// this.getInformation();
            this.gteRouter();
             this.hv= this.$route.query.data;
            console.log('hv的值为',this.hv);
        },
        methods:{
            gteRouter(){
                this.hv= this.$route.query.data;
                console.log('hv的值为',this.hv);
                this.getInformation();
            },
            getInformation(){
                let hvl=this.hv;
                console.log('hv1',hvl);
                getAdd(hvl).then((res) => {
                    console.log('res',res)
                });
                this.dataCli();
            },
            // 数据处理方法
            combineCell() {
                let list = this.data3;
                for (var field in list[0]) {  // 获取数据中的字段，也就是table中的column，只需要取其中一条记录的就可以了
                    // 定义数据list的index
                    var k = 0;
                    while (k < list.length) {
                        // 增加字段-用于统计有多少重复值
                        list[k][field + 'span'] = 1;
                        // 增加字段-用于控制显示与隐藏
                        list[k][field + 'dis'] = '';
                        for (var i = k + 1; i <= list.length - 1; i++) {
                            // 判断第k条数据的field字段，与下一条是否重复
                            if (list[k][field] === list[i][field] && list[k][field] !== '') {
                                // 如果重复，第k条数据的字段统计+1
                                list[k][field + 'span']++;
                                // 设置为显示
                                list[k][field + 'dis'] = '';
                                // 重复的记录，则设置为1，表示不跨行
                                list[i][field + 'span'] = 1;
                                // 并且该字段设置为隐藏
                                list[i][field + 'dis'] = 'none';
                            } else {
                                break;
                            }
                        }
                        // 跳转到第i条数据的索引
                        k = i;
                    }
                }
                console.log(list,'list');
                this.newDataList = list
            },
            dataCli(){
                this.datalist2.push(this.data3[0]);
                this.data3.splice(0,1);
                // this.data3.shift();
                // this.dataList=[...this.data3];
                this.combineCell();
            }
        }
    }
</script>

<style scoped lang="css">
    .hisTable{
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
    }
    .headerClass{
        font-size: 16px;
        color: white;
        background-color: #0d57bc ;
    }
    .trOne{
        font-weight:bold;
    }
    .syccxhzHeader{
        width: 100%;
        height: 90px;

        font-size: 22px;
        text-align: center;
    }
    .sycBottom{
        width: 100%;
        height: 70px;
        line-height: 70px;

        float: right;
        font-weight:bold;
    }
    .sycDan{
        width: 10%;
        height: 20px;

        float: right;
        font-size: 13px;
    }
</style>
