<!--
 * @Author: your name
 * @Date: 2021-07-23 09:01:08
 * @LastEditTime: 2021-07-23 13:28:01
 * @LastEditors: Please set LastEditors
 * @Description: 企业分析页面
 * @FilePath: /information-vue/code/src/views/workInformation/analysis/index.vue
-->

<template>
  <router-view v-if="$route.name == '企业分析详情'"/>
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="企业情报" text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新企业情报" :showTips="showTips" />-->
    <template>
      <div class="box">
        <div class="box_box">
          <div flex="sb">
            <div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部企业" name="" key="qbqy"></el-tab-pane>
                <el-tab-pane :label="item.dictLabel" :name="item.dictValue" v-for="item in analysistypeData"
                             :key="item.dictValue"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="search-input-box">
              <el-input placeholder="输入标题" size="small" v-model="title" @keyup.enter.native="searchForm">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="searchForm"></i>
              </el-input>
            </div>
          </div>
        </div>
        <div class="box_box_title" v-for="item in tableData" :key="item.id">
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
            </div>
            <div class="flex_col" flex="1">
              <div style="cursor: pointer" @click="toHref(item)">
                <div flex="c" style="margin: 10px 0">
                                           <span style="
                                      display: inline-block;
                                      color: #0D57BC;
                                      font-size: 14px;
                                      line-height: 16px;
                                      padding: 4px;
                                      background: rgba(13, 87, 188, 0.08);
                                      margin-right: 4px;
                                      "
                                                 v-if="item.contentCategory == 'yc'"
                                           >原创</span>
                  <p class="">{{ htmlRestore(item.title) }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div flex="sb" style="font-size: 14px">
                <a href="javascript:;" style="color: #0D57BC">{{
                    item.keywords
                  }}</a>
                <span>{{ item.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                       :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="pageData.total">
        </el-pagination>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {selectDictDataByType} from "@/api/dict.js";
import {AnalysisQueryByPage} from "@/api/enterpriseAnalysis.js";
import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      activeName: "",
      showTips: [
        {
          title: "企业情报动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "企业情报库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "企业情报梳理",
          text: "专家梳理整合，真正读懂企业情报脉络",
        },
        {
          title: "企业情报大数据",
          text: "用数据深度理解企业情报结构和趋势",
        },
      ],
      data1: [
        {
          id: 1,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大两会政",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 2,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
        {
          id: 3,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 4,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
      ],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
      mngEnterpriseType: [],
      catagery: "",
      title: "",
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10,
          sycFlag:'syc'
        },
        total: 0,
        currentPage: 0,
      },
      analysistypeData: [],
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "企业情报",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.selectDictDataByType({dictType: "mng_enterprise_type"});
    this.analysistype();
    this.AnalysisQueryByPage(this.pageData.pages);
    this.searchForm();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(item) {
      let newUrl = this.$router.resolve("/workInformation/syc/analysis/analysisContent/" + item.id);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //字典管理
    selectDictDataByType(params) {
      selectDictDataByType(params).then((res) => {
        if (res.ok) {
          this.mngEnterpriseType = res.data;
        }
      });
    },
    analysistype() {
      selectDictDataByType({dictType: "mng_ent_analysis_type"}).then(
          (res) => {
            if (res.ok) {
              this.analysistypeData = res.data;
            }
          }
      );
    },
    //企业分析
    AnalysisQueryByPage(parmas) {
      AnalysisQueryByPage(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.tableData.forEach(item => {
            item.publishDate = fmtDate(
                "YYYY-mm-dd",
                item.publishDate
            );
          })
          this.pageData.total = res.data.count;
        }
      });
    },
    searchForm() {
      let form = Object.assign(
          {catagery: this.catagery, title: this.title},
          this.pageData.pages
      );
      this.AnalysisQueryByPage(form);
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val;
      this.searchForm();
    },
    handleClick(tab) {
      let name = tab.name;
      this.catagery = name;
      this.pageData.pages.pageNo = 1;
      this.searchForm();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav {
  float: none;
  margin: 0 10px;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

// ::v-deep .el-input__inner {
//   border-radius: 50px;
// }
::v-deep .el-checkbox__input {
  display: none;
}

.box {
  //   padding: 0 10%;

  .box_box {
    padding: 10px;
    background: #ffffff;

    /deep/ .el-tabs__header {
      margin-bottom: 0;
    }
  }

  .box_box_title {
    margin-top: 10px;
    background: #ffffff;

    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }

    .flex_col {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.search-input-box {
  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
