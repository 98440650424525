<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 09:10:55
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-22 13:55:33
-->
<template>
  <div style="width:100%" :id="'pieEchart' + echartKey">
    <div class="pieEchart" :style="`height:${height}px`" ref="pieEchart"></div>
  </div>
</template>
<script>
// 引入echarts
import * as echarts from "echarts";
import { toThousands } from "@/utils/number";
//引入dom监听器
export default {
  name: "pieEchart",
  data() {
    return {
      myChart: null, //echartDom对象
      echartKey: Math.random(),
      // Eechart数据
      echartData: {},
    };
  },
  props: {
    // 高度
    height: {
      type: Number,
      default: 100,
    },
  },
  mounted() {},
  methods: {
    /**
     * @description: 初始化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-16 15:34:34
     * @LastEditTime: Do not edit
     */

    init(data) {
      this.echartData = data;
      this.initEchart();
      this.initLister();
    },
    /**
     * @description: 初始化监听器
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:18
     * @LastEditTime: Do not edit
     */
    initLister() {
      let elementResizeDetectorMaker = require("element-resize-detector");
      //监听元素变化
      let erd = elementResizeDetectorMaker();
      erd.listenTo(
        document.getElementById("pieEchart" + this.echartKey),
        (element) => {
          this.$nextTick(() => {
            //使echarts尺寸重置
            this.myChart.resize();
          });
        }
      );
    },
    /**
     * @description: 初始化echart
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-15 17:44:08
     * @LastEditTime: Do not edit
     */
    initEchart(name) {
      this.myChart = echarts.init(this.$refs.pieEchart);
      let option = {
        title: {
          text: name ? name : "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          appendToBody: true,
          formatter: (params) => {
            const { marker, name, value } = params;
            return `
              ${marker}<span style="margin-right: 10px">${name}地区</span>
              <span style='font-weight: bold;'>${toThousands(value)}辆</span>`;
          },
        },
        legend: {
          // type: "scroll",
          top: "5%",
          right: 0,
          icon: "circle",
        },
        series: [
          {
            top: "20%",
            type: "pie",
            radius: ["20%", "42%"],
            // avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outer",
              formatter: "{d|{d}%} \n {b|{b}地区}",
              rich: {
                d: {
                  color: "#333333",
                  fontSize: 18,
                  lineHeight: 24,
                  align: "center",
                },
                b: {
                  color: "#757575",
                  fontSize: 12,
                  lineHeight: 20,
                  align: "center",
                },
              },
              labelLine: {
                length: 40, //第一条线
                length2: 80, //第二条线
              },
            },
            data: this.echartData,
          },
        ],
      };
      this.myChart.setOption(option);
    },
    /**
     * @description: echart图片导出
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:30:11
     * @LastEditTime: Do not edit
     */
    exportSvg(name) {
      if (this.myChart) {
        // this.initEchart(name);
        const fileName = name ? name + ".png" : "图表.png";
        const base64 = this.myChart.getDataURL({
          type: "png",
          pixelRatio: 2,
          backgroundColor: "#FFFFFF",
        });
        this.downloadFileByBase64(base64, fileName);
      } else {
        this.$message.error("无法导出");
      }
      // this.initEchart();
    },
    /**
     * @description: 转Blob
     * @param {*} dataurl
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:36
     * @LastEditTime: Do not edit
     */

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = window.atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    /**
     * @description: 下载文件
     * @param {*} url
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:47
     * @LastEditTime: Do not edit
     */

    downloadFile(url, name) {
      var a = document.createElement("a"); // 新建一个a链接
      a.setAttribute("href", url); // a链接的url为图片的url
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      const clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    /**
     * @description: base64转化为文件
     * @param {*} base64
     * @param {*} name
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-25 15:37:53
     * @LastEditTime: Do not edit
     */

    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64);
      var myUrl = URL.createObjectURL(myBlob); // 创建图片的临时url
      this.downloadFile(myUrl, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.pieEchart {
  width: 100%;
  height: 100%;
}
</style>
