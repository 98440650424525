<template>
  <PageContainer breadcrumb footer>
    <Vcontent name="专题" :data="data" :props="props" is-content-two="ztfx" />

    <template slot="right">
      <Recommend
        title="相关推荐"
        type="list"
        :list="specialList"
        @click="tohref"
      />
<!--      <Recommend-->
<!--        title="行业资讯推荐"-->
<!--        type="list"-->
<!--        :list="industryInformationList"-->
<!--        @click="tohref"-->
<!--      />-->
<!--      <Recommend-->
<!--        title="分析报告推荐"-->
<!--        type="list"-->
<!--        :list="ttIndexAnalysisList"-->
<!--        @click="tohref"-->
<!--      />-->
      <ContentItem
        :title="tagText"
        url="/thematicAnalysis/index"
        type="list"
        :list="tableDataRight"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { page, ttSpecial } from '@/api/thematicAnalysis.js'
import { fmtDate } from '@/utils/date'
import Recommend from '@/components/recommends.vue'
import ContentItem from '@/components/contentItem.vue'

export default {
  components: {
    Recommend,
    ContentItem
  },
  data() {
    return {
      data: {},
      list: [],
      tableDataRight: [],
      tagText: '最新发布',
      props: {
        tag: 'keywords',
        summary: 'introduction'
      },
      industryInformationList: [],
      specialList: [],
      ttIndexAnalysisList: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        form: {
          contentCategory: ''
        }

      }
    }
  },
  watch: {
    $route(to, from) {
      const id = to.params.id
      this.ttSpecial({ id })
      // 对路由变化作出响应...
    }
  },
  created() {
    const id = this.$route.params.id
    this.ttSpecial({ id })
  },
  methods: {
    // 获取右侧列表
    pageRight() {
      const form = Object.assign({}, this.pageData.pages, this.pageData.form)

      page(form).then((res) => {
        this.tableDataRight = res.data.list.map((item) => {
          item.updateTime = fmtDate(
            'mm-dd',
            item.publishDate
          )
          return item
        })
      })
    },
    // moreClick(tab, event) {
    //
    //   this.$router.push("/thematicAnalysis/index");
    // },
    ttSpecial(params) {
      ttSpecial(params).then((res) => {
        if (res.ok) {
          this.data = res.data
          this.data.updateTime = this.data.publishDate
          this.pageData.form.contentCategory = res.data.contentCategory === 'yc'? ' ':'yc'
          if (res.data.contentCategory !== 'yc') {
            this.tagText = '原创推荐'
          } else if (res.data.contentCategory === 'yc') {
            this.tagText = '最新发布'
          }
          // this.industryInformationList = res.data.industryInformationList
          this.specialList = res.data.specialList
          // this.ttIndexAnalysisList = res.data.ttIndexAnalysisList
          this.pageRight()
        }
      })
    },
    // 跳转
    tohref(item) {
      if (item.tableName == 'tt_special') {
        this.$router.push('/thematicAnalysis/details/' + item.id)
      } else if (item.tableName == 'tt_industry_information') {
        this.$router.push({
          path: '/index/detail/IndustryDetail',
          query: {
            id: item.id,
            releaseColumn: item.releaseColumn,
            category: 'report'
          }
        })
      } else if (item.tableName == 'tt_index_analysis') {
        this.$router.push({
          path: '/index/detail/ReportDetail',
          query: {
            id: item.id,
            releaseColumn: item.releaseColumn,
            category: 'report'
          }
        })
      } else {
        const { id = '' } = this.$route.params;
        const path = this.$route.path.replace(new RegExp(`\\/${id}$`), '');
        this.$router.push(`${path}/${item.id}`);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
