<template>
  <div
    class="drag-target-area"
    :style="{ height: `${areaHeight}px` }"
  >
    <inside-drag-block
      v-for="item in areaBlockList"
      :key="item.field.name"
      :ref="item.field.name"
      :block="item"
      @dragging="handleInsideBlockDragging(item, $event)"
      @dragEnd="handleInsideBlockDragEnd(item, $event)"
      @remove="handleRemoveInsideBlock(item)"
    />
    <tip-block
      ref="tipBlockRef"
      :translate="tipBlockTranslate"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { AreaBlock } from './areaBlock'
import InsideDragBlock from './InsideDragBlock.vue'
import DraggingBlock from './DraggingBlock.vue'
import TipBlock from './TipBlock.vue'
import ResizeListener from 'element-resize-detector'
import { throttle } from 'throttle-debounce'

export default {
  name: 'DragTargetArea',
  components: {
    InsideDragBlock,
    TipBlock
  },
  props: {
    // 用于监听外部块的拖拽、结束拖拽等事件
    areaName: {
      type: [String, Array],
      required: true,
      validator(value) {
        if (value instanceof Array) {
          return Array.from(new Set(value)).length > 0
        }
        return value.length > 0
      }
    },
    // 仅用于标识不同的区域
    areaKey: {
      type: String,
      required: true,
      validator(value) {
        return value.length > 0
      }
    },
    areaBlockList: {
      type: Array,
      required: true
    }
  },
  data() {
    // 初始的区域高度
    const initAreaHeight = 32
    return {
      // 是否正在监听内部块作为外部块拖拽结束的事件
      isListeningInsideBlockAsOutsideBlockDragEndEvent: false,
      tipBlockTranslate: [0, 0],
      vm: null,
      draggingPosition: [0, 0],
      resizeListener: null,
      areaHeight: initAreaHeight,
      initAreaHeight,
      // areaBlockList 以 map 的形式保存的值
      areaBlockMap: {}
    }
  },
  computed: {
    // 当前区域是否支持向其他区域拖拽块
    supportDragBlockToOtherArea() {
      // areaName 必须是个数组，且里面的元素去重后，数量最少有两个
      return this.areaName instanceof Array && Array.from(new Set(this.areaName)).length > 1
    },
    getAreaNameStr() {
      const { areaName } = this
      return (areaName instanceof Array ? areaName.join('_') : areaName)
    },
    // 外部块拖拽事件的信道（用于处理把块从外向区域组件内部拖拽的功能场景）
    outsideBlockDraggingEventChannel() {
      return 'outsideBlockDragging_' + this.getAreaNameStr
    },
    // 外部块拖拽结束事件的信道（用于处理把块从外向区域组件内部拖拽的功能场景）
    outsideBlockDragEndEventChannel() {
      return 'outsideBlockDragEnd_' + this.getAreaNameStr
    },
    // 外部块点击事件的信道（用于处理把块从外向区域组件内部拖拽的功能场景）
    outsideBlockClickEventChannel() {
      return 'outsideBlockClick_' + this.getAreaNameStr
    },
    // 内部块作为外部块的拖拽事件的信道（该信道用于处理两个区域组件之间互相拖拽）
    insideBlockAsOutsideBlockDragEndEventChannel() {
      return this.getInsideBlockAsOutsideBlockDragEndEventChannel(this.areaKey)
    }
  },
  watch: {
    areaBlockList: {
      deep: true,
      handler() {
        this.$nextTick(this.handleAreaResize)
      }
    },
    // 如果信道改变了，需要重新监听
    outsideBlockDraggingEventChannel(newVal, oldVal) {
      this.$bus.$off(oldVal)
      // 监听外部块的拖拽事件
      this.$bus.$on(newVal, this.handleOutsideBlockDraggingEvent)
    },
    // 如果信道改变了，需要重新监听
    outsideBlockDragEndEventChannel(newVal, oldVal) {
      this.$bus.$off(oldVal)
      // 监听外部块的拖拽结束事件
      this.$bus.$on(newVal, this.handleOutsideBlockDragEndEvent)
    },
    // 如果信道改变了，需要重新监听
    outsideBlockClickEventChannel(newVal, oldVal) {
      this.$bus.$off(oldVal)
      // 监听外部块的点击事件
      this.$bus.$on(newVal, this.handleOutsideBlockClickEvent)
    }
  },
  mounted() {
    // 监听外部块的拖拽事件
    this.$bus.$on(this.outsideBlockDraggingEventChannel, this.handleOutsideBlockDraggingEvent)
    // 监听外部块的拖拽结束事件
    this.$bus.$on(this.outsideBlockDragEndEventChannel, this.handleOutsideBlockDragEndEvent)
    // 监听外部块的点击事件
    this.$bus.$on(this.outsideBlockClickEventChannel, this.handleOutsideBlockClickEvent)
    // 监听当前组件的尺寸变化
    this.resizeListener = ResizeListener({
      strategy: 'scroll',
      callOnAdd: false
    })
    // 加上节流，防止频繁调用
    // 每 0.05s 内最多只能触发一次自适应方法
    const resizeHandler = throttle(50, () => {
      this.handleAreaResize()
    })
    this.resizeListener.listenTo(this.$el, resizeHandler)
    resizeHandler()
  },
  beforeDestroy() {
    this.$bus.$off(this.outsideBlockDraggingEventChannel)
    this.$bus.$off(this.outsideBlockDragEndEventChannel)
    this.$bus.$off(this.outsideBlockClickEventChannel)
    this.resizeListener.removeAllListeners(this.$el)
  },
  methods: {
    // 获取指定 areaKey 的内部块作为外部块拖拽事件的信道
    getInsideBlockAsOutsideBlockDragEndEventChannel(areaKey) {
      return 'insideBlockAsOutsideBlockDragEnd_' + areaKey
    },
    // 添加一个新的正在排序的内部块
    appendASortingBlock(field) {
      // 如果内部块中还没有该外部块
      if (this.areaBlockList.every(item => item.field.name !== field.name)) {
        const newBlock = new AreaBlock(field, this.areaBlockList.length, true)
        // 则将该外部块以最后一个的排序，加入内部块，且状态为排序中
        this.$emit('update:areaBlockList', [...this.areaBlockList, newBlock])
        // 修改了块列表的长度
        this.$emit('blockListCountChange', { addedBlock: newBlock })
      }
    },
    // 按鼠标位置，对指定的且正在排序的内部块进行排序
    sortTheSortingBlockByMousePosition(mousePosition, field) {
      const { x: areaDomClientX, y: areaDomClientY } = this.$el.getBoundingClientRect()
      this.$nextTick(() => {
        // 找到正在排序的内部块
        const sortingInsideBlock = this.areaBlockList.find(item => item.isSorting)
        if (!sortingInsideBlock) {
          return
        }
        this.areaBlockList.find(item => {
          // 不对与当前块相同的内部块进行碰撞检测
          if (item.field.name === field.name) {
            return false
          }
          const { translate, blockDom } = this.areaBlockMap[item.sort]
          // 如果鼠标和某个内部块碰撞
          if (
            !item.isSorting &&
            mousePosition[0] <= areaDomClientX + translate[0] + blockDom.offsetWidth &&
            mousePosition[0] >= areaDomClientX + translate[0] &&
            mousePosition[1] <= areaDomClientY + translate[1] + blockDom.offsetHeight &&
            mousePosition[1] >= areaDomClientY + translate[1]
          ) {
            // 调整当前被拖动的内部块的排序
            this.changeBlockSort(sortingInsideBlock, item.sort)
            return true
          }
        })
      })
    },
    // 处理外部块拖拽事件
    handleOutsideBlockDraggingEvent({ mousePosition, field, fromAreaKey }) {
      // 不处理来自 自身所触发的 外部块拖拽事件
      if (fromAreaKey === this.areaKey) return
      const [mousePositionX, mousePositionY] = mousePosition
      // 如果内部块中已经有该外部块，且已经排好序了
      // 如果鼠标和当前组件碰撞了
      const { x: areaDomClientX, y: areaDomClientY } = this.$el.getBoundingClientRect()
      if (
        mousePositionX >= areaDomClientX + window.scrollX &&
        mousePositionY >= areaDomClientY + window.scrollY &&
        areaDomClientX + window.scrollX + this.$el.offsetWidth >= mousePositionX &&
        areaDomClientY + window.scrollY + this.$el.offsetHeight >= mousePositionY
      ) {
        // 添加新的内部块
        this.appendASortingBlock(field)
        // 按鼠标位置，对指定的且正在排序的内部块进行排序
        this.sortTheSortingBlockByMousePosition(mousePosition, field)
      } else { // 否则如果外部块没和当前组件碰撞
        // 找该外部块对应的正在排序中的块
        const sortingInsideBlock = this.areaBlockList.find(item => item.isSorting && item.field.name === field.name)
        // 如果没找到
        if (!sortingInsideBlock) {
          // 结束方法
          return
        }
        // 删除该外部块对应的正在排序中的块
        const shallowCopyBlockList = this.areaBlockList.map(item => {
          return { ...item }
        })
        const newBlockList = shallowCopyBlockList.filter(item => {
          // 如果该块的排序在被删除的块的后面
          if (item.sort > sortingInsideBlock.sort) {
            // 则将该块的排序向前 1 位
            item.sort--
          }
          // if (item.field.name !== field.name) {
          //   return true
          // }
          return item.field.name !== field.name || !item.isSorting
        })
        this.$emit('update:areaBlockList', newBlockList)
        // 修改了块列表的长度
        this.$emit('blockListCountChange', { removedBlock: sortingInsideBlock })
      }
    },
    // 处理外部块拖拽结束事件
    handleOutsideBlockDragEndEvent({ field, fromAreaKey }) {
      // 从当前块列表中找该外部块
      const dragEndBlock = this.areaBlockList.find(item => item.field.name === field.name)
      // 如果能找到，说明该外部块已放入当前区域
      if (dragEndBlock) {
        this.$set(dragEndBlock, 'isSorting', false)
        // 如果该块是从其他区域来的
        if (fromAreaKey && fromAreaKey !== this.areaKey) {
          // 则需要通知其他区域：该块已成功放入当前区域
          this.$bus.$emit(
            this.getInsideBlockAsOutsideBlockDragEndEventChannel(fromAreaKey),
            {
              field,
              isReachedOtherTargetArea: true
            }
          )
        }
      } else { // 如果未找到，说明该外部块没有成功放入当前区域
        // 如果该块是从其他区域来的
        if (fromAreaKey && fromAreaKey !== this.areaKey) {
          // 则告诉这个 正在作为外部块的内部块 之前所在的区域：该块未成功放入当前区域
          this.$bus.$emit(this.getInsideBlockAsOutsideBlockDragEndEventChannel(fromAreaKey),
            {
              field,
              isReachedOtherTargetArea: false
            }
          )
        }
      }
    },
    // 处理外部块点击事件
    handleOutsideBlockClickEvent({ field, preferredTargetAreaKeyWhenClick }) {
      // 如果该块被点击时，不是优先添加到当前区域内的，则结束
      if (preferredTargetAreaKeyWhenClick && preferredTargetAreaKeyWhenClick !== this.areaKey) return
      // 如果当前区域中已经有该块了，则结束
      if (this.areaBlockList.some(item => item.field.name === field.name)) return
      // 否则追加一个内部块
      const newBlock = new AreaBlock(field, this.areaBlockList.length, false)
      this.$emit('update:areaBlockList', [...this.areaBlockList, newBlock])
      // 修改了块列表的长度
      this.$emit('blockListCountChange', { addedBlock: newBlock })
    },
    changeBlockSort(block, newSort) {
      if (!block || newSort == null) {
        return
      }
      // 获取该块当前的排序号
      const oldSort = block.sort
      // 如果排序号没变, 则结束方法
      if (oldSort === newSort) {
        return
      }
      // 如果要将该块向前移动
      if (newSort < oldSort) {
        // 所有小于该排序号, 且大于等于新序号的块的排序都要加一
        const newBlockList = this.areaBlockList.map(item => {
          const { field, sort, isSorting } = item
          if (sort < oldSort && sort >= newSort) {
            return new AreaBlock(field, sort + 1, isSorting)
          } else if (sort === oldSort) {
            return new AreaBlock(field, newSort, isSorting)
          } else {
            return item
          }
        })
        this.$emit('update:areaBlockList', newBlockList)
      } else { // 如果要将该块向后移动
        // 所有大于该排序号, 且小于等于新序号的块的排序都要减一
        const newBlockList = this.areaBlockList.map(item => {
          const { field, sort, isSorting } = item
          if (sort > oldSort && sort <= newSort) {
            return new AreaBlock(field, sort - 1, isSorting)
          } else if (sort === oldSort) {
            return new AreaBlock(field, newSort, isSorting)
          } else {
            return item
          }
        })
        this.$emit('update:areaBlockList', newBlockList)
      }
    },
    // 处理内部块的拖动
    handleInsideBlockDragging(block, { mousePosition, draggingPosition }) {
      /**
        关于 A区域和B区域的内部块可以互相拖拽 的实现逻辑说明如下：
          前提限制条件：
            1. A区域 和 B区域 的 areaName 必须是数组，比如 [A区域的areaName, B区域的areaName]
            2. areaName 数组去重后，至少是有 2 个元素
            2. A区域的 areaName(即getAreaNameStr) 必须和 B区域的 areaName(即getAreaNameStr) 一致
          大致逻辑：
            1. A区域 的内部块拖动时，如果判断到该内部块已脱离 A区域，则 A区域 会持续触发 外部块拖拽 的事件，
               同时 A区域 需要开启一个监听(记作 监听X)，用来监听该内部块是否已完成拖拽
            2. B区域 通过 外部块拖拽事件 的监听(该监听一开始就在每个区域里开启了)，来处理来自 A区域 的内部块所触发的外部块拖拽事件
            3. 如果 A区域 的该内部块停止拖动了，则会触发 内部块作为外部块拖拽结束 事件
            4. B区域 监听到了 A区域 所触发的 内部块作为外部块拖拽结束 事件，如果 B区域中存在该外部块，
               则触发 监听X 以通知 A区域：该块已成功放入 B区域
               如果 B区域 中没有该外部块，则触发 监听X 以通知 A区域：该块没有放入 B区域
            5. A区域的 监听X 被触发后，如果事件的传值表示该块成功放入了 B区域，则 A区域 会将该块移除，如果事件的传值表示该块没有放
               入 B区域，则 A区域 什么都不用做，最后 A区域 要取消 监听X
      */
      // 如果还没创建可移动的块
      if (!this.vm) {
        // 创建一个跟随鼠标拖动的内部块
        this.createInsideMovingBlock(block)
        this.$set(block, 'isSorting', true)
      }
      this.$set(this, 'draggingPosition', draggingPosition)
      const { x: areaDomClientX, y: areaDomClientY } = this.$el.getBoundingClientRect()
      const { offsetWidth, offsetHeight } = this.$el
      // 如果当前区域支持向其他区域拖拽块
      // 并且当鼠标移动的位置已经脱离了当前拖拽区域(即与拖拽区域没有碰撞)
      if (
        this.supportDragBlockToOtherArea &&
        (
          mousePosition[0] > areaDomClientX + window.scrollX + offsetWidth ||
          mousePosition[0] < areaDomClientX + window.scrollX ||
          mousePosition[1] > areaDomClientY + window.scrollY + offsetHeight ||
          mousePosition[1] < areaDomClientY + window.scrollY
        )
      ) {
        // 监听内部块作为外部块 拖拽结束的事件
        if (!this.isListeningInsideBlockAsOutsideBlockDragEndEvent) {
          // 标识已开启监听，防止开启多个监听
          this.isListeningInsideBlockAsOutsideBlockDragEndEvent = true
          // 监听该内部块作为外部块 拖拽结束的事件
          this.$bus.$on(this.insideBlockAsOutsideBlockDragEndEventChannel, ({ field, isReachedOtherTargetArea }) => {
            // 如果该内部块已经放入了其他区域
            if (isReachedOtherTargetArea) {
              // 将该内部块从当前区域移除
              this.handleRemoveInsideBlock({ field })
            } // 否则什么都不用做，该内部块将仍然保持在当前区域内部
            // 取消监听
            this.$bus.$off(this.insideBlockAsOutsideBlockDragEndEventChannel)
            this.isListeningInsideBlockAsOutsideBlockDragEndEvent = false
          })
        }
        // 将该内部块当成是外部块，来触发块的拖拽事件
        this.$bus.$emit(this.outsideBlockDraggingEventChannel, {
          // 鼠标的坐标
          mousePosition,
          // // vm 的宽高 没有用了，注释掉
          // outsideMovingBlockSize: [this.vm.$el.offsetWidth, this.vm.$el.offsetHeight],
          // // 左上角的坐标 没有用了，注释掉
          // draggingPosition: this.draggingPosition,
          field: block.field,
          // 防止触发之后，区域自身又进行处理（因为这里触发的外部块拖动事件 是为了让其他区域监听并处理的）
          fromAreaKey: this.areaKey
        })
        return
      }
      // 找要被 交换排序值 的内部块（即其他正在静止中的内部块与正在移动的这个块发生了碰撞）
      this.areaBlockList.find(item => {
        const { translate, blockDom } = this.areaBlockMap[item.sort]
        // 鼠标是否和某个已完成排序排序，且非该被拖动的内部块的其他内部块碰撞
        if (
          !item.isSorting &&
          item.field.name !== block.field.name &&
          mousePosition[0] <= areaDomClientX + window.scrollX + translate[0] + blockDom.offsetWidth &&
          mousePosition[0] >= areaDomClientX + window.scrollX + translate[0] &&
          mousePosition[1] <= areaDomClientY + window.scrollY + translate[1] + blockDom.offsetHeight &&
          mousePosition[1] >= areaDomClientY + window.scrollY + translate[1]
        ) {
          // 调整当前被拖动的内部块的排序
          this.changeBlockSort(block, item.sort)
          return true
        }
      })
    },
    // 处理内部块结束拖动
    handleInsideBlockDragEnd(block, { mousePosition }) {
      if (!this.vm) return
      // 如果创建了可移动的块
      this.vm.remove()
      this.vm = null
      this.$set(block, 'isSorting', false)
      // 如果当前区域支持向其他区域拖拽块
      if (this.supportDragBlockToOtherArea) {
        const { x: areaDomClientX, y: areaDomClientY } = this.$el.getBoundingClientRect()
        const { offsetWidth, offsetHeight } = this.$el
        // 如果鼠标的位置已经脱离了当前拖拽区域(即与拖拽区域没有碰撞)
        if (
          mousePosition[0] > areaDomClientX + window.scrollX + offsetWidth ||
          mousePosition[0] < areaDomClientX + window.scrollX ||
          mousePosition[1] > areaDomClientY + window.scrollY + offsetHeight ||
          mousePosition[1] < areaDomClientY + window.scrollY
        ) {
          // 则将该内部块作为外部块，触发拖拽结束事件
          this.$bus.$emit(this.outsideBlockDragEndEventChannel, {
            field: block.field,
            fromAreaKey: this.areaKey
          })
        }
      }
    },
    // 处理内部块的删除事件
    handleRemoveInsideBlock(block) {
      // 用于找到这个将要被删除的块的排序号
      let toRemoveBlockSort = -1
      const shallowCopyBlockList = this.areaBlockList.map(item => {
        if (item.field.name === block.field.name) {
          toRemoveBlockSort = item.sort
        }
        return { ...item }
      })
      // 如果没找到这个将要被删除的块的排序号，则结束
      if (toRemoveBlockSort < 0) return
      const newBlockList = shallowCopyBlockList.filter(item => {
        // 如果该块的排序在被删除的块的后面
        if (item.sort > toRemoveBlockSort) {
          // 则将该块的排序向前 1 位
          item.sort--
        }
        return item.field.name != block.field.name
      })
      this.$emit('update:areaBlockList', newBlockList)
      // 修改了块列表的长度
      this.$emit('blockListCountChange', { removedBlock: block })
    },
    // 处理当前组件的尺寸或者块列表发生变化后的自适应
    handleAreaResize() {
      const newBlockMap = {}
      this.areaBlockList.forEach((item) => {
        newBlockMap[item.sort] = {
          block: item,
          blockDom: this.$refs[item.field.name][0].$el,
          translate: undefined
        }
      })
      this.$set(this, 'areaBlockMap', newBlockMap)
      const { areaBlockMap } = this
      // 获取容器的宽度
      const areaWidth = this.$el.offsetWidth
      // 按照排序，计算每个 areaBlock 的位置
      for (let i = 0; i < this.areaBlockList.length; i++) {
        const { blockDom } = areaBlockMap[i]
        // 如果是第一个元素
        if (i === 0) {
          areaBlockMap[i].translate = [0, 0]
        } else { // 否则如果不是第一个元素
          // 获取上一个元素 dom 和 位置
          const { blockDom: previousBlockDom, translate: previousBlockDomTranslate } = areaBlockMap[i - 1]
          const curBlockNewTranslate = [
            previousBlockDomTranslate[0] + previousBlockDom.offsetWidth + 10,
            previousBlockDomTranslate[1]
          ]
          // 如果计算出的本元素X轴的位置加上本元素的宽度，超过了容器的宽度
          if (curBlockNewTranslate[0] + blockDom.offsetWidth > areaWidth) {
            curBlockNewTranslate[0] = 0
            curBlockNewTranslate[1] = previousBlockDomTranslate[1] + blockDom.offsetHeight + 10
          }
          areaBlockMap[i].translate = curBlockNewTranslate
        }
        const [translateX, translateY] = areaBlockMap[i].translate
        blockDom.setAttribute('style', `transform: translate(${translateX}px, ${translateY}px)`)
      }
      // 如果只有一个内部块
      if (this.areaBlockList.length === 0) {
        // 提示块位置在最开始
        this.$set(this, 'tipBlockTranslate', [0, 0])
        // 区域使用初始高度
        this.areaHeight = this.initAreaHeight
        return
      }
      // 获取最后一个内部块
      const lastBlock = areaBlockMap[this.areaBlockList.length - 1]
      // 计算最后一个提示块的位置
      // 如果提示块X轴位置加上提示块的宽度 超过了容器的宽度
      if (lastBlock.translate[0] + lastBlock.blockDom.offsetWidth + 10 + this.$refs.tipBlockRef.$el.offsetWidth > areaWidth) {
        // 则提示块的位置在下一行最开始
        this.$set(this, 'tipBlockTranslate', [0, lastBlock.translate[1] + lastBlock.blockDom.offsetHeight + 10])
      } else { // 否则提示块的位置在最后一个元素的右边
        this.$set(this, 'tipBlockTranslate', [
          lastBlock.translate[0] + lastBlock.blockDom.offsetWidth + 10,
          lastBlock.translate[1]
        ])
      }
      // 计算当前可拖拽区域的高度
      this.areaHeight = this.tipBlockTranslate[1] + this.$refs.tipBlockRef.$el.offsetHeight
    },
    // 创建一个跟随鼠标拖动的内部块
    createInsideMovingBlock(block) {
      if (this.vm) return
      this.vm = new Vue({
        // h是createElement函数，它可以返回虚拟dom
        render: (h) => {
          // 将Component作为根组件渲染出来
          // h(标签名称或组件配置对象，传递属性、事件等，孩子元素)
          return h(DraggingBlock, {
            props: {
              field: block.field,
              // 用于实时修改 DraggingBlock 的位置
              draggingPosition: this.draggingPosition
            }
          })
        }
      }).$mount() // 挂载是为了把虚拟dom变成真实dom
      this.vm.remove = () => {
        document.body.removeChild(this.vm.$el)
        this.vm.$destroy()
      }
      document.body.appendChild(this.vm.$el)
    },
    // 移除块名列表中对应的所有块（供父组件调用）
    removeBlockByFieldNames(fieldNamesToRemove) {
      if (!fieldNamesToRemove || fieldNamesToRemove.length === 0) return
      let newBlockList = this.areaBlockList.map(item => {
        return { ...item }
      })
      // // 用于保存将要被移除的块
      // const removedBlocks = []
      // 遍历将要被删除的块名列表
      fieldNamesToRemove.forEach(fieldName => {
        const blockToRemove = this.areaBlockList.find(item => item.field.name === fieldName)
        if (!blockToRemove) return
        // removedBlocks.push(blockToRemove)
        newBlockList = newBlockList.filter(item => {
          // 如果该块的排序在被删除的块的后面
          if (item.sort > blockToRemove.sort) {
            // 则将该块的排序向前 1 位
            item.sort--
          }
          return item.field.name != fieldName
        })
      })
      this.$emit('update:areaBlockList', newBlockList)
      // 修改了块列表的长度
      // this.$emit('blockListCountChange', { removedBlocks }) // 这里目前不需要再次触发 blockListCountChange
    },
    // 添加指定名称的固定块（供父组件调用，固定是指不可删除且不可拖到其他区域）
    addFixedBlockByFieldNames(fieldNamesToAdd) {
      if (!fieldNamesToAdd || fieldNamesToAdd.length === 0) return
      const currentAreaBlockMap = {}
      this.areaBlockList.forEach(item => {
        currentAreaBlockMap[item.field.name] = true
      })
      const newBlockList = []
      fieldNamesToAdd.forEach((name, index) => {
        // 如果当前块列表里没有该名称
        if (currentAreaBlockMap[name]) return
        newBlockList.push(
          new AreaBlock(
            {
              name,
              noClose: true
            },
            this.areaBlockList.length + index
          )
        )
      })
      this.$emit('update:areaBlockList', [...this.areaBlockList, ...newBlockList])
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-target-area {
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: height .3s;
}
</style>
