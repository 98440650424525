// 政策栏目
import $http from '@/plugins/http.js'

// 政策树对应政策内容
export function getPolicyContent(params) {
  return $http.get('/api/customerPolicy/getPolicyContent', params)
}
// 本网原创
export function localOriginal(params) {
  return $http.get('/api/customerPolicy/localOriginal', params)
}
// 最新政策
export function newPolicy(params) {
  return $http.get('/api/customerPolicy/newPolicy', params)
}
// 最新政策详情
export function ttPolicy(params) {
  return $http.get('/api/policy/ttPolicy/' + params)
}
// 政策大数据
export function policyBigData(params) {
  return $http.get('/api/customerPolicy/policyBigData', params)
}
// 政策解读
export function policyExplain(params) {
  return $http.get('/api/customerPolicy/policyExplain', params)
}
// 本网原创，政策解读详情
export function ttPolicyInterpret(params) {
  return $http.get('/api/policy/ttPolicyInterpret/' + params)
}
// 政策报道
export function policyReport(params) {
  return $http.get('/api/customerPolicy/policyReport', params)
}
// 政策报道详情
export function ttPolicyReport(params) {
  return $http.get('/api/policy/ttPolicyReport/' + params)
}
// 政策梳理政策树
export function policyTree() {
  return $http.get('/api/customerPolicy/policyTree')
}
// 政策库查询
export function selectPolicy(params) {
  return $http.get('/api/customerPolicy/selectPolicy', params)
}

export function selectPolicyGjh(params) {
  return $http.get('/api/customerPolicy/selectPolicyGjh', params)
}

// 商用车政策查询
export function getPolicySyc(params) {
  return $http.get('/api/policy/ttPolicy/getPolicySyc', params)
}

// 政策树对应政策内容详情
export function getPolicyContentDetail(params) {
  return $http.get('/api/customerPolicy/getPolicyContentDetail', params)
}
// 政策大数据导出
export function policyDataExport(params) {
  return $http.DownloadDb('/api/customerPolicy/policyDataExport', params)
}

