<!--最新标准列表-->
<template>
  <router-view v-if="$route.name == '最新标准内容'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle bigTtitle="最新标准" smallTip="会员专享精华内容推荐" :bgurl="bgurl" />
    <Vlist :list="queryNewStandardData" :page="queryNewStandardPage" :pageData="queryNewStandardPage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
  </PageContainer>
</template>
<script>
import VlistTitle from "@/components/listTitle.vue";
import { queryNewStandard } from "@/api/Index.js";
import Vlist from "@/components/list";
import {getUserInfo,logRecords} from "@/utils/auth";
import {accessRecords} from "@/api/common";
export default {
    components: {
        VlistTitle,
        Vlist,
    },
    data() {
        return {
            bgurl: require("@/assets/img/bg-1.jpg"),
            queryNewStandardData: [],
            queryNewStandardPage: {
                pages: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                currentPage: 0,
            },

        };
    },
    created() {
        this.queryNewStandard(this.queryNewStandardPage.pages);
    },
    methods: {
        queryNewStandard(params) {
            queryNewStandard(params).then((res) => {
                if (res.ok) {
                    this.queryNewStandardData = res.data.list;
                    this.queryNewStandardPage.total = res.data.count;
                }
            });
        },
        handleSizeChange(v) {
            this.queryNewStandardPage.pages.pageSize = v;
            this.queryNewStandard(this.queryNewStandardPage.pages);
        },
        handleCurrentChange(v) {
            this.queryNewStandardPage.pages.page = v;
            this.queryNewStandard(this.queryNewStandardPage.pages);
        },
        toHref(item) {
          logRecords('首页','标准动态','1','tt_first_standard',item.id,'',item.title,'')

          let newUrl = this.$router.resolve("/index/detail/newStandardDetail/" + item.id);
             window.open(newUrl.href, "_blank");
        },
    },
};
</script>
