<template>
  <div class="data-range-container">
    <el-radio-group
      v-if="showRadioGroup"
      v-show="!disableToggleMode && toggleDatetimeMode !== 3"
      :value="currentDatetimeMode"
      size="small"
      @input="handleChangeDatetimeMode"
    >
      <el-radio-button label="年" />
      <el-radio-button label="月" />
    </el-radio-group>
    <template v-if="showDataPicker">
      <el-date-picker
        v-model="value[0]"
        :type="toggleDatetimeMode === 3 ? datePickerType : datePickerType"
        :format="getValueFormat"
        :value-format="getValueFormat"
        size="small"
        :clearable="false"
        :editable="false"
        placeholder="请选择"
        append-to-body
        :picker-options="pickerOptions"
        @focus="handleDatePickerFocus"
        @change="handleDateChange"
      />
      <span class="split">至</span>
      <el-date-picker
        v-model="value[1]"
        :type="toggleDatetimeMode === 3 ? datePickerType : datePickerType"
        :format="getValueFormat"
        :value-format="getValueFormat"
        size="small"
        :clearable="false"
        :editable="false"
        placeholder="请选择"
        append-to-body
        :picker-options="pickerOptions"
        @focus="handleDatePickerFocus"
        @change="handleDateChange"
      />
    </template>
  </div>
</template>

<script>
import { getUserInfo, openSy } from '@/utils/auth.js'
/**
有个时间范围，控制禁用的，

年月切换的时候，要根据年月自动格式化当前的value

value 不能超过范围
*/
export default {
  name: 'DateRange',
  props: {
    value: {
      type: Array,
      required: true
    },
    // 0 只能用年、1 只能用月、2 年月都能切换 3 动态日期
    toggleDatetimeMode: {
      type: Number,
      required: true,
      validator(value) {
        return [0, 1, 2, 3].indexOf(value) !== -1
      }
    },
    currentDatetimeMode: {
      type: String,
      required: true,
      validator(value) {
        return ['年', '月'].indexOf(value) !== -1
      }
    },
    datetimeSelectRange: {
      type: Array,
      required: true
    },
    formattedDatetimeSelectRange: {
      type: Array,
      required: true
    },
    // 选择的指标字段
    targetList: {
      type: Array,
      required: true
    },
  },
  mounted(){
    
  },
  data() {
    return {
      // 当用户为试用用户时，切换年份要弹窗提示，且不可切换过去，且样式必须保持正常，所以用该变量来重新渲染组件，以保证组件样式正常
      showRadioGroup: true,
      // 当用户为试用用户时，修改日期要弹窗提示，且不可修改成功，所以用该变量来重新渲染组件，以保证组件样式正常
      showDataPicker: true,
      dateType: 4
    }
  },
  computed: {
    getValueFormat() {
      if (this.datePickerType == 'year'){
        return 'yyyy'
      }else if (this.datePickerType == 'month'){
        return 'yyyy-MM'
      } else if (this.datePickerType == 'date'){
        return 'yyyy-MM-dd'
      } else {
        return this.currentDatetimeMode === '年' ? 'yyyy' : 'yyyy-MM'
      }
    },
    disableToggleMode() {
      return this.toggleDatetimeMode === 0 || this.toggleDatetimeMode === 1
    },
    // 是否是动态选择日期
    isTrends() {
      return this.toggleDatetimeMode === 3
    },
    datePickerType() {
      if (this.toggleDatetimeMode === 3) {
        let maxPriority = -1
        const priorityMap = {  
            '1': 3,
            '2': 2,  
            '3': 1,
            '4': 0
        }
        this.targetList.forEach(item => {
            const currentPriority = priorityMap[(item.field.dateType + '')]
            if (currentPriority > maxPriority) {  
                this.dateType = item.field.dateType;  
                maxPriority = currentPriority;  
            }  
        })
        let str = ''
        
        if (this.dateType == 4){
          str = 'year'
        }
        if (this.dateType == 2 || this.dateType == 3){
          str = 'month'
        }
        if (this.dateType == 1){
          str = 'date'
        }
        return str
      } else {
        return this.currentDatetimeMode === '年' ? 'year' : 'month'
      }
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          if (!this.datetimeSelectRange) {
            return
          }
          // 动态日期可以选择的月份数组
          let allowedMonths = [3, 6, 9, 12]
          let currentYear = time.getFullYear();
          let currentMonth = time.getMonth() + 1

          const [earliestDatetimeRange, latestDatetimeRange] = this.formattedDatetimeSelectRange
          let earliestDatetime = new Date(`${earliestDatetimeRange} 00:00:00`)
          let latestDatetime = new Date(`${latestDatetimeRange} 00:00:00`)
          let realTime = null
          if (this.currentDatetimeMode === '年') {
            realTime = new Date(`${time.getFullYear()} 00:00:00`)
            earliestDatetime = new Date(`${earliestDatetimeRange.substr(0, 4)} 00:00:00`)
            latestDatetime = new Date(`${latestDatetimeRange.substr(0, 4)} 00:00:00`)
          }
          realTime = new Date(`${time.getFullYear()}-${time.getMonth() + 1} 00:00:00`)
          // 如果是动态日期是季度的话
          if (this.toggleDatetimeMode === 3 && this.dateType == 3){
            return realTime < new Date(`${this.formattedDatetimeSelectRange[0]} 00:00:00`) || realTime >  new Date(`${this.formattedDatetimeSelectRange[1]} 00:00:00`)  || allowedMonths.every(month => month !== currentMonth)
          }else if (this.toggleDatetimeMode === 3 && (this.dateType == 1 || this.dateType == 2)){
            return time < new Date(`${this.formattedDatetimeSelectRange[0]} 00:00:00`) || time > new Date(`${this.formattedDatetimeSelectRange[1]} 00:00:00`)
          }else {
            return realTime < earliestDatetime || realTime > latestDatetime
          }
        }
      }
    }
  },
  watch: {
    // 监听指标字段的变化
    targetList: {
      handler() {
        // isTrends是否是动态日期
        if (this.isTrends){
          let maxPriority = -1
          const priorityMap = {  
              '1': 3,
              '2': 2,  
              '3': 1,
              '4': 0
          }
          this.targetList.forEach(item => {
              const currentPriority = priorityMap[(item.field.dateType + '')]
              if (currentPriority > maxPriority) {
                  this.dateType = item.field.dateType;  
                  maxPriority = currentPriority;  
              }  
          })
          //动态日期的回调
          this.$emit('changeTimes', this.dateType)
        }
      }
    },
    // 监听当前的日期选择模式
    currentDatetimeMode: {
      // immediate: true,
      handler() {
        // 重新生成 value
        this.$nextTick(() => {
          if (this.value.length === 0 || this.datetimeSelectRange.length === 0) {
            return
          }
          const dateNow = new Date()
          const curYear = dateNow.getFullYear()
          const newValue = []
          newValue[0] = this.value[0]?.substr(0, 4) || this.datetimeSelectRange[1]?.substr(0, 4) || curYear
          newValue[1] = this.value[1]?.substr(0, 4) || this.datetimeSelectRange[1]?.substr(0, 4) || curYear
          // 如果模式为 月
          if (this.currentDatetimeMode === '月') {
            const curMonth = dateNow.getMonth() + 1
            const allCurMonth = curMonth < 10 ? `0${curMonth}` : curMonth
            newValue[0] += '-' + (this.value[0]?.substr(5, 2) || this.datetimeSelectRange[1]?.substr(4, 2) || allCurMonth)
            newValue[1] += '-' + (this.value[1]?.substr(5, 2) || this.datetimeSelectRange[1]?.substr(4, 2) || allCurMonth)
          }
          // 开始限定范围
          if (this.datetimeSelectRange.length === 2) {
            const [earliestDatetimeRange, latestDatetimeRange] = this.formattedDatetimeSelectRange
            const earliestDatetime = new Date(`${earliestDatetimeRange} 00:00:00`)
            const latestDatetime = new Date(`${latestDatetimeRange} 00:00:00`)
            const startDatetime = new Date(`${newValue[0]} 00:00:00`)
            const endDatetime = new Date(`${newValue[1]} 00:00:00`)
            // 如果当前的时间的值，超过了范围，则将当前时间的值改为范围的边界值
            if (startDatetime < earliestDatetime) {
              newValue[0] = this.currentDatetimeMode === '月' ? earliestDatetimeRange : earliestDatetimeRange.substr(0, 4)
            } else if (startDatetime > latestDatetime) {
              newValue[0] = this.currentDatetimeMode === '月' ? latestDatetimeRange : latestDatetimeRange.substr(0, 4)
            }
            if (endDatetime < earliestDatetime) {
              newValue[1] = this.currentDatetimeMode === '月' ? earliestDatetimeRange : earliestDatetimeRange.substr(0, 4)
            } else if (endDatetime > latestDatetime) {
              newValue[1] = this.currentDatetimeMode === '月' ? latestDatetimeRange : latestDatetimeRange.substr(0, 4)
            }
          }
          this.$emit('input', newValue)
        })
      }
    }
  },
  methods: {
    // 切换年月模式的回调
    handleChangeDatetimeMode(val) {
      const userInfo = getUserInfo()
      if (userInfo && userInfo.roleKey == 'trialrole' && !userInfo.menuName.includes('国际化')) { // 如果是试用用户
        // 弹窗 【提示】此操作需要正式开通该栏目后操作
        openSy()
        // 埋点 弹窗提示
        this.$emit('buryingPoint')
        this.showRadioGroup = false
        this.$nextTick(() => {
          // 重新渲染组件，防止样式异常
          this.showRadioGroup = true
        })
        return
      }
      this.$emit('update:currentDatetimeMode', val)
    },
    handleDatePickerFocus() {
      const userInfo = getUserInfo()
      if (userInfo && userInfo.roleKey == 'trialrole' && !userInfo.menuName.includes('国际化')) { // 如果是试用用户
        // 弹窗 【提示】此操作需要正式开通该栏目后操作
        openSy()
        // 埋点 弹窗提示
        this.$emit('buryingPoint')
        this.showDataPicker = false
        this.$nextTick(() => {
          // 重新渲染组件，防止样式异常
          this.showDataPicker = true
        })
      }
    },
    // 日期选择的回调
    handleDateChange(val) {
      const [startDate, endDate] = this.value
      console.log(this.value, this.datePickerType)
      if (new Date(`${startDate} 00:00:00`) > new Date(`${endDate} 00:00:00`)) {
        this.$emit('input', [endDate, startDate])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-range-container {
  display: flex;
  align-items: flex-start;
}

.split {
  line-height: 32px;
  padding: 0 5px;
}

::v-deep {
  .el-radio-group {
    margin-right: 20px;
  }

  .el-radio-button {
    .el-radio-button__inner:hover {
      color: #0D57BC;
    }

    &.is-disabled {
      .el-radio-button__inner:hover {
        color: #C0C4CC;
      }
    }
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border-color: #0D57BC;
    background-color: #0D57BC;
    box-shadow: -1px 0 0 0 #0D57BC;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .el-date-editor {
    width: 93px;

    .el-input__inner {
      padding: 0 0 0 12px;
    }
  }

  .el-input__prefix {
    display: none;
  }
}
</style>
