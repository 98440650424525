import clsx from 'clsx';

import { getRootScrollTop, getScrollTop } from '../IndexList/utils';

export default {
  name: 'IndexAnchor',
  props: {
    index: [Number, String],
  },
  data() {
    return {
      top: 0,
      left: null,
      rect: { top: 0, height: 0 },
      width: null,
      active: false,
    };
  },
  computed: {
    rootTarget() {
      return this.$parent.$refs.wrap;
    },
  },
  mounted() {
    const rect = this.$el.getBoundingClientRect();
    this.rect.height = rect.height;
  },
  methods: {
    getRect(scroller, scrollerRect) {
      const el = this.$el;
      const elRect = el.getBoundingClientRect();
      this.rect.height = elRect.height;

      if (scroller === window || scroller === document.body) {
        this.rect.top = elRect.top + getRootScrollTop(this.rootTarget);
      } else {
        this.rect.top = elRect.top + getScrollTop(scroller) - scrollerRect.top;
      }

      return this.rect;
    },
  },
  render() {
    return (
      <div class={clsx('index-list-anchor', { active: this.active })}>
        {this.$slots.default || this.index}
      </div>
    );
  },
};
