var render = function render(){var _vm=this,_c=_vm._self._c;return (
      _vm.$route.name == '选装内容' ||
      _vm.$route.name == '公告查询详情' ||
      _vm.$route.name == '整车比对' ||
      _vm.$route.name == '底盘内容' ||
      _vm.$route.name == '摩托车内容' ||
      _vm.$route.name == '三轮车内容' ||
      _vm.$route.name == '历史'
    )?_c('router-view'):_c('PageContainer',[[_c('div',{staticClass:"search-form"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"整车","name":"整车"}},[(_vm.activeName == '整车')?_c('Vehicle',{attrs:{"category":_vm.activeValue}}):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"底盘","name":"底盘"}},[(_vm.activeName == '底盘')?_c('Chassis'):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"摩托车","name":"摩托车"}},[(_vm.activeName == '摩托车')?_c('Motorcycles',{attrs:{"category":_vm.activeValue}}):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"三轮汽车","name":"三轮汽车"}},[(_vm.activeName == '三轮汽车')?_c('ThirdWheel',{attrs:{"category":_vm.activeValue}}):_vm._e()],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }