<template>
  <router-view v-if="$route.name == '专题报道内容页'" />
  <PageContainer v-else>
    <template slot="actionTitle">
      <div class="special-list-tops">
        <h1>全部专题</h1>
        <p>共 <span class="blueNumber"> {{ allCount.count }} </span> 个专题，<span class="blueNumber"> {{ allCount.articleCount
        }} </span>篇文章</p>
      </div>
    </template>
    <div class="special-list-content">
      <div class="nav" ref="scrollbarRef">
        <specialSwiper :allCount="allCount.count" />
      </div>
      <speciallist :list="list" />
    </div>
  </PageContainer>
</template>
<script>
import { ttIndexSpecialPage, selectCount } from "@/api/Index.js";
import speciallist from '../Modules/SpecialLists';
import specialSwiper from '../Modules/specialSwiper';
export default {
  components: {
    speciallist,
    specialSwiper
  },
  data() {
    return {
      list: [],
      allCount: {},
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
    };
  },
  created() {
    this.selectCount();
    this.ttIndexSpecialPage();
  },
  methods: {
    ttIndexSpecialPage() {
      ttIndexSpecialPage(this.pageData.pages).then((res) => {
        if (res.ok) {
          this.list = res.data.list;
          this.pageData.total = res.data.count;
        }
      });
    },
    selectCount() {
      selectCount().then((res) => {
        if (res.ok) {
          this.pageData.pages.pageSize=res.data.count;
          this.allCount = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.blueNumber {
  color: #5888ec;
}

.special-list-tops {
  position: relative;
  background: url('~@/assets/img/special/specialtbgImg.png') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
  text-align: center;
  padding-top: 35px;
  h1 {
    font-weight: bold;
    font-size: 24px;
    color: rgb(51, 51, 51);
  }

  p {
    font-size: 14px;
    padding-top: 2px;
    // color: #828282;
  }
}


.special-list-content {
  position: relative;
  background: none;
  margin-top: 35px;

  .nav {
    position: relative;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100px;
    top: -97px;
    border-radius: 6px;
    scroll-behavior: smooth;
    overflow: visible;
  }
}


</style>
