import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import permission from './modules/permission'
import scoll from './modules/scoll'
import user from './modules/user'
import notice from './modules/notice'
import common from './modules/common'
import loading from './modules/loading'
import settings from './modules/settings'
import AIService from './modules/AIService'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    permission,
    user,
    scoll,
    loading,
    notice,
    common,
    settings,
    AIService,
  }
})
