<template>
  <router-link
    :to="to"
    tag="div"
    class="item-list clearfix"
    :class="{ 'border-none': borderNone }"
  >
    <slot></slot>
    <span class="item-list-icon" v-if="$slots.important || important">
      <slot name="important">{{ important }}</slot>
    </span>
    <div class="item-list-content" v-if="$slots.title || title">
      <slot name="title">{{ newTitle }}</slot>
    </div>
    <div class="item-list-time" v-if="$slots.time || time">
      <slot name="time">{{ time }}</slot>
    </div>
  </router-link>
</template>
<script>
import { htmlRestore } from "@/utils/htmlRestore.js";
export default {
  name: "ItemList",
  props: {
    to: {
      type: String,
      default: "",
    },
    title: {},
    time: {},
    important: {},
    borderNone: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    newTitle(){
      return  htmlRestore(this.title)
    }
  }
};
</script>
<style lang="scss" scoped>
.item-list {
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  .item-list-icon {
    display: block;
    padding: 2px 4px;
    font-size: 12px;
    border: 1px solid #0D57BC;
    color: #0D57BC;
        line-height: 14px;
        margin-bottom: 9px;
        margin-right: 2px;
  }
  .item-list-content {
    flex: 1;
    line-height: 20px;
    color: #333;
    margin-right: 20px;
    padding-bottom: 9px;
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
    text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
  }
  .item-list-time {
    margin-left: auto;
    color: #bdbdbd;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.border-none {
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
