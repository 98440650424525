<template>
  <div v-if="visible" class="target-card-container">
    <Card
      v-for="item in resultData.list"
      :key="draggingTableName + resultDataSeq + item.cardName"
      :info="item"
      :style="getStyle"
    />
  </div>
</template>

<script>
import Card from './card'

export default {
  name: 'TargetCard',
  components: {
    Card
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    // 用于 v-for 的 key
    draggingTableName: {
      type: String,
      required: true
    },
    resultData: {
      type: Object,
      required: true
    },
    graphSettingOption: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // 用于防止表格列的 key 重复, 而导致列的排序不对
      resultDataSeq: -999999
    }
  },
  computed: {
    getStyle() {
      if (this.graphSettingOption) {
        const { singleColor, border } = this.graphSettingOption
        return {
          background: singleColor,
          border: `1px solid ${border || singleColor}`
        }
      }
      return {}
    }
  },
  watch: {
    resultData: {
      deep: true,
      handler() {
        this.resultDataSeq++
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.target-card-container {
  padding: 0 $dragging-result-container-external-width;
  display: flex;
  flex-wrap: wrap;
}

// 设置每个卡的样式
::v-deep {
  .card-item {
    width: calc((100% - 3 * #{$target-card-gap}) / 4);
    padding: 20px 24px 18px;
    margin-right: $target-card-gap;
    margin-bottom: $target-card-gap;
    border-radius: 4px;
    // background-color: #ECF7FF;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .card-name {
      color: #4F4F4F;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 6px;
    }

    .value {
      color: rgba($color: #000000, $alpha: 0.9);
      font-weight: 600;
      font-size: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .statistic-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .statistic-item {
      flex: 0.48;
      width: 0;
      display: flex;
      align-items: center;
      margin-top: 18px;

      &:last-child {
        justify-content: flex-end;

        .decrease, .increase {
          width: fit-content;
          flex: unset;
        }
      }

      &:first-child {
        justify-content: flex-start;
      }

      .name {
        margin-right: 8px;
        white-space: nowrap;
      }

      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        pointer-events: none;
      }

      .decrease {
        color: #00B42A;
      }

      .increase {
        color: #F53F3F;
      }

      .decrease, .increase {
        flex: 1;
        width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 1620px) {
  ::v-deep {
    .card-item {
      .statistic-container {
        align-items: flex-start;
        flex-direction: column;

        .statistic-item {
          width: 100%;

          &:last-child {
            justify-content: flex-start;

            .decrease, .increase {
              flex: 1;
            }
          }

          .decrease, .increase {
            flex: 1;
            width: 0;
          }
        }
      }
    }
  }
}
</style>
