<template>
  <router-view v-if="$route.name == '专题分析详情'" />
  <PageContainer v-else>
    <div class="sticky-container search-top" :class="{'h':hflag}">
      <div class="search-input-box">
        <div>
          检索方式：
          <el-radio v-model="content" label="title">按标题</el-radio>
          <el-radio v-model="content" label="htmlContent">按内容</el-radio>
        </div>
        <div class="search-btn-box">
          <el-input v-model="values" style="width: 650px" />
          <div class="search-btn" @click="search">检索</div>
        </div>
      </div>
    </div>

    <el-tabs
      v-model="form.contentCategory"
      type="card"
      tab-position="left"
      :before-leave="leaveTab"
      style="float: left; margin-top: 140px ;margin-left: -42px;display: flex;position: fixed;z-index: 1;"
      @tab-click="tabClick"
    >
      <el-tab-pane :key="'yc'" label="原创" name="yc" />
      <el-tab-pane :key="'qb'" label="全部" name=" " />
    </el-tabs>

    <div v-if="hflag" v-loading="loading" class="search-bottom">
      <div
        v-for="item in list"
        :key="item.id"
        class="box_box_title"
        @click="toHref('/thematicAnalysis/details/'+item.id,item)"
      >
        <div style="display: flex">
          <div class="text-center" style="width: 267px; height: 150px">
            <img
              style="width: 267px; height: 150px"
              :src="item.imgUrl ? item.imgUrl : defaultUrl"
              alt=""
            >
          </div>
          <div class="flex_col" flex="1">
            <div>
              <div flex="c" style="margin: 10px 0">
                <span
                  v-if="item.contentCategory == 'yc'"
                  style="
                  display: inline-block;
                  color: #0d57bc;
                  font-size: 14px;
                  line-height: 16px;
                  padding: 4px;
                  background: rgba(13, 87, 188, 0.08);
                  margin-right: 4px;
                "
                >原创</span>
                <p class="" v-html="item.title" />
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.summary }}
                <!--                    {{ item.introduction }}-->
              </div>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ item.publishTime }}
              <!--                  {{ item.publishDate }}-->
            </div>
          </div>
        </div>
        <div
          v-if="item.fileInfo"
          class="check"
          :class="{ ac: item.check }"
          @click.stop="checkFun(item)"
        >
          <i class="fa fa-check" />
        </div>
      </div>
      <div class="pages">
        <el-pagination
          :current-page="pageData.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageData.pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <template slot="right" v-has-role="'freeusers'">
      <div class="file-box">
        <div class="file-title">批量下载队列</div>
        <div class="file-content">
          <div v-for="item in filterFile(checkData)" :key="item.fileName+item.id" class="file-item">
            {{ item.fileName }}
          </div>
          <div v-if="checkData.length>0 && show && !showP " class="file-btn" @click="downAll">一键下载</div>
          <div v-else class="file-btn" @click="down">一键下载</div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import { page } from '@/api/thematicAnalysis.js'
import fileMixin from '@/mixin/file.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'

import { searchData } from '@/api/FullTextRetrieval.js'

export default {
  mixins: [fileMixin],
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      content: 'title',
      list: [],
      values: '',
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      checkData: [],
      hflag: true,
      form: {
        contentCategory: 'yc'
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '专题分析',
        userId: ''
      },
      show: false,
      showP: false,
      loading: false
    }
  },
  created() {
    const query = this.$route.query
    this.values = query.title ? query.title : query.htmlContent
    this.content = query.title ? 'title' : 'htmlContent'
    this.page()
    if (getUserInfo()) {
      this.show = true
      if (getUserInfo().roleKey == 'trialrole') {
        this.showP = true
      } else if (getUserInfo().roleKey == 'paidrole') {
        const menuName = getUserInfo().menuName
        if (!menuName.includes('专题分析')) {
          this.showP = true
        }
      }
    }
  },
  methods: {
    leaveTab() {
      if (!getUserInfo()) {
        return false
      }
    },
    tabClick(tab) {
      if (getUserInfo()) {
        this.form.contentCategory = tab.name
        this.page()
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin()
      }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('专题分析', '分析报告', '1', 'tt_special', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('专题分析')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    down() {
      this.formLog.eventType = '2'
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('专题分析')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            }
          }
        }
      } else {
        this.showLogin()
      }
    },

    page() {
      const param = {
        // 搜索关键字
        keywords: this.values,
        // 页码
        pageNum: this.pageData.pages.page,
        // 每页条数
        pageSize: this.pageData.pages.pageSize,
        // 标题0 全文1
        title: this.content === 'title' ? '0' : '1',
        sortFile: '_score',
        categoryTys: ['专题分析'],
        // 全部类型0 指定类型1
        all: 1,
        contentCategory: this.form.contentCategory,
        // 指定类型
        typeParams: ['发展环境', '乘用车', '商用车', '供应链']
      }
      this.loading = true
      searchData(param).then(res => {
        const list = res.data.data
        for (let i = 0; i < list.length; i++) {
          list[i].check = false
          if (this.checkData.length > 0) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.checkData[j].id == list[i].id) {
                list[i].check = true
              }
            }
          }
        }
        this.list = list
        this.pageData.total = res.data.total
        this.loading = false
      })
    },

    /*    page() {
      let form = Object.assign({}, this.pageData.pages);
      form[this.content] = this.values
      page(form).then((res) => {
        let list = res.data.list;
        for (let i = 0; i < list.length; i++) {
          list[i].check = false;
          if (this.checkData.length > 0) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.checkData[j].id == list[i].id) {
                list[i].check = true;
              }
            }
          }
        }
        this.list = list;
        this.pageData.total = res.data.count;
      });
    },*/

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.page()
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val
      this.page()
    },
    // 搜索
    search() {
      // this.hflag = true
      logRecords('专题分析', '分析报告', '4', 'tt_special', '', '', '', this.values)

      this.page()
    }

  }
}
</script>
<style lang="scss" scoped>
.search-top {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;

  .search-input-box {
    margin: auto;
  }

  &.h {
    height: 130px;
  }
}

.search-btn-box {
  display: flex;
  margin-top: 9px;

  ::v-deep .el-input__inner {
    height: 44px;
    line-height: 44px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-btn {
    width: 109px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

.search-bottom {
  position: relative;
  z-index: 1;
}

.box_box_title {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.file-box {
  background: #fff;
  border-radius: 4px;
}

.file-title {
  padding: 15px 0 15px 16px;
  font-size: 16px;
  color: #0D57BC;
  border-bottom: 1px solid #f2f2f2;
}

.file-content {
  padding: 24px 16px;

  .file-item {
    line-height: 21px;
    color: #333;
    margin-bottom: 24px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.file-btn {
  width: 168px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0D57BC;
  border: 1px solid #0D57BC;
  margin: 0 auto;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(21, 67, 133, 0.2);
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  color: #fff;

  .fa {
    margin-right: 0;
  }

  &.ac {
    background: #0D57BC;
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}
</style>
