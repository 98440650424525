<template>
  <div class="enterprise-dynamic">
    <titlemore title="企业动态" />
    <div class="content-box">
      <div
        v-for="(i, index) in dataSource"
        :key="i.id+index"
        class="items-content"
        style="cursor: pointer"
        @click="toHref(`/workInformation/enterpriseInfo/entrepriseNews/enterDetail/${i.id}`, '_blank', i.id)"
      >
        <i v-if="index === 0" class="top icon-zhiding iconfont" />
        <i
          v-else
          :class="{
            first: index === 1,
            second: index === 2,
            third: index === 3
          }"
        > {{ (index + 1).toString().padStart(2, '0') }}</i>
        <TextOverTooltip ref-name="testName1" class-name="title-span" :content="i.title" />
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { getEnterpriseDynamicList } from '@/api/enterpriseAnalysis.js'
import TextOverTooltip from '@/components/title/index'
import titlemore from './components/titlemore.vue'

export default {
  name: 'EnterpriseDynamic',
  components: {
    TextOverTooltip,
    titlemore
  },
  data() {
    return {
      dynamicParams: {
        pageSize: 9,
        pageNum: 1
      },
      dataSource: [],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      }
    }
  },
  computed: {
    queryValueLength() {
      return text => {
        if (!text) return false
        const chineseRegex = /[\u4e00-\u9fa5]/ // 匹配汉字的正则表达式
        const maxNum = chineseRegex.test(text) ? 19 : 21
        const textNum = text.trim().length
        return textNum >= maxNum
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    toHref(routeUrl, openWay, id) {
      const newUrl = this.$router.resolve(routeUrl)
      if (getUserInfo()) {
        if (id) {
          logRecords('企业情报', '企业动态', '', '', id, '', '', '')
        }
        logRecords('企业情报', '企业动态', '', '', '', '', '', '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, openWay)
          }
        } else {
          window.open(newUrl.href, openWay)
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    init() {
      getEnterpriseDynamicList(this.dynamicParams).then(res => {
        if (res.ok) {
          this.dataSource = res.data.itemList
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-top {
    padding-left: 0;
}

.enterprise-dynamic {
    padding: 23px 20px 30px 20px;
    background: #fff;

    .content-box {
        padding: 20px 0 0 5px;

        .items-content {
            display: flex;
            align-items: center;

            .title-span {
                display: inline-block;
                width: calc(100% - 39px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:nth-child(n + 2) {
                padding-top: 11px;
            }

            i {
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                color: #ccc;
                margin-right: 20px;
            }

            .top {
                color: #ff0f0f;
            }

            .first {
                color: #ff0000;
            }

            .second {
                color: #ff6000;
            }

            .third {
                color: #fcc660;
            }
        }
    }
}
</style>

<style src="@/views/workInformation/enterpriseInfo/components/iconfont/iconfont.css"></style>
