var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.open)?_c('div',{ref:"aiServiceRef",staticClass:"ai-container"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"title"},[_vm._v("在线智能问答")]),_c('span',{staticClass:"desc"},[_c('span',[_vm._v("说明：试运行功能，如有任何问题可点击处")]),_c('span',{staticClass:"btn",on:{"click":_vm.handleMessage}},[_vm._v("留言反馈")]),_c('span',[_vm._v("给我们")])]),_c('i',{staticClass:"el-icon-close",on:{"click":_vm.handleClose}})]),_c('div',{ref:"wrapperRef",staticClass:"wrapper",style:({ height: `${_vm.height}px` })},[_c('el-scrollbar',{ref:"scrollbarRef",attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('div',{ref:"mainRef",staticClass:"main"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":require("@/assets/service.svg"),"alt":"avatar"}})]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"content"},[_c('span',[_vm._v("您好，我可以为您提供有用的信息，可以向我提问，比如 ")]),_c('ol',[_c('li',[_c('b',[_vm._v("比亚迪23年12月的销量")])]),_c('li',[_c('b',[_vm._v("宋PLUS的销量")])]),_c('li',[_c('b',[_vm._v("汽车行业最新动态")])]),_c('li',[_c('b',[_vm._v("......")])])])])])]),_vm._l((_vm.messages),function(item,index){return _c('div',{key:index,class:['item', {
            user: item.type === 'u',
            ai: item.type === 'ai',
          }]},[_c('div',{staticClass:"avatar"},[(item.type === 'ai')?_c('img',{attrs:{"src":require("@/assets/service.svg"),"alt":"avatar"}}):_c('img',{attrs:{"src":require("@/assets/user.svg"),"alt":"avatar"}})]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(item.content)}}),(item.type === 'ai')?_c('div',{staticClass:"desc"},[_vm._v("注意：答案来自AI智能生成，仅供参者，不代表本网观点")]):_vm._e()])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetching),expression:"fetching"}],staticClass:"loading"},[_c('i',{staticClass:"el-icon-loading"}),_c('span',{staticClass:"text"},[_vm._v("加载中...")])])],2)])],1),_c('div',{staticClass:"actions"},[_c('el-input',{ref:"questionRef",staticClass:"question-input",attrs:{"type":"textarea","autosize":{ minRows: 5, maxRows: 8 },"disabled":_vm.disabled || _vm.fetching,"placeholder":"请输入问题"},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}}),_c('el-button',{staticClass:"submit",attrs:{"type":"primary","disabled":_vm.disabled || _vm.fetching},on:{"click":_vm.handleSend}},[_c('img',{attrs:{"src":require("@/assets/send.svg")}})]),(_vm.disabled)?_c('div',{staticClass:"desc"},[_c('span',[_vm._v("提示：每个用户仅限使用 20 次免费对话")]),_c('span',[_vm._v("仍需使用请联系 18309754779")])]):_vm._e()],1),_c('el-dialog',{attrs:{"visible":_vm.visible,"title":"留言反馈","width":"520px","append-to-body":""},on:{"update:visible":function($event){_vm.visible=$event},"closed":_vm.onClosed}},[_c('el-form',{ref:"formRef",attrs:{"model":_vm.data,"label-position":"right","label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"企业名称","prop":"company","rules":{
          required: true,
          min: 2,
          message: '请输入企业名称',
        }}},[_c('el-input',{attrs:{"placeholder":"请输入企业名称"},model:{value:(_vm.data.company),callback:function ($$v) {_vm.$set(_vm.data, "company", $$v)},expression:"data.company"}})],1),_c('el-form-item',{attrs:{"label":"姓名","prop":"name","rules":{
          required: true,
          min: 2,
          message: '请输入姓名',
        }}},[_c('el-input',{attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('el-form-item',{attrs:{"label":"联系方式","prop":"phone","rules":[
          {
            required: true,
            message: '请输入联系方式',
          },
          {
            validator: _vm.validateUserPhone,
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入联系方式"},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('el-form-item',{attrs:{"label":"留言内容","prop":"detailedRequirements","rules":{
          required: true,
          min: 3,
          message: '请输入留言内容',
        }}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 5 },"placeholder":"请输入留言内容"},model:{value:(_vm.data.detailedRequirements),callback:function ($$v) {_vm.$set(_vm.data, "detailedRequirements", $$v)},expression:"data.detailedRequirements"}})],1)],1),_c('template',{slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提 交")]),_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("关 闭")])],1)],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }