<template>
    <div class="top" :style="`width:100%;height:${height}px`">
        <div v-for="(item, index) in list" :key="index" :style="`height: ${index === data.length - 1 ? 'unset' : topItemHeight + 'px'}`
            " :class="`top-item ${roteShow ? '' : 'top-item-rote-not-show'}`">
            <div class="top-item-number">
                <span :class="itemColor(index)" v-if="index < 9">0{{ index + 1 }}</span>
                <span class="ranking" v-else>{{ index + 1 }}</span>
            </div>
            <div class="title">
                {{ item.name || "--" }}
            </div>
            <div v-if="roteShow" class="top-item-info">
                    <div :style="`width:${(item.value * 100) / maxNum}%`">
                        <el-progress :class="[itemColor(index), 'progress']" :show-text="false" :percentage="100" />
                </div>
                <div class="value">
                    {{ item.value }}
                    <!-- {{ (item.value / maxNum).toFixed(2) }} -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toThousands } from "@/utils/number";

// 引入dom监听器
export default {
    name: "Top",
    props: {
        // 高度
        height: {
            type: Number,
            default: 600,
        },
        // 是否展示比例
        roteShow: {
            type: Boolean,
            default: false,
        },
        // 最大值
        maxNum: {
            type: Number,
            default: 0
        },
        // 展示数量
        topNum: {
            type: Number,
            default: 10,
        },
        // 每一条数据的高度
        topItemHeight: {
            type: Number,
            default: 54,
        },
        list: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },
    data() {
        return {
            data: [],
            toThousands,
        };
    },
    mounted() {

    },
    methods: {
        itemColor(index) {
            if (index === 0) {
                return { ranking1: true }
            } else if (index === 1) {
                return { ranking2: true }
            } else if (index === 2) {
                return { ranking3: true }
            } else {
                return { ranking: true }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.top {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .top-item {
        width: 100%;
        display: flex;
        align-items: center;

        .top-item-number {
            height: auto;
            // padding-right: 22px;
        }

        .title {
            width: 60px;
            text-align: right;
            font-size: 14px;
            padding-right: 12px;
            color: rgb(34, 34, 34);
        }

        .value {
            font-weight: bold;
            color: rgb(51, 51, 51);
            font-size: 18px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            width: 90px;
        }


        .top-item-info {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            border-left: 1px solid #dedede;

            .progress {
                flex: 1;
                margin-right: 12px;
            }

            .proportion {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .qoq {
                    padding-left: 23px;
                }

                .yoy,
                .qoq {
                    white-space: nowrap;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #86909c;
                    line-height: 16px;
                    width: 50%;

                    .icon-up {
                        display: inline-block;
                        height: 12px;
                        width: 10px;
                        background: url(~@/assets/img/overview/up.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    .icon-down {
                        display: inline-block;
                        height: 12px;
                        width: 10px;
                        background: url(~@/assets/img/overview/down.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    .text-up {
                        margin-left: 1px;
                        color: #f53f3f;
                    }

                    .text-down {
                        margin-left: 1px;
                        color: #00b42a;
                    }
                }
            }
        }
    }

    .top-item-rote-not-show {
        // flex: 1;
        border-bottom: 1px solid #f0f0f0;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
}

.top-item-number {
    font-size: 18px;

    .ranking1 {
        color: rgb(255, 0, 0);
    }

    .ranking2 {
        color: #ff6000;
    }

    .ranking3 {
        color: #fcce75;
    }

    .ranking {
        color: #cccccc;
    }
}

.progress {
    ::v-deep {
        .el-progress-bar__outer {
            height: 24px !important;
            background: #f3f3f3;
            border-radius: 2px;
        }

        .el-progress-bar__inner {
            border-radius: 0;
            background: linear-gradient(to right, rgb(180, 180, 180), rgba(100, 100, 100, 0.99608));
        }
    }
}

::v-deep {
    .ranking1 .el-progress-bar__inner {
        background: linear-gradient(to right, rgb(255, 188, 188), rgba(255, 45, 45, 0.99608));
    }

    .ranking2 .el-progress-bar__inner {
        background: linear-gradient(to right, rgb(255, 206, 176), rgba(255, 96, 0, 0.99608));
    }

    .ranking3 .el-progress-bar__inner {
        background: linear-gradient(to right, rgb(255, 239, 210), rgba(252, 201, 105, 0.99608));
    }
}
</style>
