<template>
  <div>
    <!--        <div class="g-content-top">-->
    <!--            <div flex="1">-->
    <!--                <h4 style="color: #999999" class="text-right">-->
    <!--&lt;!&ndash;                    更新于{{createTime}}&ndash;&gt;-->
    <!--                    <el-tooltip class="item" effect="dark" :content="remark" placement="top-start">-->
    <!--                      <a style="margin-right: 10px;" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>-->
    <!--                    </el-tooltip>-->
    <!--                </h4>-->
    <!--            </div>-->
    <!--        </div>-->


    <div class="work_tag">
      <div flex="c">
        <el-checkbox-group v-model="model" size="small" @change="confirm">
          <el-checkbox :label="item.id" border v-for="item in cxData" :key="item.dictValue">{{
              item.shortName
            }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="work_list" v-if="qyData.length>0">

      <ul style="margin-bottom: 20px;">
        <span style="color: #0f0f0f;margin-right: 10px">更多车型选择</span>
        <span style="color: #1d6cd6;cursor:pointer;" v-if="isShowNameType" @click="handleIsShowNameType">展开</span>
        <span style="color: #1d6cd6;cursor:pointer;" v-if="!isShowNameType" @click="handleIsShowNameType">收起</span>
        <li v-for="(item,index) in qyData" :key="'cx'+index">
          <!--                  <span style="margin-left: 20px">已选择{{checkList.length}}个</span>-->

          <div class="tip" v-if="!isShowNameType">
            <div style="display: inline;color: #000;margin-left: 10px;font-weight: bold;">{{ item.shortName }}</div>

            <div v-for="(citem,cindex) in formWorkList(item)" :key="'c'+cindex">

              <div class="work_chek_list_box">
                <span class="qySpan">{{ citem.name }}</span>
                <el-checkbox-group v-model="checkList">
                  <el-checkbox :label="ccitem.id" v-for="(ccitem,ccindex) in citem.data" :key="'cc'+ccindex">
                    {{ ccitem.vehicleModelName }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>

        </li>
      </ul>
      <div style="text-align: center;">

        <el-button type="primary" size="small" @click="onChange">
          确定
        </el-button>
        <el-button type="primary" plain size="small" @click="reset">
          重置
        </el-button>
      </div>
      <div>
        <el-radio-group v-model="month" size="small" @change="handleChange" fill='#0D57BC'>
          <el-radio-button label="月度"></el-radio-button>
          <el-radio-button label="年度"></el-radio-button>
        </el-radio-group>
        <div class="search-form">
          <div style="position: absolute;right: 0;">
            <el-tooltip class="item" effect="dark" :content="remark" placement="top-start">
              <a style="margin-right: 10px;color: #606266" href="javascript:;"><i class="el-icon-info"></i>预测说明</a>
            </el-tooltip>
            <el-button type="primary" v-has-role="'freeusers'" plain @click="exportExcel">
              导出
            </el-button>
          </div>
        </div>
      </div>

      <template v-if="show && !showP">
        <div id="allCharts"></div>
        <el-table id="tal" :data="tableData" :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
                  style="width: 100%; margin-bottom: 20px" stripe>
          <template v-for="(item, index) in TableHeader">
            <el-table-column :prop="item" :label="item" :key="'syc' + index" min-width="100">
            </el-table-column>
          </template>
        </el-table>
      </template>
      <div v-else>
        <img style="width:100%" src="@/assets/img/qycxxlyc.jpg" alt=""/>
        <center style="height: 1px;">
          <div
              style="position:relative;width:300px;height: 94px;bottom:600px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;"
              v-if="!show">

            <div style="padding-top: 20px">
              <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
            </div>

            <div style="margin: 10px">
              没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

            </div>
          </div>
          <div
              style="position:relative;width:320px;height:94px;bottom:600px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
              v-else>
            <div style="padding-top: 20px;padding-left: 22px;">
              <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
            </div>
            <div style="padding: 10px 20px;text-align: center">
              <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
            </div>
          </div>
        </center>

      </div>
    </div>
  </div>
</template>
<script>
import {
  showForecastEnterPrise,
  showModelByEnterPrise,
  showGraphData,
  showGraphDataByYear, exportPredictSyc,
} from "@/api/sale.js";
import * as echarts from "echarts";
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import {getUserInfo, openSy} from "@/utils/auth.js";
import {buryPoint} from "@/api/common";
import {downloadFile} from "@/utils/download";

export default {
  name: "Vyear",
  data() {
    return {
      createTime: "",
      remark: "企业销量预测采取专家预测法，重点参考了各企业年度销量目标，新产品推出力度等关键指标，仅供参考",
      model: [186],
      cxData: [],
      qyData: [],
      month: "月度",
      checkList: [],
      tableData: [],
      TableHeader: [],
      activeNameStyle: 'overflow: hidden;',
      showNameStyle: '',
      isShowNameType: false,
      show: false,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "预测",
        userId: ""
      },
    };
  },
  created() {
    this.init();
    if (getUserInfo()) {
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('预测')) {
          this.showP = true;
        } else {
          if( getUserInfo().roleKey == "trialrole"){
            this.showP = true;

          }
          this.init();
        }
      } else {
        this.init();
      }
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //初始化
    init() {
      showForecastEnterPrise().then((res) => {
        if (res.ok) {
          this.cxData = res.data;
        }
      });
      this.confirm();
    },

    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    exportExcel() {
      this.formLog.eventType = "0";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              this.insertBuryPoint();
              openSy();
            } else {
              var xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
              var table = document.querySelector('#tal').cloneNode(true)
              var wb = XLSX.utils.table_to_book(table, xlsxParam)
              /* get binary string as output */
              var wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
              try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '企业车型预测.xlsx')
              } catch (e) {
                if (typeof console !== 'undefined') {
                  console.log(e, wbout)
                }
              }
              return wbout
            }
          }
        } else {
          var xlsxParam = {raw: true} // 导出的内容只做解析，不进行格式转换
          var table = document.querySelector('#tal').cloneNode(true)
          var wb = XLSX.utils.table_to_book(table, xlsxParam)
          /* get binary string as output */
          var wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
          try {
            FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '企业车型预测.xlsx')
          } catch (e) {
            if (typeof console !== 'undefined') {
              console.log(e, wbout)
            }
          }
          return wbout
        }
      } else {
        this.showLogin();
      }

    },
    handleIsShowNameType() {
      this.isShowNameType = !this.isShowNameType;
    },
    //确定
    confirm() {
      if (this.model.length <= 0) {
        this.$message.warning("请选择企业");
        return false;
      }
      let ids = {ids: this.model.join(",")};
      showModelByEnterPrise(ids).then((res) => {
        if (res.ok) {
          this.qyData = res.data;
          let data = res.data;
          let arr = [];
          for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
              if (key != "shortName") {
                arr.push(data[i][key][0].id);
              }
            }
          }
          this.checkList = arr;
          if (this.show) {
            this.showGraphData(arr);
          }
        }
      });
    },
    //根据车型展示月度图表
    showGraphData(ids) {
      showGraphData({ids: ids.join(",")}).then((res) => {
        if (res.ok) {
          this.initChart(res.data);
        }
      });
    },
    //年度
    showGraphDataByYear(ids) {
      showGraphDataByYear({ids: ids.join(",")}).then((res) => {
        if (res.ok) {
          this.initChart(res.data);
        }
      });
    },
    //初始化chart
    initChart(data) {
      let xData = data.xaxis.data;
      let series = data.series;
      let cx = series.map((item) => item.name);
      let seriesData = [];
      let table = [];
      for (let i = 0; i < series.length; i++) {
        let obj = {
          车型: series[i].name,
          细分市场: series[i].xfsc,
        };
        let seriesData = series[i].data;
        for (let j = 0; j < xData.length; j++) {
          obj[xData[j]] = seriesData[j];
        }
        table.push(obj);
      }
      this.tableData = table;
      this.TableHeader = ["车型", "细分市场"].concat(xData);

      series.forEach((item) => {
        seriesData.push({
          name: item.name,
          type: "line",
          stack: "Total",
          data: item.data,
        });
      });
      var myChart = echarts.init(document.getElementById("allCharts"));
      var option = {
        title: {
          subtext: "辆",
        },
        legend: {
          data: cx,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "100%",
          right: "100%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {color: "#7DABB0"},
            },
          },
        ],
        series: seriesData,
      };
      myChart.clear();
      myChart.setOption(option, true);
    },
    reset() {
      this.qyData = [];
      this.model = [186];
      this.checkList = [];
      this.month = "月度";
      this.init();

    },
    formWorkList(item, index) {
      let arr = [];
      for (const key in item) {
        if (key != "shortName") {
          arr.push({name: key, data: item[key]});
        }
      }
      return arr;
    },
    onChange() {
      this.switchFun();

    },
    handleChange() {
      this.switchFun();
    },
    //切换年月
    switchFun() {
      this.formLog.eventType = "2";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('预测')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {

              if (this.month == "月度") {
                this.showGraphData(this.checkList);
              } else if (this.month == "年度") {
                this.showGraphDataByYear(this.checkList);
              }

          }
        } else {
          if (this.month == "月度") {
            this.showGraphData(this.checkList);
          } else if (this.month == "年度") {
            this.showGraphDataByYear(this.checkList);
          }
        }
      } else {
        this.showLogin();
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.work_tag {
  padding: 10px;
  background: #ffffff;

  ::v-deep .el-checkbox__input {
    display: none;
  }

  ::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
    margin-top: 8px;
    min-width: 109px;
    text-align: center;
  }

  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.work_list {
  h1 {
    font-size: 18px;
    margin: 15px 0;
  }

  h2 {
    font-size: 16px;
    margin: 15px 0;
  }
}

.work_chek_list_box {
  margin-left: 15px;

}

#allCharts {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}

.el-icon-info {
  margin-right: 2px;
}

.el-checkbox-group {
  display: inline;
}

.qySpan {
  display: inline-block;
  width: 94px;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
}

.search-form {
  height: 40px;
}

.el-divider--horizontal {
  margin: 0;
  margin-top: 6px;
  margin-bottom: -10px;
  width: 116px;
}

.more-icon {
  width: 15px
}

</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
