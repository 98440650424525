<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" isContentTwo="zcjd" />

    <template slot="right">
      <VcontentItem
        title="相关政策推荐"
        type="list"
        :list="relationPolicy"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { ttPolicyInterpret } from "@/api/policy.js";
export default {
  data() {
    return {
      data: {},
      props: {
        htmlContent: "content",
        summary:"introduction",
        source:"policyResource",
      },
      relationPolicy: [],
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.ttPolicyInterpret(id);
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.ttPolicyInterpret(id);
  },
  methods: {
    ttPolicyInterpret(params) {
      ttPolicyInterpret(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.relationPolicy = res.data.relationPolicy.map((item) => {
            item.publishDate = item.publish_date;
            return item;
          });
        }
      });
    },
    tohref(item) {
      //政策主体推荐
      this.$router.push("/policy/dynamic/contentNewPolicy/" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
