<template>
  <div class="time-line-box">
    <ul class="time-line-ul">
      <li>
        <div class="time-line-content">
          <p>{{ getCode[0].name }}</p>
          <p>{{ getCode[0].val }}</p>
          <p v-if="getCode[0].code" @click="commond({code:getCode[0],type:'replace'})">
            替代<span>{{ getCode[0].code }}</span>
          </p>
        </div>
      </li>
      <li>
        <div class="time-line-content">
          <p>{{ getCode[1].name }}</p>
          <p>{{ getCode[1].val }}</p>
        </div>
      </li>
      <li>
        <div class="time-line-content">
          <p>{{ getCode[2].name }}</p>
          <p>{{ getCode[2].val}}</p>
          <p v-if="getCode[2].code" @click="commond({code:getCode[2],type:'replaced'})">
            被<span>{{ getCode[2].code }}</span
            >替代
          </p>
        </div>
      </li>
      <li>
        <div class="time-line-content">
          <p>{{ getCode[3].name }}</p>
          <p>{{ getCode[3].val }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    times: {
      type: Object,
      default: function () {
        return {
          publishDate: "",
          implementDate: "",
          beReplacedDate: "",
          voidDate: "",
          code: 0,
          id: 0
        };
      },
    },
    timeData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    //根据字段赋值
    getCode() {
      let times = this.times;
      //console.log(times);
      let timeData = this.timeData;
      timeData.forEach((item) => {
        for (let key in times) {
          if(item.time == key){
            item.val = times[key];
            item.id = times.id;
          }
          if(item.code == key){
            item.code = times[key]
            item.id = times.id;
          }
        }
      });
      return timeData
    }
  },
  methods:{
    commond(obj){
      this.$emit('commond',obj)
    }
  }
};
</script>
<style lang="scss" scoped>
.time-line-box {
  width: 100%;
  .time-line-ul {
    position: relative;
    clear: both;
    &::after {
      content: "";
      display: block;
      overflow: hidden;
      clear: both;
    }
    li {
      position: relative;
      float: left;
      text-align: center;
      padding-right: 85px;
      width: 25%;
      white-space: nowrap;
      .time-line-content {
        position: relative;
        padding-top: 17px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 50%;
          margin-left: -4px;
          width: 9px;
          height: 9px;
          border: 2px solid #0D57BC;
          border-radius: 50%;
          background: #fff;
          // z-index: 1;
        }
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ccccf5;
      }
      &:first-child {
        &::after {
          left: calc(50% - 40px);
        }
      }
      &:last-child {
        padding-right: 0;
        &::after {
          left: -50%;
        }
      }
      p {
        margin-bottom: 6px;
        &:nth-child(1) {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #000;
        }
        &:nth-child(2) {
          min-width: 109px;
        }
        &:last-child {
          margin-bottom: 0;
          cursor: pointer;
          span {
            color: #0D57BC;
          }
        }
      }
    }
  }
}
</style>