<!--
 * @Author: your name
 * @Date: 2021-07-21 13:09:23
 * @LastEditTime: 2021-07-21 14:49:30
 * @LastEditors: Please set LastEditors
 * @Description: 出口数据页面
 * @FilePath: /information-vue/code/src/views/data/exportData/index.vue
-->

<template>
  <PageContainer>
    <!--    <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据"-->
    <!--               :showTips="showTips"/>-->
    <template>
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">出口数据</span>
            <span class="time">已更新至{{ upDataDate }}</span>
          </div>
          <!-- <button class="title-down-btn" v-has-role="'trialrole'" @click="dialogVisible = true"><i
                  class="el-icon-download"></i>下载电子版本</button> -->
        </div>
      </Vtitle>
      <el-form :model="form" ref="form" inline label-width="135px" class="n-data-content">
        <h1 class="n-title">显示字段</h1>
        <el-row style="margin:0px 0 10px;">
          <el-col class="n-check-m">
            <el-form-item prop="qyemsModShow" style="display:none"></el-form-item>
            <el-form-item prop="cxflei1Show" style="display:none"></el-form-item>
            <el-form-item prop="cxflei2Show" style="display:none"></el-form-item>
            <el-form-item prop="cxingShow" style="display:none"></el-form-item>
            <el-form-item prop="checkYearMonth" style="display:none"></el-form-item>
            <el-checkbox v-model="year" disabled class="isDisabled">年</el-checkbox>
            <el-checkbox v-model="month" @change="changeMonth">月</el-checkbox>
            <el-checkbox v-model="form.qyemsModShow" @change="changeFun('qyemsModArray')">企业名称</el-checkbox>
<!--            <el-checkbox v-model="form.cxingShow" @change="changeFun('cxingArray')">车型名称</el-checkbox>-->
            <!--            <el-checkbox v-model="form.cxflei1Show" @change="changeFun('cxflei1Array')">车辆类型</el-checkbox>-->
            <el-checkbox v-model="form.cxflei2Show" @change="changeFun('cxflei2Array')">细分类别</el-checkbox>
          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">查询字段</h1>
        <el-row>
          <el-col :span="8">
            <el-form-item label="时间：">
              <VdateEndStart ref="VdateEndStart" v-model="times" @change="timeChange"
                             :isTimeSwitch="false" :checkDate="checkDate" :close="false" width="180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业名称：" class="no-position" prop="qyemsModArray">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.qyemsModShow">

                <selectCheck v-model="form.qyemsModArray" :datas="qyemsModData"
                             :disabled="!form.qyemsModShow" :props="props" @change="qymcChange" @close="closeqy">
                </selectCheck>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="细分类别：" prop="cxflei2Array">
              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cxflei2Show">

                <el-select class="new-width" size="small" :disabled="!form.cxflei2Show"
                           v-model="form.cxflei2Array" multiple collapse-tags>
                  <el-option :value="item" :label="item" v-for="item in cxflei2Data" :key="item">
                  </el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">-->
<!--            <el-form-item label="车型名称：" class="no-position" prop="cxingArray">-->
<!--              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cxingShow">-->

<!--                <selectCheck v-model="form.cxingArray" :disabled="!form.cxingShow" :datas="cxData"-->
<!--                             :props="cxprops"></selectCheck>-->
<!--              </el-tooltip>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

        </el-row>
<!--        <el-row>-->
          <!--          <el-col :span="8">-->
          <!--            <el-form-item label="车辆类型：" prop="cxflei1Array">-->
          <!--              <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cxflei1Show">-->

          <!--                <el-select class="new-width" size="small" :disabled="!form.cxflei1Show"-->
          <!--                           v-model="form.cxflei1Array" @change="cxflei1Change" multiple collapse-tags>-->
          <!--                  <el-option value="乘用车" label="乘用车"></el-option>-->
          <!--                  <el-option value="商用车" label="商用车"></el-option>-->
          <!--                </el-select>-->
          <!--              </el-tooltip>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->

<!--        </el-row>-->
        <div class="text-center">
          <el-button @click="rest()">重置</el-button>
          <el-button type="primary" @click="searchForm()">查询</el-button>
        </div>
      </el-form>

      <div style="margin-top:10px">
        <div class="search-form">
          <div flex="sb" style="margin-bottom: 16px">
            <span class="table_title"></span>
            <div>
              <!-- <el-button type="primary" plain @click="ExportAll"  :loading="excelAllLoading">导出全部Excel</el-button> -->
              <el-button type="primary" plain @click="Export" v-has-role="'freeusers'"
                         :loading="excelLoading">导出
              </el-button>
            </div>
          </div>
          <div>
            <el-table :span-method="arraySpanMethod" v-loading="loading" width="100%" :data="tableData"
                      style="width: 100%; margin-bottom: 20px" stripe border
                      :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
              <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop"
                               :key="item.prop"></el-table-column>
              <!-- <el-table-column :prop="item" :label="item" v-for="item in tableHeaderTime" :key="item">
              </el-table-column> -->
              <template v-for="item in timeList">
                <el-table-column :label="item" :key="item" align="center">
                  <el-table-column label="出口量" :prop="item+'-ckliang'">
                    <template slot-scope="{ row }">
                      {{ toLocaleString(row[item + '-ckliang']) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="本期止累计" :prop="item+'-ljckliang'" v-if="monthShow">
                    <template slot-scope="{ row }">
                      {{ toLocaleString(row[item + '-ljckliang']) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="同期值累计" :prop="item+'-ljtqckliang'" v-if="monthShow">
                    <template slot-scope="{ row }">
                      {{ toLocaleString(row[item + '-ljtqckliang']) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="比上月增长" :prop="item+'-hb'" v-if="monthShow">
                    <template slot-scope="{ row }">
                      {{ toFixed(row[item + '-hb']) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="比同期增长" :prop="item+'-tb'">
                    <template slot-scope="{ row }">
                      {{ toFixed(row[item + '-tb']) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="比同期累计增长" :prop="item+'-btqljzz'" v-if="monthShow">
                    <template slot-scope="{ row }">
                      {{ toFixed(row[item + '-btqljzz']) }}
                    </template>

                  </el-table-column>
                </el-table-column>
              </template>
              <!-- <el-table-column prop="total" label="合计">
              </el-table-column> -->
            </el-table>
          </div>

          <div>单位：辆，%</div>
          <div class="text-right">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
            </el-pagination>
          </div>
        </div>
        <el-dialog title="出口数据电子版下载" :visible.sync="dialogVisible" width="800px">
          <div style="height:338px;overflow-x:auto">
            <el-table :data="uploadList" class="rest_table">
              <el-table-column property="attachName" label="名称"></el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button size="small" icon="el-icon-download"
                             @click="handleDown(scope.$index, scope.row)">下载
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="dialog-footer text-center">
            <el-button @click="dialogVisible = false" type="primary" plain style="width:204px">关 闭
            </el-button>
          </div>

        </el-dialog>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {
  getYearMonthList,
  ttQyCkDataPage,
  getCxflei2InfoList,
  getDefaultDate,
  ttQyCkDataExport,
  ttQyCkDataExportAll,
  ttQyCkDataEnterpriseList,
  ttQyCkDataCxingList,
  ttDataUpload,
} from "@/api/data.js";
import {
  getUserInfo, ydlTk
} from "@/utils/auth.js";
import VdateEndStart from "@/components/dateEndStartTwo";
import {
  buryPoint,
  download,
  newdownload
} from "@/api/common.js";
import {
  downloadFile
} from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";
import Vtitle from "../components/title.vue";
import {
  getMonthBetween,
  getYearArr
} from "@/utils/date.js";

export default {
  components: {
    // VnotLogin,
    VdateEndStart,
    selectCheck,
    Vtitle,
  },
  data() {
    return {
      isActive: "批发",
      show: false,
      showTips: [{
        title: "数据动态",
        text: "最新权威动向和独家原创解读",
      },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      form: {
        cxflei1Show: true,
        cxflei2Show: false,
        cxingShow: false,
        qyemsModShow: true,
        checkYearMonth: false,
        cxflei1Array: ['商用车'],
        cxflei2Array: [],
        cxingArray: [],
        qyemsModArray: [],
        startTime: "",
        endTime: "",
      },
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      uploadList: [],
      checkYearMonth: true,
      year: true,
      month: true,
      times: [],
      qyemsModData: [],
      cxflei2Data: [],
      loading: false,
      tableHeader: [],
      tableHeaderTime: [],
      checkDate: "month",
      excelLoading: false,
      excelAllLoading: false,
      dialogVisible: false,
      cxData: [],
      props: {
        label: "id",
        key: "id",
        name: "short_name",
      },
      cxprops: {
        label: "id",
        key: "id",
        name: "cxing",
      },
      upDataDate: "",
      timeList: [],
      monthShow: true,
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

      // this.ttDataUpload();
      getCxflei2InfoList({
        cxflei1: "商用车"
      }).then((res) => {
        if (res.ok) {
          this.cxflei2Data = res.data;
        }
      });
    } else {
      this.show = false;
    }
    this.getDefaultDate();
    this.monthShow = this.month

  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    toLocaleString(val) {
      if (val == null || val == '') {
        return val;
      } else {
        return val.toLocaleString();
      }

    },
    toFixed(val) {

      if (val == null || val == '') {
        return val;
      } else {
        return val.toFixed(2);
      }

    },
    //点击月份
    changeMonth(e) {
      this.form.checkYearMonth = !e
      if (e) {
        this.$refs.VdateEndStart.deteHandleCommand('month', this.updataDate)
      } else {
        this.$refs.VdateEndStart.deteHandleCommand('year')
      }


    },
    //附件列表
    ttDataUpload() {
      ttDataUpload({
        modelName: "data_upload_5"
      }).then((res) => {
        if (res.ok) {
          this.uploadList = res.data;
        }
      });
    },
    //下载附件
    handleDown(index, row) {
      this.download(row);
    },
    download(item) {
      let self = this;
      newdownload({
        fileUrl: item.attachUrl,
        articleId: item.id
      }).then(
          (res) => {
            var reader = new FileReader();
            reader.readAsText(res.data);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                let contentDisposition =
                    res.headers["content-disposition"];
                let patt = new RegExp(
                    "filename=([^;]+\\.[^\\.;]+);*"
                );
                let result = patt.exec(contentDisposition);
                let filename = decodeURI(result[1]);
                downloadFile(res.data, filename);
              }
            });
          }
      );
    },
    //初始化
    getDefaultDate() {
      getDefaultDate().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.upDataDate = res.data;
          this.timeList = [res.data]
          if (this.show) {
            this.ttQyCkDataPage();

          }
          this.ttQyCkDataEnterpriseList();
          this.ttQyCkDataCxingList(this.form.qyemsModArray);
        }
      });
    },
    ttQyCkDataEnterpriseList() {
      let form = {
        isYear: this.form.checkYearMonth,
        startTime: this.times[0],
        endTime: this.times[1],
      };
      ttQyCkDataEnterpriseList(form).then((res) => {
        if (res.ok) {
          this.qyemsModData = res.data;
        }
      });
    },
    ttQyCkDataPage() {
      this.loading = true;
      let form = Object.assign({}, this.form, this.pageData);
      form.startTime = this.times[0];
      form.endTime = this.times[1];
      delete form.total;
      // this.getYearMonthList(
      //     this.getNewDate(this.times[0]),
      //     this.getNewDate(this.times[1]),
      //     form.checkYearMonth
      // );
      ttQyCkDataPage(form).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;
          this.pageData.count = res.data.count;
          this.tableHeader = this.tableHeaderForm();
          if (this.month) {
            this.timeList = getMonthBetween(form.startTime, form.endTime)
          } else {
            this.timeList = getYearArr(form.startTime, form.endTime)
          }
          this.monthShow = this.month
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.ttQyCkDataPage();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.ttQyCkDataPage();
    },
    //重置
    rest() {
      this.$refs["form"].resetFields();
      this.times = [];
      this.month = true
      this.checkDate = "month";
      this.getDefaultDate();
    },
    searchForm() {
      this.pageData.page = 1;
      if (this.times[0] && !this.times[1]) {
        this.$message.warning("结束时间不能为空");
        return false;
      } else if (!this.times[0] && this.times[1]) {
        this.$message.warning("开始时间不能为空");
        return false;
      }

      this.ttQyCkDataPage();


    },
    //开始结束时间
    timeChange(e) {
      this.times = e.value
      if (e.swatchDateType == "month") {
        this.form.checkYearMonth = false;
        this.checkDate = "month";
      } else {
        this.form.checkYearMonth = true;
        this.checkDate = "year";
      }
      this.form.qyemsModArray = []
      this.form.cxingArray = []
      this.ttQyCkDataEnterpriseList();
      this.ttQyCkDataCxingList()
    },
    changeFun(type) {
      let isArr = Array.isArray(this.form[type]);
      if (type == "cxflei1Array") {
        if (!this.form.cxflei1Show) {
          this.form.cxflei2Show = false;
          this.form.cxingShow = false;
        }
      }

      if (type == "cxflei2") {
        if (!this.form.cxflei1Show) {
          this.form.cxingShow = false;
        }
      }

      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        this.$set(this.form, type, "");
      }

      if (this.form.cxflei2Show && type == 'cxflei2Array' && this.form.cxflei1Array.length <= 0) {
        this.$message("请选择车辆类型");
      }
    },
    cxflei1Change(e) {
      if (e) {
        getCxflei2InfoList({
          cxflei1: e.join(",")
        }).then((res) => {
          if (res.ok) {
            this.cxflei2Data = res.data;
          }
        });
      } else {
        this.cxflei2Data = [];
      }
    },
    //时间头部
    getNewDate(nowdate) {
      let timeHeader = "";
      let date = new Date(),
          year = date.getFullYear(),
          month =
              date.getMonth() + 1 >= 10 ?
                  date.getMonth() + 1 :
                  "0" + date.getMonth() + 1;
      if (nowdate) {
        if (this.form.checkYearMonth) {
          timeHeader = nowdate + "-01-01";
        } else {
          timeHeader = nowdate + "-01";
        }
      } else {
        if (this.form.checkYearMonth) {
          timeHeader = year + "-01-01";
        } else {
          timeHeader = year + "-" + month + "-01";
        }
      }

      return timeHeader;
    },
    //前面头部
    tableHeaderForm() {
      let tableArr = [];
      for (let key in this.form) {
        if (typeof this.form[key] == "boolean") {
          if (this.form[key]) {
            switch (key) {
              case "qyemsModShow":
                tableArr.push({
                  label: "企业名称",
                  prop: "qyems_mod",
                });
                break;
              case "cxflei1Show":
                tableArr.push({
                  label: "车辆类型",
                  prop: "cxflei1",
                });
                break;
              case "cxflei2Show":
                tableArr.push({
                  label: "细分类别",
                  prop: "cxflei2",
                });
                break;
              case "cxingShow":
                tableArr.push({
                  label: "车型名称",
                  prop: "cxing",
                });
                break;
            }
          }
        }
      }
      return tableArr;
    },
    getYearMonthList(startTime, endTime, isYear) {
      getYearMonthList({
        startTime,
        endTime,
        isYear
      }).then((res) => {
        if (res.ok) {
          this.tableHeaderTime = res.data;
        }
      });
    },
    checkName(value) {
      let id = value[0];
      let str = this.qyemsModData.filter((item) => item.id == id);
      if (str.length && str.length > 0) {
        return str[0].enterprise_name;
      }
    },

    //导出
    Export() {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
      let _self = this;
      this.excelLoading = true;
      ttQyCkDataExport(this.form).then((res) => {
        this.excelLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "出口数据.xlsx");
          }
        });
      });


    }
    ,
    //导出
    ExportAll() {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
      let _self = this;
      this.excelAllLoading = true;
      ttQyCkDataExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "出口全部数据.xls");
          }
        });
      });
    }
    ,
    //企业名称切换
    qymcChange(e) {
      this.form.cxingArray = [];
      this.form.qyemsModArray = e
      this.ttQyCkDataCxingList(e);
    }
    ,
    //
    closeqy(e) {
      this.form.cxingArray = [];
      this.ttQyCkDataCxingList(this.form.qyemsModArray);
    }
    ,
    ttQyCkDataCxingList(e) {
      let form = {
        isYear: this.form.checkYearMonth,
        startTime: this.times[0],
        endTime: this.times[1],
        ids: e || [],
      };
      ttQyCkDataCxingList(form).then((res) => {
        if (res.ok) {
          this.cxData = res.data;
        }
      });
    }
    ,
    arraySpanMethod({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      let col = this.tableHeader.length;
      if (this.pageData.page == 1) {
        if (col > 0) {
          if (rowIndex === 0) {
            if (columnIndex === 0) {
              return [1, this.tableHeader.length];
            } else if (columnIndex < col) {
              return [1, 0];
            }
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }

    }
    ,
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
