import scssVars from './newDataVariables.module.scss';

const formatter = (value) => Number.parseFloat(value) || 0;

export const topNavHeight = formatter(scssVars['top-nav-height']);
export const headerHeight = formatter(scssVars['header-height']);
export const breadcrumbHeight = formatter(scssVars['breadcrumb-height']);
export const appContentSpaceVertical = formatter(scssVars['app-content-space-vertical']);
export const appContentSpaceHorizontal = formatter(scssVars['app-content-space-horizontal']);
export const appContentPaddingVertical = formatter(scssVars['app-content-padding-vertical']);
export const appContentPaddingHorizontal = formatter(scssVars['app-content-padding-horizontal']);
export const pageContainerPaddingVertical = formatter(scssVars['page-container-padding-vertical']);
export const pageContainerPaddingHorizontal = formatter(scssVars['page-container-padding-horizontal']);
export const actionNavHeight = formatter(scssVars['action-nav-height']);
export const actionBarHeight = formatter(scssVars['action-bar-height']);
export const topBannaHeight = formatter(scssVars['top-banna-height']);
