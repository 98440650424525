<template>
  <section
    id="layout-container"
    :class="['layout-container', className, {
      'fixed-top-nav': checkLoginName == 'zgqcgyxxw',
      'fixed-top-Banna': topbanna,
      'fixed-header': settings.header.fixed,
      'fixed-sider': settings.sider.fixed,
      'sider-collapsed': settings.sider.collapsed,
      'sider-large': !settings.sider.collapsed && isNewDataRoute,
      'sider-disappeared': siderDisappeared,
    }]"
  >

    <template v-if="topbanna">
      <VtopBanna @click-close="handleClose" @click="showLogin()" />
      <div class="top-banna-placeholder" />
    </template>

    <template v-if="checkLoginName == 'zgqcgyxxw'">
      <VtopNav
        :show="show"
        :user-name="userName"
        @command="commandTopNav"
        @click="showLogin()"
      />
      <div class="top-nav-placeholder" />
    </template>

    <Vnav
      v-if="checkLoginName == 'zgqcgyxxw'"
      :menu="routerMenu"
      :show="show"
      :user-name="userName"
      :check-login-name="checkLoginName"
      @command="commandTopNav"
      @clickLink="clickLink"
      @click="showLogin()"
      @searchs="searchBtn"
    />
    <Vnavfuao
      v-else
      :menu="routerMenu"
      :check-login-name="checkLoginName"
      :show="show"
      :user-name="userName"
      :children-menu="childrenMenu"
      @searchs="searchBtn"
      @command="commandTopNav"
    />

    <div v-if="checkLoginName == 'zgqcgyxxw'" class="header-placeholder" />

    <Sider
      :is-data-route="isDataRoute"
      :is-new-data-route="isNewDataRoute"
      :sider-disappeared="siderDisappeared"
      :is-data="isData"
      :is-login="isLogin"
      :get-if-can-view-new-version="getIfCanViewNewVersion"
      :children-menu="childrenMenu"
      :getrouter-name="getrouterName"
      :active-menu-path="activeMenuPath"
      @click="handleClickGoToNewData"
      @select="handleSelect"
      @ceshi="ceshi"
    />

    <div class="app-content" :class="{'app-content-top': checkLoginName !== 'zgqcgyxxw'}">
      <Vbreadcrumb v-if="settings.hasBreadcrumb" />
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </div>

    <VLinks v-if="links" />
    <Vfooter v-if="footer" />

    <Fab :contact="contact" :investigate="investigate" :service="service" />
    <AIService />

    <Vfeedback
      v-if="shows"
      :aside-data="selectFirstMenuData"
      @close="shows = false"
      @submit="feedbackBtn"
    />
    <VSySq v-if="sySqShow" @close="sySqShow = false" @click="sySqBtn" />

    <!--    <VloginImg v-if="loginFlag" @close="loginFlag = false"/>-->
    <Vcollection v-if="colShow" :aside-data="selectFirstMenuData" @close="colShow = false" />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleGqTz"
      width="30%"
    >
      <img src="@/assets/img/gqtz2023.jpg">
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
      <!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      <!--    </span>-->
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      top="0"
      style="height:auto;"
      :modal="false"
    >
      <div style="height: auto">
        <iframe
          id="bdIframe"
          :src="iframeSrc"
          style="width:100%;height:100%;"
          frameborder="0"
          scrolling="yes"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="绑定手机号"
      :visible.sync="dialogFormVisible"
      width="600px"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item label="账号：" :label-width="formLabelWidth">
          {{ userName }}
        </el-form-item>
        <el-form-item
          label="绑定手机号："
          :label-width="formLabelWidth"
          prop="bindPhone"
        >
          <el-input
            v-model="form.bindPhone"
            autocomplete="off"
            prefix-icon="el-icon-phone"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item
          label="短信验证码"
          prop="yzm"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.yzm"
            style="width: 300px;"
            prefix-icon="el-icon-key"
            placeholder="请输入6位短信验证码"
          />
          <el-button
            style="height: 40px; width: 136px;margin-left: 4px;"
            type="primary"
            :class="{ 'disabled-style': getCodeBtnDisable }"
            :disabled="getCodeBtnDisable"
            @click="getCode()"
          >{{ codeBtnWord }}
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >绑 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="绑定手机号"
      :visible.sync="dialogFormVisible2"
      width="500px"
    >
      <el-form>
        <el-form-item label="账号：" :label-width="formLabelWidth">
          {{ userName }}
        </el-form-item>
        <el-form-item label="绑定手机号：" :label-width="formLabelWidth">
          {{ bindPhone }}
          <!--          <a style="margin-left: 10px" @click="dialogFormVisible2 = false;dialogFormVisible = true"><i class="el-icon-edit"></i>修改</a>-->
          <el-button
            style="margin-left: 20px"
            type="text"
            icon="el-icon-edit"
            @click="
              dialogFormVisible2 = false;
              dialogFormVisible = true;
            "
          >修改
          </el-button>

          <el-popover v-model="visible" placement="top" width="230">
            <p>确定解绑手机号 {{ bindPhone }} ?</p>
            <div style="text-align: right; margin-top: 10px">
              <el-button
                size="mini"
                type="text"
                @click="visible = false"
              >取消</el-button>
              <el-button
                type="primary"
                size="mini"
                @click="unBinding"
              >确定</el-button>
            </div>
            <el-button
              slot="reference"
              style="color: red"
              type="text"
              icon="el-icon-delete"
            >解绑</el-button>
          </el-popover>

          <!--          <el-button type="primary" icon="el-icon-edit" @click="dialogFormVisible2 = false;dialogFormVisible = true">修改</el-button>-->
          <!--          <el-button type="danger" icon="el-icon-delete">解绑</el-button>-->
        </el-form-item>
      </el-form>
      <!--      <div slot="footer" class="dialog-footer">-->
      <!--        <el-button type="primary" @click="dialogFormVisible2 = false;dialogFormVisible = true">修 改</el-button>-->
      <!--        <el-button type="danger" @click="submitForm('ruleForm')">解 绑</el-button>-->
      <!--        <el-button @click="dialogFormVisible2 = false">取 消</el-button>-->

      <!--      </div>-->
    </el-dialog>
  </section>
</template>

<script>
import VtopNav from '../components/topNav.vue'
import Vnav from '../components/nav.vue'
import Vnavfuao from '@/components/navfuao.vue'
import VSySq from '@/components/sySqTk.vue'
import Vbreadcrumb from '@/components/breadcrumb.vue'
import VtopBanna from '@/components/topBanna.vue'
import Vfeedback from '@/components/feedback.vue'
// import VloginImg from '@/components/loginImg.vue'
import Vcollection from '@/components/collection.vue'

import { getUserInfo, removetUserInfo, logRecords } from '@/utils/auth.js'
import { logout, sendBindPhoneMessage } from '@/api/login.js'
import { mapGetters } from 'vuex'
// 菜单管理
import {findMenu, getDataQueryMenus, selectFirstMenu} from '@/api/menu.js'
// 意见反馈
import { ttFeedbackManagement } from '@/api/feedback.js'
// 收藏
import { updateBindPhone } from '@/api/common.js'
import { getCurrentUser } from '@/api/login.js'
import canViewNewVersion from '@/utils/canViewNewVersion'

import VLinks from '@/components/links.vue'
import Vfooter from '@/components/footer.vue'
import Fab from '@/components/Fab.vue'
import AIService from '@/components/AIService'

import Sider from './Sider'
import store from "@/store";
export default {
  components: {
    VtopNav,
    Vnav,
    Vnavfuao,
    Sider,
    Vfeedback,
    // VloginImg,
    VSySq,
    Vcollection,
    Vbreadcrumb,
    VLinks,
    Vfooter,
    Fab,
    AIService,
    VtopBanna
  },
  provide() {
    return { appContainerIns: this }
  },
  data() {
    var validateUserPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号码'))
      } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    var validatePhoneYzm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写验证码'))
      } else if (value != this.yzm) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    return {
      childrenMent: [],
      menuKey: '',
      show: false,
      userName: '',
      isDataRoute: false,
      isNewDataRoute: false,
      siderDisappeared: true,
      checkLoginName: '',
      // 意见反馈菜单
      selectFirstMenuData: [],
      shows: false,
      visible: false,
      // 收藏
      colShow: false,
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      hfshow: true,
      loginFlag: false,
      sySqShow: false,
      fjtzUrl: require('@/assets/img/gqtz2023.jpg'),
      dialogVisible: false,
      iframeSrc: 'https://www.wjx.cn/vm/hyVeQ3K.aspx',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      waitTime: 61, // 获取验证码按钮失效时间
      dialogVisibleGqTz: false,
      form: {
        bindPhone: '',
        userId: '',
        yzm: ''
      },
      formLabelWidth: '120px',
      rules: {
        bindPhone: [
          { required: true, trigger: 'blur', validator: validateUserPhone } // 写入验证器
        ],
        yzm: [{ required: true, validator: validatePhoneYzm, trigger: 'blur' }]
      },
      yzm: '',
      bindPhone: '',
      timer: '',
      appSlots: {},
      links: false,
      topbanna: false,
      footer: false,
      contact: false,
      investigate: false,
      service: false

    }
  },
  computed: {
    ...mapGetters({
      // routerMenu: 'permission_routes',
      loading: 'loading',
      settings: 'settings'
    }),
    routerMenu: {
      get() {
        return this.$store.state.permission.routes
      },
      set(v) {
        this.$store.state.permission.routes = v
      }
      // set方法只写下面这一行也是可以的
      // set(){}
    },
    getRouterMenu() {
      const userInfo = getUserInfo() && getUserInfo().roleKey == 'paidrole' && getUserInfo().menuName.includes('国际化')
      const hasLogin = userInfo && getUserInfo().roleKey != 'trialrole'
      if (hasLogin) {
        return this.routerMenu
      } else {
        return this.routerMenu.filter(item => item.name !== '国际化')
      }
    },
    getIfCanViewNewVersion() {
      const userInfo = getUserInfo()
      return userInfo && userInfo.userId && canViewNewVersion(userInfo.userId)
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          const reg = /^1[3456789]\d{9}$/
          if (reg.test(this.form.bindPhone)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {}
    },
    // 当前是否是 数据模块的某个页面
    isData() {
      return this.$route.meta.acName === '数据'
    },
    isLogin() {
      const userInfo = getUserInfo()
      return userInfo && userInfo.userId
    },
    getrouterName() {
      return this.$route.name
    },
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form.bindPhone)) {
        return false
      }
      return true
    },
    activeMenuPath() {
      // name 有重复的，比如数据栏目下的国内产销数据和新能源专区都有 乘用车-批发量和零售量
      // 所以这里用 path
      return this.$route.path
    },
    className() {
      let className
      const { settings, appSlots } = this

      if (settings.hasBreadcrumb && appSlots.actionNav) {
        className = 'with-breadcrumb-action-nav'
      } else if (settings.hasBreadcrumb) {
        className = 'with-breadcrumb'
      } else if (settings.breadcrumbTrail && appSlots.actionNav) {
        className = 'with-breadcrumb-action-nav'
      } else if (settings.breadcrumbTrail) {
        className = 'with-breadcrumb'
      } else if (appSlots.actionNav) {
        className = 'with-action-nav'
      }
      if (process.env.VUE_APP_CHECKLOGIN == 'fuao') {
        className = 'with-breadcrumb-new'
      }

      return className
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to, oldVal) {
        // this.$refs.layoutMain.scrollTop = 0
        this.menuKey = to.name == '企业信息详细' ? '企业信息' : to.name
        this.setHidden(to)
        this.filterPath(to.meta.acName)
        this.showtopbanna(this.checkLoginName, to)
      }
    },
    checkLoginName: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.showtopbanna(newVal, this.$route)
      }
    }
  },
  created() {
    // this.hfplayer();

    // this.loginFlag = getUserInfo().loginFlag;
    this.$bus.$on('loginFlag', msg => {
      if (msg == true) {
        this.checkLoginName = process.env.VUE_APP_CHECKLOGIN
        // this.routerMenu = this.$store.state.permission.routes
        const pId = this.$route.meta.acName || ''
        this.menuKey =
            this.$route.name == '企业信息详细' ? '企业信息' : this.$route.name
        this.setHidden(this.$route)
        this.filterPath(pId)
        const userInfo = getUserInfo()
        this.form.userId = userInfo.userId
        this.userName = userInfo.loginName != null && userInfo.loginName !== '' ? userInfo.loginName : userInfo.userName
        this.show = true
        this.init(userInfo.userId)
        this.routerMenu.set(this.$store.getters.permission_routes)
      }
    })
    this.checkLoginName = process.env.VUE_APP_CHECKLOGIN
    const pId = this.$route.meta.acName || ''
    this.menuKey =
      this.$route.name == '企业信息详细' ? '企业信息' : this.$route.name
    this.setHidden(this.$route)
    this.filterPath(pId)
    const userInfo = getUserInfo()
    if (this.checkLoginName == 'zgqcgyxxw') {
      if (getUserInfo()) {
        // this.getCurrentUser();

        this.form.userId = userInfo.userId
        this.userName = userInfo.loginName
        this.show = true
      } else {
        this.show = false
      }
    } else {
      if (!getUserInfo()) {
        this.$router.push('/login')
      } else {
        this.form.userId = userInfo.userId

        this.userName = userInfo.loginName
        this.show = true
      }
    }
  },
  mounted() {
    this.selectFirstMenu()
    // this.hfplayer()
    const route = this.$route
    this.menuRecordLog(route)
  },
  methods: {
    async init(userId) {
      let menuData = null; let dataQueryMenus = {}
      menuData = await findMenu({ userId })
      // 生成并保存基本路由
      this.$store.dispatch('permission/generateRoutes', menuData.data)
      // 查询 数据栏目-数据查询的菜单，因为这部分是后台动态配置的
      dataQueryMenus = await getDataQueryMenus({ userId })
      // 生成并保存新版数据栏目的路由
      this.$store.dispatch('permission/saveDataQueryMenus', dataQueryMenus.data)
    },
    handleClose() {
      sessionStorage.setItem('showactionBanner', 'no')
      this.showtopbanna()
    },
    showtopbanna(checkLoginName, route) {
      // if ( sessionStorage.getItem('showactionBanner') === 'no') {
      this.topbanna = false
      return
      // }
      if (checkLoginName === 'zgqcgyxxw' && route.name === '首页') {
        this.topbanna = true
      } else {
        this.topbanna = false
      }
      // console.log(this.topbanna)
    },
    setHidden(route) {
      let isNewDataRoute = false
      const isDataRoute = this.checkLoginName === 'fuao' ? route.matched?.[0]?.name === '数据' : route.matched?.[1]?.name === '数据'
      if (isDataRoute) {
        const paths = [
          'data',
          'domesticData',
          'cycCar',
          'exportData',
          'carOwnership',
          'indicators',
          'productionRegion',
          'engineData',
          'foreignSalesData',
          'recommendedModels',
          'exemptionFromPurchaseTax',
          'roadTransportVehicle',
          'macroEnvironment'
        ]
        const re = new RegExp(`^\/data\/(${paths.join('|')})\/`)
        isNewDataRoute = !re.test(route.path)
      }

      this.isDataRoute = isDataRoute
      this.isNewDataRoute = isNewDataRoute
      this.siderDisappeared = isNewDataRoute ? false : route.meta.hidden == 1
    },
    // 点击前往新版
    handleClickGoToNewData() {
      // 埋点
      logRecords(
        '数据', // column1 写死
        this.$route.name, // column2 数据表名称，传当前菜单的名称
        '3', // type 写死，3 表示操作
        '', // tableName 不传也行
        '', // articleId 不传也行
        '', // attachName 不传也行
        '', // articleName 不传也行
        '', // inputBox
        '新版入口' // buttonName
      )
      sessionStorage.removeItem('version')
      this.$store.dispatch('permission/changeToNewDataRoute', true)
    },
    isvalidPhone(phone) {
      const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
      return reg.test(phone)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateBindPhone(this.form).then((res) => {
            if (res.ok) {
              this.dialogFormVisible = false
              this.$message.success('绑定成功')
              this.form.bindPhone = ''
              this.form.yzm = ''
              clearInterval(this.timer)
              this.codeBtnWord = '获取验证码'
              this.getCodeBtnDisable = false
              this.waitTime = 61
            }
          })
        } else {
          return false
        }
      })
    },
    unBinding() {
      updateBindPhone({ userId: this.form.userId, bindPhone: '' }).then(
        (res) => {
          if (res.ok) {
            this.$message.success('解绑成功')
            this.visible = false
            this.dialogFormVisible2 = false
          }
        }
      )
    },
    getCode() {
      if (this.phoneNumberStyle) {
        const params = {}
        params.receiver = this.form.bindPhone
        // 调用获取短信验证码接口
        sendBindPhoneMessage(params).then((res) => {
          if (res.ok) {
            this.yzm = res.data

            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success',
              center: true
            })
            // 因为下面用到了定时器，需要保存this指向
            const that = this
            that.waitTime--
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            this.timer = setInterval(function() {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(this.timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
              }
            }, 1000)
          }
        })
      }
    },
    opens() {
      this.showTz = false
      this.$alert('', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false
      }).catch(() => {
        this.showTz = true
      })
      var el = document.querySelector('.el-message-box')
      if (el) {
        el.style.cssText =
          'width: 960px;height: 450px;background-image: url(' +
          this.fjtzUrl +
          ');background-size:960px 450px;'
      }
      var el1 = document.querySelector(
        ' .el-message-box__headerbtn .el-message-box__close'
      )
      if (el1) el1.style.cssText = 'color: white;'
      var el2 = document.querySelector(' .el-message-box__headerbtn')
      if (el2) el2.style.cssText = ' top: 9px;right: 14px;'
    },
    isShow() {
      this.hfshow = false
    },
    mouseEnter() {
      this.fadeClass = ''
      this.hfshow = true
    },
    mouseLeave() {
      // this.fadeClass="animate__animated animate__fadeOutUp duration";
      // clearInterval();
      // this.tpImg = require('@/assets/img/gqtz3.jpg');
      this.fadeClass = ''

      this.hfshow = false
    },
    // 获取子菜单
    filterPath(name) {
      const filter = this.routerMenu.filter((item) => {
        return item.name == name
      })

      if (filter.length > 0 && filter[0].children) {
        this.childrenMenu = filter[0].children.filter(
          (item) => item.meta?.hidden == 0
        )
      } else {
        this.childrenMenu = []
      }
      this.childrenMenu.forEach((item, index) => {
        if (item.children) {
          this.childrenMenu[index].children = item.children.filter(
            (item) => item.meta.hidden == 0
          )
        }
      })
    },
    getCurrentUser() {
      getCurrentUser().then((res) => {
        if (res.ok) {
          this.loginFlag = res.data.loginFlag
          this.bindPhone = res.data.bindPhone

          if (
            this.loginFlag &&
            getUserInfo().roleKey == 'paidrole' &&
            getUserInfo().menuName.includes('数据')
          ) {
            this.dialogVisible = true

            // setTimeout(() => {
            //   this.loading = false;
            // }, 2000);

            setTimeout(function() {
              /**
               * iframe-宽高自适应显示
               */
              const oIframe = document.getElementById('bdIframe')
              const deviceWidth = document.documentElement.clientWidth
              const deviceHeight = document.documentElement.clientHeight
              // oIframe.style.width = Number(deviceWidth) - 220 + "px"; //数字是页面布局宽度差值
              oIframe.style.width = Number(deviceWidth) // 数字是页面布局宽度差值
              oIframe.style.height = Number(deviceHeight) - 150 + 'px' // 数字是页面布局高度差
            }, 100)
          }
          // if (this.loginFlag && getUserInfo().roleKey == "paidrole" && getUserInfo().menuName.includes("数据")) {
          //
          //   this.dialogVisible = true
          //
          //   // setTimeout(() => {
          //   //   this.loading = false;
          //   // }, 2000);
          //
          //   setTimeout(function () {
          //     /**
          //      * iframe-宽高自适应显示
          //      */
          //     const oIframe = document.getElementById("bdIframe");
          //     const deviceWidth = document.documentElement.clientWidth;
          //     const deviceHeight = document.documentElement.clientHeight;
          //     // oIframe.style.width = Number(deviceWidth) - 220 + "px"; //数字是页面布局宽度差值
          //     oIframe.style.width = Number(deviceWidth); //数字是页面布局宽度差值
          //     oIframe.style.height = Number(deviceHeight) - 150 + "px"; //数字是页面布局高度差
          //   }, 100);
          // }
        }
      })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin({ flag: true })
    },
    selectFirstMenu() {
      selectFirstMenu().then((res) => {
        if (res.ok) {
          this.selectFirstMenuData = res.data

          this.selectFirstMenuData = res.data.filter(
            (item) => !item.name.includes('商用车')
          )
        }
      })
    },
    commandTopNav(name) {
      if (name == 'contact') {
        // this.$refs.layoutMain.scrollTop = this.$refs.layoutMain.scrollHeight
        return
      }
      if (!getUserInfo()) {
        this.showLogin()
        return false
      }
      if (name == 'bindPhone') {
        getCurrentUser().then((res) => {
          if (res.ok) {
            this.bindPhone = res.data.bindPhone
            if (this.bindPhone != '' && this.bindPhone != null) {
              this.dialogFormVisible2 = true
            } else {
              this.dialogFormVisible = true
            }
          }
        })
      }
      if (name == 'feedback') {
        this.shows = true
      }
      if (name == 'sysq') {
        this.sySqShow = true
      }
      if (name == 'logout') {
        this.$confirm('是否退出登录', '提示', {
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          logout().then((res) => {
            if (res.ok) {
              sessionStorage.removeItem('version')
              if (this.checkLoginName == 'zgqcgyxxw') {
                removetUserInfo()
                window.location = '/'
              } else {
                removetUserInfo()
                this.$router.push('/login')
              }
            }
          })
        })
        var el = document.querySelector('.el-message-box')
        if (el) el.style.cssText = ''
        var el1 = document.querySelector(
          ' .el-message-box__headerbtn .el-message-box__close'
        )
        if (el1) el1.style.cssText = ''
        var el2 = document.querySelector(' .el-message-box__headerbtn')
        if (el2) el2.style.cssText = ''
      }

      if (name == 'collection') {
        this.colShow = true
      }
    },
    feedbackBtn(item) {
      ttFeedbackManagement(item).then((res) => {
        if (res.ok) {
          this.$message({
            message: '反馈成功',
            type: 'success'
          })
          this.shows = false
        }
      })
    },
    sysqBtn() {},
    handleSelect() {
      this.$store.dispatch('notice/SET_PC', 0)
      this.$store.dispatch('notice/SET_CPLB', '')
      this.$store.dispatch('common/SET_qiehuan', '')
    },
    ceshi(item) {
      // if (!getUserInfo()) {
      //   if (item.name == '国内产销数据' || item.name == '乘用车分车型' || item.name == '出口数据' || item.name == '汽车保有量' || item.name == '经济指标' || item.name == '分地区产量'
      //       || item.name == '发动机数据' || item.name == '世界汽车工业' || item.name == '推荐车型目录' || item.name == '免征购置税目录' || item.name == '道路运输车辆达标车型' || item.name == '宏观环境'
      //   ) {
      //     this.showLogin2();
      //   }
      // }
      this.$router.push(item.path)
      this.menuRecordLog(item)
      // let name = this.$route.name;
      // if(name=='产能分布详细'){
      //
      // }
    },
    // 显示登录弹窗
    showLogin2() {
      this.$Vlogin2({ flag: true })
    },
    searchBtn(e) {
      // if (this.$route.path != '/FullTextRetrieval') {
      this.$router.push({
        path: '/FullTextRetrieval',
        query: { keywords: e }
      })
      // }
    },

    // hfplayer() {
    //   setInterval(() => {
    //     $('#fadeId').slideUp('slow');
    //   }, 6000);
    //
    //   setTimeout(() => {
    //     this.hfshow = false;
    //   }, 6600);
    //   // setInterval(() => {
    //   //   $('#fadeId2').slideDown(2000);
    //   // }, 9000);
    // },

    clickLink(item) {
      const obj = {
        meta: {
          acName: item.name
        },
        name: item.children[0].name
      }
      this.menuRecordLog(obj)
    },
    // 访问记录
    menuRecordLog(item) {
      if (getUserInfo()) {
        logRecords(item.meta.acName, item.name, '', '', '', '', '', '', '')
        // if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

        // }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";
.app-content-top{
  margin-top: 58px !important;
}
.top-nav-placeholder {
  height: $top-nav-height;
}
.top-banna-placeholder {
  height:  $top-banna-height;
}
.header-placeholder {
  height: $header-height;
}

.layout-container {
  min-height: 0;
}
.img {
  display: inline-block;
  height: 20px;
  width: 20px;
  color: #0d57bc;
  background: url(~@/assets/img/gjhicon.png) no-repeat;
  background-size: 100% 100%;
}
// 前往新版的提示
.go-to-new-data-tip {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 15px;
  background-color: #0d57bc;
  color: #fff;

  i {
    margin-left: 10px;
  }
}

.layout-container {
  background: #f6f6f6;
}

.layout-main {
  position: relative;
  min-height: 0;
}

.layout-main.hide {
  margin: 0;
}

#layout-container .layout-header,
#layout-container .layout-sider {
  background: #fff;
}

.el-submenu.is-active {
  background: rgba(13, 87, 188, 0.03) !important;
  .el-submenu__title {
    color: #0d57bc !important;
    span {
      color: #0d57bc !important;
    }
    i {
      color: #0d57bc !important;
    }
  }
}
#layout-container .layout-sider {
  margin-right: 16px;
  margin-top: 16px;

  //  box-shadow: 0px 4px 8px rgba(214, 214, 214, 0.5);
  .el-menu-item.is-active,
  .el-menu-item:hover {
    background: rgba($color: #0d57bc, $alpha: 0.03);
    color: #0d57bc;
  }
}

#layout-container .layout-header {
  padding: 0;
}

#layout-container .el-menu {
  border-right: none;
}

#layout-container .el-menu .el-menu-item {
  font-weight: bold;
}

.slider-leave-active {
  transition: all 1s;
  transform: translate3d(0, -74px, 0);
}

.duration {
  animation-duration: 8s;
}

#fadeId {
  height: 120px;
  width: 100%;
  background: url("../assets/img/xtwh1.jpg") center center no-repeat;
}

#fadeId2 {
  max-width: 100%;
  overflow: hidden;
  height: 100px;
  background: url("../assets/img/gqtz3.jpg") center center no-repeat;
}

#fadeId2:hover {
  max-width: 100%;
  overflow: hidden;
  height: 350px;
  background: url("../assets/img/gqtz2.jpg") center center no-repeat;
}

//.bounce-enter-active {
//  animation: bounce-on 5s;
//}
//.bounce-leave-active {
//  animation: bounce-on 5s reverse;
//}
//@keyframes bounce-on {
//  0% {
//    transform: scale(0);
//  }
//  50% {
//    transform: scale(1.5);
//  }
//  100% {
//    transform: scale(1);
//  }
//}
</style>
<style>
#layout-container .el-menu .el-submenu .el-submenu__title {
  font-weight: bold !important;
}

#layout-container .el-menu-item-group__title {
  padding: 0px 0 0px 20px !important;
}
</style>
