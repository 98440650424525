<template>
  <div class="app-content-like">
    <div v-if="$slots.actionNav" :class="['action-nav-container', actionNavClass]">
      <div class="max-container">
        <slot name="actionNav" />
      </div>

    </div>
    <Vbreadcrumb :style="{backgroundColor: '#f3f5f7'}" v-if="settings.breadcrumbTrail" />

    <div v-if="$slots.actionTitle">
        <slot name="actionTitle" />
    </div>

    <div
      :class="['page-container', {
        'max-container': hasMaxContainer,
        'with-action-bar': $slots.actionBar,
      }]"
    >
      <template v-if="$slots.left">
        <div v-if="mode !== 'relative'" class="column-left-placeholder"></div>
        <ColumnBox :mode="mode" position="left">
          <slot name="left" />
        </ColumnBox>
      </template>

      <div :class="appContainerIns.isNewDataRoute? 'column-main column-main-zindex':'column-main'">
        <DataNavBar
          v-if="appContainerIns.isNewDataRoute"
          class="sticky-container"
        />
        <slot />
      </div>

      <template v-if="$slots.right">
        <div v-if="mode !== 'relative'" class="column-right-placeholder"></div>
        <ColumnBox :mode="mode" position="right">
          <slot name="right" />
        </ColumnBox>
      </template>

      <template v-if="$slots.actionBar">
        <div class="action-bar-container no-margin-top">
          <slot name="actionBar" />
        </div>
      </template>
    </div>

    <template v-if="$slots.actionBar">
      <div class="action-bar-placeholder"></div>
    </template>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';

import Vbreadcrumb from '@/components/breadcrumb.vue'
import DataNavBar from '../newDataLayout/comp/moduleNavBar';
import ColumnBox from './ColumnBox';

export default {
  name: 'PageContainer',
  inject: {
    appContainerIns: {
      default: () => ({}),
    },
  },
  props: {
    fullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    actionNavClass: {
      required: false,
      type: String,
      default: undefined,
    },
    actionTitleClass: {
      required: false,
      type: String,
      default: undefined,
    },
    breadcrumb: {
      required: false,
      type: Boolean,
      default: false,
    },
    breadcrumbTrail: {
      required: false,
      type: Boolean,
      default: false,
    },
    actionNavInformation: {
      required: false,
      type: String,
      default: '',
    },
    links: {
      required: false,
      type: Boolean,
      default: false,
    },
    footer: {
      required: false,
      type: Boolean,
      default: false,
    },
    contact: {
      required: false,
      type: Boolean,
      default: false,
    },
    investigate: {
      required: false,
      type: Boolean,
      default: false,
    },
    service: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataNavBar,
    ColumnBox,
    Vbreadcrumb,
  },
  computed: {
    ...mapGetters({
      settings: 'settings',
    }),
    hasMaxContainer() {
      if (this.fullWidth) {
        return false;
      }

      return this.appContainerIns.siderDisappeared === true;
    },
    mode() {
      // const { path } = this.$route;
      // const isHome = path === '/';
      // return isHome ? 'relative' : 'absolute';
      // return 'relative';
      const { path } = this.$route;
      const isHome = path.startsWith('/workInformation/enterpriseInfo/detail/') || path.startsWith('/workInformation/enterpriseInfo/compared');
      return isHome ? 'absolute' : 'relative';
    },
  },
  watch: {
    $slots: {
      deep: true,
      immediate: true,
      handler(slots) {
        const hasOwn = Object.prototype.hasOwnProperty;
        const prevSlots = this.appContainerIns.appSlots;
        const currSlots = slots || {};
        const nextSlotsName = [...new Set([
          ...Object.keys(prevSlots),
          ...Object.keys(currSlots),
        ])];

        const nextSlots = {};
        nextSlotsName.forEach((name) => {
          nextSlots[name] = hasOwn.call(currSlots, name)
            ? currSlots[name]
            : null;
        });

        this.appContainerIns.appSlots = {
          ...this.appContainerIns.appSlots,
          ...nextSlots,
        };
      },
    },
    breadcrumb: {
      immediate: true,
      handler(value) {
        if (value) {
          this.enableBreadcrumb();
        } else {
          this.disableBreadcrumb();
        }
      },
    },
    breadcrumbTrail:{
      immediate: true,
      handler(value) {
        if (value) {
          this.enableBreadcrumbTrail();
        } else {
          this.disableBreadcrumbTrail();
        }
      },
    },
    links: {
      immediate: true,
      handler(value) {
        this.appContainerIns.links = value;
      },
    },
    footer: {
      immediate: true,
      handler(value) {
        this.appContainerIns.footer = value;
      },
    },
    contact: {
      immediate: true,
      handler(value) {
        this.appContainerIns.contact = value;
      },
    },
    investigate: {
      immediate: true,
      handler(value) {
        this.appContainerIns.investigate = value;
      },
    },
    service: {
      immediate: true,
      handler(value) {
        this.appContainerIns.service = value;
      },
    },
  },
  methods: {
    ...mapActions({
      enableBreadcrumb: 'settings/enableBreadcrumb',
      disableBreadcrumb: 'settings/disableBreadcrumb',
      enableBreadcrumbTrail: 'settings/enableBreadcrumbTrail',
      disableBreadcrumbTrail: 'settings/disableBreadcrumbTrail',
    }),
  },
};
</script>
<style lang="scss" scoped>
 .column-main-zindex {
  z-index: 1;
 }
</style>
