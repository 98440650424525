<template>
  <el-dialog
    :visible="show"
    append-to-body
    class="return-old-version-dialog"
    width="700px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClickClose"
  >
    <div class="title">
      购买完整数据表
      <i class="el-icon-close" @click="handleClickClose" />
    </div>
    <div class="content">
      <div class="tip-container">
        <img :src="require('@/assets/img/newData/info-circle-fill.png')" alt="">
        请填写您的信息，我们会在一个工作日内尽快与您联系
      </div>
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formDataRules"
        label-width="80px"
        label-position="left"
      >
        <el-form-item prop="company" label="您的公司">
          <el-input v-model="formData.company" size="small" :maxlength="20" placeholder="请输入" />
        </el-form-item>
        <el-form-item prop="name" label="您的姓名">
          <el-input v-model="formData.name" size="small" :maxlength="20" placeholder="请输入" />
        </el-form-item>
        <el-form-item prop="phone" label="您的电话">
          <el-input v-model="formData.phone" size="small" :maxlength="11" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button size="small" @click="handleClickClose">取消</el-button>
      <el-button type="primary" size="small" :loading="submitLoading" @click="handleClickSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  selectDictDataByRequirementType,
  insertRequirementRecord
} from '@/api/data_new.js'
import { getUserInfo } from '@/utils/auth.js'

export default {
  name: 'ContactUsDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    // 用于控制 调保存接口时，传的 requirementType 取字典中的哪个值
    dictLabel: {
      type: String,
      required: true
    }
  },
  data() {
    const phoneValidator = (_rule, value, callback) => {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!value || reg.test(value)) {
        callback()
        return
      }
      callback(new Error('请输入正确的电话号码'))
    }
    return {
      submitLoading: false,
      requirementTypeList: [],
      requirementType: undefined,
      formData: {
        company: undefined,
        name: undefined,
        phone: undefined
      },
      formDataRules: {
        company: [{ required: true, message: '请输入您的公司', trigger: 'change' }],
        name: [{ required: true, message: '请输入您的姓名', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入您的电话', trigger: 'change' },
          { validator: phoneValidator, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    show: {
      immediate: false,
      handler(val) {
        // 如果打开了弹窗，则从字典中获取需求类型
        if (val) {
          this.getRequirementType()
        }
      }
    }
  },
  methods: {
    // 从字典中，获取需求类型
    getRequirementType() {
      selectDictDataByRequirementType()
        .then(res => {
          if (res) {
          // 从字典列表中获取 分地区销量 的字典值, 用于提交接口的传参
            this.$set(this, 'requirementType', res.data.find(item => item.dictLabel === this.dictLabel)?.dictValue)
          }
        })
    },
    handleClickSubmit() {
      const userInfo = getUserInfo()
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const { company, name, phone } = this.formData
          insertRequirementRecord({
            userId: userInfo?.userId,
            requirementType: this.requirementType,
            company,
            name,
            phone
          }).then(res => {
            if (res && res.ok) {
              this.handleClickClose()
              this.$message.success('提交成功')
            } else {
              this.$message.error('提交失败')
            }
          })
            .catch(() => {
              this.$message.error('提交失败')
            })
            .finally(() => {
              this.submitLoading = false
            })
        }
      })
    },
    handleClear() {
      this.$set(this, 'formData', {
        company: undefined,
        name: undefined,
        phone: undefined
      })
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
      })
    },
    handleClickClose() {
      this.handleClear()
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.return-old-version-dialog {

  .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #1D2129;
    margin-bottom: 24px;

    i {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 20px;
      font-weight: bold;
      color: #4E5969;
      cursor: pointer;
    }
  }

  .content {
    .tip-block {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      color: #757575;
      background-color: #FEF6E4;
      border-radius: 4px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
}

.tip-container {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: #FEF7E4;
  border-radius: 4px;
  padding: 0 16px;
  color: #757575;
  font-size: 13px;
  margin-bottom: 24px;

  img {
    width: 14px;
    height: 14px;
    pointer-events: none;
    margin-right: 5px;
    user-select: none;
  }
}

::v-deep {

  .el-dialog__header{
    display: none;
  }

  .el-dialog__body {
    padding: 20px 24px 10px;
  }

  .el-input {
    width: 300px;
  }
}
</style>
