<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips"></VnotLogin>-->
    <!--    <template>-->
    <el-row>
      <el-col :span="14" style="padding-bottom: 21px;background: #FFFFFF">
        <newlistHead title="最新原创" url="assets/img/gradientImg.png" href="/policy/dynamic/listLocalOriginal" />
        <div v-for="(item) in localOriginalData" :key="item.id" class="box_box_title" @click="toHref('/policy/dynamic/LocalOriginalDetails/'+item.id,item,'解读报告','tt_policy_interpret')">

          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img style="width: 267px; height: 150px" :src="item.imageUrl ? item.imageUrl : defaultUrl" alt="">
            </div>
            <div class="flex_col">
              <div>
                <div
                  flex="c"
                  style="margin: 10px 0;-webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;"
                >

                  <p>{{ htmlRestore(item.title) }}</p>
                </div>
                <div class="content">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.updateTime }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="10" style="float: right">
        <newlistHead title="政策解读" url="assets/img/gradientImg.png" href="/policy/dynamic/listExplain" />
        <ul class="newUl ">
          <li v-for="item in policyExplainData" :key="item.id">

            <div class="newUl-link" @click="toHref('/policy/dynamic/contentExplain/'+item.id,item,'政策解读','tt_policy_interpret')">
              <template v-if="item.unscrambleUnit">
                <div class="newUl-tag">{{ item.unscrambleUnit }}</div>
              </template>
              <!--                <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

              <!--                <div class="newUl-title">{{ item.title }}</div>-->
              <!--                </el-tooltip>-->
              <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />

              <div class="newUl-time">{{ item.updateTime }}</div>
            </div>
          </li>
        </ul>
      </el-col>
    </el-row>
    <!--    </template>-->
  </PageContainer>
</template>
<script>
/**
 * @description: 政策动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { localOriginal, policyExplain } from '@/api/policy.js'
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { fmtDate } from '@/utils/date.js'
import { htmlRestore } from '@/utils/htmlRestore.js'
import { buryPoint } from '@/api/common'
import TextOverTooltip from '@/components/title/index'

export default {
  components: {
    TextOverTooltip
  },
  data() {
    return {
      showTips: [
        {
          title: '政策动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '政策库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '政策梳理',
          text: '专家梳理整合，真正读懂政策脉络'
        },
        {
          title: '政策大数据',
          text: '用数据深度理解政策结构和趋势'
        }
      ],
      defaultImg: require('@/assets/img/dnc-img.png'),
      loading: false,
      tableData: [],
      pagination: {},
      show: false,
      localOriginalData: [],
      policyAnalysisData: [],
      policyReportData: [],
      policyExplainData: [],
      newPolicyDate: [],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      }
    }
  },
  computed: {
    newDate() {
      return fmtDate('YY年mm月', new Date())
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.init()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 初始化加载
    init() {
      this.localOriginal({ page: 1, pageSize: 5, unscrambleUnit: '1' })
      // this.policyAnalysis({page: 1, pageSize: 9,unscrambleUnit:'4'});

      this.policyExplain({ page: 1, pageSize: 18 })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item, column2, tableName) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('政策', column2, '1', tableName, item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 本网原创
    localOriginal(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.localOriginalData = res.data.list
          this.localOriginalData.forEach((element) => {
            element.title = htmlRestore(element.title)
          })
        }
      })
    },
    // 政策分析
    policyAnalysis(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.policyAnalysisData = res.data.list
          this.policyAnalysisData.forEach((element) => {
            element.title = htmlRestore(element.title)
          })
        }
      })
    },
    // 政策解读
    policyExplain(params) {
      policyExplain(params).then((res) => {
        if (res.ok) {
          this.policyExplainData = res.data.list
          this.policyExplainData.forEach((element) => {
            element.title = htmlRestore(element.title)
          })
        }
      })
    },
    // 字符串转数组
    strSplit(str) {
      return str.split(',')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-grop {
  display: block;
  width: 100%;
  cursor: pointer;

  .img {
    display: block;
    max-width: 100%;
    height: 132px;
    width: 232px;
    margin-bottom: 26px;
    //margin: 0 auto;
  }

  .img-title,
  .img-time {
    padding-top: 10px;
  }

  .img-title {
    font-size: 15px;
    line-height: 22px;
    color: #333;
    font-weight: bold;
  }

  .img-time {
    font-size: 14px;
    line-height: 14px;
    color: #828282;
  }
}

.local-btn {
  width: 128px;
  height: 37px;
  border: 2px solid #0d57bc;
  border-radius: 48px;
  background: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #0d57bc;
}

.newUl-link {
  cursor: pointer;
}

.new-box {
  padding: 20px 30px;
  background-color: white;
}

.index-new-link {
  display: block;
  color: #000;
  padding-bottom: 11px;
  //border-bottom: 1px solid #dcdcdc;
  //padding-left: 20px;

  div {
    span {
      font-size: 16px;
      line-height: 16px;

      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        color: #bdbdbd;
        padding-left: 10px;
      }
    }
  }
}

.zcbz-item-bottom {
  width: 100%;
  height: 410px;
  //background: #f4f5f6;
  //padding: 5px 10px 0;

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;

    &-l {
      text-align: center;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 18px;
        color: #0d57bc;
        margin-top: 16px;
      }

      .img-box {
        width: 98px;
        height: 97px;
        line-height: 97px;
        text-align: center;

        img {
          vertical-align: middle;
        }
      }
    }

    &-r {
      display: flex;
      flex-direction: column;
      width: 391px;

      p {
        font-size: 15px;
        line-height: 23px;
        color: #2c2c2c;
      }

      a {
        border-radius: 48px;
        font-size: 16px;
        line-height: 20px;
        color: #0d57bc;
        margin-left: auto;
        margin-top: auto;
      }
    }
  }

  &-bottom-tab {
    display: flex;
    border-bottom: 1px solid #e0e0e0;

    li {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;

      &.ac {
        font-weight: bold;
        color: #0d57bc;

        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #0d57bc;
        }
      }
    }
  }

  &-bottom-tab-default {
    .tips {
      margin-top: 15px;
      font-size: 15px;
      line-height: 23px;
      text-indent: 36px;
      color: #2c2c2c;
    }
  }

  &-bottom-tab-content {
    width: 100%;
  }

  &-bottom-tab-list {
    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      padding-bottom: 7px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 14px;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .text {
        flex: 1;
      }

      .time {
        padding-left: 15px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .zcbz-new-class {
    border-bottom: 1px solid #e0e0e0;

    .title {
      position: relative;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      cursor: pointer;
      padding-bottom: 18px;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }
}
.zcbz-item-bottom-tab-content {
  width: 100%;
}
.zcbz-item-bottom-tab-list {
  margin-top: 20px;
}

.zcbz-item-bottom-tab-list a {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  padding-bottom: 7px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
}
.zcbz-item-bottom-tab-list a .text{
  flex: 1;
}
.zcbz-item-bottom-tab-list a .time {
  padding-left: 15px;
  font-size: 14px;
  line-height: 20px;
}
//.img-grop {
//  display: block;
//  width: 100%;
//
//  .img {
//    display: block;
//    max-width: 100%;
//    height: 132px;
//    width: 234px;
//    margin: 0 auto;
//
//  }
//}
.box_box_title {
  padding-top: 10px;

  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}
.content {
  color: #999999;
  font-size: 14px;
  line-height: 1.8333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-col-14 {
  width: 58%;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

</style>
