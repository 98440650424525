<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" :isCollection="false"/>
  </PageContainer>
</template>
<script>
import { ttHighInterview } from "@/api/Index.js";
export default {
  data() {
    return {
      data: {},
      props:{
        htmlContent:'content'
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.ttHighInterview(id);
  },
  methods: {
    ttHighInterview(params) {
      ttHighInterview(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
