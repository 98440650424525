<!--本网原创内容页面-->
<template>
  <PageContainer breadcrumb>
     <Vcontent :data="data" :props="props" />
  </PageContainer>
</template>
<script>
import {confidenceIndexDetail} from "@/api/Index";
export default {
  data() {
    return {
      data: {},
       props:{
        htmlContent:'htmlContent',
        summary:"introduction"
      }
    };
  },
  created() {
    let id = this.$route.params.id;
    this.confidenceIndexDetail(id);
  },
  methods: {
    confidenceIndexDetail(params) {
      confidenceIndexDetail(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
