<template>
  <div style="width: 100%; background: #fff">
    <div class="g-container">
      <div class="index-top-container">
        <div class="index-top-l">
          <div class="index-banner">
            <div class="index-banner-l">
              <Vbanner :banner="banner" />
            </div>
            <div class="index-banner-r" flex="1">
              <div class="index-banner-title">
                <div class="index-title-btn">
                  <span>本网观察</span>
                </div>
                <More toHref="/index/list/LocalWatchList" />
              </div>
              <div
                class="index-watch"
                v-for="item in ttLocalWatchPageSizeOne"
                :key="item.id"
              >
                <router-link
                  :to="'/index/detail/LocalWatchDetail/' + item.id"
                  tag="h1"
                  class="text-overflow"
                >
                  {{ item.title }}
                </router-link>
                <!-- <span>{{ item.publishDate }}</span> -->
              </div>
              <div class="index-banner-title">
                <div class="index-title-btn">
                  <span>最新快讯</span>
                </div>
                <More toHref="/index/list/industryInformationList" />
              </div>
              <ul class="index-new-timeLine">
                <li
                  v-for="item in ttIndustryInformationPageSizeFive"
                  :key="item.id"
                >
                  <!-- -->
                  <div class="index-new-timeLine-content">
                    <router-link
                      :to="{
                        path:
                          '/index/detail/industryInformationDetail/' + item.id,
                      }"
                      class="index-new-link"
                    >
                      <div flex="c">
                        <span class="text-overflow"> {{ item.nowTitle }}</span>
                        <span>{{ item.publishDate }}</span>
                      </div>
                      
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="index-selected">
            <div flex="sb" class="index-selected-title">
              <VtitleIcon name="本网精选" imgSrc="assets/img/bwjx.svg" />
              <More toHref="/index/list/WebsiteSelectedList" />
            </div>

            <ul class="index-selected-content">
              <router-link
                tag="li"
                :to="'/index/detail/WebsiteSelectedDetail/' + item.id"
                v-for="item in ttWebsiteSelectedData"
                :key="item.id"
              >
                <div class="index-selected-inner">
                  <img
                    src="@/assets/img/b-zcjd.svg"
                    alt=""
                    v-if="item.module == '政策解读'"
                  />
                  <img
                    src="@/assets/img/b-bzjd.svg"
                    alt=""
                    v-if="item.module == '标准解读'"
                  />
                  <img
                    src="@/assets/img/b-ztfx.svg"
                    alt=""
                    v-if="item.module == '专题分析'"
                  />
                  <img
                    src="@/assets/img/b-ycjd.svg"
                    alt=""
                    v-if="item.module == '原创解读'"
                  />
                  <img
                    src="@/assets/img/b-qyjs.svg"
                    alt=""
                    v-if="item.module == '前沿技术'"
                  />
                  <p class="text-overflow-4">{{ item.module }}</p>
                </div>
                <div class="index-selected-tip text-overflow-2">
                  {{ item.title }}
                </div>
              </router-link>
            </ul>
          </div>

          <div class="index-img-hot">
            <a
              :href="item.jumpLink"
              class="hot-img-link"
              target="_blank"
              v-for="item in ttAdvertisingManagementdData"
              :key="item.id"
            >
              <img :src="item.imgUrl" alt="" />
            </a>
          </div>

          <div class="index-list-box clearfix">
            <div class="index-list">
              <div flex="sb" class="dx-title">
                <VtitleIcon name="标准动向" imgSrc="assets/img/bzdx.svg" />
                <More toHref="/index/list/newStandardList" />
              </div>
              <ItemList
                v-for="item in newStandardData"
                :key="item.id"
                :to="'/index/detail/newStandardDetail/' + item.id"
                :border-none="true"
              >
                <!-- <slot slot="important" v-if="index == 0">重要</slot> -->
                <slot slot="title">{{ item.title }}</slot>
                <slot slot="time">{{ item.publishDate }}</slot>
              </ItemList>
            </div>
            <div class="index-list">
              <div flex="sb" class="dx-title">
                <VtitleIcon name="政策动向" imgSrc="assets/img/zcdx.svg" />
                <More toHref="/index/list/newPolicyList" />
              </div>
              <ItemList
                v-for="item in newPolicyData"
                :key="item.id"
                :to="'/index/detail/newPolicyDetail/' + item.id"
                :border-none="true"
              >
                <!-- <slot slot="important" v-if="index == 0">重要</slot> -->
                <slot slot="title">{{ item.title }}</slot>
                <slot slot="time">{{ item.publishDate }}</slot>
              </ItemList>
            </div>
          </div>
          <!-- 专题报道开始 -->
          <div class="special-box">
            <div flex="sb" class="special-title">
              <VtitleIcon name="专题报道" imgSrc="assets/img/ztbd.svg" />
              <More toHref="/index/list/specialList" />
            </div>

            <div class="special-content">
              <router-link
                tag="div"
                :to="'/index/detail/specialDetail/' + item.id"
                class="item"
                v-for="item in ttIndexSpecialPageSizeTwo"
                :key="item.id"
              >
                <img :src="item.specialUrl" alt="" />
                <p>{{ item.specialName }}</p>
                <!-- <div>专题</div> -->
              </router-link>
            </div>
          </div>
          <!-- 专题报道结束 -->

          <div class="index-tabs-box">
            <div class="index-tabs-btn">
              <div class="tabs-btn">
                <VtitleIcon
                  name="行业资讯"
                  :imgSrc="
                    tabsIndex == 0
                      ? 'assets/img/hyzx.svg'
                      : 'assets/img/hyzx-h.svg'
                  "
                  :colors="tabsIndex == 0 ? '#333' : '#828282'"
                  @click="tabsBtn(0)"
                />
              </div>
              <div class="tabs-btn">
                <VtitleIcon
                  name="分析报告"
                  :imgSrc="
                    tabsIndex == 1
                      ? 'assets/img/fxbg.svg'
                      : 'assets/img/fxbg-h.svg'
                  "
                  :colors="tabsIndex == 1 ? '#333' : '#828282'"
                  @click="tabsBtn(1)"
                />
              </div>
            </div>
            <ul class="index-tabs-content" :class="{ hide: tabsIndex == 1 }">
              <template v-for="(item, key) in selectByGroupData">
                <li :key="item.releaseColumn">
                  <div class="index-tabs-content-l">
                    <div class="index-tabs-content-l-t">
                      <img :src="setImg(key)" alt="" />
                      <h2>{{ setIndustryAndReportLabel(key) }}</h2>
                    </div>
                    <More
                      :toHref="{
                        path: '/index/list/IndustryAndReportList',
                        query: {
                          releaseColumn: item.releaseColumn,
                          category: 'industry',
                        },
                      }"
                      style="font-size: 12px"
                    />
                  </div>
                  <div class="index-tabs-content-r">
                    <template v-if="item.list">
                      <router-link
                        :to="{
                          path: '/index/detail/IndustryDetail',
                          query: {
                            id: citem.id,
                            releaseColumn: item.releaseColumn,
                            category: 'industry',
                          },
                        }"
                        class="index-tabs-link text-overflow"
                        v-for="citem in item.list"
                        :key="citem.id"
                      >
                        {{ citem.title }}
                      </router-link>
                    </template>
                  </div>
                </li>
              </template>
            </ul>
            <ul class="index-tabs-content" :class="{ hide: tabsIndex == 0 }">
              <template v-for="(item, key) in ttIndexAnalysissGroupData">
                <li :key="item.releaseColumn">
                  <div class="index-tabs-content-l">
                    <div class="index-tabs-content-l-t">
                      <img :src="setImg(key)" alt="" />
                      <h2>{{ setIndustryAndReportLabel(key) }}</h2>
                    </div>
                    <More
                      :toHref="{
                        path: '/index/list/IndustryAndReportList',
                        query: {
                          releaseColumn: item.releaseColumn,
                          category: 'report',
                        },
                      }"
                      style="font-size: 12px"
                    />
                  </div>
                  <div class="index-tabs-content-r">
                    <template v-if="item.list">
                      <router-link
                        :to="{
                          path: '/index/detail/ReportDetail',
                          query: {
                            id: citem.id,
                            releaseColumn: item.releaseColumn,
                            category: 'report',
                          },
                        }"
                        class="index-tabs-link text-overflow"
                        v-for="citem in item.list"
                        :key="citem.id"
                      >
                        {{ citem.title }}
                      </router-link>
                    </template>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="index-top-r">
          <div class="video-box">
            <div flex="sb" class="all-title">
              <VtitleIcon name="精彩视频" imgSrc="assets/img/sptj.svg" />
              <More toHref="/index/list/videoList" />
            </div>
            <div class="video-content">
              <router-link
                class="video-content-list"
                v-for="item in ttVideoRecommendationPageSizeThree"
                tag="div"
                :key="item.id"
                :to="'/index/detail/videoDetail/' + item.id"
              >
                <div class="video-content-img">
                  <img :src="item.coverUrl" alt="" />
                </div>
                <div class="video-content-conter">
                  <h1 class="text-overflow-2">{{ item.title }}</h1>
                  <!-- <p class="text-overflow-3">{{ item.summary }}</p>
                  <span>{{ item.publishDate }}</span> -->
                </div>
              </router-link>
            </div>
          </div>

          <div class="forum-box">
            <div flex="sb" class="all-title">
              <VtitleIcon name="泰达论坛" imgSrc="assets/img/tdlt.svg" />
              <More toHref="/index/list/taidaList" />
            </div>
            <ul class="forum-content">
              <li v-for="item in taidaData" :key="item.id">
                <router-link
                  :to="'/index/detail/taidaDetail/' + item.id"
                  class="text-overflow"
                  >{{ item.shortTitle }}</router-link
                >
                <span>{{ item.publishDate }}</span>
              </li>
            </ul>
          </div>

          <div class="carMarket-box">
            <div flex="sb" class="all-title">
              <VtitleIcon name="数说车市" imgSrc="assets/img/sscs.svg" />
              <More />
            </div>
            <div class="carMarket-item">
              <div class="carMarket-title">
                <span>上月总销量</span>
                <i class="el-icon-warning-outline"></i>
              </div>
              <h1>2358372</h1>
              <div class="carMarket-img">
                <img src="../../assets/img/data1.png" alt="" />
              </div>
              <p class="carMarket-tip">国内乘用车市场销量趋势</p>
            </div>
            <div class="carMarket-item">
              <div class="carMarket-title">
                <span>同比增长</span>
                <i class="el-icon-warning-outline"></i>
              </div>
              <h1>39.01%</h1>
              <div class="carMarket-img">
                <img src="../../assets/img/data2.png" alt="" />
              </div>
              <p class="carMarket-tip">国内乘用车市场销量趋势</p>
            </div>
          </div>

          <div class="interview-box">
            <div flex="sb" class="all-title">
              <VtitleIcon name="高端访谈" imgSrc="assets/img/gdft.svg" />
              <More toHref="/index/list/highInterviewList" />
            </div>
            <el-popover
              v-for="item in ttHighInterviewPageThree"
              :key="item.id"
              placement="top-start"
              width="300"
              trigger="hover"
              :content="item.sumary"
            >
              <router-link
                class="interview-item"
                tag="div"
                :to="'/index/detail/highInterviewDetail/' + item.id"
                slot="reference"
              >
                <div class="interview-img">
                  <img :src="item.url" alt="" />
                </div>
                <div class="interview-inner">
                  <span class="interview-inner-title text-overflow-2">{{
                    item.title
                  }}</span>
                  <!-- <p class="interview-inner-content text-overflow-2">
                    {{ item.sumary }}
                  </p> -->
                  <span class="interview-inner-time">{{
                    item.publishDate
                  }}</span>
                </div>
              </router-link>
            </el-popover>
          </div>

          <div class="meeting-box">
            <div flex="sb" class="all-title">
              <VtitleIcon name="会议信息" imgSrc="assets/img/hyxx.svg" />
              <More toHref="/index/list/meetingList" />
            </div>
            <el-tabs v-model="activeName" @tab-click="handleDateClick">
              <el-tab-pane label="全部时间" name=" "></el-tab-pane>
              <el-tab-pane
                :label="nowDate.dictLabel"
                :name="nowDate.dictValue"
              ></el-tab-pane>
              <el-tab-pane
                :label="nowDateOne.dictLabel"
                :name="nowDateOne.dictValue"
              ></el-tab-pane>
              <el-tab-pane
                :label="nowDateTwo.dictLabel"
                :name="nowDateTwo.dictValue"
              ></el-tab-pane>
            </el-tabs>
            <div>
              <div class="mettingSelect">
                <el-select
                  v-model="meetingVal"
                  placeholder="全部领域"
                  @change="handleDictClick"
                >
                  <el-option label="全部领域" value=""></el-option>
                  <el-option
                    v-for="item in meetingDict"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="meeting-list-box">
                <el-popover
                  v-for="item in ttMeetingInfoPageSizeSix"
                  :key="item.id"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.conferenceTitle"
                >
                  <div class="meeting-list" slot="reference">
                    <div class="meeting-list-title">
                      <a href="javascript:;">{{ item.conferenceTitle }}</a>
                      <i class="meeting-list-icon" v-if="item.identification">{{
                        item.identification
                      }}</i>
                    </div>
                    <span class="meeting-list-time">{{
                      item.dateConference
                    }}</span>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-friendly-link">
        <div flex="sb" class="all-title">
          <VtitleIcon name="友情链接" imgSrc="assets/img/yqlj.svg" />
        </div>
        <div class="index-friendly-link-img">
          <a
            :href="item.link"
            v-for="item in ttLinks"
            :key="item.id"
            target="_blank"
          >
            <img :src="item.imgUrl" alt="" />
          </a>
        </div>
      </div>
    </div>
    <Vfooter />
  </div>
</template>

<script>
import Vbanner from "@/components/banner.vue";
import Vfooter from "@/components/footer.vue";
import VtitleIcon from "@/components/titleIcon.vue";
// 获取首页请求接口
import {
  ttIndexSpecialPage,
  ttHighInterviewPage,
  ttLocalWatchPage,
  ttIndustryInformationPage,
  ttVideoRecommendationPage,
  ttMeetingInfoPage,
  selectByGroup,
  ttIndexAnalysissGroup,
  ttOtherPage,
  ttWebsiteSelectedPage,
  ttAdvertisingManagementPage,
  ttLinksPage,
  queryNewStandard,
  queryNewPolicy,
} from "@/api/Index.js";
//字典管理
import { selectDictDataByType } from "@/api/dict.js";
//泰达论坛
import { ttTaidaiBbsPage } from "@/api/Taidai.js";
import { getYearMonth, fmtDate } from "@/utils/date.js";
export default {
  components: {
    Vbanner,
    Vfooter,
    VtitleIcon,
  },
  data() {
    return {
      // 本网观察
      ttLocalWatchPageSizeOne: [],
      // 最新快讯
      ttIndustryInformationPageSizeFive: [],
      // 视频
      ttVideoRecommendationPageSizeThree: [],
      // 会议信息
      ttMeetingInfoPageSizeSix: [],
      // 专题管理
      ttIndexSpecialPageSizeTwo: [],
      // 高端访谈
      ttHighInterviewPageThree: [],
      //最新政策
      newPolicyData: [],
      //最新标准
      newStandardData: [],
      //行业咨询分组
      selectByGroupData: {
        cyc: {},
        syc: {},
        xny: {},
        znlw: {},
        lbj: {},
        xcss: {},
      },
      //分析报告分组
      ttIndexAnalysissGroupData: {
        cyc: {},
        syc: {},
        xny: {},
        znlw: {},
        lbj: {},
        xcss: {},
      },
      //会议字典
      meetingDict: [],
      //会议值
      meetingVal: "",
      //本网精选
      ttWebsiteSelectedData: [],
      //广告管理
      ttAdvertisingManagementdData: [],
      //泰达论坛
      taidaData: [],
      //友情链接
      ttLinks: [],

      banner: [],
      tabsIndex: 0,
      activeName: " ",
      show: false,
    };
  },
  computed: {
    nowDate() {
      return getYearMonth(new Date());
    },
    nowDateOne() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 1));
    },
    nowDateTwo() {
      return getYearMonth(new Date().setMonth(new Date().getMonth() + 2));
    },
  },
  created() {
    // 调用首页初始化
    this.getInfo();
    this.ttMeetingInfoPage();
  },
  methods: {
    /**
     * @description: 初始化首页
     * @param {*}
     * @return {*}
     */
    getInfo() {
      // 本网观察 取第一个接口
      ttLocalWatchPage({ pageSize: 1, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttLocalWatchPageSizeOne = res.data.list;
        }
      });
      // 最新快讯 取前4个数据
      ttIndustryInformationPage({
        pageSize: 4,
        page: 1,
        latestNews: "是",
      }).then((res) => {
        if (res.ok) {
          this.ttIndustryInformationPageSizeFive = res.data.list;
          this.ttIndustryInformationPageSizeFive.forEach((item) => {
            item.publishDate = fmtDate("YYYY-mm-dd", item.publishDate);
            if (item.shortTitle && item.shortTitle.length > 0) {
              item.nowTitle = item.shortTitle;
            } else {
              item.nowTitle = item.title;
            }
          });
        }
      });

      // 视频  取前三个数据
      ttVideoRecommendationPage({ pageSize: 3, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttVideoRecommendationPageSizeThree = res.data.list;
        }
      });
      // 专题管理  取2个数据
      ttIndexSpecialPage({ pageSize: 2, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttIndexSpecialPageSizeTwo = res.data.list;
        }
      });
      // 高端访谈  取3个数据
      ttHighInterviewPage({ pageSize: 3, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttHighInterviewPageThree = res.data.list;
          this.ttHighInterviewPageThree.forEach((item) => {
            item.publishDate = fmtDate("YYYY-mm-dd", item.publishDate);
          });
        }
      });
      //行业咨询分组
      selectByGroup().then((res) => {
        if (res.ok) {
          res.data.forEach((item) => {
            switch (item.releaseColumn) {
              case "industry_cyc":
                this.selectByGroupData.cyc = item;
                break;
              case "industry_syc":
                this.selectByGroupData.syc = item;
                break;
              case "industry_xny":
                this.selectByGroupData.xny = item;
                break;
              case "industry_znwl":
                this.selectByGroupData.znlw = item;
                break;
              case "industry_lbj":
                this.selectByGroupData.lbj = item;
                break;
              case "industry_xcss":
                this.selectByGroupData.xcss = item;
                break;
            }
          });
        }
      });
      //分析报告分组
      ttIndexAnalysissGroup().then((res) => {
        if (res.ok) {
          res.data.forEach((item) => {
            switch (item.releaseColumn) {
              case "industry_cyc":
                this.ttIndexAnalysissGroupData.cyc = item;
                break;
              case "industry_syc":
                this.ttIndexAnalysissGroupData.syc = item;
                break;
              case "industry_xny":
                this.ttIndexAnalysissGroupData.xny = item;
                break;
              case "industry_znwl":
                this.ttIndexAnalysissGroupData.znlw = item;
                break;
              case "industry_lbj":
                this.ttIndexAnalysissGroupData.lbj = item;
                break;
              case "industry_xcss":
                this.ttIndexAnalysissGroupData.xcss = item;
                break;
            }
          });
          // console.log(res.data, "res.ttIndexAnalysissGroupData");
          // this.ttIndexAnalysissGroupData = res.data;
        }
      });
      //首页轮播图
      ttOtherPage({ page: 1, pageSize: 6 }).then((res) => {
        if (res.ok) {
          this.banner = res.data.list;
        }
      });
      //获取会议字典
      selectDictDataByType({ dictType: "meeting_information" }).then((res) => {
        if (res.ok) {
          this.meetingDict = res.data;
        }
      });
      //本网精选
      ttWebsiteSelectedPage({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.ttWebsiteSelectedData = res.data.list;
        }
      });
      //广告管理
      ttAdvertisingManagementPage({ page: 1, pageSize: 3 }).then((res) => {
        if (res.ok) {
          this.ttAdvertisingManagementdData = res.data.list;
        }
      });
      //泰达论坛
      ttTaidaiBbsPage({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.taidaData = res.data.list;
          this.taidaData.forEach((item) => {
            item.publishDate = fmtDate("YYYY-mm-dd", item.publishDate);
          });
        }
      });
      //友情链接
      ttLinksPage({ page: 1, pageSize: 7 }).then((res) => {
        if (res.ok) {
          this.ttLinks = res.data.list;
        }
      });
      //最新标准
      queryNewStandard({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.newStandardData = res.data.list;
          this.newStandardData.forEach((item) => {
            item.publishDate = fmtDate("YYYY-mm-dd", item.publishDate);
          });
        }
      });
      //最新政策
      queryNewPolicy({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.newPolicyData = res.data.list;
          this.newPolicyData.forEach((item) => {
            item.publishDate = fmtDate("YYYY-mm-dd", item.publishDate);
          });
        }
      });
    },
    tabsBtn(index) {
      this.tabsIndex = index;
    },
    //获取当前年月
    getDate(s, num) {
      let date = new Date(),
        Y = date.getFullYear(),
        M = date.getMonth() + 1;
      return Y + s + (num ? M + num : M);
    },
    ttMeetingInfoPage() {
      // 会议信息  取前6个数据
      ttMeetingInfoPage({
        pageSize: 5,
        page: 1,
        meetField: this.meetingVal,
        dateConference: this.activeName,
      }).then((res) => {
        if (res.ok) {
          this.ttMeetingInfoPageSizeSix = res.data.list;
          this.ttMeetingInfoPageSizeSix.forEach((item) => {
            item.dateConference = fmtDate("YYYY-mm-dd", item.dateConference);
          });
        }
      });
    },
    //会议切换
    handleDictClick() {
      this.ttMeetingInfoPage();
    },
    handleDateClick() {
      this.ttMeetingInfoPage();
    },
    setImg(key) {
      let url = require("@/assets/img/" + key + ".svg");
      return url;
    },
    setIndustryAndReportLabel(key) {
      let str = "";
      switch (key) {
        case "cyc":
          str = "乘用车";
          break;
        case "syc":
          str = "商用车";
          break;
        case "xny":
          str = "新能源";
          break;
        case "znlw":
          str = "智能网联";
          break;
        case "lbj":
          str = "零部件";
          break;
        case "xcss":
          str = "新车上市";
          break;
      }
      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
.g-container {
  max-width: 1200px;
  margin: 0 auto;
  //  background: #fff;
}
.index-top-container {
  display: flex;
  margin-top: 31px;
}
.index-top-l {
  flex: 0 0 885px;
  margin-right: 23px;
  background: #fff;
}
.index-top-r {
  flex: 0 0 291px;
  padding-left: 12px;
}
.index-top-l,
.index-top-r {
  overflow: hidden;
}
.index-banner {
  display: flex;
  margin-bottom: 24px;
  .index-banner-l {
    flex: 0 0 431px;
    width: 431px;
    height: 264px;
    margin-right: 28px;
  }
  .index-banner-r {
    overflow: hidden;
  }
}

.ant-carousel {
  border-radius: 5px;
  overflow: hidden;
  ::v-deep .slick-slide {
    text-align: center;
    height: 361px;
    line-height: 361px;
    background: #364d79;
    overflow: hidden;
  }
  ::v-deep .slick-slide h3 {
    color: #fff;
  }
}

.index-banner-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-title-btn {
  position: relative;
  display: inline-block;
  padding: 4px;
  font-weight: bold;
  color: #fff;
  line-height: 14px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0D57BC;
    border-radius: 6px 6px 6px 0px;
  }
  span {
    position: relative;
    z-index: 1;
  }
}

.index-watch {
  margin-top: 12px;
  margin-bottom: 24px;
  h1 {
    position: relative;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }
}
.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.index-new-timeLine {
  margin-top: 12px;
  li {
    position: relative;
    // padding-bottom: 9px;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid #0D57BC;
      z-index: 1;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      left: 3px;
      width: 1px;
      height: 100%;
      background: #ccccf5;
    }
    .index-new-timeLine-content {
      position: relative;
      padding-left: 16px;
      padding-bottom: 12px;
      .index-new-link {
        display: block;
        line-height: 20px;
        color: #333;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
        div {
          span {
            &:nth-child(1) {
              flex: 0 0 328px;
            }
            &:nth-child(2) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }
    &:last-child {
      padding-bottom: 0px;
      &::after {
        display: none;
      }
      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.index-selected {
  .index-selected-title {
    margin-top: 24px;
    margin-bottom: 22px;
  }
  .index-selected-content {
    display: flex;
    li {
      flex: 1;
      height: 139px;
      margin-right: 6px;
      background: linear-gradient(159.74deg, #f3f5f9 -12.42%, #f8fafe 77.63%);
      border: 1px solid #f2f2f2;
      padding: 0 16px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .index-selected-inner {
        display: flex;
        align-items: center;
        height: 46px;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #142137;
        border-bottom: 1px solid #eaeaea;
        img {
          margin-right: 4px;
          width: 16px;
        }
      }
      .index-selected-tip {
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
        color: #142137;
      }
    }
  }
}
.index-img-hot {
  display: flex;
  height: 99px;
  margin: 36px 0;
  .hot-img-link {
    width: 33.3333%;
    &:nth-child(2) {
      margin: 0 8px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.index-list-box {
  margin-bottom: 35px;
  .index-list {
    float: left;
    width: 48%;
    .title {
      margin-bottom: 16px;
      ::v-deep h1 {
        font-size: 20px;
        line-height: 24px;
        color: #0D57BC;
      }
    }
  }
  .index-list:nth-child(1) {
    float: right;
  }
}
.index-center {
  padding: 0 10px;
  background: #fff;
}
.hr {
  width: 100%;
  height: 10px;
  background: #f6f6f6;
}
.special-box {
  margin-bottom: 36px;
  .special-title {
    margin-bottom: 24px;
    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: #0D57BC;
    }
  }
  .special-content {
    display: flex;
    height: 87px;
    .item {
      position: relative;
      flex: 1;
      cursor: pointer;
      border-radius: 5px;
      overflow: hidden;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.1;
      }
      &:nth-child(1) {
        margin-right: 28px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;
        font-size: 24px;
        height: 24px;
        line-height: 24px;
        margin-left: 16px;
        z-index: 1;
        color: #fff;
      }
    }
  }
}
.index-tabs-box {
  .index-tabs-btn {
    display: flex;
    margin-bottom: 41px;
    .tabs-btn {
      position: relative;
      margin-right: 16px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
    }
  }
  .index-tabs-content {
    display: flex;
    flex-flow: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -28px;
    margin-top: -18px;
    &.hide {
      display: none;
    }
    li {
      display: flex;
      width: 428px;
      overflow: hidden;
      background: #fdfdfd;
      height: 210px;
      margin-left: 28px;
      margin-top: 18px;
      border: 1px solid #f2f2f2;
      .index-tabs-content-l {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 106px;
        // height: 100%;
        padding: 40px 0px 30px;
        background: linear-gradient(159.74deg, #f3f5f9 -12.42%, #f8fafe 77.63%);
        .index-tabs-content-l-t {
          text-align: center;
          img {
            width: 25px;
          }
          h2 {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            margin-top: 12px;
            color: #142137;
          }
        }
      }
      .index-tabs-content-r {
        flex: 1;
        padding: 24px;
        overflow: hidden;
        .index-tabs-link {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333;
          padding-bottom: 2px;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 12px;
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
      }
      // .index-tabs-content-img {
      //   width: 160px;
      //   height: 245px;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
      // .index-tabs-content-top {
      //   overflow: hidden;
      //   margin-left: 17px;
      //   margin-top: 15px;
      //   flex: 1;
      //   .index-tabs-content-title {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     margin-bottom: 26px;
      //     h1 {
      //       font-size: 22px;
      //       line-height: 24px;
      //       margin-bottom: 0;
      //       flex: 1;
      //     }
      //   }
      // }
      // .index-tabs-content-list {
      //   a {
      //     display: block;
      //     font-weight: 500;
      //     color: #4f4f4f;
      //     line-height: 21px;
      //     margin-bottom: 15px;
      //     padding-bottom: 2px;
      //     border-bottom: 1px solid #f2f2f2;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     white-space: nowrap;
      //   }
      // }
    }
  }
}
.all-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: #0D57BC;
  }
}
.video-box,
.forum-box {
  margin-bottom: 10px;
  background: #fff;
}
.video-box {
  margin-bottom: 32px;
  .video-content {
    .video-content-list {
      display: flex;
      margin-top: 15px;
      cursor: pointer;
      .video-content-img {
        width: 108px;
        height: 64px;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -12px;
          margin-top: -12px;
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background: url(../../assets/img/bf.svg);
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .video-content-conter {
        margin-left: 10px;
        flex: 1;
        overflow: hidden;
        h1 {
          line-height: 20px;
          color: #333;
        }
      }
    }
  }
}
.forum-box {
  .forum-content {
    list-style: none;
    padding: 0;
    margin: 22px 0 28px;
    li {
      padding-bottom: 13px;
      margin-bottom: 16px;
      border-bottom: 1px solid #f2f2f2;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 14px;
        color: #bdbdbd;
      }
    }
  }
}
.carMarket-box {
  margin-bottom: 25px;
  background: #fff;
  .carMarket-item {
    &:nth-child(2) {
      margin-top: 17px;
      margin-bottom: 34px;
    }
    .carMarket-title {
      line-height: 21px;
    }
    h1 {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      margin-top: 5px;
    }
    .carMarket-img {
      width: 100%;
      height: 44px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .carMarket-tip {
      line-height: 22px;
      color: #828282;
      margin-bottom: 0;
      margin-top: 18px;
    }
  }
}
.interview-box {
  margin-bottom: 40px;
  background: #fff;
  .all-title {
    margin-bottom: 28px;
  }
  .interview-item {
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
    .interview-img {
      width: 88px;
      height: 88px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .interview-inner {
      flex: 1;
      overflow: hidden;
      .interview-inner-title {
        display: block;
        color: #333;
        line-height: 20px;
        height: 46px;
        margin-top: 8px;
      }
    }
    .interview-inner-content {
      line-height: 21px;
      height: 44px;
      color: #828282;
      margin: 4px 0 15px;
    }
    .interview-inner-time {
      line-height: 14px;
      color: #828282;
      padding-top: 12px;
    }
  }
}
.meeting-box {
  background: #fff;
  .all-title {
    margin-bottom: 22px;
  }
  ::v-deep .ant-tabs-nav {
    .ant-tabs-tab:hover,
    .ant-tabs-tab-active {
      color: #0D57BC;
    }
  }
  ::v-deep .ant-tabs-ink-bar {
    background: #0D57BC;
  }
  .ant-dropdown-link {
    color: #333;
  }
  .meeting-list-box {
    margin-top: 20px;
    margin-bottom: -16px;
    .meeting-list {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      overflow: hidden;
      margin-bottom: 16px;
      .meeting-list-title {
        position: relative;
        // display: flex;
        // align-items: center;
        color: #333;
        padding-left: 8px;
        margin-right: 15px;
        overflow: hidden;
        float: left;
        width: 177px;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 0;
          width: 2px;
          height: 82%;
          background: #0D57BC;
        }
        a {
          color: #333;
          // flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 98px;
          display: inline-block;
          vertical-align: top;
        }
        .meeting-list-icon {
          display: inline-block;
          padding: 2px 4px;
          border: 1px solid #0D57BC;
          color: #0D57BC;
          margin-left: 8px;
          line-height: 14px;
          font-style: normal;
          font-size: 12px;
          border-radius: 2px;
        }
      }
      .meeting-list-time {
        color: #828282;
        float: right;
      }
    }
  }
  /deep/ {
    .el-tabs__item {
      padding: 0 10px;
      height: 21px;
      line-height: 21px;
      font-weight: normal;
    }
    .el-tabs__nav {
      padding-bottom: 12px;
    }
    .is-active{
      font-weight: bold;
    }
    .el-tabs__nav-wrap::after{
      height: 1px;
    }
    .el-input__suffix{
      right: 27px;
    }
  }
}
.mettingSelect {
  width: 107px;
  /deep/ .el-input__inner {
    border-width: 0;
    padding: 0;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
  }
  /deep/ .el-input__icon {
    height: 20px;
    line-height: 20px;
  }
}
.index-friendly-link {
  margin-top: 31px;
  padding-bottom: 38px;
  background: #fff;
  .index-friendly-link-img {
    display: flex;
    margin-left: -20px;
    margin-top: 21px;
    a {
      display: block;
      flex: 0 0 154px;
      height: 110px;
      margin-left: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        // box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
      }
    }
  }
}
.dx-title {
  margin-bottom: 22px;
}
</style>
