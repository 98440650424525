import { getToken, setToken, removeToken } from '@/utils/auth.js'
const state = {
    token: getToken(),
    init: false, // 是否完成初始化 // 默认未完成
    RouterList: [] // 动态路由
}
const mutations = {
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_ROTER: (state, RouterList) => {
        state.RouterList = RouterList
    },
    SET_Init: (state, status) => {
        state.init = status
    }
}

const actions = {
    login({ commit }, userInfo) {
        setToken('登录了')
        commit('SET_TOKEN', userInfo)
    },
    // 前端 登出(测试方法)
    logout({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}