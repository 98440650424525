<template>
  <div class="banner-container">
    <div class="banner">
      <swiper ref="bannerRef" :options="swiperOption">
<!--        <swiper-slide>-->
<!--          <router-link class="banner3" to="/index/detail/specialDetail/2403051534260536" target="_blank">-->
<!--            <img src="@/assets/images/banner3.png" alt="international" />-->
<!--          </router-link>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide>-->
<!--          <div class="banner3" @click="handleClick" >-->
<!--            <img src="@/assets/images/banner-td.jpg" alt="international" />-->
<!--          </div>-->
<!--        </swiper-slide>-->

        <swiper-slide>
          <img src="@/assets/images/banner01.png" alt="us" />
          <router-link to="/us" target="_blank" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/images/banner02.png" alt="international" />
          <router-link class="international" to="/international/Information/index" target="_blank" />
        </swiper-slide>
      </swiper>

      <div class="swiper-pagination swiper-banner-pagination"></div>
      <div class="swiper-button-prev swiper-banner-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-next swiper-banner-next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import OfficialSwiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { getUserInfo } from '@/utils/auth';

OfficialSwiper.use([Autoplay, Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-banner-next',
          prevEl: '.swiper-banner-prev',
        },
        loop: true,
        pagination: {
          clickable: true,
          el: '.swiper-banner-pagination'
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
      },
      logined: false,
    };
  },
  created() {
    const userInfo = getUserInfo();
    if (userInfo) {
      this.logined = true;
    }
  },
  mounted() {
    // transion animation duration
    // app-content 150ms
    // sider 260ms
    const timeout = 260;
    setTimeout(() => {
      this.$refs.bannerRef.swiperInstance.update();
    }, timeout);
  },
  methods: {
    handleClick(item) {
      // if (!this.logined) {
      //   this.showLogin();
      //   return;
      // }else{
      //   this.$router.push('/policy/dynamic/LocalOriginalDetails/2403061645320008');
      // }
      window.open('https://forum.autoinfo.org.cn/channels/forum2024/index.html')
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
  }
};
</script>

<style lang="scss" scoped>
.banner-container {
  position: relative;
  z-index: 1;
  padding-top: 33.3333%;
  margin-bottom: 20px;

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ::v-deep .swiper-container {
      height: 100%;

      .swiper-slide {
        width: 100%;
        background-color: #fbfbfb;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        .banner3{
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        a {
          position: absolute;
          left: 6.7%;
          bottom: 26%;
          width: 11.7%;
          height: 11.1%;

          &.international {
            left: 6.3%;
          }

          &.banner3 {
            left: 0%;
            bottom: 0%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    ::v-deep .swiper-pagination {
      left: 6.7%;
      bottom: 5%;
      margin-left: -4px;

      .swiper-pagination-bullet {
        margin: 0 4px;
        width: 22px;
        height: 4px;
        background-color: #0069ea;
        border-radius: 2px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      transition: opacity .12s ease-out;
      pointer-events: none;
    }

    &:hover {

      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;
        pointer-events: auto;

        &.swiper-button-disabled {
          opacity: 0.35;
        }
      }
    }
  }
}
</style>
