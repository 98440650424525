<template>
    <PageContainer>
        <div class='main'>
            <div class='mainTitle'>高管变更历史</div>
            <el-table :span-method="objectSpanMethod" class='table' :data="histroyList" border>
                <el-table-column prop="position" label="职位" width="98">
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="76">
                </el-table-column>
                <el-table-column prop="briefIntro" label="简介" fit>
                    <template slot-scope="scope">
                        {{matchReg(scope.row.briefIntro)}}
                    </template>
                </el-table-column>
                <el-table-column prop="changeDate" label="变更时间" width="106">
                </el-table-column>
            </el-table>
            <Pagination :total="listMsg.count" :page.sync="listMsg.pageNo" :limit.sync="listMsg.pageSize" @pagination="getHistroy" />
        </div>
    </PageContainer>
</template>
<script>
import { getHistroyChange } from "@/api/enterpriseAnalysis.js";
import Pagination from "@/components/Pagination";
let rowWait;
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            enterpriseId: "",
            listMsg: {
                pageNo: 1,
                pageSize: 8,
                count: 1,
            },
            histroyList: [],
            list: [],
        };
    },
    mounted() {
        this.enterpriseId = this.$route.query.enterpriseId;
        // this.enterpriseId = '02be53f1b26c4708bc45d018378c209d';
        this.getHistroy();
    },
    methods: {
        getHistroy() {
            let data = {
                enterpriseId: this.enterpriseId,
                pageNo: this.listMsg.pageNo,
                pageSize: this.listMsg.pageSize,
            };
            getHistroyChange(data).then((res) => {
                if (res.ok) {
                    this.listMsg = res.data;
                    this.list = res.data.list;
                    rowWait = [];
                    this.histroyList = [];
                    let indexNum = 0;
                    res.data.list.forEach((item, index) => {
                        item.executiveInfoList.forEach((item1, index1) => {
                            item1.changeDate = item1.changeDate.split(" ")[0];
                            item1.index = indexNum;
                            indexNum += 1;
                            // item1.index=
                            this.histroyList.push(item1);
                        });
                    });
                    // this.histroyList.forEach((item,index)=>{
                    //   item.index=index;
                    // })
                    // console.log('this.histroyList',this.histroyList)
                    res.data.list.forEach((item, index) => {
                        if (item.executiveInfoList.length > 1) {
                            let row = this.histroyList.findIndex(
                                (item1, index1) => {
                                    return (
                                        item1.index ==
                                        item.executiveInfoList[0].index
                                    );
                                }
                            );
                            let line = item.executiveInfoList.length;
                            rowWait.push([row, line]);
                        }
                    });
                    //  console.log('rowWait',rowWait)
                    // console.log('executiveInfoList', this.histroyList)
                }
            });
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                // let rowNum;
                let isFindIndex = rowWait.findIndex((item) => {
                    return item[0] == rowIndex;
                });
                if (isFindIndex >= 0) {
                    return [rowWait[isFindIndex][1], 1];
                }
                let isNullIndex = rowWait.findIndex((item) => {
                    return rowIndex > item[0] && rowIndex < item[0] + item[1];
                });
                if (isNullIndex >= 0) {
                    return [0, 0];
                }
            }
        },
        //清标签
        matchReg(str) {
            let reg = /<\/?.+?\/?>/g;
            return str.replace(reg, "");
        },
    },
};
</script>

<style scoped lang="scss">
.main {
    padding: 20px 63px 80px 32px;
    background: #ffffff;
}
.mainTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0d57bc;
}
.table {
    margin-top: 30px;
}
</style>
