<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips"> </VnotLogin>-->
    <template>
      <el-row style="margin-bottom:16px">
        <el-col :span="14">
          <newlistHead title="最新政策" url="assets/img/gradientImg.png" href="/policy/dynamic/newListPolicy" />
          <div class="list-filter" flex="c" style="width: 100%;background: #fff;">

            <el-row>
              <!--              <el-col :span="24">-->
              <!--                <div style="margin: 20px">-->
              <!--                  <div class="zcTitle">政策类别</div>-->
              <!--                  <el-radio-group size="small">-->
              <!--                    <div style="display: inherit;margin-right: 10px;margin-bottom: 10px;"-->
              <!--                         v-for="item in policyCategoryData" :key="item.id">-->
              <!--                      <el-radio :label="item.name" border-->
              <!--                                @click.native="radioChange($event,item,'/policy/dynamic/newListPolicy?title='+item.name)">-->
              <!--                        {{ item.name }}-->
              <!--                      </el-radio>-->
              <!--                    </div>-->

              <!--                  </el-radio-group>-->
              <!--                </div>-->
              <!--              </el-col>-->
              <el-col :span="24">
                <div style="margin: 20px 20px 20px 30px">
                  <div class="zcTitle">热门领域</div>
                  <el-radio-group size="small" style="height: 96px">
                    <div
                      v-for="item in belongAreasData"
                      :key="item.dictValue"
                      style="display: inherit;margin-right: 10px;margin-bottom: 10px;"
                    >
                      <el-radio
                        :label="item.dictLabel"
                        border
                        @click.native="radioChange($event,item,'/policy/dynamic/newListPolicy?title='+item.dictValue+'&ac='+ac)"
                      >
                        {{ item.dictLabel }}
                      </el-radio>
                    </div>

                  </el-radio-group>
                </div>

              </el-col>

            </el-row>
          </div>
          <div class="box">
            <ul class="tab-box">
              <li
                v-for="item in tabData"
                :key="item.value"
                class="tab-list"
                :class="{ 'ac': ac == item.value }"
                @click="tabBtn(item.value)"
              >{{ item.label }}
              </li>

            </ul>

          </div>
          <ul v-loading="loading" class="newUl" style="padding-top: 25px">
            <li v-for="item in newPolicyDate" :key="item.id">

              <div class="newUl-link" @click="toHref('/policy/dynamic/contentNewPolicyDyn/'+item.id,item,'最新政策','tt_policy')">
                <template v-if="item.areas">
                  <div v-if="item.areas == '1'" class="newUl-areas">国家</div>
                  <div v-else class="newUl-areas df">地方</div>
                </template>
                <template v-if="item.province!='' && item.province!=null">
                  <div class="newUl-areas df" style="background-color: #f1dde1;color:#823D00">{{ item.province }}</div>
                </template>
                <template v-if="item.belongAreas">
                  <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</div>
                </template>
                <!--                <el-popover-->
                <!--                    placement="top-start"-->
                <!--                    trigger="hover"-->
                <!--                    :content="item.title">-->
                <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />

                <!--                </el-popover>-->
                <div class="newUl-time">{{ item.publicDate }}</div>
              </div>

            </li>
          </ul>
        </el-col>
        <el-col :span="10" style="float: right">
          <newlistHead title="政策报道" url="assets/img/gradientImg.png" href="/policy/dynamic/listReport" />
          <ul class="newUl ">
            <li v-for="item in policyReportData" :key="item.id">
              <div class="newUl-link" @click="toHref('/policy/dynamic/contentReport/'+item.id,item,'政策报道','tt_policy_report')">
                <!--                <template v-if="item.belongAreas">-->
                <!--                  <span class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</span>-->
                <!--                </template>-->
                <template v-if="item.belongAreas">
                  <span v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</span>
                </template>
                <!--                <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

                <!--                <div class="newUl-title">-->

                <!--                  {{ item.title }}-->
                <!--                </div>-->
                <!--                </el-tooltip>-->
                <TextOverTooltip ref-name="testName2" class-name="newUl-title" :content="item.title" />

                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>

    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {
  policyReport,
  newPolicy
} from '@/api/policy.js'
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { fmtDate } from '@/utils/date.js'
import { htmlRestore } from '@/utils/htmlRestore.js'
import { buryPoint } from '@/api/common'
import { selectDictDataByType, ttDemension } from '@/api/dict'
import TextOverTooltip from '@/components/title/index'
export default {
  components: {
    TextOverTooltip
  },
  data() {
    return {
      showTips: [
        {
          title: '政策动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '政策库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '政策梳理',
          text: '专家梳理整合，真正读懂政策脉络'
        },
        {
          title: '政策大数据',
          text: '用数据深度理解政策结构和趋势'
        }
      ],
      defaultImg: require('@/assets/img/dnc-img.png'),
      loading: false,
      tableData: [],
      pagination: {},
      show: false,
      tabData: [
        { value: '', label: '全部政策' },
        { value: '1', label: '国家政策' },
        { value: '2', label: '地方政策' }
      ],
      ac: '',
      policyReportData: [],
      belongAreasData: [],
      policyCategoryData: [],
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 15
        },
        total: 0,
        currentPage: 0
      },
      isShowTooltip: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      }

    }
  },
  computed: {
    newDate() {
      return fmtDate('YY年mm月', new Date())
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.init()
  },
  methods: {

    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 初始化加载
    init() {
      this.policyReport({ page: 1, pageSize: 20 })
      this.newPolicy()
      this.getCategory()
      this.ttDemension({ code: 'policyType' })
    },

    radioChange(e, tree, url) {
      if (e.target.tagName != 'INPUT') {
        return
      }
      this.$router.push({
        path: url

      })
    },

    // 获取字典
    getCategory() {
      selectDictDataByType({ dictType: 'policy_hot_field' }).then(
        (res) => {
          if (res.ok) {
            this.belongAreasData = res.data
          }
        }
      )
    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.policyCategoryData = res.data
        }
      })
    },
    toHref(url, item, column2, tableName) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('政策', column2, '1', tableName, item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 政策报道
    policyReport(params) {
      policyReport(params).then((res) => {
        if (res.ok) {
          this.policyReportData = res.data.list
          this.policyReportData.forEach((element) => {
            element.title = htmlRestore(element.title)
            element.updateTime = fmtDate(
              'mm-dd',
              element.updateTime
            )
          })
        }
      })
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e
        this.areas = e
        this.newPolicy()
      } else {
        this.showLogin()
      }
    },
    // 最新政策
    newPolicy() {
      this.loading = true
      newPolicy({
        page: 1,
        pageSize: 15,
        flag: 0,
        areas: this.ac
      }).then((res) => {
        if (res.ok) {
          this.newPolicyDate = res.data.list
          this.newPolicyDate.forEach((element) => {
            element.title = htmlRestore(element.title)
            element.publicDate = fmtDate(
              'mm-dd',
              element.publicDate
            )
          })
          this.newPolicyPage.total = res.data.count
          this.loading = false
        }
      })
    },
    // 字符串转数组
    strSplit(str) {
      return str.split(',')
    }
  }
}
</script>
<style lang="scss" scoped>
.dynamic-top {
  padding: 20px 32px;
  margin-bottom: 12px;
  background: #fff;

  .dynamic-top-original-box {
    display: flex;
    margin-top: 32px;
    margin-left: -26px;
    cursor: pointer;

    .dynamic-top-original {
      display: flex;
      flex: 1;
      margin-left: 26px;

      .dynamic-top-original-img {
        width: 177px;
        height: 99px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
        }
      }

      .dynamic-top-original-inner {
        flex: 1;
        margin-left: 6px;

        p {
          line-height: 21px;
          color: #000;
          font-weight: 500;
          height: 64px;
          overflow: hidden;
        }

        span {
          color: #828282;
        }
      }
    }
  }
}

.newUl-link {
  cursor: pointer;
}

.el-col-14 {
  width: 58%;
}
.radioGroup {
  display: flex;
  flex-wrap: wrap;
}
.zcTitle {
  margin-top: 10px;
  white-space: nowrap;
  padding: 8px 10px 0 0;
  font-weight: bold;
  font-size: 16px
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

.box {
  padding: 0 30px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 15px;
    //line-height: 10px;
    color: #000000;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }

  ::v-deep .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.el-radio--small.is-bordered {
  margin-top: 10px;
  padding: 8px 10px 0 10px;
}

</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
